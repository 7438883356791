import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Funder Name',
    name: 'funder_name',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Funder Reference',
    name: 'reference',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Client/Funder Category',
    name: 'funder_category__name',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Funder Status',
    name: 'status',
    status: 0,
    className: 'sm-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Funder Contact Name',
    name: 'funder_contact_full_name',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Organization Title',
    name: 'funder_contact_organisation_title',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Phone no.',
    name: 'funder_contact_phone_number',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Email',
    name: 'funder_contact_email',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Branch',
    name: 'branch',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
];
