import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress, onChangeValidations } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import { AppContext, BranchItemsContext } from 'context';
import { getChoices } from 'utils/api/CoreApi';
import {
  getBranchById,
  getCompanyById,
  saveCompanyLogo,
  setCompany,
  setCompanyBranch,
  uploadCompanyLogo,
} from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  ContainerResponsive,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import Geocode from 'react-geocode';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import _ from 'lodash';
import { company_setting_choices } from 'utils/choiceConstant';
import { UploadPhotoContainer, UserImgContainer, UserImgWrapper } from './styles';
import Image from 'components/SharedComponents/Image';
import { pickFile } from 'utils/files';
import { FiUpload } from 'react-icons/fi';

const edit_company = 'Edit Company';
const new_company = 'Add Company';

const CompanyPopup = ({
  fullBind,
  idSelected,
  company_pk,
  setTitle,
  loadBranches,
  setIdSelected,
  COMPANY_NOT_FOUND,
  loadCompany,
  handleChangeChoices,

  setIsChecked,
  setModalValue
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [address, setAddress] = useState("")
  const [companyLogo, setCompanyLogo] = useState()
  const toast = useRef();
  useEffect(() => {
    if (idSelected) {
      loadBranchById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  Geocode.setApiKey(process.env.REACT_APP_GOOGLEAPI_KEY);
  Geocode.setLanguage('en');
  // useEffect(() => {
  //   if (values) {
  //     if (values.line_1 && values.city && values.country && values.zip_code) {
  //       const final_add =
  //         values.line_1 +
  //         ' ' +
  //         values.city +
  //         ' ' +
  //         values.country +
  //         ' ' +
  //         values.zip_code;
  //       Geocode.fromAddress(final_add).then(
  //         response => {
  //           const {lat, lng} = response.results[0].geometry.location;
  //           values.latitude = lat;
  //           values.longitude = lng;
  //           setValues({...values});
  //         },
  //         error => {
  //           console.error(error);
  //         }
  //       );
  //     }
  //   }
  // }, [
  //   values && values.line_1,
  //   values && values.city,
  //   values && values.country,
  //   values && values.zip_code,
  // ]);
  const handleChange = (address) => {
    setAddress(address)
  }

  const handleSelect = async (value) => {
    handleChange(value)
    const result = await geocodeByAddress(value)
    console.log(result, "41544")
    const coordinates = await getLatLng(result[0])

    if (result) {
      let tempAddress = _.cloneDeep(result[0].address_components)

      values.latitude = coordinates.lat
      values.longitude = coordinates.lng
      result[0].address_components.forEach((element, index) => {
        if (element.types.find(value => value == 'postal_code')) {
          values.zip_code = element.long_name
          //  tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'country')) {
          values.country = element.long_name
          // tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'administrative_area_level_1')) {
          values.state = element.long_name
          // tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'administrative_area_level_2' || value == 'locality')) {
          values.city = element.long_name
          // tempAddress.splice(index,1)
        }

      });
      let lineAddress = ""
      tempAddress.forEach(ele => {
        if (ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
          || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
        ) {
          lineAddress = lineAddress
        } else {
          lineAddress += ele.long_name + " "
        }
      })
      values.line_1 = lineAddress
      setValues({ ...values })
    }
  }

  useEffect(() => {
    if (idSelected) {
      setTitle(edit_company);
    } else {
      setTitle(new_company);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const loadBranchById = async () => {
    const response = await getCompanyById(idSelected);
    response.created = moment(response.created).format('YYYY-MM-DD');
    response.modified = moment(response.modified).format('YYYY-MM-DD');
    setLoadingModal(false);
    setValues({ ...values, ...response });
  };
  const { contextChoices, setContextChoices } = useContext(AppContext);

  console.log(company_pk, "pk")
  const handleSubmit = async () => {
    if (!company_pk) {
      setErrorMessage(COMPANY_NOT_FOUND);
      return;
    }

    if (!isValid && errors.length > 0) {
      setErrorMessage('Some of the fields have invalid or empty values. Please review');
      setCheckRequires(true);
      return;
    }
    console.log(values, "values")
    setLoadingSave(true);
    let body = values.id
      ? {
        id: values.id,
        name: values.name,
        line_1: values.line_1,
        city: values.city,
        email: values.email,
        zip_code: values.zip_code,
        country: values.country,
        primary_company: false,
        telephone: values.telephone,
        line_1: values.line_1,
        line_2: values.line_2,
        state: values.state
      }
      : {
        name: values.name,
        line_1: values.line_1,
        city: values.city,
        email: values.email,
        zip_code: values.zip_code,
        country: values.country,
        primary_company: false,
        telephone: values.telephone,
        line_1: values.line_1,
        line_2: values.line_2,
        state: values.state
      };
    let response = await setCompany(body);
    console.log(response, '2222');
    if (response.status == true) {
      if (companyLogo) {
        saveCompanyLogo(response.data.id, companyLogo)
      }
      setLoadingSave(false);
      loadCompany()
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      toast && toast?.current && toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved Successfully',
      });
      // setIdSelected(response.id);
      setIsChecked(true);
      handleChangeChoices(company_setting_choices)
      console.log(contextChoices, "contextChoices")
    } else {
      setLoadingSave(false);

      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
    }
    setTimeout(() => {
      setModalValue()
    }, 1000)
  };

  const saveCompanyLogo = (id, logo) => {

    try {
      const res = uploadCompanyLogo(id, logo)
      console.log(res, "res")
    } catch (error) {

    }
  }

  const handlePickFile = async (evt, section) => {

    const result = await pickFile(evt);
    console.log(result, 555333)
    switch (section) {
      case 'photo':
        setCompanyLogo(result.file);
        break;
      case 'nok':
        // setNokPhoto(result.file);
        break;
      default:
    }
  };

  const allowBackspace = (e, type) => {
    if (e.key === 'Backspace') {
      if (values[type] && values[type].length > 0)
        setValues({ ...values, [type]: values[type].slice(0, -1) })
      e.preventDefault()
    }
  }
  const handleLongLatChange = (e, type) => {
    const { valid, value } = onChangeValidations[type](e)
    if (!valid) return;
    setValues({ ...values, [type]: value })
  }

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="pl-3 pr-3 pb-3 pt-0">
          <Toast ref={toast} position="top-right" />
          <ContainerResponsive className="d-flex">
            <div className="mr-md-2 mb-2 align-items-stretch">
              <UserImgWrapper>
                <UserImgContainer>
                  <Image
                    src={
                      companyLogo
                        ? URL.createObjectURL(companyLogo)
                        : values.logo
                          ? values.logo
                          : ''
                    }
                    name={values.first_name || values.full_name}
                    lastName={values.last_name}
                    alt={'employee-img'}
                    fontSize={'1.5rem'}
                  />
                </UserImgContainer>

                <UploadPhotoContainer className="d-flex align-items-center mt-2">
                  <UploadInput
                    onInput={e => {
                      handlePickFile(e, 'photo');
                    }}
                    onClick={event => {
                      event.target.value = null;
                    }}
                    type="file"
                    accept={'.png,.jpg,.jpeg'}
                    id="branch-logo"
                    name="branch-logo"
                    placeholder={'Logo'}
                  />
                  <FiUpload />
                  {
                    <label htmlFor="branch-logo">Upload</label>
                  }

                </UploadPhotoContainer>
              </UserImgWrapper>
            </div>
            <FlexGrid3Container className={'w-100'}>
              <FlexGrid3Item ResizeWidth={'49%'} className="mr-2">
                <InputLabelTop
                  required
                  type="text"
                  id="name"
                  label={'Company Name'}
                  {...useInput('name', 'isRequired')}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item ResizeWidth={'49%'} >
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {
                    ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <InputLabelTop
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#D3D3D3', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )


                  }
                </PlacesAutocomplete>
              </FlexGrid3Item>
              <FlexGrid3Item ResizeWidth={'49%'} className="mr-2">
                <InputLabelTop
                  type="text"
                  id="address_line_1"
                  label={'Address Line 1'}
                  {...useInput('line_1')}
                  maxlength={255}
                />
              </FlexGrid3Item>
              <FlexGrid3Item ResizeWidth={'49%'} >
                <InputLabelTop
                  type="text"
                  id="address_line_2"
                  label={'Address Line 2'}
                  {...useInput('line_2')}
                  maxlength={255}
                />
              </FlexGrid3Item>
              <FlexGrid3Item ResizeWidth={'49%'} className="mr-2">
                <InputLabelTop
                  type="text"
                  id={'town_city'}
                  label={'Town / City'}
                  {...useInput('city')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item ResizeWidth={'49%'} >
                <InputLabelTop
                  type="text"
                  id={'state'}
                  label={'County / State'}
                  {...useInput('state')}
                  maxlength={100}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
          </ContainerResponsive>
          {/* <div>
            <InputLabelTop
              required
              type="text"
              id="name"
              label={'Company Name'}
              {...useInput('name', 'isRequired')}
              maxlength={50}
            />
          </div> */}
          {/* <div>
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {
                ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <InputLabelTop
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#D3D3D3', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )


              }
            </PlacesAutocomplete>
          </div> */}
          <FlexGrid3Container className={'w-100 mb-2'}>

            {/* <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
              <InputLabelTop
                type="text"
                id="address_line_1"
                label={'Address Line 1'}
                {...useInput('line_1')}
                maxlength={255}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
              <InputLabelTop
                type="text"
                id="address_line_2"
                label={'Address Line 2'}
                {...useInput('line_2')}
                maxlength={255}
              />
            </FlexGrid3Item> */}
            {/* <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
              <InputLabelTop
                type="text"
                id={'town_city'}
                label={'Town / City'}
                {...useInput('city')}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 ">
              <InputLabelTop
                type="text"
                id={'state'}
                label={'County / State'}
                {...useInput('state')}
                maxlength={100}
              />
            </FlexGrid3Item> */}
            <FlexGrid3Item className="pr-2">
              <InputLabelTop
                type="text"
                id={'postcode_zip_code'}
                label={'Postcode / Zip code'}
                {...useInput('zip_code')}
                maxlength={25}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-2">
              <InputLabelTop
                type="text"
                id={'country'}
                label={'Country'}
                {...useInput('country')}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="">
              <InputLabelTop
                //disabled={true}
                type="text"
                id="address_latitude"
                label={'Latitude'}
                {...useInput('latitude', 'isDecimal')}
                onChange={(e) => handleLongLatChange(e, 'latitude')}
                onKeyDown={(e) => allowBackspace(e, 'latitude')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-2">
              <InputLabelTop
                //disabled={true}
                type="text"
                id="longitude"
                label={'Longitude'}
                {...useInput('longitude', 'isDecimal')}
                onChange={(e) => handleLongLatChange(e, 'longitude')}
                onKeyDown={(e) => allowBackspace(e, 'longitude')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-2">
              <InputLabelTop
                type="text"
                id="telephone"
                label={'Phone Number'}
                onKeyPress={KeyPress.PHONE}
                {...useInput('telephone', 'isMobilePhone')}
                maxlength={25}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="">
              <InputLabelTop
                required
                type="email"
                id="personal_email"
                label={'Email'}
                {...useInput('email', 'isRequired')}
                maxlength={254}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default CompanyPopup;
