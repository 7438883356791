import React from 'react';
import PayrollSettings from './RateCardSettings';
import { ModalTabsContainer } from 'components/DashboardComponents/Team/AddModal/ModalStyle';
import { TabStyled } from 'shared/styles/constants/tagsStyles';
import { useState } from 'react';
import NonCommuteTravelSetting from './NonCommuteTravelSetting';


const Payroll = ({
  payrollTabData,
  getApiData,
  readPermissionPayroll,
  createPermissionPayroll,
  updatePermissionPayroll,
  deletePermissionPayroll,

  readPermissionPayrollNonCommute,
  createPermissionPayrollNonCommute,
  updatePermissionPayrollNonCommute,
  deletePermissionPayrollNonCommute
}) => {
  const tab1 = 1;
  const tab2 = 2;

  const [tabSelected, setTabSelected] = useState(tab1)

  const isCurrentTab = tab => tab === tabSelected;

  return (
    <>
      <div>
        <ModalTabsContainer>
          {readPermissionPayroll && (
            <TabStyled
              active={isCurrentTab(tab1)}
              onClick={() => {
                setTabSelected(tab1)
              }}>
              Rate Card Settings
            </TabStyled>)}
          {readPermissionPayrollNonCommute && (
            <TabStyled
              active={isCurrentTab(tab2)}
              onClick={() => {
                setTabSelected(tab2)
              }}>
              Non Commute Travel Setting
            </TabStyled>)}
        </ModalTabsContainer>
      </div>
      {tabSelected == tab1 && (
        <PayrollSettings
          payrollTabData={payrollTabData}
          getApiData={getApiData}
          readPermission={readPermissionPayroll}
          createPermission={createPermissionPayroll}
          updatePermission={updatePermissionPayroll}
          deletePermission={deletePermissionPayroll}
        />
      )}
      {
        tabSelected === tab2 && (
          <NonCommuteTravelSetting
            readPermission={readPermissionPayrollNonCommute}
            createPermission={createPermissionPayrollNonCommute}
            updatePermission={updatePermissionPayrollNonCommute}
            deletePermission={deletePermissionPayrollNonCommute}
          />
        )
      }
    </>
  )
}

export default Payroll