import React from 'react'
import { Box, Button, ButtonGroup, Checkbox, Fade, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Modal, Radio, RadioGroup, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { memo } from 'react'
import { useDrop } from 'react-dnd'
import DropDown from './FormDesigns/dropDown'
import { setTag } from './service'
import DropDownForChoices from './FormDesigns/dropDownForChoices'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Card } from './Card'
import { useCallback } from 'react'
import update from "immutability-helper";
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { RiDeleteBinLine } from 'react-icons/ri'
import { MdEdit } from 'react-icons/md'
import { ItemTypes } from './ItemTypes.js'
import { HTML_NAMES } from './constants'
import { LARGE_COL } from 'shared/styles/constants/columns'
import Table from 'components/SharedComponents/Table'
import {
    Unstable_NumberInput as BaseNumberInput,
    numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import DropDownTableOptions from './FormDesigns/dropDownTableOptions'
import { useRef } from 'react'
import { useMemo } from 'react'
import './../Client/ClientTask/taskStyle.css'
import { ERR_COLOR } from 'shared/styles/constants/colors'
import { DOWNLOAD, INFO_ICON } from 'utils/constants/icons'
import './formdata.css'
import ReactHtmlParser from 'react-html-parser';
import InputLabelTop from 'components/SharedComponents/InputLabelTop'
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons'
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles'
import { FiUpload } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa'
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import RichTextEditor from 'components/SharedComponents/RichTextEditor'

const style = {
    height: '90vh',
    width: '95%',
    textAlign: 'center',
    lineHeight: 'normal',
    float: 'left',
    overflow: "scroll"
}
export const Dustbin = memo(function Dustbin({
    accept,
    lastDroppedItem,
    onDrop,
    setDustbins,
    dustbins,
    setJson,
    json,
    handleOpen,
    setOpenSignature,
    setModalBodyMap,
    contextChoices
}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    // console.log('json', lastDroppedItem.filter((val) => [HTML_NAMES.multidropdown, HTML_NAMES.section, HTML_NAMES.checkbox, HTML_NAMES.dropdown, HTML_NAMES.radioGroup].includes(val.type)))

    const isActive = isOver && canDrop
    let backgroundColor = '#fff'
    if (isActive) {
        backgroundColor = '#dbd7d7'
    } else if (canDrop) {
        backgroundColor = '#f5eded'
    }

    // const convertStringToHTML = htmlString => {
    //     const parser = new DOMParser();
    //     const html = parser.parseFromString(htmlString, 'text/xml');
    //     return html.firstChild
    // }

    // let stringToHTML = function (str) {
    //     var dom = document.createElement('div');
    //     dom.innerHTML = str;
    //     return dom;
    // };

    const renderCols = (counts) => {
        switch (counts) {
            case '1':
                return '12';
            case '2':
                return '6';
            case '3':
                return '4';
            case '4':
                return '3';
            case '5':
                return '2';
            case '6':
                return '2';
            default:
                return '3';
        }
    }

    const getBodyData = (body_data) => {
        const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck', 'knees', 'left-soleus', 'right-soleus']
        const muscleFromBody = body_data?.every(item => listOfBodyParts.includes(item));
        const data = [
            { name: '', muscles: (muscleFromBody && body_data) ? [...body_data] : [] },
        ];
        return data
    }

    const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
        return (
            <BaseNumberInput
                slots={{
                    root: StyledInputRoot,
                    input: StyledInputElement,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                }}
                slotProps={{
                    incrementButton: {
                        children: '▴',
                    },
                    decrementButton: {
                        children: '▾',
                    },
                }}
                {...props}
                ref={ref}
            />
        );
    });
    // displays item name, id, action buttons and then dummy UI
    const wrapper = (dummy, addItem, index) => {
        return (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", borderWidth: 1, borderStyle: "dotted", borderColor: "#dbd5d5" }}>
                <div style={{ backgroundColor: "#dbd5d5", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ padding: 10 }}>
                        {addItem.type}
                    </div>
                    <div style={{ padding: 10 }}>
                        Field{addItem.id}
                    </div>
                    <div style={{ display: "flex" }}>
                        <IconWrapper
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                console.log(addItem, "PREBIOUS ITEM")

                                if (addItem.type === ItemTypes.Heading) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, headingText: {
                                                label: addItem.value,
                                                tag: addItem.html,
                                                description: addItem.description,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                } else if (addItem.type === HTML_NAMES.textInput) {

                                    setJson((prev) => {
                                        return {
                                            ...prev, textInput: {
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                evaluateCondition: addItem.evaluateCondition,
                                                evaluateExpression: addItem.evaluateExpression,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.numberInput) {

                                    setJson((prev) => {
                                        return {
                                            ...prev, numberInput: {
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                evaluateCondition: addItem.evaluateCondition,
                                                evaluateExpression: addItem.evaluateExpression,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.decimalInput) {

                                    setJson((prev) => {
                                        return {
                                            ...prev, decimalInput: {
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                evaluateCondition: addItem.evaluateCondition,
                                                evaluateExpression: addItem.evaluateExpression,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.dropdown) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, dropdown: {
                                                options: addItem.options,
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.multidropdown) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, multidropdown: {
                                                options: addItem.options,
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.switch) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, switch: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.hyperLink) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, hyperLink: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                placeholder: addItem.placeholder,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.button) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, button: {
                                                label: addItem.label,
                                                type: addItem.html,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.radioGroup) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, radioGroup: {
                                                options: addItem.options,
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.buttonGroup) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, buttonGroup: {
                                                options: addItem.options,
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.checkbox) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, checkbox: {
                                                options: addItem.options,
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.textArea) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, textArea: {
                                                placeholder: addItem.placeholder,
                                                label: addItem.label,
                                                numberOfRows: addItem.numberOfRows,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.choices) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, choices: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.datePicker) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, datePicker: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                format: addItem.format
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.timePicker) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, timePicker: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                format: addItem.format
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.dateTime) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, dateTimePicker: {
                                                label: addItem.label,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                format: addItem.format
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.signature) {
                                    setJson((prev) => {
                                        return {
                                            ...prev, signature: {
                                                label: addItem.label,
                                                type: addItem.html,
                                                description: addItem.description,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.table) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, table: {
                                                ...prev.table,
                                                cols: addItem.cols,
                                                rows: addItem.rows,
                                                placeholder: addItem.placeholder,
                                                label: addItem.label,
                                                description: addItem.description,
                                                instruction: addItem.instruction,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.section) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, section: {
                                                ...prev.section,
                                                rows: addItem.rows,
                                                cols: addItem.cols,
                                                sectionColsCount: addItem.sectionColsCount,
                                                placeholder: addItem.placeholder,
                                                label: addItem.label,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.richTextHtmlInput) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, richTextHtmlInput: {
                                                ...prev.richTextHtmlInput,
                                                cols: addItem.cols,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.richTextHtmlHeading) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, richTextHtmlHeading: {
                                                ...prev.richTextHtmlHeading,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.photoAttach) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, photoAttach: {
                                                ...prev.photoAttach,
                                                file: addItem.file,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                value: addItem.value,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.fileUpload) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, photoAttach: {
                                                ...prev.photoAttach,
                                                file: addItem.file,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                value: addItem.value,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.fileDownload) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, photoAttach: {
                                                ...prev.photoAttach,
                                                file: addItem.file,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                value: addItem.value,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.bodyMapDisplay) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, photoAttach: {
                                                ...prev.photoAttach,
                                                file: addItem.file,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                value: addItem.value,
                                                title: addItem.title,
                                                view: addItem.view,

                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }
                                else if (addItem.type === HTML_NAMES.bodyMapInput) {
                                    setJson((prev) => {
                                        console.log(prev, "PREBIOUS")
                                        return {
                                            ...prev, photoAttach: {
                                                ...prev.photoAttach,
                                                file: addItem.file,
                                                label: addItem.label,
                                                placeholder: addItem.placeholder,
                                                description: addItem.description,
                                                required: addItem.required,
                                                readOnly: addItem.readOnly,
                                                instruction: addItem.instruction,
                                                showCondition: addItem.showCondition,
                                                ruleExpression: addItem.ruleExpression,
                                                value: addItem.value,
                                            }
                                        }
                                    })
                                    handleOpen(addItem)
                                }

                                console.log("ITEMS RENDERING")
                            }}>
                            <MdEdit />
                        </IconWrapper>
                        <IconWrapper
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                let data = dustbins.map((val, indexInn) => {
                                    return {
                                        ...val,
                                        lastDroppedItem: [...val.lastDroppedItem.filter((valu, index1) => index1 !== index)]
                                    }
                                })
                                setDustbins(data)
                            }}>
                            <RiDeleteBinLine />
                        </IconWrapper>

                    </div>
                </div>
                <div style={{ width: '100%', alignSelf: addItem.cols ? '' : "flex-start", padding: 10 }}>
                    {
                        dummy
                    }
                </div>

            </div >
        )
    }

    // const renderTableRow = (val = {}, item = {}, index) => {
    //     switch (val.type) {
    //         case "Text":
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <label for="fname" style={{}} >{val.value}</label>
    //                 </div>
    //             )
    //         case HTML_NAMES.textInput:
    //             return (
    //                 <div style={{ alignItems: "center" }}>
    //                     <input type="text" id="fname" name="fname" value={val.value} placeholder={item.placeholder} style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
    //                 </div>
    //             )
    //         case HTML_NAMES.dropdown:
    //             return (
    //                 <div style={{ display: "flex", width: '100%', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <DropDownTableOptions contextChoices={contextChoices} json={val} minWidth={'100%'} />
    //                 </div>
    //             )
    //         case 'Choice Dropdown':
    //             return (
    //                 <div style={{ display: "flex", width: '100%', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <DropDownTableOptions contextChoices={contextChoices} json={val} minWidth={'100%'} />
    //                 </div>
    //             )
    //         case HTML_NAMES.headingText:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     {setTag({ tag: val.html, label: val.value })}
    //                 </div>
    //             )
    //         case HTML_NAMES.numberInput:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <CustomNumberInput className='w-100' aria-label="Demo number input" placeholder="Type a number…" />
    //                 </div>
    //             )
    //         case HTML_NAMES.decimalInput:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <input type="number" className='w-100' onkeydown="if(this.key==='.'){this.preventDefault();}" id="myInput" name="inputBox" placeholder={item.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
    //                 </div>
    //             )
    //         case HTML_NAMES.multidropdown:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <DropDownTableOptions contextChoices={contextChoices} json={val} minWidth={'100%'} multiple={true} />
    //                 </div>
    //             )
    //         case HTML_NAMES.switch:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <FormControlLabel
    //                         value="start"
    //                         control={
    //                             <Switch color="primary" />}
    //                         label={<>
    //                         </>}
    //                         style={{ margin: '0px', alignItems: 'flex-start', fontWeight: "bold", justifyContent: "flex-start" }}
    //                         labelPlacement="top"
    //                     />
    //                 </div>
    //             )
    //         case HTML_NAMES.hyperLink:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     {<p style={{ marginBottom: 8, fontSize: 12 }}>
    //                         <a href={`${item.description}`}>{item.placeholder}</a>
    //                     </p>}
    //                 </div>
    //             )
    //         case HTML_NAMES.radioGroup:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <RadioGroup
    //                         row
    //                         aria-labelledby="demo-radio-buttons-group-label"
    //                         defaultValue="female"
    //                         name="radio-buttons-group"
    //                     >
    //                         {
    //                             item?.options?.map((val, index) => {
    //                                 return (
    //                                     <FormControlLabel key={index} value={val.value || ""} control={<Radio />} label={val.value || ""} />
    //                                 )
    //                             })
    //                         }
    //                     </RadioGroup>
    //                 </div>
    //             )
    //         case HTML_NAMES.checkbox:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <FormGroup row >
    //                         {
    //                             item?.options?.map((val, index) => {
    //                                 return (
    //                                     <FormControlLabel control={<Checkbox />} label={val.value || ""} />
    //                                 )
    //                             })
    //                         }
    //                     </FormGroup>
    //                 </div>
    //             )
    //         case HTML_NAMES.textArea:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <textarea rows={Number(item.html)} id="fname" name="fname" placeholder={item.placeholder} style={{ width: '100%', height: '45px', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}>{val.value}</textarea>
    //                     {/* <TextField
    //                         className='w-100'
    //                         id="outlined-multiline-static"
    //                         label={item.label}
    //                         multiline
    //                         sx={{
    //                             '& legend': { display: 'none' },
    //                             '& .MuiInputLabel-shrink': { opacity: 0, transition: "all 0.2s ease-in" }
    //                         }}
    //                         rows={Number(item.html)}
    //                         placeholder={item.placeholder}
    //                         style={{height:'45px', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
    //                     /> */}
    //                 </div>
    //             )
    //         case HTML_NAMES.datePicker:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <div className='formdata w-100'>
    //                         <ReactDatePicker
    //                             showIcon
    //                             selected={new Date()}
    //                             style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
    //                             //   onSelect={handleDateSelect}
    //                             //   onChange={handleDateChange} 
    //                             className='formDataDatePicker'
    //                             icon="fa fa-calendar"
    //                             label="Basic date picker" />
    //                     </div>
    //                 </div>
    //             )
    //         case HTML_NAMES.timePicker:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <div className='formdata w-100'>
    //                         <ReactDatePicker
    //                             showIcon
    //                             selected={new Date()}
    //                             style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
    //                             showTimeSelect
    //                             showTimeSelectOnly
    //                             timeFormat={"HH:mm"}
    //                             dateFormat={"HH:mm"}
    //                             className='formDataDatePicker'
    //                             icon="fa fa-calendar"
    //                             label="Basic date picker" />
    //                     </div>
    //                 </div>
    //             )
    //         case HTML_NAMES.dateTime:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <div className='formdata w-100'>
    //                         <ReactDatePicker
    //                             showIcon
    //                             selected={new Date()}
    //                             style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
    //                             showTimeSelect
    //                             dateFormat="Pp"
    //                             className='formDataDatePicker'
    //                             icon="fa fa-calendar"
    //                         />
    //                     </div>
    //                 </div>
    //             )
    //         case HTML_NAMES.signature:
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <Button onClick={() => setOpenSignature(true)} variant={item.html}>{item.label}</Button>
    //                 </div>
    //             )

    //     }
    // }
    const renderMainItemComponent = (item) => {
        switch (item.type) {
            case HTML_NAMES.headingText:
                return setTag({ tag: item.html, label: item.value })
            case HTML_NAMES.textInput:
                return <input type="text" id="fname" name="fname" placeholder={item.placeholder} style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
            case HTML_NAMES.numberInput:
                return <CustomNumberInput className='w-100' aria-label="Demo number input" placeholder="Type a number…" />
            case HTML_NAMES.decimalInput:
                return <input type="number" className='w-100' onkeydown="if(this.key==='.'){this.preventDefault();}" id="myInput" name="inputBox" placeholder={item.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
            case HTML_NAMES.dropdown:
                return <DropDown minWidth='100%' json={item} />
            case HTML_NAMES.multidropdown:
                return <DropDown minWidth='100%' json={item} multiple={true} />
            case HTML_NAMES.switch:
                return (<FormControlLabel
                    value="start"
                    control={
                        <Switch color="primary" />}
                    label={<div style={{ textAlign: 'left' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    </div>}
                    style={{ margin: '0px', alignItems: 'flex-start' }}
                    labelPlacement="top"
                />)
            case HTML_NAMES.hyperLink:
                return <a href={`${item.value}`}>{item.placeholder}</a>
            case HTML_NAMES.button:
                return <Button variant={item.html}>{item.label}</Button>
            case HTML_NAMES.radioGroup:
                return (<RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                >
                    {
                        item?.options?.map((val, index) => {
                            return (
                                <FormControlLabel key={index} value={val.value || ""} control={<Radio />} label={val.value || ""} />
                            )
                        })
                    }
                </RadioGroup>)
            case HTML_NAMES.buttonGroup:
                return (<div
                    style={{
                        display: "flex",
                        flexDirection: "row"
                    }}>
                    {
                        item.options.map((val, index) => {
                            // console.log(val, "Value")
                            return (
                                <Button key={index} variant={val.type} style={{ marginRight: 5 }}>{val.value}</Button>
                            )
                        })
                    }
                </div >)
            case HTML_NAMES.checkbox:
                return (<FormGroup row >
                    {
                        item?.options?.map((val, index) => {
                            return (
                                <FormControlLabel control={<Checkbox />} label={val.value || ""} />
                            )
                        })
                    }
                </FormGroup>)
            case HTML_NAMES.textArea:
                return (<TextField
                    className='w-100'
                    id="outlined-multiline-static"
                    label={item.label}
                    sx={{
                        '& legend': { display: 'none' },
                        '& .MuiInputLabel-shrink': { opacity: 0, transition: "all 0.2s ease-in" }
                    }}
                    rows={Number(item.html)}
                    placeholder={item.placeholder}
                // style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                />)
            case HTML_NAMES.table:
                return (<Table
                    headerColumns={item.cols ?
                        item.cols.filter(val => val).map((val) => ({
                            label: val.column_name,
                            name: '',
                            status: 0,
                            className: 'grow-mobile',
                            max_width: LARGE_COL,
                        }))
                        :
                        []
                    }
                    // callBackQuerySort={setQuerySort}
                    headerPadding={false}
                    handleAllCheck={() => { }}
                    noNeed={true}
                    noEditOption
                    handleAllSelectChange={() => { }}>
                    <div>
                        {item.rows &&
                            item.rows.map((table_d, index) => {
                                return (
                                    <div>
                                        <Row bgColor>
                                            <ColsGrouper className="d-flex">
                                                {item.cols && item.cols.map((val, i) => {
                                                    return (
                                                        <>
                                                            <Col
                                                                max_width={LARGE_COL}
                                                                Center>
                                                                {val ? renderMainItemComponent(val, table_d, index) : <></>}
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                                }
                                                <Col Center Shrink NoFlexGrow>
                                                    <IconWrapper
                                                        onClick={() => {
                                                            // toDeleteClient(client.id);
                                                        }}>
                                                        <RiDeleteBinLine />
                                                    </IconWrapper>
                                                </Col>
                                            </ColsGrouper>
                                        </Row>

                                        <div className="mb-1" />
                                    </div>
                                )
                            })}
                    </div>
                </Table>)
            case HTML_NAMES.section:
                return (<div className="row pt-2">
                    {item.cols &&
                        item.cols.map((sec_d, index) => {
                            return (
                                <div className={`col-${renderCols(item.sectionColsCount)}`}>
                                    <div className='w-100 my-1' style={{ display: "flex", flexDirection: 'column' }}>
                                        <div style={{ textAlign: 'left', width: '100%' }}>
                                            <div className='w-100 d-flex justify-content-between'>
                                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{sec_d?.label}<span style={{ color: ERR_COLOR }}>{sec_d?.required && '*'}</span></label>
                                                {item.instruction &&
                                                    <Tooltip title={item.instruction}>
                                                        <IconButton size={'small'}>
                                                            {INFO_ICON}
                                                        </IconButton>
                                                    </Tooltip>}
                                            </div>
                                            {sec_d?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{sec_d?.description}</p>}
                                        </div>
                                        {sec_d ? renderMainItemComponent(sec_d, sec_d, index) : <></>}
                                    </div>
                                </div>
                            )
                        })}
                </div>)
            case HTML_NAMES.choices:
                return <DropDownForChoices minWidth='100%' json={item} multiple={item.is_multi} />
            case HTML_NAMES.datePicker:
                return (<div className='formdata w-100'>
                    <ReactDatePicker
                        dateFormat={item.format === "" ? undefined : item.format}
                        showIcon
                        selected={""}
                        style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        //   onSelect={handleDateSelect}
                        //   onChange={handleDateChange} 
                        className='formDataDatePicker'
                        icon="fa fa-calendar"
                        label="Basic date picker" />
                </div>)
            case HTML_NAMES.timePicker:
                return (<div className='formdata w-100'>
                    <ReactDatePicker
                        showIcon
                        selected={""}
                        style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat={item.format === "" ? undefined : item.format}
                        dateFormat={item.format === "" ? "p" : item.format}
                        className='formDataDatePicker'
                        label="Basic date picker" />
                </div>)
            case HTML_NAMES.dateTime:
                let dateFormat = item.format;
                let timeFormat = "";
                if (item.format) {
                    timeFormat = item.format.split(" ")[1];
                    if (item.format.split(" ")[2])
                        timeFormat = timeFormat + " " + item.format.split(" ")[2]
                } else timeFormat = undefined
                return (<div className='formdata w-100'>
                    <ReactDatePicker
                        showIcon
                        selected={""}
                        style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        showTimeSelect
                        dateFormat={item.format === "" ? "Pp" : dateFormat}
                        timeFormat={timeFormat}
                        className='formDataDatePicker'
                    />
                </div>)
            case HTML_NAMES.signature:
                return <Button onClick={() => setOpenSignature(true)} variant={item.html}>{item.label}</Button>
            case HTML_NAMES.richTextHtmlInput:
                return (<RichTextEditor
                    value={json?.richTextHtmlInput?.value || ''}
                    tabIndex={''} // tabIndex of textarea
                    onChange={newContent => { }}
                />)
            case HTML_NAMES.richTextHtmlHeading:
                return (<div id='headingHtml' style={{ textAlign: 'left' }}>
                    {ReactHtmlParser(item.label)}
                </div>)
            case HTML_NAMES.photoAttach:
                return <img style={{ width: '100%', height: '100%' }} src={item.value} />
            case HTML_NAMES.fileUpload:
                return (<UploadPhotoContainer className="d-flex align-items-center mt-2">
                    <UploadInput
                        onInput={e => {
                            // handlePickFile(e, 'photo');
                        }}
                        onClick={event => {
                            event.target.value = null;
                        }}
                        type="file"
                        // accept={'.png,.jpg,.jpeg'}
                        id="upload-file"
                        name="upload-file"
                        placeholder={'Upload File'}
                    />
                    <FiUpload />
                    {
                        <label htmlFor="upload-file">Upload File</label>
                    }

                </UploadPhotoContainer>)
            case HTML_NAMES.fileDownload:
                return (<div className="copy-list mt-1 download-hover">
                    <a className='copy-item ' style={{ justifyContent: 'left' }} target='_blank' href={item.value}>
                        <span className='mr-1'>{DOWNLOAD}</span>{item.placeholder}
                    </a>
                </div>)
            case HTML_NAMES.bodyMapDisplay:
                return (
                    <>
                        <input type="text" id="fname" name="fname" value={item?.title?.join(",")} placeholder={item.placeholder} style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
                        <div className="d-flex justify-content-center w-100">
                            {((item.view == 'front') || (item.view == 'both')) && <Model
                                data={getBodyData(item.title)}
                                style={{ width: '16rem', padding: '1rem' }}
                                // onClick={handleClick}
                                type={'anterior'}
                            />}
                            {((item.view == 'back') || (item.view == 'both')) && <Model
                                data={getBodyData(item.title)}
                                style={{ width: '16rem', padding: '1rem' }}
                                // onClick={handleClick}
                                type={'posterior'}
                            />}
                        </div>
                    </>
                )
            case HTML_NAMES.bodyMapInput:
                return (<PrimaryButtonForm minWidth="6rem" onClick={() => setModalBodyMap(true)}>
                    <FaPlus />
                    Create body map
                </PrimaryButtonForm>)
            default:
                return null;
        }
    }

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const renderDesign = (item, index) => { // returns wrapper component with dummy UI, and passed item
        switch (item.type) {
            case HTML_NAMES.headingText:
                return wrapper(renderMainItemComponent(item), item, index)

            case HTML_NAMES.textInput:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                </div>, item, index)

            case HTML_NAMES.numberInput:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                    {/* <input type="number"  id="myInput"  name="inputBox" placeholder={item.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} /> */}
                </div>, item, index)

            case HTML_NAMES.decimalInput:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                </div>, item, index)

            case HTML_NAMES.dropdown:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        {renderMainItemComponent(item)}
                    </div>, item, index)

            case HTML_NAMES.multidropdown:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        {renderMainItemComponent(item)}
                    </div>, item, index)

            case HTML_NAMES.switch:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        {renderMainItemComponent(item)}
                        {/* <Switch {...label} checked={item.value}  /> */}
                    </div>, item, index)

            case HTML_NAMES.hyperLink:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {<p style={{ marginBottom: 8, fontSize: 12 }}>
                            {renderMainItemComponent(item)}
                        </p>}
                    </div>, item, index)


            case HTML_NAMES.button:
                return wrapper(<div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    {renderMainItemComponent(item)}
                </div>, item, index)

            case HTML_NAMES.radioGroup:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControl style={{ alignSelf: "flex-start", width: '100%' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </FormControl>
                </div>
                    ,
                    item,
                    index
                )

            case HTML_NAMES.buttonGroup:
                return wrapper(
                    renderMainItemComponent(item),
                    item,
                    index
                )


            case HTML_NAMES.checkbox:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </div>,
                    item,
                    index
                )

            case HTML_NAMES.textArea:
                return wrapper(
                    <div style={{ display: "flex", marginBottom: 10, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </div>
                    ,
                    item,
                    index
                )
            case HTML_NAMES.table:
                return wrapper(
                    <div className=''>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div style={{ border: '2px solid #fafafc' }}>
                            {renderMainItemComponent(item)}
                        </div>
                    </div>
                    ,
                    item,
                    index
                )
            case HTML_NAMES.section:
                return wrapper(
                    <div style={{ border: '2px solid #fafafc' }}>
                        <div className='text-left ml-2'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold", fontSize: '20px' }} >{item.label}</label>
                        </div>
                        <div className="container">
                            {renderMainItemComponent(item)}
                        </div>
                    </div>
                    ,
                    item,
                    index
                )

            case HTML_NAMES.choices:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </div>
                    ,
                    item,
                    index
                )

            case HTML_NAMES.datePicker:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ textAlign: 'left', width: '100%' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    </div>
                    {renderMainItemComponent(item)}
                </div>, item, index)

            case HTML_NAMES.timePicker:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </div>,
                    item,
                    index
                )

            case HTML_NAMES.dateTime:
                return wrapper(
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {renderMainItemComponent(item)}
                    </div>, item, index
                )

            case HTML_NAMES.signature:
                return wrapper(<div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    {renderMainItemComponent(item)}
                </div>, item, index)

            case HTML_NAMES.richTextHtmlInput:
                return wrapper(<div style={{}}>
                    <div style={{ textAlign: 'left' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    </div>
                    {renderMainItemComponent(item)}
                </div>, item, index)
            case HTML_NAMES.richTextHtmlHeading:
                return wrapper(renderMainItemComponent(item), item, index)
            case HTML_NAMES.photoAttach:
                return wrapper(<div style={{}}>
                    <div style={{ textAlign: 'left' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    </div>
                    <div>
                        {renderMainItemComponent(item)}
                    </div>
                </div>, item, index)
            case HTML_NAMES.fileUpload:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                </div>, item, index)
            case HTML_NAMES.fileDownload:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {item.placeholder && renderMainItemComponent(item)}
                </div>, item, index)
            case HTML_NAMES.bodyMapDisplay:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                </div>, item, index)
            case HTML_NAMES.bodyMapInput:
                return wrapper(<div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item.instruction &&
                            <Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    {renderMainItemComponent(item)}
                </div>, item, index)
            default:    // removes linter warning
                return <></>

        }


    }

    // passes the card item to renderDesign
    const ui = (addItem, index) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                {renderDesign(addItem, index)}
            </div >
        )
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {

        setDustbins(prev => {
            console.log(prev, "PREVIUOU", dragIndex, hoverIndex)
            let value = [{
                ...prev[0], lastDroppedItem: update(prev[0].lastDroppedItem, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prev[0].lastDroppedItem[dragIndex]]
                    ]

                })
            }]
            return [{
                ...value[0], lastDroppedItem: value[0].lastDroppedItem.map((val, index) => ({ ...val, order: index }))
            }]
        })
    }, []);
    // displays text (ui(card, index)) and handles drag and drop
    const renderCard = useCallback((card, index) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={ui(card, index)}
                moveCard={moveCard}
            />
        );
    }, [dustbins]);

    // iterates over lastDroppedItem array and renders a Card for each item
    return (
        <div>

            <div ref={drop} style={{ ...style, backgroundColor, borderWidth: 2, borderColor: "grey", borderStyle: "dotted" }} data-testid="dustbin">
                {lastDroppedItem.length > 0 ? (
                    lastDroppedItem.map((item, inde) => {
                        return (
                            <div key={inde}>
                                {
                                    renderCard(item, inde)
                                }
                            </div>
                        )
                    })
                )
                    :
                    <p style={{ color: "grey" }}>Dropzone</p>
                }
            </div>
        </div>
    )
})

// only styles below
const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
    &.${numberInputClasses.focused} {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
);

const StyledButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      cursor: pointer;
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
  
    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  `,
);
