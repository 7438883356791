import React, {useState, useEffect, Fragment, useRef} from 'react';
import LineContainer from 'components/SharedComponents/LineContainer';
import Spinner from 'components/SharedComponents/Spinner';
import {PURPLE} from 'shared/styles/constants/colors';
import {
  StylePointer,
  TitleSection,
  SettingItemsContainer,
  LoadingContainer,
} from 'shared/styles/constants/tagsStyles';
import {getPageToSearchParam} from 'shared/methods';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import {FaPlus} from 'react-icons/fa';
import ItemList from './ItemList';
import ItemListStaffCheck from './ItemListStaffCheck';

import {
  ON_SAVE,
  ON_EDIT,
  ON_ERROR,
  ON_LOADING,
  ON_DELETE,
  ON_REMOVE,
  ON_CANCEL,
} from 'utils/constants/settingsStates';
import {getMedicationName} from 'utils/api/SettingsApi';

const CustomTemplate = ({
  titleSection,
  labelInputTitle,
  getMethod,
  setMethod,
  deleteMethod,
  textToMessageDelete,
  textToEmptyDataValues,
  addOneDropdown,
  addSecondDropdown,
  addCheckBox,
  specializeCase,
  specializedMethods,
  addOneInput,
  addSecondInput,
  addThirdInput,
  fieldAttributes = {},
  update,
  create,
  deletePermission,
  userStaff,
  handleChangeChoices = () => {},
  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  createPrioritySettings,
  createPermissionEmployeType,
  setMedicationModals,
  setTestingData,
  setCheckedId,
  setMedicationNameModal,
  staffCheck,
  setIsEdit,
  apiCall,
  setMedicationNameDelete,
  getFunction,setApiCall

  // cardBodyName,
}) => {
  useEffect(() => {
    loadDataValues();
  }, [apiCall]);
  // console.log(apiCall,"XXXXXXX")
  const [simplePagination] = useState(true);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataValues, setDataValues] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [enabledInput, setEnabledInput] = useState('');
  const [leftButtonIcon, setLeftButtonIcon] = useState(ON_EDIT);
  const [rightButtonIcon, setRightButtonIcon] = useState(ON_DELETE);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true);
  const [data, setData] = useState();
  const [offset, setOffSet] = useState(0);
  const [pageSizes, setPageSizes] = useState(10);
  const [delCheck, setDelCheck] = useState(false);
  const [dataCheckings, setDataChecking] = useState(false);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(()=>{
    getFunction(loadDataValues)
  },[apiCall])
  useEffect(() => {
    loadDataValues();
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const loadDataValues = () => {
    const createPageSize = PAGE_SIZE_RENDER / 2;

    let pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
      createPageSize,
      dataValues
    );
    console.log(dataCheckings, 'dataCheckings');
    getMethod({...pageToSearch}, createPageSize).then(res => {
      setApiCall(false)
      console.log(res, 'tttttt');
      if (!mountedRef.current) return null;

      if (specializeCase && specializeCase === 'role') {
        //below added res.result as in get api data binding position now is resp.results
        setDataValues(
          res.results ||
            res.absence_type ||
            res.absence_reason ||
            res.absence_informed_method ||
            res.absence_informed_method ||
            res.absence_planned ||
            res.absence_paid ||
            res.user_type_category ||
            res.medication ||
            []
        );
      } else {
        setDataValues(
          res.results ||
            res.absence_type ||
            res.absence_reason ||
            res.absence_informed_method ||
            res.absence_informed_method ||
            res.absence_planned ||
            res.absence_paid ||
            res.user_type_category ||
            res.medication ||
            []
        );
      }

      setPages(Math.ceil(res.count / createPageSize));
      if (res.results.length === 0 && currentPage >= 1) {
        setCurrentPage(currentPage - 1);
      } else if (res.results.length === 1 && !currentPage) {
        setCurrentPage(1);
      }

      setLoading(false);
    });
  };

  const addNewService = () => {
    const haveNew = dataValues.find(data => data.id === '');

    if (haveNew) {
      return;
    }

    setDataValues([...dataValues, {id: '', name: ''}]);

    setLeftButtonIcon(ON_SAVE);
    setRightButtonIcon(ON_REMOVE);

    setEnabledInput('');
  };
  console.log(dataValues, '55555');
  const removeNewService = () => {
    dataValues.forEach(function (value, index) {
      if (!value.id) {
        dataValues.splice(index, 1);
      }
    });
    setDataValues([...dataValues]);
  };
  let body = {};
  const handleSubmit = async valuesToSave => {
    console.log(valuesToSave, 'DDDDDD');
    if (!valuesToSave.name) {
      return;
    }
    setLeftButtonIcon(ON_LOADING);
    let setResponse = valuesToSave;

    if (specializeCase && specializeCase === 'role') {
      let myId = {};
      if (valuesToSave.id) {
        myId = {
          id: valuesToSave.id,
        };
      }

      body = {
        role: {
          ...myId,
          name: valuesToSave.name,
          is_staff: valuesToSave.is_staff ? valuesToSave.is_staff : false,
        },
      };
    }

    if (addOneDropdown) {
      if (addSecondDropdown) {
        body[addSecondDropdown.value] = valuesToSave[addSecondDropdown.value];
      } else {
        body[addOneDropdown.value] = valuesToSave[addOneDropdown.value];
      }
    }
    if (addCheckBox) {
      body[addCheckBox.value] = valuesToSave[addCheckBox.value];
    }
    if (addSecondInput) {
      body[addSecondInput.value] = valuesToSave[addSecondInput.value];
    }

    if (addThirdInput) {
      body[addThirdInput.value] = valuesToSave[addThirdInput.value];
    }
    let newData = {
      name: valuesToSave.name,
      medication_class: valuesToSave.medication_class,
      indication: valuesToSave.indication,
      supplier_brand_name: valuesToSave.supplier_brand_name,
    };

    let response = await setMethod(body.id !== '' ? valuesToSave : newData);
    if (response) {
      setResponse = response;
      onSuccess();
    } else {
    }

    return setResponse;
  };

  const onSuccess = () => {
    setEnabledInput('');
    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
    handleChangeChoices();
    loadDataValues();
  };

  const onError = valuesToSave => {
    if (valuesToSave.id) {
      setRightButtonIcon(ON_CANCEL);
    } else {
      setRightButtonIcon(ON_REMOVE);
    }
    setLeftButtonIcon(ON_ERROR);
  };

  const toDeleteId = id => {
    if (!id) {
      return;
    }
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDelete = async () => {
    await deleteMethod(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');

    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
    setEnabledInput('');
    handleChangeChoices();
    loadDataValues();

    if (specializeCase && specializeCase === 'role') {
      specializedMethods.updateRolValues();
    }
  };

  const toEditId = id => {
    console.log(id, 'ID');
    setEnabledInput(id);
    setLeftButtonIcon(ON_SAVE);
    if (id) {
      setRightButtonIcon(ON_CANCEL);
      removeNewService();
    } else {
      setRightButtonIcon(ON_REMOVE);
    }
  };

  const cancelEdit = () => {
    setEnabledInput('');
    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
  };

  const tryReSubmit = id => {
    setLeftButtonIcon(ON_SAVE);
    if (!id) {
      setRightButtonIcon(ON_REMOVE);
    } else {
      setRightButtonIcon(ON_CANCEL);
    }
  };

  return (
    <LineContainer>
      <div>
        <TitleSection className={'ml-3 mt-2 mb-2'}>{titleSection}</TitleSection>
      </div>

      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Fragment>
          <SettingItemsContainer
            minHeight={`${dataValues.length >= 1 ? '30rem' : ''}`}>
            {dataValues.length >= 1 ? (
              staffCheck ? (
                <Fragment>
                  {dataValues.map((dataValue, indexValue) => {
                    return (
                      <ItemListStaffCheck
                        key={indexValue}
                        dataValue={dataValue}
                        handleSubmit={handleSubmit}
                        toDeleteId={toDeleteId}
                        toEditId={toEditId}
                        cancelEdit={cancelEdit}
                        removeNewService={removeNewService}
                        leftButtonIcon={leftButtonIcon}
                        rightButtonIcon={rightButtonIcon}
                        enabledInput={enabledInput}
                        setEnabledInput={setEnabledInput}
                        tryReSubmit={tryReSubmit}
                        labelInputTitle={labelInputTitle}
                        addOneDropdown={addOneDropdown}
                        specializeCase={specializeCase}
                        specializedMethods={specializedMethods}
                        addOneInput={addOneInput}
                        fieldAttributes={fieldAttributes}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                        filterS
                      />
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  {dataValues.map((dataValue, indexValue) => {
                    return (
                      <ItemList
                        setMedicationNameDelete={setMedicationNameDelete}
                        setIsEdit={setIsEdit}
                        setTestingData={setTestingData}
                        setMedicationNameModal={setMedicationNameModal}
                        setCheckedId={setCheckedId}
                        key={indexValue}
                        dataValue={dataValue}
                        textToMessageDelete={textToMessageDelete}
                        handleSubmit={handleSubmit}
                        toDeleteId={toDeleteId}
                        toEditId={toEditId}
                        cancelEdit={cancelEdit}
                        removeNewService={removeNewService}
                        leftButtonIcon={leftButtonIcon}
                        rightButtonIcon={rightButtonIcon}
                        enabledInput={enabledInput}
                        setEnabledInput={setEnabledInput}
                        tryReSubmit={tryReSubmit}
                        labelInputTitle={labelInputTitle}
                        addOneDropdown={addOneDropdown}
                        addSecondDropdown={addSecondDropdown}
                        addCheckBox={addCheckBox}
                        specializeCase={specializeCase}
                        specializedMethods={specializedMethods}
                        addOneInput={addOneInput}
                        addSecondInput={addSecondInput}
                        addThirdInput={addThirdInput}
                        fieldAttributes={fieldAttributes}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                        updatePrioritySettings={updatePrioritySettings}
                        createPrioritySettings={createPrioritySettings}
                        deletePermissionPrioritySettings={
                          deletePermissionPrioritySettings
                        }
                        readPermissionPrioritySettings={
                          readPermissionPrioritySettings
                        }
                        filterAdmin={filterAdmin}
                        filterSuperAdmin={filterSuperAdmin}
                      />
                    );
                  })}
                </Fragment>
              )
            ) : (
              <div
                className={
                  'pt-4 pb-4 pl-3 pr-2 mb-4 ml-3 mr-3 border rounded text-secondary d-flex justify-content-center text-center'
                }>
                {textToEmptyDataValues}
              </div>
            )}
          </SettingItemsContainer>

          <div>
            <div className="mt-3 text-center">
              <Pagination
                simplePagination={simplePagination}
                setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                reduceMargin={true}
                dropdownEntriesLimit={false}
                dataLength={dataValues.length}
              />
            </div>

            {/* {update&&( */}
            <div className={'w-100 pr-3 pt-3 pb-3 text-right'}>
              <StylePointer
                onClick={() => {
                  setMedicationNameModal(true);
                  setTestingData(dataValues);
                  setCheckedId(false);
                }}>
                <FaPlus color={PURPLE} size={26} />
              </StylePointer>
            </div>
            {/* )
} */}
          </div>

          <ModalDecision
            type="delete"
            title="Warning"
            body={`Are you sure you want to delete this ${textToMessageDelete} ? \n DELETING the ${textToMessageDelete} will remove ALL records from database.`}
            onOk={() => {
              handleDelete();
              setDelCheck(true);
            }}
            onCancel={() => {
              setIdSelected('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </Fragment>
      )}
    </LineContainer>
  );
};

export default CustomTemplate;
