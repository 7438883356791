export const ItemTypes = {
    Switch: 'Toggle',
    Heading: 'Heading text',
    Dropdown: 'Dropdown',
    multidropdown: "Multi Dropdown",
    TextInput: 'Text Input',
    numberInput: "Number Input",
    decimalInput: "Decimal Input",
    button: "Button",
    hyperLink:'Hyper Link',
    radioGroup: "Radio Group",
    buttonGroup: "Button Group",
    checkbox: "Checkbox",
    textArea: "Text Area",
    datePicker: "Date",
    timePicker: "Time",
    dateTime: "Date/Time",
    signature: "Signature",
    table: "Table",
    section:"Section",
    richTextHtmlInput: 'Rich Text Input',
    richTextHtmlHeading: 'Rich Text Heading',
    photoAttach: 'Photo Attach',
    fileUpload: 'File Upload',
    fileDownload: 'File Download',
    bodyMapDisplay: 'BodyMap Display',
    bodyMapInput: 'BodyMap Input',
    choices: 'Choices',
    category: 'category',
    choiceDropdown: 'Choice Dropdown'
}
