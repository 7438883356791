import React, {useContext, useEffect, useState} from 'react';
import Template from 'components/SharedComponents/SettingsTemplate';
import {
  getTaskType,
  setTaskType,
  deleteTaskType,
  getCareCategory,
  setCareCategory,
  deleteCareCategory,
  setCareTask,
  deleteCareTask,
} from 'utils/api/SettingsApi';

import {getCareTask} from 'utils/api/UsersApi';
import {AppContext} from 'context';
import LineContainer from 'components/SharedComponents/LineContainer';
import {TitleSection} from 'shared/styles/constants/tagsStyles';
import PermissionsTable from './Table';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Tasks/AddModal';
import {useModal} from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';

const Tasks = ({
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  handleChangeChoices,
}) => {
  const {contextChoices: choices} = useContext(AppContext);
  const [selectedData, setSelectedData] = useState({});
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [rolValues, setRolValues] = useState([]);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  useEffect(() => {
    loadRolPermission();
  }, []);

  const loadSections = async () => {
    return getCareTask()
      .then(response => {
        return response;
      })
      .catch(() => {});
  };
  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const loadRolPermission = async () => {
    const mySections = await loadSections();
    const addArrayList = JSON.parse(JSON.stringify(mySections.results));

    //const permissionData = await loadselectedPermission();
    // let temArr = [];
    // const categoryArr =  addArrayList.map((item,index)=>{
    //     return {id : item.task_type.care_category.id, name:  item.task_type.care_category.name}
    // })
    //const filterUnique = categoryArr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);

    // filterUnique.forEach((item)=>{
    //     let sectionData = [];
    //     addArrayList.forEach((sec)=>{
    //         let subsecData = [];
    //     if(item.id === sec.task_type.care_category.id){
    //         addArrayList.forEach((subItem)=>{
    //             if(subItem.task_type.id === sec.task_type.id){
    //                 subsecData.push({id: subItem.id, name:subItem.name});
    //             }
    //         });
    //         sectionData.push({id: sec.task_type.id, name: sec.task_type.name, care_task: subsecData})
    //     }
    //     });
    //     temArr.push({...item, task_type: sectionData.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)})
    // })

    const myRolPermissions = [];

    //check all sections
    addArrayList.forEach((principalArray, index) => {
      //add new index in myRolPermissions
      myRolPermissions.push({});
      myRolPermissions[index].name = principalArray.name;
      myRolPermissions[index].id = principalArray.id;
      myRolPermissions[index].principal = true;

      const myPrincipalSections = [];
      principalArray.task_type.forEach((principalSection, indexSections) => {
        // add new index in myPrincipalSections
        myPrincipalSections.push({});
        // add principal values in new index
        myPrincipalSections[indexSections].name = principalSection.name;
        myPrincipalSections[indexSections].id = principalSection.id;
        myPrincipalSections[indexSections].category = principalArray.id;
        myPrincipalSections[indexSections].category_name = principalArray.name;
        myPrincipalSections[indexSections].principal = false;
        myPrincipalSections[indexSections].isSubsection = false;

        myPrincipalSections[indexSections].sections = [];

        principalSection.care_task.forEach((subSection, indexSubSection) => {
          myPrincipalSections[indexSections].sections.push({});
          // add principal aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].principal = false;
          // add isSubsection aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].isSubsection = true;
          //add name
          myPrincipalSections[indexSections].sections[indexSubSection].name =
            subSection.name;
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].result_type = subSection.result_type;
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].result_option = subSection.result_option
            ? subSection.result_option.split(',')
            : [];
            myPrincipalSections[indexSections].sections[
            indexSubSection
          ].header_msg = subSection.header_msg;
          //add id
          myPrincipalSections[indexSections].sections[indexSubSection].id =
            subSection.id;

          // add category
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].category = principalArray.id;

          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].task_type_id = principalSection.id;
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].task_type_name = principalSection.name;
        });
      });

      myRolPermissions[index].sections = myPrincipalSections;
    });
    setRolValues([...myRolPermissions]);
  };

  const handleOnClick = (values, method, type) => {
    handleModal();
    setSelectedData(values);
    setSelectedMethod(method);
    setSelectedType(type);
  };

  const handleDelete = (values, type) => {
    setModalDeleteValue(true);
    setSelectedData(values);
    setSelectedType(type);
  };
  const handleDeleteOk = () => {
    if (selectedType === 'care_category') {
      deleteCareCategory(selectedData.id).then(res => {
        setModalDeleteValue(false);
        setSelectedData({});
        setSelectedType('');
        loadRolPermission();
        handleChangeChoices('care_category');
      });
    } else if (selectedType === 'task_type') {
      deleteTaskType(selectedData.id).then(res => {
        setModalDeleteValue(false);
        setSelectedData({});
        setSelectedType('');
        loadRolPermission();
        handleChangeChoices('task_type');
      });
    } else if (selectedType === 'care_task') {
      deleteCareTask(selectedData.id).then(res => {
        setModalDeleteValue(false);
        setSelectedData({});
        setSelectedType('');
        loadRolPermission();
        handleChangeChoices('care_task');
      });
    }
  };

  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-xl-12 col-lg-12 pl-0 pr-0 pl-md-1 pr-md-1 pb-3">
          <LineContainer>
            <div className={'pt-2 pl-3 pr-3 pb-3'}>
              {/* <TitleSection>Care Category</TitleSection> */}
              <div className={'mt-2'}>
                <PermissionsTable
                  rolValues={rolValues}
                  handleOnClick={handleOnClick}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </LineContainer>
        </div>
      </div>
      {/* <div className="row p-0 m-0">
                <div className="col-xl-6 col-lg-6 pl-0 pr-0 pl-lg-1 pr-lg-1 pb-3">
                    <Template
                        titleSection={'Care Category'}
                        labelInputTitle={'Care category name'}
                        getMethod={getCareCategory}
                        deleteMethod={deleteCareCategory}
                        setMethod={setCareCategory}
                        textToMessageDelete={'Care Category'}
                        textToEmptyDataValues={'No care category have been added'}
                        fieldAttributes={{ maxLength: 50 }}
                        handleChangeChoices={() => handleChangeChoices('care_category')}
                        updatePrioritySettings={true}
                        createPrioritySettings={true}
                        deletePermissionPrioritySettings={true}
                        readPermissionPrioritySettings={true}
                        filterAdmin={filterAdmin}
                        filterSuperAdmin={filterSuperAdmin}
                        userStaff={userStaff}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 pl-0 pr-0 pl-lg-1 pr-lg-1 pb-3">
                    <Template
                        titleSection={'Task Type'}
                        labelInputTitle={'Task type'}
                        getMethod={getTaskType}
                        deleteMethod={deleteTaskType}
                        setMethod={setTaskType}
                        handleChangeChoices={() => handleChangeChoices('task_type')}
                        textToMessageDelete={'Task Type'}
                        textToEmptyDataValues={'No task have been added'}
                        addOneDropdown={{
                            placeholder: 'Care category ',
                            required: true,
                            careCategory: true,
                            value: 'care_category',
                            options: choices,
                            choicesValue: 'care_category',
                        }}
                        fieldAttributes={{ maxLength: 50 }}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                    />
                </div>
                <div className="col-xl-12 col-lg-12 pl-0 pr-0 pl-lg-1 pr-lg-1 pb-3">
                    <Template
                        titleSection={'Care Task'}
                        labelInputTitle={'Care Task'}
                        getMethod={getCareTask}
                        deleteMethod={deleteCareTask}
                        setMethod={setCareTask}
                        handleChangeChoices={() => handleChangeChoices('task_type')}
                        textToMessageDelete={'Care Task'}
                        textToEmptyDataValues={'No care task have been added'}
                        addOneDropdown={{
                            placeholder: 'Care category',
                            required: true,
                            careCategory: true,
                            value: 'care_category',
                            options: choices,
                            choicesValue: 'care_category',
                        }}
                        addSecondDropdown={{
                            placeholder: 'Task Type ',
                            required: true,
                            value: 'task_type',
                            options: choices,
                            choicesValue: 'task_type',
                        }}
                        addCheckBox={{
                            value: 'feedback'
                        }}
                        fieldAttributes={{ maxLength: 50 }}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                    />
                </div>
            </div> */}
      {!modalValue ? null : (
        <AddModal
          selectedData={selectedData}
          selectedMethod={selectedMethod}
          selectedType={selectedType}
          setModalValue={handleModal}
          fullBind={fullBind}
          setTitle={setTitle}
          setselectedData={setSelectedData}
          setselectedMethod={setSelectedMethod}
          setselectedType={setSelectedType}
          loadRolPermission={loadRolPermission}
          handleChangeChoices={handleChangeChoices}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete ? \n DELETING this will remove ALL records from database.'
        }
        subBody={''}
        onOk={handleDeleteOk}
        onCancel={() => {
          setSelectedData({});
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default Tasks;
