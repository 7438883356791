import styled from "styled-components";
import {
  XS,
  SM,
  MD_LG,
  MD,
  XXL,
  XXLL,
} from "shared/styles/constants/resolutions";
import { PURPLE, PURPLE_800, GRAY_300 } from "shared/styles/constants/colors";

export const NextPreviousContainer = styled.div`
  top: 2.8rem;
  left: -0.6rem;
  position: absolute;

  @media (max-width: ${XXLL}) {
    top: ${(props) => (props.progress ? "6.1rem" : "2.8rem")};
  }
  @media (max-width: ${MD_LG}) {
    top: ${(props) => (props.progress ? "9.5rem" : "2.8rem")};
  }
  @media (max-width: ${MD}) {
    top: ${(props) => (props.progress ? "10.6rem" : "2.8rem")};
  }
  @media (max-width: ${XS}) {
    top: ${(props) => (props.progress ? "10rem" : "2.8rem")};
  }

  .arrow {
    cursor: pointer;
    border: 0;
    background: 0;
    padding: 0 !important;
    margin: 0 !important;
    svg {
      padding: 0 !important;
      margin: 0 !important;
      color: ${PURPLE};
      font-size: 2.2rem;
    }
    &:active {
      svg {
        color: ${PURPLE_800};
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;

  .daily {
    color: ${(props) => (props.selected ? PURPLE : "black")};
    border: 0;
    border-bottom: ${(props) => (props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  }

  .weekly {
    color: ${(props) => (!props.selected ? PURPLE : "black")};
    border: 0;
    border-bottom: ${(props) => (!props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${(props) => (!props.selected ? "bold" : "normal")};
  }

  .monthly {
    color: ${props => (!props.selected ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props => (!props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${props => (!props.selected ? 'bold' : 'normal')};
  }
  .nextPrevious {
    .arrow {
      cursor: pointer;
      border: 0;
      background: 0;
      padding: 0 !important;
      margin: 0 !important;
      svg {
        padding: 0 !important;
        margin: 0 !important;
        color: ${PURPLE};
        font-size: 2.2rem;
      }
      &:active {
        svg {
          color: ${PURPLE_800};
        }
      }
    }
  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const EmployeeFilterContainer = styled.div`
  min-width: 8.75rem;

  @media (max-width: ${SM}) {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const Header = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid ${GRAY_300};
  display: flex;
  justify-content: space-between;
  @media (max-width: ${XXL}) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${MD}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;
