import {LARGE_COL,EX_SMALL_COL4,MEDIUM_COL,VERY_EX_SMALL_COL_CHECKBOX} from 'shared/styles/constants/columns';

export const headerColumns = [
 
  {
    label: 'Client Name',
    name: 'client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Employee Name',
    name: 'triage_created_by__full_name',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: ' Assigned Employee ',
    name: 'triage_created_by__full_name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Triage Type',
    name: 'triage_type__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Triage Title',
    name: 'tittle',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Severity',
    name: 'alert_severity__name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Status',
    name: 'alert_status__name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },

  {
    label: 'Created By',
    name: 'created_by__fullname',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Created at',
    name: 'created_at',
    status: 1,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  
];
