import React, { useEffect, useState } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { TitleSection, FlexEqual, FlexGrid3Item } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import PropTypes from 'prop-types';
import InputTextArea from '../InputTextArea';

const HealthDetail = ({
  useInput,
  nameSection,
  sectionStyle,
  readOnlyPermission,
  choices,
  values,
  dropDownHandleSelect,
  preferredHandleSelect,
  employeeDetailsMedicalInformationRead,
  errorMessage
}) => {
  const [userDescription, setUserDescription] = useState([]);
  useEffect(() => {
    if (values.client_user_type_category) {
      let temp_desc = [];
      // values.client_user_type_category.forEach(category=>{
      choices &&
        choices.user_type_description &&
        choices.user_type_description.forEach(desp => {
          if (desp.user_type_category === values.client_user_type_category) {
            temp_desc.push(desp);
          }
        });
      // })
      setUserDescription([...temp_desc]);
    }
  }, [values.client_user_type_category]);
  return (
    <FlexEqual className={sectionStyle}>
      <TitleSection>Medical Information</TitleSection>
      <div className="d-flex flex-column mt-2">
        <div className="d-flex mb-2">

          <div className="w-100 d-flex">
            <div className="w-100 mr-1">
              <small
                className="pr-1 "
                style={{
                  fontSize: '0.7rem',
                  margin: '0',
                  color: 'black',
                  fontWeight: '600'
                }}>
                Urgent Information Emergency Situation
              </small>
              <InputTextArea
                readOnly={employeeDetailsMedicalInformationRead ? false : true}
                type="text"
                height={'4rem'}
                label={'Urgent Information Emergency Situation'}
                id="emergency"
                {...useInput(`emergency_info`)}
                maxlength={15}
              />
              {(values.emergency_info && values.emergency_info.length > 5000) ?
                <small className="pr-1 " style={{ color: 'red' }}>{errorMessage}</small> :
                null}
            </div>
            <div className="w-100 ml-1">
              <small
                className="pr-1 "
                style={{
                  fontSize: '0.7rem',
                  margin: '0',
                  color: 'black',
                  fontWeight: '600'
                }}>
                DoLS (Deprivation of Liberty Safeguards)
              </small>
              <InputTextArea
                readOnly={employeeDetailsMedicalInformationRead ? false : true}
                type="text"
                height={'4rem'}
                label={'Urgent Information Emergency Situation'}  
                id="DoLS"
                {...useInput(`DoLS`)}
                maxlength={15}
              />
              {(values.DoLS && values.DoLS.length > 5000) ?
                <small className="pr-1 " style={{ color: 'red' }}>{errorMessage}</small> :
                null}
            </div>
            {/* <InputLabelTop
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label={'Urgent Information Emergency Situation'}
              type="text"
              id="emergency"
              {...useInput(`emergency_info`)}
              maxlength={100}
            /> */}
          </div>

        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'DNACPR'}
              options={choices.dnacpr}
              selectedOption={values.dnacpr ? values.dnacpr : null}
              onChange={value =>
                dropDownHandleSelect({
                  dnacpr: value ? value : null,
                  HealthDetail,
                })
              }
            />
          </div>
          <div className={'w-100'}>
            <DateSelector
              labelTop="Date of DNACPR"
              {...useInput('dnacpr_date')}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'TEP In Place'}
              options={choices.tep_in_place}
              selectedOption={values.tep_in_place ? values.tep_in_place : null}
              onChange={value =>
                dropDownHandleSelect({
                  tep_in_place: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100 mr-2">
            <DateSelector labelTop="Date of TEP" {...useInput('tep_date')} />
          </div>
          <div className={'w-100'}>
            <DropdownSearchable
              placeHolder={'Infection Control-PPE Category'}
              options={choices.infection_control}
              selectedOption={
                values.infection_control ? values.infection_control : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  infection_control: value ? value : null,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Care Level'}
              options={choices.care_level}
              selectedOption={values.care_level ? values.care_level : null}
              onChange={value =>
                dropDownHandleSelect({
                  care_level: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100 mr-2">
            <DropdownSearchable
              isMulti={true}
              placeHolder={'Specific Communication Needs'}
              options={choices.specific_communication_needs}
              selectedOptionsProps={
                values.client_specific_communication_needs
                  ? values.client_specific_communication_needs
                  : null
              }
              onChange={values =>
                preferredHandleSelect({
                  communication_needs: values.length ? values : [],
                })
              }
            />
          </div>
          <div className={'w-100'}>
            <DropdownSearchable
              placeHolder={'Rota RAG'}
              options={choices.rota_rag}
              selectedOption={values.rota_rag ? values.rota_rag : null}
              onChange={value =>
                dropDownHandleSelect({
                  rota_rag: value ? value : null,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-1">
          <div className="w-100 mr-2">
            <InputLabelTop
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label={'Rota RAG Reason'}
              type="text"
              id="rag_reason"
              {...useInput(`rota_rag_reason`)}
              maxlength={50}
            />
          </div>
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'VRA'}
              options={choices.client_vra}
              selectedOption={values.client_vra ? values.client_vra : null}
              onChange={value =>
                dropDownHandleSelect({
                  client_vra: value ? value : null,
                })
              }
            />
          </div>
          <div className={'w-100'}>
            <InputLabelTop
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label={'VRA Reason'}
              type="text"
              id="vra_reason"
              {...useInput(`client_vra_reason`)}
              maxlength={50}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Client Group'}
              isMulti={true}
              options={choices.client_group}
              selectedOptionsProps={
                values.client_client_group ? values.client_client_group : null
              }
              onChange={values =>
                preferredHandleSelect({
                  client_group: values.length ? values : [],
                })
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              placeHolder={'Care Need Category'}
              options={choices.care_needs_category}
              selectedOption={
                values.care_needs_category ? values.care_needs_category : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  care_needs_category: value ? value : null,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Care Dependency Level'}
              options={choices.care_dependency_level}
              selectedOption={
                values.care_dependency_level
                  ? values.care_dependency_level
                  : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  care_dependency_level: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              isMulti={true}
              placeHolder={'Regulated Services'}
              options={choices.regulated_services}
              selectedOptionsProps={
                values.client_regulated_services
                  ? values.client_regulated_services
                  : null
              }
              onChange={values =>
                preferredHandleSelect({
                  regulated_services: values.length ? values : [],
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'User Types-Category'}
              options={choices.user_type_category}
              selectedOption={
                values.client_user_type_category
                  ? values.client_user_type_category
                  : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  client_user_type_category: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              isMulti={true}
              placeHolder={'User Types-Description'}
              disabled={!values.client_user_type_category}
              options={userDescription}
              selectedOptionsProps={
                values.client_user_type_description
                  ? values.client_user_type_description
                  : null
              }
              onChange={values =>
                preferredHandleSelect({
                  description: values.length ? values : [],
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Living Circumstances'}
              options={choices.living_circumstances}
              selectedOption={
                values.living_circumstances ? values.living_circumstances : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  {
                    living_circumstances: value ? value : null,
                  },
                  HealthDetail
                )
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              isMulti={true}
              placeHolder={'Client Mobility'}
              options={choices.client_mobility}
              selectedOptionsProps={
                values.client_client_mobility
                  ? values.client_client_mobility
                  : null
              }
              onChange={values =>
                preferredHandleSelect({
                  client_mobility: values.length ? values : [],
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Disability'}
              options={choices.disability}
              selectedOption={values.disability ? values.disability : null}
              onChange={value =>
                dropDownHandleSelect({
                  disability: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100 mr-2">
            <InputLabelTop
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label={'Disability Name / Description'}
              type="text"
              id="disability_name"
              {...useInput(`disability_name`)}
              maxlength={100}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label={'Disability Number'}
              type="text"
              id="disability_no"
              {...useInput(`disability_no`)}
              maxlength={25}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Impairment/Sensory Loss'}
              options={choices.impairment_sensory_loss}
              selectedOption={
                values.impairment_sensory_loss
                  ? values.impairment_sensory_loss
                  : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  impairment_sensory_loss: value ? value : null,
                })
              }
            />
          </div>
          <div className="w-100">
            <ToggleButton
              readOnly={employeeDetailsMedicalInformationRead ? false : true}
              label="Do Not Resuscitate (DNR)"
              useInput={useInput('do_not_resuscitate')}
              id="do_not_resuscitate"
            />
          </div>
        </div>
      </div>
    </FlexEqual>
  );
};

HealthDetail.propTypes = {
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string,
};

HealthDetail.defaultProps = {
  sectionStyle: '',
};

export default HealthDetail;
