// import { InputFieldChangePwd, PwdStyle } from 'components/AuthComponents/Login/styles';
import { useModal } from 'hooks/ModalHook';
import React from 'react'
import { MdRemoveRedEye } from 'react-icons/md';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ErrorMessage } from 'shared/styles/constants/tagsStyles';
import { useState } from 'react';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { Fragment } from 'react';
import { ChangePwdStyle, InputFieldChangePwd } from '../styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { changePassword } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useInput } from 'hooks/InputHook';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { isObject } from 'lodash';

function ChangePassword() {

    const toast = useRef()
    const { setValue: setModalChangePassword, bind: bindModalChangePassword } = useModal(false);
    const history = useHistory()
    const { value: old_password, bind: bindOldPassword } = useInput('');
    const { value: new_password, bind: bindnewPassword } = useInput('');
    const { value: confirm_password, bind: bindConfirmPassword } = useInput('');
    const [typeInput, setTypeInput] = useState({
        oldPassword: 'password',
        newPassword: 'password',
        confirmPassword: 'password',
    });
    const [loadingSave, setLoadingSave] = useState(false);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingModal, setLoadingModal] = useState(true);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [showErrorMessage, setShowErrorMessage] = useState(
        {
            oldPassword: {
                status: false,
                message: '',
            },
            newPassword: {
                status: false,
                message: '',
            },
            confirmPassword: {
                status: false,
                message: '',
            },
            status: false,
            message: ""
        }
    )




    const handleClick = (type, field) => {
        typeInput[field] = type
        setTypeInput({ ...typeInput })
    };

    const handelSaveClick = () => {
        setTextStatus(SAVE)
        if (!old_password || !confirm_password || !new_password) {
            showErrorMessage.status = true;
            showErrorMessage.message = "All fields are mandatory, Please check!"
            if (!old_password) showErrorMessage.oldPassword.status = true;
            if (!new_password) showErrorMessage.newPassword.status = true;
            if (!confirm_password) showErrorMessage.confirmPassword.status = true;
            setShowErrorMessage({ ...showErrorMessage })
            return;
        }
        else if (new_password !== confirm_password) {
            showErrorMessage.confirmPassword.status = true;
            showErrorMessage.oldPassword.status = false;
            showErrorMessage.newPassword.status = true;
            showErrorMessage.status = true;
            showErrorMessage.message = "New password and confirm password do not match!"
            setShowErrorMessage({ ...showErrorMessage })
            return;
        }
        else {
            showErrorMessage.status = false;
            showErrorMessage.oldPassword.status = false;
            showErrorMessage.newPassword.status = false;
            showErrorMessage.confirmPassword.status = false;
            setShowErrorMessage({ ...showErrorMessage })
            setModalChangePassword(true)
        }
    }

    const handleChangePassword = async () => {
        setLoadingSave(true)
        const body = {
            old_password: old_password,
            password: new_password,
            email: localStorage.getItem("user_email")
        }

        try {
            const res = await changePassword(body)
            console.log(res)
            if (res.statusCode === 200) onSuccess(res)  
            else onError(res)
        } catch (error) {
            onError(error)
        }
    }
    const onSuccess = (res) => {
        setLoadingSave(false)
        setModalChangePassword(false)
        setTextStatus(SAVED)
        setButtonIcon(CHECK)
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${res?.message}`,
        });
        history.push('/')
    }
    const onError = (err) => {
        let message = "";
        if(err?.message) {
            if(typeof err.message == "string")
                message = err.message;
            // else if (isObject(err.message))
            //     message = Object.keys(err.message)[0] + ": " + err.message[Object.keys(err.message)[0]][0]
        }
        else message = "Something went wrong!"
        setLoadingSave(false)
        setModalChangePassword(false)
        setButtonIcon(CROSS)
        setTextStatus(ERROR)
        showErrorMessage.message = message
        showErrorMessage.status = true;
        setShowErrorMessage({ ...showErrorMessage })
        toast.current && toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${message}`,
        });
    }
    return (
        <div>
            <Toast ref={toast} />
            <div className='w-100 d-flex align-item-center justify-content-center'>
                <div className={' w-50 p-2 p-sm-3 p-md-4 pb-4'}>
                    <div className=" d-block">
                        <div className={'w-100 ml-4'}>


                            <div className={'mt-4 p-0'}>
                                <div className={'h5 font-weight-normal mb-2 '}>
                                    <div>Old Password</div>
                                </div>
                                <ChangePwdStyle>
                                    <InputFieldChangePwd
                                        type={typeInput.oldPassword}
                                        // id="old_password"
                                        autoComplete="new-password"
                                        placeholder="**********"
                                        // autoComplete="current-password"
                                        value={old_password}
                                        error={showErrorMessage.oldPassword.status}
                                        {...bindOldPassword}
                                        width='92%'
                                    // minlength="2"
                                    // maxlength="20" 
                                    />
                                    <span
                                        onMouseDown={() => handleClick('text', 'oldPassword')}
                                        onMouseUp={() => handleClick('password', 'oldPassword')}>
                                        <MdRemoveRedEye />
                                    </span>
                                </ChangePwdStyle>
                            </div>

                            <div className={'mt-4 p-0'}>
                                <div className={'h5 font-weight-normal mb-2 '}>
                                    <div>New Password</div>
                                </div>
                                <ChangePwdStyle>
                                    <InputFieldChangePwd
                                        type={typeInput.newPassword}
                                        width='92%'
                                        autoComplete='off'
                                        id="new_password"
                                        placeholder="**********"
                                        // autoComplete="current-password"
                                        value={new_password}
                                        error={showErrorMessage.newPassword.status}
                                        {...bindnewPassword}
                                    // minlength="2"
                                    // maxlength="20" 
                                    />
                                    <span
                                        onMouseDown={() => handleClick('text', 'newPassword')}
                                        onMouseUp={() => handleClick('password', 'newPassword')}>
                                        <MdRemoveRedEye />
                                    </span>
                                </ChangePwdStyle>
                            </div>

                            <div className={'mt-4 p-0'}>
                                <div className={'h5 font-weight-normal mb-2 '}>
                                    <div>Confirm Password</div>
                                </div>
                                <ChangePwdStyle>
                                    <InputFieldChangePwd
                                        type={typeInput.confirmPassword}
                                        // id="confirm_password"
                                        autoComplete='off'
                                        value={confirm_password}
                                        width='92%'
                                        placeholder="**********"
                                        // autoComplete="current-password"
                                        error={showErrorMessage.confirmPassword.status}
                                        {...bindConfirmPassword}
                                    // minlength="2"
                                    // maxlength="20" 
                                    />
                                    <span
                                        onMouseDown={() => handleClick('text', 'confirmPassword')}
                                        onMouseUp={() => handleClick('password', 'confirmPassword')}>
                                        <MdRemoveRedEye />
                                    </span>
                                </ChangePwdStyle>
                            </div>
                        </div>
                    </div>
                    {/* {showErrorMessage.status ? (
                        <ErrorMessageShake className=" mt-3">
                            {showErrorMessage.message}
                        </ErrorMessageShake>
                    ) : (
                        <Fragment />
                    )} */}



                    <div
                        className={
                            'd-sm-block text-center text-md-right justify-content-end mt-2'
                        }>
                        <div className={'mb-2'}>
                            {showErrorMessage.status ? (
                                <ErrorMessage className='ml-4' style={{ textAlign: "start" }}>
                                    <label className={'mt-2'}>{showErrorMessage.message}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div
                            className={
                                'd-flex mr-4 justify-content-center justify-content-md-end'
                            }>
                            <div className={`ml-0 ml-md-3 mr-2 ${showErrorMessage.status ? "" : "mt-3"}`}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    disabled={loadingSave}
                                    onClick={handelSaveClick}>
                                    <span className={'mr-2'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        buttonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

            {/* <ModalInformation
          type={'error'}
          title={'Incorrect Email.'}
          onCancelTitle={'Cancel'}
          icon={ERROR_OUTLINE}
          hideOnCancel={false}
          onCancel={handleErrorModelCancel}
          showModal={showErrorMessage}>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              {errorMessage.message ||
                'The email for password reset is' + ' incorrect'}
            </div>
          </MessageOptaPlanner>
        </ModalInformation> */}
            <ModalDecision
                type="confirm"
                title="Confirm"
                body={'Do you want to reset the Password?'}
                subBody={'This action will change your password. You can log in with your new credentials.'}
                onOk={() => {
                    handleChangePassword()
                }}
                onNo={() => {
                    setModalChangePassword(false)
                }}
                onCancelTitle={"NO, CANCEL"}
                okTitle={"YES, PROCEED"}
                {...bindModalChangePassword}
            />
        </div>
    )
}

export default ChangePassword