import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  DayPill
} from 'components/SharedComponents/Table/styles';
import Table from 'components/SharedComponents/Table';
import {
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';
import moment from 'moment';
import { getCodeDaysArray, getDaysArray, getTimesArrayFormat } from 'utils/Array';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'FULL NAME',
    name: 'full_name',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Day',
    name: 'day',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'SHIFT NAME',
    name: 'shift_name',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'START TIME - END TIME',
    name: 'preferred_start_time',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'TRAVEL MODE',
    name: 'travel_mode',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  }, {
    label: 'GENDER',
    name: 'gender',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Address',
    name: 'address',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'POSTCODE',
    name: 'postcode',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Skills',
    name: 'skills',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'COMPETENCIES',
    name: 'competencies',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Avoid CLIENT',
    name: 'avoid_client',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'RUN NAME',
    name: 'runname',
    status: 0,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  }
];

const timesArray = getTimesArrayFormat();
const daysArray = getDaysArray();
const codeDaysArray = getCodeDaysArray();

const AssigningCarers = ({
  values,
  setValues,
  choices,
  employeeRunData,
  loadingModalEmployeeRun,
  runEmpTableOptionData
}) => {
  const [timeSelected, setTimeSelected] = useState('');

  useEffect(() => {
    if (values.preferred_start_time && values.preferred_end_time) {
      let start_time = moment(values.preferred_start_time, 'HH:mm:ss');
      let end_time = moment(values.preferred_end_time, 'HH:mm:ss');

      if (start_time.isAfter(end_time) && timeSelected === 1) {
        values.preferred_start_time = values.preferred_end_time;
        start_time = end_time;
      }

      if (end_time.isBefore(start_time) && timeSelected === 2) {
        values.preferred_end_time = values.preferred_start_time;
        end_time = start_time;
      }
    }

    timesArray.forEach(timeValue => {
      if (timeSelected === 1 && timeValue.id === values.preferred_start_time) {
        values.start_time = timeValue.name;
      } else if (
        timeSelected === 2 &&
        timeValue.id === values.preferred_end_time
      ) {
        values.end_time = timeValue.name;
      }
    });

    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.preferred_start_time, values.preferred_end_time]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getTimeVisit = visit => {
    const time =
      moment(visit.shift.start_time, 'HH:mm').format('HH:mm') +
      ' - ' +
      moment(visit.shift.end_time, 'HH:mm').format('HH:mm');
    return time;
  };
  const getDaysSelected = visits => {
    // get the string days and make them arrays
    const days = visits.split(',');

    return days;
  };
  const isDaySelected = (name, daysSelected) => {
    let result = daysSelected.indexOf(name);

    return result > -1;
  };


  const handleAllSelectChange = (valcheck) => {
    if (!values.addingcarers) {
      values.addingcarers = [];
    }
    if (valcheck.target.checked) {
      employeeRunData.map((visit)=>{
        values.addingcarers.push({ ...visit, visit_id: `${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}` })
      })
    } else {
      employeeRunData.map((visit)=>{
        values.addingcarers.map((item, index) => {
          if (item.visit_id === `${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}`) {
            values.addingcarers.splice(index, 1);
          }
        })
      })
    }
    setValues({ ...values })
  }

  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    if (!values.addingcarers) {
      values.addingcarers = [];
    }
    if (valcheck.target.checked) {
      values.addingcarers.push({ ...visit, visit_id: `${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}` })
    } else {
      values.addingcarers.map((item, index) => {
        if (item.visit_id === `${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}`) {
          values.addingcarers.splice(index, 1);
        }
      })
    }
    setValues({ ...values })
    //setClients(arr);
  }

  const handleCheck = (visit) => {
    let check = false;
    if (values.addingcarers) {
      values.addingcarers.map((item) => {
        if (`${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}` === item.visit_id) {
          check = true
        }
      })
    }
    return check;
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists=(id)=>{
      return values.addingcarers.some(function(el) {
        return el.visit_id === id;
      });
    }
    if (values.addingcarers && values.addingcarers.length > 0) {
        employeeRunData.map((visit)=>{
          if(!visitExists(`${visit.employee_id}${visit.repeat_on}${visit.shift.detail_id}`)){
            checkAll = false
          }
        })
    }else{
      checkAll = false;
    }
    return checkAll;
  }




  const getTravelName = (travel_type) => {
    let data = []
    if (travel_type && choices && choices.travel_type) {
      travel_type.forEach(detail => {
        choices.travel_type.forEach(val => {
          if (detail.travel_method__id === val.id) {
            data.push(`${val.name}`)
          }
        })
      })
    }
    return data;
  }




  const getSkillName = (skills) => {
    let data = []
    if (skills && choices && choices.skill) {
      skills.forEach(detail => {
        choices.skill.forEach(val => {
          if (detail.name === val.id) {
            data.push(`${val.name}`)
          }
        })
      })
    }
    return data;
  }

  const getCompetencyName = (competency) => {
    let data = []
    if (competency && choices && choices.competency_name) {
      competency.forEach(detail => {
        choices.competency_name.forEach(val => {
          if (detail.competency_name__id === val.id) {
            data.push(`${val.name}`)
          }
        })
      })
    }
    return data;
  }

  const getAvoidClient = (client) => {
    let data = []
    if (client) {
      client.forEach(detail => {
            if(detail.avoid_client__full_name){
              data.push(`${detail.avoid_client__full_name}`)
            }
      })
    }
    return data;
  }


  return (
    <div>
      <>  
      <ComponentDynamic loading={loadingModalEmployeeRun} >     
      <Table values={runEmpTableOptionData} headerColumns={headerColumns} handleAllCheck={handleAllCheck} handleAllSelectChange={handleAllSelectChange} callBackQuerySort={setQuerySort} headerPadding={false} noEditOption={true} noNeed={false}>
        <div>
          {employeeRunData && employeeRunData.map((visit, index) => {
            return (
              <div key={index}>
                <Row bgColor Shadow
                //   onContextMenu={(e) => 
                //     handleRowClick(e) 
                //  }
                >
                  <Col
                    className={!(runEmpTableOptionData && runEmpTableOptionData.includes("checkbox")) && 'd-none' }
                    max_width={EX_SMALL_COL4}
                    Shrink={false}
                    NoFlexGrow={true}
                    reducePadding
                    Capitalize
                    Center
                  >
                    <input class="form-check-input" type="checkbox" checked={handleCheck(visit)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, visit, index) }} />

                  </Col>
                  <Col
                   className={!(runEmpTableOptionData && runEmpTableOptionData.includes("full_name")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    overlap={true}
                    Capitalize
                    Center>
                    {visit.employee_name
                      ? visit.employee_name
                      : ''}
                  </Col>
                  {/* DAYS */}
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("day")) ? 'd-none' : 'visits-custom-row sm-hidden visit-days' }
                    max_width={EX_SMALL_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    style={{ maxWidth: '18%' }}>
                    <DayPill
                      className={!(runEmpTableOptionData && runEmpTableOptionData.includes("day")) && 'd-none' }
                      key={`day_pill_` + visit.repeat_on}
                      Active={true}>
                      <label>{visit.repeat_on}</label>
                    </DayPill>
                  </Col>
                  <Col
                    className={!(runEmpTableOptionData && runEmpTableOptionData.includes("shift_name")) && 'd-none' }
                    max_width={SMALL_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {visit.shift.shift_name}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("preferred_start_time")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {getTimeVisit(visit) || 'Unset'}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("travel_mode")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {getTravelName(visit.travel_mode).join(',')}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("gender")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {visit.gender}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("address")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {visit.address.line_1}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("postcode")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {visit.address.zip_code}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("skills")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    overlap={true}
                    Center>
                    {getSkillName(visit.skills).join(',')}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("competencies")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    overlap={true}
                    Center>
                    {getCompetencyName(visit.competency).join(',')}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("avoid_client")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    overlap={true}
                    Capitalize
                    Center>
                    {getAvoidClient(visit.avoid_client).join(',')}
                  </Col>
                  <Col
                  className={!(runEmpTableOptionData && runEmpTableOptionData.includes("runname")) && 'd-none' }
                    max_width={LARGE_COL}
                    Shrink
                    overlap={true}
                    reducePadding
                    Capitalize
                    Center>
                    {visit.run_name && visit.run_name.join(',')}
                  </Col>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
        </div>
        {/* )} */}
      </Table>
      </ComponentDynamic>
      </>
    </div>
  );
}

export default AssigningCarers;
