import { apiURL, apiLoginURL, apiHijackURL, apiURLReplica, apiLoginURLV2, apiURLReport } from '../env';
import {
  get,
  post,
  deleteFetch,
  postWithTimeout,
  patch,
  Newpost,
  deleteFetch2,
  getWithPayload,
} from './Api';
import { methods } from 'utils/constants/api';
import queryString from 'query-string';
import {
  PAGE_SIZE_RENDER,
  PAGE_SIZE_TWELVE_RENDER,
} from 'utils/constants/pagination';
import { getToken, getTenant, getUserId } from '../localStorage/token';
import { PrimaryButton } from 'shared/styles/buttons';

const { POST, PATCH, GET, PUT, DELETE } = methods;

const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};
const getBaseUrlMicroServices = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};

const getBaseUrlReport = () => {
  let tenant = getTenant();
  return `${apiURLReport}/${tenant}/api/v1`;
};


export const setCompany = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/companies/${values.id}/`
    : `${baseUrl}/core/companies/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const getCompanyLicense = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  if (values && values.limitperpage) {
    // if (values.offset) {
    toParse.offset = values.offset;
    // }

    if (values.search) {
      toParse.search = values.search;
    }
    if (values.ordering) {
      toParse.ordering = values.ordering;
    }
    toParse.limit = values.limitperpage;
    stringified = queryString.stringify(toParse) || '';

    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/companies/company/licenses/?${stringified}`;

    return get(url);
  }
};
export const setCompaniesLicense = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/companies/company/licenses/${values.id}/`
    : `${baseUrl}/core/companies/company/licenses/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setCalendarApi = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/companies/all/calendars/${values.id}/`
    : `${baseUrl}/core/companies/all/calendars/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const getCalendar = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/all/calendars/`;
  return get(url);
};
export const getCompany = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  if (values && values.limitperpage) {
    // if (values.offset) {
    toParse.offset = values.offset;
    // }

    if (values.search) {
      toParse.search = values.search;
    }
    if (values.ordering) {
      toParse.ordering = values.ordering;
    }
    toParse.limit = values.limitperpage;
    stringified = queryString.stringify(toParse) || '';

    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/companies/?${stringified}`;
    return get(url);
  }
};

export const getCompanies = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/`;
  return get(url);
};
export const getCompany2 = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/`;
  return get(url);
};
export const getBranches = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let str = '';
  let toParse = {};
  let secondParse = {};
  if (values.company) {
    let employment_typeFilter = values.company.map(data => data.id);
    let stremployment_typeFilter = employment_typeFilter.join(',');
    secondParse.company = stremployment_typeFilter;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (secondParse.company) {
    str += '&company__id__in=' + secondParse.company;
  }
  toParse.limit = values.limitperpage;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/companies/company/branches/?${stringified}${str}`;

  return get(url);
};

export const getBranchById = branch_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/branches/${branch_id}/`;

  return get(url);
};

export const getBranchAssignedToById = branch_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/branches/get_branch_access/?branch_id=${branch_id}`;

  return get(url);
};

export const getFormBuilderAssignedToById = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/form-builder/get_client_employee_list/?limit=20&offset=0&form=${id}`;

  return get(url);
};

export const getFormBuilderListChoiceData = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/form-builder/?limit=1000&offset=0`;

  return get(url);
};

export const getDropdownData = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/form-builder/get_all_drop_down/?limit=20&offset=0`;

  return get(url);
};

export const deleteCompanyBranchAssigned = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/companies/company/branches/delete_branch_access/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

// export const deleteFormBuilderAttachedClientAndEmployee = (id) => {
//   const baseUrl = getBaseUrl();
//   const url = `${baseUrl}/core/form-builder/${id}/`;

//   return deleteFetch(url);
// };

export const deleteFormBuilderListChoiceData = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/form-builder/${id}/`;

  return deleteFetch(url);
};

export const setCompanyBranch = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/companies/company/branches/${values.id}/`
    : `${baseUrl}/core/companies/company/branches/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setCompanyBranchAssign = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/companies/company/branches/add_branch_access/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setFormBuilderAssign = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/form-builder/attach_client_employee/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setFormBuilderChoices = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/form-builder/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteCompanyBranch = async (company_pk, branch_id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/branches/${branch_id}/`;

  return deleteFetch(url);
};

export const getServiceCategories = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/service-category/?${stringified}`;

  return get(url);
};

export const setServiceCategory = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/service-category/${values.id}/`
    : `${baseUrl}/core/service-category/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteServiceCategory = async category_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/service-category/${category_id}/`;

  return deleteFetch2(url);
};
export const getRunNames = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/run-view/?${stringified}`;

  return get(url);
};
export const getRunNamesRegular = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/run-view/`;

  return get(url);
};
export const getVisitType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/visit-type/?${stringified}`;

  return get(url);
};

export const setVisitType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/visit-type/${values.id}/`
    : `${baseUrl}/core/visit-type/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteVisitType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/visit-type/${visit_id}/`;

  return deleteFetch2(url);
};

export const getMedConditions = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/all-medical-condition/?${stringified}`;

  return get(url);
};

export const getTaskType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/care-task-type/?${stringified}`;

  return get(url);
};
export const getCareCategory = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/care-category-task/?${stringified}`;

  return get(url);
};

export const getCareTask = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/care-task/?${stringified}`;

  return get(url);
};

export const setMedCondition = values => {
  const baseUrl = getBaseUrl();
  const method = values?.id ? PATCH : POST;
  const url = values?.id
    ? `${baseUrl}/core/all-medical-condition/${values.id}/`
    : `${baseUrl}/core/all-medical-condition/`;

  const body = { name: values };

  return post(url, body, method);
};

export const setNewMedCondition = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/all-medical-condition/${values.id}/`
    : `${baseUrl}/core/all-medical-condition/`;

  const body = {
    name: values,
  };

  return post(url, body, method);
};
export const setTaskType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-task-type/${values.id}/`
    : `${baseUrl}/core/care-task-type/`;

  const body = values.id
    ? {
      name: values.name,
    }
    : {
      name: values.name,
      care_category: values.care_category,
    };
  return post(url, body, method);
};

export const setCareCategory = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-category-task/${values.id}/`
    : `${baseUrl}/core/care-category-task/`;

  const body = {
    name: values.name,
  };
  return post(url, body, method);
};

export const setCareTask = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-task/${values.id}/`
    : `${baseUrl}/core/care-task/`;

  const body = {
    name: values.name,
    task_type: values.task_type,
    feedback: values.feedback,
    result_type: values.result_type,
    result_option: values.result_option,
    header_msg: values.header_msg
  };
  return post(url, body, method);
};

// export const deleteMedCondition = async cond_id => {
//   const baseUrl = getBaseUrl();
//   const url = `${baseUrl}/core/all-medical-condition/${cond_id}/`;

//   return deleteFetch2(url);
// };

export const deleteMedCondition = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/all-medical-condition/${cond_id}/`;
  return deleteFetch2(url);
};

export const deleteTaskType = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-task-type/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteCareCategory = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-category-task/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteCareTask = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-task/${cond_id}/`;

  return deleteFetch2(url);
};

export const getAllergySource = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/allergy-source/?${stringified}`;

  return get(url);
};

export const getFluVaccination = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/flu-vaccination/?${stringified}`;

  return get(url);
};

export const getDisability = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/choices-disability/?${stringified}`;

  return get(url);
};

export const getInfectionControl = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/infection_control/?${stringified}`;
  return get(url);
};

export const getOrganDonation = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/organ_donation/?${stringified}`;

  return get(url);
};

export const getDNACPR = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/dnacpr/?${stringified}`;

  return get(url);
};
export const getVaccinationExemptionType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/vaccination-exemption-type/?${stringified}`;

  return get(url);
};

export const getSignSymptoms = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/sign-symptoms/?${stringified}`;

  return get(url);
};

export const getAgeBind = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/age-banding/?${stringified}`;

  return get(url);
};

export const getRotaRag = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/rota-rag/?${stringified}`;

  return get(url);
};

export const getClientVra = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/client-vra/?${stringified}`;

  return get(url);
};

export const getHealthSafty = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/health-safety-role/?${stringified}`;

  return get(url);
};

export const setAllergySource = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/allergy-source/${values.id}/`
    : `${baseUrl}/core/allergy-source/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setHealthSafty = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/health-safety-role/${values.id}/`
    : `${baseUrl}/core/health-safety-role/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setFluVaccination = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/flu-vaccination/${values.id}/`
    : `${baseUrl}/core/flu-vaccination/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setDisability = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/choices-disability/${values.id}/`
    : `${baseUrl}/core/choices-disability/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setOrganDonation = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/organ_donation/${values.id}/`
    : `${baseUrl}/core/organ_donation/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setInfectionControl = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/infection_control/${values.id}/`
    : `${baseUrl}/core/infection_control/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setDNACPR = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/dnacpr/${values.id}/`
    : `${baseUrl}/core/dnacpr/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setVaccinationExemptionType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/vaccination-exemption-type/${values.id}/`
    : `${baseUrl}/core/vaccination-exemption-type/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setSignSymptoms = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/sign-symptoms/${values.id}/`
    : `${baseUrl}/core/sign-symptoms/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const setAgeBind = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/age-banding/${values.id}/`
    : `${baseUrl}/core/age-banding/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

export const setClientVra = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/client-vra/${values.id}/`
    : `${baseUrl}/core/client-vra/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setSupportLevel = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/support-level/${values.id}/`
    : `${baseUrl}/core/support-level/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setMedicationName = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-name/${values.id}/`
    : `${baseUrl}/core/medication-name/`;

  const body = values.id
    ? {
      name: values.name,
      supplier_brand_name: values.supplier_brand_name,
      indication: values.indication,
      medication_class: values.medication_class,
      id: values.id,
    }
    : {
      name: values.name,
      supplier_brand_name: values.supplier_brand_name,
      indication: values.indication,
      medication_class: values.medication_class,
    };

  return Newpost(url, body, method);
};
export const setMedicationAdmType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/administration-type/${values.id}/`
    : `${baseUrl}/core/administration-type/`;

  const body = values.id
    ? {
      name: values.name,
      id: values.id,
    }
    : {
      name: values.name,
    };

  return Newpost(url, body, method);
};
export const setMedicationAdmReason = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/administration-reason/${values.id}/`
    : `${baseUrl}/core/administration-reason/`;

  const body = values.id
    ? {
      name: values.name,
      id: values.id,
    }
    : {
      name: values.name,
    };

  return Newpost(url, body, method);
};
export const setMedicationAdmForm = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-form/${values.id}/`
    : `${baseUrl}/core/medication-form/`;

  const body = values.id
    ? {
      name: values.name,
      id: values.id,
    }
    : {
      name: values.name,
    };

  return Newpost(url, body, method);
};
export const setMedicationAdmTypeRoute = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-route/${values.id}/`
    : `${baseUrl}/core/medication-route/`;

  const body = values.id
    ? {
      name: values.name,
      id: values.id,
    }
    : {
      name: values.name,
    };

  return Newpost(url, body, method);
};
export const setBillingPattern = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/billing-patterns/${values.id}/`
    : `${baseUrl}/core/billing-patterns/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

export const setTrainingTypes = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/training-type/${values.id}/`
    : `${baseUrl}/core/training-type/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setRegistrationTypes = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/registration-type/${values.id}/`
    : `${baseUrl}/core/registration-type/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setRotaRag = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/rota-rag/${values.id}/`
    : `${baseUrl}/core/rota-rag/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

// export const deleteAllergySource = async cond_id => {
//   const baseUrl = getBaseUrl();
//   const url = `${baseUrl}/core/allergy-source/${cond_id}/`;

//   return deleteFetch2(url);
// };

export const deleteAllergySource = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/allergy-source/${cond_id}/`;

  return deleteFetch2(url);
};
export const deleteHealthSafty = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/health-safety-role/${cond_id}/`;

  return deleteFetch2(url);
};
export const deleteFluVaccination = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/flu-vaccination/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteDisability = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/choices-disability/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteInfectionControl = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/infection_control/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteOrganDonation = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/organ_donation/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteDNACPR = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/dnacpr/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteVaccinationExemptionType = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/vaccination-exemption-type/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteSignSymptoms = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/sign-symptoms/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteAgeBind = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/age-banding/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const deleteRotaRag = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/rota-rag/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const deleteClientVra = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/client-vra/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const deleteSupportLevel = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/support-level/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteBillingTerms = async cond_id => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/core/billing-terms/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteBillingPattern = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/billing-patterns/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteRagistration = async cond_id => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/core/registration-type/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteTrainingType = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/training-type/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const getVisitTypePriority = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/visit-priority/?${stringified}`;

  return get(url);
};

export const setVisitTypePriority = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/visit-priority/${values.id}/`
    : `${baseUrl}/core/visit-priority/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteVisitTypePriority = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/visit-priority/${visit_id}/`;

  return deleteFetch2(url);
};

export const getServiceCategoryPriority = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/service-priority/?${stringified}`;

  return get(url);
};

export const setServiceCategoryPriority = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/service-priority/${values.id}/`
    : `${baseUrl}/core/service-priority/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setRunName = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/run-view/${values.id}/`
    : `${baseUrl}/core/run-view/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const deleteRunName = id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/run-view/${id}/`;
  const body = {
    is_deleted: true,
  };

  return post(url, body, method);
};
export const deleteServiceCategoryPriority = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/service-priority/${visit_id}/`;

  return deleteFetch2(url);
};

export const getEmploymentTypes = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/employment-type/?${stringified}`;

  return get(url);
};

export const setEmploymentType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/employment-type/${values.id}/`
    : `${baseUrl}/core/employment-type/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteEmploymentType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employment-type/${visit_id}/`;

  return deleteFetch2(url);
};

export const getEmployeeCategories = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/employee-category/?${stringified}`;

  return get(url);
};

export const setEmployeeCategory = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/employee-category/${values.id}/`
    : `${baseUrl}/core/employee-category/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteEmployeeCategory = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employee-category/${visit_id}/`;

  return deleteFetch2(url);
};

export const getJobTitles = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/job-title/?${stringified}`;

  return get(url);
};

export const setJobTitle = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/job-title/${values.id}/`
    : `${baseUrl}/core/job-title/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteJobTitle = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/job-title/${visit_id}/`;

  return deleteFetch2(url);
};

export const getEmployeeContracts = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/employee-contract/?${stringified}`;

  return get(url);
};

export const setEmployeeContract = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/employee-contract/${values.id}/`
    : `${baseUrl}/core/employee-contract/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteEmployeeContract = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employee-contract/${visit_id}/`;

  return deleteFetch2(url);
};

export const getSkill = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/skill/?${stringified}`;

  return get(url);
};
export const getCareContract = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/care-contact-type/?${stringified}`;

  return get(url);
};

export const getContactMethod = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/contact-method/?${stringified}`;

  return get(url);
};
export const getNok = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/nok-rel/?${stringified}`;

  return get(url);
};
export const getMarketing = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/marketing-method/?${stringified}`;

  return get(url);
};
export const getCancellation = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/cancellation-reason/?${stringified}`;

  return get(url);
};
export const getFunderSource = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/funder-source/?${stringified}`;

  return get(url);
};
export const getCareGroup = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/care-group/?${stringified}`;

  return get(url);
};
export const getSickness = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/sickness-reason/?${stringified}`;

  return get(url);
};
export const getPronounCategory = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/pronoun-category/?${stringified}`;

  return get(url);
};
export const getBillRate = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/bill-rate-model/?${stringified}`;

  return get(url);
};

export const getBillDuration = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/billing-duration-type/?${stringified}`;

  return get(url);
};
export const getPayRate = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/pay-rate-model/?${stringified}`;

  return get(url);
};
export const getPayDuration = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/pay-duration-type/?${stringified}`;

  return get(url);
};

export const getTepIn = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/tep_in_place/?${stringified}`;

  return get(url);
};
export const getCareLevel = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/care_level/?${stringified}`;

  return get(url);
};
export const getCareNeeds = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/care-needs-category/?${stringified}`;

  return get(url);
};

export const getCareDependency = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/care-dependency-level/?${stringified}`;

  return get(url);
};
export const getLivingCircumstances = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/living_circumstances/?${stringified}`;

  return get(url);
};
export const getImpairmentSensory = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/impairment-sensory-loss/?${stringified}`;

  return get(url);
};

export const getPPE = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/ppe-category/?${stringified}`;

  return get(url);
};
export const getShifts = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/regular-shift-type/?${stringified}`;

  return get(url);
};
export const getAbsencePaid = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/absence-paid/?${stringified}`;

  return get(url);
};

export const getCompentency = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/competency-name/?${stringified}`;

  return get(url);
};

export const getLeaverReason = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/leaver-reason/?${stringified}`;

  return get(url);
};

export const getEthencity = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/ethnicity/?${stringified}`;

  return get(url);
};
export const getEthnicityDescription = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/ethnicity_description/?${stringified}`;

  return get(url);
};

export const getJobRoleSpecialist = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/job-role-specialist_area/?${stringified}`;

  return get(url);
};
export const getProbationTerm = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/probation-term/?${stringified}`;

  return get(url);
};
export const getRecruitmentSource = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/recruitment-decision/?${stringified}`;

  return get(url);
};

export const getEmployerNoticePeriod = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/employer-notice-period/?${stringified}`;

  return get(url);
};
export const getCountry = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/country/?${stringified}`;

  return get(url);
};
export const getCareCertificate = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/care_certificate/?${stringified}`;

  return get(url);
};
export const getSocialCare = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/social_care_qualification/?${stringified}`;

  return get(url);
};

export const getCommonCore = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/common_core_confirmation/?${stringified}`;

  return get(url);
};

//get skill category 
export const getSkillCategory = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/skill-category/?${stringified}`;

  return get(url);
};
export const setMedicalDose = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-dose/${values.id}/`
    : `${baseUrl}/core/medication-dose/`;

  const body = {
    name: values.name,
    count_deduction: values.count_deduction,
  };

  return Newpost(url, body, method);
};
export const setMedicalDoseTiming = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-dose-timing/${values.id}/`
    : `${baseUrl}/core/medication-dose-timing/`;

  const body = values.id
    ? {
      id: values.id,
      name: values.name,
      count_deduction: values.count_deduction,
      end_time: values?.end_time ? values?.end_time + ':00' : null,
      start_time: values.start_time + ':00',
    }
    : {
      name: values.name,
      count_deduction: values.count_deduction,
      end_time: values?.end_time ? values?.end_time + ':00' : null,
      start_time: values.start_time + ':00',
    };

  return Newpost(url, body, method);
};
export const setMedicalType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-type/${values.id}/`
    : `${baseUrl}/core/medication-type/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const getMadicationErrorType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-error-type/?${stringified}`;

  return get(url);
};

export const deleteMedicationErrorType = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-error-type/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const setMedicalError = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-error-type/${values.id}/`
    : `${baseUrl}/core/medication-error-type/`;

  const body = {
    name: values.name,
    count_deduction: values.count_deduction,
  };

  return Newpost(url, body, method);
};
export const setMedicalFrequency = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-frequency/${values.id}/`
    : `${baseUrl}/core/medication-frequency/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setMedicalForm = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-form/${values.id}/`
    : `${baseUrl}/core/medication-form/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

export const setMedicalRoute = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-route/${values.id}/`
    : `${baseUrl}/core/medication-route/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setMedicalStatus = values => {
  console.log(values, 'YYYYYY');
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-status/${values.id}/`
    : `${baseUrl}/core/medication-status/`;

  const body = {
    name: values.name,
    is_reason: values.is_reason ? values.is_reason : false,
    color_code: values.color_code,
  };

  return Newpost(url, body, method);
};
export const setMedicalAlert = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-alert/${values.id}/`
    : `${baseUrl}/core/medication-alert/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setMedicalAssistance = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/medication-assistance/${values.id}/`
    : `${baseUrl}/core/medication-assistance/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setAdministrationType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/administration-type/${values.id}/`
    : `${baseUrl}/core/administration-type/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};
export const setAdministrationReason = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/administration-reason/${values.id}/`
    : `${baseUrl}/core/administration-reason/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

export const getMadicationFrequency = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-frequency/?${stringified}`;

  return get(url);
};
export const getMadicationForm = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-form/?${stringified}`;

  return get(url);
};
export const getMadicationRoute = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-route/?${stringified}`;

  return get(url);
};
export const getMadicationStatus = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-status/?${stringified}`;

  return get(url);
};
export const getMadicationAlert = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-alert/?${stringified}`;

  return get(url);
};
export const getMadicationAssistance = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-assistance/?${stringified}`;

  return get(url);
};
export const getAdministrationType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/administration-type/?${stringified}`;

  return get(url);
};
export const getAdministrationReason = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/administration-reason/?${stringified}`;

  return get(url);
};
export const getExpiryMedication = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/expiry-medication-reason/?${stringified}`;

  return get(url);
};

export const setExpiryMedication = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/expiry-medication-reason/${values.id}/`
    : `${baseUrl}/core/expiry-medication-reason/`;

  const body = {
    name: values.name,
  };

  return Newpost(url, body, method);
};

export const getAccreditedTraining = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/accredited_training/?${stringified}`;

  return get(url);
};
export const getServiceType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/service-type-category/?${stringified}`;

  return get(url);
};

export const getServiceTypeDescription = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/service-type-description/?${stringified}`;

  return get(url);
};
export const getApprenticeshipName = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/apprenticeship-name/?${stringified}`;

  return get(url);
};
export const getTriageTaskStatus = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/alert-task-status/?${stringified}`;

  return get(url);
};
export const getTriageTask = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/alert-task/?${stringified}`;

  return get(url);
};
export const getSpecificCommunication = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/specific-communication-needs/?${stringified}`;

  return get(url);
};
export const getClientGroup = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/client-group/?${stringified}`;

  return get(url);
};
export const getRegulatedServices = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/regulatedservices/?${stringified}`;

  return get(url);
};
export const getClientMobility = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/client-mobility/?${stringified}`;

  return get(url);
};
export const getUserType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/user-type-category/?${stringified}`;

  return get(url);
};
export const getMedicationName = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-name/?${stringified}`;

  return get(url);
};

export const deleteMedicationName = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-name/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const getMadicationDose = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-dose/?${stringified}`;

  return get(url);
};
export const getMadicationDoseTiming = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset === -10) {
    toParse.offset = 0;
  } else {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-dose-timing/?${stringified}`;

  return get(url);
};

export const getMadicationType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/medication-type/?${stringified}`;

  return get(url);
};
export const deleteMedicationDose = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-dose/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationDoseTiming = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-dose-timing/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};

export const deleteMedicationType = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-type/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationFrequency = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-frequency/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationForm = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-form/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationRoute = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-route/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationStatus = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-status/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationAlert = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-alert/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteMedicationAssistance = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/medication-assistance/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteAdministrationType = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/administration-type/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteAdministrationReason = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/administration-reason/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const deleteExpiryMedication = async cond_id => {
  const baseUrl = getBaseUrl();
  // const method = PATCH;
  const url = `${baseUrl}/core/expiry-medication-reason/${cond_id}/`;

  const body = {
    is_deleted: true,
  };
  return deleteFetch2(url);
};
export const getUserTypeDescription = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/user-type-description/?${stringified}`;

  return get(url);
};
export const getEstablishment = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/establishmenttype/?${stringified}`;

  return get(url);
};
export const getTitile = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/title/?${stringified}`;

  return get(url);
};

export const getAnimal = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/animal/?${stringified}`;

  return get(url);
};
export const getSupportLevel = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/support-level/?${stringified}`;

  return get(url);
};

export const getBillingTerms = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/billing-terms/?${stringified}`;

  return get(url);
};
export const getBillingPattern = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/billing-patterns/?${stringified}`;

  return get(url);
};
export const getRegistrationType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/registration-type/?${stringified}`;

  return get(url);
};
export const getTraingType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/training-type/?${stringified}`;

  return get(url);
};
export const getApplicantSource = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/applicant-source/?${stringified}`;

  return get(url);
};
export const getReferenceStatus = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/referencestatus/?${stringified}`;

  return get(url);
};

export const getVisaStatus = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/visastatus/?${stringified}`;

  return get(url);
};
export const getAbsencePlaned = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/absence-planned/?${stringified}`;

  return get(url);
};

export const getAbsenceInformed = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/absence-informed-method/?${stringified}`;

  return get(url);
};

export const getAbsenceType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/absence-type/?${stringified}`;

  return get(url);
};

export const getAbsenceReasonType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/absence-reason/?${stringified}`;

  return get(url);
};

export const setSkill = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/skill/${values.id}/`
    : `${baseUrl}/core/skill/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCareContract = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-contact-type/${values.id}/`
    : `${baseUrl}/core/care-contact-type/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setContactMethod = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/contact-method/${values.id}/`
    : `${baseUrl}/core/contact-method/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setNok = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/nok-rel/${values.id}/`
    : `${baseUrl}/core/nok-rel/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setMarketing = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/marketing-method/${values.id}/`
    : `${baseUrl}/core/marketing-method/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCancellation = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/cancellation-reason/${values.id}/`
    : `${baseUrl}/core/cancellation-reason/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setFunderSource = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/funder-source/${values.id}/`
    : `${baseUrl}/core/funder-source/`;

  const body = {
    ...values,
    category: category,
  };

  return Newpost(url, body, method);
};
export const setCareGroup = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-group/${values.id}/`
    : `${baseUrl}/core/care-group/`;

  const body = {
    ...values,
    category: category,
  };

  return Newpost(url, body, method);
};
export const setPPES = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/ppe-category/${values.id}/`
    : `${baseUrl}/core/ppe-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setSickness = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/sickness-reason/${values.id}/`
    : `${baseUrl}/core/sickness-reason/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setPronounCategory = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/pronoun-category/${values.id}/`
    : `${baseUrl}/core/pronoun-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setBillRate = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/bill-rate-model/${values.id}/`
    : `${baseUrl}/core/bill-rate-model/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setBillDuration = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/billing-duration-type/${values.id}/`
    : `${baseUrl}/core/billing-duration-type/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setPayRate = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/pay-rate-model/${values.id}/`
    : `${baseUrl}/core/pay-rate-model/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setPayDuration = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/pay-duration-type/${values.id}/`
    : `${baseUrl}/core/pay-duration-type/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setTepIn = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/tep_in_place/${values.id}/`
    : `${baseUrl}/core/tep_in_place/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setCareLevel = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care_level/${values.id}/`
    : `${baseUrl}/core/care_level/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCareNeeds = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-needs-category/${values.id}/`
    : `${baseUrl}/core/care-needs-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCareDependency = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care-dependency-level/${values.id}/`
    : `${baseUrl}/core/care-dependency-level/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setLivingCircumstances = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/living_circumstances/${values.id}/`
    : `${baseUrl}/core/living_circumstances/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setImpairmentSensory = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/impairment-sensory-loss/${values.id}/`
    : `${baseUrl}/core/impairment-sensory-loss/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setEthnicityDescription = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/ethnicity_description/${values.id}/`
    : `${baseUrl}/core/ethnicity_description/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setJobRoleSpecialist = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/job-role-specialist_area/${values.id}/`
    : `${baseUrl}/core/job-role-specialist_area/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setProbationTerm = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/probation-term/${values.id}/`
    : `${baseUrl}/core/probation-term/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setRecruitmentSource = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/recruitment-decision/${values.id}/`
    : `${baseUrl}/core/recruitment-decision/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setEmployerNoticePeriod = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/employer-notice-period/${values.id}/`
    : `${baseUrl}/core/employer-notice-period/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCountry = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/country/${values.id}/`
    : `${baseUrl}/core/country/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCareCertificate = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/care_certificate/${values.id}/`
    : `${baseUrl}/core/care_certificate/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setSocialCare = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/social_care_qualification/${values.id}/`
    : `${baseUrl}/core/social_care_qualification/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setCommonCore = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/common_core_confirmation/${values.id}/`
    : `${baseUrl}/core/common_core_confirmation/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

// set skill category
export const setSkillCategory = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/skill-category/${values.id}/`
    : `${baseUrl}/core/skill-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};

export const setAccreditedTraining = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/accredited_training/${values.id}/`
    : `${baseUrl}/core/accredited_training/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setServiceType = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/service-type-category/${values.id}/`
    : `${baseUrl}/core/service-type-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setServiceTypeDescription = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/service-type-description/${values.id}/`
    : `${baseUrl}/core/service-type-description/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setApprenticeshipName = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/apprenticeship-name/${values.id}/`
    : `${baseUrl}/core/apprenticeship-name/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setTriageTaskStatus = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-task-status/${values.id}/`
    : `${baseUrl}/core/alert-task-status/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setTriageTask = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-task/${values.id}/`
    : `${baseUrl}/core/alert-task/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setSpecificCommunication = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/specific-communication-needs/${values.id}/`
    : `${baseUrl}/core/specific-communication-needs/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setClientGroup = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/client-group/${values.id}/`
    : `${baseUrl}/core/client-group/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setRegulatedServices = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/regulatedservices/${values.id}/`
    : `${baseUrl}/core/regulatedservices/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setClientMobility = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/client-mobility/${values.id}/`
    : `${baseUrl}/core/client-mobility/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setUserType = (values, category) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/user-type-category/${values.id}/`
    : `${baseUrl}/core/user-type-category/`;

  const body = {
    ...values,
    category: category,
  };

  return post(url, body, method);
};
export const setUserTypeDescription = (values, data, testing, newData) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/user-type-description/${values.id}/`
    : `${baseUrl}/core/user-type-description/`;

  const body = {
    name: values.name,
    user_type_category: newData,
  };

  return post(url, body, method);
};
export const setShifts = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/regular-shift-type/${values.id}/`
    : `${baseUrl}/core/regular-shift-type/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setAbsencePaid = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/absence-paid/${values.id}/`
    : `${baseUrl}/core/absence-paid/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setCompentency = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/competency-name/${values.id}/`
    : `${baseUrl}/core/competency-name/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setLeaverReasons = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/leaver-reason/${values.id}/`
    : `${baseUrl}/core/leaver-reason/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setEthencity = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/ethnicity/${values.id}/`
    : `${baseUrl}/core/ethnicity/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};
export const setEstablishment = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/establishmenttype/${values.id}/`
    : `${baseUrl}/core/establishmenttype/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};
export const setTitle = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/title/${values.id}/`
    : `${baseUrl}/core/title/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setAnimal = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/animal/${values.id}/`
    : `${baseUrl}/core/animal/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};
export const setBillingTerm = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/billing-terms/${values.id}/`
    : `${baseUrl}/core/billing-terms/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};
export const setApplicantSource = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/applicant-source/${values.id}/`
    : `${baseUrl}/core/applicant-source/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setRefrenceStatus = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/referencestatus/${values.id}/`
    : `${baseUrl}/core/referencestatus/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setVisaStatus = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/visastatus/${values.id}/`
    : `${baseUrl}/core/visastatus/`;

  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setAbsencePlaned = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/absence-planned/${values.id}/`
    : `${baseUrl}/core/absence-planned/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setAnsenceInformed = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/absence-informed-method/${values.id}/`
    : `${baseUrl}/core/absence-informed-method/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setAbsence = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/absence-type/${values.id}/`
    : `${baseUrl}/core/absence-type/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setAbsenceReason = (values, data, absence) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/absence-reason/${values.id}/`
    : `${baseUrl}/core/absence-reason/`;

  const body = {
    absence_type: absence,
    name: values.name,
    id: values.id,
  };

  return post(url, body, method);
};

export const deleteSkill = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/skill/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareContract = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-contact-type/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteContactMethod = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/contact-method/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteNok = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/nok-rel/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteMarketing = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/marketing-method/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCancellation = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/cancellation-reason/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteFunderSource = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-source/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareGruop = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-group/${visit_id}/`;

  return deleteFetch2(url);
};
export const deletePPE = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/ppe-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteSickness = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/sickness-reason/${visit_id}/`;

  return deleteFetch2(url);
};
export const deletePronounCategory = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/pronoun-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteBillRate = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/bill-rate-model/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteBillDuration = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/billing-duration-type/${visit_id}/`;

  return deleteFetch2(url);
};
export const deletePayRate = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/pay-rate-model/${visit_id}/`;

  return deleteFetch2(url);
};
export const deletePayDuration = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/pay-duration-type/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteTepIn = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/tep_in_place/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareLevel = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care_level/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareNeeds = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-needs-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareDependency = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care-dependency-level/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteLivingCircumstances = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/living_circumstances/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteImpairmentSensory = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/impairment-sensory-loss/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteEthnicityDescription = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/ethnicity_description/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteShifts = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/regular-shift-type/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteAbsenceReason = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/absence-reason/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteAbsencePaid = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/absence-paid/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteCompentency = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/competency-name/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteLeaverReason = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/leaver-reason/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteEthencity = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/ethnicity/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteEthencityDescription = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/ethnicity_description/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteJobRoleSpecialist = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/job-role-specialist_area/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteProbation = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/probation-term/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteRecruitmentSource = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/recruitment-decision/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteEmployerNoticePeriod = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employer-notice-period/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCountry = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/country/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCareCertificate = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/care_certificate/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteSocialCareQualification = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/social_care_qualification/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCommonCore = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/common_core_confirmation/${visit_id}/`;

  return deleteFetch2(url);
};

// Delete skill category
export const deleteTeamCareCategory = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/skill-category/${id}/`;

  return deleteFetch2(url);
};
export const deleteAccreditedTraining = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/accredited_training/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteServiceType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/service-type-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteServiceTypeDescription = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/service-type-description/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteApprenticeshipName = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/apprenticeship-name/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteTriageTaskStatus = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/alert-task-status/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteTriageTask = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/alert-task/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteTriageStatus = async status_id => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/core/alert-status/${status_id}/`;

  return deleteFetch2(url);
};
export const deleteSpecificCommunication = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/specific-communication-needs/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteClientGroup = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/client-group/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteRegulatedServices = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/regulatedservices/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteClientMobility = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/client-mobility/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteUserType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/user-type-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteUserTypeDescription = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/user-type-description/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteEstablishment = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/establishmenttype/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteApplicantSource = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/applicant-source/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteReferenceStatus = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/referencestatus/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteTitle = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/title/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteAnimal = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/animal/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteVisaStatus = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/visastatus/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteAbsencePlanned = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/absence-planned/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteAbsenceInformed = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/absence-informed-method/${visit_id}/`;

  return deleteFetch2(url);
};

export const deleteAbsenceType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/absence-type/${visit_id}/`;

  return deleteFetch2(url);
};
export const deleteCompany = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/${id}/`;

  return deleteFetch(url);
};
export const deleteCompanyLicense = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/licenses/${id}/`;

  return deleteFetch(url);
};
export const getCompanyClientPreferences = (companyId, ordering) => {
  let toParse = {};
  const baseUrl = getBaseUrl();
  if (ordering) {
    toParse.ordering = ordering;
  }

  const stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/companies/${companyId}/client-preferences/?${stringified}`;

  return get(url);
};

export const getCompanyClientPreferencesById = (
  companyId,
  clientPreferenceId
) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/${companyId}/client-preferences/${clientPreferenceId}/`;

  return get(url);
};
export const getCompanyById = companyId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/${companyId}/`;

  return get(url);
};
export const setCompanyClientPreference = (companyId, values) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/companies/${companyId}/client-preferences/${values.id}/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const getCompanyLicenseById = companyId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/licenses/${companyId}/`;

  return get(url);
};

export const getOrganizations = (values, pageSize) => {
  let stringified = '';
  let toParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  toParse.limit = pageSize;
  stringified = queryString.stringify(toParse) || '';

  const url = `${apiLoginURL}/organizations/?${stringified}`;
  return get(url);
};

export const getLinkedUsers = (values, pageSize) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  toParse.limit = pageSize;
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/users/organization/linked_user/`;
  return get(url);
};

export const getApiForSwitchBackOrg = switch_back_user_id => {
  const url = `${apiLoginURL}/organizations/switch-user?switch_back_user_id=${switch_back_user_id}`;
  return get(url);
};

export const createOrganization = values => {
  const method = POST;
  const url = `${apiLoginURLV2}/organizations/organization-signup/`;
  const body = {
    ...values,
  };
  return postWithTimeout(url, body, method);
};

export const createOrganizationWithPlan = values => {
  const method = POST;
  const url = `${apiLoginURL}/common_core/subscription-type/organization_subscription_update/`;
  const body = {
    ...values,
  };
  return postWithTimeout(url, body, method);
};

export const statusOrganization = (id, is_active) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/organizations/${id}/`;
  const body = {
    is_active: is_active ? false : true,
    // is_active
  };
  return post(url, body, method);
};

export const switchOrganization = (superuser_id, last_user_id) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  // const url = `${baseUrl}/organizations/${superuser_id}/`;
  const url = `${apiHijackURL}/hijack/${superuser_id}/`;
  const body = {
    '  last_user_id ': last_user_id,
  };
  return post(url, body, method);
};

export const postApiSwitchUserId = async (id, user_id) => {
  const method = POST;
  const url = `${apiLoginURL}/organizations/switch-user`;
  const body = {
    switch_user_id: user_id,
    switch_organization_id: id,

  };
  return post(url, body, method);
};

export const postApiSwitchUserIdLinkedUser = async (id, user_id) => {
  const method = POST;
  const url = `${apiLoginURL}/users/organization/switch_organization/`;
  const body = {
    user: user_id,
    org_id: id,
    view: 'Web'
  };
  return post(url, body, method);
};

//added run route api below
export const getRunRoute = (values, pageSize) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  toParse.limit = pageSize;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/run-routes/?${stringified}`;

  return get(url);
};

export const statusRunRoute = async (id, is_deleted) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/run-routes/${id}/`;
  const body = {
    is_deleted: true,
    // is_active
  };
  return post(url, body, method);
};

// export const  switchBackToOrganization = (superuser_id) => {
//   const baseUrl = getBaseUrl();
//   const method = POST;
//   // const url = `${baseUrl}/organizations/${superuser_id}/`;
//   const url = `${apiHijackURL}/hijack/${superuser_id}/`;
//   const body = {
//     "user_id"  : superuser_id
//   };
//   return post (url, body, method);

// };
// below post api for runroute page
export const postRunRoute = async (values, bodyData) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  // let stringified = '';
  // let toParse = {};
  // toParse.limit = PAGE_SIZE_RENDER;
  // stringified = queryString.stringify(toParse) || '';
  const url = values.id
    ? `${baseUrl}/core/run-routes/${values.id}/`
    : `${baseUrl}/core/run-routes/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const setRunRouteByID = values => {
  const baseUrl = getBaseUrl();
  const method = GET;
  const url = `${baseUrl}/core/run-routes/?run_route_id=${values}`;
  const body = {};
  return get(url, body, method);
};

export const getAlgoScore = (values, tabname) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/algorithms-rules/?${stringified}&categories=${tabname}`;

  return get(url);
};

export const statusAlgo = (id, status, tooglename) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/algorithms-rules/${id}/`;
  var obj = {};
  if (tooglename == 'consider_hard_rule') {
    obj = {
      consider_hard_rule: status,
    };
  } else {
    obj = {
      status: status,
    };
  }
  const body = obj;
  return post(url, body, method);
};

export const setAlgorithmsDataById = idSelected => {
  const baseUrl = getBaseUrl();
  const method = GET;
  const url = `${baseUrl}/core/algorithms-rules/${idSelected}/`;
  const body = {};
  return get(url, body, method);
};

export const algorithRuleUpdates = async bodyData => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/algorithms-rules/algorithm_rules_update/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const postAlgoTime = bodyData => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/algo-time/algo_time_update/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const getAlgoTimeData = async () => {
  const baseUrl = getBaseUrl();
  const method = GET;
  const url = `${baseUrl}/core/algo-time/get_algotime/`;
  const body = {};
  return get(url, body, method);
};

export const resetAlgoRow = async id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/algorithms-rules/reset_algorithm/?id=${id}`;
  const body = {};
  return post(url, body, method);
};

export const resetAllAlgo = async algoTabName => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/algorithms-rules/reset_algorithm/?categories=${algoTabName}`;
  const body = {};
  return post(url, body, method);
};

//get api  for Rate Card  is below
export const getRateCard = (values, limitPerPage, rateCardType) => {
  const baseUrl = getBaseUrlMicroServices();
  let stringified = '';
  let toParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  toParse.limit = limitPerPage;

  if (rateCardType) {
    toParse.category = rateCardType;
    stringified += '&category=' + toParse.category;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/bill_rates/RateCard/?${stringified}`;
  return get(url);
};

//patch api for rte crad status +delete icon  is below
export const statusIconPatch = async (id, data) => {
  const baseUrl = getBaseUrlMicroServices();
  const method = PATCH;
  const url = `${baseUrl}/bill_rates/RateCard/rate_card_update/`;
  const body = {
    id: id,
    is_active: data,
  };
  return post(url, body, method);
};

export const statusDefaultPatch = async (id, data) => {
  const baseUrl = getBaseUrlMicroServices();
  const method = PATCH;
  const url = `${baseUrl}/bill_rates/RateCard/rate_card_update/`;
  const body = {
    id: id,
    default_card_status: data,
  };
  return post(url, body, method);
};

export const DeleteRateCard = async (id, deleted) => {
  const baseUrl = getBaseUrlMicroServices();
  const method = PATCH;
  const url = `${baseUrl}/bill_rates/RateCard/rate_card_update/`;
  const body = {
    id: id,
    is_deleted: deleted,
  };
  return post(url, body, method);
};

//patch 7 post  api of rate card below
export const postOrPatchRateCard = async (values, bodyData) => {
  const baseUrl = getBaseUrlMicroServices();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/bill_rates/RateCard/${values.id}/`
    : `${baseUrl}/bill_rates/RateCard/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

//onclickedit btn of ratecard  api below
export const setRateCardById = idSelected => {
  const baseUrl = getBaseUrlMicroServices();
  const method = GET;
  const url = `${baseUrl}/bill_rates/RateCard/${idSelected}/`;
  const body = {};
  return get(url, body, method);
};

//get api NLW/RLW
export const getNLW = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  toParse.offset = values.offset;

  if (values.limitperpage) {
    toParse.limit = values.limitperpage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/bill_rates/wages-rate/?${stringified}`;
  return get(url);
};

//post/patch api for NLW -below
export const NLWSUBMIT = async (values, bodyData) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/bill_rates/wages-rate/${values.id}/`
    : `${baseUrl}/bill_rates/wages-rate/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

//onclickedit btn -setNLWRLWById- api below
export const setNLWRLWById = idSelected => {
  const baseUrl = getBaseUrl();
  const method = GET;
  const url = `${baseUrl}/bill_rates/wages-rate/${idSelected}/`;
  const body = {};
  return get(url, body, method);
};

//only delte method  - NLW below
export const DeleteNLW = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/bill_rates/wages-rate/${id}/`;
  return deleteFetch2(url);
};
export const companyLicenseDelete = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/licenses/${id}/`;

  return deleteFetch(url);
};
//get api  for Public   Holiday  is below
export const getPublicHoliday = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.limitperpage) {
    toParse.limit = values.limitperpage;
  }

  // toParse.limit = pageSize || PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/public-holiday/?${stringified}`;
  return get(url);
};

// save/edit  of   public holiday  below
export const PublicHoliday = async (values, bodyData) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/public-holiday/${values.id}/`
    : `${baseUrl}/core/public-holiday/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

//onclickedit btn -setPublicHolidayById- api below
export const setPublicHolidayById = idSelected => {
  const baseUrl = getBaseUrl();
  const method = GET;
  const url = `${baseUrl}/core/public-holiday/${idSelected}/`;
  const body = {};
  return get(url, body, method);
};

//delte icon - public holiday below
export const DeletePublicHoliday = async id => {
  const baseUrl = getBaseUrl();
  const method = DELETE;
  const url = `${baseUrl}/core/public-holiday/${id}/`;
  const body = {};
  return post(url, body, method);
};

export const addFunder = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/funder-details/${values.id}/`
    : `${baseUrl}/core/funder-details/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getFunders = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_ids = strbranchFilter;
  }
  toParse.limit = values.limitperpage;
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.branch_ids) {
    stringified += '&branch_ids=' + filterParse.branch_ids;
  }
  const url = `${baseUrl}/core/funder-details/?${stringified}`;
  return get(url);
};
export const getFunderById = funder_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-details/${funder_id}/`;

  return get(url);
};
export const deleteFunder = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-details/${id}/`;
  return deleteFetch2(url);
};

export const addImpContact = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/core-contact/${values.id}/`
    : `${baseUrl}/core/core-contact/`;
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(values)) {
    if (typeof value === 'object' && value !== null && key !== 'photo') {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();
  return json;

  // return { status: response.status, message: json };
};

export const getImpContacts = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_ids = strbranchFilter;
  }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.branch_ids) {
    stringified += '&branch_ids=' + filterParse.branch_ids;
  }
  const url = `${baseUrl}/core/core-contact/?${stringified}`;
  return get(url);
};

export const getImpContactById = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-contact/${id}/`;

  return get(url);
};

export const getSubscriptionById = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/common_core/subscription-type/get_organization_subscription_details/?org_id=${id}`;

  return get(url);
};

export const deleteImpContact = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-contact/${id}/`;
  return deleteFetch2(url);
};
export const getSettingEvv = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/evv/settings/`;
  return get(url);
};
export const getSettingEsv = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/esv/settings/`;
  return get(url);
};


export const getSplitShift = () => {
  const baseUrl = getBaseUrl();
  // https://dev.myhelpa.com/org/bill-test-org-1/api/v1/scheduler/esvSettings/splitShift/splitShiftBranchWiseList/
  const url = `${baseUrl}/scheduler/esvSettings/splitShift/splitShiftBranchWiseList/`;
  return get(url);
};

export const getSplitShiftById = (id) => {
  const baseUrl = getBaseUrl();
  //https://dev.myhelpa.com/org/bill-test-org-1/api/v1/scheduler/esvSettings/splitShift/?branch_id=12242343534546457567
  const url = `${baseUrl}/scheduler/esvSettings/splitShift/?branch_id=${id}`;
  return get(url);
};

export const setSplitShiftApi = async (bodyData) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  //https://dev.myhelpa.com/org/bill-test-org-1/api/v1/scheduler/esvSettings/splitShift/splitShiftUpdate/
  const url = `${baseUrl}/scheduler/esvSettings/splitShift/splitShiftUpdate/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const getSettingEsvById = id => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/scheduler/evv/settings/get_details/?evv_settings_core_id=${id}`;
  return get(url);
};
export const editSettingEvv = async (values, bodyData) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/evv/settings/update_evv_settings/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const editSettingEsv = async bodyData => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/esv/settings/update_esv_settings/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const getSettingFinanceBilling = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/evv/finance/get_finance/`;
  return get(url);
};

export const editSettingFinance = async bodyData => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/evv/finance/finance_billing_and_payroll/`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};

export const getTriageStatus = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/alert-status/?${stringified}`;

  return get(url);
};

export const setTriageStatus = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-status/${values.id}/`
    : `${baseUrl}/core/alert-status/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const getTriageType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/alert-type/?${stringified}`;

  return get(url);
};

export const editSettingEvvData = async (ID, bodyData) => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/evv/settings/update_evv_settings/?evv_settings_core_id=${ID}`;
  const body = {
    ...bodyData,
  };
  return post(url, body, method);
};
export const deleteTriageType = async status_id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/alert-type/${status_id}/`;
  const body = {
    is_deleted: true,
  };
  return post(url, body, method);
};

export const setTriageType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-type/${values.id}/`
    : `${baseUrl}/core/alert-type/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const getTriageLabel = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/alert-label/?${stringified}`;

  return get(url);
};

export const deleteTriageLabel = async status_id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/alert-label/${status_id}/`;
  const body = {
    is_deleted: true,
  };
  return post(url, body, method);
};

export const setTriageLabel = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-label/${values.id}/`
    : `${baseUrl}/core/alert-label/`;

  const body = values.id
    ? {
      name: [values.name],
    }
    : {
      id: values.id,
      name: values.name,
    };

  return post(url, body, method);
};

export const setAlertLabelSettings = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-label/${values.id}/`
    : `${baseUrl}/core/alert-label/`;

  const body = {
    name: [values.name],
  };

  return post(url, body, method);
};

export const setOnlyforTriagePopupTriageLabel = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-label/${values.id}/`
    : `${baseUrl}/core/alert-label/`;

  const body = {
    name: values,
  };

  return post(url, body, method);
};

export const getTriageSeverity = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/alert-severity/?${stringified}`;

  return get(url);
};

export const deleteTriageSeverity = async status_id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/alert-severity/${status_id}/`;
  const body = {
    is_deleted: true,
  };
  return post(url, body, method);
};

export const setTriageSeverity = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/alert-severity/${values.id}/`
    : `${baseUrl}/core/alert-severity/`;

  const body = {
    name: values.name,
  };

  return post(url, body, method);
};

export const getRealTimeNotification = userID => {
  const baseUrl = getBaseUrl();
  // const url = `${baseUrl}/alerts/user-notification?user_id=${userID}`;
  const url = `${baseUrl}/core/push-notification/notification/?limit=10&offset=0`;
  return get(url);
};

export const readNotification = (Id, notified) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/user-notification/update_notification/`;
  const body = {
    ids: Id,
    is_notified: notified,
  };

  return post(url, body, method);
};

export const readAllNotification = Id => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/alerts/notification/alert/bulk_update_all/?user_id=${Id}`;
  const body = {};
  return post(url, body, method);
};

export const getBellNotificationss = (values, limitPerPage, userID) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  toParse.offset = values.offset;

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_ids = strbranchFilter;
  }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.branch_ids) {
    stringified += '&branch_ids=' + filterParse.branch_ids;
  }
  // const url = `${baseUrl}/core/user-notification/?${stringified}`;
  const url = `${baseUrl}/core/push-notification/notification/?limit=10&offset=0`;
  return get(url);
};


export const getSetingsEmailHistoryApi = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {}
  toParse.limit = values.limitperpage;
  toParse.offset = values.offset;
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse)

  if (values.branch) {
    const branchIds = values.branch.map((el) => el.id)
    const branchIdStr = branchIds.join(",")
    filterParse.branch = branchIdStr
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (filterParse.branch) {
    stringified += "&branch=" + filterParse.branch
  } else if (filterParse.headerbranch) {
    stringified += "&branch=" + filterParse.headerbranch
  }

  const url = `${baseUrl}/core/mail-history/record/?${stringified}`
  // console.log("mail api", stringified, url)
  console.log(values, "values")
  return await get(url)
}

export const getEmailContentById = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/mail-history/content/${id}/`
  return await get(url)
}

export const getDownloadAttachment = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/mail-history/content/download_attachment_file/?id=${id}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: 'Token ' + getToken(),

    },
  });

  return response;
}

// Settings Team > Skill Matrix & Competency Matrix
export const getSkillMatrixList = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  toParse.limit = values.limitperpage;
  toParse.offset = values.offset;
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    toParse.branch__id = strheaderbranchFilter;
  }

  stringified = queryString.stringify(toParse)
  const url = `${baseUrl}/core/metrics/skill/?${stringified}`
  return await get(url)
}

export const addNewSkillMatrix = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/metrics/skill/`

  return post(url, body, method)
}

export const deleteMatrixSkill = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/metrics/skill/${id}/`

  return await deleteFetch(url)
}

export const updateSkillMatrix = async (id, body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/metrics/skill/${id}/`

  return await post(url, body, method)
}

export const getSkillMatrixById = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/metrics/skill/${id}/`

  return await get(url)
}

export const bulkDeleteSkillMatrix = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH
  const url = `${baseUrl}/core/metrics/skill/bulk_delete/`

  return await post(url, body, method)
}

// Settings Team > Competency Matrix APIs > 

export const getCompentencyMatrixList = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  toParse.limit = values.limitperpage;
  toParse.offset = values.offset;
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    toParse.branch__id = strheaderbranchFilter;
  }

  stringified = queryString.stringify(toParse)
  const url = `${baseUrl}/core/metrics/competency/?${stringified}`

  return get(url)
}

export const addNewCompetencyMatrix = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/metrics/competency/`

  return await post(url, body, method)
}

export const updateCompetencyMatrix = async (id, body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/metrics/competency/${id}/`

  return await post(url, body, method)
}


export const deleteCompentencyMatrix = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/metrics/competency/${id}/`

  return deleteFetch(url)
}


export const bulkDeleteCompetencySkill = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/metrics/competency/bulk_delete/`

  return await post(url, body, method)
}

//Accrual setting

export const getAccrualSetting = async (branchId) => {
  const baseUrl = getBaseUrl()
  let stringified = ''
  let toParse = {}
  toParse.branch_id = branchId
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/leave-accrual-setting/leave-accrual/?${stringified}`

  return await get(url)
}

export const updateAccrualSetting = async (branchId, body) => {
  const baseUrl = getBaseUrl()
  const method = PATCH;
  const url = `${baseUrl}/core/leave-accrual-setting/update-leave-accrual/?branch_id=${branchId}`

  return await post(url, body, method)
}

export const getBranchAbsenceOverview = async (values, currentPage, branch_id) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}
  toParse.limit = values.limitperpage
  toParse.offset = currentPage == 1 ? '0' : `${currentPage}0`
  if (branch_id) {
    toParse.branch_id = branch_id
  }
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/?${stringified}`

  return await get(url)
}

export const getOverlapedDateRange = (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}
  if (values.calendarType) {
    toParse.calendarType = values.calendarType
  }
  if (values.allowance_date_from) {
    toParse.allowance_date_from = values.allowance_date_from
  }
  if (values.allowance_date_to) {
    toParse.allowance_date_to = values.allowance_date_to
  }
  if (values.calendarType) {
    toParse.calendarType = values.calendarType
  }

  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/get_date_range/?${stringified}`
  return get(url)
  // /core/branch-absence-calendar/branch-absence-calendar-overview/get_date_range/?calendarType=cbf8fda7-ff80-41f5-842d-1551c899fb66&allowance_date_from=2023&allowance_date_to=2023
}

export const addAbsenceOverview = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/employee/absence/createAbsenceOverview/`
  return await post(url, body, method)
}
export const updateAbsenceOverview = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  // /core/branch-absence-calendar/branch-absence-calendar-overview/
  // /core/branch-absence-calendar/branch-absence-calendar-overview/
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/`
  return await post(url, body, method)
}

export const setBranchAbsenceOverview = async (body, id) => {
  const baseUrl = getBaseUrl();
  const method = id ? PATCH : POST;
  const url = id
    ? `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/${id}/`
    : `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/`
  return await post(url, body, method)
}

export const setUpdateCalendarType = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/bulk_update_employees_calendar/`

  return await post(url, body, method)
}

export const getCalendarRelatedEmployees = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  stringified = queryString.stringify(values) || ''
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/calendar_related_employees/?${stringified}`

  return await get(url)
}

export const getBranchAbsenceOverViewById = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/${id}/`

  return await get(url)
}

export const deleteBranchAbsenceOverview = (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/${id}/`;
  return deleteFetch(url);
}

export const getBranchAbsenceOverviewById = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/employee/absence/getAbsenceOverview/?branch=${id}`

  return await get(url)
}

export const getBranchTaxById = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/companies/company/tax-discount/?branch__id__in=${id}`
  return await get(url)
}
//Settings QA Taks APIs
export const getHrTaskListApi = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}
  if (values?.limitperpage) {
    toParse.limit = values?.limitperpage
  }
  if (values?.offset) {
    toParse.offset = values?.offset
  }
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/hr-task/core-task/?${stringified}`

  return await get(url)
}

export const addBranchTax = async (body) => {
  const baseUrl = getBaseUrl();
  const method = body.id ? PATCH : POST;
  const url = body.id ?
    `${baseUrl}/core/companies/company/tax-discount/${body.id}/`
    :
    `${baseUrl}/core/companies/company/tax-discount/`

  return await post(url, body, method)
}

export const deleteTaxDiscount = (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/tax-discount/${id}/`;
  return deleteFetch(url);
}

export const addHrTask = async (seletedId, body) => {
  const baseUrl = getBaseUrl();
  const method = seletedId ? PATCH : POST;
  const url = seletedId
    ? `${baseUrl}/core/hr-task/core-task/${seletedId}/`
    : `${baseUrl}/core/hr-task/core-task/`

  return await post(url, body, method)
}

export const deleteHrTask = async (taskId, body) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/hr-task/core-task/${taskId}/`

  // return await deleteFetch(url)
  const response = await fetch(url, {
    method: DELETE,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    const jsonResponse = await response.json();
    // Now `jsonResponse` contains the parsed JSON data
    return jsonResponse
  }
}

// Event task 

export const getEventTaskListApi = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter?.filter(e => e !== 'None').join(',');
    toParse.branch_id = strheaderbranchFilter;
  }
  toParse.limit = values?.limitperpage
  toParse.offset = values?.offset
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/hr-task/events-task/?${stringified}`

  return await get(url)
}


export const addBulkEventTask = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/hr-task/events-task/bulk_create/`

  return await post(url, body, method)
}

export const getEventsList = async () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/hr-task/events/?limit=100&offset=0`

  return await get(url)
}

export const deleteEventTasks = async (body) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/core/hr-task/events-task/bulk_delete/`
  const method = PATCH

  return await post(url, body, method)
}

export const updateEventTask = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH
  const url = `${baseUrl}/core/hr-task/events-task/bulk_update/`

  return await post(url, body, method)
}

export const retriveEventTasks = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/hr-task/events-task/eventRetrieve/?id=${id}`

  return await get(url)
}

// Repeating task apis
export const getRepeatingTaskList = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}
  toParse.limit = values?.limitperpage
  toParse.offset = values?.offset
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/hr-task/repeating-task/?${stringified}`

  return await get(url)
}
export const addRepeatingTask = async (seletedId, body) => {
  const baseUrl = getBaseUrl();
  const method = seletedId ? PATCH : POST;
  const url = seletedId
    ? `${baseUrl}/core/hr-task/repeating-task/${seletedId}/`
    : `${baseUrl}/core/hr-task/repeating-task/`

  return await post(url, body, method)
}

export const getRepeatingTaskById = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/hr-task/repeating-task/${id}/`

  return await get(url)
}


export const deleteRepeatingTask = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/hr-task/repeating-task/${id}/`
  return await deleteFetch(url)
}


export const getTravelCommuteList = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}

  if (values?.limitperpage) {
    toParse.limit = values?.limitperpage
  }
  if (values?.offset) {
    toParse.offset = values?.offset
  }
  if (values.id) {
    toParse.evv_settings_core__branch__id__in = values.id
  }
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/companies/company/branch/travel-commute/?${stringified}`
  return await get(url)
}

export const updateTravelCommuteBill = async (body, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const url = `${baseUrl}/core/companies/company/branch/travel-commute-bill/${id}/`

  return await post(url, body, method)
}

export const updateTravelCommute = async (body, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const url = `${baseUrl}/core/companies/company/branch/travel-commute/${id}/`

  return await post(url, body, method)
}

//Billing Non Commute
export const getBillingNonCommuteList = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let toParse = {}

  if (values?.limitperpage) {
    toParse.limit = values?.limitperpage
  }
  if (values?.offset) {
    toParse.offset = values?.offset
  }
  if (values.id) {
    toParse.evv_settings_core__branch__id__in = values.id
  }
  stringified = queryString.stringify(toParse) || ''
  const url = `${baseUrl}/core/companies/company/branch/travel-commute-bill/?${stringified}`
  return await get(url)
}

// Team Setting / Pay Type
export const getPayType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/pay-type/?${stringified}`;

  return get(url);
};

export const deletePayType = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/pay-type/${id}/`;

  return deleteFetch2(url);
};

export const setPayTypeApi = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/pay-type/${values.id}/`
    : `${baseUrl}/core/pay-type/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};


// export const setClientContractTemplate = async (body) => {
//   const baseUrl = getBaseUrl();

//   const method = body.id ? PATCH : POST;
//   const url = body.id ? `${baseUrl}/core/core-service-contract/${body.id}/` : `${baseUrl}/core/core-service-contract/`

//   return post(url, body, method);
// };


export const getClientsServicesTemplate = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  // const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;
  const url = `${baseUrl}/core/core-service-contract/template/?${stringified}`;


  return get(url);
};

export const getClientsServicesTemplateById = (serviceId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-service-contract/template/${serviceId}/`;

  return get(url);
};

export const getMedicationPrescription = (values) => {
  const baseUrl = getBaseUrl();
  let toParse = {}
  let stringified = ""

  if (values.client) {
    toParse.client = values.client
  }

  if (values.medicationDose) {
    toParse.medicationDose = values.medicationDose
  }
  if (values.serviceStartDate) {
    toParse.serviceStartDate = values.serviceStartDate
  }
  if (values.serviceEndDate) {
    toParse.serviceEndDate = values.serviceEndDate
  }

  stringified = queryString.stringify(toParse) || '';


  //http://localhost:8000/org/bill-test-org-1/api/v1/medication/getMedicationPrescription/?client=3796b7d2-038c-4f0e-9d47-3fdcbab1d8fc&medicationDose=9c087b49-0ad1-475c-ad4d-f7d22c55382c&serviceStartDate=2024-07-01&serviceEndDate=2024-07-28
  const url = `${baseUrl}/medication/getMedicationPrescription/?${stringified}`;

  return get(url);
};



export const getClientsServicesTemplateVisitById = (serviceId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-service-contract/service-visit/${serviceId}/`;

  return get(url);
};


export const setClientContractTemplate = async (contract) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(contract)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'visit' &&
      key !== 'service_funder' &&
      key !== 'bill_rate' &&
      key !== 'pay_rate' &&
      key !== 'travel_pay_rate'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key === 'visit' ||
      key === 'service_funder' ||
      key === 'bill_rate' ||
      key === 'pay_rate' ||
      key === 'travel_pay_rate'
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const method = contract.id ? PATCH : POST;
  const url = contract.id ? `${baseUrl}/core/core-service-contract/template/${contract.id}/` : `${baseUrl}/core/core-service-contract/template/`

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  //added new lines
  // if (response && response.status === unauthorized_status_code) {
  //   clearToken();
  //   window.location.reload();
  // }

  const json = await response.json();

  return { status: response.status, message: json };
};


export const setClientContractVisitsTemplate = async (body) => {
  const baseUrl = getBaseUrl();

  const method = body.id ? PATCH : POST;
  const url = body.id ? `${baseUrl}/core/core-service-contract/service-visit/${body.id}/` : `${baseUrl}/core/core-service-contract/service-visit/`

  return post(url, body, method);
};


export const getPrivacySettingApi = async (branchId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/company/company_branch_privacy/?limit=20&offset=0&branch_id=${branchId}`;

  return get(url);
}

export const setPrivacySetting = async (body, branchId, isExist) => {
  const baseUrl = getBaseUrl()
  const method = isExist ? PATCH : POST

  const url = isExist
    ? `${baseUrl}/core/companies/company/company_branch_privacy/${branchId}/`
    : `${baseUrl}/core/companies/company/company_branch_privacy/`

  return await post(url, body, method)
}

//Assessment

export const setAssessment = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/assessment/assessment/${values.id}/`
    : `${baseUrl}/core/assessment/assessment/`;

  const body = {
    name: values.name,
  };
  return post(url, body, method);
};

// 
export const deleteAssessment = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/assessment/assessment/${cond_id}/`;

  return deleteFetch2(url);
};

export const deleteAssessmentCategory = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/assessment/category/${cond_id}/`;

  return deleteFetch2(url);
};


export const deleteAssessmentType = async cond_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/assessment/type/${cond_id}/`;

  return deleteFetch2(url);
};
export const getAssessment = async () => {

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/assessment/assessment/`;
  return await get(url)
}

export const setAssessmentCategory = async (values) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;

  const body = { ...values }

  if (values.id) body.id = values.id;

  const url = values.id
    ? `${baseUrl}/core/assessment/category/${values.id}/`
    : `${baseUrl}/core/assessment/category/`

  return await post(url, body, method)
}


export const setAssessmentType = async (values) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;

  const body = { ...values }

  if (values.id) body.id = values.id;

  const url = values.id
    ? `${baseUrl}/core/assessment/type/${values.id}/`
    : `${baseUrl}/core/assessment/type/`

  return await post(url, body, method)
}


export const uploadCompanyLogo = async (id, logo) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const formData = new FormData()
  formData.append('logo', logo)
  const url = `${baseUrl}/core/companies/${id}/`
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
}


export const uploadBranchLogo = async (id, logo) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const formData = new FormData();
  formData.append('logo', logo);
  const url = `${baseUrl}/core/companies/company/branches/${id}/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();
  return { status: response.status, message: json };
};

export const uploadOrganizationLogo = async (id, logo) => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const formData = new FormData();
  formData.append('logo', logo);
  formData.append('organization_id', id)
  const url = `${baseUrl}/organizations/organization-signup/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();
  return { status: response.status, message: json };
};



export const getCalenderType = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/calendar-type/?${stringified}`;

  return get(url);
};

export const setCalendarType = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/calendar-type/${values.id}/`
    : `${baseUrl}/core/calendar-type/`;

  const body = {
    ...values,
  };
  return Newpost(url, body, method);
};

export const deleteCalendarType = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/calendar-type/${visit_id}/`;
  return deleteFetch2(url);
};

export const changePassword = async (body) => {
  const baseUrl = getBaseUrl()
  const user_id = getUserId()
  const method = PUT;
  const url = `${baseUrl}/users/${user_id}/reset/`

  return await post(url, body, method)
}
export const verifyToken = async (body) => {
  const baseUrl = getBaseUrl()
  const method = POST;
  const url = `${baseUrl}/users/accountDeactivate/verifyToken/`

  return await post(url, body, method)
}

export const deleteAccount = async (body) => {
  const baseUrl = getBaseUrl()
  const method = PATCH;
  const url = `${baseUrl}/users/accountDeactivate/userDeactivate/`

  return await post(url, body, method)
}

export const getJobDepartment = (values, limitPerPage) => {
  if (!values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/core/job-category/?${stringified}`;

  return get(url);
};

export const setJobDepartmentApi = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/core/job-category/${values.id}/`
    : `${baseUrl}/core/job-category/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteJobDepartment = async visit_id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/job-category/${visit_id}/`;

  return deleteFetch2(url);
};
export const getEmployeeCalendarType = async values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.branch_id) {
    toParse.branch_id = values.branch_id;
  }
  if (values.calendarType) {
    toParse.calendarType = values.calendarType;
  }
  if (values.applicable_date_from) {
    toParse.applicable_date_from = values.applicable_date_from;
  }
  if (values.applicable_date_to) {
    toParse.applicable_date_to = values.applicable_date_to;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/employeesForAbsenceCreate/?${stringified}`;

  return await get(url);
};

export const updateEmployeeCalendarType = async values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/branch-absence-calendar/branch-absence-calendar-overview/updateAbsenceInEmployees/`;

  const body = {
    ...values,
  };
  return await Newpost(url, body, method);
};

export const getVisitReminder = async (branch) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  stringified += `branch_id=${branch}`
  const url = `${baseUrl}/core/visit-reminder/?${stringified}`;

  return await get(url);
}

export const setVisitReminder = async (values, editMode) => {
  const baseUrl = getBaseUrl();

  const method = editMode ? PATCH : POST;
  const url = editMode
    ? `${baseUrl}/core/visit-reminder/reminder_update/`
    : `${baseUrl}/core/visit-reminder/`;

  const body = {
    ...values,
  };
  return await Newpost(url, body, method);
};



export const getLoginRestriction = async () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/common_core/login-restriction/`;

  return await get(url);
}

export const setLoginRestriction = async (body, id) => {
  const baseUrl = getBaseUrl();

  const method = PATCH;
  const url = `${baseUrl}/common_core/login-restriction/${id}/`

  return await Newpost(url, body, method);
};



export const setORGPassCode = async (body, id) => {
  const baseUrl = getBaseUrl();

  const method = id ? PATCH : POST;
  const url = id
    ? `${baseUrl}/core/report-email/${id}/`
    : `${baseUrl}/core/report-email/`

  return await Newpost(url, body, method);
  // http://127.0.0.1:8000/org/bill-test-org-1/api/v1/core/report-email/
}

export const getORGPassCode = async () => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/core/report-email/`

  return await get(url);
  // http://127.0.0.1:8000/org/bill-test-org-1/api/v1/core/report-email/
}

export const deleteORGPassCode = async (id) => {

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/report-email/${id}/`

  return deleteFetch(url);
  // http://127.0.0.1:8000/org/bill-test-org-1/api/v1/core/report-email/
}

export const resendShiftCronJobs = async () => {
  const baseUrl = getBaseUrlMicroServices();
  const method = POST;
  const url = `${baseUrl}/reports/scheduler-report`

  return await Newpost(url, {}, method);
  //http://127.0.0.1:8000/org/bill-test-org-1/api/v1/reports/scheduler-report
}