import { LG, MD } from 'shared/styles/constants/resolutions';
import styled from 'styled-components';

export const TimelineForInsights = styled.div`
  width: 100%;
  background: #fff;
  padding: 50px 25px;
  box-shadow: 0 4px 8px -5px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const InsightContainer = styled.div`
  padding-left: 12.5px;
  margin-left: 12.5px;
  border-left: solid 5px blue;
  position: relative;
  padding-bottom: 50px;
  display: flex;
  gap: 1rem;

  &&::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border: ${(props)=>`4px solid ${props.markerColor}`};
    background-color: #fff;
    border-radius: 100%;
    top: 25px;
    left: -11px;
  }
`;

export const InsightBody = styled.div`
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  background-color: ${(props)=> props.backgroundColor};
`;

export const InsightParagraph = styled.p`
  margin: 0;
`;


export const ToggleDiv = styled.div`
  display: flex;;
  .toggle-label {
    margin-left: 0.5%;
  }

  @media (max-width: ${MD}) {
    margin-bottom: 0;
    font-size: smaller;
  }
  @media (min-width: ${LG}) {
    margin-bottom: 0;
  }
`;
