import React, {useEffect, useState} from 'react';
import {useForm} from 'hooks/FormHook';
import {getVisitById} from 'utils/api/ClientApi';
import {ServiceVisit} from 'components/SharedComponents/FormSections';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {ModalContainer} from 'shared/styles/constants/tagsStyles';
import moment from 'moment';
import {pickFile} from 'utils/files';
import { pickBy } from 'lodash';

const VisitModal = ({
  clientId,
  serviceId,
  visitId,
  fullBind,
  photo = '',
  clientName = '',
  contractRef = '',
  choices,
}) => {

  const {values, setValues, useInput} = useForm({});
  const [dataVisit, setDataVisit] = useState({});
  const [loadingModal, setLoadingModal] = useState(true);
  const [serviceCategory, setServiceCategory] = useState('');
  const [name, setName] = useState('');
  const [pic, setPic] = useState('');
  const [dob, setDOB] = useState( );
  const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);
const [nokPhoto, setNokPhoto] = useState(null);
const [initials, setInitials] = useState();
const [intialsOfFirstName,setIntialsOfFirstName]=useState()
const [intialsOfLastName,setIntialsOfLastName]=useState()
  fullBind.title = 'Visit';

;


  if (!values.visits) {
    setValues({...values, visits:[]});
  }

  useEffect(() => {
    if (clientId && serviceId) {
      loadVisitById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitId]);

  useEffect(() => {
    if (dataVisit && choices && Object.keys(choices).length >= 1) {
      choices.service_category.forEach(({id, name}) => {
        if (dataVisit.service_category === id) {
          setServiceCategory(name);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVisit, choices]);

  const loadVisitById = async () => {
    const response = await getVisitById(clientId, serviceId, visitId);
    if (response.detail) {
      return;
    }
    if(response.employee_required){
          response.employee_required.map((emp,empIndex)=>{
            if(emp.skills){
              emp.skills.map((skill,skillIndex)=>{
               let name= choices.skill && choices.skill.find(x => x.id === skill.skill).name
                response.employee_required[empIndex].skills[skillIndex]={value:skill.skill,label:name}
              })
            }
          }) 
    }
    values.visits.push(response);
    setDataVisit(response);
    if (response) {
      setLoadingModal(false);
    }

    setName(response.client_data.full_name);
    if(response.client_data.photo != null){
    setPic(response.client_data.photo);}
    else{
      const  name = response.client_data.full_name;
      const  arrName = name.split(" ");
      const  initialOfFirstName =  (name.charAt(0)).toUpperCase();
      const initialOfLastName = ( arrName[arrName.length - 1].charAt(0)).toUpperCase();
      // const initials = name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join(" ").toUpperCase();
      // setPic( initials )
    
      // setInitials(initials)
      setIntialsOfFirstName(initialOfFirstName)
      setIntialsOfLastName(initialOfLastName)
    }
setDOB( response.client_data.date_of_birth  )
   };

  if (Object.keys(dataVisit).length <= 0) {
    return null;
  }

  if (!choices || Object.keys(choices).length <= 0) {
    return null;
  }

 

  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'80%'}
        showRowInfo
        minWidthTitle="8.5rem"
        photoRow={pic}
      
        name={name || intialsOfFirstName}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: 'Name :',
            value: name,
          },
         
          {
            label: 'DOB:',
            value: dob
              ? `${moment(dob).format(
                  'DD-MM-YYYY'
                )} (${moment().diff(dob, 'years')})`
              : '',
          },
        
        ]}
        loading={loadingModal}>
        <div className={'m-3'}>
          <ServiceVisit
            choices={choices}
            values={values}
            useInput={useInput}
            nameSection={`visits[0]`}
            handleDeleteVisit={() => {}}
            index={0}
            dataVisit={dataVisit}
            dropDownHandleVisits={() => {}}
            setValues={setValues}
            toDeleteVisitSkill={[]}
            readOnly={true}
            setDateError={() => {}}
            errors={[]}
            setToDeleteVisitSkill={() => {}}
          />
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default VisitModal;
