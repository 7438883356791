import { apiURL } from '../env';
import { methods } from 'utils/constants/api';
import { getToken, getTenant } from '../localStorage/token';
// import { getElSeg } from '@fullcalendar/react';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

export const attachImage = async (values, empId) => {
    const baseUrl = getBaseUrl();
    const method = POST;
    const url = `${baseUrl}/core/user-file/`;
    const formData = new FormData();
    for (let [key, value] of Object.entries(values)) {
    //   if (key === 'employeeJobDetailsBranchAccess') {
    //     formData.append(key, JSON.stringify(value));
    //   } else {
        if (value !== null) {
          formData.append(key, value);
        } else {
          formData.append(key, '');
        }
    //   }
  
    }
    const response = await fetch(url, {
      body: formData,
      headers: {
        Authorization: 'Token ' + getToken(),
      },
      method: method,
    });
    const json = await response.json();
  
    return { status: response.status, message: json };
  };