import Table from 'components/SharedComponents/Table';
import {
    Col,
    ColsGrouper,
    IconWrapper,
    Row,
} from 'components/SharedComponents/Table/styles';
import React from 'react';
import { useEffect } from 'react';
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { FiltersContainer, FiltersLeftGroup, Header, SearchAddContainer, SearchContainer, SearchIcon, Title } from 'shared/styles/constants/tagsStyles';
import {
    getEmailContentById,
    getSetingsEmailHistoryApi,
} from 'utils/api/SettingsApi';
import EmailModal from './Modal/EmailModal';
import { useModal } from 'hooks/ModalHook';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { email_setting_choices } from 'utils/choiceConstant';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useForm } from 'hooks/FormHook';
import Pagination from 'components/SharedComponents/Pagination';
import { EMAIL_SETTINGS, SELF_WEB_CREATE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { getPageToSearchParam } from 'shared/methods';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { EmploymentTypesContainer } from 'components/DashboardComponents/Navbar/styles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { PrimaryButton } from 'shared/styles/buttons';
import { GrSearch } from 'react-icons/gr';
import { Fade } from 'react-reveal';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { useRef } from 'react';

const headerColumns = [
    {
        label: 'EMAIL TO',
        name: 'send_to',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'EMAIL FROM',
        name: 'send_by',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'EMAIL SUBJECT',
        name: 'mail_subject',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'EMAIL SENT AT',
        name: 'mail_sent_at',
        status: 2,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
];

function EmailHistory(props) {
    const { contextChoices, handleGetChoices, contextPermissions, contextSubscription, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, setContextChoices } = useContext(AppContext);
    const { branchData } = useContext(BranchItemsContext);
    const { values, setValues } = useForm({ ordering: '-mail_sent_at' })
    const [emailHistoryRecord, setEmailHistoryRecord] = useState([]);
    const [emailHistoryContent, setEmailHistoryContent] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isModelLoading, setIsModelLoading] = useState(true);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    // Permissions states
    const [webReadPermission, setWebReadPermission] = useState(true)
    const [selfWebReadPermission, setSelfWebReadPermission] = useState(true)
    const [branch, setBranch] = useState([])
    const [branchFilter, setBranchFilter] = useState([]);
    const [showSearch, setShowSearch] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState("")

    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind: emailFullBind,
    } = useModal(false);

    useEffect(() => {
        handleGetChoices(email_setting_choices)
    }, [])

    useEffect(() => {
        const roles = contextPermissions;
        const rolePermissions = {};

        if (contextIsAdmin || contextIsSuperAdmin) {
            // All permissions allows
            setWebReadPermission(true);
            setSelfWebReadPermission(true)
        } else {
            if (roles.length) {
                roles.forEach(item => {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {}
                    rolePermissions[item.section.name][WEB_PERMISSION][WEB_READ_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] = item?.[SELF_WEB_UPDATE_PERMISSION]
                })

                if (rolePermissions[EMAIL_SETTINGS]) {
                    setWebReadPermission(rolePermissions[EMAIL_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION])
                }
            }
        }

    }, [contextPermissions, contextIsSuperAdmin, contextIsAdmin])

    useEffect(() => {
        values.limitperpage = PAGE_SIZE_RENDER;
        setValues({ ...values })
    }, [PAGE_SIZE_RENDER])

    const getEmailHistoryRecord = async () => {
        const pageToSearch = getPageToSearchParam(
            props.location.search,
            setCurrentPage,
        )
        try {
            const res = await getSetingsEmailHistoryApi({ ...branchData, ...values, ...pageToSearch });
            setEmailHistoryRecord(res);
            setPages(Math.ceil(res.count / values?.limitperpage));
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const findUserFromChoices = id => {
        const user = contextChoices?.user_list?.find(user => user.id === id);
        if (user) {
            return user.name;
        } else {
            return null;
        }
    };
    const emailHistoryContentById = async id => {
        setIsModelLoading(true)
        try {
            const res = await getEmailContentById(id)
            setEmailHistoryContent(res)
            setModalValue(true);
        }
        catch (error) {
            console.log(error)
        }
        setIsModelLoading(false)
    }

    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    useEffect(() => {

        getEmailHistoryRecord();

    }, [values?.limitperpage, branchData, props.location.search, values.ordering]);

    const handleSearch = item => {
        if (values.branch) {
            getEmailHistoryRecord();
        }
    };

    const setFilterBranch = item => {
        setBranchFilter([...item]);
    };

    const dropDownHandleSelect = item => {
        setValues({ ...values, ...item });
    };

    useEffect(() => {
        setBranch(contextChoices?.branch &&
            contextChoices?.branch.map((el) => {
                return { id: el && el.id, name: el && el.name }
            }))
    }, [contextChoices])

    const keyPressed = e => {
        setSearchKeyword(e.target.value)
        debounceOnchange.current(e.target.value);
    }
    const hitSearch = value => {
        values.search = value;
        getEmailHistoryRecord();
    };

    const debounce = (fn, d) => {
        let timer;
        return function (...args) {
            let context = this;
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args)
            }, d)
        }
    }
    const debounceOnchange = useRef(debounce(hitSearch, 300))
    return (
        <>
            <ComponentDynamic loading={isLoading} >
                <div>
                    <Title>Email History</Title>
                    <Header style={{ padding: 10 }}>
                        <FiltersContainer style={{ marginBottom: '4px' }}>
                            <FiltersLeftGroup>
                                <EmploymentTypesContainer className=" mr-2">
                                    <NoSelectAllDropdownFilter
                                        items={branch ? branch : []}
                                        setItemsSelected={items => {
                                            dropDownHandleSelect({ branch: items });
                                        }}
                                        itemsSelected={branchFilter}
                                        setSelectedFilter={setFilterBranch}
                                        placeHolder={'Branch'}
                                        isObject
                                        allOption
                                    />
                                </EmploymentTypesContainer>
                            </FiltersLeftGroup>
                            <PrimaryButton
                                type="button"
                                onClick={handleSearch}
                                style={{ marginLeft: '4px' }}>
                                <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
                            </PrimaryButton>
                        </FiltersContainer>
                        <SearchAddContainer className="d-flex align-items-center">
                            <SearchIcon
                                onClick={() => {
                                    setShowSearch(!showSearch);
                                }}>
                                <GrSearch />
                            </SearchIcon>

                            <SearchContainer show={showSearch}>
                                <Fade collapse when={showSearch}>
                                    <InputLabelTop
                                        no_internal_margin="true"
                                        type="text"
                                        id="search"
                                        label={'Search'}
                                        // {...useInput('search')}
                                        // onBlur={() => {
                                        //   search();
                                        // }}
                                        // onKeyPress={e => {
                                        //   keyPressed(e);
                                        // }}
                                        onChange={e => {
                                            keyPressed(e);
                                        }}
                                        value={searchKeyword}
                                    />
                                </Fade>
                            </SearchContainer>
                        </SearchAddContainer>
                    </Header>
                    {webReadPermission ? emailHistoryRecord?.results?.length ?
                        <>
                            <Table
                                headerColumns={headerColumns}
                                callBackQuerySort={setQuerySort}
                                headerPadding={false}
                                noEditOption={true}
                            >
                                <div>
                                    {emailHistoryRecord?.results?.map(item => {
                                        const sentAt = `${item?.mail_sent_at?.slice(0, 10)} ${item?.mail_sent_at?.slice(11, 16)}`;
                                        return (

                                            <Row key={Math.random()} bgColor style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                <ColsGrouper className="d-flex justify-content-start">
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{item?.send_to}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{findUserFromChoices(item.send_by)}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{item?.mail_subject}</label>
                                                    </Col>
                                                    <Col
                                                        style={{ marginRight: '10px' }}
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden me-3"
                                                        overlap={true}>
                                                        <label>{sentAt}</label>
                                                    </Col>

                                                    <Col
                                                        className="sm-hidden"
                                                        //max_width={SMALL_COL}
                                                        style={{ marginRight: '10px' }}
                                                        Center
                                                        Shrink
                                                        NoFlexGrow>
                                                        <div className=" mt-1 me-2">
                                                            <button
                                                                onClick={() => emailHistoryContentById(item?.id)}
                                                                type="button"
                                                                className="btn text-primary text-bold  h6 border border-primary  rounded  btn-sm  ">
                                                                Preview
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </ColsGrouper>
                                            </Row>

                                        );
                                    })}
                                </div>
                            </Table>
                            <div className="mt-3">
                                <Pagination
                                    totalPages={pages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    values={values}
                                    dropDownHandleSelect={dropDownHandleSelectPagination}
                                    number={PAGE_SIZE_RENDER}
                                    dataLength={emailHistoryRecord?.results?.length}
                                />
                            </div></> :
                        <div className='d-flex align-items-center justify-content-center w-100'>
                            <h4 className='text-danger' style={{ fontSize: "1rem" }}>No record found !</h4>
                        </div> : <div className='d-flex align-items-center justify-content-center w-100'>
                        <h4 className='text-danger' style={{ fontSize: "1rem" }}>No permissions given for mail history record access !</h4>
                    </div>
                    }

                    {modalValue && <EmailModal
                        fullBind={emailFullBind}
                        findUserFromChoices={findUserFromChoices}
                        setModalValue={setModalValue}
                        // modalValue={modalValue}
                        modelLoading={isModelLoading}
                        emailHistoryContent={emailHistoryContent}
                    />}

                </div>


            </ComponentDynamic>
        </>
    );
}

export default EmailHistory;
