import React from 'react'
import { useState } from 'react';
import { TabStyled } from 'shared/styles/constants/tagsStyles';
import { AboutMeTabsContainer } from './style';
import TabWraper from './Compents/TabWraper';
import { useForm } from 'hooks/FormHook';
import { getDynamicAboutMe, setDynmicAboutMe } from 'utils/api/ClientApi';
import { useEffect } from 'react';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { AppContext } from 'context';
import { ABOUT_ME, DAILY_ROUTINE, IDENTIFICATION_CHARACTERISTICS, INTEREST, LIFE_HISTORY, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, SUPPORT_NEED, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { useContext } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const tabsConfig = [
    {
        id: 1,
        label: "About Me",
        section: "about_me",
        apiKey: "about-me",
        content: [
            {
                id: 1,
                label: 'What is most important to me',
                value: "most_important",
            },
            {
                id: 2,
                label: 'People who are important to me',
                value: "important_people",
            },
            {
                id: 3,
                label: 'How I communicate and how to communicate with me',
                value: "communicate_how",
            },
            {
                id: 4,
                label: 'My wellness',
                value: "wellness",
            },
            {
                id: 5,
                label: 'What makes a good day',
                value: "makes_good_day",
            },
            {
                id: 6,
                label: 'What makes me happy (please do)',
                value: "makes_happy",
            },
            {
                id: 7,
                label: `What makes me unhappy (please don't)`,
                value: "makes_unhappy",
            },
            {
                id: 8,
                label: 'What makes a bad day',
                value: "makes_bad_day",
            },
            {
                id: 9,
                label: 'How and when to support me',
                value: "support_how",
            },
            {
                id: 10,
                label: 'Also worth knowing about me',
                value: "worth_knowing",
            },
            {
                id: 11,
                label: 'Supported to write this by',
                value: "supported_to_write",
            }
        ]
    },
    {
        id: 2,
        label: "Interests",
        section: "interest",
        apiKey: "interest",
        content: [{
            id: 1,
            label: 'Hobbies and Interests',
            value: "hobbies_and_interest",
        },
        {
            id: 2,
            label: 'Religious and/or spiritual preferences',
            value: "religious_spiritual_preferences",
        },
        {
            id: 3,
            label: 'Where I am from',
            value: "from_where",
        },
        {
            id: 4,
            label: 'What I like to talk about',
            value: "like_to_talk_about",
        },
        {
            id: 5,
            label: 'Food & Drink',
            value: "food_and_drink",
        },
        {
            id: 6,
            label: 'Favourite Items',
            value: "favourite_items",
        },
        {
            id: 7,
            label: 'TV programmes',
            value: "tv_programmes",
        },
        {
            id: 8,
            label: 'Activities',
            value: "activities",
        },
        {
            id: 9,
            label: 'Rooms',
            value: "rooms",
        },
        {
            id: 10,
            label: 'Others',
            value: "other",
        },
        ]
    },
    {
        id: 3,
        label: "Life History",
        section: "life_history",
        apiKey: "life-history",
        content: [
            {
                id: 1,
                label: 'Family and friends',
                value: "family_and_friends",
            },
            {
                id: 2,
                label: 'My childhood',
                value: "childhood",
            },
            {
                id: 3,
                label: 'My education',
                value: "education",
            },
            {
                id: 4,
                label: 'My occupation',
                value: "occupation",
            },
            {
                id: 5,
                label: 'My adulthood',
                value: "adulthood",
            },
            {
                id: 6,
                label: 'My favourite things',
                value: "favourite_things",
            }
        ]
    },
    {
        id: 4,
        label: "Daily Routine",
        section: "daily_routine",
        apiKey: "daily-routine",
        content: [
            {
                id: 1,
                label: 'Get up',
                value: "get_up",
            },
            {
                id: 2,
                label: 'Breakfast',
                value: "breakfast",
            },
            {
                id: 3,
                label: 'Lunch',
                value: "lunch",
            },
            {
                id: 4,
                label: 'Afternoon',
                value: "afternoon",
            },
            {
                id: 5,
                label: 'Teatime',
                value: "teatime",
            },
            {
                id: 6,
                label: 'Bedtime',
                value: "bedtime",
            },
        ]
    },
    {
        id: 5,
        label: "Support I Need",
        section: "support_need",
        apiKey: "support-need",
        content: [
            {
                id: 1,
                label: 'Personal Hygiene',
                value: "personal_hygiene",
            },
            {
                id: 2,
                label: 'Domestic Support',
                value: "domestic_support",
            },
            {
                id: 3,
                label: 'Shopping arrangements',
                value: "shopping_arrangements",
            },
            {
                id: 4,
                label: 'Night support',
                value: "night_support",
            },
            {
                id: 5,
                label: 'other',
                value: "other",
            },
        ]
    },
    {
        id: 6,
        label: "Identification Characteristics",
        section: "identification_characteristics",
        apiKey: "identification-characteristics",
        content: [
            {
                id: 1,
                label: 'My identification characteristics',
                value: "identification_characteristics",
            },
        ]
    }]

function AboutMeContainer({
    selectedClientId,
    props,
    name }) {
    const toast = useRef(null)
    const { values, setValues } = useForm({});
    const {
        contextPermissions,
        contextSubscription,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        loadingChoices
    } = useContext(AppContext);

    const [tabSelected, setTabSelected] = useState()
    const [textStatus, setTextStatus] = useState(SAVE)
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingModal, setLoadingModal] = useState(true);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [errorMessage, setErrorMessage] = useState('');

    const [permision, setPermision] = useState({
        about_me: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },
        interest: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },
        life_history: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },
        daily_routine: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },
        support_need: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },
        identification_characteristics: {
            readPermission: false,
            createPermission: false,
            updatePermission: false,
        },

    })

    useEffect(() => {
        const rolesPermissions = contextPermissions;
        let rolePermissions = {};

        if (rolesPermissions?.length > 0) {
            rolesPermissions.forEach(item => {
                if (item.section !== null) {
                    // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_READ_PERMISSION
                    ] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_READ_PERMISSION
                    ] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_CREATE_PERMISSION
                    ] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_CREATE_PERMISSION
                    ] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_DELETE_PERMISSION
                    ] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_UPDATE_PERMISSION
                    ] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_UPDATE_PERMISSION
                    ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_DELETE_PERMISSION
                    ] = item?.[SELF_WEB_DELETE_PERMISSION];
                }
            });
        }

        const roles = contextSubSectionPermissions;
        if (roles.length > 0) {
            roles.forEach(
                item => {
                    if (item.section !== null) {
                        rolePermissions[item.section.name] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_READ_PERMISSION
                        ] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_READ_PERMISSION
                        ] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_CREATE_PERMISSION
                        ] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_CREATE_PERMISSION
                        ] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_DELETE_PERMISSION
                        ] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_UPDATE_PERMISSION
                        ] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_UPDATE_PERMISSION
                        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_DELETE_PERMISSION
                        ] = item?.[SELF_WEB_DELETE_PERMISSION];
                    }
                }
            );
        }
        if (contextIsSuperAdmin || contextIsAdmin) {
            setPermision({
                about_me: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
                interest: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
                life_history: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
                daily_routine: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
                support_need: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
                identification_characteristics: {
                    readPermission: true,
                    createPermission: true,
                    updatePermission: true,
                },
            })

        } else {
            const getPermission = (key, type) => {
                if (rolePermissions[key]) {
                    switch (type) {
                        case WEB_READ_PERMISSION:
                            return (contextSubscription?.[key]?.[WEB_PERMISSION] &&
                                (rolePermissions[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
                            );
                        case WEB_CREATE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
                            );
                        case WEB_UPDATE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
                            )
                        case WEB_DELETE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
                            )
                        default:
                            break;
                    }

                }
            };
            setPermision({
                about_me: {
                    readPermission: getPermission(ABOUT_ME, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(ABOUT_ME, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(ABOUT_ME, WEB_UPDATE_PERMISSION) || false,
                },
                interest: {
                    readPermission: getPermission(INTEREST, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(INTEREST, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(INTEREST, WEB_UPDATE_PERMISSION) || false,
                },
                life_history: {
                    readPermission: getPermission(LIFE_HISTORY, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(LIFE_HISTORY, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(LIFE_HISTORY, WEB_UPDATE_PERMISSION) || false,
                },
                daily_routine: {
                    readPermission: getPermission(DAILY_ROUTINE, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(DAILY_ROUTINE, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(DAILY_ROUTINE, WEB_UPDATE_PERMISSION) || false,
                },
                support_need: {
                    readPermission: getPermission(SUPPORT_NEED, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(SUPPORT_NEED, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(SUPPORT_NEED, WEB_UPDATE_PERMISSION) || false,
                },
                identification_characteristics: {
                    readPermission: getPermission(IDENTIFICATION_CHARACTERISTICS, WEB_READ_PERMISSION) || false,
                    createPermission: getPermission(IDENTIFICATION_CHARACTERISTICS, WEB_CREATE_PERMISSION) || false,
                    updatePermission: getPermission(IDENTIFICATION_CHARACTERISTICS, WEB_UPDATE_PERMISSION) || false,
                },
            })
        }

    }, [contextIsSuperAdmin, contextIsAdmin, contextSubscription, contextPermissions])

    useEffect(() => {
        if (permision.about_me.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[0])
        } else if (permision.interest.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[1])
        } else if (permision.life_history.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[2])
        } else if (permision.daily_routine.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[3])
        } else if (permision.support_need.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[4])
        } else if (permision.identification_characteristics.readPermission) {
            !loadingModal && setLoadingModal(true)
            setTabSelected(tabsConfig[5])
        }
    }, [permision])

    const getAboutMeTabWiseData = async (tab) => {
        if (!selectedClientId || !tab.apiKey || !permision[tab?.section]?.readPermission) {
            return;
        }

        try {
            const response = await getDynamicAboutMe(selectedClientId, tab?.apiKey)
            console.log((response, "response"))
            if (response.status === 200) {
                values[tab.section] = response.data;
                setValues({ ...values })
                setLoadingModal(false)
            }
        } catch (error) {
            setLoadingModal(false)
        }
    }
    useEffect(() => {
        if (tabSelected)
            getAboutMeTabWiseData(tabSelected)
    }, [tabSelected])

    const handleSubmit = async () => {
        if (loadingSave) return;
        setLoadingSave(true)
        const body = { ...values[tabSelected.section], client: selectedClientId };

        try {
            const response = await setDynmicAboutMe(selectedClientId, tabSelected.apiKey, body)
            if (response.status == 200)
                onSuccess(response)
        } catch (error) {
            onError(error)
        }
    }
    const onSuccess = () => {
        getAboutMeTabWiseData(tabSelected)
        setButtonIcon(CHECK)
        setErrorMessage("")
        setTextStatus(SAVED)
        setLoadingSave(false)
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success`,
        });
    }
    const onError = () => {
        setButtonIcon(CROSS)
        setErrorMessage('Something went wrong!')
        setTextStatus(ERROR)
        setLoadingSave(false)
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: 'Something went wrong!',
        });
    }




    return (
        <ComponentDynamic loading={false}>
            <Toast ref={toast}></Toast>
            {/* About Me Tabs */}
            <AboutMeTabsContainer>
                {tabsConfig?.map(tab => {
                    if (permision[tab.section] && permision[tab.section].readPermission) {
                        return (
                            <TabStyled
                                key={tab.label}
                                active={tabSelected && tabSelected.id === tab.id}
                                onClick={() => {
                                    setTabSelected(tab);
                                    // getAboutMeTabWiseData(tab)
                                    setLoadingModal(true)
                                    setTextStatus(SAVE)
                                    setButtonIcon(FLOPPY)
                                }}>
                                {tab.label}
                            </TabStyled>
                        )
                    }
                })}
            </AboutMeTabsContainer>

            {/* About me content */}
            <div>
                <TabWraper
                    tabData={tabSelected}
                    setValues={setValues}
                    values={values}
                    textStatus={textStatus}
                    loadingSave={loadingSave}
                    loadingModal={loadingModal}
                    buttonIcon={buttonIcon}
                    errorMessage={errorMessage}
                    showError={false}
                    handleSubmit={handleSubmit}
                    permision={permision[tabSelected?.section]}
                />
                {/* <AboutMe>

                </AboutMe> */}

            </div>
        </ComponentDynamic>
    )
}

export default AboutMeContainer