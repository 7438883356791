import React, { useState, useEffect, useRef, useContext } from 'react';
import { getRateCard } from 'utils/api/SettingsApi';
import { useModal } from 'hooks/ModalHook';
import { AppContext } from "context"
import { useForm } from 'hooks/FormHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER, PAGE_SIZE_TWELVE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import { getCategoryPermission, getPermissions } from '../../../../../utils/localStorage/user';
import {
  RATE_CARD,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions'
import { getAdmin, getSuperAdmin } from "../../../../../utils/localStorage/token"
import { getStaff, getClient, getEmployee, getSubSectionPermissions } from '../../../../../utils/localStorage/user';
import CommonModelData from '../CommonModelData'
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const BillRate = (props) => {
  const {
    readPermissionBillRates,
    createPermissionBillRates,
    updatePermissionBillRates,
    deletePermissionBillRates
  } = props;
  const { values, setValues, useInput, setCheckRequires } = useForm({ limitperpage: PAGE_SIZE_RENDER });  //used useInput
  const { contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const [rateCard, setRateCard] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [updatePermissions, setUpdatePermissions] = useState(false);
  const [createPermissions, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [rateCardID, setRateCardID] = useState();
  const [loadingBillrateModal, setLoadingBillrateModal] = useState(true);
  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const fullNameCol = 'name';
  const history = useHistory();

  // useEffect(()=>{
  //   values.limitperpage =PAGE_SIZE_RENDER;
  //   setValues({...values})
  //   },[ ])

  const getBillRateType = () => {
    let temp = null;
    if (contextChoices.rate_card_type) {
      temp = contextChoices.rate_card_type.find(ratetype => ratetype.name == 'Bill Rates').id;
    }
    return temp;
  }

  const rateCardType = useMemo(() => getBillRateType(), [contextChoices.rate_card_type])

  // const rateCardType =  contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype=>ratetype.name =='Bill Rates' ).id ;

  //   useEffect(() => {
  //     if( contextChoices){
  // let rateCardType =  contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype=>ratetype.name =='Bill Rates' ).id;
  // setRateCardID(rateCardType);
  // if(rateCardType  ){ 
  //   loadRateCard(rateCardType);
  //   }
  //       }
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //     }, [
  //        contextChoices, 
  //       ]);
  console.log('rateCardType', rateCardType)

  useEffect(() => {
    if (rateCardType) {
      loadRateCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateCardType, props.location.search, values.ordering, values.limitperpage]);




  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };

  const loadRateCard = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    if (rateCardType) {
      getRateCard({ ...values, ...pageToSearch }, values.limitperpage, rateCardType).then(response => {
        if (!mountedRef.current) return null;
        if (response) {
          setLoadingBillrateModal(false);
        }
        setRateCard(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
      });
    }
  };

  useEffect(() => {
    setTitle('Add Rate Card');

    const roles = contextPermissions;

    let rolePermissions = {};

    // let rolePermissions = {};
    // const rolesPermissions = getSubSectionPermissions();

    //   if (rolesPermissions.length > 0) {
    //     rolesPermissions.forEach(item => {

    //       // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
    //         rolePermissions[item.section.name ] = {};
    //         rolePermissions[item.section.name][WEB_PERMISSION] = {};
    //         rolePermissions[item.section.name][WEB_PERMISSION][
    //           WEB_READ_PERMISSION
    //         ] = item?.[WEB_READ_PERMISSION];
    //         rolePermissions[item.section.name][WEB_PERMISSION][
    //           WEB_CREATE_PERMISSION
    //         ] = item?.[WEB_CREATE_PERMISSION];
    //         rolePermissions[item.section.name][WEB_PERMISSION][
    //           WEB_DELETE_PERMISSION
    //         ] = item?.[WEB_DELETE_PERMISSION];
    //         rolePermissions[item.section.name][WEB_PERMISSION][
    //           WEB_UPDATE_PERMISSION
    //         ] = item?.[WEB_UPDATE_PERMISSION];
    //       }
    //     // }
    //     );  
    //   }

    // if((filterAdmin == true) || (filterSuperAdmin ==true) ){
    //   setCreatePermissions(true) ;
    //   setDeletePermissions(true);
    //   setUpdatePermissions(true);
    //   setReadPermissions(true);

    // } else {
    //   if (roles.length > 0) {
    //     roles.forEach(item => {
    //       if (item?.section?.name === RATE_CARD) {
    //         rolePermissions[RATE_CARD] = {};
    //         rolePermissions[RATE_CARD][WEB_PERMISSION] = {};
    //         rolePermissions[RATE_CARD][WEB_PERMISSION][
    //           WEB_READ_PERMISSION
    //         ] = item?.[WEB_READ_PERMISSION];
    //         rolePermissions[RATE_CARD][WEB_PERMISSION][
    //           WEB_CREATE_PERMISSION
    //         ] = item?.[WEB_CREATE_PERMISSION];
    //         rolePermissions[RATE_CARD][WEB_PERMISSION][
    //           WEB_DELETE_PERMISSION
    //         ] = item?.[WEB_DELETE_PERMISSION];
    //         rolePermissions[RATE_CARD][WEB_PERMISSION][
    //           WEB_UPDATE_PERMISSION
    //         ] = item?.[WEB_UPDATE_PERMISSION];
    //       }
    //     });

    //   if(  rolePermissions[RATE_CARD] !== undefined){
    //   setCreatePermissions(
    //     rolePermissions[RATE_CARD][WEB_PERMISSION][
    //       WEB_CREATE_PERMISSION
    //     ]
    //   );
    //   setDeletePermissions(
    //     rolePermissions[RATE_CARD][WEB_PERMISSION][
    //       WEB_DELETE_PERMISSION
    //     ]
    //   );
    //   setUpdatePermissions(
    //     rolePermissions[RATE_CARD][WEB_PERMISSION][
    //       WEB_UPDATE_PERMISSION
    //     ]
    //   );

    //   setReadPermissions( 
    //     rolePermissions[RATE_CARD][WEB_PERMISSION][
    //       WEB_READ_PERMISSION
    //     ]
    //   );
    //   }  
    // }
    // }
  }, []);


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/rate-card/bill-rate",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  return (
    <div>
      <CommonModelData
        addRateCardName={'NEW BILL RATE  CARD'}
        listigData={rateCard}
        pages={pages}
        currentPage={currentPage}
        setQuerySort={setQuerySort}
        modalValue={modalValue}
        idSelected={idSelected}
        setModalValue={setModalValue}
        setRateCardModalValue={setModalValue}
        fullBind={fullBind}
        setTitle={setTitle}
        setIdSelected={setIdSelected}
        values={values}
        nameSection={'billValue'}
        useInput={useInput}
        loadRateCard={loadRateCard}
        setValues={setValues}
        setModalDeleteValue={setModalDeleteValue}
        setCheckRequires={setCheckRequires}
        bindModalDelete={bindModalDelete}
        addCard={'New Bill Rate Card'}
        editCard={'Edit Bill Rate Card'}
        copiedCard={'Copied Bill Rate Card'}
        formObjName={'payValue'}
        firstLayerAddButton={'ADD Rate Details'}
        insideFirstLayerAddButton={'ADD Rate '}
        rateCardType={rateCardType}
        setIdToDelete={setIdToDelete}
        idToDelete={idToDelete}
        setDeleteValue={setDeleteValue}
        deleteValue={deleteValue}
        dropDownHandleSelectPagination={dropDownHandleSelectPagination}
        loadingModal={loadingBillrateModal}
        readPermission={readPermissionBillRates}
        updatePermission={updatePermissionBillRates}
        createPermission={createPermissionBillRates}
        deletePermission={deletePermissionBillRates}
      />

    </div>
  );
};

export default BillRate;
