import React, { useEffect, useState, useRef } from 'react'
import { CalendarContainer } from "../../SharedComponents/TimelineMedicationCalendar/styles"
import FullCalendar from '@fullcalendar/react';
import timeLinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CALENDAR_DAILY, CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import moment from 'moment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { BsCheck } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import html2canvas from "html2canvas"

const HeavyPrint = () => {
    const getStatus = (status) => {
        switch (status) {
            case 'Fully Taken':
                return 'FT '
            case 'Not Needed':
                return 'NN '
            case 'Not Observed':
                return 'NO '
            case 'Not Reported':
                return 'NR '
            case 'Not Taken':
                return 'NT '
            case 'Self Administered':
                return 'SA '
            case 'Family Administered':
                return 'FA '
            case 'No Visit':
                return 'NV '
            case 'Partially Taken':
                return 'PT '
            default:
                return '';
        }
    }
    const viewClassNames = () => {
        window.dispatchEvent(new Event('resize'));
    };
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#6E6BFA',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#6E6BFA"
        },
    }));

    const handleDownloadImage = (shiftType) => {
        const input = document.querySelector('#full-calendar-container');
        if(!input) {
            console.log("CANNOT FIND CALENDAR TO EXPORT")
            return;
        }
        const current_date = moment().format('DD-MM-YYY');
        setTimeout(() => {
            html2canvas(input, { windowWidth: shiftType===CALENDAR_DAILY || shiftType===""? 3500:shiftType===CALENDAR_WEEKLY?1500:2800, scale: 0.85 })
                .then((canvas) => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `calendar_${current_date}.png`;
                    link.click();
                    localStorage.removeItem("heavy-print-temp-data")
                    window.close()
                })
        }, 1000)
    };

    const [shiftType, setShiftType] = useState("");
    const [events, setEvents] = useState([])
    const [resources, setResources] = useState([])
    const calendarRef = useRef();
    useEffect(() => {
        const myState = JSON.parse(localStorage.getItem("heavy-print-temp-data"))
        if (!myState) { console.log("INVALID PRINT DATA"); return; }
        if(myState.shiftType) {
            calendarRef.current.getApi().changeView(myState.shiftType);
        }
        setShiftType(myState.shiftType)
        setEvents(myState.events)
        setResources(myState.resources)
        handleDownloadImage(myState.shiftType);
    }, [])

    return (
        <CalendarContainer
            progress={false}
            reduceColumns={shiftType === CALENDAR_WEEKLY}
            minHeight={"calc(100vh - 8rem)"}
            overflow={"hidden"}
            id="full-calendar-container"
        >
            <FullCalendar
                schedulerLicenseKey={'0797593398-fcs-1672351783'}
                scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
                plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
                firstDay={1}
                height={"auto"}
                ref={calendarRef}
                headerToolbar={{
                    left: 'title',
                    center: '',
                    end: '',
                }}
                titleFormat={ // will produce something like "Tuesday, September 18, 2018"
                    (shiftType === CALENDAR_DAILY) ? {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                        weekday: 'short'
                    } : {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                    }
                }
                views={{
                    resourceTimelineDay: {
                        type: 'resourceTimelineDay',
                        buttonText: 'Daily',
                        slotDuration: '00:05:00',
                        eventMinWidth: 1,
                        slotMinWidth: 10
                    },
                    resourceTimelineWeekDays: {
                        type: 'resourceTimelineWeek',
                        duration: { weeks: 1 },
                        slotDuration: { days: 1 },
                        slotLabelFormat: [
                            { month: 'long', year: 'numeric' }, // top level of text
                            {
                                day: 'numeric',
                                weekday: 'short',
                                omitCommas: true,
                            },
                        ],
                        buttonText: 'Weekly',
                    },

                    resourceTimelineMonth: {
                        type: 'resourceTimelineMonth',
                        duration: { months: 1 },
                        slotDuration: { days: 1 },
                        slotLabelFormat: [
                            { month: 'long', year: 'numeric' }, // top level of text
                            {
                                day: 'numeric',
                                omitCommas: true,
                            },
                        ],
                        buttonText: 'Monthly',
                        slotMinWidth: 70
                    },
                }}
                slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                }}
                nextDayThreshold={'06:00'}
                initialView={CALENDAR_DAILY}
                resourceOrder="title employee"
                resourceAreaHeaderContent={'Medication Chart'}
                resourceAreaWidth={'20rem'}
                resourceAreaColumns={[
                    {
                        group: true,
                        field: 'client',
                        headerContent: 'Client'
                    },
                    {
                        field: 'med',
                        headerContent: 'Medication'
                    },
                    {
                        field: 'title',
                        headerContent: 'Doses'
                    }
                ]}
                resources={[...resources]}
                events={[...events]}
                themeSystem="bootstrap"
                viewClassNames={viewClassNames}
                lazyFetching={false}
                editable={false}
                timeZone={'none'}
                eventContent={function (args, createElement) {

                    if (args.event._def.extendedProps.icon) {
                        const icon = args.event._def.extendedProps.icon;
                        return (
                            <>
                                <BootstrapTooltip title={<>
                                    <div style={{ display: "flex" }}>
                                        <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                                            args.timeText ? args.timeText
                                                : moment.utc(args.event._instance.range.start).format("HH:mm")
                                        }</span>
                                        <span>{args.event._def.title}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                                            {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                                            {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                                            {args.event._def.extendedProps.visit_type}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                                            {args.event._def.extendedProps.service_category}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                                            {args.event._def.extendedProps.address}</div>
                                    </div>
                                    <div>
                                        <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                                            {args.event._def.extendedProps.zipcode}</div>
                                    </div>
                                </>
                                }
                                    arrow
                                >
                                    <div className='d-flex'>
                                        <div>
                                            <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                                            <span>{args.event._def.extendedProps.gender ?
                                                (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                                            }</span>
                                            <span>{args.event._def.extendedProps.priority_icon}</span>
                                            <span>{icon}</span>
                                            {args.event._def.extendedProps.isRegular && <span>
                                                R
                                            </span>}
                                            <span>{(args.event._def.title).substring(0, 15)}</span>
                                        </div>
                                        <div>
                                            <span>{args.event._def.extendedProps.assigned_by}</span>
                                            <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                                            <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>
                                        </div>
                                    </div>
                                </BootstrapTooltip>
                            </>
                        )
                    }
                    else if (args.event._def.extendedProps.typeVisit) {
                        return (
                            <>
                                <BootstrapTooltip title={<>
                                    <div>
                                        <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                                            args.timeText ? args.timeText
                                                : moment.utc(args.event._instance.range.start).format("HH:mm")
                                        }</span>
                                        <span>{args.event._def.title}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Earliest Start Time: '}</span>
                                            {args.event._def.extendedProps.earliest_start_time && args.event._def.extendedProps.earliest_start_time.slice(0, -3)}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Latest Start Time: '}</span>
                                            {args.event._def.extendedProps.latest_start_time && args.event._def.extendedProps.latest_start_time.slice(0, -3)}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div ><span style={{ fontWeight: "bold" }}>{'Visit Type: '}</span>
                                            {args.event._def.extendedProps.visit_type}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div><span style={{ fontWeight: "bold" }}>{'Service Category: '}</span>
                                            {args.event._def.extendedProps.service_category}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div><span style={{ fontWeight: "bold" }}>{'Address: '}</span>
                                            {args.event._def.extendedProps.address}</div>
                                    </div>
                                    <div>
                                        <div><span style={{ fontWeight: "bold" }}>{'Postal Code: '}</span>
                                            {args.event._def.extendedProps.zipcode}</div>
                                    </div>
                                </>
                                }
                                    arrow
                                >
                                    <div className='d-flex'>
                                        <div>
                                            <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                                            <span>{args.event._def.extendedProps.gender ?
                                                (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                                            }</span>
                                            <span>{args.event._def.extendedProps.priority_icon}</span>
                                            {args.event._def.extendedProps.isRegular && <span>
                                                R
                                            </span>}
                                            <span>{(args.event._def.title)}</span>
                                        </div>
                                        <div>
                                            <span>{args.event._def.extendedProps.assigned_by}</span>
                                            <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                                            <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>

                                        </div>
                                    </div>
                                </BootstrapTooltip>
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                <BootstrapTooltip title={<>
                                    <div>
                                        {/* <span style={{ fontWeight: "bold", marginRight: "3px" }}>{
                        args.timeText ? args.timeText
                          : moment.utc(args.event._instance.range.start).format("HH:mm")
                      }</span> */}
                                        {/* <span>{args.event._def.title}</span> */}
                                    </div>
                                    {args.event._def.extendedProps.medication_dose_time && args.event._def.extendedProps.medication_dose_time &&
                                        <div style={{ display: "flex" }}>
                                            <div ><span style={{ fontWeight: "bold" }}>{'Planned Time: '}</span>
                                                {args.event._def.extendedProps.medication_dose_time && args.event._def.extendedProps.medication_dose_time}</div>
                                        </div>}
                                    {args.event._def.extendedProps.actual_medication_dose_time && args.event._def.extendedProps.actual_medication_dose_time &&
                                        <div style={{ display: "flex" }}>
                                            <div ><span style={{ fontWeight: "bold" }}>{'Actual Time: '}</span>
                                                {args.event._def.extendedProps.actual_medication_dose_time && args.event._def.extendedProps.actual_medication_dose_time}</div>
                                        </div>}
                                    {args.event._def.extendedProps.medication_status_name &&
                                        <div style={{ display: "flex" }}>
                                            <div ><span style={{ fontWeight: "bold" }}>{'Medication Status: '}</span>
                                                {args.event._def.extendedProps.medication_status_name}</div>
                                        </div>}
                                    {/* <div style={{ display: "flex" }}>
                      <div><span style={{ fontWeight: "bold" }}>{'Reason: '}</span>
                        {args.event._def.extendedProps.service_category}</div>
                    </div> */}
                                    {args.event._def.extendedProps.review_comment &&
                                        <div style={{ display: "flex" }}>
                                            <div><span style={{ fontWeight: "bold" }}>{'Feedback: '}</span>
                                                {args.event._def.extendedProps.review_comment}</div>
                                        </div>}
                                    {args.event._def.extendedProps.employee_name && <div>
                                        <div><span style={{ fontWeight: "bold" }}>{'Attent By: '}</span>
                                            {args.event._def.extendedProps.employee_name}</div>
                                    </div>}

                                    <div>
                                        <div><span style={{ fontWeight: "bold" }}>{args.event._def.extendedProps.visitId ? 'Linked To Visit' : 'Not Linked To Visit'}</span>
                                        </div>
                                    </div>

                                    {/* <div>
                      <div><span style={{ fontWeight: "bold" }}>{'Last Administered TIme: '}</span>
                        {args.event._def.extendedProps.zipcode}</div>
                    </div> */}
                                </>
                                }
                                    arrow
                                >
                                    <div className='d-flex'>
                                        <div>
                                            {/* <span style={{ fontWeight: "bold", marginRight: "3px" }}>{args.timeText.replace(/ /g, '')}</span>
                      <span>{args.event._def.extendedProps.gender ?
                        (args.event._def.extendedProps.gender).substring(0, 1) : "A"
                      }</span>
                      <span>{args.event._def.extendedProps.priority_icon}</span> */}
                                            <span style={{ marginRight: "3px" }}>{args.event._def.extendedProps.visitId ? 'V' : ''}</span>
                                            {args.event._def.extendedProps.medication_status_name && <span>
                                                {getStatus(args.event._def.extendedProps.medication_status_name)}
                                            </span>}
                                            <span>{(args.event._def.title)}</span>
                                        </div>
                                        {/* <div>
                      <span>{args.event._def.extendedProps.assigned_by}</span>
                        <span>{args.event._def.extendedProps.is_published ? <BsCheck /> : ''}</span>
                        <span>{(!args.event._def.extendedProps.earliest_start_time && !args.event._def.extendedProps.latest_start_time) && <FiClock />}</span>

                      </div>  */}
                                    </div>

                                </BootstrapTooltip>
                            </>
                        )
                    }

                }
                }
            />
        </CalendarContainer>
    )
}

export default HeavyPrint