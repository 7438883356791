import {
    Col,
    ColsGrouper,
    IconWrapper,
    Row,
} from 'components/SharedComponents/Table/styles';
import Table from 'components/SharedComponents/Table';
import { useModal } from 'hooks/ModalHook';
import React from 'react'
import { IoIosBody } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PURPLE } from 'shared/styles/constants/colors';
import { EX_LARGE_COL, EX_SMALL_COL, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { useForm } from 'hooks/FormHook';
import { useState } from 'react';
import { addNewBodyPart, deleteVistBodyPart, getVisitWiseBodyParts, updateVistWiseBodyPart } from 'utils/api/SchedulerApi';
import { useEffect } from 'react';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Modal from './Modal';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import ConfirmModal from './DeleteMoal/ConfirmModal';
import { FaPlus } from 'react-icons/fa';
import Spinner from 'components/SharedComponents/Spinner';

const headerColumns = [
    {
        label: '',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_SMALL_COL,
    },
    {
        label: 'BODY PART',
        name: 'tittle',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'DESCRIPTION',
        name: 'description',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },

    {
        label: '',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_SMALL_COL,
    },

];

function BodyMap({ visitId, clientSelectedId, readPermissionBodyMap,
    createPermissionBodyMap,
    updatePermissionBodyMap,
    deletePermissionBodyMap }) {
    const {
        setValue: setModalBodyMap,
        value: modalBodyMap,
        fullBind: fullBindBodyMap,
    } = useModal(false);
    const toastRef = useRef(null)
    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ tempBodyMapData: {}, alert_body_part: [], backBodyParts: { tittle: [] }, frontBodyParts: { tittle: [] } });
    const { setValue: setModalDetachBodyMap, bind: bindModalDetachBodyMap } = useModal(false);
    const [backMode, setBackMode] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false)
    const [bodyParts, setBodyParts] = useState([])
    const [bodyPartId, setBodyPartId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [seletedMode , setSelectedMode] = useState("front")

    const handleModelOpen = () => {
        setIsUpdate(false)
        setModalBodyMap(true)
    }

    //Reset From
    const handleResetData = () => {
        setValues({
            ...values,
            tempBodyMapData: {
                description: "",
                tittle: ""
            },
            frontBodyParts: {
                tittle: [],
            },
            backBodyParts: {
                tittle: []
            }
        });
    }

    //Add new body part
    const addBodyParts = async () => {
        const body = {
            client: clientSelectedId,
            visit: visitId,
            tittle: values?.tempBodyMapData?.tittle,
            description: values?.tempBodyMapData?.description,
            is_back_mode: backMode
        }
        try {
            const res = await addNewBodyPart(body)
            if (res) {
                getBodyPartsList(visitId)
                handleResetData()
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${"Successfully added body part !"}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${"Somthing went wrong !"}`,
                });
            }
            setBackMode(false)
        }
        catch (error) {
            console.log(error)
        }
    }


    // Prefill data for edit
    const handleEditBodyPart = bodyPart => {
        if (bodyPart.is_back_mode) {
            setBackMode(true)
        } else {
            setBackMode(false)
        }
        // values.tempBodyMapData = {
        //     tittle: bodyPart.tittle,
        //     description: bodyPart.description
        // }

        // values.frontBodyParts.tittle = ["chest", "triceps", "front-deltoids"]
        // values.backBodyParts.tittle = bodyPart?.tittle?.split(",")
        // setValues(prevValues => ({
        //     ...prevValues,
        //     backBodyParts: {
        //       ...prevValues.backBodyParts,
        //       tittle: bodyPart?.tittle?.split(",")
        //     },
        //     frontBodyParts: {
        //         ...prevValues.frontBodyParts,
        //         tittle: bodyPart.tittle.split(",")
        //     }
        // }))


        setValues({
            ...values,
            tempBodyMapData: { description: bodyPart.description, tittle: bodyPart?.tittle },
            frontBodyParts: {
                tittle: bodyPart?.tittle?.split(","),
            },
            backBodyParts: {
                tittle: bodyPart?.tittle?.split(",")
            }
        });
        setModalBodyMap(true);

        console.log(bodyPart?.tittle?.split(","), "body parts")
    };

    //Get list of body parts
    const getBodyPartsList = async (id) => {
        try {
            const res = await getVisitWiseBodyParts(id)
            if (res?.status) {
                setBodyParts(res?.data)
                setLoading(false)
            }
        } catch (error) {
            console.log("ERROR", error)
        }
    }


    //Update body bart
    const updateBodyParts = async () => {
        const find = bodyParts.find(el => el.id == bodyPartId)
        const body = {
            client: clientSelectedId,
            visit: find?.visit,
            id: find?.id,
            tittle: values?.tempBodyMapData?.tittle,
            description: values?.tempBodyMapData?.description,
            is_back_mode: backMode
        }
        try {
            const res = await updateVistWiseBodyPart(body, find?.id)
            if (res) {
                getBodyPartsList(visitId);
                handleResetData();
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${"Successfully updated body part !"}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${"Somthing went wrong !"}`,
                });
            }
            setBackMode(false)
        } catch (error) {
            console.log("ERROR", error)
        }
    }
    //Delete body part
    const handleDeleteBodyPart = async (id) => {
        try {
            const res = await deleteVistBodyPart(id)
            if (res.status) {
                getBodyPartsList(visitId)
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res?.message}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${res?.message}`,
                });
            }
        } catch (error) {
            console.log("ERROR", error)
        }
        setOpenModal(false);
    };

    useEffect(() => {
        getBodyPartsList(visitId)
    }, [])

    useEffect(() => {
        if (!modalBodyMap) {
            setBackMode(false)
            // values.tempBodyMapData = {
            //     tittle: "",
            //     description: ""
            // }
            // setValues({ ...values });
            handleResetData()
        }
    }, [modalBodyMap])


    const capitalizeText = (text) => {
        if (text) {
            return `${text?.toString()[0]?.toUpperCase() + text?.slice(1)}`
        }
        return ""
    }
    return (
        <React.Fragment>

            <Toast ref={toastRef} position="top-right" />
            {loading ?
                <div style={{ marginTop: "300px" }}>
                    <Spinner />
                </div>
                :
                <>
                    {createPermissionBodyMap &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: "10px" }}>
                            <FaPlus color={PURPLE} size={40} onClick={handleModelOpen} />
                        </div>}
                    <div style={{
                        backgroundColor: "#f3f3f3",
                        padding: "10px",
                        maxHeight: "600px",
                        height: "20%",
                        overflow: "auto"
                    }}>
                        {bodyParts.length ?
                            <Table headerColumns={headerColumns}
                                //  callBackQuerySort={setQuerySort}
                                style={{ backgroundColor: "#f3f3f3" }}
                            >
                                <div>
                                    {bodyParts?.map((item, index) => {
                                        // const sentAt = `${item?.created_at_org?.slice(0, 10)} ${item?.created_at_org?.slice(11, 16)}`;

                                        return (
                                            <Row key={index} bgColor style={{ padding: '5px 0px', margin: "5px 0px" }}>

                                                <ColsGrouper
                                                    className="d-flex"
                                                    style={{ paddingLeft: '10px', }}>
                                                    <Col className="sm-hidden" max_width={EX_SMALL_COL} Center Icon Purple>

                                                        <IconWrapper style={{ color: PURPLE }}>
                                                            <IoIosBody />
                                                        </IconWrapper>

                                                    </Col>
                                                    <Col
                                                        max_width={EX_LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{capitalizeText(item?.tittle)}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={EX_LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{item?.description}</label>
                                                    </Col>
                                                </ColsGrouper>
                                                <Col Center Shrink NoFlexGrow>

                                                    {updatePermissionBodyMap &&
                                                        <IconWrapper
                                                            style={{ color: PURPLE }}
                                                            onClick={() => {
                                                                setBodyPartId(item?.id);
                                                                handleEditBodyPart(item);
                                                                setIsUpdate(true)
                                                            }}>
                                                            <MdEdit color={PURPLE} />
                                                        </IconWrapper>}
                                                    <Col className="sm-hidden" Center Icon Red>
                                                        {deletePermissionBodyMap &&
                                                            <IconWrapper
                                                                data-toggle="modal"
                                                                data-target="#exampleModal"
                                                                style={{ color: PURPLE, marginLeft: '0.5rem' }}
                                                                onClick={() => {
                                                                    setBodyPartId(item?.id);
                                                                    // setModalDetachBodyMap(true);
                                                                    setOpenModal(true)
                                                                }}>
                                                                <RiDeleteBinLine />
                                                            </IconWrapper>
                                                        }
                                                    </Col>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                            </Table> :
                            <div className='d-flex w-100 justify-content-center mt-2'>
                                <h4 style={{ fontSize: "1rem", margin: "20px 0" }}>No data found</h4>
                            </div>
                        }
                        {modalBodyMap ? (
                            <Modal
                                fullBind={fullBindBodyMap}
                                setModalBodyMap={setModalBodyMap}
                                useInput={useInput}
                                values={values}
                                setValues={setValues}
                                addBodyParts={addBodyParts}
                                updateBodyParts={updateBodyParts}
                                update={isUpdate}
                                // isClient={true}
                                setBackMode={setBackMode}
                                backMode={backMode}
                                setSelectedMode={setSelectedMode}
                                seletedMode={seletedMode}
                            />
                        ) : null}
                    </div>
                    <div style={{ maxWidth: "50%" }}>
                        <ConfirmModal
                            type="delete"
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            title="Warning"
                            width={"40%"}
                            max_width={'fit-Content'}
                            body={
                                'Are you sure you want to delete the row? '
                            }
                            onOk={() => {
                                handleDeleteBodyPart(bodyPartId)
                            }
                            }
                            onCancel={() => {
                                setOpenModal(false);
                            }}
                            okTitle={'DELETE'}
                            {...bindModalDetachBodyMap}
                        />
                    </div>

                </>
            }


        </React.Fragment>
    )
}

export default BodyMap