import { apiURL } from '../env';
import { get, post, deleteFetch } from './Api';
import queryString from 'query-string';
import { methods, unauthorized_status_code } from 'utils/constants/api';
import { getToken, clearToken, getTenant } from '../localStorage/token';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};
export const getTriage = (values, filterExcludeCheck, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  let dataParse = {};
  if (values.employeename) {
    let employeenameFilter = values.employeename.map(data => data.id);
    let stremployeenameFilter = employeenameFilter.join(',');
    filterParse.employeename = stremployeenameFilter;
  }
  if (values.employeeassigned) {
    let employeeassignedFilter = values.employeeassigned.map(data => data.id);
    let stremployeeassignedFilter = employeeassignedFilter.join(',');
    filterParse.employeeassigned = stremployeeassignedFilter;
  }

  if (values.severity) {
    let severityFilter = values.severity.map(data => data.id);
    let strseverityFilter = severityFilter.join(',');
    filterParse.severity = strseverityFilter;
  }

  if (values.alert_status) {
    let alertstatusFilter = values.alert_status.map(data => data.id);
    let stralertstatusFilter = alertstatusFilter.join(',');
    filterParse.alert_status = stralertstatusFilter;
  }

  if (values.type) {
    let typeFilter = values.type.map(data => data.id);
    let strtypeFilter = typeFilter.join(',');
    filterParse.type = strtypeFilter;
  }

  if (values.labels) {
    let labelsFilter = values.labels.map(data => data.id);
    let strlabelsFilter = labelsFilter.join(',');
    filterParse.labels = strlabelsFilter;
  }

  if (values.created_by) {
    let labelsFilter = values.created_by.map(data => data.id);
    let strlabelsFilter = labelsFilter.join(',');
    filterParse.created_by = strlabelsFilter;
  }

  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id);
    let strclientnameFilter = clientnameFilter.join(',');
    filterParse.clientname = strclientnameFilter;
  }

  if (values.employeename) {
    let employeenameFilter = values.employeename.map(data => data.id);
    let stremployeenameFilter = employeenameFilter.join(',');
    filterParse.employeename = stremployeenameFilter;
  }

  if (values.date_to) {
    filterParse.date_to = values.date_to + 'T' + '23:59:59';
  }

  if (values.date_from) {
    filterParse.date_from = values.date_from + 'T' + '00:00:00';
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.search) {
    toParse.search = values.search;
  }

  if (values.ordering) {
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  toParse.offset = values.offset;

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.employeename) {
    stringified += '&employee_assigned__in=' + filterParse.employeename;
  }

  if (filterParse.ordering) {
    stringified += '&ordering=' + filterParse.ordering;
  }

  if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&client__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&client__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.severity) {
    stringified += '&alert_severity__id__in=' + filterParse.severity;
  }

  if (filterParse.alert_status) {
    stringified += `${filterExcludeCheck.status ? '&alert_status__id__exclude=' : '&alert_status__id__in='}` + filterParse.alert_status;
  }

  if (filterParse.type) {
    stringified += '&alert_type__id__in=' + filterParse.type;
  }

  if (filterParse.labels) {
    stringified += '&alert_label__in=' + filterParse.labels;
  }

  if (filterParse.created_by) {
    stringified += '&created_by__id__in=' + filterParse.created_by;
  }

  if (filterParse.clientname) {
    stringified += '&client__id__in=' + filterParse.clientname;
  }

  if (filterParse.employeename) {
    stringified += '&employee_assigned__in=' + filterParse.employeename;
  }
  if (filterParse.employeeassigned) {
    stringified += '&alert_created_by__id__in=' + filterParse.employeeassigned;
  }

  if (filterParse.date_to) {
    stringified += '&created_at__lte=' + filterParse.date_to;
  }

  if (filterParse.date_from) {
    stringified += '&created_at__gte=' + filterParse.date_from;
  }

  const url = `${baseUrl}/alerts/?${stringified}`;

  return get(url);
};


export const getAllBradFordDetails = async () => {
  const baseUrl = getBaseUrl();
  //  http://127.0.0.1:8000/org/sonaliapptest/api/v1/core/bradford-factor/getBradfordFactorListRecord/?offset=0&limit=20
  const url = `${baseUrl}/core/bradford-factor/getBradfordFactorListRecord/?offset=0&limit=100`;
  console.log(url, "getAllBradFordDetails_URL")
  return get(url);
}

export const getAllBradFordDetailsbyId = async (id) => {
  const baseUrl = getBaseUrl();
  // get api by id  http://127.0.0.1:8000/org/sonaliapptest/api/v1/core/bradford-factor/getBradfordFactor/?bradford_factor=f66722c2-0d3f-4223-96cc-e2f4fddb7250
  const url = `${baseUrl}/core/bradford-factor/getBradfordFactor/?bradford_factor=${id} `
  console.log(url, "getAllBradFordDetailsBYId_URL")
  return get(url);
}


export const addBradFordFactor = async (body, edit) => {
  const baseUrl = getBaseUrl();
  const method = edit ? PATCH : POST;
  // http://127.0.0.1:8000/org/sonaliapptest/api/v1/core/bradford-factor/createBradFordFactor/
  // patch api http://127.0.0.1:8000/org/sonaliapptest/api/v1/core/bradford-factor/updateBradFordFactor/

  const url = edit ? `${baseUrl}/core/bradford-factor/updateBradFordFactor/` : `${baseUrl}/core/bradford-factor/createBradFordFactor/`;
  console.log(url, body, method, "addBradFordFactor_URL_BODY_METHOD", edit)
  return post(url, body, method);
}

export const deleteBradFord = async (id) => {
  const baseUrl = getBaseUrl();
  // delete api http://127.0.0.1:8000/org/sonaliapptest/api/v1/core/bradford-factor/deleteBradFordFactor/?bradford_factor=f66722c2-0d3f-4223-96cc-e2f4fddb7250
  const url = `${baseUrl}/core/bradford-factor/deleteBradFordFactor/?bradford_factor=${id} `;
  console.log(url, "deleteBradFord_URL")
  return deleteFetch(url);
}


export const getTriageClient = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  toParse.offset = values.offset;

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  if (filterParse.severity) {
    stringified += '&alert_severity__id__in=' + filterParse.severity;
  }

  if (filterParse.alert_status) {
    stringified += '&alert_status__id__in=' + filterParse.alert_status;
  }

  if (filterParse.type) {
    stringified += '&alert_type__id__in=' + filterParse.type;
  }

  if (filterParse.labels) {
    stringified += '&alert_label__in=' + filterParse.labels;
  }

  if (filterParse.created_by) {
    stringified += '&created_by__id__in=' + filterParse.created_by;
  }

  if (filterParse.clientname) {
    stringified += '&client__id__in=' + filterParse.clientname;
  }

  if (filterParse.employeename) {
    stringified += '&employee_assigned__in=' + filterParse.employeename;
  }
  if (filterParse.employeeassigned) {
    stringified += '&alert_created_by__id__in=' + filterParse.employeeassigned;
  }

  if (filterParse.date_to) {
    stringified += '&created_at__lte=' + filterParse.date_to;
  }

  if (filterParse.date_from) {
    stringified += '&created_at__gte=' + filterParse.date_from;
  }

  const url = `${baseUrl}/alerts/clientTriage/?${stringified}&client=${values.id}`;

  return get(url);
};
export const getTriageTeam = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  toParse.offset = values.offset;

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  if (filterParse.severity) {
    stringified += '&alert_severity__id__in=' + filterParse.severity;
  }

  if (filterParse.alert_status) {
    stringified += '&alert_status__id__in=' + filterParse.alert_status;
  }

  if (filterParse.type) {
    stringified += '&alert_type__id__in=' + filterParse.type;
  }

  if (filterParse.labels) {
    stringified += '&alert_label__in=' + filterParse.labels;
  }

  if (filterParse.created_by) {
    stringified += '&created_by__id__in=' + filterParse.created_by;
  }

  if (filterParse.clientname) {
    stringified += '&client__id__in=' + filterParse.clientname;
  }

  if (filterParse.employeename) {
    stringified += '&employee_assigned__in=' + filterParse.employeename;
  }
  if (filterParse.employeeassigned) {
    stringified += '&alert_created_by__id__in=' + filterParse.employeeassigned;
  }

  if (filterParse.date_to) {
    stringified += '&created_at__lte=' + filterParse.date_to;
  }

  if (filterParse.date_from) {
    stringified += '&created_at__gte=' + filterParse.date_from;
  }

  const url = `${baseUrl}/alerts/employeeTriage/?${stringified}&employee=${values.id} `;

  return get(url);
};
export const getClientVisit = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {}
  toParse.offset = values.offset;

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  if (values.branch) {
    let filterbranch = values.branch.map((item) => item.id);
    let strBranchFilter = filterbranch.join(',');
    filterParse.branch = strBranchFilter
  }

  if (values.clientname) {
    let filterClient = values.clientname.map((item) => item.id);
    let strClientFilter = filterClient.join(',');
    filterParse.clientname = strClientFilter
  }
  if (values.employee__id__in) {
    let filterEmployee = values.employee__id__in.map((item) => item.id);
    let strEmployeeFilter = filterEmployee.join(',');
    filterParse.employee__id__in = strEmployeeFilter
  }
  if (values.runroutes) {
    let filterRunRoutes = values.runroutes.map((item) => item.id);
    let strRunRoutesFilter = filterRunRoutes.join(',');
    filterParse.runroutes = strRunRoutesFilter
  }
  if (values.visitstatus) {
    let filterVisitStatus = values.visitstatus.map((item) => item.id);
    let strVisitStatus = filterVisitStatus.join(',');
    filterParse.visitstatus = strVisitStatus
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_to) {
    stringified += '&end_date__lte=' + values.date_to + 'T' + '23:59:59';
  }
  if (values.date_from) {
    stringified += '&start_date__gte=' + values.date_from + 'T' + '00:00:00'
  }
  if (filterParse.branch) {
    stringified += '&client_service_visit__client__branch__id__in=' + filterParse.branch
  }
  if (filterParse.clientname) {
    stringified += '&client_service_visit__client__id__in=' + filterParse.clientname
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }

  if (values.id) {
    stringified += '&client_service_visit__client__id__in=' + values.id
  }


  if (values.ordering) {
    stringified += '&ordering=' + values.ordering
  }
  if (filterParse.runroutes) {
    stringified += '&run_route=' + filterParse.runroutes
  }
  if (filterParse.visitstatus) {
    stringified += '&visit_status__id__in=' + filterParse.visitstatus
  }
  const url = `${baseUrl}/scheduler/client-visit/?${stringified}`;

  return get(url);
};
export const getSettingEvvList = (values, limitPerPage) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse ={}
  toParse.offset = values.offset;

  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  if (values.search) {
    toParse.search = values.search;
  }
  stringified = queryString.stringify(toParse) || '';

  if(values.branch__id__in){
    let strBranch = values.branch__id__in.map((item)=>item.id)
    let strFilterBranch = strBranch.join(",")
    filterParse.branch__id__in = strFilterBranch
  }
  if(values.ordering){
    stringified +='&ordering=' + values.ordering
  }
 
  if(filterParse.branch__id__in){
    stringified +='&branch__id__in=' + filterParse.branch__id__in
  }
const url = `${baseUrl}/scheduler/evv/settings/?${stringified}`;

  return get(url);
};
export const getTriageById = async idSelected => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/alerts/${idSelected}/`;
  return get(url);
};

export const setTriageApi = async (bodyData, iddata) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  for (let [key, value] of Object.entries(bodyData)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'employee_assigned' &&
      key !== 'alertImportantContact' &&
      key !== 'alert_label' &&
      key !== 'alert_body_part' &&
      key !== 'alert_comment' &&
      key !== 'alertTask'
      // && !key =='file_upload'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key == 'employee_assigned' ||
      key == 'alertImportantContact' ||
      key == 'alert_label' ||
      key == 'alert_body_part' ||
      key == 'alert_comment' ||
      key == 'alertTask'
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const method = iddata ? PATCH : POST;
  const url = iddata ? `${baseUrl}/alerts/${iddata}/` : `${baseUrl}/alerts/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return json;
};

export const deleteTriageRow = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/alerts/${id}/`;
  return deleteFetch(url);
};

export const getModifiedVisitsId = async (pageClientID, startDate) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let filterParse = {};

  if (pageClientID) {
    filterParse.pageClientID = pageClientID;
  }

  if (startDate) {
    filterParse.startDate = startDate;
  }

  if (filterParse.pageClientID) {
    stringified += 'client=' + filterParse.pageClientID;
  }

  if (filterParse.startDate) {
    stringified += '&date=' + filterParse.startDate;
  }

  const url = `${baseUrl}/clients/client-visit-filter/client/get_visit_date_wise/?${stringified}`;

  return get(url);
};
export const intantNotificationData = notificationId => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const body = { ...notificationId };
  const url = `${baseUrl}/alerts/notification/rule/single_notification/`;
  return post(url, body, method);
};
export const getVisitsId = async (timeRageID, startDate) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let filterParse = {};

  if (timeRageID) {
    filterParse.timeRageID = timeRageID;
  }

  if (startDate) {
    filterParse.startDate = startDate;
  }

  if (filterParse.timeRageID) {
    stringified += 'csv_id=' + filterParse.timeRageID;
  }

  if (filterParse.startDate) {
    stringified += '&start_date=' + filterParse.startDate;
  }

  const url = `${baseUrl}/clients/visit-filter-date?${stringified}`;

  return get(url);
};
export const getClientCareContact = async values => {
  const baseUrl = getBaseUrl();
  let stringified = '';

  if (values.client) {
    stringified += 'client=' + values.client;
  }

  const url = `${baseUrl}/clients/client/contact-details/getClientCareContact/?${stringified}`;

  return get(url);
};
export const bulkUpdateTriageApi = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/alerts/bulk_update/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const AddBulkNotification = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/alerts/notification/rule/bulk_notification/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const getNotificationData = async idSelected => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/alerts/notification/rule/?alert=${idSelected}`;
  return get(url);
};

export const setNotificaationRule = (values, valuesId) => {
  const baseUrl = getBaseUrl();
  const method = valuesId ? PATCH : POST;
  const url = valuesId
    ? `${baseUrl}/alerts/notification/rule/${valuesId}/`
    : `${baseUrl}/alerts/notification/rule/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteNotificaationRule = async valuesId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/alerts/notification/rule/${valuesId}/`;
  return deleteFetch(url);
};


