import { apiURL, apiURLReplica, apiURLReport } from '../env';
import { get, deleteFetch, post } from './Api';
import queryString from 'query-string';
import { methods } from 'utils/constants/api';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getToken, getTenant } from '../localStorage/token';
import _ from 'lodash';


const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

const getBaseUrl_V2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

//get

const getBaseUrlMicroServices = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};

const getBaseUrlMicroServices_V2 = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v2`;
};


const getBaseUrlReport = () => {
  let tenant = getTenant();
  return `${apiURLReport}/${tenant}/api/v1`;
};

const getBaseUrlReportV2 = () => {
  let tenant = getTenant();
  return `${apiURLReport}/${tenant}/api/v2`;
};

export const getBillingSummary = (values) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_service_visit__client__id__in) {
    let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }

  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }

  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id)
    let strclientnameFilter = clientnameFilter.join(",")
    filterParse.clientname = strclientnameFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.bill_pattern) {
    let bill_patternFilter = values.bill_pattern.map(data => data.id)
    let strbill_patternFilter = bill_patternFilter.join(",")
    filterParse.bill_pattern = strbill_patternFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';



  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + filterParse.client_service_visit__client__id__in
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  // if (filterParse.funder_name) {
  //   stringified += '&visit__client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  // }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.bill_pattern) {
    stringified += '&bill_pattern=' + filterParse.bill_pattern
  }
  const url = `${baseUrl}/scheduler/finance-bill/?${stringified}`;
  return get(url);
};
export const billSummaryExport = async (values, column_selector) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_service_visit__client__id__in) {
    let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let employee_statusFilter = branch_values.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = stremployee_statusFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.bill_pattern) {
    let bill_patternFilter = values.bill_pattern.map(data => data.id)
    let strbill_patternFilter = bill_patternFilter.join(",")
    filterParse.bill_pattern = strbill_patternFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + filterParse.client_service_visit__client__id__in
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  // if (filterParse.funder_name) {
  //   stringified += '&visit__client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  // }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.bill_pattern) {
    stringified += '&bill_pattern=' + filterParse.bill_pattern
  }
  const url = `${baseUrl}/scheduler/finance-bill/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};
export const getPayrollSummary = (values) => {
  const baseUrl = getBaseUrl_V2();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;
  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }

  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let employee_statusFilter = branch_values.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = stremployee_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.pay_pattern) {
    let pay_patternFilter = values.pay_pattern.map(data => data.id)
    let strpay_patternFilter = pay_patternFilter.join(",")
    filterParse.pay_pattern = strpay_patternFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.pay_pattern) {
    stringified += '&pay_pattern=' + filterParse.pay_pattern
  }
  const url = `${baseUrl}/scheduler/finance-pay-summary/?${stringified}`;
  return get(url);
};
export const paySummaryExport = async (values, column_selector) => {
  const baseUrl = getBaseUrlReportV2();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.pay_pattern) {
    let pay_patternFilter = values.pay_pattern.map(data => data.id)
    let strpay_patternFilter = pay_patternFilter.join(",")
    filterParse.pay_pattern = strpay_patternFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.pay_pattern) {
    stringified += '&pay_pattern=' + filterParse.pay_pattern
  }
  const url = `${baseUrl}/scheduler/finance-pay-summary/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};
export const getBillingCompare = (values) => {

  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';

  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;
  }
  if (values.client_service_visit__client__id__in) {
    let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.service_category__id__in) {
    let client_statusFilter = values.service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }

  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id)
    let strclientnameFilter = clientnameFilter.join(",")
    filterParse.clientname = strclientnameFilter;
  }
  if (values.client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&start_date=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&end_date=' + values.date_to
  }
  if (values.kpi) {
    stringified += '&data_format=' + values.kpi
  }
  if (filterParse.client_status) {
    stringified += '&client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&client_service_visit__client_service__service_funders__category__id__in=' + filterParse.client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.client_service__service_funders__in) {
    stringified += '&client_service_visit__client__id=' + (filterParse.client_service__service_funders__in || '')
  }
  if (filterParse.funder_source) {
    stringified += '&client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
    console.log(stringified, "DDDD")
  }
  // if (filterParse.funder_name) {
  //   stringified += '&client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  //   console.log(stringified,"CCCCC")
  // }
  if (filterParse.visit_status) {
    stringified += '&visit_status__id__in=' + filterParse.visit_status
    console.log(stringified, "BBBB")
  }
  if (filterParse.service_category__id__in) {
    stringified += '&service_category__id__in=' + filterParse.service_category__id__in
    console.log(stringified, "AAAA")
  }
  if (filterParse.clientname) {
    stringified += '&client_service_visit__client__id__in=' + filterParse.clientname
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  const url = `${baseUrl}/scheduler/finance-bill-funder-return/?${stringified}`;
  return get(url);
};

// export const getBillingCompare = (values) => {

//   const baseUrl = getBaseUrl();
//   let toParse = {};
//   let filterParse = {};
//   let stringified = '';

//     toParse.offset = values.offset;
//     if (values.limitperpage) {
//       toParse.limit = values.limitperpage;
//     }
//     if (values.client_service_visit__client__id__in){
//       let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
//       let strclient_statusFilter = client_statusFilter.join(",")
//       filterParse.client_service_visit__client__id__in = strclient_statusFilter;
//     }
//     if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))){
//       let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
//       let visit_statusFilter = branch_values.map(data => data.id)
//       let strvisit_statusFilterFilter = visit_statusFilter.join(",")
//       filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
//     }
//     if (values.client_status){
//       let client_statusFilter = values.client_status.map(data => data.id)
//       let strclient_statusFilter = client_statusFilter.join(",")
//       filterParse.client_status = strclient_statusFilter;
//     }
//     if (values.service_category__id__in){
//       let client_statusFilter = values.service_category__id__in.map(data => data.id)
//       let strclient_statusFilter = client_statusFilter.join(",")
//       filterParse.service_category__id__in = strclient_statusFilter;
//     }
//     if (values.funder_source){
//       let funder_sourceFilter = values.funder_source.map(data => data.id)
//       let strfunder_sourceFilter = funder_sourceFilter.join(",")
//       filterParse.funder_source = strfunder_sourceFilter;
//     }
//     if (values.funder_name){
//       let funder_clientFilter = values.funder_name.map(data => data.id)
//       let strfunder_clientFilter = funder_clientFilter.join(",")
//       filterParse.funder_name = strfunder_clientFilter;
//     }
//     if (values.visit_status){
//       let visit_statusFilter = values.visit_status.map(data => data.id)
//       let strvisit_statusFilterFilter = visit_statusFilter.join(",")
//       filterParse.visit_status = strvisit_statusFilterFilter;
//     }

//   if (values.clientname){
//     let clientnameFilter = values.clientname.map(data => data.id)
//     let strclientnameFilter = clientnameFilter.join(",")
//     filterParse.clientname = strclientnameFilter;
//   }
//   if (values.client_service_visit__client_service__service_funders__category__id__in){
//     let client_statusFilter = values.client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
//     let strclient_statusFilter = client_statusFilter.join(",")
//     filterParse.client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
//   }
//   if (values.ordering) {
//     toParse.ordering = values.ordering;
//   }
//     stringified = queryString.stringify(toParse) || '';
//     if(values.date_from){
//       stringified+='&start_date__gte='+values.date_from+ " "+'00:00:00'
//     }
//     if(values.date_to){
//       stringified+='&end_date__lte='+values.date_to+ " "+'23:59:59'
//     }
//     if (filterParse.client_status) {
//       stringified += '&client_service_visit__client__status__id__in=' +filterParse.client_status
//     }
//     if (filterParse.funder_name) {
//       stringified += '&funder_name=' +filterParse.funder_name
//     }
//     if (filterParse.client_service_visit__client_service__service_funders__category__id__in) {
//       stringified += '&client_service_visit__client_service__service_funders__category__id__in=' +filterParse.client_service_visit__client_service__service_funders__category__id__in
//     }
//     if (filterParse.client_service__service_funders__in) {
//       stringified += '&client_service_visit__client__id=' + (filterParse.client_service__service_funders__in || '')
//     }
//     if (filterParse.funder_source) {
//       stringified += '&client_service_visit__client_service__service_funder__category__id__in=' +filterParse.funder_source
//       console.log(stringified,"DDDD")
//     }
//     // if (filterParse.funder_name) {
//     //   stringified += '&client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
//     //   console.log(stringified,"CCCCC")
//     // }
//     if (filterParse.visit_status) {
//       stringified += '&visit_status__id__in=' +filterParse.visit_status
//       console.log(stringified,"BBBB")
//     }
//     if (filterParse.service_category__id__in) {
//       stringified += '&service_category__id__in=' +filterParse.service_category__id__in
//       console.log(stringified,"AAAA")
//     }
//     if (filterParse.clientname) {
//       stringified += '&client_service_visit__client__id__in=' +filterParse.clientname
//     }
//     if (filterParse.employee__job_detail__branch__id) {
//       stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
//     }
//   const url = `${baseUrl}/scheduler/finance-bill-compare/?${stringified}`;
//   return get(url);
// };
export const billCompareExport = async (values, column_selector) => {
  const baseUrl = getBaseUrlMicroServices();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.client_service_visit__client__id__in) {
    let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.service_category__id__in) {
    let client_statusFilter = values.service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&start_date__gte=' + values.date_from + " " + '00:00:00'
  }
  if (values.date_to) {
    stringified += '&end_date__lte=' + values.date_to + " " + '23:59:59'
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  // if (filterParse.client_service_visit__client__id__in) {
  //   stringified += '&client_service_visit__client__id__in=' +filterParse.client_service_visit__client__id__in
  // }
  if (filterParse.client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&client_service_visit__client_service__service_funders__category__id__in=' + filterParse.client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.client_service_visit__client__id__in) {
    stringified += '&client_service_visit__client__id__in=' + (filterParse.client_service_visit__client__id__in || '')
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  // if (filterParse.funder_name) {
  //   stringified += '&visit__client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  // }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.service_category__id__in) {
    stringified += '&service_category__id__in=' + filterParse.service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  const url = `${baseUrl}/scheduler/finance-bill-compare/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};
export const billReturnExport = async (values, column_selector) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.client_service_visit__client__id__in) {
    let client_statusFilter = values.client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.service_category__id__in) {
    let client_statusFilter = values.service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&start_date=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&end_date=' + values.date_to
  }
  if (values.kpi) {
    stringified += '&data_format=' + values.kpi
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  // if (filterParse.client_service_visit__client__id__in) {
  //   stringified += '&client_service_visit__client__id__in=' +filterParse.client_service_visit__client__id__in
  // }
  if (filterParse.client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&client_service_visit__client_service__service_funders__category__id__in=' + filterParse.client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.client_service_visit__client__id__in) {
    stringified += '&client_service_visit__client__id__in=' + (filterParse.client_service_visit__client__id__in || '')
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  // if (filterParse.funder_name) {
  //   stringified += '&visit__client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  // }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.service_category__id__in) {
    stringified += '&service_category__id__in=' + filterParse.service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  const url = `${baseUrl}/scheduler/finance-bill-funder-return/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};
export const getBillingDetailsReimbursement = (values) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__client_service_visit__client__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id)
    let strclientnameFilter = clientnameFilter.join(",")
    filterParse.clientname = strclientnameFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&pay_adjustment_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&pay_adjustment_date__lte=' + values.date_to
  }
  if (filterParse.client_status) {
    stringified += '&client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.visit__client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + (filterParse.visit__client_service_visit__client__id__in || '')
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&client__branch__id__in=' + filterParse.employee__job_detail__branch__id
  }

  const url = `${baseUrl}/scheduler/finance-bill-reimbursement/?${stringified}`;
  return get(url);
};
export const billDetailExportReimbursement = async (values, tab, column_selector, format) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  let exportTypes = {}
  if (format) {
    exportTypes.is_pdf = true;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__client_service_visit__client__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id)
    let strclientnameFilter = clientnameFilter.join(",")
    filterParse.clientname = strclientnameFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&pay_adjustment_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&pay_adjustment_date__lte=' + values.date_to
  }
  if (filterParse.client_status) {
    stringified += '&client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.visit__client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + (filterParse.visit__client_service_visit__client__id__in || '')
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&client__branch__id__in=' + filterParse.employee__job_detail__branch__id
  }

  const url = `${baseUrl}/scheduler/finance-bill-reimbursement/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector, ...exportTypes }),
    headers: {
      // 'Content-Type': (exportTypes.is_all_client && exportTypes.is_pdf) ? 'application/zip' : (exportTypes.is_pdf ? 'application/pdf' :'application/json'),
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};
export const getBillingDetails = (values) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  let filterNoneFunderCat = '';
  let filterNoneFunderName = ''
  let filterNoneServiceCat = ''
  let filterNoneFunderRateCard = ''

  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__client_service_visit__client__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client__id__in = strclient_statusFilter;
  }
  // if (values.visit__service_category__id__in){
  //   let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
  //   let strclient_statusFilter = client_statusFilter.join(",")
  //   filterParse.visit__service_category__id__in = strclient_statusFilter;
  // }
  if (values.visit__service_category__id__in) {
    filterNoneServiceCat = values.visit__service_category__id__in.find((d) => d.id == 'none')
    let filterEmpRateCard = values.visit__service_category__id__in.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneFunderName)) {
      let strfunder_clientFilter = visit_statusFilter.join(",")
      filterParse.visit__service_category__id__in = strfunder_clientFilter;
    }
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.funder_name) {
    filterNoneFunderName = values.funder_name.find((d) => d.id == 'none')
    let filterEmpRateCard = values.funder_name.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneFunderName)) {
      let strfunder_clientFilter = visit_statusFilter.join(",")
      filterParse.funder_name = strfunder_clientFilter;
    }
  }
  // if (values.funder_name){
  //   let funder_clientFilter = values.funder_name.map(data => data.id)
  //   let strfunder_clientFilter = funder_clientFilter.join(",")
  //   filterParse.funder_name = strfunder_clientFilter;
  // }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.clientname) {
    let clientnameFilter = values.clientname.map(data => data.id)
    let strclientnameFilter = clientnameFilter.join(",")
    filterParse.clientname = strclientnameFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visit_statusFilter = values.evv_confirmation_status__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.evv_confirmation_status__in = strvisit_statusFilterFilter;
  }
  if (values.visit__contract_bill_rate_card__id__in) {
    filterNoneFunderRateCard = values.visit__contract_bill_rate_card__id__in.find((d) => d.id == 'none')
    let filterEmpRateCard = values.visit__contract_bill_rate_card__id__in.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneFunderName)) {
      let strfunder_clientFilter = visit_statusFilter.join(",")
      filterParse.visit__contract_bill_rate_card__id__in = strfunder_clientFilter;
    }
  }
  // if (values.visit__contract_bill_rate_card__id__in){
  //   let visit_statusFilter = values.visit__contract_bill_rate_card__id__in.map(data => data.id)
  //   let strvisit_statusFilterFilter = visit_statusFilter.join(",")
  //   filterParse.visit__contract_bill_rate_card__id__in = strvisit_statusFilterFilter;
  // }
  if (values.visit__bill_confirmation__in) {
    let visit_statusFilter = values.visit__bill_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__bill_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__employee_status__id__in) {
    let visit_statusFilter = values.employee__employee_status__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__employee_status__id__in = strvisit_statusFilterFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.travel_mode__in) {
    let visit_statusFilter = values.travel_mode__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_mode__in = strvisit_statusFilterFilter;
  }
  if (values.travel_code__in) {
    let visit_statusFilter = values.travel_code__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_code__in = strvisit_statusFilterFilter;
  }
  if (values.visit__travel_bill_confirmation__in) {
    let visit_statusFilter = values.visit__travel_bill_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__travel_bill_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    filterNoneFunderCat = values.visit__client_service_visit__client_service__service_funders__category__id__in.find((d) => d.id == 'none')
    let filterEmpRateCard = values.visit__client_service_visit__client_service__service_funders__category__id__in.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneFunderName)) {
      let strfunder_clientFilter = visit_statusFilter.join(",")
      filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strfunder_clientFilter;
    }
  }
  // if (values.visit__client_service_visit__client_service__service_funders__category__id__in){
  //   let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
  //   let strclient_statusFilter = client_statusFilter.join(",")
  //   filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  // }
  if (values.bill_pattern) {
    let bill_patternFilter = values.bill_pattern.map(data => data.id)
    let strbill_patternFilter = bill_patternFilter.join(",")
    filterParse.bill_pattern = strbill_patternFilter;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  
  //Min  filter keys
  if (values.schedule_bill_amount__lte) {
    toParse.schedule_bill_amount__lte = values.schedule_bill_amount__lte;
  }
    //Max filter keys
  if (values.schedule_bill_amount__gte) {
    toParse.schedule_bill_amount__gte = values.schedule_bill_amount__gte;
  }

  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterNoneFunderName) {
    stringified += '&null_funder_name=true'
  }
  if (filterNoneFunderCat) {
    stringified += '&null_funder_category=true'
  }
  if (filterNoneServiceCat) {
    stringified += '&null_service_category=true'
  }
  if (filterNoneFunderRateCard) {
    stringified += '&null_funder_rate_card=true'
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.visit__client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + (filterParse.visit__client_service_visit__client__id__in || '')
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  // if (filterParse.visit__client_service_visit__client__id__in) {
  //   stringified += '&funder_name=' +filterParse.funder_name
  // }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified += '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in
  }
  if (filterParse.visit__contract_bill_rate_card__id__in) {
    stringified += '&visit__contract_bill_rate_card__id__in=' + filterParse.visit__contract_bill_rate_card__id__in
  }
  if (filterParse.visit__bill_confirmation__in) {
    stringified += '&visit__bill_confirmation__in=' + `${filterParse.visit__bill_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__employee_status__id__in) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee__employee_status__id__in
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.travel_mode__in) {
    stringified += '&travel_mode__in=' + filterParse.travel_mode__in
  }
  if (filterParse.travel_code__in) {
    stringified += '&travel_code__in=' + filterParse.travel_code__in
  }
  if (filterParse.visit__travel_bill_confirmation__in) {
    stringified += '&visit__travel_bill_confirmation__in=' + `${filterParse.visit__travel_bill_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.bill_pattern) {
    stringified += '&bill_pattern_id__in=' + filterParse.bill_pattern
  }

  const url = `${baseUrl}/scheduler/finance-bill-details/?${stringified}`;
  return get(url);
};
export const billDetailExport = async (values, tab, column_selector, type, format) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  let exportTypes = {}
  //toParse.offset = values.offset;
  // if (values.limitperpage) {
  //   toParse.limit = values.limitperpage;

  // }
  if (type) {
    exportTypes.is_all_client = true;
  }
  if (format) {
    exportTypes.is_pdf = true;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.visit__client_service_visit__client__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client__id__in = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id)
    let strfunder_sourceFilter = funder_sourceFilter.join(",")
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.funder_name) {
    let funder_clientFilter = values.funder_name.map(data => data.id)
    let strfunder_clientFilter = funder_clientFilter.join(",")
    filterParse.funder_name = strfunder_clientFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visit_statusFilter = values.evv_confirmation_status__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.evv_confirmation_status__in = strvisit_statusFilterFilter;
  }
  if (values.visit__contract_bill_rate_card__id__in) {
    let visit_statusFilter = values.visit__contract_bill_rate_card__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__contract_bill_rate_card__id__in = strvisit_statusFilterFilter;
  }
  if (values.visit__bill_confirmation__in) {
    let visit_statusFilter = values.visit__bill_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__bill_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__employee_status__id__in) {
    let visit_statusFilter = values.employee__employee_status__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__employee_status__id__in = strvisit_statusFilterFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.travel_mode__in) {
    let visit_statusFilter = values.travel_mode__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_mode__in = strvisit_statusFilterFilter;
  }
  if (values.travel_code__in) {
    let visit_statusFilter = values.travel_code__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_code__in = strvisit_statusFilterFilter;
  }
  if (values.visit__travel_bill_confirmation__in) {
    let visit_statusFilter = values.visit__travel_bill_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__travel_bill_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.bill_pattern) {
    let bill_patternFilter = values.bill_pattern.map(data => data.id)
    let strbill_patternFilter = bill_patternFilter.join(",")
    filterParse.bill_pattern = strbill_patternFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (tab.is_visit) {
    stringified += '&is_visit=' + tab.is_visit
  }
  if (tab.is_travel_time) {
    stringified += '&is_travel=' + tab.is_travel_time
  }
  if (tab.is_distance_bill) {
    stringified += '&is_distance=' + tab.is_distance_bill
  }
  if (filterParse.client_status) {
    stringified += '&visit__client_service_visit__client__status__id__in=' + filterParse.client_status
  }
  if (filterParse.visit__client_service_visit__client__id__in) {
    stringified += '&visit__client_service_visit__client__id__in=' + (filterParse.visit__client_service_visit__client__id__in || '')
  }
  if (filterParse.funder_name) {
    stringified += '&funder_name=' + filterParse.funder_name
  }
  if (filterParse.funder_source) {
    stringified += '&visit__client_service_visit__client_service__service_funder__category__id__in=' + filterParse.funder_source
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  // if (filterParse.funder_name) {
  //   stringified += '&visit__client_service_visit__client_service__service_funder__id__in=' +filterParse.funder_name
  // }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified += '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in
  }
  if (filterParse.visit__contract_bill_rate_card__id__in) {
    stringified += '&visit__contract_bill_rate_card__id__in=' + filterParse.visit__contract_bill_rate_card__id__in
  }
  if (filterParse.visit__bill_confirmation__in) {
    stringified += '&visit__bill_confirmation__in=' + `${filterParse.visit__bill_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__employee_status__id__in) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee__employee_status__id__in
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.travel_mode__in) {
    stringified += '&travel_mode__in=' + filterParse.travel_mode__in
  }
  if (filterParse.travel_code__in) {
    stringified += '&travel_code__in=' + filterParse.travel_code__in
  }
  if (filterParse.visit__travel_bill_confirmation__in) {
    stringified += '&visit__travel_bill_confirmation__in=' + `${filterParse.visit__travel_bill_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.bill_pattern) {
    stringified += '&bill_pattern_id__in=' + filterParse.bill_pattern
  }

  const url = `${baseUrl}/scheduler/finance-bill-details/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector, ...exportTypes }),
    headers: {
      // 'Content-Type': (exportTypes.is_all_client && exportTypes.is_pdf) ? 'application/zip' : (exportTypes.is_pdf ? 'application/pdf' :'application/json'),
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};

export const getPayrollDetails = (values, visit_shift_type) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  let filterNoneEmpRateCard = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.employee__id__in) {
    let employeeFilter = values.employee__id__in.map(data => data.id)
    let stremployeeFilter = employeeFilter.join(",")
    filterParse.employee__id__in = stremployeeFilter;
  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visit_statusFilter = values.evv_confirmation_status__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.evv_confirmation_status__in = strvisit_statusFilterFilter;
  }
  if (values.visit__contract_bill_rate_card__id__in) {
    let visit_statusFilter = values.visit__contract_bill_rate_card__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__contract_bill_rate_card__id__in = strvisit_statusFilterFilter;
  }

  if(values.shift_types){
    let visit_statusFilter = values.shift_types.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.shift_types = strvisit_statusFilterFilter;

  }

  if (values.visit__schedule_pay_rate_card__id__in) {
    filterNoneEmpRateCard = values.visit__schedule_pay_rate_card__id__in.find((d) => d.id == 'none')
    let filterEmpRateCard = values.visit__schedule_pay_rate_card__id__in.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneEmpRateCard)) {
      let strvisit_statusFilterFilter = visit_statusFilter.join(",")
      filterParse.visit__schedule_pay_rate_card__id__in = strvisit_statusFilterFilter;
    }
  }
  if (values.visit__pay_confirmation__in) {
    let visit_statusFilter = values.visit__pay_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__pay_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__employee_status__id__in) {
    let visit_statusFilter = values.employee__employee_status__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__employee_status__id__in = strvisit_statusFilterFilter;
  }
  // if (values.employee__id__in){
  //   let visit_statusFilter = values.employee__id__in.map(data => data.id)
  //   let strvisit_statusFilterFilter = visit_statusFilter.join(",")
  //   filterParse.employee__id__in = strvisit_statusFilterFilter;
  // }
  if (values.travel_mode__in) {
    let visit_statusFilter = values.travel_mode__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_mode__in = strvisit_statusFilterFilter;
  }
  if (values.travel_code__in) {
    let visit_statusFilter = values.travel_code__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_code__in = strvisit_statusFilterFilter;
  }
  if (values.travel_pay_confirmation__in) {
    let visit_statusFilter = values.travel_pay_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_pay_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.pay_pattern) {
    let pay_patternFilter = values.pay_pattern.map(data => data.id)
    let strpay_patternFilter = pay_patternFilter.join(",")
    filterParse.pay_pattern = strpay_patternFilter;
  }
  if (values.shift_branches) {
    let pay_patternFilter = values.shift_branches.map(data => data.id)
    let strpay_patternFilter = pay_patternFilter.join(",")
    filterParse.shift_branches = strpay_patternFilter;
  }
  
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.schedule_pay_amount__lte) {
    toParse.schedule_pay_amount__lte = values.schedule_pay_amount__lte;
  }
  if (values.schedule_pay_amount__gte) {
    toParse.schedule_pay_amount__gte = values.schedule_pay_amount__gte;
  }
  if (values.schedule_bill_amount__lte) {
    toParse.schedule_bill_amount__lte = values.schedule_bill_amount__lte;
  }
  if (values.schedule_bill_amount__gte) {
    toParse.schedule_bill_amount__gte = values.schedule_bill_amount__gte;
  }
  


  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (visit_shift_type.is_visit) {
    stringified += '&is_visit=' + visit_shift_type.is_visit
  }
  if (visit_shift_type.is_oncall) {
    stringified += '&is_oncall=' + visit_shift_type.is_oncall
  }
  if (visit_shift_type.is_office) {
    stringified += '&is_office=' + visit_shift_type.is_office
  }
  if (visit_shift_type.is_absence) {
    stringified += '&is_absence=' + visit_shift_type.is_absence
  }
  if(visit_shift_type.is_shift){
    stringified += '&is_shift=' + visit_shift_type.is_shift
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified += '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in
  }
  if (filterParse.visit__schedule_pay_rate_card__id__in) {
    stringified += '&visit__schedule_pay_rate_card__id__in=' + filterParse.visit__schedule_pay_rate_card__id__in
  }
  if (filterNoneEmpRateCard) {
    stringified += '&null_employee_rate_card=true'

  }
  if(filterParse.shift_types){
    stringified += '&shift_types=' + filterParse.shift_types
  }
  if(filterParse.shift_branches){
    stringified += '&shift_branches=' + filterParse.shift_branches
  }
  
  if (filterParse.visit__contract_bill_rate_card__id__in) {
    stringified += '&visit__contract_bill_rate_card__id__in=' + filterParse.visit__contract_bill_rate_card__id__in
  }
  if (filterParse.visit__pay_confirmation__in) {
    stringified += '&visit__pay_confirmation__in=' + `${filterParse.visit__pay_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.travel_mode__in) {
    stringified += '&travel_mode__in=' + filterParse.travel_mode__in
  }
  if (filterParse.travel_code__in) {
    stringified += '&travel_code__in=' + filterParse.travel_code__in
  }
  if (filterParse.travel_pay_confirmation__in) {
    stringified += '&travel_pay_confirmation__in=' + `${filterParse.travel_pay_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.pay_pattern) {
    stringified += '&pay_pattern=' + filterParse.pay_pattern
  }
  const url = `${baseUrl}/scheduler/finance-pay-details/?${stringified}`;
  return get(url);
};
export const payDetailExport = async (values, tab, column_selector, type, format) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  let exportTypes = {}
  let filterNoneEmpRateCard = ''
  //toParse.offset = values.offset;
  // if (values.limitperpage) {
  //   toParse.limit = values.limitperpage;

  // }
  if (type) {
    exportTypes.is_all_employee = true;
  }
  if (format) {
    exportTypes.is_pdf = true;
  }
  if (values.employee__id__in) {
    let employeeFilter = values.employee__id__in.map(data => data.id)
    let stremployeeFilter = employeeFilter.join(",")
    filterParse.employee__id__in = stremployeeFilter;
  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visit_statusFilter = values.evv_confirmation_status__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.evv_confirmation_status__in = strvisit_statusFilterFilter;
  }
  if (values.visit__contract_bill_rate_card__id__in) {
    let visit_statusFilter = values.visit__contract_bill_rate_card__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__contract_bill_rate_card__id__in = strvisit_statusFilterFilter;
  }
  if (values.visit__schedule_pay_rate_card__id__in) {
    filterNoneEmpRateCard = values.visit__schedule_pay_rate_card__id__in.find((d) => d.id == 'none')
    let filterEmpRateCard = values.visit__schedule_pay_rate_card__id__in.filter((d) => (d.id !== 'none'))

    let visit_statusFilter = filterEmpRateCard.map(data => data.id)
    if (visit_statusFilter && !((visit_statusFilter.length == 1) && filterNoneEmpRateCard)) {
      let strvisit_statusFilterFilter = visit_statusFilter.join(",")
      filterParse.visit__schedule_pay_rate_card__id__in = strvisit_statusFilterFilter;
    }
  }
  if (values.visit__pay_confirmation__in) {
    let visit_statusFilter = values.visit__pay_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit__pay_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__employee_status__id__in) {
    let visit_statusFilter = values.employee__employee_status__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__employee_status__id__in = strvisit_statusFilterFilter;
  }
  if (values.employee__id__in) {
    let visit_statusFilter = values.employee__id__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__id__in = strvisit_statusFilterFilter;
  }
  if (values.travel_mode__in) {
    let visit_statusFilter = values.travel_mode__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_mode__in = strvisit_statusFilterFilter;
  }
  if (values.travel_code__in) {
    let visit_statusFilter = values.travel_code__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_code__in = strvisit_statusFilterFilter;
  }
  if (values.travel_pay_confirmation__in) {
    let visit_statusFilter = values.travel_pay_confirmation__in.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.travel_pay_confirmation__in = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.visit__client_service_visit__client_service__service_funders__category__id__in) {
    let client_statusFilter = values.visit__client_service_visit__client_service__service_funders__category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__client_service_visit__client_service__service_funders__category__id__in = strclient_statusFilter;
  }
  if (values.pay_pattern) {
    let pay_patternFilter = values.pay_pattern.map(data => data.id)
    let strpay_patternFilter = pay_patternFilter.join(",")
    filterParse.pay_pattern = strpay_patternFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (tab.is_visit) {
    stringified += '&is_visit=' + tab.is_visit
  }
  if (tab.is_oncall) {
    stringified += '&is_oncall=' + tab.is_oncall
  }
  if (tab.is_office) {
    stringified += '&is_office=' + tab.is_office
  }
  if (tab.is_absence) {
    stringified += '&is_absence=' + tab.is_absence
  }
  if(tab.is_shift){
    stringified += '&is_shift=' + tab.is_shift
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.visit__client_service_visit__client_service__service_funders__category__id__in) {
    stringified += '&visit__client_service_visit__client_service__service_funders__category__id__in=' + filterParse.visit__client_service_visit__client_service__service_funders__category__id__in
  }
  if (filterParse.employee__id__in) {
    stringified += '&employee__id__in=' + filterParse.employee__id__in
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified += '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in
  }
  if (filterParse.visit__schedule_pay_rate_card__id__in) {
    stringified += '&visit__schedule_pay_rate_card__id__in=' + filterParse.visit__schedule_pay_rate_card__id__in
  }
  if (filterNoneEmpRateCard) {
    stringified += '&null_employee_rate_card=true'
  }
  if (filterParse.visit__contract_bill_rate_card__id__in) {
    stringified += '&visit__contract_bill_rate_card__id__in=' + filterParse.visit__contract_bill_rate_card__id__in
  }
  if (filterParse.visit__pay_confirmation__in) {
    stringified += '&visit__pay_confirmation__in=' + `${filterParse.visit__pay_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.travel_mode__in) {
    stringified += '&travel_mode__in=' + filterParse.travel_mode__in
  }
  if (filterParse.travel_code__in) {
    stringified += '&travel_code__in=' + filterParse.travel_code__in
  }
  if (filterParse.travel_pay_confirmation__in) {
    stringified += '&travel_pay_confirmation__in=' + `${filterParse.travel_pay_confirmation__in == 'CONFIRMED' ? 'true' : 'false'}`
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  if (filterParse.pay_pattern) {
    stringified += '&pay_pattern=' + filterParse.pay_pattern
  }
  const url = `${baseUrl}/scheduler/finance-pay-details/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...column_selector, ...exportTypes }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};

export const getFinPayroll = (values) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  const url = `${baseUrl}/scheduler/finance-pay-roll/?${stringified}`;
  return get(url);
};

export const payrollExport = async (values) => {
  const baseUrl = getBaseUrlReport();
  let toParse = {};
  let filterParse = {};
  let stringified = '';
  toParse.offset = values.offset;
  if (values.limitperpage) {
    toParse.limit = values.limitperpage;

  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id)
    let stremployee_statusFilter = employee_statusFilter.join(",")
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (values.visit__service_category__id__in) {
    let client_statusFilter = values.visit__service_category__id__in.map(data => data.id)
    let strclient_statusFilter = client_statusFilter.join(",")
    filterParse.visit__service_category__id__in = strclient_statusFilter;
  }
  if (values.visit_status) {
    let visit_statusFilter = values.visit_status.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.visit_status = strvisit_statusFilterFilter;
  }
  if (values.employee__job_detail__branch__id || (values.headerbranch && (values.headerbranch.length > 0))) {
    let branch_values = (values.headerbranch && values.headerbranch.length > 0) ? values.headerbranch : values.employee__job_detail__branch__id;
    let visit_statusFilter = branch_values.map(data => data.id)
    let strvisit_statusFilterFilter = visit_statusFilter.join(",")
    filterParse.employee__job_detail__branch__id = strvisit_statusFilterFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&schedule_start_date__gte=' + values.date_from
  }
  if (values.date_to) {
    stringified += '&schedule_end_date__lte=' + values.date_to
  }
  if (filterParse.employee_status) {
    stringified += '&employee__employee_status__id__in=' + filterParse.employee_status
  }
  if (filterParse.visit_status) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visit_status
  }
  if (filterParse.visit__service_category__id__in) {
    stringified += '&visit__service_category__id__in=' + filterParse.visit__service_category__id__in
  }
  if (filterParse.employee__job_detail__branch__id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.employee__job_detail__branch__id
  }
  const url = `${baseUrl}/scheduler/finance-pay-roll/download/?${stringified}`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};

//post and patch

export const bulkActionPostBillingDetails = (values, billingKey) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/finance-bill-details/${billingKey}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
}

export const bulkActionPostInvocie = (values) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/finance-bill-details/bill/invoice/ready/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
}

export const bulkActionPostPayrollDetails = (values, payrollKey) => {
  const method = PATCH;
  const baseUrl = getBaseUrlMicroServices();
  const url = `${baseUrl}/scheduler/finance-bulk-pay-rate-update/${payrollKey}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
}