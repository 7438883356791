import { EX_SMALL_COL4, LARGE_COL } from "shared/styles/constants/columns"

export const myheaderColumns = [
  // Always visible start
  {
    label: 'Date',
    name: '',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'date',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Branch',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'branch',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Working Day',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'working_day',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Working Day 52 Weeks',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'working_day_52_weeks',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Total Working Hrs',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'total_working_hrs',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Total Working Hrs 52 Weeks',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'total_working_hrs_52_weeks',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Avg Working Hrs',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'avg_working_hrs',
    columnSelected: true,
    removeCusCol: false,
  },
  {
    label: 'Avg Working Hrs 52 Weeks',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'avg_working_hrs_52_weeks',
    columnSelected: true,
    removeCusCol: false,
  },

  // Always visible end

  //   visit_hours_scheduled

  {
    label: 'Visit Hrs Scheduled – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_scheduled_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Scheduled – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_scheduled_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Scheduled – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_scheduled_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Scheduled – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_scheduled_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Scheduled – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_scheduled_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },
  // visit_hours_actuals

  {
    label: 'Visit Hrs Actuals – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_actuals_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Actuals – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_actuals_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Actuals – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_actuals_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Actuals – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_actuals_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Actuals – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_actuals_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },

  // visit_hours_rounded_actuals  Rounded Actuals
  {
    label: 'Visit Hrs Rounded Actuals – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_rounded_actuals_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Rounded Actuals – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_rounded_actuals_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Rounded Actuals – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_rounded_actuals_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Rounded Actuals – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_rounded_actuals_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Rounded Actuals – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_rounded_actuals_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },

  // visit_hours_banded_actuals Banded Actuals
  {
    label: 'Visit Hrs Banded Actuals – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_banded_actuals_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Banded Actuals – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_banded_actuals_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Banded Actuals – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_banded_actuals_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Banded Actuals – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_banded_actuals_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Visit Hrs Banded Actuals – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'visit_hours_banded_actuals_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },

  // travel_hours_actuals Travel Hours
  {
    label: 'Travel Hrs Actuals – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_actuals_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Actuals – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_actuals_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Actuals – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_actuals_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Actuals – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_actuals_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Actuals – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_actuals_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },

  // travel_hours_scheduled  Scheduled
  {
    label: 'Travel Hrs Scheduled – Not Started',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_scheduled_not_started',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Scheduled – Completed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_scheduled_completed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Scheduled – In Progress',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_scheduled_in_progress',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Scheduled – Missed',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_scheduled_missed',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Travel Hrs Scheduled – Cancelled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'travel_hours_scheduled_cancelled',
    columnSelected: false,
    removeCusCol: false,
  },

  // office_hours
  {
    label: 'Office Hrs Actuals',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'office_hours_actuals',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Office Hrs Scheduled',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'office_hours_scheduled',
    columnSelected: false,
    removeCusCol: false,
  },

  // Estimated Accrual Leave
  {
    label: 'Estimated Accrual Leave',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'estimated_accrual_leave',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Estimated Accrual Leave 52 Weeks',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'estimated_accrual_leave_52_weeks',
    columnSelected: false,
    removeCusCol: false,
  },

 // Others
  {
    label: 'Actual On Call Hrs',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'actual_on_call_hrs',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Schedule On Call Hrs',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'schedule_on_call_hrs',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Schedule Office Travel Hrs',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'schedule_office_travel_hrs',
    columnSelected: false,
    removeCusCol: false,
  },

  {
    label: 'Capped Al Calculation',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'capped_al_calc',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Al Accrued',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'al_accrued',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Accrual Start Date',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'accrual_start_date',
    columnSelected: false,
    removeCusCol: false,
  },
  {
    label: 'Accrual End Date',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    custum_column: true,
    id: 'accrual_end_date',
    columnSelected: false,
    removeCusCol: false,
  }
]

