import React, {Fragment, useEffect, useState} from 'react';
import {RiCheckboxBlankCircleLine} from 'react-icons/ri';
import {GiPlainCircle} from 'react-icons/gi';
import {AiFillCheckCircle} from 'react-icons/ai';
import {MainContainer, ItemContainer, Indeterminate} from './styles';
import moment from 'moment';

const CustomProgressBar = ({
  currentStep = 0,
  items = ['Unassigned', 'Assigned', 'Optimized', 'Best'],
  loadingOptaPlanner = false,
  optaplannerStatus = [{state: '', tries: 0}],
  runningDate = '',
  actualDate = '',
}) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  const [animated, setAnimated] = useState(true);

  useEffect(() => {
    if (
      moment(runningDate).isBefore(actualDate) ||
      moment(runningDate).isAfter(actualDate)
    ) {
      setAnimated(false);
    } else {
      setAnimated(true);
    }
  }, [runningDate, actualDate]);

  const stepIcon = index => {
    let iconToReturn = <div />;

    if (step > index) {
      iconToReturn = <AiFillCheckCircle />;
    } else {
      iconToReturn = <RiCheckboxBlankCircleLine />;
    }

    if (step >= 3 && index === 2 && optaplannerStatus[0].tries >= 2) {
      let total_tries = optaplannerStatus[0].tries;
      if (total_tries > 9) {
        total_tries = 9;
      }

      iconToReturn = (
        <div className={'d-flex'}>
          <GiPlainCircle />
          <span className={'tries'}>{`x${total_tries}`}</span>
        </div>
      );
    } else if (step >= 3 && index === 2) {
      iconToReturn = <AiFillCheckCircle />;
    }

    return iconToReturn;
  };

  return (
    <MainContainer>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            <ItemContainer active={step > index ? true : false}>
              {stepIcon(index)}
              <label>{item}</label>
            </ItemContainer>

            {index + 1 < items.length ? (
              <Indeterminate
                done={step > index ? true : false}
                active={
                  step === index + 1 && loadingOptaPlanner && animated
                    ? true
                    : false
                }
              />
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </MainContainer>
  );
};

export default CustomProgressBar;
