module.exports = {
  P_W_Zero:'p_w_zero',
  L_R_PAD:'l_r_pad',
  EX_SMALL_COL: 'ex_small',
  EX_SMALL_COL4: 'ex_small4',
  SMALL_COL: 'small',
  MEDIUM_COL: 'medium',
  LARGE_COL: 'large',
  EX_LARGE_COL: 'ex_large',
  EX_VERY_LARGE_COL: 'ex_very_large',
  COL_CONTAIN_PARA:'col_contain_para',
  COL_CONTAIN_WORD:'col_contain_word',
  VERY_EX_SMALL_COL_CHECKBOX:'very_smal_checbox_col',
  EX_SMALL_COL_TOGGLE: 'ex_small_toggle',
  CHECK_BOX: "check_box"
};
