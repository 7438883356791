import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED,} from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {  algorithRuleUpdates,  setAlgorithmsDataById, } from 'utils/api/SettingsApi';
import ToggleButton from 'components/SharedComponents/ToggleButton'; 

const AddMoal = ({
  fullBind,
  idSelected,
  setTitle,
  loadAlgoScore,
  setAlgoClientModalValue,
}) => {

  const {
    values, setValues, useInput,isValid, errors,  setFormErrors,setCheckRequires
  } = useForm({  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
const [penalise, setPenalise] = useState();     
const [reward, setReward] = useState();
const [detectChangedPenalise, setDetectChangedPenalise] = useState(false);     
const [detectChangedReward, setDetectChangedReward] = useState(false);
const [penaliseNumber, setPenaliseNumber] = useState(false); 
const [rewardNumber, setRewardNumber] =useState(false);
const reset = 'Edit Financial';



  useEffect(() => {
    if (idSelected) {
      setTitle(reset);
      setLoadingModal(false);
    } 
    // else {
    //   setTitle(new_holiday);
      setLoadingModal(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);


useEffect(()=>{
  if (idSelected) {
    loadAlgorithmsDataById();
  }
},[])


  const loadAlgorithmsDataById = async() => {
    const response =await setAlgorithmsDataById(idSelected)
    if(response){
      values.id=response.id && response.id;   
      values.algorithm_rules = response.algorithm_rules && response.algorithm_rules;
      values.consider_hard_rule = response.consider_hard_rule && response.consider_hard_rule;
      values.min_time = response.min_time && response.min_time;
      values.max_time = response.max_time && response.max_time;
      values.constraints = response.score_level && response.score_level;
      values.reward = response.reward && response.reward;
      values.penalise = response.penalise && response.penalise;
      values.status=response.status && response.status;
      setPenalise(response.penalise)
      setReward(response.reward)
      setValues({...values});
    }
    setLoadingModal(false);   
  };

  const handleSubmit = async (evt) => {
      evt.preventDefault();
      if (loadingSave) {
        return;
      }
  
  if ((!isValid )  ) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage('InvalidOrEmptyValues');
        return;
      }

    if (textStatus==='SAVED') {    return; }
    setLoadingSave(true);
      const bodyData=(penalise!=='')?{
        id:values.id,
        status: values.status,
        score_level: values.constraints,
        // penalise:values.penalise ,
        penalise:penalise?penalise:null ,
        // reward:null,
        consider_hard_rule:values.consider_hard_rule,
        max_time: values.max_time?values.max_time:'',
        min_time: values.min_time?values.min_time:'',        
      }:{
        id:values.id,
        status: values.status,
        score_level: values.constraints,
        // reward:values.reward,
        // penalise:null ,
        consider_hard_rule:values.consider_hard_rule,
        reward:reward?reward:null,
        max_time: values.max_time?values.max_time:'',
        min_time: values.min_time?values.min_time:'',        
      }

     await  algorithRuleUpdates(bodyData)
     .then(res=>{
       if( res.status==='Successful'){ 
          onSuccess()
          setAlgoClientModalValue(false)
          }
  else{  onError()  }
     })
     .catch(onError)
  };
//above code  is for saving rate code  
  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    loadAlgoScore()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setShowError(true);
    setCheckRequires(true);
      setErrorMessage(
        "Some of the fields have invalid or empty values. Please review."
      );
  };

const dropDownHandleSelect = (item, nameItem) => {
  values[nameItem] = item[nameItem];
 setValues({...values})
};

const handleChangePenalise=(e)=>{

  if(e.target.value.length  <= 3){
  setPenalise(e.target.value);
  }

  if(e.target.value !==null){
    setReward('');
  }
  // setReward(null);
  setRewardNumber(true);
  setDetectChangedReward(true);
  if(e.target.value.length  >3){
    setDetectChangedPenalise(true);
  }else{
    setDetectChangedPenalise(false);
  }
}
   

const handleChangeReward=(e)=>{
  if(e.target.value.length  <= 3){
    setReward(e.target.value);
  }

  if(e.target.value !==null){
    setPenalise('')
  }
  // setPenalise(null)
      setPenaliseNumber(true);
      setDetectChangedPenalise(true);
if(e.target.value.length  >3){
  setDetectChangedReward(true);
}else{
  setDetectChangedReward(false);
}
}



  return (
    <ModalContainer max_width={'60%'}>      
      <ModalDynamic
        {...fullBind}
        max_width={'50%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="pl-3 pr-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100 mb-2 pb-1'}>
          <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
          <InputLabelTop
             disabled={true}
                label={'Algorith rules '}
                type="text"
                {...useInput(`algorithm_rules`)}
              />
            </FlexGrid3Item>
            {/* <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >
            <TimeSelector
            labelTop={'Min(hrs)'}
            timeFormat={"HH:mm"}
            dateFormat={"hh:mm"}
            {...useInput('min_time')}
            placeholderText={'hh:mm'}
            locale={"sv-sv"}
            timeCaption={'Duration'}
            />
            </FlexGrid3Item> */}
            {/* <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 "  >          
            <TimeSelector
            labelTop={'Max(hrs)'}
            timeFormat={"HH:mm"}
            dateFormat={"hh:mm"}
            {...useInput('max_time')}
            placeholderText={'hh:mm'}
            locale={"sv-sv"}
            timeCaption={'Duration'}
            />
            </FlexGrid3Item> */}
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >  
             <DropdownSearchable
              required
            {...useInput(  `constraints`,  'isRequired'  )}
            placeHolder={'Constraints'}
                options={[     
                  // {id: 'All', name: 'All'},
                  {id: 'Soft', name: 'Soft'},
                  {id: 'Medium', name: 'Medium'},
                  {id: 'Hard', name: 'Hard'},
                ]}
                selectedOption={  values ?  values.constraints  : null}
                onChange={value =>
                  dropDownHandleSelect( {constraints: value ? value : null},
                  'constraints', 'isRequired'   ) }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >          
               <InputLabelTop
             disabled={detectChangedReward}
              required
              label={'Reward'}
              type="text"
              // {...useInput(`reward`)}
              onChange={handleChangeReward}  
              value={reward}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >  
            <InputLabelTop
              disabled={detectChangedPenalise}
             required
              label={'Penalise'}
             type="text"
            //  {...useInput(`penalise`)}
            onChange={handleChangePenalise}  
            // value={penalise}
            value={penalise}
              />      
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" >
              <ToggleButton
                       label={'unassigned violated'}
                    useInput={useInput('consider_hard_rule')}
                    id="consider_hard_rule"
               />    
          </FlexGrid3Item>
            </FlexGrid3Container >
          
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2 mt-1'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMoal;
