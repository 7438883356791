import React from "react"
export const setTag = (val) => {

    switch (val.tag) {
        case "h1":
            return <h1 style={{ color: "#000" }}>{val.label}</h1>
        case "h2":
            return <h2 style={{ color: "#000" }}>{val.label}</h2>
        case "h3":
            return <h3 style={{ color: "#000" }}>{val.label}</h3>
        case "h4":
            return <h4 style={{ color: "#000" }}>{val.label}</h4>
        case "h5":
            return <h5 style={{ color: "#000" }}>{val.label}</h5>
        case "h6":
            return <h6 style={{ color: "#000" }}>{val.label}</h6>
    }
}