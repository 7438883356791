import React from 'react';
import DatePicker from 'react-datepicker';
import {DatePickerStyled, ErrorLabel, Icon} from './styles';
import {FaRegCalendar} from 'react-icons/fa';
import {createDateWithTime, isDateValid} from 'shared/methods';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import 'react-datepicker/dist/react-datepicker.css';

function DDMMDateSelector(props) {
  const nr_years = 3;
  let maxYearDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + nr_years),
  );

  const _minDate = props.minDate
    ? createDateWithTime(props.minDate, '00:00:00')
    : '';
    const _maxDate = props.maxDate
    ? createDateWithTime(props.maxDate, '00:00:00')
    : '';
  let selectedValue = null;
  if (props.value) {
    selectedValue = new Date(props.value + 'T00:00:00');
  }

  return (
    <DatePickerStyled typeFilter={props.typeFilter}>
      <label>
        {props.labelTop} <span>{props.isRequired ? '*' : ''}</span>
      </label>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 0.5rem"
            }}
          >
            <button style={{
              outline: "inherit", border: "none", fontSize: "1rem"
            }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <MdKeyboardArrowLeft />
            </button>
            <span style={{
              fontWeight: "600"
            }}>
              {new Date(date).toLocaleString('default', { month: 'long' })}
            </span>
            <button style={{
              outline: "inherit", border: "none", fontSize: "1rem"
            }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <MdKeyboardArrowRight />
            </button>
          </div>
        )}
        dateFormat={props.format || "dd/MM/yyyy"}
        className={
          props['data-error']
            ? 'error-border'
            : props.value
            ? 'value-border'
            : ''
        }
        placeholderText="Click to select"
        selected={isDateValid(selectedValue) ? selectedValue : "" }
        onChange={date =>
          props.onChange({
            target: {
              value: date,
              type: 'date',
              checked: '',
              dataset: {idx: props.dataIdx},
            },
          })
        }
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        // peekNextMonth
        // showMonthDropdown
        // showYearDropdown
        dropdownMode="select"
        disabled={props.disabled}
        isClearable={!props.disabled}
        maxDate={_maxDate}
        minDate={_minDate}
      />
      {props['data-error'] ? (
        <ErrorLabel>{props.errorMessage || 'Is required'}</ErrorLabel>
      ) : (
        <></>
      )}
      {props.value ? (
        <>{props.icon ? <Icon>
          <FaRegCalendar />
        </Icon> : ''}</>
      ) : (
        <Icon>
          <FaRegCalendar />
        </Icon>
      )}
    </DatePickerStyled>
  );
}
export default DDMMDateSelector;
