import React from 'react';
import {SpinnerContainer, SpinnerStyled, SpinnerLabel} from './styles';

const Spinner = ({position}) => {
  return (
    <SpinnerContainer>
      <div style={{position: "relative", top: "-5rem", left: "-4rem"}}>
        <SpinnerStyled position={position} />
        <SpinnerLabel position={position}>Loading</SpinnerLabel>
      </div>
    </SpinnerContainer>
  );
};

export default Spinner;
