import React, { useEffect } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { TIMES } from 'utils/constants/icons';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { findIndex } from 'lodash';
const RateCard = ({
  values,
  nameSection,
  index,
  useInput,
  deleteRate,
  dropDownHandleRate,
  job_title,
  rateCard,
  readOnly,
  deletePermission,
  branchData
}) => {


  console.log(values, "447555")
  return (
    <>
      <div className={`d-flex mt-1 mr-0 ml-0}`}>
        <CloseButtonWrapper
          disabled={!deletePermission}
          onClick={() => {
            deleteRate(nameSection, index);
          }}
        >
          {TIMES}
        </CloseButtonWrapper>
        <div className="w-100 mr-2">
          <DropdownSearchable
            placeHolder={'Branch'}
            required
            options={branchData || []}
            disabled={readOnly}
            {...useInput(`${[nameSection]}[${index}].branch`,'isRequired')}
            selectedOption={
              values[nameSection]
                ? values[nameSection][index]['branch']
                  ? values[nameSection][index]['branch']
                  : null
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                {
                  rate: value ? value : null,
                }, nameSection, index, 'branch'
              )
            }
          />
        </div>
        <div className="w-100 mr-2">
          <DropdownSearchable
            placeHolder={'Pay Rate'}
            required
            options={rateCard || []}
            disabled={readOnly}
            {...useInput(`${[nameSection]}[${index}].rate_card`, "isRequired")}
            selectedOption={
              values[nameSection]
                ? values[nameSection][index]['rate_card']
                  ? values[nameSection][index]['rate_card']
                  : null
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                {
                  rate: value ? value : null,
                }, nameSection, index, 'rate_card'
              )
            }
          />
        </div>
        <div className="w-100 mr-2">
          <InputLabelTop
            disabled={true}
            readOmly={true}
            readOnly={readOnly}
            type="number"
            id="over_time_rate"
            label={'Overtime  rate/hr'}
            {...useInput(`${[nameSection]}[${index}].over_time_rate`)}
            maxlength={5}
          />
        </div>
        <div className="w-100 mr-2">
          <InputLabelTop
            disabled={true}
            readOmly={true}
            type="number"
            id="rate_per_hour"
            label={'Rate/hr'}
            {...useInput(`${[nameSection]}[${index}].rate_per_hour`,)}

          />
        </div>

        <div className="w-100 mr-2">
          <DropdownSearchable
            placeHolder={'Job Title'}
            disabled={readOnly}
            options={job_title}
            selectedOption={
              values[nameSection]
                ? values[nameSection][index]["job_title"]
                  ? values[nameSection][index]["job_title"]
                  : null
                : null

            }
            onChange={value =>
              dropDownHandleRate(
                {
                  rate: value ? value : null,
                }, nameSection, index, "job_title",
              )
            }
          />
        </div>
        <div className="w-100 mr-2">
          <DateSelector
            isRequired
            labelTop="Effective From"
            {...useInput(`${nameSection}[${index}].effective_date_from`, 'isRequired')}
          />
        </div>
        <div className="w-100 mr-2">
          <DateSelector
            labelTop="Effective To"
            {...useInput(`${nameSection}[${index}].effective_date_to`)}
          />
        </div>
      </div>

    </>
  );
}

export default RateCard;