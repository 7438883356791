import React, { useEffect } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { TIMES } from 'utils/constants/icons';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { findIndex } from 'lodash';
const JobDetailContracts = ({
  values,
  nameSection,
  index,
  useInput,
  deleteRate,
}) => {


  console.log(values, "447555")
  return (
    <>
      <div className={`d-flex mt-1  ml-0}`}>
        <CloseButtonWrapper
          onClick={() => {
            deleteRate(nameSection, index);
          }}
        >
          {TIMES}
        </CloseButtonWrapper>
        <div className="w-100 mr-2">
          <InputLabelTop
            required
            disabled={false}
            readOnly={false}
            type="number"
            id="contracted_no_hours"
            label={'No. of Contracted Hours'}
            {...useInput(`${[nameSection]}[${index}].contracted_no_hours`,'isRequired')}

          />
        </div>
        <div className="w-100 mr-2">
          <InputLabelTop
            required
            disabled={false}
            readOnly={false}
            type="number"
            id="no_of_contracted_avg_days"
            label={'No. of Contracted Average Days'}
            {...useInput(`${[nameSection]}[${index}].no_of_contracted_avg_days`,'isRequired')}

          />
        </div>
        {/* <div className="w-100 mr-2">
          <InputLabelTop
            disabled={false}
            readOnly={false}
            type="number"
            id="no_of_contracted_desired_days"
            label={'No. of Contracted Desired Days'}
            {...useInput(`${[nameSection]}[${index}].no_of_contracted_desired_days`,)}

          />
        </div> */}
        <div className="w-100 mr-2">
          <DateSelector
          isRequired
            labelTop="Contract Effective From"
            {...useInput(`${nameSection}[${index}].contracted_start_date`,'isRequired')}
          />
        </div>
        <div className="w-100 mr-2">
          <DateSelector
            labelTop="Contract Effective To"
            {...useInput(`${nameSection}[${index}].contracted_end_date`)}
          />
        </div>
      </div>

    </>
  );
}

export default JobDetailContracts;