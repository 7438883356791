import React, {Fragment,useContext,useEffect,useState} from 'react';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {PLUS, TIMES} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import {Header, Title,TitleSection} from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
const TravelTimeEstimate = ({
    index,
    data,
    addNewTravelTimeEstimate,
    deleteTravelTimeEstimate,
    useInput,
    values,
    travelModeOptions,
    travelName,
    unitName,
    dropDownHandleSelectTravelEstimate,
    setValues,
}) => {


  return (

    <div>
     {/* className={`mt-1 ${index==0?'ml-2':''}`}        */}
        <>
        <div key={index}
         className={`mt-1`}style={{marginLeft:'15px',marginRight:'20px',width:'96.3%'}}>
               <div className='d-flex'>
                  <Fragment />
                  {/* {index!==0 &&  */}
                  <CloseButtonWrapper
                  onClick={() => {
                    deleteTravelTimeEstimate(data, index);
                  }}>
                  {TIMES}
                </CloseButtonWrapper>
                  {/* } */}
       <div className="p-0  w-100 d-flex align-items-center  ">
     <FlexGrid3Container className={'w-100 mb-1 mt-1 '}  >
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0025.00%'} >
   <DropdownSearchable
        placeHolder={'Travel mode '}
        options={travelModeOptions?travelModeOptions:[]}
        selectedOption={
          values.travel_estimate_serializer 
            ?  values.travel_estimate_serializer[index].travel_mode
            : null
        }
        onChange={value =>
          dropDownHandleSelectTravelEstimate(
            {travel_mode: value ? value : null},
            'travel_mode' ,'travel_estimate_serializer',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0025.00%'}  >
   <DropdownSearchable
        placeHolder={'Travel name '}
        options={travelName?travelName:[]}
        selectedOption={
            values.travel_estimate_serializer 
            ?  values.travel_estimate_serializer[index].travel_name
            : null
        }
        onChange={value =>
          dropDownHandleSelectTravelEstimate(
            {travel_name: value ? value : null},
            'travel_name','travel_estimate_serializer',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'0025.00%'} >
   <DropdownSearchable
        placeHolder={'Unit  '}
        options={unitName?unitName:[]}
        selectedOption={
            values.travel_estimate_serializer 
            ?  values.travel_estimate_serializer[index].unit_name
            : null
        }
        onChange={value =>
          dropDownHandleSelectTravelEstimate(
            {unit_name: value ? value : null},
            'unit_name','travel_estimate_serializer',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0025.00%'}   >
   <InputLabelTop
        label={'Value'}
     type="text"
              id="Value"
              {...useInput(`travel_estimate_serializer[${index}].travel_time`)}
       
            />
   </FlexGrid3Item>
   </FlexGrid3Container>
   </div>
   </div>
   </div>
    </>
    </div>
  )
}

export default TravelTimeEstimate