import {TOKEN, TENANT,ORGNAME,SCHEMA_NAME,
  USER_FIRST_NAME, EMPLOYEE,CLIENT,
  IS_ADMIN,IS_STAFF,IS_SUPER_ADMIN,
  BAD_GATEWAY
} from 'utils/constants/localStorage';

export const getToken = () => {
  return localStorage.getItem(TOKEN) || false;
};

export const setToken = value => {
  localStorage.setItem(TOKEN, value);
};

export const clearToken = () => {
  localStorage.clear(TOKEN);
};

export const setTenant = value => {
  localStorage.setItem(TENANT, value);
};

export const getTenant = () => {
  return localStorage.getItem(TENANT);
};

export const setOrgname = value => {
  localStorage.setItem(ORGNAME, value);
};

export const getOrgname = () => {
  return localStorage.getItem(ORGNAME);
};

export const setSchemaName = value => {
  localStorage.setItem(SCHEMA_NAME, value);
};

export const getSchemaName  = () => {
  return localStorage.getItem(SCHEMA_NAME);
};
export const setEmployee = value => {
  localStorage.setItem(EMPLOYEE, value);
};

export const getEmployee  = () => {
  return localStorage.getItem(EMPLOYEE);
};
export const setClientId = value => {
  localStorage.setItem(CLIENT, value);
};

export const getClient  = () => {
  return localStorage.getItem(CLIENT);
};
export const setAdmin = value => {
  localStorage.setItem(IS_ADMIN, value);
};

export const getAdmin  = () => {
  return localStorage.getItem(IS_ADMIN);
};

export const setSuperAdmin = value => {
  localStorage.setItem(IS_SUPER_ADMIN, value);
};

export const getSuperAdmin  = () => {
  return localStorage.getItem(IS_SUPER_ADMIN);
};
export const setStaff = value => {
  localStorage.setItem(IS_STAFF, value);
};

export const getStaff  = () => {
  return localStorage.getItem(IS_STAFF);
};

export const getUserId = () => localStorage.getItem("user_id");
export const setBadGateway = value => {
  localStorage.setItem(BAD_GATEWAY, value);
};

export const getBadGateway  = () => {
  return localStorage.getItem(BAD_GATEWAY);
};
export const clearBadGateway  = () => {
  return localStorage.removeItem(BAD_GATEWAY);
};
