import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { SetActualBulkTime, SetBulkTimeRotaShiftWithOutShift, SetBulkTimeRotaShiftWithShift, SetRevertBulkClocked } from "utils/api/SchedulerApi"
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import moment from 'moment'
const RevertClocked = ({
  fullBind,
  checkedRows,
  searchRotaShift,
  setModalRevertClockedValue = () => {},
  clearSelectedElId
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Revert Clocked',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const handleTimeSelector = (e) => {
    values.actual_visit_time = moment(e.target.value).format("h:mm:ss")
    setValues({ ...values })
  }
  const handleSubmit = async() => {
    debugger
    if (loadingSave) return;
    if (errors.length > 0) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setErrorMessage('');
    let checkedWithoutShift = [];

    checkedRows.forEach((item) => {
      checkedWithoutShift.push(item.id)
    })

    let bodyShift = {
      employee_shift: [...checkedWithoutShift],
      clock_type: values.clock_type,
      is_revert: true,
      is_web: true,
      is_same: false,
    }

    try {
      const res = await SetBulkTimeRotaShiftWithShift(bodyShift);
      console.log(res, "SetBulkTimeRotaShiftWithShift")
      if (res.statusCode == 202) {
        setSaveButtonIcon(CHECK);
        setTextStatus(SAVED);
        setLoadingSave(false);
        clearSelectedElId()
        setModalRevertClockedValue(false)
        searchRotaShift()
      }
    } catch (error) {
      setSaveButtonIcon(CROSS);
      setTextStatus(ERROR);
      setLoadingSave(false);
    }

  }


  return (
    <ModalContainer max_width={'40%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' pl-3 pr-3 pb-3'}>
          <div className="mb-2">
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Select Clock Type'}
                // width="145%"
                options={[
                  { id: 'Clock-out', name: "Revert Clock Out" },
                  { id: 'Clock-in', name: "Revert Clock In" }
                ]}
                {...useInput(
                  `clock_type`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.clock_type : null
                }
                onChange={value =>
                  dropDownHandler(
                    { clock_type: value ? value : null },
                    'clock_type'
                  )
                }
              />
            </FlexGrid3Item>
          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem"
                  onClick={handleSubmit}

                >
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default RevertClocked;
