import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    HeaderVisits,
    DropdownTypesContainer,
    FiltersLeftGroup,
    FiltersContainer
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { useForm } from 'hooks/FormHook';
import {
    dateToStringDate
} from 'shared/methods';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { getEmployeeEmail, sendEmailEmployee } from 'utils/api/ClientApi';
import { EX_LARGE_COL, LARGE_COL, SMALL_COL, MEDIUM_COL, EX_SMALL_COL4 } from 'shared/styles/constants/columns';
import {
    Row,
    Col,
    ColsGrouper,
    IconWrapper
} from 'components/SharedComponents/Table/styles';
import { AppContext, BranchItemsContext } from 'context';
import { team_rota_choices } from 'utils/choiceConstant';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import Table from 'components/SharedComponents/Table';
import { useModal } from 'hooks/ModalHook';
import PreviewModal from '../PreviewModal';
import moment from 'moment'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import BulkPreviewModal from '../BulkPreviewModal';
const NEW_DATE = new Date();

const headerColumns = [
    {
        label: 1,
        checkbox: true,
        name: 'checkbox',
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
    },
    {
        label: 'FULL NAME',
        name: 'full_name',
        status: 1,
        className: 'grow-mobile  sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'EMAIL',
        name: 'contact__email_personal',
        status: 1,
        className: 'grow-mobile sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'BRANCH',
        name: '',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Last Email Sent At',
        name: '',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: MEDIUM_COL,
    },
    // {
    //     label: 'PREVIEW EMAIL',
    //     name: 'preview_email',
    //     status: 0,
    //     className: 'grow-mobile sm-hidden',
    //     max_width: EX_LARGE_COL,
    // },
    // {
    //     label: 'SEND EMAIL',
    //     name: 'send_email',
    //     status: 0,
    //     className: 'grow-mobile sm-hidden',
    //     max_width: EX_LARGE_COL,
    // },
];

const TeamRota = ({
    location,
    updateFunderSettings,
    createFunderSettings,
    deletePermissionFunderSettings,
    readPermissionFunderSettings,
    filterAdmin,
    filterSuperAdmin,
    userStaff,
    tabClick
}) => {
    const { values, setValues, useInput } = useForm({ordering:"full_name"});
    const [clientList, setclientList] = useState([]);
    const [clientId, setclientId] = useState(null);
    const [modalLoading, setModalLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(true);
    const [branchFilter, setBranchFilter] = useState([]);
    const [branch, setBranch] = useState([]);
    const {
        setValue: setEmailPreviewModal,
        value: emailPreviewModal,
        //setTitle,
        fullBind: fullBindPreview,
    } = useModal(false);
    const {
        setValue: setEmailPreviewModalBulk,
        value: emailPreviewModalBulk,
        //setTitle,
        fullBind: fullBindPreviewBulk,
    } = useModal(false);
    const { contextChoices ,handleGetChoices} = useContext(AppContext);
    useEffect(() => {
        handleGetChoices(team_rota_choices)
        
      }, [])
useEffect(()=>{
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
},[contextChoices])
    useEffect(()=>{
        if(values.date_from){
            values.date_to = moment(values.date_from,"YYYY-MM-DD").add(6, 'days').format("YYYY-MM-DD");
        setValues({...values});
        }
    },[values.date_from])

    useEffect(()=>{
        if(values.date_to){
            values.date_from = moment(values.date_to).subtract(6, 'days').format("YYYY-MM-DD");
        setValues({...values});
        }
    },[values.date_to])

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleSelectChange = (valcheck, visit, selectedIndex) => {
        if (!values.selectedClient) {
            values.selectedClient = [];
        }
        if (valcheck.target.checked) {
            values.selectedClient.push(visit.id)
        } else {
            values.selectedClient.map((item, index) => {
                if (item === visit.id) {
                    values.selectedClient.splice(index, 1);
                }
            })
        }
        setValues({ ...values })
    }

    const handleCheck = (visit) => {
        let check = false;
        if (values.selectedClient) {
            values.selectedClient.map((item) => {
                if (visit.id === item) {
                    check = true
                }
            })
        }
        return check;
    }

    const handleDownload = () => {
       if(values.date_from && values.date_to){
        const start = values.date_from;
        // console.log(values?.ordering,"ooooooo")
        const end = moment(values.date_to,"YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
        getEmployeeEmail(start, end,values)
        .then(response => {
            
	   if(response){
        setLoadingModal(false);
      }
            setclientList(response.data);
        })
        .catch((err) => {
        })
       }
    }

    useEffect(()=> {
        handleDownload()
    },[values.ordering])

    const clickPreview=(id)=>{
        setEmailPreviewModal(true); 
        setclientId(id)
    }
const dropDownHandleSelect=(item)=>{
setValues({...values,...item})
}
    const sendRotaEmail=(Id)=>{
        sendEmailEmployee(values, Id)
        .then((res)=>{})
        .catch((res)=>{})
    }
    const setFilterBranch = item => {
        setBranchFilter([...item]);
      };
      
    const handleAllSelectChange = (valcheck) => {
        if (!values.selectedClient) {
            values.selectedClient = [];
        }
        if (valcheck.target.checked) {
            clientList.map((visit) => {
                values.selectedClient.push(visit.id);
            })
        } else {
            clientList.map((visit) => {
                values.selectedClient.map((item, index) => {
                    if (item === visit.id) {
                        values.selectedClient.splice(index, 1);
                    }
                })
            })
        }
        setValues({ ...values })
    }

    const handleAllCheck = () => {
        let checkAll = true;

        const visitExists = (id) => {
            return values.selectedClient.some(function (el) {
                return el === id;
            });
        }
        if (values.selectedClient && values.selectedClient.length > 0) {
            clientList.map((visit) => {
                if (!visitExists(visit.id)) {
                    checkAll = false
                }
            })
        } else {
            checkAll = false;
        }
        return checkAll;
    }

    
console.log(values.ordering,"QQQQQQQQQ")
    return (
        <div>
            <HeaderVisits>
                <div className="d-flex justify-content-between w-100 align-items-center mt-1">
                    <FiltersContainer>
                        <div
                            className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
                            <FiltersLeftGroup
                                className={'d-flex justify-content-center mb-2'}>
                                <DropdownTypesContainer className="ml-1 mr-1">
                                    <DateSelector
                                        typeFilter={true}
                                        labelTop="Date from"
                                        value={values.date_from ? values.date_from : ''}
                                        //onChange={(val)=>onDateChange(val)}
                                        {...useInput('date_from')}
                                    />
                                </DropdownTypesContainer>
                                <DropdownTypesContainer className="ml-1 mr-1">
                                    <DateSelector
                                        typeFilter={true}
                                        labelTop="Date to"
                                        value={values.date_to ? values.date_to : ''}
                                       // minDate={values.date_from}
                                        {...useInput('date_to')}
                                    />
                                </DropdownTypesContainer>
                                <DropdownTypesContainer className="mr-2">
              <NoSelectAllDropdownFilter
              
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({branch: items});
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={setFilterBranch}
                placeHolder={'Branch'}
                isObject
                allOption
                // hitApiAfterClear={hitApiAfterClear}
              />
            </DropdownTypesContainer>
                            </FiltersLeftGroup>
                        </div>
                        <PrimaryButtonForm onClick={handleDownload} minWidth="6rem" class="btn" type="button" >
                            <span className={'mr-2'}>Search</span>
                        </PrimaryButtonForm>
                    </FiltersContainer>
                    {values?.selectedClient && (values?.selectedClient?.length > 0) &&
                        <div class="dropdown">
                            <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className={'mr-2'}>Action</span>
                            </PrimaryButtonForm>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" onClick={()=>sendRotaEmail()} >Send Email</a>
                                <a class="dropdown-item" onClick={()=>setEmailPreviewModalBulk(true)} >Preview</a>
                            </div>
                        </div>}
                </div>
            </HeaderVisits>
            {clientList?.length > 0 &&
            <>    <ComponentDynamic loading={loadingModal} >	
                      <Table
                    headerColumns={headerColumns}
                    headerPadding={false}
                    noEditOption={false}
                    //noNeed={true}
                    fontSize="0.75rem"
                    callBackQuerySort={setQuerySort}
                    handleAllSelectChange={handleAllSelectChange}
                    handleAllCheck={handleAllCheck}
        
                >

                    <div>
                        {clientList && clientList.map((list, index) => {

                            const last_email = list.last_email_sent_at ? moment(list.last_email_sent_at).format('YYYY-MM-DD HH:mm') : '';
                            return (
                                <div key={index}>
                                    <Row bgColor Shadow>
                                        <ColsGrouper
                                            className="d-flex py-2">
                                            <Col
                                                max_width={EX_SMALL_COL4}
                                                Shrink={false}
                                                NoFlexGrow={true}
                                                reducePadding
                                                Capitalize
                                                Center

                                            >
                                                <input class="form-check-input" type="checkbox" checked={handleCheck(list)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, list, index) }} />

                                            </Col>
                                            <Col
                                                max_width={EX_LARGE_COL}
                                                Shrink
                                                reducePadding
                                                Capitalize
                                                className="sm-hidden"
                                                overlap={true}
                                                Center>
                                                {
                                                    list && list.full_name
                                                }
                                            </Col>
                                            <Col
                                                max_width={LARGE_COL}
                                                Shrink
                                                reducePadding
                                                Capitalize
                                                className="sm-hidden"
                                                overlap={true}
                                                Center>
                                                {
                                                    list && list.email
                                                }
                                            </Col>
                                            <Col
                                                max_width={SMALL_COL}
                                                Shrink
                                                reducePadding
                                                Capitalize
                                                className="sm-hidden"
                                                overlap={true}
                                                Center>
                                                {
                                                    list && list.branch
                                                }
                                            </Col>
                                            <Col
                                                max_width={MEDIUM_COL}
                                                Shrink
                                                reducePadding
                                                Capitalize
                                                className="sm-hidden"
                                                overlap={true}
                                                Center>
                                                {
                                                    list && last_email
                                                }
                                            </Col>
                                            <Col
                                                className="sm-hidden"
                                                //max_width={SMALL_COL}
                                                Center Shrink NoFlexGrow
                                            >
                                                <div className=" mt-1">
                                                    <button onClick={() => clickPreview(list.id)} type="button" className="btn text-primary text-bold  h6 border border-primary  rounded  btn-sm  "
                                                    >Preview </button>

                                                </div>

                                            </Col>
                                            <Col
                                                className="sm-hidden"
                                                max_width={LARGE_COL}
                                                Center Shrink NoFlexGrow Icon
                                            >
                                                <div className=" mt-1">
                                                    <button onClick={()=>sendRotaEmail(list.id)} type="button" className="btn text-primary text-bold  h6 border border-primary  rounded  btn-sm  "
                                                    >Send Email </button>

                                                </div>

                                            </Col>

                                        </ColsGrouper>
                                    </Row>
                                    <div className="mb-1" />
                                </div>
                            );
                        })}
                        {/* <div className="mt-3">
          <Pagination   totalPages={pages}  currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={absenceList.length}     />
          </div> */}
                    </div>

                </Table>
                </ComponentDynamic></>
                }
              
            {emailPreviewModal &&
                <PreviewModal
                    clientId={clientId}
                    useInput={useInput}
                    values={values}
                    modalLoading={modalLoading}
                    setModalLoading={setModalLoading}
                    fullBind={fullBindPreview}
                    setEmailPreviewModal={setEmailPreviewModal}
                />
            }

{emailPreviewModalBulk &&
                <BulkPreviewModal
                    selectedRow={values.selectedClient}
                    useInput={useInput}
                    values={values}
                    modalLoading={modalLoading}
                    setModalLoading={setModalLoading}
                    fullBind={fullBindPreviewBulk}
                    setEmailPreviewModalBulk={setEmailPreviewModalBulk}
                />
            }

        </div>
    );
}

export default TeamRota;
