import styled from 'styled-components';

export const InitialLetters = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${({fontSize}) => (fontSize ? fontSize : '2rem')};
`;
