import React, {useEffect, useState, useRef, useContext} from 'react';
import {Title, TitleSection} from 'shared/styles/constants/tagsStyles';
import Template from 'components/SharedComponents/SettingsTemplate';
import LineContainer from 'components/SharedComponents/LineContainer';
import PermissionsTable from './Table';
import {
  getRoles,
  getGroupTasks,
  getCareTask,
  deleteRole,
  deleteGroupTasks,
  setGroupTasks,
  getGroupTaskPermissions,
  setRole,
  getSections,
  changeGroupTaskPermission,
  changeCategoryPermission,
  changeSectionPermission,
  changeSubsectionPermission,
  setSubscription,
  getSubscription,
  getRolesAndPer,
  getSelectedSubscription,
  changeSubscriptionPermission,
  deleteSubscription,
  getRolesSub,
} from 'utils/api/UsersApi';
import {FLOPPY, CHECK} from 'utils/constants/icons';
import {SAVED, FINISH} from 'utils/constants/messages';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  ROLES_AND_PERMISSIONS_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import {
  getCategoryPermission,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from 'utils/localStorage/user';
import {getAdmin, getSuperAdmin} from 'utils/localStorage/token';
import {AppContext} from 'context';
import { SettingsGridContainer } from 'components/SharedComponents/SettingsTemplate/styles';

const SettingSubscription = props => {
  const [permissionsSections, setPermissionsSections] = useState([]);
  const [selectedRol, setSelectedRol] = useState({});
  const [rolValues, setRolValues] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(FINISH);
  const [loadingSave, setLoadingSave] = useState(false);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const {
    contextPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);

  const mountedRef = useRef(true);

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  useEffect(() => {
    loadSections();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === ROLES_AND_PERMISSIONS_SETTINGS) {
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });

      if (filterAdmin == true || filterSuperAdmin == true) {
        setCreatePermissions(true);
        setDeletePermissions(true);
        setUpdatePermissions(true);
      } else {
        setCreatePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
        );
      }
    }
  }, []);

  useEffect(() => {
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rolValues)]);

  useEffect(() => {
    if (Object.keys(selectedRol).length >= 1) {
      loadRolPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);

  const loadSections = async () => {
    return getRolesSub()
      .then(response => {
        setPermissionsSections(response);
        return response;
      })
      .catch(() => {});
  };

  const loadselectedPermission = async () => {
    return getSelectedSubscription(selectedRol.id)
      .then(response => {
        return response;
      })
      .catch(() => {});
  };

  const loadRolPermission = async () => {
    setLoadingRolValues(true)
    const mySections = await loadSections();
    const addArrayList = JSON.parse(JSON.stringify(mySections));

    const permData = await loadselectedPermission();
    const permissionData = permData.data;

    // const categoryArr =  addArrayList.map((item,index)=>{
    //     return {id : item.task_type.care_category.id, name:  item.task_type.care_category.name}
    // })
    // const filterUnique = categoryArr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);

    // let temArr = [];
    // filterUnique.forEach((item)=>{
    //     let sectionData = [];
    //     addArrayList.forEach((sec)=>{
    //         let subsecData = [];
    //     if(item.id === sec.task_type.care_category.id){
    //         addArrayList.forEach((subItem)=>{
    //             if(subItem.task_type.id === sec.task_type.id){
    //                 subsecData.push({id: subItem.id, name:subItem.name});
    //             }
    //         });
    //         sectionData.push({id: sec.task_type.id, name: sec.task_type.name, care_task: subsecData})
    //     }
    //     });
    //     temArr.push({...item, task_type: sectionData.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)})
    // })

    const myRolPermissions = [];
    const addWebValues = myValue => {
      if (myValue) {
        return {
          web_read: myValue.web || false,
        };
      } else {
        return {
          web_read: false,
        };
      }
    };
    const addMobileValues = myValue => {
      if (myValue) {
        return {
          mobile_read: myValue.mobile || false,
        };
      } else {
        return {
          mobile_read: false,
        };
      }
    };

    //check all sections
    addArrayList.forEach((principalArray, index) => {
      let categoryCheck = [];

      permissionData.forEach(val => {
        if (val.category === principalArray.id) {
          categoryCheck = val;
        }
      });

      //add new index in myRolPermissions
      myRolPermissions.push({});
      myRolPermissions[index].name = principalArray.name;
      myRolPermissions[index].id = principalArray.id;
      myRolPermissions[index].principal = true;

      //add sections for each principal menu
      const myPrincipalSections = [];
      principalArray.section.forEach((principalSection, indexSections) => {
        // add new index in myPrincipalSections
        myPrincipalSections.push({});
        // add principal values in new index
        myPrincipalSections[indexSections].name = principalSection.name;
        myPrincipalSections[indexSections].id = principalSection.id;
        myPrincipalSections[indexSections].category = principalArray.id;
        myPrincipalSections[indexSections].principal = false;
        myPrincipalSections[indexSections].isSubsection = false;

        let sectionCheck = [];

        permissionData.forEach(val => {
          if (val.category === principalArray.id) {
            val.section.forEach(subSecVal => {
              if (subSecVal.section === principalSection.id) {
                sectionCheck = subSecVal;
              }
            });
          }
        });

        //add sections(sub_list) in first subList values
        myPrincipalSections[indexSections].sections = [];
        principalSection.sub_section.forEach((subSection, indexSubSection) => {
          myPrincipalSections[indexSections].sections.push({});
          // add principal aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].principal = false;
          // add isSubsection aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].isSubsection = true;
          //add name
          myPrincipalSections[indexSections].sections[indexSubSection].name =
            subSection.name;
          //add id
          myPrincipalSections[indexSections].sections[indexSubSection].id =
            subSection.id;
          //add section
          myPrincipalSections[indexSections].sections[indexSubSection].section =
            principalSection.id;
          // add category
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].category = principalArray.id;
          // add permissionId
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].permissionId = subSection.id;
          // add web
          let subSectionCheck = [];
          permissionData.forEach(val => {
            if (val.category === principalArray.id) {
              val.section.forEach(subSecVal => {
                if (subSecVal.section === principalSection.id) {
                  subSecVal.sub_section.forEach(sub => {
                    if (sub.sub_section === subSection.id) {
                      subSectionCheck = sub;
                    }
                  });
                }
              });
            }
          });
          myPrincipalSections[indexSections].sections[indexSubSection].web =
            addWebValues(subSectionCheck);

          // add mobile

          myPrincipalSections[indexSections].sections[indexSubSection].mobile =
            addMobileValues(subSectionCheck);
        });
        //add web and mobile values for first subList values
        myPrincipalSections[indexSections].web = addWebValues(sectionCheck);
        myPrincipalSections[indexSections].mobile =
          addMobileValues(sectionCheck);
      });

      // add web and mobile values for principal menu
      myRolPermissions[index].web = addWebValues(categoryCheck);
      myRolPermissions[index].mobile = addMobileValues(categoryCheck);
      myRolPermissions[index].sections = myPrincipalSections;
    });
    setRolValues([...myRolPermissions]);
    setLoadingRolValues(false)
  };

  const changeRol = async rolData => {
    setRolValues([]);
    if (Object.keys(selectedRol).length >= 1) {
      if (rolData.id === selectedRol.id) {
        setSelectedRol({});
      } else {
        setSelectedRol(rolData);
      }

      setButtonIcon(FLOPPY);
      setTextStatus(FINISH);
      return;
    }

    setSelectedRol(rolData);
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
  };

  const handleSavePermissions = async () => {
    setLoadingSave(true);

    const savePermissionsSubSections = function () {
      return async function (permissions) {
        const objToSend = {
          sub_section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSubsectionPermission(objToSend);

          return response;
        } catch (error) {}
      };
    };

    const savePermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSectionPermission(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSubSections())
          );
          return response;
        } catch (error) {}
      };
    };

    const saveRolPermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          category_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await saveRolCategory(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSections())
          );
          return response;
        } catch (error) {}
      };
    };

    await Promise.all(rolValues.map(saveRolPermissionsSections()));

    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setLoadingSave(false);
  };

  const updateRolValues = () => {
    setRolValues([]);
    setSelectedRol({});
  };

  const saveRolCategory = async objToSend => {
    try {
      const response = await changeCategoryPermission(objToSend);
      return response;
    } catch (error) {}
  };

  const saveRolSection = async objToSend => {
    try {
      const response = await changeSectionPermission(objToSend);
      return response;
    } catch (error) {}
  };

  const saveRol = rol => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await setSubscription(rol);

        if (response.id) {
          resolve(response);
          //props.handleChangePermissions();
        } else {
          reject({});
        }
      } catch (error) {
        reject({});
      }
    });
  };

  const handleCheckboxClick = (val, check) => {
    let cat_Id = selectedRol.id;
    let body = {
      status: check,
      view: val.columnType,
      category_price: '00.0',
      category: val.principal ? val.id : val.category,
    };
    if (val.principal) {
      let temp = val.sections.map(item => {
        let subTemp = item.sections.map(subItem => ({
          sub_section: subItem.id,
          sub_section_price: '00.0',
        }));
        return {section: item.id, section_price: '00.0', sub_section: subTemp};
      });
      body.section = [...temp];
    } else {
      let subSec = [];
      if (val.isSubsection) {
        subSec.push({sub_section: val.id, sub_section_price: '00.0'});
      } else {
        if (val.sections.length > 0) {
          val.sections.forEach(item => {
            subSec.push({sub_section: item.id, sub_section_price: '00.0'});
          });
        }
      }
      body.section = [
        {
          section: val.isSubsection ? val.section : val.id,
          section_price: '00.0',
          sub_section: subSec,
        },
      ];
    }
    changeSubscriptionPermission(body, cat_Id)
      .then(res => {})
      .catch(err => {});
  };
  const [loadingRolValues, setLoadingRolValues] = useState(false);

  return (
    <div>
      <Title className={'ml-1 mb-2'}>Setting Subscription</Title>
      <SettingsGridContainer>
            <Template
              titleSection={'Subscription Plans'}
              labelInputTitle={'Name'}
              getMethod={getSubscription}
              deleteMethod={deleteSubscription}
              setMethod={saveRol}
              textToMessageDelete={'plan'}
              specializeCase={'role'}
              specializedMethods={{
                changeRol: changeRol,
                selectedRol: selectedRol,
                updateRolValues: updateRolValues,
              }}
              addSecondInput={{
                label: 'Price',
                value: 'end_time',
                required: true,
              }}
              staffCheck={false}
            />
            <LineContainer>
                <TitleSection  className='px-3 py-3'>Permissions</TitleSection>
                <div className={'px-3'}>
                {
                  loadingRolValues ? "loading..." :
                  (rolValues.length > 0 ?
                    <PermissionsTable
                        rolValues={rolValues}
                        changeRol={changeRol}
                        selectedRol={selectedRol}
                        setRolValues={setRolValues}
                        handleCheckboxClick={handleCheckboxClick}
                      />
                    :
                      <div style={{fontSize:"0.85rem"}}>
                        Please select a group task to populate fields here
                      </div>
                  )
                }
                </div>
            </LineContainer>
      </SettingsGridContainer>
    </div>
  );
};

export default SettingSubscription;
