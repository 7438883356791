import styled from 'styled-components';
import {GRAY_400, PURPLE} from 'shared/styles/constants/colors';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  translate: 0 -0.4rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    color: ${props => (props.active ? PURPLE : 'gray')};
    font-size: 1.25rem;
  }

  label {
    color: ${props => (props.active ? PURPLE : 'gray')};
    font-size: 0.75rem;
    font-weight: 600;

    position: absolute;
    bottom: -1.525rem;
  }

  .tries {
    position: absolute;
    left: 4px;
    font-size: 0.7rem;
    color: white;
    padding: 0;
    margin: 0;
  }
`;

export const Dot = styled.hr`
  border-top: 0.25rem dotted ${props => (props.active ? PURPLE : 'gray')};
  width: 4.625rem;
`;

export const Indeterminate = styled.div`
  width: 4.625rem;
  margin-top: 0.5rem;
  background-color: ${GRAY_400};
  display: -webkit-flex;
  display: flex;
  height: 0.1875rem;

  &:before {
    height: 0.1875rem;
    width: 100%;
  }
  &:before {
    background-color: ${props =>
      props.active ? PURPLE : props.done ? PURPLE : GRAY_400};
    content: '';
    -webkit-animation: ${props =>
      props.active
        ? 'running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite'
        : 'none'};
    animation: ${props =>
      props.active
        ? 'running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite'
        : 'none'};
  }
  @-webkit-keyframes running-progress {
    0% {
      margin-left: 0px;
      margin-right: 100%;
    }
    50% {
      margin-left: 25%;
      margin-right: 0%;
    }
    100% {
      margin-left: 100%;
      margin-right: 0;
    }
  }
  @keyframes running-progress {
    0% {
      margin-left: 0px;
      margin-right: 100%;
    }
    50% {
      margin-left: 25%;
      margin-right: 0%;
    }
    100% {
      margin-left: 100%;
      margin-right: 0;
    }
  }
`;
