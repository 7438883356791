import {LARGE_COL,EX_LARGE_COL,EX_VERY_LARGE_COL,SMALL_COL, EX_SMALL_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Branch Name',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Company Name',
    name: 'company_name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Address',
    name: 'line_1',
    status: 0,
    className: 'sm-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'City',
    name: 'city',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Zip Code',
    name: 'zip_code',
    status: 0,
    className: 'sm-hidden',
    max_width: SMALL_COL,
  },
  
  {
    label: 'Email',
    name: 'email',
    status: 0,
    className: 'sm-hidden',
    max_width: EX_VERY_LARGE_COL,
  },
  
  
  {
    label: '',
    name: '',
    status: 0,
    className: 'sm-hidden',
    max_width: SMALL_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: 'sm-hidden',
    max_width: EX_SMALL_COL,
  },

];
