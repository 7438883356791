import { useState } from 'react';

export const useModal = ( initialValue, initialTitle = '', initialBody = '' ) => {
  const [value, setValue] = useState( initialValue );
  const [title, setTitle] = useState( initialTitle );
  const [body, setBody] = useState( initialBody );
  const [onOk, setOnOk] = useState( {} );

  return {
    value,
    setValue,
    setTitle,
    setBody,
    setOnOk : ( fn ) => setOnOk( { fn } ),
    reset   : () => setValue( false ),
    bind    : {
      value,
      showModal : value,
      onCancel  : () => {
        setValue( false );
      },
      onClose: () => {
        setValue( false );
      }
    },
    fullBind: {
      title,
      value,
      body,
      showModal : value,
      onOk      : evt => {
        evt.preventDefault();
        onOk.fn();
      },
      onCancel: () => {
        setValue( false );
      },
      onClose: () => {
        setValue( false );
      }
    },
    handleClick: evt => {
      evt.preventDefault();
      setValue( true );
    }
  };
};
