import React, {Fragment, useState, useEffect, useContext} from 'react';
import {ErrorMessage} from 'shared/styles/constants/tagsStyles';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import AddNewVisit from './AddNewVisit';
import _ from 'lodash';
import {
  Service,
  Funder,
  ServiceAddress,
} from 'components/SharedComponents/FormSections';
import PropTypes from 'prop-types';
import {
  getCategoryPermission,
  getPermissions,
  getSubSectionPermissions,
  getStaff,
  getClient,
  getEmployee,
} from '../../../../utils/localStorage/user';

import {PLAY, ERROR_OUTLINE, FLOPPY} from 'utils/constants/icons';
import {
  CLIENT_SERVICE_CONTRACT,
  WEB_DELETE_PERMISSION,
  CLIENT_FUNDER_CONTACT,
  CLIENT_FUNDER,
  CLIENT_SERVICE_VISITS,
  CLIENT_SERVICE,
  CLIENT_SERVICE_ADDRESS,
  CLIENT_FUNDER_BILL_RATE_CARD,
  CLIENT_FUNDER_PAY_RATE_CARD,
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import {getAdmin, getSuperAdmin} from '../../../../utils/localStorage/token';
import {getPayRates} from 'utils/api/ClientApi';
import {useHistory} from 'react-router-dom';
import {AppContext} from 'context';
import {useModal} from 'hooks/ModalHook';
import ConfirmationModal from './ConfirmationMdal';
import ConfirmationModalSave from './ConfirmationModalSave';
import ModalDecision from 'components/SharedComponents/ModalDecision';
const ModalServiceClient = ({
  dropDownHandleVisits,
  dropDownHandleSelect,
  dropDownHandleSelectFunder,
  dropDownHandleSelectAddress,
  values,
  setValues,
  useInput,

  choices,
  serviceId,
  clientId,
  showError,
  errorMessage,
  setErrorSplitPercentage,
  errorSplitPercentage,
  errors,
  loadingFinish,
  loadingSave,
  textStatus,
  buttonIcon,
  addressValues,
  clientFullName,
  setDateError,
  dateError,
  setTimeError,
  timeError,
  funderList,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userClient,
  updateServicePermissions,
  selectedClientId,
  clientTaskOptions,
  groupTaskOptions,
  plannedTasks,
  editMode,
  setEditMode,
  isValid,
  allErrors,
  setCheckRequires,
  promisesWithErrors,
  setShowError,
  clientErrors,
  setErrorMessage,
  DetailsNotCreated,
  InvalidOrEmptyValues,
  setSavedContract,
  checkBeforeSend,
  earlieErrors,
  setShowEarlieError,
  setEarlieMessage,
  earlieMessage,
  setEarlieError,
  showEarlieError,
  dropDownHandleSelectFunderBilling,
  contextRelativesClient,
  medDoseTimingList,
  setFormErrors,
  loadingSaveOnService,
  editTypeSelected,
  setModalValueConfirmSave,
              modalValueConfirmSave,
              fullBindConfirmSave,
              visitToUpdateData,
              visitToCreateData,
              visitToDeleteData,
              setLoadingSave,
              loadingVisit
}) => {
  console.log('service values', values)
  const sameClientValue = values.service_address
    ? values.service_address.same_as_client
    : false;
  const [toDeleteVisits] = useState([]);
  const [toDeleteFunder] = useState([]);
  const [toDeleteVisitSkill, setToDeleteVisitSkill] = useState([]);
  const [toDeleteVisitEmployee, setToDeleteVisitEmployee] = useState([]);
  const [toDeleteSplitFunder] = useState([]);
  const [changeAddress, setChangeAddress] = useState(sameClientValue);
  const [deletedSplitFunders, setDeletedSplitFunders] = useState(false);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [visitRender, setvisitRenders] = useState(false);
  const [showDeleteVisitsModal, setShowDeleteVisitsModal] = useState(false);
  const [
    clientServicesFunderContactCreate,
    setClientServicesFunderContactCreate,
  ] = useState(false);
  const [
    clientServicesFunderContactUpdate,
    setClientServicesFunderContactUpdate,
  ] = useState(false);
  const [clientServicesFunderContactRead, setClientServicesFunderContactRead] =
    useState(false);
  const [clientServicesFunderCreate, setClientServicesFunderCreate] =
    useState(false);
  const [clientServicesFunderUpdate, setClientServicesFunderUpdate] =
    useState(false);
  const [clientServicesFunderRead, setClientServicesFunderRead] =
    useState(false);
  const [clientServicesServiceVisitsRead, setClientServicesServiceVisitsRead] =
    useState(false);
  const [
    clientServicesServiceVisitsCreate,
    setClientServicesServiceVisitsCreate,
  ] = useState(false);
  const [
    clientServicesServiceVisitsUpdate,
    setClientServicesServiceVisitsUpdate,
  ] = useState(false);
  const [clientServicesServiceRead, setClientServicesServiceRead] =
    useState(false);
  const [clientServicesServiceCreate, setClientServicesServiceCreate] =
    useState(false);
  const [clientServicesServiceUpdate, setClientServicesServiceUpdate] =
    useState(false);
  const [
    clientServicesServiceAddressRead,
    setClientServicesServiceAddressRead,
  ] = useState(false);
  const [
    clientServicesServiceAddressCreate,
    setClientServicesServiceAddressCreate,
  ] = useState(false);
  const [
    clientServicesServiceAddressUpdate,
    setClientServicesServiceAddressUpdate,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardRead,
    setClientServicesFunderBillRateCardRead,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardCreate,
    setClientServicesFunderBillRateCardCreate,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardUpdate,
    setClientServicesFunderBillRateCardUpdate,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardRead,
    setClientServicesFunderPayRateCardRead,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardCreate,
    setClientServicesFunderPayRateCardCreate,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardUpdate,
    setClientServicesFunderPayRateCardUpdate,
  ] = useState(false);

  // service contract inner section below
  const [subSectionServiceContractCreate, setSubSectionServiceContractCreate] =
    useState(false);
  const [subSectionServiceUpdate, setSubSectionServiceContractUpdate] =
    useState(false);
  const [subSectionServiceContractDelete, setSubSectionServiceContractDelete] =
    useState(false);
  const [subSectionServiceContractRead, setSubSectionServiceContractRead] =
    useState(false);
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);

  const history = useHistory();
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);

  const userStaff = contextIsStaff;

  const { setValue: setModalWarning, bind: bindModalWarning } = useModal(
    false,
  );
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind: fullBindBulkAlert,
  } = useModal(false);
  // const {
  //   setValue: setBulkAlertModalValue,
  //   value: bulkAlertModalValue,
  //   // setBulkAlertTitle,
  //   fullBind: fullBindBulkAlert,
  // } = useModal(false);

  useEffect(() => {
    // setEditMode(false)
    if (filterSuperAdmin) {
      setClientServicesFunderContactCreate(true);
      setClientServicesFunderContactUpdate(true);
      setClientServicesFunderContactRead(true);
      setClientServicesFunderCreate(true);
      setClientServicesFunderUpdate(true);
      setClientServicesFunderRead(true);
      setClientServicesServiceVisitsRead(true);
      setClientServicesServiceVisitsUpdate(true);
      setClientServicesServiceVisitsCreate(true);
      setClientServicesServiceRead(true);
      setClientServicesServiceCreate(true);
      setClientServicesServiceUpdate(true);
      setClientServicesServiceAddressRead(true);
      setClientServicesServiceAddressUpdate(true);
      setClientServicesServiceAddressCreate(true);
      setClientServicesFunderBillRateCardRead(true);
      setClientServicesFunderBillRateCardUpdate(true);
      setClientServicesFunderBillRateCardCreate(true);
      setClientServicesFunderPayRateCardRead(true);
      setClientServicesFunderPayRateCardUpdate(true);
      setClientServicesFunderPayRateCardCreate(true);
    } else if (filterAdmin) {
      setClientServicesFunderContactCreate(true);
      setClientServicesFunderContactUpdate(true);
      setClientServicesFunderContactRead(
        contextSubscription?.[CLIENT_FUNDER_CONTACT]?.[WEB_PERMISSION]
      );
      setClientServicesFunderCreate(true);
      setClientServicesFunderUpdate(true);
      setClientServicesFunderRead(
        contextSubscription?.[CLIENT_FUNDER]?.[WEB_PERMISSION]
      );
      setClientServicesServiceVisitsRead(
        contextSubscription?.[CLIENT_SERVICE_VISITS]?.[WEB_PERMISSION]
      );
      setClientServicesServiceVisitsUpdate(true);
      setClientServicesServiceVisitsCreate(true);
      setClientServicesServiceRead(
        contextSubscription?.[CLIENT_SERVICE]?.[WEB_PERMISSION]
      );
      setClientServicesServiceCreate(true);
      setClientServicesServiceUpdate(true);
      setClientServicesServiceAddressRead(
        contextSubscription?.[CLIENT_SERVICE_ADDRESS]?.[WEB_PERMISSION]
      );
      setClientServicesServiceAddressUpdate(true);
      setClientServicesServiceAddressCreate(true);
      setClientServicesFunderBillRateCardRead(
        contextSubscription?.[CLIENT_FUNDER_BILL_RATE_CARD]?.[WEB_PERMISSION]
      );
      setClientServicesFunderBillRateCardUpdate(true);
      setClientServicesFunderBillRateCardCreate(true);
      setClientServicesFunderPayRateCardRead(
        contextSubscription?.[CLIENT_FUNDER_PAY_RATE_CARD]?.[WEB_PERMISSION]
      );
      setClientServicesFunderPayRateCardUpdate(true);
      setClientServicesFunderPayRateCardCreate(true);
    } else {
      if (rightPermission[CLIENT_FUNDER_CONTACT] !== undefined) {
        setClientServicesFunderContactCreate(
          rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderContactUpdate(
          rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderContactRead(
          contextSubscription?.[CLIENT_FUNDER_CONTACT]?.[WEB_PERMISSION] &&
            (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_FUNDER] !== undefined) {
        setClientServicesFunderCreate(
          rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderUpdate(
          rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderRead(
          contextSubscription?.[CLIENT_FUNDER]?.[WEB_PERMISSION] &&
            (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_SERVICE_VISITS] !== undefined) {
        setClientServicesServiceVisitsRead(
          contextSubscription?.[CLIENT_SERVICE_VISITS]?.[WEB_PERMISSION] &&
            (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientServicesServiceVisitsUpdate(
          rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesServiceVisitsCreate(
          rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_SERVICE]) {
        setClientServicesServiceRead(
          contextSubscription?.[CLIENT_SERVICE]?.[WEB_PERMISSION] &&
            (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientServicesServiceCreate(
          rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesServiceUpdate(
          rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_SERVICE_ADDRESS] !== undefined) {
        setClientServicesServiceAddressRead(
          contextSubscription?.[CLIENT_SERVICE_ADDRESS]?.[WEB_PERMISSION] &&
            (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientServicesServiceAddressUpdate(
          rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesServiceAddressCreate(
          rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD] !== undefined) {
        setClientServicesFunderBillRateCardRead(
          contextSubscription?.[CLIENT_FUNDER_BILL_RATE_CARD]?.[
            WEB_PERMISSION
          ] &&
            (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientServicesFunderBillRateCardUpdate(
          rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderBillRateCardCreate(
          rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD] !== undefined) {
        setClientServicesFunderPayRateCardRead(
          contextSubscription?.[CLIENT_FUNDER_PAY_RATE_CARD]?.[
            WEB_PERMISSION
          ] &&
            (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ]
                && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientServicesFunderPayRateCardUpdate(
          rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientServicesFunderPayRateCardCreate(
          rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ]
              && ((userClient == selectedClientId)|| (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
    }
  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription, contextRelativesClient]);

  useEffect(() => {
    const newAddressValues = {...values.service_address};
    const oldAddressValues = {...addressValues};

    if (changeAddress && sameClientValue) {
      values.service_address = {
        ...newAddressValues,
        ...oldAddressValues,
      };
    } else if (!changeAddress && !sameClientValue) {
      values.service_address = {
        ...values.service_address,
      };
      values.service_address.same_as_client = false;
      values.service_address.name = '';
      values.service_address.line_1 = '';
      values.service_address.line_2 = '';
      values.service_address.city = '';
      values.service_address.country = '';
      values.service_address.state = '';
      values.service_address.zip_code = '';
      values.service_address.latitude = '';
      values.service_address.longitude = '';
    }

    setValues({...values});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changeAddress,
    sameClientValue,
    addressValues.name,
    addressValues.line_1,
    addressValues.line_2,
    addressValues.city,
    addressValues.country,
    addressValues.state,
    addressValues.zip_code,
    addressValues.latitude,
    addressValues.longitude,
  ]);

  useEffect(() => {
    getPayRates().then(res => {
      setBillRate(
        res.filter(data => data.rate_card_type == 'Bill Rates') || []
      );
      setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
      setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates') || []);
    });

    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setSubSectionServiceContractCreate(true);
      setSubSectionServiceContractRead(true);
      setSubSectionServiceContractUpdate(true);
      setSubSectionServiceContractDelete(true);
    } else {
      setCreatePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ]
      );

      setReadPermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ]
      );

      // inner section  below
      setSubSectionServiceContractCreate(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ]
      );

      setSubSectionServiceContractRead(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ]
      );
      setSubSectionServiceContractUpdate(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ]
      );

      setSubSectionServiceContractDelete(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ]
      );
    }
  }, []);

  const addNewVisit = () => {
    if (!values.visits) {
      values.visits = [];
    }
    values.visits.push({id: '', skills_required: []});
    setValues({...values});
  };
  const addVisitCopy = index => {
    let length = values.visits.length;
    const array1 = values.visits.slice(0, index + 1);
    const array2 = values.visits.slice(index + 1, length + 1);
    array1.push({id: '', skills_required: []});
    let arr1Length = array1.length;
    array1[arr1Length - 1] = _.cloneDeep(values.visits[index]);

    array1[arr1Length - 1].id = '';
    if (array1[arr1Length - 1].client_data) {
      delete array1[arr1Length - 1].client_data;
    }
    if (array1[arr1Length - 1].bill_rate) {
      array1[arr1Length - 1].bill_rate.forEach((ele, index) => {
        if (ele.id) {
          delete array1[arr1Length - 1].bill_rate[index].id;
        }
        if (ele.client_service_visit) {
          delete array1[arr1Length - 1].bill_rate[index].client_service_visit;
        }
      });
    }
    if (array1[arr1Length - 1].employee_required) {
      array1[arr1Length - 1].employee_required.forEach((ele, index) => {
        if (ele.id) {
          delete array1[arr1Length - 1].employee_required[index].id;
        }
        if (ele.pay_rates) {
          ele.pay_rates.forEach((pay, payIndex) => {
            if (pay.id) {
              delete array1[arr1Length - 1].employee_required[index].pay_rates[
                payIndex
              ].id;
            }
          });
        }
      });
    }

    values.visits = array1.concat(array2);
    // values.visits.push({id: '', skills_required: []});

    // values.visits[length-1]=_.cloneDeep(values.visits[index])
    // values.visits[length-1].id=''
    setValues({...values});
  };

  const deleteVisit = (dataVisit, indexVisit) => {
    setFormErrors([])
    toDeleteVisits.push({...dataVisit});

    const possibleError = `service_funders[${indexVisit}]`;

    for (var i = errors.length; i--; ) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.visits.splice(indexVisit, 1);
    setvisitRenders(!visitRender)
    setValues({...values});
  };

  const deleteFunder = (dataVisit, indexVisit) => {
    setFormErrors([])
    toDeleteFunder.push({...dataVisit});

    const possibleError = `visits[${indexVisit}]`;

    for (var i = errors.length; i--; ) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.service_funders.splice(indexVisit, 1);

    setValues({...values});
  };

  const addNewSplitFunder = (index) => {
    values.service_funders[index].split_funders.push({id: ''});
    setValues({...values});
  };

  const deleteSplitFunder = (dataSplit, indexVisit, indexFunder) => {
    setFormErrors([])
    toDeleteSplitFunder.push({...dataSplit});

    const possibleError = `service_clients[0].service_funders[${indexFunder}].split_funders[${indexVisit}]`;

    for (var i = errors.length; i--; ) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.service_funders[indexFunder].split_funders.splice(indexVisit, 1);
    setValues({...values});
    setDeletedSplitFunders(true);
  };

  useEffect(() => {
    //to set default value of no of client to 1 nd editable
    if (values.no_clients == null || values.no_clients == undefined) {
      values.no_clients = '1';
      setValues({...values});
    }
  }, [values, values.no_clients]);


  const handleModal = ({
    // evt,
    // closeModal,
    // saveTrigger,
    // values,
    // toDeleteVisits,
    // toDeleteSplitFunder,
    // toDeleteVisitSkill,
  }) => {
    debugger
    const haveDateErrors = dateError.find(data => data === true);
    const haveTimeErrors = timeError.find(data => data === true);
    const haveEarliestErros = earlieErrors.find(data => data === true);
    setCheckRequires(false);
    setShowError(false);
    if (
      (!isValid && errors.length > 0) ||
      errors.length > 0 ||
      (allErrors && promisesWithErrors.length <= 0) ||
      haveDateErrors ||
      haveTimeErrors ||haveEarliestErros
    ) {
      setCheckRequires(true);
      setShowError(true);
      if (allErrors && clientErrors.length >= 1) {
        setErrorMessage(DetailsNotCreated);
      } else {
        setErrorMessage(InvalidOrEmptyValues);
      }
      return;
    }

    if (promisesWithErrors.length >= 1) {
      let error = '';

      promisesWithErrors.forEach(promise => {
        error += `*${promise} `;
      });

      setErrorMessage(`Error while saving ${error}`);
    }

    if (editTypeSelected == 'contract_visit') {
      let employee_added = true;
      values.visits.forEach((item) => {
        if (item.employee_required < 1) {
          employee_added = false
        }
      })
      if (employee_added) {
        setModalValue(true);
      } else {
        setModalWarning(true)
      }
    }else{
      setModalValue(true);
    }
    
    setSavedContract('unsaved');
  };
  const showAllService = () => {
    setShowDeleteVisitsModal(true);
  };
  const hideAllService = () => {
    setShowDeleteVisitsModal(false);
  };
  return (
    <div max_width={'92%'}>
      <div className="p-2 p-sm-3 p-md-4">
       {(editTypeSelected == 'create' || editTypeSelected == 'funder' || editTypeSelected == 'copy_funder') &&
      <>
        {(clientId
          ? clientServicesServiceRead
          : clientServicesServiceCreate) && (
          <Service
            values={values}
            useInput={useInput}
            setValues={setValues}
            editMode={editMode}
          />
        )}
        {(clientId
          ? clientServicesServiceAddressRead
          : clientServicesServiceAddressCreate) && (
          <div className={'mt-2'}>
            <ServiceAddress
              editMode={editMode}
              nameSection={'service_address'}
              values={values}
              useInput={useInput}
              choices={choices}
              dropDownHandleSelectAddress={dropDownHandleSelectAddress}
              setChangeAddress={setChangeAddress}
            />
          </div>
        )}
        <div className={'mt-2'}>
          <Funder
            editMode={editMode}
            deleteFunder={deleteFunder}
            nameSection={'service_funders'}
            values={values}
            setValues={setValues}
            useInput={useInput}
            choices={choices}
            dropDownHandleSelect={dropDownHandleSelect}
            dropDownHandleSelectFunder={dropDownHandleSelectFunder}
            dropDownHandleSelectFunderBilling={dropDownHandleSelectFunderBilling}
            billRate={billRate}
            payRate={payRate}
            travelRate={travelRate}
            funderList={funderList}
            addNewSplitFunder={addNewSplitFunder}
            deleteSplitFunder={deleteSplitFunder}
            setErrorSplitPercentage={setErrorSplitPercentage}
            errorSplitPercentage={errorSplitPercentage}
            deletedSplitFunders={deletedSplitFunders}
            deletePermission={deletePermission}
            clientId={clientId}
            clientServicesFunderRead={clientServicesFunderRead}
            clientServicesFunderCreate={clientServicesFunderCreate}
            clientServicesFunderUpdate={clientServicesFunderUpdate}
            clientServicesFunderContactRead={clientServicesFunderContactRead}
            clientServicesFunderContactCreate={
              clientServicesFunderContactCreate
            }
            clientServicesFunderContactUpdate={
              clientServicesFunderContactUpdate
            }
            clientServicesFunderBillRateCardRead={
              clientServicesFunderBillRateCardRead
            }
            clientServicesFunderBillRateCardCreate={
              clientServicesFunderBillRateCardCreate
            }
            clientServicesFunderBillRateCardUpdate={
              clientServicesFunderBillRateCardUpdate
            }
            clientServicesFunderPayRateCardRead={
              clientServicesFunderPayRateCardRead
            }
            clientServicesFunderPayRateCardCreate={
              clientServicesFunderPayRateCardCreate
            }
            clientServicesFunderPayRateCardUpdate={
              clientServicesFunderPayRateCardUpdate
            }
            setFormErrors={setFormErrors}
          />
        </div>
      </>
        }
{(editTypeSelected == 'create' || editTypeSelected == 'contract_visit') &&
        <div className={'mt-2'}>
          {(clientId
            ? clientServicesServiceVisitsRead
            : clientServicesServiceVisitsCreate) && (
            <AddNewVisit
            earlieErrors ={earlieErrors}
          setShowEarlieError={setShowEarlieError}
          setEarlieMessage={setEarlieMessage}
          earlieMessage={earlieMessage}
          setEarlieError={setEarlieError}
          showEarlieError={showEarlieError}
              editMode={editMode}
              dropDownHandleVisits={dropDownHandleVisits}
              addNewVisit={addNewVisit}
              deleteVisit={deleteVisit}
              useInput={useInput}
              choices={choices}
              visits={values.visits || []}
              values={values}
              setValues={setValues}
              toDeleteVisitSkill={toDeleteVisitSkill}
              setToDeleteVisitSkill={setToDeleteVisitSkill}
              toDeleteVisitEmployee={toDeleteVisitEmployee}
              setToDeleteVisitEmployee={setToDeleteVisitEmployee}
              serviceId={serviceId}
              clientId={clientId}
              errors={errors}
              setDateError={setDateError}
              dateError={dateError}
              setTimeError={setTimeError}
              timeError={timeError}
              addVisitCopy={addVisitCopy}
              subSectionServiceContractDelete={subSectionServiceContractDelete}
              deletePermission={deletePermission}
              billRate={billRate}
              payRate={payRate}
              travelRate={travelRate}
              clientTaskOptions={clientTaskOptions}
              groupTaskOptions={groupTaskOptions}
              plannedTasks={plannedTasks}
              visitRender={visitRender}
              medDoseTimingList={medDoseTimingList}
              setFormErrors={setFormErrors}
              editTypeSelected={editTypeSelected}
              categoryOptions={'client'}
              loading={loadingVisit}
            />
          )}
        </div>
}
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {showError ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ml-0 ml-md-3 mr-2'}>
              <PrimaryButtonForm
                disabled={!editMode}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm>
              {(selectedClientId
                    ? updateServicePermissions
                    : true) && (
              <PrimaryButtonForm
                disabled={!editMode || loadingSave}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                onClick={handleModal}>
                  <span  className={'mr-2'}>Save</span>
                {!loadingSave ? (
                    FLOPPY
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
              </PrimaryButtonForm>
                    )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {modalValue && (
        <ConfirmationModal
          selectedClientId={selectedClientId}
          type={'warning'}
          title={'CONFIRM SERVICE CONTRACT DATA'}
          okTitle={'NO, CANCEL'}
          onCancelTitle={'YES, PROCEED'}
          icon={ERROR_OUTLINE}
          hideOnCancel={false}
          onCancel={hideAllService}
          setModalValue={setModalValue}
          // setModalValue={setBulkAlertModalValue}
          allErrors={allErrors}
          fullBind={fullBindBulkAlert}
          values={values}
          false={false}
          true={true}
          toDeleteVisits={toDeleteVisits}
          toDeleteSplitFunder={toDeleteSplitFunder}
          toDeleteVisitSkill={toDeleteVisitSkill}
          toDeleteVisitEmployee={toDeleteVisitEmployee}
          checkBeforeSend={()=>{
            checkBeforeSend();
            setModalValue(false)
          }}
          showError={showError}
          onOk={() => {
            setShowDeleteVisitsModal(false);
          }}
          showModal={setShowDeleteVisitsModal}
          showAllService={showAllService}
        />
      )}
      {modalValueConfirmSave && (
        <ConfirmationModalSave
          type={'warning'}
          icon={ERROR_OUTLINE}
          setModalValue={setModalValueConfirmSave}
          fullBind={fullBindConfirmSave}
          values={values}
          toDeleteVisits={toDeleteVisits}
          toDeleteSplitFunder={toDeleteSplitFunder}
          toDeleteVisitSkill={toDeleteVisitSkill}
          toDeleteVisitEmployee={toDeleteVisitEmployee}
          checkBeforeSend={()=>{
            checkBeforeSend();
            //setModalValueConfirmSave(false)
          }}
          visitToUpdateData={visitToUpdateData}
          visitToCreateData={visitToCreateData}
          visitToDeleteData={visitToDeleteData}
          loadingSave={loadingSave}
          setLoadingSave={setLoadingSave}
        />
        
      )}
       <ModalDecision
              type="warning"
              title="Warning"
              body={
                `If no employee are added then visits won't be visible on Scheduler.`
              }
              subBody={
                'Do you still wish to proceed with no yes buttons.'
              }
              onOk={() => { 
                setModalValue(true);
                setModalWarning(false)
             }}
              onCancel={() => {
                setModalWarning(false)
              }}
              okTitle={'Confirm'}
              {...bindModalWarning}
            />
    </div>
  );
};

ModalServiceClient.propTypes = {
  serviceId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  dropDownHandleVisits: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  checkBeforeSend: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  addressValues: PropTypes.object.isRequired,
};

export default ModalServiceClient;
