import styled from 'styled-components';
import {
  GRADIENT_GREEN,
  PURPLE,
  WHITE,
  GRAY_200,
  GRAY_300,
  GRAY_700,
  GRAY_800,
  BLACK_900,
} from 'shared/styles/constants/colors';
import {SM, MD} from 'shared/styles/constants/resolutions';

function hideUseContainer(decreaseWidth) {
  if (decreaseWidth) {
    return `
        display: flex;
        align-items: center;
        justify-content: center;

        div.nameStyle {
          display: none !important;
        }

        div.emailStyle {
          display: none !important;
        }
      `;
  }
}

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.8rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${GRAY_300};

  ${({decreaseWidth}) => hideUseContainer(decreaseWidth)};

  @media (min-width: ${SM}) {
    div.emailStyle {
      justify-content: center;
    }
  }

  @media (min-width: ${MD}) {
    div.emailStyle {
      display: ${({decreaseWidth}) => (decreaseWidth ? 'none' : 'flex')};
      justify-content: center;
    }
  }

  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    div.nameStyle {
      font-weight: bold;
    }

    div.emailStyle {
      color: ${GRAY_800};
    }
  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    div.nameStyle {
      font-weight: bold;
      display: flex !important;
    }

    div.emailStyle {
      color: ${GRAY_800};
      display: flex !important;
    }
  }
`;

const wh_25 = /*css*/ `
  width: 2.5rem;
  height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
`;

const wh_38 = /*css*/ `
  width: 3.8rem;
  height: 3.8rem;
  max-width: 3.8rem;
  max-height: 3.8rem;
`;

const wh_51 = /*css*/ `
  width: 5.125rem;
  height: 5.125rem;
  max-width: 5.125rem;
  max-height: 5.125rem;
`;

const wh_48 = /*css*/ `
  width: 4.8rem;
  height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
`;

function decreaseImg(decreaseImg) {
  if (decreaseImg) {
    return `
        ${wh_25}
      `;
  }

  return `
    ${wh_48}
  `;
}

export const UserImgHalo = styled.div`
  position: relative;
  border-radius: 50%;
  background: ${WHITE};

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({decreaseWidth}) => decreaseImg(decreaseWidth)}

  img {
    border-radius: 50%;
    height: 100%;
  }

  @media (max-width: ${SM}) {
    width: 3.5rem !important;
    height: 3.5rem !important;
    max-width: 3.5rem !important;
    max-height: 3.5rem !important;
  }

  @media (max-width: ${MD}) {
    ${wh_48}
  }
`;

function hideUserImgContainer(decreaseWidth) {
  if (decreaseWidth) {
    return `
     margin-top: 2rem;
     ${wh_25}
      @media (max-width: ${MD}) {
        margin-top: 0rem;
        ${wh_51}
      }
    `;
  }
}

export const UserImgContainer = styled.div`
  position: relative;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: ${props => addBackground(props.decreaseWidth)};
  }
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  ${wh_51}

  ${({decreaseWidth}) => hideUserImgContainer(decreaseWidth)}

  @media (max-width: ${SM}) {
    margin-right: 1rem;
    ${wh_38}        
  }  
`;

function addBackground(decreaseWidth) {
  if (!decreaseWidth) {
    return `
        linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})
      `;
  } else {
    return `${GRAY_200}`;
  }
}

export const UserName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${GRAY_800};
  text-align:center;
`;

export const UserEmail = styled.div`
  font-size: 0.6875rem;
  color: ${GRAY_800};
`;

function hideSideBarContainer(decreaseWidth) {
  if (decreaseWidth) {
    return `
      max-width: 4rem;
      min-width: 4rem;
     `;
  }
}

export const SideBarContainer = styled.nav`
  max-width: 14rem;
  min-width: 14rem;
  position: fixed;
  z-index: 999;
  background-color: ${WHITE};
  box-shadow: 0px 3px 6px ${BLACK_900};

  ${({decreaseWidth}) => hideSideBarContainer(decreaseWidth)}

  @media (max-width: ${SM}) {
    max-width: 75% !important;
    min-width: 75% !important;
    margin-top: 50px;
  }

  @media (max-width: ${MD}) {
    max-width: 14rem;
    min-width: 14rem;
  }
`;

export const SideBar = styled.div`
  height: calc(100vh - 4.2381rem);
  display: flex;
  flex-direction: column;
`;

function addBorder(subItem, active) {
  let returnStyle = `
      border-top-left-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
      
  `;
  if (subItem && !active) {
    returnStyle = `
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
  `;
  }

  return returnStyle;
}

function focusItem(subItem, active) {
  let returnStyle = '';

  if (subItem && active) {
    returnStyle = `
      &:focus {
      outline: none;
      background-color: #fff;
      }
    `;
  } else if (subItem && !active) {
    returnStyle = ` 
      &:hover {
      outline: none;
      background-color: ${GRAY_200};
      }
    `;
  }

  return returnStyle;
}

export const SideBarItem = styled.label`
  position: relative;
  ${({subItem, active}) => focusItem(subItem, active)};
  & a {
    & label {
      width: 100%;
      text-align: left;
      padding-left: 0.5rem;
    }
  }
  & a.subItem {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    & label {
      padding: 0.45rem 0 0.45rem 1.25rem;
      ${({subItem, active}) => addBorder(subItem, active)};
    }
  }

  div {
    margin-top: -0.15rem;
  }

  img {
    ${({decreaseWidth}) => (decreaseWidth ? 'margin-left: 0.45rem;' : '')}
  }

  .hideItemLabel {
    display: none;
  }

  @media (max-width: ${MD}) {
    .hideItemLabel {
      display: inline-block;
    }
  }

  @media (min-width: ${MD}) {
    border-radius: 0px !important;
    border: 0px;
    border-right: ${({active, decreaseWidth}) =>
      active && decreaseWidth ? `3px solid ${PURPLE}` : ''};
  }
`;

export const DropdownSideBar = styled.li`
  z-index: 1;

  & button {
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 0px;

    label {
      cursor: pointer;
      margin: 0;
      padding-left: 0.5rem;
    }
    img {
      align-self: center !important;
      ${({decreaseWidth}) => (decreaseWidth ? 'margin-left: 0.4rem;' : '')}
    }
  }

  .hideDropdownLabel {
    display: none;
  }

  .hideDropdownItem {
    ${({decreaseWidth}) => (decreaseWidth ? 'display: none' : '')}
  }

  @media (max-width: ${MD}) {
    .hideDropdownLabel {
      display: inline-block;
    }

    .hideDropdownItem {
      display: inline-block;
    }
  }

  @media (min-width: ${MD}) {
    & button {
      border-radius: 0px !important;
      border: 0px;
      border-right: ${({active, decreaseWidth}) =>
        active && decreaseWidth ? `3px solid ${PURPLE}` : ''};
    }
  }
`;

export const DropdownSideBarContent = styled.div`
  width: 100%;
  display: none;

  &.show {
    display: inline-block;
  }
`;

export const SideBarItemLabel = styled.label`
  cursor: pointer;
  margin: 0 0 0 0.25rem;
  text-transform: ${props => (props.subItem ? 'none' : 'uppercase')};
  background-color: ${props => (props.subItem && props.active ? PURPLE : '')};
  box-shadow: ${props =>
    props.subItem && props.active ? `0 0.1875rem 0.375rem ${BLACK_900}` : ''};
  color: ${props => (props.active ? PURPLE : 'black')};
  color: ${props => (props.subItem && props.active ? WHITE : '')};
  font-weight: ${props => (props.active ? 500 : 300)};
`;

export const SideBarItemButton = styled.button`
  outline: none !important;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${props => (props.active ? PURPLE : 'black')};
  font-weight: ${props => (props.active ? 500 : 300)};
  &.selectedItem {
    color: ${PURPLE};
    font-weight: 500;
  }
`;

export const UlStyled = styled.ul`
  display: block;
  overflow: auto;
`;

export const FadeTopUl = styled.div`
  pointer-events: none;
  position: sticky;
  z-index: 1;
  top: -0.0625rem;
  display: block;
  width: 100%;
  height: 1rem;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
`;

export const FadeBottomUl = styled.div`
  pointer-events: none;
  position: sticky;
  bottom: -0.0625rem;
  display: block;
  width: 100%;
  height: 1rem;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
`;

export const StaticArrow = styled.div`
  height: 1.7rem;
  width: 1.7rem;
  right: -10px;
  top: 50%;
  cursor: pointer;
  position: absolute;
  border-radius: 4rem;
  background: ${WHITE};
  display: flex;
  padding-bottom: 0.3rem;
  padding-right: ${({decreaseWidth}) => (decreaseWidth ? '' : '0.2rem')};
  padding-left: ${({decreaseWidth}) => (decreaseWidth ? '0.2rem' : '')};
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 2px ${BLACK_900};
  z-index: 2;

  @media (max-width: ${MD}) {
    display: none;
  }
`;

export const AccordionArrow = styled.div`
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  border-radius: 4rem;
  background: ${WHITE};
  display: flex;
  padding-bottom: 0.3rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 2px ${BLACK_900};
  z-index: 2;
  margin-right: 10px;
  margin-left: -33px;

  @media (max-width: ${MD}) {
    display: none;
  }
`;

export const AccordionContainer = styled.div`
display: flex;
background-color: #e8e8ed;
padding-bottom: 8px;
padding-top: 8px;
margin-bottom: 10px;
`;

export const ImageComponent = styled.div`
  width: 100%;
  height: 100%;
  background: ${GRAY_200};
  color: ${PURPLE};
`;

export const SignOutContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${({decreaseWidth}) => (decreaseWidth ? 'center' : 'start')};
  padding-left: ${({decreaseWidth}) => (decreaseWidth ? '0' : '1rem')};
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  label {
    display: ${({decreaseWidth}) => (decreaseWidth ? 'none' : 'inline-block')};
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;
  }
  svg {
    color: ${GRAY_700};
    font-size: 1.4rem;
    margin-right: ${({decreaseWidth}) => (decreaseWidth ? '0' : '0.6rem')};
  }
  &:hover,
  &:hover svg {
    font-weight: 500;
    color: ${PURPLE}!important;
  }
`;
