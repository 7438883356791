import React, { useState, useEffect, Fragment } from 'react'
import { Title, TitleSection } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { useForm } from 'hooks/FormHook';
import VisitPayRateCard from '../../../SharedComponents/FormSections/Rate/VisitPayRateCard'
import ScheduledVisitPay from '../../../SharedComponents/FormSections/Rate/ScheduledVisitPay'
import ScheduledEmployeePay from '../../../SharedComponents/FormSections/Rate/ScheduledEmployeePay'
import ActualEmployeePay from '../../../SharedComponents/FormSections/Rate/ActualEmployeePay'
import ActualBillRate from '../../../SharedComponents/FormSections/Rate/ActualBillRate'
import ActualManualBillRate from '../../../SharedComponents/FormSections/Rate/ActualManualBillRate'
import ActualManualPayRate from '../../../SharedComponents/FormSections/Rate/ActualManualPayRate'
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FLOPPY } from 'utils/constants/icons';
import { getSchedulerRate } from 'utils/api/SchedulerApi';
import { getPayRates } from 'utils/api/ClientApi'
import { setUpdateRateDetailsData, getDataOnChangeRateID } from 'utils/api/SchedulerApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import ScheduledVisitTravel from './ScheduledVisitTravel';
import ActualEmployeeTravel from './ActualEmployeeTravel';



const RateDetails = ({
  editMode,
  handleAddVisitModal,
  searchScheduler,
  scoreDataId,
  AssignedName,
  tabSelected
}) => {


  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } = useForm({});
  const [schedulerRateDataById, setSchedulerRateDataById] = useState([]);
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);
  const [respSchedulerBillRateCard, setRespSchedulerBillRateCard] = useState();
  const [respSchedulerPayRateCard, setRespSchedulerPayRateCard] = useState();
  const [respActualBillRateCard, setRespActualBillRateCard] = useState();
  const [respActualPayRateCard, setRespActualPayRateCard] = useState();
  const [loadingModal, setLoadingModal] = useState(true);


  useEffect(() => {
    if (tabSelected == 2) {
      loadSchedulerRate();
      getPayRates()
        .then(res => {
          setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates' && data.is_active == true) || []);
          setBillRate(res.filter(data => data.rate_card_type == 'Bill Rates' && data.is_active == true) || []);
          setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates' && data.is_active == true) || []);
        })
    }
  }, [tabSelected])





  const loadSchedulerRate = async () => {
    await getSchedulerRate(scoreDataId)
      .then(res_data => {
        const resp = res_data.data;
        setLoadingModal(false)
        setSchedulerRateDataById(resp)
        setRespSchedulerBillRateCard(resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_rate_card && resp.visit_bill_rate.visit_bill_rate_card)
        setRespSchedulerPayRateCard(resp.visit_pay_rate && resp.visit_pay_rate.visit_pay_rate_card && resp.visit_pay_rate.visit_pay_rate_card)
        setRespActualBillRateCard(resp.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate_card && resp.actual_bill_rate.actual_bill_rate_card)
        setRespActualPayRateCard(resp.actual_pay_rate && resp.actual_pay_rate.actual_pay_rate_card && resp.actual_pay_rate.actual_pay_rate_card)

        if (Object.keys(resp).length > 0) {

          if (resp.funder_bill) {
            values.contract_funder_bill_rate_card = resp.funder_bill && resp.funder_bill.contract_bill_rate_card && resp.funder_bill.contract_bill_rate_card;
            values.contract_funder_bill_rateperhr = resp.funder_bill && resp.funder_bill.contract_bill_rate_per_hour && resp.funder_bill.contract_bill_rate_per_hour;
            values.contract_funder_bill_rate = resp.funder_bill && resp.funder_bill.contract_bill_rate && resp.funder_bill.contract_bill_rate;
            values.contract_funder_bill_amount = resp.funder_bill && resp.funder_bill.contract_bill_amount && resp.funder_bill.contract_bill_amount;
          }

          if (resp.funder_pay) {
            values.contract_funder_pay_rate_card = resp.funder_pay && resp.funder_pay.contract_pay_rate_card && resp.funder_pay.contract_pay_rate_card;
            values.contract_funder_pay_rateperhr = resp.funder_pay && resp.funder_pay.contract_pay_rate_per_hour && resp.funder_pay.contract_pay_rate_per_hour;
            values.contract_funder_pay_rate = resp.funder_pay && resp.funder_pay.contract_pay_rate && resp.funder_pay.contract_pay_rate;
            values.contract_funder_pay_rate_amount = resp.funder_pay && resp.funder_pay.contract_pay_amount && resp.funder_pay.contract_pay_amount;
          }

          if (resp.client_service_visit_bill) {
            values.scheduled_visit_rates_visit_bill_rate_card = resp.client_service_visit_bill && resp.client_service_visit_bill.schedule_bill_rate_card;
            values.scheduled_visit_rates_visit_bill_rateperhr = resp.client_service_visit_bill && resp.client_service_visit_bill.schedule_bill_rate_per_hour;
            values.scheduled_visit_rates_visit_bill_rate = resp.client_service_visit_bill && resp.client_service_visit_bill.schedule_bill_rate;
            values.scheduled_visit_rates_visit_amount = resp.client_service_visit_bill && resp.client_service_visit_bill.schedule_bill_amount && resp.client_service_visit_bill.schedule_bill_amount;
          }

          if (resp.requirement_pay) {
            values.scheduled_visit_pay_rate = resp.requirement_pay;
            values.scheduled_visit_pay_rate.visit_pay_rate_card = resp.requirement_pay && resp.requirement_pay.schedule_pay_rate_card;
            values.scheduled_visit_pay_rate.visit_pay_rate_per_hour = resp.requirement_pay && resp.requirement_pay.schedule_pay_rate_per_hour;
            values.scheduled_visit_pay_rate.visit_pay_rate = resp.requirement_pay && resp.requirement_pay.schedule_pay_rate;
            values.scheduled_visit_pay_rate.visit_pay_amount = resp.requirement_pay && resp.requirement_pay.schedule_pay_amount;
          }


          if (resp.visit_bill_rate) {
            values.contract_visit_bill_rate_card = resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_rate_card;
            values.contract_visit_bill_rateperhr = resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_rate_per_hour;
            values.contract_visit_bill_rate = resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_rate;
            values.contract_visit_bill_amount = resp.visit_bill_rate && resp.visit_bill_rate && resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_amount && resp.visit_bill_rate && resp.visit_bill_rate.visit_bill_amount;
          }

          if (resp.visit_manual_bill) {
            values.scheduled_manual_bill_rate = resp.visit_manual_bill && resp.visit_manual_bill.manual_bill_rate;
            values.scheduled_manual_bill_amount = resp.visit_manual_bill && resp.visit_manual_bill.manual_bill_amount;
          }

          if (resp.visit_manual_pay) {
            values.scheduled_manual_pay_rate = resp.visit_manual_pay && resp.visit_manual_pay.manual_pay_rate;
            values.scheduled_manual_pay_amount = resp.visit_manual_pay && resp.visit_manual_pay.manual_pay_amount;
          }


          if (resp.visit_pay_rate) {
            values.contract_visit_pay_rate = resp.visit_pay_rate;
            values.contract_visit_pay_rate.service_pay_rate = resp.visit_pay_rate.visit_pay_rate_card && resp.visit_pay_rate.visit_pay_rate_card;
            values.contract_visit_pay_rate.rate_per_hour = resp.visit_pay_rate.visit_pay_rate_per_hour && resp.visit_pay_rate.visit_pay_rate_per_hour;
            values.contract_visit_pay_rate.visit_contract_pay_rate = resp.visit_pay_rate.visit_pay_rate && resp.visit_pay_rate.visit_pay_rate;
            values.contract_visit_pay_rate.visit_contract_pay_amount = resp.visit_pay_rate.visit_pay_amount && resp.visit_pay_rate.visit_pay_amount;
            values.contract_visit_pay_rate.visit_pay_bill_ratio = resp.visit_pay_rate.visit_pay_bill_ratio && resp.visit_pay_rate.visit_pay_bill_ratio;

          } else {
            values.contract_visit_pay_rate = {};
            values.contract_visit_pay_rate.visit_pay_rate_card = null;
            values.contract_visit_pay_rate.visit_pay_rate_per_hour = null;
            values.contract_visit_pay_rate.visit_pay_rate = null;
            values.contract_visit_pay_rate.visit_pay_amount = null;
            values.contract_visit_pay_rate.visit_pay_bill_ratio = null;
            values.scheduled_manual_pay_rate = null;
            values.scheduled_manual_pay_amount = null;
          }
          if (resp.schedule_travel_rate_card) {
            values.scheduled_visit_travel_rate = resp.schedule_travel_rate_card;
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card_card = resp.schedule_travel_rate_card.schedule_travel_rate_card_card && resp.schedule_travel_rate_card.schedule_travel_rate_card_card;
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card_per_hour = resp.schedule_travel_rate_card.schedule_travel_rate_card_per_hour && resp.schedule_travel_rate_card.schedule_travel_rate_card_per_hour;
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card = resp.schedule_travel_rate_card.schedule_travel_rate_card && resp.schedule_travel_rate_card.schedule_travel_rate_card;
            // values.scheduled_visit_travel_rate.visit_pay_amount = resp.schedule_travel_rate_card.visit_pay_amount && resp.schedule_travel_rate_card.visit_pay_amount;
            // values.scheduled_visit_travel_rate.visit_pay_bill_ratio = resp.schedule_travel_rate_card.visit_pay_bill_ratio && resp.schedule_travel_rate_card.visit_pay_bill_ratio;
            // values.scheduled_manual_pay_rate = resp.visit_manual_pay.manual_pay_rate && resp.visit_manual_pay.manual_pay_rate;
            // values.scheduled_manual_pay_amount = resp.visit_manual_pay.manual_pay_amount && resp.visit_manual_pay.manual_pay_amount;
          } else {
            values.scheduled_visit_travel_rate = {};
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card_card = null;
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card_per_hour = null;
            // values.scheduled_visit_travel_rate.schedule_travel_rate_card = null;
            // values.scheduled_visit_travel_rate.visit_pay_amount = null;
            // values.scheduled_visit_travel_rate.visit_pay_bill_ratio = null;
            // values.scheduled_manual_pay_rate = null;
            // values.scheduled_manual_pay_amount = null;
          }

          if (resp.employee_job_detail_pay_rate) {
            values.scheduled_employee_pay_rate = resp.employee_job_detail_pay_rate;
            if (resp.employee_job_detail_pay_rate.employee_name) {
              values.scheduled_employee_pay_rate.employee_name = resp.employee_job_detail_pay_rate.employee_name;
            } else {
              values.scheduled_employee_pay_rate.employee_name = null;
            }


            values.scheduled_employee_pay_rate.service_pay_rate = resp.employee_job_detail_pay_rate && resp.employee_job_detail_pay_rate.
              service_pay_rate && resp.employee_job_detail_pay_rate.service_pay_rate;

            values.scheduled_employee_pay_rate.employee_pay_rate_hour = resp.employee_job_detail_pay_rate && resp.employee_job_detail_pay_rate.employee_pay_rate_hour && resp.employee_job_detail_pay_rate.employee_pay_rate_hour;

            values.scheduled_employee_pay_rate.employee_visit_pay_rate = resp.employee_job_detail_pay_rate && resp.employee_job_detail_pay_rate.employee_visit_pay_rate;

            values.scheduled_employee_pay_rate.employee_visit_pay_amount = resp.employee_job_detail_pay_rate && resp.employee_job_detail_pay_rate.employee_visit_pay_amount && resp.employee_job_detail_pay_rate.employee_visit_pay_amount;

          }

          if (resp.actual_bill_rate) {
            values.actuals_actual_bill_rate_card = resp.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate_card && resp.actual_bill_rate.actual_bill_rate_card;
            values.actuals_bill_rateperhr = resp.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate_per_hour && resp.actual_bill_rate.actual_bill_rate_per_hour;
            values.actuals_bill_rate = resp.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate;
            values.actuals_bill_amount = resp.actual_bill_rate && resp.actual_bill_rate.actual_bill_rate_amount && resp.actual_bill_rate.actual_bill_rate_amount;
            values.actuals_rounded_bill_amount = resp.actual_bill_rate && resp.actual_bill_rate.actual_final_bill_rate_amount && resp.actual_bill_rate.actual_final_bill_rate_amount;
          }

          if (resp.actual_manual_bill_rate) {
            values.actual_manual_bill_rate = resp.actual_manual_bill_rate && resp.actual_manual_bill_rate.actual_manual_bill_rate;
            values.actual_manual_bill_rate_amount = resp.actual_manual_bill_rate && resp.actual_manual_bill_rate.actual_manual_bill_rate_amount;
            values.actual_manual_final_bill_rate_amount = resp.actual_manual_bill_rate && resp.actual_manual_bill_rate.actual_manual_final_bill_rate_amount;

          }

          if (resp.actual_manual_pay_rate) {
            values.actual_manual_pay_rate = resp.actual_manual_pay_rate && resp.actual_manual_pay_rate.actual_manual_pay_rate;
            values.actual_manual_pay_rate_amount = resp.actual_manual_pay_rate && resp.actual_manual_pay_rate.actual_manual_pay_rate_amount;
            values.actual_manual_final_pay_rate_amount = resp.actual_manual_pay_rate && resp.actual_manual_pay_rate.actual_manual_final_pay_rate_amount;

          }
          if (resp.actual_pay_rate) {
            values.actuals_pay_rate = resp.actual_pay_rate;
            values.actuals_pay_rate.actual_pay_rate_card = resp.actual_pay_rate && resp.actual_pay_rate.actual_pay_rate_card;

            values.actuals_pay_rate.actual_pay_rate_hour = resp.actual_pay_rate && resp.actual_pay_rate.actual_pay_rate_per_hour;

            values.actuals_pay_rate.actual_pay_rate = resp.actual_pay_rate && resp.actual_pay_rate.actual_pay_rate;

            values.actuals_pay_rate.actual_pay_amount = resp.actual_pay_rate && resp.actual_pay_rate.actual_pay_rate_amount;

            values.actuals_pay_rate.actual_manual_final_pay_rate_amount = resp.actual_pay_rate && resp.actual_pay_rate.actual_manual_final_pay_rate_amount;

          } else {
            values.actuals_pay_rate = {};
            values.actuals_pay_rate.actual_pay_rate_card = null;
            values.actuals_pay_rate.actual_pay_rate_hour = null;
            values.actuals_pay_rate.actual_pay_rate = null;
            values.actuals_pay_rate.actual_pay_amount = null;
            values.actuals_pay_rate.actual_manual_final_pay_rate_amount = null;
          }
          if (resp.actual_travel_rate_card) {
            values.actuals_travel_rate = resp.actual_travel_rate_card;
          } else {
            values.actuals_travel_rate = {};
          }
        }

        setValues({ ...values, ...resp.rounded_actual_bill_rate, ...resp.rounded_actual_pay_rate, ...resp.banded_actual_bill_rate, ...resp.banded_actual_pay_rate })

      })
  }

  const dropDownHandleSelect = (item, nameItem, actualName) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values })
    if (values[nameItem]) {
      getDataOnChangeRateID(scoreDataId, values[nameItem], actualName)
        .then(res => {
          if (values[nameItem] == values.scheduled_visit_rates_visit_bill_rate_card) {
            values.scheduled_visit_rates_visit_bill_rateperhr = res.rate_per_hour;
            values.scheduled_visit_rates_visit_bill_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.scheduled_visit_rates_visit_bill_amount = res.amount;
            values.scheduled_visit_rates_visit_amount =  res.amount;
            setValues({ ...values })
          }
          if (values[nameItem] == values.actuals_actual_bill_rate_card) {
            debugger
            values.actuals_bill_rateperhr = res.rate_per_hour;
            values.actuals_bill_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.actuals_bill_amount = res.amount;
            setValues({ ...values })
            
          }
          if (values[nameItem] == values.contract_funder_bill_rate_card) {
            debugger
            values.contract_funder_bill_rateperhr = res.rate_per_hour;
            values.contract_funder_bill_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.contract_funder_bill_amount = res.amount;
            setValues({ ...values })
          }
          if (values[nameItem] == values.contract_funder_pay_rate_card) {
            debugger
            values.contract_funder_pay_rateperhr = res.rate_per_hour;
            values.contract_funder_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.contract_funder_pay_rate_amount = res.amount;
            setValues({ ...values })
          }
          if (values[nameItem] == values.contract_visit_bill_rate_card) {
            debugger
            values.contract_visit_bill_rateperhr = res.rate_per_hour;
            values.contract_visit_bill_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.contract_visit_bill_amount = res.amount;
            setValues({ ...values })
          }
        })
    }
  };


  const dropDownHandleSelectPay = (item, nameItem, objName, actualName, travelName) => {
    if (values[objName] == undefined) {
      values[objName] = {};
    }

    values[objName][nameItem] = item[nameItem];

    setValues({ ...values })

    if (values[objName][nameItem]) {
      getDataOnChangeRateID(scoreDataId, values[objName][nameItem], actualName, travelName)
        .then(res => {

          if (values[objName] == undefined) {
            values[objName] = {};
            values.actuals_pay_rate.actual_pay_rate_hour = res.rate_per_hour;
            values.actuals_pay_rate.actual_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.actuals_pay_rate.actual_pay_amount = res.amount;
            setValues({ ...values })
          } else if (values[objName][nameItem] == values.actuals_pay_rate.actual_pay_rate_card) {
            values.actuals_pay_rate.actual_pay_rate_hour = res.rate_per_hour;
            values.actuals_pay_rate.actual_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.actuals_pay_rate.actual_pay_amount = res.amount;
            setValues({ ...values })
          }

          if (values[objName][nameItem] == values.actuals_travel_rate.actual_travel_rate_card) {
            values.actuals_travel_rate.actual_pay_rate_hour = res.rate_per_hour;
            values.actuals_travel_rate.actual_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.actuals_travel_rate.actual_pay_amount = res.amount;
            setValues({ ...values })
          }

          if (values[objName][nameItem] == values.contract_visit_pay_rate.service_pay_rate) {
            values.contract_visit_pay_rate.rate_per_hour = res.rate_per_hour;
            values.contract_visit_pay_rate.visit_contract_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.contract_visit_pay_rate.visit_contract_pay_amount = res.amount;
            setValues({ ...values })
          }
        })
    }
  };



  const dropDownHandleSelectScheduledVisitPay = (item, nameItem, objName) => {
    if (values[objName] == undefined) {
      values[objName] = {};
    }

    values[objName][nameItem] = item[nameItem];


    setValues({ ...values })

    if (values[objName][nameItem]) {
      getDataOnChangeRateID(scoreDataId, values[objName][nameItem], "consider_schedule")
        .then(res => {

          if (values[objName] == undefined) {
            values[objName] = {};
            values.scheduled_visit_pay_rate.visit_pay_rate_per_hour = res.rate_per_hour;
            values.scheduled_visit_pay_rate.visit_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.scheduled_visit_pay_rate.visit_pay_amount = res.amount;
            setValues({ ...values })
          } else if (values[objName][nameItem] == values.scheduled_visit_pay_rate.visit_pay_rate_card) {
            values.scheduled_visit_pay_rate.visit_pay_rate_per_hour = res.rate_per_hour;
            values.scheduled_visit_pay_rate.visit_pay_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.scheduled_visit_pay_rate.visit_pay_amount = res.amount;
            setValues({ ...values })
          }

        })
    }
  };

  const dropDownHandleSelectScheduledVisitTravel = (item, nameItem, objName, travelName) => {
    if (values[objName] == undefined) {
      values[objName] = {};
    }

    values[objName][nameItem] = item[nameItem];


    setValues({ ...values })
    if (values[objName][nameItem]) {
      getDataOnChangeRateID(scoreDataId, values[objName][nameItem], '', travelName)
        .then(res => {
          if (values[objName] == undefined) {
            values[objName] = {};
            values.scheduled_visit_travel_rate.visit_travel_rate_per_hour = res.rate_per_hour;
            values.scheduled_visit_travel_rate.visit_travel_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.scheduled_visit_travel_rate.visit_travel_amount = res.amount;
            setValues({ ...values })
          } else if (values[objName][nameItem] == values.scheduled_visit_travel_rate.schedule_travel_rate_card) {
            values.scheduled_visit_travel_rate.schedule_travel_rate_per_hour = res.rate_per_hour;
            values.scheduled_visit_travel_rate.schedule_travel_rate = Math.round(((res.rate) + Number.EPSILON) * 100) / 100;
            values.scheduled_visit_travel_rate.schedule_travel_amount = res.amount;
            setValues({ ...values })
          }

        })
    }
  };



  const handleSaveRateDetails = async () => {
    const obj = {
      visit: {
        manual_bill_rate: (values.scheduled_manual_bill_rate === '') ? null : values.scheduled_manual_bill_rate,
        manual_bill_amount: (values.scheduled_manual_bill_amount === '') ? null : values.scheduled_manual_bill_amount,

        contract_bill_rate_card: values.contract_funder_bill_rate_card,
        contract_bill_amount: values.contract_funder_bill_amount,
        contract_bill_rate: values.contract_funder_bill_rate,
        contract_bill_rate_per_hour: values.contract_funder_bill_rateperhr,

        contract_pay_rate_card: values.contract_funder_pay_rate_card,
        contract_pay_amount: values.contract_funder_pay_rate_amount,
        contract_pay_rate: values.contract_funder_pay_rate,
        contract_pay_rate_per_hour: values.contract_funder_pay_rateperhr,

        // visit_bill_rate_card: values.contract_visit_bill_rate_card,
        // visit_bill_rate: values.contract_visit_bill_rate,
        // visit_bill_rate_per_hour: values.contract_visit_bill_rateperhr,
        // visit_bill_amount: values.contract_visit_bill_amount,

        // visit_pay_rate_card: values.contract_visit_pay_rate.service_pay_rate,
        // visit_pay_rate: values.contract_visit_pay_rate.visit_contract_pay_rate,
        // visit_pay_rate_per_hour: values.contract_visit_pay_rate.rate_per_hour,
        // visit_pay_amount: values.contract_visit_pay_rate.visit_contract_pay_amount,


   // commented on 17 jun
        // schedule_bill_rate_card: values.scheduled_visit_rates_visit_bill_rate_card,
        // schedule_bill_rate: values.scheduled_visit_rates_visit_bill_rate,
        // schedule_bill_rate_per_hour: values.scheduled_visit_rates_visit_bill_rateperhr,
        // schedule_bill_amount: values.scheduled_visit_rates_visit_bill_amount,

        // schedule_pay_rate_card: values.scheduled_visit_pay_rate.visit_pay_rate_card,
        // schedule_pay_rate: values.scheduled_visit_pay_rate.visit_pay_rate,
        // schedule_pay_rate_per_hour: values.scheduled_visit_pay_rate.visit_pay_rate_per_hour,
        // schedule_pay_amount: values.scheduled_visit_pay_rate.visit_pay_amount,

        // schedule_travel_rate_card: values.scheduled_visit_travel_rate.schedule_travel_rate_card,
        // schedule_travel_rate_per_hour: values.scheduled_visit_travel_rate.schedule_travel_rate_per_hour,
        // schedule_mileage_rate_per_mile: values.scheduled_visit_travel_rate.schedule_mileage_rate_per_mile,
        // schedule_travel_amount: values.scheduled_visit_travel_rate.schedule_travel_amount,

        manual_pay_rate: (values.scheduled_manual_pay_rate === '') ? null : values.scheduled_manual_pay_rate,
        manual_pay_amount: (values.scheduled_manual_pay_amount === '') ? null : values.scheduled_manual_pay_amount,
      },

      evv: {
        actual_bill_rate_card: values.actuals_actual_bill_rate_card,
        actual_bill_rate: values.actuals_bill_rate,
        actual_bill_rate_per_hour: values.actuals_bill_rateperhr,
        actual_bill_rate_amount: values.actuals_bill_amount,
        rounded_actual_bill_rate: values.rounded_actual_bill_rate,
        rounded_actual_bill_rate_per_hour: values.rounded_actual_bill_rate_per_hour,
        rounded_actual_bill_rate_amount: values.rounded_actual_bill_rate_amount,

        rounded_actual_pay_rate: values.rounded_actual_pay_rate,
        rounded_actual_pay_rate_per_hour: values.rounded_actual_pay_rate_per_hour,
        rounded_actual_pay_rate_amount: values.rounded_actual_pay_rate_amount,

        banded_actual_bill_rate: values.banded_actual_bill_rate,
        banded_actual_bill_rate_per_hour: values.banded_actual_bill_rate_per_hour,
        banded_actual_bill_rate_amount: values.banded_actual_bill_rate_amount,

        banded_actual_pay_rate: values.banded_actual_pay_rate,
        banded_actual_pay_rate_per_hour: values.banded_actual_pay_rate_per_hour,
        banded_actual_pay_rate_amount: values.banded_actual_pay_rate_amount,

        actual_pay_rate_card: values.actuals_pay_rate.actual_pay_rate_card,
        actual_pay_rate_per_hour: values.actuals_pay_rate.actual_pay_rate_hour,
        actual_pay_rate: values.actuals_pay_rate.actual_pay_rate,
        actual_pay_rate_amount: values.actuals_pay_rate.actual_pay_amount,

        actual_travel_rate_card: values.actuals_travel_rate.actual_travel_rate_card,
        actual_travel_rate_per_hour: values.actuals_travel_rate.actual_travel_rate_hour,
        actual_travel_rate: values.actuals_travel_rate.actual_travel_rate,
        actual_travel_rate_amount: values.actuals_travel_rate.actual_travel_amount,

        actual_manual_bill_rate: values.actual_manual_bill_rate,
        actual_manual_bill_rate_amount: values.actual_manual_bill_rate_amount,
        actual_manual_final_bill_rate_amount: values.actual_manual_final_bill_rate_amount,

        actual_manual_pay_rate: values.actual_manual_pay_rate,
        actual_manual_pay_rate_amount: values.actual_manual_pay_rate_amount,
        actual_manual_final_pay_rate_amount: values.actual_manual_final_pay_rate_amount,
      // added on 17 jun 
      schedule_bill_rate_card: values.scheduled_visit_rates_visit_bill_rate_card,
      schedule_bill_rate: values.scheduled_visit_rates_visit_bill_rate,
      schedule_bill_rate_per_hour: values.scheduled_visit_rates_visit_bill_rateperhr,
      schedule_bill_amount: values.scheduled_visit_rates_visit_bill_amount,

      schedule_pay_rate_card: values.scheduled_visit_pay_rate.visit_pay_rate_card,
      schedule_pay_rate: values.scheduled_visit_pay_rate.visit_pay_rate,
      schedule_pay_rate_per_hour: values.scheduled_visit_pay_rate.visit_pay_rate_per_hour,
      schedule_pay_amount: values.scheduled_visit_pay_rate.visit_pay_amount,

      schedule_travel_rate_card: values.scheduled_visit_travel_rate.schedule_travel_rate_card,
      schedule_travel_rate_per_hour: values.scheduled_visit_travel_rate.schedule_travel_rate_per_hour,
      schedule_mileage_rate_per_mile: values.scheduled_visit_travel_rate.schedule_mileage_rate_per_mile,
      schedule_travel_amount: values.scheduled_visit_travel_rate.schedule_travel_amount,

      }
    }

    Object.keys(obj.visit).forEach(key => {
      if (obj.visit[key] === null) {
        delete obj.visit[key];
      }
    });

    Object.keys(obj.evv).forEach(key => {
      if (obj.evv[key] === null) {
        delete obj.evv[key];
      }
    });

    try {
      let res = await setUpdateRateDetailsData(obj, scoreDataId);
      if (res) {
        handleAddVisitModal();
      }
    } catch (error) {
    } finally {
      searchScheduler();
      handleAddVisitModal();
    }
  }



  return (
    <ComponentDynamic loading={loadingModal}>
      <div >
        <>

          <div className={'mb-3'}>
            <TitleSection>Contracted</TitleSection>
            <div className={'mb-1 mt-2'}>
              <small> <TitleSection>Funder</TitleSection></small>
              <FlexGrid3Container className={'w-100 mb-1 '}>
                <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'40%'} >
                  <DropdownSearchable
                    placeHolder={'Bill Rate Card'}
                    options={billRate}
                    disabled={true}
                    selectedOption={values ? values.contract_funder_bill_rate_card : null}
                    onChange={value =>
                      dropDownHandleSelect(
                        { contract_funder_bill_rate_card: value ? value : null }, 'contract_funder_bill_rate_card'
                      )}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'15%'} >
                  <InputLabelTop
                    readOnly={true}
                    label={'Bill Rate/hr'}
                    type="text"
                    {...useInput(`contract_funder_bill_rateperhr`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Funder Contract Bill Rate'}
                    type="text"
                    {...useInput(`contract_funder_bill_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1 " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Funder Contract Bill Amount'}
                    type="text"
                    {...useInput(`contract_funder_bill_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container >

              <FlexGrid3Container className={'w-100 mt-1 '}>
                <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'40%'}>
                  <DropdownSearchable
                    placeHolder={'Pay Rate Card'}
                    disabled={true}
                    options={payRate}
                    selectedOption={values ? values.contract_funder_pay_rate_card : null}
                    onChange={value =>
                      dropDownHandleSelect(
                        { contract_funder_pay_rate_card: value ? value : null }, 'contract_funder_pay_rate_card'
                      )}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'15%'} >
                  <InputLabelTop
                    readOnly={true}
                    label={'Pay Rate/hr'}
                    type="text"
                    {...useInput(`contract_funder_pay_rateperhr`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Funder Contract Pay Rate'}
                    type="text"
                    {...useInput(`contract_funder_pay_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Funder Contract Pay Amount'}
                    type="text"
                    {...useInput(`contract_funder_pay_rate_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container >
            </div>
            {/* for visit  */}
            {/* <div className={'mb-1 mt-2'}>
              <small> <TitleSection>Visit</TitleSection></small>
              <FlexGrid3Container className={'w-100 mb-1 '}>
                <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'40%'}>
                  <DropdownSearchable
                    placeHolder={'Bill Rate Card'}
                    options={billRate}
                    disabled={!editMode}
                    selectedOption={values ? values.contract_visit_bill_rate_card : null}
                    onChange={value =>
                      dropDownHandleSelect(
                        { contract_visit_bill_rate_card: value ? value : null }, 'contract_visit_bill_rate_card'
                      )}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'15%'} >
                  <InputLabelTop
                    readOnly={true}
                    label={'Bill Rate/hr'}
                    type="text"
                    {...useInput(`contract_visit_bill_rateperhr`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Visit Contract Bill Rate'}
                    type="text"
                    {...useInput(`contract_visit_bill_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    label={'Visit Contract Bill Amount'}
                    type="text"
                    {...useInput(`contract_visit_bill_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container >

              <VisitPayRateCard
                dropDownHandleSelectPay={dropDownHandleSelectPay}
                useInput={useInput}
                values={values}
                setValues={setValues}
                datapay={values.contract_visit_pay_rate}
                billRate={billRate}
                payRate={payRate}
                editMode={editMode}

              />

            </div> */}
          </div>
        </>

        <>
          {/* for Scheduled */}
          <div className={'mb-3 mt-4 pt-1'}>
            <TitleSection>Scheduled</TitleSection>
            {/* for frunder within contracted */}
            <div className={'mb-1 mt-2 ml-1'}>
              <small> <TitleSection>Visit Rates</TitleSection></small>
              <FlexGrid3Container className={'w-100 mb-1 '}>
                <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'40%'} >
                  <DropdownSearchable
                    placeHolder={'Visit Bill Rate Card'}
                    disabled={!editMode}
                    options={billRate}
                    selectedOption={values ? values.scheduled_visit_rates_visit_bill_rate_card : null}
                    onChange={value =>
                      dropDownHandleSelect(
                        { scheduled_visit_rates_visit_bill_rate_card: value ? value : null }, 'scheduled_visit_rates_visit_bill_rate_card', "consider_schedule"
                      )}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'15%'} >
                  <InputLabelTop
                    readOnly={true}
                    disabled={true}
                    label={'Visit Bill Rate/hr'}
                    type="text"
                    {...useInput(`scheduled_visit_rates_visit_bill_rateperhr`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    disabled={true}
                    label={'Visit Bill Rate'}
                    type="text"
                    {...useInput(`scheduled_visit_rates_visit_bill_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'20%'}>
                  <InputLabelTop
                    readOnly={true}
                    disabled={true}
                    label={'Visit  Bill Amount'}
                    type="text"
                    {...useInput(`scheduled_visit_rates_visit_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container >

              <ScheduledVisitPay
                dropDownHandleSelectPay={dropDownHandleSelectScheduledVisitPay}
                billRate={billRate}
                payRate={payRate}
                useInput={useInput}
                values={values}
                setValues={setValues}
                datapay={values.scheduled_visit_pay_rate}
                editMode={editMode}

              />

              <ScheduledVisitTravel
                dropDownHandleSelectPay={dropDownHandleSelectScheduledVisitTravel}
                billRate={billRate}
                payRate={payRate}
                travelRate={travelRate}
                useInput={useInput}
                values={values}
                setValues={setValues}
                datapay={values.scheduled_visit_travel_rate}
                editMode={editMode}

              />

            </div>
            {/* forEmployee Rates */}
            <div className={'mb-1 mt-1  '}>

              {values.scheduled_employee_pay_rate ? (
                <ScheduledEmployeePay
                  dropDownHandleSelect={dropDownHandleSelect}
                  billRate={billRate}
                  useInput={useInput}
                  values={values}
                  setValues={setValues}
                  datapay={values.scheduled_employee_pay_rate}
                  payRate={payRate}
                />
              ) : (null)}

              <FlexGrid3Container className={'w-100 mb-2 mt-1 '}>
                <div style={{ margin: 'auto 0 ', width: '25%' }} className={`pr-1 mr-1 ml-1`}><small> <TitleSection>Scheduled Manual Bill Rate</TitleSection></small></div>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'35%'}>
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Schedule  Manual Bill Rate'}
                    type="text"
                    {...useInput(`scheduled_manual_bill_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'35%'}>
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Schedule  Manual Bill Amount'}
                    type="text"
                    {...useInput(`scheduled_manual_bill_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
              <FlexGrid3Container className={'w-100  mt-2 '}>
                <div style={{ margin: 'auto 0 ', width: '25%' }} className={`pr-1 mr-1 ml-1`}><small> <TitleSection>Scheduled Manual Pay Rate</TitleSection></small></div>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'35%'} >
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Schedule  Manual Pay Rate'}
                    type="text"
                    {...useInput(`scheduled_manual_pay_rate`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'35%'}>
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Schedule  Manual Pay Amount'}
                    type="text"
                    {...useInput(`scheduled_manual_pay_amount`)}
                    maxlength={5}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
          </div>
        </>
        <>
          {(AssignedName) ? (
            <div className={'mb-3  pt-1'}>
              <TitleSection>Actuals</TitleSection>
              <div className={'mb-1 mt-2'}>
                {(AssignedName ? (
                  <ActualBillRate
                    dropDownHandleSelect={dropDownHandleSelect}
                    billRate={billRate}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    editMode={editMode}
                  />
                ) : (null))}

                {AssignedName ? (
                  <ActualEmployeePay
                    dropDownHandleSelectPay={dropDownHandleSelectPay}
                    billRate={billRate}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    datapay={values.actuals_pay_rate}
                    editMode={editMode}
                    payRate={payRate}
                  />
                ) : (null)}
                {AssignedName ? (
                  <ActualEmployeeTravel
                    dropDownHandleSelectPay={dropDownHandleSelectPay}
                    billRate={billRate}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    datapay={values.actuals_travel_rate}
                    editMode={editMode}
                    payRate={payRate}
                    travelRate={travelRate}
                  />
                ) : (null)}

                {(AssignedName ? (
                  <ActualManualBillRate
                    dropDownHandleSelect={dropDownHandleSelect}
                    billRate={billRate}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                  />
                ) : (null))}

                {AssignedName ? (
                  <ActualManualPayRate
                    dropDownHandleSelect={dropDownHandleSelect}
                    billRate={billRate}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    payRate={payRate}
                    editMode={editMode}
                  />
                ) : (null)}
              </div>

            </div>
          ) : (null)}
        </>
        <div className="d-flex justify-content-end  mt-3 mb-1  mr-1 pt-1" >
          <PrimaryButtonForm minWidth="6rem"
            onClick={handleSaveRateDetails}
          >
            <span className={'mr-2'}    >SAVE</span>
            {FLOPPY}
          </PrimaryButtonForm>
        </div>
      </div>
    </ComponentDynamic>
  )
}

export default RateDetails