import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from '@mui/material'
import { ModalContainer } from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import './Signature.css'
import { PrimaryButtonForm } from 'shared/styles/buttons';

function ModalSignature({
    openSignature,
    setOpenSignature,
    setSignature,
    sigRef,
    fullBind,
    setSignModalValue
}) {
    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL('image/png'));
        setOpenSignature(false)
        setSignModalValue(false)
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    }
    // modal-dialog 
    return (
        <div className='prn-sign'>
            <ModalContainer max_width={'60%'}>
                <ModalDynamic
                    {...fullBind}
                    max_width={'60%'}
                    minWidthTitle="8.5rem"
                    loading={false}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SignatureCanvas
                            penColor="black"
                            canvasProps={{ width: 600, height: 200, className: 'sigCanvas' }}
                            ref={sigRef}
                        //  onEnd={handleSignatureEnd}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", padding: '10px 0' }}>
                        <PrimaryButtonForm
                            className='bg-danger'
                            type="button"
                            style={{
                                marginRight: '10px',
                                width: '84px'
                            }}
                            onClick={() => {
                                clearSignature()
                            }}>Clear</PrimaryButtonForm>
                        <PrimaryButtonForm
                            style={{
                                width: '84px'
                            }}
                            type="button"
                            onClick={() => {
                                handleSignatureEnd()
                            }}>Save</PrimaryButtonForm>


                    </div>
                </ModalDynamic>
            </ModalContainer>
        </div>
    )
}

export default ModalSignature