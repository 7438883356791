import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FlexGrid3Container, FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  HOUR,
  MINUTE
} from 'utils/constants/serviceVisits';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
const BulkReviewMedication = ({
  fullBind,
  useInput,
  values,
  choices,
  editMode,
  handleBulkReview,
  errorMessage,
  showError,
  dropDownHandleSelect = () => { }
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.review_comment`);
  const newDoseTime = useInput(
    `medication.review_time`, 'isRequired');
  const review_date_hook = useInput('medication.review_date', 'isRequired')
  const review_status_hook = useInput(`medication.review_status`, 'isRequired')

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication Review',
    });
  }, [fullBind]);

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className='pt-2 pl-3 pr-3 pb-3'>
          <FlexGrid3Container className={'w-100 mb-2'}>
            <FlexGrid3Item >
              <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Review Status'}
                options={choices.medication_review_status || []}
                {...review_status_hook}
                selectedOption={values.medication ? values.medication.review_status : null}
                onChange={value =>
                  dropDownHandleSelect({ review_status: value ? value : null }, 'review_status')
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item >
              <DateSelector
                isRequired
                disabled={!editMode}
                labelTop="Review Date"
                {...review_date_hook}
              />
            </FlexGrid3Item>
            <FlexGrid3Item >
              <TimeSelector
                disabled={!editMode}
                // data-error={timeError[index]}
                // errorMessage={timeErrorMessage}
                isRequired={true}
                labelTop={'Review Time'}
                {...newDoseTime}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>

          </FlexGrid3Container>
          <div className="d-flex mt-2">
            <div className="w-100 pr-1">
              <div
                className={'pt-2 ml-md-1 ml-0 text-center'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Review Comment
              </div>
              <InputTextArea
                type="text"
                height={'3.4rem'}
                id="emp_desc"
                placeHolder={'Review Comment'}
                label={'Review Comment'}
                {...ins_hook}
              //   maxlength={5}
              />
            </div>
          </div>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ml-0 ml-md-3 mr-2'}>
                {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleBulkReview}
                >
                  Save
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default BulkReviewMedication;