const { default: styled } = require("styled-components");

export const ModalTabsContainer = styled.div`
postion:fixed !important;
background-color:'#F0F0F7' !important;
width:'100%'  !important;
// margin-left:-36px;
padding:10px;
z-index: 2;
display:flex;
justify-content:around;
text-align:center;
margin-top:23px;

@media (max-width: 456px) {
  background-color:'#F0F0F7' !important;

  overflow-x: scroll !important;;
  font-size: 15px;
  padding-right: 25px;
  padding-left: 1px;
  margin-top:88px;
}
`;


