import React, { useEffect, useRef, useState, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import Image from 'components/SharedComponents/Image';
import Dropdown from 'components/SharedComponents/Dropdown';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import EditModal from './EditModal';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
// import {getPageToSearchParam} from 'shared/methods';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import { GrSearch } from 'react-icons/gr';
import { MdEdit } from 'react-icons/md';
import { FaHistory } from "react-icons/fa";
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  Col,
  ColsGrouper,
  IconWrapper,
  Row,
  UserImg,
  UserImgContainer,
} from 'components/SharedComponents/Table/styles';
import {
  DropdownTypesContainer,
  HeaderXsBreak,
  HeaderLgBreak,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
  Title,
  FiltersLeftGroup,
  FiltersContainer,
  PublishButton
} from 'shared/styles/constants/tagsStyles';
import { headerColumns } from './headerColumnsList';
import { EX_VERY_LARGE_COL, LARGE_COL, } from 'shared/styles/constants/columns';
import {
  deleteUser,
  exportUsers,
  getUsers,
  getUsersTypes,
  patchUser,
} from '../../../../utils/api/UsersApi';
import queryString from 'query-string';
import { PAGINATION_PARAM } from '../../../../utils/constants/queryParams';
import { MEDIUM_COL } from '../../../../shared/styles/constants/columns';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { CHECK_CIRCLE, DOWNLOAD, ERROR_OUTLINE } from '../../../../utils/constants/icons';
import { MessageOptaPlanner } from '../../Scheduler/styles';
import ModalInformation from '../../../SharedComponents/ModalInformation';
import LoadingModal from './LoadindModal';
import {
  USER_SETTINGS,
  USER_SETTINGS_EXPORT,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from '../../../../utils/constants/permissions';
import { AppContext } from "context"
import HistoryModal from './HistoryModal';
import { setting_user_choices } from 'utils/choiceConstant';
import moment from 'moment';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { PrimaryButton } from 'shared/styles/buttons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Users = props => {
  const history = useHistory()
  const { values, setValues, useInput } = useForm({limitperpage : PAGE_SIZE_RENDER, ordering : 'name'});
  const [showSearch, setShowSearch] = useState(false);
  const [users, setUsers] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersRoles, setUsersRole] = useState([]);
  const [usersType, setUsersType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({})
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [updatePermission, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const mountedRef = useRef(true);
  const { contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const { contextChoices } = useContext(AppContext);
  const [disableExport, setDisableExport] = useState(false)
  const [exportPermission, setExportPermission] = useState(false)
  const [roleTypeFilter, setRoleTypeFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])
  const [typeStatusFilter, setTypeStatusFilter] = useState([])
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const {
    setValue: setHistoryModal,
    value: historyModal,
    setTitle: historyTitle,
    fullBind: historyBind,
  } = useModal(false);
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(setting_user_choices)

    return () => {
      mountedRef.current = false;
    };
  }, [])
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const fullNameCol = "name";


  const dropDownHandleSelect = item => {

    setValues(prevState => ({ ...prevState, ...item }));
  };

  const keyPressed = event => {
    if (event.key === 'Enter') {
      search();
    }
  };

  useEffect(() => {
    // loadUsersRoles();

    const roles = contextPermissions;

    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === USER_SETTINGS) {
          rolePermissions[USER_SETTINGS] = {};
          rolePermissions[USER_SETTINGS][WEB_PERMISSION] = {};
          rolePermissions[USER_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[USER_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[USER_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        if (item?.section?.name === USER_SETTINGS_EXPORT) {
          rolePermissions[USER_SETTINGS_EXPORT] = {};
          rolePermissions[USER_SETTINGS_EXPORT][WEB_PERMISSION] = {};
          rolePermissions[USER_SETTINGS_EXPORT][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[USER_SETTINGS_EXPORT][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[USER_SETTINGS_EXPORT][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });
    }
    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);

      setExportPermission(true)
    }
    else {

      setCreatePermissions(
        rolePermissions[USER_SETTINGS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
      );

      setDeletePermissions(
        rolePermissions[USER_SETTINGS]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION]
      );

      setUpdatePermissions(
        rolePermissions[USER_SETTINGS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION]
      );

      if (rolePermissions[USER_SETTINGS_EXPORT]) {
        setExportPermission(rolePermissions[USER_SETTINGS_EXPORT]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
      }
    }

  }, [contextPermissions, contextIsSuperAdmin, contextIsAdmin]);




  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // values.type,
    // values.status,
    // values.role,
    values.ordering,
    props.location.search,
    values.limitperpage,
  ]);

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setCurrentPage(offset + 1);

    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage;
    return {
      offset,
    };
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage,
    );

    getUsers({ ...values, ...pageToSearch }, values.limitperpage)
      .then(response => {
        if (!mountedRef.current) return null;

        setUsers(response.results || []);

        setPages(Math.ceil(response.count / values.limitperpage));
      })
      .catch(err => {
        // setErrorMessage({...errorMessage,message: err.message})
        // setShowErrorMessage(true);
      });
  };

  const setQuerySort = querySort => {

    setValues({ ...values, ...querySort });
  };

  // const loadUsersRoles = () => {
  //   getRoles(values).then(response => {
  //     if (!mountedRef.current) return null;

  //     response.unshift({id: '', name: 'All '});
  //     setUsersRole(response);
  //   });
  // };
  const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  useEffect(() => {
    setUsersRole(contextChoices.roles && contextChoices.roles.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
  
    setUsersType(contextChoices.user_type && contextChoices.user_type.map(type => {
      return { id: type, name: capitalizeText(type) }
    }))
  }, [contextChoices])

console.log(usersType)
  const loadUsersTypes = () => {
    getUsersTypes().then(response => {
      if (!mountedRef.current) return null;

      response.unshift({ id: '', name: 'All ' });
      setUsersRole(response);
    });
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setTitle('Edit User');
    setModalValue(true);
  };
  const handleRowClicks = id => {
    setIdSelected(id);
    setTitle('History Details of  User');
    setHistoryModal(true);
  };

  const toDeleteUser = id => {

    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDeleteUser = () => {

    deleteUser(idSelected).then(response => {

      setModalDeleteValue(false);
      search();
    })
  };

  const handelActiveOrInActive = async (id, is_active) => {

    try {
      setLoading(true);
      const response = await patchUser({ id, is_active: !is_active, activeStatus: true });
      if (response.statusCode !== 202) throw new Error('Please try again.')
      console.log(users)
      users.forEach((user, index) => {
        if (user.id === id) {
          setUsers(prevState => {
            prevState.splice(index, 1, { ...response, statusCode: undefined })
            return [...prevState];
          })
        }
      })
      setLoading(false);
    } catch (err) {

      setLoading(false);
      setErrorMessage({ ...errorMessage, message: err.message });
      setShowErrorMessage(true);
    }
  }

  const handleErrorModelCancel = () => {
    setShowErrorMessage(false);
  };

  const handleSuccessModelCancel = () => {
    setShowSuccessMessage(false)
  }

  // const handelShowSuccessModal = () =>{
  //   setShowSuccessMessage(true);
  // }

  const handelSaveUser = (u) => {
    users.forEach((user, index) => {
      if (user.id === u.id) {
        setUsers(prevState => {
          prevState.splice(index, 1, { ...u })
          return [...prevState];
        })
      }
    })
  }
  const handelShowSuccessModal = () => {
    setShowSuccessMessage(true);
  }


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const handleExport = () => {
    let fileName = 'export_settings_users_details' + moment().format('YYYY-MM-DD');
    setDisableExport(true);
    exportUsers(values)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          setDisableExport(false);
        });
      })
      .catch(error => {
        console.error(error)
        setDisableExport(false);
      });
  };

  const filterRoleType = (item) => {
    setRoleTypeFilter([...item])
  }
  const filterType = (item) => {
    setTypeFilter([...item])
  }
 
  const hitSearch = () => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.set('page', 1);
    const newUrl = `${history.location.pathname}?${urlSearchParams.toString()}`;
    history.push(newUrl);
    search()
  }


  return (
    <div    >
      <Title>Users</Title>

      <HeaderLgBreak>

        <HeaderXsBreak>
          <FiltersContainer>
            <FiltersLeftGroup>
              <DropdownTypesContainer className="mr-2     ">
                <NoSelectAllDropdownFilter
                  items={usersRoles || []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ role: items });
                  }}
                  itemsSelected={roleTypeFilter}
                  setSelectedFilter={filterRoleType}
                  placeHolder={'Role'}
                  isObject
                  allOption
                />
              </DropdownTypesContainer>
              <DropdownTypesContainer className="mr-2    ">
                <NoSelectAllDropdownFilter
                  items={usersType || []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ type: items });
                  }}
                  itemsSelected={typeFilter}
                  setSelectedFilter={filterType}
                  placeHolder={'Type'}
                  isObject
                  allOption
                />
              </DropdownTypesContainer>
            </FiltersLeftGroup>

            <DropdownTypesContainer className="mr-2     ">
              <Dropdown
                items={[
                  { id: '', name: 'All' },
                  { id: true, name: 'Active' },
                  { id: false, name: 'Inactive', },
                ]}
                onSelect={item => {
                  dropDownHandleSelect({ status: item });
                }}
                placeHolder={'Status'}
                appendItem=""
                isObject
                allOption
              />
              {/* <NoSelectAllDropdownFilter
                items={[
                  { id: '', name: 'All' },
                  { id: true, name: 'Active' },
                  { id: false, name: 'Inactive', },
                ]}
                setItemsSelected={items => {
                  dropDownHandleSelect({ status: items });
                }}
                itemsSelected={typeStatusFilter}
                setSelectedFilter={filterStatusType}
                placeHolder={'Status'}
                isObject
                allOption
              /> */}
            </DropdownTypesContainer>

            <DropdownTypesContainer className="mr-2 mb-1">
              <PrimaryButton
                type="button"
                onClick={hitSearch}
                style={{ marginLeft: '4px', marginBottom: "2px" }}>
                <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
              </PrimaryButton>
            </DropdownTypesContainer>
          </FiltersContainer>
        </HeaderXsBreak>

        <div className='d-flex align-items-center'>
          <SearchAddContainer className="d-flex align-items-center ">
            <SearchIcon
              onClick={() => {
                setShowSearch(prev => !prev);
              }}>
              <GrSearch />
            </SearchIcon>

            <SearchContainer show={showSearch}>
              <Fade collapse when={showSearch}>
                <InputLabelTop
                  no_internal_margin="true"
                  type="text"
                  id="search"
                  label={'Search'}
                  {...useInput('search')}
                  onBlur={() => {
                    search();
                  }}
                  onKeyPress={e => {
                    keyPressed(e);
                  }}
                />
              </Fade>
            </SearchContainer>
          </SearchAddContainer>

          {exportPermission &&
            <PublishButton
              className='mb-1 d-flex align-items-center'
              disable={disableExport}
              onClick={!disableExport && handleExport}
            >
              {DOWNLOAD}
            </PublishButton>}
        </div>
      </HeaderLgBreak>

      <Table headerColumns={headerColumns} callBackQuerySort={setQuerySort} fullNameCol={fullNameCol}>
        <div>
          {users.map((user, index) => {
            return (
              <div key={index}>
                <Row bgColor>
                  <ColsGrouper className="d-flex">
                    <Col
                      Initials
                      max_width={EX_VERY_LARGE_COL}
                      Shrink
                      ShadowMobile
                      Capitalize
                      Center   >
                      <UserImgContainer>
                        <UserImg>
                          <Image
                            src={user.photo ? user.photo : ''}
                            name={user.first_name}
                            lastName={user.last_name}
                            alt={'user-img'}
                            fontSize={'0.9rem'}
                          />

                        </UserImg>
                        <label className="align-self-center mb-0 ">
                          {user.name || user.first_name}
                        </label>
                      </UserImgContainer>
                    </Col>
                    <Col max_width={EX_VERY_LARGE_COL} justifyContent={'center'} Center Shrink>
                      <label style={{ marginLeft: '20px' }}>
                        {user.user_type
                          ? user.user_type?.split('')[0].toUpperCase() +
                          user.user_type.slice(1) || ''
                          : ''}
                      </label>
                    </Col>
                    <Col
                      className="sm-hidden"
                      max_width={MEDIUM_COL}
                      justifyContent={'center'}>

                      <label style={{ marginLeft: '20px' }}>{user?.status || ''}</label>
                    </Col>
                    <Col
                      className="sm-hidden"
                      max_width={MEDIUM_COL}
                      justifyContent={'center'}>
                      <label>{user?.role || ''}</label>
                    </Col>
                    <Col
                      className="sm-hidden"
                      max_width={MEDIUM_COL}
                      justifyContent={'center'}>
                      <label>{user.branch_access ? user.branch_access.join(', ') : ''}</label>
                    </Col>
                    <Col
                      justifyContent={'center'}
                      className="md-hidden"
                      max_width={LARGE_COL}>
                      <label>{user.email}</label>
                    </Col>
                  </ColsGrouper>
                  {updatePermission && (



                    <IconWrapper
                      style={{ color: "purple" }}
                      onClick={() => {
                        handleRowClick(user.id);
                      }}>
                      <MdEdit />
                    </IconWrapper>

                  )}

                  {updatePermission && (

                    <IconWrapper
                      style={{ color: "purple" }}
                      onClick={() => {
                        handleRowClicks(user.id);
                      }}>
                      <FaHistory />
                    </IconWrapper>
                  )}



                  {user.is_active && (
                    <IconWrapper
                      onClick={() =>
                        updatePermission
                          ? handelActiveOrInActive(user.id, user.is_active)
                          : null
                      }>
                      <IconContext.Provider
                        value={{ color: 'green', size: '2.1rem' }}>
                        <RiCheckboxCircleFill />
                      </IconContext.Provider>
                    </IconWrapper>
                  )}
                  {!user.is_active && (
                    <IconWrapper
                      onClick={() =>
                        updatePermission
                          ? handelActiveOrInActive(user.id, user.is_active)
                          : null
                      }>
                      <IconContext.Provider
                        value={{ color: 'red', size: '2.1rem' }}>
                        <RiCloseCircleFill />
                      </IconContext.Provider>
                    </IconWrapper>
                  )}
                  {/* </Col> */}

                  {deletePermission && (
                    <IconWrapper
                      style={{ color: "red" }}
                      onClick={() => {
                        toDeleteUser(user.id);
                      }}>
                      <RiDeleteBinLine />
                    </IconWrapper>
                  )}

                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
          <div className="mt-3">
            <Pagination
              totalPages={pages}
              currentPage={currentPage} values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={users.length}
            />
          </div>
        </div>
      </Table>


      {!modalValue ? null : (
        <EditModal
          // setModalValue={setModalValue}
          Id={idSelected}
          // roles={usersRoles.filter((item, index) => index !== 0)}
          roles={usersRoles}
          setUsers={handelSaveUser}
          showSuccessModal={handelShowSuccessModal}
          setModalValue={() => {
            setModalValue(!modalValue);
          }}
          fullBind={fullBind}
        />
      )}


      {!historyModal ? null : (
        <HistoryModal
          // setModalValue={setModalValue}
          Id={idSelected}
          // roles={usersRoles.filter((item, index) => index !== 0)}
          roles={usersRoles}
          setUsers={handelSaveUser}
          showSuccessModal={handelShowSuccessModal}
          setModalValues={() => {
            setHistoryModal(!historyModal);
          }}
          fullBind={historyBind}
        />
      )}




      <LoadingModal
        showModal={loading}
        title={'Changing the status'}
      />

      <ModalInformation
        type={'error'}
        title={'Incorrect Email.'}
        onCancelTitle={'Cancel'}
        onOkTitle={'Ok'}
        onOk={handleErrorModelCancel}
        icon={ERROR_OUTLINE}
        hideOnCancel={true}
        onCancel={handleErrorModelCancel}
        showModal={showErrorMessage}>
        <MessageOptaPlanner className={'mt-4 mb-3'}>
          <div className={'warningMessage pl-3 pr-3'}>
            {errorMessage.message || 'The email for password reset is' +
              ' incorrect'}
          </div>
        </MessageOptaPlanner>
      </ModalInformation>

      <ModalInformation
        type={'success'}
        title={'Email Sent..'}
        onCancelTitle={'Cancel'}
        onOkTitle={'Ok'}
        icon={CHECK_CIRCLE}
        hideOnCancel={true}
        onOk={handleSuccessModelCancel}
        showModal={showSuccessMessage}>
        <MessageOptaPlanner className={'mt-4 mb-3'}>
          <div className={'warningMessage pl-3 pr-3'}>
            The mail is send for the reset password.
          </div>
        </MessageOptaPlanner>
      </ModalInformation>


      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this user? \n DELETING the user will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the user status to discard instead to retain database records.'
        }
        onOk={handleDeleteUser}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};


export default Users;
