import { methods } from 'utils/constants/api';
import { get, post, patch, deleteFetch } from './Api'
import { getTenant, getToken } from 'utils/localStorage/token';
import { apiURL } from 'utils/env';
import queryString from 'query-string';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
const { PUT, POST, PATCH } = methods;

const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

const getBaseUrl3 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v3`;
};

export const setLocation = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  let formData = new FormData()

  for (let [key, value] of Object.entries(values)) {
    if (typeof value == 'object' && key !== 'photo') {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  }
  for (const value of formData.values()) {
    console.log(value);
  }
  const url = values.id
    ? `${baseUrl}/clients/client/location/${values.id}/`
    : `${baseUrl}/clients/client/location/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const getLocations = async (values, search) => {
  const baseUrl = getBaseUrl();
  console.log(values, "values")
  let stringified = '';
  let toParse = {};
  let filterParse = {}
  toParse.offset = values.offset;
  toParse.limit = values.limitperpage;

  if (values.branch) {
    const branchIds = values.branch.map(b => b.id)
    const stringId = branchIds.join(",")
    filterParse.branch = stringId
  } else if (values.headerbranch) {
    const headerBranchIds = values.headerbranch.map(h => h.id)
    const stringId = headerBranchIds.join(",")
    filterParse.branch = stringId
  }
  if (values.group_area) {
    const groupAreaIds = values.group_area.map(g => g.id)
    const stringId = groupAreaIds.join(",")
    filterParse.group_area = stringId
  }
  if (values.status) {
    const statusIds = values.status.map(s => s.id)
    const stringId = statusIds.join(",")
    filterParse.status = stringId
  }
  stringified = queryString.stringify(toParse) || '';

  if (filterParse.branch) {
    stringified += '&branch__id__in=' + filterParse.branch;
  }
  if (filterParse.group_area) {
    stringified += '&location_run_routes=' + filterParse.group_area;
  }
  if (filterParse.status) {
    stringified += '&status__id__in=' + filterParse.status;
  }
  if (search) {
    stringified += '&search=' + decodeURIComponent(search);
  }

  if (values.ordering) {
    stringified += '&ordering=' + values.ordering;
  }

  const url = `${baseUrl}/clients/client/location/?${stringified}`
  let encoded = encodeURIComponent(url);
  let decoded = decodeURIComponent(encoded);
  return await get(decoded)
}

export const getLocationById = async (id) => {
  const baseUrl = getBaseUrl()
  const url = `${baseUrl}/clients/client/location/${id}/`

  return await get(url)
}


export const deleteLocation = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/location/${id}/`

  return await deleteFetch(url)
}

export const setClientContractLocation = async (contract, clientId) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(contract)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'visits' &&
      key !== 'service_funders' &&
      key !== 'bill_rate' &&
      key !== 'pay_rate' &&
      key !== 'travel_pay_rate'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key === 'visits' ||
      key === 'service_funders' ||
      key === 'bill_rate' ||
      key === 'pay_rate' ||
      key === 'travel_pay_rate'
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  // if (!client.id) {
  //   formData.append('is_active', true);
  // }

  const method = contract.id ? PATCH : POST;
  const url = contract.id
    ? `${baseUrl}/clients/${clientId}/services/${contract.id}/?location_id=${clientId}`
    : `${baseUrl}/clients/${clientId}/services/?location_id=${clientId}`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  //added new lines
  // if (response && response.status === unauthorized_status_code) {
  //   clearToken();
  //   window.location.reload();
  // }

  const json = await response.json();

  return { status: response.status, message: json };
};


export const getClientsServicesLocation = values => {
  const baseUrl = getBaseUrl3();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';
  stringified = stringified + '&is_location=true';

  // const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;
  const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;


  return get(url);
};

export const getClientsServicesByIdLocation = (clientId, serviceId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/?is_location=true`;

  return get(url);
};