module.exports = {
  TOKEN: 'token',
  TENANT: 'tenant',
  USER_NAME: 'user_name',
  USER_FIRST_NAME: 'user_first_name',
  USER_LAST_NAME: 'user_last_name',
  USER_EMAIL: 'user_email',
  USER_ID: 'user_id',
  USER_CATEGORY_PERMISSIONS: 'category_role_permission',
  USER_SUB_SECTION_PERMISSIONS: 'sub_section_role_permission',
  FILTERED_PERMISSIONS: 'filtered_permissions',
  PERMISSIONS: 'role_permission',
  USER_LOGIN_ORGANIZATION: 'default_login_organization',
  ORGNAME: 'name',
  SCHEMA_NAME: 'schema_name',
  EMPLOYEE:'Employee',
  CLIENT:'Client',
  IS_ADMIN:'is_admin',
  IS_STAFF:'is_staff',
  IS_SUPER_ADMIN:'is_superAdmin',
  ROLES_AND_PERMISSIONS: 'roles_and_permissions',
  BAD_GATEWAY: "bad_gateway"
};
