import React, {useState, useEffect, useRef} from 'react';
import {useForm} from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  getLeftButton,
  getRightButton,
  SpecializeCaseButton,
} from './IconsMethods';
import {useModal} from 'hooks/ModalHook';
import {MdEdit} from 'react-icons/md';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {PURPLE, SOFT_RED, GRAY_300} from 'shared/styles/constants/colors';
import {Toast} from 'primereact/toast';
import {ON_EDIT} from 'utils/constants/settingsStates';
import {IconWrapper} from 'shared/styles/constants/tagsStyles';
import {RiDeleteBinLine, RiSaveLine} from 'react-icons/ri';
import {truncate} from 'lodash';
import {deleteMedicationName, getMedicationName} from 'utils/api/SettingsApi';

const ItemList = ({
  dataValue,
  handleSubmit,
  toEditId,
  toDeleteId,
  cancelEdit,
  removeNewService,
  leftButtonIcon,
  rightButtonIcon,
  enabledInput,
  tryReSubmit,
  labelInputTitle,
  addOneDropdown,
  addCheckBox,
  addSecondDropdown,
  specializeCase,
  specializedMethods,
  addOneInput,
  addSecondInput,
  addThirdInput,
  fieldAttributes,
  userStaff,
  update,
  create,
  deletePermission,

  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  createPrioritySettings,
  setMedicationModals,
  textToMessageDelete,
  setDataChecking,
  setCheckedId,
  setTestingData,
  setMedicationNameModal,
  setMedicationNameDelete,
  setIsEdit,
}) => {
  const {values, setValues, useInput, errors, setCheckRequires} = useForm({});
  const [buttonLeft, setButtonLeft] = useState(<div />);
  const [buttonRight, setButtonRight] = useState(<div />);
  const [specialButton, setSpecialButton] = useState(<div />);
  const [defaultChecks, setDefaultChecks] = useState(false);
  const [editButton, setEditButton] = useState(<div />);
  const toast = useRef();
  useEffect(() => {
    if (enabledInput !== dataValue.id) {
      assignValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledInput]);
 
  useEffect(() => {
    setButtonLeft(
      getLeftButton(
        leftButtonIcon,
        executeHandleSubmit,
        toEditId,
        dataValue,
        enabledInput,
        tryReSubmit,
        values
      )
    );

    setEditButton(
      getLeftButton(
        leftButtonIcon,
        //  disabled={true}
        toEditId,
        dataValue,
        enabledInput
        // tryReSubmit,
        // values
      )
    );
    setButtonRight(
      getRightButton(
        rightButtonIcon,
        dataValue,
        removeNewService,
        executeCancel,
        toDeleteId,
        enabledInput,
        specializeCase
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftButtonIcon, rightButtonIcon, enabledInput, values]);

  useEffect(() => {
    assignValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);
  useEffect(() => {
    setSpecialButton(
      SpecializeCaseButton(specializeCase, specializedMethods, dataValue)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specializedMethods, values]);

  const assignValues = () => {
    addOneDropdown && addOneDropdown.careCategory
      ? addSecondDropdown
        ? setValues({
            name: dataValue.name || '',
            id: dataValue.id || '',
            disabled: true,
            is_staff: dataValue.is_staff,
            care_category:
              dataValue.task_type &&
              dataValue.task_type.care_category &&
              (typeof dataValue.task_type.care_category === 'object'
                ? dataValue.task_type.care_category.id
                : dataValue.task_type.care_category),
            task_type:
              dataValue.task_type &&
              (typeof dataValue.task_type === 'object'
                ? dataValue.task_type.id
                : dataValue.task_type),
            feedback: dataValue.feedback,
          })
        : setValues({
            name: dataValue.name || '',
            id: dataValue.id || '',
            disabled: true,
            is_staff: dataValue.is_staff,
            care_category:
              dataValue.care_category &&
              (typeof dataValue.care_category === 'object'
                ? dataValue.care_category.id
                : dataValue.care_category),
            feedback: dataValue.feedback,
            supplier_brand_name: dataValue.supplier_brand_name,
          })
      : addSecondInput
      ? setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          medication_class: dataValue.medication_class || '',
          supplier_brand_name: dataValue.supplier_brand_name || '',

          indication: dataValue.indication,
        })
      : addThirdInput
      ? setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          medication_class: dataValue.medication_class || '',
          medication_class: dataValue.medication_class || '',
          supplier_brand_name: dataValue.supplier_brand_name,
        })
      : setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          medication_class: dataValue.medication_class || '',
          medication_class: dataValue.medication_class || '',
          supplier_brand_name: dataValue.supplier_brand_name,
        });
  };
  const executeHandleSubmit = async () => {
    if (errors.length >= 1) {
      setCheckRequires(true);
      return;
    }
    const response = await handleSubmit(values);
    if (response) {
      dataValue.id = response.id;
      dataValue.name = response.name;
      dataValue.is_staff = response.is_staff;
      if (addOneDropdown) {
        if (addSecondDropdown) {
          dataValue[addSecondDropdown.value] =
            response[addSecondDropdown.value];
        } else {
          dataValue[addOneDropdown.value] = response[addOneDropdown.value];
        }
      }
      assignValues();
    }
  };

  const executeCancel = () => {
    assignValues();
    cancelEdit();
  };

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };

  const handleCheckBox = val => {
    if (!(leftButtonIcon === ON_EDIT))
      if (val.target.checked) {
        values[addCheckBox.value] = true;
      } else {
        values[addCheckBox.value] = false;
      }
    setValues({...values});
  };

  const renderInput1 = props => {
    if (!(addOneDropdown && addOneDropdown.careCategory)) {
      return (
        <div className={'w-100'}>
          <InputLabelTop
            required
            disabled={true}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };

  const renderInput2 = props => {
    if (addOneDropdown && addOneDropdown.careCategory) {
      return (
        <div className={'w-100 ml-2'}>
          <InputLabelTop
            disabled={true}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };
  const renderInput3 = props => {
    if (addSecondInput) {
      return (
        <div className={'w-100 ml-2'}>
          <InputLabelTop
            disabled={true}
            type="text"
            label={addSecondInput.label}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };

  // const handleToast = async event => {
  //   setMedicationModal(true);
  // };
  console.log(dataValue, 'HHHHHH');
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <div className={'d-flex pl-3 pr-3'}>
        {renderInput1({...useInput('name', 'isRequired')})}
        {!addOneDropdown ||
        !addOneDropdown.options ||
        addOneDropdown.options.length <= 0 ? null : (
          <AddOneDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addOneDropdown}
            specializeCase={specializeCase}
          />
        )}
        {!addSecondDropdown ||
        !addSecondDropdown.options ||
        addSecondDropdown.options.length <= 0 ? null : (
          <AddTwoDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addSecondDropdown}
            specializeCase={specializeCase}
          />
        )}
        {renderInput2({...useInput('name', 'isRequired')})}
        {renderInput3({...useInput('indication')})}

        {!addOneInput ? null : (
          <AddOneInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addOneInput}
          />
        )}
        {!addThirdInput ? null : (
          <AddThirdInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addThirdInput}
          />
        )}

        <div className={'ml-3'}>
          <>
            <div className="d-flex pt-1">
              {addCheckBox && (
                <div className={'d-flex align-items-center pr-4'}>
                  <p className="pr-2">Feedback?</p>
                  <input
                    disabled={true}
                    type="checkbox"
                    checked={values[addCheckBox.value]}
                    id="flexCheckDefault"
                    onChange={val => {
                      handleCheckBox(val);
                    }}
                  />
                </div>
              )}
              {/* {filterAdmin ||filterSuperAdmin  || update&& ( */}

              <IconWrapper
               color={PURPLE}
                onClick={() => {
                  setMedicationNameModal(true);
                  setTestingData({...dataValue});
                  setCheckedId(true);
                }}>
                <MdEdit/>
               
              </IconWrapper>

              {/* ) } */}

              {/* )}  */}

              {/* commnented pbelow , ermissions of company>>priority delete  icon beacuse its also used in setting>>team -delete  icon  . beacuse of priority delete  icon permission . delete  icon wasnt visble in setting>> team  */}

              {/* {filterAdmin || filterSuperAdmin || deletePermission && ( */}

              <IconWrapper>
                <RiDeleteBinLine
                  color={SOFT_RED}
                  onClick={() => {
                    setTestingData({...dataValue});
                    setCheckedId(true);
                    setMedicationNameDelete(true);
                    setIsEdit(true);
                  }}
                />
              </IconWrapper>

              {/* {filterAdmin ||filterSuperAdmin && ( */}

              {specialButton}
              {/* //  )} */}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

function AddOneDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  return (
    <div className={'w-100 ml-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={valuesDropdown.options[valuesDropdown.choicesValue]}
        selectedOption={
          values[valuesDropdown.value]
            ? values[valuesDropdown.value]
            : values.absence_type || values.user_type_category
        }
        onChange={value =>
          dropDownHandleSelect({[valuesDropdown.value]: value ? value : null})
        }
      />
    </div>
  );
}

function AddTwoDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  const taskTypeOptions = item => {
    if (item && values.care_category) {
      let options = item.filter(val => {
        if (val.care_category.id === values.care_category) {
          return {id: val.id, name: val.name};
        }
      });
      return options || [];
    } else {
      return [];
    }
  };
  return (
    <div className={'w-100 ml-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={taskTypeOptions(
          valuesDropdown.options[valuesDropdown.choicesValue]
        )}
        selectedOption={values[valuesDropdown.value]}
        onChange={value =>
          dropDownHandleSelect({[valuesDropdown.value]: value ? value : null})
        }
      />
    </div>
  );
}

function AddOneInput({useInput, enabledInput, dataValue, valuesInput}) {
  console.log(valuesInput, 'AAAAAAAAAAA');
  return (
    <div className={'w-100 ml-2'}>
      <InputLabelTop
        disabled={true}
        type="text"
        label={valuesInput.label}
        {...useInput(
          valuesInput.value,
          `${valuesInput.required ? 'isRequired' : ''}`
        )}
      />
    </div>
  );
}

function AddThirdInput({useInput, enabledInput, dataValue, valuesInput}) {
  return (
    <div className={'w-100 ml-2'}>
      <InputLabelTop
        disabled={true}
        type="text"
        label={valuesInput.label}
        {...useInput(
          valuesInput.value,
          `${valuesInput.required ? 'isRequired' : ''}`
        )}
      />
    </div>
  );
}
export default ItemList;
