import React, {useState,useContext} from 'react';
import {AiOutlineClockCircle} from 'react-icons/ai';
import {FiChevronsDown, FiChevronsUp} from 'react-icons/fi';
import Stopwatch from 'components/SharedComponents/Stopwatch';
import {DOWNLOAD} from 'utils/constants/icons';
import {RiDeleteBinLine} from 'react-icons/ri';
import {FcPlanner} from 'react-icons/fc';
import {AiOutlineAppstoreAdd} from 'react-icons/ai';
import { BiSolidFileImage } from "react-icons/bi";
import {getEmail} from "../../../utils/localStorage/user"
import {getEmployee,getClient,getAdmin,getStaff,getSuperAdmin} from "../../../utils/localStorage/token"
import {
  MainContainer,
  ItemContainer,
  LeftContainer,
  RightContainer,
  DropDownContainer,
  StopWatchContainer,
  DeleteButton,
  PublishButton,
  DeleteButtonSch,
 
} from './styles';
import {AppContext} from "context"
import { CANCELLED_VIST, DOUBLE_UP_MISSED, NEW_DOUBLE_UP, OBSERVATION_VISIT, SCHEDULER_ASSIGNED, SCHEDULER_UNASSIGNED, SHADOW_VISIT, WAIT_TIME_COLOR } from 'shared/styles/constants/colors';

const ProgressInformation = ({
  softScore = 0,
  mediumScore = 0,
  hardScore = 0,
  assigned = '0/0',
  initialTime = null,
  finalTime = null,
  timerPause = true,
  runningDate = '',
  actualDate = '',
  optaplannerStatus = [{state: '', tries: 0}],
  showDeleteAllVisits = () => {},
  showPlannerVisitsFn = () => {},
  showPlannerAssign = () => {},
  handleDownlaod,
  downloadDisable,
  handleDownloadImage,
  showDownloadImageIcon
}) => {
  const [scoreState, setScoreState] = useState(false);
  const [colorState, setColorState] = useState(false);

  const {contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextChoices}=useContext(AppContext)

  const filterEmployee=getEmployee()
  const filterClient=getClient()
  const filterStaff=contextIsStaff;
  const filterAdmin=contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;

  return (
    <MainContainer>
      <LeftContainer onClick={() => setScoreState(!scoreState)}>
        {scoreState ? <FiChevronsUp /> : <FiChevronsDown />}
        <label>Scores</label>
      </LeftContainer>
      <LeftContainer onClick={() => setColorState(!colorState)}>
        {colorState ? <FiChevronsUp /> : <FiChevronsDown />}
        <label>Color</label>
      </LeftContainer>

      <RightContainer>
        <ItemContainer>
          <label>Running time</label>
          <StopWatchContainer>
            <AiOutlineClockCircle />
            <Stopwatch
              initialTime={initialTime}
              finalTime={finalTime}
              timerPause={timerPause}
              runningDate={runningDate}
              actualDate={actualDate}
            />
          </StopWatchContainer>
        </ItemContainer>
        <ItemContainer>
          <label>Visits assigned</label>
          <div>{assigned}</div>
        </ItemContainer>
         {(filterAdmin || filterStaff || filterSuperAdmin) &&
        <>
        <ItemContainer>
          <PublishButton disable={downloadDisable} onClick={!downloadDisable && handleDownlaod}>
            {DOWNLOAD}
          </PublishButton>
        </ItemContainer>
        {showDownloadImageIcon && (
          <ItemContainer>
            <PublishButton disable={downloadDisable} onClick={!downloadDisable && handleDownloadImage}>
              <BiSolidFileImage />
            </PublishButton>
          </ItemContainer>
        )}
        <ItemContainer>
          {/* {  ( (   (filterClient ===' null') &&  (  (filterSuperAdmin == true)|| ( filterAdmin == true) ) ) || (  ( filterStaff  == true ) && ( deletePermission )  ) )  &&( */}
        {(    ( (filterClient ===' null') && (filterEmployee ===' null')  ) ||(  (filterSuperAdmin == true)|| ( filterAdmin == true) || (  ( filterStaff  == true )  )  ) ) &&(
          <DeleteButtonSch onClick={showDeleteAllVisits}>
            <RiDeleteBinLine />
          </DeleteButtonSch>
           )}
        </ItemContainer>
          <ItemContainer>
            <PublishButton onClick={showPlannerVisitsFn}>
              <FcPlanner />
            </PublishButton>
          </ItemContainer>
          <ItemContainer>
            <PublishButton onClick={showPlannerAssign}>
              <AiOutlineAppstoreAdd />
            </PublishButton>
          </ItemContainer>
        </>
        }
      </RightContainer>

      {scoreState ? (
        <DropDownContainer>
          <ItemContainer>
            <label>Soft score</label>
            <div>{softScore}</div>
          </ItemContainer>
          <ItemContainer>
            <label>Medium score</label>
            <div>{mediumScore}</div>
          </ItemContainer>
          <ItemContainer>
            <label>Hard score</label>
            <div>{hardScore}</div>
          </ItemContainer>
        </DropDownContainer>
      ) : (
        <></>
      )}
      {colorState && 
      <DropDownContainer style={{display:"block", zIndex: 2}}>
         <div className='d-flex'>
         <div style={{width:'50%'}}>
          <h6 className="ml-2"></h6>
         <ItemContainer style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: CANCELLED_VIST,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Cancel visit</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: SHADOW_VISIT,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Shadow visit</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: OBSERVATION_VISIT,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Observation visit</label>
          </ItemContainer>
          <ItemContainer style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#06d187',marginRight:'8px',alignSelf:'center'}}></div>
            <label>Completed</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#ffa500',marginRight:'8px',alignSelf:'center'}}></div>
            <label>In Progress</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#ff3b3b',marginRight:'8px',alignSelf:'center'}}></div>
            <label>Missed</label>
          </ItemContainer>
          <ItemContainer style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#00FF7F',marginRight:'8px',alignSelf:'center'}}></div>
            <label>Pinned employee</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: SCHEDULER_ASSIGNED,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Assigned</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: SCHEDULER_UNASSIGNED,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Unassigned</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: WAIT_TIME_COLOR,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Wait time</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#5aa7f2',marginRight:'8px',alignSelf:'center'}}></div>
            <label>Double up</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: DOUBLE_UP_MISSED,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Double up missed</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: NEW_DOUBLE_UP,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Double up unassigned</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor:' #f0b54a' ,marginRight:'8px',alignSelf:'center'}}></div>
            <label>Early start visit</label>
          </ItemContainer>
          <ItemContainer  style={{flexDirection:'row'}}>
            <div style={{width:'10px',height:'10px',backgroundColor: '#f74a4a',marginRight:'8px',alignSelf:'center'}}></div>
            <label>Late start visit</label>
          </ItemContainer>
         </div>
         <div style={{width:'50%'}}>
         <h6 className="ml-2">Visit Type</h6>
         {contextChoices && contextChoices.visit_type && contextChoices.visit_type.map((col)=>{
          return(
            <>
            {col.color_code && 
             <ItemContainer style={{flexDirection:'row'}}>
             <div style={{width:'10px',height:'10px',backgroundColor: col.color_code ,marginRight:'8px',alignSelf:'center'}}></div>
             <label>{col.name}</label>
           </ItemContainer>
            }
            </>
           
          )
         })}
         </div>
         </div>
        </DropDownContainer>}
    </MainContainer>
  );
};

export default ProgressInformation;
