import styled from 'styled-components';
import {MD} from 'shared/styles/constants/resolutions';

export const MainContainer = styled.div`
  margin-top: 4.2381rem;
  width: 100%;
  height: calc(100vh - 4.2381rem);
  margin-right: auto;
  margin-left: auto;
`;

export const ContainerOffset = styled.div`
  margin-left: ${({containerWidth}) => (containerWidth ? '4rem' : '14rem')};

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const ResponsiveNavbar = styled.div`
  ${({showLeftSidebar}) => showNavbar(showLeftSidebar)}
`;

function showNavbar(showLeftSidebar) {
  let returnStyle = `
    display: block;
    @media (max-width: ${MD}) {
      display: ${showLeftSidebar ? 'block' : 'none'};
    }
  `;

  return returnStyle;
}

export const BadGatewayContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   font-size: 1.1rem;
   font-weight: 500;
`;
