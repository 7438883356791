import {
    LARGE_COL,
    EX_SMALL_COL4,
    P_W_Zero
  } from 'shared/styles/constants/columns';

export const headerColumns = [
{
        label: 1,
        checkbox: true,
        name: 'checkbox',
        status: 0,
        // custum_column: true,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
},
,
 {
   label: 'Employee Name',
   name: 'employee_name',
   status: 0,
  //  custum_column: true,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
  label: 'Shift Name',
  name: 'run_name',
  status: 0,
  className: 'grow-mobile',
  // custum_column: true,
  max_width: LARGE_COL,
},
 {
   label: 'Shift Type',
   name: 'client_name',
   status: 0,
   className: 'grow-mobile',
  //  custum_column: true,
   max_width: LARGE_COL,
 },
 {
   label: 'Start date',
   name: 'schedule_start_date',
   status: 0,
   className: 'grow-mobile',
  //  custum_column: true,
   max_width: LARGE_COL,
 },
 {
   label: 'End date',
   name: 'schedule_end_date',
   status: 0,
  //  custum_column: true,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
   label: 'Planned start time',
   name: 'schedule_start_time',
   status: 0,
   className: 'grow-mobile',
  //  custum_column: true,
   max_width: LARGE_COL,
 },
 {
   label: 'Planned end time',
   name: 'schedule_end_time',
   status: 0,
   className: 'grow-mobile',
  //  custum_column: true,
   max_width: LARGE_COL,
 },
 {
  label: 'Travel Mode',
  name: 'actual_end_time',
  status: 0,
  className: 'grow-mobile',
  // custum_column: true,
  max_width: LARGE_COL,
},
{
  label: 'Actual Start time',
  name: 'actual_end_time',
  status: 0,
  className: 'grow-mobile',
  // custum_column: true,
  max_width: LARGE_COL,
},
{
  label: 'Actual End time',
  name: 'actual_end_time',
  status: 0,
  className: 'grow-mobile',
  // custum_column: true,
  max_width: LARGE_COL,
},
 {
   label: 'Status',
   name: 'shift_status',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
  //  custum_column: true,
 },
   
 ];