import React from 'react'
import { getEmployeeBranchAccess, getRateList, setJobDetailsPayRate } from 'utils/api/EmployeeApi';
import { useForm } from 'hooks/FormHook';
import { useState } from 'react';
import { getPayRates } from 'utils/api/ClientApi';
import { employee_rates_choices } from 'utils/choiceConstant';
import { ErrorMessage, FlexEqual, NoPermissionContainer, TitleSection } from '../styles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from 'context';
import RateCard from './RateCard';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Fragment } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { Toast } from 'primereact/toast';
import { EMPLOYEE_ABSENCE_RATES, EMPLOYEE_OFFICE_RATES, EMPLOYEE_ON_CALL_RATES, EMPLOYEE_PAY_RATES, EMPLOYEE_SHIFT_RATES, EMPLOYEE_TRAVEL_RATES, EMPLOYEE_WAIT_RATES, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { useModal } from 'hooks/ModalHook';
import ConfirmPopup from './ConfirmPopup';
import CustomConfirmDecision from 'components/SharedComponents/CustomConfirmDecision';
import { CancelButton, PrimaryButtonModal, } from './styles';
function Rates({
    selectedId,
    rowData,
    props,
    readPermission,
    createPermission,
    deletePermission,
    updatePermission,
    rightPermission,
    filterAdmin,
    filterSuperAdmin,
    contextSubscription
}) {
    const toast = useRef();
    const history = useHistory()

    const VISIT_RATE_CARD = 'visit_rate_card';
    const ON_CALL_RATE_CARD = 'on_Call_rate_card';
    const OFFICE_RATE_CARD = 'office_rate_card';
    const SHIFT_RATE_CARD = 'shift_rate_card';
    const WAIT_RATE_CARD = 'wait_rate_card';
    const TRAVEL_RATE_CARD = 'travel_rate_card';
    const ABSENCE_RATE_CARD = 'absence_rate_card';

    const PAY_RATES = 'Pay Rates';
    const ON_CALL = 'On Call Rates';
    const OFFICE = 'Office Rates';
    const SHIFT = 'Shift Rates';
    const WAIT = 'Wait Rates';
    const TRAVEL_RATES = 'Travel Rates';
    const ABSENCE = 'Absence Rates';

    const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires, } = useForm({});
    const { setValue: setModalConfirmation, bind: bindModalConfirmation } = useModal(false);
    const { setValue: setModalConfirmPopup, bind: bindModalConfirmPopup, value: modalConfirmPopup, setTitle: setModalTitle, } = useModal(false);
    const { handleGetChoices, contextChoices: choices, } = useContext(AppContext)

    const [rateCards, setRateCards] = useState([])

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingSave, setLoadingSave] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loading, setLoading] = useState(true)
    const [editMode, setEditMode] = useState(false)
    const [branchData, setBranchData] = useState([])
    const [specialCase, setSpecialCase] = useState(false)

    const [readPermissionPayRates, setReadPermissionPayRates] = useState(false)
    const [createPermissionPayRates, setCreatePermissionPayRates] = useState(false)
    const [updatePermissionPayRates, setUpdatePermissionPayRates] = useState(false)
    const [deletePermissionPayRates, setDeletePermissionPayRates] = useState(false)

    const [readPermissionOnCall, setReadPermissionOnCall] = useState(false)
    const [createPermissionOnCall, setCreatePermissionOnCall] = useState(false)
    const [updatePermissionOnCall, setUpdatePermissionOnCall] = useState(false)
    const [deletePermissionOnCall, setDeletePermissionOnCall] = useState(false)

    const [readPermissionOffice, setReadPermissionOffice] = useState(false)
    const [createPermissionOffice, setCreatePermissionOffice] = useState(false)
    const [updatePermissionOffice, setUpdatePermissionOffice] = useState(false)
    const [deletePermissionOffice, setDeletePermissionOffice] = useState(false)

    const [readPermissionShift, setReadPermissionShift] = useState(false)
    const [createPermissionShift, setCreatePermissionShift] = useState(false)
    const [updatePermissionShift, setUpdatePermissionShift] = useState(false)
    const [deletePermissionShift, setDeletePermissionShift] = useState(false)

    const [readPermissionWait, setReadPermissionWait] = useState(false)
    const [createPermissionWait, setCreatePermissionWait] = useState(false)
    const [updatePermissionWait, setUpdatePermissionWait] = useState(false)
    const [deletePermissionWait, setDeletePermissionWait] = useState(false)

    const [readPermissionTravelRates, setReadPermissionTravelRates] = useState(false)
    const [createPermissionTravelRates, setCreatePermissionTravelRates] = useState(false)
    const [updatePermissionTravelRates, setUpdatePermissionTravelRates] = useState(false)
    const [deletePermissionTravelRates, setDeletePermissionTravelRates] = useState(false)

    const [readPermissionAbsence, setReadPermissionAbsence] = useState(false)
    const [createPermissionAbsence, setCreatePermissionAbsence] = useState(false)
    const [updatePermissionAbsence, setUpdatePermissionAbsence] = useState(false)
    const [deletePermissionAbsence, setDeletePermissionAbsence] = useState(false)

    useEffect(() => {
        handleGetChoices(employee_rates_choices)
        getAllRateCard()
    }, []);

    const loadRates = async () => {
        try {
            const res = await getRateList(selectedId)
            debugger
            if (res?.results?.length > 0) {
                setEditMode(true)
                res.results.map(item => {
                    let match = rateCards.find(el => el.id == item.rate_card)
                    let obj = {
                        over_time_rate: match && match.over_time_rate,
                        rate_per_hour: match && match.rate_per_hour
                    }
                    if (match && match.rate_card_type === 'Pay Rates') {
                        values[VISIT_RATE_CARD] = values[VISIT_RATE_CARD]?.length
                            ? [...values[VISIT_RATE_CARD]] : [];

                        values[VISIT_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'On Call Rates') {
                        values[ON_CALL_RATE_CARD] = values[ON_CALL_RATE_CARD]?.length
                            ? [...values[ON_CALL_RATE_CARD]] : [];
                        values[ON_CALL_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'Office Rates') {
                        values[OFFICE_RATE_CARD] = values[OFFICE_RATE_CARD]?.length
                            ? [...values[OFFICE_RATE_CARD]] : [];
                        values[OFFICE_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'Shift Rates') {
                        values[SHIFT_RATE_CARD] = values[SHIFT_RATE_CARD]?.length
                            ? [...values[SHIFT_RATE_CARD]] : [];
                        values[SHIFT_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'Wait Rates') {
                        values[WAIT_RATE_CARD] = values[WAIT_RATE_CARD]?.length
                            ? [...values[WAIT_RATE_CARD]] : [];
                        values[WAIT_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'Travel Rates') {
                        values[TRAVEL_RATE_CARD] = values[TRAVEL_RATE_CARD]?.length
                            ? [...values[TRAVEL_RATE_CARD]] : [];
                        values[TRAVEL_RATE_CARD].push({ ...item, ...obj })
                    }
                    else if (match && match.rate_card_type === 'Absence Rates') {
                        values[ABSENCE_RATE_CARD] = values[ABSENCE_RATE_CARD]?.length
                            ? [...values[ABSENCE_RATE_CARD]] : [];
                        values[ABSENCE_RATE_CARD].push({ ...item, ...obj })
                    }
                    setValues({ ...values })
                })
            }
            setLoading(false)
            
        } catch (error) {

        }
    }

    const getEmployeeBranches = async () => {
        try {
            const res = await getEmployeeBranchAccess(selectedId)
            debugger
            setBranchData(choices?.branch?.filter(el => res?.employee_access_branches.includes(el.id)))
        } catch (error) {

        }
    }

    useEffect(() => { getEmployeeBranches()},[choices?.branch])
    useEffect(() => {
        if (rateCards?.length) {
            loadRates()
        }
    }, [rateCards]);

    const getAllRateCard = async () => {
        try {
            const res = await getPayRates()
            setRateCards(res.map(el => ({ ...el, branch: el.branch.length ? el.branch.map(x => x.branch_id) : [] })))
        } catch (error) {

        }
    }
    console.log(rateCards, "rateCards")

    const processRateData = (rateData) => {
        return rateData.map(ratedata => {
            delete ratedata.over_time_rate;
            delete ratedata.rate_per_hour;
            return {
                ...ratedata,
                effective_date_from: ratedata.effective_date_from,
                effective_date_to: ratedata.effective_date_to,
                job_title: ratedata.job_title,
                rate_card: ratedata.rate_card,
            };
        })
    };


    const handleSubmit = async (start_date, end_date) => {
        if (start_date && end_date) {
            setSpecialCase(true)
        }

        //rate_card_start_date, rate_card_end_date

        const body = {
            job_detail_rate_card: [
                ...(values?.[VISIT_RATE_CARD] ? processRateData(values[VISIT_RATE_CARD]) : []),
                ...(values?.[ON_CALL_RATE_CARD] ? processRateData(values[ON_CALL_RATE_CARD]) : []),
                ...(values?.[OFFICE_RATE_CARD] ? processRateData(values[OFFICE_RATE_CARD]) : []),
                ...(values?.[SHIFT_RATE_CARD] ? processRateData(values[SHIFT_RATE_CARD]) : []),
                ...(values?.[WAIT_RATE_CARD] ? processRateData(values[WAIT_RATE_CARD]) : []),
                ...(values?.[TRAVEL_RATE_CARD] ? processRateData(values[TRAVEL_RATE_CARD]) : []),
                ...(values?.[ABSENCE_RATE_CARD] ? processRateData(values[ABSENCE_RATE_CARD]) : []),
            ],
            employee_id: selectedId || null,
        };
        if (start_date) {
            body.rate_card_start_date = start_date
        }
        if (end_date) {
            body.rate_card_end_date = end_date
        }


        try {
            const res = await setJobDetailsPayRate(body, editMode, selectedId, selectedId)
            if (res.status == 'False') {
                // setModalConfirmPopup(false)
                onError(res)
            } else {
                onSuccess(res)
                setModalConfirmPopup(false)
            }

        } catch (error) {
            onError(error)
        }
    }

    const onSuccess = async response => {
        let message = response.message || "Created successful!"
        setLoadingSave(false)
        setButtonIcon('');
        setErrorMessage("")
        setCheckRequires(false)
        // setSpecialCase(false)
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        setModalConfirmPopup(false)
        values.start_date = null
        values.end_date = null
        setValues({ ...values })
        toast.current && toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
        });
    };

    useEffect(() => {
        if (!modalConfirmPopup) {
            setLoadingSave(false)
            setButtonIcon(FLOPPY)
        }
    }, [modalConfirmPopup])

    const onError = err => {
        let messgae = typeof err === "string" ? err : err.message
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setCheckRequires(true);
        setShowError(true);
        toast.current && toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: messgae,
        });
        setErrorMessage(messgae);
    };

    const addNewRate = nameSection => {
        if (!values[nameSection]) {
            values[nameSection] = [{}];
            setValues({ ...values });
        } else {
            values[nameSection] = [...values[nameSection], {}];
            setValues({ ...values });
        }
    };


    const dropDownHandleRate = (item, nameSection, index, list_key) => {
        if (list_key === "branch") {
            values[nameSection][index]['rate_card'] = null
            setValues({ ...values });
        }
        values[nameSection][index][list_key] = item.rate;
        if (list_key == 'rate_card') {
            // debugger
            let rateCard = rateCards.find(rateid => rateid.id == item.rate)
            values[nameSection][index].over_time_rate =
                rateCard && rateCard.over_time_rate;
            values[nameSection][index].rate_per_hour =
                rateCard && rateCard.rate_per_hour;
        }
        setValues({ ...values });
    };

    const deleteRate = (nameSection, index) => {
        let fill = errors.filter(el => !el.includes(nameSection))
        setFormErrors(fill)
        values[nameSection].splice(index, 1);
        setValues({ ...values });
    };

    console.log(values, "values")
    const getRateCardOptionBranchWise = (type, branchId) => {
        switch (type) {
            case PAY_RATES:
                return rateCards.filter(data => data.rate_card_type === PAY_RATES && data.branch.includes(branchId)) || []
            case ON_CALL:
                return rateCards.filter(data => data.rate_card_type === ON_CALL && data.branch.includes(branchId)) || []
            case OFFICE:
                return rateCards.filter(data => data.rate_card_type === OFFICE && data.branch.includes(branchId)) || []
            case SHIFT:
                return rateCards.filter(data => data.rate_card_type === SHIFT && data.branch.includes(branchId)) || []
            case WAIT:
                return rateCards.filter(data => data.rate_card_type === WAIT && data.branch.includes(branchId)) || []
            case TRAVEL_RATES:
                return rateCards.filter(data => data.rate_card_type === TRAVEL_RATES && data.branch.includes(branchId)) || []
            case ABSENCE:
                return rateCards.filter(data => data.rate_card_type === ABSENCE && data.branch.includes(branchId)) || []
            default:
                break;
        }
        // setPayRate(rateCards.filter(data => data.rate_card_type === 'Pay Rates' && ) || []);
        // setOnCallRate(rateCards.filter(data => data.rate_card_type === 'On Call' && ) || [])
        // setOfficeRate(rateCards.filter(data => data.rate_card_type === 'Office' && ) || [])
        // setShiftRate(rateCards.filter(data => data.rate_card_type === 'Shift' && ) || [])
        // setWaitRate(rateCards.filter(data => data.rate_card_type === 'Wait' && ) || [])
        // setTravelRate(rateCards.filter(data => data.rate_card_type === 'Travel Rates' && ) || []);
        // setAbsenceRate(rateCards.filter(data => data.rate_card_type === 'Absence' && ) || [])
    }

    useEffect(() => {
        if (filterSuperAdmin) {
            setReadPermissionPayRates(true)
            setCreatePermissionPayRates(true)
            setUpdatePermissionPayRates(true)
            setDeletePermissionPayRates(true)

            setReadPermissionOnCall(true)
            setCreatePermissionOnCall(true)
            setUpdatePermissionOnCall(true)
            setDeletePermissionOnCall(true)

            setReadPermissionOffice(true)
            setCreatePermissionOffice(true)
            setUpdatePermissionOffice(true)
            setDeletePermissionOffice(true)

            setReadPermissionShift(true)
            setCreatePermissionShift(true)
            setUpdatePermissionShift(true)
            setDeletePermissionShift(true)

            setReadPermissionWait(true)
            setCreatePermissionWait(true)
            setUpdatePermissionWait(true)
            setDeletePermissionWait(true)

            setReadPermissionTravelRates(true)
            setCreatePermissionTravelRates(true)
            setUpdatePermissionTravelRates(true)
            setDeletePermissionTravelRates(true)

            setReadPermissionAbsence(true)
            setCreatePermissionAbsence(true)
            setUpdatePermissionAbsence(true)
            setDeletePermissionAbsence(true)

        } else if (filterAdmin) {

            setReadPermissionPayRates(contextSubscription?.[EMPLOYEE_PAY_RATES]?.[WEB_PERMISSION])
            setCreatePermissionPayRates(true)
            setUpdatePermissionPayRates(true)
            setDeletePermissionPayRates(true)

            setReadPermissionOnCall(contextSubscription?.[EMPLOYEE_ON_CALL_RATES]?.[WEB_PERMISSION])
            setCreatePermissionOnCall(true)
            setUpdatePermissionOnCall(true)
            setDeletePermissionOnCall(true)

            setReadPermissionOffice(contextSubscription?.[EMPLOYEE_OFFICE_RATES]?.[WEB_PERMISSION])
            setCreatePermissionOffice(true)
            setUpdatePermissionOffice(true)
            setDeletePermissionOffice(true)

            setReadPermissionShift(contextSubscription?.[EMPLOYEE_SHIFT_RATES]?.[WEB_PERMISSION])
            setCreatePermissionShift(true)
            setUpdatePermissionShift(true)
            setDeletePermissionShift(true)

            setReadPermissionWait(contextSubscription?.[EMPLOYEE_WAIT_RATES]?.[WEB_PERMISSION])
            setCreatePermissionWait(true)
            setUpdatePermissionWait(true)
            setDeletePermissionWait(true)

            setReadPermissionTravelRates(contextSubscription?.[EMPLOYEE_TRAVEL_RATES]?.[WEB_PERMISSION])
            setCreatePermissionTravelRates(true)
            setUpdatePermissionTravelRates(true)
            setDeletePermissionTravelRates(true)

            setReadPermissionAbsence(contextSubscription?.[EMPLOYEE_ABSENCE_RATES]?.[WEB_PERMISSION])
            setCreatePermissionAbsence(true)
            setUpdatePermissionAbsence(true)
            setDeletePermissionAbsence(true)
        } else {

            const getPermission = (key, type) => {
                if (rightPermission[key]) {
                    // debugger
                    switch (type) {
                        case WEB_READ_PERMISSION:
                            return (contextSubscription?.[key]?.[WEB_PERMISSION] &&
                                (rightPermission[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                                    rightPermission[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
                            );
                        case WEB_CREATE_PERMISSION:
                            return (
                                (rightPermission[key][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
                                    rightPermission[key][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
                            );
                        case WEB_UPDATE_PERMISSION:
                            return (
                                (rightPermission[key][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
                                    rightPermission[key][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
                            )
                        case WEB_DELETE_PERMISSION:
                            return (
                                (rightPermission[key][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
                                    rightPermission[key][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
                            )
                        default:
                            break;
                    }

                }
            };

            setReadPermissionPayRates(getPermission(EMPLOYEE_PAY_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionPayRates(getPermission(EMPLOYEE_PAY_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionPayRates(getPermission(EMPLOYEE_PAY_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionPayRates(getPermission(EMPLOYEE_PAY_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionOnCall(getPermission(EMPLOYEE_ON_CALL_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionOnCall(getPermission(EMPLOYEE_ON_CALL_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionOnCall(getPermission(EMPLOYEE_ON_CALL_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionOnCall(getPermission(EMPLOYEE_ON_CALL_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionOffice(getPermission(EMPLOYEE_OFFICE_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionOffice(getPermission(EMPLOYEE_OFFICE_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionOffice(getPermission(EMPLOYEE_OFFICE_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionOffice(getPermission(EMPLOYEE_OFFICE_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionShift(getPermission(EMPLOYEE_SHIFT_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionShift(getPermission(EMPLOYEE_SHIFT_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionShift(getPermission(EMPLOYEE_SHIFT_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionShift(getPermission(EMPLOYEE_SHIFT_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionWait(getPermission(EMPLOYEE_WAIT_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionWait(getPermission(EMPLOYEE_WAIT_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionWait(getPermission(EMPLOYEE_WAIT_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionWait(getPermission(EMPLOYEE_WAIT_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionTravelRates(getPermission(EMPLOYEE_TRAVEL_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionTravelRates(getPermission(EMPLOYEE_TRAVEL_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionTravelRates(getPermission(EMPLOYEE_TRAVEL_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionTravelRates(getPermission(EMPLOYEE_TRAVEL_RATES, WEB_DELETE_PERMISSION) || false)

            setReadPermissionAbsence(getPermission(EMPLOYEE_ABSENCE_RATES, WEB_READ_PERMISSION) || false)
            setCreatePermissionAbsence(getPermission(EMPLOYEE_ABSENCE_RATES, WEB_CREATE_PERMISSION) || false)
            setUpdatePermissionAbsence(getPermission(EMPLOYEE_ABSENCE_RATES, WEB_UPDATE_PERMISSION) || false)
            setDeletePermissionAbsence(getPermission(EMPLOYEE_ABSENCE_RATES, WEB_DELETE_PERMISSION) || false)


        }

    }, [rightPermission, filterAdmin, filterSuperAdmin, contextSubscription])

    const handlePopup = () => {
        setSpecialCase(true)
        setLoadingSave(true)
        setErrorMessage("")
        setCheckRequires(false)
        setModalTitle("Update rate card")
        setModalConfirmPopup(true);
    }

    const handleCheck = () => {
        setSpecialCase(false)
        if (errors.length > 0) {
            setErrorMessage(InvalidOrEmptyValues)
            setCheckRequires(true)
            setShowError(true)
            return;
        }
        setLoadingSave(true)

        if (loading) {
            return
        }
        handleSubmit("", "")
    }

    return (
        <ComponentDynamic loading={loading}>
            {readPermission ?
                <div>
                    <Toast ref={toast} />
                    <div>
                        {/* Visit Pay Rate */}
                        {readPermissionPayRates &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Visit Pay Rate</TitleSection>
                                    {values &&
                                        values[VISIT_RATE_CARD] &&
                                        values[VISIT_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(PAY_RATES, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={VISIT_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionPayRates}
                                                    readOnly={!updatePermissionPayRates}
                                                />
                                            );
                                        })}
                                    {createPermissionPayRates &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(VISIT_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>
                        }
                        {/* On Call Pay Rate */}
                        {readPermissionOnCall &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>On Call Pay Rate</TitleSection>
                                    {values &&
                                        values[ON_CALL_RATE_CARD] &&
                                        values[ON_CALL_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(ON_CALL, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={ON_CALL_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionOnCall}
                                                    readOnly={!updatePermissionOnCall}
                                                />
                                            );
                                        })}
                                    {createPermissionOnCall &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(ON_CALL_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}

                        {/* Office Pay Rate */}
                        {readPermissionOffice &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Office Pay Rate</TitleSection>
                                    {values &&
                                        values[OFFICE_RATE_CARD] &&
                                        values[OFFICE_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(OFFICE, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={OFFICE_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionOffice}
                                                    readOnly={!updatePermissionOffice}
                                                />
                                            );
                                        })}
                                    {createPermissionOffice &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(OFFICE_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}

                        {/* Shift Pay Rates */}
                        {readPermissionShift &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Shift Pay Rates</TitleSection>
                                    {values &&
                                        values[SHIFT_RATE_CARD] &&
                                        values[SHIFT_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(SHIFT, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={SHIFT_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionShift}
                                                    readOnly={!updatePermissionShift}
                                                />
                                            );
                                        })}
                                    {createPermissionShift &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(SHIFT_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}

                        {/* Wait Pay Rate */}
                        {readPermissionWait &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Wait Pay Rate</TitleSection>
                                    {values &&
                                        values[WAIT_RATE_CARD] &&
                                        values[WAIT_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(WAIT, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={WAIT_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionWait}
                                                    readOnly={!updatePermissionWait}
                                                />
                                            );
                                        })}
                                    {createPermissionWait &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(WAIT_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}

                        {/* Travel Pay Rate */}
                        {readPermissionTravelRates &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Travel Pay Rate</TitleSection>
                                    {values &&
                                        values[TRAVEL_RATE_CARD] &&
                                        values[TRAVEL_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(TRAVEL_RATES, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={TRAVEL_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionTravelRates}
                                                    readOnly={!updatePermissionTravelRates}
                                                />
                                            );
                                        })}
                                    {createPermissionTravelRates &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(TRAVEL_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}

                        {/* Absence Pay Rate */}
                        {readPermissionAbsence &&
                            <div className="mt-3">
                                <FlexEqual>
                                    <TitleSection>Absence Pay Rate</TitleSection>
                                    {values &&
                                        values[ABSENCE_RATE_CARD] &&
                                        values[ABSENCE_RATE_CARD].map((rate, rateIndex) => {
                                            return (
                                                <RateCard
                                                    values={values}
                                                    rateCard={rate['branch'] ? getRateCardOptionBranchWise(ABSENCE, rate['branch']) : []}
                                                    dropDownHandleRate={dropDownHandleRate}
                                                    job_title={choices.job_title}
                                                    choices={choices}
                                                    useInput={useInput}
                                                    nameSection={ABSENCE_RATE_CARD}
                                                    index={rateIndex}
                                                    deleteRate={deleteRate}
                                                    setValues={setValues}
                                                    branchData={branchData}
                                                    deletePermission={deletePermissionAbsence}
                                                    readOnly={!updatePermissionAbsence}
                                                />
                                            );
                                        })}
                                    {createPermissionAbsence &&
                                        <PrimaryButtonForm
                                            className={'ml-3 mt-1 mb-2'}
                                            bgcolor="BLUE"
                                            minWidth="3rem"
                                            onClick={() => addNewRate(ABSENCE_RATE_CARD)}>
                                            <span>{<BsFillPlusSquareFill />}Rate Card</span>
                                        </PrimaryButtonForm>}
                                </FlexEqual>
                            </div>}
                    </div>

                    <div
                        className={
                            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                        }>
                        <div className={'mb-3'}>
                            {showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>

                        <div
                            className={'w-auto justify-content-center justify-content-md-end'}>
                            <div className={'d-flex ml-0 ml-md-3 mr-2'}>
                                <PrimaryButtonForm
                                    bgcolor="PURPLE_GRAY_500"
                                    minWidth="6rem"
                                    className={'mr-2'}
                                    onClick={() => {
                                        history.push('/team');
                                    }}>
                                    <span className={'mr-2'}>{'Cancel'}</span>
                                </PrimaryButtonForm>
                                {(selectedId ? updatePermission : true) && (
                                    <div className={'mr-2'}>
                                        <PrimaryButtonForm
                                            disabled={!createPermission && !updatePermission}
                                            minWidth="6rem"
                                            onClick={handlePopup}>
                                            <span className={'mr-2'}>Save With Date</span>
                                            {specialCase ?
                                                !loadingSave ? (
                                                    buttonIcon
                                                ) : (
                                                    <span
                                                        className="spinner-border spinner-border-sm mr-1"
                                                        role="status"
                                                    />
                                                ) : FLOPPY} </PrimaryButtonForm>
                                    </div>
                                )}
                                {(selectedId ? updatePermission : true) && (
                                    <div className=''>
                                        <PrimaryButtonForm
                                            disabled={!createPermission && !updatePermission}
                                            minWidth="6rem"
                                            onClick={handleCheck}>
                                            <div>
                                                <span className={'mr-2'}>{!specialCase ? textStatus : SAVE}</span>
                                                {!specialCase ?
                                                    !loadingSave ? (
                                                        buttonIcon
                                                    ) : (
                                                        <span
                                                            className="spinner-border spinner-border-sm mr-1"
                                                            role="status"
                                                        />
                                                    ) : FLOPPY}
                                            </div>
                                        </PrimaryButtonForm>
                                    </div>
                                )}
                               
                            </div>
                        </div>
                    </div>
                    {modalConfirmPopup &&
                        <ConfirmPopup
                            fullBind={bindModalConfirmPopup}
                            loadingModal={false}
                            handleSubmit={handleSubmit}
                            setSpecialCase={setSpecialCase}
                        />}
                </div> :
                <NoPermissionContainer> You don't have permission to access the information.</NoPermissionContainer>}
        </ComponentDynamic >
    );
}

export default Rates