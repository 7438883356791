import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { TabStyled, Title } from 'shared/styles/constants/tagsStyles'
import { ModalTabsContainer } from 'components/DashboardComponents/Team/AddModal/ModalStyle'
import { useState } from 'react'
import { NoPermissionContainer, ShowTeamSettingModalTab } from 'components/DashboardComponents/Team/AddModal/styles'
import AccrualLeaves from './AccrualLeaves'
import { ClientInfo } from 'components/SharedComponents/RowInfoModel/styles'
import { useContext } from 'react'
import { AppContext } from 'context'
import { branch_setting_absence_overview, settings_accrual_leaves_choices } from 'utils/choiceConstant'
import { useEffect } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { TiArrowBackOutline } from 'react-icons/ti'
import { TAB_BRANCHES_URL } from '../..'
import BranchTax from './Tax/BranchTax'
import BillingNonCommute from './BillingNonCommute'
import PayrollNonCommute from './PayrollNonCommute'
import { BRANCH_DEFAULT_PRIVACY, COMPANY_SETTINGS, LEAVE_ACCRUAL_SETTINGS, NON_COMMUTE_TRAVEL, NON_COMMUTE_TRAVEL_BILL, SETTINGS_ABSENCE_OVERVIEW, SETTINGS_TAX, VISIT_REMINDER, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions'
import PrivacySetting from './PrivacySetting'
import AbsenceOverview from './AbsenceOverview'
import VisitReminders from './VisitReminders'

function BranchSeting({readPermissionBranchSettings}) {
  const tab1 = 1;
  const tab2 = 2;
  const tab3 = 3;
  const tab4 = 4;
  const tab5 = 5;
  const tab6 = 6;
  const tab7 = 7;

  const history = useHistory()
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextChoices,
    handleGetChoices
  } = useContext(AppContext);

  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  const branchData = history?.location?.state?.branch
  const [tabSelected, setTabSelected] = useState(tab1)

  //  Branch setting permission state
  //Leave Accrual Settings
  const [readPermissionAccrualLeave, setReadPermissionsAccrualLeave] = useState(false);
  const [updatePermissionAccrualLeave, setUpdatePermissionsAccrualLeave] = useState(false);
  const [createPermissionAccrualLeave, setCreatePermissionsAccrualLeave] = useState(false);
  const [deletePermissionAccrualLeave, setDeletePermissionsAccrualLeave] = useState(false);

  // Non Commute Travel Bill
  const [readPermissionBillingNonCommute, setReadPermissionBillingNonCommute] = useState(false)
  const [createPermissionBillingNonCommute, setCreatePermissionBillingNonCommute] = useState(false)
  const [updatePermissionBillingNonCommute, setUpdatePermissionBillingNonCommute] = useState(false)
  const [deletePermissionBillingNonCommute, setDeletePermissionBillingNonCommute] = useState(false)

  // Non Commute Travel
  const [readPermissionPayrollNonCommute, setReadPermissionPayrollNonCommute] = useState(false)
  const [createPermissionPayrollNonCommute, setCreatePermissionPayrollNonCommute] = useState(false)
  const [updatePermissionPayrollNonCommute, setUpdatePermissionPayrollNonCommute] = useState(false)
  const [deletePermissionPayrollNonCommute, setDeletePermissionPayrollNonCommute] = useState(false)

  // Settings Absence Overview
  const [readPermissionAbsenceOverview, setReadPermissionAbsenceOverview] = useState(false)
  const [createPermissionAbsenceOverview, setCreatePermissionAbsenceOverview] = useState(false)
  const [updatePermissionAbsenceOverview, setUpdatePermissionAbsenceOverview] = useState(false)
  const [deletePermissionAbsenceOverview, setDeletePermissionAbsenceOverview] = useState(false)

  // Settings Tax
  const [readPermissionTax, setReadPermissionTax] = useState(false)
  const [createPermissionTax, setCreatePermissionTax] = useState(false)
  const [updatePermissionTax, setUpdatePermissionTax] = useState(false)
  const [deletePermissionTax, setDeletePermissionTax] = useState(false)

  // Privacy Setting
  const [readPermissionPrivacy, setReadPermissionPrivacy] = useState(false)
  const [createPermissionPrivacy, setCreatePermissionPrivacy] = useState(false)
  const [updatePermissionPrivacy, setUpdatePermissionPrivacy] = useState(false)
  const [deletePermissionPrivacy, setDeletePermissionPrivacy] = useState(false)

  // Reminder Setting
  const [readPermissionReminder, setReadPermissionReminder] = useState(false)
  const [createPermissionReminder, setCreatePermissionReminder] = useState(false)
  const [updatePermissionReminder, setUpdatePermissionReminder] = useState(false)
  const [deletePermissionReminder, setDeletePermissionReminder] = useState(false)

  useEffect(() => {
    if (contextIsAdmin || contextIsSuperAdmin) {
      //Leave Accrual Settings
      setReadPermissionsAccrualLeave(true)
      setUpdatePermissionsAccrualLeave(true)
      setCreatePermissionsAccrualLeave(true)
      setDeletePermissionsAccrualLeave(true)
      // Non Commute Travel Bill
      setReadPermissionBillingNonCommute(true)
      setCreatePermissionBillingNonCommute(true)
      setUpdatePermissionBillingNonCommute(true)
      setDeletePermissionBillingNonCommute(true)
      // Non Commute Travel Payroll
      setReadPermissionPayrollNonCommute(true)
      setCreatePermissionPayrollNonCommute(true)
      setUpdatePermissionPayrollNonCommute(true)
      setDeletePermissionPayrollNonCommute(true)
      // Settings Absence Overview
      setReadPermissionAbsenceOverview(true)
      setCreatePermissionAbsenceOverview(true)
      setUpdatePermissionAbsenceOverview(true)
      setDeletePermissionAbsenceOverview(true)
      // Settings Tax
      setReadPermissionTax(true)
      setCreatePermissionTax(true)
      setUpdatePermissionTax(true)
      setDeletePermissionTax(true)


      setReadPermissionPrivacy(true)
      setCreatePermissionPrivacy(true)
      setUpdatePermissionPrivacy(true)
      setDeletePermissionPrivacy(true)

      setReadPermissionReminder(true)
      setCreatePermissionReminder(true)
      setUpdatePermissionReminder(true)
      setDeletePermissionReminder(true)
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === COMPANY_SETTINGS) {
            rolePermissions[COMPANY_SETTINGS] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });

        const rolesPermissions = contextSubSectionPermissions;

        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(
            item => {
              // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
            }
            // }
          );
        }

        // Leave Accrual Settings
        if (rolePermissions[LEAVE_ACCRUAL_SETTINGS]) {
          setReadPermissionsAccrualLeave(
            rolePermissions[LEAVE_ACCRUAL_SETTINGS]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
          setUpdatePermissionsAccrualLeave(
            rolePermissions[LEAVE_ACCRUAL_SETTINGS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );
          setCreatePermissionsAccrualLeave(
            rolePermissions[LEAVE_ACCRUAL_SETTINGS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionsAccrualLeave(
            rolePermissions[LEAVE_ACCRUAL_SETTINGS]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
        }
        //NON_COMMUTE_TRAVEL_BILL
        if (rolePermissions[NON_COMMUTE_TRAVEL_BILL]) {
          setReadPermissionBillingNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
          setCreatePermissionBillingNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          )
          setUpdatePermissionBillingNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION]
          )
          setDeletePermissionBillingNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION]
          )
        }
        //NON_COMMUTE_TRAVEL Payroll
        if (rolePermissions[NON_COMMUTE_TRAVEL]) {
          setReadPermissionPayrollNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
          setCreatePermissionPayrollNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          )
          setUpdatePermissionPayrollNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION]
          )
          setDeletePermissionPayrollNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION]
          )
        }
        // Settings Absence Overview
        if (rolePermissions[SETTINGS_TAX]) {
          setReadPermissionTax(
            rolePermissions[SETTINGS_TAX]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
          setCreatePermissionTax(
            rolePermissions[SETTINGS_TAX]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          )
          setUpdatePermissionTax(
            rolePermissions[SETTINGS_TAX]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION]
          )
          setDeletePermissionTax(
            rolePermissions[SETTINGS_TAX]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION]
          )
        }
        // Settings Tax
        if (rolePermissions[SETTINGS_ABSENCE_OVERVIEW]) {
          setReadPermissionAbsenceOverview(
            rolePermissions[SETTINGS_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
          setCreatePermissionAbsenceOverview(
            rolePermissions[SETTINGS_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          )
          setUpdatePermissionAbsenceOverview(
            rolePermissions[SETTINGS_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION]
          )
          setDeletePermissionAbsenceOverview(
            rolePermissions[SETTINGS_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION]
          )
        }
        if (rolePermissions[BRANCH_DEFAULT_PRIVACY]) {
          setReadPermissionPrivacy(rolePermissions[BRANCH_DEFAULT_PRIVACY]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionPrivacy(rolePermissions[BRANCH_DEFAULT_PRIVACY]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionPrivacy(rolePermissions[BRANCH_DEFAULT_PRIVACY]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionPrivacy(rolePermissions[BRANCH_DEFAULT_PRIVACY]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }
        if (rolePermissions[VISIT_REMINDER]) {
          setReadPermissionReminder(rolePermissions[VISIT_REMINDER]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionReminder(rolePermissions[VISIT_REMINDER]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionReminder(rolePermissions[VISIT_REMINDER]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionReminder(rolePermissions[VISIT_REMINDER]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextSubSectionPermissions,
    contextPermissions,
    contextIsAdmin,
    contextChoices,
    contextIsSuperAdmin
  ]);

  useEffect(() => {
    if (readPermissionAccrualLeave) {
      setTabSelected(tab1)
    }
    else if (readPermissionAbsenceOverview) {
      setTabSelected(tab2)
    }
    else if (readPermissionTax) {
      setTabSelected(tab3)
    }
    else if (readPermissionBillingNonCommute) {
      setTabSelected(tab4)
    }
    else if (readPermissionPayrollNonCommute) {
      setTabSelected(tab5)
    } 
    else if (readPermissionPrivacy) {
      setTabSelected(tab6)
    }else if (readPermissionReminder) {
      setTabSelected(tab7)
    }
    

  }, [
    readPermissionAccrualLeave,
    readPermissionAbsenceOverview,
    readPermissionTax,
    readPermissionBillingNonCommute,
    readPermissionPayrollNonCommute,
    readPermissionPrivacy,
    readPermissionReminder
  ])

  useEffect(() => {
    handleGetChoices([...branch_setting_absence_overview, ...settings_accrual_leaves_choices])
  }, [])

  const isCurrentTab = tab => {
    return tab === tabSelected;
  };

  const handleModal = () => {
    history.push(TAB_BRANCHES_URL)
  }

console.log({readPermissionReminder,
  createPermissionReminder,
  updatePermissionReminder,
  deletePermissionReminder},"readPermissionReminder")

  return (
    <>
    {readPermissionAccrualLeave || readPermissionAbsenceOverview || readPermissionTax || readPermissionBillingNonCommute || readPermissionPayrollNonCommute  || readPermissionPrivacy || readPermissionReminder? (
    <div>
      <div style={{ width: '84%', backgroundColor: "#F0F0F7", zIndex: "2", marginTop: "-27px" }} className="position-fixed" >

        <div className='d-flex justify-content-between' style={{ marginTop: '5px' }}>
          <Title>Settings Branch</Title>
          {/* <div className={' d-flex justify-content-center'}> */}
          <div>
            <PrimaryButtonForm
              bgcolor={'PURPLE_GRAY_500'}
              minWidth="6rem"
              onClick={handleModal}>
              <div className={'d-flex align-items-center'}>
                <TiArrowBackOutline size={24} />
                <div>BACK</div>
              </div>
            </PrimaryButtonForm>
          </div>
          {/* </div> */}
        </div>
        <div className='d-flex justify-content-center mt-1'>
          <ClientInfo>
            Branch name<div>{branchData?.name}</div>
          </ClientInfo>
        </div>
        <ModalTabsContainer>
          <div style={{ width: "84%", zIndex: "1" }} className={'d-flex w-100 justify-content-center'} >
            {readPermissionAccrualLeave && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab1)}
                onClick={() => {
                  setTabSelected(tab1)
                }}>
                Accrual Leave (Beta)
              </TabStyled>)}
            {readPermissionAbsenceOverview && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab2)}
                onClick={() => {
                  setTabSelected(tab2)
                }}>
                Absence Overview (Beta)
              </TabStyled>)}
            {readPermissionTax && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab3)}
                onClick={() => {
                  setTabSelected(tab3)
                }}>
                Tax (Beta)
              </TabStyled>)}
            {readPermissionBillingNonCommute && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab4)}
                onClick={() => {
                  setTabSelected(tab4)
                }}>
                Billing Non Commute (Beta)
              </TabStyled>)}
            {readPermissionPayrollNonCommute && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab5)}
                onClick={() => {
                  setTabSelected(tab5)
                }}>
                Payroll Non Commute (Beta)
              </TabStyled>)}

            {readPermissionPrivacy && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab6)}
                onClick={() => {
                  setTabSelected(tab6)
                }}>
                Privacy Setting (Beta)
              </TabStyled>)}

            {readPermissionReminder && (
              <TabStyled
                style={{ width: "fit-content", marginLeft: "10px", marginRight: "10px" }}
                active={isCurrentTab(tab7)}
                onClick={() => {
                  setTabSelected(tab7)
                }}>
                Visit Reminders (Beta)
              </TabStyled>)}
          </div>
        </ModalTabsContainer>
      </div>

      <ShowTeamSettingModalTab show={isCurrentTab(tab1)}>
        {isCurrentTab(tab1) &&
          <AccrualLeaves
            branch={branchData}
            history={history}
            choices={contextChoices}
            readOnly={!updatePermissionAccrualLeave}
            readPermission={readPermissionAccrualLeave}
            deletePermission={deletePermissionAccrualLeave}
          />}
      </ShowTeamSettingModalTab>
      <ShowTeamSettingModalTab style={{gridTemplateColumns: '100%'}} show={isCurrentTab(tab2)}>
        {isCurrentTab(tab2) &&
          <AbsenceOverview
            branch={branchData}
            history={history}
            choices={contextChoices}
            readOnly={!updatePermissionAbsenceOverview}
            readPermission={readPermissionAbsenceOverview}
            deletePermission={deletePermissionAbsenceOverview}
            createPermisssion={createPermissionAbsenceOverview}
            updatePermission={updatePermissionAbsenceOverview}
          />}
      </ShowTeamSettingModalTab>
      <ShowTeamSettingModalTab show={isCurrentTab(tab3)}>
        {isCurrentTab(tab3) &&
          <BranchTax
            branch={branchData}
            history={history}
            choices={contextChoices}
            readOnly={!updatePermissionTax || !createPermissionTax}
            readPermission={readPermissionTax}
            deletePermission={deletePermissionTax}
            createPermisssion={createPermissionTax}
            updatePermissionTax={updatePermissionTax}
          />}
      </ShowTeamSettingModalTab>
      <ShowTeamSettingModalTab show={isCurrentTab(tab4)}>
        {
          isCurrentTab(tab4) &&
          <BillingNonCommute
            branch={branchData}
            history={history}
            choices={contextChoices}
            readPermission={readPermissionBillingNonCommute}
            // createPermissionBillingNonCommute={createPermissionBillingNonCommute}
            // updatePermissionBillingNonCommute={updatePermissionBillingNonCommute}
            readOnly={!updatePermissionBillingNonCommute}
            deletePermission={deletePermissionBillingNonCommute}
          />
        }
      </ShowTeamSettingModalTab>
      <ShowTeamSettingModalTab show={isCurrentTab(tab5)}>
        {
          isCurrentTab(tab5) &&
          <PayrollNonCommute
            branch={branchData}
            history={history}
            choices={contextChoices}
            readPermission={readPermissionPayrollNonCommute}
            readOnly={!updatePermissionPayrollNonCommute}
            // createPermissionPayrollNonCommute={createPermissionPayrollNonCommute}
            // updatePermissionPayrollNonCommute={updatePermissionPayrollNonCommute}
            deletePermission={deletePermissionPayrollNonCommute}
          />
        }
      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab6)}>
        {
          isCurrentTab(tab6) &&
          <PrivacySetting
            branch={branchData}
            choices={contextChoices}
            readPermission={readPermissionPrivacy}
            createPermission={createPermissionPrivacy}
            updatePermission={updatePermissionPrivacy}
            deletePermission={deletePermissionPrivacy}
          />
        }
      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab7)}>
        {
          isCurrentTab(tab7) &&
          <VisitReminders
            branch={branchData}
            readPermission={readPermissionReminder}
            createPermission={createPermissionReminder}
            updatePermission={updatePermissionReminder}
            deletePermission={deletePermissionReminder}
          />
        }
      </ShowTeamSettingModalTab>
    </div>
    ):(
      <NoPermissionContainer>You don't have permission to access this information.</NoPermissionContainer>
    )}
    </>
  )
}

export default BranchSeting