import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext } from 'context';
import {
    SMALL_COL,
    LARGE_COL,
    EX_SMALL_COL4,
    EX_SMALL_COL,
} from 'shared/styles/constants/columns';

import { PURPLE } from 'shared/styles/constants/colors';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { useModal } from 'hooks/ModalHook';
import { visit_task_choices } from 'utils/choiceConstant';
import { useMemo } from 'react';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import TaskTable from './TaskTable';
import './style.css'
import AddNewCare from './AddNewTask';
export const headerColumns = [
    {
        label: '',
        name: 'expand',
        status: 0,
        className: 'm-0 p-0 md-hidden',
        max_width: EX_SMALL_COL,
    },
    {
        label: 'Swap',
        name: 'file_name',
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
    },
    {
        label: 'Essential',
        name: 'file_name',
        status: 0,
        className: 'grow-mobile',
        max_width: SMALL_COL,
    },

    {
        label: 'Care Category',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Task Type',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Care Task',
        name: 'description',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Support Level',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    }
];

const VisitTasks = React.forwardRef(({
    visitId,
    selectedClientId,
    visitData = {},
    // handleChangeChoices = () => { },
    deleteRow,
    setExpandFields,
    expandFields,
    values,
    setValues,
    visitIndex,
    nameSectionPlannedTask,
    plannedTaskIndex,
    nameSection,
    useInput,
    category,
    toast,
    disabled,
    ref
}) => {
    console.log(values)
    let taskValue = category == "visits" ?
        values.visits[visitIndex][nameSectionPlannedTask][plannedTaskIndex][nameSection] :
        values[category][nameSectionPlannedTask]?.[plannedTaskIndex].tasks

    const { contextChoices, handleGetChoices , handleChangeChoices, setContextChoices} = useContext(AppContext);
    const [modalLoading, setModalLoading] = useState(true)
    const [clientTaskData, setClientTaskData] = useState([]);
    const [taskIndex, setTaskIndex] = useState(null);

    useEffect(() => {
        handleGetChoices(visit_task_choices);
    }, []);

    const {
        setValue: setAddNewCareTask,
        value: addNewCareTask,
        fullBind: fullBindCareTask,
    } = useModal(false);

    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete } =
        useModal(false);

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleDropDown = (item, nameItem, index, value) => {
        debugger
        if (nameItem === 'care_category') {
            let newdata = contextChoices.care_category.find(items => items.id === value);
            if (newdata) taskValue[index]['task_type_options'] = newdata.task_type;
            // taskValue[index]['task_type'] = null
            // taskValue[index]['care_task_data'] = null
        }


        else if (nameItem === 'task_type') {
            let newdata_task = taskValue[index]['task_type_options'].find(item => item.id === value);
            if (newdata_task)
                taskValue[index]['care_task_options'] = newdata_task.care_task;
        }


        // else if (nameItem === 'care_task') {
        //     let newdata = contextChoices.care_category.find(items => items.id === value);
        //     if (newdata) {
        //         taskValue[index]['1'] = newdata.care_task;

        //         let newdata_care_task = taskValue[index]['care_task_options'].find(items => items.id === value);
        //         if (newdata_care_task) {
        //             taskValue[index]['result_type'] = newdata_care_task && newdata_care_task.result_type;
        //             taskValue[index]['result_options'] = newdata_care_task && newdata_care_task.result_option;
        //         }
        //     }
        // }




        else if (nameItem === 'task_type_data') {
            let newDetails = contextChoices.task_type.find(item => item.id === value);
            if (newDetails) {
                taskValue[index]['care_task_options'] = newDetails?.care_task;
                let newCareCategory = contextChoices.care_category.find(item => item.id === newDetails.care_category);
                taskValue[index]['care_category_option'] = [newCareCategory];
                taskValue[index]['care_category'] = newCareCategory.id
            }
            if (!value) {
                taskValue[index]['task_type'] = null;
                taskValue[index]['task_type_data'] = null;
                taskValue[index]['care_category'] = null;
            }
            // taskValue[index]['care_task'] = null;
            // let newCareTask = contextChoices.care_task.find(item => {
            //     if (item.id === value) {
            //         return item;
            //     }
            // });
        }


        else if (nameItem === 'care_task_data') {
            if (value === 'new') {
                setAddNewCareTask(true);
                setTaskIndex(index);
            } else if (value !== 'new') {
                let newCareValue = contextChoices.care_task.find(items => items.id === value);
                let newTaskValue = contextChoices.task_type.find(items => items.id === newCareValue?.task_type);
                console.log({newCareValue :newCareValue ? newCareValue?.task_type : null, newTaskValue: newTaskValue ? newTaskValue?.care_category : null} ,"newTaskValue")
                taskValue[index]['task_type'] = newCareValue ? newCareValue?.task_type : null;
                taskValue[index]['care_category'] = newTaskValue ? newTaskValue?.care_category : null;
                if (!value) {
                    taskValue[index]['task_type'] = null;
                    taskValue[index]['care_category'] = null;
                    taskValue[index]['task_type_data'] = null

                }

            } else {
                // loadDataOnCareTask(value.task_id && value.task_id);
                // values.task_id = value.task_id;
                // setValues({ ...values });

                let newTask_Type = contextChoices.care_task.find((items, index) => items.id === value);
                taskValue[index]['task_type_option'] = newTask_Type.task_type;

                let new_taskType_Option = contextChoices.task_type.find(item => item.id === newTask_Type.task_type);
                taskValue[index]['task_type_options'] = [new_taskType_Option];
                if (new_taskType_Option) {
                    let newCare = contextChoices.care_category.find(item => item.id === new_taskType_Option.care_category);
                    taskValue[index]['care_category_option'] = [newCare];
                }
            }
        }


console.log({item,    nameItem,    value},"itemName")
        if (category === 'visits')
            values.visits[visitIndex][nameSectionPlannedTask][plannedTaskIndex][nameSection][index][nameItem] = item[nameItem];
        else
            values[category][nameSectionPlannedTask][plannedTaskIndex][nameSection][index][nameItem] = item[nameItem];

        setValues({ ...values });
    };



    const handleSwap = (index, arrow) => {
        let array = [...taskValue];
        if (arrow === 'up') {
            if (index) {
                let temp = array[index];
                array[index] = array[index - 1];
                array[index - 1] = temp;
            }
        } else {
            let temp = array[index];
            array[index] = array[index + 1];
            array[index + 1] = temp;
        }
        if (category === 'visits') {
            values.visits[visitIndex][nameSectionPlannedTask][plannedTaskIndex][nameSection] = array;
        } else {
            values[category][nameSectionPlannedTask][plannedTaskIndex][nameSection] = array;
        }
        setValues({ ...values });
    };



    const loadDataOnCareTask = id => {
        console.log(id)
        let careTaskArr = [];
        let arrLength = clientTaskData.length;
        contextChoices.care_category.forEach(item => {
            item.task_type &&
                item.task_type.forEach(task_item => {
                    task_item &&
                        task_item.care_task.forEach(care_item => {
                            if (id === care_item.id) {
                                careTaskArr.push({
                                    care_task: care_item.id,
                                    message: '',
                                    care_task_name: care_item.name,
                                    task_type_name: task_item.name,
                                    care_category_name: item.name,
                                    is_essential: item.is_essential,
                                });
                            }
                        });
                });
        });
        let careData = careTaskArr.map((val, index) => {
            return {
                care_task: val.care_task,
                message: '',
                ordered: arrLength + index + 1,
                care_task_name: val.care_task_name,
                task_type_name: val.task_type_name,
                care_category_name: val.care_category_name,
                support_level: val.support_level,
            };
        });
        values.group_id = null;
        values.task_id = null;

        const initialExpansionState = {};
        careData && careData.forEach((client, cIndex) => {
            initialExpansionState[cIndex] = { open: true };
        });
        setExpandFields({ ...initialExpansionState });

        setClientTaskData([...clientTaskData, ...careData]);
        setValues({ ...values });
    };



    const handleSelectChange = ind => {
        taskValue.forEach((item, index) => {
            if (index == ind) {
                if (category === 'visits')
                    values.visits[visitIndex][nameSectionPlannedTask][plannedTaskIndex][nameSection][index].is_essential = !taskValue[ind].is_essential
                else
                    values[category][nameSectionPlannedTask][plannedTaskIndex][nameSection][index].is_essential = !taskValue[ind].is_essential

                setValues({ ...values })
            }
        });
    };


    const handleExpansion = (taskIndex) => {
        if (taskValue[taskIndex]?.defaultExpand !== undefined && taskValue[taskIndex].defaultExpand) {
            if (category === 'visits')
                values.visits[visitIndex][nameSectionPlannedTask][plannedTaskIndex][nameSection][taskIndex].defaultExpand = false;
            else
                values[category][nameSectionPlannedTask][plannedTaskIndex][nameSection][taskIndex].defaultExpand = false

            setValues({ ...values })
        }
        if ((expandFields[taskIndex] !== undefined) && expandFields[taskIndex]['open']) {
            expandFields[taskIndex] = { open: false };
        } else {
            expandFields[taskIndex] = { open: true };
        }
        setExpandFields({ ...expandFields });
    };


    const handleMessage = (val, item, index) => {
        // val.preventDefault();
        taskValue[index].message = val;
        setValues({ ...values });
    };



    const renderSwapArrow = (index, length) => {
        let arrow = ""
        if (length == 1)
            arrow = <React.Fragment />
        else if (index == 0) {
            arrow = (<div className="d-flex flex-column">
                <FiChevronDown
                    className="align-self-center"
                    size={18}
                    color={PURPLE}
                    onClick={() =>
                        handleSwap(index, 'down')
                    }
                />
            </div>)
        } else if (index == length - 1) {
            arrow = <div className="d-flex flex-column">
                <FiChevronUp
                    className="align-self-center"
                    size={18}
                    color={PURPLE}
                    onClick={() =>
                        handleSwap(index, 'up')
                    }
                />
            </div>
        } else {
            arrow = (
                <div className="d-flex flex-column">
                    <FiChevronUp
                        className="align-self-center"
                        size={18}
                        color={PURPLE}
                        onClick={() =>
                            handleSwap(index, 'up')
                        }
                    />
                    <FiChevronDown
                        className="align-self-center"
                        size={18}
                        color={PURPLE}
                        onClick={() =>
                            handleSwap(index, 'down')
                        }
                    />
                </div>
            )
        }
        return arrow
    }
    const deleteTaskRow = (index) => {
        if (taskValue.length && taskValue.length === 1) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `The task should not be empty.`,
            });
        } else {
            setModalDeleteValue(true)
            setTaskIndex(index)
        }
    }
    const config = useMemo(
        () => ({
            readonly: false,
        }),
        []
    )
    console.log(taskValue, values[category][nameSectionPlannedTask], "taskValue")
    return (
        <div>
            <ComponentDynamic loading={false} style={{ maxHeight: '100px' }}>

                <div className="mt-3">
                    {taskValue && taskValue?.length > 0 ? (
                        <Table
                            headerColumns={taskValue.length == 1 ? headerColumns.filter(h => h.label !== "Swap") : headerColumns}
                            headerPadding={false}
                            noEditOption={true}
                            fontSize="0.75rem"
                            callBackQuerySort={setQuerySort}>
                            <div>
                                {taskValue &&
                                    taskValue?.map((task, index) => {
                                        // console.log(
                                        //     `${category}[${visitIndex}].${nameSectionPlannedTask}[${plannedTaskIndex}].${nameSection}[${index}].care_task`,
                                        //     `values.visits[${visitIndex}].nameSectionPlannedTask[${plannedTaskIndex}].${nameSection}[${index}].is_essential`, "lllllll")
                                        return (
                                            <TaskTable
                                                contextChoices={contextChoices}
                                                taskValue={taskValue}
                                                handleDropDown={handleDropDown}
                                                deleteTaskRow={deleteTaskRow}
                                                expandFields={expandFields}
                                                handleMessage={handleMessage}
                                                index={index}
                                                handleExpansion={handleExpansion}
                                                task={task}
                                                renderSwapArrow={renderSwapArrow}
                                                handleSelectChange={handleSelectChange}
                                                visitIndex={visitIndex}
                                                config={config}
                                                plannedTaskIndex={plannedTaskIndex}
                                                nameSection={nameSection}
                                                useInput={useInput}
                                                category={category}
                                                ref={ref}
                                            />
                                        );
                                    })}
                            </div>
                        </Table>
                    ) : <NoDataFoundMessage>
                        No data found
                    </NoDataFoundMessage>}
                </div>

            </ComponentDynamic>

            {addNewCareTask && (
                <AddNewCare
                    fullBind={fullBindCareTask}
                    setModalLoading={setModalLoading}
                    setAddNewCareTask={setAddNewCareTask}
                    modalLoading={modalLoading}
                    handleChangeChoices={handleChangeChoices}
                    choices={contextChoices}
                    loadDataOnCareTask={loadDataOnCareTask}
                    toast={toast}
                    taskIndex={taskIndex}
                    handleDropDown={handleDropDown}
                    setContextChoices={setContextChoices}
                    taskValue={taskValue}
                />
            )}

         <div className='delete-task-popup-resize'>
            <ModalDecision
                type="delete"
                title="Warning"
                body={
                    'Are you sure you want to delete this Task? \n DELETING the task will remove ALL records from database.'
                }
                onOk={() => {
                    deleteRow(taskIndex);
                    setModalDeleteValue(false);
                }}
                onCancel={() => {
                    setTaskIndex('');
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
       </div>

        </div >
    );
});

export default VisitTasks;
