import React, {useEffect, useState} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {TitleSection, FlexEqual} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';

const Demographics = ({
  choices,
  values,
  dropDownHandleSelect,
  sectionStyle,
  employeesData,
  preferredHandleSelect = () => {},
  preferredSelected = [],
  avoidHandleSelect = () => {},
  avoidSelected = [],
}) => {
  const [preferredEmployees, setPreferredEmployees] = useState([]);
  const [avoidEmployees, setAvoidEmployees] = useState([]);

  useEffect(() => {
    const preferred_employees = preferredSelected.map(preferred => {
      return typeof preferred === 'string'
        ? preferred
        : preferred.employee
        ? preferred.employee
        : preferred.value;
    });
    setPreferredEmployees(preferred_employees);
  }, [preferredSelected]);

  useEffect(() => {
    const avoid_employees = avoidSelected.map(avoid => {
      return typeof avoid === 'string'
        ? avoid
        : avoid.employee
        ? avoid.employee
        : avoid.value;
    });
    setAvoidEmployees(avoid_employees);
  }, [avoidSelected]);

  return (
    <FlexEqual className={sectionStyle}>
      <TitleSection>Demographics</TitleSection>

      <div className="d-flex flex-column mt-2">
        <div className="d-flex mb-2">
          <div className="mr-2 w-100">
            <DropdownSearchable
              placeHolder={'Nationality'}
              options={choices.nationality}
              selectedOption={
                values.nationality ? values.nationality : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  {nationality: value ? value : null},
                )
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              placeHolder={'Ethnicity'}
              options={choices.ethnicity}
              selectedOption={
                values.ethnicity ? values.ethnicity : null
              }
              onChange={value =>
                dropDownHandleSelect(
                   {ethnicity: value ? value : null},
                )
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="mr-2 w-100">
            <DropdownSearchable
              placeHolder={'Religion'}
              options={choices.religion}
              selectedOption={
                values.religion ? values.religion : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  {religion: value ? value : null},
                )
              }
            />
          </div>
          <div className="w-100">
            <DropdownSearchable
              placeHolder={'Preferred Language'}
              options={choices.language}
              selectedOption={
                values.language ? values.language : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  {language: value ? value : null},
                )
              }
            />
          </div>
       
        </div>
        <div className="d-flex mb-2">
         
          <div className="w-100 mr-2">
            <DropdownSearchable
              placeHolder={'Second Language'}
              options={choices.language}
              selectedOption={
                values.language_second ? values.language_second : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  {language_second: value ? value : null},
                )
              }
            />
          </div>
          <div className="w-100">
          <DropdownSearchable
            placeHolder={'Third Language'}
            options={choices.language}
            selectedOption={
              values.language_third ? values.language_third : null
            }
            onChange={value =>
              dropDownHandleSelect(
                {language_third: value ? value : null},
              )
            }
          />
        </div>
        </div>
       
      </div>

      <div className="d-flex flex-column mt-2">
      </div>
    </FlexEqual>
  );
};

Demographics.propTypes = {
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  choices: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  sectionStyle: PropTypes.string,
  employeesData: PropTypes.array,
};

Demographics.defaultProps = {
  sectionStyle: '',
  employeesData: [],
};

export default Demographics;
