import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import React from 'react'
import { useState } from 'react'
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { updateTravelCommute, updateTravelCommuteBill } from 'utils/api/SettingsApi'
import { FLOPPY } from 'utils/constants/icons'

const SUCCESS_MESSAGE = "Non-Commute travel setting updated successfully!"
const ERROR_MESSAGE = "Something went wrong!"

function ModalBillingNonCommute({
    fullBind,
    settings,
    setSettings,
    rowData,
    splitName,
    setModalValue,
    toast,
    getListOfTravelCommute,
    readOnly
}) {

    const [loadingSave, setLoadingSave] = useState(false);

    const handleChange = (name, value, index) => {
        settings[index][name] = !value
        setSettings([...settings])
    }


    const updateTravelCommuteSetting = async () => {
        if (loadingSave) {
            return;
        }
        setLoadingSave(true)
        const selectedId = rowData.id
        const body = { ...rowData }

        settings.map(setting => {
            body[`${splitName(setting.name)}_travel`] = setting.travelTime
            body[`${splitName(setting.name)}_milage`] = setting.travelMileage
        })
        try {
            const res = await updateTravelCommuteBill(body, selectedId);
            setLoadingSave(false)
            if (Object.keys(res).length > 0) {
                setModalValue(false)
                getListOfTravelCommute()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: SUCCESS_MESSAGE,
                })
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: ERROR_MESSAGE,
                });
            }

        } catch (error) {

        }
    }
    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div
                    style={{ padding: "0 1rem 1rem 1rem" }}
                    className='billing-non-commute-setting'>
                    <div style={{ width: "100%" }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <td className='header-text'>Non Commute Travel</td>
                                    <td className='header-text'>Travel Mileage</td>
                                    <td className='header-text'>Travel Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    settings.map((item, index) => {
                                        return (<tr>
                                            <td>{item.name}</td>
                                            <td>
                                                <ToggleWrapper>
                                                    <Toggle
                                                        id={`travel-mileage-${index}`}
                                                        type="checkbox"
                                                        checked={item.travelMileage}
                                                        onClick={() => {
                                                            handleChange('travelMileage', item.travelMileage, index)
                                                        }}
                                                        disabled={readOnly}
                                                    />
                                                    <ToggleLabel htmlFor={`travel-mileage-${index}`} />
                                                </ToggleWrapper>
                                            </td>
                                            <td>
                                                <ToggleWrapper>
                                                    <Toggle
                                                        id={`travel-time-${index}`}
                                                        type="checkbox"
                                                        checked={item.travelTime}
                                                        onClick={() => {
                                                            handleChange('travelTime', item.travelTime, index)
                                                        }}
                                                        disabled={readOnly}
                                                    />
                                                    <ToggleLabel htmlFor={`travel-time-${index}`} />
                                                </ToggleWrapper>
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>


                        </table>
                    </div>
                    <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
                        <div className={'ml-0 ml-md-3 mr-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={() => updateTravelCommuteSetting()}
                                style={{ marginBottom: '5px' }}>
                                <span className={'mr-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    )
}

export default ModalBillingNonCommute