import React, { useState, Fragment } from 'react';
import { ChromePicker } from 'react-color';
import { InputLabelColorPickerStyle } from './styles';
import OutsideAlerter from 'hooks/useOutsideAlerter';

function InputColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const isRequired = props.required;
  let haveError = props['data-error'];
  let labelError = <span />;

  if (props['data-error'] && !isRequired && !props.value) {
    haveError = false;
  }

  if (props['data-error'] && !isRequired && props.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>Invalid format</label>
    );
  }

  if (props['data-error'] && isRequired && !props.value) {
    labelError = (
      <label className={'errorMessage'}>
        {props.error_msg || 'Is required'}
      </label>
    );
  }

  if (props['data-error'] && isRequired && props.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>
        {props.error_msg || 'Invalid format'}
      </label>
    );
  }

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  return (
    <InputLabelColorPickerStyle
      no_internal_margin={props.no_internal_margin}
      have_error={haveError}
      type={props.type}>
      <input
        onKeyPress={props.onKeyPress}
        id={props.id}
        className={props.value ? 'filled' : ''}
        {...props}
      />
      <label>
        {props.label} <span>{props.isRequired ? '*' : ''}</span>
      </label>
      <div onClick={handleClick} className={'swatch'}>
        <div
          className={'d-flex justify-content-center swatchColor'}
          style={{ background: `${props.value}` }}>
          {!props.value ? <div>Select</div> : <Fragment />}
        </div>
      </div>
      {labelError}

      {displayColorPicker && !props.disabled ? (
        <div className={'pickerStyle'}>
          <OutsideAlerter
            clickOutside={() => {
              setDisplayColorPicker(false);
            }}>
            <ChromePicker
              color={props.value}
              disable={props.disable}
              onChange={color =>
                props.onChange({
                  target: {
                    value: color.hex,
                    type: 'text',
                  },
                })
              }
              width={'13rem'}
            />
          </OutsideAlerter>
        </div>
      ) : (
        <Fragment />
      )}
    </InputLabelColorPickerStyle>
  );
}

export default InputColorPicker;
