import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import {useState, useContext} from 'react';
import {useForm} from 'hooks/FormHook';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

import {useEffect,useRef} from 'react';
import {ErrorMessage, ModalContainer} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {AppContext, BranchItemsContext} from 'context';

import TimeSelector from 'components/SharedComponents/TimeSelector';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {getMedicationReview,setMedicationReview} from 'utils/api/ClientApi';
import {Toast} from 'primereact/toast';

import {Fragment} from 'react';
const MedicationReview = ({
  clientId,

  
}) => {
  const [data, setData] = useState({});
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({});

//   {
//     "id": "f7bdfb0f-fdfd-403f-891a-7666222ab3ce",
//     "review_status__name": null,
//     "reviewed_by__name": null,
//     "review_comment": null,
//     "review_date": null,
//     "review_time": null,
//     "review_status_2__name": null,
//     "reviewed_by_2__name": null,
//     "review_comment_2": null,
//     "review_date_2": null,
//     "review_time_2": null
// }

  useEffect(() => {
    if (clientId) {
      getMedicationReview(clientId).then(resp => {
        console.log("***************************",resp,values)
        let data = resp.data
        console.log(data,"AAAAAA")
      
              values["review_status"]= data.review_status ? data.review_status : null;
              values["review_date"] = data.review_date?data.review_date:null;
              values["review_time"] = data.review_time?data.review_time:null;
              values["review_comment"] =data.review_comment?data.review_comment:null;
            setValues({...values});
           
        
      })
    }
  
  
  }, [clientId]);
  
  const MEDICATION_REVIEW = 'Medication_review';

  const {contextChoices} = useContext(AppContext);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef();

  const [addFullBind, setAddFullBind] = useState(true);


  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication Review',
    });
  }, []);
  console.log(data,'data')
  console.log(values, 'FFFFF');
  const handleSubmit=async()=>{
    const body ={
      medication:clientId,
      review_status: values.review_status,
      review_date: values.review_date,
      review_time:values.review_time,
      review_comment:values.review_comment
    }
    let response = await setMedicationReview(body)
    if(response.status===true){
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      // setTimeout(() => {
      //   setModalValue(false);
      // }, 1000);

    }
    else{
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });

    }
  }
  const dropDownHandleSelect=(item)=>{
    setValues({...values,...item})
  }
  return (
    <div>
      <ComponentDynamic loading={false} style={{maxHeight: '100px'}}>
      <Toast ref={toast} position="top-right" />

        <div className="pt-2 pl-3 pr-3 pb-3">
          <div className="d-flex flex-column mt-2">
            <div className="d-flex mb-2">
              <div className="w-100 mr-2">
                <DropdownSearchable
                  required
                  // disabled={!editMode}
                  placeHolder={'Review Status'}
                  options={contextChoices?.medication_review_status || []}
                  {...useInput(`review_status`, 'isRequired')}
                  selectedOption={
                    values.review_status ? values?.review_status : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                      {review_status: value ? value : null},
                      'review_status'
                    )
                  }
                />
              </div>

              <div className="w-100 mr-2">
                <DateSelector
                  isRequired
                  // disabled={!editMode}
                  labelTop="Review Date"
                  {...useInput('review_date', 'isRequired')}
                />
              </div>
              <div className="w-100 mr-2">
                <TimeSelector
                  // disabled={!editMode}
                  // data-error={timeError[index]}
                  // errorMessage={timeErrorMessage}
                  isRequired={true}
                  labelTop={'Review Time'}
                  {...useInput(`review_time`, 'isRequired')}
                  //added below code  for time in 24hrs format
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="w-100 pr-1">
              <div
                className={'pt-2 ml-md-1 ml-0 text-center'}
                style={{fontSize: '0.6rem', fontWeight: 'bold'}}>
                Review Comment
              </div>
              <InputTextArea
                type="text"
                height={'3.4rem'}
                id="emp_desc"
                placeHolder={'Review Comment'}
                label={'Review Comment'}
                {...useInput('review_comment')}
                //   maxlength={5}
              />
            </div>
          </div>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2'}>
                {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                    onClick={handleSubmit}
                >
                  Save
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </ComponentDynamic>
    </div>
  );
};

export default MedicationReview;
