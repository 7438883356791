import React , {useState, useEffect} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  Title,
  HeaderVisits,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import moment from 'moment'
import {LARGE_COL, SMALL_COL, MEDIUM_COL} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';

const EventModal = ({readOnly, fullBind, eventValues, choices,  serviceCategoryData  , visitType,events}) => {
  fullBind.title = 'Evv Detail';

//   const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);
// const [nokPhoto, setNokPhoto] = useState(null);
// const [initials, setInitials] = useState();
const [intialsOfFirstName,setIntialsOfFirstName]=useState( )
const [intialsOfLastName,setIntialsOfLastName]=useState( )
const [serviceName,setServiceName]=useState( )
const [visitTypeName ,setVisitTypeName]=useState( )

const headerColumns = [
  {
    label: 'Clock in Time',
    name: 'clock_in_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock in Location',
    name: 'clock_in_location',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Time',
    name: 'clock_out_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Location',
    name: 'clock_out_location',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Action',
    name: 'action',
    status: 0,
   className: 'grow-mobile  ',
    max_width: MEDIUM_COL,
  },
  
];

// const setQuerySort = querySort => {
//   // setValues({...values, ...querySort});
// };

useEffect(()=>{
  data()
},[  eventValues.full_name ])

const data=()=>{ 
const  name = eventValues.full_name;
const  arrName = name.split(" ");
const  initialOfFirstName =  name.charAt(0).toUpperCase();
const initialOfLastName =  arrName[arrName.length - 1].charAt(0).toUpperCase();
setIntialsOfFirstName(initialOfFirstName)
    setIntialsOfLastName(initialOfLastName)

    const dataName  = serviceCategoryData.find(( u,i)=>{
      if(u.id  ==eventValues.service_category){
        return  u.name 
      } })
    //  serviceName = dataName.filter(p=>p.name)
    dataName &&  setServiceName(dataName.name)
      
const  visitTypeData =  visitType.map(w=>{
  return{
    id :w.id,
    name:w.name
  }
}).find(( u,i)=>{
  if(u.id  ==eventValues.visit_type){
    return  u.name 
  } })

  visitTypeData && setVisitTypeName(visitTypeData.name)

}

  const addVisitPriorityName = () => {
    if (
      choices &&
      choices.visit_priority.length >= 1 &&
      eventValues.visit_priority
    ) {
      return choices.visit_priority.find(
        word => word.id === eventValues.visit_priority,
      ).name;
    } else {
      return null;
    }
  };

  // const  absenceEndDate = list.end_date
// ? moment(list.end_date).format('DD-MM-YYYY')
// : 'Unset';
  const   actualStartDate =   eventValues.actual_start_time
  ? moment( eventValues.actual_start_time).format('DD-MM-YYYY')
  : ' ';   

  const   actualEndDate=  eventValues.actual_end_time
  ? moment( eventValues.actual_end_time).format('DD-MM-YYYY')
  : ' '; 


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        showRowInfo
        minWidthTitle="8.5rem"
        photoRow={eventValues.photo  }
        name={eventValues.full_name || intialsOfFirstName}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: 'Name :',
            value: eventValues.full_name,
          },
          {
            label: 'DOB:',
            value: eventValues.date_of_birth
              ? `${moment(eventValues.date_of_birth).format(
                  'DD-MM-YYYY'
                )} (${moment().diff(eventValues.date_of_birth, 'years')})`
              : '',
          },

        ]}
        loading={false}>
        <div className={'pl-3 pr-3 pb-3'}>
          <FlexGrid3Container>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Client reference'}
                type="text"
                value={eventValues.client_reference || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Employee reference'}
                type="text"
                value={eventValues.employee_reference || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
              <InputLabelTop
                disabled={readOnly}
                label={'No. of Clients'}
                type="text"
                value={eventValues.no_of_clients || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Time code'}
                type="text"
                value={eventValues.time_code || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Service category'}
                type="text"
                value={serviceName}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Visit type'}
                type="text"
                value={visitTypeName}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Visit priority'}
                type="text"
                value={addVisitPriorityName() || ''}
              />
            </FlexGrid3Item>
           
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                // label={'Actual start date'}
                label={' Clocked Start Date'}
                type="text"
                 value={actualStartDate || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked End Date'}
                // label={'Actual end date'}
                type="text"
                value={actualEndDate || ''}
              />
            </FlexGrid3Item>
            
            {/* newly fields added below */}
            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Clocked Start Time"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}
            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Clocked End Time"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Scheduled duration"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Scheduled duration"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            <FlexGrid3Item className="pr-1 ">

               <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Time Difference'}
                type="text"
                value={eventValues.Clock_in_time_difference || ''}
              />
            </FlexGrid3Item>
            
            <FlexGrid3Item className="pr-1">
               <InputLabelTop
                disabled={readOnly}
                label={'Clocked Start Early / Late'}
                type="text"
                value={eventValues.Clocked_Start_Early_Late || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
             <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Time Difference'}
                type="text"
                value={eventValues.Clock_out_time_difference || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
               <InputLabelTop
                disabled={readOnly}
                label={'Clocked End Early / Late'}
                type="text"
                value={eventValues.Clocked_End_Early_Late || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
            <TimeSelector
              disabled={readOnly}
            labelTop={'Scheduled Duration vs Clocked Duration'}
            //value={eventValues.Scheduled_Duration_vs_Clocked_Duration || ''}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item>

     
            <FlexGrid3Item className="pr-1 ">
            <InputLabelTop
                disabled={readOnly}
                label={'Clocked visit duration(%)'}
                type="text"
                value={eventValues.prcntge_clocked_visit_duration || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
            <InputLabelTop
                disabled={readOnly}
                label={'Clocked Visit Provision'}
                type="text"
                value={eventValues.Clocked_Visit_Provision || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked Time Confirmation'}
                type="text"
                value={eventValues.Clocked_Time_Confirmation || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
            <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Distance from Location'}
                type="text"
                value={eventValues.Clock_in_Distance_from_Location || ''}
              />
            </FlexGrid3Item>
        
            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Distance Verification'}
                type="text"
                value={eventValues.Clock_in_Distance_Verification || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Distance from Location'}
                type="text"
                value={eventValues.Clock_out_Distance_from_Location || ''}
              />
            </FlexGrid3Item>
         
            <FlexGrid3Item className="pr-1 ">
                <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Distance Verification'}
                type="text"
                value={eventValues.Clock_out_Distance_Verification || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
               <InputLabelTop
                disabled={readOnly}
                label={'Clocked Distance Confirmation'}
                type="text"
                value={eventValues.Clocked_Distance_Confirmation || ''}
              />
            </FlexGrid3Item>


          </FlexGrid3Container>
        </div>
        {eventValues.clock_count.length >0 ?   (
        <div    >  
     
        <div  className={'ml-1 pl-1 pb-2 mt-1'}  >   
        <Title  >Time and location  data</Title>    
          </div>
   
          <Table
        headerColumns={headerColumns}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        // style={{cursor:pointer}}
        // callBackQuerySort={setQuerySort}
        >

        <div>
        {eventValues && eventValues.clock_count &&  eventValues.clock_count.map( (list,index) =>{

const  clockInDate =  list && list.clock_in_date &&  list.clock_in_date;
const  clockInTime =  list &&  list.clock_in_time &&  list.clock_in_time;
const  clockInTimeAndDate = (clockInDate ? clockInDate : '') + ', ' + (clockInTime? clockInTime:'');

const  clockOutDate = list && list.clock_out_date  && list.clock_out_date;
const  clockOutTime = list && list.clock_out_time &&  list.clock_out_time;
const  clockOutTimeAndDate = (clockOutDate ? clockOutDate :'') + ', ' + (clockOutTime?clockOutTime:'');


            return (
              <div key={index}>
                <Row 
                bgColor Shadow
              //  cursor={pointer}
              NoShadow
              // mb-1
           
                >
                <ColsGrouper
                    className="d-flex"
                  >
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center              
                    >
                   {clockInTimeAndDate}
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    >
                   {
                     list && list.actual_start_location  && list.actual_start_location 
                   }
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    >
                 {
           clockOutTimeAndDate          
                    }
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize               
                    Center
                    // Left
                    >
                 {list && list.actual_end_location && list.actual_end_location }
                  </Col>
                  <Col
                    max_width={MEDIUM_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    className={'ml-0 mr-0 pl-0 pr-0'}
                    // Left
                    >
                   {list && list.type && list.type }
                  </Col>
                  </ColsGrouper>
                </Row>
                <div className="pb-2 mb-2" />
              </div>
            );
          })}
         
        </div>
      </Table>

      </div>
         ) : null}
      </ModalDynamic>
    </ModalContainer>

  );
};

EventModal.propTypes = {
  readOnly: PropTypes.bool,
  fullBind: PropTypes.object.isRequired,
  eventValues: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
};

EventModal.defaultProps = {
  readOnly: false,
};

export default EventModal;
