import styled from 'styled-components';
import {PURPLE} from 'shared/styles/constants/colors';

export const SpinnerContainer = styled.div`
  position: ${props=>props.position || "absolute"};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`

export const SpinnerStyled = styled.div`
  position: ${props=>props.position || "absolute"};
  border: 1rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid ${PURPLE};
  width: 7.5rem;
  height: 7.5rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const SpinnerLabel = styled.div`
  position: ${props=>props.position || "absolute"};
  top: 3rem;
  left: 2rem;
`;
