import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import { Toast } from 'primereact/toast';
import React from 'react'
import { useState } from 'react';
import { FloatingMobileButton, PrimaryButtonForm } from 'shared/styles/buttons';
import { FlexGrid3Container, FlexGrid3Item, Header } from 'shared/styles/constants/tagsStyles';
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row, IconWrapper } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PURPLE } from 'shared/styles/constants/colors';
import { useForm } from 'hooks/FormHook';
import { FaPlus } from 'react-icons/fa';
import { useModal } from 'hooks/ModalHook';
import AddSkillMatrixModal from './Modals/AddSkillMatrixModal';
import UpdateModal from './Modals/UpdateModal';
import "./SkillMatrix.css"
import { bulkDeleteSkillMatrix, deleteMatrixSkill, getSkillMatrixList } from 'utils/api/SettingsApi';
import { useEffect } from 'react';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useRef } from 'react';
import Spinner from 'components/SharedComponents/Spinner';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getPageToSearchParam, useOutsideClickClosePopup } from 'shared/methods';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { BranchItemsContext } from 'context';
import { useContext } from 'react';


function SkillMatrix({ choices,
    values,
    setValues,
    useInput,
    dropDownHandleSelect,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) {
    const toast = useRef();
    const autoCloseRef = useRef(null)
    const location = useLocation()
    const headerColumns = [
        {

            checkbox: deletePermission ? true : false,
            name: deletePermission ? 'checkbox' : "",
            status: 0,
            className: 'grow-mobile',
            max_width: EX_SMALL_COL4,
        },
        {
            label: "BRANCH",
            name: '',
            status: 0,
            className: 'grow-mobile',
            max_width: EX_LARGE_COL,
        },
        {
            label: "SKILL CATEGORY",
            name: '',
            status: 0,
            className: 'grow-mobile',
            max_width: EX_LARGE_COL,
        },
        {
            label: "SKILL",
            name: '',
            status: 0,
            className: 'grow-mobile',
            max_width: EX_LARGE_COL,
        },

        // {
        //     label: "",
        //     name: '',
        //     status: 0,
        //     className: 'grow-mobile',
        //     max_width: EX_SMALL_COL4,
        // },

    ]
    if (deletePermission) {
        headerColumns[0].label = 1
    }
    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete
    } = useModal(false);

    const {
        setValue: setbulkModalDeleteValue,
        bind: bindBulkModalDelete
    } = useModal(false);
    const { branchData } = useContext(BranchItemsContext);

    const [isLoading, setIsLoading] = useState(true)
    const [seletedRow, setSelectedRow] = useState([])
    const [seletedId, setSelectedId] = useState(null)
    const [checkedRows, setCheckedRows] = useState([])
    const [checkedValue, setCheckedValue] = useState(true)
    const [bulkToggle, setBulkToggle] = useState(false)
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [SkillMatrixList, setSkillMatrixList] = useState([])
    const [isdisabledDeleteButton, setIsDisabledDeleteButton] = useState(false)
    const [isdisabledDeleteButtonBulk, setIsDisabledDeleteButtonBulk] = useState(false)

    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
    } = useModal(false);


    const getSkillMatrixListData = async () => {
        const pageToSearch = getPageToSearchParam(
            location.search,
            setCurrentPage,
        )
        try {
            const res = await getSkillMatrixList({ ...values, ...pageToSearch, ...branchData })
            setSkillMatrixList(res?.results)
            setPages(Math.ceil(res.count / values?.limitperpage));
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        values.limitperpage = PAGE_SIZE_RENDER;
        setValues({ ...values })
    }, [PAGE_SIZE_RENDER])

    const deleteSingleSkillMatrix = async (id) => {
        if (isdisabledDeleteButton) {
            return;
        }
        setIsDisabledDeleteButton(true)
        try {
            const res = await deleteMatrixSkill(id)
            if (res.length) {
                getSkillMatrixListData()
                setIsDisabledDeleteButton(false)
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res[0]}`,
                })
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${'Something went wrong!'}`,
                });
            }
        } catch (error) {
            console.log(error)
        }
        setModalDeleteValue(false)
        setIsDisabledDeleteButton(false)
    }

    const openDesicionModel = (id) => {
        setSelectedId(id)
        setModalDeleteValue(true)
    }


    const {
        setValue: setUpdateModalValue,
        value: updateModalValue,
        setTitle: setUpdateTitle,
        fullBind: updateFullBind,
    } = useModal(false);



    const handleAllCheck = () => {
        let checkAll = true;
        const existingData = (id) => {
            return checkedRows.some(function (el) {
                return el === id;
            });
        }
        if (checkedRows && checkedRows.length > 0) {
            SkillMatrixList.map((item) => {
                if (!existingData(item.id)) {
                    checkAll = false
                }
            })
        } else {
            checkAll = false;
        }
        return checkAll;
    }

    const handleAllSelectChange = (e) => {
        setCheckedValue(!checkedValue)
        if (!checkedRows) {
            checkedRows = [];
        }
        if (e.target.checked) {
            SkillMatrixList.map((item) => {
                checkedRows.push(item.id)
            })
        } else {
            SkillMatrixList.map((el) => {
                checkedRows.map((item, index) => {
                    if (item === el.id) {
                        checkedRows.splice(index, 1);
                    }
                })
            })
        }
        setCheckedRows([...checkedRows])
    }

    const handleBulkDeleteSkillMatrix = async () => {

        if (isdisabledDeleteButtonBulk) {
            return;
        }
        const body = {
            metrics_skill_id_list: checkedRows
        }
        setIsDisabledDeleteButtonBulk(true)
        if (checkedRows?.length) {
            try {
                const res = await bulkDeleteSkillMatrix(body)

                if (res.status) {
                    setIsDisabledDeleteButtonBulk(false)
                    setCheckedRows([])
                    getSkillMatrixListData()
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${res.message}`,
                    })
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `${res.detail}`,
                    });
                }
            } catch (error) {
                console.log(error)
            }
            setbulkModalDeleteValue(false)
            setBulkToggle(false)
        }
    }

    const handleSelectChange = (e, item) => {
        if (e.target.checked) {
            setCheckedRows([...checkedRows, item.id])
        } else {
            let index = checkedRows.indexOf(item.id)
            if (index != -1) {
                let tempCheckedRows = [...checkedRows]
                tempCheckedRows.splice(index, 1)
                setCheckedRows([...tempCheckedRows])
            }
        }
    }

    const handleOpenModal = () => {
        setModalValue(true)
        setTitle("Add Skill Matrix")
        setSelectedId(null)
        values.skillMatrix = {}
        setValues({ ...values })

    }

    const findBranchName = (id) => {
        let branchName = choices?.branch?.find(item => item.id === id)
        return branchName
    }


    const findSkillName = (id) => {
        const skillName = choices?.skill?.find(item => item.id === id)
        return skillName
    }

    const findSkillCategoryName = (skillId) => {
        const categID = choices?.skill?.find(item => item.id === skillId)?.category
        const skillCategoryName = choices?.skill_category?.find(item => item.id === categID)
        return skillCategoryName
    }

    //Limit per page dropdown
    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    useEffect(() => {
        getSkillMatrixListData()
    }, [branchData, values.limitperpage, location.search])

    const handleUpdateClick = (branch, category, skill, id) => {
        setUpdateTitle("Update Skill Matrics")
        setUpdateModalValue(true)
        setSelectedId(id)
        values.skillMatrix.branch = branch?.id
        values.skillMatrix.skillCategory = category?.id
        values.skillMatrix.skill = skill?.id
        setValues({ ...values })

    }

    const togglePopUp = () => {
        setBulkToggle(!bulkToggle)
    }


    const autoClose = () => {
        setBulkToggle(false)
    }
    useOutsideClickClosePopup(autoCloseRef, autoClose);

    return (
        <div className="pl-3 pr-3 pb-3 pt-0 ">
            <>
                <Toast ref={toast} />
                <div className="d-flex justify-content-center" >
                    {readPermission && (
                        <FloatingMobileButton onClick={handleOpenModal}>
                            <FaPlus />
                            <label>NEW SKILL MATRIX</label>
                        </FloatingMobileButton>
                    )}
                </div>
                <Header style={{ paddingBottom: "20px" }}>
                    <FlexGrid3Container className={'w-100'}>
                        <FlexGrid3Item className="pr-1" >
                            {checkedRows?.length > 0 &&
                                (<div ref={autoCloseRef} style={{ maxWidth: "fit-content" }} class={`dropdown mr-2${bulkToggle ? 'show' : ""}`}>
                                    <PrimaryButtonForm

                                        minWidth="6rem"
                                        class="btn dropdown-toggle"
                                        type="button"
                                        // id="dropdownMenuButton"
                                        // data-toggle="dropdown"
                                        aria-haspopup={bulkToggle ? "true" : "false"}
                                        onClick={() => togglePopUp()}
                                    // aria-expanded="false"
                                    >
                                        <span className={'mr-2'} >Action</span>
                                    </PrimaryButtonForm>
                                    <div class={`dropdown-menu ${bulkToggle ? 'show' : ""}`} aria-labelledby="dropdownMenuButton">
                                        <button
                                            class="dropdown-item"
                                            onClick={() => {
                                                setbulkModalDeleteValue(true)
                                            }}>
                                            Bulk Delete
                                        </button>

                                    </div>
                                </div>

                                )}
                        </FlexGrid3Item>
                    </FlexGrid3Container>

                </Header>
                <div>
                    {isLoading ?
                        (<div style={{ marginTop: "300px" }} >
                            <Spinner />
                        </div>) :
                        (<div className='skill-matrix-list'>
                            {SkillMatrixList.length ? (
                                <div >
                                    <Table
                                        headerColumns={headerColumns}
                                        // handleAllCheck={handleAllCheck}
                                        // handleAllSelectChange={handleAllSelectChange}
                                        headerPadding={false}
                                        noNeed={false}
                                        // noEditOption={true}
                                    >
                                        {SkillMatrixList.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Row bgColor Shadow>
                                                        <ColsGrouper className="d-flex">
                                                            <div className="p-left-2">
                                                                <Col
                                                                    max_width={EX_SMALL_COL4}
                                                                    Shrink={false}
                                                                    NoFlexGrow={true}
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center

                                                                >
                                                                    {deletePermission && (
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="checkbox"
                                                                            checked={checkedRows.includes(item.id)}
                                                                            id="flexCheckDefault"
                                                                            onChange={event => {
                                                                                handleSelectChange(event, item, index);
                                                                            }}
                                                                        />)}
                                                                </Col>
                                                            </div>
                                                            <Col
                                                                max_width={EX_LARGE_COL}
                                                                Center
                                                                className="sm-hidden"
                                                                Capitalize
                                                                overlap={true}
                                                            >
                                                                {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                                                {findBranchName(item.branch)?.name}
                                                            </Col>
                                                            <Col
                                                                max_width={EX_LARGE_COL}
                                                                Center
                                                                className="sm-hidden"
                                                                Capitalize
                                                                overlap={true}>
                                                                {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                                                {findSkillCategoryName(item.skill)?.name}
                                                            </Col>
                                                            <Col
                                                                max_width={EX_LARGE_COL}
                                                                Center
                                                                className="sm-hidden"
                                                                Capitalize
                                                                overlap={true}>
                                                                {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                                                {findSkillName(item.skill)?.name}
                                                            </Col>

                                                            <Col
                                                                max_width={deletePermission ? EX_SMALL_COL : ""}
                                                                className="sm-hidden"
                                                                Center
                                                                Icon
                                                                Purple>
                                                                <IconWrapper
                                                                    onClick={() => handleUpdateClick(
                                                                        findBranchName(item.branch),
                                                                        findSkillCategoryName(item.skill),
                                                                        findSkillName(item.skill),
                                                                        item.id
                                                                    )}
                                                                >
                                                                    <MdEdit />
                                                                </IconWrapper>
                                                            </Col>
                                                        </ColsGrouper>


                                                        <Col
                                                            className="sm-hidden"
                                                            Center
                                                            Shrink
                                                            NoFlexGrow
                                                        >
                                                            {deletePermission && <IconWrapper
                                                                onClick={() => {
                                                                    openDesicionModel(item?.id)
                                                                }}>
                                                                <RiDeleteBinLine />
                                                            </IconWrapper>}
                                                        </Col>

                                                    </Row>
                                                    <div className="mb-1" />
                                                </div>

                                            )
                                        })

                                        }
                                    </Table>
                                    <div className="mt-3">
                                        <Pagination
                                            totalPages={pages}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            values={values}
                                            dropDownHandleSelect={dropDownHandleSelectPagination}
                                            number={PAGE_SIZE_RENDER}
                                            dataLength={SkillMatrixList.length}
                                        />
                                    </div>
                                </div>) :
                                <div className='w-100 d-flex justify-content-center'>
                                    <h4 style={{ fontSize: "1rem" }}>No skill matrix found!</h4>
                                </div>}
                        </div>)}

                </div>
                {modalValue && (
                    <AddSkillMatrixModal
                        fullBind={fullBind}
                        choices={choices}
                        values={values}
                        setValues={setValues}
                        useInput={useInput}
                        dropDownHandleSelect={dropDownHandleSelect}
                        getSkillMatrixListData={getSkillMatrixListData}
                        setModalValue={setModalValue}
                        toast={toast}
                        readOnly={!createPermission}
                    />)
                }
                {updateModalValue && (
                    <UpdateModal
                        fullBind={updateFullBind}
                        choices={choices}
                        values={values}
                        setValues={setValues}
                        useInput={useInput}
                        dropDownHandleSelect={dropDownHandleSelect}
                        updateModalValue={updateModalValue}
                        seletedId={seletedId}
                        setUpdateModalValue={setUpdateModalValue}
                        getSkillMatrixListData={getSkillMatrixListData}
                        toast={toast}
                        readOnly={!updatePermission}
                    />)
                }
                <ModalDecision
                    type="delete"
                    title="Warning"
                    isdisabled={isdisabledDeleteButton}
                    body={
                        'Are you sure you want to delete this Skill Matrix?'
                    }
                    onOk={() => deleteSingleSkillMatrix(seletedId)}
                    onCancel={() => { }}
                    okTitle={'DELETE'}
                    {...bindModalDelete}
                />

                <ModalDecision
                    type="delete"
                    title="Warning"
                    isdisabled={isdisabledDeleteButtonBulk}
                    body={
                        'Are you sure you want to delete multiple Skill Matrix?'
                    }
                    onOk={() => handleBulkDeleteSkillMatrix()}
                    onCancel={() => { }}
                    okTitle={'DELETE'}
                    {...bindBulkModalDelete}
                />
            </>
        </div>

    )

}

export default SkillMatrix