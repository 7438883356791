import styled from 'styled-components';
import {
  GRADIENT_GREEN,
  PURPLE,
  BLACK_900,
} from 'shared/styles/constants/colors';
import {MD, LG,SM} from 'shared/styles/constants/resolutions';

export const NavStyled = styled.nav`
  background-color: white;
  border: 0.3125rem solid;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-image-source: linear-gradient(to right, ${GRADIENT_GREEN}, ${PURPLE});

  .menuIcon {
    position: absolute;
    left: 1.2rem;
    bottom: 0.8rem;
    cursor: pointer;
  }
`;

export const ResponsiveBackground = styled.div`
  background: ${BLACK_900};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;

  display: none;
  @media (max-width: ${MD}) {
    display: ${({showLeftSidebar}) => (showLeftSidebar ? 'block' : 'none')};
  }

  @media (max-width: ${SM}) {
    margin-top: 50px !important;
  }
`;

export const Logo = styled.div`
  max-width: 11.25rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const LogoMain = styled.div`
  max-width: 11.25rem;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${MD}) {
    margin-left: 40px !important
  }
`;

export const NavDropdown = styled.div`
  @media (max-width: ${LG}) {
    div {
      button.button-selected {
        padding: 0 0.3rem 0 0;
      }
    }
  }
`;

export const EmploymentTypesContainer = styled.div`
  min-width: 8.75rem;

  @media (max-width: ${SM}) {
    flex-grow: 1;
    flex-basis: 0;
  }
`;