import { Switch, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import React from 'react'

export default function DatePickerComp({ json, setJson }) {
    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Date label"
                className='mb-2'
                defaultValue={"Label"}
                value={json?.datePicker?.label}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    // console.log(event.target.value)
                    setJson({
                        ...json, datePicker: {
                            ...json.datePicker,
                            label: event.target.value
                        }
                    })
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label="Description"
                className='mb-2'
                multiline
                rows={3}
                value={json.datePicker.description}
                placeholder="Description"
                onChange={(event) => {
                    setJson({
                        ...json, datePicker: {
                            ...json.datePicker,
                            description: event.target.value
                        }
                    })
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label="Instruction"
                className='mb-2'
                multiline
                rows={3}
                value={json.datePicker.instruction}
                placeholder="Instruction"
                onChange={(event) => {
                    setJson({
                        ...json, datePicker: {
                            ...json.datePicker,
                            instruction: event.target.value
                        }
                    })
                }}
            />
            <FormControl fullWidth>
                <InputLabel id="date-format">Date Format</InputLabel>
                <Select
                    labelId="date-format"
                    id="date-format-id"
                    value={json.datePicker.format}
                    label="Date Format"
                    onChange={(event) => {
                        setJson({
                            ...json, datePicker: {
                                ...json.datePicker,
                                format: event.target.value
                            }
                        })
                    }}
                >
                    <MenuItem value={""}>System Format</MenuItem>
                    <MenuItem value={"dd-MM-yyyy"}>DD-MM-YYYY</MenuItem>
                    <MenuItem value={"MM-dd-yyyy"}>MM-DD-YYYY</MenuItem>
                    <MenuItem value={"yyyy-MM-dd"}>YYYY-MM-DD</MenuItem>
                </Select>
            </FormControl>
            <div className='d-flex'>
                <div className='d-flex'>
                    <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                    <Switch
                        color="primary"
                        checked={json.datePicker.required}
                        onChange={(item) => {
                            setJson({
                                ...json, datePicker: {
                                    ...json.datePicker,
                                    required: item.target.checked
                                }
                            })
                        }}
                    />
                </div>
                <div className='d-flex ml-2'>
                    <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                    <Switch
                        color="primary"
                        checked={json.datePicker.readOnly}
                        onChange={(item) => {
                            setJson({
                                ...json, datePicker: {
                                    ...json.datePicker,
                                    readOnly: item.target.checked
                                }
                            })
                        }}
                    />
                </div>
                <div className='d-flex ml-2'>
                    <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                    <Switch
                        color="primary"
                        checked={json.datePicker.showCondition}
                        onChange={(item) => {
                            setJson({
                                ...json, datePicker: {
                                    ...json.datePicker,
                                    showCondition: item.target.checked
                                }
                            })
                        }}
                    />
                </div>

            </div>
            {json.datePicker.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.datePicker.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, datePicker: {
                                ...json.datePicker,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}
