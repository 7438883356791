import {LARGE_COL,EX_SMALL_COL4,MEDIUM_COL,VERY_EX_SMALL_COL_CHECKBOX, EX_LARGE_COL, EX_VERY_LARGE_COL, SMALL_COL} from 'shared/styles/constants/columns';

export const ClientVisitheaderColumns = [
 
  {
    label: 'Client Name',
    name: 'client__full_name',
    status: 0,
    className: 'grow-mobile ',
    max_width: LARGE_COL,
  },
  {
    label: 'Employee Name',
    name: 'triage_created_by__full_name',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  

  {
    label: 'Scheduled Start Time',
    name: 'scheduler_start_time',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },
 
  {
    label: 'Scheduled End Time',
    name: 'scheduler_end_time',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },
  {
    label: 'Visit Start Date',
    name: 'start_date',
    status: 1,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },
  {
    label: 'Visit End Date',
    name: 'scheduler_end_date',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },

  {
    label: 'Visit Start Time',
    name: 'visit_status_name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },
  {
    label: 'Visit End Time',
    name: 'visit_status_name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,

  },
  {
    label: 'Visit Status',
    name: 'visit_status_name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,

  },

];
