import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Dropdown from 'components/SharedComponents/Dropdown';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
// imported  new files
import { getClientTypes } from 'utils/api/CoreApi';
import { setClientContract, getFunderDetails, getClientTask, getClientGroupTask, getPlanned, setClientMedication, getMedicationData, getMedicationById, deleteMedication, setClientMedicationReview, setClientMedicationBulkReview } from 'utils/api/ClientApi';
import { getFundersList } from 'utils/api/ClientApi'
import { useHistory } from 'react-router';
import _ from "lodash"


import moment from 'moment';
import {
  InvalidOrEmptyValues,
  DetailsNotCreated,
  SAVE,
  SAVED,
  ERROR
} from 'utils/constants/messages';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import ModalServiceClient from './ModalServiceClient';
import {
  getClientsServicesById,
  getClientsServices,
  deleteServiceContract,
} from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';
import { BsFolderPlus } from 'react-icons/bs';
import { headerColumns } from './headerColumsList';
import { LARGE_COL, MEDIUM_COL, EX_LARGE_COL, EX_SMALL_COL4 } from 'shared/styles/constants/columns';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getPageToSearchParam } from 'shared/methods';
import { useModal } from 'hooks/ModalHook';
import { FLOPPY, CROSS, CHECK, ERROR_OUTLINE } from 'utils/constants/icons';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { AppContext } from "context"
import { COPY } from 'utils/constants/icons';

import {

  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { getCategoryPermission, getPermissions, getSubSectionPermissions } from '../../../../utils/localStorage/user';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { HOUR, MINUTE } from 'utils/constants/serviceVisits';
import { setMedicationAdmForm, setMedicationAdmReason, setMedicationAdmType, setMedicationAdmTypeRoute, setMedicationName } from 'utils/api/SettingsApi';
import { SOFT_RED } from 'shared/styles/constants/colors';
import BulkReviewMedication from './BulkReviewMedication';
import { client_med_pres_choices } from 'utils/choiceConstant';
import ConfirmationModal from './ConfirmationMdal';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';

const NEW_CONTRACT = 'new';

const MedicationPrescriptions = ({
  selectedClientId,
  choices,
  // contextChoicesMap,
  allErrors,
  errorSplitPercentage,
  setErrorSplitPercentage,
  loadingFinish,
  textStatus,
  savedContract,
  setSavedContract,
  addressValues,
  clientFullName,
  setTextStatus,
  clientErrors,
  promisesWithErrors,
  // cliId,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userClient,
  readMedicationPresPermissions,
  createMedicationPresPermissions,
  updateMedicationPresPermissions,
  deleteMedicationPresPermissions,
  handleChangeChoices,
  handleChangeChoicesMap
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm({ ordering: '-start_date', tempBodyMapData: {}, alert_body_part: [] });

  const [toDeleteReview] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [medNameSelected, setMedNameSelected] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalValue, setModalValue] = useState(false);
  const [editToggle, setEditToggle] = useState(false)
  const [pages, setPages] = useState(0);
  const [serviceContracts, setServiceContracts] = useState([]);
  const [ServiceContractLength, setServiceContractLength] = useState();
  const [DeleteMedicationData, setDeleteMedicationData] = useState();
  const [simplePagination] = useState(true);
  const [dateError, setDateError] = useState([]);
  const [timeError, setTimeError] = useState([])
  const mountedRef = useRef(true);
  const [editMode, setEditMode] = useState(true)
  const [earlieMessage, setEarlieMessage] = useState('')
  const [earlieErrors, setEarlieError] = useState([])
  const [showEarlieError, setShowEarlieError] = useState(false)
  const { contextChoices } = useContext(AppContext);
  const [loadingSaveMedicationPres, setLoadingSaveMedicationPres] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    setValue: setModalConfirmValue,
    value: modalConfirmValue,
    fullBind: fullBindBulkAlert,
  } = useModal(false);

  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const { setValue: setModalCopyWarning, bind: bindModalCopyWarning } = useModal(
    false,
  );
  const {
    setValue: setModalFunderValue,
    value: modalFunderValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const {
    setValue: setBulkReviewMedication,
    value: bulkReviewMedication,
    fullBind: fullBindBulkReviewMedication,
  } = useModal(false);

  const [loadingSave, setLoadingSave] = useState(false)
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [modalLoading, setModalLoading] = useState(true)
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)

  const history = useHistory()
  const location = useLocation();





  const { handleGetChoices ,contextChoicesMap, loadingChoices} = useContext(AppContext)


  useEffect(() => {
    handleGetChoices(client_med_pres_choices)
  }, [])
  useEffect(() => {
    if (selectedClientId) {
      getClientContracts();
    }
    else {
      setModalLoading(false)
      setModalValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, currentPage, savedContract, values.ordering]);



  useEffect(() => {

    if (idSelected) {
      searchClientService();
      setShowError(false);
      setErrorMessage('');
      setDateError([]);
      errors.splice(0, errors.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalValue]);


  const checkBeforeSend = async (
    evt,
    closeModal,
    saveTrigger,
  ) => {
    let res_med_name = {}
    let res_adm_type = {}
    let res_adm_form = {}
    let res_adm_route = {}
    let res_adm_reason = {}
debugger
    if (loadingSave) {
      return;
    }

    if (!values.name || !values.start_date) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }
    if (!(values.medication_type_name === 'PRN')) {
      if (!values.every || !values.end_type) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(InvalidOrEmptyValues);
        setModalConfirmValue(false)
        return;
      }
    }

    if (!(values.medication_type_name === 'PRN')) {
      if (!((values.every === HOUR) || (values.every === MINUTE)) && (!values.dose_list || (values.dose_list.length < 1))) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage('Please add time of dose.');
        setModalConfirmValue(false)
        return;
      }
    }
    if (!(values.medication_type_name === 'PRN')) {
      if (!((values.every === HOUR) || (values.every === MINUTE)) && (!values.dose_list[0].dose || !values.dose_list[0].medication_dose_time)) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(InvalidOrEmptyValues);
        setModalConfirmValue(false)
        return;
      }
    }

    if ((values.name === 'new_medication_name') && !values.new_medication_name) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }

    if ((values.administration_type === "new_administration_type") && !values.new_administration_type) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }

    if ((values.administration_reason === "new_administration_reason") && !values.new_administration_reason) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }

    if ((values.medication_form === "new_form") && !values.new_form) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }

    if ((values.medication_route === "new_route") && !values.new_route) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      setModalConfirmValue(false)
      return;
    }

    values.dose_list && values.dose_list && values.dose_list.length && values.dose_list.forEach((val) => {
      if (!(val.dose && val.medication_dose_time && val.medication_dose_end_time)) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(InvalidOrEmptyValues);
        setModalConfirmValue(false)
        return;
      }
    })

    setErrorMessage('');
    setCheckRequires(false);
    if (values.name === 'new_medication_name') {
      let body = {
        name: values.new_medication_name,
        supplier_brand_name: values.supplier_brand_name,
        indication: values.indication,
        medication_class: values.medication_class,
      };
      res_med_name = await setMedicationName(body);
      if (res_med_name.status === 'FAIL') {
        setErrorMessage('Medication name already exist');
        setCheckRequires(true);
        setShowError(true);
        setModalConfirmValue(false)
        return
      } else {
        handleChangeChoices('medication_name')
        handleChangeChoicesMap('medication_name')
      }
    }

    if (values.administration_type === "new_administration_type") {
      let body = {
        name: values.new_administration_type,
      };
      res_adm_type = await setMedicationAdmType(body);
      if (res_adm_type.status === 'FAIL') {
        setErrorMessage('Administration type already exist');
        setCheckRequires(true);
        setShowError(true);
        setModalConfirmValue(false)
        return
      }
    }

    if (values.administration_reason === "new_administration_reason") {
      let body = {
        name: values.new_administration_reason,
      };
      res_adm_reason = await setMedicationAdmReason(body);
      if (res_adm_reason.status === 'FAIL') {
        setErrorMessage('Administration reason already exist');
        setCheckRequires(true);
        setShowError(true);
        setModalConfirmValue(false)
        return
      }
    }

    if (values.medication_form === "new_form") {
      let body = {
        name: values.new_form,
      };
      res_adm_form = await setMedicationAdmForm(body);
      if (res_adm_form.status === 'FAIL') {
        setErrorMessage('Administration form already exist');
        setCheckRequires(true);
        setShowError(true);
        setModalConfirmValue(false)
        return
      }
    }

    if (values.medication_route === "new_route") {
      let body = {
        name: values.new_route,
      };
      res_adm_route = await setMedicationAdmTypeRoute(body);
      if (res_adm_route.status === 'FAIL') {
        setErrorMessage('Administration route already exist');
        setCheckRequires(true);
        setShowError(true);
        setModalConfirmValue(false)
        return
      }
    }

    if (values[`professional_guidance_field_error`] || values[`alternative_field_error`]) {
      setErrorMessage("Some of the fields have invalid or empty values. Please review.");
      setCheckRequires(true);
      setShowError(true);
      return
    }


    setSavedContract('unsaved');
    handleSubmit(
      evt,
      saveTrigger,
      res_med_name && res_med_name.data && res_med_name.data.id,
      res_adm_type && res_adm_type.data && res_adm_type.data.id,
      res_adm_reason && res_adm_reason.data && res_adm_reason.data.id,
      res_adm_form && res_adm_form.data && res_adm_form.data.id,
      res_adm_route && res_adm_route.data && res_adm_route.data.id
    );
  };


  const handleSubmit = (
    evt,
    saveTrigger = true,
    new_med_name,
    new_adm_type,
    new_adm_reason,
    new_adm_form,
    new_adm_route
  ) => {


    const file_upload_id = values?.upload_file?.map(files =>
      files.id ? files.id : ''
    );

    // let clientId = ''
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId
    // } else {
    //   clientId = selectedClientId
    // }
    //let body = _.cloneDeep(values)
    let body = {
      start_date: values.start_date,
      expiry_date: values.expiry_date ? values.expiry_date : "",
      effective_date: values.effective_date ? values.effective_date : '',
      repeat: values.repeat || null,
      medication_dose: values.dose_list,
      name: new_med_name ? new_med_name : values.name,
      medication_type: values.medication_type,
      administration_type: new_adm_type ? new_adm_type : values.administration_type,
      administration_reason: new_adm_reason ? new_adm_reason : values.administration_reason,
      medication_route: new_adm_route ? new_adm_route : values.medication_route,
      medication_form: new_adm_form ? new_adm_form : values.medication_form,
      client: selectedClientId,
      expiry_reason: values.expiry_reason || null,
      medication_assistance: values.medication_assistance,
      is_adhoc: false,
      end_type: values.end_type,
      every: (values.medication_frequency_name == 'Daily') ? 'DAY' : values.every,
      end_value: values.end_value ? values.end_value : null,
      dose_regime: values.dose_regime,
      frequency: values.frequency,
      important: values.important,
      prescription_volume: values.prescription_volume,
      reason: values.reason,
      potential_side_effects: values.potential_side_effects,
      storage_location: values.storage_location,
      prescription_expiry_date: values.prescription_expiry_date,
      comments: values.comments,
      administration_area: values.administration_area,
      medication_instructions: values.medication_instructions,
      file_upload_id: file_upload_id,
      file_upload: values?.upload_file ? values.upload_file : null,
      // New fields Added
      strength: values.strength,
      min_interval_between_doses: values.min_interval_between_doses,
      max_dose_in_24_hours: values.max_dose_in_24_hours,
      medication_purpose: values.medication_purpose,
      symptoms_to_look_for: values.symptoms_to_look_for,
      client_need_medication_criteria: values.client_need_medication_criteria,
      alternative_administering_interventions: values.alternative_administering_interventions,
      medication_duration: values.medication_duration,
      healthcare_professional_guidance_criteria: values.healthcare_professional_guidance_criteria,
      completed_by: values.completed_by,
      signature: values.signature || null,
      date: values.date,
    }
    if (values.medication_type_name === 'PRN') {
      body.is_prn = true
      body.prn_dose_id = values.prn_dose_id
    }
    if (values.id) {
      body.id = values.id
    }
    if (values.is_controlled_drug) {
      body.is_controlled_drug = values.is_controlled_drug
    }
    if (values.alert_body_part && values?.alert_body_part?.length > 0) {
      body.body_part = values.alert_body_part;
    }

    if (saveTrigger) {
      setLoadingSave(true);
    }
    setLoadingSaveMedicationPres(true)
    setClientMedication(body, selectedClientId)
      .then(response => {
        setModalConfirmValue(false)
        if (response.message.status !== "SUCCESS") {
          throw response.message.message;
        }

        //medication review start
        if (values.review_data && (values.review_data.length > 0)) {
          let body = {
            medication_schedule_id: values.id,
            review_data: [...values.review_data]
          }
          setClientMedicationReview(body, selectedClientId)
            .then(response => {
            })
            .catch(onError);
        }

        //medication review end

        onSuccess(response.message);
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    if (response.status === "SUCCESS" || response.status === true) {
      handleModal()
      setLoadingSave(false);
      setLoadingSaveMedicationPres(false)
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      handleChangeChoices()
    }
  };
  const onError = err => {
    setLoadingSave(false);
    setLoadingSaveMedicationPres(false)
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err.message
    );
  };

  console.log(errors,"errors")
  const handleBulkReview = () => {
    const tempList = selectedElId?[selectedElId]:checkedRows
    let body = {
      medication_schedule_id_list: tempList,
      review_data: [...values.review_data]
    }
    setClientMedicationBulkReview(body)
      .then(response => {
        if (response.status) {
          setBulkReviewMedication(false);
          clearSelectedElId()
        }
      })
      .catch(onError);
  }


  const getClientContracts = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getMedicationData({
      selectedClientId,
      idSelected,
      ...pageToSearch,
      ...values
    });

    if (response.results && response.results.length < 1) {
      setModalValue(true);
    }
    setServiceContractLength(response.results && response.results.length)
    setServiceContracts(response.results || []);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    setModalLoading(false)
  };


  const getValueName = (res, type) => {
    let value = []
    if (type) {
      res.forEach((resData) => {
        choices.care_task.forEach((careData) => {
          if (careData.id === resData.care_task) {
            value.push(careData)
          }
        })
      })
    } else {
      res.forEach((resData) => {
        choices.group_task.forEach((careData) => {
          if (careData.id === resData.group_task) {
            value.push(careData)
          }
        })
      })
    }
    return value
  }


  const searchClientService = async () => {
    setLoading(true)
    // let clientId = cliId ? cliId : selectedClientId
    const response = await getMedicationById(selectedClientId, idSelected);

    if (response.file.length > 0) {
      values.upload_file = response.file?.map(fileupload => {
        const myArray = fileupload.file.split("/files/");
        return { id: fileupload.id, name: myArray[1], file: fileupload.file };
      });
    } else {
      values.upload_file = [];
    }
  let  medication_type_name =  response?.medication_type && contextChoicesMap?.medication_type[response?.medication_type]  && contextChoicesMap?.medication_type[response?.medication_type]?.name

    setValues({
      ...values,
      review_data: response?.review_list,
      medication_type_name: medication_type_name || "",
      alert_body_part: response?.body_part || [],
      ...response
    });
    setLoading(false)
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = id => {
    if (updateMedicationPresPermissions) {
      setEditToggle(true)
      setEditMode(false)
      setIdSelected(id);
      setCheckRequires(false);
      setShowError(false);
      setErrorMessage('');
      setModalValue(true);
    }
  };

  const handleCopyModal = async id => {
    setLoading(true)
    // let clientId = cliId ? cliId : selectedClientId
    const response = await getMedicationById(selectedClientId, id);




    if (response) {
      delete response.id
    }

    if (response.file.length > 0) {
      values.upload_file = response.file?.map(fileupload => {
        const myArray = fileupload.file.split("/files/");
        return { id: fileupload.id, name: myArray[1], file: fileupload.file };
      });
    } else {
      values.upload_file = [];
    }

    response.dose_list = response.dose_list.map((item) => {
      return {
        dose: item.dose,
        instructions: item.instructions,
        medication_dose_end_time: item.medication_dose_end_time,
        medication_dose_time: item.medication_dose_time,
        medication_schedule: item.medication_schedule,
        medication_dose_timing: item.medication_dose_timing,
        repeat_on: item.repeat_on
      }
    })
    setValues({
      // ...values,
      review_data: response.review_list,
      medication_type_name: contextChoicesMap?.medication_type && contextChoicesMap?.medication_type[response.medication_type] && contextChoicesMap?.medication_type[response.medication_type]?.name || '',
      alert_body_part: response.body_part || [],
      ...response
    });
    setIdSelected('');
    setEditToggle(false)
    setButtonIcon(FLOPPY);
    setEditMode(true)
    setTextStatus(SAVE);
    setModalCopyWarning(false)
    setModalValue(!modalValue);
    setLoading(false)
  };

  const handleModal = event => {
    setIdSelected('');
    setEditToggle(false)
    if (event === NEW_CONTRACT) {
      setValues({ tempBodyMapData: {}, alert_body_part: [], dose_list: [{}] })
      setButtonIcon(FLOPPY);
      setEditMode(true)
      setTextStatus(SAVE);
    } else {
      setEditMode(false)
      getClientContracts();
    }
    setModalValue(!modalValue);
  };

  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
    deleteMed(id, 'False');
  };

  const deleteMed = async (id, force) => {
    // let clientId = cliId ? cliId : selectedClientId
    const response = await deleteMedication(id, selectedClientId, force);
    if (force === 'False') {
      setDeleteMedicationData(response.data)
    } else {
      setModalDeleteValue(false);
      getClientContracts();
      setIdSelected('');
    }

  }


  const dropDownHandleSelect = (item, nameItem, index) => {
    let id = item[nameItem]
    if (nameItem == 'dose') {
      values.dose_list[index][nameItem] = item[nameItem];
    } else if (nameItem == 'name') {
      values[nameItem] = item[nameItem];
      if (contextChoicesMap.medication_name) {
        let newTemp = contextChoicesMap && contextChoicesMap.medication_name[id];
        values.medication_brand_name = newTemp && newTemp.supplier_brand_name;
      }
    } else if (nameItem == 'medication_dose_timing') {
      values.dose_list[index][nameItem] = item[nameItem].value;
      values.dose_list[index].medication_dose_timing_name = item[nameItem].label;
      if (!((item[nameItem].label == 'Fixed') || item[nameItem].label == 'Custom')) {
        values.dose_list[index].medication_dose_time = item[nameItem].start_time;
        values.dose_list[index].medication_dose_end_time = item[nameItem].end_time;
      }
    } else if (nameItem == 'medication_type') {
      values.medication_type = item[nameItem].value
      values.medication_type_name = item[nameItem].label
    } else {
      values[nameItem] = item[nameItem];
    }
    setValues({ ...values }); //item added

  };

  const handleSelectChange = (e, visit) => {
    // console.log(visit.id,"441125")
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.id])
    } else {
      let index = checkedRows.indexOf(visit.id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      serviceContracts.map((visit) => {
        if (!visitExists(visit.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setCheckedValue(!checkedValue)
    //  let tempChecked=[]
    // if(e.target.checked){
    //   events.forEach(ev=>{
    //     tempChecked.push(ev.id)
    //   })
    // }

    // setCheckedRows([...tempChecked])

    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      serviceContracts.map((visit) => {
        checkedRows.push(visit.id)
      })
    } else {
      serviceContracts.map((visit) => {
        checkedRows.map((item, index) => {
          if (item === visit.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }

  const handleBulkReviewOnClick = () => {
    setBulkReviewMedication(true)
    values.review_data = [{}]
    setValues({ ...values })
  }

  const deleteReview = (dataVisit, indexVisit) => {
    toDeleteReview.push({ ...dataVisit });

    const possibleError = `review_data[${indexVisit}]`;

    for (var i = errors.length; i--;) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.review_data.splice(indexVisit, 1);

    setValues({ ...values });
  }

  const getDate = dateTime => {
    // if (dateTime) {
    //   let date = dateTime.split('T')[0];
    //   let time = moment(dateTime.split('T')[1], 'HH:mm:ss').format('HH:mm:ss');
    //   return date + ' ' + time;
    // } else {
    //   return null;
    // }
    if (dateTime) {
      let date = dateTime.split("T")[0]
      let time = moment(dateTime.split("T")[1], "HH:mm:ss").format("HH:mm")
      return date + " " + time
    } else {
      return null
    }
  };

  const {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  } = useContextMenu();
  const medicationPrescriptionMenu = [{
    name: "Medication Review",
    onClick: handleBulkReviewOnClick
  }]

  return (
    <ComponentDynamic
      loading={modalLoading || loadingChoices}
    >
      <ContextMenu menuItems={medicationPrescriptionMenu} />
      {(selectedClientId ? readMedicationPresPermissions : createMedicationPresPermissions) ?
        <div>


          {createMedicationPresPermissions && (serviceContracts.length >= 1) && !modalValue ? (

            <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
              <div className={'text-primary font-weight-bold'}>
                <div>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={() => handleModal(NEW_CONTRACT)}>
                    <BsFolderPlus size={18} />
                    <span className={'ml-2'}>ADD MEDICATION PRESCRIPTION</span>
                  </PrimaryButtonForm>
                </div>
              </div>

            </div>

          ) : (
            <Fragment />
          )}
          {modalValue && serviceContracts.length >= 1 ? (
            <div className={'w-100 d-flex justify-content-center'}>
              <div>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={handleModal}>
                  <div className={'d-flex align-items-center'}>
                    <TiArrowBackOutline size={24} />
                    <div>BACK</div>
                  </div>
                </PrimaryButtonForm>
              </div>

            </div>
          ) : (
            <Fragment />
          )}
          {editToggle ?
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

              <ToggleDiv>
                <ToggleWrapper>
                  <Toggle
                    id="checkbox_prn"
                    type="checkbox"
                    onClick={() => {
                      editMode ? setEditMode(false) : setEditMode(true);
                    }}
                  />
                  <ToggleLabel htmlFor="checkbox_prn" />
                </ToggleWrapper>
                <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
                  <p>Edit Mode</p>
                </div>
              </ToggleDiv>

            </div>
            : <Fragment />}

          {(checkedRows.length > 0) && (serviceContracts.length >= 1) && !modalValue
            &&
            <div class="dropdown mr-3 text-right">
              <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className={'mr-2'}>Action</span>
              </PrimaryButtonForm>
              <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item"
                  onClick={() => {
                    handleBulkReviewOnClick()
                  }
                  }
                >Medication Review</a>
              </div>
            </div>


          }


          {(!modalValue && serviceContracts.length >= 1) ?
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                handleAllCheck={handleAllCheck}
                handleAllSelectChange={handleAllSelectChange}
                noEditOption
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>
                <div>

                  {serviceContracts.map((clientContract, index) => {

                    const endDate = clientContract.expiry_date
                      ? moment(clientContract.expiry_date).format('DD-MM-YYYY')
                      : 'Unset';
                    const startDate = clientContract.start_date
                      ? moment(clientContract.start_date).format('DD-MM-YYYY')
                      : 'Unset';
                    // const daysSelected = getDaysSelected(clientContract.visits);
                    return (
                      <div key={index} onContextMenu={(e)=>{
                        e.preventDefault();
                        displayMenu(e, clientContract.id, {x:0, y:0})
                      }}>
                        <Row bgColor Shadow
                          zIndex={ServiceContractLength - index}
                        >
                          <Col
                            max_width={EX_SMALL_COL4}
                            Shrink={false}
                            NoFlexGrow={true}
                            reducePadding
                            Capitalize
                            Center

                          >
                            <input class="form-check-input" type="checkbox"
                              checked={checkedRows.includes(clientContract.id)}
                              id="flexCheckDefault"
                              onChange={(e) => { handleSelectChange(e, clientContract) }}
                            />

                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize

                            overlap={true}
                            Center>
                            {clientContract.name__name}
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            className='sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {clientContract.medication_type__name}

                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            overlap={true}
                            Capitalize
                            Center>
                            {startDate}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            className='sm-hidden md-hidden'
                            max_width={MEDIUM_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center

                          >
                            {endDate}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            className='sm-hidden md-hidden'
                            max_width={MEDIUM_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center

                          >
                            {getDate(clientContract.updated_at)}
                          </Col>
                          <Col className="" Center Shrink NoFlexGrow>
                            {updateMedicationPresPermissions && <IconWrapper
                              style={{ marginRight: '8px' }}
                              color={SOFT_RED}
                              onClick={() => {
                                setModalCopyWarning(true)
                                setIdSelected(clientContract.id)
                                setMedNameSelected(clientContract.name__name)
                              }
                              }
                            >
                              {COPY}
                            </IconWrapper>}
                            {deleteMedicationPresPermissions && <IconWrapper
                              onClick={() => {
                                toDeleteClient(clientContract.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>}
                          </Col>
                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}
                  <div className="mt-3">
                    <Pagination
                      simplePagination={simplePagination}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      dataLength={serviceContracts.length}
                      dropdownEntriesLimit={false}
                    />
                  </div>
                </div>

              </Table>
              <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      history.push('/client')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </>
            :
            (
              <ModalServiceClient
                earlieErrors={earlieErrors}
                setShowEarlieError={setShowEarlieError}
                setEarlieMessage={setEarlieMessage}
                earlieMessage={earlieMessage}
                setEarlieError={setEarlieError}
                showEarlieError={showEarlieError}
                setSavedContract={setSavedContract}
                InvalidOrEmptyValues={InvalidOrEmptyValues}
                DetailsNotCreated={DetailsNotCreated}
                setErrorMessage={setErrorMessage}
                clientErrors={clientErrors}
                setShowError={setShowError}
                promisesWithErrors={promisesWithErrors}
                setCheckRequires={setCheckRequires}
                isValid={isValid}
                allErrors={allErrors}
                editMode={editMode}
                setEditMode={setEditMode}
                idSelectedMed={idSelected}
                selectedClientId={selectedClientId}
                dropDownHandleSelect={dropDownHandleSelect}
                values={values}
                setValues={setValues}
                clientId={selectedClientId}
                useInput={useInput}
                choices={choices}
                contextChoicesMap={contextChoicesMap}
                checkBeforeSend={checkBeforeSend}
                showError={showError}
                errorMessage={errorMessage}
                setErrorSplitPercentage={setErrorSplitPercentage}
                errorSplitPercentage={errorSplitPercentage}
                errors={errors}
                loadingFinish={loadingFinish}
                loadingSave={loadingSave}
                textStatus={textStatus}
                buttonIcon={buttonIcon}
                addressValues={addressValues}
                clientFullName={clientFullName}
                setDateError={setDateError}
                dateError={dateError}
                setTimeError={setTimeError}
                timeError={timeError}
                updateMedicationPresPermissions={updateMedicationPresPermissions}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                loadingSaveMedicationPres={loadingSaveMedicationPres}
                setModalConfirmValue={setModalConfirmValue}
                modalConfirmValue={modalConfirmValue}
                fullBindBulkAlert={fullBindBulkAlert}
                loading={loading}
              />
            )}
          {!modalFunderValue ? null : (
            <AddModal
              choices={choices}
              values={values}
              setValues={setValues}
              isValid={isValid}
              errors={errors}
              useInput={useInput}
              readOnly={false}
              fullBind={fullBind}
              setTitle={setTitle}
              setIdSelected={setIdSelected}
              setCheckRequires={setCheckRequires}
              newAddFunder={true}

            />
          )}
          {
            bulkReviewMedication &&
            <BulkReviewMedication
              fullBind={()=>encloseModalBind(fullBindBulkReviewMedication)}
              useInput={useInput}
              values={values}
              setValues={setValues}
              choices={contextChoices}
              editMode={true}
              errorMessage={errorMessage}
              showError={showError}
              dropDownHandleSelect={dropDownHandleSelect}
              handleBulkReview={handleBulkReview}
              deleteReview={deleteReview}
            />
          }
          <div>
            <ModalDecision
              type="delete"
              title="Warning"
              body={
                'Are you sure you want to delete this medication? \n DELETING the medication will remove all doses for this medication.'
              }
              subBody={
                ''
              }
              onOk={() => { deleteMed(idSelected, 'True') }}
              onCancel={() => {
                setIdSelected('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
            <ModalDecision
              type="warning"
              title="Warning"
              body={
                `Do you really want to copy ${medNameSelected} medication ?`
              }
              subBody={
                ''
              }
              onOk={() => { handleCopyModal(idSelected) }}
              onCancel={() => {
                setIdSelected('');
                setModalCopyWarning(false)
                setMedNameSelected('')
              }}
              okTitle={'Confirm'}
              {...bindModalCopyWarning}
            />
          </div>
          {modalConfirmValue && (
            <ConfirmationModal
              type={'warning'}
              title={'CONFIRM MEIDCATION PRESCRIPTION DATA'}
              subTitle={'This action will update all medication data from effective date mentioned below. Do you confirm for the changes?'}
              okTitle={'NO, CANCEL'}
              onCancelTitle={'YES, PROCEED'}
              icon={ERROR_OUTLINE}
              setModalValue={setModalConfirmValue}
              fullBind={fullBindBulkAlert}
              values={values}
              checkBeforeSend={checkBeforeSend}
              loadingSaveMedicationPres={loadingSaveMedicationPres}
            // onOk={() => {
            //   setShowDeleteVisitsModal(false);
            // }}
            // showModal={setShowDeleteVisitsModal}
            />
          )}
        </div>
        :
        <NoPermissionContainer>
          You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
        </NoPermissionContainer>
      }
    </ComponentDynamic>
  );
};


MedicationPrescriptions.propTypes = {
  choices: PropTypes.object.isRequired,
  selectedClientId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allErrors: PropTypes.bool.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  savedContract: PropTypes.string.isRequired,
  setSavedContract: PropTypes.func.isRequired,
  addressValues: PropTypes.object.isRequired,
  setTextStatus: PropTypes.func.isRequired,
  clientErrors: PropTypes.array.isRequired,
  promisesWithErrors: PropTypes.array.isRequired,
};

export default MedicationPrescriptions;