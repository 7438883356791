import React, {Fragment,useEffect,useState} from 'react';
import {ServiceVisit} from 'components/SharedComponents/FormSections';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {FaPlus} from 'react-icons/fa';
import {TitleSection} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import {getPreferredVisits,getPreferredCompetency} from "utils/api/ClientApi"
const AddNewVisit = ({
  values,
  visits,
  choices,
  useInput,
  deleteVisit,
  addNewVisit,
  dropDownHandleVisits,
  setValues,
  toDeleteVisitSkill,
  setToDeleteVisitSkill,
  toDeleteVisitEmployee,
  setToDeleteVisitEmployee,
  serviceId,
  clientId,
  errors,
  setDateError,
  dateError,
  setTimeError,
  timeError,
  addVisitCopy,
deletePermission,
subSectionServiceContractDelete,
billRate,
payRate,
travelRate,
clientTaskOptions,
groupTaskOptions,
plannedTasks,editMode,
earlieErrors,
          setShowEarlieError,
          setEarlieMessage,
          earlieMessage,
          setEarlieError,
          showEarlieError,
          visitRender,
          medDoseTimingList,
          setFormErrors
}) => {
  const [preferredSkills,setPreferredSkills]=useState([])
  const [preferredCompetency,setPreferredCompetency]=useState([])
  useEffect(()=>{
    getPreferredVisits(clientId)
    .then(res=>{
      if(res.data&&res.data.length>0){
        setPreferredSkills(res.data)
      }
    }
      )
      getPreferredCompetency(clientId)
      .then(res=>{
        if(res.data&&res.data.length>0){
          setPreferredCompetency(res.data)
        }
      }
        )
  },[])

 

// const getVisitsRender=()=>{
//   let tempVisits=[]
//   visits.forEach((dataVisit, indexVisit) => {
         
//     tempVisits.push( 
//       <div  className={'mb-3'}>
//         <ServiceVisit
//           choices={choices}
//           values={values}
//           useInput={useInput}
//           nameSection={`visits[${indexVisit}]`}
//           handleDeleteVisit={deleteVisit}
//           index={indexVisit}
//           dataVisit={dataVisit}
//           dropDownHandleVisits={dropDownHandleVisits}
//           setValues={setValues}
//           toDeleteVisitSkill={toDeleteVisitSkill}
//           setToDeleteVisitSkill={setToDeleteVisitSkill}
//           toDeleteVisitEmployee={toDeleteVisitEmployee}
//           setToDeleteVisitEmployee={setToDeleteVisitEmployee}
//           errors={errors}
//           setDateError={setDateError}
//           dateError={dateError}
//           setTimeError={setTimeError}
//           timeError={timeError}
//           serviceId={serviceId}
//           clientId={clientId}
//           preferredSkills={preferredSkills}
//           preferredCompetency={preferredCompetency}
//           addVisitCopy={addVisitCopy}
//           subSectionServiceContractDelete={subSectionServiceContractDelete}
//           deletePermission={deletePermission}
//           billRate={billRate}
//       payRate={payRate}
//         />
//       </div>
//     );
//   })
//   return tempVisits
// }
  return (
    <div>
      <div className={'mb-2'}>
        <TitleSection>Service visit</TitleSection>
      </div>
      {visits && visits.length >= 1 ? (
        visits.map((dataVisit, indexVisit) => {
         
          return (
            <div key={dataVisit.id+indexVisit} className={'mb-3'}>
              <ServiceVisit
              serviceCategoryKey = {'client'}
              earlieErrors ={earlieErrors}
              setShowEarlieError={setShowEarlieError}
              setEarlieMessage={setEarlieMessage}
              earlieMessage={earlieMessage}
              setEarlieError={setEarlieError}
              showEarlieError={showEarlieError}
               editMode={editMode}
              //  readOnly={true}
                choices={choices}
                values={values}
                useInput={useInput}
                nameSection={`visits[${indexVisit}]`}
                handleDeleteVisit={deleteVisit}
                index={indexVisit}
                dataVisit={dataVisit}
                dropDownHandleVisits={dropDownHandleVisits}
                setValues={setValues}
                toDeleteVisitSkill={toDeleteVisitSkill}
                setToDeleteVisitSkill={setToDeleteVisitSkill}
                toDeleteVisitEmployee={toDeleteVisitEmployee}
                setToDeleteVisitEmployee={setToDeleteVisitEmployee}
                errors={errors}
                setDateError={setDateError}
                dateError={dateError}
                setTimeError={setTimeError}
                timeError={timeError}
                serviceId={serviceId}
                clientId={clientId}
                preferredSkills={preferredSkills}
                preferredCompetency={preferredCompetency}
                addVisitCopy={addVisitCopy}
                subSectionServiceContractDelete={subSectionServiceContractDelete}
                deletePermission={deletePermission}
                billRate={billRate}
            payRate={payRate}
            travelRate={travelRate}
            clientTaskOptions={clientTaskOptions}
          groupTaskOptions={groupTaskOptions}
          plannedTasks={plannedTasks}
          visitRender={visitRender}
          medDoseTimingList={medDoseTimingList}
          setFormErrors={setFormErrors}
              />
            </div>
          );
        })
      ) : (
        <Fragment />
      )}
      <div className={'mt-2'}>
        <PrimaryButtonForm minWidth="6rem" onClick={addNewVisit}  disabled={!editMode}>
          <FaPlus />
          SERVICE VISIT
        </PrimaryButtonForm>
      </div>
     
    </div>
  );
};

AddNewVisit.propTypes = {
  dropDownHandleVisits: PropTypes.func.isRequired,
  addNewVisit: PropTypes.func.isRequired,
  deleteVisit: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  visits: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  toDeleteVisitSkill: PropTypes.array.isRequired,
  toDeleteVisitEmployee: PropTypes.array.isRequired,
  serviceId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  errors: PropTypes.array,
};

export default AddNewVisit;
