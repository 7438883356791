export const pickFile = async event => {
  if (event.target.files[0] === undefined) {
    return {
      state: false,
      error: {},
    };
  }
  let type_error = false;
  let size_error = false;
  let fileEvent = event.target.files[0];
  console.log('File event type', fileEvent.type);

  //format type validation
  if (
    // fileEvent.type !== 'image/jpeg' &&
    // fileEvent.type !== 'image/png' &&
    // fileEvent.type !== 'application/pdf'
    fileEvent.type == 'unknown'
  ) {
    type_error = true;
  }

  // 5 MB
  if (fileEvent.size > 25000000) {
    size_error = true;
  }

  // if there's any error then display it
  if (type_error || size_error) {
    let error = {
      file: URL.createObjectURL(fileEvent),
      type: fileEvent.type,
      error_type: type_error ? true : false,
      error_size: size_error ? true : false,
    };

    return {
      state: false,
      error,
    };
  }

  // display loading state
  //   this.setLoading();

  // this.setState( {
  //   file        : URL.createObjectURL( fileEvent ),
  //   type        : fileEvent.type,
  //   image_state : 'show-thumb valid',
  //   error_type  : false,
  //   error_size  : false
  // } );

  return {
    state: true,
    error: {},
    file: fileEvent,
  };
};

export const pickFiles = async event => {
  if (event.target.files[0] === undefined) {
    return {
      state: false,
      error: {},
    };
  }

  let type_error = false;
  let size_error = false;

  for (let i = 0; i < event.target.files.length; i++) {
    //format type validation
    if (
      event.target.files[i].type !== 'image/jpeg' &&
      event.target.files[i].type !== 'image/png' &&
      event.target.files[i].type !== 'application/pdf'
    ) {
      type_error = true;
    }

    // 5 MB
    if (event.target.files[i].size >= 5000000) {
      size_error = true;
    }
  }

  // if there's any error then display it
  if (type_error || size_error) {
    let error = {
      error_type: type_error ? true : false,
      error_size: size_error ? true : false,
    };

    return {
      state: false,
      error,
    };
  }

  return {
    state: true,
    error: {},
    files: event.target.files,
  };
};
