import styled from 'styled-components';
import {BLACK_900, PURPLE, PURPLE_800, WHITE} from 'shared/styles/constants/colors';
import {MD, MD_LG, XS, XXLL,LG,XXLLL, XXLLLL,XXLLL1,XXLL1, XXLLA,XXLLB} from 'shared/styles/constants/resolutions';

export const CalendarContainer = styled.div`
  height: ${props=>props.isSplitMode ? '60% !important' : `${props.height} !important`};
  min-height: ${props=>props.minHeight?props.minHeight:"none"};
  overflow: ${props=>props.overflow?props.overflow:"visible"};;
  .fc-event {
    cursor: pointer;
  }
  .fc-event-main{
    overflow:hidden !important;
  }
  .fc-h-event .fc-event-time{
    overflow:visible !important;
  }
  .fc-view-harness {
    background-color: white;
  }
  .fc-scrollgrid-section-header {
    -webkit-box-shadow: 1px 1px 6px ${BLACK_900};
    box-shadow: 1px 1px 6px ${BLACK_900};
  }
  .fc-scroller-liquid-absolute{
    overflow:initial !important;
  }
.fc-scroller-harness-liquid{
  overflow:scroll !important;
}
.fc-timeline-slot-label{
  text-align:${props=>props.reduceColumns?"center":"left"};
}

  .fc .fc-timeline-slot-cushion {
    min-width: ${props => calculateWidth(props.reduceColumns)};
    width: ${props => calculateWidth(props.reduceColumns)};
  }

  .fc-timegrid-slot-label-frame,
  .fc-timegrid-slot-label-cushion {
    line-height: ${props => (props.reduceColumns ? 1 : 1)};
  }
  .fc .fc-timegrid-slot:empty:after {
    content: '\\a0';
  }

  .fc .fc-toolbar.fc-header-toolbar{
    margin-top: -25px;
    margin-left: 10px;
  }
  .fc-toolbar h2 {
    position: relative;
    left: 8.5rem;
    font-size: 1.4rem;
    top: 10px;
    max-width: 20rem;

    @media (max-width: ${MD_LG}) {
      font-size: 1.25rem;
    }

    @media (max-width: ${XS}) {
      font-size: 1rem;
      /* top: ${props => (props.progress ? '0rem' : '0.5rem')}; */
      font-weight: bold;
    }
  }

  .fc .fc-datagrid-cell-cushion {
    white-space: normal;
    width: 100%;
  }
`;
export const CalendarContainer1 = styled.div`
  height: 200% !important;
  .fc-event {
    cursor: pointer;
  }
  .fc-event-main{
    overflow:hidden !important;
  }
  .fc-h-event .fc-event-time{
    overflow:visible !important;
  }
  .fc-view-harness {
    background-color: white;
  }
  .fc-scrollgrid-section-header {
    -webkit-box-shadow: 1px 1px 6px ${BLACK_900};
    box-shadow: 1px 1px 6px ${BLACK_900};
  }
  .fc-scroller-liquid-absolute{
    overflow:initial !important;
  }
.fc-scroller-harness-liquid{
  overflow:scroll !important;
}
.fc-timeline-slot-label{
  text-align:${props=>props.reduceColumns?"center":"left"};
}

  .fc .fc-timeline-slot-cushion {
    min-width: ${props => calculateWidth(props.reduceColumns)};
    width: ${props => calculateWidth(props.reduceColumns)};
  }

  .fc-timegrid-slot-label-frame,
  .fc-timegrid-slot-label-cushion {
    line-height: ${props => (props.reduceColumns ? 1 : 1)};
  }
  .fc .fc-timegrid-slot:empty:after {
    content: '\\a0';
  }
  .fc-toolbar h2 {
    position: relative;
    left: 8.5rem;
    font-size: 1.4rem;
    margin-top: -5px;

    @media (max-width: ${XS}) {
      font-size: 1rem;
      /* top: 0.5rem; */
      top: ${props => (props.progress ? '0rem' : '0.5rem')};
      font-weight: bold;
    }
  }

  .fc .fc-datagrid-cell-cushion {
    white-space: normal;
    width: 100%;
  }
`;

function calculateWidth(reduceColumns) {
  if (reduceColumns) {
    return '12.3rem !important';
  } else {
    return '14.5rem !important';
  }
}

export const CalendarOptionsRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => (props.row===1 ? "1.5rem" : '0.5rem')};
  position: ${props => (props.row===2 ? "absolute" : 'static')};
  width: ${props => (props.row===2 ? "97%" : '100%')};

  @media (max-width: ${MD_LG}) {
    flex-direction: ${props => (props.row===1 && "column")};
    margin-bottom: ${props => (props.row===1 && "1rem")};
    align-items: ${props => (props.row===1 && "flex-start")};
  }
`

export const ButtonsContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;

  .daily {
    color: ${props => (props.selected ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props => (props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  }

  .weekly {
    color: ${props => (!props.selected ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props => (!props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${props => (!props.selected ? 'bold' : 'normal')};
  }

  .monthly {
    color: ${props => (!props.selected ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props => (!props.selected ? `4px solid ${PURPLE}` : 0)};
    background: transparent !important;
    font-weight: ${props => (!props.selected ? 'bold' : 'normal')};
  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const NextPreviousContainer = styled.div`
  .arrow {
    cursor: pointer;
    border: 0;
    background: 0;
    padding: 0 !important;
    margin: 0 !important;
    svg {
      padding: 0 !important;
      margin: 0 !important;
      color: ${PURPLE};
      font-size: 2.2rem;
    }
    &:active {
      svg {
        color: ${PURPLE_800};
      }
    }
  }

  /* top: 2.8rem;
  left: -0.6rem;
  position: absolute;

  @media (max-width: ${XXLL}) {
    top: ${props => (props.progress ? '6.1rem' : '2.8rem')};
  }
  @media (max-width: ${MD_LG}) {
    top: ${props => (props.progress ? '9.5rem' : '2.8rem')};
  }
  @media (max-width: ${MD}) {
    top: ${props => (props.progress ? '10.6rem' : '2.8rem')};
  }
  @media (max-width: ${XS}) {
    top: ${props => (props.progress ? '10rem' : '2.8rem')};
  } */
`;

export const ProgressContainer = styled.div`
//   display: flex;
//   /* position: absolute; */
//   /* top: 2.7rem; */
//   /* right: 0; */
//   /* justify-content: space-between; */

  
//   @media (max-width: ${XXLL}) {
//     position: initial;
//     margin-left: 1.75rem;
//     margin-top: 0.75rem;
//     margin-bottom: 1.25rem;
//   }
//   @media (max-width: ${MD_LG}) {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     margin-left: 1.75rem;
//     margin-top: 0.75rem;
//     margin-bottom: 1.25rem;
//   }
//   @media (max-width: ${MD}) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-left: 0;
//   }

//   @media (max-width: ${LG}) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-left: 0;
//   }
 
`;

export const ProgressBarContainer = styled.div`
//   height: 1.875rem;
  
//   paading-left:10px !important;
//   margin-left:2.125rem;
//   @media (max-width: ${MD_LG}) {
//     height: 3.5rem;
//   }
//   @media (max-width: ${MD}) {
//     margin-right: 0;
//   }
//   @media (max-width: 1295px) {
//     paading-left:10px !important;
//     margin-left:2.125rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
`;

export const ProgressOtherContainer = styled.div`

`;

export const StaticArrowUpDown = styled.div`
  height: 1.7rem;
  width: 1.7rem;
  left: 50%;
  top: ${({decreaseWidth}) => (decreaseWidth ? '2.5rem' : '0.75rem')};;
  cursor: pointer;
  position: relative;
  border-radius: 4rem;
  background: ${WHITE};
  display: flex;
  padding-bottom: ${({decreaseWidth}) => (decreaseWidth ? '0.3rem' : '')};
  // padding-right: ${({decreaseWidth}) => (decreaseWidth ? '' : '0.2rem')};
  // padding-left: ${({decreaseWidth}) => (decreaseWidth ? '0.2rem' : '')};
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 2px ${BLACK_900};
  z-index: 2;

  @media (max-width: ${MD}) {
    display: none;
  }
`;
