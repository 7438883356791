import React from 'react';
import { EmploymentTypesContainer, Title } from '../styles';
import './styles.css';
import { useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { useEffect } from 'react';
import { team_competency_matrix_chioces } from 'utils/choiceConstant';
import {
    getActiveEmployee,
    getDistinctCompetencyList,
    getEmployeeCompetencyMatrix,
} from 'utils/api/EmployeeApi';
import { useState } from 'react';
import moment from 'moment';
import {
    MATRIX_GREEN,
    MATRIX_RED,
    MATRIX_YELLOW,
} from 'shared/styles/constants/colors';
import { useForm } from 'hooks/FormHook';
import {
    DropdownTypesContainer,
    FiltersContainer,
    FiltersLeftGroup,
    LoadingContainer,
    PublishButton,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { DOWNLOAD } from 'utils/constants/icons';
import { PrimaryButton } from 'shared/styles/buttons';
import { getEmployee } from '../../../../utils/localStorage/token';
import {
    EMPLOYEE_COMPETENCY_MATRIX,
    SELF_WEB_CREATE_PERMISSION,
    SELF_WEB_DELETE_PERMISSION,
    SELF_WEB_READ_PERMISSION,
    SELF_WEB_UPDATE_PERMISSION,
    WEB_CREATE_PERMISSION,
    WEB_DELETE_PERMISSION,
    WEB_PERMISSION,
    WEB_READ_PERMISSION,
    WEB_UPDATE_PERMISSION,
} from 'utils/constants/permissions';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import Spinner from 'components/SharedComponents/Spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

function TeamCompetencyMatrix() {
    const toast = useRef(null);
    const {
        contextChoices: choices,
        contextPermissions,
        contextSubscription,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        handleGetChoices,
    } = useContext(AppContext);

    const userEmpoloyee = getEmployee();

    const { values, setValues, useInput } =
        useForm({});
    const { branchData } = useContext(BranchItemsContext);
    const [distinctCompetency, setDistinctCompetency] = useState([]);
    const [competencyMatrices, setCompetencyMatrices] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [selfReadPermission, setSelfReadPermission] = useState(undefined);
    const [employeeFilter, setEmployeeFilter] = useState([]);
    const [branchFilter, setBranchFilter] = useState([]);
    const [filterChoiseEmployee, setFilterChoiseEmployee] = useState([]);
    const [filterChoiseCompetency, setFilterChoiseCompetency] = useState([]);
    const [rows, setRows] = useState([]);
    const [counts, setCounts] = useState({});

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const rolesPermissions = contextPermissions;
        let rolePermissions = {};

        if (rolesPermissions?.length > 0) {
            rolesPermissions.forEach(item => {
                if (item.section !== null) {
                    // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_READ_PERMISSION
                    ] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_READ_PERMISSION
                    ] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_CREATE_PERMISSION
                    ] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_CREATE_PERMISSION
                    ] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_DELETE_PERMISSION
                    ] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_UPDATE_PERMISSION
                    ] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_UPDATE_PERMISSION
                    ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_DELETE_PERMISSION
                    ] = item?.[SELF_WEB_DELETE_PERMISSION];
                }
            });
        }
        const roles = contextSubSectionPermissions;
        if (roles.length > 0) {
            roles.forEach(item => {
                if (item.section !== null) {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_READ_PERMISSION
                    ] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_READ_PERMISSION
                    ] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_CREATE_PERMISSION
                    ] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_CREATE_PERMISSION
                    ] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_DELETE_PERMISSION
                    ] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_UPDATE_PERMISSION
                    ] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_UPDATE_PERMISSION
                    ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_DELETE_PERMISSION
                    ] = item?.[SELF_WEB_DELETE_PERMISSION];
                }
            });
        }
        if (rolePermissions[EMPLOYEE_COMPETENCY_MATRIX] !== undefined) {
            let self =
                rolePermissions[EMPLOYEE_COMPETENCY_MATRIX]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
                ];
            setSelfReadPermission(self);
        }
    }, [
        choices,
        contextIsSuperAdmin,
        contextIsAdmin,
        contextSubscription,
        contextPermissions,
        allEmployees,
    ]);

    // Employee Competency Matrix & Distinct Competency List apis
    const getCompetencyMatrixApis = async () => {
        setLoading(true)
        let date_from = values?.date_from;
        let date_to = values?.date_to;
        if (date_from && date_to && new Date(date_from) >= new Date(date_to)) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Date From should be greater than Date To!`,
            });
            return;
        }
        try {
            const [competencyMatrix, distinctCompetency, activeEmp] =
                await Promise.all([
                    getEmployeeCompetencyMatrix({ ...branchData, ...values }),
                    getDistinctCompetencyList({ ...branchData, ...values }),
                    getActiveEmployee({ ...values }),
                ]);
            if (
                competencyMatrix.status &&
                distinctCompetency.success &&
                activeEmp.status
            ) {
                setCompetencyMatrices(competencyMatrix);
                setDistinctCompetency(distinctCompetency?.data);
                setFilterChoiseCompetency(distinctCompetency?.data);
                setAllEmployees(activeEmp?.data);
                // setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getCompetencyMatrixApis();
    }, [branchData]);

    useEffect(() => {
        handleGetChoices(team_competency_matrix_chioces);
    }, []);

    useEffect(() => {
        getUpdatedEmployeeList();
    }, [distinctCompetency, allEmployees, selfReadPermission]);

    const getUpdatedEmployeeList = () => {
        if (values.employees && values.employees.length > 0) {
            let result = allEmployees?.filter(element => {
                return values.employees.includes(element.id);
            });
            setFilterChoiseEmployee(result || []);
        } else {
            setFilterChoiseEmployee(allEmployees);
        }
        if (selfReadPermission !== undefined) {
            if (selfReadPermission) {
                let filtedEmp = allEmployees?.filter(el => el.id === userEmpoloyee);
                // setActiveEmployees(filtedEmp);
                setFilterChoiseEmployee(filtedEmp)
                setLoading(false);
            } else {
                // setActiveEmployees(allEmployees);
                setFilterChoiseEmployee(allEmployees)

                setLoading(false);
            }
        } else {
            setLoading(false);
        }

    };

    const colorCombination = date => {
        const currentDate = moment();
        const oneWeekLater = moment().add(7, 'd');
        const formatedParamDate = moment(date);
        if (
            new Date(formatedParamDate) >= new Date(currentDate) &&
            new Date(formatedParamDate) <= new Date(oneWeekLater)
        ) {
            return { color: MATRIX_YELLOW, name: 'Training due to Expire' };
        } else if (new Date(formatedParamDate) > new Date(oneWeekLater)) {
            return { color: MATRIX_GREEN, name: 'Currently Trained' };
        } else {
            return { color: MATRIX_RED, name: 'Training Expired' };
        }
    };
    useEffect(() => {
        let formattedRows = filterChoiseEmployee?.map(employee => {
            let row = {
                id: employee.id,
                name: employee.full_name,
            };

            let a = filterChoiseCompetency?.forEach(competency => {
                const employeeCompetencyData = competencyMatrices?.data?.find(data =>
                    data.id === employee.id)?.employee_competency_data || [];
                const competencyMatched = employeeCompetencyData.find(data =>
                    data.id === competency.competency) || null;
                row[competency?.competency] = competencyMatched;
            });

            return row;
        });

        const shouldFilterRows = item => {
            const valuesWithoutIdAndName = Object.values(item).filter(
                val => val !== null && val !== undefined
            );
            return valuesWithoutIdAndName.length > 2;
        };

        if (
            (values.employees && values.employees.length) ||
            (values.branch && values.branch.length) ||
            values.date_from ||
            values.date_to ||
            branchData?.headerbranch?.length ||
            values.renewal_status
        ) {
            const filteredRows = formattedRows.filter(shouldFilterRows);
            setRows(filteredRows);
        } else {
            setRows(formattedRows);
        }
    }, [filterChoiseEmployee, competencyMatrices, distinctCompetency])



    const findNameFromChoices = (name, id) => {
        const find = choices?.competency_name?.find(compt => compt?.id == id);
        return find?.name;
    };

    var headers = filterChoiseCompetency?.map(c => ({
        id: c.competency,
        name: findNameFromChoices('skill', c?.competency),
    }));
    const columnWidth = `${headers?.length < 15 ? 100 / headers?.length : ''}%`;
    const capitalizeText = text =>
        text ? text.charAt(0).toUpperCase() + text.slice(1) : '';

    const statusColor = [
        { name: 'Training Expired', color_code: MATRIX_RED },
        { name: 'Currently Trained', color_code: MATRIX_GREEN },
        { name: 'Training due to Expire', color_code: MATRIX_YELLOW },
    ];

    const handleSearch = () => {
        getCompetencyMatrixApis();
    };

    const dropDownHandleSelect = (item, name) => {
        if (name === 'branch') {
            values.branch = item?.branch?.map(item => item.id);
        } else if (name === 'employees') {
            values.employees = item?.employees?.map(item => item.id);
        } else if (name === `renewal_status`) {
            values.renewal_status = item.renewal_status
        }
        setValues({ ...values });
    };
    const setEmployee = item => {
        setEmployeeFilter([...item]);
    };

    const setBranchs = item => {
        setBranchFilter([...item]);
    };
    let exportDate = moment().format('YYYY-MM-DD') + " " + moment().format('HH:mm:ss')

    useEffect(() => {
        let counts = {}
        rows.forEach((item) => {
            headers.map(header => {
                if (item[header.id]?.renewal_date) {
                    counts[colorCombination(item[header.id]?.renewal_date)?.name] =
                        (counts[colorCombination(item[header.id]?.renewal_date)?.name] || 0) + 1;
                }
            })
        })
        setCounts(counts)
    }, [rows])

    const statusDropDownValue = [
        { name: 'Training Expired', id: "training_expired" },
        { name: 'Currently Trained', id: "currently_trained" },
        { name: 'Training Due To Expire', id: "training_due_to_expire" },
    ]
    console.log(values, "values")
    return (
        <div className="team-competency-container">
            <Toast ref={toast} position="top-right" />
            <Title>Competency Matrix</Title>
            <FiltersContainer className='d-flex justify-content-between w-100 mt-2'>
                <div className='d-flex'>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={
                                    choices?.employee_listing?.map(el => ({
                                        ...el,
                                        name: el?.full_name,
                                    })) || []
                                }
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ employees: items }, 'employees');
                                }}
                                itemsSelected={employeeFilter}
                                setSelectedFilter={setEmployee}
                                placeHolder={'Employee'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.branch || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ branch: items }, 'branch');
                                }}
                                itemsSelected={branchFilter}
                                setSelectedFilter={setBranchs}
                                placeHolder={'Branch'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <DropdownTypesContainer className="ml-1 d-flex">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date From"
                                    {...useInput('date_from')}
                                />
                            </DropdownTypesContainer>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <DropdownTypesContainer className="ml-1  d-flex">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date To"
                                    {...useInput('date_to')}
                                />
                            </DropdownTypesContainer>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <DropdownSearchable
                                typeFilter={true}
                                placeHolder={'Status'}
                                options={
                                    statusDropDownValue || []
                                }
                                selectedOption={values.renewal_status}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        renewal_status: value ? value : null
                                    }, 'renewal_status')
                                }
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <PrimaryButton
                                type="button"
                                onClick={handleSearch}
                                style={{ marginLeft: '4px', marginBottom: '2px' }}>
                                <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
                            </PrimaryButton>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                </div>
                {rows.length > 0 && (
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="export-button"
                        table="competency-table-to-xls"
                        filename={`${exportDate} team-competency-matrix`}
                        sheet="tablexls"
                        buttonText={
                            <PublishButton>
                                {DOWNLOAD}
                            </PublishButton>
                        }
                    />)}
            </FiltersContainer>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                {statusColor.map(item => (
                    <>
                        <p className="color-combination d-flex">

                            <div
                                style={{
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: `${item.color_code || ''}`,
                                    marginRight: '8px',
                                    marginBottom: '5px',
                                    alignSelf: 'center',
                                }}></div>
                            <label className='count-text'> {item.name}</label>
                            <span className='count-text' style={{ marginLeft: '4px' }}>{`(${counts[item.name] || 0})`}</span>
                        </p>
                    </>
                ))}
            </div>
            <>
                {loading ? (
                    <LoadingContainer maxHeight={null}>
                        <Spinner />
                    </LoadingContainer>
                ) : (
                    <div class="competency-matrix-table">
                        {rows?.length ?
                            (<table id='competency-table-to-xls'>
                                <tr className="custom-border">
                                    <th
                                        className="body-text"
                                        style={{
                                            textAlign: headers?.length < 1 ? 'left' : '',
                                            padding: headers?.length < 1 ? '0' : '',
                                        }}>
                                        <div
                                            className={headers?.length < 1 ? 'title' : ''}
                                            style={{ maxWidth: headers?.length < 1 ? '150px' : '' }}>
                                            Employees
                                        </div>
                                    </th>
                                    {headers?.map(header => {
                                        return (
                                            <th className="body-text" style={{ maxWidth: columnWidth }}>
                                                {capitalizeText(header?.name)}
                                            </th>
                                        );
                                    })}
                                </tr>
                                {rows?.map((item, i) => {
                                    return (
                                        <tr className="custom-border">
                                            <td
                                                className="header-text"
                                                style={{
                                                    textAlign: headers.length < 1 ? 'left' : '',
                                                    padding: headers.length < 1 ? '0' : '',
                                                }}>
                                                <div
                                                    className={headers.length < 1 ? 'title' : ''}
                                                    style={{ maxWidth: headers.length < 1 ? '150px' : '' }}>
                                                    {capitalizeText(item.name)}
                                                </div>
                                            </td>
                                            {headers?.map(header => {
                                                const date = item[header.id]?.renewal_date
                                                    ? item[header.id]?.renewal_date
                                                    : null;
                                                return (
                                                    <td
                                                        className="header-text"
                                                        style={{
                                                            backgroundColor: date ? colorCombination(date).color : '',
                                                            color: date ? 'black' : '',
                                                        }}>
                                                        {date}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </table>)
                            : (
                                <div className='d-flex w-100 justify-content-center mt-4'>
                                    <h4 style={{ fontSize: '1rem' }}>No data found</h4>
                                </div>
                            )
                        }
                    </div>)}
            </>
        </div>
    );
}

export default TeamCompetencyMatrix;
