import React from 'react';
import { useEffect, useState, useRef } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import { PrimaryButtonModal } from 'components/SharedComponents/ModalDecision/styles';
import { IconStyle } from 'components/SharedComponents/ModalInformation/styles';
import { CancelButton } from 'components/SharedComponents/ModalDecision/styles';
import { Toast } from 'primereact/toast';
import { MessageOptaPlanner } from 'components/DashboardComponents/Scheduler/styles';
import _ from "lodash"

// import {} from './styles';
import { CHECK_CIRCLE, ERROR_OUTLINE } from 'utils/constants/icons';
import { ModalContainer } from 'shared/styles/constants/tagsStyles';
const ConfirmationModal = ({
  fullBind,
  setModalValue,
  type,
  title,
  handleSubmit,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Confirmation',
    });
  }, [fullBind]);

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{ERROR_OUTLINE}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              <h5>
                Are you sure you want to delete this account? This action is irreversible.<br />After deleting this account, you will lose access to all services and features linked to it.
              </h5>
              <h6>

              </h6>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() =>
                setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              // typeButton={type}

              onClick={handleSubmit}>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default ConfirmationModal;