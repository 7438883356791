import {
  USER_NAME,
  USER_FIRST_NAME,
  USER_LAST_NAME,
  USER_EMAIL,
  ORGNAME,
} from 'utils/constants/localStorage';
import {
  FILTERED_PERMISSIONS,
  PERMISSIONS,
  USER_CATEGORY_PERMISSIONS,
  USER_ID,
  USER_SUB_SECTION_PERMISSIONS,
  IS_STAFF,
  CLIENT,
  EMPLOYEE,
} from '../constants/localStorage';

export const getUserName = () => {
  return localStorage.getItem(USER_NAME) || '';
};
export const getUserFirstName = () => {
  return localStorage.getItem(USER_FIRST_NAME) || '';
};
export const getUserLastName = () => {
  return localStorage.getItem(USER_LAST_NAME) || '';
};
export const getEmail = () => {
  return localStorage.getItem(USER_EMAIL) || '';
};
export const getUserId = () => {
  return localStorage.getItem(USER_ID) || '';
};

export const setUser = user => {
  localStorage.setItem(USER_NAME, user.username);
  localStorage.setItem(USER_FIRST_NAME, user.first_name);
  localStorage.setItem(USER_LAST_NAME, user.last_name);
  localStorage.setItem(USER_EMAIL, user.email);
  localStorage.setItem(USER_ID, user.id);
};

export const setPermissions = permissions => {
  localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
};

export const getPermissions = () => {
  let permissions = localStorage.getItem(PERMISSIONS);
  return JSON.parse(permissions ? permissions : []);
};

export const setCategoryPermissions = permissions => {
  localStorage.setItem(USER_CATEGORY_PERMISSIONS, JSON.stringify(permissions));
};

export const getCategoryPermission = () => {
  let categoryPermissions = localStorage.getItem(USER_CATEGORY_PERMISSIONS);
  return JSON.parse(categoryPermissions ? categoryPermissions : []);
};

export const setSubSectionPermissions = permissions => {
  // console.log(permissions, 7444)
  localStorage.setItem(
    USER_SUB_SECTION_PERMISSIONS,
    JSON.stringify(permissions)
  );

};

export const getSubSectionPermissions = () => {
  let categoryPermissions = localStorage.getItem(USER_SUB_SECTION_PERMISSIONS);
  return categoryPermissions ? JSON.parse(categoryPermissions) : [];
};

export const getFilteredPermissions = () => {
  let filteredPermissions = localStorage.getItem(FILTERED_PERMISSIONS);
  return JSON.parse(filteredPermissions ? filteredPermissions : []);
};
export const setFilteredPermissions = permissions => {
  localStorage.setItem(FILTERED_PERMISSIONS, JSON.stringify(permissions));
};


export const setOrgname = value => {
  localStorage.setItem(ORGNAME, value);
};

export const getOrgname = () => {
  return localStorage.getItem(ORGNAME);
};

export const getStaff = () => {
  return localStorage.getItem(IS_STAFF) || '';
};

export const getClient = () => {
  return localStorage.getItem(CLIENT) || '';
};

export const getEmployee = () => {
  return localStorage.getItem(EMPLOYEE) || '';
};