import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Dropdown from 'components/SharedComponents/Dropdown';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
// imported  new files
import { getClientTypes } from 'utils/api/CoreApi';
import { setClientContract, getFunderDetails, getClientTask, getClientGroupTask, getPlanned, setClientMedication, getMedicationData, getMedicationById, deleteMedication, getMedicationStock, createMedication } from 'utils/api/ClientApi';
import { getFundersList } from 'utils/api/ClientApi'
import { useHistory } from 'react-router';
import _ from "lodash"


import moment from 'moment';
import {
    InvalidOrEmptyValues,
    DetailsNotCreated,
    SAVE,
    SAVED,
    ERROR
} from 'utils/constants/messages';
import {
    Row,
    Col,
    IconWrapper,
    DayPill,
} from 'components/SharedComponents/Table/styles';
import {
    getClientsServicesById,
    getClientsServices,
    deleteServiceContract,
} from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';
import { BsFolderPlus } from 'react-icons/bs';
// import { headerColumns } from './headerColumsList';
import { LARGE_COL, MEDIUM_COL, EX_LARGE_COL } from 'shared/styles/constants/columns';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getPageToSearchParam } from 'shared/methods';
import { useModal } from 'hooks/ModalHook';
import { FLOPPY, CROSS, CHECK } from 'utils/constants/icons';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {Toast} from 'primereact/toast';

import { AppContext } from "context"
import {

    Toggle,
    ToggleLabel,
    ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { getCategoryPermission, getPermissions, getSubSectionPermissions } from '../../../../utils/localStorage/user';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { HOUR, MINUTE } from 'utils/constants/serviceVisits';
import ModalServiceClient from './ModalServiceClient';
import { client_med_stock_audit } from 'utils/choiceConstant';

const NEW_CONTRACT = 'new';

const MedicationStockAndAudit = ({
    selectedClientId,
    choices,
    contextChoicesMap,
    allErrors,
    errorSplitPercentage,
    setErrorSplitPercentage,
    loadingFinish,
    textStatus,
    savedContract,
    setSavedContract,
    addressValues,
    clientFullName,
    setTextStatus,
    clientErrors,
    promisesWithErrors,
    // cliId,
    filterAdmin,
    filterSuperAdmin,
    contextSubscription,
    rightPermission,
    userClient,
    readServicePermissions,
    createServicePermissions,
    updateServicePermissions,
    deleteServicePermissions
}) => {
    const {
        values,
        setValues,
        useInput,
        isValid,
        errors,
        setCheckRequires,
    } = useForm({});
    const [idSelected, setIdSelected] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [modalValue, setModalValue] = useState(false);
    const [editToggle, setEditToggle] = useState(false)
    const [pages, setPages] = useState(0);
    const [serviceContracts, setServiceContracts] = useState([]);
    const [serviceContractsStock, setServiceContractsStocks] = useState([]);
    const toast = useRef();
    const [ServiceContractLength, setServiceContractLength] = useState();
    const [DeleteMedicationData, setDeleteMedicationData] = useState();
    const [simplePagination] = useState(true);
    const [dateError, setDateError] = useState([]);
    const [timeError, setTimeError] = useState([])
    const mountedRef = useRef(true);
    const [editMode, setEditMode] = useState(true)
    const [editData, setEditData] = useState(null)
    const [earlieMessage, setEarlieMessage] = useState('')
    const [earlieErrors, setEarlieError] = useState([])
    const [showEarlieError, setShowEarlieError] = useState(false)
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
        false,
    );
    const {
        setValue: setModalFunderValue,
        value: modalFunderValue,
        setTitle,
        fullBind,
    } = useModal(false);
    const [loadingSave, setLoadingSave] = useState(false)
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [modalLoading, setModalLoading] = useState(true)

    const history = useHistory()
    const location = useLocation();
    const { handleGetChoices } = useContext(AppContext)

   
    useEffect(()=>{
      handleGetChoices(client_med_stock_audit)
    },[])

    useEffect(() => {
        if (selectedClientId) {
            getClientContracts();
        }
        else {
            setModalLoading(false)
            setModalValue(true);
        }
    }, [selectedClientId, currentPage, savedContract]);

    useEffect(() => {

        if (idSelected) {
            //searchClientService();
            setShowError(false);
            setErrorMessage('');
            setDateError([]);
            errors.splice(0, errors.length - 1);
        }
    }, [modalValue]);

    
    const updateMedication = () => {

        const numberOfEntry = []
        values.adjustment.forEach(value => numberOfEntry.push({ date_of_entry: value.date_of_entry, count_up_down: value.count_up_down, id: value.id ? value.id : null, reason: value.reason }))
        const data = {
            "initial_stock": values.initial_stock,
            "medication_name": values.medication_name,
            "date_of_entry_initial_stock": values.date_of_entry_initial_stock,
            "date_of_expiry": values.date_of_expiry,
            "medication_initial_stock_id": values.id,
            "minimum_stock": values.minimum_stock,
            "adjustment": numberOfEntry,
        }

        // let clientId = ''
        // if (location.pathname.includes('/client/add')) {
        //     clientId = cliId
        // } else {
        //     clientId = selectedClientId
        // }

        createMedication(data, false, values.id, selectedClientId).then(res => {
            setIdSelected('');
            setEditToggle(false)
            setModalValue(!modalValue);
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Updated successfully',
            });
            getClientContracts();
        }).catch((err) => {
        })
    }

    const checkBeforeSend = () => {

            if (loadingSave) {
            return;
        }

        if (!values.medication_name || !values.date_of_entry_initial_stock || !values.date_of_expiry) {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(InvalidOrEmptyValues);
            return;
        }
        setCheckRequires(false);
        setErrorMessage('');
        // let clientId = ''
        // if (location.pathname.includes('/client/add')) {
        //     clientId = cliId
        // } else {
        //     clientId = selectedClientId
        // }
        if (editMode) {
            const data = {
                "initial_stock": values.initial_stock,
                "medication_name": values.medication_name,
                "minimum_stock": values.minimum_stock,
                "date_of_entry_initial_stock": values.date_of_entry_initial_stock,
                "date_of_expiry": values.date_of_expiry,
                "client": selectedClientId,
            }
            createMedication(data, true, "", "").then(res => {
                setIdSelected('');
                setEditToggle(false)
                setModalValue(!modalValue);
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Saved successfully',
                });
               getClientContracts();
            }).catch((err) => {
            })
        } else {
            updateMedication()
        }
    }

    // const checkBeforeSend = (
    //     evt,
    //     closeModal,
    //     saveTrigger,
    //     values,
    //     toDeleteVisits,
    //     toDeleteSplitFunder,
    //     toDeleteVisitSkill,
    // ) => {


    //     setSavedContract('unsaved');

    //     handleSubmit(
    //         evt,
    //         closeModal,
    //         saveTrigger,
    //         values,
    //         toDeleteVisits,
    //         toDeleteSplitFunder,
    //         toDeleteVisitSkill,
    //     );
    // };

    // const handleSubmit = (
    //     evt,
    //     closeModal = false,
    //     saveTrigger = true,
    //     serviceContractValues = { service_clients: [] },
    //     toDeleteVisits = [],
    //     toDeleteSplitFunder = [],
    //     toDeleteVisitSkill = []
    // ) => {



    //     if (loadingSave) {
    //         return;
    //     }

    //     if (!values.name || !values.start_date || !values.every || !values.end_type) {
    //         setCheckRequires(true);
    //         setShowError(true);
    //         setErrorMessage(InvalidOrEmptyValues);
    //         return;
    //     }

    //     if (!((values.every === HOUR) || (values.every === MINUTE)) && (!values.dose_list || (values.dose_list.length < 1))) {
    //         setCheckRequires(true);
    //         setShowError(true);
    //         setErrorMessage('Please add time of dose.');
    //         return;
    //     }

    //     if (!((values.every === HOUR) || (values.every === MINUTE)) && (!values.dose_list[0].dose || !values.dose_list[0].medication_dose_time)) {
    //         setCheckRequires(true);
    //         setShowError(true);
    //         setErrorMessage(InvalidOrEmptyValues);
    //         return;
    //     }

    //     setErrorMessage('');
    //     let clientId = ''
    //     if (location.pathname.includes('/client/add')) {
    //         clientId = cliId
    //     } else {
    //         clientId = selectedClientId
    //     }
    //     //let body = _.cloneDeep(values)
    //     let body = {
    //         start_date: values.start_date,
    //         expiry_date: values.expiry_date,
    //         repeat: values.repeat || null,
    //         medication_dose: values.dose_list,
    //         name: values.name,
    //         medication_type: values.medication_type,
    //         administration_type: values.administration_type,
    //         administration_reason: values.administration_reason,
    //         medication_route: values.medication_route,
    //         medication_form: values.medication_form,
    //         client: clientId,
    //         expiry_reason: values.expiry_reason || null,
    //         medication_assistance: values.medication_assistance,
    //         is_adhoc: false,
    //         end_type: values.end_type,
    //         every: (values.medication_frequency_name == 'Daily') ? 'DAY' : values.every,
    //         end_value: values.end_value ? values.end_value : null,

    //     }
    //     if (values.id) {
    //         body.id = values.id
    //     }

    //     if (saveTrigger) {
    //         setLoadingSave(true);
    //     }

    //     setClientMedication(body, clientId)
    //         .then(response => {
    //             if (response.message.status !== "SUCCESS") {
    //                 throw response.message.message;
    //             }
    //             onSuccess(response.message);
    //         })
    //         .catch(onError);
    // };

    const onSuccess = async (response) => {
        if (response.status === "SUCCESS" || response.status === true) {
            handleModal()
            setLoadingSave(false);
            setButtonIcon(CHECK);
            setTextStatus(SAVED);
        }
    };

    const onError = err => {
        setLoadingSave(false);
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(
            err.message
        );
    };

    const getClientContracts = async () => {
        const pageToSearch = getPageToSearchParam(
            '',
            setCurrentPage,
            simplePagination,
            currentPage,
        );
        // const response = await getMedicationData({
        //     selectedClientId,
        //     idSelected,
        //     ...pageToSearch,
        // });

        const resposeMedicationStock = await getMedicationStock(selectedClientId)


        if (resposeMedicationStock.results && resposeMedicationStock.results.length < 1) {
            setModalValue(true);
        }
        setServiceContractLength(resposeMedicationStock.results && resposeMedicationStock.results.length)
        //setServiceContracts(resposeMedicationStock.results || []);
        setServiceContractsStocks(resposeMedicationStock.results || []);
        setPages(Math.ceil(resposeMedicationStock.count / PAGE_SIZE_RENDER));
        setModalLoading(false)
    };

    const searchClientService = async () => {
        // let clientId = cliId ? cliId : selectedClientId
        const response = await getMedicationById(selectedClientId, idSelected);

        setValues({
            ...values,
            ...response
        });
    };

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleRowClick = id => {
        setEditToggle(true)
        setEditMode(false)
        setIdSelected(id);
        setCheckRequires(false);
        setShowError(false);
        setErrorMessage('');
        setModalValue(true);
    };

    const handleModal = event => {
        setIdSelected('');
        setEditToggle(false)
        setModalValue(!modalValue);
        if (event === NEW_CONTRACT) {
            setValues({})
            setButtonIcon(FLOPPY);
            setEditMode(true)
            setTextStatus(SAVE);
        } else {
            setEditMode(false)
            setValues({})
            getClientContracts();
        }
        
    };

    const toDeleteClient = id => {
        setModalDeleteValue(true);
        setIdSelected(id);
        deleteMed(id, 'False');
    };

    const deleteMed = async (id, force) => {
        // let clientId = cliId ? cliId : selectedClientId
        const response = await deleteMedication(id, selectedClientId, force);
        if (force === 'False') {
            setDeleteMedicationData(response.data)
        } else {
            setModalDeleteValue(false);
            getClientContracts();
            setIdSelected('');
        }

    }



    const dropDownHandleSelect = (item, nameItem, index) => {
        if (nameItem == 'dose') {
            values.dose_list[index][nameItem] = item[nameItem];
        } else {
            values[nameItem] = item[nameItem];
        }
        setValues({ ...values }); //item added

    };

    return (
        <ComponentDynamic
            loading={modalLoading}
        >
             <Toast ref={toast} position="top-right" />
            {(selectedClientId ? true : true) ?
                <div>


                    {(serviceContractsStock.length >= 1) && !modalValue ? (

                        <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
                            <div className={'text-primary font-weight-bold'}>
                                <div>
                                    <PrimaryButtonForm
                                        minWidth="6rem"
                                        onClick={() => handleModal(NEW_CONTRACT)}>
                                        <BsFolderPlus size={18} />
                                        <span className={'ml-2'}>ADD STOCK AND AUDIT</span>
                                    </PrimaryButtonForm>
                                </div>
                            </div>

                        </div>

                    ) : (
                        <Fragment />
                    )}
                    {modalValue && serviceContractsStock.length >= 1 ? (
                        <div className={'w-100 d-flex justify-content-center'}>
                            <div>
                                <PrimaryButtonForm
                                    bgcolor={'PURPLE_GRAY_500'}
                                    minWidth="6rem"
                                    onClick={handleModal}>
                                    <div className={'d-flex align-items-center'}>
                                        <TiArrowBackOutline size={24} />
                                        <div>BACK</div>
                                    </div>
                                </PrimaryButtonForm>
                            </div>

                        </div>
                    ) : (
                        <Fragment />
                    )}


                    {(!modalValue && serviceContractsStock.length >= 1) ?
                        <>
                            <Table
                                headerColumns={[
                                    {
                                        label: 'Medication Name',
                                        name: 'medication_name',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    },

                                    {
                                        label: 'Start Date',
                                        name: 'start_dates',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    },
                                    {
                                        label: 'Expiry Date',
                                        name: 'expiry_date',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    },
                                    {
                                        label: 'Quantity',
                                        name: 'end_dates',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    },
                                    {
                                        label: 'Remaining Quantity',
                                        name: 'end_dates',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    },
                                ]}
                                headerPadding={false}
                                noEditOption
                                fontSize="0.75rem"
                                callBackQuerySort={setQuerySort}
                                noNeed={false}
                                >
                                <div>

                                    {serviceContractsStock.map((clientContract, index) => {

                                        const endDate = clientContract.expiry_date
                                            ? moment(clientContract.expiry_date).format('DD-MM-YYYY')
                                            : 'Unset';
                                        const startDate = clientContract.start_date
                                            ? moment(clientContract.start_date).format('DD-MM-YYYY')
                                            : 'Unset';

                                        const findMedication = () => {
                                            let finalCount = clientContract?.adjustment?.reduce((cur, pre) => cur + pre.count_up_down, 0)
                                            return finalCount + clientContract?.initial_stock
                                        }
                                        let remainingStock = findMedication()

                                        return (
                                            <div key={index}>
                                                <Row bgColor Shadow
                                                    zIndex={serviceContractsStock - index}
                                                >
                                                    <Col
                                                        onClick={() => {
                                                            handleRowClick(clientContract.id);
                                                            setValues(clientContract)
                                                        }}
                                                        max_width={LARGE_COL}
                                                        Shrink
                                                        reducePadding
                                                        Capitalize

                                                        overlap={true}
                                                        Center>
                                                        {clientContract.medication_name && contextChoicesMap && contextChoicesMap.medication_name && contextChoicesMap.medication_name[clientContract.medication_name]?.name}
                                                    </Col>

                                                    <Col
                                                        onClick={() => {
                                                            handleRowClick(clientContract.id);
                                                            setValues(clientContract)
                                                        }}
                                                        max_width={LARGE_COL}
                                                        Shrink
                                                        reducePadding
                                                        Capitalize
                                                        overlap={true}
                                                        Center>
                                                        {clientContract.date_of_entry_initial_stock}
                                                    </Col>

                                                    <Col
                                                        onClick={() => {
                                                            handleRowClick(clientContract.id);
                                                            setValues(clientContract)
                                                        }}
                                                        max_width={LARGE_COL}
                                                        Shrink
                                                        reducePadding
                                                        overlap={true}
                                                        Capitalize
                                                        Center>
                                                        {clientContract.date_of_expiry}
                                                    </Col>

                                                    <Col
                                                        onClick={() => {
                                                            handleRowClick(clientContract.id);
                                                            setValues(clientContract)
                                                        }}
                                                        max_width={LARGE_COL}
                                                        Shrink
                                                        reducePadding
                                                        overlap={true}
                                                        Capitalize
                                                        Center>
                                                        {clientContract.initial_stock}
                                                    </Col>

                                                    <Col
                                                        onClick={() => {
                                                            handleRowClick(clientContract.id);
                                                            setValues(clientContract)
                                                        }}
                                                        max_width={LARGE_COL}
                                                        Shrink
                                                        reducePadding
                                                        overlap={true}
                                                        Capitalize
                                                        Center>
                                                        {remainingStock}
                                                    </Col>
                                                    {/* <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                                        <IconWrapper
                                                            onClick={() => {
                                                                toDeleteClient(clientContract.id);
                                                            }}>
                                                            <RiDeleteBinLine />
                                                        </IconWrapper>
                                                    </Col> */}
                                                </Row>
                                                <div className="mb-1" />
                                            </div>
                                        );
                                    })}
                                    <div className="mt-3">
                                        <Pagination
                                            simplePagination={simplePagination}
                                            setCurrentPage={setCurrentPage}
                                            totalPages={pages}
                                            currentPage={currentPage}
                                            dataLength={serviceContractsStock.length}
                                            dropdownEntriesLimit={false}
                                        />
                                    </div>
                                </div>

                            </Table>
                            {/* <div
                                className={'d-flex justify-content-center justify-content-md-end'}>
                                <div>
                                    <PrimaryButtonForm
                                        bgcolor="PURPLE_GRAY_500"
                                        minWidth="6rem"
                                        className={'mr-2'}
                                        onClick={() => {
                                            history.push('/client')
                                        }}>
                                        <span className={'mr-2'}>{'Cancel'}</span>
                                    </PrimaryButtonForm>
                                </div>
                            </div> */}
                        </>
                        :
                        (
                            <ModalServiceClient
                                earlieErrors={earlieErrors}
                                setShowEarlieError={setShowEarlieError}
                                setEarlieMessage={setEarlieMessage}
                                setModalValue={setModalValue}
                                modalValue={modalValue}
                                earlieMessage={earlieMessage}
                                setEarlieError={setEarlieError}
                                showEarlieError={showEarlieError}
                                setSavedContract={setSavedContract}
                                InvalidOrEmptyValues={InvalidOrEmptyValues}
                                DetailsNotCreated={DetailsNotCreated}
                                setErrorMessage={setErrorMessage}
                                clientErrors={clientErrors}
                                setShowError={setShowError}
                                promisesWithErrors={promisesWithErrors}
                                setCheckRequires={setCheckRequires}
                                setEditData={setEditData}
                                editData={editData}
                                isValid={isValid}
                                allErrors={allErrors}
                                editMode={editMode}
                                setEditMode={setEditMode}
                                serviceId={idSelected}
                                selectedClientId={selectedClientId}
                                dropDownHandleSelect={dropDownHandleSelect}
                                values={values}
                                setValues={setValues}
                                clientId={selectedClientId}
                                useInput={useInput}
                                choices={choices}
                                handleModal={handleModal}
                                getClientContracts={getClientContracts}
                                checkBeforeSend={checkBeforeSend}
                                showError={showError}
                                errorMessage={errorMessage}
                                setErrorSplitPercentage={setErrorSplitPercentage}
                                errorSplitPercentage={errorSplitPercentage}
                                errors={errors}
                                loadingFinish={loadingFinish}
                                loadingSave={loadingSave}
                                textStatus={textStatus}
                                buttonIcon={buttonIcon}
                                addressValues={addressValues}
                                clientFullName={clientFullName}
                                setDateError={setDateError}
                                dateError={dateError}
                                setTimeError={setTimeError}
                                timeError={timeError}
                                updateServicePermissions={updateServicePermissions}
                                filterAdmin={filterAdmin}
                                filterSuperAdmin={filterSuperAdmin}
                                contextSubscription={contextSubscription}
                                rightPermission={rightPermission}
                                userClient={userClient}
                            />
                        )}
                    {!modalFunderValue ? null : (
                        <AddModal
                            choices={choices}
                            values={values}
                            setValues={setValues}
                            isValid={isValid}
                            errors={errors}
                            useInput={useInput}
                            readOnly={false}
                            fullBind={fullBind}
                            setTitle={setTitle}
                            setIdSelected={setIdSelected}
                            setCheckRequires={setCheckRequires}
                            newAddFunder={true}

                        />
                    )}
                    <div>
                        <ModalDecision
                            type="delete"
                            title="Warning"
                            body={
                                'Are you sure you want to delete this medication? \n DELETING the medication will remove all doses for this medication.'
                            }
                            subBody={
                                ''
                            }
                            onOk={() => { deleteMed(idSelected, 'True') }}
                            onCancel={() => {
                                setIdSelected('');
                            }}
                            okTitle={'DELETE'}
                            {...bindModalDelete}
                        />
                    </div>
                </div>
                :
                <NoPermissionContainer>
                    You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
                </NoPermissionContainer>
            }
        </ComponentDynamic>
    );
};


MedicationStockAndAudit.propTypes = {
    choices: PropTypes.object.isRequired,
    selectedClientId: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    allErrors: PropTypes.bool.isRequired,
    setErrorSplitPercentage: PropTypes.func.isRequired,
    errorSplitPercentage: PropTypes.string.isRequired,
    loadingFinish: PropTypes.bool.isRequired,
    loadingSave: PropTypes.bool.isRequired,
    textStatus: PropTypes.string.isRequired,
    contractId: PropTypes.string.isRequired,
    savedContract: PropTypes.string.isRequired,
    setSavedContract: PropTypes.func.isRequired,
    addressValues: PropTypes.object.isRequired,
    setTextStatus: PropTypes.func.isRequired,
    clientErrors: PropTypes.array.isRequired,
    promisesWithErrors: PropTypes.array.isRequired,
};

export default MedicationStockAndAudit;



