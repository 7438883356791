import React, {Fragment,useContext,useEffect,useState} from 'react';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {PLUS, TIMES} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import {Header, Title,TitleSection} from 'shared/styles/constants/tagsStyles';
  import ToggleButton from 'components/SharedComponents/ToggleButton'; 

const ClockOutOfVisit = ({
  index,
  values,
  data,
  addClockOutToVisit,
  deleteClockOutToVisit,
  useInput,
}) => {
  return (
    <div>
             
        <>
        <div key={index} className={`mt-1`}style={{marginLeft:'12px',marginRight:'12px',width:'73%'}}>
               <div className='d-flex'>
                  <Fragment />
                  {/* {index!==0 &&  */}
                  <CloseButtonWrapper
                  onClick={() => {
                    deleteClockOutToVisit(data, index);
                  }}>
                  {TIMES}
                </CloseButtonWrapper>
                  {/* } */}
       <div className="p-0  w-100 d-flex align-items-center  ">
     <FlexGrid3Container className={'w-100 mb-1 mt-1 '}  >
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
   <TimeSelector
            //   disabled={!checkUpdatePermission}
            labelTop={'Time  From'}
            timeFormat={"HH:mm"}
            dateFormat={"HH:mm"}
            {...useInput(`clock_out_of_visit[${index}].reminder_time`)}
            placeholderText={'hh:mm'}
            locale={"sv-sv"}
            timeCaption={'Duration'}
            />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" style={{marginLeft:'10px'}} >

           <ToggleButton
                          useInput={useInput(`clock_out_of_visit[${index}].is_active`)}
                          id="clock_out_of_visit[${index}].is_active"
                        />
   </FlexGrid3Item>

   </FlexGrid3Container>
   </div>
   </div>
   </div>
    </>
    </div>
  )
}

export default ClockOutOfVisit