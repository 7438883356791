import React,{useEffect} from 'react';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import moment from'moment';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';

import { add_triage_choices } from 'utils/choiceConstant';
import { useContext } from 'react';
import { AppContext } from 'context';
const CommentList = ({
    commentData,
    index,
    deleteCommentList,
    useInput,
    values,
    setValues,
    employeeName,
    contextChoices,
    triage_choices,
}) => {
  const {handleGetChoices} = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(add_triage_choices)
    
  }, [])
const nameCreatedBy = contextChoices?.user_list?.find(x=>x.id==values.alert_comment[index]?.created_by)?.name;

const firstName = nameCreatedBy?.split(' ').slice(0, -1).join(' ');

const lastName = nameCreatedBy?.split(' ').slice(-1).join(' ');

const photo=contextChoices?.user_list?.find(x=>x.id==values.alert_comment[index]?.created_by)?.photo;;

const dateCreatedAt = values.alert_comment[index]?.created_at?.split('T')[0];

const timeCreatedAt = moment(values.alert_comment[index]?.created_at?.split('T')[1], 'HH:mm').format('HH:mm');

const dateTime = dateCreatedAt + '  '+ '  ' + timeCreatedAt;

const  colorBooleabValue=values.alert_comment[index].is_history;
  return (
    <div key={commentData.id} className={`mt-1 mb-1 container `} >
    <div className='row'>
    <div className='col-sm-12 col-md-4 col-lg-4'>
    <div className='mr-0'>  
    <div className=' '>

        <RowInfoModel
          name={firstName || nameCreatedBy}
          lastName={lastName}
          photoRow={''
            // photo
            //   ? URL.createObjectURL(photo)
            //     : ''
          }
          rows={[
            {value: nameCreatedBy }
          ]}

        />

   </div> 
    <div className=' mt-1 ' >
      <p  style={{ fontSize:'11.5px'}}>{dateTime} </p>
      </div>
    </div>
    </div>
    <div className='col-sm-12 col-md-8 col-lg-8'>
    <div className='ml-0'>
               <InputTextArea
               color={colorBooleabValue}
               disabled={true}
                          type="text"
                          height={'4rem'}
                          placeholder={'Previous comments'}
                {...useInput(`alert_comment[${index}].comment`)}
                 />

    </div>

    </div>
    </div>

    </div>
  )
}

export default CommentList