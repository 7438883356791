import moment from 'moment';

export const getOptionName = (array, id) => {
  let item = {name: ''};
  if (array) {
    item = array?.find(x => x?.id === id) || {
      name: 'Unspecified',
    };
  }
  return item?.name || item?.full_name;
};

export const getTimesArray = () => {
  const allTimes = [];

  //added array time with format hh:mm a.m. / p.m.
  for (let hour = 0; hour <= 23; hour++) {
    const formatTime = moment(hour, ['h:mm']).format('hh:mm A');
    allTimes.push({
      id: moment(formatTime, ['h:mm A']).format('HH:mm:ss'),
      name: formatTime,
    });
    let formatTime1 = moment(hour, ['h:mm']);
    for(let min=0; min<=2; min++){
      allTimes.push({
        id: moment(formatTime1, ['h:mm A']).add(15, 'minutes').format('HH:mm:ss'),
        name: formatTime1.add(15, 'minutes').format('hh:mm A'),
      });
      formatTime1 = formatTime1;
    }
  }
  allTimes.unshift({id: '', name: 'All'});
  // [All, 12:00 AM, 01:00 AM, etc]
  return allTimes;
};

export const getTimesArrayINHHMMFormat = () => {
  const allTimes = [];

  //added array time with format HH:mm.
  for (let hour = 0; hour <= 23; hour++) {
    const formatTime = moment(hour, ['HH:mm']).format('HH:mm');
    allTimes.push({
      id: moment(formatTime, ['HH:mm']).format('HH:mm'),
      name: formatTime,
    });
    let formatTime1 = moment(hour, ['HH:mm']);
    for(let min=0; min<=2; min++){
      allTimes.push({
        id: moment(formatTime1, ['HH:mm']).add(15, 'minutes').format('HH:mm'),
        name: formatTime1.add(15, 'minutes').format('HH:mm'),
      });
      formatTime1 = formatTime1;
    }
  }
  // allTimes.unshift({id: '', name: 'All'});
  // HH:mm. format
  return allTimes;
};

export const getTimesArrayFormat = () => {
  const allTimes = [];

  //added array time with format hh:mm a.m. / p.m.
  for (let hour = 0; hour <= 23; hour++) {
    const formatTime = moment(hour, ['h:mm']).format('HH:mm');
    allTimes.push({
      id: moment(formatTime, ['h:mm A']).format('HH:mm:ss'),
      name: formatTime,
    });
    let formatTime1 = moment(hour, ['h:mm']);
    for(let min=0; min<=2; min++){
      allTimes.push({
        id: moment(formatTime1, ['h:mm A']).add(15, 'minutes').format('HH:mm:ss'),
        name: formatTime1.add(15, 'minutes').format('HH:mm'),
      });
      formatTime1 = formatTime1;
    }
  }
  allTimes.unshift({id: '', name: 'All'});
  // [All, 12:00 AM, 01:00 AM, etc]
  return allTimes;
};

export const getDaysArray = isNumber => {
  return [
    {id: '', name: 'All'},
    {
      id: isNumber ? 1 : 'MON',
      name: 'Monday',
    },
    {
      id: isNumber ? 2 : 'TUE',
      name: 'Tuesday',
    },
    {
      id: isNumber ? 3 : 'WED',
      name: 'Wednesday',
    },
    {
      id: isNumber ? 4 : 'THU',
      name: 'Thursday',
    },
    {
      id: isNumber ? 5 : 'FRI',
      name: 'Friday',
    },
    {
      id: isNumber ? 6 : 'SAT',
      name: 'Saturday',
    },
    {
      id: isNumber ? 7 : 'SUN',
      name: 'Sunday',
    },
  ];
};

export const getCodeDaysArray = () => {
  return [
    {name: 'MON'},
    {name: 'TUE'},
    {name: 'WED'},
    {name: 'THU'},
    {name: 'FRI'},
    {name: 'SAT'},
    {name: 'SUN'},
  ];
};
