import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AppContext } from 'context';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import { IconWrapper } from 'shared/styles/constants/tagsStyles';
import { ItemTypes } from 'components/DashboardComponents/FormBuilder/ItemTypes';

export const CustomNumberInput = React.forwardRef(function CustomNumberInput(
  props,
  ref
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  );
});
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};
const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const StyledInputRoot = styled('div')(
  ({ theme }) => `
        font-family: IBM Plex Sans, sans-serif;
        font-weight: 400;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
    };
        box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark'
      ? 'rgba(0,0,0, 0.5)'
      : 'rgba(0,0,0, 0.05)'
    };
        display: grid;
        grid-template-columns: 1fr 19px;
        grid-template-rows: 1fr 1fr;
        overflow: hidden;
        column-gap: 8px;
        padding: 4px;
      
        &.${numberInputClasses.focused} {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
        }
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
);
const StyledInputElement = styled('input')(
  ({ theme }) => `
        font-size: 0.875rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.5;
        grid-column: 1/2;
        grid-row: 1/3;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: inherit;
        border: none;
        border-radius: inherit;
        padding: 8px 12px;
        outline: 0;
      `
);
const StyledButton = styled('button')(
  ({ theme }) => `
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        appearance: none;
        padding: 0;
        width: 19px;
        height: 19px;
        font-family: system-ui, sans-serif;
        font-size: 0.875rem;
        line-height: 1;
        box-sizing: border-box;
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 0;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
      
        &:hover {
          background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
          border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]
    };
          cursor: pointer;
        }
      
        &.${numberInputClasses.incrementButton} {
          grid-column: 2/3;
          grid-row: 1/2;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border: 1px solid;
          border-bottom: 0;
          border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
    };
          background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
          color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
      
          &:hover {
            cursor: pointer;
            color: #FFF;
            background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]
    };
            border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]
    };
          }
        }
      
        &.${numberInputClasses.decrementButton} {
          grid-column: 2/3;
          grid-row: 2/3;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border: 1px solid;
          border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
    };
          background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
          color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
        }
      
        &:hover {
          cursor: pointer;
          color: #FFF;
          background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
          border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]
    };
        }
      
        & .arrow {
          transform: translateY(-1px);
        }
      
        & .arrow {
          transform: translateY(-1px);
        }
      `
);

export function DropDown({
  disabled = false,
  json,
  jsonData,
  setJsonData,
  index,
  multiple = false,
}) {
  return (
    <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400 }}>
      {/* below is hidden in client */}
      <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={multiple ? (json?.value ? [...json?.value] : []) : json?.value}
        multiple={multiple}
        label={json.placeholder}
        // below 2 is on client only
        disabled={disabled}
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        onChange={item => {
          console.log(item, "22222")
          const data = jsonData.map((val, newIndex) => {
            if (newIndex === index) {
              return {
                ...val,
                value: item.target.value,
              };
            }
            return val;
          });
          setJsonData(data);
        }}>
        {json.options.map((item, index) => (
          <MenuItem key={index} value={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function DropDownForChoices({ json, jsonData, setJsonData, index }) {
  const { contextChoices } = useContext(AppContext);

  return (
    <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400 }}>
      <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={json.placeholder}
        // below only on client
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        value={json?.value}
        onChange={item => {
          console.log(item, "3333")
          const data =
            jsonData &&
            jsonData?.map((val, newIndex) => {
              console.log(newIndex, index);
              if (newIndex === index) {
                return {
                  ...val,
                  value: item.target.value,
                };
              }
              return val;
            });
          setJsonData(data);
        }}>
        {contextChoices &&
          json.choices &&
          contextChoices[json.choices] &&
          contextChoices[json.choices]
            ?.map(vale => ({ value: vale.id, label: vale.name }))
            .map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}

export function DropDownMultiSelectList({
  disabled = false,
  json,
  jsonData,
  setJsonData,
  index,
  multiple = false,
}) {
  console.log(json?.options, "json?.options")
  const toDeleteJob = indexD => {
    if (jsonData[index] && jsonData[index].value) {
      jsonData[index].value.splice(indexD, 1);
    }
    setJsonData([...jsonData]);
  };

  return (
    <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400 }}>
      {/* <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={''}
        disabled={disabled}
        label={json.placeholder}
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        onChange={item => {
          console.log(item, "44444")
          let findValue = jsonData[index]?.value?.find(
            val => item.target.value == val
          );

          if (!findValue) {
            const data = jsonData.map((val, newIndex) => {
              if (newIndex === index) {
                return {
                  ...val,
                  value: val.value
                    ? [...val.value, item.target.value]
                    : [item.target.value],
                };
              }
              return val;
            });
            setJsonData(data);
          }
        }}>
        {json?.options && json?.options.length && json?.options?.map((item, index) => (
          <MenuItem key={index} value={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {json?.value && json?.value.length && json?.value?.map((tableval, index) => (
        <div className="item-card">
          <div className="d-flex border p-1">
            <div
              className="d-flex align-items-center sticky-col w-100 justify-content-between"
              style={{
                paddingLeft: 3,
              }}>
              <div>{tableval}</div>
              <IconWrapper
                style={{ color: ERR_COLOR, marginRight: '0.5rem' }}
                onClick={() => {
                  toDeleteJob(index);
                }}>
                <RiDeleteBinLine />
              </IconWrapper>
            </div>
          </div>
        </div>
      ))}
    </FormControl>
  );
}

export const setTag = val => {
  switch (val.tag) {
    case 'h1':
      return <h1 style={{ color: '#000' }}>{val.label}</h1>;
    case 'h2':
      return <h2 style={{ color: '#000' }}>{val.label}</h2>;
    case 'h3':
      return <h3 style={{ color: '#000' }}>{val.label}</h3>;
    case 'h4':
      return <h4 style={{ color: '#000' }}>{val.label}</h4>;
    case 'h5':
      return <h5 style={{ color: '#000' }}>{val.label}</h5>;
    case 'h6':
      return <h6 style={{ color: '#000' }}>{val.label}</h6>;
    default:
      return null;
  }
};

export function DropDownTableValue({ disabled = false, setJson, json, item, rowData, multiple = false, minWidth = 400, contextChoices, index, type, label }) {
  const [selected, setSelected] = useState(multiple && !Array.isArray(item.value) ? [] : item.value ?? (rowData[item.column_name] || ""))
  const ddOptions = () => {
    if (!item) return [];
    if (item.manual_options)
      return item.manual_options.map(op => { return { value: op.label, label: op.label } });
    else if (item.type !== ItemTypes.choices && item.type !== ItemTypes.choiceDropdown && item.options)
      return item.options.map(op => { return { value: op.id, label: op.label } });
    else if (contextChoices && item.options && contextChoices[item.options])
      return contextChoices[item.options].map(op => { return { value: op.id, label: op.name } });
    return []
  }

  const handleOnChange = (event) => {
    setSelected(event.target.value)
    let json_data = json.map((j_val) => {
      if (j_val.type === ItemTypes.table) {
        let r_data = j_val?.rows && j_val?.rows.map((r_val, index_r) => {
          if (index_r === index && item.label === r_val.label) {
            r_val[item.column_name] = event.target.value;
          }
          return r_val
        })
        let data = { ...j_val, rows: r_data }
        return data
      } else {
        let r_data = j_val?.cols && j_val?.cols.map((r_val, index_r) => {
          if (index_r === index && item.label === r_val.label && label === j_val.label) {
            r_val.value = event.target.value;
          }
          return r_val
        })
        let data = { ...j_val, cols: r_data }
        return data
      }
    })

    console.log("new json data : ", json_data)
    setJson([...json_data])
  }
  return (
    <FormControl style={{ flex: 1 }} sx={{ minWidth: minWidth }}>
      <Select
        style={{ height: '45px' }}
        id="demo-simple-select"
        value={rowData[item.column_name] ? rowData[item.column_name] : selected}
        disabled={disabled}
        multiple={multiple}
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
        onChange={(event) => {
          console.log(event.target.value, "11111")
          handleOnChange(event)
        }}
      >
        {ddOptions().map((item, i) => {
          return <MenuItem key={i} value={item.value ? item.value : item.label}>
            {item.label}
          </MenuItem>

        })}
      </Select>
    </FormControl>
  )
}