import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Switch, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import DropDown from '../FormDesigns/dropDown'
import DropDownForChoices from '../FormDesigns/dropDownForChoices'
import { setTag } from '../service'
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './formViewer.css'
import Backdrop from '@mui/material/Backdrop';
import MatBox from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from 'react'
import styles from './styles.module.css'
import { useEffect } from 'react'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { LARGE_COL } from 'shared/styles/constants/columns'
import {
    Unstable_NumberInput as BaseNumberInput,
    numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import DropDownTableOptions from 'components/DashboardComponents/FormBuilder/FormDesigns/dropDownTableOptions'
import { HTML_NAMES } from 'components/DashboardComponents/FormBuilder/constants'
import DropDownTableValue from 'components/DashboardComponents/FormBuilder/FormDesigns/dropDownTableValue'
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons'
import { FaPlus } from 'react-icons/fa'
import { DOWNLOAD, INFO_ICON } from 'utils/constants/icons'
import { ERR_COLOR } from 'shared/styles/constants/colors'
import { RiDeleteBinLine } from 'react-icons/ri'
import ReactHtmlParser from 'react-html-parser';
import { useMemo } from 'react'
import { useModal } from 'hooks/ModalHook'
import { GrTableAdd } from 'react-icons/gr'
import SearchModalRichText from './SearchModalRichText'
import { formdata_choice } from 'utils/choiceConstant'
import { AppContext } from 'context'
import { useContext } from 'react'
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles'
import { FiUpload } from 'react-icons/fi'
import { attachImage } from 'utils/api/FormDataApi'
import { pickFile } from 'utils/files'
import moment from 'moment'
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import BodyMapModal from 'components/DashboardComponents/FormBuilder/BodyMapModal'
import DropDownMultiSelectList from '../FormDesigns/dropDownMultiSelectList'
import * as html2pdf from 'html2pdf.js'
import RichTextEditor from 'components/SharedComponents/RichTextEditor'

const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
    &.${numberInputClasses.focused} {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
);

const StyledButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      cursor: pointer;
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
  
    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  `,
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function FormViewer({ jsonData, setJsonData, contextChoices, name, parent = "" ,title = "", handleChangeChoices = () => {}}) {
    console.log('json', jsonData)
    const readOnly = (parent === 'client_care_plans');
    const [openSignature, setOpenSignature] = useState(false);
    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const [signatureItem, setSignatureItem] = useState({});
    const [popUpData, setPopUpData] = useState({});
    const [loadingExport, setLoadingExport] = useState(false)
    const {handleGetChoices} = useContext(AppContext);
    const {
        setValue: setModalBodyMap,
        value: modalBodyMap,
        fullBind: fullBindBodyMap,
      } = useModal(false);
    const {
        setValue: setSearchValueRich,
        value: searchValueRich,
        fullBind: fullBindSearchValueRich,
      } = useModal(false);

      useEffect(()=>{
        handleGetChoices(formdata_choice)
      },[])

    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL('image/png'));
        setOpenSignature(false)
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    }

    const renderCols = (counts)=>{
        switch (counts) {
            case "1":
              return "12";
            case "2":
              return "6";
            case "3":
              return "4";
            case "4":
              return "3";
            case "5":
              return "2";
            case "6":
              return "2";
            default:
              return "3";
          }
    }


    useEffect(() => {
        // const data = jsonData.map((val, newIndex) => {
        //     if (newIndex === signatureItem.index) {
        //         return (
        //             {
        //                 ...val,
        //                 value: signature
        //             }
        //         )
        //     }
        //     return val
        // })
        // setJsonData(data)

        let json_data = jsonData.map((j_val) => {
            if (j_val.name == "Table") {
                let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {

                    if (index_r === signatureItem.index && r_val.label === 'Signature'
                        && r_val?.id && signatureItem?.item?.id && signatureItem?.label === j_val?.label) {
                        r_val.value = signature;
                    }
                    return r_val
                })
                let data = { ...j_val, rows: r_data }
                return data
            } else {
                let r_data = j_val.cols && j_val.cols.map((r_val, index_r) => {
                    if (index_r === signatureItem.index && r_val.label === 'Signature'
                        && r_val?.id && signatureItem?.item?.id && signatureItem?.label === j_val?.label) {
                        r_val.value = signature;
                    }
                    return r_val
                })
                let data = { ...j_val, cols: r_data }
                return data
            }
            // return j_val
        })

        setJsonData([...json_data])

        const choices = [...json_data].filter(item=>item.type===HTML_NAMES.choices).map(item=>item.choices);
        handleChangeChoices(choices)
    }, [signature])

    const config = useMemo(
        () => ({
            readonly: readOnly,
        }),
        []
    )

    const handlePickFile = async (evt, index) => {

        const result = await pickFile(evt);
        console.log(result, 555333)
       
            const photoUrl = await attachImage({file : result.file})
            if(photoUrl.message.file){
                // setUploadedFile(photoUrl.message.file);
                const data = jsonData.map((j_val, newIndex) => {
                    if (newIndex === index) {
                        return (
                            {
                                ...j_val,
                                value: photoUrl.message.file,
                                placeholder: result.file.name
                            }
                        )
                    }
                    return j_val
                })
                setJsonData(data)
            }
      };

    const addNewRows = (index) => {
        let newData = jsonData.map((j_val, i) => {
            if (i == index) {
                j_val.rows = [...j_val.rows, {}];
            }
            return j_val
        })
        setJsonData(newData);
    }

    const getBodyData=(body_data)=>{
        const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck','knees','left-soleus', 'right-soleus']
        const muscleFromBody = body_data?.every(item => listOfBodyParts.includes(item));
        const data = [
            { name: '', muscles: (muscleFromBody && body_data) ? [...body_data] : [] },
        ];
    return data
    }

    const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
        return (
            <BaseNumberInput
                slots={{
                    root: StyledInputRoot,
                    input: StyledInputElement,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                }}
                slotProps={{
                    incrementButton: {
                        children: '▴',
                    },
                    decrementButton: {
                        children: '▾',
                    },
                }}
                {...props}
                ref={ref}
                disabled={readOnly}
            />
        );
    });

    const handleDownlaodPdf = () => {
        const input = document.querySelector('#dustbin-pdf');
        const current_date = moment().format('DD-MM-YYY')
        var opt = {
            filename: `form_${current_date}`,
            pagebreak: { mode: 'avoid-all' },
            image: { quality: 1 },
            html2canvas: {
                dpi: 192,
                scale: 1,                
                letterRendering: true,
                useCORS: true
              },
          };
        setLoadingExport(true)
        setTimeout(() => {
            html2pdf().set(opt).from(input).save().then(() => {
                setLoadingExport(false)
            })
        },1)
    };

    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
      }

    const renderTableRow = (val, item, table_index, type, label) => {
        console.log('section data', label,val, item)
        if(!val) return null;
        switch (val.type) {
            case "Text":
                return (
                    <div className='w-100'>
                        <label className='w-100' for="fname" style={{}} >{val.value}</label>
                    </div>
                )
            case HTML_NAMES.textInput:
                return (
                    <div className='w-100'>
                        <input
                            type="text"
                            id="fname"
                            name="fname"
                            className='w-100'
                            disabled={readOnly}
                            value={(type == "table") ? val[val.column_name] : val.value}
                            placeholder={val.placeholder}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if(j_val.name == "Table") {
                                        let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {
                                            console.log(r_val,val,"ttttt")
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val[val.column_name] = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data = j_val.cols && j_val.cols.map((r_val, index_r) => {
                                            console.log(r_val,val,"ttttt2")
                                            if (index_r === table_index && val.label === r_val.label && label ===j_val.label) {
                                                r_val.value = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    // return j_val
                                })

                                setJsonData([...json_data])
                            }}
                        />
                    </div>
                )
            case HTML_NAMES.dropdown:
                return (
                    <div className='w-100'>
                        <DropDownTableValue disabled={readOnly} type={type} className='w-100' setJson={setJsonData} contextChoices={contextChoices} json={jsonData} item={val} rowData={item} minWidth={'100%'} index={table_index} label={label} />
                    </div>
                )
            case 'Choice Dropdown':
                return (
                    <div className='w-100'>
                        <DropDownTableValue disabled={readOnly} type={type} className='w-100' setJson={setJsonData} contextChoices={contextChoices} json={jsonData} item={val} rowData={item} minWidth={'100%'} index={table_index} />
                    </div>
                )
            case HTML_NAMES.headingText:
                return (
                    <div className='w-100'>
                        {setTag({ tag: val.html, label: val.value })}
                    </div>
                )
            case HTML_NAMES.numberInput:
                return (
                    <div className='w-100'>
                        <CustomNumberInput
                            className='w-100'
                            aria-label="Demo number input"
                            placeholder={val.placeholder}
                            value={(type == "table") ? val[val.column_name] : val.value}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if (j_val.name == "Table") {
                                        let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {
                                           
                                            if (index_r === table_index && val.label === r_val.label) {
                                                console.log(r_val,item,val,"ttttt")
                                                r_val[val.column_name] = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data = j_val.cols && j_val.cols.map((r_val, index_r) => {
                                           
                                            if (index_r === table_index && val.label === r_val.label) {
                                                console.log(r_val,val,item,"ttttt2")
                                                r_val.value = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    // return j_val
                                })

                                setJsonData([...json_data])
                            }}
                        />
                    </div>
                )
            case HTML_NAMES.decimalInput:
                return (
                    <div className='w-100'>
                        <input
                            type="number"
                            className='w-100'
                            id="myInput"
                            name="inputTable"
                            disabled={readOnly}
                            placeholder={val.placeholder}
                            value={(type == "table") ? val[val.column_name] : val.value}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if (j_val.name == "Table") {
                                        let r_data =j_val?.rows && j_val?.rows.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val[val.column_name] = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data =j_val?.cols && j_val?.cols.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val.value = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    return j_val
                                })

                                setJsonData([...json_data])
                            }}
                        />
                    </div>
                )
            case HTML_NAMES.multidropdown:
                return (
                    <div className='w-100'>
                        {/* <DropDownTableOptions className='w-100' contextChoices={contextChoices} json={val} minWidth={'100%'} multiple={true} /> */}
                        <DropDownTableValue disabled={readOnly} className='w-100' setJson={setJsonData} contextChoices={contextChoices} json={jsonData} item={val} rowData={item} minWidth={'100%'} index={table_index} multiple={true} type={type} />
                    </div>
                )
            case HTML_NAMES.switch:
                return (
                    <div className='w-100'>
                        <FormControlLabel
                            value="start"
                            disabled={readOnly}
                            control={
                                <Switch disabled={readOnly} color="primary" />}
                            label={<div>
                                <label for="fname" style={{ marginRight: 10, fontWeight: "bold" }} >{val.placeholder}:</label>
                                {val.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{val.description}</p>}
                            </div>}
                            style={{ width: '100%', fontWeight: "bold", justifyContent: "flex-start" }}
                            labelPlacement="top"
                        />
                    </div>
                )
            case HTML_NAMES.hyperLink:
                return (
                    <div className='w-100'>
                        {<p style={{ width: "100%", marginBottom: 8, fontSize: 12 }}>
                            <a href={`${val.description}`}>{val.placeholder}</a>
                        </p>}
                    </div>
                )
            case HTML_NAMES.radioGroup:
                return (
                    <div className='w-100'>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            className='w-100'
                        >
                            {
                                val.options.map((val, index) => {
                                    return (
                                        <FormControlLabel disabled={readOnly} key={index} value={val.value} control={<Radio />} label={val.value} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </div>
                )
            case HTML_NAMES.checkbox:
                return (
                    <div className='w-100'>
                        <FormGroup row className='w-100'>
                            {
                                val.options.map((val, index) => {
                                    return (
                                        <FormControlLabel disabled={readOnly} control={<Checkbox disabled={readOnly} />} label={val.value} />
                                    )
                                })
                            }
                        </FormGroup>
                    </div>
                )
            case HTML_NAMES.textArea:
                return (
                    <div className='w-100'>
                        <TextField
                            id="outlined-multiline-static"
                            className='w-100'
                            label={val.label}
                            disabled={readOnly}
                            multiline
                            rows={val.html ? Number(val.html) : 2}
                            value={(type == "table") ? val[val.column_name] : val?.value}
                            placeholder={val.placeholder}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if (j_val.name == "Table") {
                                        let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val[val.column_name] = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data = j_val?.cols && j_val?.cols.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val.value = event.target.value;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    return j_val
                                })

                                setJsonData([...json_data])
                            }}
                        // style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        />
                    </div>
                )
            case HTML_NAMES.datePicker:
                let dateColumnValue = (type == "table") ? val[val.column_name] : val?.value;
                let dateValue = dateColumnValue ? new Date(dateColumnValue) : new Date();
               const isValidDate =  isDateValid(dateValue)

                return (
                    <div className='formdata w-100'>
                    <ReactDatePicker
                        showIcon
                        disabled={readOnly}
                        style={{width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        className='formDataDatePicker'
                        icon="fa fa-calendar"
                        label="Basic date picker" 
                        selected={isValidDate ?  dateValue : new Date()}
                        // selected={(item.value || val.column_name) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
                        onChange={(event) => {
                            let json_data = jsonData.map((j_val) => {
                                if (j_val.name == "Table") {
                                    let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {
                                        if (index_r === table_index && val.label === r_val.label) {
                                            r_val[val.column_name] = event;
                                        }
                                        return r_val
                                    })
                                    let data = { ...j_val, rows: r_data }
                                    return data
                                }else{
                                    let r_data =j_val.cols && j_val.cols.map((r_val, index_r) => {
                                        if (index_r === table_index && val.label === r_val.label) {
                                            r_val.value = event;
                                        }
                                        return r_val
                                    })
                                    let data = { ...j_val, cols: r_data }
                                    return data
                                }
                                // return j_val
                            })

                            setJsonData([...json_data])
                        }
                    }
                        />
                         </div>
                )
            case HTML_NAMES.timePicker:
                let timeVolumnValue = (type === "table") ? val[val.column_name] : val?.value;
                let timeValue = timeVolumnValue ? new Date(timeVolumnValue) : new Date();
                const isValidTime =  isDateValid(timeValue)
                return (
                          <div className='formdata w-100'>
                        <ReactDatePicker
                            showIcon
                            disabled={readOnly}
                            style={{width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat={"HH:mm"}
                                dateFormat={"HH:mm"}
                                className='formDataDatePicker'
                            icon="fa fa-calendar"
                            label="Basic date picker"
                            selected={isValidTime ? timeValue : new Date()}
                            // selected={(item.value || val.column_name) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if (j_val.name == "Table") {
                                        let r_data = j_val?.rows && j_val?.rows?.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val[val.column_name] = event;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data = j_val?.cols && j_val?.cols.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val.value = event;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    // return j_val
                                })

                                setJsonData([...json_data])
                            }
                            }
                             />
                    </div>
                )
            case HTML_NAMES.dateTime:

            let dateTimeColumnValue = (type === "table") ? val[val.column_name] : val?.value;
            let dateTimeValue = dateTimeColumnValue ? new Date(dateTimeColumnValue) : new Date();
            const isValidDateTime =  isDateValid(dateTimeValue)
                return (
                    <div className='w-100'>
                        <ReactDatePicker
                            className='w-100'
                            disabled={readOnly}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            showTimeSelect
                            dateFormat="Pp"
                            // value={(type == "table") ? item[val.column_name] : item.value}
                            selected={isValidDateTime ? dateTimeValue : new Date()}
                            // selected={(item[val.column_name] || item.value) ? new Date((type == "table") ? (item[val.column_name] ) : item?.value) : new Date()}
                            onChange={(event) => {
                                let json_data = jsonData.map((j_val) => {
                                    if (j_val.name == "Table") {
                                        let r_data = j_val.rows && j_val.rows.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val[val.column_name] = event;
                                            }
                                            return r_val
                                        })
                                        let data = { ...j_val, rows: r_data }
                                        return data
                                    }else{
                                        let r_data = j_val.cols && j_val.cols.map((r_val, index_r) => {
                                            if (index_r === table_index && val.label === r_val.label) {
                                                r_val.value = event;
                                            }
                                            return r_val
                                        }) 
                                        let data = { ...j_val, cols: r_data }
                                        return data
                                    }
                                    // return j_val
                                })

                                setJsonData([...json_data])
                            }
                            }
                        />
                    </div>
                )
            case HTML_NAMES.signature:
                console.log(item,"ssssssss")
                return (
                    <div className='w-100'>
                        <Button disabled={readOnly} 
                        onClick={() => {setOpenSignature(true);
                            setSignatureItem({ item: val, index: table_index, label})}} 
                        variant={val.html || "outlined"}>{val.label}</Button>
                        {val.value
                        ? <img alt='sign' className={styles.sigImage}
                            src={val.value} />
                        : null}
                    </div>
                )
             default : 
              break;
        }
    }


    const renderDesign = (item, index) => {
        console.log(item, "ITEM")
        console.log(item.name, "ITEM name")

        switch (item.type) {
            case "Heading text":
                return setTag({ tag: item.html, label: item.value })
            case "Text Input":
                return <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ flex: 1, marginBottom: '8px' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction && (<Tooltip title={item.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>
                            )}
                        </div>
                        {item.description && <p style={{ fontSize: 12, padding: 0, margin: 0 }}>{item.description}</p>}
                    </div>
                    <input
                        disabled={readOnly}
                        type="text"
                        id="text1"
                        name="text1"
                        placeholder={item.placeholder}
                        value={item.value}
                        style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8", flex: 1 }}
                        onChange={(text) => {
                            const data = jsonData.map((val, newIndex) => {
                                if (newIndex === index) {
                                    return (
                                        {
                                            ...val,
                                            value: text.target.value
                                        }
                                    )
                                }
                                return val
                            })
                            setJsonData(data)
                        }}
                    />
                </div>
            case "Number Input":
                return (<div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: item.description ? "flex-start" : "center" }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label for="labelhead" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item?.instruction && (
                        <Tooltip title={item.instruction}>
                            <IconButton size={'small'}>
                                {INFO_ICON}
                            </IconButton>
                        </Tooltip>)}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    <CustomNumberInput
                        className='w-100'
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={(event) => {
                            const data = jsonData.map((j_val, newIndex) => {
                                if (newIndex === index) {
                                    return (
                                        {
                                            ...j_val,
                                            value: event.target.value
                                        }
                                    )
                                }
                                return j_val
                            })
                            setJsonData(data)
                        }}
                    />
                </div>)

            case "Decimal Input":
                return (<div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: item.description ? "flex-start" : "center" }}>
                    <div className='w-100 d-flex justify-content-between'>
                        <label style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                        {item?.instruction && (
                        <Tooltip title={item.instruction}>
                            <IconButton size={'small'}>
                                {INFO_ICON}
                            </IconButton>
                        </Tooltip>)}
                    </div>
                    {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    <input
                        disabled={readOnly}
                        type="number"
                        className='w-100'
                        // onkeydown="if(this.key==='.'){this.preventDefault();}" 
                        id="inputdecimal"
                        name="inputDecimal"
                        placeholder={item.placeholder}
                        value={item.value}
                        style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        onChange={(event) => {
                            const data = jsonData.map((j_val, newIndex) => {
                                if (newIndex === index) {
                                    return (
                                        {
                                            ...j_val,
                                            value: event.target.value
                                        }
                                    )
                                }
                                return j_val
                            })
                            setJsonData(data)
                        }}
                    />
                </div>)
            case "Multi Dropdown":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction && (
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        <DropDownMultiSelectList disabled={readOnly} minWidth={'100%'} jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} multiple={true} />
                    </div>)
            case "Hyper Link":
                return (
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: item.description ? "flex-start" : "center" }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>
                                )}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        {<p style={{ marginBottom: 8, fontSize: 12 }}>
                            <a href={`${item.value}`}>{item.placeholder}</a>
                        </p>}
                    </div>)

            case "Dropdown":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="dropdown" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction && (
                            <Tooltip title={item?.instruction}>
                                <IconButton size={'small'}>
                                    {INFO_ICON}
                                </IconButton>
                            </Tooltip>)}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        <DropDown disabled={readOnly} minWidth={'100%'} jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} />
                    </div>)
            case 'Table':
                return (
                    <div className='w-100'>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && ( <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div style={{ border: '2px solid #fafafc' }}>
                            <Table
                                headerColumns={item.cols ?
                                    item.cols.map((val) => ({
                                        label: val.column_name,
                                        name: '',
                                        status: 0,
                                        className: 'grow-mobile',
                                        max_width: LARGE_COL,
                                    }))
                                    :
                                    []
                                }
                                // callBackQuerySort={setQuerySort}
                                headerPadding={false}
                                handleAllCheck={() => { }}
                                noEditOption
                                noNeed={true}
                                handleAllSelectChange={() => { }}
                                noFade={true}>
                                <div>
                                    {item.rows &&
                                        item.rows.map((table_d, table_index) => {
                                            return (
                                                <div>
                                                    <Row bgColor>
                                                        <ColsGrouper className="d-flex">
                                                            {item.cols && item.cols.map((val, i) => {
                                                                return (
                                                                    <>
                                                                        <Col
                                                                            max_width={LARGE_COL}
                                                                            Center>
                                                                            {renderTableRow(val, table_d, table_index, 'table')}
                                                                        </Col>
                                                                    </>
                                                                )
                                                            })
                                                            }
                                                            <Col Center Shrink NoFlexGrow>
                                                                <IconWrapper
                                                                    onClick={() => {
                                                                        // toDeleteClient(client.id);
                                                                    }}>
                                                                    <RiDeleteBinLine />
                                                                </IconWrapper>
                                                            </Col>
                                                        </ColsGrouper>
                                                    </Row>

                                                    <div className="mb-1" />
                                                </div>
                                            )
                                        })}
                                </div>
                            </Table>
                            {!readOnly && (
                                <PrimaryButtonForm minWidth="1rem" onClick={() => addNewRows(index)}>
                                <FaPlus />
                            </PrimaryButtonForm>)}
                        </div>
                    </div>
                )

            case "Section":
                return (
                    <div style={{ border: '2px solid #fafafc', width:'100%' }}>
                            <div className='text-left ml-2'>
                        <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold", fontSize:'20px' }} >{item.label}</label>
                        </div>
                            <div className="container">
                            <div className="row pt-2">
                                {item.cols &&
                                    item.cols.map((sec_d, index) => {
                                        if(!sec_d) return null;
                                        return (
                                            <div className={`col-${renderCols(item.sectionColsCount)}`}>
                                                    <div style={{ textAlign: 'left', width: '100%' }}>
                                                        <div className='w-100 d-flex justify-content-between'>
                                                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{sec_d.label}<span style={{ color: ERR_COLOR }}>{sec_d.required && '*'}</span></label>
                                                            {sec_d?.instruction && ( <Tooltip title={sec_d.instruction}>
                                                                <IconButton size={'small'}>
                                                                    {INFO_ICON}
                                                                </IconButton>
                                                            </Tooltip>)}
                                                        </div>
                                                        {sec_d.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{sec_d.description}</p>}
                                                    </div>
                                                <div className='w-100 my-1'>
                                                    {renderTableRow(sec_d, sec_d, index,"",item.label)}
                                                </div>
                                                {/* {sec_d.sectionRowCount} */}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                )

            case "Toggle":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ flex: 1 }}>
                            <div className='w-100 d-flex'>
                                <label for="toggle" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>
                                )}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12, margin: 0, padding: 0 }}>{item.description}</p>}
                        </div>
                        <FormControlLabel
                            value="start"
                            disabled={readOnly}
                            control={
                                <Switch
                                    color="primary"
                                    checked={item?.value}
                                    disabled={readOnly}
                                    onChange={(item) => {
                                        const data = jsonData.map((val, newIndex) => {
                                            if (newIndex === index) {
                                                return (
                                                    {
                                                        ...val,
                                                        value: item.target.checked
                                                    }
                                                )
                                            }
                                            return val
                                        })
                                        setJsonData(data)

                                    }}
                                />}
                            label={""}
                            style={{ fontWeight: "bold", justifyContent: "left", marginLeft: '0px', marginTop: '5px' }}
                            labelPlacement="start"
                        />
                    </div>)
            case "Button":
                return (<div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Button disabled={readOnly} variant={item.html}>{item.label}</Button>
                </div>)
            case "Radio Group":
                return (
                    <FormControl style={{ alignSelf: "flex-start", display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="radio" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && ( <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={item?.value}
                            onChange={nextValue => {
                                console.log('radio', nextValue.target.value)
                                const data = jsonData.map((val, newIndex) => {
                                    if (newIndex === index) {
                                        return (
                                            {
                                                ...val,
                                                value: nextValue.target.value
                                            }
                                        )
                                    }
                                    return val
                                })
                                setJsonData(data)
                            }}
                        >
                            {
                                item.options.map((val, index) => {
                                    return (
                                        <FormControlLabel disabled={readOnly} key={index} value={val.value} control={<Radio />} label={val.value} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl >
                )
            case "Button Group":
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                        {
                            item.options.map((val, index) => {
                                return (
                                    <Button disabled={readOnly} key={index} variant={val.type} style={{ marginRight: 5 }}>{val.value}</Button>
                                )
                            })
                        }
                    </div>
                )
            case "Checkbox":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="cehckbox" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (<Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <FormGroup row >
                            {
                                item.options.map((val1, index2) => {
                                    // console.log(val, "Value")
                                    return (
                                        <FormControlLabel control={
                                            <Checkbox
                                                disabled={readOnly}
                                                checked={(item.value.includes(val1.value)) ? true : false}
                                                key={index2}
                                                onChange={(text) => {
                                                    console.log('check', text, text.target.checked)
                                                    const data = jsonData.map((val, newIndex) => {
                                                        if (newIndex === index) {
                                                            if (val.value.includes(val1.value)) {
                                                                const array = val.value;
                                                                const index_arr = array.indexOf(val1.value);
                                                                array.splice(index_arr, 1);
                                                                let final_val = array;
                                                                return (
                                                                    {
                                                                        ...val,
                                                                        value: final_val
                                                                    }
                                                                )
                                                            } else {
                                                                return (
                                                                    {
                                                                        ...val,
                                                                        value: val.value ? [...val.value, val1.value] : [val1.value]
                                                                    }
                                                                )
                                                            }
                                                        }
                                                        return val
                                                    })
                                                    setJsonData(data)
                                                }}
                                            />
                                        } label={val1.value} />
                                    )
                                })
                            }
                        </FormGroup>
                    </div>
                )
            case "Text Area":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-start", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="textarea" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            label={item.label}
                            sx={{
                                '& legend': { display: 'none' },
                                '& .MuiInputLabel-shrink': { opacity: 0, transition: "all 0.2s ease-in" }
                            }}
                            multiline
                            rows={Number(item.html)}
                            style={{ width: "100%" }}
                            disabled={readOnly}
                            placeholder={item.placeholder}
                            value={item?.value}
                            onChange={(text) => {
                                const data = jsonData.map((val, newIndex) => {
                                    if (newIndex === index) {
                                        return ({
                                            ...val,
                                            value: text.target.value
                                        })
                                    }
                                    return val
                                })
                                setJsonData(data)
                            }}
                        />
                    </div>
                )
            case "Choices":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="choices" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                     <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <DropDownForChoices disabled={readOnly} jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} />
                    </div>
                )
            case "Date":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="date" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && ( <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div className='formdata w-100'>
                            <ReactDatePicker
                                disabled={readOnly}
                                style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                className='formDataDatePicker'
                                selected={isDateValid(item?.value)? new Date(item.value) : new Date()}
                                onChange={(event) => {
                                    const data = jsonData.map((val, newIndex) => {
                                        if (newIndex === index) {
                                            return ({
                                                ...val,
                                                value: event
                                            })
                                        }
                                        return val
                                    })
                                    setJsonData(data)
                                }
                                }
                                label="Basic date picker" />
                        </div>
                    </div>
                )
            case "Time":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="time" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div className='formdata w-100'>
                            <ReactDatePicker
                                disabled={readOnly}
                                style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat={"HH:mm"}
                                className='formDataDatePicker'
                                dateFormat="HH:mm"
                                label="Basic date picker"
                                selected={isDateValid(item?.value) ? new Date(item.value) : new Date()}
                                onChange={(event) => {
                                    const data = jsonData.map((val, newIndex) => {
                                        if (newIndex === index) {
                                            return ({
                                                ...val,
                                                value: event
                                            })
                                        }
                                        return val
                                    })
                                    setJsonData(data)
                                }
                                }
                            />
                        </div>
                    </div>
                )

            case "Date/Time":
                return (
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="date_time" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction && (
                                     <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>)}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div className='formdata w-100'>
                            <ReactDatePicker
                                style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                showTimeSelect
                                dateFormat="Pp"
                                className='formDataDatePicker'
                                selected={isDateValid(item?.value) ? new Date(item.value) : new Date()}
                                onChange={(event) => {
                                    const data = jsonData.map((val, newIndex) => {
                                        if (newIndex === index) {
                                            return ({
                                                ...val,
                                                value: event
                                            })
                                        }
                                        return val
                                    })
                                    setJsonData(data)
                                }
                                }
                            />
                        </div>
                    </div>
                )
            // case "Date/Time":
            //     return (
            //         <div className='date_picker_component' style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            //             <div style={{ flex: 1 }}>

            //                 <p style={{ marginRight: 10, fontWeight: "bold" }} >{item.placeholder} :</p>
            //             </div>
            //             <ReactDatePicker
            //                 selected={new Date()}
            //                 style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
            //                 showTimeSelect
            //                 dateFormat="Pp"
            //             />
            //         </div>
            //     )
            case "Signature":
                console.log(item.value,"ddddd")
                return (<div>
                   {!readOnly && <Button onClick={() => {
                        setOpenSignature(true);
                        setSignatureItem({ item: item, index: index ,label : item.label })
                    }} variant={item.html || "outlined"}>{item.label}</Button>}
                    {/* <div>
                   <IconWrapper>
                          <RiDeleteBinLine />
                        </IconWrapper> */}
                    {item.value
                        ? <img className={styles.sigImage}
                            src={item.value} />
                        : null}
                    {/* </div> */}
                </div>)
             case "Rich Text Input":
                return <div style={{ width: '100%' }}>
                    <div style={{ textAlign: 'left' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}

                        </div>
                        <div className='w-100 d-flex justify-content-between'>
                            <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>
                            <Tooltip title={'Search'}>
                                <IconButton size={'small'} onClick={()=>{
                                    setSearchValueRich(true)
                                    setPopUpData(item)
                                    }}>
                                    <GrTableAdd />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <RichTextEditor
                        value={item.value || ''}
                        tabIndex={''} // tabIndex of textarea
                        onChange={(event) => {
                            const data = jsonData.map((val, newIndex) => {
                            if (newIndex === index) {
                                return ({
                                    ...val,
                                    value: event
                                })
                            }
                            return val
                        })
                        setJsonData(data)
                    }}
                    config={config}
                    />
                </div>
            case "Rich Text Heading":
                return <div style={{ width: '100%' }}>
                    {ReactHtmlParser(item.label)}
                </div>
             case "Photo Attach":
                return <div style={{}}>
                    <div style={{ textAlign: 'left' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                    </div>
                    <div>
                        <img style={{width:'100%', height:'100%'}} src={item.value} />
                    </div>
                </div>
                 case "File Upload":
                    return <div style={{}}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        </div>
                        <div>
                            <UploadPhotoContainer className="d-flex align-items-center mt-2">
                                <UploadInput
                                    onInput={e => {
                                        handlePickFile(e, index);
                                    }}
                                    onClick={event => {
                                        event.target.value = null;
                                    }}
                                    type="file"
                                    // accept={'.png,.jpg,.jpeg'}
                                    id="upload-file"
                                    name="upload-file"
                                    placeholder={'Upload File'}
                                />
                                {!readOnly &&
                                    <>
                                        <FiUpload />
                                        <label htmlFor="upload-file">Upload File</label>
                                    </>
                                }

                            </UploadPhotoContainer> 
                           {item.placeholder &&
                            <div className="copy-list mt-1 download- w-100">
                                <a className='copy-item ' style={{justifyContent:'left'}} target='_blank' href={item.value}>
                                    <span className='mr-2'>{DOWNLOAD}</span>{item.placeholder}
                                </a>
                            </div>}
                        </div>
                    </div>
                     case "File Download":
                        return  <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                        {item.placeholder &&
                            <div className="copy-list mt-1 download- w-100">
                                <a className='copy-item ' style={{justifyContent:'left'}} target='_blank' href={item.value}>
                                    <span className='mr-1'>{DOWNLOAD}</span>{item.placeholder}
                                </a>
                            </div>}
                    </div>
                      case "BodyMap Display":
                        return <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', width:'100%' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                            <input type="text" id="fname" name="fname" value={item?.title?.join(",")} placeholder={item.placeholder} style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
    
                            <div className="d-flex justify-content-center w-100">
                                {((item.view == 'front') || (item.view == 'both')) && <Model
                                    data={getBodyData(item.title)}
                                    style={{ width: '16rem', padding: '1rem' }}
                                    // onClick={handleClick}
                                    type={'anterior'}
                                />}
                                {((item.view == 'back') || (item.view == 'both')) && <Model
                                    data={getBodyData(item.title)}
                                    style={{ width: '16rem', padding: '1rem' }}
                                    // onClick={handleClick}
                                    type={'posterior'}
                                />}
    
                            </div>
                        </div>
                    case "BodyMap Input":
                        return <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item.label}<span style={{ color: ERR_COLOR }}>{item.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item.description}</p>}
                            <PrimaryButtonForm minWidth="6rem" onClick={() => setModalBodyMap(true)}>
                                <FaPlus />
                                Create body map
                            </PrimaryButtonForm>
                        </div>


                     default: break
                   }
                }

            
    return (
        <>
        <div className='w-100' style={{ textAlign: 'end' }}>
        <PrimaryButtonForm className='mr-2' minWidth="6rem" disabled={loadingExport} onClick={() => handleDownlaodPdf()}>
            <span className={'mr-2'}>Export PDF</span>
                {loadingExport && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
        </PrimaryButtonForm>
    </div>
        <div data-testid="dustbin"  id='dustbin-pdf'>
            {loadingExport && <h4 className='w-100 mt-2 d-flex justify-content-center'>{title}</h4>}
            {jsonData?.length > 0 ? (
                jsonData.map((item, index) => {
                    return (
                        <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                            {renderDesign(item, index)}
                        </div>
                    )
                })
            )
                :
                <p style={{ color: "grey" }}>Dropzone</p>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openSignature}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openSignature}>

                    <MatBox sx={{ ...style, maxHeight: "80vh", overflow: "scroll" }}>
                        <div style={{ marginBottom: 20 }}>
                            <h6>Signature</h6>
                        </div>
                        <div>
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                ref={sigRef}
                            //  onEnd={handleSignatureEnd}
                            />
                            <button onClick={clearSignature}>Clear</button>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" style={{ marginRight: 10 }} onClick={() => {
                                setOpenSignature(false)
                            }}>Close</Button>
                            <Button variant="contained" onClick={() => {
                                handleSignatureEnd()
                            }}>Save</Button>
                        </div>
                    </MatBox>
                </Fade>
            </Modal>

{!modalBodyMap ? null : (
          <BodyMapModal
            fullBind={fullBindBodyMap}
            setModalBodyMap={setModalBodyMap}
          />
        )}
            {
            searchValueRich &&
            <SearchModalRichText
              fullBind={fullBindSearchValueRich}
              jsonData={jsonData}
            setJsonData={setJsonData}
            popUpData={popUpData}
            setSearchValueRich={setSearchValueRich}
            name={name}
            />
          }
        </div>
        </>
    )
}
