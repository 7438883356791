import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from 'context';
import { getChoices } from 'utils/api/CoreApi';
import { useHistory } from 'react-router-dom';
import { FLOPPY } from 'utils/constants/icons';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import Table from 'components/SharedComponents/Table';
import { RiDeleteBinLine } from 'react-icons/ri';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { deleteCompany, getCompany } from 'utils/api/SettingsApi';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { SAVE } from 'utils/constants/messages';
import { useForm } from 'hooks/FormHook';
import _ from 'lodash';
import { MdEdit } from 'react-icons/md';
import CompanyPopup from './CompanyPopup';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { Toast } from 'primereact/toast';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { company_setting_choices } from 'utils/choiceConstant';

const Company = ({
  companies,
  handleSubmit,
  location,
  handleChangeChoices
}) => {

  const { values, setValues } = useForm({
    ...companies,
    ordering: 'name',
    limitperpage: PAGE_SIZE_RENDER,
    company_pk: companies.id
  });
  const { contextChoices, setContextChoices } = useContext(AppContext);

  const [company, setCompanies] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(true)
  const toast = useRef();
  const mountedRef = useRef(true);
  const history = useHistory()
  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const headerColumns = [
    {
      label: 'Company  Name',
      name: 'name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Address',
      name: '',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'City',
      name: '',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Zip Code',
      name: '',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
  ];


  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    values.company_pk = companies.id;
    setValues({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);



  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };
  const loadCompany = () => {
    const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);

    getCompany({ ...values, ...pageToSearch }, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;

        setCompanies(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
        setLoader(false)
      }
    );
  };

  useEffect(() => {
    if (values.company_pk) {   
      loadCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
   values.company_pk,
    location.search,
    // values.search,
    values.ordering,
    values.limitperpage,
  ]);


  const handleModal = () => {
    setModalValue(!modalValue);
  };
  const handleAddCompany = () => {
    setIdSelected('');
    handleModal();
  };

  const createAddressFormat = address => {
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const country = address.country ? `${address.country}, ` : '';
    const address_state = address.state ? `${address.state} ` : '';

    return `${line_1}${country}${address_state}`;
  };
  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const toDeleteBranch = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteBranch = async () => {
    let response = await deleteCompany(idSelected);
    if (response.status == true) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Deleted Successfully',
      });
      loadCompany()
      setModalDeleteValue(false);
      handleChangeChoices(company_setting_choices)
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
      getChoices('company').then(res => {
        setContextChoices({ ...contextChoices, ...res });
      });
      loadCompany()
      // setIdSelected(response.id);
      setIsChecked(true);

      setModalValue(false);


      setIdSelected('');
      setIsChecked(true);
    }
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  return (
    <div>
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
          <FloatingMobileButton onClick={handleAddCompany}>
            <FaPlus />
            <label> NEW COMPANY</label>
          </FloatingMobileButton>
        </SearchAddContainer>
      </Header>
      <ComponentDynamic loading={loader}>
        {company?.length ? (
          <Table headerColumns={headerColumns} callBackQuerySort={setQuerySort}>
            <Toast ref={toast} position="top-right" />

            <div>
              {company.map((item, index) => {
                return (
                  <div key={index}>
                    <Row bgColor>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => {
                          handleRowClick(item.id);
                        }}>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="sm-hidden"
                          overlap={true}>
                          <label>{item.name}</label>
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="align-self-center mb-0"
                          overlap={true}>
                          {createAddressFormat(item)}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="sm-hidden"
                          overlap={true}>
                          <label>{item.city}</label>
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="md-hidden"
                          overlap={true}>
                          <label>{item.zip_code}</label>
                        </Col>
                        {/* {(filterAdmin == true ||
                      filterSuperAdmin == true ||
                      (userStaff == true && updateBranchSettings)) && ( */}
                        <Col className="sm-hidden" Center Icon Purple>
                          <IconWrapper
                            onClick={() => {
                              handleRowClick(item.id);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                        </Col>
                      </ColsGrouper>
                      {/* {(filterAdmin == true || */}
                      {/* // filterSuperAdmin == true ||
                    // (userStaff == true && deletePermissionBranchSettings)) && ( */}
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteBranch(item.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={company.length}
                />
              </div>
            </div>
          </Table>) : (
          <div className='w-100 d-flex justify-content-center mt-3'>
            <h4 style={{ fontSize: "1rem" }}>No data found</h4>
          </div>
        )}
      </ComponentDynamic>
      {!modalValue ? null : (
        <CompanyPopup
          loadCompany={loadCompany}
          setIsChecked={setIsChecked}
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          company_pk={values.company_pk}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          handleChangeChoices={handleChangeChoices}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this company? \n deleting the company will remove all records from database.'
        }
        onOk={handleDeleteBranch}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default Company;
