import {
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Full Name',
    name: 'full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Reference',
    name: 'reference',
    status: 0,
    className: 'sm-hidden md-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Status',
    name: 'employee_status__name',
    status: 0,
    className: 'sm-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Address',
    name: 'address__name',
    status: 0,
    className: '',
    max_width: LARGE_COL,
  },
  {
    label: 'Branch Name',
    name: 'job_detail__branch__name',
    status: 0,
    className: 'md-hidden sm-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Group Area',
    name: 'run__routes',
    status: 0,
    className: 'md-hidden sm-hidden',
    max_width: MEDIUM_COL,
  },

];
