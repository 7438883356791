import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  EX_LARGE_COL,
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL,
  EX_VERY_LARGE_COL,
  EX_VERY_SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Dropdown from 'components/SharedComponents/Dropdown';
import Pagination from 'components/SharedComponents/Pagination';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import {DOWNLOAD} from 'utils/constants/icons';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import CancelVisitModals from './EventModal/CanceledVisitModals';
import {MdEdit} from 'react-icons/md';
import {getPageToSearchParam} from 'shared/methods';
import {getChoices} from 'utils/api/CoreApi';
import {
  getCancelledVisits,
  getDownload,
  patchDeleteIcon,
} from 'utils/api/ClientApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';

import moment from 'moment';
import Fade from 'react-reveal/Fade';
import {GrSearch} from 'react-icons/gr';
import {RiDeleteBinLine} from 'react-icons/ri';
import {getTimesArray, getCodeDaysArray, getDaysArray} from 'utils/Array';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import {
  CLIENT_CANCELLED_VISITS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../utils/constants/permissions';
import {
  ItemContainer,
  MainContainer,
  PublishButton,
  RightContainer,
} from 'components/SharedComponents/ProgressInformation/styles';
import {
  getCategoryPermission,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from '../../../utils/localStorage/user';

import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import EventModal from './EventModal';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  CHECK,
  CROSS,
  FLOPPY,
  Home_ICON,
  BIKE_ICON,
  CAR_ICON,
  FOOT_ICON,
  High_PRIORITY,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
} from 'utils/constants/icons';
import {PrimaryButton} from 'shared/styles/buttons';
import {client_cancelled_visits_choices} from 'utils/choiceConstant';
import { NoDataFoundMessage } from '../Location/AddLocationModal/styles';
import { Toast } from 'primereact/toast';
const headerColumns = [
  {
    label: 'VISIT No.',
    name: 'visit_number',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'CLIENT FULL NAME',
    name: 'client_service_visit__client__full_name',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'REASON FOR CANCEL',
    name: 'visit_cancellation_reason__name',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'VISIT START TIME',
    name: 'start_date',
    status: 0,
    className: ' sm-hidden  ',
    max_width: SMALL_COL,
  },
  {
    label: 'VISIT END DATE',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT END TIME',
    name: 'end_date',
    status: 0,
    className: '  sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'CLIENT TO BE BILLED',
    name: 'client_to_be_billed',
    status: 0,
    className: 'grow-mobile  ',
    max_width: SMALL_COL,
  },
  {
    label: 'STAFF TO BE PAID',
    name: 'staff_to_be_billed',
    status: 0,
    className: ' sm-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'CANCEL UPDATED BY ',
    name: 'updated_by',
    status: 0,
    className: '  md-hidden ',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'CANCEL NOTES ',
    name: 'visit_cancellation_notes',
    status: 0,
    className: ' md-hidden ',
    max_width: EX_LARGE_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: ' md-hidden ',
    max_width: EX_SMALL_COL,
  },
];

function CancelledVisits(props) {
  const { values, setValues, useInput } = useForm({
    ordering: '-start_date',
    start_date__gte: moment().format("YYYY-MM-DD"),
    end_date__lte: moment().format("YYYY-MM-DD")
  });
  const [cancelledvisits, setCancelledVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const mountedRef = useRef(true);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const CANCELED_VISIT_VALUES = 'cancelVisit';
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
  } = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const {headerFilterClearValue} = useContext(BranchItemsContext);
  const [clearAll, setClearAll] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [numberValue, setNumberValue] = useState();
  const userClient = getClient();
  const userEmployee = getEmployee();
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [eventValues, setEventValues] = useState([]);
  const userStaff = contextIsStaff;
  const history = useHistory();
  const [loadingModal, setLoadingModal] = useState(true);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
const [headerData,setHeaderData]=useState({count:0,hours:0})
  const toast = useRef(null)
  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);
  const {
    setValue: setCancelledVisit,
    value: cancelledVisit,
    fullBind: fullBindCancelledVisit,
  } = useModal(false);
  const {handleGetChoices} = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(client_cancelled_visits_choices);
  }, []);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (contextIsAdmin || contextIsSuperAdmin) {
      setDeletePermissions(true);
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === CLIENT_CANCELLED_VISITS) {
            rolePermissions[CLIENT_CANCELLED_VISITS] = {};
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION] = {};
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        if (
          rolePermissions[CLIENT_CANCELLED_VISITS] &&
          rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION]
        )
          setDeletePermissions(
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ]
          );
      }
    }
  }, [contextIsAdmin, contextIsSuperAdmin]);

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setBranch(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setClients(
      contextChoices &&
        contextChoices.client_listing &&
        contextChoices.client_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );

    setEmployees(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
  }, [contextChoices]);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.ordering, branchData, props.location.search]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };
  const handleRowClick = type => {
    if (type == 'edit') {
      // cancelVisitReset()
      setCancelledVisit(!cancelledVisit);
    } else {
      // cancelVisitReset()
      // handleAddVisitModal()
      setCancelledVisit(!cancelledVisit);
    }
  };
  const search = () => {
 
    if(!values.start_date__gte && values.end_date__lte){
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Please select Date From first!`,
      });
      return ;
    }
    if(new Date(values.start_date__gte) > new Date(values.end_date__lte)){
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Date From should be greater than Date To`,
      });
      return ;
    }
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getCancelledVisits(
      {...branchData, ...values, ...pageToSearch},
      values.limitperpage,
      values.start_date_gte,
      values.end_date_lte
    ).then(response => {
      if (response) {
        setLoadingModal(false);
      }
      setHeaderData({count:response.count,hours:response.visitHrs})
      setPages(Math.ceil(response.count / values.limitperpage));
      if (!mountedRef.current) return null;
      setCancelledVisits(response.results);
    });
  };

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setFilterEmployees = item => {
    setEmployeesFilter([...item]);
  };

  const setFilterClients = item => {
    setClientsFilter([...item]);
  };

  const hitSearch = value => {
    values.search = value;
    search();
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };
  const handleDownload = () => {
    let fileName = 'CancelVisit' + moment().format('YYYY-MM-DD');
    SetDownloadDisable(true);
    getDownload('CancelVisit', {...values, ...branchData})
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        SetDownloadDisable(false);
      });
  };
  const dropDownHandleSelect = item => {
    if (item.runroutes) {
      item.runroutes =
        item.runroutes.length === (runRouteData ? runRouteData : 0).length ||
        item.runroutes.length === 0
          ? []
          : item.runroutes;
    }

    if (item.branch) {
      item.branch =
        item.branch.length === (branch ? branch : 0).length ||
        item.branch.length === 0
          ? []
          : item.branch;
    }

    setValues({...values, ...item});
  };

  const toDeleteVisit = async id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({...values});
  };

  const eventClickHandler = async evvVisit => {
    try {
      setEventValues({...evvVisit, ...{newDate: ''}});
    } catch (error) {}
  };

  const handleDeleteVisit = async () => {
    if (idToDelete) {
      const is_deleted = true;
      await patchDeleteIcon(idToDelete, is_deleted);
      search();
      setModalDeleteValue(false);
    }
  };
  const handleSearch = () => {
    if (
      values.runroutes ||
      values.branch ||
      values.clients ||
      values.employees ||
      branchData ||
      values.limitperpage ||
      values.start_date__gte ||
      values.end_date__lte
    ) {
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/cancelled-visits',
        search: stringified,
      });

      search();
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Title>Lists of Cancelled Visits</Title>
          <div className='d-flex justify-content-between'>
            <div
              className={'d-flex'} style={{gap: "0.5rem"}}>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({runroutes: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employees: items});
                    }}
                    itemsSelected={employeesFilter}
                    setSelectedFilter={setFilterEmployees}
                    placeHolder={'Employee name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={clients ? clients : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({clients: items});
                    }}
                    itemsSelected={clientsFilter}
                    setSelectedFilter={setFilterClients}
                    placeHolder={'Client name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('start_date__gte')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('end_date__lte')}
                  />
                </DropdownTypesContainer>
                <PrimaryButton
                    minWidth="6rem"
                    onClick={handleSearch}>
                    Search
                  </PrimaryButton>
            </div>
                <SearchAddContainer className="d-flex align-items-center">
                  <SearchIcon
                    onClick={() => {
                      setShowSearch(!showSearch);
                    }}>
                    <GrSearch />
                  </SearchIcon>
                  <SearchContainer show={showSearch}>
                    <Fade collapse when={showSearch}>
                      <InputLabelTop
                        no_internal_margin="true"
                        type="text"
                        id="search"
                        label={'Search'}
                        // {...useInput('search')}
                        // onBlur={() => {
                        //   search();
                        // }}
                        // onKeyPress={e => {
                        //   keyPressed(e);
                        // }}
                        onChange={e => {
                          keyPressed(e);
                        }}
                        value={searchKeyword}
                      />
                    </Fade>
                  </SearchContainer>

                        <PublishButton
                          disable={downloadDisable}
                          onClick={!downloadDisable && handleDownload}>
                          {DOWNLOAD}
                        </PublishButton>
                  
                </SearchAddContainer>
          </div>
      <DataContainer className=" ml-2 mt-2 mb-4 d-flex justify-content-start">

<div className="data-container">
  <div className="mr-2">
    {/* <p>{'Clients: ' + numClients}</p> */}
    <p>{'Visits: ' + headerData?.count}</p>
  </div>
  <div className="mr-2">
    {/* <p>{'Clients: ' + numClients}</p> */}
    <p>{' Cancelled (hrs) : ' + headerData?.hours}</p>
  </div>
</div>

</DataContainer>
      <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start"></DataContainer>
      <ComponentDynamic loading={loadingModal}>
       {cancelledvisits && cancelledvisits.length? (
        <Table
          headerColumns={headerColumns}
          headerPadding={false}
          noEditOption
          fontSize="0.75rem"
          callBackQuerySort={setQuerySort}>
          <div>
            {cancelledvisits &&
              cancelledvisits.map((visitcancelled, index) => {
                const visitEndDate = visitcancelled.end_date
                  ? moment(visitcancelled.end_date).format('DD-MM-YYYY')
                  : 'Unset';
                const visitEndTime = visitcancelled.end_date
                  ? moment(visitcancelled.end_date).format('HH:mm')
                  : 'Unset';
                const visitStartDate = visitcancelled.start_date
                  ? moment(visitcancelled.start_date).format('DD-MM-YYYY')
                  : 'Unset';
                const visitStartTime = visitcancelled.start_date
                  ? moment(visitcancelled.start_date).format('HH:mm')
                  : 'Unset';

                const date_of_cancellation =
                  visitcancelled.visit_cancelled_datetime
                    ? moment(visitcancelled.visit_cancelled_datetime).format(
                        'YYYY-MM-DD'
                      )
                    : 'Unset';
                const time_of_cancellation =
                  visitcancelled.visit_cancelled_datetime
                    ? moment(visitcancelled.visit_cancelled_datetime).format(
                        'HH:mm'
                      )
                    : 'Unset';
                return (
                  <div key={index}>
                    <Row bgColor Shadow>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => eventClickHandler(visitcancelled)}>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.visit_number}
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {
                            visitcancelled?.client_service_visit.client_data
                              ?.full_name
                          }
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.visit_cancellation_reason?.id
                            ? visitcancelled.visit_cancellation_reason.name
                            : visitcancelled.visit_cancellation_reason}
                        </Col>

                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {
                            visitStartTime //visit start date
                          }
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {
                            visitEndDate //visite start time
                          }
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitEndTime}
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.client_to_be_billed +
                            (visitcancelled.client_to_be_billed && '%')}
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          className="  "
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.staff_to_be_billed +
                            (visitcancelled.client_to_be_billed && '%')}
                        </Col>
                        <Col
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.updated_by &&
                            visitcancelled.updated_by.name}
                        </Col>

                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          overlap={true}
                          className="md-hidden"
                          Capitalize
                          Center>
                          {visitcancelled.visit_cancellation_notes}
                        </Col>

                        {deletePermission && (
                          <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            <Col className="sm-hidden" Center Icon Purple>
                              <IconWrapper
                                onClick={() => {
                                  eventClickHandler(visitcancelled);
                                  handleRowClick('edit');
                                }}>
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                            <IconWrapper
                              onClick={() => {
                                toDeleteVisit(visitcancelled.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </Col>
                        )}
                      </ColsGrouper>
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}

            <div className="mt-3">
              <Pagination
                setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                values={values}
                dropDownHandleSelect={dropDownHandleSelectPagination}
                number={PAGE_SIZE_RENDER}
                dataLength={cancelledvisits ? cancelledvisits.length : 0}
              />
            </div>
          </div>
        </Table>
       ) : (
          <NoDataFoundMessage top={'15%'}>No data found</NoDataFoundMessage>
        )}
      </ComponentDynamic>
      {!addEvent ? null : (
        <EventModal
          readOnly={true}
          fullBind={fullBindAddEvent}
          eventValues={eventValues}
          employees={employees}
        />
      )}
      {cancelledVisit && (
        <CancelVisitModals
          search={search}
          eventValues={eventValues}
          fullBind={fullBindCancelledVisit}
          useInput={useInput}
          cancelledvisits={cancelledvisits}
          setEventValues={setEventValues}
          // errorMessage={errorMessage}
          choices={contextChoices}
          nameSection={CANCELED_VISIT_VALUES}
          setCancelledVisit={setCancelledVisit}
          setValues={setValues}
          values={values}
          saveButtonIcon={saveButtonIcon}
          loadingSave={loadingSave}
          // textStatus={textStatus}
          // saveCancelVisit={saveCancelVisit}
          // dropDownHandleCancelVisit={dropDownHandleCancelVisit}
          // listView={listView}
        />
      )}
      <ModalDecision
        type="delete"
        title="Warning"
        body={`Are you sure you want to delete this Cancelled visit ? \n delete the cancelled visit will remove all records from database.`}
        onOk={handleDeleteVisit}
        onCancel={() => {
          setIdToDelete('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
}

export default CancelledVisits;
