import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { AppContext } from 'context';
import { functions } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
    // ButtonWeek,
    ErrorMessage,
    FlexGrid3Container,
    FlexGrid3Item,
    ModalContainer,
    WeekStyle,
} from 'shared/styles/constants/tagsStyles';
import { addHrTask, addRepeatingTask } from 'utils/api/SettingsApi';
import { FLOPPY } from 'utils/constants/icons';
import { KeyPress } from 'utils/constants/regularExpressions';
import { END_TYPE_AFTER, END_TYPE_NEVER, END_TYPE_ON, EVERY_DAY, EVERY_MONTH, EVERY_WEEK, EVERY_YEAR } from 'utils/constants/serviceVisits';
import styled from 'styled-components';
import {
    PURPLE,
    GRAY_100,
    GRAY_200,
    GRAY_300,
    BLACK_900,
    RED,
    WHITE,
    SOFT_RED,
    DARK_VIOLET,
    PURPLE_GRAY_500,
    ERR_COLOR,
    GRADIENT_GREEN,
    GRAY_400,
    ERR_COLOR_800,
} from 'shared/styles/constants/colors';
import { XS, SM, MD, LG, XL, XXL, XL1 } from 'shared/styles/constants/resolutions';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';

const ADD_MESSAGE = 'Repeating QA task added successfully!'
const UPDATE_MESSAGE = 'Repeating QA task updated successfully!'
const SOMETHING_WENT_WRORNG = "Something went wrong!"
const EMPLOYEE = 'Employee'
const CLIENT = 'Client'
const TYPE_DROPDOWN_OPTIONS = [
    { id: EMPLOYEE, name: EMPLOYEE },
    { id: CLIENT, name: CLIENT }
];

function AddRepeatingTask({
    fullBind,
    useInput,
    values,
    setValues,
    seletedId,
    setModalValue,
    toast,
    readOnly,
    choices,
    setCheckRequires,
    errorMessage,
    setErrorMessage,
    showError,
    setShowError,
    errors,
    daysOfWeek,
    setDaysOfWeek,
    getRepeatingTaskListData,
    modalLoading
}) {

    const [loadingSave, setLoadingSave] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false)

    const handleSubmit = async () => {
        if (errors?.length) {
            setCheckRequires(true)
            setShowError(true)
            setErrorMessage("Some of the fields have invalid or empty values. Please review")
            return;
        }
        setSaveDisabled(true)
        setLoadingSave(true)
        const selectedDays = daysOfWeek
            .filter(el => el.selected)
            .map(el => el.day)
            .join(',');
        const body = {
            core_hr_task: values.core_hr_task,
            repeat_pattern: values.repeat_no || 1,
            repeat_on: selectedDays,
            repeat_every: values.repeat_every,
            end_type: values.end_type,
            start_date: values.start_date,
            employee: values.type === EMPLOYEE ? values.employee : null,
            client: values.type === CLIENT ? values.client : null,
            end_date: values.end_date || null,
            end_value: values.end_value || null
        };

        try {
            const res = await addRepeatingTask(seletedId, body);
            setSaveDisabled(false)
            setModalValue(false)
            setCheckRequires(false)
            setShowError(false)
            if (res.status === true) {
                getRepeatingTaskListData()
                setLoadingSave(false)
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: seletedId ? UPDATE_MESSAGE : ADD_MESSAGE,
                })
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: SOMETHING_WENT_WRORNG,
                });
            }

        } catch (error) {
            console.error(error)
        }
    };

    const dropDownHandleVisits = (item, name) => {
        //recursive objects merge
        values[name] = item[name];
        setValues({ ...values });
    };

    const handleDaysChange = (readOnly, day) => {
        if (readOnly) {
            return;
        }
        const changedValue = daysOfWeek.map(item => item.day === day ? { ...item, selected: !item.selected } : item)
        setDaysOfWeek(changedValue)
    }

    const endValueInput = useInput('end_value', values.end_type === "AFTER" ? 'isRequired' : "")
    const employeeUseInput = useInput(`employee`, values.client ? "" : 'isRequired')
    const clientUseInput = useInput(`client`, values.employee ? "" : 'isRequired')

    return (
        <div className='event-task-modal'>
            <ModalContainer max_width={'65%'}>
                <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={modalLoading}>
                    <div style={{ padding: "0 1rem 1rem 1rem" }} className="w-100">
                        <div className='w-100 mt-4'>
                            <div className="d-flex w-100 mt-2">
                                <div className="w-100 mr-3">
                                    <DropdownSearchable
                                        required
                                        disabled={readOnly}
                                        {...useInput(`core_hr_task`, 'isRequired')}
                                        //   data_error={errors.indexOf(`${[nameSection]}.every`)}
                                        placeHolder={'QA Task'}
                                        options={choices?.hr_task?.map(task => ({ ...task, name: task?.title }))}
                                        selectedOption={
                                            values.core_hr_task ? values.core_hr_task : null
                                        }
                                        onChange={value =>
                                            dropDownHandleVisits({ core_hr_task: value ? value : null }, 'core_hr_task')
                                        }
                                    />
                                </div>
                                <div className="w-100  mr-3">
                                    <DropdownSearchable
                                        required
                                        {...useInput(`type`)}
                                        placeHolder={'Type'}
                                        disabled={readOnly}
                                        options={TYPE_DROPDOWN_OPTIONS}
                                        selectedOption={values.type ? values.type : null}
                                        onChange={value =>
                                            dropDownHandleVisits({ type: value ? value : null }, 'type')
                                        }
                                    />
                                </div>
                                <div className="w-100">
                                    {values.type === EMPLOYEE ? (
                                        <DropdownSearchable
                                            disabled={readOnly}
                                            required={values.client ? false : true}
                                            {...employeeUseInput}
                                            placeHolder={'Employee'}
                                            options={choices.employee_listing
                                                ? choices?.employee_listing.map(emp => ({ id: emp?.id, name: emp?.full_name }))
                                                : []}
                                            selectedOption={
                                                values ? values.employee : null
                                            }
                                            onChange={value =>
                                                dropDownHandleVisits(
                                                    { employee: value ? value : null },
                                                    'employee',

                                                )
                                            }
                                        />
                                    ) : (
                                        <DropdownSearchable
                                            disabled={readOnly}
                                            required={values.employee ? false : true}
                                            {...clientUseInput}
                                            placeHolder={'Client'}
                                            options={choices.client_listing
                                                ? choices?.client_listing.map(client => ({ id: client?.id, name: client?.full_name }))
                                                : []}
                                            selectedOption={
                                                values ? values.client : null
                                            }
                                            onChange={value =>
                                                dropDownHandleVisits(
                                                    { client: value ? value : null },
                                                    'client',

                                                )
                                            }
                                        />)
                                    }

                                </div>
                            </div>
                            <div className="d-flex w-100 mt-3">
                                <div className="w-100 mr-3">
                                    <DropdownSearchable
                                        required
                                        disabled={readOnly}
                                        {...useInput(`repeat_every`, 'isRequired')}
                                        //   data_error={errors.indexOf(`${[nameSection]}.every`)}
                                        placeHolder={'Repeat Every'}
                                        options={[
                                            { id: EVERY_DAY, name: 'Day' },
                                            { id: EVERY_WEEK, name: 'Week' },
                                            { id: EVERY_MONTH, name: 'Month' },
                                            { id: EVERY_YEAR, name: 'Year' },
                                        ]}
                                        selectedOption={
                                            values.repeat_every ? values.repeat_every : null
                                        }
                                        onChange={value =>
                                            dropDownHandleVisits({ repeat_every: value ? value : null }, 'repeat_every')
                                        }
                                    />
                                </div>
                                <div className="w-100  mr-3">
                                    <DropdownSearchable
                                        required
                                        {...useInput(`end_type`, 'isRequired')}
                                        //   data_error={errors.indexOf(`values.end_type`)}
                                        disabled={readOnly}
                                        placeHolder={'Repeat End'}
                                        options={[
                                            { id: END_TYPE_ON, name: 'On' },
                                            { id: END_TYPE_AFTER, name: 'After' },
                                            { id: END_TYPE_NEVER, name: 'Never' },
                                        ]}
                                        selectedOption={
                                            values.end_type ? values.end_type : null
                                        }
                                        onChange={value =>
                                            dropDownHandleVisits({ end_type: value ? value : null }, 'end_type')}
                                    />
                                </div>
                                <div className="w-100">
                                    <InputLabelTop
                                        disabled={readOnly}
                                        // required
                                        onKeyPress={values.repeat_no ? KeyPress.POSITIVE : KeyPress.NATURAL}
                                        label={'Repeat No.'}
                                        id={`repeat_no`}
                                        {...useInput('repeat_no')}
                                    />
                                </div>
                            </div>
                            <div className=" d-flex w-100">
                                <div style={{ width: '33.33%' }} className="mr-3 mt-2">
                                    <DateSelector
                                        disabled={readOnly}
                                        isRequired
                                        labelTop={'Start Date'}
                                        {...useInput('start_date', 'isRequired')}
                                    />
                                </div>
                                <div style={{ width: '33.33%' }} className="mr-3 mt-2">
                                    <DateSelector
                                        disabled={readOnly}
                                        isRequired={values.end_type === "ON" ? true : false}
                                        labelTop={'End Date'}
                                        {...useInput('end_date', values.end_type === "ON" ? 'isRequired' : "")}
                                    />
                                </div>
                                <div style={{ width: '33.33%' }} className={'mt-2'}>
                                    {values.end_type === "AFTER" && (
                                        <InputLabelTop
                                            disabled={readOnly}
                                            required={values.end_type === "AFTER" ? true : false}
                                            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
                                            label={'End value'}
                                            type="text"
                                            id={`end_value`}
                                            {...endValueInput}
                                        />)}
                                </div>
                            </div>
                            <div>
                                <div className={'formWeek d-block'} >
                                    <div
                                        className={'pt-2 ml-md-1 ml-0 text-center'}
                                        style={{ fontSize: '0.6rem', fontWeight: 'bold', width: "21.25rem" }}>
                                        Repeat on
                                    </div>
                                    <WeekStyle
                                        style={{ justifyContent: "start" }}
                                        daysAdded={daysOfWeek}
                                        disabled={readOnly}
                                    >
                                        {daysOfWeek.map((item, indexWeek) => {
                                            return (
                                                <ButtonWeek
                                                    key={item.day}
                                                    add_background={item.selected}
                                                    disabled={readOnly}
                                                    onClick={(e) => { handleDaysChange(readOnly, item.day) }}
                                                >
                                                    <div>
                                                        <label>{item.day}</label>
                                                    </div>
                                                </ButtonWeek>
                                            );
                                        })}
                                    </WeekStyle>
                                </div>
                            </div>
                        </div>
                        <div
                            className={'d-flex justify-content-center justify-content-md-end mt-3'}>
                            <div className={'mb-3'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <div className={'ml-0 ml-md-3 mr-1'}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    disabled={readOnly || saveDisabled}
                                    onClick={handleSubmit}
                                    style={{ marginBottom: '5px' }}>
                                    <span className={'mr-2'}>SAVE</span>
                                    {!loadingSave ? (
                                        FLOPPY
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer>
        </div >
    );
}

export default AddRepeatingTask;

export const ButtonWeek = styled.div`
  label {
    padding: 0px !important;
    margin: 0px !important;
    color: ${props => (props.add_background ? WHITE : GRAY_400)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-weight: bold;
  }

  div {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 3rem;
    background: ${props =>
        props.add_background
            ? `linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})`
            : GRAY_200};
        pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  }

  @media (max-width: ${SM}) {
    div {
      width: 2rem;
      max-width: 2rem;

      label {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${MD}) {
    div {
      width: 4rem;
      max-width: 4rem;

      label {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${XXL}) {
    div {
      max-width: 2.5rem;

      label {
        text-align: center;
        align-items: center;
        width: 10px;
        max-width: 10px;
        word-wrap: break-word;
      }
    }
  }
`;