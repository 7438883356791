import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import React from 'react'

export default function Heading({ setJson, json }) {
    return (
        <div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginBottom: 10 }}>
                <div style={{ marginBottom: 10, display: "flex", flex: 1 }}>
                    <TextField
                        id="outlined-error"
                        label="Value *"
                        placeholder='Value'
                        defaultValue={json.headingText.label}
                        style={{ marginRight: 10, flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, headingText: {
                                    ...json.headingText,
                                    label: event.target.value
                                }
                            })
                        }}
                    />
                    <FormControl style={{ flex: 1 }} >
                        <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={json.headingText.tag}
                            label="Tag"
                            onChange={(event) => {
                                setJson({
                                    ...json, headingText: {
                                        ...json.headingText,
                                        tag: event.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem value={"h1"}>H1</MenuItem>
                            <MenuItem value={"h2"}>H2</MenuItem>
                            <MenuItem value={"h3"}>H3</MenuItem>
                            <MenuItem value={"h4"}>H4</MenuItem>
                            <MenuItem value={"h5"}>H5</MenuItem>
                            <MenuItem value={"h6"}>H6</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch color="primary" />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch color="primary" />
                    </div>
                </div> */}
                   
                {/* <FormControlLabel
                    value="start"
                    control={
                        <Switch color="primary" />}
                    label={<div>
                        <label for="fname" style={{ marginRight: 10, fontWeight: "bold" }} >{'Required'}:</label>
                    </div>}
                    style={{ fontWeight: "bold", justifyContent: "flex-start", }}
                    labelPlacement="top"
                /> */}
                {/* <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={3}
                    value={json.headingText.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, headingText: {
                                ...json.headingText,
                                description: event.target.value
                            }
                        })
                    }}
                /> */}

            </div>
        </div>
    )
}
