import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  AUTH,
  CLIENT,
  COMPANY,
  COMPANY_PROFILE,
  DASHBOARDS,
  TRIAGE,
  EVV,
  EVV_VISITS,
  LOGIN,
  ROTA_SHIFTS,
  SCHEDULER,
  MEDICATION,
  SETTINGS,
  REPORT,
  FINANCE,
  BILLING,
  PAYROLL,
  SUMMARY,
  SETTINGS_CLIENT,
  REPORT_CLIENT,
  SETTINGS_ROLES_AND_PERMISSIONS,
  SETTINGS_ORGANIZATIONS,
  SETTINGS_TEAM,
  REPORT_TEAM,
  SETTINGS_CALENDAR,
  SHIFT_PATTERNS,
  TEAM,
  USERS,
  TEAM_ROUTE_MAPPING,
  VISITS,
  REGULAR_VISIT,
  CANCELLED_VISITS,
  SETTINGS_ALGOSCORE,
  ALGO_SCORE_CLIENT,
  COMPANY_PRIORITY,
  COMPANY_BRANCHES,
  //ABSENCE_HISTORY_LIST,
  PUBLIC_HOLIDAYS,
  WD_WE,
  DAY_NIGHT_OVER,
  DURATION,
  CALENDAR_DATA,
  SETTINGS_RATECARD,
  FUNDER,
  OVERVIEW,
  BILLRATE,
  TIMESOURCE,
  SETTINGS_EVV,
  SETTINGS_ESV,
  SETTINGS_FINANCE,
  PERSONAL_DETAILS,
  CLIENT_DETAILS,
  CLIENT_KPI_DETAILS,
  DETAILS,
  JOB_DETAILS,
  NOK,
  SETTINGS_SUBSCRIPTION,


  ALGO_SCORE_NEWFEILD,
  SETTINGS_TRIAGE,
  ESV,
  CLIENT_DAILY_DETAILS,
  REPORT_KPI,
  KPI_DAILY_DETAILS,
  OFFER_VISITS,
  HANDBACK_REQUEST,
  FORM_BUILDER,
  SETTINGS_FORM_BUILDER,
  SETTINGS_CHOICES,
  DASHBOARD_TEAM_KPI,
  DASHBOARD_VISIT_KPI,
  DASHBOARD_CLIENT_KPI,
  DASHBOARD_MEDICATION_KPI,
  SETTINGS_BRADFORD,
  BRADFORD_ROUTE,
  SWAP_VISIT,
  VISIT_AUDIT,
  SETTINGS_EMAIL,
  INVOICE,
  LINKED_USER,
  TEAM_SKILL_MATRIX,
  TEAM_COMPETENCY_MATRIX,
  HR_TASKS,
  TEAM_HR_TASK_MATRIX,
  CLIENT_HR_TASK_MATRIX,
  LOCATION,
  DASHBOARD_INSIGHTS,
  TEAM_KPI,
  SETTINGS_LOGIN_ROUTE,
  COMPANY_ORGANIZATION,
} from 'utils/constants/routes';
import DropdownMenu from './DropdownMenu';
import { logout } from 'utils/api/SessionApi';
import {
  getCategoryPermission,
  getEmail,
  getPermissions,
  getUserName,
  getSubSectionPermissions,
} from 'utils/localStorage/user';
import Image from 'components/SharedComponents/Image';
import {
  FadeBottomUl,
  FadeTopUl,
  ImageComponent,
  SideBar,
  SideBarContainer,
  SideBarItem,
  SideBarItemLabel,
  SignOutContainer,
  StaticArrow,
  UlStyled,
  UserContainer,
  UserEmail,
  UserImgContainer,
  UserImgHalo,
  UserName,
} from './styles';
import {
  calendar_in,
  report_in,
  report_out,
  finance_in,
  finance_out,
  calendar_out,
  client_in,
  client_out,
  dashboard_in,
  dashboard_out,
  settings_in,
  settings_out,
  team_in,
  team_out,
  triage_in,
  triage_out,
  form_build,
  location_in
} from 'images/leftSidebar';
import { IoIosArrowBack, IoIosArrowForward, IoIosLogOut } from 'react-icons/io';
import { PURPLE } from 'shared/styles/constants/colors';
import {
  ADMIN_EMAIL,
  CALENDER_PERMISSION,
  REPORT_PERMISSION,
  CLIENT_PERMISSION,
  CLIENT_SETTINGS,
  CLIENT_VISITS,
  COMPANY_SETTINGS,
  ROLES_AND_PERMISSIONS_SETTINGS,
  SETTINGS_PERMISSION,
  TEAM_PERMISSION,

  TEAM_SETTINGS,
  CALENDAR_SETTINGS,
  WEB_PERMISSION,
  WEB_PERMISSION_ALL,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  CLIENT_CANCELLED_VISITS,

  SETTINGS_ALGO_SCORE,
  WEB_DELETE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  LIST_OF_CLIENT_SETTINGS,

  LIST_OF_EMPLOYEES_SETTINGS,
  ROTA_SHIFTS_SETTINGS,
  SHIFT_PATTERN_SETTINGS,
  EVV_SETTINGS,
  SCHEDULER_SETTINGS,
  REGULAR_VISITS,
  USER_SETTINGS,
  COMPANY_LICENSE_SETTINGS,
  BRANCH_SETTINGS,
  RUN_ROUTE,
  PRIORITY_SETTINGS,
  ABSENCE_LIST,
  REPORT_TEAM_TAB,
  FINANCE_BILLING_TAB,
  FINANCE_PAYROLL_TAB,
  REPORT_CLIENT_TAB,
  FINANCE_PERMISSION,
  DASHBOARD_PER,
  TRIAGE_PERMISSION,
  CLIENT_OFFER_VISITS,
  FORM_BUILDER_PERMISSION, EMPLOYEE_ABSENCE, INVOICE_PERMISSION, LIST_OF_LOCATIONS, LOCATION_PERMISSION, INSIGHTS, TESM_KPI, QUALITY_ASSURANCE_KPI, MEDICATION_KPI, VISIT_COMPLIANCE_KPI, CLIENT_KPI, TEAM_KPI_PERMISSION,
  CLIENT_VISIT_AUDIT,
  CLIENT_MEDICATION,
  CLIENT_HANDBACK,
  CLIENT_SWAP_VISIT,
  CLIENT_QA_TASK_MATRIX,
  ESV_PERMISSION,
  CLIENT_CANELLED_VISITS
} from '../../../utils/constants/permissions';
import {
  getUserFirstName,
  getUserLastName,
  getStaff,
  getClient,
  getEmployee,
} from '../../../utils/localStorage/user';
import _ from 'lodash';
import { getAdmin, getSuperAdmin } from '../../../utils/localStorage/token';
import { AppContext } from 'context';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function LeftSidebar(props) {
  const {decreaseWidth} = props;
  const history = useHistory()
  const myLocation = useLocation();
  const [allPermissions, setAllPermissions] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [dashboardPermission, setDashboardPermission] = useState(false);
  const [team, setTeam] = useState(false);
  const [triagePermission, setTriagePermission] = useState(false);
  const [client, setClient] = useState(false);
  const [calender, setCalender] = useState(false);
  const [report, setReport] = useState(false);
  const [formBuilderPermission, setFormBuilderPermission] = useState(false);
  const [settings, setSettings] = useState(false);
  const [invoicePermission, setInvoicePermission] = useState(false);
  const [location, setLocation] = useState(false)

  const [finance, setFinance] = useState(false);
  const [teamSettings, setTeamSettings] = useState(false);
  const [companySettings, setCompanySettings] = useState(false);
  const [algoScoreSettings, setAlgoScoreSettings] = useState(false);
  const [roleAndPermissionsSettings, setRoleAndPermissionsSettings] =
    useState(false);
  const [clientSettings, setClientSettings] = useState(false);
  const [usersSettings, setUsersSettings] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  //CompanyLicenses
  const [updateCompanyLicenses, setUpdatePermissionsCompanyLicenses] =
    useState(false);
  const [createCompanyLicenses, setCreatePermissionsCompanyLicenses] =
    useState(false);
  const [deletePermissionCompanyLicenses, setDeletePermissionsCompanyLicenses] =
    useState(false);
  const [readPermissionCompanyLicenses, setReadPermissionsCompanyLicenses] =
    useState(false);

  //CalendarSettings
  const [updateCalendarSettings, setUpdateCalendarSettings] = useState(false);
  const [createCalendarSettings, setCreateCalendarSettings] = useState(false);
  const [deleteCalendarSettings, setDeleteCalendarSettings] = useState(false);
  const [readCalendarSettings, setReadCalendarSettings] = useState(false);

  //BranchSettings
  const [updateBranchSettings, setUpdatePermissionsBranchSettings] =
    useState(false);
  const [createBranchSettings, setCreatePermissionsBranchSettings] =
    useState(false);
  const [deletePermissionBranchSettings, setDeletePermissionsBranchSettings] =
    useState(false);
  const [readPermissionBranchSettings, setReadPermissionsBranchSettings] =
    useState(false);

  //Priority
  const [readPermissionEmployeeAbsence, setReadPermissionEmployeeAbsence] = useState(false)
  const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] =
    useState(false);
  const [createPrioritySettings, setCreatePermissionsPrioritySettings] =
    useState(false);
  const [
    deletePermissionPrioritySettings,
    setDeletePermissionsPrioritySettings,
  ] = useState(false);
  const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] =
    useState(false);

  //// RunRoute
  const [updateRunRoutes, setUpdatePermissionsRunRoutes] = useState(false);
  const [createRunRoutes, setCreatePermissionsRunRoutes] = useState(false);
  const [deletePermissionRunRoutes, setDeletePermissionsRunRoutes] =
    useState(false);
  const [readPermissionRunRoutes, setReadPermissionsRunRoutes] =
    useState(false);

  // roles and permissions
  const [updateRolesAndPermissions, setUpdateRolesAndPermissions] =
    useState(false);
  const [createRolesAndPermissions, setCreateRolesAndPermissions] =
    useState(false);
  const [deleteRolesAndPermissions, setDeleteRolesAndPermissions] =
    useState(false);
  const [readRolesAndPermissions, setReadRolesAndPermissions] = useState(false);

  const [defaultDashboardPermission, setDefaultDashboardPermission] = useState({
    insight: false,
    teamKPI: false,
    clientKpi: false,
    visitKpi: false,
    medicationKpi: false,

    triage: false,

    listOfClient: false,
    visits: false,
    offerVisits: false,
    cancelledVisits: false,
    handback: false,
    swapVisits: false,
    qaTaskMatrix: false,

    rotaShifts: false,
    regularVisit: false,
    scheduler: false,
    medication: false,
    visitAudit: false,
    evv: false,
    esv: false,
    shiftPattern : false,
  })

  const userClient = getClient();
  const userEmployee = getEmployee();

  const autoRedirectIfNoPermission = () => {

    let pathName = myLocation.pathname;

    if (pathName === DASHBOARDS) {

      if (defaultDashboardPermission.insight) {
        history.push(DASHBOARDS)
      } else if (defaultDashboardPermission.teamKPI) {
        history.push(DASHBOARD_TEAM_KPI)
      } else if (defaultDashboardPermission.clientKpi) {
        history.push(DASHBOARD_CLIENT_KPI)
      } else if (defaultDashboardPermission.visitKpi) {
        history.push(DASHBOARD_VISIT_KPI)
      } else if (defaultDashboardPermission.medicationKpi) {
        history.push(DASHBOARD_MEDICATION_KPI)
      }
      
      else if (defaultDashboardPermission.triage) {
        history.push(TRIAGE)
      }else if (defaultDashboardPermission.listOfClient) {
        history.push(CLIENT)
      }else if (defaultDashboardPermission.visits) {
        history.push(VISITS)
      }else if (defaultDashboardPermission.cancelledVisits) {
        history.push(CANCELLED_VISITS)
      }else if (defaultDashboardPermission.offerVisits) {
        history.push(OFFER_VISITS)
      }else if (defaultDashboardPermission.handback) {
        history.push(HANDBACK_REQUEST)
      }else if (defaultDashboardPermission.swapVisits) {
        history.push(SWAP_VISIT)
      }else if (defaultDashboardPermission.qaTaskMatrix) {
        history.push(CLIENT + TEAM_HR_TASK_MATRIX)
      }
      
      else if (defaultDashboardPermission.rotaShifts) {
        history.push(ROTA_SHIFTS)
      }else if (defaultDashboardPermission.regularVisit) {
        history.push(REGULAR_VISIT)
      }else if (defaultDashboardPermission.scheduler) {
        history.push(SCHEDULER)
      }else if (defaultDashboardPermission.medication) {
        history.push(MEDICATION)
      }else if (defaultDashboardPermission.visitAudit) {
        history.push(VISIT_AUDIT)
      }else if (defaultDashboardPermission.evv) {
        history.push(EVV + VISITS)
      }else if (defaultDashboardPermission.esv) {
        history.push(ESV + VISITS)
      }else if (defaultDashboardPermission.shiftPattern) {
        history.push(SHIFT_PATTERNS)
      }

    }
  }

  useEffect(() => {
    autoRedirectIfNoPermission()
    // console.log(defaultDashboardPermission, "defaultDashboardPermission")
  }, [defaultDashboardPermission])

  useEffect(() => {
    let role = [...props.contextCategoryPermissions];
    let tmp = props.contextPermissions;
    let settingsRole = [...tmp];
    setFirstName(getUserFirstName());
    setLastName(getUserLastName());
    setEmail(getEmail());

    const rolePermissions = {};
    if (props.contextIsSuperAdmin) {
      setTriagePermission(true);
      setTeam(true);
      setClient(true);
      setSettings(true);
      setInvoicePermission(true)
      setCalender(true);
      setFinance(true);
      setReport(true);
      setFormBuilderPermission(true)
      setDashboardPermission(true);
      setLocation(true)

    } else if (props.contextIsAdmin) {
      setDashboardPermission(
        props.contextSubscription?.[DASHBOARD_PER]?.[WEB_PERMISSION]
      );
      setTriagePermission(
        props.contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION]
      );
      setTeam(props.contextSubscription?.[TEAM_PERMISSION]?.[WEB_PERMISSION]);
      setClient(
        props.contextSubscription?.[CLIENT_PERMISSION]?.[WEB_PERMISSION]
      );
      setSettings(
        props.contextSubscription?.[SETTINGS_PERMISSION]?.[WEB_PERMISSION]
      );
      setCalender(
        props.contextSubscription?.[CALENDER_PERMISSION]?.[WEB_PERMISSION]
      );
      setFinance(
        props.contextSubscription?.[FINANCE_PERMISSION]?.[WEB_PERMISSION]
      );
      setReport(
        props.contextSubscription?.[REPORT_PERMISSION]?.[WEB_PERMISSION]
      );
      setFormBuilderPermission(
        props.contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]
      );
      setInvoicePermission(props.contextSubscription?.[INVOICE_PERMISSION]?.[WEB_PERMISSION])
      setLocation(props.contextSubscription?.[LOCATION_PERMISSION]?.[WEB_PERMISSION])
    } else {
      // setDashboardPermission(
      //   props.contextSubscription?.[DASHBOARD_PER]?.[WEB_PERMISSION]
      // );
      //  setTriagePermission(props.contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION])
      if (role.length !== 0) {
        role.forEach(role => {
          if (role.section !== null) {
            let name = role.section.name;
            rolePermissions[name] = {};
            rolePermissions[name][WEB_PERMISSION] = {};
            rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
              role[WEB_PERMISSION_ALL];
            rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
              role[WEB_READ_PERMISSION];
            rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
              role[SELF_WEB_READ_PERMISSION];
            // rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
            // role[WEB_PERMISSION_ALL];
            // rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
            // role[WEB_PERMISSION_ALL];
            // rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
            // role[WEB_PERMISSION_ALL];
          }
        });

        setPermissions(prevState => ({ ...prevState, ...rolePermissions }));


        if (rolePermissions[DASHBOARD_PER])
          setDashboardPermission(
            props.contextSubscription?.[DASHBOARD_PER]?.[WEB_PERMISSION] &&
            (rolePermissions[DASHBOARD_PER][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[DASHBOARD_PER][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[TRIAGE_PERMISSION])
          var triagePer = props.contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
      (rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])

          setDefaultDashboardPermission(prev => ({...prev, triage : triagePer}))
          setTriagePermission( triagePer);
        if (rolePermissions[TEAM_PERMISSION])
          setTeam(
            props.contextSubscription?.[TEAM_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[TEAM_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[TEAM_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        //LOCATION_PERMISSION
        if (rolePermissions[LOCATION_PERMISSION])
          setLocation(
            props.contextSubscription?.[LOCATION_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[LOCATION_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[LOCATION_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[CLIENT_PERMISSION])
          setClient(
            props.contextSubscription?.[CLIENT_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[CLIENT_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[CLIENT_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[CALENDER_PERMISSION])
          setCalender(
            props.contextSubscription?.[CALENDER_PERMISSION]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[CALENDER_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[CALENDER_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[FORM_BUILDER_PERMISSION])
          setFormBuilderPermission(
            props.contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[REPORT_PERMISSION])
          setReport(
            props.contextSubscription?.[REPORT_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[REPORT_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[REPORT_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[SETTINGS_PERMISSION])
          setSettings(
            props.contextSubscription?.[SETTINGS_PERMISSION]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[SETTINGS_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[SETTINGS_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        // if (rolePermissions[SETTINGS_PERMISSION])
        // setInvoicePermission(
        //   props.contextSubscription?.[INVOICE_PERMISSION]?.[
        //   WEB_PERMISSION
        //   ] &&
        //   (rolePermissions[INVOICE_PERMISSION][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ] ||
        //     rolePermissions[INVOICE_PERMISSION][WEB_PERMISSION][
        //     SELF_WEB_READ_PERMISSION
        //     ])
        // );
        if (rolePermissions[SETTINGS_PERMISSION])
          setInvoicePermission(
            props?.contextSubscription?.[INVOICE_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[INVOICE_PERMISSION]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
              rolePermissions[INVOICE_PERMISSION]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION])
          );
        if (rolePermissions[FINANCE_PERMISSION])
          setFinance(
            props.contextSubscription?.[FINANCE_PERMISSION]?.[WEB_PERMISSION] &&
            (rolePermissions[FINANCE_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[FINANCE_PERMISSION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
      }

      if (settingsRole.length !== 0) {
        settingsRole.forEach(role => {
          if (role.section !== null) {
            let name = role.section.name;
            rolePermissions[name] = {};
            rolePermissions[name][WEB_PERMISSION] = {};
            rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
              role[WEB_PERMISSION_ALL];
            rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
              role[WEB_READ_PERMISSION];
            rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
              role[SELF_WEB_READ_PERMISSION];
          }
        });

        // console.log(rolePermissions);
        setPermissions(prevState => ({ ...prevState, ...rolePermissions }));

        if (rolePermissions[TEAM_SETTINGS])
          setTeamSettings(
            props.contextSubscription?.[TEAM_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[CLIENT_SETTINGS])
          //added new line below
          // if(rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
          //   WEB_READ_PERMISSION]   ||   rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][ WEB_CREATE_PERMISSION]   ||   rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][ WEB_UPDATE_PERMISSION]  ||  rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][ WEB_DELETE_PERMISSION] )
          //    setClient(true);

          setClientSettings(
            props.contextSubscription?.[CLIENT_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS])
          setRoleAndPermissionsSettings(
            props.contextSubscription?.[ROLES_AND_PERMISSIONS_SETTINGS]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[COMPANY_SETTINGS])
          setCompanySettings(
            props.contextSubscription?.[COMPANY_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (rolePermissions[SETTINGS_ALGO_SCORE])
          setAlgoScoreSettings(
            props.contextSubscription?.[SETTINGS_ALGO_SCORE]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        if (
          (props.contextSubscription[LIST_OF_CLIENT_SETTINGS]?.[
            WEB_PERMISSION
          ] ||
            props.contextSubscription[CLIENT_VISITS]?.[WEB_PERMISSION] ||
            props.contextSubscription[CLIENT_CANCELLED_VISITS]?.[
            WEB_PERMISSION
            ]) &&
          (rolePermissions[LIST_OF_CLIENT_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[CLIENT_VISITS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_CANCELLED_VISITS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[LIST_OF_CLIENT_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_VISITS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_CANCELLED_VISITS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setClient(true);
        }

        if (
          (props.contextSubscription[LIST_OF_CLIENT_SETTINGS]?.[
            WEB_PERMISSION
          ] ||
            props.contextSubscription[CLIENT_VISITS]?.[WEB_PERMISSION] ||
            props.contextSubscription[CLIENT_OFFER_VISITS]?.[
            WEB_PERMISSION
            ]) &&
          (rolePermissions[LIST_OF_CLIENT_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[CLIENT_VISITS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_OFFER_VISITS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[LIST_OF_CLIENT_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_VISITS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_OFFER_VISITS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setClient(true);
        }


        // if (
        //   (props.contextSubscription[TRIAGE_PERMISSION]?.[
        //     WEB_PERMISSION
        //   ] ||
        //     props.contextSubscription[TRIAGE_PERMISSION]?.[WEB_PERMISSION] ||
        //     props.contextSubscription[TRIAGE_PERMISSION]?.[
        //     WEB_PERMISSION
        //     ]) &&
        //   (rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ] ||
        //     rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //     ] ||
        //     rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //     ] ||
        //     rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     SELF_WEB_READ_PERMISSION
        //     ] ||
        //     rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     SELF_WEB_READ_PERMISSION
        //     ] ||
        //     rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION][
        //     SELF_WEB_READ_PERMISSION
        //     ])
        // ) {
        //   setTriagePermission(true);
        // }



        if (
          (props.contextSubscription?.[REPORT_TEAM_TAB]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[REPORT_CLIENT_TAB]?.[WEB_PERMISSION]) &&
          (rolePermissions[REPORT_TEAM_TAB]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[REPORT_CLIENT_TAB]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[REPORT_TEAM_TAB]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[REPORT_CLIENT_TAB]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setReport(true);
        }

        if (
          (props.contextSubscription?.[FINANCE_BILLING_TAB]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[FINANCE_PAYROLL_TAB]?.[
            WEB_PERMISSION
            ]) &&
          (rolePermissions[FINANCE_BILLING_TAB]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[FINANCE_PAYROLL_TAB]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[FINANCE_BILLING_TAB]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[FINANCE_PAYROLL_TAB]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setFinance(true);
        }

        if (
          (props.contextSubscription?.[LIST_OF_EMPLOYEES_SETTINGS]?.[
            WEB_PERMISSION
          ] ||
            props.contextSubscription?.[TEAM_ROUTE_MAPPING]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[ABSENCE_LIST]?.[WEB_PERMISSION]) &&
          (rolePermissions[LIST_OF_EMPLOYEES_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[TEAM_ROUTE_MAPPING]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[ABSENCE_LIST]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[TEAM_ROUTE_MAPPING]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[ABSENCE_LIST]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setTeam(true);
        }

        //implemented checks in client then no rota page visible
        if (
          (props.contextSubscription?.[ROTA_SHIFTS_SETTINGS]?.[
            WEB_PERMISSION
          ] ||
            props.contextSubscription?.[SHIFT_PATTERN_SETTINGS]?.[
            WEB_PERMISSION
            ] ||
            props.contextSubscription?.[EVV_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[SCHEDULER_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[REGULAR_VISITS]?.[WEB_PERMISSION]) &&
          (rolePermissions[ROTA_SHIFTS_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[SHIFT_PATTERN_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[EVV_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[SCHEDULER_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[REGULAR_VISITS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[ROTA_SHIFTS_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[SHIFT_PATTERN_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[EVV_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[SCHEDULER_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[REGULAR_VISITS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setCalender(true);
        }

        if (
          (props.contextSubscription?.[CLIENT_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[USER_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[TEAM_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[CALENDAR_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[COMPANY_SETTINGS]?.[WEB_PERMISSION] ||
            props.contextSubscription?.[SETTINGS_ALGO_SCORE]?.[
            WEB_PERMISSION
            ]) &&
          (rolePermissions[CLIENT_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[USER_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[TEAM_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[SETTINGS_ALGO_SCORE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ] ||
            rolePermissions[CLIENT_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[USER_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[TEAM_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ] ||
            rolePermissions[SETTINGS_ALGO_SCORE]?.[WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
            ])
        ) {
          setSettings(true);
        }

        // if (rolePermissions[USER_SETTINGS])
        // setSettings(
        //   rolePermissions[USER_SETTINGS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]
        // );

        // if (rolePermissions[TEAM_SETTINGS])
        //   setSettings(
        //     rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
        //       WEB_READ_PERMISSION
        //     ]
        //   );

        // if (rolePermissions[COMPANY_SETTINGS])
        // setSettings(
        //   rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
        //     WEB_READ_PERMISSION
        //   ]
        // );

        // if (rolePermissions[SETTINGS_ALGO_SCORE])
        //   setSettings(
        //     rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
        //       WEB_READ_PERMISSION
        //     ]
        //   );
      }
    }
  }, [
    email,
    props.contextSubscription,
    props.contextPermissions,
    props.contextCategoryPermissions,
    props.contextIsAdmin,
    props.contextIsSuperAdmin,
    props.contextSubscription,
  ]);

  useEffect(() => {
    if (props.contextIsAdmin == true || props.contextIsSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setReadPermissions(true);
      setCreateCalendarSettings(true);
      setDeleteCalendarSettings(true);
      setUpdateCalendarSettings(true);
      setReadCalendarSettings(true);
      setUpdatePermissionsBranchSettings(true);
      setCreatePermissionsBranchSettings(true);
      setDeletePermissionsBranchSettings(true);
      setReadPermissionsBranchSettings(true);
      setUpdatePermissionsPrioritySettings(true);
      setCreatePermissionsPrioritySettings(true);
      setDeletePermissionsPrioritySettings(true);
      setReadPermissionsPrioritySettings(true);
      setUpdatePermissionsRunRoutes(true);
      setCreatePermissionsRunRoutes(true);
      setDeletePermissionsRunRoutes(true);
      setReadPermissionsRunRoutes(true);
      setReadPermissionEmployeeAbsence(true)
    } else {
      const roles = props.contextPermissions;

      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name) {
            rolePermissions[item?.section?.name] = {};
            rolePermissions[item?.section?.name][WEB_PERMISSION] = {};
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
          }
        });

        const rolesPermissions = props.contextSubSectionPermissions;

        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
            if (item.section !== null) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] = item?.[SELF_WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_CREATE_PERMISSION
              ] = item?.[SELF_WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_DELETE_PERMISSION
              ] = item?.[SELF_WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_UPDATE_PERMISSION
              ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            }
          });
        }
        if (
          rolePermissions[COMPANY_SETTINGS] &&
          rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION]
        ) {
          setCreatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );
          setReadPermissions(
            props.contextSubscription?.[COMPANY_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        // Company Licenses Settings
        if (rolePermissions[COMPANY_LICENSE_SETTINGS]) {
          setCreatePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );
          setReadPermissionsCompanyLicenses(
            props.contextSubscription?.[COMPANY_LICENSE_SETTINGS]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[COMPANY_LICENSE_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        // Calendar Settings
        if (rolePermissions[CALENDAR_SETTINGS]) {
          setCreateCalendarSettings(
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeleteCalendarSettings(
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdateCalendarSettings(
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );

          setReadCalendarSettings(
            props.contextSubscription?.[CALENDAR_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[CALENDAR_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        //BranchSettings
        if (rolePermissions[BRANCH_SETTINGS]) {
          setCreatePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsBranchSettings(
            props.contextSubscription?.[BRANCH_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[BRANCH_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        //Priority Settings
        if (rolePermissions[PRIORITY_SETTINGS]) {
          setCreatePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsPrioritySettings(
            props.contextSubscription?.[PRIORITY_SETTINGS]?.[WEB_PERMISSION] &&
            (rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[PRIORITY_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }

        //RunRoute
        if (rolePermissions[RUN_ROUTE]) {
          setCreatePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
            rolePermissions[RUN_ROUTE][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsRunRoutes(
            props.contextSubscription?.[RUN_ROUTE]?.[WEB_PERMISSION] &&
            (rolePermissions[RUN_ROUTE][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[RUN_ROUTE][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }


        setReadPermissionEmployeeAbsence(props.contextSubscription?.[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_ABSENCE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]))
        );




      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.contextSubscription,
    props.contextPermissions,
    props.contextSubSectionPermissions,
    props.contextIsSuperAdmin,
    props.contextIsAdmin,
  ]);

  useEffect(() => {
    const roles = props.contextPermissions;
    const categoryPermission =  [...props.contextCategoryPermissions];
  
    let rolePermissions = {};

    if (props.contextIsAdmin == true || props.contextIsSuperAdmin == true) {
      setCreateRolesAndPermissions(true);
      setDeleteRolesAndPermissions(true);
      setUpdateRolesAndPermissions(true);
      setDefaultDashboardPermission({
        insight: true,
        teamKPI: true,
        clientKpi: true,
        visitKpi: true,
        medicationKpi: true,

        triage: true,

        listOfClient: true,
        visits: true,
        offerVisits: true,
        cancelledVisits: true,
        handback: true,
        swapVisits: true,
        qaTaskMatrix: true,

        rotaShifts: true,
        regularVisit: true,
        scheduler: true,
        medication: true,
        visitAudit: true,
        evv: true,
        esv: true,
        shiftPattern: true,

        
      })
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === ROLES_AND_PERMISSIONS_SETTINGS) {
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS] = {};
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION] =
              {};
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
          }

          if (item?.section?.name) {
            rolePermissions[item?.section?.name] = {};
            rolePermissions[item?.section?.name][WEB_PERMISSION] =
              {};
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
            rolePermissions[item?.section?.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
          }
        });


        const rolesPermissions = props.contextSubSectionPermissions;

        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
            if (item.section !== null) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] = item?.[SELF_WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_CREATE_PERMISSION
              ] = item?.[SELF_WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_DELETE_PERMISSION
              ] = item?.[SELF_WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_UPDATE_PERMISSION
              ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            }
          });
        }
        
        if (categoryPermission.length !== 0) {
          categoryPermission.forEach(role => {
            if (role.section !== null) {
              let name = role.section.name;
              if (name == TRIAGE_PERMISSION) {
                rolePermissions[name] = {};
                rolePermissions[name][WEB_PERMISSION] = {};
                rolePermissions[name][WEB_PERMISSION][WEB_PERMISSION_ALL] =
                  role[WEB_PERMISSION_ALL];
                rolePermissions[name][WEB_PERMISSION][WEB_READ_PERMISSION] =
                  role[WEB_READ_PERMISSION];
                rolePermissions[name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
                  role[SELF_WEB_READ_PERMISSION];
              }
            }
          });
        }

        const getPermission = key => {
          if (rolePermissions[key]) {
            let permission = props?.contextSubscription?.[key]?.[WEB_PERMISSION] &&
              (rolePermissions[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                rolePermissions[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION
                ])
            return permission || false
          }
        }

        setDefaultDashboardPermission({
          insight: getPermission(INSIGHTS),
          teamKPI: getPermission(TEAM_KPI_PERMISSION),
          clientKpi: getPermission(CLIENT_KPI),
          visitKpi: getPermission(VISIT_COMPLIANCE_KPI),
          medicationKpi: getPermission(MEDICATION_KPI),

          triage: getPermission(TRIAGE_PERMISSION),

          listOfClient: getPermission(LIST_OF_CLIENT_SETTINGS),
          visits: getPermission(CLIENT_VISITS),
          offerVisits: getPermission(CLIENT_OFFER_VISITS),
          cancelledVisits: getPermission(CLIENT_CANELLED_VISITS),
          handback: getPermission(CLIENT_HANDBACK),
          swapVisits: getPermission(CLIENT_SWAP_VISIT),
          qaTaskMatrix: getPermission(CLIENT_QA_TASK_MATRIX),

          rotaShifts: getPermission(ROTA_SHIFTS_SETTINGS),
          regularVisit: getPermission(REGULAR_VISITS),
          scheduler: getPermission(SCHEDULER_SETTINGS),
          medication: getPermission(CLIENT_MEDICATION),
          visitAudit: getPermission(CLIENT_VISIT_AUDIT),
          evv: getPermission(EVV_SETTINGS),
          esv: getPermission(ESV_PERMISSION),
          shiftPattern: getPermission(SHIFT_PATTERN_SETTINGS),

          
        })

        if (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS]) {
          setCreateRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
            ]
          );

          setDeleteRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            SELF_WEB_DELETE_PERMISSION
            ]
          );
          setUpdateRolesAndPermissions(
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
            ]
          );

          setReadRolesAndPermissions(
            props.contextSubscription?.[ROLES_AND_PERMISSIONS_SETTINGS]?.[
            WEB_PERMISSION
            ] &&
            (rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
              ])
          );
        }
      }
    }
  }, [
    props.contextSubscription,
    props.contextPermissions,
    props.contextIsSuperAdmin,
    props.contextIsAdmin,
  ]);

  const handleClick = () => {
    props.changeWidth(!decreaseWidth);
  };

  const signOutAndRedirect = async () => {
    await logout(getUserName(), getEmail());
    localStorage.clear();
    props.history.push(AUTH + LOGIN);
  };

  const handelCalenderListValue = () => {
    const defaultValuesWithRota = [
      {
        to: ROTA_SHIFTS,
        name: 'Rota - Shifts',
        label: 'Rota - Shifts',
      },
      {
        to: REGULAR_VISIT,
        name: 'Regular Visits',
        label: 'Regular Visits',
      },
      {
        to: SCHEDULER,
        name: 'Scheduler',
        label: 'Scheduler',
      },
      {
        to: MEDICATION,
        name: 'Medication',
        label: 'Medication (Beta)',
      },
      {
        to: VISIT_AUDIT,
        name: 'Visit Audit',
        label: 'Visit Audit',
      },
      {
        to: EVV + EVV_VISITS,
        name: 'EVV',
        label: 'EVV',
      },
      {
        to: ESV + EVV_VISITS,
        name: 'ESV',
        label: 'ESV',
      },
      {
        to: SHIFT_PATTERNS,
        name: 'Shift - patterns',
        label: 'Shift - patterns',
      },
    ];

    const defaultValuesWithoutRota = [
      {
        to: REGULAR_VISIT,
        name: 'Regular Visits',
        label: 'Regular Visits',
      },
      {
        to: SCHEDULER,
        name: 'Scheduler',
        label: 'Scheduler',
      },
    ];
    const defaultValues =
      userClient === 'null' ? defaultValuesWithRota : defaultValuesWithoutRota;
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
        (props.contextSubscription?.[item.name]?.[WEB_PERMISSION] &&
          (permissions[item.name]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.name]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ]))
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        // item => (item.name === 'ESV' || item.name === 'Medication (Beta)') ? true : props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
        item => props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
  };
  const handelSettingListValue = () => {
    const defaultValues = [
      {
        to: SETTINGS + COMPANY + COMPANY_ORGANIZATION,
        name: 'Company',
      },
      {
        to: SETTINGS + SETTINGS_TEAM,
        name: 'Team',
      },
      {
        to: SETTINGS + SETTINGS_CLIENT,
        name: 'Client',
      },
      {
        to: SETTINGS + USERS,
        name: 'Users',
      },

      {
        to: SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS,
        name: 'Roles and permissions',
      },
      {
        to: SETTINGS + SETTINGS_ORGANIZATIONS,
        name: 'Organizations',
      },
      // {
      //   to: SETTINGS + SETTINGS_ALGOSCORE,
      //   name: 'Score',
      // },
    ];
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
          permissions[`${item.name} Settings`]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          permissions[`${item.name} Settings`]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ]
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        item => props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
    // return defaultValues;
  };
  const settingListValues = () => {
    let listValues = [

      props.contextIsSuperAdmin ||
        props.contextIsAdmin ||

        (readPermission || deletePermission || create || update)
        ? {
          to: SETTINGS + COMPANY + COMPANY_ORGANIZATION,
          name: 'Company Settings',
          label: ' Settings Company'

        }
        :
        props.contextIsSuperAdmin ||
          props.contextIsAdmin ||

          (readPermissionBranchSettings ||
            deletePermissionBranchSettings ||
            createBranchSettings ||
            updateBranchSettings)
          ? {
            to: SETTINGS + COMPANY + COMPANY_BRANCHES,
            name: 'Company Settings',
            label: ' Settings Company'

          }
          :
          props.contextIsSuperAdmin ||
            props.contextIsAdmin ||

            (updatePrioritySettings ||
              createPrioritySettings ||
              deletePermissionPrioritySettings ||
              readPermissionPrioritySettings)
            ? {
              to: SETTINGS + COMPANY + COMPANY_PRIORITY,
              name: 'Company Settings',
              label: 'Company Settings'

            }
            : {},
      {
        to: SETTINGS + SETTINGS_TEAM,
        name: 'Team Settings',
        label: 'Team Settings'
      },
      {
        to: SETTINGS + HR_TASKS,
        name: 'Setting QA-Task',
        label: 'Settings QA Tasks (Beta)'
      },
      {
        to: SETTINGS + SETTINGS_CLIENT + FUNDER,
        name: 'Client Settings',
        label: 'Client Settings'

      },
      {
        to: SETTINGS + USERS,
        name: 'Users Settings',
        label: 'Users Settings',

      },

      {
        to: SETTINGS + SETTINGS_CALENDAR + CALENDAR_DATA,
        name: 'Calendar Settings',
        label: 'Calendar Settings',
      },
      {
        to: SETTINGS + SETTINGS_FORM_BUILDER + SETTINGS_CHOICES,
        name: 'Form Builder Settings',
        label: 'Form Builder Settings',

      },
      {
        to: SETTINGS + SETTINGS_EVV,
        name: 'Settings Evv',
        label: 'Settings EVV',
      },

      {
        to: SETTINGS + SETTINGS_ESV,
        name: 'Settings Esv',
        label: 'Settings ESV',
      },
      {
        to: SETTINGS + SETTINGS_BRADFORD,
        name: 'Bradford Factor Settings',
        label: 'Bradford Factor Settings',
      },
      {
        to: SETTINGS + SETTINGS_EMAIL,
        name: 'Email Settings',
        // name: 'Bradford Factor Settings',
        label: 'Email History',
      },

      {
        to: SETTINGS + SETTINGS_TRIAGE,
        // name: 'Settings Alert',
        name: 'Settings Triage',
        label: 'Settings Triage',

      },
      {
        to: SETTINGS + SETTINGS_FINANCE,
        name: 'Settings Finance',
        label: 'Settings Finance',

      },

      {
        to: SETTINGS + SETTINGS_RATECARD + TIMESOURCE,
        name: 'Rate Card',
        label: 'Rate Card',

      },
      {
        to: SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_NEWFEILD,
        name: 'Algo Score',
        label: 'Algo Score',

      },
      {
        to: SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS,
        name: 'Roles and permissions Settings',
        label: 'Roles and permissions Settings',

      },
      {
        to: SETTINGS + SETTINGS_ORGANIZATIONS,
        name: 'Organizations',
        label: 'Organizations',

      },
      {
        to: SETTINGS + SETTINGS_SUBSCRIPTION,
        name: 'Subscription',
        label: 'Subscription',

      },
      {
        to: SETTINGS + SETTINGS_LOGIN_ROUTE,
        name: 'Settings Login',
        label: 'Settings Login',

      },
    ];
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = listValues.filter(
        item =>
          props.contextSubscription?.[item.name]?.[WEB_PERMISSION] &&
          (permissions[item.name]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.name]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );
      // values.push(    {
      //   to: SETTINGS + SETTINGS_ALERT,
      //   name: 'Settings Alert',
      // } )
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = listValues.filter(
        item => (item.name === 'Settings Esv' || item.name === 'Form Builder Settings') ? true : props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
      );
      // values_admin.push({
      //   to: SETTINGS + SETTINGS_TRIAGE,
      //   name: 'Settings Triage',
      // });
      return values_admin;
    } else {
      return listValues;
    }
    //return listValues.filter(list => list.name)
  };
  const reportListValues = () => {
    let listValues = [
      {
        to: REPORT + REPORT_TEAM + PERSONAL_DETAILS,
        name: 'Reports Team',
      },
      {
        to: REPORT + REPORT_CLIENT + CLIENT_DETAILS,
        name: 'Reports Client',
      },

      {
        to: REPORT + REPORT_KPI + KPI_DAILY_DETAILS,
        name: 'Reports KPI',
      },

    ];

    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = listValues.filter(
        item =>
          props.contextSubscription?.[item.name]?.[WEB_PERMISSION] &&
          (permissions[item.name]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.name]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );

      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = listValues.filter(
        item => props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
      );

      return values_admin;
    } else {
      return listValues;
    }
    //return listValues.filter(list=>list.name)
  };
  const financeListValues = () => {
    let listValues = [
      //      (  (  (userEmployee === 'null'  ) && ( userClient === 'null' )  )  ||  (  (props.contextIsSuperAdmin == true ) ||( props.contextIsAdmin === 'true' ) || (  props.contextIsStaff === 'true'  ) )   ) &&
      {
        to: FINANCE + BILLING + SUMMARY,
        name: 'Billing',
      },
      //      (  (  (userEmployee === 'null'  ) && ( userClient === 'null' )  )  ||  (  (props.contextIsSuperAdmin == true ) ||( props.contextIsAdmin === 'true' ) || (  props.contextIsStaff === 'true'  ) )   ) &&
      {
        to: FINANCE + PAYROLL + SUMMARY,
        name: 'Payroll',
      },
    ];
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = listValues.filter(
        item =>
          permissions[item.name]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          permissions[item.name]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
      );
      return values.filter(list => list.name);
    } else if (props.contextIsAdmin) {
      let values_admin = listValues.filter(
        item => props.contextSubscription?.[item.name]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return listValues.filter(list => list.name);
    }
  };

  // const alertsListValues = () => {
  //   let listValues = [
  //     // {
  //     //   to: FINANCE + BILLING + SUMMARY,
  //     //   name: 'Billing',
  //     // },
  //     {
  //       to: ALERTS_FEATURES,
  //       name: 'Alerts',
  //     },
  //   ]
  //   if (!_.isEmpty(permissions)) {
  //     let values = listValues.filter(
  //       item =>
  //         permissions[item.name]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
  //         permissions[item.name]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
  //     );
  //     return values.filter(list => list.name);
  //   } else {
  //     return listValues.filter(list => list.name);
  //   }
  // }
  const handleTriageValue = () => {
    let defaultValues = [{
      to: TRIAGE,
      permission: 'Triage',
      name: 'Triage',
    }
    ]
  }

  const handelClientListValue = () => {
    let defaultValues = [
      {
        to: CLIENT,
        permission: 'List of Clients',
        name: 'List of Clients',
      },
      {
        to: VISITS,
        permission: 'Client Contracted Visits',
        name: 'Visits',
      },
      {
        to: CANCELLED_VISITS,
        permission: 'Client Cancelled Visits',
        name: 'Cancelled Visits',
      },
      {
        to: OFFER_VISITS,
        permission: 'Offer Visit',
        name: 'Offer Visit',
      },
      {
        to: HANDBACK_REQUEST,
        name: 'Handback Request',
        permission: 'Client Hand Back',

      },
      {
        to: SWAP_VISIT,
        name: 'Swap Visit',
        permission: 'Client Swap Visit',

      },
      {
        to: CLIENT + CLIENT_HR_TASK_MATRIX,
        name: 'QA Task Matrix (Beta)',
        permission: 'Client QA Task Matrix',
      }
    ];
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
          props.contextSubscription?.[item.permission]?.[WEB_PERMISSION] &&
          (permissions[item.permission]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ] ||
            permissions[item.permission]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        item => (item.permission === 'offer visit') ? true : props.contextSubscription?.[item.permission]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
  };
  const handelEmployeeListValue = () => {
    let defaultValues = [
      {
        to: TEAM,
        name: 'List of Employees',
        permissions: 'List of Employees'
      },
      {
        to: TEAM_ROUTE_MAPPING,
        name: 'Team Route Mapping',
        permissions: 'Team Route Mapping',
      },
      {
        to: '/absence-history-list',
        name: 'Absence (Beta)',
        permissions: 'Employee Absence'
      },
      {
        to: BRADFORD_ROUTE,
        name: 'Bradford',
        permissions: 'Employee Absence'
      },
      {
        to: '/swap-shift',
        name: 'Swap Shift',
        permissions: 'Employee Absence',
      },
      {
        to: TEAM + TEAM_SKILL_MATRIX,
        name: 'Skill Matrix (Beta)',
        permissions: 'Employee Skill Matrix',
      },
      {
        to: TEAM + TEAM_COMPETENCY_MATRIX,
        name: 'Competency Matrix (Beta)',
        permissions: 'Employee Competency Matrix'
      },
      {
        to: TEAM + TEAM_HR_TASK_MATRIX,
        name: 'QA Task Matrix (Beta)',
        permissions: 'Employee QA Task Matrix'
      }
    ];

    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
          props.contextSubscription?.[item.permissions]?.[WEB_PERMISSION] &&
          (permissions[item.permissions]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.permissions]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        item => props.contextSubscription?.[item.permissions]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
  };

  const handelDasboardListValue = () => {
    let defaultValues = [
      {
        to: DASHBOARDS,
        name: 'Insights',
        permission: 'Insights'
      },
      {
        to: DASHBOARD_TEAM_KPI,
        name: 'Team KPI',
        permission: 'Team Kpi',
      },
      {
        to: DASHBOARD_CLIENT_KPI,
        name: 'Client KPI',
        permission: 'Client Kpi',
      },
      {
        to: DASHBOARD_VISIT_KPI,
        name: 'Visit KPI',
        permission: 'Visit - Compliance Kpi',
      },
      {
        to: DASHBOARD_MEDICATION_KPI,
        name: 'Medication KPI',
        permission: 'Quality Assurance Kpi',
      }
    ];
    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
          props.contextSubscription?.[item.permission]?.[WEB_PERMISSION] &&
          (permissions[item.permission]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.permission]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        item => props.contextSubscription?.[item.permission]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
  };

  const handelLocationListValue = () => {
    let defaultValues = [
      {
        to: LOCATION,
        name: 'List of Locations',
        permissions: LIST_OF_LOCATIONS
      },
    ];

    if (
      !props.contextIsAdmin &&
      !props.contextIsSuperAdmin &&
      !_.isEmpty(permissions)
    ) {
      let values = defaultValues.filter(
        item =>
          props.contextSubscription?.[item.permissions]?.[WEB_PERMISSION] &&
          (permissions[item.permissions]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
            permissions[item.permissions]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
      );
      return values;
    } else if (props.contextIsAdmin) {
      let values_admin = defaultValues.filter(
        item => props.contextSubscription?.[item.permissions]?.[WEB_PERMISSION]
      );
      return values_admin;
    } else {
      return defaultValues;
    }
  };

  return (
    <SideBarContainer decreaseWidth={decreaseWidth} className="sidebar p-0">
      <SideBar className="sidebar-sticky">
        <StaticArrow decreaseWidth={decreaseWidth} onClick={handleClick}>
          <div>
            {decreaseWidth ? (
              <IoIosArrowForward size={18} color={PURPLE} />
            ) : (
              <IoIosArrowBack size={18} color={PURPLE} />
            )}
          </div>
        </StaticArrow>
        <UserContainer decreaseWidth={decreaseWidth}>
          <UserImgContainer decreaseWidth={decreaseWidth}>
            <UserImgHalo decreaseWidth={decreaseWidth}>
              {/* TODO: add correct name, last name and img src */}
              <ImageComponent>
                <Image
                  src={''}
                  name={firstName}
                  lastName={lastName}
                  alt={'user'}
                  fontSize={decreaseWidth ? '1rem' : '2rem'}
                />
              </ImageComponent>
            </UserImgHalo>
          </UserImgContainer>
          <div>
            <UserName className={'nameStyle'}>
              {firstName + ' ' + lastName}
            </UserName>
            <UserEmail className={'emailStyle'}>{email}</UserEmail>
            {(props.contextIsStaff ||
              props.contextIsAdmin ||
              props.contextIsSuperAdmin) && (
                <UserEmail className={'emailStyle'}>
                  {props.contextIsSuperAdmin
                    ? 'SUPER ADMIN'
                    : props.contextIsAdmin
                      ? 'ADMIN'
                      : props.contextIsStaff
                        ? 'STAFF'
                        : ''}
                </UserEmail>
              )}
          </div>
        </UserContainer>
        <UlStyled
          decreaseWidth={decreaseWidth}
          className="nav flex-column nav-pills">
          <FadeTopUl />
          {/* {dashboardPermission && (
            <Li
              to={DASHBOARDS}
              name="Dashboards"
              showMenu={props.showMenu}
              decreaseWidth={decreaseWidth}
              propsLocationName={props.locationName}>
              <img
                src={
                  props.locationName === DASHBOARDS
                    ? dashboard_in
                    : dashboard_out
                }
                width={17}
                height={17}
                alt="dashboardImg"
              />
            </Li>
          )} */}
          {dashboardPermission && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName === DASHBOARDS}
              dropdownName={'Dashboards'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={handelDasboardListValue()}>
              <img
                src={
                  props.locationName === DASHBOARDS
                    ? dashboard_in
                    : dashboard_out
                }
                width={17}
                height={17}
                alt=""
              />
            </DropdownMenu>
          )}
          {triagePermission && (
            <Li
              to={TRIAGE}
              // name="ALERTS "
              name="Triage "
              showMenu={props.showMenu}
              decreaseWidth={decreaseWidth}
              propsLocationName={props.locationName}>
              <img
                src={
                  props.locationName === TRIAGE ? triage_in : triage_out
                }
                width={17}
                height={17}
                alt=""
              />
            </Li>)
          }
          {team && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName === TEAM || props.locationName.includes("team/add") || props.locationName.includes("team/edit")}
              dropdownName={'Team'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={handelEmployeeListValue()}>
              <img
                src={props.locationName === TEAM || props.locationName.includes("team/add") || props.locationName.includes("team/edit") ? team_in : team_out}
                width={18}
                height={18}
                alt=""
              />
            </DropdownMenu>
          )}
          {/* Added location section */}
          {location && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName.includes(LOCATION)}
              dropdownName={'Locations'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={handelLocationListValue()}>
              <img
                src={props.locationName === LOCATION ? location_in : location_in}
                width={18}
                height={18}
                alt=""
              />
            </DropdownMenu>
          )}
          {client && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName === CLIENT || props.locationName.includes("client/add") || props.locationName.includes("client/edit") }
              dropdownName={'Clients'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={handelClientListValue()}>
              <img
                src={props.locationName === CLIENT || props.locationName.includes("client/add") || props.locationName.includes("client/edit")  ? client_in : client_out}
                width={17}
                height={17}
                alt=""
              />
            </DropdownMenu>
          )}

          {calender && (
            <DropdownMenu
              propsLocationName={props.locationName}
              dropdownName={'Calendar'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              active={[ROTA_SHIFTS, SCHEDULER, EVV, VISIT_AUDIT, SHIFT_PATTERNS, REGULAR_VISIT].some(item => props.locationName.includes(item))}
              Li={Li}
              listValues={handelCalenderListValue()}>
              <img
                src={
                  props.locationName.includes(ROTA_SHIFTS) ||
                    props.locationName.includes(SCHEDULER) ||
                    props.locationName === EVV + EVV_VISITS ||
                    props.locationName === VISIT_AUDIT ||
                    props.locationName === REGULAR_VISIT ||
                    props.locationName === SHIFT_PATTERNS
                    ? calendar_in
                    : calendar_out
                }
                width={17}
                height={17}
                alt=""
              />
            </DropdownMenu>
          )}

          {formBuilderPermission && <Li
            to={FORM_BUILDER}
            name="Form builder"
            showMenu={props.showMenu}
            decreaseWidth={decreaseWidth}
            active={props.locationName === "/add-form"}
            propsLocationName={props.locationName}>
            <img
              src={form_build}
              width={17}
              height={17}
              alt=""
            />
          </Li>}
          {report && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName.includes(REPORT + REPORT_TEAM) ||
                props.locationName.includes(REPORT + REPORT_CLIENT) ||
                props.locationName.includes(REPORT + REPORT_KPI)}
              dropdownName={'Reports'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={reportListValues()}>
              <img
                src={
                  props.locationName.includes(REPORT + REPORT_TEAM) ||
                    props.locationName.includes(REPORT + REPORT_CLIENT) ||
                    props.locationName.includes(REPORT + REPORT_KPI)
                    ? report_in
                    : report_out
                }
                width={17}
                height={17}
                alt=""
              />
            </DropdownMenu>
          )}
          {finance && (
            <DropdownMenu
              propsLocationName={props.locationName}
              dropdownName={'Finance'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              active={props.locationName === FINANCE || props.locationName.includes(FINANCE + BILLING) || props.locationName.includes(FINANCE + PAYROLL)}
              listValues={financeListValues()}>
              <img
                src={
                  props.locationName.includes(FINANCE + BILLING) ||
                    props.locationName.includes(FINANCE + PAYROLL)
                    ? finance_in
                    : finance_out
                }
                width={17}
                height={17}
                alt=""
              />
            </DropdownMenu>
          )}
          {(props.contextOrganization && (props.contextOrganization.length > 1) && userEmployee) &&
            <Li
              to={LINKED_USER}
              // name="ALERTS "
              name="Organizations"
              showMenu={props.showMenu}
              decreaseWidth={decreaseWidth}
              propsLocationName={props.locationName}>
              <img
                src={
                  props.locationName === LINKED_USER ? client_in : client_out
                }
                width={17}
                height={17}
                alt=""
              />
            </Li>}
          {/* )
          } */}

          {invoicePermission && (
            <Li
              to={INVOICE}
              // name="ALERTS "
              name="Invoice"
              showMenu={props.showMenu}
              decreaseWidth={decreaseWidth}
              propsLocationName={props.locationName}>
              <img
                src={
                  props.locationName === INVOICE ? report_in
                    : report_out
                }
                width={17}
                height={17}
                alt=""
              />
            </Li>)
          }

          {settings && (
            <DropdownMenu
              propsLocationName={props.locationName}
              active={props.locationName.includes(SETTINGS + COMPANY) ||
                props.locationName.includes(SETTINGS + SETTINGS_TEAM) ||
                props.locationName.includes(SETTINGS + SETTINGS_CLIENT) ||
                props.locationName.includes(SETTINGS + USERS) ||
                props.locationName.includes(
                  SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS
                ) ||
                props.locationName.includes(
                  SETTINGS + SETTINGS_ORGANIZATIONS
                ) ||
                props.locationName.includes(SETTINGS + SETTINGS_ALGOSCORE) ||
                props.locationName.includes(SETTINGS + SETTINGS_EVV) ||
                props.locationName.includes(SETTINGS + SETTINGS_ESV) ||
                props.locationName.includes(SETTINGS + SETTINGS_TRIAGE) ||
                props.locationName.includes(SETTINGS + SETTINGS_FINANCE) ||
                props.locationName.includes(SETTINGS + SETTINGS_CALENDAR) ||
                props.locationName.includes(SETTINGS + SETTINGS_BRADFORD)}
              dropdownName={'Settings'}
              showMenu={props.showMenu}
              handleClick={handleClick}
              decreaseWidth={decreaseWidth}
              Li={Li}
              listValues={settingListValues()}
              isSettings={true}>
              <img
                src={
                  props.locationName.includes(SETTINGS + COMPANY) ||
                    props.locationName.includes(SETTINGS + SETTINGS_TEAM) ||
                    props.locationName.includes(SETTINGS + SETTINGS_CLIENT) ||
                    props.locationName.includes(SETTINGS + USERS) ||
                    props.locationName.includes(
                      SETTINGS + SETTINGS_ROLES_AND_PERMISSIONS
                    ) ||
                    props.locationName.includes(
                      SETTINGS + SETTINGS_ORGANIZATIONS
                    ) ||
                    props.locationName.includes(SETTINGS + SETTINGS_ALGOSCORE) ||
                    props.locationName.includes(SETTINGS + SETTINGS_EVV) ||
                    props.locationName.includes(SETTINGS + SETTINGS_ESV) ||
                    props.locationName.includes(SETTINGS + SETTINGS_TRIAGE) ||
                    props.locationName.includes(SETTINGS + SETTINGS_FINANCE) ||
                    props.locationName.includes(SETTINGS + SETTINGS_CALENDAR) ||
                    props.locationName.includes(SETTINGS + SETTINGS_BRADFORD)
                    ? settings_in
                    : settings_out
                }
                width={18}
                height={18}
                alt=""
              />
            </DropdownMenu>
          )}

          <FadeBottomUl />
        </UlStyled>

        <SignOutContainer
          decreaseWidth={decreaseWidth}
          onClick={signOutAndRedirect}>
          <IoIosLogOut />
          <label>Sign Out</label>
        </SignOutContainer>
      </SideBar>
    </SideBarContainer>
  );
}

const Li = ({
  to,
  name,
  children,
  onMouseEnter,
  active,
  propsLocationName,
  subItem = null,
  showMenu = () => { },
  decreaseWidth = false,
}) => {
  let validateSelect = propsLocationName === to || active;

  return (
    <SideBarItem
      subItem={subItem}
      active={validateSelect}
      className={`nav-item m-0 w-100`}
      onMouseEnter={onMouseEnter}
      onClick={showMenu}
      decreaseWidth={decreaseWidth}>
      <Link to={to} className={`nav-link d-flex ${subItem ? 'subItem' : ''}`}>
        <div>{children}</div>
        <SideBarItemLabel
          subItem={subItem}
          active={validateSelect}
          className={`align-self-center ${decreaseWidth ? 'hideItemLabel' : ''
            }`}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}>
          {name}
        </SideBarItemLabel>
      </Link>
    </SideBarItem>
  );
};

export default withRouter(LeftSidebar);
