import {
    LARGE_COL,
    SMALL_COL
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
      label: 'PHOTO',
      name: 'photo',
      status: 0,
      className: 'grow-mobile md-hidden sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'CONTACT TYPE',
      name: 'contact_type',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
  
    {
      label: 'NOK',
      name: 'nok',
      status: 0,
      className: 'grow-mobile md-hidden sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'LASTING POWER OF ATTORNEY',
      name: 'power_att',
      status: 0,
      className: 'grow-mobile md-hidden sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'NAME',
      name: 'name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'RELATIONSHIP',
      name: 'relation',
      status: 0,
      className: 'grow-mobile md-hidden sm-hidden',
      max_width: LARGE_COL,
    },
    {
        label: 'CONTACT STATUS',
        name: 'con_status',
        status: 0,
        className: 'grow-mobile md-hidden sm-hidden',
        max_width: LARGE_COL,
      },
      {
        label: 'EMAIL',
        name: 'mail',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: LARGE_COL,
      },
      {
        label: 'TELEPHONE',
        name: 'phone',
        status: 0,
        className: 'grow-mobile md-hidden sm-hidden',
        max_width: LARGE_COL,
      },
      {
        label: 'USER ROLE',
        name: 'role',
        status: 0,
        className: 'grow-mobile md-hidden sm-hidden',
        max_width: LARGE_COL,
      },
      {
        label: 'Rota Email',
        name: 'role',
        status: 0,
        className: 'grow-mobile md-hidden sm-hidden',
        max_width: LARGE_COL,
      },
    
  ];
  