import React, { Fragment, useState, useEffect } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { setNotificaationRule, deleteNotificaationRule } from 'utils/api/AlertsApi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiSave } from 'react-icons/bi';
import { MdSmsFailed } from 'react-icons/md';
import { getNotificationData } from 'utils/api/AlertsApi';
import { KeyPress } from 'utils/constants/regularExpressions';
const NotificationRule = ({
  data,
  index,
  useInput,
  setValues,
  values,
  idSelected,
  setCheckRequires,
  setShowError,
  setErrorMessage,
}) => {
  const [textStatus, setTextStatus] = useState(<IconWrapper><BiSave color="blue" /> </IconWrapper>);
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [errorTextMessage, setErrorTextMessage] = useState('');

  useEffect(() => {
    if (values.notificationrule[index].id) {
      if (values.notificationrule[index].email == true || values.notificationrule[index].app == true) {
        setErrorTextMessage('');
      } else {
        setErrorTextMessage('alteast one chekbox must be checked ');
      }
    }

  }, [
    values.notificationrule[index].email,
    values.notificationrule[index].app,
    values.notificationrule[index].id
  ])

  const dropDownHandleSelect = (item, index, nameItem) => {
    values.notificationrule[index][nameItem] = item[nameItem];
    setValues({ ...values, ...item });
  };

  const updateNotificationRule = async () => {
    const response = await getNotificationData(idSelected);
    if (response) {
      values.notificationrule = response.results;
      setValues({ ...values })

    }
  }

  const handleSelectChange = (e, data) => {
    if (data === 'app') {
      values.notificationrule[index].app = !values.notificationrule[index].app;
    }

    if (data === 'email') {
      values.notificationrule[index].email = !values.notificationrule[index].email;
    }
    setValues({ ...values });
  }



  const handleSubmit = (arrIndex) => {

    if (loadingSave) {
      return;
    }

    if (values.notificationrule[arrIndex].email == false && values.notificationrule[arrIndex].app == false) {
      onError('alteast one chekbox must be checked ');
    } else {
      const valuesId = values.notificationrule[arrIndex].id ? values.notificationrule[arrIndex].id : null;
      const bodyData = {
        alert: idSelected ? idSelected : '',
        app: values.notificationrule[arrIndex].app,
        email: values.notificationrule[arrIndex].email,
        every: values.notificationrule[arrIndex].every ? values.notificationrule[arrIndex].every : '',
        value: values.notificationrule[arrIndex].value ? values.notificationrule[index].value : '',
      }

      setLoadingSave(true);
      setNotificaationRule(bodyData, valuesId)
        .then(response => {
          if (response.statusCode == 200 || response.statusCode == 201) {
            if (response.id) {
              values.notificationrule[arrIndex].id = response.id;
              setValues({ ...values })
            }
            onSuccess(response);
          }
          else {
            onError('Something went wrong !!');
          }
        })
        .catch(onError);
    }
  }

  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(<IconWrapper><BiSave color="blue" /></IconWrapper>);
    updateNotificationRule();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!'
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(<IconWrapper><MdSmsFailed color="red" /></IconWrapper>);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage || 'something went wrong !!');
    setErrorTextMessage(getErrorMessage || 'something went wrong !!')
  };


  const deleteNewnotificationrule = async (data, index) => {
    let individualId = values.notificationrule[index].id
    await deleteNotificaationRule(individualId);
    // if(values.notificationrule.length >0){
    //   values.notificationrule.splice(index,1);
    //   setValues({...values})
    // }
    //     const response = await getNotificationData(idSelected);
    // if(response){
    //   values.notificationrule=response.results;
    //   setValues({...values})

    // }

    updateNotificationRule();

  }


  return (
    <div>
      <div key={data.id} className={' mb-2 '}>
        <div className='d-flex'>
          <Fragment />

          <div className={'w-100 p-0 d-flex'}>
            <FlexGrid3Container className={'w-100 mb-2'}>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'20%'}>
                <DropdownSearchable
                  placeHolder={'Repeat Every'}
                  options={[
                    { id: 'Minute', name: 'Minute' },
                    { id: 'Hour', name: 'Hour' },
                    { id: 'Day', name: 'Day' },
                    { id: 'Week', name: 'Week' },
                    { id: 'Month', name: 'Month' },
                    { id: 'Year', name: 'Year' },
                  ]}
                  selectedOption={values.notificationrule[index].every}
                  onChange={value =>
                    dropDownHandleSelect({ every: value ? value : null }, index, 'every')}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'20%'}>
                <InputLabelTop
                  id="value"
                  label={'Repeat no.'}
                  onKeyPress={values.notificationrule[index].value ? KeyPress.POSITIVE : KeyPress.NATURAL}
                  //  value={values.notificationrule[index].value}
                  // {...notificationruleValue}
                  {...useInput(`notificationrule[${index}].value`)}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'20%'}>
                <div className='d-flex'>
                  <div className="ml-1 mt-1">
                    <input
                      type="checkbox"
                      checked={values.notificationrule[index].app}
                      onChange={(e) => { handleSelectChange(e, 'app') }}
                    />

                  </div>
                  <div className="ml-1 mt-1"><p>In app notification</p></div>
                </div>
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'20%'}>
                <div className='d-flex'>
                  <div className="ml-1 mt-1">
                    <input
                      type="checkbox"
                      checked={values.notificationrule[index].email}
                      onChange={(e) => { handleSelectChange(e, 'email') }}
                    />

                  </div>
                  <div className="ml-1 pl-1 mt-1"><p>Email notification</p></div>
                </div>
              </FlexGrid3Item>
            </FlexGrid3Container>
            <div className="d-flex flex-row mt-1" >

              <div className={'ml-1'} height="2.5rem" onClick={() => { handleSubmit(index) }}>
                {!loadingSave ? (
                  <span className={'mr-2'}>{textStatus}</span>
                ) : (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
              </div>
              <div>
                <IconWrapper onClick={() => {
                  deleteNewnotificationrule(data, index);
                }}>
                  <RiDeleteBinLine color="red" />
                </IconWrapper>
              </div>
            </div>
          </div>
        </div>
        <small style={{ color: 'red' }}>{errorTextMessage}</small>
      </div>

    </div>
  )
}

export default NotificationRule