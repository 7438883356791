import { Switch, TextField, FormControl, Select, MenuItem, InputLabel  } from '@mui/material'
import React from 'react'

export default function TimePickerComp({ json, setJson }) {
    return (
        <div style={{ display: "flex", justifyContent: "center",flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Time label"
                defaultValue={"Label"}
                className='mb-2'
                value={json.timePicker.label}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    // console.log(event.target.value)
                    setJson({
                        ...json, timePicker: {
                            ...json.timePicker,
                            label: event.target.value
                        }
                    })
                }}
            />
             <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.timePicker.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, timePicker: {
                                ...json.timePicker,
                                description: event.target.value
                            }
                        })
                    }}
                />
                 <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.timePicker.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, timePicker: {
                                ...json.timePicker,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel id="time-format">Time Format</InputLabel>
                    <Select
                        labelId="time-format"
                        id="time-format-id"
                        value={json.timePicker.format}
                        label="Time Format"
                        onChange={(event) => {
                            setJson({
                                ...json, timePicker: {
                                    ...json.timePicker,
                                    format: event.target.value
                                }
                            })
                        }}
                    >
                        <MenuItem value={""}>System Format</MenuItem>
                        <MenuItem value={"hh:mm a"}>12 hours</MenuItem>
                        <MenuItem value={"HH:mm"}>24 hours</MenuItem>
                    </Select>
                </FormControl>
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.timePicker.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, timePicker: {
                                                ...json.timePicker,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.timePicker.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, timePicker: {
                                                ...json.timePicker,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.timePicker.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, timePicker: {
                                                ...json.timePicker,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.timePicker.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.timePicker.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, timePicker: {
                                ...json.timePicker,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}
