import { ERR_COLOR, GRAY_700, PURPLE } from "shared/styles/constants/colors";
import styled from "styled-components";

export const ChangePwdStyle = styled.div`
 
  span {
    z-index: 1;
    position: relative;
    top: 0.12rem;
    right: 30px;
    color: ${GRAY_700};
    cursor: pointer;
    font-size: 1.2rem;
  }

  span:active {
    color: ${PURPLE};
  }
`;

export const InputFieldChangePwd = styled.input`
  background: transparent !important;
  color: #0d0d0d;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  width: ${props => props.width ? props.width : '90%'};
  border: 0.125rem solid ${({error}) => (error ? `${ERR_COLOR}` : `${GRAY_700}`)};
  border-radius: 0.5rem;
    
  &:focus {
    outline: none;
    background-color: white;
    border-radius: 0.5rem;
    border: 0.125rem solid ${PURPLE};
  }
`;