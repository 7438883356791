import React from 'react';
import DatePicker from 'react-datepicker';
import { DatePickerStyled, ErrorLabel, Icon } from './yearInput';
import { FaRegCalendar } from 'react-icons/fa';
import { createDateWithTime } from 'shared/methods';
import 'react-datepicker/dist/react-datepicker.css';

function CustomYearInput(props) {
    const nr_years = 3;
    let maxYearDate = new Date(
        new Date().setFullYear(new Date().getFullYear() + nr_years),
    );

    const _minDate = props.minDate
        ? createDateWithTime(props.minDate, '00:00:00')
        : '';
    const _maxDate = props.maxDate
        ? createDateWithTime(props.maxDate, '00:00:00')
        : '';
    let selectedValue = null;
    if (props.value) {
        selectedValue = new Date(props.value + 'T00:00:00');
    }

    return (
        <DatePickerStyled typeFilter={props.typeFilter}>
            <label>
                {props.labelTop} <span>{props.isRequired ? '*' : ''}</span>
            </label>
            <DatePicker
        className={
          props['data-error']
            ? 'error-border'
            : props.value
            ? 'value-border'
            : ''
        }
            PopupWidth="300px"
       dateFormat="yyyy"
       yearItemNumber={24}
    
                selected={selectedValue}
                onChange={date =>
                    props.onChange({
                      target: {
                        value: date,
                        type: 'date',
                        checked: '',
                        dataset: {idx: props.dataIdx},
                      },
                    })
                  }
                placeholderText="Click to select"
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                peekNextMonth
                showYearPicker={true}
            
                dropdownMode="select"
                disabled={props.disabled}
                isClearable={!props.disabled}
                maxDate={_maxDate}
                minDate={_minDate}
               
            />
              {props['data-error'] ? (
        <ErrorLabel>{props.errorMessage || 'Is required'}</ErrorLabel>
      ) : (
        <></>
      )}
           
                {/* <Icon style={{top:'1.25rem',right:'-11.5rem'}}>
                    <FaRegCalendar  />
                </Icon> */}
        </DatePickerStyled>
    );
}
export default CustomYearInput;
