import React, {Fragment,useContext,useEffect,useState} from 'react';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {PLUS, TIMES} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import {Header, Title,TitleSection} from 'shared/styles/constants/tagsStyles';
  import ToggleButton from 'components/SharedComponents/ToggleButton'; 
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { Verified } from '@mui/icons-material';

const EvvConfimation = ({
    index,
    values,
    setValues,
    data,
    addClockInToVisit,
    deleteAutoVerification,
    useInput,namesection
}) => {
  const dropDownHandleSelect=(item,nameitem,name,index)=>{
    values[name][index][nameitem]=item[nameitem]
    setValues({...values})
  }
  const handleDelete=(index)=>{
    values[index].clockTimeDistanceConfirmation.splice(index,1)
    setValues({...values})
  }
  const [optionList,setOptionList]=useState([{id:'Verified',name:'Verified'},{id:'Not Verified',name:'Not Verified'}])
  const [optionList2,setOptionList2]=useState([{id:'CONFIRMED',name:'CONFIRMED'},{id:'AUTO CONFIRMED',name:'AUTO CONFIRMED'},{id:'NOT CONFIRMED',name:'NOT CONFIRMED'}])
  return (
    <div>
     {/* className={`mt-1 ${index==0?'ml-2':''}`}        */}
        <>
        <div key={index}
         className={`mt-1`} style={{marginLeft:'15px',marginRight:'20px'}}>
               <div className='d-flex'>
                  <Fragment />
                  {/* {index!==0 &&  */}
                  <CloseButtonWrapper
                  onClick={() => {
                    deleteAutoVerification(data, index);
                  }}
                  >
                  {TIMES}
                </CloseButtonWrapper>
                  {/* } */}
       <div className="p-0  w-100 d-flex align-items-center  ">
     <FlexGrid3Container className={'w-100 mb-1 mt-1 '}  >
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0015.00%'} >
   <DropdownSearchable
        placeHolder={'Clock in Verification '}
        options={optionList}
        selectedOption={
          values.clockTimeDistanceConfirmation 
            ?  values.clockTimeDistanceConfirmation[index].clockInTime
            : null
        }
        onChange={value =>
          dropDownHandleSelect(
            {clockInTime: value ? value : null},
            'clockInTime' ,'clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0015.00%'}  >
   <DropdownSearchable
        placeHolder={'Clock out verification '}
        options={optionList}
        selectedOption={
            values.clockTimeDistanceConfirmation 
            ?  values.clockTimeDistanceConfirmation[index].clockOutTime
            : null
        }
        onChange={value =>
          dropDownHandleSelect(
            {clockOutTime: value ? value : null},
            'clockOutTime','clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1" ResizeWidth={'0015.00%'} >
   <DropdownSearchable
        placeHolder={'Clock in Distance'}
        options={optionList?optionList:[]}
        selectedOption={
            values.clockTimeDistanceConfirmation 
            ?  values.clockTimeDistanceConfirmation[index].clockInDistance
            : null
        }
        onChange={value =>
          dropDownHandleSelect(
            {clockInDistance: value ? value : null},
            'clockInDistance','clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0015.00%'}   >
   <DropdownSearchable
        placeHolder={'Clock out Distance'}
        options={optionList?optionList:[]}
        selectedOption={
            values.clockTimeDistanceConfirmation 
            ?  values.clockTimeDistanceConfirmation[index].clockOutDistance
            : null
        }
        onChange={value =>
          dropDownHandleSelect(
            {clockOutDistance: value ? value : null},
            'clockOutDistance','clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0015.00%'}   >
   <DropdownSearchable
        placeHolder={'Visit Provision'}
        options={[{id:'Under Provision',name:'Under Provision'},{id:'Over Provision',name:'Over Provision'},{id:'On Time Provision',name:'On Time Provision'}]}
        
        selectedOption={
          values.clockTimeDistanceConfirmation 
          ?  values.clockTimeDistanceConfirmation[index].clockedProvision
          : null
      }
        onChange={value =>
          dropDownHandleSelect(
            {clockedProvision: value ? value : null},
            'clockedProvision','clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'0015.00%'}   >
   <DropdownSearchable
        placeHolder={'Evv visit Confirmation'}
        options={optionList2}
        selectedOption={
            values.clockTimeDistanceConfirmation 
            ?  values.clockTimeDistanceConfirmation[index].evvVisitConfirmation
            : null
        }
        onChange={value =>
          dropDownHandleSelect(
            {evvVisitConfirmation: value ? value : null},
            'evvVisitConfirmation','clockTimeDistanceConfirmation',index
          )
        }
      />
   </FlexGrid3Item>
   </FlexGrid3Container>
   </div>
   </div>
   </div>
    </>
    </div>
  )
}

export default EvvConfimation