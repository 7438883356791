import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumsList';
import AddModal from 'components/DashboardComponents/Settings/SettingsCompany/Branches/AddModal';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getBranches, deleteCompanyBranch } from 'utils/api/SettingsApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { FloatingMobileButton, PrimaryButtonForm } from 'shared/styles/buttons';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';

import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { EmploymentTypesContainer } from 'components/DashboardComponents/Team/styles';
import {
  FiltersContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { LARGE_COL, SMALL_COL, EX_LARGE_COL, EX_SMALL_COL } from 'shared/styles/constants/columns';
import { Toast } from 'primereact/toast';

import { useForm } from 'hooks/FormHook';
import {
  getCategoryPermission,
  getPermissions,
} from './../../../../../utils/localStorage/user';
import {
  COMPANY_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  LEAVE_ACCRUAL_SETTINGS,
  NON_COMMUTE_TRAVEL_BILL,
  NON_COMMUTE_TRAVEL,
  SETTINGS_ABSENCE_OVERVIEW,
  SETTINGS_TAX,
  VISIT_REMINDER,
  BRANCH_DEFAULT_PRIVACY,
} from './../../../../../utils/constants/permissions';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'context';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import AttachClientEmp from './AttachClientEmp';
import { setting_company_branch_choices } from 'utils/choiceConstant';
import { MdSettings } from 'react-icons/md';
import { GRAY_300, PURPLE } from 'shared/styles/constants/colors';
import { BRANCH_SETTINGS_TAB } from '..';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const Branches = ({
  companies,
  location,
  COMPANY_NOT_FOUND,
  updateBranchSettings,
  createBranchSettings,
  deletePermissionBranchSettings,
  readPermissionBranchSettings,
  filterAdmin,
  filterSuperAdmin,
  handleChangeChoices,
  userStaff,
}) => {

  const { values, setValues } = useForm({
    ...companies,
    ordering: 'name',
    limitperpage: PAGE_SIZE_RENDER
  });

  const {
    contextSubSectionPermissions,
    contextPermissions,
    contextIsAdmin,
    contextChoices,
    contextIsSuperAdmin,
  } = useContext(AppContext);

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const {
    setValue: setModalValueAttach,
    value: modalValueAttach,
    setTitle: setAttachTitle,
    fullBind: fullBindAttach,
  } = useModal(false);

  const { handleGetChoices } = useContext(AppContext)

  const [branches, setBranches] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const mountedRef = useRef(true);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [numberValue, setNumberValue] = useState();
  const history = useHistory();
  const [branchData, setBranchData] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true)
  const [isChecked, setIsChecked] = useState(false);
  const toast = useRef();
  const [branchTypeFilter, setBranchTypeFilter] = useState([]);

  //Leave Accrual Settings
  const [readPermissionAccrualLeave, setReadPermissionsAccrualLeave] = useState(false);
  const [readPermissionBillingNonCommute, setReadPermissionBillingNonCommute] = useState(false)
  const [readPermissionPayrollNonCommute, setReadPermissionPayrollNonCommute] = useState(false)
  const [readPermissionAbsenceOverview, setReadPermissionAbsenceOverview] = useState(false)
  const [readPermissionTax, setReadPermissionTax] = useState(false)

  const [readPermissionPrivacy, setReadPermissionPrivacy] = useState(false)

  const [readPermissionReminder, setReadPermissionReminder] = useState(false)



  useEffect(() => {
    handleGetChoices(setting_company_branch_choices)
  }, [])
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);

  const fullNameCol = 'name';

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // useEffect(() => {
  //   values.limitperpage = PAGE_SIZE_RENDER;
  //   setValues({ ...values });
  // }, []);

  // useEffect(() => {
  //   values.company_pk = companies.id;
  //   setValues({ ...values });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [companies]);

  useEffect(() => {
    setBranchData(
      contextChoices &&
      contextChoices.company &&
      contextChoices.company.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );
  }, [contextChoices.company]);



  // useEffect(() => {
  //   const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);

  //   getBranches({ ...values, ...pageToSearch }, values.limitperpage).then(
  //     response => {
  //       setIsChecked(false);
  //       if (!mountedRef.current) return null;

  //       setBranches(response.results || []);
  //       setPages(Math.ceil(response.count / values.limitperpage));
  //     }
  //   );
  //   setIsChecked(false);
  // }, [isChecked || values.limitperpage]);

  // useEffect(() => {
  //   if (values.company_pk) {
  //     const urlParams = queryString.parse(location.search);
  //     urlParams[PAGINATION_PARAM] = 1;
  //     const stringified = queryString.stringify(urlParams);
  //     history.push({
  //       pathname: '/settings/company/branches',
  //       search: stringified,
  //     });
  //     loadBranches();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.limitperpage]);





  // useEffect(() => {
  //   values.ordering = 'name';
  //   setNumberValue(1);
  //   setValues({ ...values });
  // }, []);

  // useEffect(() => {
  //   if (numberValue == 0) {
  //     values.ordering = '-name';
  //     setValues({ ...values });
  //   }
  // }, [numberValue]);

  const changeNumber = value => {
    setNumberValue(value);
  };

  const toDeleteBranch = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  useEffect(() => {
    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);

      //Leave Accrual Settings
      setReadPermissionsAccrualLeave(true)
      setReadPermissionBillingNonCommute(true)
      setReadPermissionPayrollNonCommute(true)
      setReadPermissionAbsenceOverview(true)
      setReadPermissionTax(true)
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === COMPANY_SETTINGS) {
            rolePermissions[COMPANY_SETTINGS] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });

        const rolesPermissions = contextSubSectionPermissions;

        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(
            item => {
              // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
            }
            // }
          );
        }

        console.log(rolePermissions, "roles")
        if (rolePermissions[COMPANY_SETTINGS]) {
          setCreatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );
        }
        // Leave Accrual Settings
        if (rolePermissions[LEAVE_ACCRUAL_SETTINGS]) {
          setReadPermissionsAccrualLeave(
            rolePermissions[LEAVE_ACCRUAL_SETTINGS]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }
        if (rolePermissions[NON_COMMUTE_TRAVEL_BILL]) {
          setReadPermissionBillingNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        if (rolePermissions[NON_COMMUTE_TRAVEL]) {
          setReadPermissionPayrollNonCommute(
            rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        if (rolePermissions[SETTINGS_TAX]) {
          setReadPermissionTax(
            rolePermissions[SETTINGS_TAX]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        if (rolePermissions[SETTINGS_ABSENCE_OVERVIEW]) {
          setReadPermissionAbsenceOverview(
            rolePermissions[SETTINGS_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        if (rolePermissions[BRANCH_DEFAULT_PRIVACY]) {
          setReadPermissionPrivacy(
            rolePermissions[BRANCH_DEFAULT_PRIVACY]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        if (rolePermissions[VISIT_REMINDER]) {
          setReadPermissionReminder(
            rolePermissions[VISIT_REMINDER]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION]
          )
        }
        

      }



    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextSubSectionPermissions,
    contextPermissions,
    contextIsAdmin,
    contextChoices,
    contextIsSuperAdmin,]);

  console.log(readPermissionAccrualLeave, "redd")

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };

  const loadBranches = () => {
    const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);

    getBranches({ ...values, ...pageToSearch }, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;
        setBranches(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
        setLoadingModal(false)
      }
    );
  };

  useEffect(() => {
    values.company_pk = companies.id;
    setValues({ ...values });
    if (values.company_pk) {
      loadBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companies,
    // values.company_pk,
    // values.id,
    location.search,
    // values.search,
    // values.ordering,
    values.limitperpage,
  ]);

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddBranches = () => {
    setIdSelected('');
    handleModal();
  };
  // useEffect(() => {
  //   loadBranches();
  // }, [values.company]);

  const handleDeleteBranch = async () => {
    let response = await deleteCompanyBranch(values.company_pk, idSelected);

    if (response.status == true) {

      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Deleted Successfully',
      });
      setModalDeleteValue(false)
      loadBranches();
      handleChangeChoices('branch')
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });

      // setIdSelected(response.id);
      setIsChecked(true);
      setModalValue(false);
      setModalDeleteValue(false);
      setIdSelected('');
      // loadBranches();
    }
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
    loadBranches();
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };


  const handleAttachClick = id => {
    setIdSelected(id);
    setModalValueAttach(true);
  };

  const createAddressFormat = address => {
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const country = address.country ? `${address.country}, ` : '';
    const address_state = address.state ? `${address.state} ` : '';
    return `${line_1}${country}${address_state}`;
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
    // loadBranches();
  };

  const setBranchType = item => {
    setBranchTypeFilter([...item]);
  };
  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/settings/company/branches',
      search: stringified,
    });
    setValues({ ...values, ...item });
  };

  console.log({
    readPermissionAccrualLeave,
    readPermissionBillingNonCommute,
    readPermissionPayrollNonCommute,
    readPermissionAbsenceOverview,
    readPermissionTax
  }, "roles")
  return (
    <>
      <div>
      <Toast ref={toast} position="top-right" />
        <Header>
          <div className="d-flex w-100 justify-content-between">
            <div>
              <FiltersLeftGroup>
                <EmploymentTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={branchData ? branchData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ company: items });
                    }}
                    itemsSelected={branchTypeFilter}
                    setSelectedFilter={setBranchType}
                    placeHolder={'Company  Type'}
                    isObject
                    allOption
                  // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
            </div>

            <SearchAddContainer>
              <FloatingMobileButton onClick={handleAddBranches}>
                <FaPlus />
                <label> NEW BRANCH</label>
              </FloatingMobileButton>
            </SearchAddContainer>
          </div>
        </Header>
        <ComponentDynamic loading={loadingModal}>
          {branches?.length ? (
            <Table
              headerColumns={headerColumns}
              callBackQuerySort={setQuerySort}
              statusNumber={numberValue}
              setStatusNumber={changeNumber}
              fullNameCol={fullNameCol}>
              <div>
                {branches.map((branch, index) => {
                  return (
                    <div key={index}>
                      <Row bgColor>
                        <ColsGrouper
                          className="d-flex"
                        >
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            overlap={true}>
                            <label>{branch.name}</label>
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            overlap={true}>
                            <label>{branch.company_name}</label>
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="align-self-center mb-0"
                            overlap={true}>
                            {createAddressFormat(branch)}
                          </Col>
                          <Col
                            max_width={EX_LARGE_COL}
                            Center
                            className="sm-hidden"
                            overlap={true}>
                            <label>{branch.city}</label>
                          </Col>
                          <Col
                            max_width={EX_LARGE_COL}
                            Center
                            className="md-hidden"
                            overlap={true}>
                            <label >{branch.zip_code}</label>
                          </Col>
                          <Col
                            max_width={SMALL_COL}
                            Center
                            className="md-hidden"
                            overlap={true}>
                            <label>{branch.branchEmail}</label>
                          </Col>

                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="md-hidden"
                            overlap={true}>
                            <PrimaryButtonForm minWidth="6rem" onClick={() => {
                              handleAttachClick(branch.id);
                            }}>
                              <span className={'mr-2'}>Attach</span>
                            </PrimaryButtonForm>
                          </Col>
                          {

                            (readPermissionAccrualLeave ||
                              readPermissionBillingNonCommute ||
                              readPermissionPayrollNonCommute ||
                              readPermissionAbsenceOverview ||
                              readPermissionTax ||
                              readPermissionPrivacy ||
                              readPermissionReminder)
                            && (
                              <Col
                                className="sm-hidden mr-2"
                                max_width={EX_SMALL_COL}
                                Center
                                Icon
                                Purple>
                                <IconWrapper
                                  onClick={() => {
                                    setValues({ ...values, branch_id: branch?.id });
                                    history.push({
                                      pathname: BRANCH_SETTINGS_TAB,
                                      state: { branch: branch }
                                    });
                                  }}
                                >
                                  <MdSettings
                                    size={20}
                                    color={PURPLE} />
                                </IconWrapper>
                              </Col>
                            )
                          }

                          {(filterAdmin == true ||
                            filterSuperAdmin == true ||
                            (userStaff == true && updateBranchSettings)) && (
                              <Col className="sm-hidden" Center Icon Purple>
                                <IconWrapper
                                  onClick={() => {
                                    handleRowClick(branch.id);
                                  }}>
                                  <MdEdit />
                                </IconWrapper>
                              </Col>
                            )}
                        </ColsGrouper>
                        {(filterAdmin == true ||
                          filterSuperAdmin == true ||
                          (userStaff == true && deletePermissionBranchSettings)) && (
                            <Col className="sm-hidden" Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  toDeleteBranch(branch.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>
                          )}
                      </Row>
                      <div className="mb-1" />
                    </div>
                  );
                })}
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER} x
                    dataLength={branches.length}
                  />
                </div>
              </div>
            </Table>
          ) :
            (<div className='w-100 d-flex justify-content-center mt-3'>
              <h4 style={{ fontSize: "1rem" }}>No data found</h4>
            </div>)}
        </ComponentDynamic>
        
        {modalValue &&
          <AddModal
            setIsChecked={setIsChecked}
            idSelected={idSelected}
            setModalValue={setModalValue}
            fullBind={fullBind}
            company_pk={values.company_pk}
            setTitle={setTitle}
            loadBranches={loadBranches}
            setIdSelected={setIdSelected}
            COMPANY_NOT_FOUND={COMPANY_NOT_FOUND}
            handleChangeChoices={handleChangeChoices}
          />
        }

        {modalValueAttach &&
          <AttachClientEmp
            idSelected={idSelected}
            setModalValue={setModalValueAttach}
            fullBind={fullBindAttach}
            setTitle={setAttachTitle}
            loadBranches={loadBranches}
          />
        }

        <ModalDecision
          type="delete"
          title="Warning"
          body={
            'Are you sure you want to delete this branch? \n deleting  the branch will remove all records from database.'
          }
          onOk={handleDeleteBranch}
          onCancel={() => {
            setIdSelected('');
          }}
          okTitle={'DELETE'}
          {...bindModalDelete}
        />
      </div>
    </>
  );
};

export default Branches;
