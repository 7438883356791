
import styled from 'styled-components';
import {PURPLE, GRAY_300} from 'shared/styles/constants/colors';
import {SM, MD, XXL} from 'shared/styles/constants/resolutions';


export const ModalTopContainer = styled.div`
postion:fixed !important;
margin-left:-13px;
background-color:'#F0F0F7' !important;
width:'86%',
padding-bottom:10px;
padding-top:10px;
z-index: 2;
display:flex;
justify-content:center;
margin-top:-7px !important ;

@media (max-width: 456px) {
  background-color:'#F0F0F7' !important;
  margin-top:41px  !important;
  margin-left:0px !important;
  width:100% !important;
}

@media (width: 540px) {
  background-color:'#F0F0F7' !important;
  margin-top:-12px;
  margin-left:0px !important;
  width:100% !important;
}
`;



export const ModalTabsContainer = styled.div`
postion:fixed !important;
border-bottom: solid 1px rgba(17, 17, 26, 0.1);
background-color:'#F0F0F7' !important;
width:'100%'  !important;,
margin-left:-36px;
padding:10px;
z-index: 2;
display:flex;
justify-content:around;
text-align:center;
margin-top:23px;

@media (max-width: 456px) {
  background-color:'#F0F0F7' !important;

  overflow-x: scroll !important;;
  font-size: 15px;
padding-right: 25px;
  padding-left: 1px;
  margin-top:88px;
}

`;


export const ModaClientTopContainer = styled.div`
postion:fixed !important;
margin-left:-13px;
background-color:'#F0F0F7' !important;
width:'86%',
padding-bottom:10px;
padding-top:10px;
z-index: 999999;
display:flex;
justify-content:center;
margin-top:-7px !important ;

@media (max-width: 456px) {
  margin-top:41px  !important;
  margin-left:0px !important;
  width:'100%';
}

@media (width: 540px) {
  margin-top:-12px;
  margin-left:0px !important;
  width:'100%';
}
`;



export const ModalClientTabsContainer = styled.div`
postion:fixed !important;
background-color:'#F0F0F7' !important;
width:'100%'  !important;,
margin-left:-36px;
padding:10px;
z-index: 999999;
display:flex;
justify-content:around;
text-align:center;
margin-top:23px;

@media (max-width: 456px) {
  overflow-x: scroll;
  font-size: 15px;
padding-right: 25px;
  padding-left: 1px;
  margin-top:88px;
}

`;



export const StickyHeader = styled.header`
    position: -webkit-sticky;
    position: sticky;
    top: 67px;
    margin-top: -9px;
    z-index: 1000;
    background-color: #F0F0F7;
    padding: 10px 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;


export const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 10px auto 0 auto;
  flex-wrap: wrap;
`;