import React,{useState} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {onChangeValidations} from 'utils/constants/regularExpressions';
import {TitleSection, FlexEqual} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import _ from 'lodash';

const Address = ({useInput, nameSection, sectionStyle,values,setValues, readOnly}) => {
  const [address,setAddress]=useState("")

  const handleChange=(address)=>{
    setAddress(address)
  }
  
  const handleSelect=async (value)=>{
    handleChange(value)
  const result=await geocodeByAddress(value)
  console.log(result,"41544")
  const coordinates=await getLatLng(result[0])
  
  if(result){
   let tempAddress=_.cloneDeep(result[0].address_components)
  if(!values[nameSection]){
    values[nameSection]={}
  }
  values[nameSection].latitude=coordinates.lat
  values[nameSection].longitude=coordinates.lng
   result[0].address_components.forEach((element,index) => {
     if(element.types.find(value=>value=='postal_code')){
       values[nameSection].zip_code=element.long_name
      //  tempAddress.splice(index,1)
     }
    else if(element.types.find(value=>value=='country')){
      values[nameSection].country=element.long_name
      // tempAddress.splice(index,1)
    }
    else if(element.types.find(value=>value=='administrative_area_level_1')){
      values[nameSection].state=element.long_name
      // tempAddress.splice(index,1)
    }
    else if(element.types.find(value=>value=='administrative_area_level_2' || value=='locality')){
      values[nameSection].city=element.long_name
      // tempAddress.splice(index,1)
    }
  
   });
   let lineAddress=""
   tempAddress.forEach(ele=>{
    if(ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
    || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
    ){
     lineAddress=lineAddress
    }else{
     lineAddress+=ele.long_name+" "
    }
   })
   values[nameSection].line_1=lineAddress
   setValues({...values})
  }
  }

  const allowBackspace = (e, type) => {
    if (e.key === 'Backspace') {
      if (values.address[type] && values.address[type].length > 0)
        setValues({ ...values, address: { ...values.address, [type]: values.address[type].slice(0, -1) } })
      e.preventDefault()
    }
  }
  const handleLongLatChange = (e, type) => {
    const { valid, value } = onChangeValidations[type](e)
    if (!valid) return;
    setValues({ ...values, address: { ...values.address, [type]: value } })
  }

  return (
    <FlexEqual className={sectionStyle}>
      <TitleSection>Address</TitleSection>
      <div className="d-flex flex-column mt-2">
      <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    >
                      {
                        ({getInputProps, suggestions, getSuggestionItemProps, loading})=>(
                          <div>
                             <InputLabelTop
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
                          <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#D3D3D3', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>  
                          </div>
                        )

                        
                      }
                    </PlacesAutocomplete>
        <div className="w-100">
          <InputLabelTop
            type="text"
            id="house_name"
            readOnly={readOnly}
            label={'House Name / Company Name'}
            {...useInput(`${[nameSection]}.name`)}
            maxlength={50}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="w-100 mr-2">
          <InputLabelTop
            required
            type="text"
            readOnly={readOnly}
            id="address_line_1"
            label={'Address Line 1'}
            {...useInput(`${[nameSection]}.line_1`, 'isRequired')}
            maxlength={255}
          />
        </div>
        <div className="w-100 ">
          <InputLabelTop
            type="text"
            id="address_line_2"
            readOnly={readOnly}
            label={'Address Line 2'}
            {...useInput(`${[nameSection]}.line_2`)}
            maxlength={255}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 mr-2">
          <InputLabelTop
            required
            type="text"
            readOnly={readOnly}
            id={`${[nameSection]}_town_city`}
            label={'Town / City'}
            {...useInput(`${[nameSection]}.city`, 'isRequired')}
            maxlength={100}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
            type="text"
            readOnly={readOnly}
            id={`${[nameSection]}_state`}
            label={'County / State'}
            {...useInput(`${[nameSection]}.state`)}
            maxlength={100}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 mr-2">
          <InputLabelTop
            required
            type="text"
            readOnly={readOnly}
            id={`${[nameSection]}_postcode_zipcode`}
            label={'Postcode / Zipcode'}
            {...useInput(`${[nameSection]}.zip_code`, 'isRequired')}
            maxlength={25}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
            required
            type="text"
            readOnly={readOnly}
            id={`${[nameSection]}_country`}
            label={'Country'}
            {...useInput(`${[nameSection]}.country`, 'isRequired')}
            maxlength={100}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 mr-2">
          <InputLabelTop
          // disabled={true}
          required
            type="text"
            readOnly={readOnly}
            id="address_latitude"
            label={'Latitude'}
            {...useInput(`${[nameSection]}.latitude`, 'isRequired')}
            onChange={(e) => handleLongLatChange(e, 'latitude')}
            onKeyDown={(e) => allowBackspace(e, 'latitude')}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
          // disabled={true}
          required
            type="text"
            id="longitude"
            readOnly={readOnly}
            label={'Longitude'}
            {...useInput(`${[nameSection]}.longitude`, 'isRequired')}
            onChange={(e) => handleLongLatChange(e, 'longitude')}
            onKeyDown={(e) => allowBackspace(e, 'longitude')}
          />
        </div>
      </div>
    </FlexEqual>
  );
};

Address.propTypes = {
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string,
};

Address.defaultProps = {
  sectionStyle: '',
};

export default Address;
