import React, { useEffect, useRef, useContext } from 'react';
import { useState } from 'react';
import { Header, LinkTab, LinkTabStyled, TabStyled, Title } from 'shared/styles/constants/tagsStyles';
import Template from 'components/SharedComponents/SettingsTemplate';
// import {getChoices} from 'utils/api/CoreApi';
import { AppContext } from 'context';
import {
  WEB_CREATE_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  TEAM_ABSENCE_INFO,
  TEAM_ABSENCE_PAID,
  TEAM_ABSENCE_PLANNED,
  TEAM_ABSENCE_REASON,
  TEAM_ABSENCE_TYPE,
  TEAM_COMPENTECY,
  TEAM_EMPLOYE_CATEGORY,
  TEAM_EMPLOYE_TYPE,
  TEAM_JOB_TITLE,
  TEAM_PERMISSION,
  TEAM_SHIFT,
  TEAM_SKILL,
  WEB_PERMISSION,
  TEAM_VISA,
  TEAM_LEAVER_REASON,
  TEAM_REFERENCE_STATUS,
  TEAM_APPLICANT_SOURCE,
  TEAM_BILLING_TERM,
  TEAM_BILLING_PATTERN,
  TEAM_TRAINGING_TYPE,
  TEAM_REGISTRATION_TYPE,
  TEAM_ETHENCITY,
  TEAM_TITLE,
  TEAM_ANIMAL,
  TEAM_ESTABLISHMENT_TYPE,
  TEAM_COMPETENCY_MATRIX,
  TEAM_SKILL_MATRIX,
  SKILL_CATEGORY,
  JOB_DEPARTMENT,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
} from 'utils/constants/permissions';
import {
  getEmploymentTypes,
  deleteEmploymentType,
  setEmploymentType,
  getEmployeeCategories,
  deleteEmployeeCategory,
  setEmployeeCategory,
  getJobTitles,
  deleteJobTitle,
  setJobTitle,
  getSkill,
  deleteSkill,
  setSkill,
  getShifts,
  deleteShifts,
  setShifts,
  getAbsenceType,
  setAbsence,
  getAbsenceReasonType,
  setAbsenceReason,
  getAbsenceInformed,
  setAnsenceInformed,
  getAbsencePlaned,
  setAbsencePlaned,
  getAbsencePaid,
  setAbsencePaid,
  deleteAbsenceType,
  deleteAbsenceInformed,
  deleteAbsencePlanned,
  deleteAbsencePaid,
  getCompentency,
  deleteCompentency,
  setCompentency,
  deleteAbsenceReason,
  getVisaStatus,
  deleteVisaStatus,
  setVisaStatus,
  getReferenceStatus,
  deleteReferenceStatus,
  setRefrenceStatus,
  getApplicantSource,
  deleteApplicantSource,
  setApplicantSource,
  getLeaverReason,
  deleteLeaverReason,
  setLeaverReason,
  getEthencity,
  deleteEthencity,
  setLeaverReasons,
  setEthencity,
  getEstablishment,
  deleteEstablishment,
  setEstablishment,
  getTitile,
  deleteTitle,
  setTitle,
  getAnimal,
  deleteAnimal,
  setAnimal,
  getSupportLevel,
  deleteSupportLevel,
  setSupportLevel,
  getBillingTerms,
  deleteBillingTerms,
  setBillingTerms,
  getBillingPattern,
  deleteBillingPattern,
  setBillingPattern,
  getTraingType,
  deleteTrainingType,
  setTrainingTypes,
  getRegistrationType,
  deleteRagistration,
  setBillingTerm,
  setRegistrationTypes,
  getCareContract,
  setCareContract,
  deleteCareContract,
  getContactMethod,
  deleteContactMethod,
  setContactMethod,
  getNok,
  deleteNok,
  setNok,
  getMarketing,
  deleteMarketing,
  setMarketing,
  getCancellation,
  deleteCancellation,
  setCancellation,
  getFunderSource,
  deleteFunderSource,
  setFunderSource,
  getCareGroup,
  deleteCareGruop,
  setCareGroup,
  getPPE,
  deletePPE,
  setPPE,
  getSickness,
  deleteSickness,
  setSickness,
  setPPES,
  getPronounCategory,
  deletePronounCategory,
  setPronounCategory,
  getBillRate,
  deleteBillRate,
  setBillRate,
  getBillDuration,
  deleteBillDuration,
  setBillDuration,
  getPayRate,
  deletePayRate,
  setPayRate,
  getPayDuration,
  deletePayDuration,
  setPayDuration,
  getTepIn,
  deleteTepIn,
  setTepIn,
  getCareLevel,
  deleteCareLevel,
  setCareLevel,
  getCareNeeds,
  deleteCareNeeds,
  setCareNeeds,
  getCareDependency,
  deleteCareDependency,
  setCareDependency,
  getLivingCircumstances,
  deleteLivingCircumstances,
  setLivingCircumstances,
  getImpairmentSensory,
  deleteImpairmentSensory,
  setImpairmentSensory,
  getEthnicityDescription,
  deleteEthencityDescription,
  setEthnicityDescription,
  getJobRoleSpecialist,
  deleteJobRoleSpecialist,
  setJobRoleSpecialist,
  getProbationTerm,
  deleteProbation,
  setProbationTerm,
  getRecruitmentSource,
  deleteRecruitmentSource,
  setRecruitmentSource,
  getEmployerNoticePeriod,
  deleteEmployerNoticePeriod,
  setEmployerNoticePeriod,
  getCountry,
  deleteCountry,
  setCountry,
  getCareCertificate,
  deleteCareCertificate,
  setCareCertificate,
  getSocialCare,
  deleteSocialCareQualification,
  setSocialCare,
  getCommonCore,
  deleteCommonCore,
  setCommonCore,
  getAccreditedTraining,
  deleteAccreditedTraining,
  setAccreditedTraining,
  getServiceType,
  deleteServiceType,
  setServiceType,
  getServiceTypeDescription,
  deleteServiceTypeDescription,
  setServiceTypeDescription,
  getApprenticeshipName,
  deleteApprenticeshipName,
  setApprenticeshipName,
  getSpecificCommunication,
  deleteSpecificCommunication,
  setSpecificCommunication,
  getClientGroup,
  deleteClientGroup,
  setClientGroup,
  getRegulatedServices,
  deleteRegulatedServices,
  setRegulatedServices,
  getClientMobility,
  deleteClientMobility,
  setClientMobility,
  deleteUserType,
  setUserType,
  getUserType,
  getUserTypeDescription,
  deleteUserTypeDescription,
  setUserTypeDescription,
  getTriageTask,
  deleteTriageTask,
  setTriageTask,
  getTriageTaskStatus,
  deleteTriageStatus,
  setTriageTaskStatus,
  deleteTriageTaskStatus,
  getSkillCategory,
  setSkillCategory,
  deleteTeamCareCategory,
  getPayType,
  deletePayType,
  setPayTypeApi,
  getJobDepartment,
  deleteJobDepartment,
  setJobDepartmentApi,
  // getEmployeeContracts,
  // deleteEmployeeContract,
  // setEmployeeContract,
} from 'utils/api/SettingsApi';
import { getAdmin, getSuperAdmin } from 'utils/localStorage/token';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import {
  TitleSection,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import { CLIENT_PERMISSION, TEAM_SETTINGS } from 'utils/constants/permissions';
import {
  getCategoryPermission,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
  setPermissions,
} from 'utils/localStorage/user';
import _, { values } from 'lodash';
import { GiLiver } from 'react-icons/gi';
import {
  AccordionArrow,
  AccordionContainer,
  StaticArrow,
} from 'components/DashboardComponents/LeftSidebar/styles';
import { PURPLE } from 'shared/styles/constants/colors';
import { FaPlus } from 'react-icons/fa';
import { Fragment } from 'react';
import { useScrollTrigger, useSwitch } from '@mui/material';
import { toBeRequired } from '@testing-library/jest-dom';
import { setting_team_choices } from 'utils/choiceConstant';
import { ModalClientTabsContainer, ModalTabsContainer } from 'components/DashboardComponents/Team/AddModal/ModalStyle';
import { ShowTeamSettingModalTab } from 'components/DashboardComponents/Team/AddModal/styles';
import SkillMatrix from './SkillMatrix';
import CompetencyMatrix from './CompetencyMatrix';
import { useForm } from 'hooks/FormHook';
import { COMPETENCIES_SETTINGS, SETTINGS, SETTINGS_TEAM } from 'utils/constants/routes';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { KeyPress } from 'utils/constants/regularExpressions';
import { SettingsTemplateContainer } from 'components/SharedComponents/SettingsTemplate/styles'

const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const tab4 = 4;
const tab5 = 5;
const tab6 = 6;
const tab7 = 7;
const tab8 = 8;
const tab9 = 9;



const SettingsTeam = ({ handleChangeChoices }) => {
  // const [choices, setChoices] = useState([]);

  const [readPermissionSkill, setReadPermissionSkill] = useState(false);
  const [updatePermissionSkill, setUpdatePermissionSkill] = useState(false);
  const [createPermissionSkill, setCreatePermissionSkill] = useState(false);
  const [deletePermissionSkill, setDeletePermissionSkill] = useState(false);
  const [readPermissionJobTile, setReadPermissionJobTile] = useState(false);
  const [updatePermissionJobTile, setUpdatePermissionJobTile] = useState(false);
  const [createPermissionJobTile, setCreatePermissionJobTile] = useState(false);
  const [deletePermissionJobTile, setDeletePermissionJobTile] = useState(false);

  
  const [readPermissionJobDepartment, setReadPermissionJobDepartment] = useState(false);
  const [updatePermissionJobDepartment, setUpdatePermissionJobDepartment] = useState(false);
  const [createPermissionJobDepartment, setCreatePermissionJobDepartment] = useState(false);
  const [deletePermissionJobDepartment, setDeletePermissionJobDepartment] = useState(false);

  // const [choices, setChoices] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [employeCategory, setEmployeeCategorys] = useState(true);
  const [employementType, setEmploymentTypes] = useState(true);
  const [jobTiltle, setJobTitles] = useState(true);
  const [jobDepartment, setJobDepartment] = useState(true);
  const [skills, setSkills] = useState(true);
  const [shiftType, setShiftType] = useState(true);
  const [absenceType, setAbsenceType] = useState(true);
  const [absenceResasons, setAbsenceResons] = useState(true);
  const [absenceInfo, setAbsenceInfo] = useState(true);
  const [plannedType, setPlannedType] = useState(true);
  const [paidType, setPaidType] = useState(true);
  const [compentencies, setCompentencys] = useState(true);
  const [visaStatus, setVisaStatuss] = useState(true);
  const [referenceStatus, setReferenceStatus] = useState(true);
  const [applicantSource, setApplicantSources] = useState(true);
  const [ethencities, setEthencities] = useState(true);
  const [liverReason, setLeaverReason] = useState(true);
  const [titles, setTitles] = useState(true);
  const [establishment, setEstablishments] = useState(true);
  const [animals, setAnimals] = useState(true);
  const [supportLevels, setSupportLevels] = useState(true);
  const [trainingType, setTrainingType] = useState(true);
  const [registrationType, setRegistrationType] = useState(true);
  const [billingPatterns, setBillingPatterns] = useState(true);
  const [billingTerms, setBillingTerms] = useState(true);
  const [hideContactMethod, setHideContactMethod] = useState(true);
  const [hideMarketing, setHideMarketing] = useState(true);
  const [HideSicknessReason, setHideSicknessReason] = useState(true);
  const [HidePronounCategory, setHidePronounCategory] = useState(true);
  const [hideBillRate, setHideBillRate] = useState(true);
  const [hideBillDuration, setHideBillDuration] = useState(true);
  const [hidePayRate, setHidePayRate] = useState(true);
  const [hidePayDuration, setHidePayDuration] = useState(true);
  const [hideEthnicityDescription, setHideEthnicityDescription] =
    useState(true);
  const [hideJobRoleSpecialist, setHideJobRoleSpecialist] = useState(true);
  const [hideProbationTerm, setHideProbationTerm] = useState(true);
  const [hideRecruitmentSource, setHideRecruitmentSource] = useState(true);
  const [hideEmployerNoticePeriod, setHideEmployerNoticePeriod] =
    useState(true);
  const [hideCountry, setHideCountry] = useState(true);
  const [hideCareCertificate, setHideCareCertificate] = useState(true);
  const [hideSocialCare, setHideSocialCare] = useState(true);
  const [hideCommonCore, setHideCommonCore] = useState(true);
  const [hideSkillCategory, setHideSkillCategory] = useState(true);
  const [hideAccreditedTraining, setHideAccreditedTraining] = useState(true);
  const [hideApprenticeshipName, setHideApprenticeshipName] = useState(true);
  const [hideTriage, setHideTriage] = useState(true);
  const [hideTask, setHideTask] = useState(true);
  const [payType, setPayType] = useState(true);


  const [readPermissionAbseneType, setReadPermissionAbsenceType] =
    useState(false);
  const [updatePermissionAbsenceType, setUpdatePermissionAbsencetype] =
    useState(false);
  const [createPermissionAbsenceType, setCreatePermissionAbsenceType] =
    useState(false);
  const [deletePermissionAbsenceType, setDeletePermissionAbsenceType] =
    useState(false);
  const [readPermissionShiftType, setReadPermissionShiftType] = useState(false);
  const [updatePermissionShiftType, setUpdatePermissionShiftType] =
    useState(false);
  const [createPermissionShiftType, setCreatePermissionShiftType] =
    useState(false);
  const [deletePermissionShiftType, setDeletePermissionShiftType] =
    useState(false);
  const [readPermissionAbsenceReason, setReadPermissionAbsenceReason] =
    useState(false);
  const [updatePermissionAbsenceReason, setUpdatePermissionAbsenceReason] =
    useState(false);
  const [createPermissionAbsenceReason, setCreatePermissionAbsenceReason] =
    useState(false);
  const [deletePermissionAbsenceReason, setDeletePermissionAbsenceReason] =
    useState(false);

  const [readPermissionAbsencePlanned, setReadPermissionAbsencePlanned] =
    useState(false);
  const [updatePermissionAbsencePlanned, setUpdatePermissionAbsencePlanned] =
    useState(false);
  const [createPermissionAbsencePlanned, setCreatePermissionAbsencePlanned] =
    useState(false);
  const [deletePermissionAbsencePlanned, setDeletePermissionAbsencePlanned] =
    useState(false);

  const [readPermissionAbsenceInformed, setReadPermissionAbsenceInformed] =
    useState(false);
  const [updatePermissionAbsenceInformed, setUpdatePermissionAbsenceInformed] =
    useState(false);
  const [createPermissionAbsenceInformed, setCreatePermissionAbsenceInformed] =
    useState(false);
  const [deletePermissionAbsenceInformed, setDeletePermissionAbsenceInformed] =
    useState(false);

  const [readPermissionCompetencyName, setReadPermissionCompetencyName] =
    useState(false);
  const [updatePermissionCompetencyName, setUpdatePermissionCompetencyName] =
    useState(false);
  const [createPermissionCompetencyName, setCreatePermissionCompetencyName] =
    useState(false);
  const [deletePermissionCompetencyName, setDeletePermissionCompetencyName] =
    useState(false);

  const [readPermissionAbsencePaid, setReadPermissionAbsencePaid] =
    useState(false);
  const [updatePermissionAbsencePaid, setUpdatePermissionAbsencePaid] =
    useState(false);
  const [createPermissionAbsencePaid, setCreatePermissionAbsencePaid] =
    useState(false);
  const [deletePermissionAbsencePaid, setDeletePermissionAbsencePaid] =
    useState(false);

  const [readPermissionEmployeType, setReadPermissionEmployeType] =
    useState(false);
  const [updatePermissionEmployeType, setUpdatePermissionEmployeType] =
    useState(false);
  const [createPermissionEmployeType, setCreatePermissionEmployeType] =
    useState(false);
  const [deletePermissionEmployeType, setDeletePermissionEmployeType] =
    useState(false);

  const [readPermissionEmployeCategory, setReadPermissionEmployeCategory] =
    useState(false);
  const [updatePermissionEmployeCategory, setUpdatePermissionEmployeCategory] =
    useState(false);
  const [createPermissionEmployeCategory, setCreatePermissionEmployeCategory] =
    useState(false);
  const [deletePermissionEmployeCategory, setDeletePermissionEmployeCategory] =
    useState(false);
  const [readPermissionVisaStatus, setReadPermissionVisaStatus] =
    useState(false);
  const [updatePermissionVisaStatus, setUpdatePermissionVisaStatus] =
    useState(false);
  const [createPermissionVisaStatus, setCreatePermissionVisaStatus] =
    useState(false);
  const [deletePermissionVisaStatus, setDeletePermissionVisaStatus] =
    useState(false);
  const [readPermissionReferenceStatus, setReadPermissionReferenceStatus] =
    useState(false);
  const [updatePermissionReferenceStatus, setUpdatePermissionReferenceStatus] =
    useState(false);
  const [createPermissionReferenceStatus, setCreatePermissionReferenceStatus] =
    useState(false);
  const [deletePermissionReferenceStatus, setDeletePermissionReferenceStatus] =
    useState(false);
  const [readPermissionApplicantSource, setReadPermissionApplicantSource] =
    useState(false);
  const [updatePermissionApplicantSource, setUpdatePermissionApplicantSource] =
    useState(false);
  const [createPermissionApplicantSource, setCreatePermissionApplicantSource] =
    useState(false);
  const [deletePermissionApplicantSource, setDeletePermissionApplicantSource] =
    useState(false);
  const [readPermissionLeaverReason, setReadPermissionLeaverReason] =
    useState(false);
  const [updatePermissionLeaverReason, setUpdatePermissionLeaverReason] =
    useState(false);
  const [createPermissionLeaverReason, setCreatePermissionLeaverReason] =
    useState(false);
  const [deletePermissionLeaverReason, setDeletePermissionLeaverReason] =
    useState(false);
  const [readPermissionBillingPatterns, setReadPermissionBillingPatterns] =
    useState(false);
  const [updatePermissionBillingPatterns, setUpdatePermissionLBillingPatterns] =
    useState(false);
  const [createPermissionBillingPatterns, setCreatePermissionBillingPatterns] =
    useState(false);
  const [deletePermissionBillingPatterns, setDeletePermissionBillingPatterns] =
    useState(false);
  const [readPermissionTrainingType, setReadPermissionTrainingType] =
    useState(false);
  const [updatePermissionTrainingType, setUpdatePermissionTrainingType] =
    useState(false);
  const [createPermissionTrainingType, setCreatePermissionTrainingType] =
    useState(false);
  const [deletePermissionTrainingType, setDeletePermissionTrainingType] =
    useState(false);

  const [readPermissionRegistrationType, setReadPermissionRegistrationType] =
    useState(false);
  const [
    updatePermissionRegistrationType,
    setUpdatePermissionRegistrationType,
  ] = useState(false);
  const [
    createPermissionRegistrationType,
    setCreatePermissionRegistrationType,
  ] = useState(false);
  const [
    deletePermissionRegistrationType,
    setDeletePermissionRegistrationType,
  ] = useState(false);
  const [readPermissionBillingTerms, setReadPermissionBillingTerms] =
    useState(false);
  const [updatePermissionBillingTerms, setUpdatePermissionBillingTerms] =
    useState(false);
  const [createPermissionBillingTerms, setCreatePermissionBillingTerms] =
    useState(false);
  const [deletePermissionBillingTerms, setDeletePermissionBillingTerms] =
    useState(false);
  const [readPermissionEthnicity, setReadPermissionEthnicity] = useState(false);
  const [updatePermissionEthnicity, setUpdatePermissionEthnicity] =
    useState(false);
  const [createPermissionEthnicity, setCreatePermissionEthnicity] =
    useState(false);
  const [deletePermissionEthnicity, setDeletePermissionEthnicity] =
    useState(false);
  const [readPermissionEstablishment, setReadPermissionEstablishment] =
    useState(false);
  const [updatePermissionEstablishment, setUpdatePermissionEstablishment] =
    useState(false);
  const [createPermissionEstablishment, setCreatePermissionEstablishment] =
    useState(false);
  const [deletePermissionEstablishment, setDeletePermissionEstablishment] =
    useState(false);

  const [readPermissionTitle, setReadPermissionTitle] = useState(false);
  const [updatePermissionTitle, setUpdatePermissionTitle] = useState(false);
  const [createPermissionTitle, setCreatePermissionTitle] = useState(false);
  const [deletePermissionTilte, setDeletePermissionTilte] = useState(false);
  const [readPermissionAnimal, setReadPermissionAnimal] = useState(false);
  const [updatePermissionAnimal, setUpdatePermissionAnimal] = useState(false);
  const [createPermissionAnimal, setCreatePermissionAnimal] = useState(false);
  const [deletePermissionAnimal, setDeletePermissionAnimal] = useState(false);

  //Skill Matrix and Competency MAtrix permissions state
  const [readPermissionSkillMatrix, setReadPermissionSkillMatrix] = useState(false)
  const [createPermissionSkillMatrix, setCreatePermissionSkillMatrix] = useState(false)
  const [updatePermissionSkillMatrix, setUpdatePermissionSkillMatrix] = useState(false)
  const [deletePermissionSkillMatrix, setDeletePermissionSkillMatrix] = useState(false)

  const [readPermissionCompetencyMatrix, setReadPermissionCompetencyMatrix] = useState(false)
  const [createPermissionCompetencyMatrix, setCreatePermissionCompetencyMatrix] = useState(false)
  const [updatePermissionCompetencyMatrix, setUpdatePermissionCompetencyMatrix] = useState(false)
  const [deletePermissionCompetencyMatrix, setDeletePermissionCompetencyMatrix] = useState(false)

  const [readPermissionSkillCategory, setReadPermissionSkillCategory] = useState(false)
  const [createPermissionSkillCategory, setCreatePermissionSkillCategory] = useState(false)
  const [updatePermissionSkillCategory, setUpdatePermissionSkillCategory] = useState(false)
  const [deletePermissionSkillCategory, setDeletePermissionSkillCategory] = useState(false)

  const [tabSelected, setTabSelected] = useState(tab1);
  const history = useHistory()
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ skillMatrix: {}, competencyMatrix: {} });

  const {
    contextChoices: choices,
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    handleGetChoices,
  } = useContext(AppContext);
  const mountedRef = useRef(true);

  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  useEffect(() => {
    handleGetChoices(setting_team_choices);
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setReadPermissions(true);
      setUpdatePermissionJobTile(true);
      setCreatePermissionJobTile(true);
      setDeletePermissionJobTile(true);
      setReadPermissionJobTile(true);
      setUpdatePermissionEmployeType(true);
      setCreatePermissionEmployeType(true);
      setDeletePermissionEmployeType(true);
      setReadPermissionEmployeType(true);
      setUpdatePermissionEmployeCategory(true);
      setCreatePermissionEmployeCategory(true);
      setDeletePermissionEmployeCategory(true);
      setReadPermissionEmployeCategory(true);
      setReadPermissionAbsenceInformed(true);
      setUpdatePermissionAbsenceInformed(true);
      setCreatePermissionAbsenceInformed(true);
      setDeletePermissionAbsenceInformed(true);
      setCreatePermissionAbsencePlanned(true);
      setReadPermissionAbsencePlanned(true);
      setUpdatePermissionAbsencePlanned(true);
      setDeletePermissionAbsencePlanned(true);
      setReadPermissionAbsencePaid(true);
      setCreatePermissionAbsencePaid(true);
      setUpdatePermissionAbsencePaid(true);
      setDeletePermissionAbsencePaid(true);
      setCreatePermissionBillingPatterns(true);
      setUpdatePermissionLBillingPatterns(true);
      setDeletePermissionBillingPatterns(true);
      setReadPermissionBillingPatterns(true);
      setReadPermissionSkill(true);
      setCreatePermissionSkill(true);
      setUpdatePermissionSkill(true);
      setDeletePermissionSkill(true);
      setCreatePermissionAbsenceType(true);
      setUpdatePermissionAbsencetype(true);
      setDeletePermissionAbsenceType(true);
      setReadPermissionAbsenceType(true);
      setCreatePermissionAbsenceReason(true);
      setUpdatePermissionAbsenceReason(true);
      setDeletePermissionAbsenceReason(true);
      setReadPermissionAbsenceReason(true);
      setReadPermissionAnimal(true);
      setCreatePermissionAnimal(true);
      setUpdatePermissionAnimal(true);
      setDeletePermissionAnimal(true);
      setCreatePermissionCompetencyName(true);
      setUpdatePermissionCompetencyName(true);
      setDeletePermissionCompetencyName(true);
      setReadPermissionCompetencyName(true);
      setCreatePermissionShiftType(true);
      setUpdatePermissionShiftType(true);
      setDeletePermissionShiftType(true);
      setReadPermissionShiftType(true);
      setReadPermissionEstablishment(true);
      setCreatePermissionShiftType(true);
      setDeletePermissionEstablishment(true);
      setUpdatePermissionEstablishment(true);
      setReadPermissionApplicantSource(true);
      setCreatePermissionApplicantSource(true);
      setDeletePermissionApplicantSource(true);
      setUpdatePermissionApplicantSource(true);
      setCreatePermissionReferenceStatus(true);
      setUpdatePermissionReferenceStatus(true);
      setDeletePermissionReferenceStatus(true);
      setReadPermissionReferenceStatus(true);
      setCreatePermissionLeaverReason(true);
      setUpdatePermissionLeaverReason(true);
      setDeletePermissionLeaverReason(true);
      setReadPermissionLeaverReason(true);
      setCreatePermissionVisaStatus(true);
      setReadPermissionVisaStatus(true);
      setUpdatePermissionVisaStatus(true);
      setDeletePermissionVisaStatus(true);
      setCreatePermissionEthnicity(true);
      setReadPermissionEthnicity(true);
      setDeletePermissionEthnicity(true);
      setUpdatePermissionEthnicity(true);
      setCreatePermissionRegistrationType(true);
      setReadPermissionRegistrationType(true);
      setUpdatePermissionRegistrationType(true);
      setDeletePermissionRegistrationType(true);
      setReadPermissionTrainingType(true);
      setCreatePermissionTrainingType(true);
      setUpdatePermissionTrainingType(true);
      setDeletePermissionTrainingType(true);
      setCreatePermissionTitle(true);
      setReadPermissionTitle(true);
      setUpdatePermissionTitle(true);
      setDeletePermissionTilte(true);
      //Competency Matrix
      setReadPermissionCompetencyMatrix(true)
      setCreatePermissionCompetencyMatrix(true)
      setUpdatePermissionCompetencyMatrix(true)
      setDeletePermissionCompetencyMatrix(true)

      //Skill Matrix
      setReadPermissionSkillMatrix(true)
      setCreatePermissionSkillMatrix(true)
      setUpdatePermissionSkillMatrix(true)
      setDeletePermissionSkillMatrix(true)
      // SKill Category
      setReadPermissionSkillCategory(true)
      setCreatePermissionSkillCategory(true)
      setUpdatePermissionSkillCategory(true)
      setDeletePermissionSkillCategory(true)

      setReadPermissionJobDepartment(true)
setUpdatePermissionJobDepartment(true)
setCreatePermissionJobDepartment(true)
setDeletePermissionJobDepartment(true)
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        console.log(roles, 'aaaaaa');
        roles.forEach(item => {
          console.log(item, 'dddd');
          if (item?.section?.name === TEAM_SETTINGS) {
            rolePermissions[TEAM_SETTINGS] = {};
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        const rolesPermissions = contextSubSectionPermissions;
        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          });
        }
        setCreatePermissions(
          rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][WEB_CREATE_PERMISSION]
        );
        setDeletePermissions(
          rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
        setUpdatePermissions(
          rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][WEB_UPDATE_PERMISSION]
        );

        setReadPermissions(
          rolePermissions[TEAM_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION]
        );

        if (rolePermissions[TEAM_SKILL]) {
          setCreatePermissionSkill(
            rolePermissions[TEAM_SKILL]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionSkill(
            rolePermissions[TEAM_SKILL]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionSkill(
            rolePermissions[TEAM_SKILL]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionSkill(
            rolePermissions[TEAM_JOB_TITLE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_JOB_TITLE]) {
          setCreatePermissionJobTile(
            rolePermissions[TEAM_JOB_TITLE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionJobTile(
            rolePermissions[TEAM_JOB_TITLE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionJobTile(
            rolePermissions[TEAM_JOB_TITLE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionJobTile(
            rolePermissions[TEAM_JOB_TITLE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_EMPLOYE_CATEGORY]) {
          setCreatePermissionEmployeCategory(
            rolePermissions[TEAM_EMPLOYE_CATEGORY]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionEmployeCategory(
            rolePermissions[TEAM_EMPLOYE_CATEGORY]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionEmployeCategory(
            rolePermissions[TEAM_EMPLOYE_CATEGORY]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionEmployeCategory(
            rolePermissions[TEAM_EMPLOYE_CATEGORY]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_EMPLOYE_TYPE]) {
          setCreatePermissionEmployeType(
            rolePermissions[TEAM_EMPLOYE_TYPE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionEmployeType(
            rolePermissions[TEAM_EMPLOYE_TYPE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionEmployeType(
            rolePermissions[TEAM_EMPLOYE_TYPE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionEmployeType(
            rolePermissions[TEAM_EMPLOYE_TYPE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_ABSENCE_TYPE]) {
          setCreatePermissionAbsenceType(
            rolePermissions[TEAM_ABSENCE_TYPE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAbsenceType(
            rolePermissions[TEAM_ABSENCE_TYPE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAbsencetype(
            rolePermissions[TEAM_ABSENCE_TYPE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAbsenceType(
            rolePermissions[TEAM_ABSENCE_TYPE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_ABSENCE_REASON]) {
          setCreatePermissionAbsenceReason(
            rolePermissions[TEAM_ABSENCE_REASON]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAbsenceReason(
            rolePermissions[TEAM_ABSENCE_REASON]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAbsenceReason(
            rolePermissions[TEAM_ABSENCE_REASON]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAbsenceReason(
            rolePermissions[TEAM_ABSENCE_REASON]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_ABSENCE_INFO]) {
          setCreatePermissionAbsenceInformed(
            rolePermissions[TEAM_ABSENCE_INFO]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAbsenceInformed(
            rolePermissions[TEAM_ABSENCE_INFO]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAbsenceInformed(
            rolePermissions[TEAM_ABSENCE_INFO]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAbsenceInformed(
            rolePermissions[TEAM_ABSENCE_INFO]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_ABSENCE_PLANNED]) {
          setCreatePermissionAbsencePlanned(
            rolePermissions[TEAM_ABSENCE_PLANNED]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAbsencePlanned(
            rolePermissions[TEAM_ABSENCE_PLANNED]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAbsencePlanned(
            rolePermissions[TEAM_ABSENCE_PLANNED]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAbsencePlanned(
            rolePermissions[TEAM_ABSENCE_PLANNED]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_ABSENCE_PAID]) {
          setCreatePermissionAbsencePaid(
            rolePermissions[TEAM_ABSENCE_PAID]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAbsencePaid(
            rolePermissions[TEAM_ABSENCE_PAID]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAbsencePaid(
            rolePermissions[TEAM_ABSENCE_PAID]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAbsencePaid(
            rolePermissions[TEAM_ABSENCE_PAID]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_COMPENTECY]) {
          setCreatePermissionCompetencyName(
            rolePermissions[TEAM_COMPENTECY]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionCompetencyName(
            rolePermissions[TEAM_COMPENTECY]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionCompetencyName(
            rolePermissions[TEAM_COMPENTECY]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionCompetencyName(
            rolePermissions[TEAM_COMPENTECY]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_SHIFT]) {
          setCreatePermissionShiftType(
            rolePermissions[TEAM_SHIFT]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionShiftType(
            rolePermissions[TEAM_SHIFT]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionShiftType(
            rolePermissions[TEAM_SHIFT]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionShiftType(
            rolePermissions[TEAM_SHIFT]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }

        if (rolePermissions[TEAM_VISA]) {
          setCreatePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }
        if (rolePermissions[TEAM_LEAVER_REASON]) {
          setCreatePermissionLeaverReason(
            rolePermissions[TEAM_LEAVER_REASON]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionLeaverReason(
            rolePermissions[TEAM_LEAVER_REASON]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionLeaverReason(
            rolePermissions[TEAM_LEAVER_REASON]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionLeaverReason(
            rolePermissions[TEAM_LEAVER_REASON]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_REFERENCE_STATUS]) {
          setCreatePermissionReferenceStatus(
            rolePermissions[TEAM_REFERENCE_STATUS]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionReferenceStatus(
            rolePermissions[TEAM_REFERENCE_STATUS]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionReferenceStatus(
            rolePermissions[TEAM_REFERENCE_STATUS]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionReferenceStatus(
            rolePermissions[TEAM_REFERENCE_STATUS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_APPLICANT_SOURCE]) {
          setCreatePermissionApplicantSource(
            rolePermissions[TEAM_APPLICANT_SOURCE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionApplicantSource(
            rolePermissions[TEAM_APPLICANT_SOURCE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionApplicantSource(
            rolePermissions[TEAM_APPLICANT_SOURCE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionApplicantSource(
            rolePermissions[TEAM_APPLICANT_SOURCE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_BILLING_TERM]) {
          setCreatePermissionBillingTerms(
            rolePermissions[TEAM_BILLING_TERM]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionBillingTerms(
            rolePermissions[TEAM_BILLING_TERM]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionBillingTerms(
            rolePermissions[TEAM_BILLING_TERM]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionBillingTerms(
            rolePermissions[TEAM_BILLING_TERM]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_BILLING_PATTERN]) {
          setCreatePermissionBillingPatterns(
            rolePermissions[TEAM_BILLING_PATTERN]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionBillingPatterns(
            rolePermissions[TEAM_BILLING_PATTERN]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionLBillingPatterns(
            rolePermissions[TEAM_BILLING_PATTERN]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionBillingPatterns(
            rolePermissions[TEAM_BILLING_PATTERN]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_TRAINGING_TYPE]) {
          setCreatePermissionTrainingType(
            rolePermissions[TEAM_TRAINGING_TYPE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionTrainingType(
            rolePermissions[TEAM_TRAINGING_TYPE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionTrainingType(
            rolePermissions[TEAM_TRAINGING_TYPE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionTrainingType(
            rolePermissions[TEAM_TRAINGING_TYPE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_REGISTRATION_TYPE]) {
          setCreatePermissionRegistrationType(
            rolePermissions[TEAM_REGISTRATION_TYPE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionRegistrationType(
            rolePermissions[TEAM_REGISTRATION_TYPE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionRegistrationType(
            rolePermissions[TEAM_REGISTRATION_TYPE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionRegistrationType(
            rolePermissions[TEAM_REGISTRATION_TYPE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (rolePermissions[TEAM_ETHENCITY]) {
          setCreatePermissionEthnicity(
            rolePermissions[TEAM_ETHENCITY]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionEthnicity(
            rolePermissions[TEAM_ETHENCITY]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionEthnicity(
            rolePermissions[TEAM_ETHENCITY]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionEthnicity(
            rolePermissions[TEAM_ETHENCITY]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_TITLE]) {
          setCreatePermissionTitle(
            rolePermissions[TEAM_TITLE]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionTilte(
            rolePermissions[TEAM_TITLE]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionTitle(
            rolePermissions[TEAM_TITLE]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionTitle(
            rolePermissions[TEAM_TITLE]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }
        if (rolePermissions[TEAM_ANIMAL]) {
          setCreatePermissionAnimal(
            rolePermissions[TEAM_ANIMAL]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionAnimal(
            rolePermissions[TEAM_ANIMAL]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionAnimal(
            rolePermissions[TEAM_ANIMAL]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionAnimal(
            rolePermissions[TEAM_TITLE]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }

        if (rolePermissions[TEAM_VISA]) {
          setCreatePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionVisaStatus(
            rolePermissions[TEAM_VISA]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }
        if (rolePermissions[TEAM_ESTABLISHMENT_TYPE]) {
          setCreatePermissionEstablishment(
            rolePermissions[TEAM_ESTABLISHMENT_TYPE]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionEstablishment(
            rolePermissions[TEAM_ESTABLISHMENT_TYPE]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionEstablishment(
            rolePermissions[TEAM_ESTABLISHMENT_TYPE]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionEstablishment(
            rolePermissions[TEAM_ESTABLISHMENT_TYPE]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }

        if (rolePermissions[TEAM_COMPETENCY_MATRIX]) {
          setReadPermissionCompetencyMatrix(rolePermissions[TEAM_COMPETENCY_MATRIX]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionCompetencyMatrix(rolePermissions[TEAM_COMPETENCY_MATRIX]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionCompetencyMatrix(rolePermissions[TEAM_COMPETENCY_MATRIX]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionCompetencyMatrix(rolePermissions[TEAM_COMPETENCY_MATRIX]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])
        }
        if (rolePermissions[TEAM_SKILL_MATRIX]) {
          setReadPermissionSkillMatrix(rolePermissions[TEAM_SKILL_MATRIX]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionSkillMatrix(rolePermissions[TEAM_SKILL_MATRIX]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionSkillMatrix(rolePermissions[TEAM_SKILL_MATRIX]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionSkillMatrix(rolePermissions[TEAM_SKILL_MATRIX]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])
        }

        if (rolePermissions[SKILL_CATEGORY]) {
          setReadPermissionSkillCategory(rolePermissions[SKILL_CATEGORY]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionSkillCategory(rolePermissions[SKILL_CATEGORY]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionSkillCategory(rolePermissions[SKILL_CATEGORY]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionSkillCategory(rolePermissions[SKILL_CATEGORY]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])

        }

        if (rolePermissions[JOB_DEPARTMENT]) {
          setReadPermissionJobDepartment(rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][WEB_READ_PERMISSION] || rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
          setUpdatePermissionJobDepartment(rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION] || rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
          setCreatePermissionJobDepartment(rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION] || rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
          setDeletePermissionJobDepartment(rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION] ||rolePermissions[JOB_DEPARTMENT]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
        }

        console.log("competency-", rolePermissions[TEAM_COMPETENCY_MATRIX], "Skill-", rolePermissions[TEAM_SKILL_MATRIX])
      }
    }
  }, [
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  ]);

  const handleAccordionClick = () => {
    setEmploymentTypes(!employementType);
  };

  const handleEmployeCategory = () => {
    setEmployeeCategorys(!employeCategory);
  };

  const handleJobTitle = () => {
    setJobTitles(!jobTiltle);
  };

  const handleJobDepartment = () => {
    setJobDepartment(!jobDepartment)
  }

  const handleSkills = () => {
    setSkills(!skills);
  };
  const handleShift = () => {
    setShiftType(!shiftType);
  };
  const handleAbsenceType = () => {
    setAbsenceType(!absenceType);
  };
  const handleAbsenceReason = () => {
    setAbsenceResons(!absenceResasons);
  };
  const handleAbsenceInfo = () => {
    setAbsenceInfo(!absenceInfo);
  };
  const handlePlanned = () => {
    setPlannedType(!plannedType);
  };
  const handlePaid = () => {
    setPaidType(!paidType);
  };
  const handleCompentency = () => {
    setCompentencys(!compentencies);
  };
  const handleVisa = () => {
    setVisaStatuss(!visaStatus);
  };
  const handleReference = () => {
    setReferenceStatus(!referenceStatus);
  };
  const handleApplicanr = () => {
    setApplicantSources(!applicantSource);
  };
  const handleLiver = () => {
    setLeaverReason(!liverReason);
  };
  const handleEthencity = () => {
    setEthencities(!ethencities);
  };
  const handleEstablishment = () => {
    setEstablishments(!establishment);
  };
  const handleTitles = () => {
    setTitles(!titles);
  };
  const handleAnimals = () => {
    setAnimals(!animals);
  };
  const handleSupportLevel = () => {
    setSupportLevels(!supportLevels);
  };
  const handleBillingTerm = () => {
    setBillingTerms(!billingTerms);
  };
  const handleBillingPattern = () => {
    setBillingPatterns(!billingPatterns);
  };
  const handleTrainingType = () => {
    setTrainingType(!trainingType);
  };
  const handleRegistrationType = () => {
    setRegistrationType(!registrationType);
  };

  const handleContactMethod = () => {
    setHideContactMethod(!hideContactMethod);
  };

  const handleMarketing = () => {
    setHideMarketing(!hideMarketing);
  };

  const handleSickness = () => {
    setHideSicknessReason(!HideSicknessReason);
  };
  const handlePronounCategory = () => {
    setHidePronounCategory(!HidePronounCategory);
  };

  const handleBillRate = () => {
    setHideBillRate(!hideBillRate);
  };
  const handleBillDuration = () => {
    setHideBillDuration(!hideBillDuration);
  };
  const handlePayRate = () => {
    setHidePayRate(!hidePayRate);
  };
  const handlePayDuration = () => {
    setHidePayDuration(!hidePayDuration);
  };

  const handleEthnicityDescription = () => {
    setHideEthnicityDescription(!hideEthnicityDescription);
  };
  const handleJobRoleSpecialist = () => {
    setHideJobRoleSpecialist(!hideJobRoleSpecialist);
  };
  const handleProbationTerm = () => {
    setHideProbationTerm(!hideProbationTerm);
  };
  const handleRecruitmentSource = () => {
    setHideRecruitmentSource(!hideRecruitmentSource);
  };

  const handleEmployerNoticePeriod = () => {
    setHideEmployerNoticePeriod(!hideEmployerNoticePeriod);
  };
  const handleCountry = () => {
    setHideCountry(!hideCountry);
  };
  const handleCareCertificate = () => {
    setHideCareCertificate(!hideCareCertificate);
  };
  const handleSocialCare = () => {
    setHideSocialCare(!hideSocialCare);
  };
  const handleCommonCore = () => {
    setHideCommonCore(!hideCommonCore);
  };

  const handleSkillCategory = () => {
    setHideSkillCategory(!hideSkillCategory)
  };
  const handleAccreditedTraining = () => {
    setHideAccreditedTraining(!hideAccreditedTraining);
  };

  const handleApprenticeshipName = () => {
    setHideApprenticeshipName(!hideApprenticeshipName);
  };

  const handleTriage = () => {
    setHideTriage(!hideTriage);
  };

  const handleTask = () => {
    setHideTask(!hideTask);
  };

  const handlePayType = () => setPayType(!payType)

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };


  const dropDownHandleSelect = (item, name) => {
    console.log(item, "item001")
    if (name == 'competency_branch') {
      values.competencyMatrix.branch = item.branch.map((item) => (item.value));
    }
    else if (name == "competency") {
      values.competencyMatrix.competency = item.competency.map((item) => (item.value));
    } else if (name == "skill_branch") {
      values.skillMatrix.branch = item.branch.map((item) => (item.value));

    } else if (name == "skillCategory") {
      values.skillMatrix.skillCategory = item.skillCategory.map((item) => (item.value));

    } else if (name == "skill") {
      values.skillMatrix.skill = item.skill.map((item) => (item.value));

    }
    setValues({ ...values });
  };

  return (
    <div>
      <Title className={'ml-1 mb-2'}>Settings team</Title>
      <ModalTabsContainer>

        <TabStyled
          active={isCurrentTab(tab1)}
          onClick={() => {
            setTabSelected(tab1)
          }}>
          Personal Details
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab2)}
          onClick={() => {
            setTabSelected(tab2)
          }}>
          Job Details
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab3)}
          onClick={() => {
            setTabSelected(tab3)
          }}>
          Compliance
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab4)}
          onClick={() => {
            setTabSelected(tab4)
          }}>
          Employee Details
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab5)}
          onClick={() => {
            setTabSelected(tab5)
          }}>
          Skills
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab6)}
          onClick={() => {
            setTabSelected(tab6)
          }}>
          Regular shift
        </TabStyled>

        <TabStyled
          active={isCurrentTab(tab7)}
          onClick={() => {
            setTabSelected(tab7)
          }}>
          Triage
        </TabStyled>

        {readPermissionSkillMatrix && (
          <TabStyled
            active={isCurrentTab(tab8)}
            onClick={() => {
              setTabSelected(tab8)
            }}>
            Skill Matrix
          </TabStyled>)
        }
        {readPermissionCompetencyMatrix && (
          <TabStyled
            active={isCurrentTab(tab9)}
            onClick={() => {
              setTabSelected(tab9)
              // history.push(SETTINGS + SETTINGS_TEAM + COMPETENCIES_SETTINGS)                  
            }}>
            Competency Matrix
          </TabStyled>)}
        {/* <LinkTabStyled active={isCurrentTab(tab9)} >
          <Link to={SETTINGS + SETTINGS_TEAM + COMPETENCIES_SETTINGS} className={'styledLink'} >
            <LinkTab active={isCurrentTab(tab9)} >
              Competency Matrix
            </LinkTab>
          </Link>
        </LinkTabStyled> */}

      </ModalTabsContainer>

      <ShowTeamSettingModalTab show={isCurrentTab(tab1)}>

        {/* Title */}
        <SettingsTemplateContainer collapsed={titles}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleTitles}>
              <div>
                {titles ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Title</TitleSection>
          </AccordionContainer>
          {!titles && (
            <Template
              titleSection={'Title'}
              labelInputTitle={'Title'}
              getMethod={getTitile}
              deleteMethod={deleteTitle}
              setMethod={setTitle}
              handleChangeChoices={() => handleChangeChoices('title ')}
              textToMessageDelete={'Title'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionTitle}
              // create={createPermissionTitle}
              // deletePermission={deletePermissionTilte}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Contact Method */}
        <SettingsTemplateContainer collapsed={hideContactMethod}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleContactMethod}>
              <div>
                {hideContactMethod ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Contact Method </TitleSection>
          </AccordionContainer>
          {!hideContactMethod && (
            <Template
              titleSection={'Contact Method '}
              labelInputTitle={'Contact Method Type '}
              getMethod={getContactMethod}
              deleteMethod={deleteContactMethod}
              setMethod={setContactMethod}
              handleChangeChoices={() => handleChangeChoices('contact_method ')}
              textToMessageDelete={'Contact Method Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Marketing Method */}
        <SettingsTemplateContainer collapsed={hideMarketing}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleMarketing}>
              <div>
                {hideMarketing ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Marketing Method </TitleSection>
          </AccordionContainer>
          {!hideMarketing && (
            <Template
              titleSection={'Marketing Method  '}
              labelInputTitle={'Marketing Method  Type '}
              getMethod={getMarketing}
              deleteMethod={deleteMarketing}
              setMethod={setMarketing}
              handleChangeChoices={() =>
                handleChangeChoices('marketing_method ')
              }
              textToMessageDelete={'Marketing Method  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Pronoun Category */}
        <SettingsTemplateContainer collapsed={HidePronounCategory}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePronounCategory}>
              <div>
                {HidePronounCategory ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Pronoun Category </TitleSection>
          </AccordionContainer>
          {!HidePronounCategory && (
            <Template
              titleSection={'Pronoun Category  '}
              labelInputTitle={'Pronoun Category  Type '}
              getMethod={getPronounCategory}
              deleteMethod={deletePronounCategory}
              setMethod={setPronounCategory}
              handleChangeChoices={() =>
                handleChangeChoices('pronoun_category ')
              }
              textToMessageDelete={'Pronoun Category  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Country */}
        <SettingsTemplateContainer collapsed={hideCountry}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCountry}>
              <div>
                {hideCountry ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Country</TitleSection>
          </AccordionContainer>
          {!hideCountry && (
            <Template
              titleSection={'Country   '}
              labelInputTitle={'Country   Type '}
              getMethod={getCountry}
              deleteMethod={deleteCountry}
              setMethod={setCountry}
              handleChangeChoices={() => handleChangeChoices('country')}
              textToMessageDelete={'Country   Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab2)}>
        {/* Employment Type */}
        {/* {readPermissionEmployeType && ( */}
        <SettingsTemplateContainer collapsed={employementType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAccordionClick}>
              <div>
                {employementType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Employment Type</TitleSection>
          </AccordionContainer>
          {!employementType && (
            <Template
              titleSection={'Employment type'}
              labelInputTitle={'Employment type name'}
              getMethod={getEmploymentTypes}
              deleteMethod={deleteEmploymentType}
              setMethod={setEmploymentType}
              handleChangeChoices={() => handleChangeChoices('employment_type')}
              textToMessageDelete={'employment type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionEmployeType}
              // create={createPermissionEmployeType}
              // deletePermission={deletePermissionEmployeType}
              userStaff={userStaff}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Employee category */}
        {/* {readPermissionEmployeCategory && ( */}
        <SettingsTemplateContainer collapsed={employeCategory}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleEmployeCategory}>
              <div>
                {employeCategory ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Employee category</TitleSection>
          </AccordionContainer>
          {!employeCategory && (
            <Template
              titleSection={'Employee category'}
              labelInputTitle={'Employee category name'}
              getMethod={getEmployeeCategories}
              deleteMethod={deleteEmployeeCategory}
              setMethod={setEmployeeCategory}
              handleChangeChoices={() =>
                handleChangeChoices('employee_category')
              }
              textToMessageDelete={'employee category'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionEmployeCategory}
              // create={createPermissionEmployeCategory}
              // deletePermission={deletePermissionEmployeCategory}
              userStaff={userStaff}
              createPermissionEmployeCategory={createPermissionEmployeCategory}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Job title */}
        {/* {readPermissionJobTile && ( */}
        <SettingsTemplateContainer collapsed={jobTiltle}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleJobTitle}>
              <div>
                {jobTiltle ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Job title</TitleSection>
          </AccordionContainer>
          {!jobTiltle && (
            <Template
              titleSection={'Job title'}
              labelInputTitle={'Job title name'}
              getMethod={getJobTitles}
              deleteMethod={deleteJobTitle}
              setMethod={setJobTitle}
              handleChangeChoices={() => handleChangeChoices('job_title')}
              textToMessageDelete={'job title'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionJobTile}
              // create={createPermissionJobTile}
              // deletePermission={deletePermissionJobTile}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Job Department */}
        <SettingsTemplateContainer collapsed={jobDepartment}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleJobDepartment}>
              <div>
                {jobDepartment ? (
                  <IoIosArrowUp size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowDown size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Job department</TitleSection>
          </AccordionContainer>
          {!jobDepartment && (
            <Template
              titleSection={'Job department'}
              labelInputTitle={'Job department name'}
              getMethod={getJobDepartment}
              deleteMethod={deleteJobDepartment}
              setMethod={setJobDepartmentApi}
              handleChangeChoices={() => handleChangeChoices('job_category')}
              textToMessageDelete={'job department'}
              textToEmptyDataValues={'No job department have been added'}
              fieldAttributes={{ maxLength: 50 }}
              update={updatePermissionJobDepartment}
              create={createPermissionJobDepartment}
              deletePermission={deletePermissionJobDepartment}
              // update={updatePermissionJobTile}
              // create={createPermissionJobTile}
              // deletePermission={deletePermissionJobTile}
              userStaff={userStaff}

            />
          )}
        </SettingsTemplateContainer>

        {/* Animal */}
        <SettingsTemplateContainer collapsed={animals}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAnimals}>
              <div>
                {animals ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Animal </TitleSection>
          </AccordionContainer>
          {!animals && (
            <Template
              titleSection={'Animal'}
              labelInputTitle={'Animal'}
              getMethod={getAnimal}
              deleteMethod={deleteAnimal}
              setMethod={setAnimal}
              handleChangeChoices={() => handleChangeChoices('animal ')}
              textToMessageDelete={'Animal'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionAnimal}
              // create={createPermissionAnimal}
              // deletePermission={deletePermissionAnimal}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* Billing Terms */}
        <SettingsTemplateContainer collapsed={billingTerms}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleBillingTerm}>
              <div>
                {billingTerms ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Billing Terms </TitleSection>
          </AccordionContainer>
          {!billingTerms && (
            <Template
              titleSection={'Billing Terms'}
              labelInputTitle={'Billing Terms'}
              getMethod={getBillingTerms}
              deleteMethod={deleteBillingTerms}
              setMethod={setBillingTerm}
              handleChangeChoices={() => handleChangeChoices('billing_terms ')}
              textToMessageDelete={'Billing Terms'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionBillingTerms}
              // create={createPermissionBillingTerms}
              // deletePermission={deletePermissionBillingTerms}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Billing Patterns */}
        <SettingsTemplateContainer collapsed={billingPatterns}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleBillingPattern}>
              <div>
                {billingPatterns ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Billing Patterns </TitleSection>
          </AccordionContainer>
          {!billingPatterns && (
            <Template
              titleSection={'Billing Patterns'}
              labelInputTitle={'Billing Patterns'}
              getMethod={getBillingPattern}
              deleteMethod={deleteBillingPattern}
              setMethod={setBillingPattern}
              handleChangeChoices={() =>
                handleChangeChoices('billing_patterns ')
              }
              textToMessageDelete={'Billing Patterns'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionBillingPatterns}
              // create={createPermissionBillingPatterns}
              // deletePermission={deletePermissionBillingPatterns}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Bill Rate Model */}
        <SettingsTemplateContainer collapsed={hideBillRate}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleBillRate}>
              <div>
                {hideBillRate ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Bill Rate Model </TitleSection>
          </AccordionContainer>
          {!hideBillRate && (
            <Template
              titleSection={'Bill Rate Model  '}
              labelInputTitle={'Bill Rate Model  Type '}
              getMethod={getBillRate}
              deleteMethod={deleteBillRate}
              setMethod={setBillRate}
              handleChangeChoices={() =>
                handleChangeChoices('bill_rate_model ')
              }
              textToMessageDelete={'Bill Rate Model  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Billing Duration Type */}
        <SettingsTemplateContainer collapsed={hideBillDuration}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleBillDuration}>
              <div>
                {hideBillDuration ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Billing Duration Type </TitleSection>
          </AccordionContainer>
          {!hideBillDuration && (
            <Template
              titleSection={'Billing Duration  '}
              labelInputTitle={'Billing Duration  Type '}
              getMethod={getBillDuration}
              deleteMethod={deleteBillDuration}
              setMethod={setBillDuration}
              handleChangeChoices={() =>
                handleChangeChoices('billing_duration_type ')
              }
              textToMessageDelete={'Billing Duration  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Pay Rate Model */}
        <SettingsTemplateContainer collapsed={hidePayRate}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePayRate}>
              <div>
                {hidePayRate ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Pay Rate Model </TitleSection>
          </AccordionContainer>
          {!hidePayRate && (
            <Template
              titleSection={'Pay Rate Model  '}
              labelInputTitle={'Pay Rate Model  Type '}
              getMethod={getPayRate}
              deleteMethod={deletePayRate}
              setMethod={setPayRate}
              handleChangeChoices={() => handleChangeChoices('pay_rate_model ')}
              textToMessageDelete={'Pay Rate Model  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Pay Duration Type */}
        <SettingsTemplateContainer collapsed={hidePayDuration}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePayDuration}>
              <div>
                {hidePayDuration ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Pay Duration Type </TitleSection>
          </AccordionContainer>
          {!hidePayDuration && (
            <Template
              titleSection={'Pay Duration  '}
              labelInputTitle={'Pay Duration  Type '}
              getMethod={getPayDuration}
              deleteMethod={deletePayDuration}
              setMethod={setPayDuration}
              handleChangeChoices={() =>
                handleChangeChoices('pay_duration_type  ')
              }
              textToMessageDelete={'Pay Duration  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Job Role Specialist Area */}
        <SettingsTemplateContainer collapsed={hideJobRoleSpecialist}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleJobRoleSpecialist}>
              <div>
                {hideJobRoleSpecialist ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Job Role Specialist Area </TitleSection>
          </AccordionContainer>
          {!hideJobRoleSpecialist && (
            <Template
              titleSection={'Job Role Specialist Area   '}
              labelInputTitle={'Job Role Specialist Area   Type '}
              getMethod={getJobRoleSpecialist}
              deleteMethod={deleteJobRoleSpecialist}
              setMethod={setJobRoleSpecialist}
              handleChangeChoices={() =>
                handleChangeChoices('job_role_specialist_area')
              }
              textToMessageDelete={'Job Role Specialist Area   Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Probation Term */}
        <SettingsTemplateContainer collapsed={hideProbationTerm}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleProbationTerm}>
              <div>
                {hideProbationTerm ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Probation Term </TitleSection>
          </AccordionContainer>
          {!hideProbationTerm && (
            <Template
              titleSection={'Probation Term    '}
              labelInputTitle={'Probation Term    Type '}
              getMethod={getProbationTerm}
              deleteMethod={deleteProbation}
              setMethod={setProbationTerm}
              handleChangeChoices={() => handleChangeChoices('probation_term')}
              textToMessageDelete={'Probation Term    Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Recruitment Source */}
        <SettingsTemplateContainer collapsed={hideRecruitmentSource}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleRecruitmentSource}>
              <div>
                {hideRecruitmentSource ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Recruitment Source</TitleSection>
          </AccordionContainer>
          {!hideRecruitmentSource && (
            <Template
              titleSection={'Recruitment Source    '}
              labelInputTitle={'Recruitment Source    Type '}
              getMethod={getRecruitmentSource}
              deleteMethod={deleteRecruitmentSource}
              setMethod={setRecruitmentSource}
              handleChangeChoices={() =>
                handleChangeChoices('recruitment_source')
              }
              textToMessageDelete={'Recruitment Source    Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Employer Notice Period */}
        <SettingsTemplateContainer collapsed={hideEmployerNoticePeriod}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleEmployerNoticePeriod}>
              <div>
                {hideEmployerNoticePeriod ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Employer Notice Period</TitleSection>
          </AccordionContainer>
          {!hideEmployerNoticePeriod && (
            <Template
              titleSection={'Employer Notice Period   '}
              labelInputTitle={'Employer Notice Period   Type '}
              getMethod={getEmployerNoticePeriod}
              deleteMethod={deleteEmployerNoticePeriod}
              setMethod={setEmployerNoticePeriod}
              handleChangeChoices={() =>
                handleChangeChoices('employer_notice_period')
              }
              textToMessageDelete={'Employer Notice Period   Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab3)}>
        {/* Visa Status */}
        {/* {readPermissionVisaStatus && ( */}
        <SettingsTemplateContainer collapsed={visaStatus}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleVisa}>
              <div>
                {visaStatus ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Visa Status</TitleSection>
          </AccordionContainer>
          {!visaStatus && (
            <Template
              titleSection={'Visa Status'}
              labelInputTitle={'Visa Status'}
              getMethod={getVisaStatus}
              deleteMethod={deleteVisaStatus}
              setMethod={setVisaStatus}
              handleChangeChoices={() => handleChangeChoices('Visa_Status')}
              textToMessageDelete={'Visa Status'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionVisaStatus}
              // create={createPermissionVisaStatus}
              // deletePermission={deletePermissionVisaStatus}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Reference Status */}
        {/* {readPermissionReferenceStatus && ( */}
        <SettingsTemplateContainer collapsed={referenceStatus}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleReference}>
              <div>
                {referenceStatus ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Reference Status</TitleSection>
          </AccordionContainer>
          {!referenceStatus && (
            <Template
              titleSection={'Reference Status'}
              labelInputTitle={'Reference Status'}
              getMethod={getReferenceStatus}
              deleteMethod={deleteReferenceStatus}
              setMethod={setRefrenceStatus}
              handleChangeChoices={() =>
                handleChangeChoices('reference_status')
              }
              textToMessageDelete={'Reference_Status'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionReferenceStatus}
              // create={createPermissionReferenceStatus}
              // deletePermission={deletePermissionReferenceStatus}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Applicant Source */}
        {/* {readPermissionApplicantSource && ( */}
        <SettingsTemplateContainer collapsed={applicantSource}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleApplicanr}>
              <div>
                {applicantSource ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Applicant Source</TitleSection>
          </AccordionContainer>
          {!applicantSource && (
            <Template
              titleSection={'Applicant Source'}
              labelInputTitle={'Applicant Source'}
              getMethod={getApplicantSource}
              deleteMethod={deleteApplicantSource}
              setMethod={setApplicantSource}
              handleChangeChoices={() =>
                handleChangeChoices('Applicant_Source')
              }
              textToMessageDelete={'Applicant Source'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionApplicantSource}
              // create={createPermissionApplicantSource}
              // deletePermission={deletePermissionApplicantSource}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Leaver Reason */}
        {/* {readPermissionLeaverReason && ( */}
        <SettingsTemplateContainer collapsed={liverReason}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleLiver}>
              <div>
                {liverReason ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Leaver Reason</TitleSection>
          </AccordionContainer>
          {!liverReason && (
            <Template
              titleSection={'Leaver Reason'}
              labelInputTitle={'Leaver Reason'}
              getMethod={getLeaverReason}
              deleteMethod={deleteLeaverReason}
              setMethod={setLeaverReasons}
              handleChangeChoices={() => handleChangeChoices('leaver_reason')}
              textToMessageDelete={'Leaver Reason'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionLeaverReason}
              // create={createPermissionLeaverReason}
              // deletePermission={deletePermissionLeaverReason}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* ) } */}

        {/* Registration Type */}
        {/* {readPermissionRegistrationType && ( */}
        <SettingsTemplateContainer collapsed={registrationType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleRegistrationType}>
              <div>
                {registrationType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Registration Type </TitleSection>
          </AccordionContainer>
          {!registrationType && (
            <Template
              titleSection={'Registration Type '}
              labelInputTitle={'Registration Type '}
              getMethod={getRegistrationType}
              deleteMethod={deleteRagistration}
              setMethod={setRegistrationTypes}
              handleChangeChoices={() =>
                handleChangeChoices('registration_type')
              }
              textToMessageDelete={'Registration Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // create={createPermissionRegistrationType}
              // deletePermission={deletePermissionRegistrationType}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab4)}>
        {/* Ethnicity */}
        {/* {readPermissionEthnicity && ( */}
        <SettingsTemplateContainer collapsed={ethencities}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleEthencity}>
              <div>
                {ethencities ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Ethnicity</TitleSection>
          </AccordionContainer>
          {!ethencities && (
            <Template
              titleSection={'Ethnicity'}
              labelInputTitle={'Ethnicity'}
              getMethod={getEthencity}
              deleteMethod={deleteEthencity}
              setMethod={setEthencity}
              handleChangeChoices={() => handleChangeChoices('ethnicity ')}
              textToMessageDelete={'Ethnicity'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionEthnicity}
              // create={createPermissionEthnicity}
              // deletePermission={deletePermissionEthnicity}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Establishment */}
        {/* {readPermissionEstablishment && ( */}
        <SettingsTemplateContainer collapsed={establishment}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleEstablishment}>
              <div>
                {establishment ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Establishment</TitleSection>
          </AccordionContainer>
          {!establishment && (
            <Template
              titleSection={'Establishment'}
              labelInputTitle={'Establishment'}
              getMethod={getEstablishment}
              deleteMethod={deleteEstablishment}
              setMethod={setEstablishment}
              handleChangeChoices={() =>
                handleChangeChoices('establishment_type ')
              }
              textToMessageDelete={'Establishment'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionEstablishment}
              // create={createPermissionEstablishment}
              // deletePermission={deletePermissionEstablishment}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Ethnicity Description */}
        <SettingsTemplateContainer collapsed={hideEthnicityDescription} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleEthnicityDescription}>
              <div>
                {hideEthnicityDescription ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Ethnicity Description </TitleSection>
          </AccordionContainer>
          {!hideEthnicityDescription && (
            <Template
              titleSection={'Ethnicity Description  '}
              labelInputTitle={'Ethnicity Description  Type '}
              getMethod={getEthnicityDescription}
              deleteMethod={deleteEthencityDescription}
              setMethod={setEthnicityDescription}
              handleChangeChoices={() =>
                handleChangeChoices('ethnicity_description')
              }
              textToMessageDelete={'Ethnicity Description  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab5)}>
        {/* Competency */}
        {/* {readPermissionCompetencyName &&( */}
        <SettingsTemplateContainer collapsed={compentencies}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCompentency}>
              <div>
                {compentencies ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Competency</TitleSection>
          </AccordionContainer>
          {!compentencies && (
            <Template
              titleSection={'Competency'}
              labelInputTitle={'Competency'}
              getMethod={getCompentency}
              deleteMethod={deleteCompentency}
              setMethod={setCompentency}
              handleChangeChoices={() => handleChangeChoices('competency_name')}
              textToMessageDelete={'Competency'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionCompetencyName}
              // create={createPermissionCompetencyName}
              // deletePermission={deletePermissionCompetencyName}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Training Type */}
        {/* {readPermissionTrainingType && ( */}
        <SettingsTemplateContainer collapsed={trainingType} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleTrainingType}>
              <div>
                {trainingType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Training Type </TitleSection>
          </AccordionContainer>
          {!trainingType && (
            <Template
              titleSection={'Training Type '}
              labelInputTitle={'Training Type '}
              getMethod={getTraingType}
              deleteMethod={deleteTrainingType}
              setMethod={setTrainingTypes}
              handleChangeChoices={() => handleChangeChoices('training_type  ')}
              textToMessageDelete={'Training Type '}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionTrainingType}
              // create={createPermissionTrainingType}
              // deletePermission={deletePermissionTrainingType}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Skill Type */}
        {/* {readPermissionSkill && ( */}
        <SettingsTemplateContainer collapsed={skills}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSkills}>
              <div>
                {skills ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Skill Type </TitleSection>
          </AccordionContainer>
          {!skills && (
            <Template
              titleSection={'Skill '}
              labelInputTitle={'Skill Type '}
              getMethod={getSkill}
              deleteMethod={deleteSkill}
              setMethod={setSkill}
              handleChangeChoices={() => handleChangeChoices('skill')}
              textToMessageDelete={'Skill Type '}
              addOneDropdown={{
                placeholder: 'Skill Category',
                required: true,
                value: 'category',
                options: choices,
                choicesValue: 'skill_category',
              }}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
              addInput={[
                {
                  label: 'Frequency',
                  value: 'reapplication_frequency',
                  type: "text",
                  onKeyPress: KeyPress.INTEGER,
                  required: false,
                  maxWidth: 80
                },
                {
                  label: 'Reminder',
                  value: 'reminder_warning',
                  type: "text",
                  onKeyPress: KeyPress.INTEGER,
                  required: false,
                  maxWidth: 80
                }
              ]}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Care Certificate */}
        <SettingsTemplateContainer collapsed={hideCareCertificate}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareCertificate}>
              <div>
                {hideCareCertificate ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Certificate</TitleSection>
          </AccordionContainer>
          {!hideCareCertificate && (
            <Template
              titleSection={'Care Certificate   '}
              labelInputTitle={'Care Certificate   Type '}
              getMethod={getCareCertificate}
              deleteMethod={deleteCareCertificate}
              setMethod={setCareCertificate}
              handleChangeChoices={() =>
                handleChangeChoices('care_certificate')
              }
              textToMessageDelete={'Care Certificate   Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Social Care Qualification */}
        <SettingsTemplateContainer collapsed={hideSocialCare}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSocialCare}>
              <div>
                {hideSocialCare ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Social Care Qualification</TitleSection>
          </AccordionContainer>
          {!hideSocialCare && (
            <Template
              titleSection={'Social Care Qualification  '}
              labelInputTitle={'Social Care Qualification  Type '}
              getMethod={getSocialCare}
              deleteMethod={deleteSocialCareQualification}
              setMethod={setSocialCare}
              handleChangeChoices={() =>
                handleChangeChoices('social_care_qualification')
              }
              textToMessageDelete={'Social Care Qualification  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Qualification and Apprenticeship Name */}
        <SettingsTemplateContainer collapsed={hideApprenticeshipName} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleApprenticeshipName}>
              <div>
                {hideApprenticeshipName ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection> Qualification and Apprenticeship Name</TitleSection>
          </AccordionContainer>
          {!hideApprenticeshipName && (
            <Template
              titleSection={'Apprenticeship Name '}
              labelInputTitle={'Apprenticeship Name  '}
              getMethod={getApprenticeshipName}
              deleteMethod={deleteApprenticeshipName}
              setMethod={setApprenticeshipName}
              handleChangeChoices={() =>
                handleChangeChoices('apprenticeship_name')
              }
              textToMessageDelete={'Apprenticeship Name  '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Accredited Training */}
        <SettingsTemplateContainer collapsed={hideAccreditedTraining}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAccreditedTraining}>
              <div>
                {hideAccreditedTraining ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Accredited Training</TitleSection>
          </AccordionContainer>
          {!hideAccreditedTraining && (
            <Template
              titleSection={'Accredited Training  '}
              labelInputTitle={'Accredited Training  Type '}
              getMethod={getAccreditedTraining}
              deleteMethod={deleteAccreditedTraining}
              setMethod={setAccreditedTraining}
              handleChangeChoices={() =>
                handleChangeChoices('accredited_training')
              }
              textToMessageDelete={'Accredited Training  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Common Core Confirmation */}
        <SettingsTemplateContainer collapsed={hideCommonCore} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCommonCore}>
              <div>
                {hideCommonCore ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Approved Mental Health Professional</TitleSection>
          </AccordionContainer>
          {!hideCommonCore && (
            <Template
              titleSection={'Common Core Confirmation  '}
              labelInputTitle={'Common Core Confirmation  Type '}
              getMethod={getCommonCore}
              deleteMethod={deleteCommonCore}
              setMethod={setCommonCore}
              handleChangeChoices={() =>
                handleChangeChoices('common_core_confirmation')
              }
              textToMessageDelete={'Common Core Confirmation  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Skill Category */}
        {readPermissionSkillCategory && (
          <SettingsTemplateContainer collapsed={hideSkillCategory} >
            <AccordionContainer style={{ marginLeft: '40px' }}>
              <AccordionArrow onClick={handleSkillCategory}>
                <div>
                  {hideSkillCategory ? (
                    <IoIosArrowDown size={18} color={PURPLE} />
                  ) : (
                    <IoIosArrowUp size={18} color={PURPLE} />
                  )}
                </div>
              </AccordionArrow>
              <TitleSection>Skill Category</TitleSection>
            </AccordionContainer>
            {!hideSkillCategory && (
              <Template
                titleSection={'Skill Category  '}
                labelInputTitle={'Skill Category '}
                getMethod={getSkillCategory}
                deleteMethod={deleteTeamCareCategory}
                setMethod={setSkillCategory}
                handleChangeChoices={() => handleChangeChoices('skill_category')}
                textToMessageDelete={'Skill Category '}
                fieldAttributes={{ maxLength: 50 }}
                update={updatePermissionSkillCategory}
                create={createPermissionSkillCategory}
                deletePermission={deletePermissionSkillCategory}
                userStaff={userStaff}
              />
            )}
          </SettingsTemplateContainer>)}

      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab6)}>
        {/* Pay Type Card */}
        <SettingsTemplateContainer collapsed={payType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePayType}>
              <div>
                {payType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Pay Type</TitleSection>
          </AccordionContainer>
          {!payType && (
            <Template
              titleSection={'Pay Type'}
              labelInputTitle={'Pay type name'}
              getMethod={getPayType}
              deleteMethod={deletePayType}
              setMethod={setPayTypeApi}
              handleChangeChoices={() =>
                handleChangeChoices('pay_type')
              }
              textToMessageDelete={'pay type'}
              fieldAttributes={{ maxLength: 50 }}

            />
          )
          }
        </SettingsTemplateContainer>
        {/* Shift Type */}
        {/* {readPermissionShiftType && ( */}
        <SettingsTemplateContainer collapsed={shiftType} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleShift}>
              <div>
                {shiftType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Shift Type</TitleSection>
          </AccordionContainer>
          {!shiftType && (
            <Template
              titleSection={'Shift Type'}
              labelInputTitle={'Shift type name'}
              getMethod={getShifts}
              deleteMethod={deleteShifts}
              setMethod={setShifts}
              handleChangeChoices={() =>
                handleChangeChoices('regular_shift_type')
              }
              textToMessageDelete={'shift type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionShiftType}
              // create={createPermissionShiftType}
              // deletePermission={deletePermissionShiftType}
              userStaff={userStaff}

              addOneDropdown={{
                placeholder: 'Type',
                required: true,
                value: 'pay_type',
                options: choices,
                choicesValue: 'pay_type',
              }}
              addInput={[
                {
                  label: 'Color Code',
                  value: 'color_code',
                  type: "color_picker"
                }
              ]}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Absence Type */}
        {/* {readPermissionAbseneType && ( */}
        <SettingsTemplateContainer collapsed={absenceType} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAbsenceType}>
              <div>
                {absenceType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Absence Type</TitleSection>
          </AccordionContainer>
          {!absenceType && (
            <Template
              titleSection={'Absence Type'}
              labelInputTitle={'Absence type name'}
              getMethod={getAbsenceType}
              deleteMethod={deleteAbsenceType}
              setMethod={setAbsence}
              handleChangeChoices={() => handleChangeChoices('absence_type')}
              textToMessageDelete={'Absence type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionAbsenceType}
              // create={createPermissionAbsenceType}
              // deletePermission={deletePermissionAbsenceType}
              userStaff={userStaff}
              addInput={[
                {
                  label: 'Color Code',
                  value: 'color_code',
                  type: "color_picker"
                }]}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Absence Reason */}
        {/* {readPermissionAbsenceReason && */}
        {choices.length <= 0 ? null : (
          <SettingsTemplateContainer collapsed={absenceResasons}>
            <AccordionContainer style={{ marginLeft: '40px' }}>
              <AccordionArrow onClick={handleAbsenceReason}>
                <div>
                  {absenceResasons ? (
                    <IoIosArrowDown size={18} color={PURPLE} />
                  ) : (
                    <IoIosArrowUp size={18} color={PURPLE} />
                  )}
                </div>
              </AccordionArrow>
              <TitleSection>Absence Reason </TitleSection>
            </AccordionContainer>
            {!absenceResasons && (
              <Template
                titleSection={'Absence Reason'}
                labelInputTitle={'Absence Reason type name'}
                getMethod={getAbsenceReasonType}
                deleteMethod={deleteAbsenceReason}
                setMethod={setAbsenceReason}
                handleChangeChoices={() =>
                  handleChangeChoices('absence_reason')
                }
                addOneDropdown={{
                  placeholder: 'absence type ',
                  required: true,
                  value: 'absence',
                  options: choices,
                  choicesValue: 'absence_type',
                }}
                textToMessageDelete={'Absence Reason'}
                fieldAttributes={{ maxLength: 50 }}
                // deletePermission={deletePermissionAbsenceReason}
                userStaff={userStaff}
                // update={updatePermissionAbsenceReason}
                // create={createPermissionAbsenceReason}
                update={update}
                create={create}
                deletePermission={deletePermission}
              />
            )}
          </SettingsTemplateContainer>
        )}

        {/* Absence informed method Type */}
        {/* {readPermissionAbsenceInformed && ( */}
        <SettingsTemplateContainer collapsed={absenceInfo}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAbsenceInfo}>
              <div>
                {absenceInfo ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Absence informed method Type</TitleSection>
          </AccordionContainer>
          {!absenceInfo && (
            <Template
              titleSection={'Absence informed method Type'}
              labelInputTitle={'Absence informed method name'}
              getMethod={getAbsenceInformed}
              deleteMethod={deleteAbsenceInformed}
              setMethod={setAnsenceInformed}
              handleChangeChoices={() =>
                handleChangeChoices('absence_informed_method')
              }
              textToMessageDelete={'Absence informed method type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionAbsenceInformed}
              // create={createPermissionAbsenceInformed}
              // deletePermission={deletePermissionAbsenceInformed}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Planned / Unplanned type */}
        {/* {readPermissionAbsencePlanned && ( */}
        <SettingsTemplateContainer collapsed={plannedType} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePlanned}>
              <div>
                {plannedType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Planned / Unplanned type</TitleSection>
          </AccordionContainer>
          {!plannedType && (
            <Template
              titleSection={'Planned / Unplanned type'}
              labelInputTitle={' Planned / Unplanned type name'}
              getMethod={getAbsencePlaned}
              deleteMethod={deleteAbsencePlanned}
              setMethod={setAbsencePlaned}
              handleChangeChoices={() => handleChangeChoices('absence_planned')}
              textToMessageDelete={'Planned / Unplanned type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionAbsencePlanned}
              // create={createPermissionAbsencePlanned}
              // deletePermission={deletePermissionAbsencePlanned}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

        {/* Sickness Reason */}
        <SettingsTemplateContainer collapsed={HideSicknessReason} >
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSickness}>
              <div>
                {HideSicknessReason ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Sickness Reason </TitleSection>
          </AccordionContainer>
          {!HideSicknessReason && (
            <Template
              titleSection={'Sickness Reason  '}
              labelInputTitle={'Sickness Reason  Type '}
              getMethod={getSickness}
              deleteMethod={deleteSickness}
              setMethod={setSickness}
              handleChangeChoices={() =>
                handleChangeChoices('sickness_reason ')
              }
              textToMessageDelete={'Sickness Reason  Type '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Paid / Unpaid / Paid at other rate type */}
        {/* {readPermissionAbsencePaid && ( */}
        <SettingsTemplateContainer collapsed={paidType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePaid}>
              <div>
                {paidType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Paid / Unpaid / Paid at other rate type</TitleSection>
          </AccordionContainer>
          {!paidType && (
            <Template
              titleSection={'Paid / Unpaid / Paid at other rate type'}
              labelInputTitle={'Paid / Unpaid / Paid at other rate type name'}
              getMethod={getAbsencePaid}
              deleteMethod={deleteAbsencePaid}
              setMethod={setAbsencePaid}
              handleChangeChoices={() => handleChangeChoices('absence_paid')}
              textToMessageDelete={'Paid / Unpaid / Paid at other rate type'}
              fieldAttributes={{ maxLength: 50 }}
              update={update}
              create={create}
              deletePermission={deletePermission}
              // update={updatePermissionAbsencePaid}
              // create={createPermissionAbsencePaid}
              // deletePermission={deletePermissionAbsencePaid}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        {/* )} */}

      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab7)}>
        {/* Triage Task */}
        <SettingsTemplateContainer collapsed={hideTriage}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleTriage}>
              <div>
                {hideTriage ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection> Triage Task </TitleSection>
          </AccordionContainer>
          {!hideTriage && (
            <Template
              titleSection={'Triage Task  '}
              labelInputTitle={'Triage Task   '}
              getMethod={getTriageTask}
              deleteMethod={deleteTriageTask}
              setMethod={setTriageTask}
              handleChangeChoices={() => handleChangeChoices('alert_task')}
              textToMessageDelete={'Triage Task'}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        {/* Triage Task Status */}
        <SettingsTemplateContainer collapsed={hideTask}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleTask}>
              <div>
                {hideTask ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection> Triage Task Status </TitleSection>
          </AccordionContainer>
          {!hideTask && (
            <Template
              titleSection={'Triage Task Status '}
              labelInputTitle={'Triage Task Status  '}
              getMethod={getTriageTaskStatus}
              deleteMethod={deleteTriageTaskStatus}
              setMethod={setTriageTaskStatus}
              handleChangeChoices={() =>
                handleChangeChoices('alert_task_status')
              }
              textToMessageDelete={'Triage Task Status '}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

      </ShowTeamSettingModalTab>

      {isCurrentTab(tab8) &&
        <SkillMatrix
          choices={choices}
          values={values}
          setValues={setValues}
          useInput={useInput}
          dropDownHandleSelect={dropDownHandleSelect}
          readPermission={readPermissionSkillMatrix}
          createPermission={createPermissionSkillMatrix}
          updatePermission={updatePermissionSkillMatrix}
          deletePermission={deletePermissionSkillMatrix}
        />
      }
      {isCurrentTab(tab9) &&
        <CompetencyMatrix
          choices={choices}
          values={values}
          setValues={setValues}
          useInput={useInput}
          dropDownHandleSelect={dropDownHandleSelect}
          readPermission={readPermissionCompetencyMatrix}
          createPermission={createPermissionCompetencyMatrix}
          updatePermission={updatePermissionCompetencyMatrix}
          deletePermission={deletePermissionCompetencyMatrix}
        />
      }
    </div>
  );
};

export default SettingsTeam;
