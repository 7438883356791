import styled from 'styled-components';
import {PURPLE, BLACK_900, WHITE} from 'shared/styles/constants/colors';

export const StyledCheck = styled.div`
  padding-left: 0;

  .custom-control-label {
    font-size: 0.875rem;
    line-height: 2;
    padding-left: 0.4rem;
  }
  .custom-control-label::after,
  .custom-control-label::before {
    height: 20px;
    width: 20px;
  }
  .custom-control-label::before {
    background-color: ${WHITE};
    border: 1px solid ${BLACK_900};
    border-radius: 50%;
  }

  /* When I remove background the icon goes back to that default size */
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${PURPLE} !important;
  }
`;
