import React, { useEffect, useState } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputTextArea from 'components/SharedComponents/InputTextArea';

import Image from 'components/SharedComponents/Image';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { UploadInput } from 'shared/styles/buttons';
import { KeyPress } from 'utils/constants/regularExpressions';
import { FiUpload } from 'react-icons/fi';
import {
  ContainerResponsive,
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
  UploadPhotoContainer,
  UserImgContainer,
  UserImgWrapper,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import ToggleButton from '../ToggleButton';
import { FlexEqual } from '../../DashboardComponents/Team/AddModal/styles';

const NextOfKin = ({
  useInput,
  nameSection,
  choices,
  values,
  handlePickFile,
  dropDownHandleSelect,
  impContactList,
  nokPhoto,
  sectionStyle,
  roles,
  setValues,
  dropDownHandleContact,
}) => {
  // let roleStatus = values[nameSection]?.role;
  // useEffect(() => {
  //   if (values[nameSection]?.role) {
  //     values[nameSection].make_user = true;
  //     values[nameSection].permission_right = values[nameSection].role;
  //     setMakeUser(true);
  //   }
  // }, [roleStatus]);

  const handelMakeUser = () => {
    if (values.permission_right) {
      values.permission_right = null;
      setValues({ ...values });
    }
  };

  const changeName = e => {
    values.full_name = e.target.value;
    setValues({ ...values });
  };

  console.log(values, 88833);

  return (
    <div className={sectionStyle}>
      <div className="d-flex flex-column mt-3 mb-3">
        <div className="d-flex mb-2">
          <div className={'d-flex mr-2 w-100'}>
            {/* <span style={{fontSize: '0.875rem'}} className={'mr-2 mt-1'}>
              {'Personal'}
            </span>
            <ToggleButton
              label="Professional"
              switch={true}
              useInput={useInput('is_professional_contact_type')}
              id="contact_type"
            /> */}
            <div className={'w-100'}>
              <DropdownSearchable
                placeHolder={'Type'}
                options={[
                  { id: 'Personal', name: 'Personal' },
                  { id: "Professional", name: 'Professional' },
                ]}
                selectedOption={values && values.type ? values.type : null}
                onChange={value =>
                  dropDownHandleSelect({ type: value ? value : null })
                }
              />
            </div>
          </div>
          <div className={'w-100 mr-2'}>
            <DropdownSearchable
              placeHolder={'Nok'}
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={values && values.is_nok ? values.is_nok : null}
              onChange={value =>
                dropDownHandleSelect({ is_nok: value ? value : null })
              }
            />
          </div>
          <div className={'w-100'}>
            <DropdownSearchable
              placeHolder={'Lasting Power Of Attorney'}
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={
                values && values.lasting_power_of_attorney
                  ? values.lasting_power_of_attorney
                  : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  lasting_power_of_attorney: value ? value : null,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className={'w-100 mr-2'}>
            <DropdownSearchable
              placeHolder={'Is Local Contact'}
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={
                values && values.is_self_contact ? values.is_self_contact : null
              }
              onChange={value =>
                dropDownHandleSelect({ is_self_contact: value ? value : null })
              }
            />
          </div>

          <div className={'w-100 mr-2'}>
            <DropdownSearchable
              placeHolder={'Health And WellBeing'}
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={
                values && values.health_wellbeing
                  ? values.health_wellbeing
                  : null
              }
              onChange={value =>
                dropDownHandleSelect({ health_wellbeing: value ? value : null })
              }
            />
          </div>
          <div className={'w-100'}>
            <DropdownSearchable
              placeHolder={'Financial'}
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={
                values && values.financial ? values.financial : null
              }
              onChange={value =>
                dropDownHandleSelect({ financial: value ? value : null })
              }
            />
          </div>
        </div>
      </div>
      <TitleSection>Important Contact</TitleSection>
      <div className="d-flex flex-column mt-2">
        <ContainerResponsive className="d-flex">
          <div className="mr-md-2 mb-2">
            <UserImgWrapper>
              <UserImgContainer>
                {/* TODO: review if values.photo is correct, add name and lastname correct */}
                <Image
                  src={
                    nokPhoto
                      ? URL.createObjectURL(nokPhoto)
                      : values.photo
                        ? values.photo
                        : ''
                  }
                  name={`${values && values[nameSection]
                    ? values[nameSection].full_name
                    : ''
                    }`}
                  alt={`${[nameSection]}-img`}
                  fontSize={'1.5rem'}
                />
              </UserImgContainer>

              <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                  onInput={e => {
                    handlePickFile(e, 'nok');
                  }}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  type="file"
                  accept={'.png,.jpg,.jpeg'}
                  id="nok-photo"
                  name="nok-photo"
                  placeholder={'Nok photo'}
                />
                <FiUpload />
                <label htmlFor="nok-photo">Upload</label>
              </UploadPhotoContainer>
            </UserImgWrapper>
          </div>

          <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex">
              <div className="w-100 mr-2">
                <InputLabelTop
                  type="text"
                  id="nok_no"
                  label={'No.'}
                  onKeyPress={KeyPress.INTEGER}
                  {...useInput(`no`, 'isInt')}
                  maxlength={5}
                />
              </div>
              <div className="w-100">
                <DropdownSearchable
                  placeHolder={'Title'}
                  options={choices.title}
                  selectedOption={values ? values.title : null}
                  onChange={value =>
                    dropDownHandleSelect({ title: value ? value : null })
                  }
                />
              </div>
            </div>

            <div className="w-100 d-flex">
              {values && values.is_self_contact && values.is_self_contact == 1 && (
                <div className={'w-100 mr-2 mb-2'}>
                  <DropdownSearchable
                    placeHolder={'Select Contact'}
                    options={impContactList}
                    selectedOption={
                      values && values.full_name ? values.full_name : null
                    }
                    onChange={value =>
                      dropDownHandleContact({ full_name: value ? value : null })
                    }
                  />
                </div>
              )}
              {values && values.is_self_contact && values.is_self_contact == 2 && (
                <div className="w-100 mr-2">
                  <InputLabelTop
                    type="text"
                    id="nok_full_name"
                    label={'Full Name'}
                    onChange={e => changeName(e)}
                    value={values.full_name}
                    maxlength={255}
                  />
                </div>
              )}

              <div className="w-100 mb-2">
                <DropdownSearchable
                  placeHolder={'Relationship'}
                  options={choices.nok_relationship}
                  selectedOption={values ? values.relation_ship : null}
                  onChange={value =>
                    dropDownHandleSelect({ relation_ship: value ? value : null })
                  }
                />
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="w-100 mr-2">
                <InputLabelTop
                  type="text"
                  id="nok_house_company_name"
                  label={'House Name / Company Name'}
                  {...useInput(`name`)}
                  maxlength={50}
                />
              </div>
              <div className="w-100">
                <DropdownSearchable
                  placeHolder={'User Type'}
                  options={choices.care_contact_type}
                  selectedOption={values ? values.contact_type : null}
                  onChange={value =>
                    dropDownHandleSelect({ contact_type: value ? value : null })
                  }
                />
              </div>
            </div>
          </div>
        </ContainerResponsive>

        <div className="d-flex">
          <div className="w-100 mr-2">
            <InputLabelTop
              type="text"
              id="nok_line_1"
              label={'Address Line 1'}
              {...useInput(`line_1`)}
              maxlength={255}
            />
          </div>

          <div className="w-100">
            <InputLabelTop
              type="text"
              id="nok_line_2"
              label={'Address Line 2'}
              {...useInput(`line_2`)}
              maxlength={255}
            />
          </div>
        </div>

        <FlexGrid3Container className="mb-2">
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              type="text"
              id="nok_town_city"
              label={'Town / City'}
              {...useInput(`city`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              type="text"
              id="nok_state"
              label={'County / State'}
              {...useInput(`state`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
            <InputLabelTop
              type="text"
              id="nok_postcode_zipcode"
              label={' Postcode / Zipcode'}
              {...useInput(`zip_code`)}
              maxlength={25}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <InputLabelTop
              type="text"
              id="nok_country"
              label={'Country'}
              {...useInput(`country`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
            <InputLabelTop
              type="text"
              id="nok_mobile"
              label={'Mobile No.'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(`mobile`, 'isMobilePhone')}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1">
            <InputLabelTop
              type="email"
              required
              id="nok_email"
              label={'Email'}
              {...useInput(`email`, 'isEmail', 'isRequired')}
              maxlength={254}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <DropdownSearchable
              placeHolder={'Contact Status'}
              options={choices.contact_status}
              selectedOption={values ? values.contact_status : null}
              onChange={value =>
                dropDownHandleSelect({ contact_status: value ? value : null })
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-0 pr-1">
            <DropdownSearchable
              placeHolder={'User Role'}
              options={roles}
              disabled={true}
              selectedOption={values ? values.permission_right : null}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1">
            <InputLabelTop
              type="text"
              id="nok_mobile"
              label={'Additional Mobile No.'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(`additional_telephone`, 'isMobilePhone')}
              maxlength={25}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>
        <div style={{ marginTop: '-7px' }} className={'d-flex'}>
          <div className={'w-100 mr-2'}>
            <small className="pr-1 " style={{ color: 'black' }}>

              Contact Notes
            </small>

            <InputTextArea
              type="text"
              height={'4rem'}
              id="nok_mobile"
              label={'Contact Notes'}
              {...useInput(`person_notes`)}
              maxlength={25}
            />
          </div>
          <div className={'w-100 mr-2'}>
            <small className="pr-1 " style={{ color: 'black' }}>
              {' '}
              Reason For Decision
            </small>
            <InputTextArea
              type="text"
              height={'4rem'}
              id="decison"
              label={'Reason For Decision'}
              {...useInput(`decision_reason`)}
              maxlength={100}
            />


          </div>
        </div>
        <div className={'d-flex'}>
          <div className={'w-100 mr-2'}>
            <DropdownSearchable
              placeHolder={
                'Is This Contact Allowed Access To Client Data On App'
              }
              options={[
                { id: 1, name: 'Yes' },
                { id: 2, name: 'No' },
              ]}
              selectedOption={
                values ? values.is_client_data_access_allowed : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  is_client_data_access_allowed: value ? value : null,
                })
              }
            />
          </div>
        </div>
        <ContainerResponsive style={{ width: "400px" }} className="d-flex justify-content-between mt-3">
          <ToggleButton
            label="Invite as User"
            useInput={useInput(`make_user`)}
            id={`make_user`}
            onClick={handelMakeUser}
          />
          {/* <ContainerResponsive className="d-flex justify-content-between mt-3"> */}
          <ToggleButton
            label="Rota Email"
            useInput={useInput(`rota_email`)}
            id={`rota_email`}
          // onClick={handelMakeUser}
          />
          {/* </ContainerResponsive> */}
        </ContainerResponsive>

        {values.make_user && (
          <ContainerResponsive>
            <FlexEqual className="mr-md-2 mr-sm-0">
              {/*<TitleSection>Roles And Permission</TitleSection>*/}
              <div className="d-flex flex-column mt-2">
                <FlexGrid3Container className="w-100 mb-2">
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                    <DropdownSearchable
                      placeHolder={'Permission Rights'}
                      options={roles?.filter(role => role.user_type === "important contact")}
                      required={values.make_user}
                      selectedOption={
                        values
                          ? values.permission_right
                            ? values.permission_right
                            : null
                          : null
                      }
                      onChange={value =>
                        dropDownHandleSelect({
                          permission_right: value ? value : null,
                        })
                      }
                    />
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
            </FlexEqual>
          </ContainerResponsive>
        )}
      </div>
    </div>
  );
};

NextOfKin.propTypes = {
  useInput: PropTypes.func.isRequired,
  handlePickFile: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  nokPhoto: PropTypes.object,
  choices: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  sectionStyle: PropTypes.string,
};

NextOfKin.defaultProps = {
  nokPhoto: null,
  sectionStyle: '',
};

export default NextOfKin;
