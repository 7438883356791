import styled from 'styled-components';
import {
  PURPLE,
  ERR_COLOR,
  WHITE,
  BLACK_900,
  GRAY_200,
  GRAY_800,
  INPUT_FOCUS_BC,
} from 'shared/styles/constants/colors';
import {SM, MD, LG, XL,XXLLLL} from 'shared/styles/constants/resolutions';
import {
  P_W_Zero,
  L_R_PAD,
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  COL_CONTAIN_PARA,
  COL_CONTAIN_WORD,
  VERY_EX_SMALL_COL_CHECKBOX,
  EX_SMALL_COL_TOGGLE,
  CHECK_BOX,
} from 'shared/styles/constants/columns';

export const Row = styled.header`
z-index: ${props=>(props.zIndex ? props.zIndex :'')};
  position: relative;
  display: flex;
  background-color: ${props => (props.bgColor ? WHITE : 
    props.ylColor?"yellow":
    'unset')};

  text-transform: ${props => (props.Upper ? 'uppercase' : 'normal')};
  font-weight: ${props => (props.Upper ? 500 : 300)};
  align-items: center;
  border-radius: 0.125rem;

  cursor: ${props => (props.NoShadow ? 'default' : 'pointer')};

  -webkit-box-shadow: ${props =>
    props.Shadow ? `0px 0.1875rem 0.375rem ${BLACK_900}` : '0'};
  box-shadow: ${props =>
    props.Shadow ? `0px 0.1875rem 0.375rem ${BLACK_900}` : 0};

  &:hover {
    border-left: ${props => (props.NoShadow ? 0 : `4px solid ${PURPLE}`)};
    -webkit-box-shadow: ${props =>
      props.NoShadow ? 0 : `0px 0.1875rem 0.375rem ${BLACK_900}`};
    box-shadow: ${props =>
      props.NoShadow ? 0 : `0px 0.1875rem 0.375rem ${BLACK_900}`};
  }

  @media (max-width: ${MD}) {
    border: ${props => (props.Selected ? `0px solid ${PURPLE}` : 0)};
  }
`;

const handleColorType = color => {
  switch (color) {
    case 'Active':
      return INPUT_FOCUS_BC;
    case 'Inactive':
      return ERR_COLOR;
    default:
      return GRAY_800;
  }
};

export const Col = styled.div`
z-index: ${props=>(props.zIndex ? props.zIndex :'')};
  display: ${props => (props.Initials ? 'unset' : (props.textOverlap ? 'inline-block' :'flex'))};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  white-space: ${props => (props.textOverlap ? 'nowrap' :'normal')};
  overflow:${props => (props.textOverlap ? 'hidden' :'unset')};
  text-overflow: ellipsis;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  align-self: center;
  background-color: ${props => (props.Selected ? PURPLE : 'inherit')};
  text-transform: ${props =>
    props.Capitalize ? 'capitalize' : props.Upper ? 'uppercase' : 'unset'};
  flex: ${props => (props.Icon ? 0.2 : props.NoFlexGrow ? 0 : props.Width ? "" : 1)};
  width: ${props => (props.Width ? '32px' : '')};
  padding: ${props => props.Selected ? "" : '0.3125rem 0.3125rem 0.3125rem 0'};
  text-align: ${props => (props.Center ? 'center' : 'left')};
  color: ${props => (props.Selected ? WHITE : handleColorType(props.Color))};
  /* font-size: 0.875rem; */
  font-size: ${props => (props.fontSize ? props.fontSize : '0.875rem')};
  margin-left: ${props =>  props.marginLeft ?  '2rem' : ':0'};
margin-right: ${props =>  props.marginRight ?  '1rem' : ':0'};
padding-right: ${props =>  props.paddingRight ?  '1rem' : ':0'};
padding-Left: ${props =>  props.paddingLeft ?  '1rem' : ':0'};
font-weight: ${props => (props.Color ? '400' : 'inherit')};
  list-style-type: ${props => (props.Color ? 'disc' : 'unset')};
  list-style-position: ${props => (props.Color ? 'inside' : 'unset')};
  word-wrap: break-word;
  overflow-wrap: ${props => props.overlap ? 'anywhere' : 'break-word'};
  svg {
    color: ${props => (props.Purple ? PURPLE : ERR_COLOR)};
  }
  label {
    margin: 0;
  }
  

  transform: ${props => (props.Rotate ? 'rotate(90deg)' : 'rotate(0deg)')};

  @media (max-width: ${MD}) {
    text-align: left;
    margin: ${props => (props.Shrink ? '0' : '1rem')};
    padding: ${props =>
      props.Shrink ? (props.reducePadding ? '0.6rem 0.4rem' : '1rem') : '0'};
    flex: ${props => (props.Shrink ? '1' : '0')};

    -webkit-box-shadow: ${props =>
      props.ShadowMobile ? `0.1875rem 0px 0.25rem ${BLACK_900}` : '0'};
    box-shadow: ${props =>
      props.ShadowMobile ? `0.1875rem 0px 0.25rem ${BLACK_900}` : 0};

    @media (max-width: ${SM}) {
      text-align: left;
      margin: ${props => (props.Shrink ? '0' : '1rem')};
      padding: ${props =>
        props.Shrink ? (props.reducePadding ? '0.6rem 0.4rem' : '1rem') : '0'};
      flex: ${props => (props.Shrink ? '1' : '0')};

      flex: ${props => (props.Icon ? 0.2 : props.NoFlexGrow ? 0 : props.Width ? "" : 1)};
      width: ${props => (props.Width ? '32px' : '')};
      align-items: ${props => (props.alignItems ? props.alignItems : 'center')};

      -webkit-box-shadow: ${props =>
        props.ShadowMobile ? `0.1875rem 0px 0.25rem ${BLACK_900}` : '0'};
      box-shadow: ${props =>
        props.ShadowMobile ? `0.1875rem 0px 0.25rem ${BLACK_900}` : 0};
    }

    &.grow-mobile {
      flex: 1;
    }
  }

  ${props => addMaxWidth(props, XL)}

  &.visits-custom {
    width: 14%;
    max-width: 421px;
    flex: auto;
  }
  &.pattern-header-custom {
    max-width: 30%;
    min-width: 13.125rem;
  }
  &.pattern-custom-row {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
    min-width: 13.125rem;
  }
  &.visits-custom-header {
    width: 20%;
    max-width: 412px;
    flex: auto;
  }
  &.visits-custom-row {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
  }

  &.visit-days {
    @media (max-width: 871px) {
      display: none;
    }
  }

  @media (max-width: calc(${LG} + 20px)) {
    ${props => addMaxWidth(props, LG)}
  }

  @media (max-width: ${MD}) {
    ${props => addMaxWidth(props, MD)}
  }

  @media (max-width: ${SM}) {
    ${props => addMaxWidth(props, SM)}
  }
`;

function addMaxWidth(props, size) {
  switch (props.max_width) {
    case P_W_Zero:
        return `
        padding: 0 !important;
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        max-width: 2%;
      `;
    case L_R_PAD:
      return `
        padding: 0 !important;
        padding-left: 3rem !important;
        padding-right: 0rem !important;
        max-width: 20%;
      `;
    case EX_SMALL_COL4:
      return `
        padding: 0 !important;
        padding-left: 1.7rem !important;
        padding-right: 0.5rem !important;
        max-width: 2%;
      `;
      case CHECK_BOX:
      return `
        padding: 0 !important;
        padding-left: 1.2rem !important;
        padding-right: 1.2rem !important;
        max-width: 2%;
      `;
      case VERY_EX_SMALL_COL_CHECKBOX:
        return `
          flex: 0 !important;
          padding: 0rem ;
          padding-left: 1rem !important;
          padding-right: 0.5rem !important;
          max-width: 2%;
        `;
    case EX_SMALL_COL:
      return `
        padding: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
        max-width: 4%;
      `;
      case EX_SMALL_COL_TOGGLE:
      return `
        padding: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
        max-width: 5%;
      `;
    case SMALL_COL:
      if (size === LG) {
        return `
          min-width: 18%;
        `;
      }
      return `
        padding: 0 !important;
        padding-left: 0.7rem !important;
        padding-right: 0.5rem !important;
        max-width: 15%;
      `;
    case MEDIUM_COL:
      if (size === SM) {
        return `
          max-width: 29%;
          margin: 0 !important;
        `;
      }

      if (size === MD) {
        return `
          max-width: 40%;
          flex: 1;
        `;
      }

      if (size === LG) {
        return `
          max-width: 50%;
        `;
      }
      return `
      padding: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 !important;
      max-width: 40%;
        
      `;
    case LARGE_COL:
      if (size === SM) {
        return `
          max-width: 40%;
        `;
      }
      if (size === MD) {
        return `
          max-width: 60%;
        `;
      }
      if (size === LG) {
        return `
          max-width: 30%;
        `;
      }
      return `
        max-width: 25%;
      `;
    case EX_LARGE_COL:
      return `
        padding: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
        max-width: 40%;
      `;
      case EX_VERY_LARGE_COL:
        return `
          max-width: 80%;
        `;
        case COL_CONTAIN_PARA:
          return `
            min-width: 35%;
          `;
          case COL_CONTAIN_WORD:
            return `
              min-width: 5%;
            `;
    default:
  }
}

export const UserImg = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  border-radius: 50%;
  img {
    width: 2.4rem;
    height: 2.4rem;
  }
  background: ${GRAY_200};
  div {
    color: ${PURPLE};
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${LG}) {
    align-self: center;
    margin-left: 0.3125rem;
    margin-right: 0.4rem;
  }
  @media (max-width: ${MD}) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0rem;
    margin-right: 0.3rem;
  }
`;

export const UserImgContainer = styled.div`
  display: flex;
  justify-content: start;
  text-align: left;

  @media (max-width: ${MD}) {
    display: flex;
    justify-content: column;
    align-self: start;
    flex-direction: column;
    text-align: start;
    width: 60px;
    margin-left: 0px;
    margin-right: 1px;
  }

  @media (max-width: ${SM}) {
    display: flex;
    justify-content: column;
    align-self: start;
    flex-direction: column;
    text-align: center;
    width: 40px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
  }

  label {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
margin: auto auto;
height: 27px;
width: 27px;
  -webkit-box-shadow: 0px 1px 3px ${BLACK_900};
  box-shadow: 0px 1px 3px ${BLACK_900};
  border: 0.5px solid ${GRAY_200};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
`;

export const ActionIcon = styled.div`
  @media (max-width: ${MD}) {
  }
`;

export const SortIcon = styled.div`
  display: inline-block;
  svg {
    font-size: 1rem;
    margin-bottom: 0;
    color: ${PURPLE};
  }
`;

export const ColsGrouper = styled.div`
  flex: 1;
`;

export const DayPill = styled.div`
  width: 3.5rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-right: 0.2rem;
  background-color: ${props => (props.Active ? PURPLE : '#bebebe')};
  
  label {
    padding-bottom:2px;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${props => (props.Active ? 'white' : '#8e8e8e')};
  }
`;

export const OptionsMobile = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 0.25rem;
  background-color: WHITE;
  top: 100%;
  left: 0.75rem;
  -webkit-box-shadow: 0px 0.1875rem 0.375rem ${BLACK_900};
  box-shadow: 0px 0.1875rem 0.375rem ${BLACK_900};
  display: none;

  div {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.2rem;
    border-bottom: 0.0625rem solid ${GRAY_200};
    svg {
      font-size: 1.4rem;
      color: ${PURPLE};
    }
    label {
      cursor: pointer;
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
  div.delete {
    background-color: ${ERR_COLOR};
    border-bottom: 0;
    color: WHITE;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    svg {
      color: WHITE;
    }
  }
  @media (max-width: ${MD}) {
    display: block;
  }
`;

