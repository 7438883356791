import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState, useContext } from 'react';
import { useEffect } from 'react';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { AppContext, BranchItemsContext } from 'context';

import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
} from 'shared/styles/constants/tagsStyles';
import { useHistory } from 'react-router-dom';

import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
import MedicationErrorModal from 'components/DashboardComponents/Client/MedicationChart/Modals/MedicationErrorModal';
import MedicationReview from 'components/DashboardComponents/Client/MedicationChart/Modals/MedicationReview';
import AuditHistory from './AuditHistory';
import { BorderContainer } from 'components/DashboardComponents/Team/AddSkills/styles';
import { ERR_COLOR, GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import { useModal } from 'hooks/ModalHook';
import BodyMapModal from './BodyMapModal';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosBody } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';
import { FLOPPY } from 'utils/constants/icons';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import './medication.css'

const EditMedication = ({
  fullBind,
  useInput,
  values,
  choices,
  editMode,
  medList,
  handleSubmit, clientId,
  dropDownHandleSelect = () => { },
  loadingSaveMedication,
  setValues
}) => {
  const tab1 = 1;
  const tab2 = 2;
  const tab3 = 3;
  const tab4 = 4;
  const [tabSelected, setTabSelected] = useState(tab1);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [signature, setSignature] = useState(null);

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.instructions`);
  const med_hook = useInput(`medication.medication_instructions`)
  const newDoseTime = useInput(
    `medication.medication_dose_time`, 'isRequired');
  const actualMedicationDoseTime = useInput(
    `medication.actual_medication_dose_time`);
  const history = useHistory();
  const newDoseStartTime = useInput(
    `medication.medication_dose_time`, 'isRequired'
  );
  const newDoseEndTime = useInput(
    `medication.medication_dose_end_time`, 'isRequired'
  );
  const med_dose_timing_hook = useInput(`medication_dose_timing`)
  const medication_storage_location = useInput('medication.storage_location')
  const medication_dose_regime = useInput('medication.dose_regime')
  const medication_name = useInput('medication.name', "isRequired")
  const medication_type = useInput('medication.medication_type', "isRequired")
  const medication_assistance = useInput('medication.medication_assistance')
  const medication_dose = useInput(`medication.core_medication_dose`, 'isRequired')
  const medication_frequency = useInput('medication.frequency')
  const medication_important = useInput('medication.important')
  const medication_prescription_volume = useInput('medication.prescription_volume')
  const medication_reason = useInput('medication.reason')
  const medication_potential_side_effects = useInput('medication.potential_side_effects')
  const medication_comments = useInput('medication.comments')
  const medication_marking_style = useInput('medication.marking_style')
  const medication_feedback = useInput('medication.feedback')
  const medication_medication_reason = useInput('medication.medication_reason')
  const administration_support = useInput('medication.administration_support')
  const administration_area = useInput('medication.administration_area')
  const medication_employee = useInput('medication.employee')
  const medication_medication_status = useInput('medication.medication_status')
  const medication_start_date = useInput('medication.start_date', "isRequired")
  const medication_actual_start_date = useInput('medication.actual_start_date')
  // Added for new fields
  let date = useInput(`date`)
  let strength = useInput(`strength`)
  let minimum_interval_between_doses = useInput(`min_interval_between_doses`)
  let maximum_dose_in_24_hours = useInput(`max_dose_in_24_hours`)
  let medication_for = useInput(`medication_purpose`)
  let symptoms = useInput(`symptoms_to_look_for`)
  let client_needs_medication = useInput(`client_need_medication_criteria`)
  let describe_any_alternative = useInput(`alternative_administering_interventions`)
  let medication_work_for = useInput(`medication_duration`)
  let guidance_from_healthcare = useInput(`healthcare_professional_guidance_criteria`)
  let completed_by = useInput('completed_by')

  const { contextChoices } = useContext(AppContext);
  const { setValue: setModalDetachBodyMap, bind: bindModalDetachBodyMap } = useModal(false);
  const [bodyMapDeleteIndex, setBodyMapDeleteIndex] = useState(null);
  const {
    setValue: setModalBodyMap,
    value: modalBodyMap,
    fullBind: fullBindBodyMap,
  } = useModal(false);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication',
    });
  }, [fullBind]);
  const isCurrentTab = tab => {

    return tabSelected === tab;
  };
  const checkTabSwitch = tabName => {
    setTabSelected(tabName);
    if (tabName !== tab3) {
      history.push({
        pathname: '/medication',
      });
    }
  };

  const toDeleteBodyData = (index) => {
    values.medication_body_part.splice(index, 1);
    setValues({ ...values })
    setModalDetachBodyMap(false)
  }

  const handleRowBodyClick = (index) => {
    setModalBodyMap(true)
    values.tempBodyMapData = { ...values.medication_body_part[index], index: `${index}` }
    setValues({ ...values })
  }

  const createNewBodyMap = () => {
    setModalBodyMap(true)
    values.tempBodyMapData = {}
    if (!values.medication_body_part) {
      values.medication_body_part = []
    }
    setValues({ ...values })
  }

  useEffect(() => {
    if (values.alternative_administering_interventions && values.alternative_administering_interventions.length > 500) {
      // setErrorText('Ensure this field has no more than 500 characters.')
      values.alternative_field_error = true
    } else {
      values.alternative_field_error = false
    }
    if (values.healthcare_professional_guidance_criteria && values.healthcare_professional_guidance_criteria.length > 500) {
      values.professional_guidance_field_error = true
    } else {
      values.professional_guidance_field_error = false
    }
    setValues({ ...values })
  }, [values.alternative_administering_interventions, values.healthcare_professional_guidance_criteria])

  console.log(values, "values")
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>


        <div style={{ display: "flex", justifyContent: "center", marginBottom: 15 }}>
          {' '}
          <RowInfoModel
            name={choices.client_listing.find(val => val.id === values?.medication?.client)?.first_name || choices.client_listing.find(val => val.id === values?.medication?.client)?.last_name}
            lastName={choices.client_listing.find(val => val.id === values?.medication?.client)?.last_name}
            photoRow={
              choices.client_listing.find(val => val.id === values?.medication?.client)?.photo
            }
            rows={[
              { label: 'Name:', value: choices.client_listing.find(val => val.id === values?.medication?.client)?.full_name },
              {
                label: 'DOB:',
                value: choices.client_listing.find(val => val.id === values?.medication?.client)?.date_of_birth
                  ? `${moment(choices.client_listing.find(val => val.id === values?.medication?.client)?.date_of_birth).format(
                    'DD-MM-YYYY'
                  )} (${moment().diff(choices.client_listing.find(val => val.id === values?.medication?.client)?.date_of_birth, 'years')})`
                  : '',
              },
            ]}
          // dateOfBirth={dateOfBirth}
          />
        </div>
        <div className="p-2 p-sm-3 p-md-4">
          <div className="d-flex justify-content-around text-center">
            <TabStyled
              active={isCurrentTab(tab1)}
              onClick={() => checkTabSwitch(tab1)}>
              Edit Medication
            </TabStyled>

            <TabStyled
              active={isCurrentTab(tab2)}
              onClick={() => checkTabSwitch(tab2)}
            >
              Medication Review
            </TabStyled>
            <TabStyled
              active={isCurrentTab(tab3)}
              onClick={() => checkTabSwitch(tab3)}
            >
              Medication Error
            </TabStyled>
            <TabStyled
              active={isCurrentTab(tab4)}
              onClick={() => checkTabSwitch(tab4)}
            >
              Audit History
            </TabStyled>
          </div>
        </div>
        <ShowTab show={isCurrentTab(tab1)}>
          {tabSelected === tab1 && (
            <div className='pt-2 pl-3 pr-3 pb-3'>
              <div className="d-flex mb-2">
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    required
                    disabled={!editMode}
                    placeHolder={'Medication Type'}
                    options={choices.medication_type || []}
                    selectedOption={values.medication ? values.medication.medication_type : null}
                    {...medication_type}
                    onChange={(value, label, obj) =>
                      dropDownHandleSelect({ medication_type: obj ? obj : null }, 'medication_type')
                    }
                  />
                </div>

                <div className="w-100 pr-1">
                  <DropdownSearchable
                    required
                    disabled={!editMode}
                    placeHolder={'Medication Name'}
                    options={!(values?.medication?.medication_type_name === 'PRN') ? choices.medication_name : medList}
                    {...medication_name}
                    selectedOption={values.medication ? values.medication.name : null}
                    onChange={(value, label, obj) =>
                      dropDownHandleSelect({ name: obj ? obj : null }, 'name')
                    }
                  />

                </div>



              </div>
              <div className="d-flex mb-2">
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Route'}
                    options={choices.medication_route || []}
                    selectedOption={values.medication ? values.medication.medication_route : null}
                    onChange={value =>
                      dropDownHandleSelect({ medication_route: value ? value : null }, 'medication_route')
                    }
                  />
                </div>
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Form'}
                    options={choices.medication_form || []}
                    selectedOption={values.medication ? values.medication.medication_form : null}
                    onChange={value =>
                      dropDownHandleSelect({ medication_form: value ? value : null }, 'medication_form')
                    }
                  />
                </div>
              </div>
              <div className="d-flex mb-2">


                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Medication Assistance'}
                    options={choices.medication_assistance || []}
                    selectedOption={values.medication ? values.medication.medication_assistance : null}
                    {...medication_assistance}
                    onChange={value =>
                      dropDownHandleSelect({ medication_assistance: value ? value : null }, 'medication_assistance')
                    }
                  />
                </div>

                <div className="w-100 pr-1">
                  <DropdownSearchable
                    required
                    disabled={!editMode}
                    placeHolder={'Medication Dose'}
                    {...medication_dose}
                    options={choices.medication_dose || []}
                    selectedOption={values.medication ? values.medication.core_medication_dose : null}
                    onChange={value =>
                      dropDownHandleSelect(
                        { core_medication_dose: value ? value : null },
                        'core_medication_dose')
                    }
                  />
                </div>
              </div>
              {/* Added extra fields */}
              <div className='d-flex mb-2'>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="minimum_interval_between_doses"
                    label={'Minimum interval between doses'}
                    {...minimum_interval_between_doses}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="maximum_dose_in_24_hours"
                    label={'Maximum dose in 24 hours'}
                    {...maximum_dose_in_24_hours}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="medication_for"
                    label={'What is the medication for?'}
                    {...medication_for}
                    maxlength={100}
                  />
                </div>
              </div>
              <div className='d-flex mb-2'>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="symptoms"
                    label={'what are the symptoms to look out for?'}
                    {...symptoms}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="client_needs_medication"
                    label={' How will you know if the client will needs the medication?'}
                    {...client_needs_medication}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="medication_work_for"
                    label={'How long should the medication work for?'}
                    {...medication_work_for}
                    maxlength={100}
                  />
                </div>
              </div>
              <div className='d-flex mb-2'>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="strength"
                    label={'Strength'}
                    {...strength}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <InputLabelTop
                    readOnly={false}
                    type="text"
                    id="completed_by"
                    label={'Completed by'}
                    {...completed_by}
                    maxlength={100}
                  />
                </div>
                <div className="w-100 mr-2">
                  <DateSelector
                    // disabled={!editMode}
                    labelTop="Date"
                    {...date}
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="w-100 pr-1">
                  <div
                    className={'pt-2 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    When should guidance sought from a healthcare professional?
                  </div>
                  <InputTextArea
                    type="text"
                    height={'4.4rem'}
                    // disabled={!editMode}
                    // label={'Comments'}
                    // onKeyPress={KeyPress.INTEGER}
                    {...guidance_from_healthcare}
                  />
                  {values.professional_guidance_field_error && <div
                    className={'pt-1 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
                    Ensure this field has no more than 500 characters.
                  </div>
                  }
                </div>

                <div className="w-100 pr-1">
                  <div
                    className={'pt-2 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    Describe any alternative interventions to consider before administering the medication.
                  </div>
                  <InputTextArea
                    type="text"
                    maxlength={5000}
                    height={'4.4rem'}
                    // disabled={!editMode}
                    // label={'Prescription Instructions'}
                    // onKeyPress={KeyPress.INTEGER}
                    {...describe_any_alternative}
                  />
                  {values.alternative_field_error && <div
                    className={'pt-1 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
                    Ensure this field has no more than 500 characters.
                  </div>}
                </div>
              </div>

              <div className="d-flex mb-2">


                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Frequency'}
                    type="text"
                    // onKeyPress={KeyPress.INTEGER}
                    {...medication_frequency}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Important'}
                    type="text"
                    {...medication_important}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Prescription volume'}
                    type="text"
                    {...medication_prescription_volume}
                  />
                </div>
              </div>

              {/* Added by divyansh */}



              <div className="d-flex mb-2">


                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Reason'}
                    type="text"
                    {...medication_reason}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Potential side effects'}
                    type="text"
                    {...medication_potential_side_effects}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Comments'}
                    type="text"
                    {...medication_comments}
                  />
                </div>
              </div>

              <div className="d-flex mb-2">


                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Marking style'}
                    type="text"
                    {...medication_marking_style}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Storage location'}
                    type="text"
                    // onKeyPress={KeyPress.INTEGER}
                    {...medication_storage_location}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Feedback'}
                    type="text"

                    // onKeyPress={KeyPress.INTEGER}
                    {...medication_feedback}
                  />
                </div>
              </div>


              <div className="d-flex mb-2">


                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Medication reason'}
                    type="text"
                    // onKeyPress={KeyPress.INTEGER}
                    {...medication_medication_reason}
                  />
                </div>

                {/* <div className="w-100 pr-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Client'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </div> */}



                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Dose Regime'}
                    type="text"
                    {...medication_dose_regime}
                  />
                </div>



              </div>





              <div className="d-flex mb-2">



                <div className='w-100 pr-1'>

                  <div
                    className={'pt-2 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    Medication Instruction
                  </div>

                  <InputTextArea
                    type="text"
                    height={'2.4rem'}
                    id="med_desc"
                    placeHolder={'Medication Instruction.'}
                    label={'Medication Instruction'}
                    {...med_hook}
                  //   maxlength={5}
                  />
                </div>


              </div>




              {/* Add by divyansh end  */}
              <TitleSection>Administration</TitleSection>
              <div className="d-flex mt-2">
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Administration Reason'}
                    options={choices.administration_reason || []}
                    selectedOption={values.medication ? values.medication.administration_reason : null}
                    onChange={value =>
                      dropDownHandleSelect({ administration_reason: value ? value : null }, 'administration_reason')
                    }
                  />
                </div>
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Administration Type'}
                    options={choices.administration_type || []}
                    selectedOption={values.medication ? values.medication.administration_type : null}
                    onChange={value =>
                      dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                    }
                  />
                </div>
              </div>

              <div className="d-flex mt-2">


                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Administration support'}
                    type="text"
                    {...administration_support}
                  />
                </div>

                <div className="w-100 pr-1">
                  <InputLabelTop
                    disabled={!editMode}
                    label={'Administration Area'}
                    type="text"
                    {...administration_area}
                  />
                </div>

              </div>
              <div className="d-flex mt-2">
                <div className="w-100 pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Employee'}
                    options={choices.employee_listing.map(val => ({ ...val, name: val.full_name })) || []}
                    selectedOption={values.medication ? values.medication.employee : null}
                    {...medication_employee}
                    onChange={value =>
                      dropDownHandleSelect({ employee: value ? value : null }, 'employee')
                    }
                  />
                </div>
                <div className="w-100 pr-1">

                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Medication status'}
                    options={choices.medication_status || []}
                    selectedOption={values.medication ? values.medication.medication_status : null}
                    {...medication_medication_status}

                    onChange={value =>
                      dropDownHandleSelect({ medication_status: value ? value : null }, 'medication_status')
                    }
                  />
                </div>
              </div>

              <div className="d-flex mt-2">
                <div className="w-100 pr-1">
                  <DateSelector
                    isRequired
                    disabled={!editMode}
                    labelTop="Start Date"
                    {...medication_start_date}
                  />
                </div>
                <div className="w-100 pr-1">
                  <TimeSelector
                    disabled={!editMode}
                    // data-error={timeError[index]}
                    // errorMessage={timeErrorMessage}
                    isRequired
                    labelTop={'Dose Time'}
                    {...newDoseTime}
                    //added below code  for time in 24hrs format
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                  />
                </div>
              </div>
              <div className="d-flex mt-2 mb-1">

                <div className="w-100 pr-1">
                  <DateSelector
                    disabled={!editMode}
                    labelTop="Actual medication start date"
                    {...medication_actual_start_date}
                  />

                </div>

                <div className="w-100 pr-1">
                  <TimeSelector
                    disabled={!editMode}
                    // data-error={timeError[index]}
                    // errorMessage={timeErrorMessage}
                    //isRequired={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
                    labelTop={'Actual medication dose time'}
                    {...actualMedicationDoseTime}
                    //added below code  for time in 24hrs format
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                  />
                </div>
              </div>

              <div className="d-flex mt-2">
                <div className="w-100 pr-1">
                  <div
                    className={'pt-2 ml-md-1 ml-0'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    Additional Instructions
                  </div>
                  <InputTextArea
                    type="text"
                    height={'2.4rem'}
                    id="emp_desc"
                    placeHolder={'Additional Instructions'}
                    label={'Additional Instructions'}
                    {...ins_hook}
                  //   maxlength={5}
                  />
                </div>
              </div>
              {/* Signature Added */}
              <div className={'mt-3'}>
                <div className={'mb-2'}>
                  <TitleSection>Signature</TitleSection>
                </div>
                <div className="d-flex align-items-center" >
                  {
                    signature &&
                    <img
                      style={{ background: '#eef1f38c' }}
                      height={100} width={200}
                      src={signature} alt='sign' />
                  }
                </div>
              </div>

              <div>
                <TitleSection>Are there any additional instructions ?</TitleSection>
                <div>
                  {values?.medication_body_part?.map((item, indexBody) => {
                    return (<>
                      <BorderContainer className={'pl-1 pr-2 pt-1 pb-1 mb-2'}>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex w-100'>
                            <div className='ml-1 mr-2' style={{ color: PURPLE }}>
                              <IoIosBody />
                            </div>
                            <div className='d-flex w-100'>
                              <div className='' style={{ width: '30%' }}>
                                {item.tittle}
                              </div>
                              <div className='ml-2' style={{ color: GRAY_700, width: '70%' }}>
                                {item.description}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <IconWrapper
                              style={{ color: PURPLE }}
                              onClick={() => {
                                handleRowBodyClick(indexBody);
                              }}>
                              <MdEdit />
                            </IconWrapper>
                            <IconWrapper
                              style={{ color: ERR_COLOR, marginLeft: '1rem' }}
                              onClick={() => {
                                setModalDetachBodyMap(true)
                                setBodyMapDeleteIndex(indexBody)
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </div>
                        </div>
                      </BorderContainer>
                    </>)
                  })}
                </div>
                <div className={'mt-2 mb-2'}>
                  <PrimaryButtonForm minWidth="6rem" onClick={() => createNewBodyMap()}>
                    <FaPlus />
                    Create body map
                  </PrimaryButtonForm>
                </div>
              </div>
              <div
                className={
                  'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                }>
                <div className={'mb-3'}>
                  {false ? (
                    <ErrorMessage>
                      <label className={'mt-2'}>{'errorMessage'}</label>
                    </ErrorMessage>
                  ) : (
                    <Fragment />
                  )}
                </div>

                <div
                  className={'d-flex justify-content-center justify-content-md-end'}>
                  <div className={'ml-0 ml-md-3 mr-2'}>
                    {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                    <PrimaryButtonForm
                      disabled={loadingSaveMedication}
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={handleSubmit}
                    >
                      <span className={'mr-2'}>Save</span>
                      {!loadingSaveMedication ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>

                  </div>
                  <div></div>
                </div>
              </div>




            </div>)}
        </ShowTab>
        <ShowTab show={isCurrentTab(tab2)}>

          {tabSelected == tab2 && (
            <div className="pl-3 pr-3 pb-3 ">

              <MedicationReview clientId={values?.medication?.id} />


            </div>
          )}
        </ShowTab>
        <ShowTab show={isCurrentTab(tab3)}>

          {tabSelected == tab3 && (
            <div className="pl-3 pr-3 pb-3 ">

              <MedicationErrorModal clientId={values?.medication?.id} contextChoices={contextChoices} comment={values?.medication.medication_error_comment} />


            </div>
          )}
        </ShowTab>
        <ShowTab show={isCurrentTab(tab4)}>

          {tabSelected == tab4 && (
            <div className="pl-3 pr-3 pb-3 ">
              <AuditHistory
                med_id={values?.medication?.id}
                choices={contextChoices}
              />
            </div>
          )}
        </ShowTab>
        {!modalBodyMap ? null : (
          <BodyMapModal
            fullBind={fullBindBodyMap}
            setModalBodyMap={setModalBodyMap}
            useInput={useInput}
            values={values}
            setValues={setValues}
          />
        )}
        <div className='body-map-modal'>
          <ConfirmDecision
            type="confirm"
            title="Confirm"
            body={
              'Are you sure you want to delete the row? '
            }
            onOk={() => {
              toDeleteBodyData(bodyMapDeleteIndex);
            }
            }
            onNo={
              () => {
                setModalDetachBodyMap(false)
              }
            }
            {...bindModalDetachBodyMap}
          />
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default EditMedication;