import React, {Fragment} from 'react';
import DynamicList from 'components/SharedComponents/DynamicList';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {FaTimes} from 'react-icons/fa';
import DateSelector from 'components/SharedComponents/DateSelector';
import moment from 'moment';
import {CloseButtonWrapper, SkillRow, LeftSkillRow,BorderContainer} from './styles';
import LineContainer from 'components/SharedComponents/LineContainer';

function AddReferences(props) {
  const dropDownHandleSelect = (item, index, attribute) => {
    const newReferences = [...props.employee_reference];
    newReferences[index][attribute] = item;

    props.setReferencesCallback(newReferences);
  };

  const handleInputChange = (e, attribute) => {
    const newReferences = [...props.employee_reference];
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    newReferences[e.target.dataset.idx][attribute] = e.target.value;

    props.setReferencesCallback(newReferences);
  };

  return (
    <DynamicList
      title="References"
      titleAdd="ADD Reference"
      titleSave="SAVE SKILLS"
      addNew={props.addNewReferenceCallback}
      save={() => {}}
      noSave={props.noSave}>
      <Fragment>
        {props.employee_reference ? (
          props.employee_reference.map((reference, index) => {
            return (
            <BorderContainer className={'pl-1 pr-2 pt-3 pb-1 mb-3'}>
              <SkillRow className="d-flex ml-1 mb-2" key={index}>
                <LeftSkillRow className="d-flex flex-column w-100">
                  
                  <div className={'d-flex mb-2'}>
                  <CloseButtonWrapper>
                    <FaTimes
                      onClick={() => {
                        props.deleteReferenceCallback(index);
                      }}
                    />
                  </CloseButtonWrapper>
                    <div className="w-100 mr-1">
                    <DropdownSearchable
                      placeHolder={'Reference Status'}
                      options={props.reference_status}
                      disabled={props.readOnly}
                      selectedOption={
                        props.employee_reference[index].reference_status
                          ? props.employee_reference[index].reference_status
                          : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            value ? value : null,
                            index,
                            'reference_status',
                          )
                        }
                      />
                    </div>
                  <div className="w-100 mr-1">
                  <DateSelector
                        id={`completed_date_${index}`}
                        dataIdx={index}
                        labelTop="Reference Received Date"
                        value={
                          props.employee_reference[index].reference_received_date
                            ? props.employee_reference[index].reference_received_date
                            : ''
                        }
                        onChange={e => {
                          handleInputChange(e, 'reference_received_date');
                        }}
                      />
                    </div>
                    <div className="w-100 mr-1">
                    <DropdownSearchable
                      placeHolder={'Reference Updated By'}
                      options={props.employee_list}
                      disabled={props.readOnly}
                      selectedOption={
                        props.employee_reference[index].reference_updated_by
                          ? props.employee_reference[index].reference_updated_by
                          : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            value ? value : null,
                            index,
                            'reference_updated_by',
                          )
                        }
                      />
                    </div>
                  </div>
                 
                  </LeftSkillRow>
                </SkillRow>
                </BorderContainer>
              );
            })
          ) : (
            <></>
          )}
        </Fragment>
      </DynamicList>
    );
  }
  
  export default AddReferences;
  