import React from 'react';
import HeaderTable from './HeaderTable';
import BodyTable from './BodyTable';
import {Container} from './styles';

const PermissionsTable = ({rolValues, selectedRol, setRolValues,handleCheckboxClick}) => {
  return (
    <Container>
      <div className="wrapper">
        <table className="table table-sm">
          <thead>
            <HeaderTable selectedRol={selectedRol} />
          </thead>
          <tbody>
            <BodyTable
              handleCheckboxClick={handleCheckboxClick}
              rolValues={rolValues}
              selectedRol={selectedRol}
              setRolValues={setRolValues}
            />
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default PermissionsTable;
