import React, { useState, Fragment, useEffect,useContext } from 'react';
import {
    UserImgContainer,
    UserImgWrapper,
    TitleSection,
    FlexEqual,
    ContainerResponsive,
    ErrorMessage,
    UploadPhotoContainer,
    NoPermissionContainer,
  } from './styles';
  import { useForm } from 'hooks/FormHook';
  import { KeyPress } from 'utils/constants/regularExpressions';
  import Image from 'components/SharedComponents/Image';
  import { pickFile } from 'utils/files';
  import { FiUpload } from 'react-icons/fi';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons';
  import AvoidDetails from 'components/SharedComponents/FormSections/Team/AvoidDetails'
  import {BsFillPlusSquareFill} from "react-icons/bs"
  import _ from 'lodash';
  import {
    FlexGrid3Container,
    FlexGrid3Item,
  } from 'shared/styles/constants/tagsStyles';
  import {
    setEmployeeDetails,
    getEmployeeDetails,
  } from 'utils/api/EmployeeApi';
  import {setMedCondition,setNewMedCondition,setAllergySource} from 'utils/api/SettingsApi';
  import {AppContext} from "context"  ;
  import {
    EMPLOYEE_DEMOGRAPHICS,
    EMPLOYEE_NEXT_OF_KIN,
    EMPLOYEE_SKILLS_AND_QUALIFICATIONS,
    MEDICAL_DETAILS,
    ALLERGIES_AND_INTOLERANCE,
    WEB_CREATE_PERMISSION,
    WEB_PERMISSION,
    WEB_UPDATE_PERMISSION,
    WEB_READ_PERMISSION,
    SELF_WEB_CREATE_PERMISSION,
    SELF_WEB_UPDATE_PERMISSION,
    SELF_WEB_READ_PERMISSION,VISIT
  } from '../../../../utils/constants/permissions';
  import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
  import DateSelector from 'components/SharedComponents/DateSelector';
  import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
  import ToggleButton from 'components/SharedComponents/ToggleButton';
  import { useHistory,useLocation } from 'react-router';
  import {
    uploadEmployeeDetailsPhoto
  } from 'utils/api/EmployeeApi';
import { employee_details_choices } from 'utils/choiceConstant';
  

const EmployeeDetails = ({
  choices,
  empId,
  selectedId,
  successCallback,
  createPermissionEmployeeDetails,
  updatePermissionEmployeeDetails,
  readPermissionEmployeeDetails,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userEmployee,
  empDet,
  setEmpDet,
  handleChangeChoices,
}) => {
  const [nokPhoto, setNokPhoto] = useState(null);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [modalLoading, setModalLoading] = useState(true)
  const [employeeDetailsEmployeeDemographicsCreate, setEmployeeDetailsEmployeeDemographicsCreate] = useState(false);
  const [employeeDetailsEmployeeDemographicsUpdate, setEmployeeDetailsEmployeeDemographicsUpdate] = useState(false);
  const [employeeDetailsEmployeeDemographicsRead, setEmployeeDetailsEmployeeDemographicsRead] = useState(false);
  const [employeeDetailsEmployeeNextOfKinCreate, setEmployeeDetailsEmployeeNextOfKinCreate] = useState(false);
  const [employeeDetailsEmployeeNextOfKinUpdate, setEmployeeDetailsEmployeeNextOfKinUpdate] = useState(false);
  const [employeeDetailsEmployeeNextOfKinRead, setEmployeeDetailsEmployeeNextOfKinRead] = useState(false);
  const [employeeDetailsEmployeeSkillsAndQualificationsRead, setEmployeeDetailsEmployeeSkillsAndQualificationsRead] = useState(false);
  const [employeeDetailsEmployeeSkillsAndQualificationsCreate, setEmployeeDetailsEmployeeSkillsAndQualificationsCreate] = useState(false);
  const [employeeDetailsEmployeeSkillsAndQualificationsUpdate, setEmployeeDetailsEmployeeSkillsAndQualificationsUpdate] = useState(false);
  const [employeeDetailsMedicalDetailsRead, setEmployeeDetailsMedicalDetailsRead] = useState(false);
  const [employeeDetailsMedicalDetailsCreate, setEmployeeDetailsMedicalDetailsCreate] = useState(false);
  const [employeeDetailsMedicalDetailsUpdate, setEmployeeDetailsMedicalDetailsUpdate] = useState(false);
  const [employeeDetailsAllergiesAndIntolerancesRead, setEmployeeDetailsAllergiesAndIntolerancesRead] = useState(false);
  const [employeeDetailsAllergiesAndIntolerancesCreate, setEmployeeDetailsAllergiesAndIntolerancesCreate] = useState(false);
  const [employeeDetailsAllergiesAndIntolerancesUpdate, setEmployeeDetailsAllergiesAndIntolerancesUpdate] = useState(false);
  const [medicalConditionList, setMedicalConditionList] = useState([]);
  const [allegrySourceList, setAllegrySourceList] = useState([]);
  const [newMedical, setNewMedical] = useState(); 
  const [indexMed, setIndexMed] = useState();
  const [newAllergy, setNewAllergy] = useState();
  const {contextChoices}=useContext(AppContext) ;
  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
    useForm({});
  const history = useHistory()
  const location = useLocation()
  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(employee_details_choices)
  },[])
  useEffect(()=>{
    let medical_condition = [];
    let allergy_source = [];
   
      if (contextChoices && contextChoices.all_medical_condition) {
        medical_condition = contextChoices.all_medical_condition.map(item => {
          return { id: item.id, name: item.name };
        });
        medical_condition.unshift({ id: 'newMedical', name: '+ new medical condition' })
        setMedicalConditionList(medical_condition || []);
    }

    if (contextChoices && contextChoices.allergy_source) {

      allergy_source = contextChoices.allergy_source.map(item => {
        return { id: item.id, name: item.name };
      });
      allergy_source.unshift({ id: 'newAllegry', name: '+ new allergy source' })
      setAllegrySourceList(allergy_source || []);
  }

  },[contextChoices.allergy_source,
    contextChoices.allergy_source
  ])
  useEffect(() => {
    if (filterSuperAdmin) {
      setEmployeeDetailsEmployeeDemographicsCreate(true);
      setEmployeeDetailsEmployeeDemographicsUpdate(true);
      setEmployeeDetailsEmployeeDemographicsRead(true);
      setEmployeeDetailsEmployeeNextOfKinCreate(true)
      setEmployeeDetailsEmployeeNextOfKinUpdate(true)
      setEmployeeDetailsEmployeeNextOfKinRead(true)
      setEmployeeDetailsEmployeeSkillsAndQualificationsRead(true)
      setEmployeeDetailsEmployeeSkillsAndQualificationsUpdate(true)
      setEmployeeDetailsEmployeeSkillsAndQualificationsCreate(true)
      setEmployeeDetailsMedicalDetailsRead(true)
      setEmployeeDetailsMedicalDetailsCreate(true)
      setEmployeeDetailsMedicalDetailsUpdate(true)
      setEmployeeDetailsAllergiesAndIntolerancesRead(true)
      setEmployeeDetailsAllergiesAndIntolerancesUpdate(true)
      setEmployeeDetailsAllergiesAndIntolerancesCreate(true)
    }else if(filterAdmin){
      setEmployeeDetailsEmployeeDemographicsCreate(true);
      setEmployeeDetailsEmployeeDemographicsUpdate(true);
      setEmployeeDetailsEmployeeDemographicsRead(contextSubscription?.[EMPLOYEE_DEMOGRAPHICS]?.[WEB_PERMISSION]);
      setEmployeeDetailsEmployeeNextOfKinCreate(true)
      setEmployeeDetailsEmployeeNextOfKinUpdate(true)
      setEmployeeDetailsEmployeeNextOfKinRead(contextSubscription?.[EMPLOYEE_NEXT_OF_KIN]?.[WEB_PERMISSION])
      setEmployeeDetailsEmployeeSkillsAndQualificationsRead(contextSubscription?.[EMPLOYEE_SKILLS_AND_QUALIFICATIONS]?.[WEB_PERMISSION])
      setEmployeeDetailsEmployeeSkillsAndQualificationsUpdate(true)
      setEmployeeDetailsEmployeeSkillsAndQualificationsCreate(true)
      setEmployeeDetailsMedicalDetailsRead(contextSubscription?.[MEDICAL_DETAILS]?.[WEB_PERMISSION])
      setEmployeeDetailsMedicalDetailsCreate(true)
      setEmployeeDetailsMedicalDetailsUpdate(true)
      setEmployeeDetailsAllergiesAndIntolerancesRead(contextSubscription?.[ALLERGIES_AND_INTOLERANCE]?.[WEB_PERMISSION]);
      setEmployeeDetailsAllergiesAndIntolerancesUpdate(true)
      setEmployeeDetailsAllergiesAndIntolerancesCreate(true)
    }
    else {
      if (rightPermission[EMPLOYEE_DEMOGRAPHICS] !== undefined) {
        setEmployeeDetailsEmployeeDemographicsCreate(
          rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsEmployeeDemographicsUpdate(
          rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsEmployeeDemographicsRead(contextSubscription?.[EMPLOYEE_DEMOGRAPHICS]?.[WEB_PERMISSION] && (
          rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[EMPLOYEE_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[EMPLOYEE_NEXT_OF_KIN] !== undefined) {

        setEmployeeDetailsEmployeeNextOfKinCreate(
          rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsEmployeeNextOfKinUpdate(
          rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsEmployeeNextOfKinRead(contextSubscription?.[EMPLOYEE_NEXT_OF_KIN]?.[WEB_PERMISSION] && (
          rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[EMPLOYEE_NEXT_OF_KIN][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS] !== undefined) {
        setEmployeeDetailsEmployeeSkillsAndQualificationsRead(contextSubscription?.[EMPLOYEE_SKILLS_AND_QUALIFICATIONS]?.[WEB_PERMISSION] && (
          rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setEmployeeDetailsEmployeeSkillsAndQualificationsUpdate(
          rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsEmployeeSkillsAndQualificationsCreate(
          rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_SKILLS_AND_QUALIFICATIONS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
      if (rightPermission[MEDICAL_DETAILS]) {
        setEmployeeDetailsMedicalDetailsRead(contextSubscription?.[MEDICAL_DETAILS]?.[WEB_PERMISSION] && (
          rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        )
        setEmployeeDetailsMedicalDetailsCreate(
          rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        )
        setEmployeeDetailsMedicalDetailsUpdate(
          rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[MEDICAL_DETAILS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        )
      }
      if (rightPermission[ALLERGIES_AND_INTOLERANCE] !== undefined) {
        setEmployeeDetailsAllergiesAndIntolerancesRead(contextSubscription?.[ALLERGIES_AND_INTOLERANCE]?.[WEB_PERMISSION] && (
          rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setEmployeeDetailsAllergiesAndIntolerancesUpdate(
          rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setEmployeeDetailsAllergiesAndIntolerancesCreate(
          rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[ALLERGIES_AND_INTOLERANCE][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
    }

  }, [filterAdmin, filterSuperAdmin, rightPermission,contextSubscription])


  useEffect(() => {
    if (!values.medical_details) {
      values.medical_details = []
      setValues({ ...values })
    }
    if (selectedId || empDet) {
      loadEmpDetails()

    }
    else if (empId) {
      setModalLoading(false)
    }
  }, [])

  const handlePickFile = async (evt, section) => {
    const result = await pickFile(evt);
    switch (section) {
      case 'photo':
        setEmployeePhoto(result.file);
        break;
      case 'nok':
        setNokPhoto(result.file);
        break;
      default:
    }
  };
  const dropDownHandleSelect = item => {
    if(item.allergy_source == 'newAllegry'){
      setNewAllergy(true);
    }else{
      setNewAllergy(false);
    
    }
            // recursive objects merge
            const result = _.merge(values, item);
            setValues({ ...result })
  };

  useEffect(()=>{
    if(values.allergy_source !== 'newAllegry'){
      setNewAllergy(false);
    }
  },[values.allergy_source])


  const loadEmpDetails = async () => {
    let id = selectedId ? selectedId : empDet
    const response = await getEmployeeDetails(id)
    if (response.results.length > 0) {
      if (response.results[0].nok && response.results[0].nok.permission_right === true) {
        response.results[0].nok.permission_right = 1
      }
      else if (response.results[0].nok && response.results[0].nok.permission_right === false) {
        response.results[0].nok.permission_right = 2
      }
      setValues({ ...values, ...response.results[0] })
    }
    setModalLoading(false)
  }

  const deleteSkill = index => {
    // if skill id then save it for delete later
    if (values.skills[index].id) {
      setSkillsToDelete(skillsToDelete.concat([values.skills[index]]));
    }

    // new array of skills without the deleted skill
    let newSkills = [...values.skills];
    newSkills.splice(index, 1);
    values.skills = newSkills;
    setValues({ ...values });
  };
  const setSkills = skills => {
    values.skills = skills;
    setValues({ ...values });
  };
  const dropDownHandleAvoid = (item, nameSection, index, list_key) => {
    if (item.avoid == "newMedical" ) {
    setNewMedical('booleanValue');
    setIndexMed(index);
    let booleanValue='booleanValue';
    values[nameSection][index][booleanValue]=true;
    values[nameSection][index][list_key]=item.avoid;
    setValues({ ...values });
  } else {
    let booleanValue='booleanValue';
    setNewMedical('booleanValue');
    values[nameSection][index][booleanValue]=false;
    values[nameSection][index][list_key]=item.avoid;
        setValues({...values})
  }
  }

  const deleteAvoid = (nameSection, index) => {
    values[nameSection].splice(index, 1)
    setValues({ ...values })
  }
  const addNewAvoid = (nameSection) => {
    values[nameSection] = [...values[nameSection], {}]
    setValues({ ...values })

  }
  const handleSubmit = async(evt) => {
    evt.preventDefault();
    if (loadingSave) {
      return;
    }
if(values.medical_details){
        let medicalCondition =[];
        let data = values?.medical_details?.forEach(name=>{
          if( name.medical_condition ==  'newMedical'){
            medicalCondition.push(name.new_medical_condition)
          }
         })
        let arrIds=[];
       await setNewMedCondition(medicalCondition)
         .then(response => {
        if(response){
   
         let data= values?.medical_details?.forEach(dataname=>{
              if(dataname.medical_condition == 'newMedical'){
           let medicalNewIds = response.data.find(medicalData=>medicalData.name == dataname.new_medical_condition ).id;
           delete dataname.medical_condition;
           delete dataname.booleanValue;
           delete dataname.new_medical_condition;
           arrIds.push({...dataname, medical_condition:medicalNewIds})
              }else{
                delete dataname.booleanValue;
                arrIds.push({...dataname})
              }
            })

              values.medical_details=arrIds;

        }
         })

         .catch(onError);
         
        }

    if(values.allergy_source){  
   if(values.allergy_source == "newAllegry" ) {
    let allergyName = values.new_allergy_source;
await setAllergySource({name:allergyName}).then(response=>{
if(response){
  delete values.allergy_source;
  delete values.new_allergy_source;
  values.allergy_source=response.id;
}
})
.catch(onError);
   }
  }
    let body = {
      ...values
    };


    // if (nokPhoto) {
    //   body.photo = nokPhoto;
    // } else if (employeePhoto) {
    //   delete body.employeePhoto;
    // }

    if (body.nok && body.nok.permission_right) {
      if (body.nok.permission_right == 1) {
        body.nok.permission_right = true
      }
      else if (body.nok.permission_right == 2) {
        body.nok.permission_right = false
      }
    }
    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    setLoadingSave(true);
    setCheckRequires(false);
    setShowError(false);
    let value = [];
    let employeeId = ''
    if (location.pathname.includes('/team/add')) {
      employeeId = empId
    } else {
      employeeId = selectedId
    }
    setEmployeeDetails(body, employeeId)
      .then(response => {
        if (response.statusCode === 400) {
          // console.log( response.message,"445877")
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
        handleChangeChoices();
      })
      .catch(onError);
  };

  const savePhoto = (empId) => {
    let data = {
      ...values
    };
    if (nokPhoto) {
      let body = {
        photo: nokPhoto
      }
      uploadEmployeeDetailsPhoto(body, empId, data)
    }
  }

  const onSuccess = async (response) => {
    if (response.statusCode === 200 || response.statusCode === 201) {

      let employeeId = ''
      if (location.pathname.includes('/team/add')) {
        employeeId = empId
      } else {
        employeeId = selectedId
      }

      let employeeDetId = response.id;
      // if employee id

      setValues({
        ...values,
        id: employeeDetId,
      });

      successCallback();
      setEmpDet(employeeId);
      savePhoto(employeeId)  //added new
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
    );
  };

  const nok_no_hook = useInput('nok.no', 'isInt')
  const nok_full_name = useInput('nok.full_name')
  const nok_name = useInput('nok.name')
  const nok_line_1 = useInput('nok.line_1')
  const nok_line_2 = useInput('nok.line_2')
  const nok_city = useInput('nok.city')
  const nok_state = useInput('nok.state')
  const nok_zip_code = useInput('nok.zip_code')
  const nok_country = useInput('nok.country')
  const nok_mobile = useInput('nok.mobile')
  const nok_email = useInput('nok.email', 'isEmail')
  const disability_name = useInput('disability_name')
  const disability_no = useInput('disability_no')
  const allergy_start_date = useInput('allergy_start_date')
  const treatment_reaction = useInput('treatment_reaction')
  const allergy_notes = useInput('allergy_notes')
  const  new_allergy_source=useInput('new_allergy_source')
console.log(rightPermission,"rightPermission")
  const switchAllergySource = (props) => {
    if (newAllergy ) {
     return( 
     <div className="w-100">
      <InputLabelTop
      type="text"
      id="allegry_source"
      label={'new Allegry Source'}
      {...props}
      maxlength={100}
     />
  </div>
     )
    } else {
      return(
        <Fragment />
      )
    }
  }


  return (
    <>

      <ComponentDynamic
        loading={modalLoading}
      >
        {(selectedId ? readPermissionEmployeeDetails : createPermissionEmployeeDetails) ?
          <>
            {(selectedId ? employeeDetailsEmployeeNextOfKinRead : employeeDetailsEmployeeNextOfKinCreate)
              &&
              <>
                <TitleSection>Next of kin</TitleSection>
                <div className="d-flex flex-column mt-2">
                  <ContainerResponsive className="d-flex">
                    <div className="mr-md-2 mb-2">
                      <UserImgWrapper>
                        <UserImgContainer>
                          <Image
                            src={
                              nokPhoto
                                ? URL.createObjectURL(nokPhoto)
                                : values.nok
                                  ? values.nok.photo
                                  : ''
                            }
                            name={values && values.nok ? values.nok.full_name : ''}
                            alt={'employee-details-img'}
                            fontSize={'1.5rem'}
                          />
                        </UserImgContainer>

                        <UploadPhotoContainer className="d-flex align-items-center mt-2">
                          <UploadInput
                            onInput={e => {
                              handlePickFile(e, 'nok');
                            }}
                            onClick={event => {
                              event.target.value = null;
                            }}
                            type="file"
                            accept={'.png,.jpg,.jpeg'}
                            id="nok-photo"
                            name="nok-photo"
                            placeholder={'Nok photo'}
                          />
                          <FiUpload />
                          <label htmlFor="nok-photo">Upload</label>
                        </UploadPhotoContainer>
                      </UserImgWrapper>
                    </div>

                    <div className="w-100 d-flex flex-column">
                      <div className="w-100 d-flex">
                        <div className="w-100 mr-2">
                          <InputLabelTop
                            type="text"
                            id="nok_no"
                            label={'No.'}
                            readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                            onKeyPress={KeyPress.INTEGER}
                            {...nok_no_hook}
                            maxlength={5}
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Title'}
                            options={choices.title}
                            disabled={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                            selectedOption={
                              values.nok
                                ? values.nok.title
                                  ? values.nok.title
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                nok: { title: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="w-100 d-flex">
                        <div className="w-100 mr-2">
                          <InputLabelTop
                            type="text"
                            id="nok_full_name"
                            label={'Full Name'}
                            readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                            {...nok_full_name}
                            maxlength={255}
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Relationship'}
                            options={choices.nok_relationship}
                            selectedOption={
                              values.nok
                                ? values.nok.relation
                                  ? values.nok.relation
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                nok: { relation: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="d-flex mb-2">
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Permission Rights'}
                            options={[
                              { id: 1, name: 'Active' },
                              { id: 2, name: 'Inactive' },
                            ]}
                            disabled={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                            selectedOption={
                              values.nok
                                ? values.nok.permission_right
                                  ? values.nok.permission_right
                                  : null
                                : null}
                            onChange={value =>
                              dropDownHandleSelect({
                                nok: { permission_right: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </ContainerResponsive>

                  <InputLabelTop
                    type="text"
                    id="nok_house_company_name"
                    label={'House Name'}
                    readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                    {...nok_name}
                    maxlength={50}
                  />
                  <div className="d-flex">
                    <div className="w-100 mr-2">
                      <InputLabelTop
                        type="text"
                        id="nok_line_1"
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        label={'Address Line 1'}
                        {...nok_line_1}
                        maxlength={255}
                      />
                    </div>

                    <div className="w-100">
                      <InputLabelTop
                        type="text"
                        id="nok_line_2"
                        label={'Address Line 2'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_line_2}
                        maxlength={255}
                      />
                    </div>
                  </div>

                  <FlexGrid3Container className="w-100 mb-2">
                    <FlexGrid3Item className="pr-1">
                      <InputLabelTop
                        type="text"
                        id="nok_town_city"
                        label={'Town / City'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_city}
                        maxlength={100}
                      />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                      <InputLabelTop
                        type="text"
                        id="nok_country_state"
                        label={'County / State'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_state}
                        maxlength={100}
                      />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
                      <InputLabelTop
                        type="text"
                        id="nok_postcode_zipcode"
                        label={' Postcode / Zipcode'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_zip_code}
                        maxlength={25}
                      />
                    </FlexGrid3Item>

                    <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
                      <InputLabelTop
                        type="text"
                        id="nok_country"
                        label={'Country'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_country}
                        maxlength={100}
                      />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
                      <InputLabelTop
                        type="text"
                        id="nok_mobile"
                        label={'Mobile No.'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_mobile}
                        maxlength={25}
                      />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="pl-1">
                      <InputLabelTop
                        type="email"
                        id="nok_email"
                        label={'Email'}
                        readOnly={selectedId ? !employeeDetailsEmployeeNextOfKinUpdate : !employeeDetailsEmployeeNextOfKinCreate }
                        {...nok_email}
                        maxlength={254}
                      />
                    </FlexGrid3Item>
                  </FlexGrid3Container>
                </div>
              </>
            }
            <ContainerResponsive className="justify-content-between mt-3">
              {(selectedId ? employeeDetailsEmployeeDemographicsRead : employeeDetailsEmployeeDemographicsCreate)
                &&
                <FlexEqual className="mr-sm-0 mr-md-2">
                  <TitleSection>Employee Demographics</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <div className="d-flex mb-2">
                      <div className="mr-2 w-100">
                        <DropdownSearchable
                          placeHolder={'Nationality'}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          options={choices.nationality}
                          selectedOption={
                            values.demographics
                              ? values.demographics.nationality
                                ? values.demographics.nationality
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { nationality: value ? value : null },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Country Of Birth'}
                          options={choices.country}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.birth_country
                                ? values.demographics.birth_country
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { birth_country: value ? value : null },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mr-2 w-100">
                        <DropdownSearchable
                          placeHolder={'Ethnicity Category'}
                          options={choices.ethnicity}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.ethnicity
                                ? values.demographics.ethnicity
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { ethnicity: value ? value : null },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Ethnicity Description'}
                          options={choices.ethnicity_description}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.ethnicity_description
                                ? values.demographics.ethnicity_description
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { ethnicity_description: value ? value : null },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mr-2 w-100">
                        <DropdownSearchable
                          placeHolder={'Religion'}
                          options={choices.religion}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.religion
                                ? values.demographics.religion
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { religion: value ? value : null },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'British Citizenship'}
                          options={choices.common_core_confirmation}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.british_citizenship
                                ? values.demographics.british_citizenship
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { british_citizenship: value ? value : null },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mr-2 w-100">
                        <DropdownSearchable
                          placeHolder={'Preferred Language'}
                          options={choices.language}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.language
                                ? values.demographics.language
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: { language: value ? value : null },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Second Language'}
                          options={choices.language}
                          disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                          selectedOption={
                            values.demographics
                              ? values.demographics.language_second
                                ? values.demographics.language_second
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              demographics: {
                                language_second: value ? value : null,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <DropdownSearchable
                        placeHolder={'Third Language'}
                        options={choices.language}
                        disabled={selectedId ? !employeeDetailsEmployeeDemographicsUpdate : !employeeDetailsEmployeeDemographicsCreate }
                        selectedOption={
                          values.demographics
                            ? values.demographics.language_third
                              ? values.demographics.language_third
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            demographics: { language_third: value ? value : null },
                          })
                        }
                      />
                    </div>
                  </div>
                </FlexEqual>
              }
              <FlexEqual marginTopSm noMarginLeft className="ml-sm-0 md-2 mt-4">
                {(selectedId ? employeeDetailsMedicalDetailsRead : employeeDetailsMedicalDetailsCreate)
                  &&
                  <>
                    <TitleSection>Medical details</TitleSection>
                    <div className="d-flex flex-column mt-2">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <DropdownSearchable
                            placeHolder={'Disability'}
                            options={choices.disability}
                            disabled={selectedId ? !employeeDetailsMedicalDetailsUpdate : !employeeDetailsMedicalDetailsCreate }
                            selectedOption={
                              values
                                ? values.disability
                                  ? values.disability
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                { disability: value ? value : null },
                              )
                            }

                          />
                        </div>
                        <div className="w-100 mr-2">
                          <InputLabelTop
                            type="text"
                            id="disability_name"
                            label={'Disability Name / Description'}
                            readOnly={selectedId ? !employeeDetailsMedicalDetailsUpdate : !employeeDetailsMedicalDetailsCreate }
                            {...disability_name}
                            maxlength={100}
                          />
                        </div>
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            id="disability_no"
                            label={'Disability Number'}
                            readOnly={selectedId ? !employeeDetailsMedicalDetailsUpdate : !employeeDetailsMedicalDetailsCreate }
                            {...disability_no}
                            maxlength={25}
                          />
                        </div>

                      </div>
                      {/* <div className="d-flex">
                    <div className="w-100 mr-2">
                      <DropdownSearchable
                        placeHolder={'Medical Condition'}
                        options={choices.medical_condition}
                        selectedOption={
                          values
                            ? values.medical_condition
                              ? values.medical_condition
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                             { medical_condition: value ? value : null },
                          )
                        }
                      />
                      </div>
                      <div className='w-100'>
                      <InputLabelTop
                      type="text"
                      id="medical_desc"
                      label={'Medical Condition Name - Description'}
                      {...useInput('medical_desc')}
                      maxlength={255}
                    />
                      </div>
                    </div> */}

                    </div>
                    <div>

                      {
                        values && values.medical_details && values.medical_details.map((medical, indexMedical) => {
                          return (
                            <AvoidDetails
                              values={values}
                              dropDownLabel={'Medical Condition'}
                              dropDownList={medicalConditionList}
                          newMedical={newMedical}
                          indexMed={indexMed}
                              // dropDownList={choices.all_medical_condition}
                              list_key={'medical_condition'}
                              dropDownHandleAvoid={dropDownHandleAvoid}
                              useInput={useInput}
                              medical={true}
                              nameSection={'medical_details'}
                              index={indexMedical}
                              deleteAvoid={deleteAvoid}
                              readOnly={selectedId ? !employeeDetailsMedicalDetailsUpdate : !employeeDetailsMedicalDetailsCreate }
                            />
                          )
                        })
                      }
                    </div>
                    <PrimaryButtonForm
                      className={'ml-3 mt-1 mb-2'}
                      bgcolor="BLUE"
                      minWidth="3rem"
                      onClick={() => addNewAvoid('medical_details')}
                    >
                      <span>{<BsFillPlusSquareFill />}Medical Condition</span>
                    </PrimaryButtonForm>
                  </>
                }
                {(selectedId ? employeeDetailsAllergiesAndIntolerancesRead : employeeDetailsAllergiesAndIntolerancesCreate)
                  &&
                  <>
                    <TitleSection>Allergies And Intolerances</TitleSection>
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <DateSelector
                          labelTop="Date Entered"
                          {...allergy_start_date}
                        />
                      </div>
                      <div className="w-100 mr-2">
                        <DropdownSearchable
                          placeHolder={'Allergy/Intolerance Type'}
                          options={choices.allergy_type}
                          disabled={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                          selectedOption={
                            values
                              ? values.allergy_type
                                ? values.allergy_type
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { allergy_type: value ? value : null },
                            )
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Allergy/Intolerance Source'}
                          // options={choices.allergy_source}
                          options={allegrySourceList}
                          disabled={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                          selectedOption={
                            values
                              ? values.allergy_source
                                ? values.allergy_source
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { allergy_source: value ? value : null },
                            )
                          }
                        />
                      </div>
                      {/* {switchAllergySource({ ...useInput(`new_allergy_source`) })} */}
                      {switchAllergySource( {...new_allergy_source})}
                    </div>
                    <div className="d-flex mt-2">
                      <div className="w-100 mr-2">
                        <DropdownSearchable
                          placeHolder={'Severity'}
                          options={choices.severity}
                          disabled={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                          selectedOption={
                            values
                              ? values.severity
                                ? values.severity
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { severity: value ? value : null },
                            )
                          }
                        />
                      </div>
                      <div className="w-100 mr-2">
                        <DropdownSearchable
                          placeHolder={'Sign/Symptoms'}
                          options={choices.sign_symptoms}
                          disabled={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                          selectedOption={
                            values
                              ? values.sign_symptoms
                                ? values.sign_symptoms
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { sign_symptoms: value ? value : null },
                            )
                          }
                        />
                      </div>
                      <div className='w-100'>
                        <InputLabelTop
                          type="text"
                          id="treatment"
                          label={'Treatment-in case of reaction'}
                          readOnly={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                          {...treatment_reaction}
                          maxlength={255}
                        />
                      </div>
                    </div>
                    <div className='w-100'>
                      <InputLabelTop
                        type="text"
                        id="other_allergy"
                        readOnly={selectedId ? !employeeDetailsAllergiesAndIntolerancesUpdate : !employeeDetailsAllergiesAndIntolerancesCreate }
                        label={'Other Allergy Or Intolerance Notes'}
                        {...allergy_notes}
                        maxlength={300}
                      />
                    </div>
                  </>
                }
              </FlexEqual>
            </ContainerResponsive>


            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>

                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      history.push('/team')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                  {(selectedId ? updatePermissionEmployeeDetails : true) && (
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={handleSubmit}>
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  )}
                </div>
              </div>
            </div>
          </>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedId ? 'read' : 'create'} the information.
          </NoPermissionContainer>
        }
      </ComponentDynamic>

    </>
  );
}

export default EmployeeDetails;
