import React, { useState, useEffect, useRef, } from 'react';
import AddForm from './AddForm';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {  addBranchTax, deleteTaxDiscount, getBranchTaxById } from 'utils/api/SettingsApi';
import { Header } from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useModal } from 'hooks/ModalHook';
import AddFormPopup from './AddFormPopup';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import ModalDecision from 'components/SharedComponents/ModalDecision';


const UPDATE_MESSAGE = "Tax updated successfully!"
const ADD_MESSAGE = "Tax Added successfully!"

const BranchTax = ({
  branch,
  choices,
  readOnly,
  readPermission,
  deletePermission,
  createPermisssion,
  updatePermissionTax
}) => {
  const toast = useRef(null);
  const history = useHistory()
  const BRANCH_TAX = 'tax_branch';
  const { values, setValues, useInput, isValid, errors, setCheckRequires, setFormErrors, } = useForm({ [BRANCH_TAX]: [] });
  const [errorMessage, setErrorMessage] = useState('');
  const [disableSave, setDisableSave] = useState(false)
  const [loading, setLoading] = useState(true)
  const [absenceAlreadyExists, setAbsenceAlreadyExists] = useState([])
  const [textStatus, setTextStatus] = useState(SAVE);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [seletedId, setSelectedId] = useState(null)
  const [deleteDisable, setDeleteDisable] = useState(false)

  const {
    setValue: setModalAdd,
    value: modalAdd,
    setTitle,
    fullBind: fullBindAdd,
  } = useModal(false);

  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);

  const addNewAvoid = (id, index, rowData) => {
    // values[BRANCH_TAX].push({});
    // setValues({ ...values });


    if (id) {
      values.id = id;
      values.tax_type = getTaxType(rowData)
      setValues({ ...values, ...rowData })
    } else {
      onResetFrom()
    }
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setLoadingSave(false)
    setModalAdd(true)
  };

  const getTaxType = (row) =>
    row.is_travel ? 'is_travel' :
      row.is_distance ? 'is_distance' :
        row.is_expenses ? 'is_expenses' :
          'is_visit';

  const onResetFrom = _ => {
    values.id = null
    values.tax_type = null
    values.discount_percentage = null
    values.discount_amount = null
    values.tax_amount = null
    values.tax_percentage = null
    values.service_category = null
    values.effective_date_from = null
    values.effective_date_to = null
    setValues({ ...values })
  }

  const getTaxListById = async () => {
    try {
      const res = await getBranchTaxById(branch?.id)
      setAbsenceAlreadyExists(res.results ? res.results : [])
      values[BRANCH_TAX] = res.results ? res.results : []
      setValues({ ...values })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTaxListById()
  }, [])

  // useEffect(() => {
  //   if (values.tax_branch?.length === 0) {
  //     setErrorMessage(
  //       'Some of the fields have invalid or empty values. Please review'
  //     );
  //     setDisableSave(true)
  //   } else if (values.tax_branch?.length === 1) {
  //     setErrorMessage();
  //     setDisableSave(false)
  //   }
  // }, [values.tax_branch?.length])

  useEffect(() => {
    if (values?.tax_branch?.length) {
      values.tax_branch.map((item) => {
        if (item.absence_type && item.time_unit && item.allowance_year_from) {
          setErrorMessage('');
          setCheckRequires(false);
          setFormErrors([]);
          setDisableSave(false);
        }
      });
    }
  }, [values]);

  const HandleSubmit = async () => {
    if (loadingSave) {
      return;
    }
    if (errors.length > 0) {
      setErrorMessage('Some of the fields have invalid or empty values. Please review');
      setCheckRequires(true);
      return;
    }
    const body =
    {
      effective_date_from: values.effective_date_from || null,
      effective_date_to: values.effective_date_to,
      service_category: (values.tax_type == 'is_visit') ? values.service_category : "",
      branch: branch?.id,
      discount_percentage: values.discount_percentage || null,
      discount_amount: values.discount_amount || null,
      tax_amount: values.tax_amount || null,
      tax_percentage: values.tax_percentage || null,
      is_travel: (values.tax_type == 'is_travel') ? true : false,
      is_distance: (values.tax_type == 'is_distance') ? true : false,
      is_expenses: (values.tax_type == 'is_expenses') ? true : false,
      is_visit: (values.tax_type == 'is_visit') ? true : false,
    }
    if (values.id) {
      body.id = values.id
    }

    setLoadingSave(true)
    try {
      const res = await addBranchTax(body)
      if (res.id) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: values.id ? UPDATE_MESSAGE : ADD_MESSAGE,
        })
        onSuccess()
      }
      // if (absenceAlreadyExists.length || !values?.tax_branch?.length) {
      //   const res = await updateAbsenceOverview(body)
      //   if (res.status) {
      //     toast.current.show({
      //       severity: 'success',
      //       summary: 'Success',
      //       detail: `${res.message}`,
      //     })
      //   } else {
      //     toast.current.show({
      //       severity: 'error',
      //       summary: 'Failed',
      //       detail: `${res.message}`,
      //     });
      //   }

      // } else {
      //   const res = await addAbsenceOverview(body)
      //   if (res.status) {
      //     toast.current.show({
      //       severity: 'success',
      //       summary: 'Success',
      //       detail: `${res.message}`,
      //     })
      //   } else {
      //     toast.current.show({
      //       severity: 'error',
      //       summary: 'Failed',
      //       detail: `${res.message}`,
      //     });
      //   }
      // }
      // setDisableSave(false)
    } catch (error) {
      onError(error)
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Something went wrong!`,
      });
    }
  }

  const onSuccess = () => {
    setModalAdd(false)
    getTaxListById()
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  }

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };


  const deleteTaxDiscountRow = async () => {
    if (deleteDisable) {
      return;
    }
    setDeleteDisable(true)
    try {
      const res = await deleteTaxDiscount(seletedId)
      setSelectedId(null)
      setModalDeleteValue(false)
      setDeleteDisable(false)
      if (res.status) {
        getTaxListById()
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.message,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `Something went wrong!`,
        });
      }
    } catch (error) {

    }
  }
  return (
    <div className='w-100 mb-4' style={{ marginTop: "8rem" }}>
      <Toast ref={toast} position='top-right' />
      <Header className='mb-2'>
        <div className='w-100 d-flex justify-content-end'>
          {createPermisssion && <PrimaryButtonForm
            className={'ml-3 mt-1 mb-2'}
            bgcolor="BLUE"
            style={{ width: '5px' }}
            onClick={() => addNewAvoid()}>
            <span>{<BsFillPlusSquareFill />}New Tax</span>
          </PrimaryButtonForm>}
        </div>
      </Header>
      <div className='mt-3'>
        <ComponentDynamic loading={loading}>
          {values.tax_branch?.length >= 1 &&
            values.tax_branch?.map((item, index) => {
              return (
                <AddForm
                  values={values}
                  setValues={setValues}
                  useInput={useInput}
                  choices={choices}
                  toast={toast}
                  index={index}
                  errorMessage={errorMessage}
                  addNewAvoid={addNewAvoid}
                  updatePermissionTax={updatePermissionTax}
                  deletePermission={deletePermission}
                  getTaxType={getTaxType}
                  setModalDeleteValue={setModalDeleteValue}
                  setSelectedId={setSelectedId}
                />
              )
            })}


          {modalAdd &&
            <AddFormPopup
              values={values}
              setValues={setValues}
              useInput={useInput}
              choices={choices}
              toast={toast}
              errorMessage={errorMessage}
              fullBind={fullBindAdd}
              setModalAdd={setModalAdd}
              HandleSubmit={HandleSubmit}
              isValid={isValid}
              errors={errors}
              setTitle={setTitle}
              // readOnly={readOnly}
              updatePermissionTax={updatePermissionTax}
              textStatus={textStatus}
              buttonIcon={buttonIcon}
              loadingSave={loadingSave}
            />}

          <ModalDecision
            type="delete"
            title="Warning"
            body={
              'Are you sure you want to delete this Tax Discount? \n deleting the Tax Discount will remove all records from database.'
            }
            onOk={deleteTaxDiscountRow}
            onCancel={() => {
              setSelectedId('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />

        </ComponentDynamic>
      </div>
    </div>


  );
};

export default BranchTax;
