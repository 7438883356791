import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { TIMES } from 'utils/constants/icons';
import {
  CloseButtonWrapper,
  SampleMessage,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import DurationInput from 'components/SharedComponents/DurationInput';
import RateCardAdhoc from './RateCardAdhoc';
import moment from 'moment';
import { getSchedulerPlanned } from 'utils/api/ClientApi';

const Serviceadhocemployee = ({
  choices,
  nameSection,
  values,
  setValues,
  useInput,
  dropdownHandleAdhocEmployeeSkill,
  indexEmployee,
  handleClick,
  dropdownHandleAdhocEmployee,
  deleteVisitEmployee,
  readOnly = false,
  editMode,
  addPayrate,
  bill_rate,
  data,
  dataList,
  payRateDrodownList,
  clientDropdownId,
}) => {
  // const [plannedTasks, setPlannedTasks] = useState([]);

  useEffect(() => {
    // namesction=visitValues
    let emp_start_time =
      values[nameSection] &&
      values[nameSection].employee_required[indexEmployee] &&
      values[nameSection].employee_required[indexEmployee].start_time;
    let emp_end_time =
      values[nameSection] &&
      values[nameSection].employee_required[indexEmployee] &&
      values[nameSection].employee_required[indexEmployee].end_time;
    if (emp_start_time && emp_end_time) {
      let start_time = moment(emp_start_time, 'HH:mm:ss');
      let end_time = moment(emp_end_time, 'HH:mm:ss');
      if (end_time > start_time) {
        values[nameSection].employee_required[indexEmployee].duration =
          end_time.diff(start_time, 'seconds');
      } else {
        let add_date = end_time.add(1, 'days');
        values[nameSection].employee_required[indexEmployee].duration =
          add_date.diff(start_time, 'seconds');
      }
    } else {
      values[nameSection].employee_required[indexEmployee].duration = 0;
    }
    setValues({ ...values });
  }, [
    values[nameSection] &&
    values[nameSection].employee_required[indexEmployee] &&
    values[nameSection].employee_required[indexEmployee].start_time,
    values[nameSection] &&
    values[nameSection].employee_required[indexEmployee] &&
    values[nameSection].employee_required[indexEmployee].end_time,
  ]);

  // useEffect(() => {
  //   if (values[nameSection].client) {
  //     getPlannedTasks();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values[nameSection].client]);

  // const getPlannedTasks = async () => {
  //   const response = await getSchedulerPlanned(values[nameSection].client);

  //   if (response.results) {
  //     setPlannedTasks(
  //       response.results.map(item => {
  //         return { id: item.id, name: item.group_name };
  //       })
  //     );
  //   }
  // };

  const dropDownHandleRate = (
    item,
    nameSection,
    sectionIndex,
    list_key,
    category
  ) => {
    if (list_key == 'service_pay_rate') {
      if (item.rate === null) {
        values[category].employee_required[indexEmployee][nameSection][
          sectionIndex
        ].pay_rate_per_hour = null;
      } else {
        values[category].employee_required[indexEmployee][nameSection][
          sectionIndex
        ].pay_rate_per_hour = payRateDrodownList.find(
          rate => rate.id === item.rate
        ).rate_per_hour;
      }
    }
    values[category].employee_required[indexEmployee][nameSection][
      sectionIndex
    ][list_key] = item.rate;
    setValues({ ...values });
  };

  const addNewRate = nameKey => {
    if (!values[nameSection].employee_required[indexEmployee].pay_rates) {
      values[nameSection].employee_required[indexEmployee].pay_rates = [{}];
      setValues({ ...values });
    } else {
      values[nameSection].employee_required[indexEmployee][nameKey] = [
        ...values[nameSection].employee_required[indexEmployee][nameKey],
        {},
      ];
      setValues({ ...values });
    }
  };
  const deleteRate = (nameKey, category, sectionIndex) => {
    values[category].employee_required[indexEmployee][nameKey].splice(
      sectionIndex,
      1
    );
    setValues({ ...values });
  };

  return (
    <div
      style={{
        marginTop: indexEmployee !== 0 ? "30px" : "10px",
        border:
          values[nameSection] &&
            values[nameSection].client_service_visit_requirement &&
            values[nameSection].client_service_visit_requirement.id ===
            values.visitValues.employee_required[indexEmployee].id
            ? '1px solid red'
            : 'none',
      }}
      className={''}>
      <div
        key={indexEmployee}
        className={`d-flex mb-1 ${readOnly ? 'pl-0' : indexEmployee === 0 ? 'pl-0' : 'pl-0'
          }`}>
        {indexEmployee !== 0 && !readOnly && (
          <CloseButtonWrapper
            onClick={() => {
              deleteVisitEmployee(indexEmployee);
            }}>
            {TIMES}
          </CloseButtonWrapper>
        )}

        <div className={'w-100 p-0 d-flex mb-1'}>
          <div style={{width: indexEmployee !== 0 ? "94%":'100%'}}>
            <DropdownSearchable

              required
              
              {...useInput(
                `${[
                  nameSection,
                ]}.employee_required[${indexEmployee}].no_of_employees`,
                'isRequired'
              )}
              placeHolder={'No. of Employees'}
              options={[
                { id: 1, name: 1 },
                { id: 2, name: 2 },
                { id: 3, name: 3 },
                { id: 4, name: 4 },
                { id: 5, name: 5 },
              ]}
              selectedOption={
                values[nameSection]
                  ? values[nameSection].employee_required[indexEmployee]
                    .no_of_employees
                  : null
              }
              onChange={value =>
                dropdownHandleAdhocEmployee(
                  { no_of_employees: value ? value : null },
                  'no_of_employees',
                  indexEmployee
                )
              }
            />
          </div>
          <div className={'w-100'}>
            <DropdownSearchable

              required
              {...useInput(
                `${[
                  nameSection,
                ]}.employee_required[${indexEmployee}].skill_level`,
                'isRequired'
              )}
              placeHolder={'Employee Type'}
              marginLeft={'1%'}
              // width={'50%'}
              options={choices.job_title}
              selectedOption={
                values[nameSection]
                  ? values[nameSection].employee_required[indexEmployee]
                    .skill_level
                  : null
              }
              onChange={value =>
                dropdownHandleAdhocEmployee(
                  { skill_level: value ? value : null },
                  'skill_level',
                  indexEmployee
                )
              }
            />
          </div>
        </div>
      </div>

      <div className={'w-100 d-flex pb-2'}>
        <div className={'w-100'}
        // style={{ width: "70%" }}
        >
          <DropdownSearchable
            width={'100%'}

            // marginLeft={'1%'}
            placeHolder={'Skills required'}
            options={choices.skill}
            isMulti={true}
            selectedOptionsProps={
              values[nameSection]
                ? values[nameSection].employee_required[indexEmployee].skills
                : null
            }
            onChange={value =>
              dropdownHandleAdhocEmployeeSkill(
                { skill: value.length ? value : [] },
                indexEmployee
              )
            }
          />
        </div>

        <div className={'w-100 pl-2'}
        // style={{ width: "70%" }}
        >
          <DropdownSearchable

            // width={"67.5%"}
            // marginLeft={'2.4%'}
            placeHolder={'Competency required'}
            options={choices.competency_name}
            isMulti={true}
            selectedOptionsProps={
              values[nameSection].employee_required[indexEmployee].competency
            }
            onChange={value =>
              dropdownHandleAdhocEmployeeSkill(
                { competency: value.length ? value : [] },
                indexEmployee
              )
            }
          />
        </div>
        {/* <div className={'w-100 pl-2'}
        // style={{ width: "70%" }}
        >
          <DropdownSearchable

            // width={'30%'}
            // marginLeft={'0.5%'}
            placeHolder={'Planned Tasks'}
            options={plannedTasks ? plannedTasks : []}
            isMulti={true}
            selectedOptionsProps={
              values[nameSection].employee_required[indexEmployee].specific_groups
            }
            onChange={value =>
              dropdownHandleAdhocEmployeeSkill(
                { specific_groups: value.length ? value : [] },
                indexEmployee
              )
            }
          />
        </div> */}
      </div>

      <div className={'w-100  d-flex'}>
        <div className={'w-100 pr-2'}>
          <DropdownSearchable

            placeHolder={'Preferred Gender'}
            // width={'19%'}
            // marginRight={'1%'}
            options={choices.gender}
            selectedOption={
              values[nameSection]
                ? values[nameSection].employee_required[indexEmployee]
                  .preferred_gender
                : null
            }
            onChange={value =>
              dropdownHandleAdhocEmployee(
                { preferred_gender: value ? value : null },
                'preferred_gender',
                indexEmployee
              )
            }
          />
        </div>
        <div className={'w-100 pr-2'}
          onClick={() => !readOnly && handleClick(3)}
        >
          <TimeSelector

            labelTop={'Shift Start'}
            {...useInput(
              `${[nameSection]}.employee_required[${indexEmployee}].start_time`
            )}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
        <div className={'w-100 pr-2'}
          onClick={() => !readOnly && handleClick(4)}>
          <TimeSelector

            labelTop={'Shift End'}
            {...useInput(
              `${[nameSection]}.employee_required[${indexEmployee}].end_time`
            )}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
        <div className={'w-100'}>
          <DurationInput

            label_top={'Duration'}
            {...useInput(
              `${[nameSection]}.employee_required[${indexEmployee}].duration`
            )}
          />
        </div>
      </div>
      {/* <div>
        {addPayrate &&
          data &&
          data.pay_rates &&
          data.pay_rates.map((payRate, indexBill) => {
            return (
              <RateCardAdhoc
                readOnly={readOnly}
                editMode={editMode}
                values={values}
                bill_rate={bill_rate}
                list_key1={'service_pay_rate'}
                dropDownHandleRate={dropDownHandleRate}
                useInput={useInput}
                category={nameSection}
                nameSection={'pay_rates'}
                index={indexBill}
                deleteRate={deleteRate}
                payrate={true}
                payData={payRate}
                indexEmployee={indexEmployee}
                dataList={payRateDrodownList}
              />
            );
          })}
      </div> */}
      {/* {addPayrate && (
        <PrimaryButtonForm
          className={'ml-3 mt-1 mb-2'}
          bgcolor="BLUE"
          minWidth="3rem"
          onClick={() => addNewRate('pay_rates')}>
          <span>{<BsFillPlusSquareFill />}Add Rate Card</span>
        </PrimaryButtonForm>
      )} */}
    </div>
  );
};

export default Serviceadhocemployee;
