import styled from 'styled-components';
import {WHITE, GRADIENT_GREEN, BLACK_900} from 'shared/styles/constants/colors';

export const DivContainerLine = styled.div`
  background: ${props =>
    props.background_color ? props.background_color : WHITE};

  border-top: 4px solid
    ${props => (props.line_color ? props.line_color : GRADIENT_GREEN)};
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  width: ${props => (props.width ? props.width : 'auto')};

  min-height: ${props => (props.minHeight ? props.minHeight : 'auto')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'auto')};

  -webkit-box-shadow: 0 0.1875rem 0.25rem ${BLACK_900};
  box-shadow: 0 0.1875rem 0.25rem ${BLACK_900};
`;
