import React, { useEffect, useState, useRef, useContext } from 'react';
import { getEmployeesRouteMap } from '../../../utils/api/TeamVehicleRouting';
import { useForm } from 'hooks/FormHook';
import { getEmployee, getStaff } from "../../../utils/localStorage/token"
import moment from 'moment';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

import { mapboxToken } from '../../../utils/env';
import {
  NextPreviousContainer,
  EmployeeFilterContainer,
  Header,
  ButtonsContainer,
} from './styles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  FiltersLeftGroup,
  FiltersContainer,
  Title,
  DropdownTypesContainer,
} from 'shared/styles/constants/tagsStyles';
import MultiSelectDropdown from 'components/SharedComponents/MultiSelectDropdown';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { PURPLE } from 'shared/styles/constants/colors';
import {
  TEAM_ROUTE_MAP,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  WEB_PERMISSION,
} from '../../../utils/constants/permissions';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { getEmployees } from 'utils/api/EmployeeApi';
import { getClients } from 'utils/api/ClientApi';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import Spinner from 'components/SharedComponents/Spinner';
import DateSelector from 'components/SharedComponents/DateSelector';
import { PrimaryButton } from 'shared/styles/buttons';
import { team_route_mapping_choices } from 'utils/choiceConstant';
import homeIcon from 'images/home-icon.png';

const TeamRouteMapping = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-3.5);
  const [lat, setLat] = useState(54.5);
  const [zoom, setZoom] = useState(5);
  const { values, setValues, useInput } = useForm({});
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [clientMarkers, setClientMarkers] = useState([]);
  //dropdown filter runrotes+job department  below
  const [runRouteData, setRunRouteData] = useState([]);
  const [readPermission, setReadPermissions] = useState(false);
  const [readPermissionSelf, setReadPermissionsSelf] = useState(false);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const { branchData } = useContext(BranchItemsContext);
  const [branchFilter, setBranchFilter] = useState(branchData.headerbranch || []);
  const { contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [loading, setLoading] = useState(false)

  const filterEmployee = getEmployee()
  const is_staff = contextIsStaff;
  let employee_id = is_staff != 'true' ? filterEmployee : null;

  const choose_date_hook = useInput('choose_date');





  // useEffect(() => {
  //   const roles = contextPermissions;

  //   let rolePermissions = {};

  //   if(contextIsAdmin || contextIsSuperAdmin){
  //     setReadPermissions(true);
  //   }
  //   else {
  //     if (roles.length > 0) {
  //       roles.forEach(item => {
  //         if (item?.section?.name === TEAM_ROUTE_MAP) {
  //           rolePermissions[TEAM_ROUTE_MAP] = {};
  //           rolePermissions[TEAM_ROUTE_MAP][WEB_PERMISSION] = {};
  //           rolePermissions[TEAM_ROUTE_MAP][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION];
  //           rolePermissions[TEAM_ROUTE_MAP][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[SELF_WEB_READ_PERMISSION];
  //         }
  //       });

  //       setReadPermissions(
  //       rolePermissions[TEAM_ROUTE_MAP]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] 
  //     );
  //     setReadPermissionsSelf(
  //       rolePermissions[TEAM_ROUTE_MAP]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
  //     )
  //     debugger
  //   }
  // }


  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contextIsAdmin, contextIsSuperAdmin,contextPermissions]);


  useEffect(() => {
    if (is_staff == 'true') {
      let checkObj = {};

      employeesFilter.forEach(employee => (checkObj[employee.id] = true));

      let filteredArray = routes.length > 0 && routes.filter(route => checkObj[route.employeeId]);

      setFilteredRoutes(filteredArray);
    }
    else {
      setFilteredRoutes(routes);
    }

  }, [routes, employeesFilter]);


  const {handleGetChoices } = useContext(AppContext);
  useEffect(()=>{
    handleGetChoices(team_route_mapping_choices)
  }, [])
  useEffect(() => {
    loadChoices();
  }, [contextChoices]);

  useEffect(()=>{
    loadClients();
  },[contextChoices.client_listing])


  useEffect(() => {
    loadEmployees();
   

    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/matt-haddon-myhelpa/ckrvus1t910n617ryx1eezg43',
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on('load', function () {
      const geojson = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      };

      map.current.loadImage(
        homeIcon,
        function (error, image) {
          if (error) throw error;
          map.current.addImage('house-image', image, { sdf: 'true' });

          map.current.addSource('home-data', geojson);

          map.current.addLayer({
            id: 'home-layer',
            type: 'symbol',
            source: 'home-data',
            layout: {
              'icon-image': 'house-image',
              'icon-size': 0.07,
            },
            paint: { 'icon-color': ['get', 'color'] },
          });
        }
      );

      map.current.addSource('routing-data', geojson);

      map.current.addLayer({
        id: 'routing-layer',
        type: 'line',
        source: 'routing-data',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-opacity': 0.75,

          'line-width': 3,
        },
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    clientMarkers.forEach(marker => {
      marker.remove();
    });

    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    if (map.current.getSource('routing-data')) {
      map.current.getSource('routing-data').setData(geojson);
    }

    if (map.current.getSource('home-data')) {
      map.current.getSource('home-data').setData(geojson);
    }

    const south = [90];
    const west = [90];
    const north = [-90];
    const east = [-90];
    console.log(filteredRoutes, "4457888")
    if (filteredRoutes.length > 0) {
      const markerArray = [];
      const routesArray = [];
      const homesArray = [];
      filteredRoutes.map((employee, employeeIndex) => {
        employee.routes.map((route, routeIndex) => {
          const selectedEmployee = employees.filter(
            emp => emp.id === employee.employeeId && emp.id !== 'Unassigned'
          ).map(emp=> {
            return {
              ...emp,
              longitude: route.originLon,
              latitude: route.originLat,
              originIsClient: route.originIsClient,
            }
          });
          const selectedClient = contextChoices?.client_listing?.filter(
            client => client.id === route.destinationUserId
          );

          if (
            route.destinationLat > -90 &&
            route.destinationLat < 90 &&
            route.destinationLon > -180 &&
            route.destinationLon < 180
            && selectedClient &&
            selectedClient.length > 0
          ) {
            const marker = new mapboxgl.Marker({
              color: PURPLE,
              className: 'marker',
            });

            const popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            }).setHTML(
              `<p>Client Address: <strong>${selectedClient[0].full_name}</strong></p>`
            );

            marker
              .setLngLat([+route.destinationLon, +route.destinationLat])
              .addTo(map.current);

            const element = marker.getElement();
            element.id = 'marker';
            // hover event listener
            element.addEventListener('mouseenter', () => {
              popup.addTo(map.current);
            });
            element.addEventListener('mouseleave', () => {
              popup.remove();
            });

            // add popup to marker
            marker.setPopup(popup);

            markerArray.push(marker);

            // set map boundries

            if (+route.destinationLat < south[0]) {
              south.pop();
              south.push(+route.destinationLat);
            }

            if (+route.destinationLon < west[0]) {
              west.pop();
              west.push(+route.destinationLon);
            }

            if (+route.destinationLat > north[0]) {
              north.pop();
              north.push(+route.destinationLat);
            }

            if (+route.destinationLon > east[0]) {
              east.pop();
              east.push(+route.destinationLon);
            }

            //set home layer dataValues

            const homePopup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            });

            if (selectedEmployee &&
              selectedEmployee.length > 0 &&
              selectedEmployee[0].longitude &&
              selectedEmployee[0].latitude &&
              !selectedEmployee[0].originIsClient
            ) {
              const homeFeature = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [
                    +selectedEmployee[0].longitude,
                    +selectedEmployee[0].latitude,
                  ],
                },
                properties: {
                  color: selectedEmployee[0]?.color,
                  description: `<p>Employee Address: <strong>${selectedEmployee[0]?.name}</strong></p>

                  `,
                },
              };
              homesArray.push(homeFeature);
            }
            map.current.on('mouseenter', 'home-layer', e => {
              const description = e.features[0].properties.description;
              homePopup
                .setLngLat([e.lngLat.lng, e.lngLat.lat])
                .setHTML(description)
                .addTo(map.current);
            });

            map.current.on('mouseleave', 'home-layer', () => {
              homePopup.remove();
            });

            //set route layer data

            const routingCoordinates = [];

            if (route.routeMap) {
              route.routeMap.forEach(waypoint => {
                routingCoordinates.push([waypoint[1], waypoint[0]]);
              });

              const arrival = route.arrivalAtDestination;
              const arrivalTime = moment(arrival).format('HH:mm');
              const departure = route.departureFromOrigin;
              const departureTime = moment(departure).format('HH:mm');

              const routingFeature = {
                type: 'Feature',
                geometry: { type: 'LineString', coordinates: routingCoordinates },
                properties: {
                  color: selectedEmployee[0]?.color,
                  description: `<strong>Travel To ${selectedClient[0].full_name}</strong>
                  <p>Employee: <strong>${selectedEmployee[0]?.name}</strong></p>
                  <p>Depart: <strong>${departureTime}
                  </strong></p>
                  <p>Visit Arrival: <strong>${arrivalTime}
                  </strong></p>`,
                },
              };

              routesArray.push(routingFeature);

              //pop up for routes starts here

              const routePopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
              });

              map.current.on('mouseenter', 'routing-layer', e => {
                const description = e.features[0].properties.description;

                routePopup
                  .setLngLat([e.lngLat.lng, e.lngLat.lat])
                  .setHTML(description)
                  .addTo(map.current);
              });

              map.current.on('mouseleave', 'routing-layer', () => {
                routePopup.remove();
              });
            }
          }
        });
      });

      if (map.current.getSource('routing-data')) {
        const geojson = {
          type: 'FeatureCollection',
          features: [...routesArray],
        };

        map.current.getSource('routing-data').setData(geojson);
      }

      if (map.current.getSource('home-data')) {
        const geojson = {
          type: 'FeatureCollection',
          features: [...homesArray],
        };

        map.current.getSource('home-data').setData(geojson);
      }

      map.current.fitBounds([
        [...west, ...south],
        [...east, ...north],
      ]);

      setClientMarkers([...markerArray]);
    }
  }, [filteredRoutes, clients, contextChoices.client_listing]);

  mapboxgl.accessToken = mapboxToken;

  const loadEmployees = async () => {
    const response = await getEmployees({}, 500);
    let employees = [];

    if (response.results) {
      employees = response.results.map(employee => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return {
          id: employee.id,
          name: employee.full_name,
          color: color,
        };
      });
    }

    employees.push({ id: 'Unassigned', name: 'Unassigned' });
    setEmployees(employees || []);
  };

  const loadClients = async () => {
    let clients = [];
    if (contextChoices.client_listing) {
      clients = contextChoices.client_listing.map(client => {
        return { id: client.id, fullName: client.full_name, photo: client.photo };
      });
    }

    setClients(clients || []);
  };

  


  const getRoutes = () => {
    // debugger
    // if(!readPermission && readPermissionSelf){
    //   let employee_Id_self = [filterEmployee];
    //   getEmployeesRouteMap(
    //     branchData,
    //     values.runroutes,
    //     values.branch,
    //     values.start_date,
    //     values.end_date,
    //     employee_Id_self,
    //     values.runNames,

    //   ).then(res => setRoutes(res));
    // }else{
      if(!loading){
        setLoading(true)
      }
    getEmployeesRouteMap(
      branchData,
      values.runroutes,
      values.branch,
      values.start_date,
      values.end_date,
      values.employees,

    ).then(res => {
      setLoading(false)
      setRoutes(res)
    })
    .catch((err)=>{
      setLoading(false)
    })
    .finally(()=>{
      setLoading(false)
    })
    // }


  };

  useEffect(() => {
      const today = values.choose_date ? new Date(values.choose_date) : new Date();
      const start = today.setUTCHours(0, 0, 0, 0);
      const end = today.setUTCHours(23, 59, 59, 0);
      values.start_date = start;
      values.end_date = end;
      setValues({ ...values });
      getRoutes();
  }, [values.choose_date]);

  const showToday = () => {
    const today = new Date();
    const start = today.setUTCHours(0, 0, 0, 0);
    const end = today.setUTCHours(23, 59, 59, 0);

    values.start_date = start;
    values.end_date = end;

    setValues({ ...values });
    getRoutes();
  };

  const nextChange = () => {
    const start = values.start_date + 86400000;
    const end = values.end_date + 86400000;

    values.start_date = start;
    values.end_date = end;

    setValues({ ...values });
    getRoutes();
  };

  const previousChange = () => {
    const start = values.start_date - 86400000;
    const end = values.end_date - 86400000;

    values.start_date = start;
    values.end_date = end;
    setValues({ ...values });
    getRoutes();
  };

  const loadChoices = () => {

    setRunRouteData(contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setBranch(contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
  };

  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  //newly added 
  const setEmpfilter = (item) => {
    setEmployeesFilter([...item])
  }

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
  };

  console.log('values', values)


  return (
    <div>
      <Title>Team Route Mapping</Title>
      <Header>
        {
          <FiltersContainer>
              <FiltersLeftGroup>
                <div className='mr-1'>
                  {/* <MultiSelectDropdown
                placeHolder={'Select Employees'}
                isObject
                items={employees}
                itemsSelected={employeesFilter}
                setItemsSelected={setEmployeesFilter}
              /> */}

                  {/* onpage load all item unchecked~newly added employees filter below */}
                  {/* {(!readPermission && readPermissionSelf) &&  */}
                  <NoSelectAllDropdownFilter
                    placeHolder={'Employees'}
                    isObject
                    items={employees}
                    itemsSelected={employeesFilter}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employees: items });
                    }}
                    setSelectedFilter={setEmpfilter}
                    allOption
                  />

                </div>
                <div className='ml-1  mr-1  '>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    //  placeHolder={'Runroute'}
                    placeHolder={'Group Area'}
                    isObject
                    allOption

                  />
                </div>
                <div className='ml-1 mr-1'>
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption

                  />
                </div>
              </FiltersLeftGroup>
              <PrimaryButton onClick={getRoutes} className='ml-1'>
                Search
              </PrimaryButton>
          </FiltersContainer>
        }
      </Header>

   { 
   loading &&
   <div style={{
    background: "#fff",
    opacity: 0.75,
    position: 'fixed',
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    zIndex: 11
   }}>
   <Spinner
     spinnerTop={"70%"}
     textTop={'68%'}
   />
   </div>
}
 
   <>
   
      <ButtonsContainer  style={{ marginTop: '-3rem', cursor: loading ? 'not-allowed' : 'default' }}>
        <NextPreviousContainer className={'nextPrevious ml-3 d-flex'}>
       
          <div className={'d-flex align-items-center'}>
            <div className={'arrow'} style={{cursor:loading ? 'not-allowed' : 'default'}}>
              <MdKeyboardArrowLeft onClick={previousChange} />
            </div>
            <div className={'arrow'} style={{cursor:loading ? 'not-allowed' : 'default'}}>
              <MdKeyboardArrowRight onClick={nextChange} />
            </div>
            <PrimaryButtonForm onClick={showToday} style={{cursor:loading ? 'not-allowed' : 'default'}}>
              <span>Today</span>
            </PrimaryButtonForm>
          </div>
          <DropdownTypesContainer className="ml-1 mr-1">
                <DateSelector
                disabled={loading}
                  typeFilter={true}
                  labelTop="Select Date"
                  value={values.choose_date ? values.choose_date : ''}
                  {...choose_date_hook}
                />
              </DropdownTypesContainer>
{/* } */}
          <h3 className="ml-2">
            {moment(values.start_date).format('MMMM D, YYYY')}
          </h3>
        </NextPreviousContainer>
      </ButtonsContainer>

      <div
        style={{ height: '50rem', marginTop: '8rem' }}
        ref={mapContainer}
        className="map-container"></div>
    </>
    </div>

  );
};

export default TeamRouteMapping;
