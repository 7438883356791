import React, {useEffect, useState} from 'react';
import {
  DropdownSideBar,
  DropdownSideBarContent,
  SideBarItemButton,
} from './styles';
import {FiChevronUp, FiChevronDown} from 'react-icons/fi';

const DropdownMenu = ({
  propsLocationName,
  dropdownName,
  listValues,
  Li,
  active,
  children,
  showMenu,
  handleClick,
  decreaseWidth,
  isSettings = false
}) => {
  const [selectedItem, setSelectedItem] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);

  useEffect(() => {
    const found = listValues.find(element => element.to === propsLocationName);
    setDisplayMenu(found ? true : false);
    setSelectedItem(found ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsLocationName]);
  
  const showSubmenu = e => {
    if (decreaseWidth) handleClick();
    setDisplayMenu(!displayMenu);
  };

  const reviewSelectedItem = () => {
    if (selectedItem || active) {
      return 'selectedItem';
    }
    return '';
  };

  const showItemsList = () => {
    if (displayMenu && !decreaseWidth) {
      return `show ${decreaseWidth ? 'hideDropdownItem' : ''}`;
    }
    return '';
  };

  return (
    <DropdownSideBar decreaseWidth={decreaseWidth}>
      <SideBarItemButton
        onClick={showSubmenu}
        className={`w-100 text-left nav-link d-flex ${reviewSelectedItem()}`}>
        <div className={'d-flex w-100 imageIconConteiner'}>
          <span>{children}</span>
          <label className={`ml-1 ${decreaseWidth ? 'hideDropdownLabel' : ''}`}>
            {dropdownName}
          </label>
        </div>
        <div className={`${decreaseWidth ? 'hideDropdownLabel' : ''}`}>
          {displayMenu ? (
            <FiChevronUp className="align-self-center" />
          ) : (
            <FiChevronDown className="align-self-center" />
          )}
        </div>
      </SideBarItemButton>

      <DropdownSideBarContent className={showItemsList()}>
        {listValues.map(value => {
          return (
            <Li
              subItem
              key={value.name}
              to={value.to}
              name={isSettings ? value.label : value.name}
              showMenu={showMenu}
              decreaseWidth={decreaseWidth}
              propsLocationName={propsLocationName}>
              <div className="align-self-center pb-1">
                {value.icon ? React.createElement(value.icon) : <></>}
              </div>
            </Li>
          );
        })}
      </DropdownSideBarContent>
    </DropdownSideBar>
  );
};

export default DropdownMenu;
