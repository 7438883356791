import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import Table from 'components/SharedComponents/Table';
import { Col, IconWrapper, Row } from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { EX_LARGE_COL, } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { useModal } from 'hooks/ModalHook';
import SplitShiftModal from './AddModal';
import { getSplitShift } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useContext } from 'react';
import { AppContext } from 'context';
import { split_shift_choices } from 'utils/choiceConstant';

export const headerColumns = [
  {
    label: 'Branch',
    name: 'visit__start_date',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Updated At',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: EX_LARGE_COL,
  },

  {
    label: 'Updated By',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: EX_LARGE_COL,
  },

];

function SplitShift({ props, permission }) {
  const toast = useRef(null)
  const [splitShifts, setSplitShifts] = useState([])
  const [idSelected, setIdSelected] = useState('');
  const [loadingModal, setLoadingModal] = useState(true)

  const { handleGetChoices, contextChoices, loadingChoices } = useContext(AppContext);
  const { values, setValues } = useForm({ limitperpage: PAGE_SIZE_RENDER });
  const { setValue: setModalValue, value: modalValue, fullBind } = useModal(false);


  const handleEditRow = (id) => {
    setModalValue(true)
    setIdSelected(id)
  }

  const loadSplitShift = async () => {
    setLoadingModal(true)
    try {
      const response = await getSplitShift()
      if (response?.data?.length) {
        setSplitShifts(response.data)
      }
      setLoadingModal(false)

    } catch (error) {
      console.log("Error: fething split shift branch wise API", error)
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    loadSplitShift()
    handleGetChoices(split_shift_choices)
  }, [])


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const calculateNumberOfIcons = () => {
    let count = 0;
    if (permission.update)
      count = count + 1;
    // if (permission.delete)
    //   count = count + 1;

    return count || 0
  }


  return (
    <div className='mt-2 '>
      <Toast ref={toast} position="top-right" />
      <>
        {permission?.read ?
          <ComponentDynamic loading={(loadingModal || loadingChoices)} >

            {splitShifts.length > 0 ? (
              <Table
                headerColumns={headerColumns}
                callBackQuerySort={setQuerySort}
                headerPadding={false}
                noNeed={false}
                numberOfIcons={calculateNumberOfIcons()}
                noEditOption={true}
              >
                <div>
                  {splitShifts.map((item, index) => {
                    return (
                      <div >
                        <Row bgColor>

                          <Col max_width={EX_LARGE_COL}
                            Center overlap={true}>
                            {item.name}
                          </Col>

                          <Col max_width={EX_LARGE_COL}
                            CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {item.splitShiftUpdatedAt && moment(item.splitShiftUpdatedAt).format('YYYY-MM-DD HH:mm')}
                          </Col>

                          <Col max_width={EX_LARGE_COL}
                            CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {item.splitShiftUpdatedByName}
                          </Col>

                          {permission?.update &&
                            <Col className="" Center Shrink NoFlexGrow Purple>
                              <IconWrapper
                                onClick={() => {
                                  handleEditRow(item.id && item.id);
                                }}>
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                          }

                          {/* {permission?.delete &&
                            <Col className="" Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  handleDeleteRow(item.id && item.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper> 
                            </Col>}*/}

                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}
                </div>
              </Table>
            ) : (
              <NoDataFoundMessage>No data found!</NoDataFoundMessage>
            )}
          </ComponentDynamic>
          :
          <NoPermissionContainer className='text-center'>
            You don't have permission to access the information.
          </NoPermissionContainer>
        }

        {modalValue && (
          <SplitShiftModal
            fullBind={fullBind}
            setModalValue={setModalValue}
            permission={permission}
            setIdSelected={setIdSelected}
            idSelected={idSelected}
            loadSplitShift={loadSplitShift}
            toast={toast}
            contextChoices={contextChoices}
          />)}
      </>
    </div>
  )
}

export default SplitShift