import React from 'react';
import {MdCheck, MdClose} from 'react-icons/md';
import {RiSaveLine, RiDeleteBinLine,RiHome2Fill,RiEBikeFill,RiWalkFill} from 'react-icons/ri';
import {FaPlus, FaTimes,FaCarAlt,FaCopy} from 'react-icons/fa';
import {BsPlayFill} from 'react-icons/bs';
import {FiCheckCircle} from 'react-icons/fi';
import {TiWarningOutline} from 'react-icons/ti';
import {ImDownload3} from "react-icons/im"
import {FcHighPriority} from 'react-icons/fc'
import {FcLowPriority} from 'react-icons/fc'
import {FcMediumPriority} from 'react-icons/fc'
import { HiOutlineInformationCircle } from "react-icons/hi";
import { FaRegFileAlt } from "react-icons/fa";


const CHECK = React.createElement(MdCheck);
const CROSS = React.createElement(MdClose);
const FLOPPY = React.createElement(RiSaveLine);
const DELETE = React.createElement(RiDeleteBinLine);
const COPY = React.createElement(FaCopy);
const PLUS = React.createElement(FaPlus);
const TIMES = React.createElement(FaTimes);
const PLAY = React.createElement(BsPlayFill);
const CHECK_CIRCLE = React.createElement(FiCheckCircle);
const ERROR_OUTLINE = React.createElement(TiWarningOutline);
const Home_ICON=React.createElement(RiHome2Fill);
const BIKE_ICON=React.createElement(RiEBikeFill);
const CAR_ICON=React.createElement(FaCarAlt);
const FOOT_ICON=React.createElement(RiWalkFill);
const DOWNLOAD=React.createElement(ImDownload3);
const High_PRIORITY=React.createElement(FcHighPriority);
const LOW_PRIORITY=React.createElement(FcLowPriority);
const MEDIUM_PRIORITY=React.createElement(FcMediumPriority);
const INFO_ICON=React.createElement(HiOutlineInformationCircle);
const READ_INFO = React.createElement(FaRegFileAlt);


export {
  CHECK,
  CROSS,
  FLOPPY,
  DELETE,
  PLUS,
  TIMES,
  PLAY,
  CHECK_CIRCLE,
  ERROR_OUTLINE,
  Home_ICON,
  BIKE_ICON,
  CAR_ICON,
  FOOT_ICON,
  DOWNLOAD,
  COPY,
  High_PRIORITY,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
  INFO_ICON,
  READ_INFO
};
