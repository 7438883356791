import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {
  TitleSection,
  ModalContainer,
  LinkTabStyled, 
  LinkTab,
  WeekStyle,
  ButtonWeek
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    ErrorMessage
  } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import { KeyPress } from 'utils/constants/regularExpressions';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import Table from 'components/SharedComponents/Table';
import {
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper
} from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import {RiDeleteBinLine} from 'react-icons/ri';
import ToggleButton from 'components/SharedComponents/ToggleButton';

const headerRunColumns = [
  {
    label: 1,
    checkbox:true,
    name: 'checkbox',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Visits',
    name: 'repeat_on',
    status: 0,
    className: 'm-0 p-0  md-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Requirement',
    name: 'requirement',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Start-End Time',
    name: 'time',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Repeat',
    name: 'repeat',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Start Date',
    name: 'start_date',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Pin Start-End Time',
    name: 'start_time',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Run Name',
    name: 'run_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Employee Name',
    name: 'employee_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  }


];

const BulkRunName = ({
  fullBind,
  loadingModal,
  useInput,
  values,
  setValues,
  errorMessage,
  dataValues,
  calculateEndValueProps,
  dropDownHandleSelect,
  switchEndValueInput,
  dropDownHandleRegVisit,
  handleSaveBulkRun,
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  saveButtonIcon,
  loadingSave,
  textStatus,
  setLoadingModal,
  choices,
  newRun,
  pinnedData = [],
  deletePinnedEmp,
  employees
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [displayPhoto,setDisplayPhoto]=useState( )
  const [teamTabs, setTeamTabs] = useState('create');

  const alluseInp = {
    add_emp_hook: useInput('add_emp'),
    run_name: { ...useInput('run_name', 'isRequired') },
    regular_start_date: {...useInput(`regular_start_date`)},
    regular_end_date: {...useInput(`regular_end_date`)},
    every: {...useInput(`every`)},
    repeat: {...useInput(`repeat`, 'isInt')},
    end_type: {...useInput(`end_type`)},
    emp_name: {...useInput(`employee`)},
    end_value: {
      ...useInput(
        `end_value`,
        calculateEndValueProps(
          values.end_type
        )
      ),
    }
}
  useEffect(() => {
    setAddFullBind({
        ...addFullBind,
        title: 'Run Name',
        // <RowInfoModel
        // name={values.employee.first_name || values.employee.full_name}
        // lastName={values.employee.last_name}
        // photoRow={
        //   values.employee.photo
        //     ? values.employee.photo
        //     : displayPhoto
        // }
        // rows={[
        //   { label: 'Name:', value: values.employee.full_name },
        // ]}
        // popupStyle={true}
        // />
      });
      setLoadingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  const selectedDay = (day, indexWeek) => {
    daysOfWeek[indexWeek].selected = !daysOfWeek[indexWeek].selected;

    setDaysOfWeek(daysOfWeek);

    if (!values.repeat_on) {
      values.repeat_on = [];
    }

    values.repeat_on.forEach(detail => {
      if (detail.week_of_day - 1 === indexWeek) {
        detail.selected = daysOfWeek[indexWeek].selected;
      }
    });

    const addNew = values.repeat_on.find(
      detail => detail.week_of_day === daysOfWeek[indexWeek].dayValue
    );

    if (!addNew) {
      values.repeat_on.push(
        daysOfWeek[indexWeek].day,
      );
    }

    setValues({...values});
  };
  console.log('days', daysOfWeek)

  const switchRunNameInput = props => {
     if (newRun) {
     return( 
       <div className="pl-3 pr-3 pb-3 pt-0">
      <InputLabelTop
      required
      readOnly={false}
      type="text"
      id="run_name"
      label={'Run Name'}
      {...props}
      maxlength={100}
     />
  </div>
     )
    } else {
      return(
        <Fragment />
      )
   }
  };

  const createRender=(item)=>{
        return(
        <div>
          <div className="d-flex pl-3 pr-3 pb-3 pt-0">
          <label style={{fontSize:"0.875rem", marginRight:'7px', alignSelf:'center'}}>Attach new employee with selected visits</label>
            <ToggleButton
              label=" "
              disabled={false}
              useInput={item.add_emp_hook}
              id="test"
              // onClick={handelOverNight}
            />
          </div>
         {values.add_emp && 
            <div className="pl-3 pr-3 pb-3 pt-0">
            <DropdownSearchable
               required
               {...item.emp_name}
                placeHolder={'Employee Name'}
                options={employees}
                selectedOption={values.employee ? values.employee : ''}
                onChange={value =>
                  dropDownHandleSelect({employee: value ? value : null})
                }
              />
            </div>
         }
          <div className="pl-3 pr-3 pb-3 pt-0">
            <DropdownSearchable
              placeHolder={'Select Run Name'}
              options={[{ id: 'new', name: '+ New Run' }, ...choices.run_view]}
              selectedOption={values.run_name_view}
              onChange={value =>
                dropDownHandleSelect({ run_name_view: value ? value : null })
              }
            />
          </div>
          {switchRunNameInput(item.run_name)}
      <FlexGrid3Container className="pl-3 pr-3">
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
               
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service start date'}
                {...item.regular_start_date}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service end date'}
                {...item.regular_end_date}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DropdownSearchable
                {...item.every}
                placeHolder={'Repeat Every'}
                options={[
                  { id: EVERY_DAY, name: 'Day' },
                  { id: EVERY_WEEK, name: 'Week' },
                  { id: EVERY_MONTH, name: 'Month' },
                  { id: EVERY_YEAR, name: 'Year' },
                ]}
                selectedOption={
                  values
                    ? values.every
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { every: value ? value : null },
                    'every'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
              <InputLabelTop
                //   disabled={readOnly}
                label={'Repeat No.'}
                onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
                id={`repeat`}
                {...item.repeat}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
              <DropdownSearchable
                {...item.end_type}
                // data_error={errors.indexOf(`end_type`)}
                //   disabled={readOnly}
                placeHolder={'End'}
                options={[
                  { id: END_TYPE_ON, name: 'On' },
                  { id: END_TYPE_AFTER, name: 'After' },
                  { id: END_TYPE_NEVER, name: 'Never' },
                ]}
                selectedOption={
                  values
                    ? values.end_type
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { end_type: value ? value : null },
                    'end_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 mt-0 md-2 pr-0 pr-md-1">
              {switchEndValueInput(item.end_value)}
            </FlexGrid3Item>
            <div className={'formWeek d-block'}>
                <div
                  className={'pt-2 ml-md-1 ml-0 text-center'}
                  style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                  Day of the week
                </div>

                <WeekStyle  >
                  {daysOfWeek.map((itemWeek, indexWeek) => {

                    return (
                      <ButtonWeek
                        // disabled={values.start_date? true : false}
                        key={itemWeek.day}
                        add_background={itemWeek.selected}
                        onClick={() => {
                          selectedDay(itemWeek.day, indexWeek);
                        }}
                      // disabled={readOnly}
                      >
                        <div>
                          <label>{itemWeek.day}</label>
                        </div>
                      </ButtonWeek>
                    );
                  })}
                </WeekStyle>

                {requireDayOfWeek ? (
                  <div
                    className={'text-center'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    <ErrorMessage fontSize={'0.6rem'}>
                      <label className={'mt-2'}>Is required</label>
                    </ErrorMessage>
                  </div>

                ) : (
                  <Fragment />
                )}
              </div>
          </FlexGrid3Container>
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ml-0 ml-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveBulkRun()} >
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>)
  }
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>       
          {createRender(alluseInp)}
  </ModalDynamic>
    </ModalContainer>
  );
};


export default BulkRunName;
