import { FEILDS_CONST, HTML_NAMES } from "./constants"
import { ItemTypes } from "./ItemTypes"

export const inital = {
    textInput: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""

    },
    numberInput: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: "",
        evaluateCondition: false,
        evaluateExpression: ""
    },
    decimalInput: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: "",
        evaluateCondition: false,
        evaluateExpression: ""
    },
    headingText: {
        label: "",
        tag: "h1",
        description: "",
        showCondition: false,
        ruleExpression: ""
    },
    dropdown: {
        label: "",
        options: [],
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    multidropdown: {
        label: "",
        options: [],
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    switch: {
        label: "",
        value: false,
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    hyperLink: {
        label: "",
        value: '',
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    button: {
        label: "",
        type: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    radioGroup: {
        label: "",
        options: [],
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    buttonGroup: {
        label: "",
        options: [],
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    checkbox: {
        label: "",
        options: [],
        description: "",
        showCondition: false,
        ruleExpression: ""
    },
    textArea: {
        label: "",
        placeholder: "",
        numberOfRows: 2,
        description: "",
        instruction: ""
    },
    choices: {
        label: "",
        description: "",
        instruction: "",
        is_multi: false,
        showCondition: false,
        ruleExpression: ""
    },
    datePicker: {
        label: "",
        value: false,
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: "",
        format: ""
    },
    timePicker: {
        label: "",
        value: false,
        description: "",
        showCondition: false,
        ruleExpression: "",
        format: ""
    },
    dateTimePicker: {
        label: "",
        value: false,
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: "",
        format: ""
    },
    signature: {
        label: "",
        type: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    table: {
        cols: [{}],
        rows: [{}],
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    section: {
        cols: [{id : 1}],
        rows:[{}],
        sectionColsCount: 1,
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    richTextHtmlInput: {
        cols: [{}],
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    richTextHtmlHeading: {
        cols: [{}],
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    photoAttach: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: "",
        file: "",
        value: ""
    },
    fileUpload: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    fileDownload: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    bodyMapDisplay: {
        label: "",
        title: [],
        view: 'front',
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
    bodyMapInput: {
        label: "",
        placeholder: "",
        description: "",
        instruction: "",
        showCondition: false,
        ruleExpression: ""
    },
}


export const UI_FEILDS = [
    {
        id: 1,
        name: HTML_NAMES.headingText,
        type: ItemTypes.Heading,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
   
    {
        id: 2,
        name: HTML_NAMES.textInput,
        type: ItemTypes.TextInput,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 15,
        name: HTML_NAMES.numberInput,
        type: ItemTypes.numberInput,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 16,
        name: HTML_NAMES.decimalInput,
        type: ItemTypes.decimalInput,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 3,
        name: HTML_NAMES.dropdown,
        type: ItemTypes.Dropdown,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 4,
        name: HTML_NAMES.multidropdown,
        type: ItemTypes.multidropdown,
        html: null,
        value: [],
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 5,
        name: HTML_NAMES.switch,
        type: ItemTypes.Switch,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 17,
        name: HTML_NAMES.hyperLink,
        type: ItemTypes.hyperLink,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    // {
    //     id: 5,
    //     name: HTML_NAMES.button,
    //     type: ItemTypes.button,
    //     html: null,
    //     value: "",
    //     placeholder: "",
    //     required: false,
    //     order: "",
    //     options: [],
    //     choices: []
    // },
    {
        id: 6,
        name: HTML_NAMES.radioGroup,
        type: ItemTypes.radioGroup,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    // {
    //     id: 7,
    //     name: HTML_NAMES.buttonGroup,
    //     type: ItemTypes.buttonGroup,
    //     html: null,
    //     value: "",
    //     placeholder: "",
    //     required: false,
    //     order: "",
    //     options: [],
    //     choices: []
    // },
    {
        id: 8,
        name: HTML_NAMES.checkbox,
        type: ItemTypes.checkbox,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 9,
        name: HTML_NAMES.textArea,
        type: ItemTypes.textArea,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 10,
        name: HTML_NAMES.datePicker,
        type: ItemTypes.datePicker,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 11,
        name: HTML_NAMES.timePicker,
        type: ItemTypes.timePicker,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 12,
        name: HTML_NAMES.dateTime,
        type: ItemTypes.dateTime,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 13,
        name: HTML_NAMES.signature,
        type: ItemTypes.signature,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: []
    },
    {
        id: 14,
        name: HTML_NAMES.table,
        type: ItemTypes.table,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: []
    },
    {
        id: 18,
        name: HTML_NAMES.section,
        type: ItemTypes.section,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: []
    },
    {
        id: 19,
        name: HTML_NAMES.richTextHtmlInput,
        type: ItemTypes.richTextHtmlInput,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 20,
        name: HTML_NAMES.richTextHtmlHeading,
        type: ItemTypes.richTextHtmlHeading,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 21,
        name: HTML_NAMES.photoAttach,
        type: ItemTypes.photoAttach,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 22,
        name: HTML_NAMES.fileUpload,
        type: ItemTypes.fileUpload,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 23,
        name: HTML_NAMES.fileDownload,
        type: ItemTypes.fileDownload,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 24,
        name: HTML_NAMES.bodyMapDisplay,
        type: ItemTypes.bodyMapDisplay,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },
    {
        id: 25,
        name: HTML_NAMES.bodyMapInput,
        type: ItemTypes.bodyMapInput,
        html: null,
        value: "",
        placeholder: "",
        required: false,
        readOnly: false,
        order: "",
        options: [],
        choices: [],
        description: ""
    },

]