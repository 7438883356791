import {LARGE_COL, MEDIUM_COL, SMALL_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Rate Card Ref',
    name: 'rate_card_reference',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Rate Card Name',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Branch Access',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Status',
    name: 'is_active',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: SMALL_COL,
  },

  {
    label: 'Default',
    name: 'is_active',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: SMALL_COL,
  },
];
