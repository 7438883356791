import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumsList';
import {
  LARGE_COL,
  MEDIUM_COL,
  EX_SMALL_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import Pagination from 'components/SharedComponents/Pagination';
import { MdEdit } from 'react-icons/md';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import AddModal from 'components/DashboardComponents/Settings/SettingsRateCard/BillRate/AddModal';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { FloatingMobileButton } from 'shared/styles/buttons';
import {
  getRateCard,
  DeleteRateCard,
  statusIconPatch,
} from 'utils/api/SettingsApi';
import {
  PAGE_SIZE_RENDER,
  PAGE_SIZE_TWELVE_RENDER,
} from 'utils/constants/pagination';
import { COPY } from 'utils/constants/icons';
import { SOFT_RED } from 'shared/styles/constants/colors';
import _ from 'lodash';
//import {set} from 'react-hook-form';
import { setRateCardById } from 'utils/api/SettingsApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { statusDefaultPatch } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
const CommonModelData = ({
  idSelected,
  deleteValue,
  setIdToDelete,
  setIdSelected,
  idToDelete,
  // idSelected,
  setDeleteValue,
  addRateCardName,
  listigData,
  pages,
  currentPage,
  setQuerySort,
  setValues,
  setCheckRequires,
  setModalValue,
  modalValue,
  setModalDeleteValue,
  values,
  addCard,
  editCard,
  formObjName,
  firstLayerAddButton,
  insideFirstLayerAddButton,
  useInput,
  fullBind,
  setTitle,
  bindModalDelete,
  rateCardType,
  travelTab,
  loadRateCard = () => { },
  dropDownHandleSelectPagination,
  copiedCard,
  filterShiftType,
  onCallTab,
  officeTab,
  officeShiftType,
  rateSource,
  absencePaid,
  absenceType,
  abscenceTab,
  loadingModal,
  isPayRate = false,
  shiftType,
  shiftTab,
  waitTab,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission
}) => {
  const [copyToken, setCopyToken] = useState(false);
  const [copiedListigData, setCopiedListigData] = useState();
  const [dataById, setDataById] = useState();
  const toast = useRef();

  useEffect(() => {
    setTitle('Add Pay Rates Card');
  }, []);

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddNewRateCard = () => {
    setIdSelected('');
    handleModal();
    setCopyToken(false);
    // setCheckRequires(false);
  };

  const handleClickToggleBtn = async (id, active) => {
    if (active == false) {
      active = true;
    } else {
      active = false;
    }
    await statusIconPatch(id, active);
    loadRateCard(rateCardType);
  };

  const handleClickDefaultToggleBtn = async (id, data) => {
    if (data == false) {
      data = true;
    } else {
      data = false;
    }
    await statusDefaultPatch(id, data);
    loadRateCard(rateCardType);
  };

  const handleRowClick = id => {
    setCopyToken(false); // copied usestate made false  while editi
    setIdSelected(id);
    setModalValue(true);
  };

  //  working below for popu msg delete
  const toDeleteRateCard = async (id, deleted) => {
    setModalDeleteValue(true);
    setIdToDelete(id);
    if (deleted == false) {
      deleted = true;
      setDeleteValue(deleted);
    }
  };

  const handleDeleteRateCard = async () => {
    await DeleteRateCard(idToDelete, deleteValue);
    setModalDeleteValue(false);
    loadRateCard(rateCardType);
  };

  //for copy rate card
  const copyRateCard = async (index, id) => {
    const responseById = await setRateCardById(id);
    if (responseById) {
      //  delete id from data after cloning it
      let tempCopyData = _.cloneDeep(responseById);
      if (tempCopyData.id) {
        delete tempCopyData.id;
      }

      if (tempCopyData.rate_card_details) {
        tempCopyData.rate_card_details.map(data => {
          delete data.id;
          delete data.details_rate;
          return data;
        });
      }

      // if (tempCopyData.rate_card_details.filter(data => data.details_rate)) {
      //   tempCopyData.rate_card_details &&
      //     tempCopyData.rate_card_details.filter(data => {
      //       data.details_rate.map(rate => {
      //         delete rate.id;
      //         return rate;
      //       });
      //     });
      // }

      // if(tempCopyData.rate_card_details.filter(data=>data.detail_rate_code)){

      //   tempCopyData.rate_card_details &&  tempCopyData.rate_card_details.filter(data=>{
      //     data.detail_rate_code.map(rate=>{
      //       delete rate.id
      //        return rate
      //     })
      //   })
      // }

      setCopiedListigData(tempCopyData);
      setCopyToken(true);
      setIdSelected('');
      setModalValue(true);
    }
  };
  console.log(listigData, 'listigData');
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
          {createPermission && (
            <FloatingMobileButton onClick={handleAddNewRateCard}>
              <FaPlus />
              <label>{addRateCardName}</label>
            </FloatingMobileButton>
          )}
        </SearchAddContainer>
      </Header>
      {readPermission ? (
        <ComponentDynamic loading={loadingModal}>
          {listigData && listigData?.length ? (
            <Table
              headerColumns={headerColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}
              fontSize="0.75rem">
              <div>
                {listigData &&
                  listigData.map((list, index) => {
                    const branch_access = list.branch && (list.branch.length > 0) && list.branch.map((val) => (val.branch_name)).join(',')
                    return (
                      <div key={index}>
                        <Row bgColor Shadow>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center>
                            {list.rate_card_reference && list.rate_card_reference}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            // className="sm-hidden"
                            Capitalize
                            Left>
                            {list.name && list.name}
                          </Col>

                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            // className="sm-hidden"
                            Capitalize
                            Left>
                            {branch_access}
                          </Col>

                          <Col
                            max_width={SMALL_COL}
                            Shrink
                            reducePadding
                            className="sm-hidden"
                            Capitalize
                            Center>
                            <ToggleButton
                              onClick={() =>
                                handleClickToggleBtn(list.id, list.is_active)
                              }
                              status={list.is_active}
                            />
                          </Col>
                          <Col
                            max_width={SMALL_COL}
                            Shrink
                            reducePadding
                            className="sm-hidden"
                            Capitalize
                            Center>
                            <ToggleButton

                              onClick={() =>
                                handleClickDefaultToggleBtn(
                                  list.id,
                                  list.default_card_status
                                )
                              }
                              status={list.default_card_status}
                            />
                          </Col>
                          {/* <Col> */}
                          {/* <div className="d-flex  justify-content-end "> */}
                          {/* <Col
                            className="sm-hidden"
                            Center
                            Icon
                            Purple
                            Shrink
                            reducePadding
                            onClick={() => {
                              handleRowClick(list.id);
                            }}>
                            
                          </Col>

                          <Col
                            className="sm-hidden"
                            Center
                            Shrink
                            reducePadding
                            NoFlexGrow
                            onClick={() => {
                              toDeleteRateCard(list.id, false);
                            }}>
                           
                          </Col> */}

                          <Col
                            className="sm-hidden"
                            Center
                            Shrink
                            reducePadding
                            NoFlexGrow
                          >
                            {updatePermission && (
                              <IconWrapper
                                className={'ml-1 mr-1'}
                                onClick={() => {
                                  handleRowClick(list.id);
                                }}>
                                <MdEdit />
                              </IconWrapper>)}

                            {deletePermission && (
                              <IconWrapper
                                className={'ml-1 mr-1'}
                                onClick={() => {
                                  toDeleteRateCard(list.id, false);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>)}

                            {createPermission && (
                              <IconWrapper
                                className={'ml-1 mr-1'}
                                color={SOFT_RED}
                                onClick={() => {
                                  copyRateCard(index, list.id);
                                }}
                              // onClick={() => copyRateCard(index,list.id)}
                              >
                                {COPY}
                              </IconWrapper>)}
                          </Col>
                          {/* </div> */}
                          {/* </Col> */}
                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}

                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={listigData.length}
                  />
                </div>
              </div>
            </Table>) :
            <NoDataFoundMessage>
              No data found
            </NoDataFoundMessage>
          }
        </ComponentDynamic>
      ) : (
        <NoPermissionContainer>
          You don't have permission to access the information.
        </NoPermissionContainer>
      )}
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          copyToken={copyToken}
          setCopyToken={setCopyToken}
          setModalValue={setModalValue}
          setRateCardModalValue={setModalValue}
          fullBind={fullBind}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          nameSection={'rateValue'}
          useInput={useInput}
          loadRateCard={loadRateCard}
          addCard={addCard}
          editCard={editCard}
          copiedCard={copiedCard}
          formObjName={formObjName}
          firstLayerAddButton={firstLayerAddButton}
          insideFirstLayerAddButton={insideFirstLayerAddButton}
          rateCardType={rateCardType}
          travelTab={travelTab}
          copiedListigData={copiedListigData}
          filterShiftType={filterShiftType}
          onCallTab={onCallTab}
          officeTab={officeTab}
          officeShiftType={officeShiftType}
          rateSource={rateSource}
          absencePaid={absencePaid}
          absenceType={absenceType}
          abscenceTab={abscenceTab}
          toast={toast}
          isPayRate={isPayRate}
          shiftType={shiftType}
          shiftTab={shiftTab}
          waitTab={waitTab}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={'Are you sure you want to delete this rate card? '}
        onOk={handleDeleteRateCard}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default CommonModelData;
