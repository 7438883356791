import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FlexGrid3Container, FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  HOUR,
  MINUTE
} from 'utils/constants/serviceVisits';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
import AddMedicationReview from './AddMedicationReview';
import { FaPlus } from 'react-icons/fa';
const BulkReviewMedication = ({
  fullBind,
  useInput,
  values,
  setValues,
  choices,
  editMode,
  handleBulkReview,
  errorMessage,
  showError,
  deleteReview,
  dropDownHandleSelect = () => { }
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.review_comment`);
  const newDoseTime = useInput(
    `medication.review_time`, 'isRequired');
  const review_date_hook = useInput('medication.review_date', 'isRequired')
  const review_status_hook = useInput(`medication.review_status`, 'isRequired')

  const addNewReview = () => {
    if (!values.review_data) {
      values.review_data = [];
    }
    values.review_data.push({});
    setValues({ ...values });
  };

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication Review',
    });
  }, [fullBind]);

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
          <div className='pt-2 pl-3 pr-3 pb-3'>
        {values.review_data && values.review_data.map((item, index) => {
          return (
            <>
              <AddMedicationReview
                values={values}
                useInput={useInput}
                setValues={setValues}
                editMode={false}
                choices={choices}
                // contextChoicesMap={contextChoicesMap}
                itemData={item}
                indexReview={index}
                nameSection={'review_data'}
                deleteReview={deleteReview}
              />
            </>
          )
        })}
        < div className={'mt-2'}>
          <PrimaryButtonForm minWidth="6rem" onClick={addNewReview}>
            <FaPlus />
          </PrimaryButtonForm>
        </div>
        <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleBulkReview}
                >
                  Save
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default BulkReviewMedication;