import React, {useState, useEffect, Fragment,useRef, useContext} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import DateSelector from 'components/SharedComponents/DateSelector';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {useForm} from 'hooks/FormHook';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {getDaysArray} from 'utils/Array';
import {Header, Title} from 'shared/styles/constants/tagsStyles';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {setCalendarApi, getCalendar} from 'utils/api/SettingsApi';
import {getAdmin, getSuperAdmin} from "../../../../../utils/localStorage/token"
import {getCategoryPermission, getPermissions,getStaff,getClient,getEmployee,getSubSectionPermissions} from '../../../../../utils/localStorage/user';
import {
    CALENDAR_SETTINGS,
    COMPANY_SETTINGS,
    WEB_CREATE_PERMISSION,
    WEB_DELETE_PERMISSION,
    WEB_PERMISSION,
    WEB_UPDATE_PERMISSION,
    WEB_READ_PERMISSION,
  } from '../../../../../utils/constants/permissions'
  import {AppContext} from "context"
import { setting_calendar_choices } from 'utils/choiceConstant';

const Calendar = ({}) => {

  const CALENDAR_NOT_FOUND = 'Calendar details have not been created';

  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [calendar, setCalendar] = useState({});
    //CalendarSettings
    const [updateCalendarSettings, setUpdateCalendarSettings] = useState(false);
    const [createCalendarSettings, setCreateCalendarSettings] = useState(false);
    const [deleteCalendarSettings,setDeleteCalendarSettings]= useState(false);
    const [readCalendarSettings, setReadCalendarSettings] = useState(false);
    const [edit, setEdit] = useState( );
    const {contextSubSectionPermissions, contextIsSuperAdmin, contextIsAdmin, contextIsStaff, handleGetChoices, contextChoices}=useContext(AppContext)

  const daysArray = getDaysArray(true).splice(1);
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm(calendar);

  const mountedRef = useRef(true);

  const  userClient =  getClient();
  const  userEmployee = getEmployee();
  const  userStaff = contextIsStaff;
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;

  useEffect(() => {
    handleGetChoices(setting_calendar_choices)
    
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  
  useEffect(() => {
    loadCalendar()

    // const roles = getPermissions();
  
    // let rolePermissions = {};
    // if (roles.length > 0) {
    //   roles.forEach(item => {
    //     if (item?.section?.name === COMPANY_SETTINGS) {
    //       rolePermissions[COMPANY_SETTINGS] = {};
    //       rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION] = {};
    //       rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
    //         WEB_READ_PERMISSION
    //       ] = item?.[WEB_READ_PERMISSION];
    //       rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
    //         WEB_CREATE_PERMISSION
    //       ] = item?.[WEB_CREATE_PERMISSION];
    //       rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
    //         WEB_DELETE_PERMISSION
    //       ] = item?.[WEB_DELETE_PERMISSION];
    //       rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
    //         WEB_UPDATE_PERMISSION
    //       ] = item?.[WEB_UPDATE_PERMISSION];
    //     }
    //   });
  

      const rolesPermissions = contextSubSectionPermissions;
      let rolePermissions = {};
      if (rolesPermissions.length > 0) {
        rolesPermissions.forEach(item => {
         
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name ] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        // }
        );  
      }
      
      if((filterAdmin == true) || (filterSuperAdmin ==true) ){

        setCreateCalendarSettings(true) ;
        setDeleteCalendarSettings(true);
        setUpdateCalendarSettings(true);
        setReadCalendarSettings(true);
        
      }
      else {
     
// Calendar Settings
setCreateCalendarSettings(
  rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
    WEB_CREATE_PERMISSION
  ]
);
setDeleteCalendarSettings(
  rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
    WEB_DELETE_PERMISSION
  ]
);
setUpdateCalendarSettings(
  rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
    WEB_UPDATE_PERMISSION
  ]
);

setReadCalendarSettings( 
  rolePermissions[CALENDAR_SETTINGS]?.[WEB_PERMISSION][
    WEB_READ_PERMISSION
  ]
);

    }

    // }
  }, []);


  useEffect(() => {
    if (textStatus === SAVE) {
      setValues({...values,...calendar});
    }
  }, [calendar]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
  }, [values]);

  const validateCalendarValues = setBody => {
    // if (!setBody.calendar) {
    //   setBody.calendar = {};
    // }

    if (
      !setBody.min_shift_length ||
      setBody.min_shift_length== ""
    ) {
      setBody.min_shift_length = null;
    }

    if (
      !setBody.max_shift_length ||
      setBody.max_shift_length == ""
    ) {
      setBody.max_shift_length = null;
    }

    if (
      !setBody.min_hour_between_shift ||
      setBody.min_hour_between_shift == ""
    ) {
      setBody.min_hour_between_shift = null;
    }

    if (
      !setBody.rota_max_hour_per_week ||
      setBody.rota_max_hour_per_week== ""
    ) {
      setBody.rota_max_hour_per_week = null;
    }

    if (
      !setBody.shift_max_per_week ||
      setBody.shift_max_per_week == ""
    ) {
      setBody.shift_max_per_week = null;
    }

    if (
      !setBody.shift_max_per_day ||
      setBody.shift_max_per_day== ""
    ) {
      setBody.shift_max_per_day = null;
    }

    if (
      !setBody.max_span_hour_per_day ||
      setBody.max_span_hour_per_day == ""
    ) {
      setBody.max_span_hour_per_day = null;
    }

    if (!setBody.match_score || setBody.match_score == "") {
      setBody.match_score = null;
    }

    if (!setBody.overall_score || setBody.overall_score == "") {
      setBody.overall_score = null;
    }

    if (!setBody.timezone || setBody.timezone == "") {
      setBody.timezone = null;
    }

    if (
      !setBody.start_of_weekday ||
      setBody.start_of_weekday== ""
    ) {
      setBody.start_of_weekday = null;
    }

    if (
      !setBody.start_of_weekend ||
      setBody.start_of_weekend== ""
    ) {
      setBody.start_of_weekend = null;
    }

    if (
      !setBody.daytime_hour_begins ||
      setBody.daytime_hour_begins== ""
    ) {
      setBody.daytime_hour_begins = null;
    }

    if (
      !setBody.daytime_hour_ends ||
      setBody.daytime_hour_ends== ""
    ) {
      setBody.daytime_hour_ends = null;
    }

    if (
      !setBody.nighttime_hour_begin ||
      setBody.nighttime_hour_begin== ""
    ) {
      setBody.nighttime_hour_begin = null;
    }

    if (
      !setBody.nighttime_hour_end ||
      setBody.nighttime_hour_end== ""
    ) {
      setBody.nighttime_hour_end = null;
    }

    if (
      !setBody.bussines_day_end_time ||
      setBody.bussines_day_end_time== ""
    ) {
      setBody.bussines_day_end_time = null;
    }

    if (
      !setBody.overnight_hour_begins ||
      setBody.overnight_hour_begins== ""
    ) {
      setBody.overnight_hour_begins = null;
    }

    if (
      !setBody.overnight_hour_ends ||
      setBody.overnight_hour_ends == ""
    ) {
      setBody.overnight_hour_ends = null;
    }

    if (
      !setBody.verification_time_clock_in ||
      setBody.verification_time_clock_in == ""
    ) {
      setBody.verification_time_clock_in = null;
    }
    if (
      !setBody.verification_time_clock_out ||
      setBody.verification_time_clock_out== ""
    ) {
      setBody.verification_time_clock_out = null;
    }

    if (
      !setBody.verification_distance_clock_out ||
      setBody.verification_distance_clock_out  == ""
    ) {
      setBody.verification_distance_clock_out = null;
    }

    if (
      !setBody.verification_distance_clock_in 
      ||
        setBody.verification_distance_clock_in == ""
    ) {
      setBody.verification_distance_clock_in = null;
    }

  };

  const checkBeforeSend = async () => {
    if (!isValid  &&   errors.length > 0) {
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    const setBody = values;
    validateCalendarValues(setBody);
    if (!setBody) {
      setErrorMessage(CALENDAR_NOT_FOUND);
      return;
    }
    try {

      let response = await handleSubmitCalendar(setBody);
 
      if (response.id  ) {
values.id = response.id


setValues({...values})
        onSuccess();
      }
       else {
        onError();
      }
    } 
    catch (e) {
      onError();
    }
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setErrorMessage('');
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setErrorMessage(InvalidOrEmptyValues);
  };

  const handleSubmitCalendar = async valuesToSave => {
    try {
      let response = await setCalendarApi(valuesToSave);

      return response;
    } catch (error) {
      return error;
    }
  };

  const loadCalendar = () => {
    getCalendar().then(response => {
      if (!mountedRef.current) return null;
    //  const r = values.calendar.min_hour_between_shift = response.results.map(x=>x.min_hour_between_shift)
    //   setCalendar(r);

    if(response.count  >= 1 ){
        setEdit(false)
    }else{
        setEdit(true)
    }

      setCalendar({...response.results[0]} || {})

    });
  };

  const dropDownHandleSelect = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({...values});
  };

  const checkUpdatePermission = (filterAdmin || filterSuperAdmin ||  ( (  userStaff == true  ) && (  updateCalendarSettings  || (createCalendarSettings  &&  edit )  )) )


  return (
    <div>
      <div className="d-flex align-items-center mt-2">
        <FlexGrid3Container className={'w-100 mb-2'}>
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
            disabled={!checkUpdatePermission}
              type="text"
              id="min_shift_length"
              label={'Min shift length (hours)'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`min_shift_length`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="max_shift_length"
              label={'Max shift length (hours)'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`max_shift_length`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="min_hour_between_shift"
              label={'Min hours between shifts'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`min_hour_between_shift`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <DateSelector
              disabled={!checkUpdatePermission}
              id="rota_start_date"
              labelTop={'Effective start date'}
              {...useInput(`rota_start_date`)}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
            <DateSelector
              disabled={!checkUpdatePermission}
              id="rota_effective_end_date"
              labelTop={'Effective end date'}
              {...useInput(`rota_effective_end_date`)}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="rota_max_hour_per_week"
              label={'Max hours per week'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`rota_max_hour_per_week`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="shift_max_per_week"
              label={'Max shifts per week'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`shift_max_per_week`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="shift_max_per_day"
              label={'Max shifts per day'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`shift_max_per_day`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="max_span_hour_per_day"
              label={'Max span of hours per day'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`max_span_hour_per_day`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="match_score"
              label={'Match criteria score'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`match_score`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="overall_score"
              label={'Overall scoring'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`overall_score`, 'isInt')}
              maxlength={5}
            />
          </FlexGrid3Item>

          {/* <FlexGrid3Item className="pl-1">
            <DropdownSearchable
              placeHolder={'Timezone'}
              options={[]}
              selectedOption={
                values &&  values.timezone ? values.timezone : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {timezone: value ? value : null},
                  'timezone',
                )
              }
            />
          </FlexGrid3Item> */}

          {/* <FlexGrid3Item className="pr-1 mb-2 mb-md-0">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Date format'}
              options={[
                {id: 'dd/MM/yyyy', name: 'DD/MM/YYYY'},
                {id: 'MM/DD/yyyy', name: 'MM/DD/YYYY'},
                {id: 'yyyy/mm/dd', name: 'YYYY/MM/DD'},
                // {id: 'dd/MMM/yyyy', name: 'DD/MM/YYYY'},
              ]}
              selectedOption={
                values && values.dateformat ? values.dateformat : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {dateformat: value ? value : null},
                  'dateformat',
                )
              }
            />
          </FlexGrid3Item> */}

          <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Start of the weekday'}
              options={daysArray}
              selectedOption={
                values &&  values.start_of_weekday
                  ? values.start_of_weekday
                  : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {start_of_weekday: value ? value : null},
                  'start_of_weekday',
                )
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0 mb-2">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Start of the weekend'}
              options={daysArray}
              selectedOption={
                values&&  values.start_of_weekend
                  ? values.start_of_weekend
                  : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {start_of_weekend: value ? value : null},
                  'start_of_weekend',
                )
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0 mb-2">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Daytime hour begins'}
              {...useInput(`daytime_hour_begins_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
        
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 mb-2">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Daytime hour ends'}
              {...useInput(`daytime_hour_ends_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
             
          <FlexGrid3Item className="pl-1 mb-2">
            <TimeSelector
            disabled={!checkUpdatePermission}
              labelTop={'Night time hour begin'}
              {...useInput(`nighttime_hour_begin_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
    
          <FlexGrid3Item className="pr-1 mb-2 mb-md-0 mb-2">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Night time hours end'}
              {...useInput(`nighttime_hour_end_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-1 mb-2">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Business day end at'}
              {...useInput(`bussines_day_end_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
      
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0 mb-2">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Overnight hour begins at'}
              {...useInput(`overnight_hour_begins_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
       
          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <TimeSelector
              disabled={!checkUpdatePermission}
              labelTop={'Overnight hour ends at'}
              {...useInput(`overnight_hour_ends_time`)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
          </FlexGrid3Item>
             
           <FlexGrid3Item className="pr-1">
            <TimeSelector
              disabled={!checkUpdatePermission}
            labelTop={'Verification  Time Clock-in (hours)'}
            timeFormat={"HH:mm"}
            dateFormat={"hh:mm"}
            {...useInput('verification_time_clock_in')}
            placeholderText={'hh:mm'}
            locale={"sv-sv"}
            timeCaption={'Duration'}
            
            />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1">
            <TimeSelector
              disabled={!checkUpdatePermission}
            labelTop={'Verification  Time  Clock-out (hours)'}
            timeFormat={"HH:mm"}
            dateFormat={"HH:mm"}
            {...useInput('verification_time_clock_out')}
            placeholderText={'hh:mm'}
            locale={"sv-sv"}
            timeCaption={'Duration'}
            />
            </FlexGrid3Item>

          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="verification_distance"
              label={'Verification  Distance Clock-in(meters)'}
              onKeyPress={KeyPress.DECIMAL}
              {...useInput(`verification_distance_clock_in`) }
              maxlength={4}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              disabled={!checkUpdatePermission}
              type="text"
              id="verification_distance"
              label={'Verification  Distance Clock-out (meters)'}
              onKeyPress={KeyPress.DECIMAL}
              {...useInput(`verification_distance_clock_out`)}
              maxlength={4}
            />
          </FlexGrid3Item>

          {/* below is the new fields */}
          {/* <FlexGrid3Item className="pr-1">
            <TimeSelector
            labelTop={'testing Time'}
            timeFormat={"HH:mm"}
            dateFormat={"h:mm"}
            {...useInput('01:25')}
            placeholderText={'hh:mm'}
            />
            </FlexGrid3Item> */}

          <FlexGrid3Item className="pr-1">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Date format'}
              options={contextChoices.date_format
              //   [
              //   { id: 'dd/MM/yyyy', name: 'DD/MM/YYYY' },
              //   { id: 'MM/DD/yyyy', name: 'MM/DD/YYYY' },
              //   { id: 'yyyy/mm/dd', name: 'YYYY/MM/DD' },
              // ]
            }
              selectedOption={
                values && values.date_format ? values.date_format : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  { date_format: value ? value : null },
                  'date_format',
                )
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-1">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Export Date format'}
              options={contextChoices.date_format
              //   [
              //   {id: 'dd/MM/yyyy', name: 'DD/MM/YYYY'},
              //   {id: 'MM/DD/yyyy', name: 'MM/DD/YYYY'},
              //   {id: 'yyyy/mm/dd', name: 'YYYY/MM/DD'},
              // ]
            }
              selectedOption={
                values && values.export_date_format ? values.export_date_format : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {export_date_format: value ? value : null},
                  'export_date_format',
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Time format'}
              options={contextChoices.time_format
              //   [
              //   {id: 'HH:mm', name: 'HH:mm'},
              //   {id: 'hh:mm a', name: 'hh:mm a'},
              //   {id: 'HH:mm:ss', name: 'HH:mm:ss'},
              //   {id: 'hh:mm:ss a', name: 'hh:mm:ss a'},
              // ]
            }
              selectedOption={
                values && values.time_format ? values.time_format : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {time_format: value ? value : null},
                  'time_format',
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 mt-2">
            <DropdownSearchable
              disabled={!checkUpdatePermission}
              placeHolder={'Export Time format'}
              options={contextChoices.time_format
              //   [
              //   {id: 'HH:mm', name: 'HH:mm'},
              //   {id: 'hh:mm a', name: 'hh:mm a'},
              //   {id: 'HH:mm:ss', name: 'HH:mm:ss'},
              //   {id: 'hh:mm:ss a', name: 'hh:mm:ss a'},
              // ]
            }
              selectedOption={
                values && values.export_time_format ? values.export_time_format : ''
              }
              onChange={value =>
                dropDownHandleSelect(
                  {export_time_format: value ? value : null},
                  'export_time_format',
                )
              }
            />
          </FlexGrid3Item>

        </FlexGrid3Container>
      </div>

      <div
        className={
          'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
        }>
        <div className={'mb-3'}>
          {errorMessage ? (
            <ErrorMessage>
              <label className={'mt-2'}>{errorMessage}</label>
            </ErrorMessage>
          ) : (
            <Fragment />
          )}
        </div>
        {checkUpdatePermission ?(  
        <div className={'d-flex justify-content-center justify-content-md-end'}>
          <div className={'ml-0 ml-md-3 mr-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={checkBeforeSend}>
              <span className={'mr-2'}>{textStatus}</span>
              {!loadingSave ? (
                buttonIcon
              ) : (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              )}
            </PrimaryButtonForm>
          </div>
        </div>
        ) : null  }
      </div>
    </div>
  );
};

export default Calendar;
