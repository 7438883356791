import styled from 'styled-components';
import {MD, SM} from 'shared/styles/constants/resolutions';
import {
  ERR_COLOR,
  PURPLE,
  GRAY_700,
  WHITE,
  GRAY_400,
} from 'shared/styles/constants/colors';
import backgroundLogin from 'images/backgroundLogin.jpg';

const SIZE_FOR_LOGIN = '938px';

export const LoginPageContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${WHITE} !important;
  background-size: cover;
`;

export const UpperCaseText = styled.div`
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  font-size: 2em;
`;

export const ImgWrapper = styled.div`
  padding-top: 3%;
  display: flex;
  justify-content: start;

  img {
    width: 15em;
    height: 4.6em;;
  }

`;

export const ImgFpWrapper = styled.div`

  display: flex;
  justify-content: start;


  img {
    width: 15em;
    height: 4.3em;;
  }
  
   @media (max-width: ${MD}) {
   display: flex;
  justify-content: center;
    img {
    width: 16em;
    height: 5em;;
  }

  @media (max-width: ${SM}) {
    display: flex;
   justify-content: center;
     img {
     width: 16em;
     height: 5em;;
   }

  }
`;

export const InputsContent = styled.div`
  @media (max-width: ${SM}) {
    min-width: 80%;
    max-width: 80%;
  }

  @media (max-width: ${MD}) {
    min-width: 70%;
  }

  @media (min-width: ${MD}) {
    min-width: 30rem;
  }
`;

export const SmallText = styled.div`
  font-size: 0.8rem;
  font-weight:700;
  
@media(min-width: 450px) {
 margin-right:20px;

}

`;

export const PwdStyle = styled.div`
 
  span {
    z-index: 1;
    position: relative;
    top: 0.12rem;
    right: 30px;
    color: ${GRAY_700};
    cursor: pointer;
    font-size: 1.2rem;
  }

  span:active {
    color: ${PURPLE};
  }
`;

export const InputFieldLogin = styled.input`
  background: transparent !important;
  color: #0d0d0d;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  width: 90%;
  border: 0.125rem solid
    ${({error}) => (error ? `${ERR_COLOR}` : `${GRAY_700}`)};
    
  &:focus {
    outline: none;
    background-color: white;
    border: 0.125rem solid ${PURPLE};
  }
`;

export const ColumnSwap = styled.div`

@media(max-width: 768px) {
  .bdy{
   display :flex !important;
     flex-direction: column-reverse !important;

  }
}s
`;

export const Changecolumn = styled.div`

@media(max-width: 769px) {
  .bdy{
   display :flex !important;
     flex-direction: column-reverse !important;
     justify-content-center !important;
     
}s
}
@media(min-width: 780px) {
  .cdlogo{
   display :flex !important;
     justify-content:center;
     
}s
}

@media(min-width: 780px) {
  .cdtext{
   display :flex !important;
     justify-content:center;
     
}s
}
`;

export const ForgetPassword = styled.div`
  font-size: 1em;
  font-weight:500;
  margin-left:1px;
  text-decoration:none !important;
  
  &:hover{
  text-decoration:none !important;
  }
`;

export const Horizontal = styled.div`
// background-color:#6200f9 !important;
  border:0 px;
height: 2px !important;
width:15%;
margin-top:1px;

`;

export const LoginContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${WHITE} !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: ${SIZE_FOR_LOGIN}) {
    min-width: 70%;

    from {
      background-position: 0% 0%;
    }
    to {
      background-position: 0% -100%;
    }

    background-image: url('');
    background: ${WHITE};
    background: linear-gradient(149deg, ${WHITE} 0%, ${GRAY_400} 100%);
  }
`;

export const UpperText = styled.div`
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  font-size: 1.5em; 

  @media (max-width: ${SIZE_FOR_LOGIN}) {
    margin-top: 1.2rem;
  }
`;

export const ImageWrapper = styled.div`
  padding-top: 2%;
  margin-top:2%;
  display: flex;
  justify-content: center;

  img {
    width: 25rem;
    height: 7.5rem;
  }

  @media (max-width: ${MD}) {
    padding-top: 3%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
      height: 12%;
    }
    margin-top: 0.2rem;
  }

  @media (max-width: ${SM}) {
    padding-top: 3%;
    display: flex;
  justify-content: center;
    img {
      width: 50%;
      height: 10%;
    }
  }
`;

export const FormContent = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${SIZE_FOR_LOGIN}) {
    margin-right: 0%;
    justify-content: center;
  }
`;

export const PasswordStyle = styled.div`
  position: relative;

  span {
    z-index: 1;
    position: absolute;
    top: 0.3rem;
    right: 10px;
    color: ${GRAY_700};
    cursor: pointer;
    font-size: 1.2rem;
  }

  span:active {
    color: ${PURPLE};
  }
`;

export const InputLogin = styled.input`
  background: transparent !important;
  color: #0d0d0d;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  width: 100%;
  border: 0.125rem solid
    ${({error}) => (error ? `${ERR_COLOR}` : `${GRAY_700}`)};
  border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;

  &:focus {
    outline: none;
    background-color: white;
    border: 0.125rem solid ${PURPLE};
  }
`;

// export const signupWrapper = `
// display:flex;
// justify-content:end;
// flex-direction:row;

//    @media (max-width: ${MD}) {
//     display:flex;
//     justify-content:end;
//     flex-direction:row;
  
//   @media (max-width: ${SM}) {
   
//     display:flex;
// justify-content:center;
// flex-direction:row;

//   }
// `;
