import { Switch, TextField } from '@mui/material'
import React from 'react'

export default function BodyMapComponent({ json, setJson }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Label *"
                placeholder='label'
                className='mb-2'
                defaultValue={json.bodyMapInput.label}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    console.log(event.target.value)
                    setJson({
                        ...json, bodyMapInput: {
                            ...json.bodyMapInput,
                            label: event.target.value
                        }
                    })
                }}
            />


        <TextField
            id="outlined-multiline-static"
            label="Description"
            className='mb-2'
            multiline
            rows={3}
            value={json.bodyMapInput.description}
            placeholder="Description"
            onChange={(event) => {
                setJson({
                    ...json, bodyMapInput: {
                        ...json.bodyMapInput,
                        description: event.target.value
                    }
                })
            }}
        />
         <TextField
                id="outlined-multiline-static"
                label="Instruction"
                multiline
                rows={3}
                value={json.bodyMapInput.instruction}
                placeholder="Instruction"
                onChange={(event) => {
                    setJson({
                        ...json, bodyMapInput: {
                            ...json.bodyMapInput,
                            instruction: event.target.value
                        }
                    })
                }}
            />
            <div className='d-flex'>
                <div className='d-flex'>
                    <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                    <Switch
                                color="primary"
                                checked={json.bodyMapInput.required}
                                onChange={(item) => {
                                    setJson({
                                        ...json, bodyMapInput: {
                                            ...json.bodyMapInput,
                                            required: item.target.checked
                                        }
                                    })
                                }}
                            />
                </div>
                <div className='d-flex ml-2'>
                    <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                    <Switch
                                color="primary"
                                checked={json.bodyMapInput.readOnly}
                                onChange={(item) => {
                                    setJson({
                                        ...json, bodyMapInput: {
                                            ...json.bodyMapInput,
                                            readOnly: item.target.checked
                                        }
                                    })
                                }}
                            />
                </div>
                <div className='d-flex ml-2'>
                    <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                    <Switch
                                color="primary"
                                checked={json.bodyMapInput.showCondition}
                                onChange={(item) => {
                                    setJson({
                                        ...json, bodyMapInput: {
                                            ...json.bodyMapInput,
                                            showCondition: item.target.checked
                                        }
                                    })
                                }}
                            />
                </div>
                
            </div>
           {json.bodyMapInput.showCondition &&
            <TextField
                id="outlined-multiline-static"
                label="Rule Expression"
                className='mb-2'
                multiline
                rows={3}
                value={json.bodyMapInput.ruleExpression}
                placeholder="Rule Expression"
                onChange={(event) => {
                    setJson({
                        ...json, bodyMapInput: {
                            ...json.bodyMapInput,
                            ruleExpression: event.target.value
                        }
                    })
                }}
            />}
    </div>
    )
}
