import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {AssignAllVisits,AssignFunder,setShadowVisits} from "utils/api/SchedulerApi"
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
import { getFundersList } from 'utils/api/ClientApi';

const FunderName = ({
    fullBind,
    checkedRows,
    searchScheduler,
    choices,
    rightClickSelectedId,
    setSelectedEvents,
    setRightClickSeletcedId
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
    const [funderList,setFunderList]=useState([])

    useEffect(()=>{
      funderLists();
    },[])

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Funder Name'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);
      
    const dropDownHandler=(item,nameItem)=>{
        values[nameItem] = item[nameItem];
        setValues({...values});
      }

      const funderLists=()=>{
        getFundersList()
        .then(res=>{
          if(res.length>0){
           let newFunders=res.map(funder=>{
             return {
               id:funder.id,
               name:funder.funder_name
             }
           })
           setFunderList([...newFunders])
          }
        })
      }

      const handleSubmit=()=>{
        if(errors.length>0 || loadingSave){
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues);
            return
          }
          setLoadingSave(true)
          setErrorMessage('');
          
        //   actionType=='Reassign'?{
        //       visit_ids:checkedRows.join(","),
        //       employee_id:values.employee_id
        //   }:
        let body= {
            visit_ids: rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows],
            funder: values.funder_name,
            category: values.funder_category,
            self_funded: (values.self_funded === 1) ? true : false
          }
          AssignFunder(body)
         .then(res=>{
          if(res.statusCode==400){
            throw res.message
          }
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          setErrorMessage('')
          searchScheduler()
          setRightClickSeletcedId([])
          setSelectedEvents([])
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(err)
        })
      
      }

console.log(values,"44155")
    return (
        <ModalContainer max_width={'60%'}>
                <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
               <div className={' pl-3 pr-3 pb-3'}>
    <div className="mb-2">
    <FlexGrid3Item className="pr-1 pb-2 mb-2">
    <DropdownSearchable
              placeHolder={'Is Self Funded'}
              options={[
                { id: 1, name: "Yes" },
                { id: 2, name: "No" }
              ]}
              selectedOption={
                values.self_funded
                  ? values.self_funded
                  : null
              }
              onChange={(value) =>
                dropDownHandler(
                  { self_funded: value ? value : null },
                  'self_funded',
                )
              }
            />
            </FlexGrid3Item>
            <div className='d-flex'> 
             <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
             <DropdownSearchable
             disabled={(values.self_funded && (values.self_funded === 1) ? true : false)}
                placeHolder={'Funder Name'}
                options={funderList}
                selectedOption={  
                  values.funder_name
                    ? values.funder_name
                    : null
                }
                onChange={(value) =>
                  dropDownHandler(
                    { funder_name: value ? value : null },
                    'funder_name',
                  )
                }
              />
           </FlexGrid3Item>
           <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <DropdownSearchable
              placeHolder={'Client / Funder category'}
              options={choices.funder_source}
              selectedOption={
                values.funder_category
                  ? values.funder_category
                  : null
              }
              onChange={(value) =>
                dropDownHandler(
                  { funder_category: value ? value : null },
                  'funder_category',
                )
              }
            />
          </FlexGrid3Item>
           </div>
        </div>
        <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
            </ModalDynamic> 
        </ModalContainer>
    );
}

export default FunderName;
