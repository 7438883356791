import React, { useEffect, useState, useRef, useContext } from 'react';

import { useForm } from 'hooks/FormHook';
import { AppContext, BranchItemsContext } from 'context';

import AbsenceHistoryTab from './AddModal/AbsenceHistoryTab';
import AbsenceOverviewTab from './AddModal/AbsenceOverviewTab';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
} from 'shared/styles/constants/tagsStyles';
import { useHistory } from 'react-router-dom';
import { EMPLOYEE } from 'utils/constants/localStorage';
import {
  EMPLOYEE_ABSENCE_OVERVIEW,
  EMPLOYEE_ABSENCE_HISTORY,
  WEB_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  WEB_CREATE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_DELETE_PERMISSION,
} from 'utils/constants/permissions';

import { PURPLE_800, PURPLE } from 'shared/styles/constants/colors';
import { useScrollTrigger } from '@mui/material';
const AbsenceHistoryList = props => {
  const tab1 = 1;
  const tab2 = 2;
  const tab3 = 3;

  const [tabSelected, setTabSelected] = useState(tab1);

  const [readPermissionAbsenceOverview, setReadPermissionOverview] =
    useState(false);
  const [updatePermissionAbsenceOverview, setUpdatePermissionOverview] =
    useState(false);
  const [deletePermissionAbsenceOverview, setDeletePermissionOverview] =
    useState(false);
  const [createPermissionAbsenceOverview, setCreatePermissionOverview] =
    useState(false);
  const [rightPermission, setRightPermission] = useState({});
  const {
    contextChoices: choices,
    contextChoicesMap,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextRelativesClient,
    contextChoices,
  } = useContext(AppContext);
  const filterSuperAdmin = contextIsSuperAdmin;
  const [readPermissionAbsenceHistory, setReadAbsenceHistory] = useState(false);
  const [updatePermissionAbsenceHistory, setUpdateAbsenceHistory] =
    useState(false);
  const [deletePermissionAbsenceHistory, setDeleteAbsenceHistory] =
    useState(false);
  const [createPermissionAbsenceHistory, setCreateAbsenceHistory] =
    useState(false);
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;

  const history = useHistory();
  const rolesPermissions = contextPermissions;
  useEffect(() => {
    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];

          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];

          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];

          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];

        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];

        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];

        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
      });
    }
    setRightPermission({ ...rolePermissions });
    if (filterSuperAdmin || filterAdmin) {
      setUpdatePermissionOverview(true);
      setDeletePermissionOverview(true);
      setCreatePermissionOverview(true);

      setReadPermissionOverview(
        contextSubscription?.[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]
      );

      setReadAbsenceHistory(
        contextSubscription?.[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]
      );
      setUpdateAbsenceHistory(true);
      setDeleteAbsenceHistory(true);
      setCreateAbsenceHistory(true);
    }
    else {
      if (rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]) {
        setCreatePermissionOverview(
          contextSubscription?.[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
            ])
        );
        setUpdatePermissionOverview(
          contextSubscription?.[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
            ])
        );
        setDeletePermissionOverview(
          contextSubscription?.[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
            ])
        );
        setReadPermissionOverview(
          contextSubscription?.[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_OVERVIEW]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
        );
      }

      ///for absence history
      if (rolePermissions[EMPLOYEE_ABSENCE_HISTORY]) {
        setReadAbsenceHistory(
          contextSubscription?.[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ])
        );
        setUpdateAbsenceHistory(
          contextSubscription?.[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ])
        );

        setDeleteAbsenceHistory(
          contextSubscription?.[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ])
        );
        setCreateAbsenceHistory(
          contextSubscription?.[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ] ||
            rolePermissions[EMPLOYEE_ABSENCE_HISTORY]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ])
        );
      }
    }
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions || contextSubSectionPermissions,
    contextSubscription,
  ]);
  useEffect(() => {
    if (!readPermissionAbsenceOverview && readPermissionAbsenceHistory) {
      setTabSelected(tab2);
    }
  }, [readPermissionAbsenceHistory && !readPermissionAbsenceOverview]);
  const { values, setValues, useInput } = useForm({
    // ordering: 'ee.full_name',
    absence_ids: [],
  });

  const reviewLocation = url => {
    return url === history.location.pathname;
  };
  const isCurrentTab = tab => {
    return tabSelected === tab;
  };
  const checkTabSwitch = tabName => {
    setTabSelected(tabName);
    if (tabName !== tab1) {
      history.push({
        pathname: '/absence-history-list',
      });
    }
  };

  return (
    <div>
      <div className="p-2 p-sm-3 p-md-4">
        <div className="d-flex justify-content-center text-center">
          {readPermissionAbsenceOverview && (
            <TabStyled
              style={{
                maxWidth: 'fit-content',
                paddingLeft: '10px',
                paddingRight: '10px'

              }}
              active={isCurrentTab(tab1)}
              onClick={() => checkTabSwitch(tab1)}>
              <label style={{ color: tabSelected === tab1 ? PURPLE : 'black' }}>
                Absence Overview
              </label>
            </TabStyled>
          )}
          {readPermissionAbsenceHistory && (
            <TabStyled
              style={{
                maxWidth: 'fit-content',
                paddingLeft: '10px',
                paddingRight: '10px'

              }}
              active={isCurrentTab(tab2)}
              onClick={() => checkTabSwitch(tab2)}>
              <label style={{ color: tabSelected === tab2 ? PURPLE : 'black' }}>
                Absence History
              </label>
            </TabStyled>
          )}
        </div>
      </div>
      {readPermissionAbsenceOverview && (
        <ShowTab show={isCurrentTab(tab1)}>
          {tabSelected === tab1 && (
            <AbsenceOverviewTab
              newData={props && props?.location?.search}
              updatePermissionAbsenceOverview={
                updatePermissionAbsenceOverview &&
                updatePermissionAbsenceOverview
              }
              deletePermissionAbsenceOverview={
                deletePermissionAbsenceOverview &&
                deletePermissionAbsenceOverview
              }
              createPermissionAbsenceOverview={
                createPermissionAbsenceOverview &&
                createPermissionAbsenceOverview
              }
              readPermissionAbsenceOverview={
                readPermissionAbsenceOverview && readPermissionAbsenceOverview
              }
            />
          )}
        </ShowTab>
      )}
      {readPermissionAbsenceHistory && (
        <ShowTab show={isCurrentTab(tab2)}>
          {tabSelected === tab2 && (
            <AbsenceHistoryTab
              newDatas={props && props?.location?.search}
              readPermissionAbsenceHistory={
                readPermissionAbsenceHistory && readPermissionAbsenceHistory
              }
              updatePermissionAbsenceHistory={
                updatePermissionAbsenceHistory && updatePermissionAbsenceHistory
              }
              deletePermissionAbsenceHistory={
                deletePermissionAbsenceHistory && deletePermissionAbsenceHistory
              }
              createPermissionAbsenceHistory={
                createPermissionAbsenceHistory && createPermissionAbsenceHistory
              }
            />
          )}
        </ShowTab>
      )}
    </div>
  );
};

export default AbsenceHistoryList;
