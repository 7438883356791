import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumsList';
import AddModal from 'components/DashboardComponents/Settings/SettingsCompany/RunRoutes/AddModal';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getRunRoute, statusRunRoute } from 'utils/api/SettingsApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { Row, Col, IconWrapper } from 'components/SharedComponents/Table/styles';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { useForm } from 'hooks/FormHook';
import queryString from 'query-string';
import {
  COMPANY_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from './../../../../../utils/constants/permissions'

import { useHistory } from 'react-router-dom';
import { AppContext } from "context"
import { Toast } from 'primereact/toast';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const RunRoutes = ({
  location,
  RUNROUTE_NOT_FOUND,
  props,
  updateRunRoutes,
  createRunRoutes,
  deletePermissionRunRoutes,
  readPermissionRunRoutes,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  handleChangeChoices
}) => {
  const toast = useRef(null)
  const { values, setValues, useInput } = useForm({
    limitperpage: PAGE_SIZE_RENDER,
    ordering: 'name'
  });
  const [runroute, setRunRoute] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [supervisorId, setSupervisorId] = useState([]);
  const [supervisorame, setSupervisorame] = useState([]);
  const [runrotename, setRunrotename] = useState([]);
  const [deleteValue, setDeleteValue] = useState();
  const [runroteid, setRunroteid] = useState([]);
  const [postalcodeid, setPostalCodeId] = useState([]);
  const [postalCodeData, setPostalCodeData] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [numberValue, setNumberValue] = useState();
  const [loader, setLoader] = useState(true)
  const history = useHistory();
  const mountedRef = useRef(true);
  const { contextPermissions, contextIsAdmin, contextIsSuperAdmin } = useContext(AppContext);

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const fullNameCol = "name";

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);


  // useEffect(() => {
  //   let limitperpage = {};
  //   values.limitperpage = PAGE_SIZE_RENDER;
  //   setValues({ ...values })
  // }, [PAGE_SIZE_RENDER])

  // useEffect(() => {
  //   const urlParams = queryString.parse(location.search);
  //   urlParams[PAGINATION_PARAM] = 1;
  //   const stringified = queryString.stringify(urlParams);
  //   history.push({
  //     pathname: "/settings/company/run-route",
  //     search: stringified,
  //   });
  //   loadRunRoute();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.limitperpage]);


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };



  const loadRunRoute = () => {
    const pageToSearch = getPageToSearchParam(location.search);
    getRunRoute({ ...values, ...pageToSearch }, values.limitperpage).then(response => {
      if (!mountedRef.current) return null;
      setRunRoute(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
      setLoader(false)
    });
  };

  useEffect(() => {
    loadRunRoute();
  }, [
    location.search,
    values.ordering,
    values.limitperpage
  ])

  useEffect(() => {
    // loadRunRoute();
    setTitle('Add Postal Code');
    dataPostalCodeId()
    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === COMPANY_SETTINGS) {
            rolePermissions[COMPANY_SETTINGS] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        if (rolePermissions[COMPANY_SETTINGS]) {
          setCreatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissions(
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   values.ordering = 'name';
  //   setNumberValue(1);
  //   setValues({ ...values });
  // }, [])

  // useEffect(() => {
  //   if (numberValue == 0) {
  //     values.ordering = '-name';
  //     setValues({ ...values });
  //   }
  // }, [numberValue])


  const changeNumber = (value) => {
    setNumberValue(value);
  }


  const toDeleteRunrRoute = (id, is_deleted) => {
    //     let  temp = [...runroute]
    //  temp.find(v => v.id ===   id)
    //   is_deleted  =  true ;
    //    setRunRoute([...temp]);
    //    await statusRunRoute(id, is_deleted ) 
    setModalDeleteValue(true);
    setIdSelected(id);
    setDeleteValue(is_deleted);
    // setIdToDelete(id);
  };

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddRunrRoute = () => {
    setIdSelected('');
    handleModal();
  };

  const handleDeleteRunRoute = async () => {
    // await statusRunRoute(idSelected);
    let temp = [...runroute]
    temp.find(v => v.id === idSelected)
    const deleteValue = true;
    setRunRoute([...temp]);
    //  await statusRunRoute(id, is_deleted ) 
    await statusRunRoute(idSelected, deleteValue);
    handleChangeChoices()
    setModalDeleteValue(false);
    setIdSelected('');
    loadRunRoute()
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
    // setValues({...values});
  };


  const getPostalCodeData = (postalcode) => {
    let res = postalcode.map((x) => (x.name));
    let z = res.join(",")
    return z;
  }



  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });

  };
  //new below
  const dataPostalCodeId = () => {
    let ids = postalcodeid.map(y => y.id)
    let t = ids + '  '

    return t;
  }


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }



  return (
    <div>
      <Toast ref={toast} />
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
          <FloatingMobileButton onClick={handleAddRunrRoute}>
            <FaPlus />
            <label> NEW GROUP AREA</label>
          </FloatingMobileButton>
        </SearchAddContainer>
      </Header>
      <ComponentDynamic loading={loader}>
        {runroute?.length ? (
          <Table
            headerColumns={headerColumns}
            callBackQuerySort={setQuerySort}
            statusNumber={numberValue}
            setStatusNumber={changeNumber}
            fullNameCol={fullNameCol}
          >
            <div>
              {runroute.map((runroutes, index) => {
                return (
                  <div key={index}>
                    <Row bgColor>
                      {/* <ColsGrouper
                    className="d-flex"
                
                    > */}

                      <Col max_width={LARGE_COL}
                        Shrink
                        //  reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center
                        onClick={() => {
                          handleRowClick(runroutes.id);
                        }} overlap={true}>
                        <label>{runroutes.name}</label>
                      </Col>

                      <Col max_width={LARGE_COL}
                        Shrink
                        //  reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center
                        onClick={() => {
                          handleRowClick(runroutes.id);
                        }} overlap={true}>
                        {getPostalCodeData(runroutes.postal_codes)}
                      </Col>

                      <Col max_width={LARGE_COL}
                        Shrink
                        //  reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center
                        overlap={true}
                        onClick={() => {
                          handleRowClick(runroutes.id);
                        }}   >
                        <label>{runroutes.supervisor_name}</label>
                      </Col>
                      <Col   >
                        <div className='d-flex  justify-content-end  '>
                          {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (updateRunRoutes))) && (
                            <Col className="sm-hidden  align-right" Center Shrink Icon Purple onClick={() => {
                              handleRowClick(runroutes.id);
                            }}>
                              <IconWrapper
                              // onClick={() => {
                              //   handleRowClick(runroutes.id);
                              // }}
                              >
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                          )}
                          {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (deletePermissionRunRoutes))) && (
                            <Col className="sm-hidden align-right" Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  toDeleteRunrRoute(runroutes.id, runroutes.is_deleted);
                                }}
                              >
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>
                          )}
                        </div>
                        {/* </ColsGrouper> */}
                      </Col>
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              <div className="mt-3">
                <Pagination totalPages={pages} currentPage={currentPage} values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={runroute.length} />
              </div>
            </div>
          </Table>) :
          (<div className='w-100 d-flex justify-content-center mt-3'>
            <h4 style={{ fontSize: "1rem" }}>No data found</h4>
          </div>)}
      </ComponentDynamic>
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          setTitle={setTitle}
          loadRunRoute={loadRunRoute}
          supervisorId={supervisorId}
          supervisorame={supervisorame}
          runroteid={runroteid}
          runrotename={runrotename}
          setSupervisorame={setSupervisorame}
          setIdSelected={setIdSelected}
          setSupervisorId={setSupervisorId}
          dropDownHandleSelect={dropDownHandleSelect}
          values={values}
          RUNROUTE_NOT_FOUND={RUNROUTE_NOT_FOUND}
          getPostalCodeData={getPostalCodeData}
          handleChangeChoices={handleChangeChoices}
          // postalCodeData={postalCodeData}
          toast={toast}
          setClosePopup={setModalValue}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this group area? Deleting the group areas will remove ALL records from database.'
        }
        // subBody={
        //   // 'TIP (Recommended) - you could change the run route status to discard instead to retain database records.'
        // }
        onOk={handleDeleteRunRoute}
        onCancel={() => {
          setIdSelected('');
          // setIdToDelete('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default RunRoutes;
