import styled from 'styled-components';
import {ERR_COLOR, INPUT_FOCUS_BC} from 'shared/styles/constants/colors';
import {keyShake} from 'shared/styles/animations';

export const FormContent = styled.div`
  -webkit-border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  background: #fff;
  padding: 1.875rem;
  width: 90%;
  max-width: 28.125rem;
  position: relative;
  padding: 0;
  border: 1px solid #c8c6c6;
  text-align: center;

  hr {
    width: 85%;
  }
`;

export const FormFooter = styled.div`
  padding: 1.5625rem;
  text-align: center;
  -webkit-border-radius: 0 0 0.625rem 0.625rem;
  border-radius: 0 0 0.625rem 0.625rem;
  div div {
    border-left: 0.0625rem solid white;
    margin-left: 0.625rem;
    padding-left: 0.625rem;
  }
  div a svg {
    width: 1.25rem;
    margin-right: 0.5rem;
  }
  div a svg path {
    fill: #b4dc2d;
  }
`;

export const InputLoginWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: calc(1.25rem / 1.1);
    right: calc(1.25rem * 2.2);
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: #c8c6c6;
    }
  }
`;

export const InputLogin = styled.input`
  background-color: transparent;
  border: 0.0625rem solid #c8c6c6;
  color: #0d0d0d;
  padding: 0.625rem calc(1.25rem * 1.5) 0.625rem 0.8rem;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.3125rem;
  width: 85%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;

  &:focus {
    outline: none;
    background-color: #fff;
    border-bottom: 0.125rem solid #5fbae9;
  }

  &[data-error] {
    border-bottom: 0.125rem solid ${ERR_COLOR};
    color: ${ERR_COLOR};
  }

  &[data-error] ~ label {
    color: ${ERR_COLOR};
  }
`;

export const ErrorMessage = styled.label`
  color: ${ERR_COLOR};
`;

export const ErrorMessageShake = styled.label`
  animation: ${keyShake} 1s;
  color: ${ERR_COLOR};
`;

export const SuccessMessage = styled.label`
  color: ${INPUT_FOCUS_BC};
`;

export const IconWrapper = styled.div`
  margin-top: 2rem;
  svg {
    width: 6.25rem;
    height: 6.25rem;
    path {
      fill: #c8c6c6;
    }
  }
`;

export const DropdownWrapperRegister = styled.div`
  width: 85%;
  margin: auto;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
`;
