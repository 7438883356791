import React, {Fragment} from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {AppContext, BranchItemsContext} from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PrimaryButtonModal} from 'components/SharedComponents/ModalDecision/styles';
import {Title} from 'shared/styles/constants/tagsStyles';
import {IconStyle} from 'components/SharedComponents/ModalInformation/styles';
import {CancelButton} from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import {Toast} from 'primereact/toast';
import {MessageOptaPlanner} from 'components/DashboardComponents/Scheduler/styles';
import { setClientContract } from 'utils/api/ClientApi';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import _ from "lodash"
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';
import {CHECK_CIRCLE} from 'utils/constants/icons';
import PropTypes from 'prop-types';
import {red} from '@mui/material/colors';
const ConfirmationModal = ({
  fullBind,
  setModalValue,
  values,
  checkBeforeSend,
  toDeleteVisits,
  toDeleteSplitFunder,
  toDeleteVisitSkill,
  toDeleteVisitEmployee,
  type,
  title,
  icon,
  children,
  showModal,
  onOk,
  okTitle,
  onCancel,
  onCancelTitle,
  hideOnCancel,
  setShowDeleteVisitsModal
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {contextChoices} = useContext(AppContext);
  const [newDetails, setDetails] = useState([]);
  const [fullDay ,setFullDay]=useState("Full Day")
  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: '',
    });
  }, [fullBind]);
  useEffect(() => {
    handleData();
  }, []);
  
  const handleToast = async() => {

    
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Data Updated Successfully',
    });
      setTimeout(() => {
        setModalValue(false);
      }, 1000);
    }
    
  
  const moment = require('moment');
  const today = moment();
  let date = today.format().substring(0, 10);
  const handleData = () => {
    let newData =values.visits &&values.visits.map(item => {
        let categoryName =
          item.service_category && contextChoices.service_category
            ? contextChoices.service_category.find(element => {
                if (element.id == item.service_category) {
                  return element;
                }
              })
            : '';
        return {
          preferredStart: item.preferred_start_time?item.preferred_start_time.toString().substring(0, 5):item.full_day ==true&&fullDay,
          preferredEnd: item.preferred_end_time&&item.preferred_end_time.toString().substring(0, 5),
          EffectiveDate: item.effective_date ? item.effective_date : date,
          service_category: item.service_category,
          service_category_name: categoryName ? categoryName.name : '',
          
          
        };
      });
    
    setDetails(newData)
  };

 
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              <h5>
                This action will update all visits data on the service contract
                from effective date mentioned below. Do you confirm for the
                changes?
              </h5>
            </div>
            <div style={{marginTop: '20px'}}>
              {newDetails &&
                newDetails.map(item => {
                  return (
                    <div
                      style={{
                        marginRight: '3px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        paddingRight: '10px',
                      }}>
                      <IoIosArrowDroprightCircle />
                      {item.service_category_name} ({item.preferredStart}-
                      {item.preferredEnd}) Form Date({item.EffectiveDate})
                    </div>
                  );
                })}
              <h6></h6>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => 
              setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              // typeButton={type}

              onClick={evt => {
                checkBeforeSend(
                  evt,
                  false,
                  true,
                  values,
                  toDeleteVisits,
                  toDeleteSplitFunder,
                  toDeleteVisitSkill,
                  toDeleteVisitEmployee
                );
                handleToast();
              }}>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default ConfirmationModal;
