import { ModalTabsContainer } from 'components/DashboardComponents/Team/AddModal/ModalStyle'
import { ShowTeamSettingModalTab } from 'components/DashboardComponents/Team/AddModal/styles'
import React from 'react'
import { useState } from 'react'
import { TabStyled, TabStyledNew, Title } from 'shared/styles/constants/tagsStyles'
import HRTask from './HrTask'
import EventTask from './EventTask'
import { AppContext } from 'context'
import { useContext } from 'react'
import { useEffect } from 'react'
import { EVENT_HR_TASTS, HR_TASKS, REPEATING_HR_TASKS, SETTING_HR_TASK, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions'
import RepeatingTasks from './RepeatingTasks'

function TaskMatrix(props) {
  const tab1 = 1
  const tab2 = 2
  const tab3 = 3
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin: isAdmin,
    contextIsSuperAdmin: isSuperAdmin,
    contextIsStaff: isStaff,
  } = useContext(AppContext);

  const [tabSelected, setTabSelected] = useState(tab1)

  //Roles & Permission states
  // HR-Tasks
  const [readPermissionHRTasks, setReadPermissionHRTasks] = useState(false)
  const [createPermissionHRTasks, setCreatePermissionHRTasks] = useState(false)
  const [updatePermissionHRTasks, setUpdatePermissionHRTasks] = useState(false)
  const [deletePermissionHRTasks, setDeletePermissionHRTasks] = useState(false)

  // Event HR-Tasks
  const [readPermissionEventTasks, setReadPermissionEventTasks] = useState(false)
  const [createPermissionEventTasks, setCreatePermissionEventTasks] = useState(false)
  const [updatePermissionEventTasks, setUpdatePermissionEventTasks] = useState(false)
  const [deletePermissionEventTasks, setDeletePermissionEventTasks] = useState(false)

  // Repeating HR-Tasks
  const [readPermissionRepeatingTasks, setReadPermissionRepeatingTasks] = useState(false)
  const [createPermissionRepeatingTasks, setCreatePermissionRepeatingTasks] = useState(false)
  const [updatePermissionRepeatingTasks, setUpdatePermissionRepeatingTasks] = useState(false)
  const [deletePermissionRepeatingTasks, setDeletePermissionRepeatingTasks] = useState(false)


  useEffect(() => {
    if (isAdmin == true || isSuperAdmin === true) {
      setReadPermissionHRTasks(true)
      setCreatePermissionHRTasks(true)
      setUpdatePermissionHRTasks(true)
      setDeletePermissionHRTasks(true)

      setReadPermissionEventTasks(true)
      setCreatePermissionEventTasks(true)
      setUpdatePermissionEventTasks(true)
      setDeletePermissionEventTasks(true)

      setReadPermissionRepeatingTasks(true)
      setCreatePermissionRepeatingTasks(true)
      setUpdatePermissionRepeatingTasks(true)
      setDeletePermissionRepeatingTasks(true)
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === SETTING_HR_TASK) {
            rolePermissions[SETTING_HR_TASK] = {};
            rolePermissions[SETTING_HR_TASK][WEB_PERMISSION] = {}
            rolePermissions[SETTING_HR_TASK][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION]
            rolePermissions[SETTING_HR_TASK][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION]
            rolePermissions[SETTING_HR_TASK][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION]
            rolePermissions[SETTING_HR_TASK][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION]
          }
        })
        const rolesPermissions = contextSubSectionPermissions;
        if (rolesPermissions.length > 0) {
          rolesPermissions.map(item => {
            rolePermissions[item?.section?.name] = {}
            rolePermissions[item?.section?.name][WEB_PERMISSION] = {}
            rolePermissions[item?.section?.name][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION]
            rolePermissions[item?.section?.name][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION]
            rolePermissions[item?.section?.name][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION]
            rolePermissions[item?.section?.name][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION]

          })
        }
        if (rolePermissions[HR_TASKS]) {
          setReadPermissionHRTasks(rolePermissions[HR_TASKS]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionHRTasks(rolePermissions[HR_TASKS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionHRTasks(rolePermissions[HR_TASKS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionHRTasks(rolePermissions[HR_TASKS]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])
        }
        if (rolePermissions[EVENT_HR_TASTS]) {
          setReadPermissionEventTasks(rolePermissions[EVENT_HR_TASTS]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionEventTasks(rolePermissions[EVENT_HR_TASTS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionEventTasks(rolePermissions[EVENT_HR_TASTS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionEventTasks(rolePermissions[EVENT_HR_TASTS]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])
        }
        if (rolePermissions[REPEATING_HR_TASKS]) {
          setReadPermissionRepeatingTasks(rolePermissions[REPEATING_HR_TASKS]?.[WEB_PERMISSION][WEB_READ_PERMISSION])
          setCreatePermissionRepeatingTasks(rolePermissions[REPEATING_HR_TASKS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION])
          setUpdatePermissionRepeatingTasks(rolePermissions[REPEATING_HR_TASKS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION])
          setDeletePermissionRepeatingTasks(rolePermissions[REPEATING_HR_TASKS]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION])

        }
      }
    }
  }, [contextPermissions, contextSubSectionPermissions, contextCategoryPermissions, isAdmin, isSuperAdmin, isStaff])

  const isCurrentTab = (tab) => (tab === tabSelected)
  return (
    <div>
      <Title className={''}>Settings QA Tasks</Title>
      <ModalTabsContainer className='d-flex justify-content-center' style={{ padding: 0 ,marginTop: "5px"}}>
        {readPermissionHRTasks && (
          <TabStyledNew
            style={{ width: "fit-content", marginRight: "1rem", paddingLeft: "10px", paddingRight: '10px' }}
            active={isCurrentTab(tab1)}
            onClick={() => {
              setTabSelected(tab1)
            }}>
            QA Tasks (Beta)
          </TabStyledNew>
        )}
        {readPermissionEventTasks && (
          <TabStyledNew
            style={{ width: "fit-content", marginRight: "1rem", paddingLeft: "10px", paddingRight: '10px' }}
            active={isCurrentTab(tab2)}
            onClick={() => {
              setTabSelected(tab2)
            }}>
            Event QA Tasks (Beta)
          </TabStyledNew>)}
        {readPermissionRepeatingTasks && (
          <TabStyledNew
            style={{ width: "fit-content", marginRight: "1rem", paddingLeft: "10px", paddingRight: '10px' }}
            active={isCurrentTab(tab3)}
            onClick={() => {
              setTabSelected(tab3)
            }}>
            Repeating QA Tasks (Beta)
          </TabStyledNew>)}
      </ModalTabsContainer>

      <ShowTeamSettingModalTab show={isCurrentTab(tab1)}>
        {readPermissionHRTasks &&
          (isCurrentTab(tab1) && (
            <HRTask
              props={props}
              readPermission={readPermissionHRTasks}
              createPermission={createPermissionHRTasks}
              deletePermission={deletePermissionHRTasks}
              updatePermission={updatePermissionHRTasks}
            />
          ))
        }
      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab2)}>
        {readPermissionEventTasks &&
          (isCurrentTab(tab2) && (
            <EventTask
              props={props}
              readPermission={readPermissionEventTasks}
              createPermission={createPermissionEventTasks}
              updatePermission={updatePermissionEventTasks}
              deletePermission={deletePermissionEventTasks}
            />
          ))
        }
      </ShowTeamSettingModalTab>

      <ShowTeamSettingModalTab show={isCurrentTab(tab3)}>
        {readPermissionRepeatingTasks &&
          (isCurrentTab(tab3) && (
            <RepeatingTasks
              props={props}
              readPermission={readPermissionRepeatingTasks}
              createPermission={createPermissionRepeatingTasks}
              updatePermission={updatePermissionRepeatingTasks}
              deletePermission={deletePermissionRepeatingTasks}
            />
          ))
        }
      </ShowTeamSettingModalTab>
    </div>

  )
}

export default TaskMatrix