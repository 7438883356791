import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles'
import InputLabelTop from 'components/SharedComponents/InputLabelTop'
import { useForm } from 'hooks/FormHook'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useRef } from 'react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ErrorMessage } from 'shared/styles/AuthComponents'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { FlexGrid3Container, FlexGrid3Item, Title, TitleSection } from 'shared/styles/constants/tagsStyles'
import { getLoginRestriction, setLoginRestriction } from 'utils/api/SettingsApi'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons'
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED, SERVER_ERROR } from 'utils/constants/messages'
import { KeyPress } from 'utils/constants/regularExpressions'

function SettingsLogin() {
    const toast = useRef(null)
    const { values, setValues, useInput, isValid, errors } = useForm({})
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [textStatus, setTextStatus] = useState(SAVE)
    const [loadingSave, setLoadingSave] = useState(false)
    const [buttonIcon, setButtonIcon] = useState(FLOPPY)
    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(true)



    const getLoginRestrictionData = async () => {
        setServerError("")
        try {
            const res = await getLoginRestriction()
            if (res.status == 200) {
                setValues({ ...values, ...res.data })
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setServerError(SERVER_ERROR)
        }
    }

    useEffect(() => {
        getLoginRestrictionData()
    }, [])

    const handleSubmit = async () => {
        setServerError("")
        if (errors.length) {
            setShowError(true)
            setErrorMessage(InvalidOrEmptyValues);
            return;
        }
        setLoadingSave(true)
        const body = {
            "login_attempt": values.login_attempt,
            "password_expiry": values.password_expiry
        }

        try {
            const res = await setLoginRestriction(body, values?.id)
            if (res.status == 200) {
                onSuccess()
            } else {
                onError(res)
            }
        } catch (error) {
            onError(error)
            setServerError(SERVER_ERROR)
        }
    }
    const onSuccess = (response) => {
        setButtonIcon(CHECK)
        setTextStatus(SAVED)
        setLoadingSave(false)
        setShowError(false)
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success!`,
        });
    }
    const onError = (error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: error?.message ? error?.message : "Something went wrong!",
        });

        setServerError(SERVER_ERROR)
        setButtonIcon(CROSS)
        setTextStatus(ERROR)
        setLoadingSave(false)
        setShowError(true)
        setErrorMessage(error.message)

    }
    const login_attempt_hook = useInput('login_attempt', "isRequired")
    const password_expiry_hook = useInput('password_expiry', 'isRequired')
    return (
        <ComponentDynamic loading={loading}>
            <Toast ref={toast} />
            <Title>Settings Login</Title>
            {!serverError ?
                <div className='' style={{ marginTop: "3rem" }}>
                    <div className={'w-75 mb-2'}>
                        <div className='d-flex justify-content-between'>
                            <small>
                                <b>
                                    <TitleSection>
                                        {/* How many login attempts would you like to allow? */}
                                        How many login attempts would you like to allow before locking the account?
                                    </TitleSection>
                                </b>
                            </small>
                            <FlexGrid3Item className="pr-1 d-flex">
                                <InputLabelTop
                                    type="text"
                                    required
                                    label='Login Attempt'
                                    value={values.login_attempt}
                                    {...login_attempt_hook}
                                    onKeyPress={KeyPress.NON_ZERO_INTEGER}
                                />
                            </FlexGrid3Item>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <small>
                                <b>
                                    <TitleSection>
                                        {/* How many days should a password be valid? */}
                                        How many days should a password be valid before it needs to be changed?
                                    </TitleSection>
                                </b>
                            </small>
                            <FlexGrid3Item className="pr-1 d-flex">
                                <InputLabelTop
                                    type="text"
                                    required
                                    label='Password Expiry'
                                    value={values.login_attempt}
                                    {...password_expiry_hook}
                                    onKeyPress={KeyPress.NON_ZERO_INTEGER}
                                />
                            </FlexGrid3Item>
                        </div>
                    </div>
                    <div
                        className={'d-flex justify-content-center justify-content-md-end mt-3'}>
                        <div className={'mb-3'}>
                            {showError && errors.length ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div className={'ml-0 ml-md-3 mr-1'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={false}
                                onClick={handleSubmit}
                                style={{ marginBottom: '5px' }}>
                                <span className={'mr-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div> :
                <NoDataFoundMessage>
                    {serverError}
                </NoDataFoundMessage>}
        </ComponentDynamic>
    )
}

export default SettingsLogin