import React, {useState, useEffect,useContext} from 'react';
import {Link, Switch, Route, useHistory} from 'react-router-dom';
import {getChoices} from 'utils/api/CoreApi';
import {getClients} from 'utils/api/ClientApi';
import {getEmployees} from 'utils/api/EmployeeApi';
import {getAllVisitEsvslist, getAllVisitEvvslist, getVisitDetail} from 'utils/api/SchedulerApi';
import {getEmployee,getClient} from "../../../utils/localStorage/token"
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  dateToStringDate,
  compareStringDates,
  createDateWithTime,
} from 'shared/methods';
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import {EVV, EVV_VISITS, EVV_GPS, EVV_TRAVEL, ESV_VISITS, ESV_GPS, ESV_TRAVEL, ESV} from 'utils/constants/routes';
import EventModal from './EventModal';
import EvvVisits from './EvvVisits';
import EvvGps from './EvvGps';
import EvvTravel from './EvvTravel';
import {getAdmin, getSuperAdmin} from "../../../utils/localStorage/token"
import {getCategoryPermission,getStaff, getPermissions,getSubSectionPermissions,getEmail} from  '../../../utils/localStorage/user';
import {
  EVV_INDEX,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from ' ../../utils/constants/permissions';
import moment from 'moment';
import {AppContext} from "context"
import {BranchItemsContext} from "context"  
import { calendar_esv_choices } from 'utils/choiceConstant';

const TAB_ESV_VISITS = ESV + ESV_VISITS;
const TAB_ESV_GPS = ESV + ESV_GPS;
const TAB_ESV_TRAVEL = ESV + ESV_TRAVEL;

const NEW_DATE = new Date();

const Esv = () => {
  const {values, setValues, useInput} = useForm({
    date_from: dateToStringDate(NEW_DATE),
    date_to: dateToStringDate(NEW_DATE),
    ordering:'employee__full_name',
  });

  const {contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext)
  const {branchData}=useContext(BranchItemsContext) ;
  const {headerFilterClearValue}=useContext(BranchItemsContext) ;

  const [evvVisitsEvents, setEvvVisitsEvents] = useState([]);
  const [evvGpsEvents, setEvvGpsEvents] = useState([]);
  const [evvTravelEvents, setEvvTravelEvents] = useState([]);
  const [eventValues, setEventValues] = useState([]);
  const [choices, setChoices] = useState({visit_priority: []});
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [visitType, seVisitType] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [subUpdate, setSubUpdate] = useState(false);
  const [subCreates, setSubCreates] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [branch, setBranch] = useState([]);
  const [visitStatus , setVisitStatus] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [shiftTypeFilter, setShiftTypeFilter] = useState([]);
  const [travelModeFilter, setTravelModeFilter] = useState([]);
  const [travelCodeFilter, setTravelCodeFilter] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [runRoutesFilter1, setRunRoutesFilter1] = useState([]);

  const [employeeFilter, setEmployeeFilter] = useState([]);
  const[choicesVisitStatus,setChoicesVisitStatus]=useState([])
  const[numberValue,setNumberValue]=useState() ;
  
  const[pages,setPages]=useState(0)
  let history = useHistory();
  const filterEmployee=getEmployee()
  const filterClient=getClient()
  const  userStaff = contextIsStaff;
  // const filterSuperAdmin=getEmail()==='admin@myhelpa.com'?true:
  const filterSuperAdmin= contextIsSuperAdmin;
  const filterAdmin= contextIsAdmin;

  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);

  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(calendar_esv_choices)
  },[])
  /**
   * Change date_to to date_from when date_from is greater than date_to
   */
  useEffect(() => {
    if (
      values.date_to &&
      values.date_from &&
      compareStringDates(values.date_from, values.date_to, '>')
    ) {
      values.date_to = values.date_from;
      setValues({...values});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date_from]);

  useEffect(() => {
    // loadChoices();
    // loadClients();
    // loadEmployees();

    const roles = contextPermissions;

    let rolePermissions = {};
    

      if(filterAdmin  == true  ||  filterSuperAdmin == true  ){
        setCreatePermissions(true) ;
        setDeletePermissions(true);
        setUpdatePermissions(true);
      }
      else { 
        if (roles.length > 0) {
          roles.forEach(item => {
            if (item?.section?.name === EVV_INDEX) {
              rolePermissions[EVV_INDEX] = {};
              rolePermissions[EVV_INDEX][WEB_PERMISSION] = {};
              rolePermissions[EVV_INDEX][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[EVV_INDEX][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[EVV_INDEX][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
            }
          });

      setCreatePermissions(
        rolePermissions[EVV_INDEX][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        rolePermissions[EVV_INDEX][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        rolePermissions[EVV_INDEX][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ]
      );
    }
  }

  }, [filterAdmin, filterSuperAdmin]);

  useEffect(()=>{
    setChoices(contextChoices || {visit_priority: []});
    setServiceCategoryData(contextChoices && contextChoices.service_category && contextChoices.service_category.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }))
    seVisitType( contextChoices.visit_type && contextChoices.visit_type.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }  ) )
    setChoicesVisitStatus(contextChoices && contextChoices.shift_status && contextChoices.shift_status.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }  ));
    
    setRunRouteData(contextChoices && contextChoices.run_routes && contextChoices.run_routes.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }))

    setBranch(contextChoices && contextChoices.branch && contextChoices.branch.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }  ));
  
    setVisitStatus(contextChoices.shift_status && contextChoices.shift_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setClients(contextChoices && contextChoices.client_listing && contextChoices.client_listing.map(y=>{
      return{ id: y && y.id , name: y && y.full_name}
    }  ));

    setEmployees(contextChoices && contextChoices.employee_listing && contextChoices.employee_listing.map(y=>{
      return{ id: y && y.id , name: y && y.full_name}
    }  ));

  },[contextChoices])


  // const loadChoices = async () => {
  //   try {
  //     const response = await getChoices();
  //     setServiceCategoryData(response.service_category)
  //     seVisitType(response.visit_type)
  //     if (Object.keys(response).length >= 1) {
  //       setChoices(response || {visit_priority: []});
  //     }
  //     setChoicesVisitStatus(response.visit_status)
   
  //     setRunRouteData(response.run_routes && response.run_routes.map(y => {
  //       return { id: y && y.id, name: y && y.name }
  //     }))

  //     setBranch(response.branch && response.branch.map(y => {
  //       return { id: y && y.id, name: y && y.name }
  //     }))
  //     setVisitStatus(response.visit_status && response.visit_status.map(y => {
  //       return { id: y && y.id, name: y && y.name }
  //     }))
  //   } catch (error) {}
  // };

  /**
   * reset the evvs existing in tables and get new evvs
   * @param {object} filter_values
   * @param {string} tab // tab identifier
   */
  
  console.log(values,"checking")
  const loadEvvs = async (filter_values, tab,pageSize) => {
    let esv_type = 'ESV'
    if (tab === TAB_ESV_VISITS) {
      setEvvVisitsEvents([]);
      esv_type = 'ESV'
    }
    if (tab === TAB_ESV_GPS) {
      setEvvGpsEvents([]);
      esv_type = 'GPS'
    }
    if (tab === TAB_ESV_TRAVEL) {
      setEvvTravelEvents([]);
      esv_type = 'Travel'
    }



    setLoadingCalendar(true);
    try {
      //fuse this api to pass data in evv pop up
      const response = await getAllVisitEsvslist({...values,...filter_values}, esv_type, pageSize);
      let newEvents = [];
      setPages(0)
      if (response && response.data) {
        setLoadingModal(false);
          newEvents=[...response.data]
       }

      if (tab === TAB_ESV_VISITS) {
        setEvvVisitsEvents(newEvents);
        setPages(Math.ceil(response.count_data / pageSize));
      }
      if (tab === TAB_ESV_GPS) {
        setEvvGpsEvents(newEvents);
        setPages(Math.ceil(response.count_data / pageSize));
      }
      if (tab === TAB_ESV_TRAVEL) {
        setEvvTravelEvents(newEvents);
        setPages(Math.ceil(response.count_data / pageSize));
      }
      setLoadingCalendar(false);
    } catch (error) {
      setLoadingCalendar(false);
    }
  };

  /**
   * return json to save in the arrangement
   * @param {object} _results
   */
  const assignValues = _results => {
    return {
      start: createDateWithTime(
        _results.schedule_start_date,
        _results.schedule_start_time,
      ),
      extendedProps: {
        visit_no: _results.visit_obj.visit_number,
        client_name: _results.client_obj.full_name,
        employee_name: _results.employee_obj.full_name,
        schedule_start_time: _results.schedule_start_time
          ? _results.schedule_start_time.substring(0, 5)
          : '',
        schedule_end_time: _results.schedule_end_time
          ? _results.schedule_end_time.substring(0, 5)
          : '',
        duration: _results.duration,
        actual_start_time: _results.actual_start_time,
        actual_end_time: _results.actual_end_time,
        // actual_duration: _results.duration,
        actual_duration: '',
        visit_status: '',
        visit_id: _results.visit,
        client_reference: _results.client_obj.reference,
        employee_reference: _results.employee_obj.reference,

        //gps additional values
        //TODO: clock_in_method, clock_out_method 'Manual' only option available for now
        clock_in_method: 'Manual',
        clock_out_method: 'Manual',
        clock_in_location: _results.actual_start_location,
        clock_out_location: _results.actual_end_location,
        clock_in_verification: _results.actual_start_verification,
        clock_out_verification: _results.actual_end_verification,

        //travel additional values
        travel_code: _results.travel_code, //(actual_visit_travel)
        travel_mode:_results.travel_mode,
        journey_start_postcode: _results.journey_start_zipcode,
        journey_end_postcode: _results.journey_end_zipcode,
        schedule_travel_distance: _results.schedule_travel_miles,
        schedule_travel_time: _results.schedule_travel_time
          ? _results.schedule_travel_time.substring(0, 5)
          : '',
        actual_travel_distance: _results.actual_travel_miles,
        actual_travel_time: _results.actual_time
          ? _results.actual_time.substring(0, 5)
          : '', // todo: review if the variable is correct
          start_travel_time:_results.start_travel_time?_results.start_travel_time:"",
          end_travel_time:_results.end_travel_time?_results.end_travel_time:"",
      },
    };
  };

  /**
   * Change dropdown value
   * @param {object} item
   */
  const dropDownHandleSelect = item => {  
    setValues({...values, ...item});
  };

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }
  const setRunroutefilter = (item) => {
   
    setRunRoutesFilter([...item])
  }
 

  const setFiltersetVisitStatus = (item) => {
    setVisitStatusFilter([...item])
  }
  const setFiltersetShiftType = (item) => {
    setShiftTypeFilter([...item])
  }
  const setFiltersetTravelMode = (item) => {
    setTravelModeFilter([...item])
  }
  const setFiltersetTravelCode = (item) => {
    setTravelCodeFilter([...item])
  }
  const setFiltersetEmployee = (item)=>{
    setEmployeeFilter([...item])
  }
  /**
   * return true if url is equal to history location pathname
   * @param {object} url
   */
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  // console.log(evvVisit, 753951)
  const eventClickHandler = async evvVisit => {
    console.log(evvVisit, 753951)

    const actualVisitStartTime=evvVisit?.actual_visit_start_time?.split('T')[1]?evvVisit?.actual_visit_start_time?.split('T')[1] : '';
    const actualVisitStartTimeHHMM=moment(actualVisitStartTime,'HH:mm:ss').format('HH:mm');
    const schedule_start_time =moment(evvVisit?.schedule_start_time,'HH:mm:ss').format('HH:mm');

// duration in hours
var hours =evvVisit?.schedule_start_time ? -1* moment(evvVisit?.schedule_start_time,'HH:mm:ss').diff(moment(actualVisitStartTime,'HH:mm:ss'), 'hours'): '';

// duration in minutes
var minutes =evvVisit?.schedule_start_time ? moment.utc(moment(evvVisit?.schedule_start_time, "HH:mm:ss").diff(moment(actualVisitStartTime, "HH:mm:ss"))).format("mm") : '';
    const Clock_in_time_difference_hhmm=hours+':'+ minutes ;

    const actual_visit_end_time=evvVisit?.actual_visit_end_time?.split('T')[1]?evvVisit?.actual_visit_end_time?.split('T')[1] : '';
    const actual_visit_end_time_hhmm=moment(actual_visit_end_time,'HH:mm:ss').format('HH:mm');
    const schedule_end_time =moment(evvVisit?.schedule_end_time,'HH:mm:ss').format('HH:mm');

// duration in hours
var hoursClockout = evvVisit?.schedule_end_time ?-1* moment(evvVisit?.schedule_end_time,'HH:mm:ss').diff(moment(actual_visit_end_time,'HH:mm:ss'), 'hours') : '';

// duration in minutes
var minutesClockout =evvVisit?.schedule_end_time ? moment.utc(moment(evvVisit?.schedule_end_time, "HH:mm:ss").diff(moment(actual_visit_end_time, "HH:mm:ss"))).format("mm") : '';
    const Clock_out_time_difference_hhmm= hoursClockout+':'+ minutesClockout;
     const Scheduled_Duration_vs_Clocked_Duration = evvVisit?.schedule_duration_clocked_duration_diff ;
     const Scheduled_Duration_vs_Clocked_Duration_hhmm= Math.floor(Scheduled_Duration_vs_Clocked_Duration / 60) + ':' + (Scheduled_Duration_vs_Clocked_Duration % 60);

    const prcntge_clocked_visit_duration= (evvVisit?.actual_visit_duration)/(evvVisit?.duration);



    try {

        const visit_detail = {
          client_reference: evvVisit.client_obj && evvVisit.client_obj.reference, //from client
          employee_reference: evvVisit.employee_obj && evvVisit.employee_obj.reference, //from employee
          no_of_clients: evvVisit.visit_obj.no_of_client,
          // res.client_service.no_clients, //from service contract
          no_of_employees:evvVisit.visit_obj.no_of_employee,
          // res.client_service_visit.no_employees, //from service visit
      
          // TODO: add correct time_code
          time_code: 'Visit', //'Visit' (by default), 'Travel', 'Waiting'
          service_category:evvVisit.visit_obj.service_category,
          // res.client_service_visit.service_category, //from service visit
          visit_type: evvVisit.visit_obj.visit_type,
          // res.client_service_visit.visit_type, //from service visit
          visit_priority: evvVisit.visit_obj.visit_priority,
          // res.client_service_visit.visit_priority, //from service visit
          full_name: evvVisit.client_obj.full_name,
          // res.client_service_visit.client_data.full_name,
          date_of_birth: evvVisit.client_obj.date_of_birth,
          photo: evvVisit.client_obj.photo,

          actual_start_time: evvVisit.actual_start_time,
          actual_end_time: evvVisit.actual_end_time,

          clock_count : evvVisit.clock_count,

//newly added fields below 

          Clock_in_time_difference : Clock_in_time_difference_hhmm ? Clock_in_time_difference_hhmm: '',

          Clocked_Start_Early_Late:evvVisit?.actual_visit_start,//text
          
          Clock_out_time_difference : Clock_out_time_difference_hhmm?Clock_out_time_difference_hhmm:'',

          Clocked_End_Early_Late : evvVisit?.actual_visit_end,//text

          Scheduled_Duration_vs_Clocked_Duration: Scheduled_Duration_vs_Clocked_Duration_hhmm, //input

          prcntge_clocked_visit_duration:prcntge_clocked_visit_duration  ? Math.round((prcntge_clocked_visit_duration + Number.EPSILON)*100)/100 : '', //input

          Clocked_Visit_Provision: evvVisit?.clocked_visit_provision, //inputtext

          Clocked_Time_Confirmation: (evvVisit.end_time_verification ? 'Verified' : 'Not Verified') +' -' + (evvVisit?.start_time_verification? 'Verified' : 'Not Verified') , 
          // inputtext

          Clock_in_Distance_from_Location:evvVisit?.start_distance_verification_message ,   // inputtext

          Clock_in_Distance_Verification : evvVisit?.start_distance_verification,  // inputtext 

          Clock_out_Distance_from_Location : evvVisit?.end_distance_verification_message,  // inputtext 

          Clock_out_Distance_Verification : evvVisit?.end_distance_verification,  // inputtext 

          Clocked_Distance_Confirmation : (evvVisit.end_distance_verification ? 'Verified' : 'Not Verified') + "-" + (evvVisit?.start_distance_verification? 'Verified' : 'Not Verified'),


        };


        setEventValues(visit_detail );
        setAddEvent(!addEvent);
      // }
    } catch (error) {}
  };

//   useEffect(()=>{
//     fetchChoiceData()
//     findVistName()
//     console.log(choiceVisitStatusData,'choiceVisitStatusData77')
//     console.log(fetchChoiceData(),'fetchChoiceData()')
//   },[ ])
 
//   const fetchChoiceData = async()=>{
//   const resp = await (choices.visit_status);
//     setChoiceVisitStatusData(resp);
//     const data = await  (resp).map((z, y) => {
//       if ((evvVisitsEvents.map(x => x.visit_obj).find(w => w.visit_status)) == (z.id)) {
//         return y.name;
//       }
//     })
//       return data
// }

//   const findVistName=()=>{
// const data =  (choiceVisitStatusData).map((z, y) => {
//   if ((evvVisitsEvents.map(x => x.visit_obj).find(w => w.visit_status)) == (z.id)) {
//     return y.name;
//   }
// })
//   return data
// }

  return (
    <div>
      <Title> Electronic Shift Verification (ESV) </Title>
      <div className="   ">
        <ContainerTabStyled className="text-center mb-4">
          <div className={'d-flex firstRow'}>
            <LinkTabStyled active={reviewLocation(TAB_ESV_VISITS)}>
              <Link to={TAB_ESV_VISITS} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_ESV_VISITS)}>
                  Shifts
                </LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_ESV_GPS)}>
              <Link to={TAB_ESV_GPS} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_ESV_GPS)}>GPS</LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_ESV_TRAVEL)}>
              <Link to={TAB_ESV_TRAVEL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_ESV_TRAVEL)}>
                  Travel
                </LinkTab>
              </Link>
            </LinkTabStyled>
          </div>
        </ContainerTabStyled>

        <Switch>
          <Route exact path={TAB_ESV_VISITS}>
            <EvvVisits
              values={values}
              setValues={setValues}
              useInput={useInput}
              dropDownHandleSelect={dropDownHandleSelect}
              events={evvVisitsEvents}
              choices={choices}
              clients={clients}
              employees={employees}
              eventClickHandler={eventClickHandler}
              loadEvvs={loadEvvs}
              loadingCalendar={loadingCalendar}
              filterClient={filterClient}
              filterEmployee={filterEmployee}
              pages={pages}
              runRouteData={runRouteData}
              branch={branch}
              setFilterBranch={setFilterBranch}
             	setRunroutefilter={setRunroutefilter}
            
               branchFilter={branchFilter}
               runRoutesFilter={runRoutesFilter}
               choicesVisitStatus={choicesVisitStatus}
visitStatus={visitStatus}
setFiltersetVisitStatus={setFiltersetVisitStatus}
setFiltersetShiftType={setFiltersetShiftType}
visitStatusFilter={visitStatusFilter}
shiftTypeFilter={shiftTypeFilter}
loadingModal={loadingModal}
employeeFilter={employeeFilter}
setFiltersetEmployee={setFiltersetEmployee}
            />
          </Route>
          <Route exact path={TAB_ESV_GPS}>
            { (  ( (filterClient === 'null' )  && (   (userStaff == true )   )  ||  (filterSuperAdmin  == true ) || (filterAdmin == true ) ||  (filterEmployee === 'true' )   ) ) && (
            <EvvGps
              values={values}
              setValues={setValues}
              useInput={useInput}
              dropDownHandleSelect={dropDownHandleSelect}
              events={evvGpsEvents}
              choices={choices}
              clients={clients}
              employees={employees}
              eventClickHandler={eventClickHandler}
              loadEvvs={loadEvvs}
              loadingCalendar={loadingCalendar}
              filterClient={filterClient}
              filterEmployee={filterEmployee}
              pages={pages}
              runRouteData={runRouteData}
              branch={branch}
              setFilterBranch={setFilterBranch}
             	setRunroutefilter={setRunroutefilter}
               branchFilter={branchFilter}
               runRoutesFilter={runRoutesFilter}
               loadingModal={loadingModal}
               visitStatus={visitStatus}
setFiltersetVisitStatus={setFiltersetVisitStatus}
setFiltersetShiftType={setFiltersetShiftType}
visitStatusFilter={visitStatusFilter}
shiftTypeFilter={shiftTypeFilter}
employeeFilter={employeeFilter}
setFiltersetEmployee={setFiltersetEmployee}
            />
             ) }
          </Route>
          <Route exact path={TAB_ESV_TRAVEL}>
          { ( (filterSuperAdmin == true  ) || ( filterAdmin == true  ) ) &&
            <EvvTravel
              values={values}
              setValues={setValues}
              useInput={useInput}
              dropDownHandleSelect={dropDownHandleSelect}
              events={evvTravelEvents}
              choices={choices}
              clients={clients}
              employees={employees}
              eventClickHandler={eventClickHandler}
              loadEvvs={loadEvvs}
              loadingCalendar={loadingCalendar}
              filterClient={filterClient}
              filterEmployee={filterEmployee}
              pages={pages}
              runRouteData={runRouteData}
              branch={branch}
              setFilterBranch={setFilterBranch}
             	setRunroutefilter={setRunroutefilter}
              branchFilter={branchFilter}
              runRoutesFilter={runRoutesFilter}
              loadingModal={loadingModal}
              visitStatus={visitStatus}
              setFiltersetVisitStatus={setFiltersetVisitStatus}
              setFiltersetShiftType={setFiltersetShiftType}
              setFiltersetTravelMode={setFiltersetTravelMode}
              visitStatusFilter={visitStatusFilter}
              shiftTypeFilter={shiftTypeFilter}
              travelModeFilter={travelModeFilter}
              travelCodeFilter={travelCodeFilter}
              setFiltersetTravelCode={setFiltersetTravelCode}
              employeeFilter={employeeFilter}
              setFiltersetEmployee={setFiltersetEmployee}

            />
          }
          </Route>
        </Switch>
      </div>

      {!addEvent ? null : (
        <EventModal
          readOnly={true}
          fullBind={fullBindAddEvent}
          eventValues={eventValues}
          choices={choices}
          serviceCategoryData={serviceCategoryData}
          visitType={visitType}
        
        />
      )}
    </div>
  );
};

export default Esv;
