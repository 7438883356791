import React from 'react'
import { useState } from 'react'
import "./NonCommuteTravel.css"
import { getTravelCommuteList } from 'utils/api/SettingsApi'
import { useEffect } from 'react'
import { EX_LARGE_COL, EX_SMALL_COL, LARGE_COL } from 'shared/styles/constants/columns'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { MdEdit } from 'react-icons/md'
import { useContext } from 'react'
import { AppContext } from 'context'
import { payroll_non_commute_choice } from 'utils/choiceConstant'
import moment from 'moment'
import Spinner from 'components/SharedComponents/Spinner'
import { useModal } from 'hooks/ModalHook'
import ModalNonCommute from './ModalNonCommute'
import Pagination from 'components/SharedComponents/Pagination'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import { getPageToSearchParam } from 'shared/methods'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useForm } from 'hooks/FormHook'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles'

const headerColumns = [
    {
        label: 'Branch Name',
        name: 'send_to',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Created By',
        name: 'send_by',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Updated By',
        name: 'mail_subject',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Last Created At',
        name: 'mail_subject',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Last Updated At',
        name: 'mail_subject',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
];

function NonCommuteTravelSetting({
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) {
    const readOnly = !updatePermission || !createPermission
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
    } = useModal(false);
    const location = useLocation()
    const toast = useRef(null)
    const { values, setValues } = useForm({ limitperpage: PAGE_SIZE_RENDER });

    const { contextChoices: choices, handleGetChoices } = useContext(AppContext);
    const [travelCommute, setTravelCommute] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState({})
    const [traveSettings, setTraveSettings] = useState([
        { name: "Home-Client", travelTime: false, travelMileage: false },
        { name: "Home-Office", travelTime: false, travelMileage: false },
        { name: "Office-Client", travelTime: false, travelMileage: false },
        { name: "Office-Home", travelTime: false, travelMileage: false },
        { name: "Office-Office", travelTime: false, travelMileage: false },
        { name: "Client-Client", travelTime: false, travelMileage: false },
        { name: "Client-Office", travelTime: false, travelMileage: false },
        { name: "Client-Home", travelTime: false, travelMileage: false },
    ])

    const getListOfTravelCommute = async () => {
        const pageToSearch = getPageToSearchParam(
            location.search,
            setCurrentPage,
        )
        try {
            const res = await getTravelCommuteList({ ...pageToSearch, ...values })
            setTravelCommute(res.results)
            setPages(Math.ceil(res.count / values?.limitperpage));
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        values['limitperpage'] = PAGE_SIZE_RENDER;
        setValues({ ...values })
    }, [PAGE_SIZE_RENDER])

    useEffect(() => {
        handleGetChoices(payroll_non_commute_choice)
    }, [])

    useEffect(() => {
        getListOfTravelCommute()
    }, [values?.limitperpage, location.search])

    //Find user name form choices user list
    const findNameFromChoice = (id) => {
        if (id) {
            const find = choices?.user_list?.find(user => user.id === id)
            return find?.name ? find?.name : null
        }
    }


    //Format date
    const formatDate = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD')
        }
    }

    //Limit per page dropdown
    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const splitName = (name) => {
        const arr = name.split("-")
        let n = arr[0].toLowerCase() + '_to_' + arr[1].toLowerCase()
        return n
    }

    const updateTravelSettingState = (item) => {
        let data = traveSettings.map(setting => ({
            ...setting,
            travelTime: item[`${splitName(setting.name)}_travel`] || false,
            travelMileage: item[`${splitName(setting.name)}_milage`] || false
        }))

        console.log(data, "data")
        setRowData(item)
        setTraveSettings(data)
    }

    const handleUpdateClick = (item) => {
        setModalValue(true)
        setTitle('Update Non Commute Travel Setting')
        updateTravelSettingState(item)
    }
    return (
        <>{readPermission ? (
            <div>
                <Toast ref={toast} />
                {isLoading ?
                    (<div style={{ marginTop: "300px" }} >
                        <Spinner />
                    </div>) :
                    <>
                        {travelCommute?.length > 0 ? (
                            <>
                                <Table
                                    headerColumns={headerColumns}
                                    headerPadding={false}
                                    noNeed={false}
                                // noEditOption={true}
                                >
                                    <div>
                                        {travelCommute?.map(item => {
                                            return (

                                                <Row key={Math.random()} bgColor style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                    <ColsGrouper className="d-flex justify-content-start">
                                                        <Col
                                                            max_width={EX_LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            <label>{item?.branch_name}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            <label>{findNameFromChoice(item.created_by)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            <label>{findNameFromChoice(item.updated_by)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            <label>{formatDate(item.created_at)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            <label>{formatDate(item.updated_at)}</label>
                                                        </Col>
                                                    </ColsGrouper>

                                                    <Col
                                                        className="sm-hidden"
                                                        max_width={EX_SMALL_COL}
                                                        // style={{ marginRight: '10px',maxWidth: "4%"}}
                                                        Center
                                                        Purple
                                                        Icon
                                                    >
                                                        <IconWrapper
                                                            onClick={() => handleUpdateClick(item)}
                                                        >
                                                            <MdEdit />
                                                        </IconWrapper>
                                                    </Col>
                                                </Row>

                                            );
                                        })}
                                    </div>
                                </Table>
                                <div className="mt-3">
                                    <Pagination
                                        totalPages={pages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        values={values}
                                        dropDownHandleSelect={dropDownHandleSelectPagination}
                                        number={PAGE_SIZE_RENDER}
                                        dataLength={travelCommute?.length}
                                    />
                                </div>
                            </>)
                            : (
                                <div className='d-flex w-100 justify-content-center'>
                                    <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                                </div>
                            )}
                    </>}

                {modalValue &&
                    <ModalNonCommute
                        fullBind={fullBind}
                        traveSettings={traveSettings}
                        setTraveSettings={setTraveSettings}
                        rowData={rowData}
                        splitName={splitName}
                        setModalValue={setModalValue}
                        getListOfTravelCommute={getListOfTravelCommute}
                        toast={toast}
                        readOnly={readOnly}
                    />}
            </div>
        ) : (
            <NoPermissionContainer>
                You don't have permission to read the information.
            </NoPermissionContainer>
        )
        }</>
    )
}

export default NonCommuteTravelSetting