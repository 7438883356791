import {createGlobalStyle} from 'styled-components';
import {MD, LG} from 'shared/styles/constants/resolutions';

export const GlobalStyle = createGlobalStyle`
// ::-webkit-scrollbar {
//     height: 1rem;
//     width: 0.6rem;
//     background: #fff;
// }
// ::--thumb {
//     background: rgba(0, 0, 0, 0.5);
//     border-radius: 2px;
// }

    body {
        height: calc(100vh - 4.3381rem);
        margin: 0 auto;
        overscroll-behavior: none;
        width: 100%;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #F0F0F7;
    }

    .root {
        height: 100%;
    }

    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    ul, li, h1, h2, h3, p, button {
        margin: 0;
    }

    ul {
        list-style: none!important;
    }

    .form-control {
        &:focus {
            box-shadow: none!important;
        }
    }
    .btn  {
        cursor: pointer
    }
    .sm-show {
      display: none!important;
      @media (max-width: ${MD}) {
        display: inherit!important;
      }
    }
    .md-hidden {
      @media (max-width: ${LG}) {
        display: none!important;
      }
    }
    .sm-hidden {
      @media (max-width: ${MD}) {
        display: none!important;
      }
    }
    .flex-1 {
      flex: 1;
    }
`;
