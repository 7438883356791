import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {SetActualBulkTime} from "utils/api/SchedulerApi"
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import moment from 'moment'
const ActualEdits = ({
    fullBind,
    checkedRows,
    searchScheduler,
    setActualEditsVisits,
    setRightClickSeletcedId,
    setSelectedEvents,
    rightClickSelectedId,
    clearSelectedElId
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Edit Actuals',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);
      const dropDownHandler=(item,nameItem)=>{
          if(nameItem=='time_type'){
              values.actual_visit_time=null
          }
        values[nameItem] = item[nameItem];
        setValues({...values});
      }

      const handleTimeSelector=(e)=>{
        values.actual_visit_time= moment(e.target.value).format("HH:mm:ss")
        values.actual_time = moment(e.target.value).format("HH:mm")
        setValues({...values})
      }
      const handleSubmit=()=>{
       
        if(errors.length>0 || loadingSave){
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
        setLoadingSave(true)
          setErrorMessage('');
          let body={
            visit_ids:rightClickSelectedId.length ? [...rightClickSelectedId] : [...checkedRows],
            clock_type:values.clock_type,
            time_type:values.time_type,
            actual_visit_time:values.actual_visit_time && values.actual_visit_time
        }
      let res=  SetActualBulkTime(body)
        .then(res=>{
          if(res.cancelled_visits && res.cancelled_visits.length > 0){
            setSaveButtonIcon(CROSS);
            setErrorMessage('Visit is cancelled from selected row');
            setTextStatus(ERROR);
            setLoadingSave(false);
          }else{
            setSaveButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            searchScheduler()
            setActualEditsVisits(false)
            setRightClickSeletcedId([])
            setSelectedEvents([])
            clearSelectedElId()
          }
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          
        })

      }


    return (
        <ModalContainer max_width={'40%'}>
            <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
                      <div className={' pl-3 pr-3 pb-3'}>
    <div className="mb-2">
    <FlexGrid3Item className="pr-1 pb-2 mb-2">
    <DropdownSearchable
              required
                placeHolder={'Select Clock Type'}
                // width="145%"
                options={[
                    {id:"Clock-out",name:"Actual Clock Out"},
                    {id:"Clock-in",name:"Actual Clock In"}
                ]}
                {...useInput(
                  `clock_type`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.clock_type : null
                }
                onChange={value =>
                  dropDownHandler(
                    {clock_type: value ? value : null},
                    'clock_type'
                  )
                }
              />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pb-2 mb-2">
        <DropdownSearchable
              required
                placeHolder={'Select Time Type'}
                // width="145%"
                options={[
                    {id:"default",name:"Same As Scheduled Time"},
                    {id:"Manual",name:"Custom"},
                    {id:"Revert",name:"Revert"}
                ]}
                {...useInput(
                  `time_type`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.time_type : null
                }
                onChange={value =>
                  dropDownHandler(
                    {time_type: value ? value : null},
                    'time_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pb-2">
                {values.time_type=='Manual' &&
                 <TimeSelector
                     isRequired={true}
                     labelTop={'Actual Visit Time'}
                     timeFormat={'HH:mm'}
                     dateFormat={'HH:mm'}
                     onChange={(e)=>handleTimeSelector(e)}
                     value={values.actual_time}
                    //  {...useInput(`actual_visit_time`,'isRequired')}
                   />
                }
           
            </FlexGrid3Item>

    </div>
    <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
        </ModalDynamic>
        </ModalContainer>
    );
}

export default ActualEdits;
