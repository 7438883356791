import React from 'react'
import { useState } from 'react';
import { FloatingMobileButton, PrimaryButtonForm } from 'shared/styles/buttons';
import { FlexGrid3Container, FlexGrid3Item, Header } from 'shared/styles/constants/tagsStyles';
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, EX_VERY_LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row, IconWrapper } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import _ from 'lodash';
import AddCompetencyMatrixModal from './Modals/AddCompetencyMatrixModal';
import { useModal } from 'hooks/ModalHook';
import { FaPlus } from 'react-icons/fa';
import "./CompetencyMatrix.css"
import { addNewCompetencyMatrix, bulkDeleteCompetencySkill, deleteCompentencyMatrix, getCompentencyMatrixList, updateCompetencyMatrix } from 'utils/api/SettingsApi';
import { useEffect } from 'react';
import UpdateModal from './Modals/UpdateModal';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import Spinner from 'components/SharedComponents/Spinner';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getPageToSearchParam, useOutsideClickClosePopup } from 'shared/methods';
import { useHistory, useLocation } from 'react-router-dom';
import { BranchItemsContext } from 'context';
import { useContext } from 'react';




function CompetencyMatrix({
    choices,
    values,
    setValues,
    useInput,
    dropDownHandleSelect,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission,

}) {
    const location = useLocation()


    const headerColumns = [
        {
            label: deletePermission ? 1 : "",
            checkbox: deletePermission ? true : false,
            name: deletePermission ? 'checkbox' : "",
            status: 0,
            className: 'grow-mobile',
            max_width: EX_SMALL_COL4,
        },
        {
            label: "BRANCH",
            name: '',
            status: 0,
            className: 'grow-mobile',
            max_width: EX_VERY_LARGE_COL,
        },
        {
            label: "COMPETENCY",
            name: '',
            status: 0,
            className: 'grow-mobile',
            max_width: EX_VERY_LARGE_COL,
        },
        // {
        //     label: "",
        //     name: '',
        //     status: 0,
        //     className: 'grow-mobile',
        //     max_width: EX_SMALL_COL4,
        // },
        // {
        //     label: "",
        //     name: '',
        //     status: 0,
        //     className: 'grow-mobile',
        //     max_width: EX_SMALL_COL4,
        // }
    ]

    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
    } = useModal(false);

    const {
        setValue: setUpdateModalValue,
        value: updateModalValue,
        setTitle: setUpdateTitle,
        fullBind: updateFullBind,
    } = useModal(false);

    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete
    } = useModal(false);

    const {
        setValue: setbulkModalDeleteValue,
        bind: bindBulkModalDelete
    } = useModal(false);

    const toast = useRef()
    const autoCloseRef = useRef(null)
    const { branchData } = useContext(BranchItemsContext);
    const [isLoading, setIsLoading] = useState(true)
    const [seletedRow, setSelectedRow] = useState([])
    const [competencyMatrixList, setCompetencyMatrixList] = useState([])
    const [seletedId, setSelectedId] = useState(null)
    const [checkedRows, setCheckedRows] = useState([])
    const [checkedValue, setCheckedValue] = useState(true)
    const [bulkToggle, setBulkToggle] = useState(false)
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isdisabledDeleteButton, setIsDisabledDeleteButton] = useState(false)
    const [isdisabledDeleteButtonBulk, setIsDisabledDeleteButtonBulk] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false);


    // Get list of competency matrix 
    const getCompetencyMatrixListData = async () => {
        const pageToSearch = getPageToSearchParam(
            location.search,
            setCurrentPage,
        )
        try {
            const res = await getCompentencyMatrixList({ ...values, ...pageToSearch, ...branchData })
            setCompetencyMatrixList(res?.results)
            setPages(Math.ceil(res.count / values?.limitperpage));
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        values.limitperpage = PAGE_SIZE_RENDER;
        setValues({ ...values })
    }, [PAGE_SIZE_RENDER])

    useEffect(() => {
        getCompetencyMatrixListData()
    }, [values?.limitperpage, location.search, branchData])

    //Find the branch name from choices
    const findBranchName = (id) => {
        let branch = choices?.branch?.find(item => item.id === id)
        return branch
    }
    //Find the competency name from choices
    const findCompetencyName = (id) => {
        let competency = choices.competency_name?.find(item => item.id === id)
        return competency
    }


    // Open add matrix modal
    const handleOpenModal = () => {
        setModalValue(true)
        setTitle('Add Competency Matrix')
        setSelectedId(null)
        values.competencyMatrix = {}
        setValues({ ...values })
    }

    // Open update matrix modal & perfill values
    const handleUpdateClick = (item) => {
        setUpdateTitle("Update Competency Matrix")
        setSelectedId(item.id)
        values.competencyMatrix.branch = item.branch
        values.competencyMatrix.competency = item.competency
        setValues({ ...values })
        setUpdateModalValue(true)
    }


    //Open delete pop-up
    const handleDeleteDesicion = (id) => {
        setSelectedId(id)
        setModalDeleteValue(true)
    }

    // Delete single matrix
    const deleteCompetencyMatrixRow = async () => {
        if (isdisabledDeleteButton) return;
        setIsDisabledDeleteButton(true)
        try {
            const res = await deleteCompentencyMatrix(seletedId)
            if (res.length) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Competency matrix deleted`,
                })
                setIsDisabledDeleteButton(false)
                setModalDeleteValue(false)
                getCompetencyMatrixListData()
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `Somthing went wrong!`,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Bulk delete  matrix function
    const handleBulkDeleteCompetencyMatrix = async () => {
        if (isdisabledDeleteButtonBulk) return;
        const body = {
            metrics_competency_id_list: checkedRows
        }
        setIsDisabledDeleteButtonBulk(true)
        if (checkedRows?.length) {
            await bulkDeleteCompetencySkill(body).
                then(res => {
                    if (res.status) {
                        toast.current.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: `${res.message}`,
                        })
                        setbulkModalDeleteValue(false)
                        getCompetencyMatrixListData()
                        setCheckedRows([])
                        setBulkToggle(false)
                        setIsDisabledDeleteButtonBulk(false)
                    } else {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Failed',
                            detail: `Somthing went wrong!`,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }


    const handleAllCheck = () => {
        let checkAll = true;
        const existingData = (id) => {
            return checkedRows.some(function (el) {
                return el === id;
            });
        }
        if (checkedRows && checkedRows.length > 0) {
            competencyMatrixList.map((item) => {
                if (!existingData(item.id)) {
                    checkAll = false
                }
            })
        } else {
            checkAll = false;
        }
        return checkAll;
    }

    const handleAllSelectChange = (e) => {
        setCheckedValue(!checkedValue)
        if (!checkedRows) {
            checkedRows = [];
        }
        if (e.target.checked) {
            competencyMatrixList.map((item) => {
                checkedRows.push(item.id)
            })
        } else {
            competencyMatrixList.map((el) => {
                checkedRows.map((item, index) => {
                    if (item === el.id) {
                        checkedRows.splice(index, 1);
                    }
                })
            })
        }
        setCheckedRows([...checkedRows])
    }

    const handleSelectChange = (e, item) => {
        if (e.target.checked) {
            setCheckedRows([...checkedRows, item.id])
        } else {
            let index = checkedRows.indexOf(item.id)
            if (index != -1) {
                let tempCheckedRows = [...checkedRows]
                tempCheckedRows.splice(index, 1)
                setCheckedRows([...tempCheckedRows])
            }
        }
    }

    //Add new compentency matrix function
    const HandleAddCompetencyMatrix = async () => {
        setLoadingSave(true)
        if (values?.competencyMatrix?.branch?.length && values?.competencyMatrix?.competency?.length) {
            const body = {
                branch: values.competencyMatrix.branch,
                competency: values.competencyMatrix.competency,
            };
            try {
                const res = await addNewCompetencyMatrix(body)
                if (res.status) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${res.message}`,
                    })
                    setModalValue(false)

                    getCompetencyMatrixListData()
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `Somthing went wrong!`,
                    })
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please fill the mandatory fields`,
            })
        }
        setLoadingSave(false)
    }

    //Update Competency Matrix function
    const handleUpdateCompetencyMatrix = async () => {
        setLoadingSave(true)
        if (values.competencyMatrix.branch && values.competencyMatrix.competency) {
            const body = {
                branch: values.competencyMatrix.branch,
                competency: values.competencyMatrix.competency,
            };
            try {
                const res = await updateCompetencyMatrix(seletedId, body)
                if (res.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${`Competency matrix updated`}`,
                    })
                    getCompetencyMatrixListData()

                    setUpdateModalValue(false)
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `Somthing went wrong!`,
                    })
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please fill the mandatory fields`,
            })
        }
        setLoadingSave(false)
    }

    //Limit per page dropdown
    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const autoClose = () => {
        setBulkToggle(false)
    }
    useOutsideClickClosePopup(autoCloseRef, autoClose);

    console.log(values, location, "values001")
    return (
        <div className="pl-3 pr-3 pb-3 pt-0 ">
            <Toast ref={toast} />
            {createPermission && (
                <div className="d-flex justify-content-center" >
                    <FloatingMobileButton onClick={handleOpenModal}>
                        <FaPlus />
                        <label>NEW COMPETENCY MATRIX</label>
                    </FloatingMobileButton>
                </div>
            )}
            <Header style={{ paddingBottom: "20px" }}>
                <FlexGrid3Container className={'w-100'}>
                    <FlexGrid3Item className="pr-1">
                        {checkedRows?.length > 0 &&
                            (<div ref={autoCloseRef} class={`dropdown mr-2${bulkToggle ? 'show' : ""}`}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    class="btn dropdown-toggle"
                                    type="button"
                                    aria-haspopup={bulkToggle ? "true" : "false"}
                                    onClick={() => setBulkToggle(!bulkToggle)}
                                >
                                    <span className={'mr-2'} >Action</span>
                                </PrimaryButtonForm>
                                <div class={`dropdown-menu ${bulkToggle ? 'show' : ""}`} aria-labelledby="dropdownMenuButton">
                                    <button
                                        class="dropdown-item"
                                        onClick={() => { setbulkModalDeleteValue(true) }}>
                                        Bulk Delete
                                    </button>

                                </div>
                            </div>

                            )}
                    </FlexGrid3Item>
                </FlexGrid3Container>

            </Header>
            {isLoading ? (
                <div style={{ marginTop: "300px" }} >
                    <Spinner />
                </div>) :
                (<div className='competency-matrix-list'>
                    {competencyMatrixList?.length ?
                        (<React.Fragment>
                            <Table
                                headerColumns={headerColumns}
                                handleAllCheck={handleAllCheck}
                                handleAllSelectChange={handleAllSelectChange}
                                headerPadding={false}
                                noNeed={false}
                            >
                                {competencyMatrixList.map((item, index) => {
                                    return (
                                        <Row key={index} bgColor style={{ margin: "5px 0" }}>
                                            <ColsGrouper className="d-flex">
                                                <div className="p-left-2">
                                                    <Col
                                                        max_width={EX_SMALL_COL4}
                                                        Shrink={false}
                                                        NoFlexGrow={true}
                                                        reducePadding
                                                        Capitalize
                                                        Center>
                                                        {deletePermission && (
                                                            <input
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                checked={checkedRows.includes(item.id)}
                                                                id="flexCheckDefault"
                                                                onChange={event => { handleSelectChange(event, item, index) }}
                                                            />
                                                        )}
                                                    </Col>
                                                </div>
                                                <Col
                                                    max_width={EX_VERY_LARGE_COL}
                                                    Center
                                                    onClick={() => {

                                                    }}
                                                    // className="sm-hidden"
                                                    overlap={true}>
                                                    {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                                    {findBranchName(item.branch)?.name}
                                                </Col>
                                                <Col
                                                    max_width={EX_VERY_LARGE_COL}
                                                    Center
                                                    onClick={() => {

                                                    }}
                                                    // className="sm-hidden"
                                                    overlap={true}>
                                                    {/* {getTriageStatusName(alertsData?.alert_status)} */}
                                                    {findCompetencyName(item.competency)?.name}
                                                </Col>
                                                <Col
                                                    style={{ maxWidth: deletePermission ? "6.5%" : "" }}
                                                    className="sm-hidden"
                                                    Center
                                                    Icon
                                                    Purple>
                                                    {(
                                                        <IconWrapper
                                                            onClick={() => handleUpdateClick(item)}>
                                                            <MdEdit />
                                                        </IconWrapper>)}
                                                </Col>
                                            </ColsGrouper>


                                            <Col
                                                className="sm-hidden"
                                                Center
                                                Shrink
                                                // Icon
                                                NoFlexGrow
                                            >
                                                {deletePermission && (
                                                    <IconWrapper
                                                        onClick={() => {
                                                            handleDeleteDesicion(item.id)
                                                        }}>
                                                        <RiDeleteBinLine />
                                                    </IconWrapper>
                                                )}

                                            </Col>

                                        </Row>
                                    )
                                })
                                }

                            </Table>
                            <div className="mt-3">
                                <Pagination
                                    totalPages={pages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    values={values}
                                    dropDownHandleSelect={dropDownHandleSelectPagination}
                                    number={PAGE_SIZE_RENDER}
                                    dataLength={competencyMatrixList?.length}
                                />
                            </div>
                        </React.Fragment>
                        ) :
                        <div className='w-100 d-flex justify-content-center'>
                            <h4 style={{ fontSize: "1rem" }}>No competency matrix found!</h4>
                        </div>}
                </div>

                )
            }

            {modalValue &&
                <AddCompetencyMatrixModal
                    fullBind={fullBind}
                    choices={choices}
                    values={values}
                    setValues={setValues}
                    useInput={useInput}
                    dropDownHandleSelect={dropDownHandleSelect}
                    setModalValue={setModalValue}
                    getCompetencyMatrixListData={getCompetencyMatrixListData}
                    handleSubmit={HandleAddCompetencyMatrix}
                    readOnly={!createPermission}
                    loadingSave={loadingSave}
                />}

            {updateModalValue &&
                <UpdateModal
                    fullBind={updateFullBind}
                    choices={choices}
                    values={values}
                    setValues={setValues}
                    useInput={useInput}
                    updateModalValue={updateModalValue}
                    seletedId={seletedId}
                    setUpdateModalValue={setUpdateModalValue}
                    getCompetencyMatrixListData={getCompetencyMatrixListData}
                    handleUpdate={handleUpdateCompetencyMatrix}
                    readOnly={!updatePermission}
                    loadingSave={loadingSave}
                />}
            <ModalDecision
                type="delete"
                title="Warning"
                isdisabled={isdisabledDeleteButton}
                body={
                    'Are you sure you want to delete this Competency Matrix?'
                }
                onOk={() => deleteCompetencyMatrixRow()}
                onCancel={() => { }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />

            <ModalDecision
                type="delete"
                title="Warning"
                isdisabled={isdisabledDeleteButtonBulk}
                body={
                    'Are you sure you want to delete multiple Competency Matrix?'
                }
                onOk={() => handleBulkDeleteCompetencyMatrix()}
                onCancel={() => { }}
                okTitle={'DELETE'}
                {...bindBulkModalDelete}
            />
        </div>

    )

}


export default CompetencyMatrix