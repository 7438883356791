import React, {
  useState,
  useEffect,
  useContext,
  useModal,
  useRef,
  Fragment,
} from 'react';

import { AppContext, BranchItemsContext } from 'context';
import { getEmployeeBranchAccess, setAbsenceOverview } from 'utils/api/EmployeeApi';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { CloseButtonWrapper } from 'components/DashboardComponents/ModalDynamic/styles';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import AddNewAbsence from '../AddNewAbsence';
import { width } from '@mui/system';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';

const AddAbsenceModal = ({
  fullBind,
  idSelected,
  setTitle,
  editDatas,
  setDataCheck,
  setModalValue,
  selectedClientId,
  setDataChecking,
  addData,
  data,
  loadData,
  setAddModalValue,
  toast,
  getCalendarType,
}) => {
  const ABSENCE_OVERVIEW = 'absence_overview';
  // const toast = useRef();
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
    setFormErrors,
  } = useForm({ [ABSENCE_OVERVIEW]: [{}] });
  const { contextChoices } = useContext(AppContext);
  const [ansenceIndex, setAbsenceInsex] = useState();
  const [employees, setEmployees] = useState([]);
  const edit_absence = 'Edit Absence Overview';
  const new_absence = 'Add Absence Overview';
  const [loadingModal, setLoadingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonCheck, setButtonCheck] = useState(false)
  const [branchData, setBranchData] = useState([])
  useEffect(() => {
    setEmployees(
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(item => {
        return {
          id: item.id,
          name: item.full_name,
        };
      })
    );
    setTitle('Add Absence Overview');
  }, [contextChoices]);
  useEffect(() => {
    if (values.absence_overview?.length === 0) {
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review'
      );
      setButtonCheck(true)
    } else if (values.absence_overview?.length === 1) {
      setErrorMessage();
      setButtonCheck(false)
    }
  }, [
    values.absence_overview?.length === 0,
    values.absence_overview?.length === 1
  ]);
  useEffect(() => {
    if (
      values?.employee &&
      values?.absence_overview &&
      values?.absence_overview[0]?.absence_type &&
      values?.absence_overview[0]?.time_unit &&
      values?.absence_overview[0]?.allowance_year
    ) {
      setErrorMessage('');

      setCheckRequires(false);
      setFormErrors([]);
    }
  }, [values]);

  const addNewAvoid = () => {
    values[ABSENCE_OVERVIEW].push({});
    setValues({ ...values });
  };
  const dropDownHandleSelect1  = async (item) => {
    if(item.employee){
      const res = await getCalendarType(item.employee)
      values.absence_calendar_year = res;
      setValues({ ...values, ...item });
    }
    setValues({ ...values, ...item });
    await getEmployeeBranches(item.employee)
  };

  const HandleSubmit = async () => {
    if (errors.length > 0) {
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review'
      );
      setCheckRequires(true);
      return;
    } 

      const body = {
        employee: values.employee,
        calendarType: values.absence_calendar_year,
        branch: values.branch,
        absence: values.absence_overview.map(item => {
          return {
            absenceType: item.absence_type,
            // absenceReason: item.absence_reason,
            absencePaid: item.absence_paid,
            timeUnit: item.time_unit,
            absenceAllowance: item.absence_allowance,
            allowance_date_from: item.allowance_year.slice(0, 4),
            // allowanceDate: item.allowanceDate,
            // absencePlanned: item.absence_planneds,
          };
        }),
      };
      try {
        let response = await setAbsenceOverview(body, idSelected);
        if (response.status === true) {
          loadData();
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${response.message}`,
          });
          // setTimeout(() => {
          setAddModalValue(false);
          // }, 1000);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${response.message}`,
          });
        }
      } catch (error) {
        setErrorMessage(
          error.message
        );
        setCheckRequires(true);
      }

      // setTimeout(() => {
      //   setAddModalValue(false);
      // }, 1000);
    
  };

  const getEmployeeBranches = async (id) => {
    if(!id) return;
    try {
        const res = await getEmployeeBranchAccess(id)
        setBranchData(contextChoices?.branch?.filter(el => res?.employee_access_branches.includes(el.id)))
    } catch (error) {

    }
}
  
  console.log(values, "values")
  return (
    <ModalContainer max_width={'60%'}>
      {/* <Toast ref={toast} position="top-right" /> */}

      <ModalNoInfo
        {...fullBind}
        max_width={'50%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
          <div className='ml-2 mr-2 mb-3'>
        <div
          style={{
            // marginLeft: '10px',
            // marginRight: '10px',
            marginBottom: '1.5rem',
          }}>
          <div className="d-flex">

            <div className="w-100 pl-2 pr-2 pl-2">
              <FlexGrid3Item className="w-100">
                <DropdownSearchable
                  required
                  placeHolder={'Employee'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={employees ? employees : []}
                  {...useInput(`employee`, 'isRequired')}
                  selectedOption={values.employee}
                  onChange={value =>
                    dropDownHandleSelect1(
                      {
                        employee: value ? value : null,
                      },
                      'employee'
                    )
                  }
                />
              </FlexGrid3Item>
              </div>
              <div className="w-100 pl-1 pr-2">
              <FlexGrid3Item className="w-100">
                <DropdownSearchable
                  placeHolder={'Absence Calendar Year'}
                  // disabled
                  // options={[
                  //   {
                  //     id: "Joining Calendar",
                  //     name: "Joining Calendar"
                  //   },
                  //   {
                  //     id: "Financial Calendar",
                  //     name: "Financial Year"
                  //   }
                  // ]}
                  required
                  options={contextChoices && contextChoices.calendar_type &&  contextChoices.calendar_type || []}
                  {...useInput(`absence_calendar_year`, 'isRequired')}
                  selectedOption={
                    values
                      ? values.absence_calendar_year
                        ? values.absence_calendar_year
                        : null
                      : null
                  }
                  onChange={(value) =>
                    dropDownHandleSelect1(
                      {
                        absence_calendar_year: value ? value : null,
                      },
                      'absence_calendar_year'
                    )
                  }
                />
              </FlexGrid3Item>
              </div>
          </div>
            <div className="d-flex mt-2">
              <div className="w-100 pl-2 pr-2 pl-2">
                <FlexGrid3Item className="w-100">
                  <DropdownSearchable
                    required
                    placeHolder={'Branch'}
                    {...useInput(`branch`, 'isRequired')}
                    options={branchData ? branchData : []}
                    selectedOption={values.branch}
                    onChange={value =>
                      dropDownHandleSelect1(
                        {
                          branch: value ? value : null,
                        },
                        'branch'
                      )
                    }
                  />
                </FlexGrid3Item>
              </div>
            </div>
        
        </div>
        {values.absence_overview?.length >= 0 &&
          values.absence_overview.map((item, index) => {
            return (
              <div>
                <AddNewAbsence
                  values={values}
                  setValues={setValues}
                  index={index}
                  addNewAvoid={addNewAvoid}
                  useInput={useInput}
                  namesection={'absence_overview'}
                  setAbsenceInsex={setAbsenceInsex}
                  setCheckRequires={setCheckRequires}
                />
              </div>
            );
          })}
        <div style={{ display: 'flex', justifyContent: 'left' }}>
          <PrimaryButtonForm
            className={'ml-3 mt-2 mb-2'}
            bgcolor="BLUE"
            style={{ width: '5px' }}
            onClick={() => addNewAvoid()}>
            <span>{<BsFillPlusSquareFill />}New Absence Type</span>
          </PrimaryButtonForm>
        </div>

        <div
          className={'ml-0 ml-md-3 mr-2'}
          style={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
          <div
            className={'mb-3'}
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginRight: '4px',
            }}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <PrimaryButtonForm
            disabled={buttonCheck}
            minWidth="6rem"
            onClick={HandleSubmit}
            style={{ marginBottom: '5px' }}>
            Save
          </PrimaryButtonForm>
        </div>
        </div>
      </ModalNoInfo>
    </ModalContainer>
  );
};

export default AddAbsenceModal;
