import React, { useEffect } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import RegularEmployeePost from "./RegularEmployeePost"
import RegularEmployeeEdit from "./RegularEmployeeEdit"
import { KeyPress } from 'utils/constants/regularExpressions';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { BsFillPlusSquareFill } from "react-icons/bs"
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
const ClientSpecific = ({
  useInput,
  values,
  setValues,
  choices,
  dropDownHandleRegVisit,
  dropDownHandleRegEmpDet,
  clients,
  employees,
  visit,
  dayOptions,
  dateError,
  dateErrorMessage,
  deleteEmployeeDetails,
  addNewEmployeeDet,
  switchEndValueInput,
  calculateEndValueProps,
  employeeRequired,
  setSingleRun,
  idSelected
}) => {

  return (
    <>
      <div className={'mb-3'}>
        <DropdownSearchable
          required
          {...useInput(
            `client`,
            'isRequired'
          )}
          placeHolder={'Client Name'}
          options={clients}
          selectedOption={values.client ? values.client : null}
          onChange={value =>
            dropDownHandleRegVisit({ client: value ? value : null }, 'client')
          }
        />
      </div>
      <div className={'d-flex pr-1'}>
        <DropdownSearchable
          required
          {...useInput(
            `visit`,
            'isRequired'
          )}
          placeHolder={'Select Visit'}
          width={'100%'}
          options={visit}
          selectedOption={
            values ? values.visit : null
          }
          onChange={value =>
            dropDownHandleRegVisit(
              { visit: value ? value : null },
              'visit'
            )
          }
        />
        <div className="w-100 ml-1">
          <DropdownSearchable
            placeHolder={'Run Name'}
            options={choices.run_view}
            selectedOption={values.run_name_view}
            onChange={value =>
              dropDownHandleRegVisit({ run_name_view: value ? value : null },
                'run_name_view'

              )
            }
          />
        </div>

        {/* </FlexGrid3Item> */}
      </div>
      {!values.id && values.employee_day_detail && values.employee_day_detail.map((visit_det, index) => {
        return (
          <RegularEmployeePost
            values={values}
            setValues={setValues}
            index={index}
            dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
            employeeRequired={employeeRequired}
            employees={employees}
            useInput={useInput}
            dayOptions={dayOptions}
            deleteEmployeeDetails={deleteEmployeeDetails}
            visitDetails={visit_det}
            setSingleRun={setSingleRun}
            idSelected={idSelected}
          />
        )
      })
      }
      {values.id && values.employee_day_detail && values.employee_day_detail.map((visit_det, index) => {
        return (
          <RegularEmployeeEdit
            values={values}
            setValues={setValues}
            index={index}
            dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
            employeeRequired={employeeRequired}
            employees={employees}
            dayOptions={dayOptions}
            useInput={useInput}
            deleteEmployeeDetails={deleteEmployeeDetails}
            visitDetails={visit_det}
            setSingleRun={setSingleRun}
            idSelected={idSelected}
          />


        )
      })
      }
      <PrimaryButtonForm
        bgcolor="BLUE"
        minWidth="0.5rem"
        className={'mb-2'}
        onClick={addNewEmployeeDet}
      >
        <span>{<BsFillPlusSquareFill />}</span>
      </PrimaryButtonForm>
      <FlexGrid3Container>
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
          <DateSelector
            //   disabled={readOnly}
            isRequired
            data-error={dateError}
            errorMessage={dateErrorMessage}
            labelTop={'Service start date'}
            {...useInput(`start_date`, 'isRequired')}
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
          <DateSelector
            //   disabled={readOnly}
            data-error={dateError}
            errorMessage={dateErrorMessage}
            labelTop={'Service end date'}
            {...useInput(`end_date`)}
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
          <DropdownSearchable
            required
            {...useInput(`every`, 'isRequired')}
            placeHolder={'Repeat Every'}
            options={[
              { id: EVERY_DAY, name: 'Day' },
              { id: EVERY_WEEK, name: 'Week' },
              { id: EVERY_MONTH, name: 'Month' },
              { id: EVERY_YEAR, name: 'Year' },
            ]}
            selectedOption={
              values
                ? values.every
                : null
            }
            onChange={value =>
              dropDownHandleRegVisit(
                { every: value ? value : null },
                'every'
              )
            }
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
          <InputLabelTop
            //   disabled={readOnly}
            label={'Repeat No.'}
            id={`repeat`}
            onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
            {...useInput(`repeat`, 'isInt')}
            maxlength={5}
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
          <DropdownSearchable
            required
            {...useInput(`end_type`, 'isRequired')}
            // data_error={errors.indexOf(`end_type`)}
            //   disabled={readOnly}
            placeHolder={'End'}
            options={[
              { id: END_TYPE_ON, name: 'On' },
              { id: END_TYPE_AFTER, name: 'After' },
              { id: END_TYPE_NEVER, name: 'Never' },
            ]}
            selectedOption={
              values
                ? values.end_type
                : null
            }
            onChange={value =>
              dropDownHandleRegVisit(
                { end_type: value ? value : null },
                'end_type'
              )
            }
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pl-1 mt-0 md-2 pr-0 pr-md-1">
          {switchEndValueInput({
            ...useInput(
              `end_value`,
              calculateEndValueProps(
                values.end_type
              )
            ),
          })}
        </FlexGrid3Item>
      </FlexGrid3Container>
      <FlexGrid3Container className='mt-3'>
        <FlexGrid3Item className="pr-0 pl-0 pt-1 ">
          <ToggleButton
            //   disabled={readOnly}
            label={'Service suspended'}
            useInput={useInput(`is_suspended`)}
            id={`is_suspended`}
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
          <DateSelector
            //   disabled={readOnly}
            labelTop={'Service suspended from date'}
            {...useInput(`suspended_from_date`)}
          />
        </FlexGrid3Item>
        <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0 pt-1 pt-md-0">
          <DateSelector
            //   disabled={readOnly}
            labelTop={'Service suspended to date'}
            {...useInput(`suspended_to_date`)}
          />
        </FlexGrid3Item>
      </FlexGrid3Container>
    </>
  );
}

export default ClientSpecific;
