import React, {Fragment} from 'react';
import Modal from 'react-bootstrap4-modal';
import {FaTimes} from 'react-icons/fa';
import './decision.css'

import {CancelButton, PrimaryButtonModal, Body, SubBody, WarningText} from './styles';

function ModalDecision({
  type,
  max_width,
  title,
  body,
  waningText = "",
  subBody,
  showModal,
  onOk,
  onCancel,
  okTitle,
  hideOnCancel,
  hideOnOk,
  onCancelTitle,
  isdisabled
}) {
  const iconType = () => {
    switch (type) {
      case 'delete':
        return <FaTimes />;
      default:
        return <></>;
    }
  };

  return (
    <Modal visible={showModal}     >
      <div className="modal-header border-0">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body text-center">
        <Body>{body}</Body>
        {waningText ? <WarningText>{waningText}</WarningText> : null}
        <SubBody>{subBody}</SubBody>
      </div>
      <div className="d-flex justify-content-center modal-footer border-0">
      {hideOnCancel ? (
          <Fragment />
        ) : (
        <CancelButton
          type="button"
          className="btn btn-secondary"
          onClick={() => onCancel()}>
          {onCancelTitle || 'CANCEL'}
        </CancelButton>
        )}
        {hideOnOk ? (
          <Fragment />
        ) : (
        <PrimaryButtonModal disabled={isdisabled} typeButton={type} onClick={onOk}>
          {iconType()}
          {okTitle || 'Ok'}
        </PrimaryButtonModal>
        )}
      </div>
    </Modal>
  );
}

export default ModalDecision;
