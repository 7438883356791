import {
    LARGE_COL,
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
      label: 'Regular Shift Name',
      name: 'regular_shift_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'BRANCH',
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'START DATE',
      name: 'start_dates',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'END DATE',
      name: 'end_dates',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
        label: 'Repeats Every',
        name: 'repeat_every',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Repeat Numbers',
        name: 'repeat_every',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    {
      label: 'START TIME - END TIME',
      name: 'times',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    // {
    //   label: 'SHIFT DAYS',
    //   name: 'shift_days',
    //   status: 0,
    //   className: 'grow-mobile visits-custom sm-hidden',
    //   max_width: LARGE_COL,
    // },
  ];
  