
import styled from 'styled-components';
import {PURPLE, GRAY_300} from 'shared/styles/constants/colors';
import {SM, MD, XXL} from 'shared/styles/constants/resolutions';


export const ModaClientTopContainer = styled.div`
postion:fixed !important;
margin-left:-13px;
background-color:'#F0F0F7' !important;
width:'86%',
padding-bottom:10px;
padding-top:10px;
z-index: 111;
display:flex;
justify-content:center;
margin-top:-8px;

@media (max-width: 535px) {
    margin-top: 40px;
    z-index: 111;
  margin-left:0px !important;
  width:'100%';
}
@media (width: 540px) {
    margin-top: -4px
    z-index: 111;
  margin-left:0px !important;
  width:'100%';
}
@media (max-width: 456px) {
    margin-top: 40px;
    z-index: 111;
    width:'100%';
    margin-left:0px !important;
  }

@media screen and (max-width: 920px) and (min-width: 820px) {
    margin-top: -6px;
  margin-left:0px !important;
  width:'100%';
  
}
`;



export const ModalClientTabsContainer = styled.div`
postion:fixed !important;
translate: -2px 0;
background-color:'#F0F0F7' !important;
border-bottom: solid 1px rgba(17, 17, 26, 0.1);
// width:'100%';
// max-height: 10px;
padding:10px 10px 0px 10px;
z-index: 111;
display:flex;
justify-content:around;
text-align:center;

@media (max-width: 456px) {
  overflow-x: scroll;
  font-size: 15px;
  margin-top: 97px;

}

@media (max-width: 540px) {
    overflow-x: scroll;
  margin-left:0px !important;
  width:'100%';
}

@media screen and (max-width: 920px) and (min-width: 820px) {
    overflow-x: scroll;
    margin-top: 30px;
  margin-left:0px !important;
  width:'100%';
  
}

`;