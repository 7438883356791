import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import React from 'react'
import { EmploymentTypesContainer, Title } from '../styles'
import './style.css'
import { useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { useEffect } from 'react';
import { employee_hr_task_matrix } from 'utils/choiceConstant';
import { getActiveEmployee, getEmployeeHrTaskMatrix, getTeamHrTaskById, setHrTask } from 'utils/api/EmployeeApi';
import { useState } from 'react';
import moment from 'moment';
import { MATRIX_GREEN, MATRIX_RED, MATRIX_YELLOW, PURPLE } from 'shared/styles/constants/colors';
import { DropdownTypesContainer, FiltersContainer, FiltersLeftGroup, LoadingContainer, PublishButton } from 'shared/styles/constants/tagsStyles';
import { PrimaryButton } from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CHECK, CROSS, DOWNLOAD, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useContextMenu } from 'hooks/CustomContext';
import { MdEdit } from 'react-icons/md';
import UpdateModal from 'components/DashboardComponents/Team/EmployeeHRTasks/UpdateModal';
import { useModal } from 'hooks/ModalHook';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import { EMPLOYEE_HR_TASK_MATRIX, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { getEmployee } from 'utils/localStorage/token';
import Spinner from 'components/SharedComponents/Spinner';
function HRTaskMatrix() {
    const toast = useRef(null)
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle: setTitle,
        fullBind: fullBind,
    } = useModal(false);

    const {
        contextChoices: choices,
        contextPermissions,
        contextSubscription,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        handleGetChoices
    } = useContext(AppContext);
    let currentDate = moment().format('YYYY-MM-DD')

    const userEmpoloyee = getEmployee();
    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ date: currentDate });
    const { branchData } = useContext(BranchItemsContext);
    const [hrTaskMatrix, setHrTaskMatrix] = useState([])
    const [loading, setLoading] = useState(true)
    const [employeeFilter, setEmployeeFilter] = useState([]);
    const [hrTaskFilter, setTaskFilter] = useState([]);
    const [filterChoiseHrTask, setFilterChoiseHrTask] = useState([])
    const [filterChoiseEmployee, setFilterChoiseEmployee] = useState([])
    const { xPos, yPos, showMenu, seletedId, setSeletedId } = useContextMenu();
    const [errorMessage, setErrorMessage] = useState("")
    const [showError, setShowError] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [selfReadPermission, setSelfReadPermission] = useState(undefined)
    const [allEmployees, setAllEmployees] = useState([])
    const [rows, setRows] = useState([]);
    const [counts, setCounts] = useState({});
    let exportDate = moment().format('YYYY-MM-DD') + " " + moment().format('HH:mm:ss')


    useEffect(() => {
        const rolesPermissions = contextPermissions;
        let rolePermissions = {};

        if (rolesPermissions?.length > 0) {
            rolesPermissions.forEach(item => {
                if (item.section !== null) {
                    // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_READ_PERMISSION
                    ] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_READ_PERMISSION
                    ] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_CREATE_PERMISSION
                    ] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_CREATE_PERMISSION
                    ] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_DELETE_PERMISSION
                    ] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_UPDATE_PERMISSION
                    ] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_UPDATE_PERMISSION
                    ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_DELETE_PERMISSION
                    ] = item?.[SELF_WEB_DELETE_PERMISSION];
                }
            })
        }
        const roles = contextSubSectionPermissions;
        if (roles.length > 0) {
            roles.forEach(
                item => {
                    if (item.section !== null) {
                        rolePermissions[item.section.name] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_READ_PERMISSION
                        ] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_READ_PERMISSION
                        ] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_CREATE_PERMISSION
                        ] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_CREATE_PERMISSION
                        ] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_DELETE_PERMISSION
                        ] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_UPDATE_PERMISSION
                        ] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_UPDATE_PERMISSION
                        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_DELETE_PERMISSION
                        ] = item?.[SELF_WEB_DELETE_PERMISSION];
                    }
                }
            );
        }
        if (rolePermissions[EMPLOYEE_HR_TASK_MATRIX] !== undefined) {
            let self = (rolePermissions[EMPLOYEE_HR_TASK_MATRIX]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION])
            setSelfReadPermission(self);
        }


    }, [choices, contextIsSuperAdmin, contextIsAdmin, contextSubscription, contextPermissions, allEmployees])



    useEffect(() => {
        handleGetChoices(employee_hr_task_matrix)
    }, [])

    // Employee hr Matrix 
    const getHrTaskMatrixData = async () => {
        const [hrTask, activeEmp] = await Promise.all([
            getEmployeeHrTaskMatrix({ ...branchData, ...values }),
            getActiveEmployee({ ...values })
        ])
        if (hrTask.status && activeEmp.status) {
            setHrTaskMatrix(hrTask.data)
            setAllEmployees(activeEmp?.data)
        }
    };

    const getTasks = () => {
        let date_from = values?.date_from
        let date_to = values?.date_to
        if (!date_from && date_to) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please fill the date from before fill date to`,
            });
            return;
        }
        if (date_from && date_to) {
            if (new Date(date_to) >= new Date(date_from)) {
                getHrTaskMatrixData()
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `Date form should be earlier than date to`,
                });
                values.date_to = '';
                setValues({ ...values });
            }
        } else {
            getHrTaskMatrixData()
        }

    }

    useEffect(() => {
        getTasks()
    }, [branchData, choices])

    useEffect(() => {
        getUpdatedHrTaskList(hrTaskMatrix)
    }, [hrTaskMatrix, allEmployees, selfReadPermission])

    const getUpdatedHrTaskList = (hrtask) => {
        if (values?.hr_task && values?.hr_task?.length > 0) {
            let result = choices?.hr_task?.filter((element) => {
                return values.hr_task.includes(element.id)
            });
            setFilterChoiseHrTask(result || [])
        } else {
            let result = choices?.hr_task?.filter((element) => {
                return hrtask?.some((item) => (item?.id === element?.id) && (item?.employee_hr_task_data?.length > 0));
            });
            setFilterChoiseHrTask(result || [])
        }

        if (values.employees && values.employees.length > 0) {
            let result = allEmployees?.filter((element) => {
                return values.employees.includes(element.id)
            });
            setFilterChoiseEmployee(result || [])
        } else {
            setFilterChoiseEmployee(allEmployees)
        }

        if (selfReadPermission !== undefined) {
            if (selfReadPermission) {
                let filtedEmp = allEmployees?.filter(el => el.id === userEmpoloyee)
                setFilterChoiseEmployee(filtedEmp)
                setLoading(false);
            } else {
                setFilterChoiseEmployee(allEmployees)
                setLoading(false);
            }
        } else {
            setLoading(false);
        }

    }
    const colorCombination = date => {
        const currentDate = moment();
        const oneWeekLater = moment().add(7, 'd');
        const formatedParamDate = moment(date);
        if (
            new Date(formatedParamDate) >= new Date(currentDate) &&
            new Date(formatedParamDate) <= new Date(oneWeekLater)
        ) {
            return { color: MATRIX_YELLOW, name: 'In Progress' };
        } else if (new Date(formatedParamDate) > new Date(oneWeekLater)) {
            return { color: MATRIX_GREEN, name: 'Task Initiated' };
        } else {
            return { color: MATRIX_RED, name: "Action Pending" };
        }
    };

    const statusColor = [
        { name: "Action Pending", color_code: MATRIX_RED },
        { name: "In Progress", color_code: MATRIX_YELLOW },
        { name: "Task Initiated", color_code: MATRIX_GREEN },
    ]
    const dropDownHandleSelect = (item, name) => {
        if (name === "hr_task") {
            values.hr_task = item?.hr_task?.map(item => item.id);
        } else if (name === 'employees') {
            values.employees = item?.employees?.map(item => item.id);
        }
        setValues({ ...values });
    };
    const setEmployee = (item) => {
        setEmployeeFilter([...item])
    }
    const setHrTasks = (item) => {
        setTaskFilter([...item])
    }
    const searchTeamHrTask = async () => {
        const response = await getTeamHrTaskById(seletedId);
        if (response.status) {
            setValues({
                ...values,
                ...response.data
            });

        }

    };

    const handleSearch = () => {
        getTasks()
    }

    const handleRightClck = (e, id) => {
        e.preventDefault()
        setSeletedId(id)
    }
    const handleUpdateHrTask = () => {
        setTitle('Update Employee QA Task')
        setModalValue(!modalValue)
        values.id = seletedId || '';
        setValues({ ...values })
        searchTeamHrTask()
    }

    const handleSubmit = (
        evt,
        saveTrigger = true,
    ) => {
        evt.preventDefault();

        if (loadingSave) {
            return;
        }
        setLoadingSave(true)
        if ((!isValid && errors.length > 0)) {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(InvalidOrEmptyValues);
            return;
        }

        let body = {
            employee: values.employee,
            core_hr_task: values.core_hr_task,
            title: values.title,
            instruction: values.instruction,
            notes: values.notes,
            status: values.status,
            due_date: values.due_date
        }
        if (!values.id) {
            body.start_date = values.start_date
        }
        let value = ''

        setHrTask(body, values.id)
            .then(response => {
                if (response.statusCode === 400) {
                    if (response.message) {
                        value = response.message
                    } else {
                        value = Object.keys(response)[0] + "-" + Object.values(response)[0]
                    }
                    throw value;
                }
                if (response.status === "False") {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `${response?.message?.expense_document[0]}`,
                    });
                } else {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${'Successful'}`,
                    });
                }
                setShowError(false);
                onSuccess(response);
                // loadHrTask()
                setModalValue(false)
                setSeletedId('')
            })
            .catch(onError);
    };
    const onSuccess = async (response) => {
        let expenseId = response.id;
        // if employee id
        setValues({
            ...values,
            id: expenseId,
        });
        getTasks()
        setLoadingSave(false);
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
    }

    const onError = err => {
        // const onError = () => {
        setLoadingSave(false);
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(
            err
            // "Some of the fields have invalid or empty values. Please review."
        );
    };

    const headers = filterChoiseHrTask?.map(h => ({
        id: h.id,
        name: h.title
    }))


    useEffect(() => {
        const formattedRows = filterChoiseEmployee?.map(employee => {
            const formattedRow = {
                id: employee?.id,
                name: employee?.full_name,
            };

            filterChoiseHrTask && filterChoiseHrTask.forEach(hrTask => {
                const hrTaskData = hrTaskMatrix?.find(task => task.id === hrTask.id);
                const employeeHrTaskData = hrTaskData
                    ? hrTaskData.employee_hr_task_data.filter(task => task.employee_id === employee.id)
                    : [];

                const matchedHrTask = employeeHrTaskData.find(task => task.employee_id === employee.id) || null;
                const hrTaskId = matchedHrTask ? matchedHrTask.id : null;

                formattedRow[hrTask?.id] = matchedHrTask;
                formattedRow.task_id = hrTaskId;
            });

            return formattedRow;
        });

        const shouldFilterRows = item => {
            const valuesWithoutIdAndName = Object.values(item).filter(
                val => val !== null && val !== undefined
            );
            return valuesWithoutIdAndName.length > 2;
        };
        if (
            (values.employees && values.employees.length) ||
            (values.hr_task && values.hr_task.length) ||
            values.date_from ||
            values.date_to ||
            branchData?.headerbranch?.length
        ) {
            const filteredRows = formattedRows.filter(shouldFilterRows);
            setRows(filteredRows);
            // setLoading(false);

        } else {
            setRows(formattedRows);
            // setLoading(false);
        }

    }, [filterChoiseEmployee, hrTaskMatrix, allEmployees, branchData])

    const columnWidth = `${headers?.length < 15 ? 100 / headers?.length : ''}%`;
    const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";



    useEffect(() => {
        let counts = {}
        rows.forEach((item) => {
            headers.map(header => {
                let dueDate = item[header.id]?.due_date ? item[header.id]?.due_date : null
                if (dueDate) {
                    counts[`${colorCombination(dueDate)?.name}`] =
                        (counts[colorCombination(dueDate)?.name] || 0) + 1;
                }
            })
        })
        setCounts(counts)
    }, [rows])

    return (
        <div className='hr-task-matrix-container' style={{ height: "89vh" }}>
            <Toast ref={toast} position='top-right' />
            <Title>QA Task Matrix</Title>
            {/* <Header style={{ padding: 10 }}> */}
            <FiltersContainer className='d-flex justify-content-between w-100 mt-2'>
                <div className='d-flex'>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.employee_listing?.map(el => ({ ...el, name: el?.full_name })) || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ employees: items }, 'employees');
                                }}
                                itemsSelected={employeeFilter}
                                setSelectedFilter={setEmployee}
                                placeHolder={'Employee'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}                           
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer style={{ minWidth: "160px" }} className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.hr_task?.map(el => ({ ...el, name: el?.title })) || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ hr_task: items }, 'hr_task');
                                }}
                                itemsSelected={hrTaskFilter}
                                setSelectedFilter={setHrTasks}
                                placeHolder={'QA Task'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}                           
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer style={{ minWidth: "160px" }} className="mr-2">
                            <DropdownTypesContainer className="ml-1 d-flex">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date From"
                                    {...useInput('date_from')}
                                />
                            </DropdownTypesContainer>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <DropdownTypesContainer className="ml-1 d-flex">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date To"
                                    {...useInput('date_to')}
                                />
                            </DropdownTypesContainer>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <PrimaryButton
                                type="button"
                                onClick={handleSearch}
                                style={{ marginLeft: '4px', marginBottom: "2px" }}>
                                <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
                            </PrimaryButton>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                </div>
                {rows.length > 0 && (
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="export-button"
                        table="team-table-to-xls"
                        filename={`${exportDate} team-hr-task-matrix`}
                        sheet="tablexls"
                        buttonText={
                            <PublishButton>
                                {DOWNLOAD}
                            </PublishButton>
                        }
                    />)}
            </FiltersContainer>

            {/* </Header> */}
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5px' }}>
                {statusColor.map((item) => (
                    <>
                        <p className="color-combination d-flex">
                            <div style={{ width: '12px', height: '12px', backgroundColor: `${item.color_code || ''}`, marginRight: '8px', marginBottom: '5px', alignSelf: 'center' }}></div>
                            <label className='count-text'>{item.name}</label>
                            <span className='count-text' style={{ marginLeft: '4px' }}>{`(${counts[item.name] || 0})`}</span>
                        </p>

                    </>
                ))
                }
            </div>
            <> {loading ? (
                <LoadingContainer maxHeight={null}>
                    <Spinner />
                </LoadingContainer>
            ) : (
                <div class="hr-task-matrix-table">
                    {rows.length ? (
                        <table id="team-table-to-xls">
                            <tr className="custom-border">
                                <th
                                    className="body-text"
                                    style={{
                                        textAlign: headers?.length < 1 ? "left" : "",
                                        padding: headers?.length < 1 ? "0" : ""
                                    }}>
                                    <div
                                        className={headers?.length < 1 ? "title" : ""}
                                        style={{ maxWidth: headers?.length < 1 ? "150px" : "" }}>
                                        Employees
                                    </div>
                                </th>
                                {headers?.map(header => {
                                    return (
                                        <th className="body-text" style={{ maxWidth: columnWidth }}>
                                            {capitalizeText(header?.name)}
                                        </th>
                                    );
                                })}
                            </tr>
                            {rows?.map((item, i) => {
                                return (
                                    <tr className="custom-border">
                                        <td
                                            className="header-text hr-task-matrix-border"
                                            style={{
                                                textAlign: headers.length < 1 ? "left" : "",
                                                padding: headers.length < 1 ? "0" : ""
                                            }}>
                                            <div className={headers.length < 1 ? "title" : ""} style={{ maxWidth: headers.length < 1 ? "150px" : "" }}>
                                                {capitalizeText(item.name)}
                                            </div>
                                        </td>
                                        {headers?.map(header => {
                                            const date = item[header.id]?.due_date
                                                ? item[header.id]?.due_date
                                                : null;
                                            const id = date ? item[header.id]?.id : null
                                            return (
                                                <td
                                                    onContextMenu={(e) => handleRightClck(e, id)}
                                                    className="header-text  hr-task-matrix-border"
                                                    style={{
                                                        backgroundColor: date ? colorCombination(date).color : "",
                                                        color: date ? 'black' : '',
                                                        cursor: date ? 'pointer' : ""
                                                    }}>
                                                    {date}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </table>
                    ) : (
                        <div className='d-flex w-100 justify-content-center mt-4'>
                            <h4 style={{ fontSize: '1rem' }}>No data found</h4>
                        </div>
                    )
                    }
                </div>)}
            </>

            {modalValue &&
                <UpdateModal
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    contextChoices={choices}
                    fullBind={fullBind}
                    errors={errors}
                    errorMessage={errorMessage}
                    showError={showError}
                    loadingSave={loadingSave}
                    setCheckRequires={setCheckRequires}
                    setErrorMessage={setErrorMessage}
                    setShowError={setShowError}
                    handleSubmit={handleSubmit}
                    setLoadingSave={setLoadingSave}
                />
            }

            {(showMenu && seletedId) && (
                <div
                    style={{
                        top: yPos,
                        left: xPos,
                        width: '100px',
                        border: '2px solid gray',
                    }}
                    onContextMenu={() => {
                        if (seletedId) {
                            setSeletedId(null)
                        }
                    }}
                    class="dropdown-menu dropdown-menu-right show right-click-action-team">
                    <button
                        class="dropdown-item"
                        type="button"
                        onClick={() => handleUpdateHrTask()}
                    >
                        <MdEdit height={55} width={55} color={PURPLE} />
                        <span style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            fontWeight: "450"
                        }}>Update</span>
                    </button>

                </div>
            )
            }
        </div >
    )
}

export default HRTaskMatrix
