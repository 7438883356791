import React, {useMemo} from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import {Container, FloatingLabel, ErrorLabel} from './styles';
import Select from 'react-select';
import {InputLabelTopStyle} from 'shared/styles/inputs';


const EditableDropdownList = ({
  options = [],
  data,
  handleChange,
  optionNumberData,
  selectedOption = null,
  selectedOptionsProps = [],
  onChnageData = () => {},
  required = false,
  placeHolder = '',
  disabled,
  clearable = true,
  isMulti = false,
  errorMessage = '',
  data_error = false,
  typeFilter = false,
  ...props

}) => {
 
    const isRequired = required;
    let haveError = props['data-error'];
    let labelError = <span />;
    // let value = isMulti
    //   ? (selectedOptionsProps && selectedOptionsProps.length)
    //   : selectedOption
    //   ? selectedOption
    //   : 0;
  
    if (props['data-error'] && !isRequired && !data) {
      haveError = false;
    }
  
    if (props['data-error'] && isRequired && !data) {
      labelError = <ErrorLabel>{errorMessage || 'Is required'}</ErrorLabel>;
    }
  

  return (
    <div >
      <InputLabelTopStyle
        no_internal_margin={props.no_internal_margin}
        have_error={haveError}
        type={props.type}
        typeFilter={props.typeFilter}>
        <div style={{ "overflow-x": "hidden", "overflow": "scroll", "width": "100%", "height": "2.375rem", }} >
          <input type="text" onChange={onChnageData} class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            value={data} style={{ "height": "2.375rem", "width": " 100%", "border": " 1px solid #6200f936" }}
          />
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{width:'100%',marginTop:'34px', marginRight:'-12px'}} >
            {options.map((item, index) =>
              <span style={{color:'#212529'}} key={index} onClick={()=>onChnageData('selected',item.id)} class="dropdown-item">{item.name}</span>
            )}

          </div>
        </div>
        {labelError}

        <FloatingLabel typeFilter={typeFilter}>
          {placeHolder} <span>{required ? '*' : ''}</span>
        </FloatingLabel>

      </InputLabelTopStyle>
    </div>
  );
};

export default EditableDropdownList;
