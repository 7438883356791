import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import Image from 'components/SharedComponents/Image';
import Dropdown from 'components/SharedComponents/Dropdown';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import AddModal from 'components/DashboardComponents/Client/AddModal';
import { getClients, deleteClient } from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
// import {getPageToSearchParam} from 'shared/methods';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import { FaPlus } from 'react-icons/fa';
import { GrSearch } from 'react-icons/gr';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getChoices } from 'utils/api/CoreApi';
import {
  Row,
  Col,
  UserImg,
  UserImgContainer,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderLgBreak,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  DataContainer,

} from 'shared/styles/constants/tagsStyles';
import { getClientTypes, getClientsStatus } from 'utils/api/CoreApi';
import { headerColumns } from './headerColumsList';
import {
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';
import { getCategoryPermission, getPermissions, getStaff, getClient, getEmployee } from '../../../utils/localStorage/user';
import {
  CLIENT_PERMISSION,
  LIST_OF_CLIENT_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
} from '../../../utils/constants/permissions';

import { debounce } from "lodash";
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { getAdmin, getSuperAdmin } from "../../../utils/localStorage/token"
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { useHistory } from 'react-router';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import _ from 'lodash'
import _debounce from 'lodash/debounce';
import NoSelectAllDropdownFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilterCustom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { list_of_client_choices, rota_shift_choices } from 'utils/choiceConstant';


const Client = props => {
  const { handleChangeChoices } = props
  const [clients, setClients] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [clientsStatus, setClientsStatus] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [updateSelf, setUpdateSelfPermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [numClients, setNumClients] = useState(0);
  const [runRouteData, setRunRouteData] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [clientTypeFilter, setClientTypeFilter] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const mountedRef = useRef(true);
  const {contextRelativesClient, contextChoices: choices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, handleGetChoices } = useContext(AppContext)
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [loadingModal, setLoadingModal] = useState(true);
  let tempChoices = _.cloneDeep(choices)
  const history = useHistory()
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  const { values, setValues, useInput } = useForm({ ordering: 'full_name' });
  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [clientStatusFilterTemp, setClientStatusFilterTemp] = useState([]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(()=>{
    handleGetChoices(list_of_client_choices)
  },[])

  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])

  useEffect(() => {
    let status = choices && choices.client_status && choices.client_status.filter(y => y.name === "Active");
    setValues({ ...values, client_status: status ? status : null })
  }, [choices])


  useEffect(() => {
    if (values && values.client_status) {
      search();
    }
  }, [
    values.client_status,
    values.runroutes,
    values.branch,
    values.client_type,
    branchData,
    values.ordering,
    props.location.search,
    values.limitperpage, ,
  ]);


  useEffect(() => {
    if (choices) {
      loadChoices()
    }
  }, [choices])

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getClients({ ...branchData, ...values, ...pageToSearch }, values.limitperpage).then(response => {
      if (!mountedRef.current) return null;
      if (response) {
        setLoadingModal(false);
      }
      setClients(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
      setNumClients(Math.ceil(response.count))
    });
  };


  const loadChoices = async () => {

    setRunRouteData(tempChoices && tempChoices.run_routes && tempChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setClientStatus(tempChoices && tempChoices.client_status && tempChoices.client_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    let status_obj = tempChoices && tempChoices.client_status && tempChoices.client_status.find(y => y.name === "Active")
    setClientStatusFilter([{ id: status_obj && status_obj.id, name: status_obj && status_obj.name }])
    setClientStatusFilterTemp([{ id: status_obj && status_obj.id, name: status_obj && status_obj.name }])
    setBranch(tempChoices && tempChoices.branch && tempChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setClientTypes(tempChoices && tempChoices.client_type && tempChoices.client_type.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setClientsStatus(tempChoices && tempChoices.client_status && tempChoices.client_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

  };

  const getRunRoutes = (runRoute) => {
    let runroutes = runRoute && runRoute.map(item => {
      if (tempChoices && tempChoices.run_routes &&
        tempChoices.run_routes.find(choice => choice.id === item.run_routes) &&
        tempChoices.run_routes.find(choice => choice.id === item.run_routes).name) {
        return tempChoices.run_routes.find(choice => choice.id === item.run_routes).name
      }

    })
    let routes = runroutes && runroutes.join(",")
    return routes
  }



  useEffect(() => {

    setTitle('Add client');

    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setUpdateSelfPermissions(true)
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === LIST_OF_CLIENT_SETTINGS) {
            rolePermissions[LIST_OF_CLIENT_SETTINGS] = {};
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
          }
        });
        setCreatePermissions(
          rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );

        setUpdateSelfPermissions(rolePermissions[LIST_OF_CLIENT_SETTINGS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
      }
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIsAdmin, contextIsSuperAdmin]);


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
    console.log("++++++",values,querySort)
  };

  const hitSearch = (value) => {
    values.search = value
    search();
  }

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d)
    }
  }

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value)
    debounceOnchange.current(e.target.value)
  };


  const filterClientStatus = (item) => {
    setClientStatusFilter([...item])

  }
  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }
  const filterClientType = (item) => {
    setClientTypeFilter([...item])
  }


  const dropDownHandleSelect = item => {

    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/client",
      search: stringified,
    });

    setValues({ ...values, ...item });
  };


  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleDeleteClient = async () => {
    try {
      await deleteClient(idSelected);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      search();
      handleChangeChoices()
    
    }
  };

  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleRowClick = (id,clientName )=> {
    console.log("clientname",clientName,id)
    setIdSelected(id);
    setModalValue(true);
    history.push({
      pathname: `/client/edit/${id}/personalDetails`,
      state: {
        updateSelfListOfEmployee: updateSelf,
        updateAllListOfEmployee: update,
        clientName
      }
    })
  };

  const handleAddClient = () => {
    setIdSelected('');
    handleModal();
    history.push('/client/add/personalDetails');
  };


  const getClientStatus = id => {
    if (!id) {
      return '';
    }

    let status = clientsStatus.find(x => x.id === id) || {
      name: 'Unspecified',
    };

    return status.name;
  };

  const createAddressFormat = address => {
    const name = address.name ? `${address.name}, ` : '';
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const city = address.city ? `${address.city}, ` : '';
    const zip_code = address.zip_code ? `${address.zip_code}` : '';

    return `${name}${line_1}${city}${zip_code}`;
  };


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }


  return (
    <div>
      <Title>List of clients</Title>
      <HeaderLgBreak>
        <div className="d-flex " style={{flexWrap:'wrap'}}>
          <DropdownTypesContainer className="mr-2 mb-1">
            <NoSelectAllDropdownFilter
              items={clientTypes ? clientTypes : []}
              setItemsSelected={items => {
                dropDownHandleSelect({ client_type: items });
              }}
              itemsSelected={clientTypeFilter}
              setSelectedFilter={filterClientType}
              placeHolder={'Client type'}
              isObject
              allOption
            />    
          </DropdownTypesContainer>
          <DropdownTypesContainer className="mr-2 mb-1">
            <NoSelectAllDropdownFilter
              items={branch ? branch : []}
              setItemsSelected={items => {
                dropDownHandleSelect({ branch: items });
              }}
              itemsSelected={branchFilter}
              setSelectedFilter={setFilterBranch}
              placeHolder={'Branch'}
              isObject
              allOption

            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="mr-2 mb-1">
            <NoSelectAllDropdownFilterCustom
              items={clientStatus ? clientStatus : []}
              setItemsSelected={items => {
                dropDownHandleSelect({ client_status: items });
              }}
              clientStatusFilterTemp={clientStatusFilterTemp}
              itemsSelected={clientStatusFilter}
              setSelectedFilter={filterClientStatus}
              placeHolder={'Client Status'}
              isObject
              allOption
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="mr-2 mb-1">
            <NoSelectAllDropdownFilter
              items={runRouteData ? runRouteData : []}
              setItemsSelected={items => {
                dropDownHandleSelect({ runroutes: items });
              }}
              itemsSelected={runRoutesFilter}
              setSelectedFilter={setRunroutefilter}
              placeHolder={'Group Area'}
              isObject
              allOption

            />
          </DropdownTypesContainer>
        </div>

        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}       
                onChange={(e) => {
                  keyPressed(e)
                }
                }
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>

          {create && (
            <FloatingMobileButton onClick={handleAddClient}>
              <FaPlus />
              <label>NEW CLIENT</label>
            </FloatingMobileButton>
          )}
        </SearchAddContainer>
      </HeaderLgBreak>

      <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">

        <div className="data-container">
          <div className="mr-2">
            <p>{'Clients: ' + numClients}</p>
          </div>
        </div>
      </DataContainer>
      <ComponentDynamic loading={loadingModal} >
        <Table headerColumns={headerColumns} callBackQuerySort={setQuerySort} headerPadding={false} >

          {/* {readPermission && (   */}
          <div>
            {clients.map((client, index) => {
              let clientName = client.full_name
              return (
                <div key={index}>
                  <Row bgColor>
                    <ColsGrouper
                      className="d-flex"
                      onClick={() => {
                        return update ? handleRowClick(client.id) : null;
                      }}>

                      <Col
                        Initials
                        max_width={LARGE_COL}
                        Shrink
                        ShadowMobile
                        Capitalize
                        overlap={true}
                        Center>
                        <UserImgContainer>
                          <UserImg>
                            <Image
                              src={client.photo ? client.photo : ''}
                              name={client.first_name || client.full_name}
                              lastName={client.last_name}
                              alt={'client-img'}
                              fontSize={'0.9rem'}
                            />
                          </UserImg>
                          <label className="align-self-center mb-0">
                            {client.full_name}
                          </label>
                        </UserImgContainer>
                      </Col>


                      <Col className="md-hidden sm-hidden" max_width={MEDIUM_COL} Center overlap={true} >
                        <label>{client.reference}</label>
                      </Col>

                      <Col
                        className="sm-hidden"
                        max_width={SMALL_COL}
                        Capitalize
                        Center
                        overlap={true}
                        Color={getClientStatus(client.status)}>
                        {getClientStatus(client.status)}
                      </Col>


                      <Col max_width={LARGE_COL} Center overlap={true}>
                        {client.address
                          ? createAddressFormat(client.address)
                          : ''}
                      </Col>

                      <Col
                        className="md-hidden sm-hidden"
                        Capitalize
                        Center
                        overlap={true}
                        max_width={MEDIUM_COL}>
                        {client.branch_name ? client.branch_name : ''}
                      </Col>
                      <Col
                        className="md-hidden sm-hidden"
                        Capitalize
                        Center
                        overlap={true}
                        max_width={MEDIUM_COL}>
                        {getRunRoutes(client && client.client_run_routes)}
                      </Col>
                      {/* {(update || (updateSelf && (userClient == client.id)) || filterAdmin || filterSuperAdmin) && ( */}
                      {(update || updateSelf || filterAdmin || filterSuperAdmin) && (
                        <Col Center Icon Purple>
                          <IconWrapper
                            onClick={() => {
                              handleRowClick(client.id,clientName);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                        </Col>
                      )}
                    </ColsGrouper>

                    {(deletePermission || filterAdmin || filterSuperAdmin) && (
                      <Col Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteClient(client.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                    )}
                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
            <div className="mt-3">
              <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={clients.length} />

            </div>
          </div>
          {/* )} */}
        </Table>
      </ComponentDynamic>
      {/* {!modalValue ? null : (
        <AddModal
          Id={idSelected}
          setModalValue={handleModal}
          successCallback={search}
          // fullBind={fullBind}
          // tempChoices={tempChoices}
        />
      )} */}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this client? \n DELETING the client will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the client status to discard instead to retain database records.'
        }
        onOk={handleDeleteClient}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default Client;
