import {
  LARGE_COL,
  SMALL_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
  P_W_Zero,
  EX_SMALL_COL4
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    id: 'checkbox',
    removeCusCol: true, 
    columnSelected: true,
    className: 'grow-mobile',
    max_width: VERY_EX_SMALL_COL_CHECKBOX,
  },
  {
    label: 'Branch Name',
    name: 'branch_name',
    status: 0,
    custum_column: true,
    id: 'branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Emp Name',
    name: 'employee_name',
    status: 0,
    custum_column: true,
    id: 'employee_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Service Category',
    name: 'service_category',
    status: 0,
    custum_column: true,
    id: 'service_category',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    custum_column: true,
    id: 'client_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Client Branch',
    name: 'client_branch_name',
    status: 0,
    custum_column: true,
    id: 'client_branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Status',
    name: 'visit_status',
    status: 0,
    custum_column: true,
    id: 'visit_status',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Visit Shift Id',
    name: 'visit_shift_id',
    status: 0,
    custum_column: true,
    id: 'visit_shift_id',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start Date',
    name: 'start_date',
    status: 0,
    custum_column: true,
    id: 'start_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'end_date',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Start Time',
    name: 'schedule_start_date',
    status: 0,
    custum_column: true,
    id: 'schedule_start_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch End Time',
    name: 'schedule_end_date',
    status: 0,
    custum_column: true,
    id: 'schedule_end_date',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Sch Hrs',
    name: 'schedule_duration_hrs',
    status: 0,
    custum_column: true,
    id: 'schedule_duration_hrs',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Travel Code',
    name: 'travel_code',
    status: 0,
    custum_column: true,
    id: 'travel_code',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Travel Mode',
    name: 'travel_mode',
    status: 0,
    custum_column: true,
    id: 'travel_mode',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Postcode From',
    name: 'post_code_from',
    status: 0,
    custum_column: true,
    id: 'post_code_from',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Postcode To',
    name: 'post_code_to',
    status: 0,
    custum_column: true,
    id: 'post_code_to',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Pay Pattern',
    name: 'pay_pattern',
    status: 0,
    id: 'pay_pattern',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'EVV Visit Confirmation',
    name: 'evv_visit_confirmation',
    status: 0,
    custum_column: true,
    id: 'evv_visit_confirmation',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Travel Rate card',
    name: 'travel_rate_card',
    status: 0,
    custum_column: true,
    id: 'travel_rate_card',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Sch Travel Distance (miles)',
    name: 'schedule_travel_distance_miles',
    status: 0,
    custum_column: true,
    id: 'schedule_travel_distance_miles',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Sch Mileage Rate',
    name: 'schedule_mileage_rate',
    status: 0,
    custum_column: true,
    id: 'schedule_mileage_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Travel Distance (miles)',
    name: 'actual_travel_distance_miles',
    status: 0,
    custum_column: true,
    id: 'actual_travel_distance_miles',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Mileage Rate',
    name: 'actual_mileage_rate',
    status: 0,
    custum_column: true,
    id: 'actual_mileage_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Sch Mileage Amt',
    name: 'schedule_mileage_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_mileage_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Actual Mileage Amt',
    name: 'actual_mileage_amount',
    status: 0,
    custum_column: true,
    id: 'actual_mileage_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Visit Pay Confirmation',
    name: 'visit_pay_confirmation',
    status: 0,
    custum_column: true,
    id: 'visit_pay_confirmation',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Distance Pay Confirmation',
    name: 'distance_pay_confirmation',
    status: 0,
    custum_column: true,
    id: 'distance_pay_confirmation',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Cancelled Pay Percentage',
    name: 'cancelled_pay_percentage',
    status: 0,
    custum_column: true,
    id: 'cancelled_pay_percentage',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Amt',
    name: 'non_commute_schedule_travel_bill_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_bill_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Duration (Hrs)',
    name: 'non_commute_schedule_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Travel Distance',
    name: 'non_commute_schedule_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Scheduled Mileage Amt',
    name: 'non_commute_schedule_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_schedule_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Amt',
    name: 'non_commute_actual_travel_bill_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_bill_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Duration (Hrs)',
    name: 'non_commute_actual_travel_duration',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_duration',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Travel Distance',
    name: 'non_commute_actual_travel_miles',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Non-commute Actual Mileage Amt',
    name: 'non_commute_actual_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'non_commute_actual_travel_miles_amount',
      columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Employee Ref.',
    name: 'employee_reference',
    status: 0,
    id: 'employee_reference',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Weekly Hours',
    name: 'weekly_hrs',
    status: 0,
    id: 'weekly_hrs',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
];

export const headerColumnsSelector = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Travel Mode',
    id: 'travel_mode',
  },
  {
    name: 'Postcode From',
    id: 'post_code_from',
  },
  {
    name: 'Postcode To',
    id: 'post_code_to',
  },
  {
    name: 'EVV Visit Confirmation',
    id: 'evv_visit_confirmation',
  },
  {
    name: 'Travel Rate card',
    id: 'travel_rate_card',
  },
  {
    name: 'Sch Travel Distance (miles)',
    id: 'schedule_travel_distance_miles',
  },
  {
    name: 'Sch Mileage Rate',
    id: 'schedule_mileage_rate',
  },
  {
    name: 'Actual Travel Distance (miles)',
    id: 'actual_travel_distance_miles',
  },
  {
    name: 'Actual Mileage Rate',
    id: 'actual_mileage_rate',
  },
  {
    name: 'Sch Mileage Amt',
    id: 'schedule_mileage_amount',
  },
  {
    name: 'Actual Mileage Amt',
    id: 'actual_mileage_amount',
  },
  {
    name: 'Visit Pay Confirmation',
    id: 'visit_pay_confirmation',
  },
  {
    name: 'Distance Pay Confirmation',
    id: 'distance_pay_confirmation',
  },

];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Checkbox',
    id: 'checkbox',
  },
  {
    name: 'Emp Name',
    id: 'employee_name',
  },
  {
    name: 'Service Category',
    id: 'service_category',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Visit Status',
    id: 'visit_status',
  },

  {
    name: 'Start Date',
    id: 'start_date',
  },
  {
    name: 'Sch Start Time',
    id: 'schedule_start_time',
  },
  {
    name: 'Sch End Time',
    id: 'schedule_end_time',
  },

  {
    name: 'Sch Hrs',
    id: 'schedule_duration_hrs',
  },
  {
    name: 'Travel Mode',
    id: 'travel_mode',
  },
  {
    name: 'Postcode From',
    id: 'post_code_from',
  },
  {
    name: 'Postcode To',
    id: 'post_code_to',
  },
  {
    name: 'EVV Visit Confirmation',
    id: 'evv_visit_confirmation',
  },
  {
    name: 'Travel Rate card',
    id: 'travel_rate_card',
  },
  {
    name: 'Sch Travel Distance (miles)',
    id: 'schedule_travel_distance_miles',
  },
  {
    name: 'Sch Mileage Rate',
    id: 'schedule_mileage_rate',
  },
  {
    name: 'Actual Travel Distance (miles)',
    id: 'actual_travel_distance_miles',
  },
  {
    name: 'Actual Mileage Rate',
    id: 'actual_mileage_rate',
  },
  {
    name: 'Sch Mileage Amt',
    id: 'schedule_mileage_amount',
  },
  {
    name: 'Actual Mileage Amt',
    id: 'actual_mileage_amount',
  },
  {
    name: 'Visit Pay Confirmation',
    id: 'visit_pay_confirmation',
  },
  {
    name: 'Distance Pay Confirmation',
    id: 'distance_pay_confirmation',
  },

];
