import React, {useState, useEffect, Fragment} from 'react';
import { Title,TitleSection} from 'shared/styles/constants/tagsStyles';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {NameWrapper}  from 'shared/styles/constants/tagsStyles';

const ActualEmployeePay = ({
  dropDownHandleSelectPay,
  billRate,
    useInput,
    values,
    setValues,
    datapay,
    editMode,
    payRate,
}) => {


  return (
    <div className='mt-1'>
         <>
        <div 
        //  key={indexpay} className={' mb-1'}
         >
      {/* <NameWrapper  className='ml-1'> {datapay.employee_name && datapay.employee_name } </NameWrapper> */}

        <Fragment />
            <FlexGrid3Container className={'w-100  ml-1 '}>
          <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  ResizeWidth={'40%'} >
          <DropdownSearchable
          placeHolder={'Actual Pay Rate Card'}
           disabled={!editMode  }
           options={payRate}
        selectedOption={ datapay   ?   datapay.actual_pay_rate_card   : null }
        onChange={value =>
          dropDownHandleSelectPay(
         {actual_pay_rate_card: value ? value : null},  'actual_pay_rate_card' , 'actuals_pay_rate','actual'
          )  }
        />
            </FlexGrid3Item>
            <FlexGrid3Item className=" pr-0 pr-md-1 pt-1  " ResizeWidth={'15%'} >
            <InputLabelTop
                readOnly={true}
                disabled={true}
                label={'Actual Pay Rate/hr'}
                type="text"
                {...useInput(`actuals_pay_rate.actual_pay_rate_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'18%'}>          
            <InputLabelTop
               readOnly={true}
               disabled={true}
                label={'Actual Pay Rate'}
                type="text"
                {...useInput(`actuals_pay_rate.actual_pay_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'18%'}>          
            <InputLabelTop
               readOnly={true}
               disabled={true}
                label={'Actual Pay Amount'}
                type="text"
                {...useInput(`actuals_pay_rate.actual_pay_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container >
            <FlexGrid3Container className={'w-100 mb-1 '}>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Pay Rate/hr'}
   
                {...useInput(`rounded_actual_pay_rate_per_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Pay Rate'}
   
                {...useInput(`rounded_actual_pay_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Pay Amount'}
   
                {...useInput(`rounded_actual_pay_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container className={'w-100 mb-1 '}>
           
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Pay Rate/hr'}
   
                {...useInput(`banded_actual_pay_rate_per_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Pay Rate'}
   
                {...useInput(`banded_actual_pay_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Pay Amount'}
   
                {...useInput(`banded_actual_pay_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container>
             </div>

        </>
    </div>
  )
}

export default ActualEmployeePay