import React, {useEffect, useState} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {KeyPress} from 'utils/constants/regularExpressions';
import {
  TitleSection,
  FlexGrid3Container,
  FlexGrid3Item,
  ContainerResponsive,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import ToggleButton from '../ToggleButton';
import {FlexEqual} from '../../DashboardComponents/Team/AddModal/styles';

const CareContact = ({
  useInput,
  choices,
  values,
  nameSection,
  dropDownHandleSelect,
  sectionStyle,
  roles,
}) => {
  const [makeUser, setMakeUser] = useState(false);

  let roleStatus = values[nameSection]?.role;
  useEffect(() => {
    if (values[nameSection]?.role) {
      values[nameSection].make_user = true;
      values[nameSection].permission_right = values[nameSection].role;
      setMakeUser(true);
    }
  }, [roleStatus]);

  const handelMakeUser = () => {
    if (values[nameSection]?.permission_right) {
      values[nameSection].permission_right = null;
    }

    setMakeUser(prevState => !prevState);
  };
  return (
    <div className={sectionStyle}>
      <TitleSection>Care contact</TitleSection>
      <div className="d-flex flex-column mt-2">
        <div className="w-100 d-flex flex-column">
          <div className="w-100 d-flex">
            <div className="w-100 mr-2">
              <InputLabelTop
                type="text"
                id="no"
                label={'No.'}
                onKeyPress={KeyPress.INTEGER}
                {...useInput(`${[nameSection]}.no`, 'isInt')}
                maxlength={5}
              />
            </div>
            <div className="w-100">
              <DropdownSearchable
                placeHolder={'Title'}
                options={choices.title}
                required={makeUser}
                selectedOption={
                  values[nameSection] ? values[nameSection].title : null
                }
                onChange={value =>
                  dropDownHandleSelect({
                    [nameSection]: {title: value ? value : null},
                  })
                }
              />
            </div>
          </div>

          <div className="w-100 d-flex">
            <div className="w-100 mr-2">
              <InputLabelTop
                type="text"
                id={`${[nameSection]}_full_name`}
                label={'Full Name'}
                required={makeUser}
                {...useInput(`${[nameSection]}.full_name`)}
                maxlength={255}
              />
            </div>
            <div className="w-100">
              <DropdownSearchable
                placeHolder={'Relationship'}
                options={choices.care_contact_type}
                required={makeUser}
                selectedOption={
                  values[nameSection] ? values[nameSection].relationship : null
                }
                onChange={value =>
                  dropDownHandleSelect({
                    [nameSection]: {relationship: value ? value : null},
                  })
                }
              />
            </div>
          </div>

          <div className="w-100 d-flex">
            <div className="w-100 mr-2">
              <InputLabelTop
                type="text"
                id="house_company_name"
                label={'House Name / Company Name'}
                {...useInput(`${[nameSection]}.name`)}
                maxlength={50}
              />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="w-100 mr-2">
            <InputLabelTop
              type="text"
              id="line_1"
              label={'Address Line 1'}
              {...useInput(`${[nameSection]}.line_1`)}
              maxlength={255}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              type="text"
              id="line_2"
              label={'Address Line 2'}
              {...useInput(`${[nameSection]}.line_2`)}
              maxlength={255}
            />
          </div>
        </div>

        <FlexGrid3Container className="mb-2">
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              type="text"
              id={`${[nameSection]}_town_city`}
              label={'Town / City'}
              {...useInput(`${[nameSection]}.city`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              type="text"
              id={`${[nameSection]}_state`}
              label={'County / State'}
              {...useInput(`${[nameSection]}.state`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
            <InputLabelTop
              type="text"
              id={`${[nameSection]}_postcode_zipcode`}
              label={' Postcode / Zipcode'}
              {...useInput(`${[nameSection]}.zip_code`)}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0">
            <InputLabelTop
              type="text"
              id={`${[nameSection]}_country`}
              label={'Country'}
              {...useInput(`${[nameSection]}.country`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
            <InputLabelTop
              type="text"
              id="mobile"
              label={'Mobile No.'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(`${[nameSection]}.mobile`, 'isMobilePhone')}
              maxlength={20}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1">
            <InputLabelTop
              type="email"
              id="email"
              label={'Email'}
              required={makeUser}
              {...useInput(`${[nameSection]}.email`, 'isEmail')}
              maxlength={254}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>
        <ContainerResponsive className="d-flex justify-content-between mt-3">
          <ToggleButton
            label="Invite as User"
            useInput={useInput(`${[nameSection]}.make_user`)}
            id={`${[nameSection]}.make_user`}
            onClick={handelMakeUser}
          />
        </ContainerResponsive>

        {makeUser && (
          <ContainerResponsive>
            <FlexEqual className="mr-md-2 mr-sm-0">
              {/*<TitleSection>Roles And Permission</TitleSection>*/}
              <div className="d-flex flex-column mt-2">
                <FlexGrid3Container className="w-100 mb-2">
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                    <DropdownSearchable
                      placeHolder={'Permission Rights'}
                      options={roles}
                      required={makeUser}
                      selectedOption={
                        values[nameSection]
                          ? values[nameSection].permission_right
                          : null
                      }
                      onChange={value =>
                        dropDownHandleSelect({
                          [nameSection]: {
                            permission_right: value ? value : null,
                          },
                        })
                      }
                    />
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
            </FlexEqual>
          </ContainerResponsive>
        )}
      </div>
    </div>
  );
};

CareContact.propTypes = {
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  choices: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  sectionStyle: PropTypes.string,
};

CareContact.defaultProps = {
  sectionStyle: '',
};

export default CareContact;
