import { apiURL, apiURLReplica, apiURLReport } from '../env';
import { get, post, deleteFetch, Newpost, post2 } from './Api';
import queryString, { parse } from 'query-string';
import { methods, unauthorized_status_code } from 'utils/constants/api';
import { getToken, clearToken, getTenant } from '../localStorage/token';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { DELETE } from 'utils/constants/icons';
import moment from 'moment';
// import { getElSeg } from '@fullcalendar/react';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

const getBaseUrlMicroServices = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};

const getBaseUrlMicroServices2 = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v2`;
};

const getBaseUrlReport = () => {
  let tenant = getTenant();
  return `${apiURLReport}/${tenant}/api/v1`;
};

export const getEmployees = (values, limitPerPage) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  let dataParse = {};
  if (values.employment_type) {
    let employment_typeFilter = values.employment_type.map(data => data.id);
    let stremployment_typeFilter = employment_typeFilter.join(',');
    filterParse.employment_type = stremployment_typeFilter;
  }

  if (values.employee_category) {
    let employee_categoryFilter = values.employee_category.map(data => data.id);
    let stremployee_categoryFilter = employee_categoryFilter.join(',');
    filterParse.employee_category = stremployee_categoryFilter;
  }

  if (values.job_title) {
    let job_titleFilter = values.job_title.map(data => data.id);
    let strjob_titleFilter = job_titleFilter.join(',');
    filterParse.job_title = strjob_titleFilter;
  }

  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id);
    let stremployee_statusFilter = employee_statusFilter.join(',');
    filterParse.employee_status = stremployee_statusFilter;
  }

  if (values.run_route) {
    let runRouteFilter = values.run_route.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // filterParse.ordering=values.ordering;

    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  // if (values.offset  ) {
  toParse.offset = values.offset;
  // }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.runroute) {
    stringified += '&employee_run_routes__run_routes=' + filterParse.runroute;
  }

  // if (filterParse.ordering) {
  //   stringified += '&ordering=' + filterParse.ordering;
  // }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employee_status) {
    stringified += '&employee_status__id__in=' + filterParse.employee_status;
  }
  if (filterParse.job_title) {
    stringified += '&job_detail__job_title_id__in=' + filterParse.job_title;
  }
  if (filterParse.employee_category) {
    stringified +=
      '&job_detail__employee_category_id__in=' + filterParse.employee_category;
  }
  if (filterParse.employment_type) {
    stringified +=
      '&job_detail__employment_type_id__in=' + filterParse.employment_type;
  }

  const url = stringified
    ? `${baseUrl}/employees/?${stringified}`
    : `${baseUrl}/employees/`;

  return get(url);
};

export const getEmployeesList = async (values, tab) => {
  console.log(values, tab, 'FFFFFFFFF');

  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.employment_type) {
    let employment_typeFilter = values.employment_type.map(data => data.id);
    let stremployment_typeFilter = employment_typeFilter.join(',');
    filterParse.employment_type = stremployment_typeFilter;
  }

  if (values.employee_category) {
    let employee_categoryFilter = values.employee_category.map(data => data.id);
    let stremployee_categoryFilter = employee_categoryFilter.join(',');
    filterParse.employee_category = stremployee_categoryFilter;
  }

  if (values.job_title) {
    let job_titleFilter = values.job_title.map(data => data.id);
    let strjob_titleFilter = job_titleFilter.join(',');
    filterParse.job_title = strjob_titleFilter;
  }

  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id);
    let stremployee_statusFilter = employee_statusFilter.join(',');
    filterParse.employee_status = stremployee_statusFilter;
  }

  if (values.run_route) {
    let runRouteFilter = values.run_route.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.runroute) {
    stringified += '&employee_run_routes__run_routes=' + filterParse.runroute;
  }
  if (values.complete_from) {
    stringified += '&complete_from=' + values.complete_from;
  }
  if (values.complete_to) {
    stringified += '&complete_to=' + values.complete_to;
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employee_status) {
    stringified += '&employee_status__id__in=' + filterParse.employee_status;
  }
  if (filterParse.job_title) {
    stringified += '&job_detail__job_title_id__in=' + filterParse.job_title;
  }
  if (filterParse.employee_category) {
    stringified +=
      '&job_detail__employee_category_id__in=' + filterParse.employee_category;
  }
  if (filterParse.employment_type) {
    stringified +=
      '&job_detail__employment_type_id__in=' + filterParse.employment_type;
  }

  const url = stringified
    ? `${baseUrl}/employees/download-employee?view_set=${tab}${stringified}`
    : `${baseUrl}/employees/download-employee?view_set=${tab}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};

export const exportTeamCronJob = async (values, column_selectorr) => {
  console.log(values.column_selector, column_selectorr, 'FFFFFFFFF');

  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  let column_selector;
  if (values?.column_selector && values?.column_selector?.length) {
    column_selector = values?.column_selector?.map(item => item.id)
  }


  if (values.employee) {
    let employeeFilter = values.employee.map(data => data.id);
    let strEmployeeFilter = employeeFilter.join(',');
    filterParse.employee = strEmployeeFilter;
  }

  if (values.shift_type) {
    let shift_typeFilter = values.shift_type.map(data => data.id);
    let strShiftFilter = shift_typeFilter.join(',');
    filterParse.shift_type = strShiftFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.employee_status) {
    let ids = values.employee_status.map(data => data.id).join(',');
    filterParse.employee_status = ids;
  }

  

  stringified = queryString.stringify(toParse) || '';

 
  if (values.shift_start_date) {
    stringified += '&shift_start_date=' + values.shift_start_date;
  }
  // if (values.complete_to) {
  //   stringified += '&complete_to=' + values.complete_to;
  // }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employee) {
    stringified += '&employee=' + filterParse.employee;
  }

  if (filterParse.shift_type) {
    stringified += '&shift_type=' + filterParse.shift_type;
  }
  if (filterParse.employee_status) {
    stringified += '&employee_status=' + filterParse.employee_status;
  }


  const url = stringified
    ? `${baseUrl}/reports/shift-cron-jobs?${stringified}`
    : `${baseUrl}/reports/shift-cron-jobs`;
//http://127.0.0.1:8000/org/bill-test-org-1/api/v1/reports/shift-cron-jobs?branch=94dd43ab-50ca-4261-bd11-b23aa8399659&shift_start_date=2022-04-11&employee=aeffd473-3343-409d-9d3b-c8eafda758dd&shift_type=47b2c148-6605-13cc-d6bc-971b9c874fd0

const response = await fetch(url, {
  method: 'POST',
  body: JSON.stringify({ column_selector: column_selector}),
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Token ' + getToken(),

  },
});


return response;
};

export const getEmployee = id => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/employees/${id}/`;

  return get(url);
};

export const getAbsenceOverview = (values, limitPerPage, page) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  toParse.limit = limitPerPage;

  toParse.offset = values.offset

  if (values.start_date) {
    toParse.start_date = values.start_date
  }
  if (values.end_date) {
    toParse.end_date = values.end_date
  }

  stringified = queryString.stringify(toParse) || '';

  if (values.absence_type) {
    let absenceFilter = values.absence_type.map(data => data.id);
    let strabsenceFilter = absenceFilter.join(',');
    filterParse.absence_id = strabsenceFilter;
  }
  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees_id = stremployeesFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.filter(el => el.id !== "None").map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  console.log(values?.employee_status, "employee_status")
  if (values?.employee_status) {
    let statusFilter = values.employee_status.map(data => data.id);
    let statusFilterIds = statusFilter.join(',');
    filterParse.employee_status = statusFilterIds;
  }
  if (filterParse.absence_id) {
    stringified += '&absenceType=' + filterParse.absence_id;
  }
  if (filterParse.employees_id) {
    stringified += '&employee=' + filterParse.employees_id;
  }

  if (filterParse.headerbranch) {
    stringified += '&branch=' + filterParse.headerbranch;
  }
  if (filterParse.employee_status) {
    stringified += '&employee_status=' + filterParse.employee_status
  }

  const url = `${baseUrl}/shifts/absence-overview/getAbsenceOverview/?${stringified}`;

  return get(url);
};

export const getUploadedFiles = (id, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.employee_id= id
  toParse.offset = values.offset;
  toParse.limit = values.limitperpage;
  
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/file-upload/employee/?${stringified}`;

  return get(url);
};
export const getUploadedFilesDownload = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/file-upload/employee/download_employee_file/?id=${id}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};

export const setEmployee = values => {
  // const baseUrl = getBaseUrlMicroServices();
  const baseUrl = getBaseUrl2();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/employees/${values.id}/`
    : `${baseUrl}/employees/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setEvvVerification = values => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/visits/all/evvs/bulkUpdateEvvVerificationStatus/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setEvvTravel = values => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/visits/all/evvs/bulkUpdateTravelConfirmation/`;

  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const SetEvvDistance = values => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/visits/all/evvs/bulkUpdateDistanceConfirmation/`;

  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const setAbsenceOverview = (values, id) => {
  const baseUrl = getBaseUrl2();
  const method = id ? PATCH : POST;
  const url = id
    ? `${baseUrl}/shifts/absence-overview/updateEmployeeAbsenceOverview/`
    : `${baseUrl}/shifts/absence-overview/createEmployeeAbsenceOverview/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const getAbsenceCalendarType = async (id) => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/absence-overview/get_employee_calendar/?employee_id=${id}`

  return await get(url)
}
export const deleteAbsenceDetail = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/employees/employee/employee-absence-list/absence_delete/`;

  const body = {
    absence_ids: values,
  };

  return post(url, body, method);
};

export const deleteAbsenceListData = values => {
  const baseUrl = getBaseUrl();

  const method = DELETE;
  const url = `${baseUrl}/employees/employee/employee-absence-list/delete_shift_availability/?availability_ids=${values}`;
  const body = {};
  return deleteFetch(url, body, method);
};
export const deleteCategoryFormBuilder = id => {
  const baseUrl = getBaseUrl();

  const method = DELETE;
  const url = `${baseUrl}/form-builder/form/category/${id}/`;
  const body = {};
  return deleteFetch(url, body, method);
};
export const deleteFormBuilder = id => {
  const baseUrl = getBaseUrl();

  const method = DELETE;
  const url = `${baseUrl}/form-builder/${id}/`;
  const body = {};
  return deleteFetch(url, body, method);
};
export const uploadEmployeePhoto = async (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/employees/${empId}/`;
  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (key === 'employeeJobDetailsBranchAccess') {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }

  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const uploadEmployeeDetailsPhoto = async (values, empId, data) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/employees/employee/details/${data.id}/?employee_id=${empId}`;
  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (value !== null) {
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const setEmployeeNotes = (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/employees/employee-description?employee_id=${empId}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setEmployeeUploadFile = async values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/employees/file-upload/employee/`;

  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (value !== null) {
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const getEmployeeNotes = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee-description?employee_id=${id}`;

  return get(url);
};
export const setEmployeeJobDetails = (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/employees/job/details/${values.id}/?employee_id=${empId}`
    : `${baseUrl}/employees/job/details/?employee_id=${empId}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setJobDetailsPayRate = (values, isEdit, id, employeeId) => {

  const baseUrl = getBaseUrlMicroServices();
  const method = isEdit ? PATCH : POST;
  //http://localhost:8000/org/sonaliapptest/api/v1/employees/employee/rate/card/employee/ratecard/
  const url = !isEdit
    ? `${baseUrl}/employees/employee/rate/card/`
    : `${baseUrl}/employees/employee/rate/card/employee/ratecard/`

  const body = {
    ...values,
  };

  return post(url, body, method);
}

export const getRateList = (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/rate/card/?employee__id__in=${id}`;

  return get(url);
}

export const getEmployeeBranchAccess = (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/get_employee_branches/?employee_id=${id}`;

  return get(url);
}

export const setEmployeeSkills = (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/employees/employee/training-skills/${values.id}/?employee_id=${empId}`
    : `${baseUrl}/employees/employee/training-skills/?employee_id=${empId}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const getEmployeeSkillsDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/training-skills/?employee_id=${id}`;

  return get(url);
};
export const setAbsenceApprovel = (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/shifts/absence-approval/updateAbsenceApproval/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const getEmployeeJobDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/job/details/?employee_id=${id}`;

  return get(url);
};

export const setEmployeeDetails = async (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/employees/employee/details/${values.id}/?employee_id=${empId}`
    : `${baseUrl}/employees/employee/details/?employee_id=${empId}`;

  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setHrTask = async (values, id) => {
  const baseUrl = getBaseUrl();
  const method = id ? PATCH : POST;
  const url = id
    ? `${baseUrl}/employees/employee/employee-hr-task/${id}/`
    : `${baseUrl}/employees/employee/employee-hr-task/`;

  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setHrTaskClient = async (values, id) => {
  const baseUrl = getBaseUrl();
  const method = id ? PATCH : POST;
  const url = id
    ? `${baseUrl}/clients/client/client-hr-task/${id}/`
    : `${baseUrl}/clients/client/client-hr-task/`;

  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getEmployeeDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/details/?employee_id=${id}`;

  return get(url);
};
export const setComplianceDet = async (values, empId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/employees/employee/compliance/${values.id}/?employee_id=${empId}`
    : `${baseUrl}/employees/employee/compliance/?employee_id=${empId}`;

  // const body = {
  //   ...values,
  // };
  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'vehicle_insurance_copy' &&
      key !== 'vehicle_mot_copy' &&
      key !== 'driving_licence_copy' &&
      key !== 'reference_details'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (key === 'reference_details') {
      formData.append(key, JSON.stringify(value));
      // value.forEach((val,index)=>{
      //  for(let [keySub, valueSub] of Object.entries(val)){
      //    formData.append(`${key}.${index}.${keySub}`,valueSub)
      //  }
      // })
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
  // return post(url, body, method);
};

export const getComplianceDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/compliance/?employee_id=${id}`;

  return get(url);
};
// export const setEmployee = async employee => {
//   const baseUrl = getBaseUrl();
//   let formData = new FormData();
//   //remove nulls and append data format
//   for (let [key, value] of Object.entries(employee)) {
//     if (typeof value === 'object' && value !== null && key !== 'photo') {
//       for (let [keySub, valueSub] of Object.entries(value)) {
//         if (valueSub !== null) {
//           formData.append(`${key}.${keySub}`, valueSub);
//         } else {
//           formData.append(`${key}.${keySub}`, '');
//         }
//       }
//     } else {
//       if (value !== null) {
//         formData.append(key, value);
//       } else {
//         formData.append(key, '');
//       }
//     }
//   }

//   if (!employee.title) {
//     formData.append('title', '');
//   }

//   if (!employee.id) {
//     formData.append('is_active', true);
//   }

//   if (employee.role) {
//     formData.append('role_id', employee.role);
//     formData.append('role', employee.role);
//   }

//   const method = employee.id ? PUT : POST;
//   const url = employee.id
//     ? `${baseUrl}/employees/${employee.id}/`
//     : `${baseUrl}/employees/`;
//   const response = await fetch(url, {
//     body: formData,
//     headers: {
//       Authorization: 'Token ' + getToken(),
//     },
//     method: method,
//   });

//   if (response && response.status === unauthorized_status_code) {
//     clearToken();
//     window.location.reload();
//   }

//   const json = await response.json();

//   return {status: response.status, message: json};
// };

export const deleteEmployee = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${id}/`;
  return deleteFetch(url);
};

export const deleteAbsenceOverview = async (id, values) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  if (id) {
    stringified += 'id=' + id;
  }

  if (values.start_date) {
    stringified += '&start_date=' + values.start_date
  }
  if (values.end_date) {
    stringified += '&end_date=' + values.end_date
  }

  const url = `${baseUrl}/shifts/absence-overview/deleteAbsenceOverview/?${stringified}`;

  return deleteFetch(url);
};

export const deleteUploadedFile = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/file-upload/employee/${id}/`;

  return deleteFetch(url);
};

export const getEmployeeSkills = async id => {
  if (!id) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${id}/skills/`;
  return get(url);
};

export const setEmployeeSkill = async (employeeId, skill) => {
  if (!employeeId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = skill.id ? PUT : POST;
  const url = skill.id
    ? `${baseUrl}/employees/${employeeId}/skills/${skill.id}/`
    : `${baseUrl}/employees/${employeeId}/skills/`;

  const body = {
    ...skill,
  };

  return post(url, body, method);
};

export const deleteEmployeeSkill = async (employeeId, skillId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${employeeId}/skills/${skillId}/`;

  return deleteFetch(url);
};

export const getPreferredClients = async preferenceId => {
  if (!preferenceId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${preferenceId}/employee-preferred/`;

  return get(url);
};

export const getAvoidClients = async preferenceId => {
  if (!preferenceId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${preferenceId}/employee-avoid/`;

  return get(url);
};

export const getDetails = async Id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/employee-absence-list/get_availability_data/?availability_ids=${Id}`;

  return get(url);
};

export const getDetailsBradford = async (Id, start, end) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/bradford/getEmployeeSpecificData/?start_date=${start}&end_date=${end}&employee=${Id}`;

  return get(url);
};

export const savePreferredClients = async (
  employeeId,
  preferenceId,
  clientId
) => {
  if (!employeeId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/employees/${employeeId}/employee-preferred/`;

  const body = {
    client: clientId,
    employee: preferenceId,
  };

  return post(url, body, method);
};

export const deletePreferredClients = async (preferenceId, preferId) => {
  if (!preferenceId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${preferenceId}/employee-preferred/${preferId}/`;

  return deleteFetch(url);
};

export const saveAvoidClients = async (employeeId, preferenceId, clientId) => {
  if (!employeeId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/employees/${employeeId}/employee-avoid/`;

  const body = {
    client: clientId,
    employee: preferenceId,
  };

  return post(url, body, method);
};

export const deleteAvoidClients = async (preferenceId, avoidId) => {
  if (!preferenceId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/${preferenceId}/employee-avoid/${avoidId}/`;

  return deleteFetch(url);
};

export const getAbsenceList = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();

  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // filterParse.ordering=values.ordering;

    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  // if (values.offset  ) {
  toParse.offset = values.offset;
  // }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_id = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees_id = stremployeesFilter;
  }
  if (values.absence_type) {
    let absenceFilter = values.absence_type.map(data => data.id);
    let strabsenceFilter = absenceFilter.join(',');
    filterParse.absence_id = strabsenceFilter;
  }
  if (values.start_date) {
    let startFilter = values.start_date;
    let strstartFilter = startFilter;
    filterParse.start_id = strstartFilter;
  }
  if (values.end_date) {
    let endFilter = values.end_date;
    let strendFilter = endFilter;
    filterParse.end_id = strendFilter;
  }

  if (values.Id) {
    let FilterId = values.Id;
    filterParse.filterId = FilterId;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.runroute) {
    stringified += '&employee_run_routes__id__in=' + filterParse.runroute;
  }

  if (filterParse.branch_id) {
    if (filterParse.branch_id.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch_id;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.branch_id;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employees_id) {
    stringified += '&employee__id__in=' + filterParse.employees_id;
  }
  if (filterParse.absence_id) {
    stringified += '&absence_type__id__in=' + filterParse.absence_id;
  }

  if (filterParse.start_id) {
    stringified +=
      '&start_date__gte=' + filterParse.start_id + ' ' + '00:00:00';
  }
  if (filterParse.end_id) {
    stringified += '&end_date__lte=' + filterParse.end_id + ' ' + '23:59:59';
  }
  if (filterParse.filterId) {
    stringified += '&selected_id=' + filterParse.filterId;
  }
  let defaultOrdering = '&ordering=-start_date';
  const url = `${baseUrl}/employees/employee/employee-absence-list/?${stringified}${defaultOrdering}`;
  return get(url);
};

export const getBradfordList = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();

  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  // if (values.offset  ) {
  toParse.offset = values.offset;
  // }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_id = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees_id = stremployeesFilter;
  }
  if (values.start_date) {
    let startFilter = values.start_date;
    let strstartFilter = startFilter;
    filterParse.start_id = strstartFilter;
  }
  if (values.end_date) {
    let endFilter = values.end_date;
    let strendFilter = endFilter;
    filterParse.end_id = strendFilter;
  }

  if (values.Id) {
    let FilterId = values.Id;
    filterParse.filterId = FilterId;
  }

  stringified = queryString.stringify(toParse) || '';

  if (filterParse.branch_id) {
    if (filterParse.branch_id.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch_id;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.branch_id;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employees_id) {
    stringified += '&employees=' + filterParse.employees_id;
  }

  if (filterParse.start_id) {
    stringified += '&start_date=' + filterParse.start_id;
  }
  if (filterParse.end_id) {
    stringified += '&end_date=' + filterParse.end_id;
  }
  const url = `${baseUrl}/employees/employee/bradford/employeeBradfordListing/?${stringified}`;
  return get(url);
};
export const downloadAbsenceList = (values, limitperpage) => {
  const baseUrl = getBaseUrlMicroServices();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (limitperpage) {
    toParse.limit = values.limitperpage;
  }

  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_id = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees_id = stremployeesFilter;
  }
  if (values.absence_type) {
    let absenceFilter = values.absence_type.map(data => data.id);
    let strabsenceFilter = absenceFilter.join(',');
    filterParse.absence_id = strabsenceFilter;
  }
  if (values.start_date) {
    let startFilter = values.start_date;
    let strstartFilter = startFilter;
    filterParse.start_id = strstartFilter;
  }
  if (values.end_date) {
    let endFilter = values.end_date;
    let strendFilter = endFilter;
    filterParse.end_id = strendFilter;
  }

  if (values.Id) {
    let FilterId = values.Id;
    filterParse.filterId = FilterId;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.branch_id) {
    if (filterParse.branch_id.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch_id;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.branch_id;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&job_detail__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&job_detail__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.employees_id) {
    stringified += '&employees=' + filterParse.employees_id;
  }
  if (filterParse.absence_id) {
    stringified += '&absence_type=' + filterParse.absence_id;
  }

  if (filterParse.start_id) {
    stringified += '&start_date__gte=' + filterParse.start_id + " " + '00:00:00';
  }
  if (filterParse.end_id) {
    stringified += '&end_date__lte=' + filterParse.end_id + " " + '23:59:59';
  }
  if (filterParse.filterId) {
    stringified += '&selected_id=' + filterParse.filterId;
  }

  const url = `${baseUrl}/clients/app/download-excel-file?view_set=AbsenceList&${stringified}`;
  const response = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });
  return response;
};
export const downloadAbsenceOverview = (values, limitPerPage) => {
  const baseUrl = getBaseUrlMicroServices2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};


  if (values.absence_reason) {
    let absenceReasonFilter = values.absence_reason.map(data => data.id);
    let streAbsenceReasonFilter = absenceReasonFilter.join(',');
    filterParse.absenceReason = streAbsenceReasonFilter;
  }
  if (values.absence_type) {
    let absenceFilter = values.absence_type.map(data => data.id);
    let strabsenceFilter = absenceFilter.join(',');
    filterParse.absence_id = strabsenceFilter;
  }
  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees_id = stremployeesFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.employee_status) {
    let employee_statusFilter = values.employee_status.map(data => data.id);
    let stremployee_statusFilter = employee_statusFilter.join(',');
    filterParse.employee_status = stremployee_statusFilter;
  }
  if (filterParse.absenceReason) {
    stringified += '&absenceReason=' + filterParse.absenceReason;
  }
  if (filterParse.absence_id) {
    stringified += '&absenceType=' + filterParse.absence_id;
  }
  if (filterParse.employees_id) {
    stringified += '&employee=' + filterParse.employees_id;
  }

  if (values.start_date) {
    stringified += '&start_date=' + values.start_date
  }
  if (values.end_date) {
    stringified += '&end_date=' + values.end_date
  }
  if (filterParse.headerbranch) {
    stringified += '&branch=' + filterParse.headerbranch

  }
  if (filterParse.employee_status) {
    stringified += '&employee_status=' + filterParse.employee_status
  }

  const url = `${baseUrl}/clients/app/download-excel-file?view_set=AbsenceOverView&excelReport=true${stringified}`;

  const response = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });
  return response;
};
export const setEmployeeExpenses = async (expense, employeeId) => {
  const baseUrl = getBaseUrl();
  console.log(expense, "expense")
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(expense)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'expense_document'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    }
    // else if(key==='visits'){
    //   formData.append(key,JSON.stringify(value))

    //  }
    else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const method = expense.id ? PATCH : POST;
  const url = expense.id
    ? `${baseUrl}/employees/employee/expense/${expense.id}/?employee_id=${employeeId}`
    : `${baseUrl}/employees/employee/expense/?employee_id=${employeeId}`;
  console.log(formData, "expense form")
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return json;
};

export const getTeamExpenses = (selectedEmpId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.search) {
    toParse.search = values.search;
  }

  toParse.offset = values.offset;
  toParse.limit = values.limitperpage || 20

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/employees/employee/expense/?employee_id=${selectedEmpId}&${stringified}`;

  return get(url);
};
export const getHrTask = (selectedEmpId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.search) {
    toParse.search = values.search;
  }

  toParse.offset = values.offset;
  toParse.limit = values.limitperpage || 20

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/employees/employee/employee-hr-task/?employee_id=${selectedEmpId}&${stringified}`;

  return get(url);
};
export const getHrTaskClient = (selectedEmpId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (values.search) {
    toParse.search = values.search;
  }

  toParse.offset = values.offset;
  toParse.limit = values.limitperpage || 20

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/client/client-hr-task/?client_id=${selectedEmpId}&${stringified}`;

  return get(url);
};
export const getFormBuilder = (
  values,
  categoryBuilder,
  page,
  ordering,
  search
) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let stringified = '';

  toParse.limit = values.limitperpage;
  toParse.offset = values.offset
  toParse.ordering = "name"
    console.log(values,"params")
 
  //  if (page) {
  // toParse.page = page;search
  //  }

  // toParse.limit = page;
  stringified = queryString.stringify(toParse) || '';
  if (values.search) {
    stringified += '&search=' + values.search;
  }
  const url = categoryBuilder
    ? `${baseUrl}/form-builder/form/category/?${stringified}`
    : `${baseUrl}/form-builder/?${stringified}`;

  return get(url);
};
export const getTeamExpenseById = (empId, expId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/expense/${expId}/?employee_id=${empId}`;

  return get(url);
};

export const getTeamHrTaskById = (empId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/employee-hr-task/${empId}/`;

  return get(url);
};

export const getClientHrTaskById = (empId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/client-hr-task/${empId}/`;

  return get(url);
};

export const deleteExpense = async (empId, deleteId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/expense/${deleteId}/?employee_id=${empId}`;

  return deleteFetch(url);
};

export const deleteHrTask = async (deleteId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/employee-hr-task/${deleteId}/`;

  return deleteFetch(url);
};
export const deleteHrTaskClient = async (deleteId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/client-hr-task/${deleteId}/`;

  return deleteFetch(url);
};


export const getVisitDateWiseApi = async (id, date) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-visit-filter/client/get_visit_date_wise/?client=${id}&date=${date}`

  return get(url)
}

export const getEmployeeAverageHours = async (employeeId, values) => {
  let stringified = ''
  let toParse = {};
  let filterParse = {}
  toParse.start_date = values.start_date
  toParse.end_date = values.end_date
  toParse.data_format = values.data_format
  toParse.employee = employeeId
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.filter(e => e !== 'None').join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  const baseUrl = getBaseUrlMicroServices();
  if (filterParse.headerbranch) {
    stringified += "&branch=" + filterParse.headerbranch
  }
  console.log(values, "values api")
  const url = `${baseUrl}/employees/employee/average/work/employeeAverageWorkingRecord/?${stringified}`

  return await get(url)
}

export const exportEmployeeAverageHours = async (selectedEmpId, values) => {
  const baseUrl = getBaseUrlMicroServices();

  let stringified = ''
  let toParse = {};
  let filterParse = {}
  toParse.start_date = values.start_date
  toParse.end_date = values.end_date
  toParse.data_format = values.data_format
  toParse.employee = selectedEmpId
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.filter(e => e !== 'None').join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.headerbranch) {
    stringified += "&branch=" + filterParse.headerbranch
  }
  const url = `${baseUrl}/reports/employee/reports/employeeAvgWorkingReports/?${stringified}`
  // const url = `${baseUrl}/reports/employee/reports/employeeAvgWorkingReports/?start_date=2022-01-01&end_date=2023-01-01&data_format=week`
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });
  return response;
}

//Employee skill matrix apis
export const getEmployeeSkillMatrix = async (values) => {
  const baseUrl = getBaseUrl()
  let stringified = ''
  let filterParse = {};
  let toParse = {};
  console.log(values, "tooo")

  //date range
  if (values.date_from) {
    toParse.start_date = values?.date_from
  }

  if (values.date_to) {
    toParse.end_date = values?.date_to
  }
  if (values.expiration_status) {
    toParse.expiration_status = values.expiration_status
  }

  if (values.employees) {
    const empId = values?.employees?.join(",")
    filterParse.employee_id = empId;
  }

  if (values.status) {
    const ids = values?.status?.join(",")
    filterParse.skill_status_id = ids;
  }

  if (values.category) {
    const ids = values?.category?.join(",")
    filterParse.skill_category_id = ids;
  }

  stringified = queryString.stringify(toParse) || '';

  if (values.branch) {
    const branchId = values?.branch?.join(",")
    stringified += `&branch__id=${branchId}`
  } else if (values?.headerbranch?.length) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    stringified += `&branch__id=${branchIds}`;
  }

  if (filterParse.employee_id) {
    stringified += `&employee_id=${filterParse.employee_id}`
  }

  if (filterParse.skill_status_id) {
    stringified += `&skill_status_id=${filterParse.skill_status_id}`
  }

  if (filterParse.skill_category_id) {
    stringified += `&skill_category_id=${filterParse.skill_category_id}`
  }



  const url = `${baseUrl}/core/matrix/employee-skill/?${stringified && stringified}`

  return await get(url)
}

export const getDistinctSkillList = async (values) => {
  const baseUrl = getBaseUrl()
  let stringified = ''
  if (values.branch) {
    const branchId = values?.branch?.join(",")
    stringified += `&branch__id=${branchId}`
  } else if (values?.headerbranch?.length) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    stringified += `&branch__id=${branchIds}`;
  }
  const url = `${baseUrl}/core/metrics/skill/distinct-metrics-skill/?${stringified && stringified}`

  return await get(url)
}

export const getEmployeeCompetencyMatrix = async (values) => {
  const baseUrl = getBaseUrl()
  let stringified = ''
  let filterParse = {};
  let toParse = {};
  console.log(values, "tooo")

  //date range
  if (values.date_from) {
    toParse.start_date = values?.date_from
  }

  if (values.date_to) {
    toParse.end_date = values?.date_to
  }
  if (values.renewal_status) {
    toParse.renewal_status = values.renewal_status
  }

  if (values.employees) {
    const empId = values?.employees?.join(",")
    filterParse.employee_id = empId;
  }

  stringified = queryString.stringify(toParse) || '';

  if (values.branch) {
    const branchId = values?.branch?.join(",")
    stringified += `&branch__id=${branchId}`
  } else if (values?.headerbranch?.length) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    stringified += `&branch__id=${branchIds}`;
  }


  if (filterParse.employee_id) {
    stringified += `&employee_id=${filterParse.employee_id}`
  }

  const url = `${baseUrl}/core/matrix/employee-competency/?${stringified && stringified}`

  return await get(url)
}

export const getDistinctCompetencyList = async (values) => {
  const baseUrl = getBaseUrl()
  let stringified = ''
  if (values.branch) {
    const branchId = values?.branch?.join(",")
    stringified += `&branch__id=${branchId}`
  } else if (values?.headerbranch?.length) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    stringified += `&branch__id=${branchIds}`;
  }
  const url = `${baseUrl}/core/metrics/competency/distinct-metrics-competency/?${stringified && stringified}`

  return await get(url)
}

export const getEmployeeHrTaskMatrix = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let filterParse = {};
  let toParse = {};

  if (values.date_from) {
    toParse.date_from = values?.date_from
  }

  if (values.date_to) {
    toParse.date_to = values?.date_to
  }

  if (values.headerbranch) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    filterParse.branch__id = branchIds
  }

  if (values.employees) {
    const emp = values?.employees?.join(",")
    filterParse.employee_id = emp;
  }

  if (values.hr_task) {
    const hr_id = values?.hr_task?.join(",")
    filterParse.core_hr_task_id = hr_id
  }


  stringified = queryString.stringify(toParse) || '';

  // filter parse to to parse
  if (filterParse.branch__id) {
    stringified += `&branch__id=${filterParse.branch__id}`
  }

  if (filterParse.employee_id) {
    stringified += `&employee_id=${filterParse.employee_id}`
  }

  if (filterParse.core_hr_task_id) {
    stringified += `&core_hr_task_id=${filterParse.core_hr_task_id}`
  }

  const url = `${baseUrl}/employees/employee/employee-hr-task-matrix/?${stringified}`

  return await get(url)
}

//

export const getActiveEmployee = async (values) => {
  const baseUrl = getBaseUrl()
  const currentDate = moment().format("YYYY-MM-DD")
  let stringified = ''
  const toParse = {}

  toParse.date = values.date_to ? values.date_to : currentDate


  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/getActiveEmployeeList/?${stringified}`

  return await get(url)
}


// Employee care notes APIs
export const getEmployeeCareNotes = async (values, employeeId) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let stringified = '';

  toParse.limit = values.limitperpage;
  toParse.offset = values.offset;
  toParse.employee__id__in = employeeId
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/employee/employee-notes/?${stringified}`

  return await get(url)
}
export const setEmployeeCareNotes = async (values, employeeId) => {
  const baseUrl = getBaseUrl();
  const body = { ...values }

  const method = values.id ? PATCH : POST
  const url = values.id
    ? `${baseUrl}/employees/employee/employee-notes/${values.id}/?employee__id__in=${employeeId}`
    : `${baseUrl}/employees/employee/employee-notes/`

  return await post(url, body, method)
}

export const deleteEmployeeCareNote = async (id, selectedEmpId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/employee-notes/${id}/?employee__id__in=${selectedEmpId}`

  return await deleteFetch(url)
}


export const shouldUpdateEmpStatus = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  toParse.employee = values.employee
  toParse.employmentEndDate = values.last_working_date
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/shifts/regular-shift/get_employee_regular_shift_end_status/?${stringified}`
  // const url = `${baseUrl}/employees/getEmployeeWorkRelatedData/?${stringified}`

  return await get(url)
}

export const setRegularShiftEndDate = async (values) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const body = {
    ...values
  }
  const url = `${baseUrl}/shifts/regular-shift/bulkUpdateEndDate/`
  return await post(url, body, method)
}