import React, { useEffect, useState } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from "react-icons/bs"
import AddNewFunderSplit from 'components/DashboardComponents/Client/ClientServices/AddNewFunderSplit';
import {
  TitleSection,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import RateCard from "components/DashboardComponents/Client/AddModal/RateCard"
import { ImTab } from 'react-icons/im';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { PURPLE } from 'shared/styles/constants/colors';
import { AccordionArrow, AccordionContainer, StaticArrow } from 'components/DashboardComponents/LeftSidebar/styles';
import LineContainer from 'components/SharedComponents/LineContainer';
import { FaPlus } from 'react-icons/fa';
import { Fragment } from 'react';
import FunderSection from './FunderSection';


const Funder = ({
  useInput,
  deleteFunder,
  sectionStyle,
  nameSection,
  choices,
  setValues,
  values,
  dropDownHandleSelect,
  dropDownHandleSelectFunder,
  dropDownHandleSelectFunderBilling,
  setChangeAddress,
  billRate,
  payRate,
  travelRate,
  funderList,
  addNewSplitFunder,
  deleteSplitFunder,
  setErrorSplitPercentage,
  errorSplitPercentage,
  deletedSplitFunders,
  deletePermission,
  clientId,
  clientServicesFunderRead,
  clientServicesFunderCreate,
  clientServicesFunderUpdate,
  clientServicesFunderContactRead,
  clientServicesFunderContactCreate,
  clientServicesFunderContactUpdate,
  clientServicesFunderBillRateCardRead,
  clientServicesFunderBillRateCardCreate,
  clientServicesFunderBillRateCardUpdate,
  clientServicesFunderPayRateCardRead,
  clientServicesFunderPayRateCardCreate,
  clientServicesFunderPayRateCardUpdate, 
  editMode,
  setFormErrors
}) => {
  const [HideFunder, setHideFunder] = useState(true);
  const [HideContractPay, setHideContractPay] = useState(true);
  const [HideFunderPayBill, setHideFunderPayBill] = useState(true);

  const discount_hook = useInput(`discount`)
  const funder_cancellation_fees_hook = useInput(`funder_cancellation_fees`)
  const is_split_hook = useInput(`is_split`)
  useEffect(() => {
    if (!values.bill_rate) {
      values.bill_rate = []
    }
    if (!values.pay_rate) {
      values.pay_rate = []
    }

    if (!values.travel_pay_rate) {
      values.travel_pay_rate = []
    }

    setValues({ ...values })


  }, [])

  const dropDownHandleRate = (item, nameSection, index, list_key, category) => {
    if (list_key === 'funder_bill_rate') {
      values[nameSection][index].bill_rate_per_hour = billRate.find(rate => rate.id === item.rate)?.rate_per_hour
    }
    if (list_key === 'funder_pay_rate' && item.rate) {
      values[nameSection][index].pay_rate_per_hour = payRate.find(rate => rate.id === item.rate)?.rate_per_hour
    }
    if(list_key==='funder_travel_pay_rate'){
      values[nameSection][index].travel_pay_rate_per_hour= travelRate.find(rate=>rate.id===item.rate)?.rate_per_hour
    }
    values[nameSection][index][list_key] = item.rate
    setValues({ ...values })
  }
  const addNewRate = (nameSection) => {
    values[nameSection] = [...values[nameSection], {}]
    setValues({ ...values })
  }
  const deleteRate = (nameSection, category, index) => {
    setFormErrors([])
    values[nameSection].splice(index, 1)
    setValues({ ...values })
  }

  const changeFunderName = (e) => {
    values.service_funders.name = e.target.value
    setValues({ ...values })
  }

  const handleAccordionClick = () => {
    setHideFunder(!HideFunder);
  }

  const addNewVisit = () => {
    if (!values.service_funders) {
      values.service_funders = [];
    }
    values.service_funders.push({id: '', split_funders: []});
    setValues({...values});
  };


  return (
    <div className={sectionStyle}>
      <AccordionContainer>
        <AccordionArrow onClick={handleAccordionClick}>
          <div>
            {HideFunder ? (
              <IoIosArrowUp size={18} color={PURPLE} />
            ) : (
              <IoIosArrowDown size={18} color={PURPLE} />
            )}
          </div>
        </AccordionArrow>
        <TitleSection>Funder</TitleSection>
      </AccordionContainer>
      {!HideFunder &&
         <>
          {values && values.service_funders && values.service_funders.length >= 1 ? (
        values.service_funders.map((dataVisit, indexVisit) => {
          return (
           <FunderSection 
           indexVisit={indexVisit}
           handleDeleteFunder={deleteFunder}
           dataVisit={dataVisit}
           editMode={editMode}
           nameSection={'service_funders'}
           values={values}
           setValues={setValues}
           useInput={useInput}
           choices={choices}
           dropDownHandleSelect={dropDownHandleSelect}
           dropDownHandleSelectFunder={dropDownHandleSelectFunder}
           dropDownHandleSelectFunderBilling={dropDownHandleSelectFunderBilling}
           funderList={funderList}
           addNewSplitFunder={addNewSplitFunder}
           deleteSplitFunder={deleteSplitFunder}
           setErrorSplitPercentage={setErrorSplitPercentage}
           errorSplitPercentage={errorSplitPercentage}
           deletedSplitFunders={deletedSplitFunders}
           deletePermission={deletePermission}
           clientId={clientId}
           clientServicesFunderRead={clientServicesFunderRead}
           clientServicesFunderCreate={clientServicesFunderCreate}
           clientServicesFunderUpdate={clientServicesFunderUpdate}
           clientServicesFunderContactRead={clientServicesFunderContactRead}
           clientServicesFunderContactCreate={
             clientServicesFunderContactCreate
           }
           clientServicesFunderContactUpdate={
             clientServicesFunderContactUpdate
           }
           clientServicesFunderBillRateCardRead={
             clientServicesFunderBillRateCardRead
           }
           clientServicesFunderBillRateCardCreate={
             clientServicesFunderBillRateCardCreate
           }
           clientServicesFunderBillRateCardUpdate={
             clientServicesFunderBillRateCardUpdate
           }
           clientServicesFunderPayRateCardRead={
             clientServicesFunderPayRateCardRead
           }
           clientServicesFunderPayRateCardCreate={
             clientServicesFunderPayRateCardCreate
           }
           clientServicesFunderPayRateCardUpdate={
             clientServicesFunderPayRateCardUpdate
           }
           />
          );
        })
      ) : (
        <Fragment />
      )}
        <div className={'mt-2 mb-2'}>
        <PrimaryButtonForm minWidth="6rem" onClick={addNewVisit}  disabled={!editMode}>
          <FaPlus />
          Funder
        </PrimaryButtonForm>
      </div>
         </>
        }

      {/* {(clientId ? clientServicesFunderBillRateCardRead : clientServicesFunderBillRateCardCreate) &&
        <>
          <AccordionContainer>
            <AccordionArrow onClick={() => setHideFunderPayBill(!HideFunderPayBill)}>
              <div>
                {HideFunderPayBill ? (
                  <IoIosArrowUp size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowDown size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Funder Bill/Pay Details</TitleSection>
          </AccordionContainer>

          {!HideFunderPayBill &&
            <>
              <FlexGrid3Container className="d-flex pb-2 mt-2">
                <FlexGrid3Item className="pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Invoice Type'}
                    options={choices.Invoice_type}
                    selectedOption={
                      values.invoice_type
                        ? values.invoice_type
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { invoice_type: value ? value : null },
                        'invoice_type'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 ">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Bill Rate Model'}
                    options={choices.bill_rate_model}
                    selectedOption={
                      values
                        ? values.billing_mode
                          ? values.billing_mode
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { billing_mode: value ? value : null },
                        'billing_mode'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 ">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Billing Duration Type'}
                    options={choices.billing_duration_type}
                    selectedOption={
                      values
                        ? values.billing_duration_type
                          ? values.billing_duration_type
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { billing_duration_type: value ? value : null },
                        'billing_duration_type'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pr-1 mt-2">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Billing Duration Source'}
                    options={choices.billing_duration_source}
                    selectedOption={
                      values
                        ? values.billing_duration_source
                          ? values.billing_duration_source
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { billing_duration_source: value ? value : null },
                        'billing_duration_source'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Bill Time Unit'}
                    options={choices.bill_time_unit}
                    selectedOption={
                      values
                        ? values.bill_time_unit
                          ? values.bill_time_unit
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { bill_time_unit: value ? value : null },
                        'bill_time_unit'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                  <InputLabelTop
                    disabled={!editMode}
                    type="text"
                    id="funder_discount"
                    label={'Funder discount'}
                    {...discount_hook}
                    maxlength={25}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pr-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Billing Pattern'}
                    options={choices.Billing_patterns}
                    selectedOption={
                      values
                        ? values.pattern
                          ? values.pattern
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { pattern: value ? value : null },
                        'pattern'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Billing Term'}
                    options={choices.Billing_terms}
                    selectedOption={
                      values
                        ? values.billing_term
                          ? values.billing_term
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { billing_term: value ? value : null },
                        'billing_term'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                  <DropdownSearchable
                    disabled={!editMode}
                    placeHolder={'Payment Method'}
                    options={choices.Payment_method}
                    selectedOption={
                      values
                        ? values.payment_method
                          ? values.payment_method
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelectFunderBilling(
                        { payment_method: value ? value : null },
                        'payment_method'
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pr-1 mt-2">
                  <InputLabelTop
                    disabled={!editMode}
                    type="text"
                    id="funder_cancel_fees"
                    label={'Funder Cancellation Fees'}
                    {...funder_cancellation_fees_hook}
                    maxlength={25}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                  <ToggleButton
                    disabled={!editMode}
                    label="Split funding"
                    useInput={is_split_hook}
                    id="funder_is_split"
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
              <div>
                <FlexGrid3Container className="d-flex pb-2 mt-2">
                  <FlexGrid3Item className="pr-1">
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Pay Rate Modal'}
                      options={choices.pay_rate_model}
                      selectedOption={
                        values
                          ? values.pay_rate_model
                            ? values.pay_rate_model
                            : null
                          : null
                      }
                      onChange={(value) =>
                        dropDownHandleSelectFunderBilling(
                          { pay_rate_model: value ? value : null },
                          'pay_rate_model'
                        )
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Pay Duration Type'}
                      options={choices.pay_duration_type}
                      selectedOption={
                        values
                          ? values.pay_duration_type
                            ? values.pay_duration_type
                            : null
                          : null
                      }
                      onChange={(value) =>
                        dropDownHandleSelectFunderBilling(
                          { pay_duration_type: value ? value : null },
                          'pay_duration_type'
                        )
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Pay Duration Source'}
                      options={choices.pay_duration_source}
                      selectedOption={
                        values
                          ? values.pay_duration_source
                            ? values.pay_duration_source
                            : null
                          : null
                      }
                      onChange={(value) =>
                        dropDownHandleSelectFunderBilling(
                          { pay_duration_source: value ? value : null },
                          'pay_duration_source'
                        )
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pr-1 mt-2">
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Pay Time Unit'}
                      options={choices.pay_time_unit}
                      selectedOption={
                        values
                          ? values.pay_time_unit
                            ? values.pay_time_unit
                            : null
                          : null
                      }
                      onChange={(value) =>
                        dropDownHandleSelectFunderBilling(
                          { pay_time_unit: value ? value : null },
                          'pay_time_unit'
                        )
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Contract/Employee Pay Rate'}
                    />
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
            </>
          }
        </>
      } */}


      {(clientId ? clientServicesFunderPayRateCardRead : clientServicesFunderPayRateCardCreate) &&
        <>
          <AccordionContainer>
            <AccordionArrow onClick={() => setHideContractPay(!HideContractPay)}>
              <div>
                {HideContractPay ? (
                  <IoIosArrowUp size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowDown size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Contract Rate Card Details</TitleSection>
          </AccordionContainer>
          {!HideContractPay &&
            <div>
              <TitleSection >Contract Bill</TitleSection>
              <div>

                {
                  values && values.bill_rate && values.bill_rate.map((bill_rate, indexBill) => {
                    return (
                      <RateCard
                        editMode={editMode}
                        values={values}
                        data={billRate}
                        list_key1={'funder_bill_rate'}
                        dropDownHandleRate={dropDownHandleRate}
                        useInput={useInput}
                        nameSection={'bill_rate'}
                        index={indexBill}
                        deleteRate={deleteRate}
                      />
                    )
                  })
                }
              </div>
              <PrimaryButtonForm
                disabled={!editMode}
                className={'ml-3 mt-1 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewRate('bill_rate')}
              >
                <span>{<BsFillPlusSquareFill />}Rate Card</span>
              </PrimaryButtonForm>
             
              <TitleSection >Contract Pay</TitleSection>
              <div>

                {
                  values && values.pay_rate && values.pay_rate.map((pay_rate, indexPay) => {
                    return (
                      <RateCard
                        editMode={editMode}
                        values={values}
                        data={payRate}
                        list_key1={'funder_pay_rate'}
                        dropDownHandleRate={dropDownHandleRate}
                        useInput={useInput}
                        nameSection={'pay_rate'}
                        index={indexPay}
                        deleteRate={deleteRate}
                        payrate={true}
                        travelrate={true}
                      />
                    )
                  })
                }
              </div>
              <PrimaryButtonForm
                disabled={!editMode}
                className={'ml-3 mt-1 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewRate('pay_rate')}
              >
                <span>{<BsFillPlusSquareFill />}Rate Card</span>
              </PrimaryButtonForm>

              <TitleSection >Contract Travel</TitleSection>
              <div>

                {
                  values && values.travel_pay_rate && values.travel_pay_rate.map((pay_rate, indexTravel) => {
                    return (
                      <RateCard
                        editMode={editMode}
                        values={values}
                        data={travelRate}
                        list_key1={'funder_travel_pay_rate'}
                        dropDownHandleRate={dropDownHandleRate}
                        useInput={useInput}
                        nameSection={'travel_pay_rate'}
                        index={indexTravel}
                        deleteRate={deleteRate}
                        travelrate={true}
                      />
                    )
                  })
                }
              </div>
              <PrimaryButtonForm
                disabled={!editMode}
                className={'ml-3 mt-1 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewRate('travel_pay_rate')}
              >
                <span>{<BsFillPlusSquareFill />}Rate Card</span>
              </PrimaryButtonForm>
            </div>}
        </>
      }
    </div>
  );
};

Funder.propTypes = {
  useInput: PropTypes.func.isRequired,
  sectionStyle: PropTypes.string,
  nameSection: PropTypes.string.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

Funder.defaultProps = {
  sectionStyle: '',
};

export default Funder;
