import {LARGE_COL  ,  MEDIUM_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Group Area',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
   {
    label: 'Postal Code',
    name: 'Postal_code',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
 {
  label: 'Supervisor',
  name: 'supervisor__name',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
];
