import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {KeyPress} from 'utils/constants/regularExpressions';
import {TitleSection, FlexEqual} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';

const Contact = ({
  useInput,
  nameSection,
  sectionStyle,
  choices,
  values,
  dropDownHandleSelect,
  inviteUserStatus,
  readOnly
}) => {
  return (
    <FlexEqual marginTopSm noMarginLeft className={sectionStyle}>
      <TitleSection>Contact</TitleSection>
      <div className="d-flex flex-column mt-2">
        {/* <InputLabelTop
          required
          type="text"
          id="telephone"
          label={'Telephone'}
          onKeyPress={KeyPress.PHONE}
          {...useInput(
            `${[nameSection]}.telephone`,
            'isMobilePhone, isRequired',
          )}
          maxlength={25}
          
        /> */}


<div className="d-flex">
          <div className="w-100 mr-2">
            <InputLabelTop
           required
           readOnly={readOnly}
          type="text"
          id="telephone_home"
          label={'Telephone (Preferred)'}
          onKeyPress={KeyPress.PHONE}
          {...useInput(
            `${[nameSection]}.telephone`,
            'isMobilePhone, isRequired',
          )}
          maxlength={25}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
          type="text"
          readOnly={readOnly}
          id="mobile"
          label={' Mobile'}
          onKeyPress={KeyPress.PHONE}
          {...useInput(
            `${[nameSection]}.mobile`,
            'isMobilePhone',
          )}
          maxlength={25}  />
          </div>
        </div>

        <div className="d-flex">
          <div className="w-100 mr-2">
            <InputLabelTop
              type="email"
              readOnly={readOnly}
              required={inviteUserStatus?'required':''}
              // id="email_personal"
              // label={'Personal email'}
              id="work_email"
              label={'Work email'}
              {...useInput(`${[nameSection]}.email_personal`, inviteUserStatus?'isEmail, isRequired' :'')}
              // {...useInput(`${[nameSection]}.email_personal`,inviteUserStatus? 'isEmail, isRequired' : '')}
              maxlength={254}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              type="email"
              readOnly={readOnly}
              id="email_personal"
              label={'Personal email'}
              // id="work_email"
              // label={'Work email'}
              {...useInput(`${[nameSection]}.email_work`, 'isEmail')}
        

              maxlength={254}
            />
          </div>
        </div>

        <div className="w-100 mr-2">
          <InputLabelTop
            type="text"
            readOnly={readOnly}
            id="keysafe"
            label={'Keysafe Code'}
            {...useInput(`${[nameSection]}.keysafe`)}
            maxlength={5000}
          />
        </div>
        <div className="w-100 mr-2">
          <DropdownSearchable
            placeHolder={'Preferred Contact Method'}
            options={choices.contact_method}
            disabled={readOnly}
            selectedOption={
              values[nameSection] ? values[nameSection].contact_method : null
            }
            onChange={value =>
              dropDownHandleSelect({
                [nameSection]: {contact_method: value ? value : null},
              })
            }
          />
        </div>
        <div className="w-100 mt-2">
          <DropdownSearchable
            placeHolder={'Preferred Marketing Method'}
            disabled={readOnly}
            options={choices.marketing_method}
            selectedOption={
              values[nameSection] ? values[nameSection].marketing_method : null
            }
            onChange={value =>
              dropDownHandleSelect({
                [nameSection]: {marketing_method: value ? value : null},
              })
            }
          />
        </div>
      </div>
    </FlexEqual>
  );
};

Contact.propTypes = {
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string,
  values: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
};

Contact.defaultProps = {
  sectionStyle: '',
};

export default Contact;
