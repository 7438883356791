import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import {useState} from 'react';
import {useEffect,useRef} from 'react';
import {ErrorMessage, ModalContainer} from 'shared/styles/constants/tagsStyles';
import {useForm} from 'hooks/FormHook';
import { setMedError } from 'utils/api/ClientApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { Toast } from 'primereact/toast';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {Fragment} from 'react';
const MedicationErrorType = ({
  fullBind,
  data,
 
  choices,
  editMode,

  errorMessage,
  showError,setMedBulkEdit,checkedRows,setMedicationError,
  dropDownHandleSelect = () => {},
  clearSelectedElId
}) => {
    const {values, setValues, useInput} = useForm({});
    const toast = useRef();

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.review_comment`);
  const newDoseTime = useInput(`medication.review_time`, 'isRequired');
  const review_date_hook = useInput('medication.review_date', 'isRequired');
  const review_status_hook = useInput(`medication.review_status`, 'isRequired');
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication Error',
    });
  }, [fullBind]);
  const dropDownHandleRunRoute = item => {
  
    values.medication_error = item.medication_error;
    setValues({...values,...item});

  };
const handleAddMedicationError=async()=>{
 let  body= {
    medication:[...checkedRows],
    medicationError:values?.medication_error_type.map((item)=>item.value),
    comment:values.comments

  }
   let response = await setMedError(body)
   if(response.status){
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${response.message}`,
    });
    clearSelectedElId()
    setTimeout(()=>{
      setMedicationError(false)

    },1000)

   }else{

    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${response.message}`,
    });
   }

   setTimeout(()=>{
    setMedicationError(false)

  },1000)
}
  return (
    <ModalContainer max_width={'60%'}>
              <Toast ref={toast} position="top-right" />

      <ModalDynamic
      style={{padding:'5px'}}
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className="d-flex flex-column mt-2" >

          <div className="d-flex mb-2">
            <div
              className="w-100 mr-2"
              style={{marginLeft: '10px', marginRight: '10px'}}>
              <DropdownSearchable
                          isMulti={true}
                            placeHolder={'Medication Error'}
                            options={choices.medication_error_type}
                           
                            selectedOptionsProps={
                              values
                                ? values.medication_error_type
                                  ? values.medication_error_type
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleRunRoute(
                                {medication_error_type: value ? value : null},
                                'medication_error_type'
                              )
                            }
                          />
            </div>
            
          </div>
        </div>

        <FlexGrid3Item className="pr-1 mb-2" ResizeWidth={'100%'} style={{marginLeft:"8px" ,marginRight:'2px'}}>
                <small className="pr-1 " style={{color: 'black'}}>
                  Comments
                </small>
                <InputTextArea
                 
                  type="text"
                  height={'4rem'}
                  id="comments"
                  label={'Comments'}
                  {...useInput('comments')}
                  maxlength={5}
                />
              </FlexGrid3Item>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {showError ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ml-0 ml-md-3 mr-2'}>
              <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                style={{marginBottom: '4px'}}
                onClick={handleAddMedicationError}>
                Save
              </PrimaryButtonForm>
            </div>
            <div></div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default MedicationErrorType;
