import React from 'react'
import { FaTimes } from 'react-icons/fa';
import './style.css'
import Modal from 'react-bootstrap4-modal';
import { CancelButton, PrimaryButtonModal, Body, SubBody } from './styles';
import { Fragment } from 'react';

function ConfirmModal({
    type,
    max_width,
    title,
    body,
    subBody,
    showModal,
    onOk,
    onCancel,
    okTitle,
    hideOnCancel,
    hideOnOk,
    onCancelTitle,
    openModal,
    setOpenModal
}) {
    const iconType = () => {
        switch (type) {
            case 'delete':
                return <FaTimes />;
            default:
                return <></>;
        }
    };
    return (
        <div className='visit-body-map'>
            <Modal visible={openModal}     >
                <div className="modal-header border-0">
                    <h5 className="modal-title">{title}</h5>
                </div>
                <div className="modal-body text-center">
                    <Body>{body}</Body>
                    <SubBody>{subBody}</SubBody>
                </div>
                <div className="d-flex justify-content-center modal-footer border-0">
                    {hideOnCancel ? (
                        <Fragment />
                    ) : (
                        <CancelButton
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setOpenModal(false)}>
                            {onCancelTitle || 'CANCEL'}
                        </CancelButton>
                    )}
                    {hideOnOk ? (
                        <Fragment />
                    ) : (
                        <PrimaryButtonModal typeButton={type} onClick={onOk}>
                            {iconType()}
                            {okTitle || 'Ok'}
                        </PrimaryButtonModal>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default ConfirmModal
