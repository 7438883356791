import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { Toast } from 'primereact/toast';
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FormContainer, MainContainer } from './FormModal/styles';
import { useContext } from 'react';
import { AppContext } from 'context';
import { useEffect } from 'react';
import { getClientCareForms } from 'utils/api/ClientApi';
import { Fragment } from 'react';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { PURPLE } from 'shared/styles/constants/colors';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import FormViewer from '../AddModal/formViewer/formViewer';
import ShowForm from './FormModal/ShowForm';

function ClientCarePlans({
  selectedClientId,
  props,
  name
}) {
  const toast = useRef(null);

  const { contextChoices, handleGetChoices, setContextChoices } = useContext(AppContext);

  const [pageLoader, setPageLoader] = useState(true)
  const [careForms, setCareForms] = useState([])
  const [expand, setExpand] = useState("")
  const [createdForms, setCreatedForms] = useState([])
  const [showForm, setShowForm] = useState({ name: "", visible: false })
  const [formData, setFormData] = useState(null)

  const getClientCareFormsData = async () => {
    try {
      const res = await getClientCareForms(selectedClientId)
      setCareForms(res?.results || []);
      setPageLoader(false)
    } catch (error) {
      console.error("Error: Fetting client care form", error)
    }
  }

  useEffect(() => {
    if (!selectedClientId) return;
    getClientCareFormsData()
  }, [selectedClientId])


  const handleExpand = (index) => {
    setExpand(prev => prev === index ? "" : index)
  }


  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ComponentDynamic loading={pageLoader}>
        <MainContainer>
          <div className='w-100 d-flex justify-content-center'>
            <TitleSection>Client Care Plans</TitleSection>
          </div>
          {careForms &&
            careForms.map((item, index) => {
              return (
                <div className="care-task-title" style={{ marginTop: "1rem" }}>
                  <AccordionContainer>
                    <AccordionArrow style={{ marginLeft: "-30px" }}
                      onClick={() => {
                        handleExpand(index);
                        setCreatedForms(() => {
                          return JSON.parse(item.json_data)
                        })
                        setShowForm({ name: item.name, visible: true })
                        setFormData(item)
                      }}>
                      <div>
                        {expand === index ? (
                          <IoIosArrowUp size={18} color={PURPLE} />
                        ) : (
                          <IoIosArrowDown size={18} color={PURPLE} />
                        )}
                      </div>
                    </AccordionArrow>
                    <TitleSection>{item.name}</TitleSection>
                  </AccordionContainer>
                  <Fragment>
                    {
                      expand === index && (
                        <FormContainer Shadow >
                          <ShowForm
                            children={
                              <>
                                <FormViewer
                                  jsonData={createdForms}
                                  contextChoices={contextChoices}
                                  setJsonData={setCreatedForms}
                                  name={name}
                                  parent={'client_care_plans'}
                                />

                              </>
                            }
                            title={showForm.name}
                            onClose={() => {
                              setShowForm({ name: "", visible: false })
                              setExpand("")
                            }}
                            showModal={showForm.visible}
                            max_width={'60%'}
                            minWidthTitle="8.5rem"
                            loading={false}
                            data={[]}
                          />
                        </FormContainer>
                      )
                    }
                  </Fragment>
                </div>
              );
            })}

        </MainContainer>

      </ComponentDynamic>
    </div>
  )
}

export default ClientCarePlans