import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {
  FlexGrid3Item,
  ModalContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import "./VisitBodyMapStyle.css"
import { useCallback } from 'react';
import MultiSelectBox from './MultiSelect/MultiSelectBox';

const Modal = ({
  fullBind,
  setModalBodyMap,
  useInput,
  values,
  setValues,
  addBodyParts,
  updateBodyParts,
  update,
  //   isClient,
  setBackMode,
  backMode,
  seletedMode,
  setSelectedMode

}) => {

  const [loadingModal, setLoadingModal] = useState(false);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  // const [data, setData] = useState(values?.frontBodyParts?.tittle)
  // const [frontParts , setFrontParts] = useState([])
  // const [backParts , setBackParts] = useState([])
  // const [backMode, setBackMode] = useState(true);
  const toast = useRef();
  // const listOfBodyParts = ["trapezius", "upper-back", "lower-back", "chest", "biceps", "triceps", "forearm", "back-deltoids", "front-deltoids", "abs", "obliques", "adductor", "abductors", "hamstring", "quadriceps", "calves", "gluteal", "head", "neck", "knees", "left-soleus", "right-soleus",]
  // const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck']
  // const muscleFromBody = listOfBodyParts.includes(values.tempBodyMapData.tittle)
  // const data = [
  //   { name: '', muscles:
  //    (muscleFromBody && values?.tempBodyMapData?.tittle) ? [values.tempBodyMapData.tittle] : [] },
  // ];

  // const frontData = [
  //   {
  //     name: "",
  //     muscles: values?.frontBodyParts?.tittle || []
  //   }
  // ]

  // const backData = [
  //   {
  //     name: "name",
  //     muscles: values?.backBodyParts?.tittle || []
  //   }

  // ]

  const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck']
  const muscleFromBody = listOfBodyParts.includes(values.tempBodyMapData.tittle)
  const data = [
    { name: '', muscles: (muscleFromBody && values?.tempBodyMapData?.tittle) ? [values.tempBodyMapData.tittle] : [] },
  ];

  const handleFrontClick = ({ muscle, data }) => {
    // const { exercises, frequency } = data;
    //if(values.tempBodyMapData){

    // setFrontParts([...frontParts,muscle])
    // values.tempBodyMapData['back'] = []
    // values.tempBodyMapData.back = []
    // values.tempBodyMapData.isBakMode = false

    // values.frontBodyParts.tittle = [...values.frontBodyParts.tittle, muscle]
    // setValues({ ...values })
    if (!values.frontBodyParts?.tittle.includes(muscle)) {
      setValues(prevValues => ({
        ...prevValues,
        frontBodyParts: {
          ...prevValues.frontBodyParts,
          tittle: [...prevValues.frontBodyParts.tittle, muscle]
        }
      }))
    }

    // }
    // alert(`You clicked the ${muscle}! You've worked out this muscle ${frequency} times through the following exercises: ${JSON.stringify(exercises)}`)

  }
  const arr = []
  const handleBackClick = ({ muscle, data }) => {
    // const { exercises, frequency } = data;
    //if(values.tempBodyMapData){

    // setBackParts([...backParts, muscle])
    // values.tempBodyMapData.tittle = [...values.tempBodyMapData.tittle,muscle]
    // values.tempBodyMapData.isBakMode = true

    //  arr.push(muscle)
    // values.backBodyParts = {...values.backBodyParts, tittle: [...values.backBodyParts.tittle,muscle]}
    // setValues({ ...values })
    if (!values.backBodyParts?.tittle.includes(muscle)) {
      setValues(prevValues => ({
        ...prevValues,
        backBodyParts: {
          ...prevValues.backBodyParts,
          tittle: [...prevValues.backBodyParts.tittle, muscle]
        }
      }));
    }
    // }
    // setValues({ ...values })
    // alert(`You clicked the ${muscle}! You've worked out this muscle ${frequency} times through the following exercises: ${JSON.stringify(exercises)}`)

  }
  // const spiltBodyPart = (array1, array2) => {
  //   const joinedArray1 = array1.length ? array1.join(",") : "";
  //   const joinedArray2 = array2.length ? array2.join(",") : "";
  //   return joinedArray1 + (joinedArray1 && joinedArray2 ? "," : "") + joinedArray2
  // }
  // useEffect(() => {
  //   if (values.frontBodyParts?.tittle?.length || values.backBodyParts?.tittle?.length)
  //     values.tempBodyMapData.tittle =
  //       values.tempBodyMapData = {
  //         tittle: spiltBodyPart(values.frontBodyParts?.tittle, values.backBodyParts?.tittle),
  //         description: values.tempBodyMapData.description
  //       }
  //   setValues({ ...values });

  // }, [values.frontBodyParts?.tittle, values.backBodyParts?.tittle])

  // useEffect(() => {

  //   setAddFullBind({
  //     ...addFullBind,
  //     title: 'Body Map',

  //   });
  //   setLoadingModal(false);

  // }, [fullBind]);

  // console.log("body parts", frontData, backData)

  const addBodyMuscle = () => {
    if (values.tempBodyMapData.tittle && values.tempBodyMapData.description) {
      if (values.tempBodyMapData.index) {
        values.alert_body_part[values.tempBodyMapData.index] = values.tempBodyMapData;
      } else {
        values.alert_body_part = [...values.alert_body_part, { ...values.tempBodyMapData }]
      }
      setValues({ ...values })

      update ? updateBodyParts(backMode) : addBodyParts(backMode)

      setModalBodyMap(false)
    } else {
      toast && toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: (!values.tempBodyMapData.tittle || !values.tempBodyMapData.description) ? 'Please fill the mandatory fields' : '',
      });
    }
  }

  // const handleRemoveFrontBodyParts = (data, index) => {
  //   let newData = [...data]
  //   newData.splice(index, 1)
  //   setValues(prevValues => ({
  //     ...prevValues,
  //     frontBodyParts: {
  //       tittle: [...newData]
  //     },
  //   }));
  // }
  // const handleRemoveBackBodyParts = (data, index) => {
  //   let newData = [...data]
  //   newData.splice(index,  1)
  //   setValues(prevValues => ({
  //     ...prevValues,
  //     backBodyParts: {
  //       tittle: [...newData]
  //     }
  //   }));
  // }

  // useEffect(() => {
  //   if (seletedMode == "front") {
  //     setData(values?.frontBodyParts?.tittle)
  //   } else if (seletedMode == "back") {
  //     setData(values?.backBodyParts?.tittle)
  //   } else {
  //     setData([...values?.frontBodyParts?.tittle, ...values?.backBodyParts?.tittle])
  //   }
  // }, [seletedMode, values?.frontBodyParts?.tittle, values?.backBodyParts?.tittle])

  const handleClick = React.useCallback(({ muscle, data }) => {
    // const { exercises, frequency } = data;
    //if(values.tempBodyMapData){
    values.tempBodyMapData.tittle = muscle
    // }
    setValues({ ...values })
    // alert(`You clicked the ${muscle}! You've worked out this muscle ${frequency} times through the following exercises: ${JSON.stringify(exercises)}`)

  }, []);

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <Toast ref={toast} position="top-right" />

        <div className='d-flex'>
          <div className="col-4">
            <Model
              data={data}
              style={{ width: '16rem', padding: '1rem' }}
              onClick={handleClick}
              type={backMode ? 'posterior' : 'anterior'}
            />

          </div>
          <div className='col-8' style={{ paddingLeft: '4rem', paddingTop: '6rem' }}>
            <ToggleDiv>
              <ToggleWrapper>
                <Toggle
                  id="checkbox"
                  type="checkbox"
                  checked={backMode}
                // onClick={()=> setBackMode(true)}
                // onClick={() => {
                //   backMode ? setBackMode(false) : setBackMode(true);
                // }}
                />
                <ToggleLabel htmlFor="checkbox" onClick={() => {
                  backMode ? setBackMode(false) : setBackMode(true);
                }}
                />
              </ToggleWrapper>
              <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
                <p>Back</p>
              </div>
            </ToggleDiv>
            <FlexGrid3Item className="pr-1 mt-1 ">
              <InputLabelTop
                // disabled={readonly}
                required
                type="text"
                id="name"
                label={'Title'}
                {...useInput('tempBodyMapData.tittle')}
                maxlength={50}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 mb-2">
              <small className="pr-1 " style={{ color: 'black' }}>
                Description <span style={{ color: ERR_COLOR }}>*</span>
              </small>
              <InputTextArea
                // disabled={readonly}
                required
                type="text"
                height={'4rem'}
                id="description"
                label={'Description'}
                //onChange={(val)=>handleOnChangeDescription(val)}
                {...useInput('tempBodyMapData.description')}
                maxlength={8}
              />
            </FlexGrid3Item>
            <div className=''>

              <PrimaryButtonForm
                minWidth="6rem"
                onClick={addBodyMuscle}
              //disabled={readonly && !update ? true : false}
              >
                <span className={'mr-2'}>{update ? "Update" : "Add"}</span>

              </PrimaryButtonForm>

            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>

    //Updated code


    // <div className='visit-body-map1'>
    //   <ModalContainer max_width={'40%'}>
    //     <ModalDynamic
    //       {...addFullBind}
    //       max_width={'50%'}
    //       minWidthTitle="8.5rem"
    //       loading={loadingModal}>
    //       <Toast ref={toast} position="top-right" />

    //       <div
    //       // className='d-flex'
    //       >
    //         <div className='d-flex justify-content-center'>
    //           <ToggleDiv>
    //             <ToggleWrapper>
    //               <Toggle
    //                 id="checkbox"
    //                 type="checkbox"
    //                 checked={seletedMode == "front"}
    //                 // onClick={()=> setBackMode(true)}
    //                 // onClick={() => {
    //                 //   backMode ? setBackMode(false) : setBackMode(true);
    //                 // }}
    //                 onClick={() => setSelectedMode("front")}
    //               />
    //               <ToggleLabel htmlFor="checkbox" onClick={() => {
    //                 setSelectedMode("front")
    //                 // backMode ? setBackMode(false) : setBackMode(true);
    //               }}
    //               />
    //             </ToggleWrapper>
    //             <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
    //               <p>Front</p>
    //             </div>
    //           </ToggleDiv>
    //           <ToggleDiv>
    //             <ToggleWrapper>
    //               <Toggle
    //                 id="checkbox"
    //                 type="checkbox"
    //                 checked={seletedMode == "back"}
    //                 // onClick={()=> setBackMode(true)}
    //                 // onClick={() => {
    //                 //   backMode ? setBackMode(false) : setBackMode(true);
    //                 // }}
    //                 onClick={() => setSelectedMode("back")}
    //               />
    //               <ToggleLabel htmlFor="checkbox" onClick={() => {
    //                 setSelectedMode("back")
    //                 // backMode ? setBackMode(false) : setBackMode(true);
    //               }}
    //               />
    //             </ToggleWrapper>
    //             <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
    //               <p>Back</p>
    //             </div>
    //           </ToggleDiv>
    //           <ToggleDiv>
    //             <ToggleWrapper>
    //               <Toggle
    //                 id="checkbox"
    //                 type="checkbox"
    //                 checked={seletedMode == "both"}
    //                 // onClick={()=> setBackMode(true)}
    //                 // onClick={() => {
    //                 //   backMode ? setBackMode(false) : setBackMode(true);
    //                 // }}
    //                 onClick={() => setSelectedMode("both")}
    //               />
    //               <ToggleLabel htmlFor="checkbox" onClick={() => {
    //                 setSelectedMode("both")
    //                 // backMode ? setBackMode(false) : setBackMode(true);
    //               }}
    //               />
    //             </ToggleWrapper>
    //             <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
    //               <p>Both</p>
    //             </div>
    //           </ToggleDiv>
    //         </div>
    //         <div className="col-12 d-flex">
    //           {seletedMode == "front" && (
    //             <div className='col-12 d-flex flex-column align-items-center'>
    //               <div className="toggle-label ml-2  mb-3 mr-2">

    //                 <div style={{ fontSize: "1rem", fontWeight: "400" }}>
    //                   Front
    //                 </div>
    //               </div>
    //               <Model
    //                 data={frontData}
    //                 style={{ width: '16rem', height: "25rem" }}
    //                 onClick={handleFrontClick}
    //                 type={'anterior'}
    //               />
    //             </div>
    //           )}
    //           {seletedMode == "back" && (
    //             <div className='col-12 d-flex flex-column align-items-center '>
    //               <div className="toggle-label ml-2  mb-3 mr-2">

    //                 <div style={{ fontSize: "1rem", fontWeight: "400" }}>
    //                   Back
    //                 </div>
    //               </div>
    //               <Model
    //                 data={backData}
    //                 style={{ width: '16rem', height: "25rem" }}
    //                 onClick={handleBackClick}
    //                 type={'posterior'}
    //               />
    //             </div>
    //           )
    //           }
    //           {
    //             seletedMode == "both" && (
    //               <>
    //                 <div className='col-6 d-flex flex-column align-items-center'>
    //                   <div className="toggle-label ml-2  mb-3 mr-2">

    //                     <div style={{ fontSize: "1rem", fontWeight: "400" }}>
    //                       Front
    //                     </div>
    //                   </div>
    //                   <Model
    //                     data={frontData}
    //                     style={{ width: '16rem', height: "25rem" }}
    //                     onClick={handleFrontClick}
    //                     type={'anterior'}
    //                   />
    //                 </div>
    //                 <div className='col-6 d-flex flex-column align-items-center '>
    //                   <div className="toggle-label ml-2  mb-3 mr-2">

    //                     <div style={{ fontSize: "1rem", fontWeight: "400" }}>
    //                       Back
    //                     </div>
    //                   </div>
    //                   <Model
    //                     data={backData}
    //                     style={{ width: '16rem', height: "25rem" }}
    //                     onClick={handleBackClick}
    //                     type={'posterior'}
    //                   />
    //                 </div>
    //               </>
    //             )
    //           }
    //         </div>
    //         <div className='col-12' style={{ padding: "1.5rem 4rem" }}>
    //           {/* <ToggleDiv>
    //         <ToggleWrapper>
    //           <Toggle
    //             id="checkbox"
    //             type="checkbox"
    //             checked=ees,quadriceps,abductors,chest,abs{backMode}
    //           // onClick={()=> setBackMode(true)}
    //           // onClick={() => {
    //           //   backMode ? setBackMode(false) : setBackMode(true);
    //           // }}
    //           />
    //           <ToggleLabel htmlFor="checkbox" onClick={() => {
    //             backMode ? setBackMode(false) : setBackMode(true);
    //           }}
    //           />
    //         </ToggleWrapper>
    //         <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
    //           <p>Back</p>
    //         </div>
    //       </ToggleDiv> */}
    //           <FlexGrid3Item className="pr-1 mt-1 ">
    //             <InputLabelTop
    //               // disabled={readonly}
    //               required
    //               type="text"
    //               id="name"
    //               label={'Title'}

    //               {...useInput('tempBodyMapData.tittle')}
    //               maxlength={50}
    //             />
    //           </FlexGrid3Item>

    //           <FlexGrid3Item className="pr-1 mt-1 ">
    //             <div >
    //               <small className="pr-1 " style={{ color: 'black' }}>
    //                 Body Parts
    //               </small>
    //               {<div id='multi-select-bxx' className='d-flex ctr'
    //                 style={{
    //                   backgroundColor: "rgb(250, 250, 252)",
    //                   border: "1px solid rgba(98, 0, 249, 0.21)",
    //                   borderRadius: "3px",
    //                   minHeight: "2.4rem",
    //                   flexWrap: "wrap",
    //                 }}>
    //                 {values.frontBodyParts.tittle.length ? values.frontBodyParts.tittle.map((el, index) => {
    //                   return (
    //                     <div class="slt-ctr">
    //                       <span class="slt-value-icon" aria-hidden="true" onClick={() => handleRemoveFrontBodyParts(values.frontBodyParts.tittle, index)}>×</span>
    //                       <span class="slt-value-label" role="option" aria-selected="true" id="react-select-14--value-1">{el}<span class="slt-aria-only">&nbsp;</span></span>
    //                     </div>)
    //                 }) : null}
    //                 {values.backBodyParts.tittle.length ? values.backBodyParts.tittle.map((el, index) => {
    //                   return (
    //                     <div class="slt-ctr">
    //                       <span class="slt-value-icon" aria-hidden="true" onClick={() => handleRemoveBackBodyParts(values.backBodyParts.tittle, index)}>×</span>
    //                       <span class="slt-value-label" role="option" aria-selected="true" id="react-select-14--value-1">{el}<span class="slt-aria-only">&nbsp;</span></span>
    //                     </div>)
    //                 }) : null}
    //               </div>}
    //             </div>
    //           </FlexGrid3Item>
    //           <div className='col-12'>
    //             <div className='d-flex cal-6'>
    //               {/* {values.frontBodyParts?.tittle?values.frontBodyParts?.tittle.map((el, index) => {
    //     return (
    //       <div className='d-flex me-2'>
    //         <p className='body-part-text'>{el}</p>
    //         <span  className='cross-botton' onClick={() => handleRemoveFrontBodyParts(values.frontBodyParts?.tittle, index)}>&#10060;</span>
    //       </div>
    //     )
    //   }):""} */}
    //               {/* {values.backBodyParts?.tittle?values.backBodyParts?.tittle.map((el, index) => {
    //     return (
    //       <div className='d-flex me-2'>
    //         <p className='body-part-text'>{el}</p>
    //         <span  className='cross-botton' onClick={() => handleRemoveBodyParts(values.frontBodyParts?.tittle, index)}>&#10060;</span>
    //       </div>
    //     )
    //   }):""} */}
    //             </div>
    //           </div>
    //           <FlexGrid3Item className="pr-1 mb-2" >
    //             <small className="pr-1 " style={{ color: 'black' }}>
    //               Description <span style={{ color: ERR_COLOR }}>*</span>
    //             </small>
    //             <InputTextArea
    //               // disabled={readonly}
    //               required
    //               type="text"
    //               height={'4rem'}
    //               id="description"
    //               label={'Description'}

    //               //onChange={(val)=>handleOnChangeDescription(val)}
    //               {...useInput('tempBodyMapData.description')}
    //               maxlength={8}
    //             />
    //           </FlexGrid3Item>
    //           <div className='d-flex justify-content-end pt-1'>

    //             <PrimaryButtonForm
    //               minWidth="6rem"
    //               onClick={addBodyMuscle}
    //             //disabled={readonly && !update ? true : false}
    //             >
    //               <span className={'mr-2'}>{update ? "Update" : "Add"}</span>

    //             </PrimaryButtonForm>

    //           </div>
    //         </div>
    //       </div>
    //     </ModalDynamic>
    //   </ModalContainer></div>
  )
}

export default Modal







