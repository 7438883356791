import React, { useState, useRef, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';

import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import { getClientVisits } from 'utils/api/RegularVisitApi';
import { getVisitsId, getModifiedVisitsId } from 'utils/api/AlertsApi';
import {
  setVisitCareNotesDetails,
  getVisitCareNotesByRowsId,
  getHistoryNotes,
} from 'utils/api/ClientApi';
import DescriptionList from './DescriptionList';
import { setOnlyforTriagePopupTriageLabel, setTriageLabel } from 'utils/api/SettingsApi';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { client_visit_care_notes } from 'utils/choiceConstant';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';

const VisitCareNotesModal = ({
  fullBind,
  idSelected,
  labels,
  employeeName,
  alertPrivacy,
  setModalValue,
  setTitle,
  loadVisitCareNotes,
  handleChangeChoices,
  contextChoices,
  pageClientID,
  userList,
  rowData,
  displayPhoto,

  readPermissionCareNotes,
  createPermissionCareNotes,
  updatePermissionCareNotes,
  deletePermissionCareNotes,

  readPermissionCareNotesReview,
  createPermissionCareNotesReview,
  updatePermissionCareNotesReview,
  deletePermissionCareNotesReview
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [visitIDData, setVisitIDData] = useState([]);
  const [timeRange, setTimeRange] = useState([]);
  const [showError, setShowError] = useState(false);
  const edit_notes = 'Edit Visit Care Notes';
  const new_notes = 'Add Visit Care Notes';

  const ins_hook = useInput(`review_comment`);
  const newDoseTime = useInput(`review_time`);
  const review_date_hook = useInput(`review_date`);

  useEffect(() => {
    if (idSelected) {
      handleChangeChoices(client_visit_care_notes);
      loadVisitCareNotesByRowsId();
      setTitle(edit_notes);
      // setLoadingModal(false);
      loadHistoryNotes();
    } else {
      setTitle(new_notes);
      // setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  // useEffect(()=>{
  //   if(pageClientID && !idSelected){
  //     getClientVisits(pageClientID)
  //         .then(res => {
  //    if(res){
  // let TimeData=res.data.map(time=>{
  // return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
  // });
  // setTimeRange(TimeData);
  //    }
  //       } ) }
  // },[pageClientID])

  // useEffect(()=>{
  //   if(idSelected  && values.clientName && values.client_service_visit_date ){
  //     getClientVisits(values.clientName)
  //         .then(res => {
  //    if(res){
  // let TimeData=res.data.map(time=>{
  // return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
  // });
  // setTimeRange(TimeData);
  //    }
  //       } ) }
  // },[
  //   values.clientName,
  //   values.client_service_visit_date,
  // ])
  const toast = useRef();


  useEffect(() => {
    if (pageClientID && values.client_service_visit_date && contextChoices)
      visitCompleteData();
  }, [pageClientID, values.client_service_visit_date, idSelected]);

  const loadVisitCareNotesByRowsId = async () => {
    setLoadingModal(true)
    const response = await getVisitCareNotesByRowsId(idSelected, pageClientID);
    if (response) {
      values.post_description = response.care_notes ? response.care_notes : '';
      values.alert_created_by = response.care_notes_created_by
        ? response.care_notes_created_by
        : '';
      values.alert_label = response.care_notes_label?.map(x => {
        return {
          value: x.label_id,
          label: labels?.find(dataid => dataid.id == x.label_id)?.name,
          id: x.label_id,
          lebelId: x.id
        };
      });
      values.alert_privacy = response.notes_privacy
        ? response.notes_privacy
        : '';
      values.visit = response.visit ? response.visit : '';
      values.client_service_visit_date = response.visit_start_date
        ? response.visit_start_date.split('T')[0]
        : '';
      values.client_service_visit_timerange = response.csv ? response.csv : '';
      values.clientName = response.client ? response.client : '';
      values.id = response.id ? response.id : '';
      values.client_service_visit_date = response.notes_date;
      values.review_status = response.review_status;
      values.review_comment = response.review_comment;
      values.review_date = response.review_date_time?.split('T')[0];
      values.review_time = response.review_date_time?.split('T')[1];
      values.updated_by = response?.updated_by.hasOwnProperty('name') && response?.updated_by?.name
      setValues({ ...values });
    }
    setLoadingModal(false)
  };


  const loadHistoryNotes = async () => {
    const resp = await getHistoryNotes(idSelected);
    if (resp.data) {
      values.prev_description = resp.data;
      setValues({ ...values });
    }
  };

  const visitCompleteData = async () => {
    if (pageClientID && values.client_service_visit_date) {
      const response = await getModifiedVisitsId(
        pageClientID,
        values.client_service_visit_date
      );
      let VisitDropdown = response?.data?.map(visitData => {
        const jobTitle = visitData.job_title ? `${visitData.job_title} ` : '';
        const timeRange = `${visitData.start_date && visitData.start_date?.split("T")[1].slice(0, 5)} - ${visitData.end_date && visitData.end_date?.split("T")[1].slice(0, 5)}`;

        return {
          id: visitData.id,
          name: `${jobTitle}${timeRange}`,
        };
      });

      setVisitIDData(VisitDropdown);
      setValues({ ...values });
    }
  };

  const dropDownHandleSelect = (item, nameItem) => {
    if (nameItem == 'client_service_visit_timerange') {
      values.client_service_visit_timerange =
        item.client_service_visit_timerange;
    }

    setValues({ ...values, ...item });
  };

  const HandleMultiSelect = async (preferred, diffKeyname) => {
    setValues({ ...values, ...preferred });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }
    let alertNewName = [];
    let arrIds = [];
    if (values.alert_label) {
      let data = values?.alert_label?.forEach(name => {
        if (name.className === 'Select-create-option-placeholder') {
          alertNewName.push(name.label);
        }
      });
      if (alertNewName && (alertNewName.length > 0)) {
        await setOnlyforTriagePopupTriageLabel(alertNewName)
          .then(response => {
            if (response && response.data && response.data.length) {
              let data = values?.alert_label?.forEach(dataname => {
                if (dataname.className === 'Select-create-option-placeholder') {
                  let alertNewIds = response.data.find(
                    alertData => alertData.name == dataname.label
                  ).id;
                  delete dataname.value;
                  arrIds.push({ ...dataname, value: alertNewIds });
                } else {
                  arrIds.push({ ...dataname });
                }
              });
              values.alert_label_data = arrIds.map(label => {
                if (label.lebelId) {
                  return { id: label.lebelId, label_id: label.value };
                } else if (
                  label.className === 'Select-create-option-placeholder'
                ) {
                  return { label_id: label.value };
                } else {
                  return { label_id: label.value };
                }
              });
            }
          })
          .catch(onError);
      }
    }
    if (!values.post_description || !values.alert_privacy) {
      setErrorMessage(InvalidOrEmptyValues);
      setCheckRequires(true)
    } else {
      const bodyData = {
        client: pageClientID ? pageClientID : '',
        visit: values.visit ? values.visit : '',
        notes_privacy: values.alert_privacy ? values.alert_privacy : '',
        care_notes_created_by: values.alert_created_by
          ? values.alert_created_by
          : '',
        // care_notes_label: alertLabel? alertLabel : '',
        care_notes_label: values.alert_label_data || (
          Array.isArray(values?.alert_label) && values.alert_label.length
            ? values.alert_label.map(label => ({
                ...(label?.lebelId && { id: label.lebelId }),
                label_id: label?.value
              }))
            : ""
        ),
        care_notes: values.post_description ? values.post_description : '',
        notes_date: values.client_service_visit_date ? values.client_service_visit_date : null,
      };

      // if (values.review_status) {
      //   bodyData.review_status = values.review_status || '';
      // }
      // if (values.review_status) {
      //   bodyData.review_comment = values.review_comment || "";
      // }
      // if (values.review_status) {
      //   bodyData.review_date_time = `${values.review_date || ''} ${values.review_time || ''}`;
      // }

      setLoadingSave(true);
     await setVisitCareNotesDetails(bodyData, values.id, pageClientID)
        .then(response => {
          if (response?.status === true) {

            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: `${response?.message}`,
            });


          } else {
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: `${response?.message}`,
            });
          }
          if (response.message.detail) {
            onError(response.message.detail);
          } else {
            onSuccess(response);
          }
        })
        .catch(onError);
    }
  };
  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(true);
    handleChangeChoices(client_visit_care_notes);
    loadVisitCareNotes();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
  };

  const headerInfo = () => {
    return (
      <RowInfoModel
        name={rowData.first_name || rowData.full_name}
        lastName={rowData.last_name}
        photoRow={
          rowData.photo
            ? URL.createObjectURL(rowData.photo)
            : displayPhoto
              ? displayPhoto
              : ''
        }
        rows={[
          { label: 'Name:', value: rowData.full_name },
          {
            label: 'DOB:',
            value: rowData.date_of_birth
              ? `${moment(rowData.date_of_birth).format(
                'DD-MM-YYYY'
              )} (${moment().diff(rowData.date_of_birth, 'years')})`
              : '',
          },
        ]}
      // dateOfBirth={dateOfBirth}
      />
    )
  }
  const client_service_visit_date_hook = useInput(`client_service_visit_date`)
  const visit_hook = useInput(`visit`, 'isRequired')
  const alert_privacy_hook = useInput(`alert_privacy`, 'isRequired')
  const post_description_hook = useInput('post_description', 'isRequired')
  // const review_status_hook = useInput(`review_status`, 'isRequired')
  // const last_updated_by_hook = useInput(`updated_by`)


  return (
    <>
      <ModalContainer max_width={'60%'}>
        <Toast ref={toast} position="top-right" />

        <ModalNoInfo
          {...fullBind}
          max_width={'60%'}
          minWidthTitle="8.5rem"
          loading={loadingModal}
          rowInfoData={headerInfo}
        >

          <div className="pl-3 pr-3 pb-3 pt-0">
            {(idSelected ? readPermissionCareNotes : createPermissionCareNotes) && (
              <>
                {/* <TitleSection>Care Notes Details</TitleSection> */}
                <FlexGrid3Container className={'w-100 mb-2'} ResizeWidth={'50%'}>

                  <FlexGrid3Item className="pr-1 mt-1 mb-2">
                  <div style={{ width: '100%' }}>
                    <DateSelector
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      labelTop="Client Service Visit Date"
                      {...client_service_visit_date_hook}
                    />
                    </div>
                  </FlexGrid3Item>

                  <FlexGrid3Item className="pr-1 mt-1 " ResizeWidth={'50%'}>
                  <div style={{ width: '100%' }}>
                    <DropdownSearchable
                      {...visit_hook}
                      placeHolder={'Visit'}
                      width={'100%'}
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      options={visitIDData ? visitIDData : []}
                      selectedOption={values ? values.visit : null}
                      onChange={value =>
                        dropDownHandleSelect({ visit: value ? value : null }, 'visit')
                      }
                    />
                    </div>
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                    <DropdownSearchable
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      placeHolder={'Employee'}
                      options={userList ? userList : []}
                      selectedOption={values.alert_created_by}
                      onChange={value =>
                        dropDownHandleSelect({
                          alert_created_by: value ? value : null,
                        })
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                    <DropdownSearchable
                      required
                      {...alert_privacy_hook}
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      placeHolder={'Privacy Type'}
                      options={alertPrivacy ? alertPrivacy : []}
                      selectedOption={values.alert_privacy}
                      onChange={value =>
                        dropDownHandleSelect({ alert_privacy: value ? value : null })
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pr-1 mb-2" ResizeWidth={'100%'}>
                    <div
                      className={'pt-2 ml-md-1 ml-0'}
                      style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                      Description
                    </div>
                    <InputTextArea
                      required
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      type="text"
                      height={'4rem'}
                      id="description"
                      // placeholder={'Description'}
                      {...post_description_hook}
                      maxlength={5}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'100%'}>
                    <DropdownSearchable
                      isCreate={true} // to add items not present in the options(dropdown items)
                      isMulti={true}
                      disabled={idSelected ? !updatePermissionCareNotes : !createPermissionCareNotes}
                      placeHolder={'Labels'}
                      options={labels ? labels : []}
                      selectedOptionsProps={values.alert_label && values.alert_label}
                      selectedOption={values.alert_label && values.alert_label}
                      onChange={value =>
                        HandleMultiSelect(
                          { alert_label: value ? value : null },
                          'alert_label'
                        )
                      }
                    />
                  </FlexGrid3Item>
                  {values.prev_description?.length > 0 ? (
                    <FlexGrid3Item
                      className="pr-1 pt-1 mt-1 mb-2"
                      ResizeWidth={'100%'}
                      style={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        height: '300px',
                      }}>
                      {values.prev_description?.length > 0 ? (
                        <small className="pr-1 ">Previous Description</small>
                      ) : null}
                      {values.prev_description?.length > 0 &&
                        values.prev_description?.map((data, index) => {
                          return (
                            <div key={data.id}>
                              <DescriptionList
                                data={data}
                                index={index}
                                useInput={useInput}
                                values={values}
                                setValues={setValues}
                                employeeName={employeeName}
                                contextChoices={contextChoices}
                              />
                            </div>
                          );
                        })}
                    </FlexGrid3Item>
                  ) : null}
                </FlexGrid3Container>
              </>
            )}
            {/* {(idSelected ? readPermissionCareNotesReview : createPermissionCareNotesReview) && (
              <>
                <TitleSection>Review Details</TitleSection>
                <div>
                  <div className="d-flex mt-2">
                    <div className="w-100 mr-2">
                      <DropdownSearchable
                        // required
                        disabled={idSelected ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                        placeHolder={'Review Status'}
                        options={contextChoices?.medication_review_status || []}
                        {...review_status_hook}
                        selectedOption={
                          values.review_status
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { review_status: value ? value : null },
                            'review_status'
                          )
                        }
                      />
                    </div>
                    <div className="w-100 pr-1">
                      <DateSelector
                        disabled={idSelected ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                        labelTop="Review Date"
                        {...review_date_hook}
                      />
                    </div>
                    <div className="w-100 pr-1">
                      <TimeSelector
                        disabled={idSelected ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                        labelTop={'Review Time'}
                        {...newDoseTime}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                      />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100 pr-1">
                      <div
                        className={'pt-2 ml-md-1 ml-0'}
                        style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                        Review Comment
                      </div>
                      <InputTextArea
                        type="text"
                        height={'3.4rem'}
                        id="emp_desc"
                        placeHolder={'Review Comment'}
                        label={'Review Comment'}
                        {...ins_hook}
                        disabled={idSelected ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                      />
                    </div>
                  </div>
                </div>
              </>
            )} */}
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {errorMessage ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={handleSubmit}
                    disabled={idSelected
                      ? (!updatePermissionCareNotes && !updatePermissionCareNotesReview)
                      : (!createPermissionCareNotes && !createPermissionCareNotesReview)}
                  >
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </div>
        </ModalNoInfo>
      </ModalContainer>
    </>
  );
};

export default VisitCareNotesModal;


