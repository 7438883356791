import React, {Fragment, useEffect} from 'react';
import LineContainer from 'components/SharedComponents/LineContainer';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {FaPlus} from 'react-icons/fa';
import {
  TitleSection,
  IconWrapper,
  SampleMessage,
} from 'shared/styles/constants/tagsStyles';
import {FunderSplit} from 'components/SharedComponents/FormSections';
import {SOFT_RED} from 'shared/styles/constants/colors';
import {DELETE} from 'utils/constants/icons';
import PropTypes from 'prop-types';

const AddNewFunderSplit = ({
  values,
  setValues,
  split_funders,
  useInput,
  deleteSplitFunder,
  addNewSplitFunder,
  setErrorSplitPercentage,
  errorSplitPercentage,
  editMode,
  indexVisit
}) => {
  useEffect(()=>{
    if(!values.service_funder){
      values.service_funder={}
    }
    if(!values.service_funder.split_funders){
      values.service_funder.split_funders=[{}]
      setValues({...values})
    }
     
  },[])
  
  useEffect(() => {
    const splits = values.service_funder && values.service_funder.split_funders &&  values.service_funder.split_funders || [];
    let splitPercentage = 0;

    splits.forEach(elementSplit => {
      if (elementSplit.arrangement) {
        splitPercentage += parseInt(elementSplit.arrangement);
      }
    });

    if (splitPercentage > 100) {
      setErrorSplitPercentage(
        `Funding split percentage arrangement is exceeds 100%.`,
      );
    } else {
      setErrorSplitPercentage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // if (split_funders.length <= 0 && !deletedSplitFunders) {
  //   split_funders.push({});
  // }
  return (
    <div>
      <div className={'mb-2'}>
        <TitleSection>Funder Contact</TitleSection>
      </div>
      {split_funders && split_funders.length >= 1 ? (
        split_funders.map((dataSplit, indexSplit) => {
          return (
            <div key={indexSplit} className={'mb-3'}>
              <LineContainer
               editMode={!editMode}
                line_color={SOFT_RED}
                className={'pl-2 pr-2 pt-2 pb-4'}>
                {split_funders.length === 1 ? null : (
                  <div className={'pb-2 d-flex justify-content-end'}>
                    <IconWrapper
                     disabled={!editMode}
                      color={SOFT_RED}
                      onClick={() => deleteSplitFunder(dataSplit, indexSplit, indexVisit)}>
                      {DELETE}
                    </IconWrapper>
                  </div>
                )}
                <FunderSplit
                 editMode={editMode}
                  nameSection={`service_funder[${indexVisit}].split_funders[${indexSplit}]`}
                  useInput={useInput}
                  indexSplit={indexSplit}
                />
              </LineContainer>
            </div>
          );
        })
      ) : (
        <Fragment />
      )}
      <SampleMessage>{errorSplitPercentage}</SampleMessage>
      <div className={'mt-2'} >
        <PrimaryButtonForm minWidth="6rem" disabled={!editMode} onClick={()=>addNewSplitFunder(indexVisit)} >
      
          <FaPlus disabled={!editMode}/>
          NEW FUNDER SPLIT
        </PrimaryButtonForm>
      </div>
    </div>
  );
};

AddNewFunderSplit.propTypes = {
  addNewSplitFunder: PropTypes.func.isRequired,
  deleteSplitFunder: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  split_funders: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  deletedSplitFunders: PropTypes.bool.isRequired,
  
};

export default AddNewFunderSplit;
