import React, {useEffect, useState, useRef, useContext} from 'react';
import {useModal} from 'hooks/ModalHook';
import {useHistory} from 'react-router-dom';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {getAdmin, getSuperAdmin} from "../../../../utils/localStorage/token"
import Funder from '../SettingsClient/Funder'
import {getSubSectionPermissions,getStaff} from '../../../../utils/localStorage/user';
import ImportantContact from './ImportantContact';
import {
  SETTINGS,
  CLIENT,
  FUNDER,
  HEALTH,
  PRIORITY,
  IMPCONTACT,
  CLIENT_TASK,
  GROUP_CLIENT_TASK,
  MEDICATIONS,
  GROUP_CLIENT_SERVICE_CONTRACT
  // RUN_ROUTE
} from 'utils/constants/routes';
import {ContainerTabStyled, Title, LinkTabStyled, LinkTab,} from 'shared/styles/constants/tagsStyles';
import Health from './Health'
import Priority from './Priority'
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  FUNDER_DETAILS,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  PRIORITY_SETTINGS,
  WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions'
import {AppContext} from "context"
import Tasks from './Tasks';
import GroupTask from './GroupTask';
import Medications from './Medications';
import TemplateServiceClientList from './TemplateClientServices';

const TAB_FUNDER_URL = SETTINGS + CLIENT + FUNDER;
const TAB_HEALTH_URL= SETTINGS + CLIENT + HEALTH;
const TAB_MEDICATION_URL = SETTINGS + CLIENT + MEDICATIONS ;
const TAB_PRIORITY_URL=SETTINGS + CLIENT + PRIORITY;
const TAB_IMPCONTACT_URL=SETTINGS + CLIENT + IMPCONTACT
const TAB_CLIENT_TASK_URL=SETTINGS + CLIENT + CLIENT_TASK
const TAB_GROUP_CLIENT_TASK_URL=SETTINGS + CLIENT + GROUP_CLIENT_TASK
const TAB_GROUP_CLIENT_SERVICE_CONTRACT_URL=SETTINGS + CLIENT + GROUP_CLIENT_SERVICE_CONTRACT


const SettingsClient = props => {
  
const [updateFunderSettings, setUpdatePermissionsFunderSettings] = useState(false);
const [createFunderSettings, setCreatePermissionsFunderSettings] = useState(false);
const [deletePermissionFunderSettings,setDeletePermissionsFunderSettings]= useState(false);
const [readPermissionFunderSettings, setReadPermissionsFunderSettings] = useState(false);
const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] = useState(false);
const [createPrioritySettings, setCreatePermissionsPrioritySettings] = useState(false);
const [deletePermissionPrioritySettings,setDeletePermissionsPrioritySettings]= useState(false);
const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] = useState(false);
const { contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextChoices}=useContext(AppContext)

  const [pages] = useState(0);
  const [clientSettings, setClientSettings] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [company, setCompany] = useState({});
  const mountedRef = useRef(true);
  let history = useHistory();
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;
  const  userStaff = contextIsStaff;
  const reviewLocation = url => {
    return url === history.location.pathname;

  };
 useEffect(()=>{
  let rolePermissions = {};
  const rolesPermissions = contextSubSectionPermissions;
    
      
      if((filterAdmin == true) || (filterSuperAdmin ==true) ){
        setUpdatePermissionsFunderSettings(true) ;
        setCreatePermissionsFunderSettings(true) ;
        setDeletePermissionsFunderSettings(true) ;
        setReadPermissionsFunderSettings(true) ;
        setUpdatePermissionsPrioritySettings(true) ;
        setCreatePermissionsPrioritySettings(true) ;
        setDeletePermissionsPrioritySettings(true) ;
        setReadPermissionsPrioritySettings(true) ;
      }else{
        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
           
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
              rolePermissions[item.section.name ] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
            }
          // }
          );  
        }
        setCreatePermissionsFunderSettings(
          rolePermissions[FUNDER_DETAILS]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissionsFunderSettings(
          rolePermissions[FUNDER_DETAILS]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissionsFunderSettings(
          rolePermissions[FUNDER_DETAILS]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ]
        );
        
        setReadPermissionsFunderSettings( 
          rolePermissions[FUNDER_DETAILS]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]
        );
        setCreatePermissionsPrioritySettings(
          rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissionsPrioritySettings(
          rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissionsPrioritySettings(
          rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ]
        );
        
        setReadPermissionsPrioritySettings( 
          rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]
        );
      }
 },[])



  return (
    <div>
      <Title>Settings client</Title>
      <div className="p-2 p-sm-3 p-md-4">
      <ContainerTabStyled className="text-center mb-4">
      <div className={'d-flex firstRow'}>
      { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_FUNDER_URL)}>
              <Link to={TAB_FUNDER_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_FUNDER_URL)}>
                  Funder Contact
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
             { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_IMPCONTACT_URL)}>
              <Link to={TAB_IMPCONTACT_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_IMPCONTACT_URL)}>
                  Important Contact
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
            { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_HEALTH_URL)}>
              <Link to={TAB_HEALTH_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_HEALTH_URL)}>
                  Health
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
             { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_MEDICATION_URL)}>
              <Link to={TAB_MEDICATION_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_MEDICATION_URL)}>
                  Medication
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
              { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_PRIORITY_URL)}>
              <Link to={TAB_PRIORITY_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_PRIORITY_URL)}>
             Services
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
              { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (((readPermissionFunderSettings) || (deletePermissionFunderSettings) || (createFunderSettings) || (updateFunderSettings)) ) )  && ( 
            <LinkTabStyled active={reviewLocation(TAB_CLIENT_TASK_URL)}>
              <Link to={TAB_CLIENT_TASK_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_CLIENT_TASK_URL)}>
             Tasks
                </LinkTab>
            </Link>
            </LinkTabStyled>
            )}
            <LinkTabStyled active={reviewLocation(TAB_GROUP_CLIENT_TASK_URL)}>
              <Link to={TAB_GROUP_CLIENT_TASK_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_GROUP_CLIENT_TASK_URL)}>
             Group Task
                </LinkTab>
            </Link>
            </LinkTabStyled>
            <LinkTabStyled active={reviewLocation(TAB_GROUP_CLIENT_SERVICE_CONTRACT_URL)}>
              <Link to={TAB_GROUP_CLIENT_SERVICE_CONTRACT_URL} className={'styledLink'}>
             <LinkTab active={reviewLocation(TAB_GROUP_CLIENT_SERVICE_CONTRACT_URL)}>
             Template Contract
                </LinkTab>
            </Link>
            </LinkTabStyled>
      </div>
      </ContainerTabStyled>
      </div>
      <Switch>
      <Route exact path={TAB_FUNDER_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <Funder
             location={props.location}
             updateFunderSettings={updateFunderSettings}
             createFunderSettings={createFunderSettings}
             deletePermissionFunderSettings={deletePermissionFunderSettings}
             readPermissionFunderSettings={readPermissionFunderSettings}
             filterAdmin={filterAdmin}
             filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_IMPCONTACT_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <ImportantContact
             location={props.location}
             updateFunderSettings={updateFunderSettings}
             createFunderSettings={createFunderSettings}
             deletePermissionFunderSettings={deletePermissionFunderSettings}
             readPermissionFunderSettings={readPermissionFunderSettings}
             filterAdmin={filterAdmin}
             filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_HEALTH_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <Health
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            handleChangeChoices={props.handleChangeChoices}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_MEDICATION_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <Medications
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            handleChangeChoices={props.handleChangeChoices}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_PRIORITY_URL}>
          { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (  (userStaff == true )  )  || 
(( (updatePrioritySettings) || (createPrioritySettings) || (deletePermissionPrioritySettings) || (readPermissionPrioritySettings) ) ))  && ( 
            <Priority
            updatePrioritySettings={updatePrioritySettings}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            handleChangeChoices={props.handleChangeChoices}
             userStaff={userStaff}
            />
 ) }
          </Route>
          <Route exact path={TAB_CLIENT_TASK_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <Tasks
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            handleChangeChoices={props.handleChangeChoices}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_GROUP_CLIENT_TASK_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <GroupTask
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            handleChangeChoices={props.handleChangeChoices}
            />
            {/* )} */}
          </Route>
          <Route exact path={TAB_GROUP_CLIENT_SERVICE_CONTRACT_URL}>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || (((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
            <TemplateServiceClientList
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            handleChangeChoices={props.handleChangeChoices}
            choices={contextChoices}
            />
            {/* )} */}
          </Route>
        </Switch>
    </div>
  );
};

export default SettingsClient;
