import {keyframes} from 'styled-components';

export const keyShake = keyframes`
 from,
  to {
      transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
      transform: translate3d(-6px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
      transform: translate3d(6px, 0, 0);
  }
`;
const AnimationStyle = /*css*/ `
  &:hover {
    text-decoration: none;

    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);

    -moz-transition: transform 0.05s ease-in-out;
    -webkit-transition: transform 0.05s ease-in-out;
    -ms-transition: transform 0.05s ease-in-out;
  }
  &:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);

    -moz-transition: transform 0.05s ease-in-out;
    -webkit-transition: transform 0.05s ease-in-out;
    -ms-transition: transform 0.05s ease-in-out;
  }
`;

export const SvgIconHoverSize = /*css*/ `
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
  }
  ${AnimationStyle}
`;
