import React, { Fragment, useState, useEffect } from 'react';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';
import { FaAngleDown, FaAngleUp, FaLastfmSquare } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { FaUserShield, FaUsers, FaUser } from 'react-icons/fa';
import { SquareStyle, ArrowStyle, LoaderWraper } from './styles';
import Spinner from 'components/SharedComponents/Spinner';
import { useRef } from 'react';
// import { PermissionActionButton, PremissionActionButton } from 'shared/styles/buttons';
import { BsThreeDotsVertical } from 'react-icons/bs';
import "./style.css"
import { DISABLE_ALL, DISABLE_SELF, ENABEL_SELF, ENABLE_ALL } from '..';
import { useOutsideClickClosePopup } from 'shared/methods';

let TITLE_PADDING = 3;
let TITLE_FONT_WEIGHT = 800;
const CREATE_TITLE = 'Create';
const READ_TITLE = 'Read';
const UPDATE_TITLE = 'Update';
const DELETE_TITLE = 'Delete';
const EMPLOYEE = 'employee'
const _ = require('lodash');

const BodyTable = ({
  rolValues,
  selectedRol,
  setRolValues,
  handleCheckboxClick,
  userType,
  permissionLoading,
  updateRowLevelPermission,
  expandable,
  setExpandable,
  scrollValue,
}) => {
  // console.log(expandable, "expandable")
  const [currentIndex, setCurrentIndex] = useState(null)
  const dropdownRef = useRef(null); 
  // Save currect clicked position
  const saveCurrectPosition = () => {
    scrollValue.current = window.scrollY
  }
  // scroll at saved position
  useEffect(() => {
    window.scrollTo(0, scrollValue.current);
  }, [permissionLoading])

  let visibleItemsForOthers = ['Calendar', 'Client', 'Triage', 'Notification']
  // useEffect(() => {
  //   _.forEach(expandable, function (value, key) {
  //     expandable[key] = false;
  //   });

  //   setExpandable({ ...expandable });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedRol]);

  const resetCurrentIndex = () => {
    setCurrentIndex(null);
  };

    useOutsideClickClosePopup(dropdownRef,resetCurrentIndex)
  

  const SubHeader = () => {
    return (
      <>
        <tr className={'border'}>
          <th className={'sticky-col'}>
            <div className={'d-flex justify-content-center'}>
              <FaUserShield className={'mt-1'} size={18} />
            </div>
          </th>
          <td className={'border-left'}>
            <div className={'d-flex justify-content-between font-weight-bold'}>
              <div>{CREATE_TITLE}</div>
              <div>{READ_TITLE}</div>
              <div>{UPDATE_TITLE}</div>
              <div>{DELETE_TITLE}</div>
            </div>
          </td>
          <td className={'border-left'}>
            <div className={'d-flex justify-content-between font-weight-bold'}>
              <div>{CREATE_TITLE}</div>
              <div>{READ_TITLE}</div>
              <div>{UPDATE_TITLE}</div>
              <div>{DELETE_TITLE}</div>
            </div>
          </td>
        </tr>
        <tr className={'border'}>
          <th className={'sticky-col'}>
          </th>
          <td className={'border-left'}>
            <div className={'d-flex justify-content-between font-weight-bold'}>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
            </div>
          </td>
          <td className={'border-left'}>
            <div className={'d-flex justify-content-between font-weight-bold'}>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
              <div className={'d-flex justify-content-centre'}>
                {userType === EMPLOYEE && <FaUsers className={'mr-2'} size={18} />}
                <FaUser className={'mr-2'} size={13} />
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

  const filteredDataToMapFunc = (valuesToMap) => {
    let filteredDataToMap = []
    if (userType !== EMPLOYEE) {
      filteredDataToMap = valuesToMap.map((item) =>
        ({ ...item, principal: visibleItemsForOthers.includes(item.name) }))
    } else {
      filteredDataToMap = valuesToMap
    }
    return filteredDataToMap
  }

  const createRows = (sections, keyValue = '') => {
    let tableToReturn = [];

    let valuesToMap = sections ? sections : rolValues;
    filteredDataToMapFunc(valuesToMap).forEach((tableValue, index) => {
      const myKey = tableValue.name.replace(/\s/g, '') + '/' + keyValue;

      expandable[`${keyValue ? keyValue : myKey}`] =
        expandable[`${keyValue ? keyValue : myKey}`] === undefined
          ? false
          : expandable[`${keyValue ? keyValue : myKey}`];

      tableToReturn = [
        ...tableToReturn,
        <Fragment key={index}>
          {!expandable[`${keyValue ? keyValue : myKey}`] &&
          !tableValue.principal ? null : (
            // adding rows
            <tr key={index} className={'border'}>
              <th
                className={'sticky-col'}
                style={{
                  paddingLeft: TITLE_PADDING,
                  fontWeight: TITLE_FONT_WEIGHT,
                }}>
                <div
                  className={
                    'd-flex align-items-center justify-content-between'
                  }>
                  <div>
                    {/**adding square */}
                    {tableValue.principal
                      ? addSquare(tableValue, expandable, keyValue, myKey)
                      : null}

                    {/**adding arrows */}
                    {tableValue.sections &&
                    tableValue.sections.length >= 1 &&
                    !tableValue.principal ? (
                      <Fragment>{addArrows(expandable, myKey)}</Fragment>
                    ) : null}
                    {/**adding name */}
                    {tableValue.name}
                  </div>
                    <div className={''}>
                      <div class="permission-action dropdown" >
                        <div
                          onClick={() => {
                            saveCurrectPosition();
                            setCurrentIndex(`${keyValue+`${index}` ? keyValue+`${index}` : myKey+`${index}`}`)
                          }}
                          class="dropdown-toggle"
                          id={`dropdownMenuButton-${index}`}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <BsThreeDotsVertical 
                          color={currentIndex == (`${keyValue+`${index}` ? keyValue+`${index}` : myKey+`${index}`}`) ? PURPLE : ""}
                          style={{ height: '1.2rem', width: '1.2rem' }} />
                          {/* <PermissionActionButton>
                            Action
                          </PermissionActionButton> */}
                        </div>
                        <div 
                        ref={dropdownRef}
                        id='myid'
                          class="dropdown-menu mr-1"
                          aria-labelledby="dropdownMenuButton"
                          style={{ zIndex: 9999 }}>

                          
                          {userType === EMPLOYEE && (
                            <li
                              class="dropdown-item"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                updateRowLevelPermission(tableValue, ENABLE_ALL, true);
                                // saveCurrectPosition();
                              }}>
                              {ENABLE_ALL}
                            </li>
                          )}
                          {userType === EMPLOYEE && (
                            <li
                              class="dropdown-item"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                updateRowLevelPermission(tableValue, DISABLE_ALL, false);
                                // saveCurrectPosition();
                              }}>
                              {DISABLE_ALL}
                            </li>
                          )}
                          <li
                            class="dropdown-item"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              updateRowLevelPermission(tableValue, ENABEL_SELF, true);
                              // saveCurrectPosition();
                            }}>
                            {ENABEL_SELF}
                          </li>
                          <li
                            class="dropdown-item"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              updateRowLevelPermission(tableValue, DISABLE_SELF, false);
                              // saveCurrectPosition();
                            }}>
                            {DISABLE_SELF}
                          </li>
                        </div>
                      </div>

                    {/* <label className={'checkbox-label mr-2'}>
                       <input
                         type="checkbox"
                         aria-label={""}
                         checked={true}
                         onChange={() => {
                           console.log()
                         }}
                       />
                       <span className="checkbox-custom" />
                     </label>
                     <label className={'checkbox-label mr-2'}>
                       <input
                         type="checkbox"
                         aria-label={""}
                         checked={true}
                         onChange={() => {
                           console.log()
                         }}
                       />
                       <span className="checkbox-custom" />
                     </label> */}
                  </div>
                </div>
              </th>
              {/**adding web checkboxes */}
              <td
                className={'align-content-center border-left checkboxPosition'}>
                {checkboxComponent(tableValue, 'web')}
              </td>
              {/**adding mobile checkboxes */}
              <td className={'align-self-center border-left checkboxPosition'}>
                {checkboxComponent(tableValue, 'mobile')}
              </td>
            </tr>
          )}
        </Fragment>,
      ];

      if (tableValue.sections && tableValue.sections.length >= 1) {
        TITLE_PADDING += 20;
        TITLE_FONT_WEIGHT -= 150;
        tableToReturn = [
          ...tableToReturn,
          createRows(tableValue.sections, `${myKey}`),
        ];
        TITLE_PADDING -= 20;
        TITLE_FONT_WEIGHT += 150;
      }

      if (tableValue.principal) {
        TITLE_PADDING = 3;
      }
    });
    return tableToReturn;
  };

  const addSquare = (tableValue, expandable, keyValue, myKey) => {
    if (!expandable[`${keyValue ? keyValue : myKey}`] && tableValue.principal) {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillPlusSquare
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]: !expandable[
                  `${keyValue ? keyValue : myKey}`
                ],
              });
            }}
          />
        </SquareStyle>
      );
    } else {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillMinusSquare
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]: !expandable[
                  `${keyValue ? keyValue : myKey}`
                ],
              });
            }}
          />
        </SquareStyle>
      );
    }
  };

  const addArrows = (expandable, myKey) => {
    if (expandable[`${myKey}`]) {
      return (
        <ArrowStyle className={'pb-1 pl-0'}>
          <FaAngleUp
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    } else {
      return (
        <ArrowStyle className={'pb-1 pl-0'}>
          <FaAngleDown
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    }
  };

  /**
   * Return horizontal checkboxes
   * @param {Object} tableValue
   * @param {String} columnType
   * @returns
   */
  const checkboxComponent = (tableValue, columnType) => {
    return (
      <div className={'d-flex  justify-content-between'}>
        <div className={'d-flex checkbox-container justify-content-between'}>
          {userType === EMPLOYEE &&
            <label className={'checkbox-label mr-2'}>
              <input
                type="checkbox"
                // ref={scrollValue}
                aria-label={CREATE_TITLE}
                checked={tableValue[columnType][`${columnType}_create_all`]}
                onChange={() => {
                  saveCurrectPosition();
                  changeCheckboxValues(
                    tableValue,
                    rolValues,
                    columnType,
                    `${columnType}_create_all`,
                    'create',
                    'all'
                  );
                }}
              />
              <span className="checkbox-custom" />
            </label>}
          <label className={'checkbox-label mr-2'}>
            <input
              type="checkbox"
              // ref={scrollValue}
              aria-label={CREATE_TITLE}
              checked={tableValue[columnType][`${columnType}_create_self`]}
              onChange={() => {
                saveCurrectPosition();
                changeCheckboxValues(
                  tableValue,
                  rolValues,
                  columnType,
                  `${columnType}_create_self`,
                  'create',
                  'self'
                );
              }}
            />
            <span className="checkbox-custom" />
          </label>
        </div>
        <div className={'d-flex justify-content-center '}>

          {userType === EMPLOYEE &&
            <label className={'checkbox-label mr-2'}>
              <input
                type="checkbox"
                // ref={scrollValue}
                aria-label={READ_TITLE}
                checked={tableValue[columnType][`${columnType}_read_all`]}
                onChange={() => {
                  saveCurrectPosition();
                  changeCheckboxValues(
                    tableValue,
                    rolValues,
                    columnType,
                    `${columnType}_read_all`,
                    'read',
                    'all'
                  );
                }}
              />
              <span className="checkbox-custom" />
            </label>}
          <label className={'checkbox-label mr-2'}>
            <input
              type="checkbox"
              // ref={scrollValue}
              aria-label={READ_TITLE}
              checked={tableValue[columnType][`${columnType}_read_self`]}
              onChange={() => {
                saveCurrectPosition();
                changeCheckboxValues(
                  tableValue,
                  rolValues,
                  columnType,
                  `${columnType}_read_self`,
                  'read',
                  'self'
                );
              }}
            />
            <span className="checkbox-custom" />
          </label>
        </div>
        <div className={'d-flex justify-content-center'}>

          {userType === EMPLOYEE &&
            <label className={'checkbox-label mr-2'}>
              <input
                type="checkbox"
                // ref={scrollValue}
                aria-label={UPDATE_TITLE}
                checked={tableValue[columnType][`${columnType}_update_all`]}
                onChange={() => {
                  saveCurrectPosition();
                  changeCheckboxValues(
                    tableValue,
                    rolValues,
                    columnType,
                    `${columnType}_update_all`,
                    'update',
                    'all'
                  );
                }}
              />
              <span className="checkbox-custom" />
            </label>}
          <label className={'checkbox-label mr-2'}>
            <input
              type="checkbox"
              // ref={scrollValue}
              aria-label={UPDATE_TITLE}
              checked={tableValue[columnType][`${columnType}_update_self`]}
              onChange={() => {
                saveCurrectPosition();
                changeCheckboxValues(
                  tableValue,
                  rolValues,
                  columnType,
                  `${columnType}_update_self`,
                  'update',
                  'self'
                );
              }}
            />
            <span className="checkbox-custom" />
          </label>
        </div>
        <div className={'d-flex justify-content-center '}>

          {userType === EMPLOYEE &&
            <label className={'checkbox-label mr-2'}>
              <input
                type="checkbox"
                // ref={scrollValue}
                aria-label={DELETE_TITLE}
                checked={tableValue[columnType][`${columnType}_delete_all`]}
                onChange={() => {
                  saveCurrectPosition();
                  changeCheckboxValues(
                    tableValue,
                    rolValues,
                    columnType,
                    `${columnType}_delete_all`,
                    'delete',
                    'all'
                  );
                }}
              />
              <span className="checkbox-custom" />
            </label>}
          <label className={'checkbox-label mr-2'}>
            <input
              type="checkbox"
              // ref={scrollValue}
              aria-label={DELETE_TITLE}
              checked={tableValue[columnType][`${columnType}_delete_self`]}
              onChange={() => {
                saveCurrectPosition();
                changeCheckboxValues(
                  tableValue,
                  rolValues,
                  columnType,
                  `${columnType}_delete_self`,
                  'delete',
                  'self'
                );
              }}
            />
            <span className="checkbox-custom" />
          </label>
        </div>
      </div>
    );
  };

  const changeCheckboxValues = (
    tableValue,
    rolValues,
    columnType,
    typeCheckbox,
    type,
    optionType
  ) => {
    let myMenuIndex = 0;
    debugger
    if (tableValue.principal) {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.id,
      );
    } else {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.category,
      );
    }

    if (tableValue.principal) {
      rolValues[myMenuIndex].sections.forEach(rolSection => {
        //change rolValues ej. menu>subMenu.web{web_create: false or true}
        rolSection[columnType][typeCheckbox] = !tableValue[columnType][
          typeCheckbox
        ];
        if (rolSection[columnType][typeCheckbox]) {
          if (optionType === 'all') {
            if (rolSection[columnType][`${columnType}_${type}_self`]) {
              rolSection[columnType][`${columnType}_${type}_self`] = false;
            }
          } else {
            if (rolSection[columnType][`${columnType}_${type}_all`]) {
              rolSection[columnType][`${columnType}_${type}_all`] = false;
            }
          }
        }

        //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
        rolSection.sections.forEach(rolSubSection => {
          rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
            typeCheckbox
          ];
          if (rolSubSection[columnType][typeCheckbox]) {
            if (optionType === 'all') {
              if (rolSubSection[columnType][`${columnType}_${type}_self`]) {
                rolSubSection[columnType][`${columnType}_${type}_self`] = false;
              }
            } else {
              if (rolSubSection[columnType][`${columnType}_${type}_all`]) {
                rolSubSection[columnType][`${columnType}_${type}_all`] = false;
              }
            }
          }
        });
      });
    } else {
      //Change principal menu to false when submenu change to false
      if (!tableValue.isSubsection) {
        rolValues[myMenuIndex].sections.forEach(rolSection => {

          //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
          if (rolSection.id === tableValue.id) {
            rolSection.sections.forEach(rolSubSection => {
              rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
                typeCheckbox
              ];
              if (rolSubSection[columnType][typeCheckbox]) {
                if (optionType === 'all') {
                  if (rolSubSection[columnType][`${columnType}_${type}_self`]) {
                    rolSubSection[columnType][`${columnType}_${type}_self`] = false;
                  }
                } else {
                  if (rolSubSection[columnType][`${columnType}_${type}_all`]) {
                    rolSubSection[columnType][`${columnType}_${type}_all`] = false;
                  }
                }
              }
            });
          }
        });
      } else {
        if (tableValue[columnType][typeCheckbox]) {
          rolValues[myMenuIndex][columnType][typeCheckbox] = false;
        }
      }
    }

    // change menu.web{web_create: false or true}
    tableValue[columnType][typeCheckbox] = !tableValue[columnType][
      typeCheckbox
    ];
    let checkSelfAll = (optionType === 'all') ? tableValue[columnType][`${columnType}_${type}_self`] : tableValue[columnType][`${columnType}_${type}_all`];
    if (tableValue[columnType][typeCheckbox]) {
      if (optionType === 'all') {
        if (tableValue[columnType][`${columnType}_${type}_self`]) {
          tableValue[columnType][`${columnType}_${type}_self`] = false;
        }
      } else {
        if (tableValue[columnType][`${columnType}_${type}_all`]) {
          tableValue[columnType][`${columnType}_${type}_all`] = false;
        }
      }
    }
    let check = tableValue[columnType][typeCheckbox];
    handleCheckboxClick({ ...tableValue, type: type, columnType: columnType }, check, optionType, checkSelfAll);

    setRolValues([...rolValues]);
  };

  return (
    <Fragment>
      <SubHeader />
      {permissionLoading ? (
        <LoaderWraper>
          <Spinner />
        </LoaderWraper>
      ) : (
        <>
          {Object.keys(selectedRol).length >= 1 ? createRows() : null}
        </>
      )}
    </Fragment>
  );
};

export default BodyTable;