import React from 'react'
import { Actions, ListContainer, ListItem } from './styles';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { TitleSection } from '../styles';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { PURPLE } from 'shared/styles/constants/colors';

function DetailsList({
    list,
    handleAccordionClick,
    expandedSections,
    checkedRows,
    setCheckedRows,
    handleSelect,
    setIsAllSelected,
    actionItems,
    itemName,
    title }) {
    return (
        <div>
            <AccordionContainer style={{ backgroundColor: '#f7f7f7' }}>
                <AccordionArrow className='ml-1'
                    onClick={() =>
                        handleAccordionClick(itemName)
                    }>
                    <div>
                        {expandedSections[itemName] ? (
                            <IoIosArrowUp size={18} color={PURPLE} />
                        ) : (
                            <IoIosArrowDown size={18} color={PURPLE} />
                        )}
                    </div>
                </AccordionArrow>
                <div className='d-flex justify-content-between w-100'>
                    <TitleSection>{title}</TitleSection>
                    <div style={{ marginRight: '10px' }}>
                        <Actions>
                            <div
                                class="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <BsThreeDotsVertical />
                            </div>
                            <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>

                                {actionItems.map(action => {
                                    return (
                                        <li class="dropdown-item"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => action.onClick(itemName)}>
                                            {action.name}</li>
                                    )
                                })}
                            </div>
                        </Actions>
                    </div>
                </div>
            </AccordionContainer>
            {!expandedSections.regularShifts &&
                <ListContainer>
                    {list?.map(item => {
                        return (
                            <ListItem>
                                <div style={{ maxWidth: "95%" }}>{item.message}</div>
                                <input
                                    style={{
                                        marginTop: '0.3rem',
                                        marginLeft: '-1.25rem'
                                    }}
                                    type="checkbox"
                                    checked={checkedRows[itemName].includes(item.id)}
                                    id="flexCheckDefault"
                                    onChange={e => {
                                        handleSelect(e, item.id, itemName);
                                    }}
                                />

                            </ListItem>
                        );
                    })}
                </ListContainer>
            }
        </div>
    )
}

export default DetailsList