import React, { useState, useRef,useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { useForm } from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  Title
} from 'shared/styles/constants/tagsStyles';
import {
  ModalContainer,
} from  './style';
import moment from 'moment';      
import {getAbsenceList, getDetails, deleteAbsenceDetail, getDetailsBradford} from 'utils/api/EmployeeApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {EX_LARGE_COL, LARGE_COL, SMALL_COL, MEDIUM_COL,EX_SMALL_COL4} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper
} from 'components/SharedComponents/Table/styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Table from 'components/SharedComponents/Table';
import {RiDeleteBinLine} from 'react-icons/ri';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {useModal} from 'hooks/ModalHook';




const BradfordModal = ({
  fullBind,
  Id,
  readOnly=true,
  props,
  location,
  dataIndex,
  bradfordData,
  dates_data,
  contextChoicesMap
}) => {
  const { values, setValues, useInput, isValid, errors,setFormErrors, setCheckRequires } =
    useForm({absence_detail_ids: []});


  const [loadingModal, setLoadingModal] = useState(true);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [absenceList, setAbsenceList] = useState([]);
  const [absenceDetailList, setAbsenceDeytailList] = useState([]);
  const [intialsOfFirstName,setIntialsOfFirstName]=useState( )
const [intialsOfLastName,setIntialsOfLastName]=useState( )
  const mountedRef = useRef(true);

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  const headerColumns = [
    {
      label: 'Absence Type',
      name: 'absence_type',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: EX_LARGE_COL,
    },
      {
        label: 'START DATE',
        name: 'start_date',
        status: 0,
        className: 'grow-mobile  sm-hidden',
        max_width: EX_LARGE_COL,
      },
      {
        label: 'END DATE',
        name: 'end_date',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: EX_LARGE_COL,
      },
    ];

  const getData=()=>{
    getDetailsBradford(Id, dates_data.start_date, dates_data.end_date)
    .then((res)=>{
      if(res.status){
        setAbsenceDeytailList(res.data);
      }
    })
  }

useEffect(() => {
  getData();
    setLoadingModal(false);
},[Id]);

const handleSelectChange = (valcheck, visit, selectedIndex) => {
  if (!values.absence_detail_ids) {
    values.absence_detail_ids = [];
  }
  if (valcheck.target.checked) {
    values.absence_detail_ids.push(visit.id)
  } else {
    values.absence_detail_ids.map((item, index) => {
      if (item === visit.id) {
        values.absence_detail_ids.splice(index, 1);
      }
    })
  }
  setValues({ ...values })
}

const handleCheck = (visit) => {
  let check = false;
  if (values.absence_detail_ids) {
    values.absence_detail_ids.map((item) => {
      if (visit.id === item) {
        check = true
      }
    })
  }
  return check;
}

const deleteDeatils=()=>{
  deleteAbsenceDetail(values.absence_detail_ids)
  .then((res)=>{
    if(res.status === 'True'){
      setModalDeleteValue(false);
      setValues({absence_detail_ids: []});
      getData();

    }
  })
}

const deleteClick=(id)=>{
  if(values.absence_detail_ids && values.absence_detail_ids.length === 0){
    values.absence_detail_ids = [id];
  setValues({...values});
  setModalDeleteValue(true);
  }
}

const handleAllSelectChange = (valcheck) => {
  if (!values.absence_detail_ids) {
    values.absence_detail_ids = [];
  }
  if (valcheck.target.checked) {
    absenceDetailList.map((visit)=>{
      values.absence_detail_ids.push(visit.id);
    })
  } else {
    absenceDetailList.map((visit)=>{
      values.absence_detail_ids.map((item, index) => {
        if (item === visit.id) {
          values.absence_detail_ids.splice(index, 1);
        }
      })
    })
  }
  setValues({ ...values })
}

const handleAllCheck = () => {
  let checkAll = true;

  const visitExists=(id)=>{
    return values.absence_detail_ids.some(function(el) {
      return el === id;
    });
  }
  if (values.absence_detail_ids && values.absence_detail_ids.length > 0) {
    absenceDetailList.map((visit)=>{
        if(!visitExists(visit.id)){
          checkAll = false
        }
      })
  }else{
    checkAll = false;
  }
  return checkAll;
}


  return (
    <>
<ModalContainer max_width={'60%'}>

      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        showRowInfo
        minWidthTitle="8.5rem"
        name={Id && contextChoicesMap.employee_listing && contextChoicesMap?.employee_listing[Id]?.full_name}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: 'Employee Name :',
            value: Id && contextChoicesMap.employee_listing && contextChoicesMap?.employee_listing[Id]?.full_name,
          },
        ]}
        loading={false}>
        <div className={'pl-3 pr-3 pb-3'}>
        {absenceDetailList && absenceDetailList.length > 0 &&
        <>
          <Table
        headerColumns={headerColumns}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        handleAllSelectChange={handleAllSelectChange} 
        handleAllCheck={handleAllCheck} 
        // statusNumber={numberValue}   
        // setStatusNumber={changeNumber}   
        //   fullNameCol={fullNameCol}
        >

        <div>
          {absenceDetailList &&  absenceDetailList.map((list, index) => {
            return (
              <div key={index}>
                <Row bgColor Shadow>
                <ColsGrouper
                    className="d-flex">
                       <Col
                    max_width={EX_LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    className="sm-hidden"
                     overlap={true}
                    Center>
                    {
                   list.absence_type && contextChoicesMap.absence_type && contextChoicesMap?.absence_type[list.absence_type]?.name              
                    }
                  </Col>
                  <Col
                    max_width={EX_LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    className="sm-hidden"
                     overlap={true}
                    Center>
                    {
              list && moment(list.start_date ).format('YYYY-MM-DD')               
                    }
                  </Col>
                  <Col
                    max_width={EX_LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    className="sm-hidden"
                     overlap={true}
                    Center>
                   {
                 list && moment(list.end_date).format('YYYY-MM-DD')
                    }
                  </Col>
                  {/* <Col className="sm-hidden" Center Shrink NoFlexGrow>
                     <IconWrapper
                        onClick={() => {
                          deleteClick(list.id);
                        }}
                        >
                        <RiDeleteBinLine />
                      </IconWrapper>
                    </Col> */}
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
         {/* <div className="mt-3">
          <Pagination   totalPages={pages}  currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={absenceList.length}     />
          </div> */}
        </div> 
  
      </Table>
        </>
      }
      </div>
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this ? \n DELETING the data will remove ALL records from database.'
        }
        onOk={deleteDeatils}
        onCancel={() => {}}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

      </ModalDynamic>

    </ModalContainer>

    </>
  );
};

export default BradfordModal;
