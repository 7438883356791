import { Switch, TextField } from '@mui/material'
import React, { useState, useEffect, Fragment } from 'react';
import {
    FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

const BodyMapDisplayComponent = ({ setJson, json }) => {
    const [backMode, setBackMode] = useState(false);
    const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck','knees','left-soleus', 'right-soleus']
    // const muscleFromBody = listOfBodyParts.includes(json.bodyMapDisplay.title)
    const muscleFromBody = json?.bodyMapDisplay?.title?.every(item => listOfBodyParts.includes(item));
    const data = [
        { name: '', muscles: (muscleFromBody && json?.bodyMapDisplay?.title) ? [...json.bodyMapDisplay.title] : [] },
    ];

    const handleClick = ({ muscle, data }) => {
        const indexPresent = json?.bodyMapDisplay?.title?.findIndex((i)=>i==muscle);
        if((indexPresent && (indexPresent > 0)) || (indexPresent == 0)){
            let data = [...json.bodyMapDisplay.title]
             data.splice(indexPresent, 1)
            setJson({
                ...json, bodyMapDisplay: {
                    ...json.bodyMapDisplay,
                    title: [...data]
                }
            })
        }else{
            setJson({
                ...json, bodyMapDisplay: {
                    ...json.bodyMapDisplay,
                    title: json.bodyMapDisplay.title ? [...json.bodyMapDisplay.title,muscle] : [muscle]
                }
            })
        }
       
    };

    return (
        <div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginBottom: 10 }}>
                <div style={{ marginBottom: 10, display: "flex" }}>

                    <TextField
                        id="outlined-error"
                        label="Input Label *"
                        placeholder='Input label'
                        defaultValue={json.bodyMapDisplay.label}
                        style={{ marginRight: 10, flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, bodyMapDisplay: {
                                    ...json.bodyMapDisplay,
                                    label: event.target.value
                                }
                            })
                        }}
                    />
                    {/* <TextField
                        id="outlined-error-helper-text"
                        label="Placeholder"
                        placeholder="placeholder"
                        defaultValue={json.bodyMapDisplay.placeholder}
                        style={{ flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, bodyMapDisplay: {
                                    ...json.bodyMapDisplay,
                                    placeholder: event.target.value
                                }
                            })
                        }}
                    /> */}
                </div>

                <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.bodyMapDisplay.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, bodyMapDisplay: {
                                ...json.bodyMapDisplay,
                                description: event.target.value
                            }
                        })
                    }}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.bodyMapDisplay.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, bodyMapDisplay: {
                                ...json.bodyMapDisplay,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                            color="primary"
                            checked={json.bodyMapDisplay.required}
                            onChange={(item) => {
                                setJson({
                                    ...json, bodyMapDisplay: {
                                        ...json.bodyMapDisplay,
                                        required: item.target.checked
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                            color="primary"
                            checked={json.bodyMapDisplay.readOnly}
                            onChange={(item) => {
                                setJson({
                                    ...json, bodyMapDisplay: {
                                        ...json.bodyMapDisplay,
                                        readOnly: item.target.checked
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                            color="primary"
                            checked={json.bodyMapDisplay.showCondition}
                            onChange={(item) => {
                                setJson({
                                    ...json, bodyMapDisplay: {
                                        ...json.bodyMapDisplay,
                                        showCondition: item.target.checked
                                    }
                                })
                            }}
                        />
                    </div>

                </div>
                <div className=''>
                    <div className=''>
                    <div className={'w-100'}>
                      <DropdownSearchable
                        placeHolder={'Choose View'}
                        options={[
                            {id: 'front', name:'Front Body'},
                            {id: 'back', name:'Back Body'},
                            {id: 'both', name:'Both'}
                        ]}
                        selectedOption={json.bodyMapDisplay.view}
                        onChange={value =>
                            setJson({
                                ...json, bodyMapDisplay: {
                                    ...json.bodyMapDisplay,
                                    view: value
                                }
                            })
                        }
                      />
                    </div>
                       
                        <div className="pr-1 mt-1 ">
                            <InputLabelTop
                                // disabled={readonly}
                                required
                                type="text"
                                id="name"
                                label={'Title'}
                                value={json?.bodyMapDisplay?.title?.join(",")}
                                // {...useInput('tempBodyMapData.tittle')}
                                maxlength={50}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        {((json.bodyMapDisplay.view == 'front') || (json.bodyMapDisplay.view == 'both')) && <Model
                            data={data}
                            style={{ width: '16rem', padding: '1rem' }}
                            onClick={handleClick}
                            type={'anterior'}
                        />}
                         {((json.bodyMapDisplay.view == 'back') || (json.bodyMapDisplay.view == 'both')) && <Model
                            data={data}
                            style={{ width: '16rem', padding: '1rem' }}
                            onClick={handleClick}
                            type={'posterior'}
                        />}

                    </div>
                </div>
                {json.bodyMapDisplay.showCondition &&
                    <TextField
                        id="outlined-multiline-static"
                        label="Rule Expression"
                        className='mb-2'
                        multiline
                        rows={3}
                        value={json.bodyMapDisplay.ruleExpression}
                        placeholder="Rule Expression"
                        onChange={(event) => {
                            setJson({
                                ...json, bodyMapDisplay: {
                                    ...json.bodyMapDisplay,
                                    ruleExpression: event.target.value
                                }
                            })
                        }}
                    />}
            </div>
        </div>

    )
}

export default BodyMapDisplayComponent;
