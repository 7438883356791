import {LARGE_COL  , SMALL_COL,EX_SMALL_COL, MEDIUM_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Calendar Year',
    name: 'calendar_year',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },
   {
    label: 'Public Holiday Name',
    name: 'public_holiday_name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Date',
    name: 'date',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Holiday Type',
    name: 'rate_code__name',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Rate Code',
    name: 'rate_code__name',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Country',
    name: 'country__name',
    status: 0,
    className: 'grow-mobile ',
    max_width: LARGE_COL,
  },
  {
    label: 'Bill Multiplier',
    name: 'bill_multiplier',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Pay Multiplier',
    name: 'pay_multiplier',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  // {
  //   label: ' ',
  //   name: ' edit',
  //   status: 0,
  //   className: ' sm-hidden  ',
  //   max_width: SMALL_COL,
  // },
  // {
  //   label: ' ',
  //   name: ' delete',
  //   status: 0,
  //   className: ' sm-hidden  ',
  //   max_width: SMALL_COL,
  // },
];
