import React, {useState, useEffect, Fragment} from 'react';
import { Title,TitleSection} from 'shared/styles/constants/tagsStyles';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {NameWrapper}  from 'shared/styles/constants/tagsStyles';

const ActualBillRate = ({
    dropDownHandleSelect,
    billRate,
      useInput,
      values,
      setValues,
      editMode,
}) => {
  console.log(values, 741852)
  return (
    <div>
        <FlexGrid3Container className={'w-100 mb-1 '}>
          <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"   ResizeWidth={'40%'}>
          <DropdownSearchable
          placeHolder={'Actual Bill Rate Card'}
        disabled={!editMode }
        options={billRate}
        selectedOption={ values   ?  values.actuals_actual_bill_rate_card   : null }
        onChange={value =>
        dropDownHandleSelect(
         {actuals_actual_bill_rate_card: value ? value : null},  'actuals_actual_bill_rate_card','actual'
          )  }
        />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  ResizeWidth={'15%'} >
            <InputLabelTop
                readOnly={true}
                disabled={true}
                label={'Actual Bill Rate/hr'}
          
                {...useInput(`actuals_bill_rateperhr`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 " ResizeWidth={'20%'}>          
            <InputLabelTop
                   readOnly={true}
                   disabled={true}
                label={'Actual Bill Rate'}
   
                {...useInput(`actuals_bill_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  " ResizeWidth={'20%'}>          
            <InputLabelTop
          readOnly={true}
          disabled={true}
                label={'Actual  Bill Amount'}
   
                {...useInput(`actuals_bill_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container >   
            <FlexGrid3Container className={'w-100 mb-1 '}>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Bill Rate/hr'}
   
                {...useInput(`rounded_actual_bill_rate_per_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Bill Rate'}
   
                {...useInput(`rounded_actual_bill_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Rounded Bill Amount'}
   
                {...useInput(`rounded_actual_bill_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container className={'w-100 mb-1 '}>
           
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Bill Rate/hr'}
   
                {...useInput(`banded_actual_bill_rate_per_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Bill Rate'}
   
                {...useInput(`banded_actual_bill_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1   " ResizeWidth={'30%'}>          
            <InputLabelTop
              readOnly={true}
              disabled={true}
                label={'Actual Banded Bill Amount'}
   
                {...useInput(`banded_actual_bill_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container>
    </div>
  )
}

export default ActualBillRate