import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { getVisitTask } from 'utils/api/ClientApi';
import { setVisitTask } from 'utils/api/ClientApi';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
  EX_SMALL_COL4,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import { RiDeleteBinLine } from 'react-icons/ri';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
// import { ColsGrouper, Row, Col } from 'components/SharedComponents/Table/styles';
import { useForm } from 'hooks/FormHook';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import moment from 'moment';
import {
  CloseButtonWrapper,
  DropdownTypesContainer,
  FiltersContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { TIMES } from 'utils/constants/icons';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import AddNewCare from '../AddModal/AddNewCare';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  WeekContainer,
  WeekStyle,
  ButtonWeek,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
  ColsGrouper,
  row,
} from 'components/SharedComponents/Table/styles';
import { data } from 'jquery';
import { del } from 'dot-object';
import { ItemsDropdownStyle } from 'components/SharedComponents/NoSelectAllDropdownFilter/styles';
import { debounce, deburr } from 'lodash';
import { useModal } from 'hooks/ModalHook';
import CustomDropdownSearchable from 'components/SharedComponents/DropdownSearchable/CustomDropdowns';
import { visit_task_choices } from 'utils/choiceConstant';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { useMemo } from 'react';
import { useRef } from 'react';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import Spinner from 'components/SharedComponents/Spinner';
export const headerColumns = [
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0 md-hidden',
    max_width: EX_SMALL_COL,
  },
  {
    label: 'Swap',
    name: 'file_name',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Essential',
    name: 'file_name',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'Care Category',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Task Type',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Care Task',
    name: 'description',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Message',
  //   name: 'uploaded',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Result',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Support Level',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  // {
  //   label: 'Feedback',
  //   name: 'uploaded',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Status',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
];

const VisitTasks = ({
  visitId,
  selectedClientId,
  visitData = {},
  handleChangeChoices = () => { },
}) => {
  const { visit_start_date, visit_end_date, id: service_visit } = visitData;
  const { contextChoices, handleGetChoices } = useContext(AppContext);

  // const { values, setValues, useInput } = useForm({});
  console.log(visitData,visit_start_date,
    visit_end_date,
    service_visit, 'visitData')
  const TASK_VALUE = 'task_value';
  const [taskData, setTaskData] = useState([]);
  const [employeenameFilter, setEmployeeNameFilter] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [Data, setData] = useState();
  const [category, setCategory] = useState('');
  const [visitid, setVisitID] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusName, setStatusName] = useState();
  const [newArray, setNewArray] = useState([]);
  const [modalLoading, setModalLoading] = useState(true);
  const [clientTaskData, setClientTaskData] = useState([]);
  const [newCare, setCare] = useState();
  const [indexData, setIndexData] = useState();
  const [expandFields, setExpandFields] = useState({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    handleGetChoices(visit_task_choices);
  }, []);

  const {
    setValue: setAddNewCareTask,
    value: AddNewCareTask,
    fullBind: fullBindCareTask,
  } = useModal(false);

  const [value, setValue] = useState({
    care_task: null,
    message: null,
    status: null,
  });
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({ [TASK_VALUE]: [] });

  const addNewRow = () => {
    
    values[TASK_VALUE] = [{}, ...values[TASK_VALUE]];
    const initialExpansionState = {};
    values[TASK_VALUE] && values[TASK_VALUE].forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setValues({ ...values });
    setTaskData({ values });
  };

  const deleteRow = shiftIndex => {
    values[TASK_VALUE].splice(shiftIndex, 1);

    setValues({ ...values });
  };
 
  useEffect(() => {
    values.visit_start_date = visit_start_date;
    values.visit_end_date = visit_end_date;
    values.service_visit = service_visit;
    setValue(values)
    setNewArray([values[TASK_VALUE]]);
    
  }, [values.date_from, values.date_to, values.employee_name, contextChoices]);

  useEffect(()=>{
    if (contextChoices) {
      getVisitTaskData();
    }
  },[contextChoices])

  const filterEmployeeName = item => {
    setEmployeeNameFilter([...item]);
  };

  useEffect(() => {
    // contextChoices.care_task
    setEmployeeName(
      // let newdetail = newdata.task_type.find((items) => {
      //   if (items.id === value) {
      //     return items
      //   }
      // })
      contextChoices &&
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    );
  }, [contextChoices]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
debugger
  const getVisitTaskData = async () => {
    try {
      const response = await getVisitTask(null, values);

      setData(response.data);
      if (response && response?.data) {
        console.log(response?.data, "response")
        let newData = response?.data?.map(item => {
          let newTaskData = {}
          let newdata_care_task = {}
          let result_opt = {}

          if (item.care_category) {
            newTaskData = contextChoices?.care_category?.find(items => {
              if (items.id === item.care_category) {
                return items;
              }
            });
          }

          if (newTaskData && item?.task_type) {
            newdata_care_task = newTaskData?.task_type?.find(items => {
              if (items.id === item.task_type) {
                return items;
              }
            });

            if (newdata_care_task && item?.care_task) {
              result_opt = newdata_care_task &&
                newdata_care_task?.care_task.find(items => {
                  if (items.id === item.care_task) {
                    return items;
                  }
                });
            }
          }

          return {
            care_task: item.care_task || null,
            status: item.status || null,
            message: item.message || null,
            result: item.result_option || null,
            result_type: item.result_type || null,
            result_option: item.result_option || null, 
            result_options: result_opt.result_option
              ? result_opt.result_option
              : [],
            is_essential: item.is_essential || false,
            feedback: item.feedback || null,
            newdata_care_task: item.newdata_care_task || null,
            task_type_options: newTaskData.task_type || null,
            care_task_options: newdata_care_task.care_task || null,
            care_category: item.care_category || null,
            task_type: item.task_type || null,
            id: item.id || null,
            support_level: item.support_level|| null,
          };
        });
        const initialExpansionState = {};
    newData && newData.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    console.log(newData,"newData")
        setValues({...values, task_value: newData });
        setNewArray([...newArray, ...newData]);
        setLoading(false)
      }
    } catch { 
      setLoading(false)
    }
  };

  const handleDropDown = (item, nameItem, index, value) => {
    debugger
    setIndexData(index);
    if (nameItem === 'care_category') {
      let newdata = contextChoices.care_category.find(items => {
        if (items.id === value) {
          return items;
        }
      });

      values[TASK_VALUE][index]['task_type_options'] = newdata.task_type;
    } else if (nameItem === 'task_type') {
      let newdata_task = values[TASK_VALUE][index]['task_type_options'].find(
        items => {
          if (items.id === value) {
            return items;
          }
        }
      );
      values[TASK_VALUE][index]['care_task_options'] = newdata_task.care_task;
    } else if (nameItem === 'care_task') {
      let newdata = contextChoices.care_category.find(items => {
        if (items.id === value) {
          return items;
        }
      });

      values[TASK_VALUE][index]['1'] = newdata.care_task;

      let newdata_care_task = values[TASK_VALUE][index][
        'care_task_options'
      ].find(items => {
        if (items.id === value) {
          return items;
        }
      });

      values[TASK_VALUE][index]['result_type'] =
        newdata_care_task && newdata_care_task.result_type;
      values[TASK_VALUE][index]['result_options'] =
        newdata_care_task && newdata_care_task.result_option;
    } else if (nameItem === 'care_task_data') {
      if (value === 'new') {
        setAddNewCareTask(true);
      } else if (value !== 'new') {
        let newCareValue = contextChoices.care_task.find(items => {
          if (items.id === value) {
            return items;
          }
        });
        let newTaskValue = contextChoices.task_type.find(items => {
          if (items.id === newCareValue?.task_type) {
            return items;
          }
        });
        if (newCareValue) {
          values[TASK_VALUE][index]['task_type'] = newCareValue?.task_type;

          values[TASK_VALUE][index]['care_category'] =
            newTaskValue?.care_category;
        }
      } else {
        loadDataOnCareTask(value.task_id && value.task_id);
        values.task_id = value.task_id;
        setValues({ ...values });

        let newTask_Type = contextChoices.care_task.find((items, index) => {
          if (items.id === value) {
            return items;
          }
        });
        values[TASK_VALUE][index]['task_type_option'] = newTask_Type.task_type;

        let new_taskType_Option = contextChoices.task_type.find(item => {
          if (item.id === newTask_Type.task_type) {
            return item;
          }
        });
        values[TASK_VALUE][index]['task_type_options'] = [new_taskType_Option];
        setCare({ ...values[TASK_VALUE][index]['task_type_options'] });
        if (new_taskType_Option) {
          let newCare = contextChoices.care_category.find(item => {
            if (item.id === new_taskType_Option.care_category) {
              return item;
            }
          });
          values[TASK_VALUE][index]['care_category_option'] = [newCare];
        }
      }
    } else if (nameItem === 'task_type_data') {
      let newDetails = contextChoices.task_type.find(item => {
        if (item.id === value) {
          return item;
        }
      });
      values[TASK_VALUE][index]['care_task_options'] = newDetails?.care_task;
      let newCareCategory = contextChoices.care_category.find(item => {
        if (item.id === newDetails.care_category) {
          return item;
        }
      });
      values[TASK_VALUE][index]['care_category_option'] = [newCareCategory];

      let newCareTask = contextChoices.care_task.find(item => {
        if (item.id === value) {
          return item;
        }
      });
    }

    if (nameItem === 'status') {
      let newData = contextChoices.task_status.find(item => {
        if (item.id == value) {
          return item.name;
        }
      });

      let newStatus = newData.name;
      values[TASK_VALUE][index]['status_options'] = newData.newStatus;

      setStatusName(newStatus);
    }

    values[TASK_VALUE][index][nameItem] = item[nameItem];
    setValues({ ...values });
  };

  const handleResult = (val, item, index) => {
    val.preventDefault();
    values[TASK_VALUE][index].result_option = val.target.value;
    setValues({ ...values });
  };
  const handleFeedback = (val, item, index) => {
    val.preventDefault();
    values[TASK_VALUE][index].feedback = val.target.value;
    setValues({ ...values });
  };
  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setErrorMessage('');
    getVisitTaskData()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setErrorMessage(InvalidOrEmptyValues);
  };
  const setNewTask = async () => {
    const body_data = values[TASK_VALUE].map((item, index) => {
       return {
         care_category: item.care_category || item.care_category,
        care_task: item.care_task_data || item.care_task,
        task_type: item.task_type || item.task_type,
        feedback: item.feedback || null,
        result: item.result_option || [],
        status: item.status || 'Not Started',
        ordered: index + 1,
        message: item.message || null,
        result_type: item.result_type || null,
        result_option: item.result_option || null,
        support_level: item.support_level,
        is_essential: item.is_essential,
        id: item?.id && item?.id
      };
    });

    const data = { task: body_data };

    try {
      let response = await setVisitTask(data, visitId, values);
      if (response) {
        onSuccess();
      } else {
        onError();
      }
    } catch {
      onError();
    }
  };

  const getOptions = value => {
    let arr = value.split(',');
    let finalArray = arr.map(item => {
      return {
        id: item,
        name: item,
      };
    });
    return finalArray;
  };
  const handleSwap = (index, arrow) => {
    let array = [...values[TASK_VALUE]];
    if (arrow === 'up') {
      if (index) {
        let temp = array[index];
        array[index] = array[index - 1];
        array[index - 1] = temp;
      }
    } else {
      let temp = array[index];
      array[index] = array[index + 1];
      array[index + 1] = temp;
    }
    values[TASK_VALUE] = array;
    setValues({ ...values });
  };
  const handleDropDownSelect = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  };
  const loadDataOnCareTask = id => {
    let careTaskArr = [];
    let arrLength = clientTaskData.length;
    contextChoices.care_category.forEach(item => {
      item.task_type &&
        item.task_type.forEach(task_item => {
          task_item &&
            task_item.care_task.forEach(care_item => {
              if (id === care_item.id) {
                careTaskArr.push({
                  care_task: care_item.id,
                  message: '',
                  care_task_name: care_item.name,
                  task_type_name: task_item.name,
                  care_category_name: item.name,
                  is_essential: item.is_essential,
                });
              }
            });
        });
    });
    let careData = careTaskArr.map((val, index) => {
      return {
        care_task: val.care_task,
        message: '',
        ordered: arrLength + index + 1,
        care_task_name: val.care_task_name,
        task_type_name: val.task_type_name,
        care_category_name: val.care_category_name,
        support_level: val.support_level,
      };
    });
    values.group_id = null;
    values.task_id = null;

    const initialExpansionState = {};
    careData && careData.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    
    setClientTaskData([...clientTaskData, ...careData]);
    setValues({ ...values });
  };
  const handleSelectChange = ind => {
    values[TASK_VALUE].forEach((item, index) => {
      if (index == ind) {
        values[TASK_VALUE][ind].is_essential =
          !values[TASK_VALUE][ind].is_essential;
      }
      setValue({ ...values });
    });
  };

  const handleExpansion = (clientIndex) => {
    if (expandFields[clientIndex].open) {
      expandFields[clientIndex].open = false;
      setExpandFields({ ...expandFields });
    } else {
      expandFields[clientIndex].open = true;
      setExpandFields({ ...expandFields });
    }
  };


  const handleMessage = (val, item, index) => {
    // val.preventDefault();
    values[TASK_VALUE][index].message = val;
    setValues({ ...values });
  };

console.log(values[TASK_VALUE],"values")
  const renderSwapArrow = (index, length) => {
    let arrow = ""
    if (length == 1)
        arrow = <React.Fragment />
    else if (index == 0) {
        arrow = (<div className="d-flex flex-column">
            <FiChevronDown
                className="align-self-center"
                size={18}
                color={PURPLE}
                onClick={() =>
                    handleSwap(index, 'down')
                }
            />
        </div>)
    } else if (index == length - 1) {
        arrow = <div className="d-flex flex-column">
            <FiChevronUp
                className="align-self-center"
                size={18}
                color={PURPLE}
                onClick={() =>
                    handleSwap(index, 'up')
                }
            />
        </div>
    } else {
        arrow = (
            <div className="d-flex flex-column">
                <FiChevronUp
                    className="align-self-center"
                    size={18}
                    color={PURPLE}
                    onClick={() =>
                        handleSwap(index, 'up')
                    }
                />
                <FiChevronDown
                    className="align-self-center"
                    size={18}
                    color={PURPLE}
                    onClick={() =>
                        handleSwap(index, 'down')
                    }
                />
            </div>
        )
    }
    return arrow
}

  return (
    <div>
      <ComponentDynamic loading={false} style={{ maxHeight: '100px' }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FaPlus color={PURPLE} size={40} onClick={addNewRow} />
          </div>
          <>
            <div
              className="mt-3"
              style={{
                maxHeight: '600px',
                height: '20%',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}>
              {loading ? 
              <div style={{ marginTop: '300px' }}>
              <Spinner />
            </div>
              :<>
              {values[TASK_VALUE] && values[TASK_VALUE].length > 0 ? (
                <Table
                  headerColumns={headerColumns}
                  headerPadding={false}
                  noEditOption={true}
                  fontSize="0.75rem"
                  callBackQuerySort={setQuerySort}>
                  <div>
                    {values[TASK_VALUE] &&
                      values[TASK_VALUE].map((shift, index) => {
                        return (
                          <div key={index}>
                            <Row
                              bgColor
                              Shadow
                              width={'111%'}
                              zIndex={values[TASK_VALUE].length - index}>
                              <ColsGrouper
                                className="d-flex"
                                margin-left={'77px'}
                                width={'111%'}>
                                <Col
                                  max_width={EX_SMALL_COL}
                                  className="d-flex justify-content-center align-content-center">
                                  <CloseButtonWrapper
                                    className="closeButtonWrapper"
                                    onClick={() => {
                                      handleExpansion(index);
                                    }}>
                                    {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                      <IoIosArrowDown
                                        style={{ color: '#6200f9', fontWeight: 'bold' }}
                                      />
                                    ) : (
                                      <IoIosArrowForward
                                        style={{ color: '#6200f9', fontWeight: 'bold' }}
                                      />
                                    )}
                                  </CloseButtonWrapper>
                                </Col>
                                <Col
                                  max_width={EX_SMALL_COL4}
                                  Shrink={false}
                                  NoFlexGrow={true}
                                  Capitalize
                                  style={{
                                    // maxWidth: '20%',
                                    paddingLeft: '15px',
                                    paddingRight: '24px',
                                  }}>
                                  {/* <div className="d-flex flex-column">
                                    <FiChevronUp
                                      className="align-self-center"
                                      size={18}
                                      color={PURPLE}
                                      onClick={() =>
                                        handleSwap(shift, index, 'up')
                                      }
                                    />
                                    <FiChevronDown
                                      className="align-self-center"
                                      size={18}
                                      color={PURPLE}
                                      onClick={() =>
                                        handleSwap(shift, index, 'down')
                                      }
                                    /> 
                                  </div>*/}
                                    {renderSwapArrow(index, values[TASK_VALUE].length)}
                                </Col>
                                <Col
                                  // style={{marginLeft:"10px"}}
                                  max_width={SMALL_COL}
                                  Capitalize
                                  // left={'4.5rem'}
                                  // width={'111%'}
                                  style={{ width: '80%' }}>
                                  <input
                                    style={{ width: '20px', height: '20px' }}
                                    type="checkbox"
                                    checked={
                                      values[TASK_VALUE][index].is_essential
                                    }
                                    onChange={() => {
                                      handleSelectChange(index);
                                    }}
                                  />
                                </Col>

                                <Col
                                  // style={{marginLeft:"10px"}}
                                  max_width={EX_LARGE_COL}
                                  Capitalize
                                  // left={'4.5rem'}
                                  // width={'111%'}
                                 
                                 >
                                  <div  style={{ width: '100%', paddingRight: '2px' }}>
                                  <CustomDropdownSearchable
                                     style={{ width: '100%', paddingRight: '2px' }}
                                    // style={{ margin: '2px' }}
                                    placeHolder="Care Category"
                                    // {...useInput(`${[TASK_VALUE]}[${index}].care_category`)}
                                    // value={ values[TASK_VALUE][index].care_category}
                                    options={
                                      values[TASK_VALUE][index]
                                        .care_category_option
                                        ? values[TASK_VALUE][index]
                                          .care_category_option
                                        : contextChoices.care_category
                                    }
                                    selectedOption={
                                      values[TASK_VALUE][index].care_category
                                        ? values[TASK_VALUE][index]
                                          .care_category
                                        : null
                                    }
                                    onChange={value =>
                                      handleDropDown(
                                        { care_category: value ? value : null },
                                        'care_category',
                                        [index],
                                        value
                                      )
                                    }
                                  />
                                  </div>
                                </Col>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  
                                  >
                                  <div style={{ width: '100%', paddingLeft: '2px' }}>
                                  <CustomDropdownSearchable
                                    style={{ width: '100%', paddingLeft: '2px' }} 
                                    placeHolder="Task Type"
                                    options={
                                      values[TASK_VALUE][index]
                                        .task_type_options
                                        ? values[TASK_VALUE][index]
                                          .task_type_options
                                        : contextChoices.task_type
                                    }
                                    selectedOption={
                                      values[TASK_VALUE][index].task_type
                                        ? values[TASK_VALUE][index].task_type
                                        : values[TASK_VALUE][index]
                                          .task_type_data
                                    }
                                    onChange={value =>
                                      handleDropDown(
                                        {
                                          task_type_data: value
                                            ? value
                                            : contextChoices.task_type,
                                        },
                                        'task_type_data',
                                        [index],
                                        value
                                      )
                                    }
                                  />
                                  </div>
                                </Col>
                                <Col
                                  max_width={EX_LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize>
                                  <CustomDropdownSearchable
                                    width={'200%'}
                                    placeHolder="Care Task"
                                    options={
                                      values[TASK_VALUE][index]
                                        .care_task_options
                                        ? values[TASK_VALUE][index]
                                          .care_task_options
                                        : contextChoices.care_task
                                          ? [
                                            {
                                              id: 'new',
                                              name: '+ New Care Task',
                                            },
                                            ...contextChoices.care_task,
                                          ]
                                          : []
                                    }
                                    selectedOption={
                                      values[TASK_VALUE][index].care_task
                                        ? values[TASK_VALUE][index].care_task
                                        : values[TASK_VALUE][index]
                                          .care_task_data
                                    }
                                    onChange={value =>
                                      handleDropDown(
                                        {
                                          care_task_data: value
                                            ? value
                                            : contextChoices.care_task,
                                        },
                                        'care_task_data',
                                        [index],
                                        value
                                      )
                                    }
                                  // style={{max_width: '500px'}}
                                  />
                                </Col>
                                {/* <Col
                                  max_width={EX_LARGE_COL}
                                
                                >
                                 

                                  <InputTextArea
                                    disabled={false}
                                    type="text"
                                    height={'4rem'}
                                    id={`message${index}`}
                                    label={'Message'}
                                    maxlength={5000}
                                    noErrorNeed={true}
                                    onChange={val =>
                                      handleMessage(val, shift, index)
                                    }
                                    value={values[TASK_VALUE][index].message || ''}
                                  />
                                </Col> */}

                                <Col
                                  max_width={LARGE_COL}
                                // Shrink
                                // reducePadding
                                // Capitalize
                                >
                                   <div style={{ width: '100%', paddingLeft: '2px' }}>
                                  {shift.result_type === 'option' ? (
                                    <DropdownSearchable
                                      width={'111%'}
                                      // margin={'3px'}
                                      placeHolder="result"
                                      // value={
                                      //   values[TASK_VALUE][index]
                                      //     .newdata_care_task
                                      // }
                                      options={
                                        shift.result_options
                                          ? getOptions(shift.result_options)
                                          : []
                                      }
                                      selectedOption={
                                        values[TASK_VALUE][index].result_option
                                          ? values[TASK_VALUE][index]
                                            .result_option
                                          : null
                                      }
                                      onChange={value =>
                                        handleDropDown(
                                          {
                                            result_option: value ? value : null,
                                          },
                                          'result_option',
                                          [index],
                                          value
                                        )
                                      }
                                      style={{ max_width: '500px' }}
                                    />
                                  ) : shift.result_type === 'text' ? (
                                    <InputLabelTop
                                      margin={'5px'}
                                      readOnly={
                                        shift.result_type === 'text'
                                          ? false
                                          : true
                                      }
                                      style={{ margin: '2px' }}
                                      type="text"
                                      value={values[TASK_VALUE][index].result}
                                      id={`result${index}`}
                                      onChange={val =>
                                        handleResult(val, shift, index)
                                      }
                                      maxlength={100}
                                      key={contextChoices}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  </div>
                                </Col>

                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize>
                                  <DropdownSearchable
                                    width={'111%'}
                                    // marginLeft={'0.5%'}
                                    options={contextChoices.support_level}
                                    selectedOption={
                                      values[TASK_VALUE][index].support_level
                                        ? values[TASK_VALUE][index]
                                          .support_level
                                        : null
                                    }
                                    onChange={value =>
                                      handleDropDown(
                                        { support_level: value ? value : [] },
                                        'support_level',
                                        [index],
                                        value
                                      )
                                    }
                                  />
                                </Col>

                                {/* <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize>
                                  <InputTextArea



                                    disabled={false}
                                    height={'4rem'}
                                    id={`feedback${index}`}
                                    label={'FeedBack'}
                                    maxlength={5000}
                                    noErrorNeed={true}
                                    onChange={val =>
                                      handleFeedback(val, shift, index)
                                    }
                                    value={values[TASK_VALUE][index].feedback || ''}






                                  />
                                </Col> */}
                                <Col
                                  max_width={LARGE_COL}
                                // Shrink
                                // reducePadding
                                // Capitalize
                                >
                                  <DropdownSearchable
                                    width={'111%'}
                                    placeHolder="Status"
                                    options={contextChoices.task_status}
                                    style={{ max_width: '500px' }}
                                    selectedOption={
                                      values[TASK_VALUE][index].status
                                        ? values[TASK_VALUE][index].status
                                        : null
                                    }
                                    onChange={value =>
                                      handleDropDown(
                                        { status: value ? value : null },
                                        'status',
                                        [index],
                                        value
                                      )
                                    }
                                  />
                                </Col>
                                <Col
                                  className="sm-hidden pl-2"
                                  Center
                                  Shrink
                                  NoFlexGrow>
                                  <IconWrapper onClick={() => deleteRow(index)}>
                                    <RiDeleteBinLine />
                                  </IconWrapper>
                                </Col>
                              </ColsGrouper>
                            </Row>
                            {expandFields && expandFields[index] && expandFields[index]?.open &&
                              <div className='wrapper-class mt-4'>
                                <small className="pr-1 " style={{ color: 'black' }}>Feedback</small>
                                <InputTextArea
                                 disabled={false}
                                 height={'4rem'}
                                 id={`feedback${index}`}
                                 label={'FeedBack'}
                                 maxlength={5000}
                                 noErrorNeed={true}
                                 onChange={val =>
                                   handleFeedback(val, shift, index)
                                 }
                                 value={values[TASK_VALUE][index].feedback || ''}
                                />

                                <div className='mt-3'>
                                <small className="pr-1 " style={{ color: 'black' }}>Task Description</small>
                                          <RichTextEditor
                                            value={shift.message}
                                            tabIndex={index} // tabIndex of textarea
                                            onChange={newContent => handleMessage(newContent, shift, index)}
                                          />
                                </div>
                              </div>

                            }
                          </div>
                        );
                      })}
                  </div>
                </Table>
              ) : <NoDataFoundMessage>
                No data found
              </NoDataFoundMessage>}
              </>}
              <div
                className={'ml-0 ml-md-3'}
                style={{ display: 'flex', justifyContent: 'right' }}>
                <PrimaryButtonForm
                  disabled={
                    values[TASK_VALUE] && values[TASK_VALUE].length > 0
                      ? false
                      : true
                  }
                  style={{ marginTop: '200px', textAlign: 'right' }}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={setNewTask}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
                {AddNewCareTask && (
                  <AddNewCare
                    fullBind={fullBindCareTask}
                    setModalLoading={setModalLoading}
                    modalLoading={modalLoading}
                    handleChangeChoices={handleChangeChoices}
                    contextChoices={contextChoices}
                    loadDataOnCareTask={loadDataOnCareTask}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </ComponentDynamic>
    </div>
  );
};

export default VisitTasks;
