import Spinner from 'components/SharedComponents/Spinner'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles'
import { useModal } from 'hooks/ModalHook'
import React from 'react'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FloatingMobileButton } from 'shared/styles/buttons'
import { EX_SMALL_COL, EX_VERY_LARGE_COL } from 'shared/styles/constants/columns'
import { Header, IconWrapper } from 'shared/styles/constants/tagsStyles'
import AddHRTask from '../Modals/AddHRTask'
import { useForm } from 'hooks/FormHook'
import { addHrTask, deleteHrTask, getHrTaskListApi } from 'utils/api/SettingsApi'
import { useEffect } from 'react'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams'
import Pagination from 'components/SharedComponents/Pagination'
import ModalDecision from 'components/SharedComponents/ModalDecision'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'
import { AppContext } from 'context'
import { useContext } from 'react'

const headerColumns = [
  {
    label: "Title",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: "Instruction",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: ' sm-hidden ',
    max_width: EX_SMALL_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: ' sm-hidden ',
    max_width: EX_SMALL_COL,
  },
]

const ADD_MESSAGE = 'QA task added successfully!'
const DELETE_MESSAGE = 'QA task deleted successfully!'
const UPDATE_MESSAGE = 'QA task updated successfully!'
const SOMETHING_WENT_WRORNG = "Something went wrong!"

function HRTask({
  props,
  readPermission,
  createPermission,
  deletePermission,
  updatePermission
}) {
  const toast = useRef();
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);

  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);
  const {
    setValue: setModalForceDelete,
    bind: bindModalForceDelete
  } = useModal(false);

  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});
  const { setContextChoices, contextChoices, handleGetChoices } = useContext(AppContext);

  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [hrTaskList, setHrTaskList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [seletedId, setSelectedId] = useState(null)
  const [isdisabledDeleteButton, setIsdisabledDeleteButton] = useState(false)

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const getHrTaskListData = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage,
    )
    try {
      const res = await getHrTaskListApi({ ...values, ...pageToSearch })
      setHrTaskList(res?.results)
      setPages(Math.ceil(res?.count / values?.limitperpage));
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleGetChoices(['hr_task'])
  }, [])

  const handleSubmit = async () => {

    if (errors.length) {
      setCheckRequires(true)
      setShowError(true)
      setErrorMessage('Some of the fields have invalid or empty values. Please review');
      return;
    }
    setModalValue(false)
    setLoadingSave(true)
    setShowError(false)
    const body = {
      title: values.title,
      instruction: values.instruction,
    };
    try {
      const res = await addHrTask(seletedId, body);
      setModalValue(false)
      setLoadingSave(false)
      if (res.status) {
        getHrTaskListData()
        if (contextChoices && contextChoices?.hr_task) {
          const existingHrTask = contextChoices?.hr_task || [];
          const updatedHrTask = [...existingHrTask, { ...res.data }];
          setContextChoices({
            ...contextChoices,
            hr_task: updatedHrTask
          });
        } else {
          setContextChoices({ ...contextChoices, hr_task: [res.data] });
        }
        // console.log(contextChoices, resss, "hr task")
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: seletedId ? UPDATE_MESSAGE : ADD_MESSAGE
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: SOMETHING_WENT_WRORNG,
        });
      }

    } catch (error) {
      console.error(error)
    }
  };

  const handleDeleteHrTask = async (isForce) => {
    setIsdisabledDeleteButton(true)
    const body = {
      force: isForce
    }
    try {
      const res = await deleteHrTask(seletedId, body)
      setModalDeleteValue(false)
      setModalForceDelete(false)
      setIsdisabledDeleteButton(false)
      if (res.status) {
        if (res.is_force) {
          setModalForceDelete(true)
        } else {
          if (contextChoices && contextChoices?.hr_task) {
            let filteredHrTask = contextChoices?.hr_task.filter(item => item.id !== seletedId)
            setContextChoices({ ...contextChoices, hr_task: filteredHrTask });
          }
          getHrTaskListData()
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: DELETE_MESSAGE,
          })
        }

      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: SOMETHING_WENT_WRORNG,
        });
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getHrTaskListData()
  }, [values.limitperpage, props.location.search])

  //Add Modal open
  const handleModalOpen = () => {
    setTitle('Add QA Task')
    setShowError(false)
    setCheckRequires(false)
    setSelectedId(null)
    setModalValue(true)
    values.title = ""
    values.instruction = ""
    setValues({ ...values })
  }

  //Update Modal open
  const handleEditClick = (task) => {
    setTitle('Update QA Task')
    setShowError(false)
    setCheckRequires(false)
    setSelectedId(task.id)
    values.title = task.title
    values.instruction = task.instruction
    setValues({ ...values })
    setModalValue(true)
  }

  //Limit per page dropdown
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const handelOpenDeletePopup = (taskId) => {
    setSelectedId(taskId)
    setModalDeleteValue(true)
  }

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])
  return (
    <div className='w-100'>
      <Toast ref={toast} />

      {createPermission && (
        <div className="d-flex w-100 justify-content-end" >
          <FloatingMobileButton
            style={{ marginTop: "-25px", marginBottom: "-10px" }}
            onClick={handleModalOpen}>
            <FaPlus />
            <label>NEW QA TASK</label>
          </FloatingMobileButton>
        </div>
      )}
      {/* <Header>  </Header> */}
      <div>
        {isLoading ?
          (<div style={{ marginTop: "300px" }} >
            <Spinner />
          </div>) :
          <div className='mt-4'>
            {hrTaskList.length > 0 ?
              (<>
                <Table
                  headerColumns={headerColumns}
                  // handleAllCheck={handleAllCheck}
                  // handleAllSelectChange={handleAllSelectChange}
                  headerPadding={true}
                  noNeed={false}
                  noEditOption={true}
                >
                  {hrTaskList.map((task, index) => {
                    let instruction = task.instruction ? task.instruction.length > 50 ? task.instruction.slice(0, 50) + "..." : task.instruction : null;
                    return (
                      <div key={index}>
                        <Row bgColor Shadow >
                          <ColsGrouper className="d-flex" style={{ cursor: "" }}>
                            <Col
                              max_width={EX_VERY_LARGE_COL}
                              Center
                              className="sm-hidden"
                              Capitalize
                              overlap={true}
                            >
                              {task.title}
                            </Col>

                            <Col
                              max_width={EX_VERY_LARGE_COL}
                              Center
                              className="sm-hidden"
                              Capitalize
                              overlap={true}
                            >
                              {instruction}
                            </Col>
                            <Col
                              max_width={EX_SMALL_COL}
                              onClick={() => { handleEditClick(task) }}
                              className="sm-hidden"
                              Center
                              Icon
                              Purple>
                              <IconWrapper>
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                          </ColsGrouper>
                          <Col
                            className="sm-hidden"
                            Center
                            Shrink
                            NoFlexGrow
                            style={{ marginRight: "10px" }}
                          >
                            {deletePermission && (
                              <IconWrapper
                                onClick={() => {
                                  handelOpenDeletePopup(task?.id)
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            )}
                          </Col>

                        </Row>
                        <div className="mb-1" />
                      </div>

                    )
                  })

                  }
                </Table>
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={hrTaskList.length}
                  />
                </div>
              </>) : (
                (<div className='d-flex w-100 align-items-center justify-content-center mt-3'>
                  <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                </div>)
              )
            }
          </div>
        }
      </div>

      {modalValue && (
        <AddHRTask
          fullBind={fullBind}
          useInput={useInput}
          values={values}
          setValues={setValues}
          seletedId={seletedId}
          getHrTaskListData={getHrTaskListData}
          setModalValue={setModalValue}
          toast={toast}
          readOnly={seletedId ? !updatePermission : !createPermission}
          errors={errors}
          setCheckRequires={setCheckRequires}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          showError={showError}
          setShowError={setShowError}
          loadingSave={loadingSave}
          handleSubmit={handleSubmit}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        isdisabled={isdisabledDeleteButton}
        body={
          'Are you sure you want to delete QA Task?'
        }
        onOk={() => handleDeleteHrTask(false)}
        onCancel={() => { }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

      <ModalDecision
        type="delete"
        title="Warning"
        isdisabled={isdisabledDeleteButton}
        body={
          'This QA Task is associated with Event QA Task. Do you still want to delete it?'
        }
        onOk={() => handleDeleteHrTask(true)}
        onCancel={() => { }}
        okTitle={'DELETE'}
        {...bindModalForceDelete}
      />
    </div>


  )
}

export default HRTask