import {getToken, clearToken, setBadGateway, clearBadGateway} from '../localStorage/token';
import {bad_gateway_status_code, headers, methods, unauthorized_status_code} from 'utils/constants/api';

const {DELETE, PATCH} = methods;

export const get = async url => {
  const response = await fetch(url, {
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

   if(response && response.status === bad_gateway_status_code){
     setBadGateway(true)
   }else{
    clearBadGateway()
   }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }
  const json = await response.json();

  return json;
};
export const newGet = async url => {
  const response = await fetch(url, {
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }
  const json = await response.json();

  return {...json, status_code: response.status};
};
export const post = async (url, body, method) => {
  let currentDate = new Date()

  //console.log(currentDate, 'current');

  const response = await fetch(url, {
    method,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
  console.log(response, 'response');

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }

  const j = await response.text()
  //console.log(j, new Date(), 'current text');
  const json = JSON.parse(j)
  
  //console.log(json, new Date(), 'current json');
  if (json !== 'Running!') json.statusCode = response.status;
  // Todo: import clearTocken from '../localStorage/token';
  // if (response.status !== 200) {
  //   if (response.status === 401) clearToken();
  //   throw json;
  // }

  return json;
};

export const post2 = async (url, body, method) => {
  const response = await fetch(url, {
    method,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
  console.log(response, 'response');

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }

  const json = await response.json();
  console.log(json, 'json');
 

  return json;
};

export const Newpost = async (url, body, method) => {
  const response = await fetch(url, {
    method,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
  console.log(response, 'response');

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }

  const json = await response.json();
  console.log(json, 'json');
  // if (json !== 'Running!')
  //  json.statusCode = response.status;
  // Todo: import clearTocken from '../localStorage/token';
  // if (response.status !== 200) {
  //   if (response.status === 401) clearToken();
  //   throw json;
  // }

  return json;
};

export const postWithTimeout = async (url, body, method) => {
  const response = await fetch(url, {
    method,
    timeout: 1800000,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }

  const json = await response.json();
  if (json !== 'Running!') json.statusCode = response.status;
  // Todo: import clearTocken from '../localStorage/token';
  // if (response.status !== 200) {
  //   if (response.status === 401) clearToken();
  //   throw json;
  // }

  return json;
};

export const deleteFetch = async url => {
  const response = await fetch(url, {
    method: DELETE,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }
  const json = await response?.json();

  // Todo: import clearTocken from '../localStorage/token';
  // if (response.status !== 200) {
  //   if (response.status === 401) clearToken();
  //   throw json;
  // }
  return json;
};
export const deleteFetch2 = async url => {
  const response = await fetch(url, {
    method: DELETE,
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }

  const json = await response;

  // Todo: import clearTocken from '../localStorage/token';
  // if (response.status !== 200) {
  //   if (response.status === 401) clearToken();
  //   throw json;
  // }

  return json;
};

export const patch = async (url, body, method) => {
  const response = await fetch(url, {
    method: PATCH,
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: 'Token ' + getToken(),
    },
    body: JSON.stringify(body),
  });

  if(response && response.status === bad_gateway_status_code){
    setBadGateway(true)
  }else{
   clearBadGateway()
  }

  const json = await response.json();

  return json;
};


export const getWithPayload = async (url, body) => {
  debugger
  const response = await fetch(url, {
    method: 'GET',
    headers: {  
      "Content-Type": "application/json",
      Authorization: 'Token ' + getToken(),
    },
    body: JSON.stringify(body),
  });
debugger
if(response && response.status === bad_gateway_status_code){
  setBadGateway(true)
}else{
 clearBadGateway()
}

  if (response && response.status === unauthorized_status_code) {
    clearToken();
    window.location.reload();
  }
  const json = await response.text();
  debugger
  return json;
};