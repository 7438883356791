import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {
  FlexGrid3Item,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const BodyMapModal = ({
  fullBind,
  setModalBodyMap,
  useInput,
  values,
  setValues,
  addBodyParts,
  updateBodyParts,
  update,
  isClient
}) => {

  const [loadingModal, setLoadingModal] = useState(true);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [backMode, setBackMode] = useState(false);
  const toast = useRef();

  const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck']
  const muscleFromBody = listOfBodyParts.includes(values.tempBodyMapData.tittle)
  const data = [
    { name: '', muscles: (muscleFromBody && values?.tempBodyMapData?.tittle) ? [values.tempBodyMapData.tittle] : [] },
  ];

  const handleClick = React.useCallback(({ muscle, data }) => {
    // const { exercises, frequency } = data;
    //if(values.tempBodyMapData){
    values.tempBodyMapData.tittle = muscle
    // }
    setValues({ ...values })
    // alert(`You clicked the ${muscle}! You've worked out this muscle ${frequency} times through the following exercises: ${JSON.stringify(exercises)}`)

  }, []);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Body Map',
    });
    setLoadingModal(false);
  }, [fullBind]);

  const addBodyMuscle = () => {
    if (values.tempBodyMapData.tittle && values.tempBodyMapData.description) {
      if (values.tempBodyMapData.index) {
        values.alert_body_part[values.tempBodyMapData.index] = values.tempBodyMapData;
      } else {
        values.alert_body_part = [...values.alert_body_part, { ...values.tempBodyMapData }]
      }
      setValues({ ...values })
      if (isClient) {
        update ? updateBodyParts() : addBodyParts()
      }
      setModalBodyMap(false)
    } else {
      toast && toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: (!values.tempBodyMapData.tittle || !values.tempBodyMapData.description) ? 'Please fill the mandatory fields' : '',
      });
    }
  }



  // const updateBodyPart = () => {
  //  if(isClient){  }
  //   setModalBodyMap(false)
  //  }


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <Toast ref={toast} position="top-right" />

        <div className='d-flex'>
          <div className="col-4">
            <Model
              data={data}
              style={{ width: '16rem', padding: '1rem' }}
              onClick={handleClick}
              type={backMode ? 'posterior' : 'anterior'}
            />

          </div>
          <div className='col-8' style={{ paddingLeft: '4rem', paddingTop: '6rem' }}>
            <ToggleDiv>
              <ToggleWrapper>
                <Toggle
                  id="checkbox"
                  type="checkbox"
                  checked={backMode}
                  onClick={() => {
                    backMode ? setBackMode(false) : setBackMode(true);
                  }}
                />
                <ToggleLabel htmlFor="checkbox" />
              </ToggleWrapper>
              <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
                <p>Back</p>
              </div>
            </ToggleDiv>
            <FlexGrid3Item className="pr-1 mt-1 ">
              <InputLabelTop
                // disabled={readonly}
                required
                type="text"
                id="name"
                label={'Title'}
                {...useInput('tempBodyMapData.tittle')}
                maxlength={50}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 mb-2">
              <small className="pr-1 " style={{ color: 'black' }}>
                Description
              </small>
              <InputTextArea
                // disabled={readonly}
                required
                type="text"
                height={'4rem'}
                id="description"
                label={'Description'}
                //onChange={(val)=>handleOnChangeDescription(val)}
                {...useInput('tempBodyMapData.description')}
                maxlength={8}
              />
            </FlexGrid3Item>
            <div className=''>

              <PrimaryButtonForm
                minWidth="6rem"
                onClick={addBodyMuscle}
              //disabled={readonly && !update ? true : false}
              >
                <span className={'mr-2'}>{update ? "Update" : "Add"}</span>

              </PrimaryButtonForm>

            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  )
}

export default BodyMapModal