import React, { useEffect, useState } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from "react-icons/bs"
import AddNewFunderSplit from 'components/DashboardComponents/Client/ClientServices/AddNewFunderSplit';
import {
    TitleSection,
    FlexGrid3Container,
    FlexGrid3Item,
    IconWrapper,
    ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import RateCard from "components/DashboardComponents/Client/AddModal/RateCard"
import { ImTab } from 'react-icons/im';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { PURPLE, SOFT_RED } from 'shared/styles/constants/colors';
import { AccordionArrow, AccordionContainer, StaticArrow } from 'components/DashboardComponents/LeftSidebar/styles';
import LineContainer from 'components/SharedComponents/LineContainer';
import { FaPlus } from 'react-icons/fa';
import { Fragment } from 'react';
import DateSelector from 'components/SharedComponents/DateSelector';
import {DELETE, COPY} from 'utils/constants/icons';
import {useModal} from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';


const FunderSection = ({
    useInput,
    nameSection,
    choices,
    setValues,
    values,
    dropDownHandleSelectFunder,
    funderList,
    addNewSplitFunder,
    deleteSplitFunder,
    setErrorSplitPercentage,
    errorSplitPercentage,
    deletedSplitFunders,
    deletePermission,
    clientId,
    clientServicesFunderRead,
    clientServicesFunderCreate,
    clientServicesFunderContactRead,
    clientServicesFunderContactCreate,
    clientServicesFunderBillRateCardRead,
    clientServicesFunderBillRateCardCreate,
    editMode,
    indexVisit,
    dataVisit,
    dropDownHandleSelectFunderBilling,
    handleDeleteFunder = () => {},
}) => {

   
    const {setValue: setModalVisitDeleteValue, bind: bindModalVisitDelete} =
    useModal(false);

   

    const changeFunderName = (e) => {
        values.service_funders[indexVisit].name = e.target.value
        setValues({ ...values })
    }


    const no_hook = useInput(`${[nameSection]}[${indexVisit}].no`);
    const po_number_hook = useInput(`${[nameSection]}[${indexVisit}].po_number`);
    const commissioned_hours_per_week_hook = useInput(`${[nameSection]}[${indexVisit}].commissioned_hours_per_week`)
    const commissioned_hours_per_month_hook = useInput(`${[nameSection]}[${indexVisit}].commissioned_hours_per_month`)
    const funder_accounts_code_hook = useInput(`${[nameSection]}[${indexVisit}].funder_accounts_code`)
    const reference_hook = useInput(`${[nameSection]}[${indexVisit}].reference`)
    const effective_from = useInput(`${[nameSection]}[${indexVisit}].effective_date_from`, 'isRequired')
    const effective_to = useInput(`${[nameSection]}[${indexVisit}].effective_date_to`)
    const discount_hook = useInput(`${[nameSection]}[${indexVisit}].discount`)
    const funder_cancellation_fees_hook = useInput(`${[nameSection]}[${indexVisit}].funder_cancellation_fees`)
    const is_split_hook = useInput(`${[nameSection]}[${indexVisit}].is_split`)

    const toggleDeleteModal = () => {
        setModalVisitDeleteValue(true);
      };

    return (

        <LineContainer className={'pl-2 pr-2 pt-2 pb-4 mb-2'}>
             {!editMode ? (
          <div className={'pt-2'} />
        ) : (
          <div className={'pb-2 d-flex justify-content-end'}>
            {/* <IconWrapper
              disabled={!editMode}
              style={{marginRight: '8px'}}
              color={SOFT_RED}
              onClick={() => addVisitCopy(index)}>
              {COPY}
            </IconWrapper> */}
              <IconWrapper
                disabled={!editMode}
                color={SOFT_RED}
                onClick={() => toggleDeleteModal()}>
                {DELETE}
              </IconWrapper>
          </div>
        )}
            <div className={'mt-2'}>
                {(clientId ? clientServicesFunderRead : clientServicesFunderCreate) &&
                    <>

                        <FlexGrid3Container className="d-flex pb-2">
                            <FlexGrid3Item className="pr-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Is Self Funded'}
                                    options={[
                                        { id: 1, name: "Yes" },
                                        { id: 2, name: "No" }
                                    ]}
                                    selectedOption={
                                        values.service_funders[indexVisit]
                                            ? values.service_funders[indexVisit].self_funded
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunder(
                                            { self_funded: value ? value : null },
                                            'self_funded',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>

                            {values.service_funders[indexVisit] && values.service_funders[indexVisit].self_funded && values.service_funders[indexVisit].self_funded == 1
                                &&
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <InputLabelTop
                                        disabled={!editMode}
                                        type="text"
                                        id="funder_name"
                                        label={'Funder name'}
                                        onChange={changeFunderName}
                                        value={values.service_funders[indexVisit].name}
                                        maxlength={255}
                                    />
                                </FlexGrid3Item>
                            }

                            {values.service_funders[indexVisit] && values.service_funders[indexVisit].self_funded && values.service_funders[indexVisit].self_funded == 2
                                &&
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Funder Name'}
                                        options={funderList}
                                        selectedOption={
                                            values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].name
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelectFunder(
                                                { name: value ? value : null },
                                                'name',indexVisit
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                            }
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Client / Funder category'}
                                    options={choices.funder_source}
                                    selectedOption={
                                        values.service_funders[indexVisit]
                                            ? values.service_funders[indexVisit].category
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunder(
                                            { category: value ? value : null },
                                            'category',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                        </FlexGrid3Container>
                        <FlexGrid3Container className="d-flex pb-2">
                            <FlexGrid3Item className="pr-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="funder_no"
                                    label={'Funder code'}
                                    {...no_hook}
                                    maxlength={25}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="po_no"
                                    label={'PO NO.'}
                                    {...po_number_hook}
                                    maxlength={255}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="comm_hour_per_week"
                                    label={'No. of Commissioned Hours per week'}
                                    onKeyPress={KeyPress.DECIMAL}
                                    step={'.01'}
                                    {...commissioned_hours_per_week_hook}
                                    maxlength={255}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="comm_hour_per_month"
                                    label={'No. of Commissioned Hours per month'}
                                    onKeyPress={KeyPress.DECIMAL}
                                    {...commissioned_hours_per_month_hook}
                                    maxlength={255}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Funder Status'}
                                    options={[
                                        { id: 1, name: "Active" },
                                        { id: 2, name: "Inactive" }
                                    ]}
                                    selectedOption={
                                        (values.service_funders && values.service_funders[indexVisit])
                                            ? values.service_funders[indexVisit].status
                                                ? values.service_funders[indexVisit].status
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunder(
                                            { status: value ? value : null },
                                            'status',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="fund_acount_code"
                                    label={'Funder Accounts Code'}
                                    {...funder_accounts_code_hook}
                                    maxlength={255}
                                />
                            </FlexGrid3Item>

                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="refrence"
                                    label={'Reference'}
                                    {...reference_hook}
                                    maxlength={25}
                                />
                            </FlexGrid3Item>
                        <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                            <DateSelector
                            isRequired
                                labelTop="Effective From"
                                disabled={!editMode}
                                {...effective_from}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                            <DateSelector
                                labelTop="Effective To"
                                disabled={!editMode}
                                {...effective_to}
                            />
                        </FlexGrid3Item>
                        </FlexGrid3Container>
                    </>
                }
                <div className={'mt-2 mb-3'}>
                    {(clientId ? clientServicesFunderContactRead : clientServicesFunderContactCreate) &&
                        <AddNewFunderSplit
                            editMode={editMode}
                            readOnly={true}
                            addNewSplitFunder={addNewSplitFunder}
                            indexVisit={indexVisit}
                            deleteSplitFunder={deleteSplitFunder}
                            useInput={useInput}
                            split_funders={
                                values.service_funders[indexVisit] ?
                                    values.service_funders[indexVisit].split_funders ?
                                        values.service_funders[indexVisit].split_funders
                                        : []
                                    : []
                            }
                            values={values}
                            setValues={setValues}
                            setErrorSplitPercentage={setErrorSplitPercentage}
                            errorSplitPercentage={errorSplitPercentage}
                            deletedSplitFunders={deletedSplitFunders}
                            deletePermission={deletePermission}
                        />
                    }
                </div>
                <>
                    <div className={'mb-2'}>
                        <TitleSection>Funder Bill/Pay Details</TitleSection>
                    </div>
                       <FlexGrid3Container className="d-flex pb-2 mt-2">
                            <FlexGrid3Item className="pr-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Invoice Type'}
                                    options={choices.Invoice_type}
                                    selectedOption={
                                        values.service_funders[indexVisit]
                                            ? values.service_funders[indexVisit].invoice_type
                                                : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { invoice_type: value ? value : null },
                                            'invoice_type',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 ">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Bill Rate Model'}
                                    options={choices.bill_rate_model}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].billing_mode
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { billing_mode: value ? value : null },
                                            'billing_mode',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 ">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Billing Duration Type'}
                                    options={choices.billing_duration_type}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].billing_duration_type
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { billing_duration_type: value ? value : null },
                                            'billing_duration_type',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1 mt-2">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Billing Duration Source'}
                                    options={choices.billing_duration_source}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].billing_duration_source
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { billing_duration_source: value ? value : null },
                                            'billing_duration_source',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Bill Time Unit'}
                                    options={choices.bill_time_unit}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].bill_time_unit
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { bill_time_unit: value ? value : null },
                                            'bill_time_unit',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="funder_discount"
                                    label={'Funder discount'}
                                    {...discount_hook}
                                    maxlength={25}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Billing Pattern'}
                                    options={choices.Billing_patterns}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].pattern
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { pattern: value ? value : null },
                                            'pattern',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Billing Term'}
                                    options={choices.Billing_terms}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].billing_term
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { billing_term: value ? value : null },
                                            'billing_term',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                <DropdownSearchable
                                    disabled={!editMode}
                                    placeHolder={'Payment Method'}
                                    options={choices.Payment_method}
                                    selectedOption={
                                        values
                                            ? values.service_funders[indexVisit]
                                                ? values.service_funders[indexVisit].payment_method
                                                : null
                                            : null
                                    }
                                    onChange={(value) =>
                                        dropDownHandleSelectFunderBilling(
                                            { payment_method: value ? value : null },
                                            'payment_method',indexVisit
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1 mt-2">
                                <InputLabelTop
                                    disabled={!editMode}
                                    type="text"
                                    id="funder_cancel_fees"
                                    label={'Funder Cancellation Fees'}
                                    {...funder_cancellation_fees_hook}
                                    maxlength={25}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                                <ToggleButton
                                    disabled={!editMode}
                                    label="Split funding"
                                    useInput={is_split_hook}
                                    id="funder_is_split"
                                />
                            </FlexGrid3Item>
                        </FlexGrid3Container>
                        <div>
                            <FlexGrid3Container className="d-flex pb-2 mt-2">
                                <FlexGrid3Item className="pr-1">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Pay Rate Modal'}
                                        options={choices.pay_rate_model}
                                        selectedOption={
                                            values
                                                ? values.service_funders[indexVisit]
                                                    ? values.service_funders[indexVisit].pay_rate_model
                                                    : null
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelectFunderBilling(
                                                { pay_rate_model: value ? value : null },
                                                'pay_rate_model',indexVisit
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Pay Duration Type'}
                                        options={choices.pay_duration_type}
                                        selectedOption={
                                            values
                                                ? values.service_funders[indexVisit]
                                                    ? values.service_funders[indexVisit].pay_duration_type
                                                    : null
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelectFunderBilling(
                                                { pay_duration_type: value ? value : null },
                                                'pay_duration_type',indexVisit
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Pay Duration Source'}
                                        options={choices.pay_duration_source}
                                        selectedOption={
                                            values
                                                ? values.service_funders[indexVisit]
                                                    ? values.service_funders[indexVisit].pay_duration_source
                                                    : null
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelectFunderBilling(
                                                { pay_duration_source: value ? value : null },
                                                'pay_duration_source',indexVisit
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pr-1 mt-2">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Pay Time Unit'}
                                        options={choices.pay_time_unit}
                                        selectedOption={
                                            values
                                                ? values.service_funders[indexVisit]
                                                    ? values.service_funders[indexVisit].pay_time_unit
                                                    : null
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelectFunderBilling(
                                                { pay_time_unit: value ? value : null },
                                                'pay_time_unit',indexVisit
                                            )
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
                                    <DropdownSearchable
                                        disabled={!editMode}
                                        placeHolder={'Contract/Employee Pay Rate'}
                                    />
                                </FlexGrid3Item>
                            </FlexGrid3Container>
                        </div>
                        </>
            </div>
            <ModalContainer max_width={'40%'}>
        <ModalDecision
          type="delete"
          title="Warning"
          width={'50%'}
          body={'Are you sure you want to delete this funder?'}
          subBody={''}
          onOk={() => {
            handleDeleteFunder(dataVisit, indexVisit);
            setModalVisitDeleteValue(false);
          }}
          onCancel={() => {}}
          okTitle={'DELETE'}
          {...bindModalVisitDelete}
        />
      </ModalContainer>
        </LineContainer>
    );
};

FunderSection.propTypes = {
    useInput: PropTypes.func.isRequired,
    sectionStyle: PropTypes.string,
    nameSection: PropTypes.string.isRequired,
    dropDownHandleSelectFunder: PropTypes.func.isRequired,
    choices: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};

FunderSection.defaultProps = {
    sectionStyle: '',
};

export default FunderSection;
