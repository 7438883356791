import { Switch, TextField, FormControl, Select, MenuItem, InputLabel  } from '@mui/material'
import React from 'react'

export default function DateTimePickerComp({ json, setJson }) {
    return (
        <div style={{ display: "flex", justifyContent: "center",flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Date/time label"
                defaultValue={"Label"}
                value={json.dateTimePicker.label}
                style={{ marginRight: 10 }}
                className='mb-2'
                onChange={(event) => {
                    // console.log(event.target.value)
                    setJson({
                        ...json, dateTimePicker: {
                            ...json.dateTimePicker,
                            label: event.target.value
                        }
                    })
                }}
            />
            <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.dateTimePicker.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, dateTimePicker: {
                                ...json.dateTimePicker,
                                description: event.target.value
                            }
                        })
                    }}
                />
                 <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.dateTimePicker.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, dateTimePicker: {
                                ...json.dateTimePicker,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel id="date-format">Date/Time Format</InputLabel>
                    <Select
                        labelId="date-format"
                        id="date-format-id"
                        value={json.dateTimePicker.format}
                        label="Date/Time Format"
                        onChange={(event) => {
                            setJson({
                                ...json, dateTimePicker: {
                                    ...json.dateTimePicker,
                                    format: event.target.value
                                }
                            })
                        }}
                    >
                        <MenuItem value={""}>System Format</MenuItem>
                        <MenuItem value={"dd-MM-yyyy hh:mm a"}>DD-MM-YYYY 12h</MenuItem>
                        <MenuItem value={"dd-MM-yyyy HH:mm"}>DD-MM-YYYY 24h</MenuItem>
                        <MenuItem value={"MM-dd-yyyy hh:mm a"}>MM-DD-YYYY 12h</MenuItem>
                        <MenuItem value={"MM-dd-yyyy HH:mm"}>MM-DD-YYYY 24h</MenuItem>
                        <MenuItem value={"yyyy-MM-dd hh:mm a"}>YYYY-MM-DD 12h</MenuItem>
                        <MenuItem value={"yyyy-MM-dd HH:mm"}>YYYY-MM-DD 24h</MenuItem>
                    </Select>
                </FormControl>
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.dateTimePicker.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, dateTimePicker: {
                                                ...json.dateTimePicker,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.dateTimePicker.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, dateTimePicker: {
                                                ...json.dateTimePicker,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.dateTimePicker.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, dateTimePicker: {
                                                ...json.dateTimePicker,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.dateTimePicker.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.dateTimePicker.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, dateTimePicker: {
                                ...json.dateTimePicker,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}
