import styled from 'styled-components';

export const ContextMenuContainer = styled.div`
    background: white;
    display: none;
    position: fixed;
    z-index: 1033;
    border-radius: 0.15rem;
    border: solid 1px #efefef;
    overflow-y: auto;
    max-height: 13rem;
    ul {
        padding-left: 0;
        li {
            cursor: pointer;
            padding: 0.5rem 1rem;
        }
        li:hover {
            background-color: #eee;
        }
    }
`

export const BackdropContextMenu = styled.div`
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    width: 100vw;
    height: 100vh;
`