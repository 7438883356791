import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'

export default function ButtonComponent({ json, setJson }) {
    return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Button text"
                defaultValue={"Button text"}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    setJson({
                        ...json, button: {
                            ...json.button,
                            label: event.target.value
                        }
                    })
                }}
            />
            <FormControl style={{ flex: 1 }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={json.button.type}
                    label="Tag"
                    onChange={(event) => {
                        setJson({
                            ...json, button: {
                                ...json.button,
                                type: event.target.value
                            }
                        })
                    }}
                >
                    <MenuItem value={"contained"}>Contained</MenuItem>
                    <MenuItem value={"outlined"}>Outlined</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}
