import React, { useState, useEffect, useRef, useContext } from 'react';
import CommonModelData from '../CommonModelData'
import { getRateCard, } from 'utils/api/SettingsApi';
import { useModal } from 'hooks/ModalHook';
import { AppContext } from "context"
import { useForm } from 'hooks/FormHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import { shift_rate_card } from 'utils/choiceConstant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Wait(props) {
  const history = useHistory();
  const {
    readPermissionWait,
    createPermissionWait,
    updatePermissionWait,
    deletePermissionWait
  } = props;
  const { setValue: setModalValue, value: modalValue, setTitle, fullBind, } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false,);
  const { values, setValues, useInput, setCheckRequires } = useForm({ limitperpage: PAGE_SIZE_RENDER });  //used useInput

  const { contextChoices, handleGetChoices } = useContext(AppContext);

  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [waitRateCards, setWaitRateCard] = useState([]);
  const [rateCardID, setRateCardID] = useState();
  // const [shiftType, setShiftType] = useState([]);
  const [waitTab, setWaitTab] = useState(false)
  const [rateSource, setRateSource] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);
  const mountedRef = useRef(true);




  useEffect(() => {
    handleGetChoices(shift_rate_card)
  }, [])


  useEffect(() => {
    if (contextChoices) {
      let rateCardType = contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype => ratetype.name == 'Wait Rates').id;
      if (rateCardType) {
        setRateCardID(rateCardType);
        loadRateCard(rateCardType);
        setWaitTab(true);
      }

      setRateSource(contextChoices.wages_rate_sources && contextChoices.wages_rate_sources.map(y => { return { id: y, name: y } }));
      // let keys = ['Shift', 'Availability']
      // setShiftType(contextChoices.regular_shift_type &&
      //     contextChoices.regular_shift_type.filter(shift => keys.includes(shift.name))
      // )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices]);





  useEffect(() => {
    if (rateCardID) {
      loadRateCard(rateCardID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, values.ordering, values.limitperpage]);


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };



  const loadRateCard = (rateCardType) => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    if (rateCardType) {
      getRateCard({ ...values, ...pageToSearch }, values.limitperpage, rateCardType).then(response => {
        if (!mountedRef.current) return null;
        if (response) {
          setWaitRateCard(response.results || []);
          setPages(Math.ceil(response.count / values.limitperpage));
          setLoadingModal(false);
        }
      });
    }
  };



  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/rate-card/shift",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }


  return (
    <div>
      <CommonModelData
        addRateCardName={'NEW WAIT RATE CARD'}
        listigData={waitRateCards}
        pages={pages}
        currentPage={currentPage}
        setQuerySort={setQuerySort}
        modalValue={modalValue}
        idSelected={idSelected}
        setModalValue={setModalValue}
        setRateCardModalValue={setModalValue}
        fullBind={fullBind}
        setTitle={setTitle}
        setIdSelected={setIdSelected}
        values={values}
        nameSection={'payValue'}
        useInput={useInput}
        loadRateCard={loadRateCard}
        setValues={setValues}
        setModalDeleteValue={setModalDeleteValue}
        setCheckRequires={setCheckRequires}
        bindModalDelete={bindModalDelete}
        addCard={'New Wait Rate Card'}
        editCard={'Edit Wait Rate Card'}
        copiedCard={'Copied Wait Rate Card'}
        formObjName={'payValue'}
        firstLayerAddButton={'ADD Rate Details'}
        insideFirstLayerAddButton={'ADD Rate '}
        rateCardType={rateCardID}
        setIdToDelete={setIdToDelete}
        idToDelete={idToDelete}
        setDeleteValue={setDeleteValue}
        deleteValue={deleteValue}
        dropDownHandleSelectPagination={dropDownHandleSelectPagination}
        // shiftTab={shiftTab}
        rateSource={rateSource}
        loadingModal={loadingModal}
        // shiftType={shiftType}
        waitTab={waitTab}
        readPermission={readPermissionWait}
        createPermission={createPermissionWait}
        updatePermission={updatePermissionWait}
        deletePermission={deletePermissionWait}
      />

    </div>
  )
}

export default Wait