import React, { useRef, useMemo } from 'react'
import JoditEditor from 'jodit-react'
import "./style.css"

// bug: fast typing while pressing backspace/enter results in focus to be at the start of editor value
// this might be due to slow setter of value resulting in internal jodit state conflicting with it
// e.g. settings > form builder > assessment > new popup

const RichTextEditor = ({
    value = '', onBlur, onChange, config, tabIndex
}) => {
    const editor = useRef(null);
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: 'Start typings...',
            toolbarButtonSize: "small",
            statusbar: false,
            hidePoweredByJodithidePoweredByJodit: true,
            showTooltipDelay: 0,
            // height: 200,
            // removeButtons: [],
            buttons: [
                'bold',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent',
                'align', '|',
                'font',
                'fontsize',
                'brush',
                'paragraph', '|',
                'image',
                'video',
                'table',
                'link', 
                'hr','|', 'undo', 'redo', '|',
                '---',
                '|',
                'eraser',
                'copyformat', '|',
                'fullsize',
            ],
            buttonsMD: [
                'bold',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'align', 
                'font',
                'fontsize',
                'brush', '|',
                'table',
                'link', 
                'hr','|',
                '---',
                '|',
                'eraser', 
                'fullsize',
                'dots'
            ],
            buttonsSM: [
                'bold',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'table',
                'link',
                'hr', '|',
                '---',
                '|',
                'eraser',
                'fullsize',
                'dots'
            ],
            buttonsXS: [
                'bold',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'align', 
                'font',
                'fontsize',
                'brush', 
                'hr', '|',
                '---',
                '|',
                'eraser',
                'fullsize',
                'dots'
            ],
            // disablePlugins: ["mobile"], // removes dots (show-all)
            ...config
        }),
        [config]
    )
    return (
        <JoditEditor
            className='editor-class'
            editorRef={editor}
            value={value}
            config={editorConfig}
            tabIndex={tabIndex}
            onBlur={onBlur}
            onChange={onChange}
        />
    )
}

RichTextEditor.defaultProps = {
    config: {}
}

export default RichTextEditor