import React from 'react';
import {Title} from './styles';
import {RoleName} from './styles';

const HeaderTable = ({selectedRol}) => {
  return (
    <tr>
      {/* <th scope="col" className={'border sticky-col first-col'}>
        <div className={'d-flex align-items-center'}>
          <div className={'ml-2 text-uppercase'}>{selectedRol.name || ''}</div>
        </div>
      </th> */}
      <th scope="col" className={'text-center border all-cols'}>
        {/* <Title>Web</Title> */}
      </th>
      {/* <th scope="col" className={'text-center border all-cols'}>
        <Title>Mobile</Title>
      </th> */}
    </tr>
  );
};

export default HeaderTable;
