import { apiURL } from '../env';
import { get, post, deleteFetch, deleteFetch2 } from './Api';
import queryString from 'query-string';
import { methods, unauthorized_status_code } from 'utils/constants/api';
import { getToken, clearToken, getTenant } from '../localStorage/token';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

export const getInvoiceList = (values) => {
    const baseUrl = getBaseUrl();
    let stringified = '';
    let toParse = {};

    toParse.offset = values.offset;
    if (values.limitperpage) {
      toParse.limit = values.limitperpage;
    }
  
    stringified = queryString.stringify(toParse) || '';

    const url = `${baseUrl}/scheduler/invoicing/?${stringified}`;
  
    return get(url);
  };

  export const getInvoiceVisitsByDate = (values, limit) => {
    const baseUrl = getBaseUrl2();
    let stringified = '';

    let toParse = {};
    if(values.client){
      toParse.client = values.client
    }

    toParse.offset =  values.offset;

    if (limit) {
      toParse.limit = limit;
    }
  
    stringified = queryString.stringify(toParse) || '';

    const url = `${baseUrl}/scheduler/invoicing/invoicing-visit/?start_date=${values.start_day}&end_date=${values.end_day}&${stringified}`;
  
    return get(url);
  };

  export const setInvoice = (values) => {
    const baseUrl = getBaseUrl();
    const method = values.id ? PATCH : POST;
    const url = values.id
      ? `${baseUrl}/scheduler/invoicing/${values.id}/`
      : `${baseUrl}/scheduler/invoicing/`;
    const body = {
      ...values,
    };
  
    return post(url, body, method);
  };

  export const getInvoiceAddressByClientId = (id) => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/clients/getClientAddress/?client=${id}`;
  
    return get(url);
  };

  export const getInvoiceAddressByFunderId = (id) => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/funder-details/getFunderAddress/?funder=${id}`;
  
    return get(url);
  };

  export const getInvoiceDataById = (id) => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/scheduler/invoicing/${id}/`;
  
    return get(url);
  };

  export const deleteInvoice = (values) => {

    const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/invoicing/${values}/`;

  return deleteFetch2(url);
  };