import React from 'react';
import {TextAreaInput} from 'shared/styles/inputs';

function InputTextArea(props) {
  const isRequired = props?.required;
  let haveError = props['data-error'];

  let labelError = <span />;

  if (props['data-error'] && !isRequired && !props?.value) {
    haveError = false;
  }

  if (props['data-error'] && !isRequired && props?.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>Invalid format</label>
    );
  }

  if (props['data-error'] && isRequired && !props?.value) {
    labelError = (
      <label className={'errorMessage'}>
        {props?.error_msg || 'Is required'}
      </label>
    );
  }

  if (props['data-error'] && isRequired && props?.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>
        {props?.error_msg || 'Invalid format'}
      </label>
    );
  }


  return (
    <TextAreaInput    have_error={haveError}
    type={props?.type}
    typeFilter={props?.typeFilter}
    onKeyPress={props?.onKeyPress}
    id={props?.id}
    className={props?.value ? 'filled' : ''}
    {...props}
  > 
      <label>
        {props?.label} <span>{isRequired ? '*' : ''}</span>
      </label> 

      {labelError}


    </TextAreaInput>
  );
}
export default InputTextArea;
