import React from 'react';
import Template from 'components/SharedComponents/SettingsTemplate';
import {
  getServiceCategories,
  deleteServiceCategory,
  setServiceCategory,
  getVisitType,
  deleteVisitType,
  setVisitType,
  getVisitTypePriority,
  deleteVisitTypePriority,
  setVisitTypePriority,
  getRunNames,
  setRunName,
  deleteRunName
} from 'utils/api/SettingsApi';
import { SCHEDULER_UNASSIGNED } from 'shared/styles/constants/colors';

const Priority = ({
  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  createPrioritySettings,
  handleChangeChoices
}) => {

  const options = [
    { id: 'location', name: 'Location' },
    { id: 'client', name: 'Client' }
  ]
  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-xl-6 col-lg-6 pl-0 pr-0 pl-lg-1 pr-lg-1 pb-3">
          <Template
            titleSection={'Visit type'}
            labelInputTitle={'Visit type name'}
            getMethod={getVisitType}
            deleteMethod={deleteVisitType}
            setMethod={setVisitType}
            textToMessageDelete={'visit type'}
            fieldAttributes={{ maxLength: 50 }}
            handleChangeChoices={() => handleChangeChoices('visit_type')}
            updatePrioritySettings={updatePrioritySettings}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            defaultColor={SCHEDULER_UNASSIGNED}
            addInput={[
              {
                label: 'Color Code',
                value: 'color_code',
                type: "color_picker",
              }
            ]}
          />
        </div>
        <div className="col-xl-6 col-lg-6 pl-0 pr-0 pl-lg-1 pr-lg-1 pb-3">
          <Template
            titleSection={'Service category'}
            labelInputTitle={'Service category name'}
            getMethod={getServiceCategories}
            deleteMethod={deleteServiceCategory}
            setMethod={setServiceCategory}
            textToMessageDelete={'service category'}
            fieldAttributes={{ maxLength: 50 }}
            handleChangeChoices={() => handleChangeChoices('service_category')}
            updatePrioritySettings={updatePrioritySettings}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
            addInput={
              [{
                label: 'Service category type',
                value: 'category_type',
                type: "dropdown",
                required: false,
                options: [...options],
              },
              {
                label: 'Is Billable',
                value: 'is_billable',
                type: "toggle",
                required: false,
              }]
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Priority;

