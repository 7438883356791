export const FEILDS_CONST = {
    label: "Label",
    Placeholder: "Placeholder"
}


export const HTML_NAMES = {
    textInput: "Text Input",
    numberInput: "Number Input",
    decimalInput: "Decimal Input",
    headingText: "Heading text",
    dropdown: "Dropdown",
    multidropdown: "Multi Dropdown",
    switch: "Toggle",
    hyperLink:'Hyper Link',
    button: "Button",
    radioGroup: "Radio Group",
    buttonGroup: "Button Group",
    checkbox: "Checkbox",
    textArea: "Text Area",
    datePicker: "Date",
    timePicker: "Time",
    dateTime: "Date/Time",
    signature: "Signature",
    table: "Table",
    section:"Section",
    richTextHtmlInput: 'Rich Text Input',
    richTextHtmlHeading: 'Rich Text Heading',
    photoAttach: 'Photo Attach',
    fileUpload: 'File Upload',
    fileDownload: 'File Download',
    bodyMapDisplay: 'BodyMap Display',
    bodyMapInput: 'BodyMap Input',
    choices: 'Choices',
}