import {
    LARGE_COL,
    SMALL_COL
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
      label: 'Branch Name',
      name: 'branch_name',
      status: 0,
      custum_column: true,
      id: 'branch_name',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
        label: 'Funder Name',
        name: 'funder_name',
        status: 0,
        custum_column: true,
        id: 'funder_name',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Funder Category',
        name: 'category_name',
        status: 0,
        custum_column: true,
        id: 'category_name',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    {
        label: 'Client Name',
        name: 'client_name',
        status: 0,
        custum_column: true,
        id: 'client_name',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    
    {
      label: 'Funder Contract Bill Amt',
      name: 'funder_contract_bill_amount',
      status: 0,
      custum_column: true,
      id: 'funder_contract_bill_amount',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Contract visit billing Amt',
      name: 'contract_visit_bill_amount',
      status: 0,
      custum_column: true,
      id: 'contract_visit_bill_amount',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
        label: 'Adhoc Bill Amt',
        name: 'adhoc_bill_amount',
        status: 0,
        custum_column: true,
        id: 'adhoc_bill_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Schedule Billing Amt',
        name: 'schedule_bill_amount',
        status: 0,
        custum_column: true,
        id: 'schedule_bill_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Schedule  Manual Billing Amt',
        name: 'manual_schedule_visit_bill_amount',
        status: 0,
        custum_column: true,
        id: 'manual_schedule_visit_bill_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Actual Bill Amt',
        name: 'actual_billing_amount',
        status: 0,
        custum_column: true,
        id: 'actual_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Actual Manual Amt',
        name: 'actual_manual_billing_amount',
        status: 0,
        custum_column: true,
        id: 'actual_manual_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Contract Travel Billing Amount',
        name: 'contract_travel_billing_amount',
        status: 0,
        custum_column: true,
        id: 'contract_travel_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Schedule Travel Billing Amount',
        name: 'schedule_travel_billing_amount',
        status: 0,
        custum_column: true,
        id: 'schedule_travel_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Visit Travel Billing Amount',
        name: 'visit_travel_billing_amount',
        status: 0,
        custum_column: true,
        id: 'visit_travel_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Actual Travel Billing Amount',
        name: 'actual_travel_billing_amount',
        status: 0,
        custum_column: true,
        id: 'actual_travel_billing_amount',
      columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
  ];
  
  export const headerColumnsSelector = [
    {
        name: 'Funder Name',
        id: 'funder_name',
      },
    {
        name: 'CLient Name',
        id: 'client_name',
      },
    
    {
      name: 'Contract Bill Amt',
      id: 'sum_of_funder_contract_bill_amount',
    },
    {
      name: 'visit billing Amt',
      id: 'sum_of_contract_visit_bill_amount',
    },
    
    {
        name: 'Adhoc Bill Amt',
        id: 'sum_of_adhoc_bill_amount',
      },
      {
        name: 'Schedule Billing Amt',
        id: 'sum_of_schedule_bill_amount',
      },
      {
        name: 'Schedule  Manual Billing Amt',
        id: 'sum_of_manual_schedule_visit_bill_amount',
      },
      {
        name: 'Actual Bill Amt',
        id: 'sum_of_actual_billing_amount',
      },
      {
        name: 'Actual Manual Amt',
        id: 'sum_of_actual_manual_billing_amount',
      },
  ];
  
  export const headerColumnsSelectorByDefault = [
    {
        name: 'Funder Name',
        id: 'funder_name',
      },
    {
        name: 'CLient Name',
        id: 'client_name',
      },
    
    {
      name: 'Contract Bill Amt',
      id: 'sum_of_funder_contract_bill_amount',
    },
    {
      name: 'visit billing Amt',
      id: 'sum_of_contract_visit_bill_amount',
    },
    
    {
        name: 'Adhoc Bill Amt',
        id: 'sum_of_adhoc_bill_amount',
      },
      {
        name: 'Schedule Billing Amt',
        id: 'sum_of_schedule_bill_amount',
      },
      {
        name: 'Schedule  Manual Billing Amt',
        id: 'sum_of_manual_schedule_visit_bill_amount',
      },
      {
        name: 'Actual Bill Amt',
        id: 'sum_of_actual_billing_amount',
      },
      {
        name: 'Actual Manual Amt',
        id: 'sum_of_actual_manual_billing_amount',
      },
  ];