import styled from 'styled-components';
import {PURPLE, GRAY_400, WHITE} from 'shared/styles/constants/colors';
import {MD} from 'shared/styles/constants/resolutions';

export const Container = styled.label`
  span {
    font-size: 0.875rem;
    @media (max-width: ${MD}) {
      font-size: 0.75rem;
    }
  }
`;
export const StyledCheck = styled.label`
  position: relative;
  display: inline-block;
  width: 3.75rem;
  min-width: 3.75rem;
  height: 1.8rem;
  margin-right: 0.4rem;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props=>props.switch?PURPLE:GRAY_400};
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 1.325rem;
    width: 1.325rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: ${WHITE};
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: ${PURPLE};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${PURPLE};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  label {
    margin: 0;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 2.125rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const StyledCheckSmallSize = styled.label`
  position: relative;
  display: inline-block;
  min-width: 2rem;
    width: 2.6rem;
    height: 1.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props=>props.switch?PURPLE:GRAY_400};
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 1.125rem;
    width: 1.125rem;
    left: 0.15rem;
    bottom: 0.15rem;
    background-color: ${WHITE};
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: ${PURPLE};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${PURPLE};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  label {
    margin: 0;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 2.125rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
