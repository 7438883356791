import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { LARGE_COL, SMALL_COL, MEDIUM_COL, EX_LARGE_COL, COL_CONTAIN_PARA, COL_CONTAIN_WORD, EX_SMALL_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { BiReset } from 'react-icons/bi'
import {
  Row,
  Col,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import {
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import { getAlgoScore, statusAlgo, resetAlgoRow, resetAllAlgo } from 'utils/api/SettingsApi';
import { getCategoryPermission, getPermissions } from '../../../../../utils/localStorage/user';
import {
  SETTINGS_ALGO_SCORE,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions';
import { getAdmin, getSuperAdmin } from "../../../../../utils/localStorage/token"
import { MdEdit } from 'react-icons/md';
import { ResetButton } from 'components/SharedComponents/ProgressInformation/styles'
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import AddModal from './AddModal'
import { AppContext } from "context"



const headerColumns = [
  {
    label: 'ALGORITHM  RULES',
    name: 'algorithm_rules',
    status: 0,
    className: 'grow-mobile',
    max_width: COL_CONTAIN_PARA,
  },
  // {
  //   label: 'MIN(HRS)',
  //   name: 'min_time',
  //   status: 0,
  //   className: 'sm-hidden    grow-mobile   ',
  //   max_width: COL_CONTAIN_WORD,
  // },

  // {
  //   label: 'MAX(HRS)',
  //   name: 'max_time',
  //   status: 0,
  //   className: 'sm-hidden   grow-mobile ',
  //   max_width: COL_CONTAIN_WORD,
  // },
  {
    label: 'CONSTRAINTS',
    name: 'score_level',
    status: 0,
    className: ' grow-mobile   ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'REWARD',
    name: 'reward',
    status: 0,
    className: ' md-hidden  grow-mobile ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'PENALISE',
    name: 'penalise',
    status: 0,
    className: 'sm-hidden    grow-mobile ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'UNASSIGNED VIOLATED',
    name: 'consider_hard_rule',
    status: 0,
    className: 'md-hidden   grow-mobile  ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'STATUS',
    name: 'status',
    status: 0,
    className: 'sm-hidden   grow-mobile  ',
    max_width: COL_CONTAIN_WORD,
  },

];

function Employee() {
  const { values, setValues, useInput, setCheckRequires } = useForm({});  //used useInput
  const [employee, setEmployee] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const { contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);

  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  const [numberValue, setNumberValue] = useState();

  const fullNameCol = 'algorithm_rules';
  const tabName = 'Employee';
  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );

  useEffect(() => {

    const roles = contextPermissions;
    let rolePermissions = {};


    if (filterAdmin == true || filterSuperAdmin == true) {
      setUpdatePermissions(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === SETTINGS_ALGO_SCORE) {
            rolePermissions[SETTINGS_ALGO_SCORE] = {};
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION] = {};
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        // if(rolePermissions[SETTINGS_ALGO_SCORE] && rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION])
        setUpdatePermissions(
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    values.ordering = 'algorithm_rules';
    setNumberValue(1);
    setValues({ ...values });
  }, [])

  useEffect(() => {
    if (numberValue == 0) {
      values.ordering = '-algorithm_rules';
      setValues({ ...values });
    }
  }, [numberValue])


  const changeNumber = (value) => {
    setNumberValue(value);
  }

  useEffect(() => {
    loadAlgoScore();
  }, [
    values.ordering,
  ]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  }

  const loadAlgoScore = () => {
    getAlgoScore({ ...values }, tabName).then(response => {
      setEmployee(response.Employee)
    });
  };


  const handleRowClick = async (id, status, togglename) => {
    if (status == null || status == false) {
      status = true;
    } else {
      status = false;
    }
    await statusAlgo(id, status, togglename)
    loadAlgoScore()
  }

  const handleModal = () => {
    setModalValue(!modalValue);
  };


  const handleRowClickEdit = id => {
    setIdSelected(id);
    setModalValue(true);
    // setValues({...values});
  };

  const toAlgoClientrRowData = async (id) => {
    // setModalDeleteValue(true);
    // setIdToDelete(id);
    await resetAlgoRow(id);
    // setModalDeleteValue(false);
    loadAlgoScore();
  };

  const resetAll = async () => {
    // setModalDeleteValue(true);
    // setIdToDelete(id);
    await resetAllAlgo('Employee');
    // setModalDeleteValue(false);
    loadAlgoScore();
  };



  return (
    <div>

      <div className="d-flex justify-content-end" data-toggle="tooltip" data-placement="top" title="Reset All" >
        <ResetButton onClick={resetAll} >
          <BiReset />
        </ResetButton>
      </div>

      <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">
      </DataContainer>
      <Table
        headerColumns={headerColumns}
        callBackQuerySort={setQuerySort}
        headerPadding={false}
        fontSize="0.75rem"
        statusNumber={numberValue}
        setStatusNumber={changeNumber}
        fullNameCol={fullNameCol}
      >
        <div>
          {employee && employee.map((employees, index) => {

            return (
              <div key={index}>
                <Row bgColor Shadow>
                  <Col
                    max_width={COL_CONTAIN_PARA}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {
                      employees.algorithm_rules && employees.algorithm_rules
                    }
                  </Col>
                  {/* <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                     {
                    employees.min_time  &&   employees.min_time
                      }
                  </Col> */}
                  {/* <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                     {
                     employees.max_time  &&  employees.max_time
                      }
                  </Col> */}
                  <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {
                      employees.score_level && employees.score_level
                    }
                  </Col>
                  <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    Capitalize
                    className="md-hidden"
                    Center>
                    {
                      employees.reward && employees.reward
                    }
                  </Col>
                  <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                    {
                      employees.penalise && employees.penalise
                    }
                  </Col>
                  <Col
                    max_width={SMALL_COL}
                    //  Shrink
                    //  reducePadding
                    className="md-hidden"
                    Capitalize
                  >

                    <div className=" mt-1">
                      {update && (<ToggleButton
                        onClick={() => handleRowClick(employees.id, employees.consider_hard_rule, 'consider_hard_rule')}
                        status={employees.consider_hard_rule} />)}
                      {!update && (employees.consider_hard_rule ? 'Active' : 'InActive')}
                    </div>

                  </Col>

                  <Col
                    max_width={SMALL_COL}
                    Shrink
                    reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                    <div className="mr-4">
                      {update && (
                        <ToggleButton
                          onClick={() => handleRowClick(employees.id, employees.status, 'status')}
                          status={employees.status}
                        />
                      )}
                      {!update && (employees.status ? 'Active' : 'InActive')}
                    </div>
                  </Col>

                  <Col className="sm-hidden" Center Icon Purple Shrink
                    reducePadding>
                    <IconWrapper
                      onClick={() => {
                        handleRowClickEdit(employees.id);
                      }}
                    >
                      <MdEdit />
                    </IconWrapper>
                  </Col>

                  <Col className="sm-hidden" Center Shrink
                    reducePadding NoFlexGrow >
                    <div data-toggle="tooltip" data-placement="top" title="Reset " >
                      <IconWrapper onClick={() => {
                        toAlgoClientrRowData(employees.id);
                      }} >
                        <BiReset />
                      </IconWrapper>
                    </div>
                  </Col>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}

        </div>
      </Table>
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          setAlgoClientModalValue={setModalValue}
          fullBind={fullBind}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          useInput={useInput}
          loadAlgoScore={loadAlgoScore}
        />
      )}
    </div>
  )
}

export default Employee;
