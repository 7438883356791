import {apiURL, apiURLReplica} from '../env';
import {get, deleteFetch, post} from './Api';
import queryString from 'query-string';
import {methods} from 'utils/constants/api';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {getToken, getTenant} from '../localStorage/token';
import _ from 'lodash';
import {RiCreativeCommonsZeroLine} from 'react-icons/ri';

const {PUT, POST, PATCH} = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};


const getBaseUrlMicroServices = () =>{
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};

export const getEvvVisits = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  // if (values.client_id) {
  //   toParse.client__id = values.client_id;
  // }
  if (values.travel_method) {
    toParse.travel_method = values.travel_method;
  }
  // if (values.employee_id) {
  //   toParse.employee__id =  values.employee_id;
  // }
  if (values.employee_id) {
    let runRouteFilter = values.employee_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_id = strRunRouteFilter;
  }
  if (values.clocked_visit_provision__in) {
    let visitProvisionFilter = values.clocked_visit_provision__in.map(
      data => data.id
    );
    let strVisitProvisionFilter = visitProvisionFilter.join(',');
    filterParse.clocked_visit_provision__in = strVisitProvisionFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visitConfirmationFilter = values.evv_confirmation_status__in.map(
      data => data.id
    );
    let strVisitConfirmationFilter = visitConfirmationFilter.join(',');
    filterParse.evv_confirmation_status__in = strVisitConfirmationFilter;
  }
  if (values.client_id) {
    let runRouteFilter = values.client_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.client_id = strRunRouteFilter;
  }
  if (values.visit_priority_id) {
    toParse.visit__client_service_visit__visit_priority__id =
      values.visit_priority_id;
  }
  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }
  if (values.location) {
    let locationIds = values.location.map(el => el.id)
    const joinedIds = locationIds.join(",")
    filterParse.location = joinedIds
  }
  if (values.visit_no) {
    toParse.visit__visit_number = values.visit_no;
  }
  // if (values.ordering) {
  //   // toParse.ordering = values.ordering;
  //   // for individual column name below lines
  //   let lastOdering = values.ordering.split(',').pop();
  //   toParse.ordering = lastOdering;
  // }
  // if (values.offset) {
  // toParse.offset = values.offset;
  // }
  if (values.client__clients_run_routes__run_routes__id__in) {
    let clientRunRouteFilter = values.client__clients_run_routes__run_routes__id__in.map(data => data.id);
    let strClientRunRouteFilter = clientRunRouteFilter.join(',');
    filterParse.client__clients_run_routes__run_routes__id__in = strClientRunRouteFilter;
  }
  if (values.employee__employee_run_routes__run_routes__id__in) {
    let employeeRunRouteFilter = values.employee__employee_run_routes__run_routes__id__in.map(data => data.id);
    let stEmployeeRunRouteFilter = employeeRunRouteFilter.join(',');
    filterParse.employee__employee_run_routes__run_routes__id__in = stEmployeeRunRouteFilter;
  }
  if (values.visitstatus) {
    let visitstatusFilter = values.visitstatus.map(data => data.id);
    let strvisitstatusFilter = visitstatusFilter.join(',');
    filterParse.visitstatus = strvisitstatusFilter;
  }
  if (values.travel_method) {
    let travelMethodFilter = values.travel_method;
    let strTravel;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }
  // toParse.limit = pageSize || PAGE_SIZE_RENDER;

  let datesRange = '';

  // start_date to utc and backend required format
  datesRange = 'start_date=' + values.date_from;

  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date=' + values.date_to;

  // if stringified has value then concat with &
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client__clients_run_routes__run_routes__id__in) {
    stringified += '&client__clients_run_routes__run_routes__id__in=' + filterParse.client__clients_run_routes__run_routes__id__in;
  }
  if (filterParse.employee__employee_run_routes__run_routes__id__in) {
    stringified += '&employee__employee_run_routes__run_routes__id__in=' + filterParse.employee__employee_run_routes__run_routes__id__in;
  }
  if(!((values.clockedDurationDiff__gte == null) || (values.clockedDurationDiff__gte == undefined)) ){
    stringified+= '&clockedDurationDiff__gte=' + values.clockedDurationDiff__gte;
  }
  if(!((values.clockedDurationDiff__lte == null) || (values.clockedDurationDiff__lte == undefined)) ){
    stringified+= '&clockedDurationDiff__lte=' + values.clockedDurationDiff__lte;
  }
  if(!((values.clockedDurationPercent__gte == null) || (values.clockedDurationPercent__gte == undefined)) ){
    stringified+= '&clockedDurationPercent__gte=' + values.clockedDurationPercent__gte;
  }
  if(!((values.clockedDurationPercent__lte == null) || (values.clockedDurationPercent__lte == undefined)) ){
    stringified+= '&clockedDurationPercent__lte=' + values.clockedDurationPercent__lte;
  }
  if (filterParse.visitstatus) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visitstatus;
  }
  if (filterParse.employee_id) {
    stringified += '&employee__id__in=' + filterParse.employee_id;
  }
  if (filterParse.client_id) {
    stringified += '&client__id__in=' + filterParse.client_id;
  }
  if (filterParse.clocked_visit_provision__in) {
    stringified +=
      '&clocked_visit_provision__in=' + filterParse.clocked_visit_provision__in;
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified +=
      '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in;
  }
  if (filterParse.branch_id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.branch_id;
  }
  if (values.timeDistance) {
    stringified += '&timeDistance=' + filterParse.timeDistance;
  }
  if (filterParse.location) {
    stringified += `&location__id__in=${filterParse.location}`
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&employee__job_detail__branch__id=' + filterParse.headerbranch;
    }
  }

  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }


  const url = `${baseUrl}/scheduler/evvscreen/?${stringified}`;

  return get(url);
};

export const getEvvTravel = values => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  // if (values.client_id) {
  //   toParse.client_ids = values.client_id;
  // }
  // if (values.ordering) {
  //   // for individual column name below lines
  //   let lastOdering = values.ordering.split(',').pop();
  //   toParse.ordering=lastOdering;
  // }
  // if (values.employee_id) {
  //   toParse.employees =  values.employee_id;
  // }
  if (values.employee_id) {
    let runRouteFilter = values.employee_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_id = strRunRouteFilter;
  }
  if (values.client_id) {
    let runRouteFilter = values.client_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.client_id = strRunRouteFilter;
  }
  if (values.visit_priority_id) {
    toParse.visit_priority_ids = values.visit_priority_id;
  }
  if (values.visit_no) {
    toParse.visit_number = values.visit_no;
  }
  if (values.client__clients_run_routes__run_routes__id__in) {
    let clientRunRouteFilter = values.client__clients_run_routes__run_routes__id__in.map(data => data.id);
    let strClientRunRouteFilter = clientRunRouteFilter.join(',');
    filterParse.client__clients_run_routes__run_routes__id__in = strClientRunRouteFilter;
  }
  if (values.employee__employee_run_routes__run_routes__id__in) {
    let employeeRunRouteFilter = values.employee__employee_run_routes__run_routes__id__in.map(data => data.id);
    let stEmployeeRunRouteFilter = employeeRunRouteFilter.join(',');
    filterParse.employee__employee_run_routes__run_routes__id__in = stEmployeeRunRouteFilter;
  }
  if (values.clocked_visit_provision__in) {
    let visitProvisionFilter = values.clocked_visit_provision__in.map(
      data => data.id
    );
    let strVisitProvisionFilter = visitProvisionFilter.join(',');
    filterParse.clocked_visit_provision__in = strVisitProvisionFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visitConfirmationFilter = values.evv_confirmation_status__in.map(
      data => data.id
    );
    let strVisitConfirmationFilter = visitConfirmationFilter.join(',');
    filterParse.evv_confirmation_status__in = strVisitConfirmationFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  let datesRange = '';

  // start_date to utc and backend required format
  datesRange = 'start_date=' + values.date_from;

  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date=' + values.date_to + '&';

  // if stringified has value then concat with &
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client__clients_run_routes__run_routes__id__in) {
    stringified += '&client_run_route=' + filterParse.client__clients_run_routes__run_routes__id__in;
  }
  if (filterParse.employee__employee_run_routes__run_routes__id__in) {
    stringified += '&employee_run_route=' + filterParse.employee__employee_run_routes__run_routes__id__in;
  }
  if (filterParse.employee_id) {
    stringified += '&employee_id=' + filterParse.employee_id;
  }
  if (filterParse.client_id) {
    stringified += '&client_id=' + filterParse.client_id;
  }
  if (filterParse.clocked_visit_provision__in) {
    stringified +=
      '&clocked_visit_provision__in=' + filterParse.clocked_visit_provision__in;
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified +=
      '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in;
  }
  if (filterParse.branch_id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.branch_id;
  }
  if (values.timeDistance) {
    stringified += '&timeDistance=' + filterParse.timeDistance;
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId=branchItemsIds.split(",").filter(e=>e!=='None').join(",")
      if(branchIdsWithoutNoneId)
        stringified += '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else{
      stringified += '&employee__job_detail__branch__id=' +   filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' +  'None';
      let branchItemsIds =filterParse.headerbranch;
      const branchIdsWithoutNoneId=branchItemsIds.split(",").filter(e=>e!=='None').join(",")
      if(branchIdsWithoutNoneId)
        stringified += '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else{
      stringified += '&employee__job_detail__branch__id=' +  filterParse.headerbranch;
    }
  }

  if (stringified) {
    // stringified = stringified + '&' + datesRange;
    stringified = datesRange + stringified;
  } else {
    stringified = datesRange;
  }
  const url = `${baseUrl}/scheduler/evvscreen/travel/?${stringified}`;

  return get(url);
};

export const getEvvExport = async (start_date, end_date, values, column_selector) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  if (values.travel_method) {
    toParse.travel_method = values.travel_method;
  }
  if (values.employee_id) {
    let runRouteFilter = values.employee_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_id = strRunRouteFilter;
  }
  if (values.clocked_visit_provision__in) {
    let visitProvisionFilter = values.clocked_visit_provision__in.map(
      data => data.id
    );
    let strVisitProvisionFilter = visitProvisionFilter.join(',');
    filterParse.clocked_visit_provision__in = strVisitProvisionFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visitConfirmationFilter = values.evv_confirmation_status__in.map(
      data => data.id
    );
    let strVisitConfirmationFilter = visitConfirmationFilter.join(',');
    filterParse.evv_confirmation_status__in = strVisitConfirmationFilter;
  }
  if (values.client_id) {
    let runRouteFilter = values.client_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.client_id = strRunRouteFilter;
  }
  if (values.location) {
    let locationIds = values.location.map(el => el.id)
    const joinedIds = locationIds.join(",")
    filterParse.location = joinedIds
  }
  if (values.visit_priority_id) {
    toParse.visit__client_service_visit__visit_priority__id =
      values.visit_priority_id;
  }
  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }
  if (values.visit_no) {
    toParse.visit__visit_number = values.visit_no;
  }
  if (values.client__clients_run_routes__run_routes__id__in) {
    let clientRunRouteFilter = values.client__clients_run_routes__run_routes__id__in.map(data => data.id);
    let strClientRunRouteFilter = clientRunRouteFilter.join(',');
    filterParse.client__clients_run_routes__run_routes__id__in = strClientRunRouteFilter;
  }
  if (values.employee__employee_run_routes__run_routes__id__in) {
    let employeeRunRouteFilter = values.employee__employee_run_routes__run_routes__id__in.map(data => data.id);
    let stEmployeeRunRouteFilter = employeeRunRouteFilter.join(',');
    filterParse.employee__employee_run_routes__run_routes__id__in = stEmployeeRunRouteFilter;
  }
  if (values.visitstatus) {
    let visitstatusFilter = values.visitstatus.map(data => data.id);
    let strvisitstatusFilter = visitstatusFilter.join(',');
    filterParse.visitstatus = strvisitstatusFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }

  let datesRange = '';
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client__clients_run_routes__run_routes__id__in) {
    stringified += '&client__clients_run_routes__run_routes__id__in=' + filterParse.client__clients_run_routes__run_routes__id__in;
  }
  if (filterParse.employee__employee_run_routes__run_routes__id__in) {
    stringified += '&employee__employee_run_routes__run_routes__id__in=' + filterParse.employee__employee_run_routes__run_routes__id__in;
  }
  if(!((values.clockedDurationDiff__gte == null) || (values.clockedDurationDiff__gte == undefined)) ){
    stringified+= '&clockedDurationDiff__gte=' + values.clockedDurationDiff__gte;
  }
  if(!((values.clockedDurationDiff__lte == null) || (values.clockedDurationDiff__lte == undefined)) ){
    stringified+= '&clockedDurationDiff__lte=' + values.clockedDurationDiff__lte;
  }
  if(!((values.clockedDurationPercent__gte == null) || (values.clockedDurationPercent__gte == undefined)) ){
    stringified+= '&clockedDurationPercent__gte=' + values.clockedDurationPercent__gte;
  }
  if(!((values.clockedDurationPercent__lte == null) || (values.clockedDurationPercent__lte == undefined)) ){
    stringified+= '&clockedDurationPercent__lte=' + values.clockedDurationPercent__lte;
  }
  if (filterParse.visitstatus) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visitstatus;
  }
  if (filterParse.employee_id) {
    stringified += '&employee__id__in=' + filterParse.employee_id;
  }
  if (filterParse.client_id) {
    stringified += '&client__id__in=' + filterParse.client_id;
  }
  if (filterParse.clocked_visit_provision__in) {
    stringified +=
      '&clocked_visit_provision__in=' + filterParse.clocked_visit_provision__in;
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified +=
      '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in;
  }
  if (filterParse.branch_id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.branch_id;
  }
  if (filterParse.timeDistance) {
    stringified += '&timeDistance=' + filterParse.timeDistance;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&employee__job_detail__branch__id=' + filterParse.headerbranch;
    }
  }

if (filterParse.location) {
  stringified += `&location__id__in=${filterParse.location}`
}

// const url = `${baseUrl}/clients/app/download-excel-file?view_set=EvvVisitAll&schedule_start_date__gte=${start_date}&schedule_end_date__lte=${end_date}&limit=5000`;
const url = `${baseUrl}/reports/evv/export/reports/evv_export_record/?start_date=${start_date}&end_date=${end_date}${stringified}`

const response = await fetch(url, {
  method: 'POST',
  body: JSON.stringify({...column_selector}),
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Token ' + getToken(),
  },
});

// if (response && response.status === 401) {
//   clearToken();
//   window.location.reload();
// }
return response;
};
export const getESvExport = async (start_date, end_date ,viewset) => {
  const baseUrl = getBaseUrlMicroServices();
  const url = `${baseUrl}/reports/employee-shift/reports/downloadEmployeeShift/?view=${viewset}&start_date=${start_date}&end_date=${end_date}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });


  // if (response && response.status === 401) {
  //   clearToken();
  //   window.location.reload();
  // }
  return response;
};
export const getVisitAuditExport = async (start_date, end_date ,viewset) => {
  const baseUrl = getBaseUrlMicroServices();
  const url = `${baseUrl}/reports/visit-audit/reports/exportVisitAuditRecord/?start_date=${start_date}&end_date=${end_date}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });


  // if (response && response.status === 401) {
  //   clearToken();
  //   window.location.reload();
  // }
  return response;
};
