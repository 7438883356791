import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';

const Stopwatch = ({
  initialTime = null,
  finalTime = null,
  timerPause = true,
  runningDate = '',
  actualDate = '',
}) => {
  const mountedRef = useRef(true);
  const [timerTime, setTimerTime] = useState(0);
  const [timer, setTimer] = useState(0);
  const [calculateTimerTime, setCalculateTimerTime] = useState('00:00:00');

  useEffect(() => {
    if (
      moment(runningDate).isBefore(actualDate) ||
      moment(runningDate).isAfter(actualDate)
    ) {
      calculateNewTime();
    } else {
      if (timerPause) {
        clearInterval(timer);
        setTimer(0);
        setTimerTime('00:00:00');
        setCalculateTimerTime('00:00:00');
        calculateNewTime();
      } else {
        if (finalTime) {
          clearInterval(timer);
          setTimer(0);
          calculateNewTime();
        } else {
          if (timer === 0) {
            startTimer();
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerPause, initialTime, finalTime]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = () => {
    const dateTimeInitial = moment()
      .utc(initialTime)
      .format('MM/DD/YYYY HH:mm:ss');

    const newTimerId = setInterval(() => {
      if (!mountedRef.current) return null;
      const today = moment(Date.now()).format('MM/DD/YYYY HH:mm:ss');
      const dateInitial = moment(dateTimeInitial, 'MM/DD/YYYY HH:mm:ss').format(
        'MM/DD/YYYY HH:mm:ss',
      );

      let difference = moment
        .utc(
          moment(today, 'MM/DD/YYYY HH:mm:ss').diff(
            moment(dateInitial, 'MM/DD/YYYY HH:mm:ss'),
          ),
        )
        .format('HH:mm:ss');

      if (difference === 'Invalid date') {
        difference = 0;
      }
      setTimerTime(difference);
    }, 60000);

    setTimer(newTimerId);
  };

  const calculateNewTime = () => {
    if (finalTime) {
      const initial = moment(initialTime).format('HH:mm:ss a');
      const final = moment(finalTime).format('HH:mm:ss a');

      const startTime = moment(initial, 'HH:mm:ss a');
      const endTime = moment(final, 'HH:mm:ss a');

      const duration = moment.duration(endTime.diff(startTime));
      var hours = Math.floor(duration.asHours());
      var mins = Math.floor(duration.asMinutes()) - hours * 60;

      setCalculateTimerTime(`${hours}:${mins}:00`);
    } else {
      setCalculateTimerTime('00:00:00');
    }
  };

  return (
    <div>
      {timer !== 0 && !timerPause && moment(runningDate).isSame(actualDate) ? (
        <div>
          {moment(timerTime, 'HH:mm:ss').hours()}h:
          {moment(timerTime, 'HH:mm:ss').minutes()}m
        </div>
      ) : (
        <div>
          {moment(calculateTimerTime, 'HH:mm:ss').hours()}h:
          {moment(calculateTimerTime, 'HH:mm:ss').minutes()}m
        </div>
      )}
    </div>
  );
};

export default Stopwatch;
