import React, { useState, useRef, useEffect, Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    TitleSection,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import { getModifiedVisitsId } from 'utils/api/AlertsApi';
import { getHistoryNotes, } from 'utils/api/ClientApi';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DescriptionList from './CareNotesDescription';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

const CareNotesModal = ({
    idSelected,
    employeeName,
    choices,
    clientId,
    values,
    setValues,
    useInput,
    setShowModal,
    errorMessage,
    handleSubmit,
    textStatus,
    loadingSave,
    buttonIcon,
    editMode,
    readPermissionCareNotes,
    createPermissionCareNotes,
    updatePermissionCareNotes,
    deletePermissionCareNotes,
    readPermissionCareNotesReview,
    createPermissionCareNotesReview,
    updatePermissionCareNotesReview,
    deletePermissionCareNotesReview
}) => {

    const [visitIDData, setVisitIDData] = useState([]);

    // const ins_hook = useInput(`review_comment`);
    // const newDoseTime = useInput(`review_time`);
    // const review_date_hook = useInput(`review_date`);
    const client_visit_date = useInput(`client_service_visit_date`)
    const visit_hook = useInput(`visit`, 'isRequired')
    const alert_privacy_hook = useInput(`alert_privacy`, 'isRequired')
    const post_description_hook = useInput('post_description', 'isRequired')
    // const review_by_hook = useInput(`review_by`, 'isRequired')
    // const review_status_hook = useInput(`review_status`, 'isRequired')

    // useEffect(() => {
    //     if (idSelected) {
    //         handleChangeChoices();
    //         // loadVisitCareNotesByRowsId();
    //         setTitle(edit_notes);
    //         // setLoadingModal(false);
    //         loadHistoryNotes();
    //     } else {
    //         setTitle(new_notes);
    //         // setLoadingModal(false);
    //     }  
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [idSelected]);


    const toast = useRef();


    useEffect(() => {
        if (clientId && values.client_service_visit_date && choices)
            visitCompleteData();
    }, [clientId, values.client_service_visit_date, idSelected]);



    const loadHistoryNotes = async () => {
        const resp = await getHistoryNotes(idSelected);
        if (resp.data) {
            values.prev_description = resp.data;
            setValues({ ...values });
        }
    };

    const visitCompleteData = async () => {
        if (clientId && values.client_service_visit_date) {
            const response = await getModifiedVisitsId(
                clientId,
                values.client_service_visit_date
            );
            let VisitDropdown = response?.data?.map(visitData => {
                const jobTitle = visitData.job_title ? `${visitData.job_title} ` : '';
                const timeRange = `${visitData.start_date && visitData.start_date?.split("T")[1].slice(0, 5)} - ${visitData.end_date && visitData.end_date?.split("T")[1].slice(0, 5)}`;

                return {
                    id: visitData.id,
                    name: `${jobTitle}${timeRange}`,
                };
            });

            setVisitIDData(VisitDropdown);
            setValues({ ...values });
        }
    };

    const dropDownHandleSelect = (item, nameItem) => {
        if (nameItem == 'client_service_visit_timerange') {
            values.client_service_visit_timerange =
                item.client_service_visit_timerange;
        }

        setValues({ ...values, ...item });
    };

    const HandleMultiSelect = async (item, diffKeyname) => {
        console.log(item, diffKeyname, "preferred")
        values.alert_label = item?.alert_label;
        setValues({ ...values });
    };




    return (
        <>
            {/* {(editMode ? (updatePermissionCareNotes || updatePermissionCareNotesReview) : (createPermissionCareNotes || createPermissionCareNotesReview))
            ? */}
            <div className="pl-3 pr-3 pb-3 pt-0">

                {(editMode ? readPermissionCareNotes : createPermissionCareNotes) && (
                    <div>
                        {/* <TitleSection>Care Notes Details</TitleSection> */}
                        <FlexGrid3Container className={'w-100 mb-2'}>
                            {editMode && (
                                <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                    <DateSelector
                                        disabled={true}
                                        labelTop="Client Service Visit Date"
                                        {...client_visit_date}
                                    />
                                </FlexGrid3Item>
                            )}

                            {editMode && (
                                <FlexGrid3Item className="pr-1 mt-1 " ResizeWidth={'50%'}>
                                    <DropdownSearchable
                                        {...visit_hook}
                                        placeHolder={'Visit'}
                                        disabled={true}
                                        width={'100%'}
                                        options={visitIDData || []}
                                        selectedOption={values ? values.visit : null}
                                        onChange={value =>
                                            dropDownHandleSelect({ visit: value ? value : null }, 'visit')
                                        }
                                    />
                                </FlexGrid3Item>
                            )}
                            <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                <DropdownSearchable
                                    disabled={editMode ? !updatePermissionCareNotes : !createPermissionCareNotes}
                                    placeHolder={'Employee'}
                                    options={choices ? choices?.user_list : []}
                                    selectedOption={values.alert_created_by}
                                    onChange={value =>
                                        dropDownHandleSelect({
                                            alert_created_by: value ? value : null,
                                        })
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                <DropdownSearchable
                                    required
                                    {...alert_privacy_hook}
                                    disabled={editMode ? !updatePermissionCareNotes : !createPermissionCareNotes}
                                    placeHolder={'Privacy Type'}
                                    options={choices?.alert_privacy ? choices.alert_privacy : []}
                                    selectedOption={values.alert_privacy}
                                    onChange={value =>
                                        dropDownHandleSelect({ alert_privacy: value ? value : null })
                                    }
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1 mb-2" ResizeWidth={'100%'}>
                                <div className={'pt-2 ml-md-1 ml-0'}
                                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                                    New Description<span style={{ color: "red" }}> *</span>
                                </div>
                                <InputTextArea
                                    required
                                    disabled={editMode ? !updatePermissionCareNotes : !createPermissionCareNotes}
                                    type="text"
                                    height={'4rem'}
                                    id="description"
                                    placeholder={'New Description'}
                                    {...post_description_hook}
                                    maxlength={5}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'100%'}>
                                <DropdownSearchable
                                    isCreate={true} // to add items not present in the options(dropdown items)
                                    isMulti={true}
                                    placeHolder={'Labels'}
                                    disabled={editMode ? !updatePermissionCareNotes : !createPermissionCareNotes}
                                    options={choices && choices.alert_label ? choices.alert_label.map(x => ({ id: x.id, name: x.name })) : []}
                                    selectedOptionsProps={
                                        values
                                            ? values.alert_label
                                                ? values.alert_label
                                                : null
                                            : null
                                    }
                                    // selectedOption={values.alert_label && values.alert_label}
                                    onChange={value =>
                                        HandleMultiSelect(
                                            { alert_label: value ? value : null },
                                            'alert_label'
                                        )
                                    }
                                />
                            </FlexGrid3Item>
                            {values.prev_description?.length > 0 ? (
                                <FlexGrid3Item
                                    className="pr-1 pt-1 mt-1 mb-2"
                                    ResizeWidth={'100%'}
                                    style={{
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                        height: '300px',
                                    }}>
                                    {values.prev_description?.length > 0 ? (
                                        <small className="pr-1 ">Previous Description</small>
                                    ) : null}
                                    {values.prev_description?.length > 0 &&
                                        values.prev_description?.map((data, index) => {
                                            return (
                                                <div key={data.id}>
                                                    <DescriptionList
                                                        readOnly={editMode ? !updatePermissionCareNotes : !createPermissionCareNotes}
                                                        data={data}
                                                        index={index}
                                                        useInput={useInput}
                                                        values={values}
                                                        setValues={setValues}
                                                        employeeName={employeeName}
                                                        contextChoices={choices}
                                                    />
                                                </div>
                                            );
                                        })}
                                </FlexGrid3Item>
                            ) : null}
                        </FlexGrid3Container>
                    </div>
                )}

                {/* {(editMode ? readPermissionCareNotesReview : createPermissionCareNotesReview) && (
                    <div>
                        <TitleSection>Review Details</TitleSection>
                        <div className="d-flex mt-2">
                            <div className="w-100 mr-2">
                                <DropdownSearchable
                                    disabled={editMode ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                                    placeHolder={'Review By'}
                                    options={choices?.user_list || []}
                                    {...review_by_hook}
                                    selectedOption={
                                        values.review_by
                                    }
                                    onChange={value =>
                                        dropDownHandleSelect(
                                            { review_by: value ? value : null },
                                            'review_by'
                                        )
                                    }
                                />
                            </div>
                            <div className="w-100 mr-2">
                                <DropdownSearchable
                                    disabled={editMode ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                                    placeHolder={'Review Status'}
                                    options={choices?.medication_review_status || []}
                                    {...review_status_hook}
                                    selectedOption={
                                        values.review_status
                                    }
                                    onChange={value =>
                                        dropDownHandleSelect(
                                            { review_status: value ? value : null },
                                            'review_status'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div className="w-100 pr-1">
                                <DateSelector
                                    disabled={editMode ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                                    labelTop="Review Date"
                                    {...review_date_hook}
                                />
                            </div>
                            <div className="w-100 pr-1">
                                <TimeSelector
                                    disabled={editMode ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                                    labelTop={'Review Time'}
                                    {...newDoseTime}
                                    timeFormat={'HH:mm'}
                                    dateFormat={'HH:mm'}
                                />
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div className="w-100 pr-1">
                                <div
                                    className={'pt-2 ml-md-1 ml-0'}
                                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                                    Review Comment
                                </div>
                                <InputTextArea
                                    type="text"
                                    height={'3.4rem'}
                                    id="emp_desc"
                                    placeHolder={'Review Comment'}
                                    label={'Review Comment'}
                                    {...ins_hook}
                                    disabled={editMode ? !updatePermissionCareNotesReview : !createPermissionCareNotesReview}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
                <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
                    <div className={'mb-3'}>
                        {errorMessage ? (
                            <ErrorMessage>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                    </div>
                    <div className={'d-flex justify-content-center justify-content-md-end'}>
                        <div className={'ml-0 ml-md-3 mr-2'}>
                            <PrimaryButtonForm
                                bgcolor="PURPLE_GRAY_500"
                                minWidth="6rem"
                                className='mr-2'
                                onClick={() => setShowModal(false)}>
                                Cancel
                            </PrimaryButtonForm>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={handleSubmit}
                                disabled={editMode
                                    ? (!updatePermissionCareNotes && !updatePermissionCareNotesReview)
                                    : (!createPermissionCareNotes && !createPermissionCareNotesReview)}
                            >
                                <span className={'mr-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </div>
            {/* :
            <NoPermissionContainer>
                You don't have permission to access the information.
            </NoPermissionContainer>} */}
        </>
    );
};

export default CareNotesModal;


