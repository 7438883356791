import React, {
    useState,
    useEffect,
    useContext,
    useModal,
    useRef,
    Fragment,
  } from 'react';
  import InputTextArea from 'components/SharedComponents/InputTextArea';
  import {setAbsenceApprovel, setEvvVerification} from 'utils/api/EmployeeApi';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {useForm} from 'hooks/FormHook';
  import {Toast} from 'primereact/toast';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {AppContext} from 'context';
  
  import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import { getAllVisitEsvslist } from 'utils/api/SchedulerApi';
  
  const EvvBulkModal = ({
    fullBind,
    bulkId,
    setTitle,
    editDatas,
    setDataCheck,
    
    selectedClientId,
    setAbsenceModalValue,
    addData,
    idSelected,loadData,
    absenceListData,search,setModalValue,
    clearSelectedElId
  }) => {
   
    const [errorMessage, setErrorMessage] = useState('');
  
    const [loadingModal, setLoadingModal] = useState(false);
    const toast = useRef();
    const {
      contextChoices,
      contextIsAdmin,
      contextIsSuperAdmin,
      contextPermissions,
      contextIsStaff,
    } = useContext(AppContext);
    const [verificationStatusList ,setVerificationLisi]=useState([])

    useEffect(()=>{
    let newList =     contextChoices?.verificationStatus.map((item)=>{
            return{
                id:item,
                name:item
            }
        })

        setVerificationLisi([...newList])
    },[])
    const {values, setValues, useInput, isValid, errors, setCheckRequires} =
      useForm({});
  useEffect(()=>{
  
    setTitle('Evv Verification')
  })
   
    const handleDropDownSelect = item => {
      setValues({...values, ...item});
    };
    const handleSubmit = async () => {
      if (!isValid && errors.length > 0) {
        setErrorMessage(
          'Some of the fields have invalid or empty values. Please review'
        );
        setCheckRequires(true);
        return;
      }
      else{
      let body = {
        id: bulkId,
        verificationStatus: values.verificationStatus,
        evvComments: values.evvComments,
      };
      let response = await setEvvVerification(body);
      if (response.status === true) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${response.message}`,
        });
        clearSelectedElId();
        loadData()

        setTimeout(() => {
            setModalValue(false);
        }, 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response.message}`,
        });
      }
    }
    };
    return (
      <ModalContainer max_width={'55%'}>
        <ModalDynamic {...fullBind()} minWidthTitle="8.5rem" loading={loadingModal}>
          <Toast ref={toast} position="top-right" />
          <div className="d-flex flex-column mt-2">
            <div className="d-flex mb-2">
              <div
                className="w-100 mr-2"
                style={{marginLeft: '10px', marginRight: '10px'}}>
                <DropdownSearchable
                  required
                  {...useInput('verificationStatus', 'isRequired')}
                  placeHolder="Verification Status"
                  options={verificationStatusList}
                  selectedOption={
                    values.verificationStatus ? values.verificationStatus : null
                  }
                  onChange={value =>
                    handleDropDownSelect(
                      {
                        verificationStatus: value ? value : null,
                      },
                      'verificationStatus',
  
                      value
                    )
                  }
                />
              </div>
              <div
                className="w-100 mr-2"
                style={{marginLeft: '10px', marginRight: '10px'}}>
                <InputTextArea
                  type="text"
                  height={'2.5rem'}
                  id="Comments"
                  placeholder='Evv Comments'
                  {...useInput('evvComments')}
                />
              </div>
            </div>
          </div>
  
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div
              className={'mb-3'}
              style={{display: 'flex', justifyContent: 'right'}}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  style={{marginBottom: '5px'}}
                  onClick={handleSubmit}>
                  Save
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </ModalDynamic>
      </ModalContainer>
    );
  };
  
  export default EvvBulkModal;
  