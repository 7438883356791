import React from 'react';
import {SpinnerStyled, SpinnerLabel} from './styles';
import PropTypes from 'prop-types';

const Spinner = ({spinnerTop, textTop}) => {
  return (
    <div>
      <SpinnerStyled spinnerTop={spinnerTop} />

      <SpinnerLabel textTop={textTop}>Loading</SpinnerLabel>
    </div>
  );
};

Spinner.propTypes = {
  spinnerTop: PropTypes.string,
  textTop: PropTypes.string,
};

Spinner.defaultProps = {
  spinnerTop: '66%',
  textTop: '64%',
};

export default Spinner;
