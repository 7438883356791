import React from 'react'
import profile from "../../../../images/leftSidebar/profile.svg"
import './style.css'
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdSettings } from "react-icons/io";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ACCOUNT_ROUTE, CHANGE_PASSWORD_ROUTE } from 'utils/constants/routes';


function AccountSettings({ setDeleteAccountModalValue, setDeleteAccountTitle }) {
  const history = useHistory()
  const password_expiry_days_left = localStorage.getItem('password_expiry_left')

  return (
    <div className='setting-profile'>
      <div className='icon-container'>
        <div className="dropdown" >
          <button className="dropdown-toggle icon-button" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img alt="" className='icon' src={profile}></img>
          </button>
          <div class="dropdown-menu" style={{ left: '-179px', padding: "0", paddingBottom: '10px' }} aria-labelledby="dropdownMenuButton">
            <div className='d-flex align-items-center bg-primary' style={{ padding: "0 10px" }}>
              <IoMdSettings color="white" />
              <div className="dropdown-header text-white" style={{ width: '250px', whiteSpace: 'normal', fontSize: '14px', padding: '0.5rem 10px', cursor: "default" }}>Account Settings</div>
            </div>

            <div class="dropdown-item" style={{ fontSize: '14px' }}
              onClick={() => {
                history.push(ACCOUNT_ROUTE + CHANGE_PASSWORD_ROUTE);
              }}> <span title="Number of password expiry days left" class="circle">{password_expiry_days_left && password_expiry_days_left > 0 ? password_expiry_days_left : 0}</span>Change Password</div>
            <div class="dropdown-item" style={{ fontSize: '14px' }}
              onClick={() => {
                setDeleteAccountModalValue(true)
                setDeleteAccountTitle('Delete Account')
              }}><span class="trash-icon"><RiDeleteBinLine /></span>Delete Account</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSettings