import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from 'components/DashboardComponents/Settings/SettingsClient/Funder/headerColumnsList';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { FloatingMobileButton } from 'shared/styles/buttons';
import Fade from 'react-reveal/Fade';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { getPageToSearchParam } from 'shared/methods';
import { GrSearch } from 'react-icons/gr';
import { AppContext, BranchItemsContext } from "context"
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  Header, SearchAddContainer, DropdownTypesContainer,
  SearchIcon,
  SearchContainer,
} from 'shared/styles/constants/tagsStyles';
import { getFunders, deleteFunder } from 'utils/api/SettingsApi';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';

import { useForm } from 'hooks/FormHook';
import { setting_client_funder_choices } from 'utils/choiceConstant';

const Funder = ({
  location,
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff
}) => {
  const { values, setValues, useInput, errors, isValid, setCheckRequires } = useForm({});
  const [branchFilter, setBranchFilter] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [funders, setFunders] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pages, setPages] = useState(0);
  const [numberValue, setNumberValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [branchCount, setBranchCount] = useState(0);
  const fullNameCol = "funder_name";
  const history = useHistory();
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const { contextChoices: choices } = useContext(AppContext)
  const { branchData } = useContext(BranchItemsContext);
  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(setting_client_funder_choices)
  },[])
  const handleModal = () => {
    setModalValue(!modalValue);
    setValues({})
  };

  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])


  useEffect(() => {
    values.ordering = 'funder_name';
    setNumberValue(1);
    setValues({ ...values });
  }, [])

  useEffect(() => {
    if (numberValue == 0) {
      values.ordering = '-funder_name';
      setValues({ ...values });
    }
  }, [numberValue])

  useEffect(() => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/client/funder",
      search: stringified,
    });
    loadFunders()
  }, [values.limitperpage])


  const changeNumber = (value) => {
    setNumberValue(value);
  }


  useEffect(() => {
    if (branchFilter.length == 0)
      loadFunders()
  }, [values.ordering, location.search, branchData])



  useEffect(() => {
    if (branchFilter.length > 0 || branchCount == 1) {
      loadFunders()
      setBranchCount(1)
    }

  }, [branchFilter])

  const loadFunders = () => {
    const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);
    getFunders({ ...branchData, ...values, ...pageToSearch })
      .then(response => {
        setFunders(response.results || []);
        setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
      })
  }

  const getFunderCategory = (category_id) => {
    let selectedSource = choices && choices.funder_source && choices.funder_source.find(source => source.id === category_id)
    if (selectedSource) {
      return selectedSource.name
    }
  }
  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d)
    }
  }
  const searchFunc = (value) => {
    values.search = value
    loadFunders();
  }

  const handleDeleteFunder = async () => {
    await deleteFunder(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');
    loadFunders();
  };

  const debounceOnchange = useRef(debounce(searchFunc, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value)
    debounceOnchange.current(e.target.value)
  }
  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const toDeleteFunder = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const dropDownHandleSelect = item => {
    // if(item.branch){
    //   item.branch=item.branch.length===(choices.branch ? choices.branch :0).length||item.branch.length===0?[]:item.branch
    // }
    setValues({ ...values, ...item })
  }
  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }
  const handleAddFunder = () => {
    setIdSelected('');
    handleModal();
  };
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }



  return (
    <div>
      <Header>
        <DropdownTypesContainer className="mr-2">
          <NoSelectAllDropdownFilter
            items={choices.branch ? choices.branch : []}
            setItemsSelected={items => {
              dropDownHandleSelect({ branch: items });
            }}
            itemsSelected={branchFilter}
            setSelectedFilter={setFilterBranch}
            placeHolder={'Branch'}
            isObject
            allOption
          // hitApiAfterClear={hitApiAfterClear}    
          />
        </DropdownTypesContainer>

        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex mb-2 align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}       
                onChange={(e) => {
                  keyPressed(e)
                }
                }
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>
          {(filterAdmin || filterSuperAdmin || createFunderSettings) &&
            <FloatingMobileButton onClick={handleAddFunder}>
              <FaPlus />
              <label>ADD NEW FUNDER</label>
            </FloatingMobileButton>
          }

        </SearchAddContainer>
      </Header>
      {(filterAdmin || filterSuperAdmin || readPermissionFunderSettings || updateFunderSettings || deletePermissionFunderSettings) &&
        <Table
          headerColumns={headerColumns}
          headerPadding={false}
          fontSize="0.75rem"
          callBackQuerySort={setQuerySort}
          statusNumber={numberValue}
          setStatusNumber={changeNumber}
          fullNameCol={fullNameCol}
        >
          <div>
            {funders && funders.map((funder, index) => {
              return (
                <div key={index}>
                  <Row bgColor>
                    <ColsGrouper
                      className="d-flex"
                      onClick={() => {
                        handleRowClick(funder.id);
                      }}>
                      <Col max_width={LARGE_COL} Center className="sm-hidden" textOverlap={true}>
                        {funder.funder_name}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden">
                        {funder.reference}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden">
                        {funder.funder_category && getFunderCategory(funder.funder_category)}
                      </Col>
                      <Col max_width={SMALL_COL} Center className="sm-hidden">
                        {funder.status ? 'Active' :
                          funder.status == false ?
                            'Inactive' : ""}
                      </Col>
                      <Col max_width={LARGE_COL} Center className="sm-hidden">
                        {funder.funder_contact_full_name}
                      </Col>
                      <Col max_width={LARGE_COL} Center className="sm-hidden">
                        {funder.funder_contact_organisation_title}
                      </Col>
                      <Col max_width={LARGE_COL} Center className="align-self-center mb-0">
                        {funder.funder_contact_phone_numbers}
                      </Col>
                      <Col max_width={LARGE_COL} Center className="align-self-center mb-0">
                        {funder.funder_contact_email}
                      </Col>
                      <Col max_width={LARGE_COL} Center className="sm-hidden">
                        {funder.branches &&
                          funder.branches.map(branch => branch.branch_name).join(",")
                        }
                      </Col>
                      {filterAdmin || filterSuperAdmin || updateFunderSettings && (
                        <Col className="sm-hidden" Center Icon Purple>
                          <IconWrapper
                            onClick={() => {
                              handleRowClick(funder.id);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                        </Col>
                      )}
                    </ColsGrouper>
                    {(((filterAdmin == true) || (filterSuperAdmin == true)) ||  (deletePermissionFunderSettings)) && (
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteFunder(funder.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                    )}
                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
            <div className="mt-3">
              <Pagination totalPages={pages}
                values={values}
                dropDownHandleSelect={dropDownHandleSelectPagination}
                number={PAGE_SIZE_RENDER}
                currentPage={currentPage}
                dataLength={funders.length} />
            </div>
          </div>
        </Table>
      }


      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          choices={choices}
          values={values}
          setValues={setValues}
          isValid={isValid}
          errors={errors}
          useInput={useInput}
          readOnly={(filterAdmin || filterSuperAdmin) ? false
            : (readPermissionFunderSettings && !updateFunderSettings && !createFunderSettings)
              ? true : false
          }
          fullBind={fullBind}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          setCheckRequires={setCheckRequires}
          loadFunders={loadFunders}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Funder? \n DELETING the Funder will remove ALL records from database.'
        }
        subBody={
          ''
        }
        onOk={handleDeleteFunder}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
}

export default Funder;
