import { Switch, TextField } from '@mui/material'
import React from 'react'

export default function SwitchComp({ json, setJson }) {
    console.log(json, "JSON")
    return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
            <TextField
                id="outlined-error"
                label="Label *"
                defaultValue={"Label"}
                style={{ marginBottom: 10 }}
                value={json.switch.label}
                onChange={(event) => {
                    console.log(event.target.value)
                    setJson({
                        ...json, switch: {
                            ...json.switch,
                            label: event.target.value
                        }
                    })
                }}
            />

            <TextField
                id="outlined-multiline-static"
                label="Description"
                className='mb-2'
                multiline
                rows={3}
                style={{ marginBottom: 10 }}
                value={json.switch.description}
                placeholder="Description"
                onChange={(event) => {
                    setJson({
                        ...json, switch: {
                            ...json.switch,
                            description: event.target.value
                        }
                    })
                }}
            />
           <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.switch.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, switch: {
                                ...json.switch,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.switch.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, switch: {
                                                ...json.switch,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.switch.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, switch: {
                                                ...json.switch,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.switch.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, switch: {
                                                ...json.switch,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.switch.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.switch.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, switch: {
                                ...json.switch,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}
