import React from 'react'
import BillRateCardSetting from './BillRateCardSetting'
import { useState } from 'react';
import { ModalTabsContainer } from 'components/DashboardComponents/Team/AddModal/ModalStyle';
import { TabStyled } from 'shared/styles/constants/tagsStyles';
import BillingNonCommuteSetting from './BillingNonCommuteSetting';

function Billing({
  payrollTabData,
  getApiData,

  readPermissionBilling,
  createPermissionBilling,
  updatePermissionBilling,
  deletePermissionBilling,

  readPermissionBillingNonCommute,
  createPermissionBillingNonCommute,
  updatePermissionBillingNonCommute,
  deletePermissionBillingNonCommute,
}) {
  const tab1 = 1;
  const tab2 = 2;

  const [tabSelected, setTabSelected] = useState(tab1)

  const isCurrentTab = tab => tab === tabSelected;
  return (
    <>
      <div>
        <ModalTabsContainer>
          {readPermissionBilling && (
            <TabStyled
              active={isCurrentTab(tab1)}
              onClick={() => {
                setTabSelected(tab1)
              }}>
              Rate Card Settings
            </TabStyled>)}
          {readPermissionBillingNonCommute && (
            <TabStyled
              active={isCurrentTab(tab2)}
              onClick={() => {
                setTabSelected(tab2)
              }}>
              Non Commute Travel Setting
            </TabStyled>)}
        </ModalTabsContainer>
      </div>
      {tabSelected === tab1 && (
        <BillRateCardSetting
          payrollTabData={payrollTabData}
          getApiData={getApiData}
          readPermission={readPermissionBilling}
          createPermission={createPermissionBilling}
          updatePermission={updatePermissionBilling}
          deletePermission={deletePermissionBilling}
        />
      )}

      {tabSelected === tab2 && (
        <BillingNonCommuteSetting
          readPermission={readPermissionBillingNonCommute}
          createPermission={createPermissionBillingNonCommute}
          updatePermission={updatePermissionBillingNonCommute}
          deletePermission={deletePermissionBillingNonCommute}
        />
      )}
    </>
  )
}

export default Billing