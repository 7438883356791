import React, {useState, useEffect} from 'react';
import {InitialLetters} from './styles';
import {FiCameraOff} from 'react-icons/fi';

const Image = ({src, alt, name, lastName, fontSize}) => {
  const [nameInitial, setNameInitial] = useState('  ');
  const [lastNameInitial, setLastNameInitial] = useState('');
  const [reviewImage, setReviewImage] = useState(true);
  const myRef = React.createRef();

  useEffect(() => {
    //if the reviewImage is true, the image will be revised
    if (reviewImage) {
      const img = myRef.current;

      //check that the image has been uploaded
      if (img && img.complete) {
        if (img.naturalWidth === 0) {
          setReviewImage(false);
        }
      }

      //check that the image reference exists and will be change reviewImage
      //on false for image won`t show
      if (!img) {
        setReviewImage(false);
      }

      //check that the image src exists and will be change reviewImage
      //on false for image won`t show
      if (!src) {
        setReviewImage(false);
      }

      //assign the first letter of name
      if (name) {

        setNameInitial(
          name
            .replace(/ /g, '')
            .charAt(0)
            .toUpperCase(),   
        );


      } else {
        setNameInitial('');
      }
      //assign the first letter of last name
      if (lastName) {
      
        setLastNameInitial(

          lastName
            .replace(/ /g, '')
            .charAt(0)
            .toUpperCase(),
            
        );

      } else {
        setLastNameInitial('');
      }

      //if name and last name are empty will be show a camera image
      if (!name && !lastName) {
        setNameInitial(
          <div className={'mb-2'}>
            <FiCameraOff />
          </div>,
        );

        setLastNameInitial('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, name, lastName, reviewImage]);

  useEffect(() => {
    //if the src of the image changes, the image will be revised in useEffect
    //and will be change reviewImage in true for to show it
    setReviewImage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  // useEffect   below  is  to solve  issue  of intials of fullname   of   list whose image files  are'nt uploaded 
  useEffect(() => {
    //assign the first letter of name
       if (name) {
         setNameInitial(
           name
             .replace(/ /g, '')
             .charAt(0)
             .toUpperCase(),
         );
       } else {
         setNameInitial('');
       }
       //assign the first letter of last name
       if (lastName) {
         setLastNameInitial(
           lastName
             .replace(/ /g, '')
             .charAt(0)
             .toUpperCase(),       
         );
       } else {
         setLastNameInitial('');
       } 
   }, [name,lastName]);
   
     
  const handleOnError = () => {
    //if the image does have error does`t show
    setReviewImage(false);
  };

 
  return (

    <span>
      {reviewImage ? (
        <img src={src} alt={alt} ref={myRef} onError={handleOnError} />
      ) : (
        <InitialLetters fontSize={fontSize}>
          <div>
            {nameInitial} {lastNameInitial}
          </div>
        </InitialLetters>
      )}
    </span>
  );
};

export default Image;
