import { FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import { IconWrapper } from 'components/SharedComponents/Table/styles'
import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FloatingMobileButton } from 'shared/styles/buttons'

export default function MultiDropdown({ json, setJson }) {

    const opt = {
        label: ""
    }


    return (
        <div style={{ marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                    id="outlined-error"
                    label="Dropdown Name *"
                    placeholder="Dropdown name"
                    defaultValue={json.dropdown.label}
                    style={{ marginBottom: 10 }}
                    onChange={(event) => {
                        setJson({
                            ...json, multidropdown: {
                                ...json.multidropdown,
                                label: event.target.value
                            }
                        })
                    }}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.multidropdown.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, multidropdown: {
                                ...json.multidropdown,
                                description: event.target.value
                            }
                        })
                    }}
                />
             <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.multidropdown.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, multidropdown: {
                                ...json.multidropdown,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                 </div>
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.multidropdown.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, multidropdown: {
                                                ...json.multidropdown,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.multidropdown.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, multidropdown: {
                                                ...json.multidropdown,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.multidropdown.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, multidropdown: {
                                                ...json.multidropdown,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.multidropdown.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.multidropdown.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, multidropdown: {
                                ...json.multidropdown,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
            {
                json.multidropdown.options.map((item, index) => {
                    return (
                        <div key={index} style={{ display: "flex", flex: 1, justifyContent: "space-between", marginTop: 10 }}>
                            <TextField
                                id="outlined-error"
                                label="Option value*"
                                placeholder='Option value'
                                value={item.label}
                                style={{ marginRight: 10, width:'100%' }}
                                onChange={(event) => {
                                    setJson({
                                        ...json, multidropdown: {
                                            ...json.multidropdown,
                                            options: json.multidropdown.options.map((item, index1) => {
                                                if (index === index1) {
                                                    return { label: event.target.value }
                                                }
                                                return item
                                            })
                                        }
                                    })
                                }}
                            />
                            {/* <TextField
                                id="outlined-error-helper-text"
                                label="Value"
                                placeholder='Value'
                                value={item.value}
                                onChange={(event) => {
                                    setJson({
                                        ...json, multidropdown: {
                                            ...json.multidropdown,
                                            options: json.multidropdown.options.map((item, index1) => {
                                                if (index === index1) {
                                                    return { ...item, value: event.target.value }
                                                }
                                                return item
                                            })
                                        }
                                    })
                                }}
                            /> */}

                            <IconWrapper
                                onClick={() => {
                                    setJson({
                                        ...json, multidropdown: {
                                            ...json.multidropdown,
                                            options: json.multidropdown.options.filter((item, index2) => index2 !== index)
                                        }
                                    })
                                }}>
                                <RiDeleteBinLine />
                            </IconWrapper>
                        </div>
                    )
                })
            }
            <div style={{ marginTop: 10 }}>

                <FloatingMobileButton onClick={() => {
                    setJson({
                        ...json, multidropdown: {
                            ...json.multidropdown,
                            options: [...json.multidropdown.options, opt]
                        }
                    })
                }}>
                    <FaPlus />
                    <label>
                        {/* NEW ALERTS */}
                        ADD OPTIONS
                    </label>
                </FloatingMobileButton>
            </div>

        </div>
    )
}
