import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { HTML_NAMES } from '../constants';

export default function DropDownTableValue({disabled=false,setJson, json,item,rowData, multiple= false, minWidth = 400, contextChoices, index, type ,label}) {
    // const [label, setLabel] = useState(json.dropdown.label)
    const [selected, setSelected] = useState('');

    useEffect(()=>{
       if(item){
        setSelected((type == "table") ? rowData[item?.column_name] : item?.value)
       }
    },[item, rowData])

    const handleOnChange = (event)=>{
        setSelected(event.target.value)
        let json_data = json.map((j_val)=>{
            if(j_val.name == "Table"){
                let r_data = j_val?.rows && j_val?.rows.map((r_val,index_r)=>{
                    if(index_r === index && item.label === r_val.label){
                        r_val[item.column_name] = event.target.value;
                    }
                    return r_val
                })
               let data = {...j_val, rows: r_data}
               return data
            }else{
                let r_data = j_val?.cols && j_val?.cols.map((r_val, index_r) => {
                    if (index_r === index && item.label === r_val.label && label == j_val.label) {
                        r_val.value = event.target.value;
                    }
                    return r_val
                })
                let data = { ...j_val, cols: r_data }
                return data
            }
            // return j_val
        })

        setJson([...json_data])
    }

    return (
        <FormControl style={{ flex: 1 }} sx={{ minWidth: minWidth }}>
             {/* <InputLabel id="demo-simple-select-label">Value</InputLabel> */}
            <Select
             style={{height:'45px'}}
                id="demo-simple-select"
                value={selected}
                disabled={disabled}
                multiple={multiple}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                // label={'Value'}
                onChange={(event) => {
                    handleOnChange(event)
                }}
            >
                {item?.manual_options ?
                    item?.manual_options.map((item, i) => <MenuItem key={i} value={item?.label}>{item?.label}</MenuItem>)
                    :
                    json.type !== HTML_NAMES.choices ? json.options.map((item, index)=><MenuItem key={index} value={item.id}>{item.label}</MenuItem>):
                    (contextChoices && item?.options && contextChoices[item.options] && contextChoices[item.options].map((item, i) => <MenuItem key={i} value={item.id}>{item.name}</MenuItem>))

                }
            </Select>
        </FormControl>
    )
}
