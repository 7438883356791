import styled from 'styled-components';
import {
  PURPLE,
  GRAY_100,
  GRAY_200,
  ERR_COLOR,
  PURPLE_800,
} from 'shared/styles/constants/colors';
import {XS} from 'shared/styles/constants/resolutions';

export const Icon = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
`;

export const ErrorLabel = styled.div`
  font-size: 0.6rem;
  color: ${ERR_COLOR};
  font-weight: bold;
`;

export const DatePickerStyled = styled.div`
  position: relative;
  label {
    position: absolute;
    left: 0.55rem;
    z-index: 1;
    font-size: 0.6rem;
    margin: 0;
    color: black;
    font-weight: 600;
    span {
      color: ${ERR_COLOR};
    }
  }
  div.react-datepicker-popper {
    z-index: 2;
  }
  div.react-datepicker-wrapper {
    width: 100%;
    div {
      input {
        width: 100%;
        height: 2.3994rem;
        padding-left: 0.55rem;
        padding-right: 1.6rem;
        font-size: 0.875rem;
        font-weight: 300;
        border: 1px solid ${PURPLE_800};
        background-color: ${GRAY_100};
        border-radius: 0.25rem;

        @media (max-width: ${XS}) {
          padding-left: 0.5rem;
          font-size: 0.7rem;
        }
      }
      input:focus {
        border: 1px solid ${PURPLE};
        outline: none;
        outline-color: unset;
      }
      input.error-border {
        border: 1px solid ${ERR_COLOR};
      }
      input.value-border {
        border: 1px solid ${PURPLE_800};
      }
      button::after {
        background-color: ${PURPLE};
      }
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${PURPLE};
  }
`;
