import React , {useState, useEffect} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  Title,
  HeaderVisits,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import moment from 'moment'

const EventModal = ({readOnly, fullBind, eventValues,employees}) => {
  fullBind.title = 'Cancelled Visits Detail';

const date_of_cancellation =  eventValues.visit_cancelled_datetime
? moment( eventValues.visit_cancelled_datetime).format('DD-MM-YYYY')
: 'Unset';   

const time_of_cancellation =  eventValues.visit_cancelled_datetime
? moment( eventValues.visit_cancelled_datetime).format('HH:mm:ss')
: 'Unset';  

const EmployeeName=eventValues.employee ?  employees.find(data=>data.id == eventValues.employee)?.name :  '';


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        showRowInfo
        minWidthTitle="8.5rem"
        // photoRow={eventValues.photo }
        // name={eventValues && eventValues.client_service_visit && eventValues.client_service_visit.client_data && eventValues.client_service_visit.client_data.full_name}
        // lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: 'Name :',
            value: eventValues && eventValues.client_service_visit && eventValues.client_service_visit.client_data && eventValues.client_service_visit.client_data.full_name,
          }
        ]}
        loading={false}>
        <div className={'pl-3 pr-3 pb-3'}>
          <FlexGrid3Container>
            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Notification method of cancel'}
                type="text"
                value={eventValues && eventValues.visit_cancellation_notification_method && eventValues.visit_cancellation_notification_method.name}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Date of cancel'}
                type="text"
                value={date_of_cancellation}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Time of cancel'}
                type="text"
                 value={time_of_cancellation}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Cancel update by'}
                type="text"
                value={eventValues && eventValues.updated_by && eventValues.updated_by.name}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Employee Name'}
                type="text"
                value={EmployeeName}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'client charge %'}
                type="text"
                value={eventValues.client_to_be_billed? eventValues.client_to_be_billed :''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'staff pay % '}
                type="text"
                value={eventValues.staff_to_be_billed ? eventValues.staff_to_be_billed :''}
              />
            </FlexGrid3Item>

          </FlexGrid3Container>
        </div>
      </ModalDynamic>
    </ModalContainer>

  );
};

EventModal.propTypes = {
  readOnly: PropTypes.bool,
  fullBind: PropTypes.object.isRequired,
  eventValues: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
};

EventModal.defaultProps = {
  readOnly: false,
};

export default EventModal;
