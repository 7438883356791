import styled from 'styled-components';
import {
  ERR_COLOR,
  PURPLE,
  PURPLE_800,
  SOFT_RED,
  GRAY_100,
  GRAY_200,
  WHITE,
  LIGHTBLACK,
  BLUE,
} from 'shared/styles/constants/colors';

export const InputForm = styled.input`
  background-color: ${GRAY_100};
  border: 1px solid ${GRAY_200};
  color: black !important;
  font-size: 0.875rem;
  font-weight: 300;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
  }

  &:focus {
    border-color: ${PURPLE};
  }

  &[data-error] {
    border-color: ${ERR_COLOR};
  }
  &[type='date'] {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 10px !important;
  }
`;

export const TextAreaForm = styled.textarea`
  &[data-error] {
    border-color: ${ERR_COLOR};
  }
`;

const inputType = type => {
  switch (type) {
    case 'text':
      return `
      color: black;
      font-size: 0.875rem;
      font-weight: 300;
      padding: 0.4em 1% 0 0.55rem;
      pointer-events: none;
      line-height: 1.1em;
      top: 16%;
      bottom: 1%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 1.3875rem;
      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
      `;
    case 'date':
      return `
      top: 1%;
      font-size: 0.6em;
      padding-top: 0.2em;
      font-weight: 600;
      position: absolute;
      `;
    default:
      return `
      color: black;
      font-size: 0.875rem;
      font-weight: 300;
      padding: 0.4em 1% 0 0.55rem;
      pointer-events: none;
      line-height: 1.1em;
      top: 16%;
      bottom: 1%;
      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
      `;
  }
};

export const InputLabelTopStyleNumber = styled.div`
height: ${props => createHeight(props.no_internal_margin, props.have_error)};
position: relative;
margin-bottom: ${props => (props.no_internal_margin ? '0' : '1.25rem')};


 input[type=number] {
  width: 6em;
  padding: 4px;
  min-height: 2.3rem;
    border: 1px solid #6200f936;
    border-radius: 1em;
    text-align: center;
    color: #868686;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
    margin: 0;
  }
   input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    background: #868686;
  }
  label {
    left: 35%;
    top: 0%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
    margin-left: ${props => (!props.typeFilter ? 'auto' : '0.4rem')};
    cursor: text;
    transition: all 0.15s ease;
    width: 100%;
  }

  input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: ${PURPLE};
  }

  input.filled ~ label,
  input:focus ~ label {
    top: 1%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
    margin-left: ${props => (!props.typeFilter ? 'auto' : '0.4rem')};
  }

  input.filled ~ label {
    /* color: #007932; */
  }

 

  

  input[data-error] {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: ${props => (props.have_error ? ERR_COLOR : '')} !important;
  }

  .errorMessage {
    ${props => errorMessage(props.have_error, props.type)}
  }

  .errorMessageHaveValue {
    ${props => errorMessageHaveValue(props.have_error)}
  }
`;

export const InputLabelTopStyle = styled.div`
  height: ${props => createHeight(props.no_internal_margin, props.have_error)};
  position: relative;
  width: 100%;
  margin-bottom: ${props => (props.no_internal_margin ? '0' : '1.25rem')};

  input,
  label {
    cursor: text;
    padding: 0.6em 0.3rem 0.15em 0.55rem;
    position: absolute;
    transition: all 0.15s ease;
    width: 100%;
    color: blue;
  }
  input,
  div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  input,
  span {
    color: ${SOFT_RED};
  }

  input {
    background-color: ${props => (!props.typeFilter ? GRAY_100 : WHITE)};
    border: 1px solid ${GRAY_200};
    color: black !important;
    font-size: 0.875rem;
    font-weight: 300;
    height: 2.10rem;
    border-radius: ${props => (!props.typeFilter ? '0.25rem' : '10rem')};

    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  label {
    ${props => inputType(props.type)}
  }

  input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: ${PURPLE};
  }

  input.filled ~ label,
  input:focus ~ label {
    top: 1%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
    margin-left: ${props => (!props.typeFilter ? 'auto' : '0.4rem')};
  }

  input.filled ~ label {
    /* color: #007932; */
  }
  input[value^="HHHHHHHH"] {
    border: 1px solid black;
    border-radius: 2px;
}

  input[type='text'].filled,
  input[type='email'].filled:valid,
  input[type='date'].filled:valid {
    border-color: ${PURPLE_800};
  }
  input[type='date'] {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 10px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[data-error] {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: ${props => (props.have_error ? ERR_COLOR : '')} !important;
  }

  .errorMessage {
    ${props => errorMessage(props.have_error, props.type)}
  }

  .errorMessageHaveValue {
    ${props => errorMessageHaveValue(props.have_error)}
  }
`;

export const CustomInputLabelTopStyle = styled.div`
  height: ${props => createHeight(props.no_internal_margin, props.have_error)};
  position: relative;
  width: 100%;
  margin-bottom: ${props => (props.no_internal_margin ? '0' : '1.25rem')};

  input,
  label {
    cursor: text;
    padding: 0.6em 0.3rem 0.15em 0.55rem;
    position: absolute;
    transition: all 0.15s ease;
    width: 100%;
    color: blue;
  }

  input,
  span {
    color: ${SOFT_RED};
  }

  input {
    background-color: ${props => (!props.typeFilter ? GRAY_100 : WHITE)};
    border: 1px solid ${GRAY_200};
    color: black !important;
    font-size: 0.875rem;
    font-weight: 300;
    height: 2.4rem;
    border-radius: ${props => (!props.typeFilter ? '0.25rem' : '10rem')};

    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  label {
    ${props => inputType(props.type)}
  }

  input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: ${PURPLE};
  }

  input.filled ~ label,
  input:focus ~ label {
    top: 1%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
    margin-left: ${props => (!props.typeFilter ? 'auto' : '0.4rem')};
  }

  input.filled ~ label {
    /* color: #007932; */
  }

  input[type='text'].filled,
  input[type='email'].filled:valid,
  input[type='date'].filled:valid {
    border-color: ${PURPLE_800};
  }
  input[type='date'] {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 10px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: button;
    margin: 2;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[data-error] {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: ${props => (props.have_error ? ERR_COLOR : '')} !important;
  }

  .errorMessage {
    ${props => errorMessage(props.have_error, props.type)}
  }

  .errorMessageHaveValue {
    ${props => errorMessageHaveValue(props.have_error)}
  }
`;
const errorMessageFormat = /*css*/ `
  position: absolute;
  font-size: 0.6rem;
  left: -0.4rem;
  color: ${ERR_COLOR} !important;
  font-weight:bold;
`;

function createHeight(no_internal_margin, have_error) {
  if (no_internal_margin) {
    return '2.375rem';
  }

  if (have_error) {
    return '2.3rem';
  } else {
    return '1.65rem';
  }
}

function errorMessage(have_error, type) {
  if (have_error) {
    return `
      margin-top: ${type === 'date' ? '2.3rem' : '1.9rem'};
      ${errorMessageFormat}
    `;
  }
}

function errorMessageHaveValue(have_error) {
  if (have_error) {
    return `
      margin-top: 2.4rem;
      ${errorMessageFormat}
    `;
  }
}



export const TextAreaInput = styled.textarea`
width: 100%;   

&[data-error] {
  border-color: ${ERR_COLOR};
}

background-color: ${props => (!props.typeFilter ? GRAY_100 : WHITE)};
  border: 1px solid ${GRAY_200};
  color:${props => (props.color ? BLUE : 'black !important')};
  font-size: 0.875rem;
  font-weight: 300;
  height:${props=>(props.height?props.height:'2.4rem')};
  border-radius: ${props => (!props.typeFilter ? '0.25rem' : '10rem')};
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}

:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: none;
  border-color: ${PURPLE};
}

label {
  ${props => inputType(props.type)}
}


.filled ~ label,
:focus ~ label {
  top: 1%;
  font-size: 0.6em;
  padding-top: 0.2em;
  font-weight: 600;
  position: absolute;
  margin-left: ${props => (!props.typeFilter ? 'auto' : '0.4rem')};
}

.filled ~ label {
  /* color: #007932; */
}

[type='text'].filled,
[type='email'].filled:valid,
[type='date'].filled:valid {
  border-color: ${PURPLE_800};
}
[type='date'] {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 10px !important;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type='number'] {
  -moz-appearance: textfield;
}

[data-error] {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: ${props => (props.have_error ? ERR_COLOR : '')} !important;
}

.errorMessage {
  ${props => errorMessage(props.have_error, props.type)}
}

.errorMessageHaveValue {
  ${props => errorMessageHaveValue(props.have_error)}
}
`;