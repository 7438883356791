import React, { useState, useEffect, Fragment, useContext } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import AddingVisitRun from './AddingVisitRun';
import CreateRun from './CreateRun';
import { getClientVisits, setVisitsRun, setVisitsDetachRun, setVisitsSwapRun, getRegularVisitRunById, setVisitsDetachAllRun } from 'utils/api/RegularVisitApi'
import {
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import DateSelector from 'components/SharedComponents/DateSelector';
import { KeyPress } from 'utils/constants/regularExpressions';
import moment from 'moment';
import { FLOPPY, CHECK, CROSS } from 'utils/constants/icons';
import {
  InvalidOrEmptyValues,
} from 'utils/constants/messages';
import {
  getRunNamesRegular
} from 'utils/api/SettingsApi';
import { AppContext } from "context"
import DetachRun from './DetachRun';
import SwapRun from './SwapRun';
import ModalDecision from 'components/SharedComponents/ModalDecision';


const RegularVisitModalRun = ({
  values,
  setValues,
  useInput,
  setCheckRequires,
  searchKeyword,
  clientsData,
  getvisits,
  setLoadingModal,
  loadingModal,
  setClientsData,
  menuRef,
  setErrorMessage,
  setTextStatus,
  errorMessage,
  textStatus,
  setVisitRunModal,
  setVisitDetachRunModal,
  setVisitSwapRunModal,
  visitRunModal,
  visitDetachRunModal,
  visitSwapRunModal,
  fullBind,
  fullBindVisitSwap,
  setNewRun,
  newRun,
  handleChangeChoices,
  bindModalDetachAall,
  setModalDetachAll,
  runTableOptionData
}) => {
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [runNames, setRunNames] = useState([]);
  const [choiceSkills, setChoiceSkills] = useState([]);
  const [choiceCompetency, setChoiceCompetency] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [choices, setChoices] = useState([])
  const [visitRun, setVisitRun] = useState('runvisits');
  const [visit, setVisit] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [dataValues, setDataValues] = useState([]);
  const [dataValuesDetach, setDataValuesDetach] = useState([]);
  const [employeeRequired, setEmployeeRequired] = useState([]);
  const [empVisit, setEmpVisit] = useState([]);
  const [empEmployeeReq, setEmpEmployeeReq] = useState([])
  const { contextChoices } = useContext(AppContext);
  const [modalLoading, setModalLoading] = useState(true)

  useEffect(() => {
    getRun()
  }, [])

  useEffect(() => {
    setChoices(contextChoices && contextChoices || []);
    let runValues = [];
    if (contextChoices && contextChoices.run_view) {
      runValues = contextChoices.run_view.map(y => {
        return { id: y && y.id, name: y && y.name }
      })
      runValues.unshift({ id: 'none', name: 'None' })
    }
    setRunNames(runValues || []);

    let runName = [];
    let rubNameNotNew = []
    if (contextChoices && contextChoices.run_view) {
      runName = contextChoices.run_view.map(item => {
        return { id: item.id, name: item.name };
      });
      rubNameNotNew = contextChoices.run_view.map(item => {
        return { id: item.id, name: item.name };
      });
    }
    runName.unshift({ id: 'new', name: '+ new run' })
    setDataValuesDetach(rubNameNotNew || [])
    setDataValues(runName || []);

    setChoiceSkills(contextChoices && contextChoices.skill && contextChoices.skill.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setChoiceCompetency(contextChoices && contextChoices.competency_name && contextChoices.competency_name.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    if (contextChoices && contextChoices.client_listing) {
      setClientsOptions(contextChoices.client_listing.map((y) => {
        return { id: y && y.id, name: y && y.full_name }
      }))
    }
    if (contextChoices && contextChoices.employee_listing) {
      setEmployeeOptions(contextChoices.employee_listing.map((y) => {
        return { id: y && y.id, name: y && y.full_name }
      }))
    }
  }, [contextChoices])

  const getRun = async () => {
    try {
      const response = await getRunNamesRegular();
      let runName = [];
      if (response.results) {
        runName = response.results.map(item => {
          return { id: item.id, name: item.name };
        });
      }
      runName.unshift({ id: 'new', name: '+ new run' })
      //setDataValues(runName || []);
    } catch (err) {
    }

  }

  const getVisitName = visit => {
    return (
      moment(visit.preferred_start_time, 'HH:mm').format('hh:mm A') +
      ' - ' +
      moment(visit.preferred_end_time, 'HH:mm').format('hh:mm A')
    );
  }
  const getSkillLevelName = empReq => {
    let required = ''
    let skillLevelName = ''
    let gender = ''
    if (empReq.skill_level) {
      skillLevelName = "" + choices && choices.job_title
        && choices.job_title.find(job => job.id == empReq.skill_level).name
    }
    if (empReq.preferred_gender) {
      gender = "" + choices && choices.gender
        && choices.gender.find(gender => gender.id == empReq.preferred_gender).name
    }
    if (skillLevelName) {
      required = '' + skillLevelName
    }
    if (empReq.start_time && empReq.end_time) {
      required =
        required +
        '(' +
        moment(empReq.start_time, 'HH:mm').format('hh:mm A') +
        ' - ' +
        moment(empReq.end_time, 'HH:mm').format('hh:mm A') + ')'

    }
    if (gender) {
      required = required + gender
    }
    return required
  }

  const dropDownHandleRegVisit = (item, nameItem) => {
    if (nameItem === 'client') {
      values[nameItem] = item[nameItem];
      values.visit = null
      setVisit([])
      setValues({ ...values });
      if (values.client) {
        getClientVisits(values[nameItem])
          .then(res => {
            let tempVisit = []
            setVisitData([...res.data])
            tempVisit = res && res.data && res.data.map(visit => {
              let visitName = getVisitName(visit)
              return (
                { id: visit.id, name: visitName }
              )

            })
            setVisit(tempVisit)
          })
      }
    }
    else if (nameItem === 'visit') {
      values[nameItem] = item[nameItem];
      values.employee_day_detail = [{}]
      setEmployeeRequired([])
      let tempEmpReq = []
      let selectedVisit = visitData && visitData.find(visit => visit.id == item.visit)
      tempEmpReq = selectedVisit && selectedVisit.employee_required
        && selectedVisit.employee_required.map(empReq => {
          let skillLevelName = getSkillLevelName(empReq)
          return (
            {
              id: empReq.id,
              name: skillLevelName
            }
          )
        })
      setEmployeeRequired([...tempEmpReq])
      setValues({ ...values })
    }
    else if (nameItem === 'specific') {
      setEmpVisit([])
      setEmpEmployeeReq([])
      setValues({ [nameItem]: item[nameItem], employee_day_detail: [{}] })
    }
    else {
      values[nameItem] = item[nameItem]
      setValues({ ...values });
    }

  };

  const calculateEndValueProps = value => {
    switch (value) {
      case END_TYPE_ON:
        return 'isRequired';
      case END_TYPE_AFTER:
        return 'isInt, isRequired';
      default:
        return '';
    }
  };

  const switchEndValueInput = props => {
    // render correct input type depending of the end_type selected
    switch (values.end_type) {
      case END_TYPE_ON:
        // check if current value has date format
        const validDate = props.value
          ? moment(props.value, 'YYYY-MM-DD', true).isValid()
          : true;
        // if current value has date format then send props
        const dateProps = validDate ? props : {};
        return (
          <DateSelector
            isRequired
            labelTop={'End value'}
            {...dateProps}
          />
        );
      case END_TYPE_AFTER:
        return (
          <InputLabelTop
            required
            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
            label={'End value'}
            type="text"
            id={`end_value`}
            {...props}
          />
        );
      case END_TYPE_NEVER:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };

  const handleSaveVisitRun = () => {

    if (!values.visits) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    if (!values.run_name) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setCheckRequires(false)
    setErrorMessage('');

    let run_id = {};
    if (values.run_name_id) {
      run_id.run_name_id = values.run_name_id;
    }
    let run_name = {};
    if (!values.run_name_id) {
      run_name.run_name = values.run_name;
    }

    let visits = [];
    let day = [];
    if (values && values.visits) {
      values.visits.map((val) => {
        visits.push({ client_service_visit: val.client_service_visit, requirement: val.requirement, repeat_on: val.day });
        day.push(`${val.day}`);
      })
    }

    let obj = {
      end_date: values.end_date,
      end_type: values.end_type,
      every: values.every,
      repeat: values.repeat,
      start_date: values.start_date,
      end_value: values.end_value ? values.end_value : '',
      csv_data: [...visits],
      ...run_id,
      ...run_name
    };
    saveRunData(obj);
  }

  const handleSaveVisitDetachRun = async () => {
    if (!values.visits) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    if (!values.run_name_id) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    } else {
      if (values.run_name_id.length < 1) {
        setCheckRequires(true)
        setErrorMessage(InvalidOrEmptyValues);
        return
      }
    }
    setCheckRequires(false)
    setErrorMessage('');

    let run_id = {};
    if (values.run_name_id) {
      let arr = []
      values.run_name_id.map((item) => {
        arr.push(`${item.value}`)
      })
      run_id.run_name = arr;
    }

    let visits = [];
    let day = [];
    if (values && values.visits) {
      values.visits.map((val) => {
        visits.push({ client_service_visit: val.client_service_visit, requirement: val.requirement, repeat_on: val.day });
        day.push(`${val.day}`);
      })
    }

    let obj = {
      csv_data: [...visits],
      ...run_id,
    };
    setLoadingSave(true);
    try {
      const response = await setVisitsDetachRun(obj);
      if (response.statusCode === 200) {
        setLoadingSave(false);
        //setSaveButtonIcon(CHECK);
        setTextStatus('SAVED');
        setValues({});
        setCheckRequires(false)
        setErrorMessage('');
        getvisits();
        setVisitDetachRunModal(false);

      }
    } catch {
      setLoadingSave(false);
      setTextStatus('ERROR');
      setSaveButtonIcon(CROSS);
    }
  }

  const handleSaveVisitDetachAllRun = async () => {
    if (!values.visits) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }

    setCheckRequires(false)
    setErrorMessage('');


    let visits = [];
    let day = [];
    if (values && values.visits) {
      values.visits.map((val) => {
        visits.push({ client_service_visit: val.client_service_visit, requirement: val.requirement, repeat_on: val.day });
        day.push(`${val.day}`);
      })
    }

    let obj = {
      csv_data: [...visits],
    };
    setLoadingSave(true);
    try {
      const response = await setVisitsDetachAllRun(obj);
      if (response.statusCode === 200) {
        setLoadingSave(false);
        setValues({});
        setCheckRequires(false)
        setErrorMessage('');
        getvisits();
        setModalDetachAll(false);

      }
    } catch {
      setLoadingSave(false);
    }
  }

  const handleSaveVisitSwapRun = async () => {
    if (!values.visits) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    if (!values.run_name) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setCheckRequires(false)
    setErrorMessage('');

    let run_id = {};
    if (values.run_name_id) {
      run_id.run_name = values.run_name_id;
    }
    let run_name = {};
    if (!values.run_name_id) {
      run_name.new_run_name = values.run_name;
    }

    let visits = [];
    let day = [];
    if (values && values.visits) {
      values.visits.map((val) => {
        visits.push({ client_service_visit: val.client_service_visit, requirement: val.requirement, repeat_on: val.day });
        day.push(`${val.day}`);
      })
    }

    let obj = {
      end_date: values.end_date,
      end_type: values.end_type,
      every: values.every,
      repeat: values.repeat,
      start_date: values.start_date,
      specific: 'run',
      end_value: values.end_value ? values.end_value : '',
      csv_data: [...visits],
      ...run_id,
      ...run_name
    };
    setLoadingSave(true);
    try {
      const response = await setVisitsSwapRun(obj);
      if (response.statusCode === 200) {
        setLoadingSave(false);
        //setSaveButtonIcon(CHECK);
        setTextStatus('SAVED');
        setValues({});
        setCheckRequires(false)
        setErrorMessage('');
        getvisits()
        setVisitSwapRunModal(false);
      }
    } catch {
      setLoadingSave(false);
      setTextStatus('ERROR');
      setSaveButtonIcon(CROSS);
    }
  }

  const saveRunData = async (values) => {
    setLoadingSave(true);
    try {
      const response = await setVisitsRun(values);
      if (response.message === "Success") {
        setLoadingSave(false);
        //setSaveButtonIcon(CHECK);
        setTextStatus('SAVED');
        setValues({});
        getvisits();
        setCheckRequires(false)
        setErrorMessage('');
        setVisitRunModal(false);
        handleChangeChoices()
      }
    } catch {
      setLoadingSave(false);
      setTextStatus('ERROR');
      setSaveButtonIcon(CROSS);
    }
  }

  const getRunName = (name) => {
    let data = '';
    if (name && dataValues) {
      dataValues.forEach(val => {
        if (name.run_name_id === val.id) {
          data = val.name
        }
      })
    }
    return data;
  }

  const dropDownHandleSelect = item => {
    //recursive objects merge
    if (item.run_name_id === 'new') {
      setNewRun(true);
      values.run_name = '';
      values.run_name_id = ''
      setValues({ ...values });
    } else {
      let result;
      if (item.role) {
        item.role_id = item.role;
      }
      item.run_name = getRunName(item)
      result = _.merge(values, item);
      //getRunById(item.run_name_id)
      setNewRun(false);
      setValues({ ...result });
    }
  };

  const dropDownHandleDetachRun = item => {
    values.run_name_id = item.run_name_id
    setValues({ ...values })
  }

  // const goto = () => {
  //   setValues({});
  //   setTextStatus('NEXT');
  //   setVisitRun('runvisits');
  // }

  // const getRunById = async (id) => {
  //   try {
  //     const response = await getRegularVisitRunById(id);
  //     if (response.message === "Success") {
  //       let val = values;
  //       setValues({ ...response.data, ...val })
  //     }
  //   } catch (err) {
  //   }

  // }


  return (
    <>
      <div>
        <div className={'p-3'} style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'scroll', height: 'calc(100vh - 140px)' }}>
          <div>
            <AddingVisitRun
              values={values}
              setValues={setValues}
              searchKeyword={searchKeyword}
              errorMessage={errorMessage}
              choices={choices}
              useInput={useInput}
              textStatus={textStatus}
              loadingSave={loadingSave}
              loadingModal={loadingModal}
              setVisitRunModal={setVisitRunModal}
              setLoadingModal={setLoadingModal}
              saveButtonIcon={saveButtonIcon}
              visitRun={visitRun}
              clientsOptions={clientsOptions}
              clientsData={clientsData}
              getvisits={getvisits}
              setClientsData={setClientsData}
              menuRef={menuRef}
              runTableOptionData={runTableOptionData}
            />
            {visitRunModal &&
              <CreateRun
                useInput={useInput}
                values={values}
                setValues={setValues}
                errorMessage={errorMessage}
                dataValues={dataValues}
                modalLoading={modalLoading}
                setModalLoading={setModalLoading}
                textStatus={textStatus}
                newRun={newRun}
                fullBind={fullBind}
                choices={choices}
                loadingSave={loadingSave}
                saveButtonIcon={saveButtonIcon}
                calculateEndValueProps={calculateEndValueProps}
                switchEndValueInput={switchEndValueInput}
                dropDownHandleRegVisit={dropDownHandleRegVisit}
                dropDownHandleSelect={dropDownHandleSelect}
                handleSaveVisitRun={handleSaveVisitRun}
              />
            }
            {visitDetachRunModal &&
              <DetachRun
                useInput={useInput}
                values={values}
                setValues={setValues}
                errorMessage={errorMessage}
                dataValues={dataValuesDetach}
                modalLoading={modalLoading}
                setModalLoading={setModalLoading}
                textStatus={textStatus}
                fullBind={fullBind}
                choices={choices}
                loadingSave={loadingSave}
                saveButtonIcon={saveButtonIcon}
                calculateEndValueProps={calculateEndValueProps}
                switchEndValueInput={switchEndValueInput}
                dropDownHandleRegVisit={dropDownHandleRegVisit}
                dropDownHandleDetachRun={dropDownHandleDetachRun}
                handleSaveVisitDetachRun={handleSaveVisitDetachRun}
              />
            }
            {visitSwapRunModal &&
              <SwapRun
                useInput={useInput}
                values={values}
                setValues={setValues}
                errorMessage={errorMessage}
                dataValues={dataValues}
                modalLoading={modalLoading}
                setModalLoading={setModalLoading}
                textStatus={textStatus}
                newRun={newRun}
                fullBind={fullBindVisitSwap}
                choices={choices}
                loadingSave={loadingSave}
                saveButtonIcon={saveButtonIcon}
                calculateEndValueProps={calculateEndValueProps}
                switchEndValueInput={switchEndValueInput}
                dropDownHandleRegVisit={dropDownHandleRegVisit}
                dropDownHandleSelect={dropDownHandleSelect}
                handleSaveVisitSwapRun={handleSaveVisitSwapRun}
              />
            }
          </div>
        </div>
        <ModalDecision
          type="detch all"
          title="Warning"
          body={
            'Are you sure you want to detach all? \n DETACHING the runname will remove the runname from database.'
          }
          subBody={
            ''
          }
          onOk={() => handleSaveVisitDetachAllRun()}
          onCancel={() => {

          }}
          okTitle={'DETACH ALL'}
          {...bindModalDetachAall}
        />
      </div>
    </>
  );
}

export default withRouter(RegularVisitModalRun);
