import React, {useState, useEffect, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import {headerColumns} from './headerColumnList';
import AddModal from 'components/DashboardComponents/Invoice/AddModal';
import {FaPlus} from 'react-icons/fa';
import {RiDeleteBinLine} from 'react-icons/ri';
import {MdRemoveRedEye} from 'react-icons/md';
import {
  ERR_COLOR,
  PURPLE,
  GRAY_700,
  WHITE,
  GRAY_400,
} from 'shared/styles/constants/colors';
import {
  getTriage,
  deleteTriageRow,
  intantNotificationData,
} from 'utils/api/AlertsApi';
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';

import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {MdEdit} from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {
  Header,
  SearchAddContainer,
  HeaderLgBreak,
  Title,
  DropdownTypesContainer,
  HeaderVisits,
  FiltersLeftGroup,
  FiltersContainer,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {LARGE_COL, EX_SMALL_COL4} from 'shared/styles/constants/columns';
import {useForm} from 'hooks/FormHook';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext, BranchItemsContext} from 'context';
import Dropdown from 'components/SharedComponents/Dropdown';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import moment from 'moment';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
import {TIMES} from 'utils/constants/icons';
import {Toast} from 'primereact/toast';
import {
  getCategoryPermission,
  getEmail,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from '../../../utils/localStorage/user';
import {
  TEAM_PERMISSION,
  TRIAGE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../utils/constants/permissions';
import {TRIAGE_FEATURES} from 'utils/constants/routes';
import NoSelectAllDropdownFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilterCustom';
import NoSelectFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilter/NoSelectFilterCustom';
import {invoice_module, triage_choices} from 'utils/choiceConstant';
import { deleteInvoice, getInvoiceList } from 'utils/api/InvoiceApi';
import { getFundersList } from 'utils/api/ClientApi';
const Invoice = props => {
  const {values, setValues, useInput} = useForm({
    ordering: '-created_at',
    limitperpage: PAGE_SIZE_RENDER,
  });
  const [triage, setTriage] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const history = useHistory();
  const {contextChoices, handleGetChoices, setContextChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const [clientName, setClientName] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);

  const [severity, setSeverity] = useState([]);
  const [TriagePrivacy, setTriagePrivacy] = useState([]);
  const [labels, setLabels] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [TriageStatus, setTriageStatus] = useState([]);
  const [type, setType] = useState([]);
  const [userList, setUserList] = useState([]);
  const [clientsnameFilter, setClientNameFilter] = useState([]);
  const [employeenameFilter, setEmployeeNameFilter] = useState([]);
  const [employeeAssignFilter, setEmployeeAssignFilter] = useState([]);
  const [severityFilter, setSeverityFilter] = useState([]);
  const [labelsFilter, setLabelsFilter] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState([]);
  const [TriageStatusFilter, setTriageStatusFilter] = useState([]);
  const [TriageStatusFilterTemp, setTriageStatusFilterTemp] = useState([]);
  const [filterExcludeCheck, setFilterExcludeCheck] = useState({status: true});

  const [typeFilter, setTypeFilter] = useState([]);
  const [callLoadTriageFirstTime, setCallLoadTriageFirstTime] = useState(true);

  const [title, setTitles] = useState('');
  const [employeeAssigned, setEmployeeAssigned] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextSubscription,
  } = useContext(AppContext);
  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  // const {
  //   setValue: setBulkTriageModalValue,
  //   value: bulkTriageModalValue,
  //   // setBulkAlertTitle,
  //   fullBind: fullBindBulkTriage,
  // } = useModal(false);

  // const {
  //   setValue: setBulkNotificationRuleModalValue,
  //   value: bulkNotificationRuleModalValue,
  //   setTitle: setBulkNotificationRuleTitle,
  //   fullBind: fullBindBulkNotificationRule,
  // } = useModal(false);

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
    const [funderList, setFunderList] = useState([])

  const [loadingModal, setLoadingModal] = useState(false);
  // useEffect(() => {
  //   handleGetChoices(triage_choices);
  // }, []);
  // useEffect(() => {
  //   setTitle('Add New Triage');

  //   let selfPermission = {};
  //   const self = contextCategoryPermissions;
  //   if (self.length > 0) {
  //     self.forEach(item => {
  //       selfPermission[item.section.name] = {};
  //       selfPermission[item.section.name][WEB_PERMISSION] = {};
  //       selfPermission[item.section.name][WEB_PERMISSION][
  //         SELF_WEB_READ_PERMISSION
  //       ] = item?.[SELF_WEB_READ_PERMISSION];

  //       selfPermission[item.section.name][WEB_PERMISSION][
  //         SELF_WEB_CREATE_PERMISSION
  //       ] = item?.[SELF_WEB_CREATE_PERMISSION];

  //       selfPermission[item.section.name][WEB_PERMISSION][
  //         SELF_WEB_UPDATE_PERMISSION
  //       ] = item?.[SELF_WEB_UPDATE_PERMISSION];

  //       selfPermission[item.section.name][WEB_PERMISSION][
  //         SELF_WEB_DELETE_PERMISSION
  //       ] = item?.[SELF_WEB_DELETE_PERMISSION];
  //     });
  //   }
  //   let rolePermissions = {};

  //   const roles = contextCategoryPermissions;
  //   if (roles.length > 0) {
  //     roles.forEach(item => {
  //       rolePermissions[item.section.name] = {};
  //       rolePermissions[item.section.name][WEB_PERMISSION] = {};
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_READ_PERMISSION
  //       ] = item?.[WEB_READ_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_CREATE_PERMISSION
  //       ] = item?.[WEB_CREATE_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_DELETE_PERMISSION
  //       ] = item?.[WEB_DELETE_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_UPDATE_PERMISSION
  //       ] = item?.[WEB_UPDATE_PERMISSION];
  //     });
  //     contextPermissions.forEach(item => {
  //       rolePermissions[item.section.name] = {};
  //       rolePermissions[item.section.name][WEB_PERMISSION] = {};
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_READ_PERMISSION
  //       ] = item?.[WEB_READ_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_CREATE_PERMISSION
  //       ] = item?.[WEB_CREATE_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_DELETE_PERMISSION
  //       ] = item?.[WEB_DELETE_PERMISSION];
  //       rolePermissions[item.section.name][WEB_PERMISSION][
  //         WEB_UPDATE_PERMISSION
  //       ] = item?.[WEB_UPDATE_PERMISSION];
  //     });
  //   }
  //   if (filterSuperAdmin) {
  //     setReadPermissions(true);
  //     setUpdatePermissions(true);
  //     setDeletePermissions(true);
  //     setCreatePermissions(true);
  //   }
  //   if (filterAdmin) {
  //     setReadPermissions(
  //       contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION]
  //     );
  //     setUpdatePermissions(true);
  //     setDeletePermissions(true);
  //     setCreatePermissions(true);
  //   }

  //   if (rolePermissions[TRIAGE_PERMISSION] !== undefined) {
  //     setCreatePermissions(
  //       (contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
  //         rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           WEB_CREATE_PERMISSION
  //         ]) ||
  //         selfPermission[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           SELF_WEB_CREATE_PERMISSION
  //         ]
  //     );
  //     setDeletePermissions(
  //       (contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
  //         rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           WEB_DELETE_PERMISSION
  //         ]) ||
  //         selfPermission[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           SELF_WEB_DELETE_PERMISSION
  //         ]
  //     );
  //     setUpdatePermissions(
  //       (contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
  //         rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           WEB_UPDATE_PERMISSION
  //         ]) ||
  //         selfPermission[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           SELF_WEB_UPDATE_PERMISSION
  //         ]
  //     );

  //     setReadPermissions(
  //       (contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
  //         rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           WEB_READ_PERMISSION
  //         ]) ||
  //         selfPermission[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
  //           SELF_WEB_READ_PERMISSION
  //         ]
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   contextIsAdmin,
  //   contextIsSuperAdmin,
  //   contextPermissions,
  //   contextCategoryPermissions,
  //   contextSubscription,
  // ]);

  // useEffect(() => {
  //   setClientName(
  //     contextChoices &&
  //       contextChoices.client_listing &&
  //       contextChoices.client_listing.map(y => {
  //         return {id: y && y.id, name: y && y.full_name};
  //       })
  //   );

  //   setEmployeeName(
  //     contextChoices &&
  //       contextChoices.employee_listing &&
  //       contextChoices.employee_listing.map(y => {
  //         return {id: y && y.id, name: y && y.full_name};
  //       })
  //   );
  //   setEmployeeAssigned(
  //     contextChoices &&
  //       contextChoices.employee_listing &&
  //       contextChoices.employee_listing.map(y => {
  //         return {id: y && y.id, name: y && y.full_name};
  //       })
  //   );
  //   setSeverity(
  //     contextChoices &&
  //       contextChoices.alert_severity &&
  //       contextChoices.alert_severity.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   setTriageStatus(
  //     contextChoices &&
  //       contextChoices.alert_status &&
  //       contextChoices.alert_status.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   let status_obj =
  //     contextChoices &&
  //     contextChoices.alert_status &&
  //     contextChoices.alert_status.filter(y => y.name == 'Resolved');
      
  //   setTriageStatusFilter(status_obj || []);
  //   setTriageStatusFilterTemp(status_obj || []);
  //   setValues({...values, alert_status: status_obj || []});

  //   setType(
  //     contextChoices &&
  //       contextChoices.alert_type &&
  //       contextChoices.alert_type.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   setLabels(
  //     contextChoices &&
  //       contextChoices.alert_label &&
  //       contextChoices.alert_label.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   setCreatedBy(
  //     contextChoices &&
  //       contextChoices.user_list &&
  //       contextChoices.user_list.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   setTriagePrivacy(
  //     contextChoices &&
  //       contextChoices.alert_privacy &&
  //       contextChoices.alert_privacy.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );

  //   setUserList(
  //     contextChoices &&
  //       contextChoices.user_list &&
  //       contextChoices.user_list.map(y => {
  //         return {id: y && y.id, name: y && y.name};
  //       })
  //   );
  // }, [contextChoices]);

  const funderLists = () => {
    getFundersList()
      .then(res => {
        if (res.length > 0) {
          let newFunders = res.map(funder => {
            return {
              id: funder.id,
              name: funder.funder_name
            }
          })
          setFunderList([...newFunders])
        }
      })
  }

  useEffect(() => {
    handleGetChoices(invoice_module);
    funderLists();
  }, []);

  useEffect(() => {
     loadInvoiceData();
  }, [
    // branchData,
    // values.alert_status,
    values.limitperpage,
    history.location.search,
    // values.ordering,
  ]);

  const toDeleteTriageRow = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const loadInvoiceData = () => {
    const pageToSearch = getPageToSearchParam(
      history.location.search,
      setCurrentPage
    );

    getInvoiceList(
      {...branchData, ...values, ...pageToSearch}
    ).then(response => {
      if (response) {
        setLoadingModal(false);
      }
      setTriage(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  const handleModal = () => {
    setModalValue(!modalValue);
  };


  const handleAddEdit = (id) => {
    setIdSelected(id || null);
    handleModal();
  };

  const handleDeleteTriage = async () => {
    await deleteInvoice(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');
    loadInvoiceData();
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
    history.push({
      pathname: `/triage`,
      state: {
        updateSelfListOfEmployee: update,
        updateAllListOfEmployee: update,
      },
    });
  };

  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/invoice',
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const filterClientName = item => {
    setClientNameFilter([...item]);
  };

  const filterEmployeeName = item => {
    setEmployeeNameFilter([...item]);
  };

  const filterEmployeeAssign = item => {
    setEmployeeAssignFilter([...item]);
  };
  const filterSeverity = item => {
    setSeverityFilter([...item]);
  };

  const filterLabels = item => {
    setLabelsFilter([...item]);
  };

  const filterCreatedBy = item => {
    setCreatedByFilter([...item]);
  };

  const filterTriageStatus = item => {
    setTriageStatusFilter([...item]);
  };

  const filterType = item => {
    setTypeFilter([...item]);
  };

  const handleSelectChange = (e, triageData, selectedIndex) => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      values.checkbox_alerdata.push({
        ...triageData,
        checkbox_rowId: triageData.id,
      });
    } else {
      values.checkbox_alerdata.map((data, index) => {
        if (data.checkbox_rowId == triageData.id) {
          values.checkbox_alerdata.splice(index, 1);
        }
      });
    }
    setValues({...values});
  };

  const handleCheck = alertsData => {
    let check = false;
    if (values.checkbox_alerdata) {
      values.checkbox_alerdata.map(data => {
        if (data.checkbox_rowId == alertsData.id) {
          check = true;
        }
      });
    }
    return check;
  };

  const handleAllSelectChange = e => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      triage.map(triageData => {
        values.checkbox_alerdata.push({
          ...triageData,
          checkbox_rowId: triageData.id,
        });
      });
    } else {
      // alerts.map((alertsData,index)=>{
      //   values.checkbox_alerdata.map(chkdata=>{
      //     if(chkdata.checkbox_rowId == alertsData.id  ){
      //       values.checkbox_alerdata.splice(index,1)
      //     }
      //   })
      // })
      values.checkbox_alerdata = [];
    }
    setValues({...values});
  };

  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = id => {
      return values.checkbox_alerdata.some(function (el) {
        return el.checkbox_rowId === id;
      });
    };

    if (values.checkbox_alerdata && values.checkbox_alerdata.length > 0) {
      triage.map(triageData => {
        if (!alertCheckBoxExists(triageData.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const resetAllCheck = () => {
    values.checkbox_alerdata = [];
    setValues({...values});
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props?.location?.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/invoice',
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const getNameClientFunder=(client, funder)=>{
    let tempName = ''
    if(funder){
       tempName = funderList.find((i)=>i.id==funder)?.funder_name
    }else{
       tempName = contextChoices?.client_listing?.find((i)=>i.id==client)?.full_name
    }
    return tempName
  }


  // const clearDatePickerFilter = (dateValue, date) => {
  //   if (date && dateValue === 'date_to') {
  //     values.date_to = '';
  //     setValues({...values});
  //     loadInvoiceData();
  //   } else if (date && dateValue === 'date_from') {
  //     values.date_from = '';
  //     setValues({...values});
  //     loadInvoiceData();
  //   }
  // };
  const toast = useRef();
  // const handleInstantNotification = async () => {
  //   let newdet = [];
  //   if (values.checkbox_alerdata) {
  //     values.checkbox_alerdata.forEach(item => {
  //       newdet.push(item.id);
  //     });
  //   }

  //   const data = {alert: newdet};

  //   let response = await intantNotificationData(data);
  //   if (response) {
  //     toast.current.show({
  //       severity: 'success',
  //       summary: 'Success',
  //       detail: 'Instant notification send successfully',
  //     });
  //   } else {
  //   }
  // };
  // const handleChangeFilterCheckbox=(e, dropdown_name)=>{
  //   setFilterExcludeCheck({...filterExcludeCheck, [dropdown_name]: e.target.checked})
  // }
  return (
    <div>
       {!modalValue &&(
        <Title>
          Invoice
        </Title>
       )}
        <Toast ref={toast} position="top-right" />
        {!modalValue &&(
      <HeaderVisits className='justify-content-end pb-1'>
       
         {/* <div className="d-flex align-items-center">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}
              style={{width: '1000px'}}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={clientName ? clientName : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({clientname: items});
                    }}
                    itemsSelected={clientsnameFilter}
                    setSelectedFilter={filterClientName}
                    placeHolder={'Client Name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={employeeName ? employeeName : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employeename: items});
                    }}
                    itemsSelected={employeenameFilter}
                    setSelectedFilter={filterEmployeeName}
                    placeHolder={' Assigned Employee'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={employeeAssigned ? employeeAssigned : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employeeassigned: items});
                    }}
                    itemsSelected={employeeAssignFilter}
                    setSelectedFilter={filterEmployeeAssign}
                    placeHolder={'Employee Name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={severity ? severity : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({severity: items});
                    }}
                    itemsSelected={severityFilter}
                    setSelectedFilter={filterSeverity}
                    placeHolder={'Severity'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectFilterCustom
                    items={TriageStatus ? TriageStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ alert_status: items });
                    }}
                    itemsSelected={TriageStatusFilter}
                    setSelectedFilter={(item) => setTriageStatusFilter([...item])}
                    placeHolder={'Triage Status'}
                    isObject
                    allOption
                    exclude={{
                      name: 'status',
                      checked: filterExcludeCheck.status || false,
                      handleChangeFilterCheckbox: handleChangeFilterCheckbox
                    }}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={type ? type : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({type: items});
                    }}
                    itemsSelected={typeFilter}
                    setSelectedFilter={filterType}
                    placeHolder={'Type'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={createdBy ? createdBy : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({created_by: items});
                    }}
                    itemsSelected={createdByFilter}
                    setSelectedFilter={filterCreatedBy}
                    placeHolder={'Created By'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <NoSelectAllDropdownFilter
                    items={labels ? labels : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({labels: items});
                    }}
                    itemsSelected={labelsFilter}
                    setSelectedFilter={filterLabels}
                    placeHolder={'Labels'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>

                <DropdownTypesContainer className="ml-1 mr-1  d-flex">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...useInput('date_from')}
                    />
                  </div>
                  <div>
                    <CloseButtonWrapper
                      onClick={() => {
                        clearDatePickerFilter('date_from', values.date_from);
                      }}>
                      {TIMES}
                    </CloseButtonWrapper>
                  </div>
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 d-flex">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      minDate={values.date_from}
                      {...useInput('date_to')}
                    />
                  </div>
                  <div>
                    <CloseButtonWrapper
                      onClick={() => {
                        clearDatePickerFilter('date_to', values.date_to);
                      }}>
                      {TIMES}
                    </CloseButtonWrapper>
                  </div>
                </DropdownTypesContainer>
                <PrimaryButtonForm minWidth="6rem" className={'mr-1'} onClick={() => {
                 loadInvoiceData()
                }}
                >
                  <span className={'mr-1'}>Search</span>
                </PrimaryButtonForm>
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div> */}
        <SearchAddContainer className="d-flex align-items-center">
          {/* {values.checkbox_alerdata && values.checkbox_alerdata.length > 0 ? (
            <div class="dropdown  mr-1">
              <PrimaryButtonForm
                minWidth="6rem"
                class="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span className={'mr-2'}>Action</span>
              </PrimaryButtonForm>
              <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  onClick={handleBulkTriage}
                  style={{cursor: 'pointer'}}>
                  Update All
                </a>
                <a
                  class="dropdown-item"
                  onClick={handleBulkNotificationRule}
                  style={{cursor: 'pointer'}}>
                  BulkNotification
                </a>
                <a
                  class="dropdown-item"
                  onClick={handleInstantNotification}
                  style={{cursor: 'pointer'}}>
                  Instant Notification
                </a>
              </div>
            </div>
          ) : null} */}
         
            <FloatingMobileButton onClick={()=>handleAddEdit()}>
              <FaPlus />
              <label>
                New Invoice
              </label>
            </FloatingMobileButton>
          
        </SearchAddContainer> 
      </HeaderVisits>
      )}
      { !modalValue && triage && (triage.length > 0) && 
      <ComponentDynamic loading={loadingModal}>
       <Table
          headerColumns={headerColumns}
          callBackQuerySort={setQuerySort}
          headerPadding={false}
          noNeed={false}
          handleAllCheck={handleAllCheck}
          handleAllSelectChange={handleAllSelectChange}>
          <div>
            {triage.map((inv, index) => {
              return (
                <div>
                  <Row bgColor>
                  
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {inv.invoice_number}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {inv.client ? 'Client' : 'Funder'}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {getNameClientFunder(inv.client, inv.funder)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {inv.issue_date}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {inv.invoice_due_date}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Center>
                        {inv.sub_total}
                    </Col>
                      <Col className="sm-hidden" Center Icon Purple>
                        <IconWrapper
                          onClick={() => {
                            handleAddEdit(inv.id);
                          }}>
                          <MdEdit />
                        </IconWrapper>
                      </Col>
                     
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteTriageRow(inv.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                  </Row>

                  <div className="mb-1" />
                </div>
              );
            })}
            <div className="mt-3">
              <Pagination
                setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                values={values}
                dropDownHandleSelect={dropDownHandleSelectPagination}
                number={PAGE_SIZE_RENDER}
                dataLength={triage.length}
              />
            </div>
          </div>
        </Table>
      </ComponentDynamic>
}

      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          setTitle={setTitle}
          contextChoices={contextChoices}
          props={props}
          loadInvoiceData={loadInvoiceData}
          setFunderList={setFunderList}
          funderList={funderList}
        />
      )}

      {/* {!bulkTriageModalValue ? null : (
        <BulkTriageModal
          type={type}
          TriageStatus={TriageStatus}
          severity={severity}
          setModalValue={setBulkTriageModalValue}
          fullBind={fullBindBulkTriage}
          loadInvoiceData={loadInvoiceData}
          triage={triage}
          contextChoices={contextChoices}
          dataTriageId={values}
          resetAllCheck={resetAllCheck}
          handleChangeChoices={props.handleChangeChoices}
        />
      )}

      {!bulkNotificationRuleModalValue ? null : (
        <BulkNotificationRule
          fullBind={fullBindBulkNotificationRule}
          loadInvoiceData={loadInvoiceData}
          triage={triage}
          contextChoices={contextChoices}
          dataTriageId={values}
          resetAllCheck={resetAllCheck}
          handleChangeChoices={props.handleChangeChoices}
          setModalValue={setBulkNotificationRuleModalValue}
        />
      )}
      */}
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Invoice? \n DELETING the invoice will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the Triage to discard instead to retain database records.'
        }
        onOk={handleDeleteTriage}
        onCancel={() => {
          setIdSelected('');
          setModalDeleteValue(false);

        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      /> 
    </div>
  );
};

export default Invoice;
