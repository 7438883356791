import React, { useEffect, useState } from 'react';
import { getSuggestedEmployee } from 'utils/api/SchedulerApi'
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './suggestedHeaders';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

import {
  PrimaryButtonForm,
} from 'shared/styles/buttons';
import {
  Row,
  Col,
} from 'components/SharedComponents/Table/styles';
const SuggestedEmployee = ({
  visit_id,
  employees,
  assignSuggestedEmployee,
}) => {
  const [totalEmployees, setTotalEmployees] = useState([])
  const [suggestedEmployees, setSuggestedEmployees] = useState([])
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    getSuggestedEmployee(visit_id)
      .then(res => {
        setLoadingModal(false)
        setTotalEmployees(res.data)
        setSuggestedEmployees(res && res?.data?.slice(0, 5))
      })
  }, [])

  const getInterval = (start, end) => {
    let tempStart = start.split(".")[0].split("T")[1].split(":").slice(0, 2).join(":")
    let tempEnd = end.split(".")[0].split("T")[1].split(":").slice(0, 2).join(":")
    let resultStr = tempStart + " - " + tempEnd
    return resultStr
  }

  const sortedSuggestedEmployee = (key) => {
    let sortedData = totalEmployees.sort((a, b) => b[key] - a[key])
    let topEmployee = sortedData.slice(0, 5)
    setSuggestedEmployees(topEmployee)
  }

  const convertHours = (decimaltime) => {
    let hrs = parseInt(Number(decimaltime));
    let min = Math.round((Number(decimaltime) - hrs) * 60);
    let clocktime = hrs + ':' + (min < 10 ? '0' : '') + min;
    return clocktime
  }

  const convertMinutes = (totalMinutes) => {
    let val = Math.sign(totalMinutes)
    let totalPlusMinutes = Math.abs(totalMinutes);
    let minutes = (parseInt(Number(totalPlusMinutes)) % 60);
    let hours = (parseInt(Number(totalPlusMinutes)) - minutes) / 60;
    let output = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    return (val < 0) ? '-' + output : '' + output
  }

  return (
    <div>
      {
        suggestedEmployees && suggestedEmployees.length > 0 &&
        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "35px" }}>
          <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className={'mr-2'}>Sort BY</span>
          </PrimaryButtonForm>
          <div style={{ cursor: "pointer" }} class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item"
              onClick={() => {
                sortedSuggestedEmployee('visitCount')
              }
              }
            >Visit Count</a>
            <a class="dropdown-item"
              onClick={() => {
                sortedSuggestedEmployee('contractedHours')
              }
              }
            >Contracted Hrs</a>
            <a class="dropdown-item"
              onClick={() => {
                sortedSuggestedEmployee('desiredHours')
              }
              }
            >Desired Hrs</a>
            <a class="dropdown-item"
              onClick={() => {
                sortedSuggestedEmployee('remainingMinutes')
              }
              }
            >Remaining Mins</a>
            <a class="dropdown-item"
              onClick={() => {
                sortedSuggestedEmployee('travelIntervalMilliSec')
              }
              }
            >Travel Duration</a>
          </div>
        </div>
      }
      <ComponentDynamic loading={loadingModal}>


        {
          suggestedEmployees?.length > 0 &&
          <>
            <Table
              headerColumns={headerColumns}
              headerPadding={true}
              noEditOption
              fontSize="0.75rem"
            //   callBackQuerySort={setQuerySort}
            >
              {suggestedEmployees.map((det, index) => {
                return (
                  <Row bgColor Shadow>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center
                    >
                      {employees.find(emp => emp.id == det.employeeId) && employees.find(emp => emp.id == det.employeeId).name}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center
                    >
                      {det.visitCount}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center
                    >
                      {det.contractedHours && convertHours(det.contractedHours)}
                    </Col>

                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center
                    >
                      {det.desiredHours && convertHours(det.desiredHours)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      {det.remainingDesiredMinutes && convertMinutes(det.remainingDesiredMinutes)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      {det.remainingMinutes && convertMinutes(det.remainingMinutes)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      {det.travelIntervalMilliSec ? convertMinutes(parseInt(det.travelIntervalMilliSec / 60000)) : ""}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      {getInterval(det.travelIntervalStart, det.travelIntervalEnd)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      {getInterval(det.assignedIntervalStart, det.assignedIntervalEnd)}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                    >
                      <PrimaryButtonForm
                        minWidth="6rem"
                        onClick={() => assignSuggestedEmployee(det.employeeId)}
                      >
                        <span className={'pl-4 pr-4'}>Assign</span>
                      </PrimaryButtonForm>
                    </Col>

                  </Row>
                )
              })}

            </Table>
          </>
        }
      </ComponentDynamic>
    </div>
  );
}

export default SuggestedEmployee;
