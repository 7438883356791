import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { AppContext, BranchItemsContext } from 'context';

import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import Table from 'components/SharedComponents/Table';
import { RiDeleteBinLine } from 'react-icons/ri';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
// import {deleteCompany, getCompany, getCompany2} from 'utils/api/SettingsApi';
import { getCompanyLicense, companyLicenseDelete } from 'utils/api/SettingsApi';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { useForm } from 'hooks/FormHook';
import Geocode from 'react-geocode';
import { Toast } from 'primereact/toast';

import _ from 'lodash';
import { MdEdit } from 'react-icons/md';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import CompanyLicensePopup from './CompanyLicensePopup';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { company_license } from 'utils/choiceConstant';

const CompanyLicense = ({
  companies,
  handleSubmit,
  location
}) => {
  const { values, setValues } = useForm({
    ...companies,
    ordering: 'name',
    limitperpage: PAGE_SIZE_RENDER,
    company_pk: companies.id
  });
  const { contextChoices, handleGetChoices, setContextChoices } = useContext(AppContext);
  const mountedRef = useRef(true);
  const history = useHistory();
  const toast = useRef();
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const [numberValue, setNumberValue] = useState();
  const [companyLicense, setCompanyLicense] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [dataCheking, setDataChecking] = useState(false);
  const [loader, setLoader] = useState(true)
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const headerColumns = [
    {
      label: 'Company',
      name: 'name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'License Expiry Date',
      name: 'licence_expiration_date',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Bank account name',
      name: 'account_name',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Bank account No.',
      name: 'account_no',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
  ];

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values });
    handleGetChoices(company_license)
  }, []);

  // useEffect(() => {
  //   const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);

  //   getCompanyLicense({ ...values, ...pageToSearch }, values.limitperpage).then(
  //     response => {
  //       setIsChecked(false);
  //       if (!mountedRef.current) return null;

  //       setCompanyLicense(response.results || []);
  //       setPages(Math.ceil(response.count / values.limitperpage));
  //     }
  //   );
  //   setIsChecked(false);
  // }, [isChecked || values.limitperpage]);
  
  // useEffect(() => {
  //   if (values.company_pk) {
  //     const urlParams = queryString.parse(location.search);
  //     urlParams[PAGINATION_PARAM] = 1;
  //     const stringified = queryString.stringify(urlParams);
  //     history.push({
  //       pathname: '/settings/company/license',
  //       search: stringified,
  //     });
  //     loadCompany();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.limitperpage]);

  useEffect(() => {
    values.company_pk = companies.id;
    setValues({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);



  // useEffect(() => {
  //   values.ordering = 'name';
  //   setNumberValue(1);
  //   setValues({ ...values });
  // }, []);

  // useEffect(() => {
  //   if (numberValue == 0) {
  //     values.ordering = '-name';
  //     setValues({ ...values });
  //   }
  // }, [numberValue]);

  // const changeNumber = value => {
  //   setNumberValue(value);
  // };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };
  const loadCompany = () => {
    const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);

    getCompanyLicense({ ...values, ...pageToSearch }, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;

        setCompanyLicense(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
        setLoader(false)
      }
    );
  };

  // const loadCompany = () => {
  //   const pageToSearch = getPageToSearchParam(location.search, setCurrentPage);
  //   getCompanyLicense({ ...values, ...pageToSearch }, values.limitperpage).then(
  //     (response) => {
  //       if (!mountedRef.current) return null;
  //       setCompanyLicense(response.results || []);
  //       setPages(Math.ceil(response.count / values.limitperpage));
  //       setLoader(false)
  //     }
  //   );
  // };

  useEffect(() => {
    if (values.company_pk) {
      loadCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.company_pk,
    location.search,
    // values.search,
    values.ordering,
    values.limitperpage,
  ]);

  const handleModal = () => {
    setModalValue(!modalValue);
  };
  const handleAddCompany = () => {
    setIdSelected('');
    handleModal();
  };

  const createAddressFormat = address => {
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const country = address.country ? `${address.country}, ` : '';
    const address_state = address.state ? `${address.state} ` : '';

    return `${line_1}${country}${address_state}`;
  };
  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const toDeleteCompany = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteBranch = async () => {
    let response = await companyLicenseDelete(idSelected);
    if (response.status == true) {
      setLoadingSave(false);

      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Deleted Successfully',
      });

      setModalDeleteValue(false)
      loadCompany()
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });

      // setIdSelected(response.id);
      setIsChecked(true);
      setModalValue(false);

      setModalDeleteValue(false);
      setIdSelected('');
      setIsChecked(true);
      loadCompany()
    }
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
  return (
    <div>
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
          <FloatingMobileButton onClick={handleAddCompany}>
            <FaPlus />
            <label> NEW COMPANY LICENSE</label>
          </FloatingMobileButton>
        </SearchAddContainer>
      </Header>
      <Toast ref={toast} position="top-right" />
      <ComponentDynamic loading={loader}>
        {companyLicense.length ? (
          <Table headerColumns={headerColumns}>
            <div>
              {companyLicense.map((item, index) => {
                return (
                  <div key={index}>
                    <Row bgColor>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => {
                          handleRowClick(item.id);
                        }}>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="sm-hidden"
                          overlap={true}>
                          <label>{item.company_name}</label>
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="align-self-center mb-0"
                          overlap={true}>
                          {item.license_expiration_date}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="sm-hidden"
                          overlap={true}>
                          <label>{item.account_name}</label>
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          className="md-hidden"
                          overlap={true}>
                          <label>{item.account_no}</label>
                        </Col>
                        {/* {(filterAdmin == true ||
                      filterSuperAdmin == true ||
                      (userStaff == true && updateBranchSettings)) && ( */}
                        <Col className="sm-hidden" Center Icon Purple>
                          <IconWrapper
                            onClick={() => {
                              handleRowClick(item.id);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                        </Col>
                      </ColsGrouper>
                      {/* {(filterAdmin == true || */}
                      {/* // filterSuperAdmin == true ||
                    // (userStaff == true && deletePermissionBranchSettings)) && ( */}
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            toDeleteCompany(item.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col>
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={companyLicense.length}
                />
              </div>
            </div>
          </Table>) :
          (<div className='w-100 d-flex justify-content-center mt-3'>
            <h4 style={{ fontSize: "1rem" }}>No data found</h4>
          </div>)}
      </ComponentDynamic>
      {!modalValue ? null : (
        <CompanyLicensePopup
          setDataChecking={setDataChecking}
          setIsChecked={setIsChecked}
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          company_pk={values.company_pk}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          loadCompany={loadCompany}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this company License? \n deleting the company License will remove all records from database.'
        }
        onOk={handleDeleteBranch}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default CompanyLicense;
