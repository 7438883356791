import React from 'react';
import {Container, StyledCheck, StyledCheckSmallSize} from './styles';

const ToggleButton = props => {

  return (
    <Container className="d-flex align-items-center">
      {props.small ?
      <StyledCheckSmallSize switch={props.switch}>
      <input
        id={props.id}
        {...props.useInput}
        disabled={props.disabled}
        type="checkbox"
        // checked={
        //   props.useInput ? (props.useInput.value ? 'checked' : '') : ''
        // }  
        checked={
          props.useInput || props.status  ? ( (props.useInput&& props.useInput.value ) || props.status ? 'checked' : '') : ''
        }
 
        onClick={props.onClick}
      />
      <span className="slider round" />
    </StyledCheckSmallSize>
    :
        <StyledCheck switch={props.switch}>
        <input
          id={props.id}
          {...props.useInput}
          disabled={props.disabled}
          type="checkbox"
          // checked={
          //   props.useInput ? (props.useInput.value ? 'checked' : '') : ''
          // }  
          checked={
            props.useInput || props.status  ? ( (props.useInput&& props.useInput.value ) || props.status ? 'checked' : '') : ''
          }
   
          onClick={props.onClick}
        />
        <span className="slider round" />
      </StyledCheck>}

      <span htmlFor={props.id}>{props.label}</span>
    </Container>
  );
};

export default ToggleButton;
