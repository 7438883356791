import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {PAGE_404, AUTH, LOGIN, FORGOT_PASSWORD,VERIFICATION, RESET_PASSWORD, RESET_PIN} from 'utils/constants/routes';
import Login from 'components/AuthComponents/Login';
import ForgotPassword from 'components/AuthComponents/ForgotPassword';
import {Wrapper} from './styles';
import VerifyEmail from "../VerifyEmail";
import ResetPassword from "../ResetPassword";
import ResetPin from '../ResetPin';

function AuthRouter() {
  return (
    <Wrapper>
      <Switch>
        <Route exact path={AUTH + LOGIN} component={Login} />
        <Route exact path={AUTH + FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={AUTH + VERIFICATION +'/:token'} component={VerifyEmail} />
        <Route exact path={AUTH + RESET_PASSWORD +'/:token'} component={ResetPassword} />
        <Route exact path={AUTH + RESET_PIN +'/:token'} component={ResetPin} />
        <Route exact path={AUTH} component={Login} />

        <Redirect
          to={{
            pathname: PAGE_404,
            state: {referrer: AUTH + LOGIN},
          }}
        />
      </Switch>
    </Wrapper>
  );
}

export default AuthRouter;
