import ModalDynamic from 'components/DashboardComponents/ModalDynamic'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import React from 'react'
import { useState } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { FLOPPY } from 'utils/constants/icons'

function UpdateModal({
    fullBind,
    choices,
    values,
    setValues,
    useInput,
    handleUpdate,
    readOnly,
    loadingSave
}) {

    const dropDownHandleSelect = (item, name) => {
        console.log(item, name, "drop value")
        if (name == 'branch') {
            values.competencyMatrix.branch = item.branch;
        }
        else if (name == "competency") {
            values.competencyMatrix.competency = item.competency
        }
        setValues({ ...values });
    }
    console.log(readOnly, "update")

    return (
        <ModalContainer max_width={'60%'}>
            <ModalDynamic {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div>
                    <div className="d-flex flex-column mt-2">

                        <div className='m-2'>
                            <DropdownSearchable
                                required
                                {...useInput(`competencyMatrix.branch`, 'isRequired')}
                                placeHolder={'Branch'}
                                  disabled={readOnly}
                                options={choices.branch}
                                selectedOption={values.competencyMatrix.branch ? values.competencyMatrix.branch : null}
                                // {...useInput(`branch`, 'isRequired')}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { branch: value ? value : null },
                                        'branch'
                                    )
                                }
                            />
                        </div>

                        <div className='m-2'>
                            <DropdownSearchable
                                required
                                {...useInput(`competencyMatrix.competency`, 'isRequired')}
                                placeHolder={'Competency'}
                                  disabled={readOnly}
                                options={choices.competency_name}
                                selectedOption={values.competencyMatrix.competency ? values.competencyMatrix.competency : null}
                                // {...useInput(`branch`, 'isRequired')}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { competency: value ? value : null },
                                        'competency'
                                    )}
                            />
                        </div>
                    </div>

                    <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
                        <div className={'ml-0 ml-md-3 mr-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={() => handleUpdate()}
                                style={{ marginBottom: '5px' }}>
                                <span className={'mr-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalDynamic>
        </ModalContainer>
    )
}

export default UpdateModal