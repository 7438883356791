import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED,InvalidOrEmptyValues} from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { NLWSUBMIT,setNLWRLWById} from 'utils/api/SettingsApi';
import DateSelector from 'components/SharedComponents/DateSelector';
// import {AppContext} from "context"

const AddMoal = ({
  fullBind,
  idSelected,
  setTitle,
  loadPublicHoliday,
  setPublicHolidayModalValue,
  contextChoices,
  countryData,
  rateSource,
}) => {

  const {
    values, setValues, useInput,isValid, errors,  setFormErrors,setCheckRequires
  } = useForm({  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);   
  const [ageErorMEsg, setAgeErorMEsg] = useState(null);
  const [regionErorMEsg, setRegionErorMEsg] = useState(null);
const [rateCode, setRateCode] = useState([]);
const [region, setRegion] = useState([]);
const edit_nlw = 'EDIT NLW/RLW RATE CARD';
const new_nlw = 'NEW NLW/RLW RATE CARD';


  useEffect(() => {
    if (idSelected) {
      setTitle(edit_nlw);
    } else {
      setTitle(new_nlw);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);


  useEffect(()=>{ 
  setRateCode(contextChoices && contextChoices.rate_code && contextChoices.rate_code.map(y=>{
      return{ id: y.id  ,  name: y.name}
  }) )  
  setRegion(contextChoices && contextChoices.region && contextChoices.region.map(y=>{
    return{ id: y.id  ,  name: y.name}
}) ) 
  },[contextChoices])


  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


useEffect(()=>{
  if (idSelected) {
    loadNLWRLWById();
  }
},[])



  const loadNLWRLWById = async() => {
    const response =await setNLWRLWById(idSelected)
    if(response){
      values.id=response.id;
      values.wages_type=response.wages_type;
      values.wages_rate_description=response.wages_rate_description;
      values.age_from=response.age_from;
      values.age_to=response.age_to;
      values.effective_date_from=response.effective_date_from;
      values.effective_date_to=response.effective_date_to;
      values.rate_per_hour=response.rate_per_hour;
      values.regions =response.regions?.map(x=>{return{id:x.id,wages_rate:x.wages_rate,value: x.regions, label:contextChoices.region && contextChoices.region.find(w=>w.id ===x.regions).name}}); 
      // values.regions=response.regions?.map(x=>{return{value: x.regions, label:contextChoices.region && contextChoices.region.find(w=>w.id ===x.regions).name}}); 

    setValues({...values});
    }
    setLoadingModal(false);   
  };

  useEffect(()=>{
    if(parseInt(values.age_from) > parseInt(values.age_to)){
      setAgeErorMEsg('age from should be less than age to')
      setShowError(true);
      setErrorMessage('age from should be less than age to');
    }else if(parseInt(values.age_from) < parseInt(values.age_to)){
      setAgeErorMEsg(null);
      setShowError(false);
      setErrorMessage(null);
    }

  },[ values.age_from , values.age_to])


  useEffect(()=>{
    if( values.regions?.length==0){
      setRegionErorMEsg('please fill the regions field')
    } else if(values.regions?.length>0){
      setRegionErorMEsg(null)
    }

  },[values.regions])



  const handleSubmit = async (evt) => {
    let allGood=false;
      evt.preventDefault();
      if (loadingSave) {
        return;
      }

      if(parseInt(values.age_from) > parseInt(values.age_to)){
        setShowError(true);
        allGood=true
        setErrorMessage('age from should be less than age to');
        return;
      }


      if(values.effective_date_from && values.effective_date_to){
              if(values.effective_date_from > values.effective_date_to){
        setShowError(true);
        allGood=true
        setErrorMessage('effective date from should be less than effective date to');
        return;
      }
    }


    if(!values.wages_type || !values.age_from || !values.age_from || !values.age_to || !values.age_from  || !values.effective_date_from || !values.rate_per_hour ){
      setCheckRequires(true);
      setShowError(true);
      allGood=true
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    if(values.regions?.length==0 ){
      setCheckRequires(true);
      setShowError(true);
      allGood=true
      setErrorMessage(InvalidOrEmptyValues);
      return;
    } 

      if(allGood){
        return
      }

    if (textStatus==='SAVED') {    return; }
    setLoadingSave(true);
      const bodyData=  values.id ? {
        wages_type:values.wages_type,
        wages_rate_description: values.wages_rate_description,
        age_from: values.age_from,
        age_to: values.age_to,
        regions: values.regions.map(x=>{return{id:x.id,regions:x.value}}),
        effective_date_from: values.effective_date_from,
        effective_date_to: values.effective_date_to,
        rate_per_hour: parseFloat(values.rate_per_hour?values.rate_per_hour:0).toFixed(2),
      }:{
        wages_type:values.wages_type,
        wages_rate_description: values.wages_rate_description,
        age_from: values.age_from,
        age_to: values.age_to,
        regions: values.regions.map(data=>data.value),
        effective_date_from: values.effective_date_from,
        effective_date_to: values.effective_date_to,
        rate_per_hour:parseFloat(values.rate_per_hour?values.rate_per_hour:0).toFixed(2),
      }
 
     await  NLWSUBMIT(values,bodyData)
     .then(res=>{
       if( res){ 
          onSuccess()
          setPublicHolidayModalValue(false)
          }
  else{  onError()  }
     })
     .catch(onError)
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    loadPublicHoliday()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setShowError(true);
    setCheckRequires(true);
      setErrorMessage(
        "Some of the fields have an invalid or empty values. Please review."
      );
  };

 const dropdownHandleMultiSelect = preferred => {
  setValues({ ...values, ...preferred });
};


const dropDownHandleRate = (item, nameItem) => {
       values[nameItem] = item[nameItem];
      setValues({...values})
    };

  return (
    <ModalContainer max_width={'70%'}>      
      <ModalDynamic
        {...fullBind}
        max_width={'70%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="pl-3 pr-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100 mb-2 pb-1'}>
          <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
          <DropdownSearchable
            required
            {...useInput( `wages_type`,'isRequired' )}
         placeHolder={'Rate source'}
         options={rateSource}
          selectedOption={
             values.wages_type ? values.wages_type : null
                }
                onChange={value =>
                  dropDownHandleRate(
                    {wages_type: value ? value : null},
                    'wages_type', 'isRequired'
                  )
                }

              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 "  >          
                 <InputLabelTop
                // disabled={true}
              label={'Wage Rate Description'}
                type="text"
                {...useInput(`wages_rate_description`, 'isRequired')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >
            <InputLabelTop
             required
              label={'Age from'}
                type="text"
                {...useInput(`age_from`, 'isRequired')}
              />
              <small style={{color:"red",font:"0.01px"}}><small>{ageErorMEsg}</small></small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1 " >
                  <InputLabelTop
            required
              label={'Age to'}
                type="text"
                {...useInput(`age_to`, 'isRequired')}
              />
          <small style={{color:"red",font:"0.1px,"}}><small>{ageErorMEsg}</small></small>
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >
            <DateSelector
            isRequired
              id="datefrom"
              labelTop={'Effective Date from'}
              {...useInput(`effective_date_from`, 'isRequired')}
            />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >
            <DateSelector
              id="dateto"
              labelTop={'Effective Date to'}
              {...useInput(`effective_date_to`)}
            />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 "  >          
            <InputLabelTop
              required
               type="number"
                 id="rate_per_hours"
             label={'Rate/hr'}
             {...useInput(`rate_per_hour`, 'isRequired')}
             maxlength={5}
                    />   
            </FlexGrid3Item>
       
    <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1"  ResizeWidth={"0 0 66.666666%"}> 
      
          <DropdownSearchable
              required
              {...useInput(`regions`,  'isRequired' )}
             width={'100%'}
             placeHolder={'Region'}
              options={region}
            isMulti={true}
             selectedOptionsProps={
            values && values.regions && values.regions }
          onChange={value =>
           dropdownHandleMultiSelect( { regions: value.length ? value : [] },
            'regions', 'isRequired' ) }
              />
   <small style={{color:"red",font:"0.01px"}}><small>{regionErorMEsg}</small></small>

            </FlexGrid3Item>

            
            </FlexGrid3Container >
          
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2 mt-1'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMoal;
