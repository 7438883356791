import React from 'react';
import {PURPLE, SOFT_RED, GRAY_300} from 'shared/styles/constants/colors';
import {IconWrapper} from 'shared/styles/constants/tagsStyles';
import {RiDeleteBinLine, RiSaveLine} from 'react-icons/ri';
import {MdEdit, MdCheck, MdClose} from 'react-icons/md';
import {TiWarningOutline} from 'react-icons/ti';
import {
  ON_SAVE,
  ON_EDIT,
  ON_SUCCESS,
  ON_ERROR,
  ON_LOADING,
  ON_DELETE,
  ON_REMOVE,
  ON_CANCEL,
} from 'utils/constants/settingsStates';
import {MdSettings} from 'react-icons/md';
import {SettingsIcon} from './styles';

export const getLeftButton = (
  leftButtonIcon,
  executeHandleSubmit,
  toEditId,
  dataValue,
  enabledInput,
  tryReSubmit,values
) => {
  
  let icon = <IconWrapper />;

  if (dataValue.id && enabledInput !== dataValue.id) {
    icon = (
      <IconWrapper onClick={() => toEditId(dataValue.id)}>
        <MdEdit color={PURPLE} />
      </IconWrapper>
    );
  }

  if (!dataValue.id) {
    icon = (
      <IconWrapper onClick={() => executeHandleSubmit()}>
        <RiSaveLine color={PURPLE} />
      </IconWrapper>
    );
  }

  if (enabledInput === dataValue.id) {
    switch (leftButtonIcon) {
      case ON_EDIT:
        icon = (
          <IconWrapper onClick={() => toEditId(dataValue.id)}>
            <MdEdit color={PURPLE} />
          </IconWrapper>
        );
        break;
      case ON_SAVE:
        icon = (
          <IconWrapper onClick={() => executeHandleSubmit()}>
            <RiSaveLine color={PURPLE} />
          </IconWrapper>
        );
        break;
      case ON_LOADING:
        icon = (
          <IconWrapper>
            <span
              className="spinner-border spinner-border-sm text-secondary"
              role="status"
            />
          </IconWrapper>
        );
        break;
      case ON_SUCCESS:
        icon = (
          <IconWrapper>
            <MdCheck color={PURPLE} />
          </IconWrapper>
        );
        break;
      case ON_ERROR:
        icon = (
          <IconWrapper onClick={() => tryReSubmit(dataValue.id)}>
            <TiWarningOutline color={SOFT_RED} />
          </IconWrapper>
        );
        break;
      default:
        icon = (
          <IconWrapper onClick={() => toEditId(dataValue.id)}>
            <MdEdit color={PURPLE} />
          </IconWrapper>
        );
    }
  }

  return icon;
};

export const getRightButton = (
  rightButtonIcon,
  dataValue,
  removeNewService,
  executeCancel,
  toDeleteId,
  enabledInput,
  specializeCase,
) => {
  let icon = <IconWrapper />;

  icon = (
    <IconWrapper
      onClick={() => {
        let sendValue = dataValue.id;
        if (specializeCase && specializeCase === 'role') {
          sendValue = dataValue;
        }

        toDeleteId(sendValue);
      }}>
      <RiDeleteBinLine color={SOFT_RED} />
    </IconWrapper>
  );

  if (enabledInput === dataValue.id) {
    switch (rightButtonIcon) {
      case ON_CANCEL:
        icon = (
          <IconWrapper onClick={executeCancel}>
            <MdClose color={SOFT_RED} />
          </IconWrapper>
        );
        break;
      case ON_REMOVE:
        icon = (
          <IconWrapper onClick={() => removeNewService(dataValue.id)}>
            <MdClose color={SOFT_RED} />
          </IconWrapper>
        );
        break;
      case ON_DELETE:
        icon = (
          <IconWrapper onClick={() => toDeleteId(dataValue.id)}>
            <RiDeleteBinLine color={SOFT_RED} />
          </IconWrapper>
        );
        break;
      default:
        icon = (
          <IconWrapper onClick={() => toDeleteId(dataValue.id)}>
            <RiDeleteBinLine color={SOFT_RED} />
          </IconWrapper>
        );
    }
  }

  return icon;
};

export const SpecializeCaseButton = (
  specializeCase,
  specializedMethods,
  dataValue,
) => {
  const selectedRol = specializedMethods
    ? specializedMethods.selectedRol.id
    : '';
  let icon = null;

  if (specializeCase && specializeCase === 'role' && dataValue.id) {
    icon = (
      <SettingsIcon
        onClick={() => {
          if (dataValue.id) {
            specializedMethods.changeRol(dataValue);
          }
        }}>
        <MdSettings
          size={20}
          color={selectedRol === dataValue.id ? SOFT_RED : PURPLE}
        />
      </SettingsIcon>
    );
  } else if (specializeCase && specializeCase === 'role' && !dataValue.id) {
    icon = (
      <div>
        <MdSettings size={20} color={GRAY_300} />
      </div>
    );
  }
  return icon;
};
