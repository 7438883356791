import React, { Fragment, useState, useEffect } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { TiArrowBackOutline } from 'react-icons/ti';
import ModalContacts from "./ModalContacts"
import { useForm } from 'hooks/FormHook';
import { pickFile } from 'utils/files';
import { headerColumns } from './headerColumnsList';
import Table from 'components/SharedComponents/Table';
import { RiDeleteBinLine } from 'react-icons/ri';
import _ from 'lodash'
import moment from 'moment';
import { LARGE_COL } from 'shared/styles/constants/columns';
import {
  setClientImpContact, getClientsContracts,
  getClientsContactById, deleteServiceContact, getImpContacts
} from 'utils/api/ClientApi'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { getPageToSearchParam } from 'shared/methods';
import {
  Row,
  Col,
  IconWrapper,
  UserImgContainer,
  UserImg,
} from 'components/SharedComponents/Table/styles';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import Image from 'components/SharedComponents/Image';
import { useModal } from 'hooks/ModalHook';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from 'components/SharedComponents/Pagination';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { AppContext } from 'context';
import { useContext } from 'react';
import { important_contact_choices } from 'utils/choiceConstant';
import ToggleButton from 'components/SharedComponents/ToggleButton';
const ImportantContacts = ({
  choices,
  selectedClientId,
  //cliId,
  readPermissionImportantContact,
  createPermissionImportantContact,
  updatePermissionImportantContact,
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [importantContacts, setImportantContacts] = useState([]);
  const [modalValue, setModalValue] = useState(false);
  const [nokPhoto, setNokPhoto] = useState(null);
  const [pages, setPages] = useState(0);
  const [idSelected, setIdSelected] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [simplePagination] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [modalLoading, setModalLoading] = useState(true)
  const [impContactList, setImpContactList] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const NEW_CONTACT = 'new';
  const history = useHistory()
  const location = useLocation()
  const { handleGetChoices } = useContext(AppContext)


  useEffect(() => {
    handleGetChoices(important_contact_choices)
  }, [])

  useEffect(() => {
    loadContactList()
  }, [])

  const loadContactList = () => {
    getImpContacts()
      .then(res => {
        if (res.length > 0) {
          let newContacts = res.map(contact => {
            return {
              id: contact.id,
              name: contact.full_name
            }
          })
          setImpContactList([...newContacts])
        }
      })
  }

  useEffect(() => {
    if (selectedClientId) {
      getClientAllContacts();
    }
    else {
      setModalLoading(false)
    }
  }, [selectedClientId, currentPage])
  useEffect(() => {
    if (idSelected) {
      searchClientContact();
      setShowError(false);
      setErrorMessage('');
    }
  }, [idSelected])
  const handleModal = event => {
    setModalValue(!modalValue);
    setIdSelected('');
    setValues({ type: 'Personal', is_self_contact: 2 })

    if (event === NEW_CONTACT) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    } else {
      getClientAllContacts();
    }
  };
  const searchClientContact = async () => {
    //let clientId=cliId?cliId:selectedClientId
    const response = await getClientsContactById(selectedClientId, idSelected);

    if (response) {
      if (response.is_nok === true) {
        response.is_nok = 1
      }
      if (response.is_nok === false) {
        response.is_nok = 2
      }
      if (response.lasting_power_of_attorney === true) {
        response.lasting_power_of_attorney = 1
      }
      if (response.lasting_power_of_attorney === false) {
        response.lasting_power_of_attorney = 2
      }
      if (response.health_wellbeing === true) {
        response.health_wellbeing = 1
      }
      if (response.full_name) {
        if (response.is_self_contact === true) {
          response.full_name = impContactList.find(contact => contact.name == response.full_name).id
        }
      }
      if (response.health_wellbeing === false) {
        response.health_wellbeing = 2
      }
      if (response.financial === true) {
        response.financial = 1
      }
      if (response.financial === false) {
        response.financial = 2
      }
      if (response.is_self_contact === true) {
        response.is_self_contact = 1
      }
      if (response.is_self_contact === false) {
        response.is_self_contact = 2
      }

      if (response.is_client_data_access_allowed === true) {
        response.is_client_data_access_allowed = 1
      }
      if (response.is_client_data_access_allowed === false) {
        response.is_client_data_access_allowed = 2
      }
      if (response.role) {
        response.make_user = true
        response.permission_right = response.role
      }
    }

    values.rota_email = response?.rota_email
    values.type = response?.is_professional_contact_type ? "Professional" : "Personal"
    setValues({
      ...values,
      ...response
    });
    console.log(response, "response")
  };
  const getClientAllContacts = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getClientsContracts({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    setImportantContacts(response.results || []);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    setModalLoading(false)
  };
  const handleDeleteContact = async () => {
    try {
      // let clientId=cliId?cliId:selectedClientId
      await deleteServiceContact(selectedClientId, deleteId);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      getClientAllContacts();
      setDeleteId('')
    }
  };
  const toDeleteContact = id => {
    setModalDeleteValue(true);
    setDeleteId(id);
  };
  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const handleSubmit = (
    evt,
    saveTrigger = true,
  ) => {
    evt.preventDefault();

    if (loadingSave) {
      return;
    }

    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = _.cloneDeep(values)
    body.is_professional_contact_type = (body.type === 'Professional');
    if (body.rota_email === undefined) {
      body.rota_email = false
    }
    if (body.is_nok) {
      if (body.is_nok == 1) {
        body.is_nok = true
      }
      if (body.is_nok == 2) {
        body.is_nok = false
      }
    }

    if (body.lasting_power_of_attorney) {
      if (body.lasting_power_of_attorney == 1) {
        body.lasting_power_of_attorney = true
      }
      if (body.lasting_power_of_attorney == 2) {
        body.lasting_power_of_attorney = false
      }
    }
    if (body.health_wellbeing) {
      if (body.health_wellbeing == 1) {
        body.health_wellbeing = true
      }
      if (body.health_wellbeing == 2) {
        body.health_wellbeing = false
      }
    }
    if (body.financial) {
      if (body.financial == 1) {
        body.financial = true
      }
      if (body.financial == 2) {
        body.financial = false
      }
    }
    if (body.is_client_data_access_allowed) {
      if (body.is_client_data_access_allowed == 1) {
        body.is_client_data_access_allowed = true
      }
      if (body.is_client_data_access_allowed == 2) {
        body.is_client_data_access_allowed = false
      }
    }
    if (body.is_self_contact) {
      if (body.is_self_contact == 1) {
        body.is_self_contact = true
      }
      if (body.is_self_contact == 2) {
        body.is_self_contact = false
      }
    }
    if (body.full_name) {
      if (body.is_self_contact == 1) {
        body.full_name = impContactList.find(contact => contact.id == body.full_name).name
      }
    }
    if (nokPhoto) {
      body.photo = nokPhoto;
    } else if (body.photo) {
      delete body.photo;
    }
    if (body.make_user) {
      delete body.make_user
    }
    if (saveTrigger) {
      setLoadingSave(true);
    }

    let value = [];
    // let clientId=''
    // if(location.pathname.includes('/client/add')){
    //   clientId=cliId
    // }else{
    //   clientId=selectedClientId
    // }
    body.client = selectedClientId

    setClientImpContact(body, selectedClientId)
      .then(response => {
        if (((response.status === 400) || response.status === 'Fail')) {
          if (response?.message) {
            value = response?.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    if (response.status === 200 || response.status === 201) {
      let contactId = response?.message?.id;
      // if employee id

      setValues({
        ...values,
        id: contactId,
      });
      setLoadingSave(false);
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };
  const onError = err => {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
    );
  };
  const handlePickFile = async (evt, section) => {
    const result = await pickFile(evt);

    switch (section) {
      case 'nok':
        setNokPhoto(result.file);
        break;
      default:
    }
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getName = (key, id) => {
    if (choices && choices[key] && choices[key].find(data => data.id == id)
      && choices[key].find(data => data.id == id).name)
      return choices[key].find(data => data.id == id).name
  }
  const getRoleName = (id) => {
    if (choices && choices.roleList && choices.roleList.find(data => data.id == id)
      && choices.roleList.find(data => data.id == id).name)
      return choices.roleList.find(data => data.id == id).name
  }


  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    if (item.role) {
      item.role_id = item.role;
    }
    if (item.is_self_contact) {
      values.full_name = null
    }
    result = _.merge(values, item);

    setValues({ ...result });
  };
  return (
    <ComponentDynamic
      loading={modalLoading}
    > {(selectedClientId ? readPermissionImportantContact : createPermissionImportantContact) ?
      <>
        <div>
          {!modalValue ? (

            <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
              <div className={'text-primary font-weight-bold'}>
                <div>
                  {(selectedClientId ? updatePermissionImportantContact : createPermissionImportantContact) &&
                    <PrimaryButtonForm
                      minWidth="6rem"
                      onClick={() => handleModal(NEW_CONTACT)}
                    >
                      <BsFolderPlus size={18} />
                      <span className={'ml-2'}>ADD IMPORTANT CONTACT</span>
                    </PrimaryButtonForm>}
                </div>

              </div>

            </div>

          ) : (
            <Fragment />
          )}
          {modalValue ? (
            <div className={'w-100 d-flex justify-content-center'}>
              <div>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={handleModal}
                >
                  <div className={'d-flex align-items-center'}>
                    <TiArrowBackOutline size={24} />
                    <div>BACK</div>
                  </div>
                </PrimaryButtonForm>
              </div>
            </div>
          ) : (
            <Fragment />
          )}
          {importantContacts.length >= 1 && !modalValue ? (
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                noEditOption
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>
                <div>
                  {importantContacts.map((importantContact, index) => {
                    // const daysSelected = getDaysSelected(importantContact.visits);
                    return (
                      <div key={index}>
                        <Row bgColor Shadow>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            <UserImgContainer>
                              <UserImg>
                                <Image
                                  src={importantContact.photo ? importantContact.photo : ''}
                                  name={importantContact.full_name}
                                  lastName={importantContact.full_name ?
                                    importantContact.full_name.split(" ")[1] ?
                                      importantContact.full_name.split(" ")[1] : "" : ""
                                  }
                                  alt={'employee-img'}
                                  fontSize={'0.9rem'}
                                />
                              </UserImg>
                            </UserImgContainer>
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.is_professional_contact_type ? "Professional" : "Personal"}
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.is_nok ? "Yes" : "No"}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.lasting_power_of_attorney ? "Yes" : "No"}
                          </Col>
                          <Col
                            className=''
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.full_name ? importantContact.full_name : ""}
                          </Col>
                          <Col
                            className='md-hidden sm-hidden'
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.relation_ship ?
                              getName('nok_relationship', importantContact.relation_ship) : ""
                            }
                          </Col>
                          <Col
                            className='md-hidden sm-hidden'
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.relation_ship ?
                              getName('contact_status', importantContact.contact_status) : ""
                            }
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.email ? importantContact.email : ""}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.mobile ? importantContact.mobile : ""}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.role ?
                              getRoleName(importantContact.role) : ""
                            }
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(importantContact.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >
                            {importantContact.rota_email ? "Yes" : "No"}
                          </Col>
                          {/* {  (deletePermission)   &&   (   */}
                          <Col
                            Center
                            Shrink
                            reducePadding
                            Icon
                            className=""
                          >
                            <IconWrapper
                              onClick={() => {
                                toDeleteContact(importantContact.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </Col>
                          {/* // )} */}
                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}
                  <div className="mt-3">
                    <Pagination
                      simplePagination={simplePagination}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                    />
                  </div>
                </div>

              </Table>
              <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      history.push('/client')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <Fragment />
              {!modalValue &&
                <div
                  className={'d-flex justify-content-center justify-content-md-end'}>
                  <div>
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'mr-2'}
                      onClick={() => {
                        history.push('/client')
                      }}>
                      <span className={'mr-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm>
                  </div>
                </div>
              }
            </>
          )}
          {modalValue ? (
            <ModalContacts
              useInput={useInput}
              values={values}
              choices={choices}
              handlePickFile={handlePickFile}
              nokPhoto={nokPhoto}
              dropDownHandleSelect={dropDownHandleSelect}
              setValues={setValues}
              showError={showError}
              errorMessage={errorMessage}
              handleSubmit={handleSubmit}
              impContactList={impContactList}
              textStatus={textStatus}
              loadingSave={loadingSave}
              buttonIcon={buttonIcon}
            />
          ) : null}

        </div>
        <div>
          <ModalDecision
            type="delete"
            title="Warning"
            body={
              'Are you sure you want to delete this contact? \n DELETING the contact will remove ALL records from database.'
            }
            // subBody={
            //   'TIP (Recommended) - you could change the contact status to discard instead to retain database records.'
            // }
            onOk={handleDeleteContact}
            onCancel={() => {
              setDeleteId('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </div>
      </>
      :
      <NoPermissionContainer>
        You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
      </NoPermissionContainer>
      }
    </ComponentDynamic>
  );
}

export default ImportantContacts;
