import styled from 'styled-components';
import {
  PURPLE,
  GRADIENT_GREEN,
  GRAY_200,
  WHITE,
  GRAY_400,
  GRAY_300,
} from 'shared/styles/constants/colors';
import {SM, MD, XXL} from 'shared/styles/constants/resolutions';

export const WeekContainer = styled.div`
  display: flex;

  .formContainer {
    width: 66.5%;
    max-width: 66.5%;
  }

  .formWeek {
    width: 33.5%;
    max-width: 33.5%;
  }

  @media (max-width: ${XXL}) {
    display: block !important;

    .formContainer {
      width: 100%;
      min-width: 100%;
    }

    .formWeek {
      width: 100%;
      max-width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export const CustomButtonWeek = styled.div`

  width: 3.5rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-right: 0.2rem;
  background-color: ${props => (props.add_background ? PURPLE : GRAY_300)};
  
  label {
    padding-bottom:2px;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${props => (props.Active ? 'white' : '#8e8e8e')};

  }
 


  div {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 3rem;
    background: ${props =>
      props.add_background
        ? `linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})`
        : GRAY_200};
  }

  @media (max-width: ${SM}) {
    div {
      width: 2rem;
      max-width: 2rem;

      label {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${MD}) {
    div {
      width: 4rem;
      max-width: 4rem;

      label {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${XXL}) {
    div {
      max-width: 2.5rem;

      label {
        text-align: center;
        align-items: center;
        width: 10px;
        max-width: 10px;
        word-wrap: break-word;
      }
    }
  }
`;

export const WeekStyle = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;   

  @media (min-width: calc(${MD} + 20rem)) {
    padding-left: 1rem;
    padding-top: 0rem;
  }
`;

export const ButtonWeek = styled.div`
  label {
    padding: 0px !important;
    margin: 0px !important;
    color: ${(props) => (props.add_background ? WHITE : GRAY_400)};
    cursor: pointer;
    font-weight: bold;
  }

  div {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 3rem;
    background: ${(props) =>
      props.add_background
        ? `linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})`
        : GRAY_200};
  }

  @media (max-width: ${SM}) {
    div {
      width: 2rem;
      max-width: 2rem;

      label {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${MD}) {
    div {
      width: 4rem;
      max-width: 4rem;

      label {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${XXL}) {
    div {
      max-width: 2.5rem;

      label {
        text-align: center;
        align-items: center;
        width: 10px;
        max-width: 10px;
        word-wrap: break-word;
      }
    }
  }
`;
