import React, {Fragment} from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {AppContext, BranchItemsContext} from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PrimaryButtonModal} from 'components/SharedComponents/ModalDecision/styles';
import {Title, TitleSection} from 'shared/styles/constants/tagsStyles';
import {IconStyle} from 'components/SharedComponents/ModalInformation/styles';
import {CancelButton} from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import {Toast} from 'primereact/toast';
import {MessageOptaPlanner} from 'components/DashboardComponents/Scheduler/styles';
import { setClientContract } from 'utils/api/ClientApi';
import {IoIosArrowDown, IoIosArrowDroprightCircle, IoIosArrowUp} from 'react-icons/io';
import _ from "lodash"
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';
import {CHECK_CIRCLE} from 'utils/constants/icons';
import PropTypes from 'prop-types';
import {red} from '@mui/material/colors';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import { PURPLE } from 'shared/styles/constants/colors';
import { Col } from 'components/SharedComponents/Table/styles';
const ConfirmationModalSave = ({
  fullBind,
  setModalValue,
  values,
  checkBeforeSend,
  toDeleteVisits,
  toDeleteSplitFunder,
  toDeleteVisitSkill,
  toDeleteVisitEmployee,
  type,
  icon,
  visitToUpdateData,
  visitToCreateData,
  visitToDeleteData,
  loadingSave,
  setLoadingSave
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {contextChoices} = useContext(AppContext);
  const [newDetails, setDetails] = useState([]);
  const [fullDay ,setFullDay]=useState("Full Day")
  const [HideUpdate, setHideUpdate] = useState(true);
  const [HideCreate, setHideCreate] = useState(true);
  const [HideDelete, setHideDelete] = useState(true);

  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: '',
    });
  }, [fullBind]);
  useEffect(() => {
    handleData();
  }, []);

  const handleAccordionClick = (type) => {
    if(type == 'update'){
      setHideUpdate(!HideUpdate)
    }else if(type == 'create'){
      setHideCreate(!HideCreate)
    }else{
      setHideDelete(!HideDelete)
    }
  }
  
  const handleToast = async() => {

    
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Data Updated Successfully',
    });
      setTimeout(() => {
        setModalValue(false);
      }, 1000);
    }
    
  
  const moment = require('moment');
  const today = moment();
  let date = today.format().substring(0, 10);
  const handleData = () => {
    let newData =values.visits &&values.visits.map(item => {
        let categoryName =
          item.service_category && contextChoices.service_category
            ? contextChoices.service_category.find(element => {
                if (element.id == item.service_category) {
                  return element;
                }
              })
            : '';
        return {
          preferredStart: item.preferred_start_time?item.preferred_start_time.toString().substring(0, 5):item.full_day ==true&&fullDay,
          preferredEnd: item.preferred_end_time&&item.preferred_end_time.toString().substring(0, 5),
          EffectiveDate: item.effective_date ? item.effective_date : date,
          service_category: item.service_category,
          service_category_name: categoryName ? categoryName.name : '',
          
          
        };
      });
    setDetails(newData)
  };

 
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'60%'} max_width={'80%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              <h5>
                This action will update, create and delete some visits data on the location service contract. Do you confirm for the
                changes?
              </h5>
            </div>
            <div style={{marginTop: '20px'}}>
            <AccordionContainer style={{backgroundColor:'#f7f7f7'}}>
        <AccordionArrow className='ml-1' onClick={()=>handleAccordionClick('update')}>
         <div>
            {HideUpdate ? (
              <IoIosArrowUp size={18} color={PURPLE} />
            ) : (
              <IoIosArrowDown size={18} color={PURPLE} />
            )}
          </div>
        </AccordionArrow>
        <div className='d-flex justify-content-between w-100'>
        <TitleSection>Visit To Update</TitleSection>
        <div 
        style={{
          marginRight:'10px',
          padding:'4px 10px',
          backgroundColor:'#fff'
        }}
        >count : {visitToUpdateData.length || 0}
        </div>
        </div>

      </AccordionContainer>
      {!HideUpdate &&
        <div className='d-flex' style={{flexWrap:'wrap', height:'200px', overflowY:'scroll'}}>
           {visitToUpdateData &&
          visitToUpdateData.map(item => {
            return (
             <>
              <div
                style={{
                  marginLeft: '30px',
                  padding:'4px',
                  textAlign: 'left',
                   boxShadow:'0px 0.1875rem 0.375rem #00000029'
                }}>
                {item.start_date && moment(item.start_date).format('DD-MM-YYYY HH:mm')}-{item.end_date && moment(item.end_date).format('DD-MM-YYYY HH:mm')}
              </div>
             </>
            );
          })}
        </div>
      }
      <AccordionContainer  style={{backgroundColor:'#f7f7f7'}}>
        <AccordionArrow className='ml-1' onClick={()=>handleAccordionClick('create')}>
          <div>
            {HideCreate ? (
              <IoIosArrowUp size={18} color={PURPLE} />
            ) : (
              <IoIosArrowDown size={18} color={PURPLE} />
            )}
          </div>
        </AccordionArrow>
        <div className='d-flex justify-content-between w-100'>
        <TitleSection>Visit To Add</TitleSection>
        <div 
        style={{
          marginRight:'10px',
          padding:'4px 10px',
          backgroundColor:'#fff'
        }}
        >count : {visitToCreateData.length || 0}
        </div>
        </div>
      </AccordionContainer>
      {!HideCreate &&
        <div className='d-flex' style={{flexWrap:'wrap', height:'200px', overflowY:'scroll'}}>
           {visitToCreateData &&
          visitToCreateData.map(item => {
            return (
             <>
              <div
                style={{
                  marginLeft: '30px',
                  padding:'4px',
                  textAlign: 'left',
                   boxShadow:'0px 0.1875rem 0.375rem #00000029'
                }}>
                {item.start_date && moment(item.start_date).format('DD-MM-YYYY HH:mm')}-{item.end_date && moment(item.end_date).format('DD-MM-YYYY HH:mm')}
              </div>
             </>
            );
          })}
        </div>
      }
      <AccordionContainer style={{backgroundColor:'#f7f7f7'}}>
        <AccordionArrow className='ml-1' onClick={()=>handleAccordionClick('delete')}>
          <div>
            {HideDelete ? (
              <IoIosArrowUp size={18} color={PURPLE} />
            ) : (
              <IoIosArrowDown size={18} color={PURPLE} />
            )}
          </div>
        </AccordionArrow>
        <div className='d-flex justify-content-between w-100'>
        <TitleSection>Visit To Delete</TitleSection>
        <div 
        style={{
          marginRight:'10px',
          padding:'4px 10px',
          backgroundColor:'#fff'
        }}
        >count : {visitToDeleteData.length || 0}
        </div>
        </div>
      </AccordionContainer>
      {!HideDelete &&
        <div className='d-flex' style={{flexWrap:'wrap', height:'200px', overflowY:'scroll'}}>
           {visitToDeleteData &&
          visitToDeleteData.map(item => {
            return (
             <>
              <div
                style={{
                  marginLeft: '30px',
                  padding:'4px',
                  textAlign: 'left',
                   boxShadow:'0px 0.1875rem 0.375rem #00000029'
                }}>
                {item.start_date && moment(item.start_date).format('DD-MM-YYYY HH:mm')}-{item.end_date && moment(item.end_date).format('DD-MM-YYYY HH:mm')}
              </div>
             </>
            );
          })}
        </div>
      }
              <h6></h6>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => 
              setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonForm
              // typeButton={type}
             disabled={loadingSave}
              onClick={evt => {
                checkBeforeSend();
                setLoadingSave(true)
                // handleToast();
              }}>
                 <span  className={'mr-2'}> YES, PROCEED</span>
                {!loadingSave ? (
                    ''
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
             
            </PrimaryButtonForm>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default ConfirmationModalSave;
