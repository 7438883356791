import React, { Fragment } from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import { PrimaryButtonForm } from 'shared/styles/buttons';
import { PrimaryButtonModal } from 'components/SharedComponents/ModalDecision/styles';
import { Title } from 'shared/styles/constants/tagsStyles';
import { IconStyle } from 'components/SharedComponents/ModalInformation/styles';
import { CancelButton } from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import { Toast } from 'primereact/toast';
import { MessageOptaPlanner } from 'components/DashboardComponents/Scheduler/styles';
import { setClientContract } from 'utils/api/ClientApi';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { FaRegDotCircle } from "react-icons/fa";
import { BsDash } from "react-icons/bs";

import _ from "lodash"
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';
import { CHECK_CIRCLE } from 'utils/constants/icons';
import PropTypes from 'prop-types';
import { red } from '@mui/material/colors';
import { UnassignAllVisits } from 'utils/api/SchedulerApi';
const ConfirmationModal = ({
  fullBind,
  setModalValue,
  checkBeforeSend,
  type,
  title,
  icon,
  unassignedData,
  handleDeleteAvailabilityDetail,
  searchRotaShift
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [newDetails, setDetails] = useState([]);
  const [loadingUnassigned, setLoadingUnassigned] = useState([]);
  const [disableButtonUnassigned, setDisableButtonUnassigned] = useState([]);

  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: '',
    });
  }, [fullBind]);

  const handleUnAssignVisits = async (id, index) => {
    let body = {
      visit_ids: id
    }
    let response = await UnassignAllVisits(body)

    let loadingArr = unassignedData.filter(ele => ele !== index + 1);
    setLoadingUnassigned([...loadingArr]);
    setDisableButtonUnassigned([...disableButtonUnassigned, index + 1]);

    if((response.status == 'FAIL') && response.message){
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: response.message,
      });
    }else{
      searchRotaShift()
    }

    
  }

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              <h5>
                The user have visits assigned. Do you still want delete this shift ?
              </h5>
            </div>
            <div style={{ marginTop: '20px' }}>

                {unassignedData &&
                  unassignedData.map((item, index) => {
                    return (
                      <>
                         <div className='d-flex justify-content-between mt-1'>
                         <div
                            style={{
                              display:'flex',
                              marginRight: '3px',
                              textAlign: 'left',
                              paddingLeft: '30px',
                              paddingRight: '10px',
                            }}>
                               <FaRegDotCircle />
                            <div className='ml-2' style={{lineHeight:'normal'}}>
                            {item.start_date} {item.start_date_time} <BsDash />
                            {item.end_date} {item.end_date_time}
                            </div>
                          </div>
                        <div className={'mr-1'}>
                          <PrimaryButtonForm
                            minWidth="10rem"
                            disabled={disableButtonUnassigned.find(ele => ele === index + 1) ? true : false}
                            onClick={() => {
                              handleUnAssignVisits(item.visit_id, index)
                            }}


                          >
                            <span className={'mr-1 pl-0.5 pr-0.5'}>Unassign</span>
                            {!loadingUnassigned.find(ele => ele === index + 1) ? (
                              null
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                              />
                            )}
                          </PrimaryButtonForm>

                        </div>
                         </div>
                      </>
                    );
                  })}
                  
              </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() =>
                setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              // typeButton={type}

              onClick={evt => {
                handleDeleteAvailabilityDetail(true);
              }}>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default ConfirmationModal;
