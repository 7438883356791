import React, {useEffect, useState, useRef, useContext} from 'react';
import {Title, TitleSection} from 'shared/styles/constants/tagsStyles';
import Template from 'components/SharedComponents/SettingsTemplate';
import LineContainer from 'components/SharedComponents/LineContainer';
import PermissionsTable from './Table';
import {
  getRoles,
  getGroupTasks,
  getCareTask,
  deleteRole,
  deleteGroupTasks,
  setGroupTasks,
  getGroupTaskPermissions,
  setRole,
  getSections,
  changeGroupTaskPermission,
  changeCategoryPermission,
  changeSectionPermission,
  changeSubsectionPermission,
  changeOrdering,
  changeOrderingDragDrop,
} from 'utils/api/UsersApi';
import {FLOPPY, CHECK} from 'utils/constants/icons';
import {SAVED, FINISH} from 'utils/constants/messages';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  ROLES_AND_PERMISSIONS_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import {getCategoryPermission, getPermissions,getStaff,getClient,getEmployee} from 'utils/localStorage/user';
import {getAdmin, getSuperAdmin} from "utils/localStorage/token"
import {AppContext} from "context"  
import CareTaskList from './List';
import { SettingsGridContainer } from 'components/SharedComponents/SettingsTemplate/styles';

const GroupTask = (props) => {
  const [permissionsSections, setPermissionsSections] = useState([]);
  const [selectedRol, setSelectedRol] = useState({});
  const [rolValues, setRolValues] = useState([]);
  const [permissionValues, setPermissionValues] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(FINISH);
  const [loadingSave, setLoadingSave] = useState(false);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const {contextPermissions,contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext) ;

  const mountedRef = useRef(true);


  const  userClient =  getClient();
  const  userEmployee = getEmployee();
  const  userStaff = contextIsStaff;
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;
  
  useEffect(() => {
    loadSections();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(()=>{
    const roles = contextPermissions;
  
    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === ROLES_AND_PERMISSIONS_SETTINGS) {
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });
  
      if(filterAdmin  == true  ||  filterSuperAdmin == true  ){
        setCreatePermissions(true) ;
        setDeletePermissions(true);
        setUpdatePermissions(true);
      }
      else {
      setCreatePermissions(
        rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ]
      );

      setReadPermissions( 
        rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ]
      );
    }

    }


  },[])


  useEffect(() => {
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rolValues)]);

  useEffect(() => {
    if (Object.keys(selectedRol).length >= 1) {
      loadRolPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);

  const loadSections = async () => {
    return getCareTask()
      .then(response => {
        setPermissionsSections(response);
        return response;
      })
      .catch(() => {});
    
  };

  const loadselectedPermission= async () =>{
      return getGroupTaskPermissions(selectedRol.id)
      .then(response => {
        return response;
      })
      .catch(() => {});
  }

  const loadRolPermission = async () => {
    setLoadingRolValues(true)
    const mySections = await loadSections();
    const addArrayList = JSON.parse(JSON.stringify(mySections.results));

    const permissionData = await loadselectedPermission();

    // const categoryArr =  addArrayList.map((item,index)=>{
    //     return {id : item.task_type.care_category.id, name:  item.task_type.care_category.name}
    // })
    // const filterUnique = categoryArr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);

    // let temArr = [];
    // filterUnique.forEach((item)=>{
    //     let sectionData = [];
    //     addArrayList.forEach((sec)=>{
    //         let subsecData = [];
    //     if(item.id === sec.task_type.care_category.id){
    //         addArrayList.forEach((subItem)=>{
    //             if(subItem.task_type.id === sec.task_type.id){
    //                 subsecData.push({id: subItem.id, name:subItem.name});
    //             }
    //         });
    //         sectionData.push({id: sec.task_type.id, name: sec.task_type.name, care_task: subsecData})
    //     }
    //     });
    //     temArr.push({...item, task_type: sectionData.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)})
    // })
    

    const myRolPermissions = [];
    const addWebValues = myValue => {
      if (myValue) {
        return {
          web_read: myValue,
        };
      } else {
        return {
          web_read: false,
        };
      }
    };
    const addMobileValues = myValue => {
      if (myValue) {
        return {
          mobile_read: myValue,
        };
      } else {
        return {
          mobile_read: false,
        };
      }
    };

    //check all sections
    addArrayList.forEach((principalArray, index) => {
      //add new index in myRolPermissions
      myRolPermissions.push({});
      myRolPermissions[index].name = principalArray.name;
      myRolPermissions[index].id = principalArray.id;
      myRolPermissions[index].principal = true;

      let categoryWebCheck = false;
      let categoryMobileCheck = false;

      //add sections for each principal menu
      const myPrincipalSections = [];
      principalArray.task_type.forEach((principalSection, indexSections) => {
        // add new index in myPrincipalSections
        myPrincipalSections.push({});
        // add principal values in new index
        myPrincipalSections[indexSections].name = principalSection.name;
        myPrincipalSections[indexSections].id = principalSection.id;
        myPrincipalSections[indexSections].category = principalArray.id;
        myPrincipalSections[indexSections].principal = false;
        myPrincipalSections[indexSections].isSubsection = false;

        let sectionWebCheck = false;
        let sectionMobileCheck = false;
        //add sections(sub_list) in first subList values
        myPrincipalSections[indexSections].sections = [];
        principalSection.care_task.forEach((subSection, indexSubSection) => {
       

          myPrincipalSections[indexSections].sections.push({});
          // add principal aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].principal = false;
          // add isSubsection aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].isSubsection = true;
          //add name
          myPrincipalSections[indexSections].sections[indexSubSection].name =
            subSection.name;
          //add id
          myPrincipalSections[indexSections].sections[indexSubSection].id =
            subSection.id;

             // add category
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].category = principalArray.id;
         
          // add web
          let webSubSectionCheck = false;
          permissionData.forEach((val)=>{
            if((val.care_task.id === subSection.id) && val.web_permission){
              webSubSectionCheck = true;
              sectionWebCheck = true;
              categoryWebCheck = true;
              myPrincipalSections[indexSections].sections[
                indexSubSection
              ].permission_id = val.id;
            }
          })
          myPrincipalSections[indexSections].sections[indexSubSection].web = addWebValues(webSubSectionCheck);
            
          // add mobile
          let mobileSubSectionCheck = false;
          permissionData.forEach((val)=>{
            if((val.care_task.id === subSection.id) && val.mobile_permission){
              mobileSubSectionCheck = true;
              sectionMobileCheck = true;
              categoryMobileCheck = true;
            }
          })
          myPrincipalSections[indexSections].sections[indexSubSection].mobile = addMobileValues(mobileSubSectionCheck);
            
           // addMobileValues(mySubSectionPermission[0] || []);
        });

         //add web and mobile values for first subList values
         myPrincipalSections[indexSections].web = addWebValues(sectionWebCheck);
          myPrincipalSections[indexSections].mobile = addMobileValues(sectionMobileCheck);


      });

   

      // add web and mobile values for principal menu
      myRolPermissions[index].web = addWebValues(categoryWebCheck
      );
      myRolPermissions[index].mobile = addMobileValues(categoryMobileCheck
      );
      myRolPermissions[index].sections = myPrincipalSections;
    });
    setRolValues([...myRolPermissions]);
    setPermissionValues([...permissionData])
    setLoadingRolValues(false)
  };

  const changeRol = async rolData => {
    setRolValues([]);
    if (Object.keys(selectedRol).length >= 1) {
      if (rolData.id === selectedRol.id) {
        setSelectedRol({});
      } else {
        setSelectedRol(rolData);
      }

      setButtonIcon(FLOPPY);
      setTextStatus(FINISH);
      return;
    }

    setSelectedRol(rolData);
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
  };

  const handleSavePermissions = async () => {
    setLoadingSave(true);

    const savePermissionsSubSections = function () {
      return async function (permissions) {
        const objToSend = {
          sub_section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSubsectionPermission(objToSend);

          return response;
        } catch (error) {}
      };
    };

    const savePermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSectionPermission(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSubSections())
          );
          return response;
        } catch (error) {}
      };
    };

    const saveRolPermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          category_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await saveRolCategory(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSections())
          );
          return response;
        } catch (error) {}
      };
    };

    await Promise.all(rolValues.map(saveRolPermissionsSections()));

    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setLoadingSave(false);
  };

  const updateRolValues = () => {
    setRolValues([]);
    setSelectedRol({});
  };

  const saveRolCategory = async objToSend => {
    try {
      const response = await changeCategoryPermission(objToSend);
      return response;
    } catch (error) {}
  };

  const saveRolSection = async objToSend => {
    try {
      const response = await changeSectionPermission(objToSend);
      return response;
    } catch (error) {}
  };

  const saveRol = rol => {
   
    return new Promise(async (resolve, reject) => {
      try {
        const response = await setGroupTasks(rol.role);

        if (response.id) {
          resolve(response);
          props.handleChangeChoices();
        } else {
          reject({});
        }
      } catch (error) {
        reject({});
      }
    });
  };

  const handleCheckboxClick=(val, check)=>{
    let type = {};
    if(val.columnType === 'web'){
      type.web_permission = true
    }else {
      type.mobile_permission = true
    }
    let body = {
      status: check,
      group : selectedRol.id,
      ...type
    };
    if(val.principal){
      let temp = []
      val.sections.forEach((item)=>{
        item.sections.forEach((subItem)=>{
          temp.push(subItem.id)
        })
      })
      body.care_task = [...temp]
    } else {
      if(val.isSubsection){
        body.care_task = [val.id]
      } else {
        let subTemp = val.sections.map((subItem)=>(subItem.id))
        body.care_task = [...subTemp]
      }
    }
    changeGroupTaskPermission(body)
    .then((res)=>{
      loadRolPermission();
    })
    .catch((err)=>{})
  }

  const handleSwap=(item, index, arrow)=>{
    let body = {
      id: item,
      ordering_status: arrow
    }
    changeOrdering(body)
    .then((res)=>{
      if(res.status === 'True'){
        loadRolPermission();
      }
    })
    .catch((err)=>{})
}

const handleSwapDrop=(src, des)=>{
  let element = permissionValues[src];
  permissionValues.splice(src, 1);
  permissionValues.splice(des, 0, element);
  setPermissionValues([...permissionValues]);

  let body = {
      id: permissionValues[des].id,
      prePos: src + 1,
      newPos: des + 1,
      group_id: selectedRol.id
  }
  changeOrderingDragDrop(body)
  .then((res)=>{
    if(res.status == true){
      loadRolPermission();
    }
  })
  .catch((err)=>{})
}
const [loadingRolValues, setLoadingRolValues] = useState(false);

  return (
    <SettingsGridContainer size="sm">
          <Template
            titleSection={'Group Tasks'}
            labelInputTitle={'Task Name'}
            getMethod={getGroupTasks}
            deleteMethod={deleteGroupTasks}
            setMethod={setGroupTasks}
            textToMessageDelete={'task'}
            specializeCase={'role'}
            specializedMethods={{
              changeRol: changeRol,
              selectedRol: selectedRol,
              updateRolValues: updateRolValues,
            }}
            size="sm"
          />
          <LineContainer>
              <TitleSection className='px-3 py-3'>Task</TitleSection>
              {
                loadingRolValues ? <div className='px-3' style={{fontSize:"0.85rem"}}>loading...</div> :
                (rolValues.length > 0 ? 
                  <PermissionsTable
                    rolValues={rolValues}
                    changeRol={changeRol}
                    selectedRol={selectedRol}
                    setRolValues={setRolValues}
                    handleCheckboxClick={handleCheckboxClick}
                  />
                  :
                  <div className='px-3' style={{fontSize:"0.85rem"}}>
                    Please select a group task to populate fields here
                  </div>
                )
              }
          </LineContainer>
          <LineContainer>
              <TitleSection className='px-3 py-3'>Ordering</TitleSection>
              {
                loadingRolValues ? <div className='px-3' style={{fontSize:"0.85rem"}}>loading...</div> :
                (rolValues.length > 0 ? 
                  <CareTaskList
                    rolValues={rolValues}
                    changeRol={changeRol}
                    selectedRol={selectedRol}
                    setRolValues={setRolValues}
                    handleSwap={handleSwap}
                    permissionValues={permissionValues}
                    handleSwapDrop={handleSwapDrop}
                  />
                  :
                  <div className='px-3' style={{fontSize:"0.85rem"}}>
                    Please select a group task to populate fields here
                  </div>
                )
              }
          </LineContainer>
    </SettingsGridContainer>
  );
};

export default GroupTask;
