import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import React from 'react'
import { ModalContainer, TitleSection } from 'shared/styles/constants/tagsStyles'
import AddForm from '../AddForm'
import { ErrorMessage } from 'shared/styles/AuthComponents'
import { Fragment } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { BsFillPlusSquareFill } from 'react-icons/bs'
import CustomYearInput from 'components/SharedComponents/DateSelector/CustomYearInput'
import { useEffect } from 'react'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles'
import { EX_LARGE_COL, EX_SMALL_COL4 } from 'shared/styles/constants/columns'
import { useState } from 'react'
import { getOverlapedDateRange } from 'utils/api/SettingsApi'

const headerColumns = [
    {
        label: 1,
        checkbox: true,
        name: 'checkbox',
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
    },
    {
        label: 'Employee Name',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'ABSENCE TYPE',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'ABSENCE PAID',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Allowance From',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Allowance To',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    }

];

function AddAbsence({
    addFullBind,
    values,
    setValues,
    errorMessage,
    useInput,
    choices,
    toast,
    errors,
    setCheckRequires,
    setFormErrors,
    setErrorMessage,
    readOnly,
    isValid,
    HandleSubmit,
    textStatus,
    loadingSave,
    buttonIcon,
    dropDownHandleSelect,
    NAME_SPACE,
    modalLoading,
    relatedEmployees,
    relatedEmployeesCount,
    handleSelectChange,
    handleAllCheck,
    handleAllSelectChange,
    checkedRows,
    setModalWarningValue,
    getNameFromChoice }) {
    const [overlapDates, setOverlapDates] = useState([])


    const addNewAbsence = () => {
        if (!values[NAME_SPACE]) {
            values[NAME_SPACE] = [{}]
            setValues({ ...values })
        } else {
            values[NAME_SPACE] = [...values[NAME_SPACE], {}]
            setValues({ ...values })
        }
    }

    const getOverlapList = async () => {
        if (!values.calendar_type ||
            !values.applicable_date_from ||
            !values.applicable_date_to) {
            return;
        }

        const payload = {
            calendarType: values.calendar_type,
            allowance_date_from: values.applicable_date_from.length > 4 ? values.applicable_date_from?.slice(0, 4) : String(values.applicable_date_from),
            allowance_date_to: values.applicable_date_to.length > 4 ? values.applicable_date_to?.slice(0, 4) : String(values.applicable_date_to),
        }

        try {
            const res = await getOverlapedDateRange(payload)
            console.log(res, "resss")
            setOverlapDates(res?.date_range && res?.date_range)
        } catch (error) {

        }
    }

    useEffect(() => {
        getOverlapList()
    }, [values.calendar_type, values.applicable_date_from, values.applicable_date_to])


    //absence_type absence_paid
    const calendar_type_hook = useInput(`calendar_type`, 'isRequired')
    const applicable_date_from_hook = useInput(`applicable_date_from`, 'isRequired')
    const applicable_date_to_hook = useInput(`applicable_date_to`, 'isRequired')
    // const overlapped_date_hook = useInput('overlapped_date')
    console.log(checkedRows, relatedEmployees, "name space")
    return (
        <ModalContainer max_width={'65%'}>
            <ModalNoInfo
                {...addFullBind}
                max_width={'60%'}
                minWidthTitle="6.5rem"
                loading={modalLoading}
            >
                <div className='pl-3 pr-3 pb-3 setting-absence'>
                    {relatedEmployees?.length === 0 ?
                        <>
                            <div className="d-flex mt-2">
                                <div className="w-100 pr-2">
                                    <DropdownSearchable
                                        required
                                        {...calendar_type_hook}
                                        placeHolder={'Calendar Type'}
                                        // options={[
                                        //     {
                                        //         id: "Joining Calendar",
                                        //         name: "Joining Calendar"
                                        //     },
                                        //     {
                                        //         id: "Financial Calendar",
                                        //         name: "Financial Calendar"
                                        //     }
                                        // ]}
                                        options={choices?.calendar_type && choices?.calendar_type || []}
                                        selectedOption={
                                            values?.calendar_type
                                                ? values?.calendar_type
                                                : null
                                        }
                                        onChange={(value) =>
                                            dropDownHandleSelect(
                                                { calendar_type: value ? value : null },
                                                'calendar_type'
                                            )
                                        }

                                    />
                                </div>

                                <div className="w-100  pr-2">
                                    {/* <DateSelector
                            isRequired
                                labelTop="Date From"
                                {...useInput('applicable_date_from','isRequired')}
                            /> */}
                                    <CustomYearInput
                                        isRequired
                                        labelTop="Year From"
                                        {...applicable_date_from_hook}

                                    />

                                </div>
                                <div className="w-100 ">
                                    {/* <DateSelector
                                isRequired
                                labelTop="Date To"
                                {...useInput('applicable_date_to', 'isRequired')}
                            /> */}
                                    <CustomYearInput
                                        // minDate={values.applicable_date_from}
                                        isRequired
                                        labelTop="Year To"
                                        {...applicable_date_to_hook}

                                    />
                                </div>
                            </div>
                            {overlapDates?.length ?
                                <div className='ml-1 d-flex-col mt-2'>
                                    <TitleSection style={{fontWeight:"500"}}>Overlapped Dates</TitleSection>
                                    <div className="d-flex">
                                        <ul style={{marginLeft:"-15px"}}>
                                            {overlapDates.map(item => {
                                                return <li>{`${item[0]} - ${item[1]}`}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div> : null}
                            <div className='mt-3'>
                                {values[NAME_SPACE]?.length >= 1 &&
                                    values[NAME_SPACE]?.map((item, index) => {
                                        return (
                                            <AddForm
                                                readOnly={readOnly}
                                                values={values}
                                                NAME_SPACE={NAME_SPACE}
                                                setValues={setValues}
                                                useInput={useInput}
                                                choices={choices}
                                                toast={toast}
                                                index={index}
                                                errorMessage={errorMessage}
                                                errors={errors}
                                                setCheckRequires={setCheckRequires}
                                                setFormErrors={setFormErrors}
                                                setErrorMessage={setErrorMessage}
                                            />
                                        )
                                    })}
                                <PrimaryButtonForm
                                    className={'ml-3 mt-1 mb-2'}
                                    bgcolor="BLUE"
                                    minWidth="3rem"
                                    onClick={() => addNewAbsence()}>
                                    <span>{<BsFillPlusSquareFill />}New Absence Type</span>
                                </PrimaryButtonForm>

                            </div>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: values[NAME_SPACE]?.length ? "" : "end",
                            }}>

                                <div
                                    className={'ml-0 ml-md-3 mr-2'}
                                    style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', height: "46px", }}>
                                    <div
                                        className={'mb-3'}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            marginRight: '4px',

                                        }}>
                                        {(errorMessage && !isValid && errors.length > 0) ? (
                                            <ErrorMessage style={{ color: "#e44011" }}>
                                                <label className={'mt-2'}>{errorMessage}</label>
                                            </ErrorMessage>
                                        ) : (
                                            <Fragment />
                                        )}
                                    </div>
                                    <PrimaryButtonForm
                                        minWidth="6rem"
                                        disabled={loadingSave}
                                        onClick={HandleSubmit}
                                        style={{ marginBottom: '5px' }}>
                                        <span className={'mr-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            buttonIcon
                                        ) : (
                                            <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>


                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex justify-content-end m-2'>
                                {checkedRows && checkedRows.length > 0 &&
                                    <div class="  mr-2">
                                        <PrimaryButtonForm
                                            minWidth="6rem"
                                            onClick={() => setModalWarningValue(true)}
                                        >
                                            <span className={'mr-2'}>Update</span>
                                        </PrimaryButtonForm>
                                    </div>
                                }
                            </div>
                            <div className='mt-3 mb-4 table-container'>
                                <Table
                                    headerColumns={headerColumns}
                                    // callBackQuerySort={setQuerySort}
                                    handleAllCheck={handleAllCheck}
                                    handleAllSelectChange={handleAllSelectChange}
                                    headerPadding={false}
                                    noEditOption={true}
                                    noNeed={false}
                                >
                                    <div>
                                        {
                                            relatedEmployees && relatedEmployees?.map((item, index) => {
                                                return (
                                                    <div>
                                                        <Row key={Math.random()} bgColor Shadow >
                                                            <ColsGrouper className="d-flex justify-content-start h-30">
                                                                <Col
                                                                    className='custom-padding'
                                                                    max_width={EX_SMALL_COL4}
                                                                    Shrink={false}
                                                                    NoFlexGrow={true}
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center
                                                                >
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        checked={checkedRows.includes(item.id)}
                                                                        id="flexCheckDefault"
                                                                        onChange={event => {
                                                                            handleSelectChange(event, item, index);
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.employee__full_name}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{getNameFromChoice(item.absenceType, "absence_type")}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{getNameFromChoice(item.absencePaid, "absence_paid")}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.allowance_date_from}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.allowance_date_to}</label>
                                                                </Col>
                                                            </ColsGrouper>

                                                        </Row>
                                                        <div className='mb-1' />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Table>
                            </div>
                        </>}

                </div>
            </ModalNoInfo>
        </ModalContainer>
    )
}

export default AddAbsence