import {
  LARGE_COL,
  SMALL_COL
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Employee Name',
    name: 'employee_name',
    status: 0,
    custum_column: true,
    id: 'employee_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Branch',
    name: 'client__branch__name',
    status: 1,
    custum_column: true,
    id: 'branch_name',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Pattern',
    name: 'pay_pattern',
    status: 0,
    id: 'pay_pattern',
    columnSelected: false,
    custum_column: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Sch Visit Pay Amt',
    name: 'schedule_visit_pay_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_visit_pay_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Visit Pay Amt',
    name: 'actual_visit_pay_amount',
    status: 0,
    custum_column: true,
    id: 'actual_visit_pay_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Travel Amt',
    name: 'schedule_travel_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_travel_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Travel Amt',
    name: 'travel_amt',
    status: 0,
    custum_column: true,
    id: 'actual_travel_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Mileage Amt',
    name: 'schedule_travel_mileage_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_travel_mileage_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Mileage Amt',
    name: 'mileage_amt',
    status: 0,
    custum_column: true,
    id: 'actual_travel_mileage_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Sch Gross Amt',
    name: 'sch_gross_amount',
    status: 0,
    custum_column: true,
    id: 'total_schedule_gross_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Act Gross Amt',
    name: 'act_gross_amount',
    status: 0,
    custum_column: true,
    id: 'total_actual_gross_amount',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
 
  // {
  //   label: 'Total Gross Amt',
  //   name: 'gross_amount',
  //   status: 0,
  //   custum_column: true,
  //   id: 'total_gross_amount',
  //   columnSelected: true,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // }
  {
    label: 'Schedule Office Pay Amount',
    name: 'schedule_office_pay_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_office_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Office Duration',
    name: 'schedule_office_duration',
    status: 0,
    custum_column: true,
    id: 'schedule_office_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Office Travel Pay Amount',
    name: 'schedule_office_travel_pay_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_office_travel_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Office Travel Duration',
    name: 'schedule_office_travel_duration',
    status: 0,
    custum_column: true,
    id: 'schedule_office_travel_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Office Travel Miles Amount',
    name: 'schedule_office_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_office_travel_miles_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Office Travel Miles',
    name: 'schedule_office_travel_miles',
    status: 0,
    custum_column: true,
    id: 'schedule_office_travel_miles',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Pay Amount',
    name: 'actual_office_pay_amount',
    status: 0,
    custum_column: true,
    id: 'actual_office_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Duration',
    name: 'actual_office_duration',
    status: 0,
    custum_column: true,
    id: 'actual_office_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Travel Pay Amount',
    name: 'actual_office_travel_pay_amount',
    status: 0,
    custum_column: true,
    id: 'actual_office_travel_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Travel Duration',
    name: 'actual_office_travel_duration',
    status: 0,
    custum_column: true,
    id: 'actual_office_travel_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Travel Miles Amount',
    name: 'actual_office_travel_miles_amount',
    status: 0,
    custum_column: true,
    id: 'actual_office_travel_miles_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Office Travel Miles',
    name: 'actual_office_travel_miles',
    status: 0,
    custum_column: true,
    id: 'actual_office_travel_miles',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Absence Pay Amount',
    name: 'absence_pay_amount',
    status: 0,
    custum_column: true,
    id: 'absence_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Absence Pay Duration',
    name: 'absence_pay_duration',
    status: 0,
    custum_column: true,
    id: 'absence_pay_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Oncall Pay Amount',
    name: 'schedule_oncall_pay_amount',
    status: 0,
    custum_column: true,
    id: 'schedule_oncall_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Oncall Pay Duration',
    name: 'schedule_oncall_pay_duration',
    status: 0,
    custum_column: true,
    id: 'schedule_oncall_pay_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Oncall Pay Amount',
    name: 'actual_oncall_pay_amount',
    status: 0,
    custum_column: true,
    id: 'actual_oncall_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Oncall Pay Duration',
    name: 'actual_oncall_pay_duration',
    status: 0,
    custum_column: true,
    id: 'actual_oncall_pay_duration',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Expenses Pay Amount',
    name: 'expenses_pay_amount',
    status: 0,
    custum_column: true,
    id: 'expenses_pay_amount',
    columnSelected: false,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

];

export const headerColumnsRates = [
  {
    label: 'Schedule Rate',
    name: 'schedule_rate',
    status: 0,
    custum_column: true,
    id: 'schedule_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Schedule Travel Rate',
    name: 'schedule_travel_rate',
    status: 0,
    custum_column: true,
    id: 'schedule_travel_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Rate',
    name: 'actual_rate',
    status: 0,
    custum_column: true,
    id: 'actual_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Travel Rate',
    name: 'actual_travel_rate',
    status: 0,
    custum_column: true,
    id: 'actual_travel_rate',
    columnSelected: true,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
]


export const headerColumnsSelector = [
  {
    name: 'Name',
    id: 'employee__full_name',
  },
  {
    name: 'Branch',
    id: 'branch_name',
  },
  {
    name: 'Sch Visit Amt',
    id: 'schedule_amount',
  },
  {
    name: 'Act Visit Amt',
    id: 'actual_amount',
  },
  {
    name: 'Sch Travel Amt',
    id: 'schedule_travel_amount',
  },
  {
    name: 'Act Travel Amt',
    id: 'actual_travel_amount',
  },
  {
    name: 'Sch Mileage Amt',
    id: 'schedule_travel_mileage_amount',
  },
  {
    name: 'Act Mileage Amt',
    id: 'actual_travel_mileage_amount',
  },
  {
    name: 'Sch Gross Amt',
    id: 'total_schedule_amount',
  },
  {
    name: 'Act Gross Amt',
    id: 'total_actual_amount',
  },
  {
    name: 'Total Gross Amt',
    id: 'gross_amount',
  }
];

export const headerColumnsSelectorByDefault = [
  {
    name: 'Name',
    id: 'employee__full_name',
  },
  {
    name: 'Branch',
    id: 'branch_name',
  },
  {
    name: 'Sch Visit Amt',
    id: 'schedule_amount',
  },
  {
    name: 'Act Visit Amt',
    id: 'actual_amount',
  },
  {
    name: 'Sch Travel Amt',
    id: 'schedule_travel_amount',
  },
  {
    name: 'Act Travel Amt',
    id: 'actual_travel_amount',
  },
  {
    name: 'Sch Mileage Amt',
    id: 'schedule_travel_mileage_amount',
  },
  {
    name: 'Act Mileage Amt',
    id: 'actual_travel_mileage_amount',
  },
  {
    name: 'Sch Gross Amt',
    id: 'total_schedule_amount',
  },
  {
    name: 'Act Gross Amt',
    id: 'total_actual_amount',
  },
  {
    name: 'Total Gross Amt',
    id: 'gross_amount',
  }
];
