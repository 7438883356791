import {
  LARGE_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'CONTRACT REFERENCE',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'FUNDER  CATEGORY',
    name: 'client_category',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'START DATE',
    name: 'start_dates',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'END DATE',
    name: 'end_dates',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'START TIME - END TIME',
    name: 'times',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT DAYS',
    name: 'visit_days',
    status: 0,
    className: 'grow-mobile visits-custom sm-hidden',
    max_width: EX_LARGE_COL,
  },
];
