import React,{useState,useEffect} from 'react'
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Toggle } from 'shared/styles/buttons'

const MapComponent = withGoogleMap((props) =>{


return (
  <GoogleMap
    defaultZoom={8}
    // defaultCenter={{ lat: -34.397, lng: 150.644 }}
    center={{lat: props.latitude?parseFloat(props.latitude):-34.397, lng: props.latitude?parseFloat(props.longitude):150.644}}
    onClick={ev => {
      props.mapClickHandler(ev.latLng.lat(),ev.latLng.lng())
    
    }}
  >
 
<Marker position={{ lat:parseFloat(props.latitude), lng:parseFloat(props.longitude)}} />
 
  </GoogleMap>
)
})

export default MapComponent