
import React, {Fragment, useEffect} from 'react';
import {FaTimes} from 'react-icons/fa';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import Spinner from 'components/SharedComponents/Spinner';
const ComponentDynamic = ({
    children,
    title,
    onClose,
    showModal,
    max_width = null,
    loadingMaxWidth= null,
    rowInfoData,
    photoRow = '',
    showRowInfo = false,
    minWidthTitle = '',
    name = '',
    lastName = '',
    loading = true,
    dateOfBirth = '',
}) => {
    return (
        <div>
            {loading ? (
        // <LoadingContainer maxHeight={loadingMaxWidth}>
          <Spinner />
        // </LoadingContainer>
      ) : (
        <Fragment>
          {/* <ModalDynamicContainer className="modal-header row-muted border-0">
            <FlexGrow>
              <ModalTitle MinWidth={minWidthTitle} className="modal-title">
                {title}
              </ModalTitle>
            </FlexGrow>

            <CloseButtonContainer>
              <CloseButtonWrapper>
                <FaTimes onClick={onClose} />
              </CloseButtonWrapper>
            </CloseButtonContainer>
          </ModalDynamicContainer>
          <ModalDynamicContainer className="modal-header row-muted border-0">
          {showRowInfo ? (
              <RowInfoModel
                name={name}
                lastName={lastName}
                photoRow={photoRow}
                rows={rowInfoData}
                dateOfBirth={dateOfBirth}
              />
            ) : (
              <></>
            )}
            </ModalDynamicContainer> */}

          {children}
        </Fragment>
      )}
        </div>
    );
}

export default ComponentDynamic;
