import { Switch, TextField } from '@mui/material'
import React from 'react'

const Textarea = ({ setJson, json }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <TextField
                    id="outlined-error"
                    label="Text area Label *"
                    placeholder='Text area label'
                    defaultValue={json.textArea.label}
                    style={{ marginRight: 10 }}
                    onChange={(event) => {
                        console.log(event.target.value)
                        setJson({
                            ...json, textArea: {
                                ...json.textArea,
                                label: event.target.value
                            }
                        })
                    }}
                />
                <TextField
                    id="outlined-error-helper-text"
                    label="Placeholder"
                    placeholder="placeholder"
                    defaultValue={json.textArea.placeholder}
                    style={{ marginRight: 10 }}
                    onChange={(event) => {
                        console.log(event.target.value)
                        setJson({
                            ...json, textArea: {
                                ...json.textArea,
                                placeholder: event.target.value
                            }
                        })
                    }}
                />


                <input type="number" placeholder='Number of rows' value={json.textArea.numberOfRows} onChange={(event) => {
                    console.log(event.target.value, "Vlaue")
                    setJson({
                        ...json, textArea: {
                            ...json.textArea,
                            numberOfRows: event.target.value
                        }
                    })
                }} />
            </div>

            <TextField
                id="outlined-multiline-static"
                label="Description"
                className='mb-2'
                multiline
                rows={3}
                value={json.textArea.description}
                placeholder="Description"
                onChange={(event) => {
                    setJson({
                        ...json, textArea: {
                            ...json.textArea,
                            description: event.target.value
                        }
                    })
                }}
            />
             <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.textArea.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, textArea: {
                                ...json.textArea,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textArea.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textArea: {
                                                ...json.textArea,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textArea.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textArea: {
                                                ...json.textArea,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textArea.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textArea: {
                                                ...json.textArea,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.textArea.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.textArea.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, textArea: {
                                ...json.textArea,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}

export default Textarea;
