import React, { useState, useEffect, useRef } from 'react';
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  SETTINGS,
  SETTINGS_CALENDAR,
  PUBLIC_HOLIDAYS,
  WD_WE,
  DAY_NIGHT_OVER,
  DURATION,
  CALENDAR_DATA,
  PAGE_404,
  CALENDAR_TYPE,
} from 'utils/constants/routes';
import Calendar from 'components/DashboardComponents/Settings/SettingsCalendar/Calendar';
import PublicHolidays from 'components/DashboardComponents/Settings/SettingsCalendar/PublicHolidays';
import WDWE from 'components/DashboardComponents/Settings/SettingsCalendar/WDWE';
import DayNightOver from 'components/DashboardComponents/Settings/SettingsCalendar/DayNightOver';
import Duration from 'components/DashboardComponents/Settings/SettingsCalendar/Duration';
import CalendarType from './CalendarType';
import { SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION,CALENDAR_TYPE as CALENDAR_TYPE_PERMISSION_} from 'utils/constants/permissions';
import { useContext } from 'react';
import { AppContext } from 'context';

const TAB_CALENDAR_URL = SETTINGS + SETTINGS_CALENDAR + CALENDAR_DATA;
const TAB_PUBLIC_HOLIDAYS_URL = SETTINGS + SETTINGS_CALENDAR + PUBLIC_HOLIDAYS;
const TAB_WDWES_URL = SETTINGS + SETTINGS_CALENDAR + WD_WE;
const TAB_DAYNIGHTOVER_URL = SETTINGS + SETTINGS_CALENDAR + DAY_NIGHT_OVER;
const TAB_DURATION_URL = SETTINGS + SETTINGS_CALENDAR + DURATION;
const TAB_CALENDAR_TYPE = SETTINGS + SETTINGS_CALENDAR + CALENDAR_TYPE;

const SettingsCalendar = props => {


  const [readPermissionCalendarType, setReadPermissionCalendarType] = useState(true)
  const [createPermissionCalendarType, setCreatePermissionCalendarType] = useState(true)
  const [updatePermissionCalendarType, setUpdatePermissionCalendarType] = useState(true)
  const [deletePermissionCalendarType, setDeletePermissionCalendarType] = useState(true)


  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextSubscription,
    contextIsSuperAdmin
  } = useContext(AppContext)

  let subscription = contextSubscription
  let history = useHistory();

  const reviewLocation = url => {
    return url === history.location.pathname;
  };


  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }

    if (contextIsAdmin == true || contextIsSuperAdmin == true) {
      setDeletePermissionCalendarType(true)
      setReadPermissionCalendarType(true)
      setUpdatePermissionCalendarType(true)
      setCreatePermissionCalendarType(true)
    } else {

      const getPermission = (key, type) => {
        if (rolePermissions[key]) {
          debugger
          switch (type) {
            case WEB_READ_PERMISSION:
              return (subscription?.[key]?.[WEB_PERMISSION] &&
                (rolePermissions[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
              );
            case WEB_CREATE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
              );
            case WEB_UPDATE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
              )
            case WEB_DELETE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
              )
            default:
              break;
          }

        }
      };
      // Calendar type
      setReadPermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_READ_PERMISSION) || false)
      setCreatePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_DELETE_PERMISSION) || false)

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSubscription, contextPermissions, contextIsSuperAdmin, contextIsAdmin, subscription]);
  console.log(contextSubscription, "contextSubscription")

  return (
    <div>
      <Title> Settings calendar</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center mb-4">

          <div className={'d-flex firstRow'}>
            <LinkTabStyled active={reviewLocation(TAB_CALENDAR_URL)}>
              <Link to={TAB_CALENDAR_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_CALENDAR_URL)}>
                  Calendar
                </LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_PUBLIC_HOLIDAYS_URL)} >
              <Link to={TAB_PUBLIC_HOLIDAYS_URL} className={'styledLink'} >
                <LinkTab active={reviewLocation(TAB_PUBLIC_HOLIDAYS_URL)} >
                  Public Holidays
                </LinkTab>
              </Link>
            </LinkTabStyled>

            {readPermissionCalendarType && (
              <LinkTabStyled active={reviewLocation(TAB_CALENDAR_TYPE)}>
                <Link to={TAB_CALENDAR_TYPE} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_CALENDAR_TYPE)}>
                    Calendar Type
                  </LinkTab>
                </Link>
              </LinkTabStyled>)}
          </div>

          <div className={'d-flex firstRow'}>
            {/* <LinkTabStyled active={reviewLocation(TAB_WDWES_URL)}>
              <Link to={TAB_WDWES_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_WDWES_URL)}>
                Weekdays/Weekend
                </LinkTab>
              </Link>
            </LinkTabStyled> */}

            {/* <LinkTabStyled active={reviewLocation(TAB_DAYNIGHTOVER_URL)}>
              <Link to={TAB_DAYNIGHTOVER_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_DAYNIGHTOVER_URL)}>
                Day Night Over
                </LinkTab>
              </Link>
            </LinkTabStyled> */}

            {/* <LinkTabStyled active={reviewLocation(TAB_DURATION_URL)}>
              <Link to={TAB_DURATION_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_DURATION_URL)}>
                Duration
                </LinkTab>   
              </Link>
            </LinkTabStyled>     */}

          </div>
        </ContainerTabStyled>

        <Switch>
          <Route exact path={TAB_CALENDAR_URL}>
            <Calendar />
          </Route>

          <Route exact path={TAB_PUBLIC_HOLIDAYS_URL}>
            <PublicHolidays location={props.location} />
          </Route>

          {/* <Route exact path={TAB_WDWES_URL}>
          <WDWE/>
          </Route> */}

          {/* <Route exact path={TAB_DAYNIGHTOVER_URL}>
          <DayNightOver />
          </Route> */}

          {/* <Route exact path={TAB_DURATION_URL}>
          <Duration />
          </Route> */}

          <Route exact path={TAB_CALENDAR_TYPE}>
            <CalendarType
              readPermission={readPermissionCalendarType}
              createPermission={createPermissionCalendarType}
              updatePermission={updatePermissionCalendarType}
              deletePermission={deletePermissionCalendarType}
            />
          </Route>


          <Redirect
            to={{
              pathname: PAGE_404,
              state: { referrer: '/' },
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default SettingsCalendar;
