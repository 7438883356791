import React, { Fragment, useEffect, useState } from 'react';
import { ServiceVisit } from 'components/SharedComponents/FormSections';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import {
  ButtonWeek,
  CloseButtonWrapper,
  FlexGrid3Container,
  FlexGrid3Item,
  IconWrapper,
  TitleSection,
  WeekStyle,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import { getPreferredVisits, getPreferredCompetency } from 'utils/api/ClientApi';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { COPY, DELETE, TIMES } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { HOUR, MINUTE } from 'utils/constants/serviceVisits';

const AddNewDose = ({
  values,
  dataVisit,
  choices,
  useInput,
  nameSection,
  deleteVisit,
  addNewVisit,
  clientId,
  editMode,
  dropDownHandleSelect,
  indexVisit,
  setValues,
  addDoseCopy
}) => {
  const ins_hook = useInput(`dose_list[${indexVisit}].instructions`)
  const [daysAdded, setDaysAdded] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', dayValue: 1, selected: false },
    { day: 'TUE', dayValue: 2, selected: false },
    { day: 'WED', dayValue: 3, selected: false },
    { day: 'THU', dayValue: 4, selected: false },
    { day: 'FRI', dayValue: 5, selected: false },
    { day: 'SAT', dayValue: 6, selected: false },
    { day: 'SUN', dayValue: 7, selected: false },
  ]);
  const newDoseStartTime = useInput(
    `dose_list[${indexVisit}].medication_dose_time`, 'isRequired'
  );
  const newDoseEndTime = useInput(
    `dose_list[${indexVisit}].medication_dose_end_time`, 'isRequired'
  );


  useEffect(() => {
    const initial = [
      { day: 'MON', selected: false },
      { day: 'TUE', selected: false },
      { day: 'WED', selected: false },
      { day: 'THU', selected: false },
      { day: 'FRI', selected: false },
      { day: 'SAT', selected: false },
      { day: 'SUN', selected: false },
    ]
    const auxArray = [];
    const tempDays = [];
    if (values[nameSection][indexVisit].repeat_on) {
      const reviewStringVelue = String(values[nameSection][indexVisit].repeat_on).split(',');

      reviewStringVelue.forEach(dayValue => {
        daysOfWeek.forEach(dayItem => {
          if (dayItem.day.toString() === dayValue.toString()) {
            dayItem.selected = true;
            auxArray.push(dayValue.toString());
          }
        });
      });
    }

    var addUniqs = auxArray.filter(function (item, indexUniq) {
      return auxArray.indexOf(item) === indexUniq;
    });
    setDaysAdded(addUniqs);

    if (auxArray.length < 1) {
      setDaysOfWeek(initial)
    } else {
      initial.forEach((item) => {
        if (addUniqs.includes(item.day)) {
          item.selected = true;
          tempDays.push(item)
        } else {
          item.selected = false;
          tempDays.push(item)
        }
      })
      setDaysOfWeek(tempDays)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection][indexVisit].repeat_on]);


  const selectedDay = (day, indexWeek) => {
    // if (readOnly) {
    //   return;
    // }

    const removeValue = daysAdded.indexOf(day);
    const weekDays = daysOfWeek;
    const addedDays = daysAdded;

    if (removeValue > -1) {
      daysAdded.splice(removeValue, 1);

      weekDays[indexWeek].selected = false;

      values[nameSection][indexVisit].repeat_on = addedDays.join();

      setDaysOfWeek(weekDays);
      setDaysAdded(daysAdded);
      setValues({ ...values });
      return;
    }

    addedDays.push(day);

    var addUniqs = addedDays.filter(function (item, indexUniq) {
      return addedDays.indexOf(item) === indexUniq;
    });

    weekDays[indexWeek].selected = true;

    values[nameSection][indexVisit].repeat_on = addedDays.join();

    setDaysOfWeek(weekDays);
    setDaysAdded(addUniqs);
    setValues({ ...values });
  };

  console.log('values', values)


  return (
    <>
      <div className="d-flex mb-2">
        <CloseButtonWrapper
          onClick={() => {
            deleteVisit(nameSection, indexVisit);
          }}
        >
          {TIMES}
        </CloseButtonWrapper>
        <IconWrapper
          style={{ marginRight: '8px' }}
          color={SOFT_RED}
          onClick={() => addDoseCopy(indexVisit)}
        >
          {COPY}
        </IconWrapper>
        <div className="w-100 pr-1">
          <DropdownSearchable
            required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
            disabled={!editMode}
            placeHolder={'Medication Dose'}
            {...useInput(`dose`, ((values.every === HOUR) || (values.every === MINUTE)) ? '' : 'isRequired')}
            options={choices.medication_dose || []}
            selectedOption={
              values[nameSection] && values[nameSection][indexVisit]
                ? values[nameSection][indexVisit].dose
                : null
            }
            onChange={value =>
              dropDownHandleSelect(
                { dose: value ? value : null },
                'dose',
                indexVisit
              )
            }
          />
        </div>
        <div className="w-100 pr-1">
        <DropdownSearchable
            required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
            disabled={!editMode}
            placeHolder={'Medication Dose Timing'}
            {...useInput(`[${nameSection}].[${indexVisit}].medication_dose_timing`, ((values.every === HOUR) || (values.every === MINUTE)) ? '' : 'isRequired')}
            options={choices.medication_dose_timing || []}
            selectedOption={
              values[nameSection] && values[nameSection][indexVisit]
                ? values[nameSection][indexVisit].medication_dose_timing
                : null
            }
            onChange={(value, label, obj) =>
              dropDownHandleSelect(
                { medication_dose_timing: obj ? obj : null },
                'medication_dose_timing',
                indexVisit
              )
            }
          />
           {/* <TimeSelector
            required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
            disabled={!editMode}
            labelTop={'Dose Time'}
            {...newDoseStartTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          /> */}
        </div>
      </div>
      <div className="d-flex mb-2">
      <div className="w-100 pr-1">
        <TimeSelector
            disabled={values[nameSection] && values[nameSection][indexVisit]
            ? !((values[nameSection][indexVisit].medication_dose_timing_name == 'Fixed') || (values[nameSection][indexVisit].medication_dose_timing_name == 'Custom'))
            : false}
            // data-error={timeError[index]}
            // errorMessage={timeErrorMessage}
            isRequired={true}
            labelTop={'Start Dose Time'}
            {...newDoseStartTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
        <div className="w-100 pr-1">
        <TimeSelector
             disabled={values[nameSection] && values[nameSection][indexVisit]
              ? !((values[nameSection][indexVisit].medication_dose_timing_name == 'Fixed') || (values[nameSection][indexVisit].medication_dose_timing_name == 'Custom'))
              : false}
            // data-error={timeError[index]}
            // errorMessage={timeErrorMessage}
            isRequired={true}
            labelTop={'End Dose Time'}
            {...newDoseEndTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
      </div>
      <div className="d-flex mb-2">
        <div className="w-100 pr-1">
          <div
            className={'pt-2 ml-md-1 ml-0 text-center'}
            style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
            Additional Instructions
          </div>
          <InputTextArea
            type="text"
            height={'4.4rem'}
            id="emp_desc"
            placeHolder={'Additional Instructions'}
            label={'Additional Instructions'}
            {...ins_hook}
          //   maxlength={5}
          />
        </div>
        <div className={'formWeek d-block'}>
          <div
            className={'pt-2 ml-md-1 ml-0 text-center'}
            style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
            Day of the week
          </div>
          <WeekStyle>
            {daysOfWeek.map((itemWeek, indexWeek) => {
              return (
                <ButtonWeek
                  key={itemWeek.day}
                  add_background={itemWeek.selected}
                  onClick={() => {
                    selectedDay(itemWeek.day, indexWeek);
                  }}
                  >
                  <div>
                    <label>{itemWeek.day}</label>
                  </div>
                </ButtonWeek>
              );
            })}
          </WeekStyle>
        </div>
      </div>
    </>
  );
};

AddNewDose.propTypes = {
  dropDownHandleVisits: PropTypes.func.isRequired,
  addNewVisit: PropTypes.func.isRequired,
  deleteVisit: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  visits: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  toDeleteVisitSkill: PropTypes.array.isRequired,
  toDeleteVisitEmployee: PropTypes.array.isRequired,
  serviceId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  errors: PropTypes.array,
};

export default AddNewDose;
