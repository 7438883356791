import {
    LARGE_COL,
    SMALL_COL
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
        label: 'Employee Name',
        name: 'emp_name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    {
        label: 'Prev Visit Assignation',
        name: 'visit_assign',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
  
      {
        label: 'Contracted Hrs',
        name: 'contracted_hrs',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Desired Hrs',
        name: 'desired_hrs',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Remaining Desired Hrs',
        name: 'remain_min',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Remaining Contracted Mins',
        name: 'remain_min',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    {
      label: 'Travel Duration',
      name: 'trv_duration',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
        label: 'Travel Interval',
        name: 'trv_interval',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Assigned Interval',
        name: 'assigned_int',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: '',
        name: 'btn_assign',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    
  ];
  