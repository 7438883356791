import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { IconWrapper } from 'components/SharedComponents/Table/styles'
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FloatingMobileButton } from 'shared/styles/buttons'

export default function ButtonGroups({ setJson, json }) {
    const opt = {
        value: "",
        type: ""
    }
    console.log(json, "ABCD")
    return (
        <div style={{ marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Button group name"
                defaultValue={json.buttonGroup.label}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    setJson({
                        ...json, buttonGroup: {
                            ...json.buttonGroup,
                            label: event.target.value
                        }
                    })
                }}
            />
            {/* <p>Options</p> */}
            {
                json.buttonGroup.options.map((item, index) => {
                    return (
                        <div key={index} style={{ display: "flex", flex: 1, justifyContent: "space-between", marginTop: 10 }}>
                            <TextField
                                id="outlined-error"
                                label="Label"
                                placeholder='Label'
                                value={item.value}
                                style={{ marginRight: 10 }}
                                onChange={(event) => {
                                    setJson({
                                        ...json, buttonGroup: {
                                            ...json.buttonGroup,
                                            options: json.buttonGroup.options.map((item, index1) => {
                                                if (index === index1) {
                                                    return { ...item, value: event.target.value }
                                                }
                                                return item
                                            })
                                        }
                                    })
                                }}
                            />

                            <FormControl style={{ flex: 1 }}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={json.buttonGroup.type}
                                    label="Tag"
                                    onChange={(event) => {
                                        setJson({
                                            ...json, buttonGroup: {
                                                ...json.buttonGroup,
                                                options: json.buttonGroup.options.map((item, index1) => {
                                                    if (index === index1) {
                                                        return { ...item, type: event.target.value }
                                                    }
                                                    return item
                                                })
                                            }
                                        })
                                    }}
                                >
                                    <MenuItem value={"contained"}>Contained</MenuItem>
                                    <MenuItem value={"outlined"}>Outlined</MenuItem>
                                </Select>
                            </FormControl>

                            <IconWrapper
                                onClick={() => {
                                    setJson({
                                        ...json, buttonGroup: {
                                            ...json.buttonGroup,
                                            options: json.buttonGroup.options.filter((item, index2) => index2 !== index)
                                        }
                                    })
                                }}>
                                <RiDeleteBinLine />
                            </IconWrapper>
                        </div>
                    )
                })
            }

            <div style={{ marginTop: 10 }}>
                <FloatingMobileButton onClick={() => {
                    setJson({
                        ...json, buttonGroup: {
                            ...json.buttonGroup,
                            options: [...json.buttonGroup.options, opt]
                        }
                    })
                }}>
                    <FaPlus />
                    <label>
                        ADD OPTIONS
                    </label>
                </FloatingMobileButton>
            </div>
        </div>

    )
}
