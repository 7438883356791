import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast'
import React from 'react'
import { useRef } from 'react'
import AddVisitReminder from './AddForm';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY, PLUS } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useState } from 'react';
import { getVisitReminder, setVisitReminder } from 'utils/api/SettingsApi';
import { useEffect } from 'react';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { Fragment } from 'react';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

function VisitReminders({
  branch,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission }) {
  const toast = useRef()
  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } = useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [ timeValues, setTimeValues] = useState([])
  let readOnly = editMode ? !updatePermission : !createPermission
  const handleSubmit = async () => {
    if (loadingSave) return;
    if (errors.length) {
      setShowError(true);
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues)
      return;
    }
    setLoadingSave(true)
    const body = {
      branch_id: branch?.id,
      reminder_list: values?.visit_reminders && values?.visit_reminders.map((rem) => {
        return {
          reminder: rem.reminder,
          is_active: rem.is_active || false,
          ...(rem.id && { id: rem.id })
        }
      }
      )
    }
    values && values.reminder_list && values.reminder_list.map(el => {
      if (el.hasOwnProperty('id')) setEditMode(true)
    })
    try {
      const res = await setVisitReminder(body, editMode)
      if (res.status == true) {
        onSuccess(res)
      }
      if (res.status === "FAIL") {
        onError(res)
      }
    } catch (error) {
      onError(error)
    }
  }

  const onSuccess = (response) => {
    setButtonIcon(CHECK)
    setTextStatus(SAVED)
    setLoadingSave(false)
    getVisitreminders()
    setShowError(false)
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${response?.message}`,
    });
  }
  const onError = (error) => {
    setButtonIcon(CROSS)
    setTextStatus(ERROR)
    setLoadingSave(false)
    setShowError(true)
    setErrorMessage(error.message)
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${error.message}`,
    });
  }


  const getVisitreminders = async () => {
    try {
      const res = await getVisitReminder(branch?.id);
      if (res?.data?.length) {
        setEditMode(true)
        values.visit_reminders = res.data.map(el => {
          return {
            reminder: el.reminder_time,
            id: el.id,
            is_active: el.is_active
          }
        })
      } else {
        setEditMode(false)
      }
      setValues({ ...values })
      setLoadingModal(false)
    } catch (error) {
      console.error("Error:", error)
    }
  }
  useEffect(() => {
    getVisitreminders()
    generateTimes()
  }, [])

  const generateTimes = () => {
    const timeArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 10) {
          const formattedHour = String(hour).padStart(2, '0');
          const formattedMinute = String(minute).padStart(2, '0');
          const time = `${formattedHour}:${formattedMinute}`;
          if(time !== '00:00')
          timeArray.push({ id: time, name: time });
      }
  }
  setTimeValues(timeArray)
  }

  const deleteVisitReminder = (index) => {
   let err =  errors.filter((err => !err.includes(`visit_reminders[${index}]`)))
   setFormErrors(err)
    if (values.visit_reminders && values.visit_reminders.length > 0) {
      values.visit_reminders.splice(index, 1);
    }
    setValues({ ...values });
  }

  const addVisitReminder = () => {
    if (!values.visit_reminders)
      values.visit_reminders = [];
    values.visit_reminders.push({});
    setValues({ ...values });
  };
  console.log(errors,"errors")
  return (
    <div style={{ width: "100%", marginTop: "9rem" }}>
      {readPermission ? <>
        <Toast ref={toast} position="top-right" />
        <ComponentDynamic loading={loadingModal}>
          <div className="mt-3  pt-2  mb-2">
            <div className="mt-1 ">
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Visit Reminders
                  </TitleSection>
                </b>{' '}
              </small>
            </div>
            {values.visit_reminders &&
              values.visit_reminders.length >= 1 &&
              values.visit_reminders.map((item, index) => {
                return (
                  <AddVisitReminder
                    index={index}
                    item={item}
                    setValues={setValues}
                    useInput={useInput}
                    values={values}
                    deleteVisitReminder={deleteVisitReminder}
                    readOnly={readOnly}
                    deletePermission={deletePermission}
                    timeValues={timeValues}
                  />
                );
              })}
            <div className={'d-flex justify-content-center justify-content-md-between'}
              style={{ marginLeft: '15px' }}>
              <div className={'mt-1 mb-1'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  disabled={readOnly}
                  onClick={addVisitReminder}>
                  <span>{PLUS} Visit Reminder</span>
                </PrimaryButtonForm>
              </div>

            </div>
            <div
              className={'d-flex justify-content-center justify-content-md-end'}
            // style={{ width: "44%" }}
            >
              <div className='d-flex'>
                {(showError) ? (
                  <ErrorMessage style={{ color: "#e44011" }}>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
                <div className={'mt-1 ml-2 mb-1'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    disabled={readOnly}
                    onClick={() => handleSubmit()}>
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </div>
        </ComponentDynamic>
      </> :
        <NoPermissionContainer>You don't have permission to access the information</NoPermissionContainer>}
    </div>
  )
}

export default VisitReminders