import { apiURL, apiLoginURL, apiURLReplica, apiURLReport } from '../env';
import { get, deleteFetch, post, patch, newGet, Newpost } from './Api';
import { clearToken } from '../localStorage/token';
import queryString, { stringify } from 'query-string';
import { methods } from 'utils/constants/api';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getToken, getTenant } from '../localStorage/token';
import moment from 'moment';

import _, { values } from 'lodash';
import axios from 'axios';
import { config } from '@fullcalendar/react';
import { error } from 'jquery';
import { asyncAct } from '@testing-library/react/dist/act-compat';
import { identity } from 'fullcalendar';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

const getBaseUrl3 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v3`;
};

const getBaseUrlMicroServices = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};

const getBaseUrlMicroServices2 = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v2`;
};

const getBaseUrlReport = () => {
  let tenant = getTenant();
  return `${apiURLReport}/${tenant}/api/v1`;
};
export const getMedicationChart = (values, pageSize, clientId) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client && typeof values.client === 'object') {
    console.log(values.client, "call")
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }

  if (values.medication_name) {
    let runNameFilter = values.medication_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_name = strRunNameFilter;
  }

  if (values.medication_status__id__in) {
    let runNameFilter = values.medication_status__id__in.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_status__id__in = strRunNameFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = moment(values.start_date).format('YYYY-MM-DD');
  const new_end_date = moment(values.end_date)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format

  datesRange = 'start_date__gte=' + new_start_date;
  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date__lte=' + new_end_date;

  conditionString += datesRange;

  if (filterParse.employees) {
    conditionString += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += '&client__id__in=' + filterParse.clients;
    //conditionString += '&client__id__in=' + 'f05a6391-242e-41bb-b763-9ebbacb38de6'
  }
  if (filterParse.medication_name) {
    conditionString += '&name__id__in=' + filterParse.medication_name;
  }

  if (filterParse.medication_status__id__in) {
    conditionString +=
      '&medication_status__id__in=' + filterParse.medication_status__id__in;
  }

  if (clientId) {
    conditionString += '&client__id__in=' + clientId;
  }

  //conditionString+='&view=Web'
  let url = '';
  url = `${baseUrl}/medication/medication/chart/?${conditionString}`;

  return get(url);
};

export const getMedicationCalendar = (values, pageSize, clientId) => {
  console.log(values,"getMedicationCalendar")
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client) {
    let clientFilter = values?.client?.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }

  if (values.medication_name) {
    let runNameFilter = values.medication_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_name = strRunNameFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.medication_status__id__in) {
    let runNameFilter = values.medication_status__id__in.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_status__id__in = strRunNameFilter;
  }

  if (values.visit_link) {
    filterParse.visit_link = values.visit_link;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = moment(values.start_date).format('YYYY-MM-DD');
  const new_end_date = moment(values.end_date).format('YYYY-MM-DD');
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format
  console.log({new_start_date,
    new_end_date},"getMedicationCalendar")

  datesRange = 'start_date__gte=' + new_start_date;
  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date__lte=' + new_end_date;

  conditionString += datesRange;
  if (filterParse.headerbranch) {
    conditionString += '&branch_id=' + filterParse.headerbranch;
  }
  if (filterParse.employees) {
    conditionString += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += '&client__id__in=' + filterParse.clients;
    //conditionString += '&client__id__in=' + 'f05a6391-242e-41bb-b763-9ebbacb38de6'
  }
  if (filterParse.medication_name) {
    conditionString += '&name__id__in=' + filterParse.medication_name;
  }

  if (filterParse.medication_status__id__in) {
    conditionString +=
      '&medication_status__id__in=' + filterParse.medication_status__id__in;
  }
  if (filterParse.visit_link) {
    conditionString += '&is_relation=' + ((filterParse.visit_link === 'link') ? 'true' : 'false');
  }
  if (clientId) {
    conditionString += '&client__id__in=' + clientId;
  }

  //conditionString+='&view=Web'
  let url = '';
  url = `${baseUrl}/medication/medication/calendar/?${conditionString}`;

  return get(url);
};

export const getMedicationList = (clientId) => {
  const baseUrl = getBaseUrl();

  let url = `${baseUrl}/medication/medication/name/prn/?client=${clientId}`;

  return get(url);
};

export const getClientDiary = (values, pageSize, clientId) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrlMicroServices2();

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = moment(values.choose_date).format('YYYY-MM-DD');
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format

  datesRange = 'dateOfRecord=' + new_start_date;

  conditionString += datesRange;

  if (clientId) {
    conditionString += '&client=' + clientId;
  }

  //conditionString+='&view=Web'
  let url = '';
  url = `${baseUrl}/scheduler/client-consolidate-data/clientConsolidatedData/?${conditionString}`;

  return get(url);
};


export const multiClientDiary = (values, pageSize, clientId) => {
  const baseUrl = getBaseUrlMicroServices2();
  let stringified = ''
  let toParse = {};
  if (clientId) {
    toParse.client = clientId
  }
  if (values.date_to) {
    toParse.date_to = values.date_to
  }
  if (values.choose_date) {
    toParse.date_from = values.date_from
  }
  stringified = queryString.stringify(toParse) || '';
  console.log(toParse, "toParse")
  const url = `${baseUrl}/scheduler/client-consolidate-data/clientConsolidatedDateRange/?${stringified}`
  return get(url);
};


export const getDownloadMedicationFile = async (values, pageSize, clientId) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrlMicroServices();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }

  if (values.medication_name) {
    let runNameFilter = values.medication_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_name = strRunNameFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = moment(values.start_date).format('YYYY-MM-DD');
  const new_end_date = moment(values.end_date).format('YYYY-MM-DD');
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format

  datesRange = 'start_date__gte=' + new_start_date;
  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date__lte=' + new_end_date;

  conditionString += datesRange;

  if (filterParse.employees) {
    conditionString += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += '&client__id__in=' + filterParse.clients;
  }
  if (filterParse.medication_name) {
    conditionString += '&name__id__in=' + filterParse.medication_name;
  }

  if (clientId) {
    conditionString += '&client__id__in=' + clientId;
  }

  conditionString += '&view_set=MedicationReport&view=Web';

  let url = '';
  url = `${baseUrl}/clients/app/download-excel-file?${conditionString}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};
export const getDownloadMedicationPdf = async (values, pageSize, clientId) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }

  if (values.medication_name) {
    let runNameFilter = values.medication_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_name = strRunNameFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = moment(values.start_date).format('YYYY-MM-DD');
  const new_end_date = moment(values.end_date).format('YYYY-MM-DD');
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format

  datesRange = 'start_date__gte=' + new_start_date;
  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&end_date__lte=' + new_end_date;

  conditionString += datesRange;

  if (filterParse.employees) {
    conditionString += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += '&client__id__in=' + filterParse.clients;
  }
  if (filterParse.medication_name) {
    conditionString += '&name__id__in=' + filterParse.medication_name;
  }

  if (clientId) {
    conditionString += '&client__id__in=' + clientId;
  }

  conditionString += '&view=Web';

  let url = '';
  url = `${baseUrl}/medication/medication/chart/get_pdf/?${conditionString}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'text/html',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};

export const getDownloadMedicationPdfCalendar = async (
  values,
  pageSize,
  clientId
) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrlMicroServices();
  console.log(values, "values body")
  let stardate = moment(values?.start_date).toISOString()
  let endDate = moment(values?.end_date).subtract(1).toISOString()
  console.log(endDate, stardate, "date body")
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.Unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }

  if (values.medication_name) {
    let runNameFilter = values.medication_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.medication_name = strRunNameFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;



  let conditionString = '';
  conditionString += `start_date__gte=${stardate?.split("T")[0]}`
  conditionString += `&end_date__lte=${endDate?.split("T")[0]}`
  if (filterParse.employees) {
    conditionString += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += '&client__id__in=' + filterParse.clients;
  }
  if (filterParse.medication_name) {
    conditionString += '&name__id__in=' + filterParse.medication_name;
  }

  if (clientId) {
    conditionString += '&client__id__in=' + clientId;
  }

  conditionString += '&view=Web';
  console.log(conditionString, "conditionString")
  let url = `${baseUrl}/medication/medication/calendar/get_pdf/?${conditionString}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'text/html',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};

export const getClients = (values, pageSize) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }

  if (pageSize) {
    toParse.limit = pageSize || PAGE_SIZE_RENDER;
  }
  if (values.client_type) {
    let client_typeFilter = values.client_type.map(data => data.id);
    let strclient_typeFilter = client_typeFilter.join(',');
    filterParse.client_type = strclient_typeFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id);
    let strclient_statusFilter = client_statusFilter.join(',');
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client_type) {
    stringified += '&type_id__in=' + filterParse.client_type;
  }
  if (filterParse.client_status) {
    stringified += '&status__id__in=' + filterParse.client_status;
  }
  if (filterParse.runroute) {
    stringified += '&client_run_routes=' + filterParse.runroute;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch__id__in=' + filterParse.headerbranch;
    }
  }

  // const url = `${baseUrl}/clients/?${stringified}`;
  const url = stringified
    ? `${baseUrl}/clients/?${stringified}`
    : `${baseUrl}/clients/`;
  return get(url);
};

export const getClientsList = async (values, tab) => {
  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  if (values.client_type) {
    let client_typeFilter = values.client_type.map(data => data.id);
    let strclient_typeFilter = client_typeFilter.join(',');
    filterParse.client_type = strclient_typeFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id);
    let strclient_statusFilter = client_statusFilter.join(',');
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client_type) {
    stringified += '&type_id__in=' + filterParse.client_type;
  }
  if (filterParse.client_status) {
    stringified += '&status__id__in=' + filterParse.client_status;
  }
  if (filterParse.runroute) {
    stringified += '&client_run_routes=' + filterParse.runroute;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch__id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch__id__in=' + filterParse.headerbranch;
    }
  }

  const url = stringified
    ? `${baseUrl}/clients/download-client?view_set=${tab}${stringified}`
    : `${baseUrl}/clients/download-client?view_set=${tab}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};
export const getClientsEmail = async (date_from, date_to, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (date_from) {
    toParse.date_from = date_from;
  }
  if (date_to) {
    toParse.date_to = values.date_to;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (date_from) {
    let startDateFilter = date_from;
    let strStartDateFilter = startDateFilter;
    filterParse.date_from = strStartDateFilter;
  }
  if (date_to) {
    let endDateFilter = date_to;
    let strEndDateFilter = endDateFilter;
    filterParse.date_to = strEndDateFilter;
  }
  if (filterParse.date_from) {
    stringified += '&start_date=' + filterParse.date_from;
  }
  if (filterParse.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  if (filterParse.date_to) {
    stringified += '&end_date=' + filterParse.date_to;
  }
  if (values.ordering) {
    stringified += '&order_by=' + values.ordering;
  }
  const url = `${baseUrl}/scheduler/client-visit-mail/?${stringified}`;

  return get(url);
};

export const getMedicationReview = async id => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  if (id) {
    stringified += 'medication=' + id;
  }
  const url = `${baseUrl}/medication/getMedicationReview/?${stringified}`;

  return get(url);
};
export const getMedicationError = async id => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  if (id) {
    stringified += 'medication=' + id;
  }
  const url = `${baseUrl}/medication/getMedicationError/?${stringified}`;
  return get(url);
};
export const getClientsFilter = async (values, tab) => {
  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};

  if (values.start_date) {
    toParse.visit__start_date__gte = values.start_date;
  }
  if (values.end_date) {
    toParse.visit__end_date__lte = values.end_date;
  }
  toParse.ordering = '-visit__start_date';
  toParse.is_export = 'True'
  toParse.dateFilterType = 'Visit Date';
  toParse.view_set = tab 
  stringified = queryString.stringify(toParse) || '';
  const url = stringified
    ? `${baseUrl}/clients/download-client?${stringified}`
    : `${baseUrl}/clients/download-client?view_set=${tab}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};

export const exportServiceContract = async (values, viewSet) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};


  toParse.view_set = viewSet

  if (values.start_date) {
    toParse.start_date = values.start_date;
  }
  if (values.end_date) {
    toParse.end_date = values.end_date;
  }

  if (values.clients) {
    const clientsFilter = values.clients.map(data => data.id);
    const joinedIds = clientsFilter.join(',');
    filterParse.clients = joinedIds;
  }

  if (values.branchs) {
    const branchFilter = values.branchs.map(data => data.id);
    const joinedIds = branchFilter.join(',');
    filterParse.headerbranch = joinedIds;
  } else if (values.headerbranch) {
    const branchFilter = values.headerbranch.map(data => data.id);
    const joinedIds = branchFilter.join(',');
    filterParse.headerbranch = joinedIds;
  }



  stringified = queryString.stringify(toParse) || '';

  if (filterParse.headerbranch) {
    stringified += '&branch=' + filterParse.headerbranch
  }
  if (filterParse.clients) {
    stringified += '&client_id=' + filterParse.clients
  }
  // https://stage.myhelpa.com/org/sonaliapptest/api/v1/clients/download-client?end_date=2024-04-24&start_date=2024-03-31&view_set=Client_Service_Contracts
  const url = `${baseUrl}/clients/download-client?${stringified}`
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
}
export const getClientsTaskFilter = async (values, tab) => {
  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.start_date) {
    toParse.start_date = values.start_date;
  }
  // if (values.Clients) {

  //   toParse.Clients = values.Clients.map((item)=> item.id);
  // }
  // if (values.branch) {
  //   toParse.branch = values.branch.map((item)=> item.id);
  // }
  if (values.end_date) {
    toParse.end_date = values.end_date;
  }
  if (values.start_date) {
    let startDateFilter = values.start_date;
    let strStartDateFilter = startDateFilter;
    filterParse.start_date = strStartDateFilter;
  }
  if (values.clients) {
    let clientDateFilter = values.clients.map(item => item.id);
    let strClientDateFilter = clientDateFilter;
    filterParse.clients = strClientDateFilter;
  }
  if (values.branch) {
    let branchDateFilter = values.branch.map(item => item.id);
    let strBranchDateFilter = branchDateFilter;
    filterParse.branch = strBranchDateFilter;
  }
  if (values.end_date) {
    let endDateFilter = values.end_date;
    let strEndDateFilter = endDateFilter;
    filterParse.end_date = strEndDateFilter;
  }
  if (filterParse.start_date) {
    stringified += '&startDate=' + filterParse.start_date;
  }
  if (filterParse.end_date) {
    stringified += '&endDate=' + filterParse.end_date;
  }
  if (filterParse.clients) {
    stringified += '&client_id=' + filterParse.clients;
  }
  if (filterParse.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  const url = stringified
    ? `${baseUrl}/clients/app/download-excel-file?view_set=${tab}${stringified}`
    : `${baseUrl}/clients/app/download-excel-file?view_set=${tab}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};
export const getClientKpiDailyFilter = async (values, tab) => {
  const baseUrl = getBaseUrlReport();
  let stringified = '';
  //let toParse = {};
  let filterParse = {};
  // if (values.start_date) {
  //   toParse.start_date = values.start_date;
  // }
  // if (values.end_date) {
  //   toParse.end_date = values.end_date;
  // }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  // if (values.start_date) {
  //   let startDateFilter = values.start_date;
  //   let strStartDateFilter = startDateFilter;
  //   filterParse.start_date = strStartDateFilter;
  // }
  // if (values.end_date) {
  //   let endDateFilter = values.end_date;
  //   let strEndDateFilter = endDateFilter;
  //   filterParse.end_date = strEndDateFilter;
  // }
  let startString = (tab === 'financekpi') ? 'schedule_start_date__gte=' : 'startDate='
  let endString = (tab === 'financekpi') ? '&schedule_end_date__lte=' : '&endDate='
  if (values.start_date) {
    stringified += startString + values.start_date;
  }
  if (filterParse.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  if (values.end_date) {
    stringified += endString + values.end_date;
  }
  let data = tab === 'financekpi' ? `&data_format=${values.kpi}` : `&dataFormat=${values.kpi}`
  let day = tab === 'financekpi' ? `&data_format=${values.kpi}` : `&dataFormat=${values.kpi}`

  let url =
    tab === 'visit'
      ? `${baseUrl}/core/visit-compliance-kpi/visitComplianceReports/?${stringified}${day}`
      : tab === 'kpi'
        ? `${baseUrl}/core/client-kpi/clientKpiReports/?${stringified}${data}`
        : tab === 'teamkpi'
          ? `${baseUrl}/core/team-kpi/teamKpiReports/?${stringified}${day}`
          : tab === 'qualitykpi'
            ? `${baseUrl}/core/quality-assurance-kpi/qualityAssuranceReport/?${stringified}${day}`
            : tab === 'triagekpi'
              ? `${baseUrl}/core/alerts-kpi/alertsKpiReports/?${stringified}${data}`
              : tab === 'medicationkpi'
                ? `${baseUrl}/core/medication-kpi/medicationKpiReports/?${stringified}${data}`
                : tab === 'financekpi' ? `${baseUrl}/reports/finance/kpi/download/?${stringified}${data}` : ''

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};
export const getEmployeeEmail = async (date_from, date_to, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (date_from) {
    toParse.date_from = date_from;
  }
  if (date_to) {
    toParse.date_to = values.date_to;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (date_from) {
    let startDateFilter = date_from;
    let strStartDateFilter = startDateFilter;
    filterParse.date_from = strStartDateFilter;
  }
  if (date_to) {
    let endDateFilter = date_to;
    let strEndDateFilter = endDateFilter;
    filterParse.date_to = strEndDateFilter;
  }
  if (filterParse.date_from) {
    stringified += '&start_date=' + filterParse.date_from;
  }
  if (filterParse.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  if (filterParse.date_to) {
    stringified += '&end_date=' + filterParse.date_to;
  }
  if (values.ordering) {
    // const patterns = ["full_name", "-full_name", "contact__email_personal", "-contact__email_personal", "-contact__email_personal,full_name", "contact__email_personal,-full_name", "-contact__email_personal,-full_name"];
    // if(patterns.includes(values.ordering)){
    stringified += "&order_by=" + values.ordering
    // }
  }
  const url = `${baseUrl}/scheduler/client-visit-mail/employee_listing/?${stringified}`;

  return get(url);
};

export const getVisitTask = async (Id, values) => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {

  if (values.employee_name) {
    let runRouteFilter = values.employee_name.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_name = strRunRouteFilter;
  }

  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&start_date=' + values.date_from;
  }
  if (values.date_to) {
    stringified += '&end_date=' + values.date_to;
  }
  if (filterParse.employee_name) {
    stringified += '&employee=' + filterParse.employee_name;
  }
  if (values.visit_start_date) {
    stringified += '&start_date=' + values.visit_start_date;
  }
  if (values.service_visit) {
    stringified += '&service_visit=' + values.service_visit;
  }
  if (Id) {
    stringified += `&visit_id=${Id}`
  }
  toParse.offset = values.offset;
  // }
  toParse.limit = values.limitperpage;

  //stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/clients/client/task-visit-wise/?${stringified}`;

  return get(url);
};

export const getClientDetails = async (date_from, date_to, Id) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/client-visit-mail/get_client_visit_data/?start_date=${date_from}&end_date=${date_to}&client_id=${Id}`;

  return get(url);
};

export const getEmployeeDetails = async (date_from, date_to, Id) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/client-visit-mail/get_employee_visit_data/?start_date=${date_from}&end_date=${date_to}&employee=${Id}`;
  return get(url);
};

export const getEmployeeBulkPreviewData = async (date_from, date_to, Id) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/client-visit-mail/getBulkEmployeeVisitData/?start_date=${date_from}&end_date=${date_to}&employee=${Id}`;
  return get(url);
};

export const getClientBulkPreviewData = async (date_from, date_to, Id) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/client-visit-mail/getBulkClientVisitData/?start_date=${date_from}&end_date=${date_to}&client=${Id}`;
  return get(url);
};

export const deleteClient = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${id}/`;

  return deleteFetch(url);
};

export const deleteClientTadks = async id => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/clients/client/task-client/${id}/`;

  return deleteFetch(url);
};
export const deleteClientFormBuilder = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/form-builder/client/form/${id}/`;

  return deleteFetch(url);
};

export const deleteEmployeeFormBuilder = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/form-builder/employee/form/${id}/`;

  return deleteFetch(url);
};
export const getClient = id => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/clients/${id}/`;

  return get(url);
};

export const getMedicationDoseById = (id, clientId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medication/chart/${id}/?client__id__in=${clientId}`;

  return get(url);
};

export const getMedDetailById = (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medication/prn/schedule/?id=${id}`;

  return get(url);
};

export const getPlannedDataById = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/planned/specific/task/get_group_specific_task/?group_specific=${id}`;

  return get(url);
};

export const getUploadedFiles = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
console.log(values,"params")

    toParse.offset = values.offset;
    toParse.limit = values.limitperpage;
    toParse.client__id__in = values.id;

  stringified = queryString.stringify(toParse);
  console.log(stringified,toParse,"stringified")
  const url = `${baseUrl}/clients/file-upload/client/?&${stringified}`;

  return get(url);
};

export const getClientTaskTab = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/planned/client/task/get_planned_task/?client_id=${id}`;

  return get(url);
};

export const getPlannedGroupTask = id => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/core/group-permission-task/group_care_task/?group=${id}`;

  return get(url);
};

export const getUploadedFilesDownload = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/file-upload/client/download_client_file/?id=${id}`;

  return get(url);
};

export const setClientUploadFile = async values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/file-upload/client/`;

  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (value !== null) {
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const setClient = async client => {
  const baseUrl = getBaseUrl2();
  let formData = new FormData();
  //remove nulls and append data format
  console.log(client, "client")
  for (let [key, value] of Object.entries(client)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'photo' &&
      key !== 'client_privacy' &&
      key !== 'client_services' &&
      key !== 'client_run_routes'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (key === 'client_privacy' || key === 'client_services' || key === 'client_run_routes') {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  // if (!client.id) {
  //   formData.append('is_active', true);
  // }

  const method = client.id ? PATCH : POST;
  const url = client.id
    ? `${baseUrl}/clients/${client.id}/`
    : `${baseUrl}/clients/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  //added new lines
  // if (response && response.status === unauthorized_status_code) {
  //   clearToken();
  //   window.location.reload();
  // }

  const json = await response.json();

  return { status: response.status, message: json };
};
export const setClientContract = async (contract, clientId) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(contract)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'visits' &&
      key !== 'service_funders' &&
      key !== 'bill_rate' &&
      key !== 'pay_rate' &&
      key !== 'travel_pay_rate'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key === 'visits' ||
      key === 'service_funders' ||
      key === 'bill_rate' ||
      key === 'pay_rate' ||
      key === 'travel_pay_rate'
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  // if (!client.id) {
  //   formData.append('is_active', true);
  // }

  const method = contract.id ? PATCH : POST;
  const url = contract.id
    ? `${baseUrl}/clients/${clientId}/services/${contract.id}/?client_id=${clientId}`
    : `${baseUrl}/clients/${clientId}/services/?client_id=${clientId}`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  //added new lines
  // if (response && response.status === unauthorized_status_code) {
  //   clearToken();
  //   window.location.reload();
  // }

  const json = await response.json();

  return { status: response.status, message: json };
};

export const setClientContractVisits = async (body, clientId) => {
  const baseUrl = getBaseUrl3();

  const method = body.id ? PATCH : POST;
  const url = body.id ? `${baseUrl}/clients/client/contract/visits/${body.id}/` : `${baseUrl}/clients/client/contract/visits/`

  return post(url, body, method);
};

export const setClientContractFunder = async (body, clientId, isLocation = false) => {
  const baseUrl = getBaseUrl3();
  let formData = new FormData();
  //remove nulls and append data format
  // for (let [key, value] of Object.entries(body)) {
  //   if (
  //     typeof value === 'object' &&
  //     value !== null &&
  //     key !== 'visits' &&
  //     key !== 'service_funders' &&
  //     key !== 'bill_rate' &&
  //     key !== 'pay_rate' &&
  //     key !== 'travel_pay_rate'
  //   ) {
  //     for (let [keySub, valueSub] of Object.entries(value)) {
  //       if (valueSub !== null) {
  //         formData.append(
  //           `${key}.${keySub}`,
  //           Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
  //         );
  //       } else {
  //         formData.append(`${key}.${keySub}`, '');
  //       }
  //     }
  //   } else if (
  //     key === 'visits' ||
  //     key === 'service_funders' ||
  //     key === 'bill_rate' ||
  //     key === 'pay_rate' ||
  //     key === 'travel_pay_rate'
  //   ) {
  //     formData.append(key, JSON.stringify(value));
  //   } else {
  //     if (value !== null) {
  //       formData.append(key, value);
  //     } else {
  //       formData.append(key, '');
  //     }
  //   }
  // }

  const method = PATCH;
  const url = isLocation
    ? `${baseUrl}/clients/${clientId}/services/${body.id}/?is_location=true`
    : `${baseUrl}/clients/${clientId}/services/${body.id}/`
  // const response = await fetch(url, {
  //   body: formData,
  //   headers: {
  //     Authorization: 'Token ' + getToken(),
  //   },
  //   method: method,
  // });

  // //added new lines
  // // if (response && response.status === unauthorized_status_code) {
  // //   clearToken();
  // //   window.location.reload();
  // // }

  // const json = await response.json();

  // return { status: response.status, message: json };
  return post(url, body, method);
};

export const setClientMedication = async (contract, clientId) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(contract)) {
    if (value && key === 'body_part') {
      formData.append(key, JSON.stringify(value));
    }
    if (typeof value === 'object' && value && key !== 'medication_dose' && key !== 'body_part') {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          if (key !== 'body_part') {
            formData.append(`${key}.${keySub}`, '');
          }
        }
      }
    } else if (value && key === 'medication_dose') {
      formData.append(key, JSON.stringify(value));
    }
    else {
      if ((value || key == 'expiry_date' || key == 'effective_date')) {
        if (key !== 'body_part') {
          formData.append(key, value);
        }
      }
      // else {
      //   formData.append(key, '');
      // }
    }
  }

  // if (!client.id) {
  //   formData.append('is_active', true);
  // }

  const method = contract.id ? PATCH : POST;
  const url = contract.id
    ? `${baseUrl}/medication/${contract.id}/`
    : `${baseUrl}/medication/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  //added new lines
  // if (response && response.status === unauthorized_status_code) {
  //   clearToken();
  //   window.location.reload();
  // }

  const json = await response.json();

  return { status: response.status, message: json };
};

export const setClientMedicationReview = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/medication/update_medication_review/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setForceLogout = (id) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/users/custom_logout/`;
  const body = {

    user_id: id




  };

  return post(url, body, method);
};
export const createdHandback = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/handBack/handBackCreate/`;
  const body = {
    visits: values,
  };

  return post(url, body, method);
};

export const setClientMedicationBulkReview = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/medication/update_bulk_medication_review/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setMedClickEvent = (values, clientId, visitId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  let visit_id = '';
  if (visitId) {
    visit_id = `&isFromVisit=${visitId}`
  }
  const url = values.id
    ? `${baseUrl}/medication/medication/chart/${values.id}/?client__id__in=${clientId}${visit_id}`
    : `${baseUrl}/medication/medication/chart/?client__id__in=${clientId}${visit_id}`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setMedicationReview = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/medication/updateMedicationReview/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setMedicationError2 = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = clientId ? PATCH : POST;
  const url = clientId
    ? `${baseUrl}/medication/MedicationErrorUpdate/`
    : `${baseUrl}/medication/medicationError/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setClientTasks = (values, clientId) => {
  const baseUrl = getBaseUrl2();
  const method = clientId ? PATCH : POST;
  const url = clientId
    ? `${baseUrl}/clients/client/task-client/${clientId}/`
    : `${baseUrl}/clients/client/task-client/create_task/`;
  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setMedBulkEdit = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/medication/medication/calendar/bulk_edit/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setMedError = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/medication/medicationError/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setMedReview = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/medication/medication/calendar/update_review/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const setClientPrefernce = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/clients/client/demographics/${values.id}/?client_id=${clientId}`
    : `${baseUrl}/clients/client/demographics/?client_id=${clientId}`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setClientTask = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/clients/planned/specific/task/update_client_specific_task/`;
  // const url = `${baseUrl}/clients/planned/client/task/update_planned_task/?client_id=${clientId}`

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const deleteMedication = async (id, clientId, force) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/${id}/?client_id=${clientId}&force=${force}`;

  return deleteFetch(url);
};

// export const deleteMedication = (id, values) => {
//   const baseUrl = getBaseUrl();
//   const method = PATCH;
//   const url = `${baseUrl}/medication/${id}/`;
//   // const url = `${baseUrl}/clients/planned/client/task/update_planned_task/?client_id=${clientId}`

//   const body = {
//     ...values,
//   };

//   return post(url, body, method);
// };

export const setVisitTask = (data, visitid, values) => {
  const baseUrl = getBaseUrl2();
  const method = PATCH;
  debugger
  let stringified = '';
  const toParse = {}
  if (values.visit_start_date) {
    toParse.start_date = values.visit_start_date
  }
  if (values.service_visit) {
    toParse.service_visit = values.service_visit
  }
  if (visitid) {
    toParse.visit = visitid
  }


  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/client/task-visit-wise/update_task_visit_wise/?${stringified}`;

  const body = {
    ...data,
    visitid,
  };

  return post(url, body, method);
};

export const sendEmailClient = (values, Id) => {
  const baseUrl = getBaseUrl();
  let stringified = '';

  if (values.selectedClient && !Id) {
    let strclient_typeFilter = values.selectedClient.join(',');
    stringified = strclient_typeFilter;
  } else {
    stringified = Id;
  }

  const method = POST;
  const url = `${baseUrl}/scheduler/client-template-mail-send/client_mail_send/?start_date=${values.date_from}&end_date=${values.date_to}&client_id=${stringified}`;

  const body = {};

  return post(url, body, method);
};
export const sendEmailEmployee = (values, Id) => {
  const baseUrl = getBaseUrl();
  let stringified = '';

  if (values.selectedClient && !Id) {
    let strclient_typeFilter = values.selectedClient.join(',');
    stringified = strclient_typeFilter;
  } else {
    stringified = Id;
  }

  const method = POST;
  const url = `${baseUrl}/scheduler/client-template-mail-send/employee_mail_send/?start_date=${values.date_from}&end_date=${values.date_to}&employee_id=${stringified}`;

  const body = {};

  return post(url, body, method);
};
export const getClientPreference = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/demographics/?client_id=${id}`;

  return get(url);
};
export const getClientsServices = values => {
  const baseUrl = getBaseUrl3();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  // const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;
  const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;


  return get(url);
};

export const getClientsServicesAll = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;
  //const url = `${baseUrl}/clients/${values.selectedClientId}/services/?${stringified}`;


  return get(url);
};

export const getMedicationData = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/medication/?client_id=${values.selectedClientId}&${stringified}`;

  return get(url);
};
// http://localhost:8000/org/sonaliapptest/api/v1/
export const getMedicationStock = values => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medication/stock/initial/?client__id__in=${values}`;
  return get(url);
};

export const createMedication = (values, val, id, clientId) => {
  // http://localhost:8000/org/sonaliapptest/api/v1/medication/medication/stock/initial/
  const baseUrl = getBaseUrl();
  const method = val ? POST : PATCH;
  const url = val
    ? `${baseUrl}/medication/medication/stock/initial/`
    : `${baseUrl}/medication/medication/stock/initial/${id}/?client__id__in=${clientId}`;

  return post(url, values, method);
};

export const getPlanned = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/planned/specific/task/get_client_specific_task/?client_id=${values.selectedClientId}&${stringified}`;

  return get(url);
};

export const getSchedulerPlanned = clientDropdownId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/planned/specific/task/get_client_specific_task/?client_id=${clientDropdownId}`;
  return get(url);
};

export const getClientsServicesById = (clientId, serviceId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/`;

  return get(url);
};

export const getClientsServicesVisitsByVisitId = (clientId, serviceId) => {
  const baseUrl = getBaseUrl3();
  const url = `${baseUrl}/clients/client/contract/visits/${serviceId}/`;

  return get(url);
};

export const getMedicationById = (clientId, Id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/${Id}/?client_id=${clientId}`;

  return get(url);
};
export const getClientGroupTask = clientId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-preferred/grouptask/preferred_client_group_task/?client_id=${clientId}`;
  return get(url);
};
export const getClientTask = clientId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-preferred/task/preferred_client_task/?client_id=${clientId}`;

  return get(url);
};

export const setServiceContractVisit = async (clientId, serviceId, visit) => {
  const baseUrl = getBaseUrl();
  if (!clientId || !serviceId) {
    return;
  }

  const method = visit.id ? PATCH : POST;
  const url = visit.id
    ? `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visit.id}/`
    : `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/`;

  if (!visit.id) delete visit.id;
  if (!visit.id && visit.client_data) {
    delete visit.client_data;
  }
  const body = {
    ...visit,
  };

  delete body.skills_required;
  delete body.employee_required;

  return post(url, body, method);
};
export const setSeviceEmployeeRequired = async (
  clientId,
  serviceId,
  visitId,
  requirement
) => {
  const baseUrl = getBaseUrl();
  if (!clientId || !serviceId || !visitId) {
    return;
  }
  let seconds = requirement.duration;
  seconds = parseInt(seconds); //because moment js dont know to handle number in string format
  let formatted =
    Math.floor(moment.duration(seconds, 'seconds').asHours()) +
    ':' +
    moment.duration(seconds, 'seconds').minutes();
  const method = POST;
  // const url = requirement.id
  //   ? `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/${requirement.id}`
  //   : `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/`;
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/`;
  // if (!visit.id) delete visit.id;

  const body = {
    service_visit: visitId,
    no_of_employees: parseInt(requirement.no_of_employees),
    preferred_gender: requirement.gender,
    skill_level: requirement.skill_level,
    start_time: requirement.start_time,
    end_time: requirement.end_time,
    duration: formatted,
  };

  // delete body.skills_required;

  return post(url, body, method);
};
export const setSeviceEmployeeSkills = async (
  clientId,
  serviceId,
  visitId,
  requireID,
  reqSkills
) => {
  const baseUrl = getBaseUrl();
  if (!clientId || !serviceId || !visitId || !requireID) {
    return;
  }
  const method = POST;
  // const url = skills.id
  //   ? `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/${requireID}/skills/skills.id`
  //   : `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/${requireID}/skills`;
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/${requireID}/skills/`;
  // if (!visit.id) delete visit.id;

  const body = {
    client_service_visit_requirement: requireID,
    skills: reqSkills,
  };

  // delete body.skills_required;

  return post(url, body, method);
};

export const deleteUploadedFile = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/file-upload/client/${id}/`;

  return deleteFetch(url);
};

export const deleteServiceContractVisit = async (
  clientId,
  serviceId,
  visit
) => {
  if (!visit.id) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visit.id}/`;

  return deleteFetch(url);
};

export const setClientServicesContract = async (clientId, values) => {
  if (!clientId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/clients/${clientId}/services/${values.id}/`
    : `${baseUrl}/clients/${clientId}/services/`;

  const body = {
    ...values,
  };

  delete body.visits;

  return post(url, body, method);
};

export const deleteServiceContract = async (idSelected) => {
  const baseUrl = getBaseUrl2();
  // const url = `${baseUrl}/clients/${clientId}/services/${idSelected}/`;
  const url = `${baseUrl}/clients/client/contract/visits/${idSelected}/?is_force=true`;

  return deleteFetch(url);
};


export const shouldDeleteServiceContractApi = async (visitId) => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/clients/client/contract/visits/validate_service_visit_contract_visit_status/?client_service_visit=${visitId}`

  return await get(url)
}

export const shouldRemoveVisitEmployeeApi = async (empId) => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/clients/client/contract/visits/validate_service_visit_employee_visit_status/?client_service_visit_requirement=${empId}`

  return await get(url)
}



export const deleteServiceContractTemplate = async (idSelected, cvisit_id) => {
  const baseUrl = getBaseUrl();
  // const url = `${baseUrl}/clients/${clientId}/services/${idSelected}/`;
  const url = cvisit_id ? `${baseUrl}/core/core-service-contract/service-visit/${cvisit_id}/` : `${baseUrl}/core/core-service-contract/template/${idSelected}/`;

  return deleteFetch(url);
};

export const deletePlannedTasks = async (clientId, idSelected) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/planned/specific/task/delete_specific_group/?specific_group=${idSelected}`;

  return deleteFetch(url);
};

export const deleteCareTasks = async (group_id, care_id, clientId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/planned/specific/task/delete_specific_task/?specific_group_id=${group_id}&clientId=${clientId}&care_task=${care_id}`;

  return deleteFetch(url);
};

export const setServiceContractSplitFunder = async (
  clientId,
  serviceId,
  split
) => {
  if (!clientId || !serviceId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = split.id ? PATCH : POST;
  const url = split.id
    ? `${baseUrl}/clients/${clientId}/services/${serviceId}/split-funders/${split.id}/`
    : `${baseUrl}/clients/${clientId}/services/${serviceId}/split-funders/`;

  if (!split.id) delete split.id;

  const body = {
    ...split,
  };

  return post(url, body, method);
};

export const deleteServiceContractSplitFunder = async (
  clientId,
  serviceId,
  visit
) => {
  if (!visit.id) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/split-funders/${visit.id}/`;

  return deleteFetch(url);
};

export const getServiceContractVisitSkills = (clientId, serviceId, values) => {
  if (!clientId || !serviceId || !values) {
    return;
  }
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${values.id}/skills/?${stringified}`;

  return get(url);
};

export const setServiceContractVisitSkills = async (
  clientId,
  serviceId,
  visitId,
  skill
) => {
  if (!clientId || !serviceId || !visitId || !skill) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/skills/`;
  if (!skill.id) delete skill.id;

  const body = {
    ...skill,
  };

  return post(url, body, method);
};

export const deleteServiceContractVisitSkills = async (
  clientId,
  serviceId,
  skillValues
) => {
  if (!skillValues.client_service_visit || !skillValues.skill) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${skillValues.client_service_visit}/skills/${skillValues.skill}/`;

  return deleteFetch(url);
};
export const deleteServiceVisitEmployeeRequirement = async (
  clientId,
  serviceId,
  visitId,
  requireId
) => {
  // if (!skillValues.client_service_visit || !skillValues.skill) {
  //   return;
  // }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/requirement/${requireId}/`;

  return deleteFetch(url);
};
export const savePreferredEmployees = async (
  clientId,
  demographicId,
  employeeId
) => {
  if (!clientId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/${clientId}/demographics/${demographicId}/client-prefered/`;

  const body = {
    employee: employeeId,
  };

  return post(url, body, method);
};
export const saveSchedulerVisit = async (clientId, serviceId, visitId) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/scheduler/`;

  const body = {};

  return post(url, body, method);
};
export const deletePreferredEmployees = async (
  clientId,
  demographicId,
  preferredEmployeeId
) => {
  if (!clientId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/demographics/${demographicId}/client-prefered/${preferredEmployeeId}/`;

  return deleteFetch(url);
};

export const saveAvoidEmployees = async (
  clientId,
  demographicId,
  employeeId
) => {
  if (!clientId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/${clientId}/demographics/${demographicId}/client-avoid/`;

  const body = {
    employee: employeeId,
    reason: 'unset',
  };

  return post(url, body, method);
};

export const deleteAvoidEmployees = async (
  clientId,
  demographicId,
  avoidEmployeeId
) => {
  if (!clientId) {
    return;
  }
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/demographics/${demographicId}/client-avoid/${avoidEmployeeId}/`;

  return deleteFetch(url);
};

export const getVisitById = async (clientId, serviceId, visitId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${clientId}/services/${serviceId}/visits/${visitId}/`;

  return get(url);
};
export const getHandBackList = async (values, pageSize) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.limitperpage) {
    stringified += 'limit=' + values.limitperpage;
  }
  if (values.employees) {
    let employeFilter = values.employees.map(item => item.id);
    let strEmployee = employeFilter.join(',');
    filterParse.employees = strEmployee;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(item => item.id);
    let strBranch = branchFilter.join(',');
    filterParse.branch = strBranch;
  }

  if (filterParse.employees) {
    stringified += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/handBack/?${stringified}&offset=${values.offset}`;

  return get(url);
};
export const getSwapVisitList = async (values, pageSize) => {
  let stringified = '';
  let filterParse = {}

  const baseUrl = getBaseUrl2();

  if (values.limitperpage) {
    stringified += 'limit=' + values.limitperpage;
  }
  if (values.date_from) {
    stringified += '&start_date__gte=' + values.date_from + ' ' + '00:00:00';
  }
  if (values.date_to) {
    stringified += '&end_date__lte=' + values.date_to + ' ' + '23:59:59';
  }
  if (values.employee) {
    let employeeId = values.employee.map(item => item.id);
    let strEmployeeId = employeeId.join(',');
    filterParse.employee = strEmployeeId;
  }
  if (values.swap_employee) {
    let swapEmployeeId = values.swap_employee.map(item => item.id);
    let strSwapEmployeeId = swapEmployeeId.join(',');
    filterParse.swap_employee = strSwapEmployeeId;
  }
  if (filterParse.employee) {
    stringified += '&employee__id__in=' + filterParse.employee;
  }
  if (values.swap_employee) {
    stringified += '&swap_employee__in=' + filterParse.swap_employee;
  }
  const url = `${baseUrl}/scheduler/swapVisit/?${stringified}&offset=${values.offset && values.offset
    }`;

  return get(url);
};
export const getVisitAudit = async (values, pageSize) => {
  console.log(values, "AAAAA")
  let stringified = '';
  let filterParse = {}
  let toParse = {}
  const baseUrl = getBaseUrl();
  toParse.offset = values.offset;



  stringified = queryString.stringify(toParse) || '';
  if (values.limitperpage) {
    stringified += '&limit=' + values.limitperpage;
  }
  if (values.start_date__gte) {
    stringified += '&start_date=' + values.start_date__gte
  }
  if (values.ordering) {
    stringified += '&ordering=' + values.ordering
  }
  if (values.end_date__lte) {
    stringified += '&end_date=' + values.end_date__lte
  }
  if (values.taskPartiallyComplete__lte) {
    stringified += '&taskPartiallyComplete__lte=' + values.taskPartiallyComplete__lte
  }
  if (values.taskNotStarted__lte) {
    stringified += '&taskNotStarted__lte=' + values.taskNotStarted__lte
  }


  if (values.visitAlert__lte) {
    stringified += '&visitAlert__lte=' + values.visitAlert__lte
  }


  if (values.visitCareNotes__lte) {
    stringified += '&visitCareNotes__lte=' + values.visitCareNotes__lte
  }


  if (values.taskPartiallyComplete__gte) {
    stringified += '&taskPartiallyComplete__gte=' + values.taskPartiallyComplete__gte
  }
  if (values.taskNotStarted__gte) {
    stringified += '&taskNotStarted__gte=' + values.taskNotStarted__gte
  }


  if (values.visitAlert__gte) {
    stringified += '&visitAlert__gte=' + values.visitAlert__gte
  }

  if (values.visitCareNotes__gte) {
    stringified += '&visitCareNotes__gte=' + values.visitCareNotes__gte
  }


  if (values.employees) {
    let employeeId = values.employees.map(item => item.id);
    let strEmployeeId = employeeId.join(',');
    filterParse.employees = strEmployeeId;
  }
  if (values.visitstatus) {
    let visitId = values.visitstatus.map(item => item.id);
    let strVisitId = visitId.join(',');
    filterParse.visitstatus = strVisitId;
  }
  if (values.clients) {
    let swapClientId = values.clients.map(item => item.id);
    let strClientId = swapClientId.join(',');
    filterParse.clients = strClientId;
  }
  if (values.visitstatus) {
    let visitId = values.visitstatus.map(item => item.id);
    let strVisitId = visitId.join(',');
    filterParse.visitstatus = strVisitId;
  }
  if (values.branch) {
    let branchId = values.branch.map(item => item.id);
    let strBrnachId = branchId.join(',');
    filterParse.branch = strBrnachId;
  }
  if (filterParse.employees) {
    stringified += '&employee__id__in=' + filterParse.employees;
  }
  if (filterParse.clients) {
    stringified += '&client_service_visit__client__id__in=' + filterParse.clients;
  }
  if (filterParse.visitstatus) {
    stringified += '&visit_status__id__in=' + filterParse.visitstatus;
  }

  if (values.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  if (values.branch) {
    stringified += '&branch=' + filterParse.branch;
  }
  if (values.visitstatus) {
    stringified += '&visit_status__id__in=' + filterParse.visitstatus;
  }
  const url = `${baseUrl}/scheduler/visit-audit/getVisitAuditRecord/?${stringified}`;

  return get(url);
};
export const getSwapShiftList = async (values, pageSize) => {
  let stringified = '';
  let filterParse = {};

  const baseUrl = getBaseUrl2();

  if (values.limitperpage) {
    stringified += 'limit=' + values.limitperpage;
  }
  if (values.employee) {
    let employeeId = values.employee.map(item => item.id);
    let strEmployeeId = employeeId.join(',');
    filterParse.employee = strEmployeeId;
  }
  if (values.swap_employee) {
    let swapEmployeeId = values.swap_employee.map(item => item.id);
    let strSwapEmployeeId = swapEmployeeId.join(',');
    filterParse.swap_employee = strSwapEmployeeId;
  }
  if (filterParse.employee) {
    stringified += '&employee__id__in=' + filterParse.employee;
  }
  if (values.swap_employee) {
    stringified += '&swap_employee__in=' + filterParse.swap_employee;
  }

  const url = `${baseUrl}/shifts/shifts/getSwapEmployeeShift/?${stringified}&offset=${values.offset && values.offset
    }`;

  return get(url);
};
export const getVisits = async (values, limitPerPage) => {
  const baseUrl = getBaseUrl();

  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  // if (values.preferred_start_time) {
  //   toParse.preferred_start_time__gte = values.preferred_start_time;
  // }
  // if (values.preferred_end_time) {
  //   toParse.preferred_end_time__lte = values.preferred_end_time;
  // }
  // if (values.repeat_on) {
  //   toParse.repeat_on__contains = values.repeat_on;
  // }

  if (values.service_category) {
    let service_categoryFilter = values.service_category.map(data => data.id);
    let strservice_categoryFilter = service_categoryFilter.join(',');
    filterParse.service_category = strservice_categoryFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id);
    let strclient_statusFilter = client_statusFilter.join(',');
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id);
    let strfunder_sourceFilter = funder_sourceFilter.join(',');
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strclientFilter = clientFilter.join(',');
    filterParse.client = strclientFilter;
  }
  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.preferred_start_time) {
    filterParse.preferred_start_time__gte = values.preferred_start_time;
  }
  if (values.preferred_end_time) {
    filterParse.preferred_end_time__lte = values.preferred_end_time;
  }
  // if (values.repeat_on) {
  //   filterParse.repeat_on__contains = values.repeat_on;
  // }
  if (values.repeat_on) {
    let repeat_onFilter = values.repeat_on.map(data => data.id);
    let strrepeat_onFilter = repeat_onFilter.join(',');
    filterParse.repeat_on__contains = strrepeat_onFilter;
  }
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client) {
    stringified += '&Client_Service__Client__id__in=' + filterParse.client;
  }
  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.preferred_start_time__gte) {
    stringified +=
      '&preferred_start_time__gte=' + filterParse.preferred_start_time__gte;
  }

  if (filterParse.preferred_end_time__lte) {
    stringified +=
      '&preferred_end_time__lte=' + filterParse.preferred_end_time__lte;
  }

  if (filterParse.repeat_on__contains) {
    stringified += '&repeat_on__contains=' + filterParse.repeat_on__contains;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&Client_Service__Client__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&Client_Service__Client__branch_id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&Client_Service__Client__branch_id__in=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&Client_Service__Client__branch_id__in=' + filterParse.headerbranch;
    }
  }

  if (filterParse.service_category) {
    stringified += '&service_category_id__in=' + filterParse.service_category;
  }
  if (filterParse.client_status) {
    stringified +=
      '&Client_Service__Client__status_id__in=' + filterParse.client_status;
  }
  if (filterParse.funder_source) {
    stringified +=
      '&client_service__service_funder__category_id__in=' +
      filterParse.funder_source;
  }

  const url = stringified
    ? `${baseUrl}/clients/all/services/all/visits/?${stringified}`
    : `${baseUrl}/clients/all/services/all/visits/`;
  return get(url);
};

export const deleteVisit = async visitId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/all/services/all/visits/${visitId}/`;
  return deleteFetch(url);
};
export const getDownload = async (viewSet, values) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.start_date__gte) {
    toParse.start_date__gte = values.start_date__gte;
  }
  if (values.end_date__lte) {
    toParse.end_date_lte = values.end_date_lte;
  }
  // if (values.ordering) {
  //   // toParse.ordering = values.ordering;
  //   // for individual column name below lines
  //   let lastOdering = values.ordering.split(',').pop();
  //   toParse.ordering = lastOdering;
  // }
  // if (values.offset) {
  // toParse.offset = values.offset;
  // }

  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees = stremployeesFilter;
  }
  if (values.start_date__gte) {
    let startSDateFilter = values.start_date__gte;
    let strStartSDateFilter = startSDateFilter;
    filterParse.start_date__gte = strStartSDateFilter;
  }
  if (values.end_date__lte) {
    let endDateFilter = values.end_date__lte;
    let strEndDateFilter = endDateFilter;
    filterParse.end_date__lte = strEndDateFilter;
  }

  if (values.clients) {
    let clientsFilter = values.clients.map(data => data.id);
    let strclientsFilter = clientsFilter.join(',');
    filterParse.clients = strclientsFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  // stringified = queryString.stringify(toParse) || '';

  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.employees) {
    stringified += '&employee__id__in=' + filterParse.employees;
  }

  if (filterParse.clients) {
    stringified +=
      '&client_service_visit__client__id__in=' + filterParse.clients;
  }
  if (filterParse.start_date__gte) {
    stringified +=
      '&start_date__gte=' + filterParse.start_date__gte + ' ' + '00:00:00';
  }
  if (filterParse.end_date__lte) {
    stringified +=
      '&end_date__lte=' + filterParse.end_date__lte + ' ' + '23:59:59';
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' +
        filterParse.headerbranch;
    }
  }

  const baseUrl = getBaseUrlMicroServices();
  const url = `${baseUrl}/clients/app/download-excel-file?view_set=${viewSet}${stringified}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};
export const getCancelledVisits = (values, pageSize) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }

  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name below lines
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees = stremployeesFilter;
  }
  if (values.start_date__gte) {
    let startDateFilter = values.start_date__gte;
    let strStartDateFilter = startDateFilter;
    filterParse.start_date__gte = strStartDateFilter;
  }
  if (values.end_date__lte) {
    let endDateFilter = values.end_date__lte;
    let strEndDateFilter = endDateFilter;
    filterParse.end_date__lte = strEndDateFilter;
  }

  if (values.clients) {
    let clientsFilter = values.clients.map(data => data.id);
    let strclientsFilter = clientsFilter.join(',');
    filterParse.clients = strclientsFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  stringified = queryString.stringify(toParse) || '';

  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.employees) {
    stringified += '&employee__id__in=' + filterParse.employees;
  }

  if (filterParse.clients) {
    stringified +=
      '&client_service_visit__client__id__in=' + filterParse.clients;
  }
  if (filterParse.start_date__gte) {
    stringified +=
      '&start_date__gte=' + filterParse.start_date__gte + ' ' + '00:00:00';
  }
  if (filterParse.end_date__lte) {
    stringified +=
      '&end_date__lte=' + filterParse.end_date__lte + ' ' + '23:59:59';
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' +
        filterParse.headerbranch;
    }
  }

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/canceling_visit/?${stringified}`;
  return get(url);
};

export const getOfferVisits = (values, pageSize) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.start_date__gte) {
    toParse.start_date__gte = values.start_date__gte;
  }
  if (values.end_date__lte) {
    toParse.end_date_lte = values.end_date_lte;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name below lines
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }

  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.employees) {
    let employeesFilter = values.employees.map(data => data.id);
    let stremployeesFilter = employeesFilter.join(',');
    filterParse.employees = stremployeesFilter;
  }
  if (values.start_date__gte) {
    let startDateFilter = values.start_date__gte;
    let strStartDateFilter = startDateFilter;
    filterParse.start_date__gte = strStartDateFilter;
  }
  if (values.end_date__lte) {
    let endDateFilter = values.end_date__lte;
    let strEndDateFilter = endDateFilter;
    filterParse.end_date__lte = strEndDateFilter;
  }

  if (values.clients) {
    let clientsFilter = values.clients.map(data => data.id);
    let strclientsFilter = clientsFilter.join(',');
    filterParse.clients = strclientsFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  stringified = queryString.stringify(toParse) || '';

  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.employees) {
    stringified += '&employee__id__in=' + filterParse.employees;
  }

  if (filterParse.clients) {
    stringified +=
      '&client_service_visit__client__id__in=' + filterParse.clients;
  }
  if (filterParse.start_date__gte) {
    stringified +=
      '&start_date__gte=' + filterParse.start_date__gte + ' ' + '00:00:00';
  }
  if (filterParse.end_date__lte) {
    stringified +=
      '&end_date__lte=' + filterParse.end_date__lte + ' ' + '23:59:59';
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&client_service_visit__client__branch__id__in=' +
          branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&client_service_visit__client__branch__id__in=' +
        filterParse.headerbranch;
    }
  }

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/offer/visit/?${stringified}`;
  return get(url);
};

export const deleteOfferVisit = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/offer/visit/deleteOfferVisit/?visitId=${id}`;
  return deleteFetch(url);
};

export const patchDeleteIcon = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/${id}/`;
  const body = {
    is_deleted: is_deleted,
  };
  return post(url, body, method);
};
export const patchDeleteHandBack = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/handBack/declinedHandBack/`;
  const body = {
    visits: id,
  };
  return post(url, body, method);
};

export const patchApprovalHandBack = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/scheduler/handBack/approveHandBack/`;
  const body = {
    visits: id,
  };
  return post(url, body, method);
};
export const patchDeleteSwapVisit = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/scheduler/swapVisit/declinedSwapVisit/`;
  const body = {
    visits: id,
  };
  return patch(url, body, method);
};
export const DeleteSwapShift = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/shifts/shifts/swapShiftDeclined/`;
  const body = {
    id: id,
  };
  return patch(url, body, method);
};
export const patchDeleteSwap = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/scheduler/swapVisit/declinedSwapVisit/`;
  const body = {
    visits: id,
  };
  return patch(url, body, method);
};
export const patchDeleteSwapShift = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/shifts/shifts/swapShiftBulkDeclined/`;
  let newIdFormat = id.split(",")
  const body = {
    id: newIdFormat,
  };
  return patch(url, body, method);
};
export const patchApprovalSwapVisit = (id, is_deleted) => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/scheduler/swapVisit/${id}/`;
  const body = {
    visit_id: id,
  };
  return patch(url, body, method);
};
export const patchApprovalSwapShift = (id, is_deleted) => {

  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/shifts/shifts/swapBulkShiftApproval/`;
  let newIdFormat = id.split(",")
  const body = {
    id: newIdFormat,
  };
  return patch(url, body, method);
};
export const patchApprovalSwapShiftSplitOverride = (values, is_deleted) => {

  const method = PATCH;
  const baseUrl = getBaseUrl2();

  const url = `${baseUrl}/shifts/shifts/swapBulkShiftApproval/`;
  const body = {
    ...values,
  };
  return patch(url, body, method);
};
export const editCancelledVisit = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/visits/update_cancel_visit/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const editcareForumClient = (values, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/form-builder/client/form/${id}/?limit=20&offset=0`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const editcareForumEmployee = (values, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/form-builder/employee/form/${id}/?limit=20&offset=0`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const getDownloadFile = async (viewSet, values) => {
  const baseUrl = getBaseUrlMicroServices();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  if (values.preferred_start_time) {
    toParse.preferred_start_time__gte = values.preferred_start_time;
  }
  if (values.preferred_end_time) {
    toParse.preferred_end_time__lte = values.preferred_end_time;
  }
  if (values.repeat_on) {
    toParse.repeat_on__contains = values.repeat_on;
  }

  if (values.service_category) {
    let service_categoryFilter = values.service_category.map(data => data.id);
    let strservice_categoryFilter = service_categoryFilter.join(',');
    filterParse.service_category = strservice_categoryFilter;
  }
  if (values.client_status) {
    let client_statusFilter = values.client_status.map(data => data.id);
    let strclient_statusFilter = client_statusFilter.join(',');
    filterParse.client_status = strclient_statusFilter;
  }
  if (values.funder_source) {
    let funder_sourceFilter = values.funder_source.map(data => data.id);
    let strfunder_sourceFilter = funder_sourceFilter.join(',');
    filterParse.funder_source = strfunder_sourceFilter;
  }
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strclientFilter = clientFilter.join(',');
    filterParse.client = strclientFilter;
  }
  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_id = strbranchFilter;
  }
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client) {
    stringified += '&Client_Service__Client__id__in=' + filterParse.client;
  }
  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.branch_id) {
    stringified +=
      '&Client_Service__Client__branch_id__in=' + filterParse.branch_id;
  }
  if (filterParse.service_category) {
    stringified += '&service_category_id__in=' + filterParse.service_category;
  }
  if (filterParse.client_status) {
    stringified +=
      '&Client_Service__Client__status_id__in=' + filterParse.client_status;
  }
  if (filterParse.funder_source) {
    stringified +=
      '&client_service__service_funder__category_id__in=' +
      filterParse.funder_source;
  }
  const url = `${baseUrl}/clients/app/download-excel-file?view_set=${viewSet + stringified
    }`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
};

export const getPayRates = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/bill_rates/RateCard/rate_card_listing/`;

  return get(url);
};
export const setClientHealthDetails = async (health, clientId) => {
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  for (let [key, value] of Object.entries(health)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'client_client_group' &&
      key !== 'client_specific_communication_needs' &&
      key !== 'client_regulated_services' &&
      key !== 'client_user_type_description' &&
      key !== 'client_client_mobility' &&
      key !== 'client_medical_condition' &&
      key !== "allergy_and_intolerances"
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key == 'client_client_group' ||
      key == 'client_specific_communication_needs' ||
      key == 'client_regulated_services' ||
      key == 'client_user_type_description' ||
      key == 'client_client_mobility' ||
      key == 'client_medical_condition' ||
      key == "allergy_and_intolerances"
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const method = health.id ? PATCH : POST;
  const url = health.id
    ? `${baseUrl}/clients/client/health-details/${health.id}/?client_id=${clientId}`
    : `${baseUrl}/clients/client/health-details/?client_id=${clientId}`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return { status: response.status, message: json };
};

export const getClientHealthDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/health-details/?client_id=${id}`;

  return get(url);
};

export const setClientNotes = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client-description?client_id=${clientId}`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setCareNotes = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client-care-notes?client_id=${clientId}`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setMedicationNotes = (values, clientId) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client-medication-notes?client_id=${clientId}`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getClientNotes = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-description?client_id=${id}`;

  return get(url);
};
export const getClientTasks = values => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (values.client_id) {
    toParse.client_id = values.client_id;
  }

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  if (values.care_category) {
    let careCategoryId = values.care_category.map(item => item.id);
    let strCareCategory = careCategoryId.join(',');
    filterParse.care_category = strCareCategory;
  }
  if (values.care_task) {
    let careTaskId = values.care_task.map(item => item.id);
    let strCareTask = careTaskId.join(',');
    filterParse.care_task = strCareTask;
  }
  if (values.task_status) {
    let TaskStatus = values.task_status.map(item => item.id);
    let strTaskStatus = TaskStatus.join(',');
    filterParse.task_status = strTaskStatus;
  }
  if (values.task_type) {
    let TaskType = values.task_type.map(item => item.id);
    let strTaskType = TaskType.join(',');
    filterParse.task_type = strTaskType;
  }
  stringified = queryString.stringify(toParse) || '';

  if (values.care_category) {
    stringified +=
      '&care_task__task_type__care_category__id__in=' +
      filterParse.care_category;
  }
  if (values.care_task) {
    stringified += '&care_task__id__in=' + filterParse.care_task;
  }

  if (values.task_type) {
    stringified += '&care_task__task_type__id__in=' + filterParse.task_type;
  }
  if (values.task_status) {
    stringified += '&task_status__in=' + filterParse.task_status;
  }
  if (values.start_date__gte) {
    stringified +=
      '&start_date=' + values.start_date__gte;
  }
  if (values.end_date__lte) {
    stringified += '&end_date=' + values.end_date__lte;
  }
  const url = `${baseUrl}/clients/client/task-client/?&limit=${values.limitperpage && values.limitperpage}&${stringified}`;

  return get(url);
};

export const getClientFormBuilder = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (values.client_id) {
    toParse.client_id = values.client_id;
  }
  if (toParse.client_id) {
    stringified += '&client__id__in=' + toParse.client_id;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  toParse.limit = values.limitperpage;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/form-builder/client/form/?${stringified}`;
  return get(url);
};

export const getTeamFormBuilder = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.offset = values.offset;
  toParse.limit = values.limitperpage;

  if (values.emp_id) {
    toParse.employee__id__in = values.emp_id;
  }

  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/form-builder/employee/form/?${stringified}`;

  return get(url);
};

export const getClientTasksById = id => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  const url = `${baseUrl}/clients/client/task-visit-wise/?${id}`;

  return get(url);
};
export const getCareNotes = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-care-notes?client_id=${id}`;
  return get(url);
};

export const getMedicationNotes = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-medication-notes?client_id=${id}`;
  return get(url);
};

export const setClientImpContact = async (contact, clientId) => {
  const baseUrl = getBaseUrl2();
  let formData = new FormData();
  //remove nulls and append data format
  for (let [key, value] of Object.entries(contact)) {
    if (typeof value === 'object' && value !== null) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    }
    // else if(key==='visits'){
    //   formData.append(key,JSON.stringify(value))

    //  }
    else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  for (let [key, value] of Object.entries(contact)) {
    if (value !== null) {
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  }

  const method = contact.id ? PATCH : POST;
  const url = contact.id
    ? `${baseUrl}/clients/client/contact-details/${contact.id}/?client_id=${clientId}`
    : `${baseUrl}/clients/client/contact-details/?client_id=${clientId}`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return { status: response.status, message: json };
};
export const getClientsContracts = values => {
  const baseUrl = getBaseUrl2();
  let stringified = '';
  let toParse = {};

  if (values.offset) {
    toParse.offset = values.offset;
  }

  toParse.limit = PAGE_SIZE_RENDER;
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/client/contact-details/?client_id=${values.selectedClientId}&${stringified}`;

  return get(url);
};
export const getClientsContactById = (clientId, contactId) => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/clients/client/contact-details/${contactId}/?client_id=${clientId}`;

  return get(url);
};
export const deleteServiceContact = async (clientId, idSelected) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const body = {
    is_deleted: true,
  };
  const url = `${baseUrl}/clients/client/contact-details/${idSelected}/?client_id=${clientId}`;

  return post(url, body, method);
};
export const getPreferredVisits = clientId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-preferred-skills?client_id=${clientId}`;

  return get(url);
};
export const getPreferredCompetency = clientId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-preferred/competency/client_competency/?client_id=${clientId}`;

  return get(url);
};

export const getFundersList = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-details/funder_list/`;

  return get(url);
};

export const getMedicationDoseTimingOptionList = (clientId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medicationVisitWise/medicationDoseTime/?client=${clientId}`;

  return get(url);
};

export const getClientFundersList = values => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/get-funder-name?start_date=${values.date_from}&end_date=${values.date_to}`;

  return get(url);
};
export const getFundersData = values => {
  const baseUrl = getBaseUrl();
  
  const url = `${baseUrl}/core/choices/?key=funder_name&start_date=${values.start}&end_date=${values.end}`;

  return get(url);
};

export const getImpContacts = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-contact/core_contact_listing/`;

  return get(url);
};

export const getFunderDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-details/${id}/`;

  return get(url);
};

export const getContactDetails = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/core-contact/${id}/`;

  return get(url);
};

export const setVisitCareNotesDetails = async (
  bodyData,
  iddata,
  pageClientID
) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  let formData = new FormData();
  console.log(bodyData, "bodyData")
  for (let [key, value] of Object.entries(bodyData)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'care_notes_label'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    } else if (
      key == 'care_notes_label'
      // || key == 'alert_comment'
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  if (pageClientID) {
    toParse.client__id = pageClientID
  }
  stringified = queryString.stringify(toParse) || '';



  const method = iddata ? PATCH : POST;
  const url = iddata
    ? `${baseUrl}/clients/client/care-notes/${iddata}/?${stringified}`
    : `${baseUrl}/clients/client/care-notes/`;

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return { status: response.status, message: json };
};

export const getVisitCareNotes = (values, limitPerPage, selectedClientId) => {
  let stringified = '';
  let toParse = {};
  let filterdParse = {};
  const baseUrl = getBaseUrl();
  if (values.search) {
    toParse.search = values.search;
  }
  // if (values.ordering) {
  //   let lastOdering = values.ordering;
  //   toParse.ordering = lastOdering;
  // }
  toParse.offset = values.offset;
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  if (selectedClientId) {
    toParse.client__id__in = selectedClientId;
  }

  if (values.start_date) {
    toParse.visit__start_date__gte = values.start_date;
  }
  if (values.end_date) {
    toParse.visit__end_date__lte = values.end_date;
  }

  if (values.dateFilterType) {
    toParse.dateFilterType = values.dateFilterType;
  }

  if (values.privacy_type && values.privacy_type.length) {
    filterdParse.privacy_type = values.privacy_type.map((item) => item.id).join(",")
  }

  stringified = queryString.stringify(toParse) || '';

  if (filterdParse.privacy_type) {
    stringified += `&privacy_type=${filterdParse.privacy_type}`
  }

  if (values.ordering) {
    stringified += `&ordering=${values.ordering}`;
  }
  const url = `${baseUrl}/clients/client/care-notes/?${stringified}`;
  return get(url);
};

export const getVisitCareNotesByRowsId = (idSelected, pageClientID) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  if (pageClientID) {
    stringified += '&client__id=' + pageClientID;
  }
  const url = `${baseUrl}/clients/client/care-notes/${idSelected}/?${stringified}`;
  return get(url);
};

// export const deleteVisitCareNotes = (idSelected) => {
//   const baseUrl = getBaseUrl();
//   const method = DELETE;
//   const body={ }
//   const url = `${baseUrl}/clients/client/care-notes/${idSelected}/`;
//   return post(url, body, method);
// };

export const deleteVisitCareNotes = (idSelected, selectedClientId) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  stringified = queryString.stringify(toParse) || '';
  if (selectedClientId) {
    stringified += '&client__id=' + selectedClientId;
  }
  const url = `${baseUrl}/clients/client/care-notes/${idSelected}/?${stringified}`;
  return deleteFetch(url);
};

export const getHistoryNotes = idSelected => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/care-notes-history?care_notes=${idSelected}`;
  return get(url);
};


export const getCarePlans = async (clientId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/carePlans/carePlansRecord/?client=${clientId}`

  return await get(url)

}

// CLIENT BODY PART APIs
export const getClientBodyPart = async (clientId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.offset = values.offset
  toParse.limit = values.limitperpage;
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }

  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/clients/client/body-part/?${stringified}&client__id__in=${clientId}`
  return await get(url)
}

//Add body part
export const postAddBodyPart = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client/body-part/`

  return await post(url, body, method)
}

export const postBulkReviewStatus = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/clients/client/care-notes/update_client_care_notes_ids_data/`

  return await post(url, body, method)
}


//Update body part
export const updateBodyPart = async (body, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/clients/client/body-part/${id}/`
  return await post(url, body, method)
}

//Delete body part
export const deleteBodyPart = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/body-part/${id}/`
  return await deleteFetch(url)
}



// Client expense get api
export const getClientExpense = async (clientId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.offset = values.offset
  toParse.limit = values.limitperpage;
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  toParse.start_date = values.start_date

  if (values.end_date) {
    toParse.end_date = values?.end_date
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/employee/expense-client/?client_id=${clientId}&${stringified}`

  return await get(url)
}


//  Client hr task matrix list api
export const getClientHrTaskMatrix = async (values) => {
  const baseUrl = getBaseUrl();
  let stringified = ''
  let filterParse = {};
  let toParse = {};
  console.log(values, "tooo")

  //date range
  if (values.date_from) {
    toParse.start_date = values?.date_from
  }

  if (values.date_to) {
    toParse.end_date = values?.date_to
  }

  if (values.headerbranch) {
    const filteredBranch = values?.headerbranch?.map(item => item.id)
    const branchIds = filteredBranch?.filter(e => e !== 'None').join(',');
    filterParse.branch__id = branchIds
  }

  if (values.clients) {
    const client_ids = values?.clients?.join(",")
    filterParse.client_id = client_ids;
  }

  if (values.hr_task) {
    const hr_task_ids = values?.hr_task?.join(",")
    filterParse.core_hr_task_id = hr_task_ids
  }

  stringified = queryString.stringify(toParse) || '';


  if (filterParse.branch__id) {
    stringified += `&branch__id=${filterParse.branch__id}`
  }

  if (filterParse.client_id) {
    stringified += `&client_id=${filterParse.client_id}`
  }
  if (filterParse.core_hr_task_id) {
    stringified += `&core_hr_task_id=${filterParse.core_hr_task_id}`
  }

  const url = `${baseUrl}/clients/client/client-hr-task-matrix/?${stringified}`
  console.log(stringified, "called")

  return await get(url)
}


export const getClientHrTaskById = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/client-hr-task/${id}/`

  return await get(url)
}


export const shouldUpdateStatus = async (values) => {
  const baseUrl = getBaseUrlMicroServices();
  let stringified = ''
  let toParse = {};
  toParse.client = values.client
  toParse.final_client_date = values.final_client_date
  stringified = queryString.stringify(toParse) || '';
  // /clients/client/demographics/get_client_visit_end_status/?client=bfbf4e88-b618-4670-96d4-70e688a38fc2&final_client_date=2022-12-10
  const url = `${baseUrl}/clients/client/demographics/get_client_visit_end_status/?${stringified}`

  return await get(url)
}


export const exportClientVisitCareNotes = async (values, selectedClientId) => {
  // const baseUrl = getBaseUrlMicroServices();
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterdParse = {};

  if (selectedClientId) {
    toParse.client__id__in = selectedClientId;
  }

  if (values.start_date) {
    toParse.visit__start_date__gte = values.start_date;
  }
  if (values.end_date) {
    toParse.visit__end_date__lte = values.end_date;
  }

  if (values.dateFilterType) {
    toParse.dateFilterType = values.dateFilterType;
  }

  if (values.privacy_type && values.privacy_type.length) {
    filterdParse.privacy_type = values.privacy_type.map((item) => item.id).join(",")
  }

  stringified = queryString.stringify(toParse) || '';

  if (filterdParse.privacy_type) {
    stringified += `&privacy_type=${filterdParse.privacy_type}`
  }
  if (values.ordering) {
    stringified += `&ordering=${values.ordering}`;
  }

  stringified += `&is_export=True`

  const url = `${baseUrl}/clients/download-client?view_set=Export_Client_Care_Notes&${stringified}`

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
}

export const getClientCareForms = client_id => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.offset = 0;
  toParse.limit = 10000;
  if (client_id) {
    toParse.client_id = client_id;
  }


  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/form-builder/client/form/?${stringified}`;
  return newGet(url);
};


export const setClientCareForms = values => {
  const baseUrl = getBaseUrl();
  const method = POST;

  const body = {
    ...values
  }

  const url = `${baseUrl}/form-builder/client/form/`;
  return post(url, body, method);
};


// About me APIs starts

export const getDynamicAboutMe = async (clientId, apiKey) => {
  const baseUrl = getBaseUrl();
  const url =
    //  apiKey ==='about-me' 
    // ?`${baseUrl}/clients/client/about-me/?client_id=${clientId}`
    `${baseUrl}/clients/client/${apiKey}/?client_id=${clientId}`
  return await get(url)
}

export const setDynmicAboutMe = async (clientId, apiKey, body) => {
  const baseUrl = getBaseUrl();
  const method = body.id ? PATCH : POST
  const url = body.id
    ? `${baseUrl}/clients/client/${apiKey}/${body.id}/`
    : `${baseUrl}/clients/client/${apiKey}/`
  return await post(url, body, method)
}


export const getRiskAssessmentFiles = (values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.client__id__in = values.id;
  toParse.limit = values.limitperpage;
  toParse.offset = values.offset;

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/clients/risk/assessment/?${stringified}`;

  return get(url);
};

export const setRiskAssessmentFile = async values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/risk/assessment/ `;

  const formData = new FormData();
  for (let [key, value] of Object.entries(values)) {
    if (value !== null) {
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  }
  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });
  const json = await response.json();

  return { status: response.status, message: json };
};

export const deleteRiskAssessmentFile = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/risk/assessment/${id}/`;

  return deleteFetch(url);
};

export const getReviewSection = (id) => {
  const baseUrl = getBaseUrl();
  
  const url = `${baseUrl}/clients/client/review-care-notes/?clientCareNotes=${id}`;
  //http://localhost:8000/org/bill-test-org-1/api/v1/clients/client/review-care-notes/?clientCareNotes=id 
  return get(url);
};

export const setReviewSection = async (body, selectedId) => {
  const baseUrl = getBaseUrl();
  const method = selectedId ? PATCH : POST;

  const url = selectedId
    ? `${baseUrl}/clients/client/review-care-notes/${selectedId}/`
    : `${baseUrl}/clients/client/review-care-notes/`;


//http://localhost:8000/org/bill-test-org-1/api/v1/clients/client/review-care-notes/cf1fb236-52b6-400f-9983-c29b536e926a/    -  update
//http://localhost:8000/org/bill-test-org-1/api/v1/clients/client/review-care-notes/  - post

return await post(url, body, method)
};


export const fileStoreInOffline = async (clientId, values) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const body = {
    ...values
  }
  const url = `${baseUrl}/clients/file-upload/client/${clientId}/updateClientFileConnectivity/`;
  console.log(url,body, "updateClientFileConnectivity")
  return await Newpost(url, body, method)
};


export const formsStoreInOffline = async (rowId, values) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const body = {
    ...values
  }
  const url = `${baseUrl}/form-builder/client/form/${rowId}/updateClientFormConnectivity/`;
  //https://stage.myhelpa.com/org/bill-test-org-1/api/v1/form-builder/client/form/5ff5a9ec-779d-4352-a993-8b90d5f9d338/updateClientFormConnectivity/  console.log(url,body, "updateClientFileConnectivity")
  return await Newpost(url, body, method)
};
export const exportClientCronJob = async (values, apiKey) => {
  const baseUrl = getBaseUrlReport();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  let column_selector;

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values.column_selector && values.column_selector.length) {
    column_selector = values.column_selector.map(item => item.id)
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.service_category) {
    let ids = values.service_category.map(data => data.id).join(',');
    filterParse.service_category = ids;
  }

  if (values.visit_type) {
    let ids = values.visit_type.map(data => data.id).join(',');
    filterParse.visit_type = ids;
  }

  if (values.client) {
    let ids = values.client.map(data => data.id).join(',');
    filterParse.client = ids;
  }

  if (values.location) {
    let ids = values.location.map(data => data.id).join(',');
    filterParse.location = ids;
  }
  if (values.medication_dose) {
    let ids = values.medication_dose.map(data => data.id).join(',');
    filterParse.medication_dose = ids;
  }

  if (values.deleted_by) {
    let ids = values.deleted_by.map(data => data.id).join(',');
    filterParse.deleted_by = ids;
  }

  if (values.visit_status) {
    let ids = values.visit_status.map(data => data.id).join(',');
    filterParse.visit_status = ids;
  }
  if (values.client_status) {
    let ids = values.client_status.map(data => data.id).join(',');
    filterParse.client_status = ids;
  }
  
  if (values.start_date) {
    toParse.contract_start_date = values.start_date
  }
  if (values.medication_start_date) {
    toParse.medication_start_date = values.medication_start_date
  }
  if (values.deleted_date) {
    toParse.deleted__date = values.deleted_date
  }

  if (values.deleted_end_date) {
    toParse.deleted_end_date = values.deleted_end_date
  }

  if (values.visit_start_date) {
    toParse.visit_start_date = values.visit_start_date
  }

  
  if (values.visit_end_date) {
    toParse.visit_end_date = values.visit_end_date
  }


  stringified = queryString.stringify(toParse) || '';

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified += '&branch=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&branch=' + filterParse.headerbranch;
    }
  }


  if (filterParse.service_category) {
    stringified += `&service_category=${filterParse.service_category}`
  }
  if (filterParse.visit_type) {
    stringified += `&visit_type=${filterParse.visit_type}`
  }
  if (filterParse.client) {
    stringified += `&client=${filterParse.client}`
  }
  if (filterParse.location) {
    stringified += `&location=${filterParse.location}`
  }

  if (filterParse.medication_dose) {
    stringified += `&medication_dose=${filterParse.medication_dose}`
  }

  if (filterParse.deleted_by) {
    stringified += `&deleted_by=${filterParse.deleted_by}`
  }
  if (filterParse.visit_status) {
    stringified += `&visit_status=${filterParse.visit_status}`
  }
  if (filterParse.client_status) {
    stringified += `&client_status=${filterParse.client_status}`
  }


  // visit_status
  // deleted_by
  // deleted_date
  // visit_start_date
  // visit_end_date

  const url = stringified
    ? `${baseUrl}/reports/${apiKey}?${stringified}`
    : `${baseUrl}/reports/${apiKey}`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ column_selector: column_selector }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + getToken(),

    },
  });


  return response;
};


