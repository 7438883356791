import React, { useState, useEffect, useRef } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
  TabStyledNew,
} from 'shared/styles/constants/tagsStyles';
import {
  SETTINGS,
  PAYROLL,
  SETTINGS_FINANCE,
  PAGE_404,
  BILLING,
} from 'utils/constants/routes';
import Billing from 'components/DashboardComponents/Settings/SettingsFinance/Billing';
import Payroll from 'components/DashboardComponents/Settings/SettingsFinance/PayrollSettings';

import { getSettingFinanceBilling } from 'utils/api/SettingsApi';
import { useHistory } from 'react-router-dom';
import { setCompany } from 'utils/api/SettingsApi';
import { useContext } from 'react';
import { AppContext } from 'context';
import { setting_finance_choices } from 'utils/choiceConstant';
import { BILLING_SETTINGS, NON_COMMUTE_TRAVEL, NON_COMMUTE_TRAVEL_BILL, PAYROLL_SETTINGS, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';

const TAB_FINANCE_BILLING_URL = SETTINGS + SETTINGS_FINANCE;
const TAB_FINANCE_PAYROLL_URL = SETTINGS + SETTINGS_FINANCE + PAYROLL;


const SettingsFinance = props => {
  const {
    contextChoices: choices,
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    handleGetChoices,
  } = useContext(AppContext);

  let history = useHistory();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  const [billingTabData, setBillingTabData] = useState({});
  const [payrollTabData, setPayrollTabData] = useState({});
  // Permission state
  const [readPermissionBilling, setReadPermissionBilling] = useState(false)
  const [createPermissionBilling, setCreatePermissionBilling] = useState(false)
  const [updatePermissionBilling, setUpdatePermissionBilling] = useState(false)
  const [deletePermissionBilling, setDeletePermissionBilling] = useState(false)

  const [readPermissionBillingNonCommute, setReadPermissionBillingNonCommute] = useState(false)
  const [createPermissionBillingNonCommute, setCreatePermissionBillingNonCommute] = useState(false)
  const [updatePermissionBillingNonCommute, setUpdatePermissionBillingNonCommute] = useState(false)
  const [deletePermissionBillingNonCommute, setDeletePermissionBillingNonCommute] = useState(false)

  const [readPermissionPayroll, setReadPermissionPayroll] = useState(false)
  const [createPermissionPayroll, setCreatePermissionPayroll] = useState(false)
  const [updatePermissionPayroll, setUpdatePermissionPayroll] = useState(false)
  const [deletePermissionPayroll, setDeletePermissionPayroll] = useState(false)

  const [readPermissionPayrollNonCommute, setReadPermissionPayrollNonCommute] = useState(false)
  const [createPermissionPayrollNonCommute, setCreatePermissionPayrollNonCommute] = useState(false)
  const [updatePermissionPayrollNonCommute, setUpdatePermissionPayrollNonCommute] = useState(false)
  const [deletePermissionPayrollNonCommute, setDeletePermissionPayrollNonCommute] = useState(false)

  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  useEffect(() => {
    handleGetChoices(setting_finance_choices)
  }, [])

  const getApiData = async () => {
    const response = await getSettingFinanceBilling();
    setBillingTabData(response?.data?.billing || {});
    setPayrollTabData(response?.data?.payroll || {});
    return response
  }

  useEffect(() => {
    if (filterAdmin || filterSuperAdmin) {

      setReadPermissionBilling(true)
      setCreatePermissionBilling(true)
      setUpdatePermissionBilling(true)
      setDeletePermissionBilling(true)

      setReadPermissionBillingNonCommute(true)
      setCreatePermissionBillingNonCommute(true)
      setUpdatePermissionBillingNonCommute(true)
      setDeletePermissionBillingNonCommute(true)

      setReadPermissionPayroll(true)
      setCreatePermissionPayroll(true)
      setUpdatePermissionPayroll(true)
      setDeletePermissionPayroll(true)

      setReadPermissionPayrollNonCommute(true)
      setCreatePermissionPayrollNonCommute(true)
      setUpdatePermissionPayrollNonCommute(true)
      setDeletePermissionPayrollNonCommute(true)
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === SETTINGS_FINANCE) {
            rolePermissions[SETTINGS_FINANCE] = {};
            rolePermissions[SETTINGS_FINANCE][WEB_PERMISSION] = {};
            rolePermissions[SETTINGS_FINANCE][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION];
            rolePermissions[SETTINGS_FINANCE][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[SETTINGS_FINANCE][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[SETTINGS_FINANCE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        const rolesPermissions = contextSubSectionPermissions;
        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION];
          });
        }

        if (rolePermissions[BILLING_SETTINGS]) {
          setReadPermissionBilling(rolePermissions[BILLING_SETTINGS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionBilling(rolePermissions[BILLING_SETTINGS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionBilling(rolePermissions[BILLING_SETTINGS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionBilling(rolePermissions[BILLING_SETTINGS]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }

        if (rolePermissions[NON_COMMUTE_TRAVEL_BILL]) {
          setReadPermissionBillingNonCommute(rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionBillingNonCommute(rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionBillingNonCommute(rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionBillingNonCommute(rolePermissions[NON_COMMUTE_TRAVEL_BILL]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }

        if (rolePermissions[PAYROLL_SETTINGS]) {
          setReadPermissionPayroll(rolePermissions[PAYROLL_SETTINGS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionPayroll(rolePermissions[PAYROLL_SETTINGS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionPayroll(rolePermissions[PAYROLL_SETTINGS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionPayroll(rolePermissions[PAYROLL_SETTINGS]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }

        if (rolePermissions[NON_COMMUTE_TRAVEL]) {
          setReadPermissionPayrollNonCommute(rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION])
          setCreatePermissionPayrollNonCommute(rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION])
          setUpdatePermissionPayrollNonCommute(rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION])
          setDeletePermissionPayrollNonCommute(rolePermissions[NON_COMMUTE_TRAVEL]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION])
        }
      }
    }
  }, [
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff
  ])


  return (
    <div>
      <Title> Finance</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center mb-4">
          <div className={'d-flex justify-content-center firstRow'}>

            <TabStyledNew
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              active={reviewLocation(TAB_FINANCE_BILLING_URL)}
              onClick={() => {
                props.history.push(TAB_FINANCE_BILLING_URL);
              }}
            >
              Billing
            </TabStyledNew>

            <TabStyledNew
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              active={reviewLocation(TAB_FINANCE_PAYROLL_URL)}
              onClick={() => {
                props.history.push(TAB_FINANCE_PAYROLL_URL);
              }}
            >
              Payroll
            </TabStyledNew>
          </div>
        </ContainerTabStyled>

        <Switch>
          <Route exact path={TAB_FINANCE_BILLING_URL}>
            <Billing
              location={props.location}
              billingTabData={billingTabData}
              getApiData={getApiData}
              readPermissionBilling={readPermissionBilling}
              createPermissionBilling={createPermissionBilling}
              updatePermissionBilling={updatePermissionBilling}
              deletePermissionBilling={deletePermissionBilling}
              readPermissionBillingNonCommute={readPermissionBillingNonCommute}
              createPermissionBillingNonCommute={createPermissionBillingNonCommute}
              updatePermissionBillingNonCommute={updatePermissionBillingNonCommute}
              deletePermissionBillingNonCommute={deletePermissionBillingNonCommute}
            />
          </Route>
          <Route exact path={TAB_FINANCE_PAYROLL_URL}>
            <Payroll
              location={props.location}
              payrollTabData={payrollTabData}
              getApiData={getApiData}
              readPermissionPayroll={readPermissionPayroll}
              createPermissionPayroll={createPermissionPayroll}
              updatePermissionPayroll={updatePermissionPayroll}
              deletePermissionPayroll={deletePermissionPayroll}
              readPermissionPayrollNonCommute={readPermissionPayrollNonCommute}
              createPermissionPayrollNonCommute={createPermissionPayrollNonCommute}
              updatePermissionPayrollNonCommute={updatePermissionPayrollNonCommute}
              deletePermissionPayrollNonCommute={deletePermissionPayrollNonCommute}
            />
          </Route>
          <Redirect
            to={{
              pathname: PAGE_404,
              state: { referrer: '/' },
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default SettingsFinance;
