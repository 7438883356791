import {LARGE_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Shift Pattern Name',
    name: 'name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'START TIME - END TIME',
    name: 'details__start',
    status: 0,
    className: 'grow-mobile',
    max_width: '',
  },
  {
    label: 'Pattern',
    name: 'pattern',
    status: 0,
    className: 'grow-mobile pattern-header-custom sm-hidden',
    max_width: LARGE_COL,
  },
];
