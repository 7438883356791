import styled from 'styled-components';
import {DivContainerLine} from "components/SharedComponents/LineContainer/styles";

export const SettingsIcon = styled.div`
  cursor: pointer;
`;

export const HideWeekDays = styled.div`
  .react-datepicker__day-names {
    display: none !important;
  }
`;

export const SettingsGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props)=>props.size==="sm"?"repeat( auto-fit, minmax(18rem, 1fr) );":"repeat( auto-fit, minmax(24rem, 1fr) );"};
  gap: 1rem;
`;

export const SettingsTemplateContainer = styled.div`
  min-width: ${(props)=>props.collapsed?"0":"100%"};
  grid-column: ${(props)=>props.collapsed?"0":"1 / -1"};

  ${DivContainerLine} {
    margin-left: 0.5rem;
  }
`

export const SearchContainer = styled.div`
  width: ${props => (props.show ? (props.size==="sm"?'7rem':'12rem') : '0')};
  min-width: ${props => (props.show ? (props.size==="sm"?'7rem':'12rem') : '0')};
  transition-duration: 0.25s;
`;