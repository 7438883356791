import { BorderContainer } from 'components/DashboardComponents/Team/AddReferences/styles';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { IoIosBody } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ERR_COLOR, GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { useModal } from 'hooks/ModalHook';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { useState } from 'react';
import BodyMapModal from '../BodyMap/BodyMapModal';

function AddBodyParts({
    values,
    setValues,
    useInput,
    toast,
    isBackMode_,
    setIsBackMode_
}) {

    const [selectedIndex, setSeletedIndex] = useState(null)
    const {
        setValue: setModalBodyMap,
        value: modalBodyMap,
        fullBind: fullBindBodyMap,
    } = useModal(false);
    const {
        setValue: setModalDetachBodyMap,
        bind: bindModalDetachBodyMap
    } = useModal(false);

    const capitalize = (text) => {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
    }

    const addBodyPart = () => {
        setModalBodyMap(true)
        setSeletedIndex(null)
        values.tempBodyMapData = {}
        setValues({ ...values })
    }

    const hanldeUpdateBodyPart = (index) => {
        setModalBodyMap(true)
        values.tempBodyMapData = { ...values.alert_body_part[index], index: `${index}` }
        setValues({ ...values })
    }

    const deleteBodyData = (index) => {
        values.alert_body_part.splice(index, 1);
        setValues({ ...values })
        setModalDetachBodyMap(false)
    }
    return (
        <div>
            <div className={'mt-3'}>
                <div className={'mb-2'}>
                    <TitleSection>Add Body Parts</TitleSection>
                </div>{
                    values?.alert_body_part &&
                    values?.alert_body_part.map((item, index) => {
                        return (
                            <BorderContainer style={{ boxShadow: `0 0.1875rem 0.25rem #00000029, 0 -0.0575rem 0.25rem #00000029` }} className={'pl-1 pr-2 pt-1 pb-1 mb-2'}>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-100'>
                                        <div className='ml-1 mr-2' style={{ color: PURPLE }}>
                                            <IoIosBody />
                                        </div>
                                        <div className='d-flex w-100'>
                                            <div className='' style={{ width: '30%' }}>
                                                {capitalize(item.tittle)}
                                            </div>
                                            <div className='ml-2' style={{ color: GRAY_700, width: '70%' }}>
                                                {item?.description?.length < 75 ? item.description : item.description.substring(0, 75) + '...'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <IconWrapper
                                            style={{ color: PURPLE }}
                                            onClick={() => {
                                                hanldeUpdateBodyPart(index);
                                                setSeletedIndex(index)
                                                setIsBackMode_(item.is_back_mode)
                                            }}>
                                            <MdEdit />
                                        </IconWrapper>
                                        <IconWrapper
                                            style={{ color: ERR_COLOR, marginLeft: '1rem' }}
                                            onClick={() => {
                                                setModalDetachBodyMap(true)
                                                setSeletedIndex(index)

                                            }}>
                                            <RiDeleteBinLine />
                                        </IconWrapper>
                                    </div>
                                </div>
                            </BorderContainer>
                        )
                    })
                }
                <div className={'mt-2'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={addBodyPart}>
                        <FaPlus />
                    </PrimaryButtonForm>
                </div>
            </div>
            {!modalBodyMap ? null : (
                <BodyMapModal
                    fullBind={fullBindBodyMap}
                    setModalBodyMap={setModalBodyMap}
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    toast={toast}
                    // addBodyParts
                    // updateBodyParts
                    update={selectedIndex === null ? false : true}
                    isClient={false}
                    setBackMode={setIsBackMode_}
                    backMode={isBackMode_}
                />
            )}
            <ConfirmDecision
                type="confirm"
                title="Confirm"
                body={
                    'Are you sure you want to delete the row? '
                }
                onOk={() => {
                    deleteBodyData(selectedIndex);
                }
                }
                onNo={
                    () => {
                        setModalDetachBodyMap(false)
                    }
                }
                {...bindModalDetachBodyMap}
            />
        </div>
    )
}

export default AddBodyParts