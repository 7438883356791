import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import moment from 'moment';
import { data } from 'jquery';
import { useEffect } from 'react';
import { useState } from 'react';

export const getPageToSearchParam = (
  searchQuery,
  setCurrentPage,
  simplePagination,
  currentPage,
  ADD_PAGE_SIZE_VALUE
) => {

  
  const urlParams = queryString.parse(searchQuery);
  
  let offset = urlParams[PAGINATION_PARAM]
    ? urlParams[PAGINATION_PARAM] - 1
    : 0;

  if (simplePagination) {
    offset = currentPage - 1;
  }

  setCurrentPage(offset + 1);

  const page_size = ADD_PAGE_SIZE_VALUE
    ? ADD_PAGE_SIZE_VALUE
    : PAGE_SIZE_RENDER;

  


  offset = offset * page_size;

  return {
    offset,
  };
};

export const validateDate = (isRequired, start_date, end_date) => {
  let isValid = false;
  if (start_date && end_date) {
    if (moment(start_date).isSameOrBefore(end_date)) {
      isValid = true;
    }
  }

  if (!start_date && !end_date && !isRequired) {
    isValid = true;
  }

  return isValid;
};

export const validateTime = (
  isRequired,
  start_date,
  end_date,
  start_time,
  end_time
) => {
  let isValid = false;

  if (
    (!start_date && !end_date) ||
    (!start_date && end_date) ||
    (start_date && !end_date)
  ) {
    return true;
  }

  if (!validateDate(true, start_date, end_date)) {
    return true;
  }

  if (start_date && end_date && start_time && end_time) {
    const initialDate = moment(`${start_date}T${start_time}`);
    const finalDate = moment(`${end_date}T${end_time}`);
    if (moment(initialDate).isBefore(finalDate)) {
      isValid = true;
    }
  }

  if (!start_time && !end_time && !isRequired) {
    isValid = true;
  }

  return isValid;
};

/**
 * Compare two dates
 * @param {String} start_date // formats 2021-01-26 0r 2021/01/26
 * @param {String} end_date // formats 2021-01-26 Or 2021/01/26
 */
export const compareStringDates = (start_date, end_date, type) => {
  let initialDate = start_date;
  let finalDate = end_date;

  let split_initialDate = '';
  let split_finalDate = '';

  if (initialDate.includes('-')) {
    split_initialDate = initialDate.split('-');
    split_finalDate = finalDate.split('-');
  } else {
    split_initialDate = initialDate.split('/');
    split_finalDate = finalDate.split('/');
  }

  initialDate = new Date(
    parseInt(split_initialDate[0]),
    parseInt(split_initialDate[1]) - 1,
    parseInt(split_initialDate[2]),
    0,
    0,
    0,
    0
  );

  finalDate = new Date(
    parseInt(split_finalDate[0]),
    parseInt(split_finalDate[1]) - 1,
    parseInt(split_finalDate[2]),
    0,
    0,
    0,
    0
  );

  let result = '';

  switch (type) {
    case '>':
      result = initialDate > finalDate;
      break;
    case '>=':
      result = initialDate >= finalDate;
      break;
    case '<':
      result = initialDate < finalDate;
      break;
    case '<=':
      result = initialDate <= finalDate;
      break;
    default:
      result = initialDate.getTime() === finalDate.getTime();
      break;
  }

  return result;
};

/**
 * return the string date (2021-01-26) from date format
 * @param {Date} date_value //Date lte gmt
 */
export const dateToStringDate = date_value => {
  return `${date_value.getFullYear()}-${
    date_value.getMonth() + 1 <= 9 ? '0' : ''
  }${date_value.getMonth() + 1}-${
    date_value.getDate() <= 9 ? '0' : ''
  }${date_value.getDate()}`;
};

export const dateToLocalStringDate = date_value => {
  return `${date_value.getFullYear()}-${
    date_value.getMonth() + 1 <= 9 ? '0' : ''
  }${date_value.getMonth() + 1}-${
    date_value.getDate() <= 9 ? '0' : ''
  }${date_value.getDate()}T00:00:00.000Z`;
};

/**
 * Return new date with time from string date and time
 * @param {String} date_value // formats 2021-01-26 0r 2021/01/26
 * @param {String} time_value // format 14:00:00
 */
export const createDateWithTime = (date_value, time_value) => {
  let split_date = '';
  let split_time = time_value.split(':');

  if (date_value.includes('-')) {
    split_date = date_value.split('-');
  } else {
    split_date = date_value.split('/');
  }

  return new Date(
    parseInt(split_date[0]),
    parseInt(split_date[1]) - 1,
    parseInt(split_date[2]),
    parseInt(split_time[0]),
    parseInt(split_time[1]),
    parseInt(split_time[2]),
    0
  );
};

/**
 * Return new date from string date
 * @param {String} date_value // formats 2021-01-26 0r 2021/01/26
 */
export const dateStringToDate = date_value => {
  let split_date = '';

  if (date_value.includes('-')) {
    split_date = date_value.split('-');
  } else {
    split_date = date_value.split('/');
  }

  return new Date(
    parseInt(split_date[0]),
    parseInt(split_date[1]) - 1,
    parseInt(split_date[2]),
    0,
    0,
    0,
    0
  );
};

export const twentyFourTimeToTwelve = time => {
  if (!time) {
    return;
  }
  const splitTime = time.split(':');
  const amPm = parseInt(splitTime[0]) >= 12 ? 'PM' : 'AM';
  return `${((parseInt(splitTime[0]) + 11) % 12) + 1}:${splitTime[1]} ${amPm}`;
};

export function useOutsideClickClosePopup(ref, myFun) {
  useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              myFun()
          }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [myFun]);
}

export const useDebounce = value => {
  const [dbValue, setDbvalue] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDbvalue(value);
    }, 1000);

    return () => clearTimeout(timer);
  }, [value]);

  return dbValue;
};

export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}



export function timeToMinutes(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

export function minutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${remainingMinutes
    .toString()
    .padStart(2, '0')}`;
}

export const capitalize = (code) => {
  if (!code || !code.includes("-")) return code;

  const [first, second] = code.split("-");
  const capitalizeWord = (word) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  return `${capitalizeWord(first)}-${capitalizeWord(second)}`;
};


export function roundToTwoDecimals(number) {
  if (Number.isFinite(number) && number.toString().includes('.')) {
      return parseFloat(number.toFixed(2));
  }
  return number;
}