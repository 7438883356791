import React, {useState, useEffect, useRef,useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import {headerColumns} from './headerColumsList';
import AddModal from 'components/DashboardComponents/Settings/SettingsCalendar/PublicHolidays/AddModal';
import {FaPlus} from 'react-icons/fa';
import {RiDeleteBinLine} from 'react-icons/ri';
import { getPublicHoliday, DeletePublicHoliday} from 'utils/api/SettingsApi';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {MdEdit} from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import {Header, SearchAddContainer} from 'shared/styles/constants/tagsStyles';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {LARGE_COL , MEDIUM_COL,EX_SMALL_COL,SMALL_COL} from 'shared/styles/constants/columns';
import {useForm} from 'hooks/FormHook';
import Pagination from 'components/SharedComponents/Pagination';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
// import {PAGE_SIZE_RENDER,PAGE_SIZE_TWELVE_RENDER} from 'utils/constants/pagination';
import queryString from 'query-string';
import {AppContext} from "context"
import {getCategoryPermission,getPermissions} from '../../../../..//utils/localStorage/user';
import {
  PUBLIC_HOLIDAY,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions'
import {getAdmin, getSuperAdmin} from "../../../../..//utils/localStorage/token"
import { getStaff,getClient,getEmployee,getSubSectionPermissions} from '../../../../../utils/localStorage/user';
import moment from 'moment';
import { setting_company_public_holiday } from 'utils/choiceConstant';


const  PublicHolidays =({ location}) => {

  const {values, setValues, useInput,setCheckRequires} = useForm({});  //used useInput
  const [publicHoliday, setPublicHoliday] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState( );
  const [idToDelete, setIdToDelete] = useState('');
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {contextChoices, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext)
  const[tempChoices,setTempChoices]=useState({choices_data: []});
  const [countryData, setCountryData] = useState([]);
  const [updatePermissions, setUpdatePermissions] = useState(false);
  const [createPermissions, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const[numberValue,setNumberValue]=useState(1) ;
  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} = useModal(
    false,
  );
  const fullNameCol="public_holiday_name" ;
  const  userClient =  getClient();
  const  userEmployee = getEmployee();
  const  userStaff = contextIsStaff;
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;

  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(setting_company_public_holiday)
  },[])
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  
  useEffect(()=>{
    let limitperpage={};
    values.limitperpage =PAGE_SIZE_RENDER;
    setValues({...values})
    },[PAGE_SIZE_RENDER])


  useEffect(() => {
    loadPublicHoliday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ location.search , values.limitperpage,  values.ordering, ]);

  useEffect(()=>{
    setTempChoices(contextChoices || []);
    setCountryData(contextChoices && contextChoices.country && contextChoices.country.map(y=>{
      return{ id: y && y.id , name: y && y.name}
    }))
  },[contextChoices])

  const changeNumber=(value)=>{
    if(value === 0){
      values.ordering='-public_holiday_name';
      setNumberValue(0)
    } else {
      values.ordering='public_holiday_name';
    }
    setValues({...values});
  }


  const getCountryName=(countryId)=>{
    if ( (countryId)   && (countryData && countryData.find(x=>x.id === countryId).name )) {
      return  countryData && countryData.find(x=>x.id === countryId).name 
    }else{
     return  ''
  }
}

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    // offset = offset * PAGE_SIZE_RENDER;
    offset = offset *  values.limitperpage;
    return {
      offset,
    };
  };

  const loadPublicHoliday = () => {
    const pageToSearch = getPageToSearchParam(location.search);
    getPublicHoliday({...values,...pageToSearch}).then(response => {
      if (!mountedRef.current) return null;
      setPublicHoliday(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
});
  };

  useEffect(() => {

    setTitle('Add Holiday');

    // const roles = getPermissions();
  
    // let rolePermissions = {};
    // if (roles.length > 0) {
    //   roles.forEach(item => {
    //     if (item?.section?.name === PUBLIC_HOLIDAY) {
    //       rolePermissions[PUBLIC_HOLIDAY] = {};
    //       rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION] = {};
    //       rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
    //         WEB_READ_PERMISSION
    //       ] = item?.[WEB_READ_PERMISSION];
    //       rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
    //         WEB_CREATE_PERMISSION
    //       ] = item?.[WEB_CREATE_PERMISSION];
    //       rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
    //         WEB_DELETE_PERMISSION
    //       ] = item?.[WEB_DELETE_PERMISSION];
    //       rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
    //         WEB_UPDATE_PERMISSION
    //       ] = item?.[WEB_UPDATE_PERMISSION];
    //     }
    //   });
 
    let rolePermissions = {};
    const rolesPermissions = contextSubSectionPermissions;
    
      if (rolesPermissions.length > 0) {
        rolesPermissions.forEach(item => {
         
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name ] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        // }
        );  
      }
      
      if((filterAdmin == true) || (filterSuperAdmin ==true) ){
        setCreatePermissions(true) ;
        setDeletePermissions(true);
        setUpdatePermissions(true);
        setReadPermissions(true);

      } else {
        if( rolePermissions[PUBLIC_HOLIDAY] !== undefined){
        setCreatePermissions(
          rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ]
        );
  
        setReadPermissions( 
          rolePermissions[PUBLIC_HOLIDAY][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
        );
        
        }  
      }

  }, [ ]);

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddHoliday= () => {
    setIdSelected('');
    handleModal();
    // setCheckRequires(false);
  };

  // const handleDeletePublicHoliday = async (id) => {
  //   await DeletePublicHoliday(id);
  //   loadPublicHoliday();
  // };

  //delete  msg pop up begin 
	const toDeletePublicHoliday = id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };


  const handleDeletePublicHoliday = async () => {
    await DeletePublicHoliday(idToDelete);
    setModalDeleteValue(false);
    loadPublicHoliday();
  };
   //delete  msg pop up begin 

const handleRowClick = id => {
  setIdSelected(id);
  setModalValue(true);
  // setValues({...values});
};

const getHolidayType = ratecode => {
  if(ratecode){
 return contextChoices && contextChoices.rate_code && contextChoices.rate_code.find(w=>w.id  === ratecode).description;
  }else{
    return ' ' ;
  }
};

const getRateCodeName = ratecode => {
   if(ratecode){
    return  contextChoices && contextChoices.rate_code && contextChoices.rate_code.find(w=>w.id  === ratecode).name;
   }else{
     return '';
   }
};

const dropDownHandleSelectPagination=(item,namesection)=>{
  values.limitperpage=item[namesection]
  setValues({...values});
}


  return (
    <div>
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
        { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || ((userStaff == true)&&(createPermissions) ) )  && ( 
          <FloatingMobileButton onClick={handleAddHoliday}>
            <FaPlus />
            <label>ADD HOLIDAY</label>
          </FloatingMobileButton>
            )}
        </SearchAddContainer>
      </Header>

      <Table
        headerColumns={headerColumns}
 callBackQuerySort={setQuerySort} 
 headerPadding={false}  
//  statusNumber={numberValue}
//   setStatusNumber={changeNumber}    
//    fullNameCol={fullNameCol}
   >
        <div>
    {publicHoliday && publicHoliday.map((list, index) => {
          return (  
              <div  key={index}>
              <Row bgColor Shadow>
              <ColsGrouper
                    className="d-flex"
                    >
                  <Col
                    max_width={MEDIUM_COL}
                    Shrink 
                    // reducePadding
                    Capitalize
                    Center
                    >
           {list.calendar_year}
                  </Col>
                  <Col
                      max_width={LARGE_COL}
                      Shrink 
                    // reducePadding
                    // className="sm-hidden"
                    Capitalize
                    Center     
                    >
                  {list.public_holiday_name}    
                  </Col>     
                  <Col
                 max_width={MEDIUM_COL}
                 Shrink 
                //  reducePadding
                //  className="sm-hidden"
                Capitalize
                Center    
                >
          {list.date && moment(list.date).format('DD/MM/YYYY')}    
                  </Col>
                  <Col
                 max_width={MEDIUM_COL}
                 Shrink 
                //  reducePadding
                //  className="sm-hidden"
                Capitalize
                Center
                >
                  {getHolidayType(list.rate_code)}
                  {/* {list.rate_code.description}    */}
                  </Col>
                  <Col
                 max_width={MEDIUM_COL}
                 Shrink 
                //  reducePadding
                //  className="sm-hidden"
                Capitalize
                Center
                >
                   {getRateCodeName(list.rate_code)}
              {/* {list.rate_code.name}    */}
                  </Col>
                  <Col
                 max_width={LARGE_COL}  
                 Shrink 
                //  reducePadding
                //  className="sm-hidden"
                Capitalize
                Center       
                >
               {getCountryName(list &&  list.country)}   
                  </Col>
                  <Col
                 max_width={MEDIUM_COL}
                 Shrink 
                //  reducePadding
                Capitalize
                Center           
                >
              {list.bill_multiplier}   
                  </Col>       
                  <Col
                 max_width={MEDIUM_COL}
                 Shrink 
                //  reducePadding
                Capitalize
                Center           
                >
              {list.pay_multiplier}   
                  </Col> 
                  { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (updatePermissions)  )  && ( 
            <Col   
                      // max_width={SMALL_COL}
                     className="sm-hidden " 
                   Icon Purple  
                   NoFlexGrow 
                     Shrink 
                 reducePadding   
                 onClick={() => {
                  handleRowClick(list.id);
                }}
                     >
                   {/* <IconWrapper> */}
                        <MdEdit />
                      {/* </IconWrapper>    */}
                    </Col>
                    	  )}
           { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || ( deletePermission) )  && ( 
               <Col   
                // max_width={SMALL_COL}
                className="sm-hidden " 
                Shrink 
                reducePadding
                 NoFlexGrow   
                 onClick={() => {
                  toDeletePublicHoliday(list.id);
                }}
                  >
                  {/* <IconWrapper  > */}
                      <RiDeleteBinLine />
                    {/* </IconWrapper> */}
                  </Col>   
                    )}
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
 ); 
      })} 
         
         <div className="mt-3">
         <Pagination totalPages={pages} currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={publicHoliday.length} />
          </div>

        </div>
      </Table>
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          setPublicHolidayModalValue={setModalValue}
          fullBind={fullBind}        
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          // nameSection={'rateValue'}
          useInput={useInput}
          loadPublicHoliday={loadPublicHoliday}
          contextChoices={contextChoices}
          countryData={countryData}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this public holiday? '
        }
        onOk={handleDeletePublicHoliday}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default PublicHolidays;
