import styled from 'styled-components';
import {PURPLE, GRAY_200, BLACK_900} from 'shared/styles/constants/colors';

export const EmployeeSelectedContainer = styled.div`
  padding: 0;
`;

export const ClientInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  div {
    font-weight: normal;
    margin-left: 0.3rem;
  }
`;

export const UserImg = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  margin-right: 1rem;
  border-radius: 50%;
  -webkit-box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};
  box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};
  img {
    width: 2.4rem;
    height: 2.4rem;
  }
  background: ${GRAY_200};
  div {
    color: ${PURPLE};
    width: 100%;
    height: 100%;
  }
`;
