import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
    StylePointer,
} from 'shared/styles/constants/tagsStyles';
import _ from 'lodash';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import Geocode from 'react-geocode';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { setCareTask } from 'utils/api/SettingsApi';


const AddNewCare = ({
    fullBind,
    modalLoading,
    setModalLoading,
    choices,
    handleChangeChoices,
    loadDataOnCareTask,
    setAddNewCareTask,
    perffredId,
    loadTaskData,
    toast,
    taskIndex,
    handleDropDown,
    setContextChoices,
    taskValue
}) => {
    const { values, setValues, useInput, isValid, errors, setCheckRequires } =
        useForm({});

    const [loadingSave, setLoadingSave] = useState(false);
    //const [loadingModal, setLoadingModal] = useState(true);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [errorMessage, setErrorMessage] = useState('');

    const [addFullBind, setAddFullBind] = useState(fullBind);
    console.log(perffredId, "YYYYY")
    useEffect(() => {
        setAddFullBind({
            ...addFullBind,
            title: 'Add Care Task',
        });
        setModalLoading(false);
    }, [fullBind]);

    useEffect(() => {
        if (buttonIcon !== FLOPPY) {
            setButtonIcon(FLOPPY);
            setTextStatus(SAVE);
        }

        if (isValid && errors.length <= 0) {
            setCheckRequires(false);
            setErrorMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const handleSubmit = async () => {
        if (!isValid && errors.length > 0) {
            setErrorMessage(InvalidOrEmptyValues);
            setCheckRequires(true);
            return;
        }
        setLoadingSave(true);

        let body = {
            name: values.name,
            task_type: values.task_id,
            feedback: false,
            result_type: values.result_type,
            result_option: values.result_option ? values.result_option.join(',') : '',
        };

        try {
            const response = await setCareTask(body)
            debugger
            if (response && response?.id) {
                values.id = response?.id;
                setValues({ ...values })
                let choices = await handleChangeChoices(['care_task', 'task_type', 'care_category']);
                setContextChoices({ ...choices })
                let newTaskValue = choices.task_type.find(items => items.id === response.task_type);
                console.log({ newTaskValue: newTaskValue ? newTaskValue?.care_category : null }, "newTaskValue")
                taskValue[taskIndex]['care_task_data'] = response?.id
                taskValue[taskIndex]['task_type'] = response.task_type || null;
                taskValue[taskIndex]['care_category'] = newTaskValue ? newTaskValue?.care_category : null;

                onSuccess(response);
            }
        } catch (error) {
            onError(error)
        }
    }

    const onSuccess = response => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success`,
        });

        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        setLoadingSave(false);
        setAddNewCareTask(false)
        // handleDropDown(
        //     { care_task_data: response.id ? response.id : null, },
        //     'care_task_data',
        //     taskIndex,
        //     response.id)

    };

    const onError = () => {
        setLoadingSave(false);
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `Something went wrong!`,
        });
    };

    const dropDownHandleSelect = item => {
        //recursive objects merge
        let result;
        if (item.option) {
            item.option = item.option;
        }

        result = _.merge(values, item);

        setValues({ ...result });
    };

    const addNewService = () => {
        // const haveNew = optionsArray.find(data => data.id === '');

        // if (haveNew) {
        // return;
        // }
        setValues({
            ...values,
            result_option: values.result_option
                ? [...values.result_option, '']
                : [''],
        });
        // setOptionsArray([...optionsArray, '']);
    };

    const handleInputChange = (e, attribute, index) => {
        const newOption = values.result_option ? [...values.result_option] : [];

        //newOption[e.target.dataset.idx][attribute] = e.target.value;
        newOption[index] = e.target.value;
        values.result_option = newOption;
        // setOptionsArray(...values.result_option)
        setValues({ ...values });
    };

    const preferredHandleSelect = (preferred, data) => {
        if (preferred.care_category) {
            values.care_category = preferred.care_category;
            setValues({ ...values });
        }
        if (preferred.task_id) {
            values.task_id = preferred.task_id;
            setValues({ ...values });
        }
    };
    const deleteRow = selIndex => {
        const deleteTemp = [];
        values.result_option.forEach((item, index) => {
            if (!(index === selIndex)) {
                deleteTemp.push(item);
            }
        });
        values.result_option = deleteTemp;
        setValues({ ...values });
    };

    return (
        <ModalContainer max_width={'60%'}>
            <ModalDynamic
                {...addFullBind}
                max_width={'60%'}
                minWidthTitle="8.5rem"
                loading={modalLoading}>
                <div className="pl-3 pr-3 pb-3 pt-0">
                    <FlexGrid3Container className={'w-100'}>
                        {/* <FlexGrid3Item className="pl-0 pr-1 pb-1">
                <DropdownSearchable
                    marginLeft={'0.5%'}
                    placeHolder={'Care Category'}
                    options={choices.care_category}
                    selectedOption={values.care_category}
                    onChange={(value, name, data) =>
                        preferredHandleSelect(
                            { care_category: value.length ? value : [] }, data
                        )
  useEffect(()=>{
    getMedicationName()
  },[])
                    }
                />
            </FlexGrid3Item> */}
                        <FlexGrid3Item className="pl-0 pr-1 pb-1">
                            <DropdownSearchable
                                // width={'33%'}
                                marginLeft={'0.5%'}
                                required
                                placeHolder={'Task Type'}
                                selectedOption={values.task_id}
                                options={choices && choices?.task_type && choices?.task_type}
                                {...useInput('task_id', 'isRequired')}
                                onChange={(value, name, data) =>
                                    preferredHandleSelect(
                                        { task_id: value.length ? value : [] },
                                        data
                                    )
                                }
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pl-0 pr-1 pb-1">
                            <InputLabelTop
                                required
                                type="text"
                                id="name"
                                label={'Name'}
                                {...useInput('name', 'isRequired')}
                                maxlength={50}
                            />
                        </FlexGrid3Item>
                    </FlexGrid3Container>
                    <FlexGrid3Container>
                        <FlexGrid3Item className="pl-0 pr-1 pb-1">
                            <DropdownSearchable
                                placeHolder={'Result Type'}
                                options={[
                                    { id: 'none', name: 'None' },
                                    { id: 'text', name: 'Text' },
                                    { id: 'option', name: 'Option' },
                                ]}
                                selectedOption={values.result_type}
                                required={true}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        result_type: value ? value : null,
                                    })
                                }
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item>
                            {values.result_type === 'option' && (
                                <StylePointer onClick={addNewService} className="pt-1 pl-1">
                                    <FaPlus color={PURPLE} size={26} />
                                </StylePointer>
                            )}
                        </FlexGrid3Item>
                    </FlexGrid3Container>
                    <FlexGrid3Container>
                        {values.result_option &&
                            values.result_option.map((item, index) => {
                                return (
                                    <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
                                        <div className="d-flex">
                                            <InputLabelTop
                                                type="text"
                                                id=""
                                                value={item ? item : ''}
                                                label={`Result option ${index + 1}`}
                                                onChange={e => {
                                                    handleInputChange(e, 'result_option', index);
                                                }}
                                                maxlength={30}
                                            />
                                            <div className="pt-1 pl-1">
                                                <IconWrapper
                                                    onClick={() => {
                                                        deleteRow(index);
                                                    }}>
                                                    <RiDeleteBinLine />
                                                </IconWrapper>
                                            </div>
                                        </div>
                                    </FlexGrid3Item>
                                );
                            })}
                    </FlexGrid3Container>

                    <div
                        className={
                            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                        }>
                        <div className={'mb-3'}>
                            {errorMessage ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div
                            className={
                                'd-flex justify-content-center justify-content-md-end'
                            }>
                            <div className={'ml-0 ml-md-3 mr-2'}>
                                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                                    <span className={'mr-2'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        buttonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm mr-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDynamic>
        </ModalContainer>
    );
};

export default AddNewCare;
