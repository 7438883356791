import {apiLoginURL, apiLoginURLV2} from '../env';
import {methods, headers, unauthorized_status_code} from 'utils/constants/api';
import {post} from './Api';
import {getToken} from '../localStorage/token';

const {POST, GET} = methods;

export const getUser = async username => {
  const url = `${apiLoginURLV2}/users/${username}/`;

  const response = await fetch(url, {
    headers: {
      ...headers,
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === unauthorized_status_code) {
    return false;
  }
  const json = await response.json();
  return json;
};

export const login = async (email, password) => {
  const url = `${apiLoginURLV2}/users/login/`;

  const body = {
    email,
    password,
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const logout = async (username, email) => {
  const url = `${apiLoginURL}/users/logout/`;

  const body = {
    username,
    email,
  };

  return post(url, body, POST);
};

export const forgotPassword = async email => {
  const url = `${apiLoginURL}/users/forgot/`;

  const body = {
    email,
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const verifyEmail = async ({token, password}) => {
  const url = `${apiLoginURL}/users/verifyEmail/`;

  const body = {
    token,
    password,
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const checkToken = async ({token}) => {
  const url = `${apiLoginURL}/users/validateVerifyEmail/?token=${token}`;


  const response = await fetch(url, {
    method: GET,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
  });

  const json = await response.json();

  // if (response.status !== 200) throw json;

  return json;
};

export const verifyPin = async (token, password, password_confirmation) => {
  const url = `${apiLoginURL}/users/forgotMobilePassword/`;

  const body = {
    token,
    password,
    password_confirmation
  };

  const response = await fetch(url, {
    method: POST,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  //if (response.status !== 200) throw json;

  return json;
};
