import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'

export default function DropDown({ json, multiple= false,jsonData = [], setJsonData=()=>{}, index, minWidth = 400 }) {
    // const [label, setLabel] = useState(json.dropdown.label)
    const DDOptions = json.options ? json.options : json.manual_options ? json.manual_options : [];
    return (
        <FormControl style={{ flex: 1 }} sx={{ minWidth: minWidth }}>
            <InputLabel shrink={false} id="demo-simple-select-label">{json.placeholder}</InputLabel>
            <Select
                style={{height:'45px'}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={multiple ? [] : json?.value}
                multiple={multiple}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                label={json.placeholder}
                onChange={item => {
                    const data = jsonData.map((val, newIndex) => {
                        if (newIndex === index) {
                            return ({
                                ...val,
                                value: item.target.value
                            })
                        }
                        return val
                    })
                    setJsonData(data)
                }}
            >
                {
                    DDOptions.map((item, index) => <MenuItem key={index} value={item.label}>{item.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
