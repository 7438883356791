import React, {
  useState,
  useEffect,
  useContext,
  useModal,
  useRef,
  Fragment,
} from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { getAbsenceCalendarType, getEmployeeBranchAccess, setAbsenceOverview } from 'utils/api/EmployeeApi';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  ERROR,
  SAVE,
  SAVED,
  InvalidOrEmptyValues,
} from 'utils/constants/messages';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import CustomYearDropdown from 'components/SharedComponents/DateSelector/custumDateSelector';
import CustomYearInput from 'components/SharedComponents/DateSelector/CustomYearInput';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';

const edit_absence = 'Edit Absence Overview';
const new_absence = 'Add Absence Overview';

const AbsenceModal = ({
  fullBind,
  idSelected,
  setTitle,
  editDatas,
  setDataCheck,
  setModalValue,
  selectedClientId,
  setDataChecking,
  addData,
  data,
  loadData,
  toast,
  getCalendarType
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const { contextChoices } = useContext(AppContext);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    if (
      values.employee &&
      values.absence_type &&
      values.absence_reason &&
      values.time_unit
    ) {
      setErrorMessage('');
    }
  }, [values]);
  useEffect(() => {
    setAbsenceType(
      contextChoices.absence_type &&
      contextChoices.absence_type.map(y => {
        return { id: y.id, name: y.name };
      })
    );
    setAbsenceReason(
      contextChoices.absence_reason &&
      contextChoices.absence_reason.map(y => {
        return { id: y.id, name: y.name };
      })
    );
    setAbsencePaid(
      contextChoices.absence_paid &&
      contextChoices.absence_paid.map(y => {
        return { id: y.id, name: y.name === 'n/a' ? y.name.toUpperCase() : y.name };
      })
    );

    setEmployees(
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(item => {
        return {
          id: item.id,
          name: item.full_name,
        };
      })
    );

    if (idSelected && data && employees) {
      setTitle(edit_absence);

      values.absence_type = data.absenceType;
      // values.absence_planneds = data.absencePlanned;
      // values.absence_reason = data.absenceReason;
      values.absence_calendar_year = data.calendar_type
      values.time_unit = data.timeUnit;
      values.absence_paid = data.absencePaid;
      values.absence_allowance = data.absenceAllowance;
      values.allowance_date_from = data.allowance_date_from;
      values.branch = data.branch || null
      // values.absence_calendar_year = data.absence_calendar_year
      // values.allowanceDate = data.allowanceDate

      setValues({ ...values });
    }
  }, [contextChoices || employees || values.employee]);

  const getBranch = async (id) => {
    await getEmployeeBranches(id)
  }

  useEffect(() => {
    if (data) {
      let emp = employees &&  employees.find(item => item.name === data.employee__full_name)?.id;
      values.employee = emp
      getBranch(emp)
    }
  }, [employees || data]);
  // const toast = useRef();

  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [absenceType, setAbsenceType] = useState([]);
  const [absencePaid, setAbsencePaid] = useState([]);
  const [timeUnit, setTimeUnit] = useState([]);
  const [absenceReason, setAbsenceReason] = useState([]);
  const [branchData, setBranchData] = useState([])

  console.log(values.task_type_value, 'iiiii');
  const getEmployeeBranches = async (id) => {
    if(!id) return;
    try {
        const res = await getEmployeeBranchAccess(id)
        setBranchData(contextChoices?.branch?.filter(el => res?.employee_access_branches.includes(el.id)))
        setLoadingModal(false);
    } catch (error) {

    }
}
 
  const dropDownHandleSelect = async(item , name )=> {
    if (name === 'employee') {
      const res = await getCalendarType(item.employee)
      values.absence_calendar_year = res
      setValues({ ...values, ...item });
      await getEmployeeBranches(item.employee)
    }
    setValues({ ...values, ...item });
  };
  const HandleSubmit = async () => {
    if (errors.length > 0) {
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review'
      );
      setCheckRequires(true);
      return;
    }
    const body = idSelected
      ? {
        id: idSelected,
        calendarType: values.absence_calendar_year,
        employee: values.employee,
        absenceType: values.absence_type,
        absencePaid: values.absence_paid,
        absenceAllowance: values.absence_allowance,
        timeUnit: values.time_unit,
        allowance_date_from: `${new Date(values.allowance_date_from).getFullYear()}`,
        branch: values.branch

      }
      : {
        employee: values.employee,
        absence: [
          {
            absenceType: values.absence_type,
            absencePaid: values.absence_paid,
            timeUnit: values.time_unit,
            absenceAllowance: values.absence_allowance,
            allowance_date_from: values.allowance_date_from.slice(0.4),
            // absencePlanned: values.absence_planneds,
            // allowanceDate: values.allowanceDate
            branch: values.branch
          },
        ],
      };
    let response = await setAbsenceOverview(body, idSelected);
    if (response.status === true) {
      loadData();
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      // setTimeout(() => {
      setModalValue(false);
      // }, 1000);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
      setErrorMessage(response.message);
      setCheckRequires(true);
    }
  };

  const dropDownHandleSelect1 = async(item) => {
    if(item.branch){
      values.branch = item.branch;
      setValues({ ...values, ...item });
    }
  }

  console.log(values, data, 'allowanceYear');
  return (
    <ModalContainer max_width={'60%'}>
      {/* <Toast ref={toast} position="top-right" /> */}

      <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={loadingModal}>
        {/* <Toast ref={toast} position="top-right" /> */}
      <div className='ml-2 mr-2 mb-3'>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="w-100 mr-2 ml-2 mb-2">
              <DropdownSearchable
                placeHolder={'Employee'}
                required
                {...useInput(`employee`, 'isRequired')}
                options={employees ? employees : []}
                selectedOption={values?.employee}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      employee: value ? value : null,
                    },
                    'employee'
                  )
                }
              />
            </div>
            <div className="w-100 mr-2">
              <DropdownSearchable
                placeHolder={'Absence Calendar Year'}
                // disabled
                // options={[
                //   {
                //     id: "Joining Calendar",
                //     name: "Joining Calendar"
                //   },
                //   {
                //     id: "Financial Calendar",
                //     name: "Financial Year"
                //   }
                // ]}
                required
                options={contextChoices && contextChoices.calendar_type &&  contextChoices.calendar_type || []}
                {...useInput(`absence_calendar_year`, 'isRequired')}
                selectedOption={
                  values
                    ? values.absence_calendar_year
                      ? values.absence_calendar_year
                      : null
                    : null
                }
                onChange={(value) =>
                  dropDownHandleSelect(
                    {
                      absence_calendar_year: value ? value : null,
                    },
                    'absence_calendar_year'
                  )
                }
              />
            </div>
            {/* <div
              className="w-100 mr-2">
              <DropdownSearchable
                placeHolder={'Absence Reason'}

                {...useInput(`absence_reason`)}
                options={absenceReason ? absenceReason : []}
                selectedOption={values.absence_reason ? values.absence_reason : null}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      absence_reason: value ? value : null,
                    },
                    'absence_reason'
                  )
                }
              />
            </div> */}
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            {/* <div
              className="w-100 mr-2 ml-2 mb-2">
              <DropdownSearchable
                placeHolder={'Absence Planed'}
                options={
                  contextChoices.absence_planned
                    ? contextChoices.absence_planned.map(x => ({...x,name:x.name === 'n/a' ? x.name.toUpperCase() : x.name}))
                    : []
                }
                selectedOption={values.absence_planneds}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      absence_planneds: value ? value : null,
                    },
                    'absence_planneds'
                  )
                }
              />
            </div> */}
            <div
              className="w-100 mr-2 ml-2 mb-2">
                  <DropdownSearchable
                    required
                    placeHolder={'Branch'}
                    {...useInput(`branch`, 'isRequired')}
                    options={branchData ? branchData : []}
                    selectedOption={values.branch}
                    onChange={value =>
                      dropDownHandleSelect1(
                        {
                          branch: value ? value : null,
                        },
                        'branch'
                      )
                    }
                  />
              
            </div>

            <div
              className="w-100 mr-2">
              <DropdownSearchable
                required
                {...useInput(`time_unit`, 'isRequired')}
                disabled={false}
                placeHolder={'Time Unit'}
                options={contextChoices.absence_time_unit}
                selectedOption={values.time_unit}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      time_unit: value ? value : null,
                    },
                    'time_unit'
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column ">
          <div className="d-flex">
            <div className="w-100 mr-2 mr-2 ml-2 " >
              {/* <DropdownSearchable
                disabled={false}
                required
                placeHolder={'Absence Paid'}
                options={absencePaid ? absencePaid : []}
                selectedOption={values.absence_paid}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      absence_paid: value ? value : null,
                    },
                    'absence_paid'
                  )
                }
                {...useInput('absence_paid','isRequired')}
              /> */}
              <DropdownSearchable
                required
                {...useInput(`absence_paid`, 'isRequired')}
                disabled={false}
                placeHolder={'Absence Paid'}
                options={absencePaid ? absencePaid : []}
                selectedOption={values.absence_paid}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      absence_paid: value ? value : null,
                    },
                    'absence_paid'
                  )
                }
              />
            </div>

            <div className="w-100 mr-2" >
              <InputLabelTop
                // margin={'5px'}
                required
                label={'Absence Allowance'}
                type="number"
                id={`absence_allowance`}
                {...useInput('absence_allowance','isRequired')}
                maxlength={100}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column ">


        <div className="d-flex">
            <div className="w-100 mr-2 mr-2 ml-2 " >
            <DropdownSearchable
                required
                {...useInput(`absence_type`, 'isRequired')}
                placeHolder={'Absence Type'}
                options={absenceType ? absenceType : []}
                selectedOption={values.absence_type}
                onChange={value =>
                  dropDownHandleSelect(
                    {
                      absence_type: value ? value : null,
                    },
                    'absence_type'
                  )
                }
              />
            </div>

            <div className="w-100 mr-2" >
            <CustomYearInput
              isRequired
              labelTop="Select year"
              {...useInput(`allowance_date_from`, 'isRequired')}

            />
            </div>
          </div>
        </div>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div
            className={'mb-3'}
            style={{ display: 'flex', justifyContent: 'right' }}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ml-0 ml-md-3 mr-2'}>
              <PrimaryButtonForm
                minWidth="6rem"
                onClick={HandleSubmit}
                style={{ marginBottom: '5px' }}>
                <span className={'mr-2'}>{textStatus}</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
        </div>
      </ModalNoInfo>
    </ModalContainer>
  );
};

export default AbsenceModal;
