import styled from 'styled-components';

export const flexContainer  = styled.div`
display: flex;
  flex-direction: row;
background-color: #dddddd;
`

export const flexContainerColumn  = styled.div`
font-family: arial, sans-serif;
width: 20%;
display: flex;
flex-direction: column;
align-items: stretch;
background-color: #dddddd;
text-align: center;
`
export const TitleCheckbox = styled.div`
  font-weight: 608;
  font-size:12px;
padding-left:${props => (props.paddingLeft ? props.paddingLeft :  '0.5px')};
padding-right:${props => (props.paddingRightt ? props.paddingRightt :  ' 0px')};
text-align:left;
`;