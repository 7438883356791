import React from 'react'
import { useEffect } from 'react'
import { useHistory, useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { useState } from 'react';
import { TabStyledNew } from 'shared/styles/constants/tagsStyles';
import LocationDetails from './LocationDetails';
import { ModalTabsContainer } from '../styles';
import { LocationContainer, ModaTopContainer } from './styles';
import { AppContext } from 'context';
import { useContext } from 'react';
import { LOCATION_DETAILS, LOCATION_SERVICE_CONTRACT, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import {
    getClient as localStorageClient,
} from 'utils/localStorage/token';
import ServiceClientList from './ClientServices';
import { FLOPPY } from 'utils/constants/icons';
import { SAVE } from 'utils/constants/messages';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
const tab1 = 1;
const tab2 = 2;

function AddLocationModal() {
    const { id } = useParams()
    const history = useHistory()

    const {
        contextChoices: choices,
        contextPermissions,
        contextSubscription,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        contextChoices,
        contextRelativesClient
    } = useContext(AppContext);

    const filterSuperAdmin = contextIsSuperAdmin;
    const filterAdmin = contextIsAdmin;
    const [tabSelected, setTabSelected] = useState(tab1);
    const [selectedId, setSeletedId] = useState(id)
    const userClient = localStorageClient();

    const [showError, setShowError] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingFinish, setLoadingFinish] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [errorSplitPercentage, setErrorSplitPercentage] = useState('');
    const [contractId, setContractId] = useState('');
    const [savedContract, setSavedContract] = useState('unsaved');
    const [promisesWithErrors, setPromisesWithErrors] = useState([]);
    const [rightPermission, setRightPermission] = useState({});
    const [clientAddress, setClientAddress] = useState({});
    const [clientFullName, setClientFullName] = useState('');
    const [rowData, setRowData] = useState({})
    const [displayPhoto, setDisplayPhoto] = useState()

    //Permissions Statee
    const [readPermissionLocationDetails, setReadPermissionLocationDetails] = useState(false)
    const [createPermissionLocationDetails, setCreatePermissionLocationDetails] = useState(false)
    const [updatePermissionLocationDetails, setUpdatePermissionLocationDetails] = useState(false)
    const [deletePermissionLocationDetails, setDeletePermissionLocationDetails] = useState(false)

    const [readPermissionLocationServiceContract, setReadPermissionLocationServiceContract] = useState(false)
    const [createPermissionLocationServiceContract, setCreatePermissionLocationServiceContract] = useState(false)
    const [updatePermissionLocationServiceContract, setUpdatePermissionLocationServiceContract] = useState(false)
    const [deletePermissionLocationServiceContract, setDeletePermissionLocationServiceContract] = useState(false)

    useEffect(() => {
        if (history.location.pathname === '/location/add/location-details' ||
            history.location.pathname === `/location/edit/${selectedId}/location-details`) {
            setTabSelected(tab1)
        } else if (history.location.pathname === '/location/add/contract' ||
            history.location.pathname === `/location/edit/${selectedId}/contract`) {
            setTabSelected(tab2)
        }
    }, [history.location.pathname])
    useEffect(() => { setSeletedId(id) }, [id])

    useEffect(() => {
        const rolesPermissions = contextPermissions;

        let rolePermissions = {};
        if (rolesPermissions.length > 0) {
            rolesPermissions.forEach(
                item => {
                    if (item.section !== null) {
                        rolePermissions[item.section.name] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_READ_PERMISSION
                        ] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_READ_PERMISSION
                        ] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_CREATE_PERMISSION
                        ] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_CREATE_PERMISSION
                        ] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_DELETE_PERMISSION
                        ] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_UPDATE_PERMISSION
                        ] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_UPDATE_PERMISSION
                        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_DELETE_PERMISSION
                        ] = item?.[SELF_WEB_DELETE_PERMISSION];
                    }
                }
                // }
            );
        }
        const roles = contextSubSectionPermissions;

        if (roles.length > 0) {
            roles.forEach(
                item => {
                    if (item.section !== null) {
                        rolePermissions[item.section.name] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_READ_PERMISSION
                        ] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_READ_PERMISSION
                        ] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_CREATE_PERMISSION
                        ] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_CREATE_PERMISSION
                        ] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_DELETE_PERMISSION
                        ] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_UPDATE_PERMISSION
                        ] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_UPDATE_PERMISSION
                        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_DELETE_PERMISSION
                        ] = item?.[SELF_WEB_DELETE_PERMISSION];
                    }
                }
            );
        }

        if (contextIsSuperAdmin || contextIsAdmin) {
            setReadPermissionLocationDetails(true)
            setCreatePermissionLocationDetails(true)
            setUpdatePermissionLocationDetails(true)
            setDeletePermissionLocationDetails(true)

            setReadPermissionLocationServiceContract(true)
            setCreatePermissionLocationServiceContract(true)
            setUpdatePermissionLocationServiceContract(true)
            setDeletePermissionLocationServiceContract(true)

        } else {

            // Location details
            if (rolePermissions[LOCATION_DETAILS]) {
                const hasWebPermission = contextSubscription?.[LOCATION_DETAILS]?.[WEB_PERMISSION];
                // const isSameEmployee = userEmployee === selectedEmpId;

                const hasReadPermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION];
                const hasSelfReadPermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION];
                const shouldReadAccess = (hasReadPermission || hasSelfReadPermission);

                setReadPermissionLocationDetails(shouldReadAccess)

                const hasCreatePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
                const hasSelfCreatePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
                const shouldCreateAccess = (hasCreatePermission || hasSelfCreatePermission);

                setCreatePermissionLocationDetails(shouldCreateAccess)

                const hasUpdatePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
                const hasSelfUpdatePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
                const shouldUpdateAccess = hasWebPermission && (hasUpdatePermission || hasSelfUpdatePermission);

                setUpdatePermissionLocationDetails(shouldUpdateAccess)

                const hasDeletePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
                const hasSelfDeletePermission = rolePermissions[LOCATION_DETAILS]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION]
                const shouldDeleteAccess = hasDeletePermission || hasSelfDeletePermission;

                setDeletePermissionLocationDetails(shouldDeleteAccess)
            }

            if (rolePermissions[LOCATION_SERVICE_CONTRACT]) {
                const hasWebPermission = contextSubscription?.[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION];
                // const isSameEmployee = userEmployee === selectedEmpId;

                const hasReadPermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION];
                const hasSelfReadPermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION];
                const shouldReadAccess = (hasReadPermission || hasSelfReadPermission);

                setReadPermissionLocationServiceContract(shouldReadAccess)

                const hasCreatePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
                const hasSelfCreatePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
                const shouldCreateAccess = (hasCreatePermission || hasSelfCreatePermission);

                setCreatePermissionLocationServiceContract(shouldCreateAccess)

                const hasUpdatePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
                const hasSelfUpdatePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
                const shouldUpdateAccess = hasWebPermission && (hasUpdatePermission || hasSelfUpdatePermission);

                setUpdatePermissionLocationServiceContract(shouldUpdateAccess)

                const hasDeletePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
                const hasSelfDeletePermission = rolePermissions[LOCATION_SERVICE_CONTRACT]?.[WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION]
                const shouldDeleteAccess = hasDeletePermission || hasSelfDeletePermission;

                setDeletePermissionLocationServiceContract(shouldDeleteAccess)
            }

        }

        setRightPermission(rolePermissions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextChoices, contextIsSuperAdmin, contextIsAdmin, contextSubscription]);


    const isCurrentTab = tab => {
        return tabSelected === tab;
    };


    return (
        <div>
            <div className="">
                <ModaTopContainer
                    className="position-fixed width-available"
                    style={{
                        paddingBottom: '10px',
                        paddingTop: '10px',
                        marginTop: "-135px",
                        backgroundColor: '#F0F0F7',
                    }}>
                    <RowInfoModel
                        name={rowData.first_name || rowData.full_name}
                        lastName={rowData.middle_name}
                        photoRow={
                            rowData.photo
                                ? URL.createObjectURL(rowData.photo)
                                : displayPhoto
                                    ? displayPhoto
                                    : ''
                        }
                        rows={[
                            { label: 'Name:', value: rowData.full_name },
                            {
                                label: 'OBD:',
                                value: rowData.location_onboarded_date ? `${moment(rowData.location_onboarded_date).format('DD-MM-YYYY')} (${moment().diff(rowData.location_onboarded_date, 'years')})` : '',
                            },
                        ]}
                    // dateOfBirth={dateOfBirth}
                    />
                    {/* </div> */}
                </ModaTopContainer>
                <ModalTabsContainer
                    className="position-fixed "
                    style={{ width: `86%`, backgroundColor: 'rgb(240, 240, 247)', marginTop: '-100px', paddingTop: '30px' }}>
                    <div className='w-100 d-flex justify-content-center'>
                        <TabStyledNew
                            style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                            active={isCurrentTab(tab1)}
                            onClick={() => {
                                if (history.location.pathname.includes('/location/add') && selectedId) {
                                    history.push('/location/add/location-details');
                                } else if (history.location.pathname.includes('/location/edit')) {
                                    history.push(`/location/edit/${selectedId}/location-details`);
                                }
                            }}>
                            Location Details
                        </TabStyledNew>
                        {readPermissionLocationServiceContract && (
                            <TabStyledNew
                                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                                active={isCurrentTab(tab2)}
                                onClick={() => {
                                    if (history.location.pathname.includes('/location/add') && selectedId) {
                                        history.push('/location/add/contract');
                                    } else if (history.location.pathname.includes('/location/edit')) {
                                        history.push(`/location/edit/${selectedId}/contract`);
                                    }
                                }}>
                                Service Contract
                            </TabStyledNew>)}
                    </div>
                </ModalTabsContainer>
            </div>
            <LocationContainer >
                {
                    isCurrentTab(tab1) && (
                        <LocationDetails
                            selectedId={selectedId}
                            readPermissionLocationDetails={readPermissionLocationDetails}
                            createPermissionLocationDetails={createPermissionLocationDetails}
                            updatePermissionLocationDetails={updatePermissionLocationDetails}
                            deletePermissionLocationDetails={deletePermissionLocationDetails}
                            setRowData={setRowData}
                            setClientAddress={setClientAddress}
                            setDisplayPhoto={setDisplayPhoto}
                        />
                    )
                }
                {
                    isCurrentTab(tab2) && (
                        <ServiceClientList
                            choices={choices}
                            selectedClientId={selectedId}
                            allErrors={showError}
                            setErrorSplitPercentage={setErrorSplitPercentage}
                            errorSplitPercentage={errorSplitPercentage}
                            loadingFinish={loadingFinish}
                            loadingSave={loadingSave}
                            textStatus={textStatus}
                            buttonIcon={buttonIcon}
                            filterAdmin={filterAdmin}
                            filterSuperAdmin={filterSuperAdmin}
                            contextSubscription={contextSubscription}
                            rightPermission={rightPermission}
                            userClient={userClient}
                            contractId={contractId}
                            savedContract={savedContract}
                            setSavedContract={setSavedContract}
                            addressValues={clientAddress || {}}
                            clientFullName={clientFullName}
                            setButtonIcon={setButtonIcon}
                            setTextStatus={setTextStatus}
                            clientErrors={[]}
                            promisesWithErrors={promisesWithErrors}
                            readServicePermissions={readPermissionLocationServiceContract}
                            createServicePermissions={createPermissionLocationServiceContract}
                            updateServicePermissions={updatePermissionLocationServiceContract}
                            deleteServicePermissions={deletePermissionLocationServiceContract}
                            contextRelativesClient={contextRelativesClient}
                        />
                    )
                }
            </LocationContainer>


        </div >
    )
}

export default withRouter(AddLocationModal)
