import React from 'react';
import {MessageSpan} from '../styles';
import PropTypes from 'prop-types';

const DuplicatedTimeRange = ({startDate, endDate}) => {
  return (
    <div className={'mt-3 d-flex justify-content-center text-center'}>
      <div>
        <div>
          The user has a shift assigned from{' '}
          <MessageSpan>{startDate}</MessageSpan> to{' '}
          <MessageSpan>{endDate}</MessageSpan>
        </div>
        <div className={'font-weight-bold'}>Would you like to replace it?</div>
      </div>
    </div>
  );
};

DuplicatedTimeRange.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default DuplicatedTimeRange;
