import { apiURL } from '../env';
import { get, deleteFetch, post, Newpost } from './Api';
import queryString from 'query-string';
import { methods } from 'utils/constants/api';
import { getToken, getTenant } from '../localStorage/token';
import moment from 'moment';

const { PUT, POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
export const getDasboardClientInfo = (start_date, end_date) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/dashboard/client/?start_date=${start_date}&end_date=${end_date}`;

  return get(url);
};
export const getDasboardEmployeeInfo = (start_date, end_date) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/dashboard/employee/?start_date=${start_date}&end_date=${end_date}`;

  return get(url);
};

export const getDasboardTeamKpis = (start_date, end_date, day) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/team-kpi/teamKpiRecord/?startDate=${start_date}&endDate=${end_date}&dataFormat=${day}`;

  return get(url);
};

export const getDasboardMedicationKpis = (start_date, end_date, day) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/medication-kpi/getMedicationRecord/?startDate=${start_date}&endDate=${end_date}&dataFormat=${day}`;

  return get(url);
};

export const getDasboardClientKpis = (start_date, end_date, day) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/client-kpi/clientKpiRecords/?startDate=${start_date}&endDate=${end_date}&dataFormat=${day}`;

  return get(url);
};

export const getDasboardVisitKpis = (start_date, end_date, day) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/visit-compliance-kpi/visitComplianceRecord/?startDate=${start_date}&endDate=${end_date}&dataFormat=${day}`;

  return get(url);
};

export const getInsightsRecords = async (values, offset, index) => {
  const baseUrl = getBaseUrl();
  let toParse = {};
  let filterParse = {}
  let stringified = "";

  toParse.offset = offset > 0 ? offset : '0';
  toParse.limit = values.limitperpage;

  if (values.start_date) {
    toParse.start_date = values.start_date
  }

  if (values.end_date) {
    toParse.end_date = values.end_date
  }
  if (values.client) {
    const clientIds = values.client.map(el => el.id)
    let joinedIds = clientIds.join(",")
    filterParse.clients = joinedIds
  }
  if(values.employees){
    const employeeIds = values.employees.map(el => el.id).join(",")
    filterParse.employees = employeeIds
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.filter(el => el.id !=="None").map(data => data.id).join(',');
    filterParse.headerbranch = branchFilter;
  }

  if(values.branch){
    const branchIds = values.branch.map(el => el.id).join(",")
    filterParse.branch = branchIds
  }


  if (values.headerbranch) {
    let branchFilter = values.headerbranch.filter(el => el.id !=="None").map(data => data.id).join(',');
    filterParse.headerbranch = branchFilter;
  }

  if(values.branch){
    const branchIds = values.branch.map(el => el.id).join(",")
    filterParse.branch = branchIds
  }


  stringified = queryString.stringify(toParse) || '';


  if (filterParse.clients) {
    stringified += '&clients=' + filterParse.clients
  }
  if(filterParse.employees){
    stringified += '&employees=' + filterParse.employees
  }
  if (filterParse.branch) {
    stringified += '&branch_id=' + filterParse.branch
  } else if (filterParse.headerbranch) {
    stringified += '&branch_id=' + filterParse.headerbranch
  }
  const url = `${baseUrl}/core/insights/insightsRecords/?${stringified}`
  return await get(url)
}

export const addToTriage = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/core/insights/insightsAddInTriage/`;
  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};

export const setMarkReview = (values, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/core/insights/markReviewInsightsData/?id=${id}`;
  const body = {
    ...values,
  };

  return Newpost(url, body, method);
};
