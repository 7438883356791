import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Title } from '@mui/icons-material';
import { Icon, IconButton, TextField, Typography } from '@mui/material';
import Select from 'react-select';
import { useState } from 'react';
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';
import Dropdown from './Dropdowns/filterDropDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'
import colors from 'shared/styles/constants/colors';
import { FlexGrid3Container, FlexGrid3Item, ModalContainer, TitleSection } from 'shared/styles/constants/tagsStyles';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Multiselect } from 'multiselect-react-dropdown';
import CloseIcon from '@mui/icons-material/Close';
import '../settingsBradFord.css'
import { makeStyles } from '@material-ui/core/styles';
import '../settingsBradFord.css'

import {
    Row,
    Col,
    IconWrapper,
    ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FLOPPY } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { useEffect } from 'react';
import { editCancelledVisit } from 'utils/api/ClientApi';
import { stubTrue } from 'lodash';
const edit_Title = 'Edit BradFord';
const new_Title = 'Add BradFord';
const EditBradFord = ({ addBradModal, setAddBradModal, fullBind, edit, setEdit, selectedOption, setSelectedOption, displayToggle, setDisplayToggle, alertToggle, setAlertToggle, period, setPeriod, arr, setArrr, absenceList, setAbsenceList, branchList, setBranchList, selectedBranch, setSelectedBranch, bradFordStatus, setBradFordStatus, saveBradFordFactor, stateManagement, isSubmitted,
    update, setBradFodeId, error, setError, setTitle
}) => {
    const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } = useForm({});

    const alAddedData = {
        lower_range: null,
        upper_range: null,
        trigger_point: null,
        message: null,
    }
    const classes = useStyles();
    const formValidation = () => {
        console.log("check formValidation")
        // setIsSubmitted(true)
        let error = {
            errorValue: false,
            abseneType: false,
            rollingPeriod: false,
            branchType: false,
            bradFordStatus: false,
            factorValue: false,
            factorMessgae: false
        }
        if (!selectedOption.length) { error = { ...error, errorValue: true, abseneType: true } }
        else { error = { ...error, errorValue: false, abseneType: false } }

        if (period == null) { error = { ...error, errorValue: true, rollingPeriod: true } }
        else { error = { ...error, errorValue: false, rollingPeriod: false } }

        if (selectedBranch == null) { error = { ...error, errorValue: true, branchType: true } }
        else { error = { ...error, errorValue: false, branchType: false } }

        if (!bradFordStatus.length) { error = { ...error, errorValue: true, bradFordStatus: true } }
        else { error = { ...error, errorValue: false, bradFordStatus: false } }

        if (bradFordStatus.length && bradFordStatus[0]?.name === null) { error = { ...error, errorValue: true, factorValue: true } }
        else { error = { ...error, errorValue: false, factorValue: false } }

        if (bradFordStatus.length && bradFordStatus[0]?.point === null) { error = { ...error, errorValue: true, factorMessgae: true } }
        else { error = { ...error, errorValue: false, factorMessgae: false } }


        if (error.abseneType || error.rollingPeriod || error.branchType || error.bradFordStatus || error.factorMessgae || error.factorValue
        ) {
            setError({ ...error, errorValue: true })
            return
        } else {
            setError({ ...error, value: false })
            saveBradFordFactor()
        }
    }


    useEffect(() => {
        if (edit) {
            setTitle(edit_Title)
        } else {
            setTitle(new_Title)

        }
    }, [edit])

    // console.log({
    //     bradFordStatus: bradFordStatus,
    //     selectedOption: selectedOption,
    //     period: period,
    //     selectedBranch: selectedBranch,
    //     name: bradFordStatus[0]?.name,
    //     point: bradFordStatus[0]?.point,
    //     error: error
    // }, "VALIDIATION CHECK")

    const bradFordStatusData = {
        name: null,
        point: null,
    }


    return (
        <Modal className={classes.modal}
            open={addBradModal}

            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-escription"
        >

            <Box sx={{ ...style }} maxHeight={'80%'} overflow={'hidden'} >

                <div style={{ display: "flex", flexDirection: 'row', marginBottom: 20 }}>
                    <Typography variant="h5" flex={1}>{edit ? 'Edit Bradford' : 'Add Bradford'}</Typography>
                    <div style={{ display: ' flex', justifyContent: 'flex-end', alignItems: "center" }}>
                        <CloseIcon onClick={() => {
                            // onClose = {() => {
                            stateManagement()
                            // }}
                        }} />
                    </div>

                </div>

                {/* Branch - rolling-etc */}
                <div className={`d-flex mt-2 mb-3 mr-4 `} >
                    <div className={'w-50 mr-2 '} >
                        <DropdownSearchable
                            required={true}
                            // {...useInput(`selectedBranch.id`, 'isRequired')}

                            disabled={edit ? (update ? false : true) : false}
                            // isMulti={true}
                            placeHolder={'Branch Name'}
                            options={branchList}
                            // selectedOptionsProps={selectedOption} // for multiple
                            selectedOption={selectedBranch}
                            // {...useInput(`selectedBranch`, 'isRequired')}
                            // for singleselect
                            onChange={value => {
                                setSelectedBranch(value)
                                setError({
                                    ...error,
                                    branchType: false
                                })
                            }
                            }
                        />
                    </div>

                    <div className={'w-50 mr-2'} >
                        <TextField
                            style={{ backgroundColor: colors.GRAY_100 }}
                            disabled={edit ? (update ? false : true) : false}
                            required
                            error={error.errorValue && error.rollingPeriod ? true : false}
                            id="outlined-error"
                            label="Rolling period(In month)"
                            placeholder='Rolling period(In month)'
                            // InputProps={{ inputProps: { min: "1", max: "12", step: "1" } }}
                            // type='number'
                            InputLabelProps={{
                                classes: {
                                    asterisk: classes.asterisk
                                },
                                style: {
                                    color: period ? "black" : null
                                },
                                shrink: true,
                            }}
                            // inputProps={{ inputMode: 'numeric' }}

                            type="number"
                            InputProps={{
                                inputProps: {
                                    inputMode: 'numeric',
                                },
                            }}
                            size='small'
                            color={'primary'}
                            defaultValue={period}
                            // style={{ flex: 1, }}
                            onChange={(text) => {
                                console.log(text.target.value, "jedokfjdkjfdfo")
                                setPeriod(parseInt(text.target.value))
                                setError({
                                    ...error,
                                    rollingPeriod: false
                                })
                            }}
                        />
                    </div>

                    <div className={'w-50 mr-2'}>
                        <FormControlLabel control={<Switch disabled={edit ? (update ? false : true) : false} checked={displayToggle} onChange={() => { setDisplayToggle(!displayToggle) }} />} label="Display Notification " />
                    </div>

                    <div className={'w-50 mr-2'}>
                        <FormControlLabel control={<Switch disabled={edit ? (update ? false : true) : false} checked={alertToggle} onChange={() => { setAlertToggle(!alertToggle) }} />} label="Alert Notification " />
                    </div>

                </div>
                {/* ABSENEC type DropDown */}
                <div style={{ flex: 1, display: "flex", flexDirection: 'row', margin: 5, }}>
                    <FlexGrid3Container className="w-100 mb-2">
                        {/* <FlexGrid3Item className="pl-1 pr-0 pr-md-1"> */}
                        <div className='w-100'>
                            <DropdownSearchable
                                required={true}
                                disabled={edit ? (update ? false : true) : false}
                                isMulti={true}
                                placeHolder={'Absence Type'}
                                options={absenceList}
                                selectedOptionsProps={selectedOption} // for multiple
                                // selectedOption={values.alertImportantContact}  // for singleselect
                                onChange={value => {
                                    setSelectedOption(value)
                                    setError({
                                        ...error,
                                        abseneType: false
                                    })
                                }
                                }
                            />
                        </div>
                        {/* </FlexGrid3Item> */}
                    </FlexGrid3Container>
                </div>



                {/* TriggeR POINT */}
                <div>
                    {/* <Typography variant="h6" style={{ marginTop: 20, }} >{'Trigger Point'}</Typography> */}
                    <div className={'mb-2'}>
                        <TitleSection>Trigger Point</TitleSection>
                    </div>
                    {
                        arr.map((item, index) => {

                            return (
                                <div key={index}>
                                    {/* <TitleSection >NEW TITLE ..!!</TitleSection> */}


                                    <div style={{ flex: 1, flexDirection: 'row', display: "flex", margin: 10 }}>

                                        <div style={{ flex: 1, display: ' flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                            <TextField
                                                style={{ backgroundColor: colors.GRAY_100 }}

                                                disabled={edit ? (update ? false : true) : false}
                                                id="outlined-error"
                                                label="Trigger point"
                                                placeholder='Trigger point'
                                                // type='number'
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                }}
                                                size='small'
                                                color={'primary'}
                                                defaultValue={item?.trigger_point}
                                                InputLabelProps={{
                                                    style: {
                                                        color: item?.trigger_point ? "black" : null
                                                    }
                                                }}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    // setPeriod(text.target.value)

                                                    const final = []
                                                    arr.forEach(value => {
                                                        if (item.trigger_id === value.trigger_id) {
                                                            final.push({ ...value, trigger_point: parseInt(text.target.value) })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setArrr(final)

                                                }}
                                            />
                                        </div>

                                        <div style={{ flex: 1, display: ' flex', justifyContent: "center", alignItems: "center", marginLeft: 10, }}>
                                            <TextField
                                                disabled={edit ? (update ? false : true) : false}
                                                style={{ backgroundColor: colors.GRAY_100 }}

                                                id="outlined-error"
                                                label="Lower Value"
                                                placeholder='Lower Value'
                                                size='small'
                                                // type='number'
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                }}
                                                color={'primary'}
                                                defaultValue={item?.lower_range}
                                                InputLabelProps={{
                                                    style: {
                                                        color: item?.lower_range ? "black" : null
                                                    }
                                                }}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    const final = []
                                                    arr.forEach(value => {
                                                        if (item.trigger_id === value.trigger_id) {
                                                            final.push({ ...value, lower_range: parseInt(text.target.value) })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setArrr(final)
                                                }}
                                            />
                                        </div>

                                        <div style={{ flex: 1, display: ' flex', justifyContent: "center", alignItems: "center", marginLeft: 10, }}>
                                            <TextField
                                                disabled={edit ? (update ? false : true) : false}
                                                style={{ backgroundColor: colors.GRAY_100 }}

                                                id="outlined-error"
                                                label="Upper Value"
                                                placeholder='Upper Value'
                                                size='small'
                                                // type='number'
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                }}
                                                color={'primary'}
                                                defaultValue={item?.upper_range}
                                                InputLabelProps={{
                                                    style: {
                                                        color: item?.upper_range ? "black" : null
                                                    }
                                                }}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    const final = []
                                                    arr.forEach(value => {
                                                        if (item.trigger_id === value.trigger_id) {
                                                            final.push({ ...value, upper_range: parseInt(text.target.value) })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setArrr(final)
                                                }}
                                            />
                                        </div>

                                        <div style={{ flex: 1, display: ' flex', justifyContent: 'flex-end', alignItems: "center", marginLeft: 10, }}>
                                            <TextField
                                                disabled={edit ? (update ? false : true) : false}
                                                style={{ backgroundColor: colors.GRAY_100 }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: item?.message ? "black" : null
                                                    }
                                                }}
                                                id="outlined-error"
                                                label="Alert"
                                                placeholder='Alert'
                                                size='small'
                                                color={'primary'}
                                                defaultValue={item?.message}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    const final = []
                                                    arr.forEach(value => {
                                                        if (item.trigger_id === value.trigger_id) {
                                                            final.push({ ...value, message: text.target.value })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setArrr(final)
                                                }}
                                            />
                                        </div>

                                        <div style={{ display: ' flex', justifyContent: 'flex-end', alignItems: "center", marginLeft: 10, }}>
                                            {
                                                edit ? (update &&
                                                    <IconWrapper

                                                        onClick={() => {
                                                            // message("Don't Clicke Me.")
                                                            setArrr(arr.filter((val, trigger_id) => trigger_id !== index))
                                                        }}>
                                                        <RiDeleteBinLine size={15} color='red' />
                                                    </IconWrapper>) :
                                                    <IconWrapper

                                                        onClick={() => {
                                                            // message("Don't Clicke Me.")
                                                            setArrr(arr.filter((val, trigger_id) => trigger_id !== index))
                                                        }}>
                                                        <RiDeleteBinLine size={15} color='red' />
                                                    </IconWrapper>
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                    <Fab
                        disabled={edit ? (update ? false : true) : false}
                        onClick={() => { setArrr([...arr, { ...alAddedData, trigger_id: arr.length }]) }}
                        // style={{ margin: 10 }}
                        style={{ color: colors.WHITE, backgroundColor: colors.PURPLE, padding: 10 }}

                        variant="extended" color="primary" aria-label="add" size='small'>
                        <AddIcon />
                        Trigger Point
                    </Fab>
                </div>

                {/*  Brad-Ford Status */}
                <div style={{ marginTop: 20 }}>

                    {/* <Typography required variant="h6" style={{ marginTop: 20 }}>Status <span style={{ color: 'red' }}>*</span></Typography> */}
                    <div className={'mb-2'}>
                        <TitleSection>Status <span style={{ color: colors.ERROR }}>*</span></TitleSection>
                    </div>
                    {
                        bradFordStatus?.map((item, index) => {

                            return (
                                <div key={index}>
                                    {/* <TitleSection >NEW TITLE ..!!</TitleSection> */}


                                    <div style={{ flex: 1, flexDirection: 'row', display: "flex", margin: 10 }}>

                                        <div style={{ display: ' flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                            <TextField
                                                disabled={edit ? (update ? false : true) : false}
                                                style={{ backgroundColor: colors.GRAY_100 }}
                                                required
                                                error={error.errorValue && error.factorValue ? true : false}
                                                id="outlined-error"
                                                label="Factor Value"
                                                placeholder='Factor Value'
                                                // type='numbe'
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                }}
                                                size='small'
                                                color={'primary'}
                                                defaultValue={item?.point}
                                                InputLabelProps={{
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    },
                                                    style: {
                                                        color: item?.point ? "black" : null
                                                    }
                                                }}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    // setPeriod(text.target.value)

                                                    const final = []
                                                    bradFordStatus.forEach(value => {
                                                        if (item.status_id === value.status_id) {
                                                            final.push({ ...value, point: parseInt(text.target.value) })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setBradFordStatus(final)
                                                    setError({
                                                        ...error,
                                                        factorValue: false
                                                    })

                                                }}
                                            />
                                        </div>

                                        <div style={{ marginLeft: 10, display: ' flex', justifyContent: "center", alignItems: "center", }}>
                                            <TextField
                                                disabled={edit ? (update ? false : true) : false}
                                                style={{ backgroundColor: colors.GRAY_100 }}

                                                required
                                                error={error.errorValue && error.factorMessgae ? true : false}
                                                id="outlined-error"
                                                label="Status"
                                                placeholder='Status'
                                                size='small'
                                                color={'primary'}
                                                defaultValue={item?.name}
                                                InputLabelProps={{
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    },
                                                    style: {
                                                        color: item?.name ? "black" : null
                                                    }
                                                }}
                                                // style={{ flex: 1, }}
                                                onChange={(text) => {
                                                    // setPeriod(text.target.value)

                                                    const final = []
                                                    bradFordStatus.forEach(value => {
                                                        if (item.status_id === value.status_id) {
                                                            final.push({ ...value, name: text.target.value })
                                                            return
                                                        }
                                                        final.push(value)
                                                    })
                                                    setBradFordStatus(final)
                                                    setError({
                                                        ...error,
                                                        factorMessgae: false
                                                    })

                                                }}
                                            />
                                        </div>

                                        <div style={{ marginLeft: 10, display: ' flex', justifyContent: 'flex-end', alignItems: "center", }}>
                                            {
                                                edit ? (update &&
                                                    <IconWrapper
                                                        onClick={() => {
                                                            // message("Don't Clicke Me.")
                                                            setBradFordStatus(bradFordStatus.filter((val, status_id) => status_id !== index))
                                                        }}>
                                                        <RiDeleteBinLine size={15} color='red' />
                                                    </IconWrapper>) :
                                                    <IconWrapper
                                                        onClick={() => {
                                                            // message("Don't Clicke Me.")
                                                            setBradFordStatus(bradFordStatus.filter((val, status_id) => status_id !== index))
                                                        }}>
                                                        <RiDeleteBinLine size={15} color='red' />
                                                    </IconWrapper>
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                    <Fab
                        disabled={edit ? (update ? false : true) : false}
                        onClick={() => {
                            setBradFordStatus([...bradFordStatus, { ...bradFordStatusData, status_id: bradFordStatus.length }])
                            setError({
                                ...error,
                                bradFordStatus: false
                            })
                        }}
                        style={{ color: colors.WHITE, backgroundColor: colors.PURPLE, padding: 10 }}
                        variant="extended" aria-label="add" size='small'>
                        <AddIcon />
                        Status
                    </Fab>
                </div>


                <div style={{ marginTop: 10, flexDirection: "row", display: "flex", marginTop: '16px', justifyContent: "flex-end" }}>
                    {
                        (error.errorValue || error.abseneType || error.branchType || error.bradFordStatus || error.factorMessgae || error.factorValue || error.rollingPeriod) &&
                        <div style={{ color: colors.ERROR, margin: 5 }}>Some of the fields have invalid or empty values,Please review. </div>
                    }
                    {/* <Button variant="contained" color="primary"
                        style={{ color: colors.WHITE, backgroundColor: colors.PURPLE }}
                        disabled={isSubmitted}
                        onClick={() => {
                            formValidation()
                        }}>
                        {'Save'}
                    </Button> */}
                    {
                        edit ? (update &&
                            <PrimaryButtonForm
                                disabled={isSubmitted}
                                minWidth="6rem"
                                onClick={
                                    () => {
                                        formValidation()
                                    }
                                }
                            >
                                <span className={'mr-2'}>SAVE</span>
                                {FLOPPY}
                            </PrimaryButtonForm>) :
                            <PrimaryButtonForm
                                disabled={isSubmitted}
                                minWidth="6rem"
                                onClick={
                                    () => {
                                        formValidation()
                                    }
                                }
                            >
                                <span className={'mr-2'}>SAVE</span>
                                {FLOPPY}
                            </PrimaryButtonForm>
                    }
                </div>

            </Box >
        </Modal >

        // <ModalContainer max_width={'70%'}>
        //     <ModalDynamic
        //         {...fullBind}
        //         max_width={'65%'}
        //         minWidthTitle="8.5rem"
        //         loading={false}
        //     >
        //         <div className="pl-3 pr-3 pb-3 pt-0">
        //             {/* Branch - rolling-etc */}
        //             <div className={`d-flex mt-2 mb-3 mr-4`}>
        //                 <div className={'w-50 mr-2'}>
        //                     <DropdownSearchable
        //                         required={true}
        //                         disabled={edit ? (update ? false : true) : false}
        //                         // isMulti={true}
        //                         placeHolder={'Branch Name'}
        //                         options={branchList}
        //                         // selectedOptionsProps={selectedOption} // for multiple
        //                         selectedOption={selectedBranch}  // for singleselect
        //                         onChange={value => {
        //                             setSelectedBranch(value)
        //                             setError({
        //                                 ...error,
        //                                 branchType: false
        //                             })
        //                         }
        //                         }
        //                     />
        //                 </div>

        //                 <div className={'w-50 mr-2'}>
        //                     <TextField
        //                         disabled={edit ? (update ? false : true) : false}
        //                         required
        //                         error={error.errorValue && error.rollingPeriod ? true : false}
        //                         id="outlined-error"
        //                         label="Rolling period(In month)"
        //                         placeholder='Rolling period(In month)'
        //                         // InputProps={{ inputProps: { min: "1", max: "12", step: "1" } }}
        //                         // type='number'
        //                         InputLabelProps={{
        //                             classes: {
        //                                 asterisk: classes.asterisk
        //                             }
        //                         }}
        //                         inputProps={{ inputMode: 'numeric' }}
        //                         size='small'
        //                         color={'primary'}
        //                         defaultValue={period}
        //                         // style={{ flex: 1, }}
        //                         onChange={(text) => {
        //                             setPeriod(parseInt(text.target.value))
        //                             setError({
        //                                 ...error,
        //                                 rollingPeriod: false
        //                             })
        //                         }}
        //                     />
        //                 </div>

        //                 <div className={'w-50 mr-2'}>
        //                     <FormControlLabel control={<Switch disabled={edit ? (update ? false : true) : false} checked={displayToggle} onChange={() => { setDisplayToggle(!displayToggle) }} />} label="Display Notification " />
        //                 </div>

        //                 <div className={'w-50 mr-2'}>
        //                     <FormControlLabel control={<Switch disabled={edit ? (update ? false : true) : false} checked={alertToggle} onChange={() => { setAlertToggle(!alertToggle) }} />} label="Alert Notification " />
        //                 </div>

        //             </div>
        //             {/* ABSENEC type DropDown */}
        //             <div style={{ flex: 1, display: "flex", flexDirection: 'row', margin: 5, }}>
        //                 <FlexGrid3Container className="w-100 mb-2">
        //                     {/* <FlexGrid3Item className="pl-1 pr-0 pr-md-1"> */}
        //                     <div className='w-100'>
        //                         <DropdownSearchable
        //                             required={true}
        //                             disabled={edit ? (update ? false : true) : false}
        //                             isMulti={true}
        //                             placeHolder={'Absence Type'}
        //                             options={absenceList}
        //                             selectedOptionsProps={selectedOption} // for multiple
        //                             // selectedOption={values.alertImportantContact}  // for singleselect
        //                             onChange={value => {
        //                                 setSelectedOption(value)
        //                                 setError({
        //                                     ...error,
        //                                     abseneType: false
        //                                 })
        //                             }
        //                             }
        //                         />
        //                     </div>
        //                     {/* </FlexGrid3Item> */}
        //                 </FlexGrid3Container>
        //             </div>
        //             {/* TriggeR POINT */}
        //             <div>
        //                 {/* <Typography variant="h6" style={{ marginTop: 20, }} >{'Trigger Point'}</Typography> */}
        //                 <div className={'mb-2'}>
        //                     <TitleSection>Trigger Point</TitleSection>
        //                 </div>
        //                 {
        //                     arr.map((item, index) => {

        //                         return (
        //                             <div key={index}>
        //                                 {/* <TitleSection >NEW TITLE ..!!</TitleSection> */}


        //                                 <div style={{ flex: 1, flexDirection: 'row', display: "flex", margin: 10 }}>

        //                                     <div style={{ flex: 1, display: ' flex', justifyContent: 'flex-start', alignItems: "center" }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}
        //                                             id="outlined-error"
        //                                             label="Trigger point"
        //                                             placeholder='Trigger point'
        //                                             // type='number'
        //                                             inputProps={{ inputMode: 'numeric' }}
        //                                             size='small'
        //                                             color={'primary'}
        //                                             defaultValue={item?.trigger_point}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 // setPeriod(text.target.value)

        //                                                 const final = []
        //                                                 arr.forEach(value => {
        //                                                     if (item.trigger_id === value.trigger_id) {
        //                                                         final.push({ ...value, trigger_point: parseInt(text.target.value) })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setArrr(final)

        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ flex: 1, display: ' flex', justifyContent: "center", alignItems: "center", marginLeft: 10, }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}

        //                                             id="outlined-error"
        //                                             label="Lower Value"
        //                                             placeholder='Lower Value'
        //                                             size='small'
        //                                             // type='number'
        //                                             inputProps={{ inputMode: 'numeric' }}
        //                                             color={'primary'}
        //                                             defaultValue={item?.lower_range}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 const final = []
        //                                                 arr.forEach(value => {
        //                                                     if (item.trigger_id === value.trigger_id) {
        //                                                         final.push({ ...value, lower_range: parseInt(text.target.value) })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setArrr(final)
        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ flex: 1, display: ' flex', justifyContent: "center", alignItems: "center", marginLeft: 10, }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}

        //                                             id="outlined-error"
        //                                             label="Upper Value"
        //                                             placeholder='Upper Value'
        //                                             size='small'
        //                                             // type='number'
        //                                             inputProps={{ inputMode: 'numeric' }}
        //                                             color={'primary'}
        //                                             defaultValue={item?.upper_range}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 const final = []
        //                                                 arr.forEach(value => {
        //                                                     if (item.trigger_id === value.trigger_id) {
        //                                                         final.push({ ...value, upper_range: parseInt(text.target.value) })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setArrr(final)
        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ flex: 1, display: ' flex', justifyContent: 'flex-end', alignItems: "center", marginLeft: 10, }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}

        //                                             id="outlined-error"
        //                                             label="Alert"
        //                                             placeholder='Alert'
        //                                             size='small'
        //                                             color={'primary'}
        //                                             defaultValue={item?.message}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 const final = []
        //                                                 arr.forEach(value => {
        //                                                     if (item.trigger_id === value.trigger_id) {
        //                                                         final.push({ ...value, message: text.target.value })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setArrr(final)
        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ display: ' flex', justifyContent: 'flex-end', alignItems: "center", marginLeft: 10, }}>
        //                                         {
        //                                             edit ? (update &&
        //                                                 <IconWrapper

        //                                                     onClick={() => {
        //                                                         // message("Don't Clicke Me.")
        //                                                         setArrr(arr.filter((val, trigger_id) => trigger_id !== index))
        //                                                     }}>
        //                                                     <RiDeleteBinLine size={15} color='red' />
        //                                                 </IconWrapper>) :
        //                                                 <IconWrapper

        //                                                     onClick={() => {
        //                                                         // message("Don't Clicke Me.")
        //                                                         setArrr(arr.filter((val, trigger_id) => trigger_id !== index))
        //                                                     }}>
        //                                                     <RiDeleteBinLine size={15} color='red' />
        //                                                 </IconWrapper>
        //                                         }
        //                                     </div>

        //                                 </div>
        //                             </div>
        //                         )
        //                     })
        //                 }
        //                 <Fab
        //                     disabled={edit ? (update ? false : true) : false}
        //                     onClick={() => { setArrr([...arr, { ...alAddedData, trigger_id: arr.length }]) }}
        //                     // style={{ margin: 10 }}
        //                     style={{ color: colors.WHITE, backgroundColor: colors.PURPLE, padding: 10 }}

        //                     variant="extended" color="primary" aria-label="add" size='small'>
        //                     <AddIcon />
        //                     Trigger Point
        //                 </Fab>
        //             </div>

        //             {/*  Brad-Ford Status */}
        //             <div style={{ marginTop: 20 }}>

        //                 {/* <Typography required variant="h6" style={{ marginTop: 20 }}>Status <span style={{ color: 'red' }}>*</span></Typography> */}
        //                 <div className={'mb-2'}>
        //                     <TitleSection>Status *</TitleSection>
        //                 </div>
        //                 {
        //                     bradFordStatus?.map((item, index) => {

        //                         return (
        //                             <div key={index}>
        //                                 {/* <TitleSection >NEW TITLE ..!!</TitleSection> */}


        //                                 <div style={{ flex: 1, flexDirection: 'row', display: "flex", margin: 10 }}>

        //                                     <div style={{ display: ' flex', justifyContent: 'flex-start', alignItems: "center" }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}

        //                                             required
        //                                             error={error.errorValue && error.factorValue ? true : false}
        //                                             id="outlined-error"
        //                                             label="Factor Value"
        //                                             placeholder='Factor Value'
        //                                             // type='numbe'
        //                                             inputProps={{ inputMode: 'numeric' }}
        //                                             size='small'
        //                                             color={'primary'}
        //                                             defaultValue={item?.point}
        //                                             InputLabelProps={{
        //                                                 classes: {
        //                                                     asterisk: classes.asterisk
        //                                                 }
        //                                             }}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 // setPeriod(text.target.value)

        //                                                 const final = []
        //                                                 bradFordStatus.forEach(value => {
        //                                                     if (item.status_id === value.status_id) {
        //                                                         final.push({ ...value, point: parseInt(text.target.value) })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setBradFordStatus(final)
        //                                                 setError({
        //                                                     ...error,
        //                                                     factorValue: false
        //                                                 })

        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ marginLeft: 10, display: ' flex', justifyContent: "center", alignItems: "center", }}>
        //                                         <TextField
        //                                             disabled={edit ? (update ? false : true) : false}

        //                                             required
        //                                             error={error.errorValue && error.factorMessgae ? true : false}
        //                                             id="outlined-error"
        //                                             label="Status"
        //                                             placeholder='Status'
        //                                             size='small'
        //                                             color={'primary'}
        //                                             defaultValue={item?.name}
        //                                             InputLabelProps={{
        //                                                 classes: {
        //                                                     asterisk: classes.asterisk
        //                                                 }
        //                                             }}
        //                                             // style={{ flex: 1, }}
        //                                             onChange={(text) => {
        //                                                 // setPeriod(text.target.value)

        //                                                 const final = []
        //                                                 bradFordStatus.forEach(value => {
        //                                                     if (item.status_id === value.status_id) {
        //                                                         final.push({ ...value, name: text.target.value })
        //                                                         return
        //                                                     }
        //                                                     final.push(value)
        //                                                 })
        //                                                 setBradFordStatus(final)
        //                                                 setError({
        //                                                     ...error,
        //                                                     factorMessgae: false
        //                                                 })

        //                                             }}
        //                                         />
        //                                     </div>

        //                                     <div style={{ marginLeft: 10, display: ' flex', justifyContent: 'flex-end', alignItems: "center", }}>
        //                                         {
        //                                             edit ? (update &&
        //                                                 <IconWrapper
        //                                                     onClick={() => {
        //                                                         // message("Don't Clicke Me.")
        //                                                         setBradFordStatus(bradFordStatus.filter((val, status_id) => status_id !== index))
        //                                                     }}>
        //                                                     <RiDeleteBinLine size={15} color='red' />
        //                                                 </IconWrapper>) :
        //                                                 <IconWrapper
        //                                                     onClick={() => {
        //                                                         // message("Don't Clicke Me.")
        //                                                         setBradFordStatus(bradFordStatus.filter((val, status_id) => status_id !== index))
        //                                                     }}>
        //                                                     <RiDeleteBinLine size={15} color='red' />
        //                                                 </IconWrapper>
        //                                         }
        //                                     </div>

        //                                 </div>
        //                             </div>
        //                         )
        //                     })
        //                 }
        //                 <Fab
        //                     disabled={edit ? (update ? false : true) : false}
        //                     onClick={() => {
        //                         setBradFordStatus([...bradFordStatus, { ...bradFordStatusData, status_id: bradFordStatus.length }])
        //                         setError({
        //                             ...error,
        //                             bradFordStatus: false
        //                         })
        //                     }}
        //                     style={{ color: colors.WHITE, backgroundColor: colors.PURPLE, padding: 10 }}
        //                     variant="extended" aria-label="add" size='small'>
        //                     <AddIcon />
        //                     Status
        //                 </Fab>
        //             </div>


        //             <div style={{ marginTop: 10, flexDirection: "row", display: "flex", marginTop: '16px', justifyContent: "flex-end" }}>
        //                 {
        //                     (error.errorValue || error.abseneType || error.branchType || error.bradFordStatus || error.factorMessgae || error.factorValue || error.rollingPeriod) &&
        //                     <div style={{ color: colors.ERROR, margin: 5 }}>Some of the fields have invalid or empty values,Please review. </div>
        //                 }
        //                 {/* <Button variant="contained" color="primary"
        //                 style={{ color: colors.WHITE, backgroundColor: colors.PURPLE }}
        //                 disabled={isSubmitted}
        //                 onClick={() => {
        //                     formValidation()
        //                 }}>
        //                 {'Save'}
        //             </Button> */}
        //                 {
        //                     edit ? (update &&
        //                         <PrimaryButtonForm
        //                             disabled={isSubmitted}
        //                             minWidth="6rem"
        //                             onClick={
        //                                 () => {
        //                                     formValidation()
        //                                 }
        //                             }
        //                         >
        //                             <span className={'mr-2'}>SAVE</span>
        //                             {FLOPPY}
        //                         </PrimaryButtonForm>) :
        //                         <PrimaryButtonForm
        //                             disabled={isSubmitted}
        //                             minWidth="6rem"
        //                             onClick={
        //                                 () => {
        //                                     formValidation()
        //                                 }
        //                             }
        //                         >
        //                             <span className={'mr-2'}>SAVE</span>
        //                             {FLOPPY}
        //                         </PrimaryButtonForm>
        //                 }
        //             </div>
        //         </div>

        //         {/* <div style={{ display: "flex", flexDirection: 'row', marginBottom: 20 }}>
        //             <Title>Settings Company</Title>
        //             <div style={{ display: ' flex', justifyContent: 'flex-end', alignItems: "center" }}>
        //                 <CloseIcon onClick={() => {
        //                     // onClose = {() => {
        //                     stateManagement()
        //                     // }}
        //                 }} />
        //             </div>

        //         </div> */}


        //     </ModalDynamic>
        // </ModalContainer>
    )
}

export default EditBradFord;


const style = {
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: 'translate(-50%)',
    width: '65%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: '2px outset ',
    pt: 2,
    px: 4,
    pb: 3,
};


const useStyles = makeStyles({
    asterisk: {
        color: 'red', // Change the color here
    },
    modal: {
        // Disable vertical and horizontal lines
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
    },
});



{/* 
<Dropdown
    items={absenceList} // array yhui se jayda
    itemsSelected={selectedOption} //dekhane ke liye
    setSelectedFilter={item => { // set krane ke liye dekhane ke vaaste
        setSelectedOption(item)
    }}
    placeHolder={'Absence Types'}
    isObject
    allOption

    setItemsSelected={items => {
        // console.log(items, "------------")
    }}
/>
*/}
{/* 
    <div className="d-flex" >
        <div className="p-2 flex-fill">{edit ? 'Edit Brad Ford' : 'Add Brad Ford'}</div>
    </div>
    <hr />
*/}


