import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getShiftPatternById, SetActualBulkTime } from "utils/api/SchedulerApi"
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import moment from 'moment'

const SelectPattern = ({
  fullBind,
  setModalSelectPattern,
  shiftPatterns,
  parentValues,
  setParentValues,
  toast
}) => {
  const SHIFT_VALUES = 'shiftValues';

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Select Shift Pattern',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const loadShiftPatternById = async idSelected => {
    const response = await getShiftPatternById(idSelected);

    values[SHIFT_VALUES] = [];
    values.name = response.name;
    //values.id = response.id;
    values.color_code = response.color_code;
    Object.keys(response.details).forEach((key, index) => {
      values[SHIFT_VALUES][key] = {};
      response.details[key].forEach((detail, detIndex) => {
        if (detIndex === 0) {
          values[SHIFT_VALUES][key].start = detail.start;
          values[SHIFT_VALUES][key].end = detail.end;
          values[SHIFT_VALUES][key].break_start = detail.break_start;
          values[SHIFT_VALUES][key].break_end = detail.break_end;
          values[SHIFT_VALUES][key].shift_type = detail.shift_type;
          values[SHIFT_VALUES][key].travel_mode = detail.travel_mode;
          values[SHIFT_VALUES][key].shift_bill_rate = detail.shift_bill_rate;
          values[SHIFT_VALUES][key].pay_rate_card = detail.pay_rate_card;
          values[SHIFT_VALUES][key].repeat = detail.repeat;
          values[SHIFT_VALUES][key].duration = detail.duration;
          values[SHIFT_VALUES][key].travel_time = detail.travel_time;
          values[SHIFT_VALUES][key].duration = detail.start - detail.end;

          if (detail.start === '00:00:00' && detail.end === '23:59:00') {
            values[SHIFT_VALUES][key].full_day = true;
          } else {
            values[SHIFT_VALUES][key].full_day = false;
          }
          values[SHIFT_VALUES][key].is_overnight = detail.is_overnight;
          values[SHIFT_VALUES][key].daysofWeek = [
            { day: 'MON', dayValue: 1, selected: false },
            { day: 'TUE', dayValue: 2, selected: false },
            { day: 'WED', dayValue: 3, selected: false },
            { day: 'THU', dayValue: 4, selected: false },
            { day: 'FRI', dayValue: 5, selected: false },
            { day: 'SAT', dayValue: 6, selected: false },
            { day: 'SUN', dayValue: 7, selected: false },
          ];
        }
        values[SHIFT_VALUES][key].daysofWeek.find(
          day => day.dayValue === detail.day_of_week
        ).selected = true;
        let repeat_on_days = []
        values[SHIFT_VALUES][key].daysofWeek.forEach((item) => {
          if (item.selected) {
            repeat_on_days.push(item.day)
          }
        });
        values[SHIFT_VALUES][key].repeat_on = repeat_on_days;
      });
    });
    values.shiftValues.forEach((obj, index) => {
      parentValues.regularShifts.shifts.push({
        id: obj.id,
        type: obj.shift_type,
        travel_mode: obj.travel_mode && obj.travel_mode,
        end_time: obj.end,
        start_time: obj.start,
        effective_date: obj.start_date,
        break_start_time: obj.break_start,
        break_end_time: obj.break_end,
        is_full_day: obj.full_day,
        is_overnight: obj.is_overnight,
        duration: obj.duration,
        travel_time: obj.travel_time && obj.travel_time,
        repeat_on: obj.repeat_on ? obj.repeat_on.join(',') : '',
        regular_shift_pay_rate_id: obj.pay_rate_card,
      });
    });
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Added Successfully',
    });
    setParentValues({ ...parentValues });
    setLoadingSave(false);
    setValues({ ...values });
    setModalSelectPattern(false)
  };

  const handleTimeSelector = (e) => {
    values.actual_visit_time = moment(e.target.value).format("HH:mm:ss")
    values.actual_time = moment(e.target.value).format("HH:mm")
    setValues({ ...values })
  }
  const handleSubmit = () => {
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setErrorMessage('');
    loadShiftPatternById(values.shift_pattern_id)
  }


  return (
   <>
    <ModalContainer max_width={'40%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' pl-3 pr-3 pb-3'}>
          <div className="mb-2">
            <div className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Shift Pattern'}
                // width="145%"
                options={shiftPatterns || []}
                {...useInput(
                  `shift_pattern_id`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.shift_pattern_id : null
                }
                onChange={value =>
                  dropDownHandler(
                    { shift_pattern_id: value ? value : null },
                    'shift_pattern_id'
                  )
                }
              />
            </div>

          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem"
                  onClick={handleSubmit}

                >
                  <span className={'mr-2'}>Add Shifts</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
   </>
  );
}

export default SelectPattern;
