import {LARGE_COL  , SMALL_COL,EX_SMALL_COL, MEDIUM_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Menu',
    name: 'menu',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
   {
    label: 'Time Source',
    name: 'time_source',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Type ',
    name: 'type',
    status: 0,
    className: 'grow-mobile ',
    max_width: '',
  },
  {
    label: 'Rate Source',
    name: 'rate_source',
    status: 0,
    className: 'grow-mobile ',
    max_width: LARGE_COL,
  },
 
  
];

export  const RowsData=[
  {
    Menu: "Client",
    TimeSource: "Visit",
    Type: null,
    RateSource: "Bill Rate Card"
  },
  {
    Menu: "Client",
    TimeSource: "Cancellations - % on visit cancellation",
    Type: null,
    RateSource: "Bill Rate Card"
  },
  {
    Menu: "Client",
    TimeSource: "Discounts / Promotions",
    Type: null,
    RateSource: "Bill Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Visit",
    Type: "Regular",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Visit",
    Type: "Overtime",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Visit",
    Type: "Shadowing",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Visit",
    Type: "Observations",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Visit",
    Type: "Driver",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Travel - Time",
    Type: null,
    RateSource: "Travel Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Travel - Mileage",
    Type: null,
    RateSource: "Travel Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Bank-Flexible",
    Type: "Regular",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Bank-Flexible",
    Type: "Overtime",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Bank-Flexible",
    Type: "Regular",
    RateSource: "Pay Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "On Call",
    Type: "On Call",
    RateSource: "On Call Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Office - Supervisory",
    Type: "Supervisory",
    RateSource: "Office Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Office - Training",
    Type: "Training",
    RateSource: "Office Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Office - Meetings",
    Type: "Meetings",
    RateSource: "Office Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Office - Administration",
    Type: "Admin",
    RateSource: "Office Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Break",
    Type: null,
    RateSource: null
  },
  {
    Menu: "Employees",
    TimeSource: "Wait",
    Type: null,
    RateSource: null
  },
  {
    Menu: "Employees",
    TimeSource: "Pay Adjustments - Additions/Deductions",
    Type: null,
    RateSource: "Team Expenses"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Compassionate Leave - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  // start
  {
    Menu: "Employees",
    TimeSource: "Absence - Compassionate Leave - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Dependents Leave - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Dependents Leave - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Holidays - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Holidays - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Maternity/Paternity - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Maternity/Paternity - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Medical Appointment - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Medical Appointment - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Other Leave - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Other Leave - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Sickness - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Sickness - Unpaid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Sickness - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Training / Study Leave - Paid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Training / Study Leave - Unpaid",
    Type: null,
    RateSource: "Absence Rate Card"
  },
  {
    Menu: "Employees",
    TimeSource: "Absence - Training / Study Leave - Paid at other rate",
    Type: null,
    RateSource: "Absence Rate Card"
  },
]
