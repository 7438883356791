import React, { useState, useEffect, Fragment, useContext } from 'react';
import { getChoices, getCompanyBranches } from 'utils/api/CoreApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  setClient,
  getClient,
  getClients,
  setServiceContractVisit,
  deleteServiceContractVisit,
  setClientServicesContract,
  setServiceContractSplitFunder,
  deleteServiceContractSplitFunder,
  setServiceContractVisitSkills,
  deleteServiceContractVisitSkills,
  savePreferredEmployees,
  deletePreferredEmployees,
  saveAvoidEmployees,
  deleteAvoidEmployees,
  setSeviceEmployeeRequired,
  setSeviceEmployeeSkills,
  saveSchedulerVisit,
} from 'utils/api/ClientApi';
import { getEmployees } from 'utils/api/EmployeeApi';
import PersonalDetails from 'components/DashboardComponents/Client/AddModal/PersonalDetails';
import ClientPreference from 'components/DashboardComponents/Client/AddModal/ClientPreference';
import Notes from 'components/DashboardComponents/Client/AddModal/Notes';
import CareNotes from 'components/DashboardComponents/Client/AddModal/CareNotes';
import VisitCareNote from 'components/DashboardComponents/Client/AddModal/VisitCareNotes/VisitCareNote';
import MedicationNotes from 'components/DashboardComponents/Client/AddModal/MedicationNotes';
import { pickFile } from 'utils/files';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import moment from 'moment';
import {
  HealthDetail,
  NextOfKin,
  CareContact,
} from 'components/SharedComponents/FormSections';
import { useForm } from 'hooks/FormHook';
import {
  ContainerResponsive,
  ModalContainer,
  TabStyled,
  ShowTab,
  ErrorMessage,
  FlexGrid3Container,
  FlexGrid3Item,
  ShowClientModalTab,
  TabStyledNew,
} from 'shared/styles/constants/tagsStyles';
import _ from 'lodash';
import ServiceClientList from '../ClientServices';
import { FlexEqual, TitleSection } from '../../Team/AddModal/styles';
import DropdownSearchable from '../../../SharedComponents/DropdownSearchable';
import { getRoles } from '../../../../utils/api/UsersApi';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import {
  getCategoryPermission,
  getPermissions,
  getSubSectionPermissions,
  getStaff,
  getEmployee,
  getOrgname,
} from '../../../../utils/localStorage/user';
import {
  CLIENT_SERVICE_CONTRACT,
  CLIENT_DETAILS,
  CLIENT_KPI_DETAILS,
  CLIENT_PERSONAL_DETAILS,
  CLIENT_PREFRENCES,
  LIST_OF_CLIENT_SETTINGS,
  TRIAGE_PERMISSION,
  CLIENT_IMPORTANT_CONTACT,
  CLIENT_NOTES,
  CLIENT_MEDICATION_NOTES,
  CLIENT_CARE_NOTES,
  CLIENT_FILE,
  CLIENT_CONTRACTED_VISITS,
  CLIENT_CANELLED_VISITS,
  CLIENT_OFFER_VISITS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  CLIENT_DETAILS_HEALTH,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  CLIENT_CANCELLED_VISITS,
  SELF_WEB_DELETE_PERMISSION,
  CLIENT_VISITS,
  CLIENT_VISIT_CARE_NOTES,
  CLIENT_TASK,
  CLIENT_TRIAGE,
  CLIENT_VISIT,
  CLIENT_PLANNED_TASK,
  CLIENT_MEDICATION_PRESCRIPTION,
  VISIT_CARE_NOTES,
  CLIENT_CARE_DIARY,
  CLIENT_CARE_PLAN,
  CLIENT_BODY_PART,
  CLIENT_MEDICATION_TAB,
  CLIENT_FORMS,
  CLIENT_EXPENSES,
  EMPLOYEE_AVERAGE_HOURS,
  CLIENT_HR_TASK,
  CLIENT_ABOUT_ME,
  CLIENT_RISK_ASSESSMENT
} from '../../../../utils/constants/permissions';
import { AppContext } from 'context';
import { withRouter, useParams } from 'react-router';
import HealthDetails from 'components/DashboardComponents/Client/AddModal/HealthDetails';
import ImportantContacts from 'components/DashboardComponents/Client/ImportantContacts';
import {
  getAdmin,
  getSuperAdmin,
  getClient as localStorageClient,
} from '../../../../utils/localStorage/token';
import Files from './Files';
import {
  ModaClientTopContainer,
  ModalClientTabsContainer,
} from './ModalClientStyle';
import VisitTasks from '../VisitTasks';
import ClientTask from '../ClientTask';
import PlannedTasks from '../PlannedTasks';
import { CLIENT } from 'utils/constants/localStorage';
import MedicationPrescriptions from '../MedicationPrescriptions';
import MedicationChart from '../MedicationChart';
import MedicationStockAndAudit from '../MedicationStock&Audit';
import TaskModal from './TaskModal';
import ClientDiary from '../ClientDiary';
import CareForum from './CareForum';
import TriageModal from 'components/DashboardComponents/Team/AddModal/TriageModal';
import TriageClientModal from './TriageClientModal';
import ClientVisitModal from './ClientVisitModal';
import CarePlans from '../CarePlans/CarePlans';
import BodyMap from '../BodyMap';
import ServiceClientListAll from '../ClientServicesAll';
import Expense from '../Expense';
import './ClientModal.css'
import ClientHRTasks from '../ClientHRTasks';
import ClientCarePlans from '../ClientCarePlans';
import { CLIENT_ABOUT_ME_ROUTE, RISK_ASSESSMENT_ROUTE } from 'utils/constants/routes';
import AboutMeContainer from '../AboutMe';
import RiskAssessment from './RiskAssessment';
const nokSection = 'nok';
const addressSection = 'address';
const contactSection = 'contact';
const servAddressSection = 'service_address';
const demographicSection = 'demographic';
const healthDetailSection = 'detail';
const careContactSection = 'care_contact';

const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const tab4 = 4;
const tab5 = 5;
const tab6 = 6;
const tab7 = 7;
const tab7A = '7a';
const tab7B = '7b';
const tab7c = '7c';

const tab8 = 8;
const tab9 = 9;
const tab10 = 10;
const tab11 = 11;
const tab12 = 12;
const tab13 = 13;
const tab14 = 14;
const tab15 = 15;
const tab16 = 16;
const tab17 = 17;
const tab18 = 18;
const tab19 = 19;
const tab20 = 20;
const tab21 = 21;
const tab22 = 22;
const tab23 = 23;


const edit_client = 'Edit Client';

const Client = props => {
  const {
    fullBind,
    Id,
    setModalValue = () => { },
    successCallback = () => { },
    handleChangeChoices = () => { },
    handleChangeChoicesMap = () => { },
  } = props;
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [Orgname, setOrgname] = useState('');
  const [tabSelected, setTabSelected] = useState(tab1);
  const [tabSelectedMedication, setTabSelectedMedication] = useState(1);
  // const [choices, setChoices] = useState({client_status: []});
  const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);
  const [nokPhoto, setNokPhoto] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [companyBranches, setCompanyBranches] = useState([]);
  const [linkedTo, setLinkedTo] = useState([]);
  const [errorSplitPercentage, setErrorSplitPercentage] = useState('');
  const [employees, setEmployees] = useState([]);
  const [contractId, setContractId] = useState('');
  const [clientId, setClientId] = useState(Id);
  const [cliId, setCliId] = useState('');
  const [savedContract, setSavedContract] = useState('unsaved');
  const [loadingModal, setLoadingModal] = useState(true);
  const [loadedPreferred, setLoadedPreferred] = useState([]);
  const [preferredSelected, setPreferredSelected] = useState([]);
  const [loadedAvoided, setLoadedAvoided] = useState([]);
  const [avoidedSelected, setAvoidedSelected] = useState([]);
  const [promisesWithErrors, setPromisesWithErrors] = useState([]);
  const [finishSavePromises, setFinishSavePromises] = useState(false);
  const [rightPermission, setRightPermission] = useState({});
  const [makeUser, setMakeUser] = useState(false);
  const [updatePersonalDetails, setUpdatePermissionsPersonalDetails] =
    useState(false);
  const [createPersonalDetails, setCreatePermissionsPersonalDetails] =
    useState(false);
  const [deletePermissionPersonalDetails, setDeletePermissionsPersonalDetails] =
    useState(false);
  const [readPermissionPersonalDetails, setReadPermissionsPersonalDetails] =
    useState(false);
  const [subUpdate, setSubUpdate] = useState(false);
  const [subCreates, setSubCreates] = useState(false);
  const [subRead, setSubRead] = useState(false);
  const [prefDet, setPrefDet] = useState('');
  const [healthDet, setHealthDet] = useState('');
  //below fro  Client Preferences
  const [subSectionPrefrencesCreate, setSubSectionPrefrencesCreate] =
    useState(false);
  const [subSectionPrefrencesRead, setSubSectionPrefrencesRead] =
    useState(false);
  const [subSectionPrefrencesUpdate, setSubSectionPrefrencesUpdate] =
    useState(false);
  const [updatePermissionPrefrences, setUpdatePermissionsPrefrences] =
    useState(false);
  const [createPermissionPrefrences, setCreatePermissionPrefrences] =
    useState(false);
  const [deletePermissionPrefrences, setDeletePermissionsPrefrences] =
    useState(false);
  const [readPermissionPrefrences, setReadPermissionsPrefrences] =
    useState(false);
  // Client Details
  const [subSectionClientDetailsCreate, setSubSectionClientDetailsCreate] =
    useState(false);
  const [subSectionClientDetailsRead, setSubSectionClientDetailsRead] =
    useState(false);
  const [subSectionClientDetailsUpdate, setSubSectionClientDetailsUpdate] =
    useState(false);
  const [updatePermissionClientDetails, setUpdatePermissionClientDetails] =
    useState(false);
  const [createPermissionClientDetails, setCreatePermissionClientDetails] =
    useState(false);
  const [deletePermissionClientDetails, setDeletePermissionClientDetails] =
    useState(false);
  const [readPermissionClientDetails, setReadPermissionClientDetails] =
    useState(false);

  //Triage
  const [subSectionTriageCreate, setSubSectionTriageCreate] = useState(false);
  const [subSectionTriageRead, setSubSectionTriageRead] = useState(false);
  const [subSectionTriageUpdate, setSubSectionTriageUpdate] = useState(false);

  const [updatePermissionTriage, setUpdatePermissionTriage] = useState(false);
  const [createPermissionTriage, setCreatePermissionTriage] = useState(false);
  const [deletePermissionTriage, setDeletePermissionTriage] = useState(false);
  const [readPermissionTriage, setReadPermissionTriage] = useState(false);

  //service section -add modal
  const [updateServicePermissions, setUpdateServicePermissions] =
    useState(false);
  const [createServicePermissions, setCreateServicePermissions] =
    useState(false);
  const [deleteServicePermissions, setDeleteServicePermissions] =
    useState(false);
  const [readServicePermissions, setReadServicePermissions] = useState(false);
  //planned task
  const [updatePlannedTaskPermissions, setUpdatePlannedTaskPermissions] =
    useState(false);
  const [createPlannedTaskPermissions, setCreatePlannedTaskPermissions] =
    useState(false);
  const [deletePlannedTaskPermissions, setDeletePlannedTaskPermissions] =
    useState(false);
  const [readPlannedTaskPermissions, setReadPlannedTaskPermissions] = useState(false);
  //medication prescription

  const [updateMedicationPresPermissions, setUpdateMedicationPresPermissions] =
    useState(false);
  const [createMedicationPresPermissions, setCreateMedicationPresPermissions] =
    useState(false);
  const [deleteMedicationPresPermissions, setDeleteMedicationPresPermissions] =
    useState(false);
  const [readMedicationPresPermissions, setReadMedicationPresPermissions] = useState(false);
  // service contract inner section below
  const [subSectionServiceContractCreate, setSubSectionServiceContractCreate] =
    useState(false);
  const [subSectionServiceUpdate, setSubSectionServiceContractUpdate] =
    useState(false);
  const [subSectionServiceContractDelete, setSubSectionServiceContractDelete] =
    useState(false);
  const [subSectionServiceContractRead, setSubSectionServiceContractRead] =
    useState(false);
  // Client Details Health
  const [permissionDetailsCreateHealth, setPermissionDetailsCreateHealth] =
    useState(false);
  const [permissionDetailsReadHealth, setPermissionDetailsReadHealth] =
    useState(false);
  const [permissionDetailsUpdateHealth, setPermissionDetailsUpdateHealth] =
    useState(false);
  // read
  const [readPermissionImportantContact, setReadPermissionImportantContact] =
    useState(false);
  const [readPermissionNewVisit, setReadPermissionNewVisit] = useState(false);
  const [readPermissionCareNotes, setReadPermissionCareNotes] = useState(false);
  const [readPermissionCarePlan, setReadPermissionCarePlan] = useState(false);
  //Client care plans
  const [readPermissionClientCarePlan, setReadPermissionClientCarePlan] = useState(false);

  const [readPermissionMedicationNotes, setReadPermissionMedicationNotes] =
    useState(false);
  const [readPermissionMedicationTab, setReadPermissionMedicationTab] =
    useState(false);
  const [readPermissionNotes, setReadPermissionNotes] = useState(false);
  const [readPermissionClientFile, setReadPermissionClientFile] =
    useState(false);
  const [readPermissionClientTriage, setReadPermissionClientTriage] =
    useState(false);
  const [readPermissionClientVisit, setReadPermissionClientVisit] =
    useState(false);
  const [createPermissionClientVisit, setCreatePermissionClientVisit] =
    useState(false);
  const [updatePermissionClientVisit, setUpdatePermissionClientVisit] =
    useState(false);
  const [deletePermissionClientVisit, setDeletePermissionClientVisit] =
    useState(false);
  //care notes
  const [readPermissionVisitCareNotes, setReadPermissionVisitCareNotes] =
    useState(false);
  const [createPermissionVisitCareNotes, setCreatePermissionVisitCareNotes] =
    useState(false);
  const [updatePermissionVisitCareNotes, setUpdatePermissionVisitCareNotes] =
    useState(false);
  const [deletePermissionVisitCareNotes, setDeletePermissionVisitCareNotes] =
    useState(false);
  //care diary
  const [readPermissionCareDiary, setReadPermissionCareDiary] =
    useState(false);
  const [createPermissionCareDiary, setCreatePermissionCareDiary] =
    useState(false);
  const [updatePermissionCareDiary, setUpdatePermissionCareDiary] =
    useState(false);
  const [deletePermissionCareDiary, setDeletePermissionCareDiary] =
    useState(false);

  const [readPermissionTask, setReadPermissionTask] = useState(false);
  const [readPermissionCareForm, setReadPermissionCareForm] = useState(false);
  const [createPermissionCareForm, setCreatePermissionCareForm] = useState(false);
  const [updatePermissionCareForm, setUpdatePermissionCareForm] =  useState(false);
  const [deletePermissionCareForm, setDeletePermissionCareForm] =  useState(false);

  const [
    readPermissionClientContractedVisits,
    setReadPermissionClientContractedVisits,
  ] = useState(false);
  const [
    readPermissionClientCancelledVisits,
    setReadPermissionClientCancelledVisits,
  ] = useState(false);
  //create
  const [
    createPermissionImportantContact,
    setCreatePermissionImportantContact,
  ] = useState(false);
  const [createPermissionNewVisit, setCreatePermissionNewVisit] =
    useState(false);
  const [createPermissionCareNotes, setCreatePermissionCareNotes] =
    useState(false);
  const [createPermissionMedicationNotes, setCreatePermissionMedicationNotes] =
    useState(false);
  const [createPermissionNotes, setCreatePermissionNotes] = useState(false);
  const [createPermissionClientFile, setCreatePermissionClientFile] =
    useState(false);
  const [createPermissionTask, setCreatePermissionTask] = useState(false);
  const [
    createPermissionClientContractedVisits,
    setCreatePermissionClientContractedVisits,
  ] = useState(false);
  const [
    createPermissionClientCancelledVisits,
    setCreatePermissionClientCancelledVisits,
  ] = useState(false);
  //update
  const [
    updatePermissionImportantContact,
    setUpdatePermissionImportantContact,
  ] = useState(false);
  const [updatePermissionNewVisit, setUpdatePermissionNewVisit] =
    useState(false);
  const [updatePermissionCareNotes, setUpdatePermissionCareNotes] =
    useState(false);
  const [updatePermissionMedicationNotes, setUpdatePermissionMedicationNotes] =
    useState(false);
  const [updatePermissionNotes, setUpdatePermissionNotes] = useState(false);
  const [updatePermissionClientFile, setUpdatePermissionClientFile] =
    useState(false);
  const [updatePermissionTask, setUpdatePermissionTask] = useState(false);

  const [
    updatePermissionClientContractedVisits,
    setUpdatePermissionClientContractedVisits,
  ] = useState(false);
  const [
    updatePermissionClientCancelledVisits,
    setUpdatePermissionClientCancelledVisits,
  ] = useState(false);

  const [deletePermissionClientFile, setDeletePermissionClientFile] =
    useState(false);
  const [deletePermissionTask, setDeletePermissionTask] = useState(false);

  const [readPermissionBodyMap, setReadPermissionBodyMap] = useState(false)
  const [createPermissionBodyMap, setCreatePermissionBodyMap] = useState(false)
  const [updatePermissionBodyMap, setUpdatePermissionBodyMap] = useState(false)
  const [deletePermissionBodyMap, setDeletePermissionBodyMap] = useState(false)
  const [readPermissionClientExpense, setReadPermissionClientExpense] = useState(false)
  //Average Hours permision
  const [readPermissionAverageHours, setReadPermissionAverageHours] = useState(false)

  //QA task
  const [readPermissionHRTask, setReadPermissionHRTask] = useState(false)
  const [createPermissionHRTask, setCreatePermissionHRTask] = useState(false)
  const [updatePermissionHRTask, setUpdatePermissionHRTask] = useState(false)
  const [deletePermissionHRTask, setDeletePermissionHRTask] = useState(false)
  //For About me tab
  const [readPermissionAboutMe, setReadPermissionAboutMe] = useState(false)

  const [readPermissionRiskAssessment, setReadPermissionRiskAssessment] = useState(false)
  const [createPermissionRiskAssessment, setCreatePermissionRiskAssessment] = useState(false)
  const [updatePermissionRiskAssessment, setUpdatePermissionRiskAssessment] = useState(false)
  const [deletePermissionRiskAssessment, setDeletePermissionRiskAssessment] = useState(false)

  const [clientAddress, setClientAddress] = useState({});
  const [clientFullName, setClientFullName] = useState('');
  const [notesDet, setNotesDet] = useState('');
  const [displayPhoto, setDisplayPhoto] = useState();
  const [rowData, setRowData] = useState({});
  const userClient = localStorageClient();
  const userEmployee = getEmployee();

  const { id: selectedClientId } = useParams();
  const {
    contextChoices: choices,
    contextChoicesMap,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextRelativesClient,
    contextChoices,
  } = useContext(AppContext);
  const filterSuperAdmin = contextIsSuperAdmin;
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  useEffect(() => {
    if (finishSavePromises) {
      if (promisesWithErrors.length >= 1) {
        let error = '';

        promisesWithErrors.forEach(promise => {
          error += `*${promise} `;
        });

        onError(`Error while saving ${error}`);
      } else {
        onSuccess();
        setValues({ ...values });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishSavePromises, promisesWithErrors]);
  useEffect(() => {
    if (
      props.location.pathname === '/client/add/personalDetails' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/personalDetails`
    ) {
      setTabSelected(tab1);
    } else if (
      props.location.pathname === '/client/add/clientPreferences' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/clientPreferences`
    ) {
      setTabSelected(tab2);
    } else if (
      props.location.pathname === '/client/add/contacts' ||
      props.location.pathname === `/client/edit/${selectedClientId}/contacts`
    ) {
      setTabSelected(tab3);
    } else if (
      props.location.pathname === '/client/add/healthDetails' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/healthDetails`
    ) {
      setTabSelected(tab4);
    } else if (
      props.location.pathname === '/client/add/serviceContract' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/serviceContract`
    ) {
      setTabSelected(tab5);
    } else if (
      props.location.pathname === '/client/add/serviceContractAll' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/serviceContractAll`
    ) {
      setTabSelected(tab18);
    } else if (
      props.location.pathname === '/client/add/notes' ||
      props.location.pathname === `/client/edit/${selectedClientId}/notes`
    ) {
      setTabSelected(tab6);
    } else if (
      props.location.pathname === '/client/add/careNotes' ||
      props.location.pathname === `/client/edit/${selectedClientId}/careNotes`
    ) {
      setTabSelected(tab7);
    } else if (
      props.location.pathname === '/client/add/medicationNotes' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/medicationNotes`
    ) {
      setTabSelected(tab8);
    } else if (
      props.location.pathname === '/client/add/files' ||
      props.location.pathname === `/client/edit/${selectedClientId}/files`
    ) {
      setTabSelected(tab9);
    } else if (
      props.location.pathname === '/client/add/clientTask' ||
      props.location.pathname === `/client/edit/${selectedClientId}/clientTask`
    ) {
      setTabSelected(tab10);
    } else if (
      props.location.pathname === '/client/add/task' ||
      props.location.pathname === `/client/edit/${selectedClientId}/task`
    ) {
      setTabSelected(tab7c);
    } else if (
      props.location.pathname === '/client/add/clientdiary' ||
      props.location.pathname === `/client/edit/${selectedClientId}/clientdiary`
    ) {
      setTabSelected(tab12);
    } else if (
      props.location.pathname === '/client/add/clientvisits' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/clientvisits`
    ) {
      setTabSelected(tab15);
    } else if (
      props.location.pathname === '/client/add/careforum' ||
      props.location.pathname === `/client/edit/${selectedClientId}/careforum`
    ) {
      setTabSelected(tab14);
    } else if (
      props.location.pathname === '/client/add/medicationDoses' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/medicationDoses`
    ) {
      setTabSelected(tab11);
    } else if (
      props.location.pathname === '/client/add/visitCareNotes' ||
      props.location.pathname ===
      `/client/edit/${selectedClientId}/visitCareNotes`
    ) {
      setTabSelected(tab7A);
    } else if (
      props.location.pathname === '/client/add/triage' ||
      props.location.pathname === `/client/edit/${selectedClientId}/triage`
    ) {
      setTabSelected(tab7B);
    } else if (props.location.pathname === `/client/edit/${selectedClientId}/carePlans`) {
      setTabSelected(tab16)
    } else if (props.location.pathname === `/client/edit/${selectedClientId}/bodyMap`) {
      setTabSelected(tab17)
    } else if (props.location.pathname === `/client/edit/${selectedClientId}/expense`) {
      setTabSelected(tab19)
    } else if (props.location.pathname === `/client/edit/${selectedClientId}/hrclienttask`) {
      setTabSelected(tab20)
    }else if(props.location.pathname === `/client/edit/${selectedClientId}/care-plans`){
      setTabSelected(tab21)
    }else if(props.location.pathname === `/client/edit/${selectedClientId + CLIENT_ABOUT_ME_ROUTE}`){
      setTabSelected(tab22)
    }else if(props.location.pathname === `/client/add/risk-assessment` || props.location.pathname === `/client/edit/${selectedClientId}/risk-assessment`){
      setTabSelected(tab23)
    }
  });

  // useEffect(()=>{
  //   loadCompanyBranches();
  // },[])

  useEffect(() => {

    // loadLinkedTo();
    setEmployees(contextChoices?.employee_listing?.map(val => ({ ...val, name: val.full_name })) || []);
    setLinkedTo(contextChoices?.client_listing?.map(val => ({ ...val, name: val.full_name })) || []);
    // loadEmployees();
    // loadRoles();
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    setRightPermission({ ...rolePermissions });
    if (contextIsSuperAdmin) {
      setCreatePermissionsPersonalDetails(true);
      setDeletePermissionsPersonalDetails(true);
      setUpdatePermissionsPersonalDetails(true);
      setReadPermissionsPersonalDetails(true);

      setSubUpdate(true);
      setSubCreates(true);
      setSubRead(true);

      setSubSectionPrefrencesCreate(true);
      setSubSectionPrefrencesRead(true);

      setCreatePermissionPrefrences(true);
      setDeletePermissionsPrefrences(true);
      setUpdatePermissionsPrefrences(true);
      setReadPermissionsPrefrences(true);

      setSubSectionClientDetailsCreate(true);
      setSubSectionClientDetailsRead(true);
      setSubSectionClientDetailsUpdate(true);

      setReadPermissionClientDetails(true);

      setCreateServicePermissions(true);
      setDeleteServicePermissions(true);
      setCreatePlannedTaskPermissions(true);
      setDeletePlannedTaskPermissions(true);
      setDeletePermissionTriage(true);
      setCreatePermissionTriage(true);

      setUpdateServicePermissions(true);
      setUpdatePlannedTaskPermissions(true);
      setUpdatePermissionTriage(true);
      setReadServicePermissions(true);
      setReadPlannedTaskPermissions(true);
      setReadPermissionTriage(true);
      setSubSectionServiceContractCreate(true);
      setSubSectionServiceContractRead(true);
      setSubSectionServiceContractUpdate(true);
      setSubSectionServiceContractDelete(true);

      setPermissionDetailsCreateHealth(true);
      setPermissionDetailsReadHealth(true);
      setPermissionDetailsUpdateHealth(true);

      setReadPermissionImportantContact(true);
      setReadPermissionNewVisit(true);
      setReadPermissionCareNotes(true);
      setReadPermissionCarePlan(true)
      //Client care plans
      setReadPermissionClientCarePlan(true)
      setReadPermissionMedicationNotes(true);
      setReadPermissionMedicationTab(true)
      setReadPermissionNotes(true);
      setReadPermissionClientFile(true);
      setReadPermissionClientTriage(true);
      setReadPermissionClientVisit(true);
      setCreatePermissionClientVisit(true);
      setUpdatePermissionClientVisit(true);
      setDeletePermissionClientVisit(true);
      setReadPermissionTask(true);
      setReadPermissionCareForm(true)
      setCreatePermissionCareForm(true)
      setUpdatePermissionCareForm(true)
      setDeletePermissionCareForm(true)
      setReadPermissionClientContractedVisits(true);
      setReadPermissionClientCancelledVisits(true);

      setCreatePermissionImportantContact(true);
      setCreatePermissionCareNotes(true);
      setCreatePermissionMedicationNotes(true);
      setCreatePermissionNotes(true);
      setCreatePermissionClientFile(true);
      setCreatePermissionTask(true)
      setCreatePermissionClientContractedVisits(true);
      setCreatePermissionClientCancelledVisits(true);

      setUpdatePermissionImportantContact(true);
      setUpdatePermissionCareNotes(true);
      setUpdatePermissionMedicationNotes(true);
      setUpdatePermissionNotes(true);
      setUpdatePermissionClientFile(true);
      setUpdatePermissionTask(true);
      setUpdatePermissionClientContractedVisits(true);
      setUpdatePermissionClientCancelledVisits(true);

      setDeletePermissionClientFile(true);
      setDeletePermissionTask(true);

      //med pres
      setCreateMedicationPresPermissions(true);
      setDeleteMedicationPresPermissions(true);
      setUpdateMedicationPresPermissions(true);
      setReadMedicationPresPermissions(true);
      //visit care notes
      setCreatePermissionVisitCareNotes(true);
      setDeletePermissionVisitCareNotes(true);
      setUpdatePermissionVisitCareNotes(true);
      setReadPermissionVisitCareNotes(true);
      //care diary
      setCreatePermissionCareDiary(true);
      setDeletePermissionCareDiary(true);
      setUpdatePermissionCareDiary(true);
      setReadPermissionCareDiary(true);
      //Client Body Map
      setReadPermissionBodyMap(true)
      setCreatePermissionBodyMap(true)
      setUpdatePermissionBodyMap(true)
      setDeletePermissionBodyMap(true)
      // Client Expense
      setReadPermissionClientExpense(true)
      //Average Hours
      setReadPermissionAverageHours(true)

      //Hr Task
      setReadPermissionHRTask(true)
      setCreatePermissionHRTask(true)
      setUpdatePermissionHRTask(true)
      setDeletePermissionHRTask(true)
      //About me
      setReadPermissionAboutMe(true)

      setReadPermissionRiskAssessment(true)
      setCreatePermissionRiskAssessment(true)
      setUpdatePermissionRiskAssessment(true)
      setDeletePermissionRiskAssessment(true)

    } else if (contextIsAdmin) {
      setReadPermissionTask(true);

      setReadPermissionCareForm(true)
      setCreatePermissionCareForm(true)
      setUpdatePermissionCareForm(true)
      setDeletePermissionCareForm(true)

      setCreatePermissionsPersonalDetails(true);
      setDeletePermissionsPersonalDetails(true);
      setUpdatePermissionsPersonalDetails(true);
      setReadPermissionsPersonalDetails(
        contextSubscription?.[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]
      );

      setSubUpdate(true);
      setSubCreates(true);
      setSubRead(
        contextSubscription?.[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]
      );

      setSubSectionPrefrencesCreate(true);
      setSubSectionPrefrencesRead(
        contextSubscription?.[CLIENT_PREFRENCES]?.[WEB_PERMISSION]
      );

      setCreatePermissionPrefrences(true);
      setDeletePermissionsPrefrences(true);
      setUpdatePermissionsPrefrences(true);
      setReadPermissionsPrefrences(
        contextSubscription?.[CLIENT_PREFRENCES]?.[WEB_PERMISSION]
      );

      setSubSectionClientDetailsCreate(true);
      setSubSectionClientDetailsRead(
        contextSubscription?.[CLIENT_DETAILS]?.[WEB_PERMISSION]
      );
      setSubSectionClientDetailsUpdate(true);

      setReadPermissionClientDetails(true);

      setCreateServicePermissions(true);
      setDeleteServicePermissions(true);
      setDeletePermissionTriage(true);
      setUpdateServicePermissions(true);
      setUpdatePermissionTriage(true);
      setReadServicePermissions(
        contextSubscription?.[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]
      );
      setReadPermissionTriage(
        contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION]
      );
      setSubSectionServiceContractCreate(true);
      setSubSectionServiceContractRead(
        contextSubscription?.[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]
      );
      setSubSectionServiceContractUpdate(true);
      setSubSectionServiceContractDelete(true);

      setPermissionDetailsCreateHealth(true);
      setPermissionDetailsReadHealth(
        contextSubscription?.[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION]
      );
      setPermissionDetailsUpdateHealth(true);

      setReadPermissionImportantContact(
        contextSubscription?.[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION]
      );
      setReadPermissionNewVisit(
        contextSubscription?.[CLIENT_VISIT_CARE_NOTES]?.[WEB_PERMISSION]
      );
      setReadPermissionCareNotes(
        contextSubscription?.[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]
      );
      setReadPermissionCarePlan(
        contextSubscription?.[CLIENT_CARE_PLAN]?.[WEB_PERMISSION]
      )
      //Client care plans
      setReadPermissionClientCarePlan(true)
      setReadPermissionMedicationNotes(
        contextSubscription?.[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]
      );
      setReadPermissionMedicationTab(
        contextSubscription?.[CLIENT_MEDICATION_TAB]?.[WEB_PERMISSION]
      );
      setReadPermissionNotes(
        contextSubscription?.[CLIENT_NOTES]?.[WEB_PERMISSION]
      );
      setReadPermissionClientFile(
        contextSubscription?.[CLIENT_FILE]?.[WEB_PERMISSION]
      );
      setReadPermissionClientTriage(
        contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION]
      );
      setReadPermissionClientVisit(
        contextSubscription?.[CLIENT_VISIT]?.[WEB_PERMISSION]
      );

      setReadPermissionClientContractedVisits(
        contextSubscription?.[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]
      );
      setReadPermissionClientCancelledVisits(
        contextSubscription?.[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]
      );

      setCreatePermissionImportantContact(true);
      setCreatePermissionCareNotes(true);
      setCreatePermissionMedicationNotes(true);
      setCreatePermissionNotes(true);
      setCreatePermissionTask(true)
      setCreatePermissionClientFile(true);
      setCreatePermissionClientContractedVisits(true);
      setCreatePermissionClientCancelledVisits(true);

      setUpdatePermissionImportantContact(true);
      setUpdatePermissionCareNotes(true);
      setUpdatePermissionMedicationNotes(true);
      setUpdatePermissionNotes(true);
      setUpdatePermissionClientFile(true);
      setUpdatePermissionTask(true);
      setUpdatePermissionClientContractedVisits(true);
      setUpdatePermissionClientCancelledVisits(true);

      setDeletePermissionClientFile(true);
      setDeletePermissionTask(true);

      setCreatePlannedTaskPermissions(true);
      setDeletePlannedTaskPermissions(true);

      setUpdatePlannedTaskPermissions(true);
      setReadPlannedTaskPermissions(true);

      //medication prescription
      setCreateMedicationPresPermissions(true);
      setDeleteMedicationPresPermissions(true);
      setUpdateMedicationPresPermissions(true);
      setReadMedicationPresPermissions(true);
      //visit care notes
      setCreatePermissionVisitCareNotes(true);
      setDeletePermissionVisitCareNotes(true);
      setUpdatePermissionVisitCareNotes(true);
      setReadPermissionVisitCareNotes(true);
      //care diary
      setCreatePermissionCareDiary(true);
      setDeletePermissionCareDiary(true);
      setUpdatePermissionCareDiary(true);
      setReadPermissionCareDiary(true);
      //Client Body Map
      setReadPermissionBodyMap(true)
      setCreatePermissionBodyMap(true)
      setUpdatePermissionBodyMap(true)
      setDeletePermissionBodyMap(true)
      // Client Expense
      setReadPermissionClientExpense(true)

      //Average Hours
      setReadPermissionAverageHours(true)

      //Hr Task
      setReadPermissionHRTask(true)
      setCreatePermissionHRTask(true)
      setUpdatePermissionHRTask(true)
      setDeletePermissionHRTask(true)

      //About me
      setReadPermissionAboutMe(true)

      setReadPermissionRiskAssessment(true)
      setCreatePermissionRiskAssessment(true)
      setUpdatePermissionRiskAssessment(true)
      setDeletePermissionRiskAssessment(true)

    } else {
      // for personal detail infor sub section pages
      if (rolePermissions[CLIENT_PERSONAL_DETAILS] !== undefined) {
        setCreatePermissionsPersonalDetails(
          rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setDeletePermissionsPersonalDetails(
          rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissionsPersonalDetails(
          rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );

        setReadPermissionsPersonalDetails(
          contextSubscription?.[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );

        // for basic infor sub section pages
        setSubUpdate(
          rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setSubCreates(
          rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setSubRead(
          contextSubscription?.[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_PERSONAL_DETAILS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_PERSONAL_DETAILS]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }
      //for  Preferences
      if (rolePermissions[CLIENT_PREFRENCES] !== undefined) {
        setSubSectionPrefrencesCreate(
          rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setSubSectionPrefrencesRead(
          contextSubscription?.[CLIENT_PREFRENCES]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setSubSectionPrefrencesUpdate(
          rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setCreatePermissionPrefrences(
          rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setDeletePermissionsPrefrences(
          rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissionsPrefrences(
          rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );

        setReadPermissionsPrefrences(
          contextSubscription?.[CLIENT_PREFRENCES]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_PREFRENCES][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_PREFRENCES]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }
      // Triage
      if (rolePermissions[TRIAGE_PERMISSION] !== undefined) {
        setUpdatePermissionTriage(
          rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setCreatePermissionTriage(
          rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setDeletePermissionTriage(
          rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setReadPermissionTriage(
          contextSubscription?.[TRIAGE_PERMISSION]?.[WEB_PERMISSION] &&
          (rolePermissions[TRIAGE_PERMISSION][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[TRIAGE_PERMISSION]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }
      // for client deatils
      if (rolePermissions[CLIENT_DETAILS] !== undefined) {
        setSubSectionClientDetailsCreate(
          rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setSubSectionClientDetailsRead(
          contextSubscription?.[CLIENT_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setSubSectionClientDetailsUpdate(
          rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );
        setCreatePermissionClientDetails(
          rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );

        setUpdatePermissionClientDetails(
          rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );

        setReadPermissionClientDetails(
          contextSubscription?.[CLIENT_DETAILS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_DETAILS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_DETAILS]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              contextRelativesClient?.find(id => id === selectedClientId)))
        );
      }
      //service
      if (rolePermissions[CLIENT_SERVICE_CONTRACT] !== undefined) {
        setCreateServicePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );
        setDeleteServicePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );
        setUpdateServicePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId))
        );

        setReadServicePermissions(
          contextSubscription?.[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              contextRelativesClient?.find(id => id === selectedClientId)))
        );

        //Planned task

        if (rolePermissions[CLIENT_PLANNED_TASK] !== undefined) {
          setCreatePlannedTaskPermissions(
            rolePermissions[CLIENT_PLANNED_TASK][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_PLANNED_TASK]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ] &&
              contextRelativesClient?.find(id => id === selectedClientId))
          );
          setDeletePlannedTaskPermissions(
            rolePermissions[CLIENT_PLANNED_TASK][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_PLANNED_TASK]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ] &&
              contextRelativesClient?.find(id => id === selectedClientId))
          );
          setUpdatePlannedTaskPermissions(
            rolePermissions[CLIENT_PLANNED_TASK][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_PLANNED_TASK]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              contextRelativesClient?.find(id => id === selectedClientId))
          );

          setReadPlannedTaskPermissions(
            contextSubscription?.[CLIENT_PLANNED_TASK]?.[WEB_PERMISSION] &&
            (rolePermissions[CLIENT_PLANNED_TASK][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rolePermissions[CLIENT_PLANNED_TASK]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
              ] &&
                contextRelativesClient?.find(id => id === selectedClientId)))
          );
        }

        //medication prescription

        if (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION] !== undefined) {
          setCreateMedicationPresPermissions(
            rolePermissions[CLIENT_MEDICATION_PRESCRIPTION][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setDeleteMedicationPresPermissions(
            rolePermissions[CLIENT_MEDICATION_PRESCRIPTION][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setUpdateMedicationPresPermissions(
            rolePermissions[CLIENT_MEDICATION_PRESCRIPTION][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );

          setReadMedicationPresPermissions(
            contextSubscription?.[CLIENT_MEDICATION_PRESCRIPTION]?.[WEB_PERMISSION] &&
            (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rolePermissions[CLIENT_MEDICATION_PRESCRIPTION]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
              ] &&
                ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
          );
        }

        //visit care notes

        if (rolePermissions[VISIT_CARE_NOTES] !== undefined) {
          setCreatePermissionVisitCareNotes(
            rolePermissions[VISIT_CARE_NOTES][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            (rolePermissions[VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setDeletePermissionVisitCareNotes(
            rolePermissions[VISIT_CARE_NOTES][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            (rolePermissions[VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setUpdatePermissionVisitCareNotes(
            rolePermissions[VISIT_CARE_NOTES][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            (rolePermissions[VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );

          setReadPermissionVisitCareNotes(
            contextSubscription?.[VISIT_CARE_NOTES]?.[WEB_PERMISSION] &&
            (rolePermissions[VISIT_CARE_NOTES][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rolePermissions[VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
              ] &&
                ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
          );
        }

        //care diary

        if (rolePermissions[CLIENT_CARE_DIARY] !== undefined) {
          setCreatePermissionCareDiary(
            rolePermissions[CLIENT_CARE_DIARY][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_CARE_DIARY]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setDeletePermissionCareDiary(
            rolePermissions[CLIENT_CARE_DIARY][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_CARE_DIARY]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );
          setUpdatePermissionCareDiary(
            rolePermissions[CLIENT_CARE_DIARY][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ] ||
            (rolePermissions[CLIENT_CARE_DIARY]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
          );

          setReadPermissionCareDiary(
            contextSubscription?.[CLIENT_CARE_DIARY]?.[WEB_PERMISSION] &&
            (rolePermissions[CLIENT_CARE_DIARY][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rolePermissions[CLIENT_CARE_DIARY]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
              ] &&
                ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
          );
        }

        // inner section  below
        setSubSectionServiceContractCreate(
          rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );

        setSubSectionServiceContractRead(
          contextSubscription?.[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setSubSectionServiceContractUpdate(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_SERVICE_CONTRACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );

        setSubSectionServiceContractDelete(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
      }
      //client health
      if (rolePermissions[CLIENT_DETAILS_HEALTH] !== undefined) {
        setPermissionDetailsCreateHealth(
          rolePermissions[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPermissionDetailsReadHealth(
          contextSubscription?.[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_DETAILS_HEALTH][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setPermissionDetailsUpdateHealth(
          rolePermissions[CLIENT_DETAILS_HEALTH][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_DETAILS_HEALTH]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      //Client Body Map
      if (rolePermissions[CLIENT_BODY_PART] !== undefined) {
        setCreatePermissionBodyMap(
          rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))

        )

        setReadPermissionBodyMap(
          contextSubscription?.[CLIENT_BODY_PART]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))

        )

        setUpdatePermissionBodyMap(
          rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))

        )

        setDeletePermissionBodyMap(
          rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        )
      }

      //Hr Task
      if (rolePermissions[CLIENT_HR_TASK] !== undefined) {
        setCreatePermissionHRTask(
          rolePermissions[CLIENT_HR_TASK][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_HR_TASK]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))

        )

        setReadPermissionHRTask(
          contextSubscription?.[CLIENT_HR_TASK]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_HR_TASK][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rolePermissions[CLIENT_HR_TASK]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))

        )

        setUpdatePermissionHRTask(
          rolePermissions[CLIENT_HR_TASK][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[CLIENT_HR_TASK]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))

        )

        setDeletePermissionHRTask(
          rolePermissions[CLIENT_HR_TASK][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        )
      }

      //Client EMPLOYEE AVERAGE HOURS
      if (rolePermissions[EMPLOYEE_AVERAGE_HOURS] !== undefined) {

        setReadPermissionAverageHours(
          contextSubscription?.[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION] &&
          (rolePermissions[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[EMPLOYEE_AVERAGE_HOURS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              (userClient == selectedClientId) || (contextRelativesClient.find(id => id == selectedClientId))))
        )


        // setCreatePermissionBodyMap(
        //   rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
        //   (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] &&
        //     ((userClient == selectedClientId) || (contextRelativesClient.find(id => id == selectedClientId))))
        // )


        // setUpdatePermissionBodyMap(
        //   rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
        //   (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
        //     ((userClient == selectedClientId) || (contextRelativesClient.find(id => id == selectedClientId))))
        // )

        // setDeletePermissionBodyMap(
        //   rolePermissions[CLIENT_BODY_PART][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
        //   (rolePermissions[CLIENT_BODY_PART]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION] &&
        //     ((userClient == selectedClientId) || (contextRelativesClient.find(id => id == selectedClientId))))
        // )
      }
      setReadPermissionClientExpense(
        contextSubscription?.[CLIENT_EXPENSES]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_EXPENSES]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_EXPENSES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            contextRelativesClient?.find(id => id === selectedClientId)))

      )

      setReadPermissionImportantContact(
        contextSubscription?.[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionNewVisit(
        contextSubscription?.[CLIENT_VISIT_CARE_NOTES]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_VISIT_CARE_NOTES]?.[WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionCareNotes(
        contextSubscription?.[CLIENT_CARE_NOTES]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionCarePlan(
        contextSubscription?.[CLIENT_CARE_PLAN]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_CARE_PLAN]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_CARE_PLAN]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      //Client care plans
      setReadPermissionClientCarePlan(true)
      setReadPermissionMedicationNotes(
        contextSubscription?.[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionMedicationTab(
        contextSubscription?.[CLIENT_MEDICATION_TAB]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_MEDICATION_TAB]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_MEDICATION_TAB]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionNotes(
        contextSubscription?.[CLIENT_NOTES]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionClientFile(
        contextSubscription?.[CLIENT_FILE]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionClientTriage(
        contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ])
      );
      setReadPermissionClientVisit(
        contextSubscription?.[CLIENT_VISIT]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ])
      );
      setReadPermissionTask(
        contextSubscription?.[CLIENT_TASK]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ])
        // && (contextRelativesClient.find((id) => id === selectedClientId))
      );
      if (contextSubscription?.[CLIENT_FORMS] !== undefined) {
        setReadPermissionCareForm(
          contextSubscription?.[CLIENT_FORMS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ] ||
            rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
            ])
          // && (contextRelativesClient.find((id) => id === selectedClientId))
        );

        setCreatePermissionCareForm(contextSubscription?.[CLIENT_FORMS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ] ||
            rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
            ]))
        setUpdatePermissionCareForm(contextSubscription?.[CLIENT_FORMS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ] ||
            rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
            ]))
        setDeletePermissionCareForm(contextSubscription?.[CLIENT_FORMS]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ] ||
            rolePermissions[CLIENT_FORMS]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
            ]))
      }

      setReadPermissionClientContractedVisits(
        contextSubscription?.[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
      setReadPermissionClientCancelledVisits(
        contextSubscription?.[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ] ||
          (rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );

      setCreatePermissionImportantContact(
        rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION][
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionClientVisit(
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION][
        WEB_CREATE_PERMISSION
        ] ||
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setCreatePermissionCareNotes(
        rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionMedicationNotes(
        rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionNotes(
        rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionClientFile(
        rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionTask(
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
        && (contextRelativesClient.find((id) => id === selectedClientId)
        ))
      setCreatePermissionClientContractedVisits(
        rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setCreatePermissionClientCancelledVisits(
        rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );

      setUpdatePermissionImportantContact(
        rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION][
        WEB_UPDATE_PERMISSION
        ] ||
        rolePermissions[CLIENT_IMPORTANT_CONTACT]?.[WEB_PERMISSION]?.[
        SELF_WEB_UPDATE_PERMISSION
        ]
      );
      setUpdatePermissionClientVisit(
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION][
        WEB_UPDATE_PERMISSION
        ] ||
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_UPDATE_PERMISSION
        ]
      );
      setUpdatePermissionCareNotes(
        rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CARE_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setUpdatePermissionMedicationNotes(
        rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_MEDICATION_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setUpdatePermissionNotes(
        rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_NOTES]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setUpdatePermissionClientFile(
        rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setUpdatePermissionTask(
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
        SELF_WEB_UPDATE_PERMISSION
        ]
        && (contextRelativesClient.find((id) => id === selectedClientId)
        )
      );
      setDeletePermissionTask(
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION] ||
        rolePermissions[CLIENT_TASK]?.[WEB_PERMISSION]?.[
        SELF_WEB_DELETE_PERMISSION
        ]
        // && (contextRelativesClient.find((id) => id === selectedClientId)
        // )
      );
      setUpdatePermissionClientContractedVisits(
        rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CONTRACTED_VISITS]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setUpdatePermissionClientCancelledVisits(
        rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
        WEB_UPDATE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_CANELLED_VISITS]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );
      setDeletePermissionClientVisit(
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
        WEB_DELETE_PERMISSION
        ] ||
        rolePermissions[CLIENT_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_DELETE_PERMISSION
        ]
      );
      setDeletePermissionClientFile(
        rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
        WEB_DELETE_PERMISSION
        ] ||
        (rolePermissions[CLIENT_FILE]?.[WEB_PERMISSION]?.[
          SELF_WEB_DELETE_PERMISSION
        ] &&
          ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
      );

      //About me
      setReadPermissionAboutMe(
        contextSubscription?.[CLIENT_ABOUT_ME]?.[WEB_PERMISSION] &&
        (rolePermissions[CLIENT_ABOUT_ME]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[CLIENT_ABOUT_ME]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
      );
    }



      //CLIENT_RISK_ASSESSMENT
      if (rolePermissions[CLIENT_RISK_ASSESSMENT] !== undefined) {
        setCreatePermissionRiskAssessment(
          rolePermissions[CLIENT_RISK_ASSESSMENT][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
           (rolePermissions[CLIENT_RISK_ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )

        setReadPermissionRiskAssessment(
          contextSubscription?.[CLIENT_RISK_ASSESSMENT]?.[WEB_PERMISSION] &&
          (rolePermissions[CLIENT_RISK_ASSESSMENT][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[CLIENT_RISK_ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        )

        setUpdatePermissionRiskAssessment(
          rolePermissions[CLIENT_RISK_ASSESSMENT][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[CLIENT_RISK_ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )

        setDeletePermissionRiskAssessment(
          rolePermissions[CLIENT_RISK_ASSESSMENT][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        )
      }
      
    


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices, contextIsSuperAdmin, contextIsAdmin, contextSubscription]);
  useEffect(() => {
    // get info and display it
    // this validation is for not provoke a new search when saving a new contract
    setOrgname(getOrgname());
    if (Id) {
      loadClient();
      // medicalRead();
    } else {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);
  useEffect(() => {
    if (
      showError &&
      errors.length === 0 &&
      errorSplitPercentage === '' &&
      !finishSavePromises
    ) {
      setShowError(false);
      setCheckRequires(false);
    }

    if (textStatus !== SAVE && !finishSavePromises) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }
    if (values.make_user === undefined && values.role) {
      values.make_user = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, errorSplitPercentage]);

  useEffect(() => {
    if (values.id) {
      setAddFullBind({ ...addFullBind, title: edit_client });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  const loadClient = async () => {
    const response = await getClient(clientId);
    if (response.client_run_routes) {
      response.run_routes = response.client_run_routes.map(route => {
        if (
          choices &&
          choices.run_routes &&
          choices.run_routes.find(item => item.id === route.run_routes) &&
          choices.run_routes.find(item => item.id === route.run_routes).name
        ) {
          return {
            value: route.run_routes,
            label:
              choices &&
              choices.run_routes &&
              choices.run_routes.find(item => item.id === route.run_routes)
                .name,
          };
        }
      });
    }
    setValues({
      ...values,
      ...response,
    });

    const preferred_formatted = response.demographic
      ? response.demographic.prefered_employees.map(employee => {
        return { ...employee, value: employee.employee };
      })
      : [];
    setLoadedPreferred(preferred_formatted);
    setPreferredSelected(preferred_formatted);

    const avoided_formatted = response.demographic
      ? response.demographic.avoid_employees.map(employee => {
        return { ...employee, value: employee.employee };
      })
      : [];
    setLoadedAvoided(avoided_formatted);
    setAvoidedSelected(avoided_formatted);

    setLoadingModal(false);
  };

  const loadCompanyBranches = async () => {
    const response = await getCompanyBranches();
    setCompanyBranches(response.results || []);
  };


  // const loadRoles = async () => {
  //   const response = await getRoles(values);
  //   setRolesList(response.results || []);
  // };

  const handlePickFile = async (evt, section) => {
    const result = await pickFile(evt);

    switch (section) {
      case 'photo':
        setBasicInfoPhoto(result.file);
        break;
      case 'nok':
        setNokPhoto(result.file);
        break;
      default:
    }
  };

  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    if (item.role) {
      item.role_id = item.role;
    }

    result = _.merge(values, item);

    setValues({ ...result });
  };
  const dropDownHandleRunRoute = item => {
    values.run_routes = item.run_route;
    setValues({ ...values });
  };
  const preferredHandleSelect = preferred => {
    setPreferredSelected(preferred.demographic.prefered_employees);
  };

  const avoidHandleSelect = avoided => {
    setAvoidedSelected(avoided.demographic.avoid_employees);
  };

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };
  const handleSubmit = (
    evt,
    closeModal = false,
    saveTrigger = true,
    serviceContractValues = { service_clients: [] },
    toDeleteVisits = [],
    toDeleteSplitFunder = [],
    toDeleteVisitSkill = []
  ) => {
    evt.preventDefault();
    setPromisesWithErrors([]);

    setFinishSavePromises(false);

    if (loadingSave || loadingFinish) {
      return;
    }

    if ((!isValid && errors.length > 0) || errorSplitPercentage !== '') {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = {
      ...values,
    };

    // if (basicInfoPhoto) {
    //   body.photo = basicInfoPhoto;
    // } else if (body.photo) {
    //   delete body.photo;
    // }
    // if(body.run_routes){
    //   body.run_routes_id=body.run_routes.map(item=>item.value)
    //   delete body.run_routes
    // }
    // if (nokPhoto) {
    //   if (body.nok) {
    //     body.nok.photo = nokPhoto;
    //   } else {
    //     body.nok = {photo: ''};
    //     body.nok.photo = nokPhoto;
    //   }
    // } else if (body.nok && body.nok.photo) {
    //   delete body.nok.photo;
    // }

    //Service address
    if (body[servAddressSection]) {
      if (body[servAddressSection].same_as_client === undefined) {
        body[servAddressSection].same_as_client = false;
      }

      if (body[servAddressSection].smoking === undefined) {
        body[servAddressSection].smoking = false;
      }
    }

    //Health detail
    // if (body[healthDetailSection]) {
    //   if (body[healthDetailSection].is_disability === undefined) {
    //     body[healthDetailSection].is_disability = false;
    //   }
    //   if (body[healthDetailSection].disability_no === undefined) {
    //     body[healthDetailSection].disability_no = false;
    //   }
    //   if (body[healthDetailSection].do_not_resuscitate === undefined) {
    //     body[healthDetailSection].do_not_resuscitate = false;
    //   }
    // }

    if (saveTrigger) {
      setLoadingSave(true);
    }

    let value = [];
    setClient(body)
      .then(response => {
        if (response.status === 400) {
          if (response.message) {
            value = Object.values(response.message);
          }
          throw new Error('');
        }
        continueSavingValues(
          response,
          closeModal,
          serviceContractValues,
          toDeleteVisits,
          toDeleteSplitFunder,
          toDeleteVisitSkill
        );
      })
      .catch(err => {
        setPromisesWithErrors([...value]);
      });
  };

  const validateServiceContractValues = serviceContractValues => {
    if (serviceContractValues['service_funder']) {
      if (serviceContractValues['service_funder'].is_fixed === undefined) {
        serviceContractValues['service_funder'].is_fixed = false;
      }
    }

    if (serviceContractValues['service_funder']) {
      if (serviceContractValues['service_funder'].is_split === undefined) {
        serviceContractValues['service_funder'].is_split = false;
      }
    }

    if (serviceContractValues['service_address']) {
      if (
        serviceContractValues['service_address'].same_as_client === undefined
      ) {
        serviceContractValues['service_address'].same_as_client = false;
      }
      if (serviceContractValues['service_address'].smoking === undefined) {
        serviceContractValues['service_address'].smoking = false;
      }
    } else {
      serviceContractValues['service_address'] = {
        same_as_client: false,
        smoking: false,
      };
    }
  };

  const validateVisits = visits => {
    if (visits.time_critical === undefined) {
      visits.time_critical = false;
    }

    if (visits.include_holiday === undefined) {
      visits.include_holiday = false;
    }

    if (visits.is_suspended === undefined) {
      visits.is_suspended = false;
    }
    if (visits.is_overnight === undefined) {
      visits.is_overnight = false;
    }
    // BE expects repeat default value as 1
    if (!visits.repeat || visits.repeat <= 0) {
      visits.repeat = 1;
    }

    // BE expects repeat default value as 1
    if (visits.end_value === undefined || visits.end_value === null) {
      visits.end_value = '';
    }

    // format duration from seconds to HH:mm:ss
    if (visits.duration || visits.duration === 0) {
      const formatted = moment.utc(visits.duration * 1000).format('HH:mm:ss');
      visits.duration = formatted;
    }
  };

  const saveVisitsContract = async function (
    serviceId,
    clientId,
    serviceContractValues,
    indexVisit,
    visits
  ) {
    const VALUE = 'Save visit';
    let returnSave = false;
    if (
      !serviceContractValues['visits'][indexVisit] ||
      !serviceContractValues['visits'][indexVisit].skills_required
    ) {
      serviceContractValues['visits'][indexVisit] = {};
      serviceContractValues['visits'][indexVisit].skills_required = [];
    }

    if (
      !serviceContractValues['visits'][indexVisit].skills_required ||
      serviceContractValues['visits'][indexVisit].skills_required.length < 1
    ) {
      returnSave = true;
    }
    if (visits.full_day) {
      visits.preferred_start_time = '00:00:00';
      visits.preferred_end_time = '23:59:59';
      delete visits.full_day;
      // values.client_services[0].visits[indexVisit].preferred_start_time="00:00:00"
      // values.client_services[0].visits[indexVisit].preferred_end_time="23:59:59"
    }
    if (visits) {
      validateVisits(visits);
    } else {
      return;
    }

    return await setServiceContractVisit(clientId, serviceId, visits)
      .then(response => {
        if (!visits.id) {
          visits.id = response.id;
        }
        if (visits && visits.employee_required.length === 0) {
          saveSchedulerVisit(clientId, serviceId, visits.id);
        } else {
          let foundNewReq = visits.employee_required.find(
            element => element.id === ''
          );
          if (!foundNewReq) {
            saveSchedulerVisit(clientId, serviceId, visits.id);
          }
        }

        let total_empReq =
          visits && visits.employee_required && visits.employee_required.length;
        response.id &&
          visits &&
          visits.employee_required &&
          visits.employee_required.map((employee_req, empIndex) => {
            saveVisitEmployeeRequired(
              clientId,
              serviceId,
              visits.id,
              employee_req,
              total_empReq,
              empIndex
            )();
          });

        // if (!returnSave) {
        //   // Save skills of the visit contract
        //   Promise.all(
        //     serviceContractValues['visits'][indexVisit].skills_required.map(
        //       saveVisitsSkillsContract(serviceId, response.id, clientId)
        //     )
        //   );
        // }
      })
      .catch(() => {
        // setNewError(VALUE);
      });
  };

  const deleteVisitsContract = async function (
    visit,
    serviceId,
    clientId,
    toDeleteVisits,
    indexVisit
  ) {
    return await deleteServiceContractVisit(clientId, serviceId, visit).then(
      response => {
        if (
          response &&
          (response.status === 200 ||
            response.status === 201 ||
            response.status === 202 ||
            response.status === 204)
        ) {
          toDeleteVisits.splice(indexVisit);
        }
      }
    );
  };

  const saveSplitFunders = function (serviceId, clientId, splitIndex) {
    const VALUE = 'Save split funders';

    return async function (split_funders) {
      return await setServiceContractSplitFunder(
        clientId,
        serviceId,
        split_funders
      )
        .then(response => {
          split_funders.id = response.id;
        })
        .catch(() => {
          setNewError(VALUE);
        });
    };
  };

  const deleteSplitFunders = async function (
    split_funder,
    serviceId,
    clientId,
    toDeleteSplitFunder,
    indexSplit
  ) {
    return await deleteServiceContractSplitFunder(
      clientId,
      serviceId,
      split_funder
    ).then(response => {
      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 204)
      ) {
        toDeleteSplitFunder.splice(indexSplit);
      }
    });
  };

  const saveVisitsSkillsContract = function (serviceId, visitId, clientId) {
    const VALUE = 'Save visit skill';

    return async function (skill) {
      if (skill.id) {
        return;
      }
      skill.client_service_visit = visitId;
      return await setServiceContractVisitSkills(
        clientId,
        serviceId,
        visitId,
        skill
      )
        .then(response => {
          skill.id = response.id;
        })
        .catch(() => {
          setNewError(VALUE);
        });
    };
  };
  const saveVisitEmployeeRequired = function (
    clientId,
    serviceId,
    visitId,
    requirement,
    total_empReq,
    empIndex
  ) {
    const VALUE = 'Save Employee Requirement';
    return async function () {
      if (requirement.id) {
        return;
      }

      // skill.client_service_visit = visitId;
      return await setSeviceEmployeeRequired(
        clientId,
        serviceId,
        visitId,
        requirement
      )
        .then(response => {
          requirement.id = response.id;
          if (total_empReq === empIndex + 1 && !requirement.skills) {
            saveSchedulerVisit(clientId, serviceId, visitId);
          }
          requirement.skills &&
            saveVisitEmployeeSkills(
              serviceId,
              visitId,
              clientId,
              requirement.id,
              requirement.skills,
              total_empReq,
              empIndex
            )();
        })
        .catch(error => {
          setNewError(VALUE);
        });
    };
  };
  const saveVisitEmployeeSkills = function (
    serviceId,
    visitId,
    clientId,
    requireId,
    reqskills,
    total_empReq,
    empIndex
  ) {
    const VALUE = 'Save Employee Requirement Skills';

    return async function () {
      // if (skills.id) {
      //   return;
      // }

      // skill.client_service_visit = visitId;
      return await setSeviceEmployeeSkills(
        clientId,
        serviceId,
        visitId,
        requireId,
        reqskills
      )
        .then(response => {
          // skills.id = response.id;
          if (total_empReq === empIndex + 1) {
            saveSchedulerVisit(clientId, serviceId, visitId);
          }
        })
        .catch(() => {
          setNewError(VALUE);
        });
    };
  };
  const deleteVisitsSkillsContract = async function (
    skill,
    serviceId,
    clientId,
    toDeleteVisitSkill,
    indexSkill
  ) {
    return await deleteServiceContractVisitSkills(
      clientId,
      serviceId,
      skill
    ).then(response => {
      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 202 ||
          response.status === 204)
      ) {
        toDeleteVisitSkill.splice(indexSkill);
      }
    });
  };

  const saveServiceContract = async (
    clientId,
    serviceContractValues,
    toDeleteVisits,
    toDeleteSplitFunder,
    toDeleteVisitSkill,
    closeModal
  ) => {
    validateServiceContractValues(serviceContractValues);

    //Save the contract
    const VALUE = 'Contract';
    const response = await setClientServicesContract(
      clientId,
      serviceContractValues
    )
      .then(response => {
        if (!response || !response.id) {
          setNewError(VALUE);
        }
        return response;
      })
      .catch(() => {
        setNewError(VALUE);
      });

    //Verify that the contract was saved
    if (!response || !response.id) {
      return null;
    }

    let serviceId = response.id;

    if (serviceId) {
      if (!serviceContractValues['visits']) {
        serviceContractValues['visits'] = [];
      }

      if (
        !serviceContractValues['service_funder'] ||
        !serviceContractValues['service_funder'].split_funders
      ) {
        serviceContractValues['service_funder'].split_funders = [];
      }

      //Save the split funders of the contract
      Promise.all(
        serviceContractValues['service_funder'].split_funders.map(
          saveSplitFunders(serviceId, clientId)
        )
      );

      //Delete the split funders of the contract
      if (toDeleteSplitFunder.length > 0) {
        Promise.all(
          toDeleteSplitFunder.map(async (split_funder, indexSplit) => {
            deleteSplitFunders(
              split_funder,
              serviceId,
              clientId,
              toDeleteSplitFunder,
              indexSplit
            );
          })
        );
      }

      //Save the visits of the contract
      Promise.all(
        serviceContractValues['visits'].map(async (visits, indexVisit) => {
          saveVisitsContract(
            serviceId,
            clientId,
            serviceContractValues,
            indexVisit,
            visits
          );
        })
      );

      // Delete visits of the contract
      if (toDeleteVisits.length > 0) {
        Promise.all(
          toDeleteVisits.map(async (visit, indexVisit) => {
            deleteVisitsContract(
              visit,
              serviceId,
              clientId,
              toDeleteVisits,
              indexVisit
            );
          })
        );
      }

      // Delete skill of the visit contract
      if (toDeleteVisitSkill.length > 0) {
        Promise.all(
          toDeleteVisitSkill.map(async (skill, indexSkill) => {
            deleteVisitsSkillsContract(
              skill,
              serviceId,
              clientId,
              toDeleteVisitSkill,
              indexSkill
            );
          })
        );
      }
    }

    if (!closeModal && serviceId) {
      setSavedContract('saved');
    }

    //return service id value
    return serviceId;
  };

  const setNewError = VALUE => {
    const haveError = promisesWithErrors.find(nameError => {
      return nameError === VALUE;
    });

    if (!haveError) {
      const addNewError = promisesWithErrors;
      addNewError.push(VALUE);

      setPromisesWithErrors(addNewError);
    }
  };

  const savePreferred = function (clientId, demographicId) {
    const VALUE = 'Save preferred';

    return async function (employee) {
      return await savePreferredEmployees(
        clientId,
        demographicId,
        employee.value
      )
        .then(response => {
          const preferred = loadedPreferred;
          preferred.push({ ...response, value: response.employee });
          setLoadedPreferred(preferred);
        })
        .catch(() => {
          setNewError(VALUE);
        });
    };
  };

  const deletePreferred = function (clientId, demographicId) {
    return async function (employee) {
      return await deletePreferredEmployees(
        clientId,
        demographicId,
        employee.id
      );
    };
  };

  const saveAvoid = function (clientId, demographicId) {
    const VALUE = 'Do not send employees';

    return async function (employee) {
      return await saveAvoidEmployees(clientId, demographicId, employee.value)
        .then(response => {
          const avoided = loadedAvoided;
          avoided.push({ ...response, value: response.employee });
          setLoadedAvoided(avoided);
        })
        .catch(() => {
          setNewError(VALUE);
        });
    };
  };

  const deleteAvoid = function (clientId, demographicId) {
    return async function (employee) {
      return await deleteAvoidEmployees(clientId, demographicId, employee.id);
    };
  };

  const continueSavingValues = async (
    response,
    closeModal = false,
    serviceContractValues,
    toDeleteVisits,
    toDeleteSplitFunder,
    toDeleteVisitSkill
  ) => {
    if (response && (response.status === 200 || response.status === 201)) {
      let responseClientId = response.message.id;

      if (!response.message.id && clientId) {
        responseClientId = clientId;
      }

      setClientId(responseClientId);

      //Find values that are in loadedPreferred but not in preferredSelected
      let preferredToDelete = loadedPreferred.filter(function (obj) {
        return !preferredSelected.some(function (obj2) {
          return obj.value === obj2.value;
        });
      });

      //Find values that are in preferredSelected but not in loadedPreferred
      let preferredToAdd = preferredSelected.filter(function (obj) {
        return !loadedPreferred.some(function (obj2) {
          return obj.value === obj2.value;
        });
      });

      //Find values that are in loadedAvoided but not in avoidedSelected
      let avoidToDelete = loadedAvoided.filter(function (obj) {
        return !avoidedSelected.some(function (obj2) {
          return obj.value === obj2.value;
        });
      });

      //Find values that are in avoidedSelected but not in loadedAvoided
      let avoidToAdd = avoidedSelected.filter(function (obj) {
        return !loadedAvoided.some(function (obj2) {
          return obj.value === obj2.value;
        });
      });

      if (response.message.demographic) {
        await saveDemographics(
          preferredToAdd,
          preferredToDelete,
          avoidToAdd,
          avoidToDelete,
          responseClientId,
          response
        );
      }

      let contractId = '';
      if (serviceContractValues.service_clients.length >= 1) {
        await saveServiceContract(
          responseClientId,
          serviceContractValues.service_clients[0],
          toDeleteVisits,
          toDeleteSplitFunder,
          toDeleteVisitSkill,
          closeModal
        ).then(response => {
          //get the id of contract
          contractId = response;
        });
      }

      setFinishSavePromises(true);

      if (!closeModal) {
        setContractId(contractId || '');
        successCallback();
        setValues({
          ...values,
          id: responseClientId,
          is_active: response.message.is_active,
        });
      }

      if (closeModal) {
        setModalValue();
      }
    } else {
      onError();
    }
  };

  const saveDemographics = async (
    preferredToAdd,
    preferredToDelete,
    avoidToAdd,
    avoidToDelete,
    responseClientId,
    response
  ) => {
    try {
      //preferred employees
      await Promise.all(
        preferredToAdd.map(
          savePreferred(responseClientId, response.message.demographic.id)
        )
      );
      //preferred employees
      await Promise.all(
        preferredToDelete.map(
          deletePreferred(responseClientId, response.message.demographic.id)
        )
      );
      //avoid employees
      await Promise.all(
        avoidToAdd.map(
          saveAvoid(responseClientId, response.message.demographic.id)
        )
      );
      //avoid employees
      await Promise.all(
        avoidToDelete.map(
          deleteAvoid(responseClientId, response.message.demographic.id)
        )
      );
    } finally {
      //preferredToAdd =
      return;
    }
  };

  const onError = msg => {
    setLoadingSave(false);
    setLoadingFinish(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(msg || InvalidOrEmptyValues);
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setLoadingFinish(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage('');
  };

  const handelMakeUser = () => {
    setValues({ ...values, roles: null, role_id: null });
    setMakeUser(prevState => !prevState);
  };

  // // to make  medial section only readable  if only client is true
  // useEffect(()=>{
  //   if( (userClient !=='null' ) && (filterAdmin == false) && (filterSuperAdmin ==false) &&( userStaff == false))
  //   {
  //     setClientDetailsHealthPermissionToRead(true)

  //     }

  //     console.log( (userClient !=='null' ) && (filterAdmin == false) && (filterSuperAdmin ==false) &&( userStaff == false), 'setClientDetailsHealthPermissionToRead')

  // },[])

  console.log(readPermissionCareForm,"readPermissionCareForm")
  return (
    <>
      {/* // <ModalContainer max_width={'90%'}>
    //   <ModalDynamic
    //     {...addFullBind}
    //     max_width={'90%'}
    //     minWidthTitle="8.5rem"
    //     showRowInfo
    //     photoRow={
    //       basicInfoPhoto
    //         ? URL.createObjectURL(basicInfoPhoto)
    //         : values.photo
    //         ? values.photo
    //         : ''
    //     }
    //     name={values.first_name || values.full_name}
    //     lastName={values.last_name}
    //     rowInfoData={[
    //       {label: 'Name:', value: values.full_name},
    //       {
    //         label: 'DOB:',
    //         value: values.date_of_birth
    //           ? `${moment(values.date_of_birth).format(
    //               'DD-MM-YYYY'
    //             )} (${moment().diff(values.date_of_birth, 'years')})`
    //           : '',
    //       },
    //     ]}
    //     loading={loadingModal}> */}
      {/* <div className={'d-flex justify-content-center'}
    style={{
      position:'fixed',
      marginLeft:'-13px',
      backgroundColor:'#F0F0F7',
      width:'86%',
      marginTop:'-7px',
      paddingBottom:'10px',
      paddingTop:'10px',
      zIndex: 111
     }}
    > */}
      <ModaClientTopContainer
        id="client-fixed-top-header"
        className="position-fixed width-available"
        style={{
          paddingBottom: '10px',
          paddingTop: '10px',
          backgroundColor: '#F0F0F7',
        }}>
        <RowInfoModel
          name={rowData.first_name || rowData.full_name}
          lastName={rowData.last_name}
          photoRow={
            rowData.photo
              ? URL.createObjectURL(rowData.photo)
              : displayPhoto
                ? displayPhoto
                : ''
          }
          rows={[
            { label: 'Name:', value: rowData.full_name },
            {
              label: 'DOB:',
              value: rowData.date_of_birth
                ? `${moment(rowData.date_of_birth).format(
                  'DD-MM-YYYY'
                )} (${moment().diff(rowData.date_of_birth, 'years')})`
                : '',
            },
          ]}
        // dateOfBirth={dateOfBirth}
        />
        {/* </div> */}
      </ModaClientTopContainer>

      {/* <div className="d-flex justify-content-around text-center" 
          style={{
            position:'fixed',
            marginTop:'27px',
            width:'86%',
            marginLeft:'-36px',
            backgroundColor:'#F0F0F7',
            padding:'10px',
            zIndex: 111
          }}
          >  */}

      {/* All details ONLY TAB NAME  */}

      <ModalClientTabsContainer
        className="position-fixed client-modal-container"
        style={{ width: '-webkit-fill-available' }}
        id="client-fixed-top-tabs"
      >
        <div
          className="   "
          style={{ width: '100%', backgroundColor: '#F0F0F7' }}>
          <div
            className="d-flex text-center tab-div-client"
          // style={{flexWrap: 'wrap' }}
          >
            {/* {readPermissionPersonalDetails && */}
            <TabStyledNew
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              active={isCurrentTab(tab1)}
              onClick={() => {
                if (props.location.pathname.includes('/client/add')) {
                  props.history.push('/client/add/personalDetails');
                } else {
                  props.history.push(
                    `/client/edit/${selectedClientId}/personalDetails`
                  );
                }
              }}>
              Personal Details
            </TabStyledNew>
            {/* About me tab */}
            {readPermissionAboutMe &&
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab22)}
                onClick={() => {
                  if (selectedClientId) {
                    props.history.push(`/client/edit/${selectedClientId + CLIENT_ABOUT_ME_ROUTE}`);
                  }
                }}>
                About Me
              </TabStyledNew>}
            {readPermissionPrefrences && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab2)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/clientPreferences');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/clientPreferences`
                    );
                  }
                }}>
                Client Preferences
              </TabStyledNew>
            )}

            {readPermissionImportantContact && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab3)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/contacts');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/contacts`
                    );
                  }
                }}>
                Important Contacts
              </TabStyledNew>
            )}

            {permissionDetailsReadHealth && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab4)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/healthDetails');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/healthDetails`
                    );
                  }
                }}>
                Health Details
              </TabStyledNew>
            )}

            {/* {readServicePermissions && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab18)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/serviceContractAll');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/serviceContractAll`
                    );
                  }
                }}>
                Service Contract
              </TabStyledNew>
            )} */}

            {readServicePermissions &&
              // (contextIsSuperAdmin || (Orgname === 'SonaliAppTest') || (Orgname === 'Care Services')) &&
              (
                <TabStyledNew
                  style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                  active={isCurrentTab(tab5)}
                  onClick={() => {
                    if (
                      props.location.pathname.includes('/client/add') &&
                      cliId
                    ) {
                      props.history.push('/client/add/serviceContract');
                    } else if (
                      props.location.pathname.includes('/client/edit')
                    ) {
                      props.history.push(
                        `/client/edit/${selectedClientId}/serviceContract`
                      );
                    }
                  }}>
                  Service Contract
                </TabStyledNew>
              )}

            {readPermissionCareNotes && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab6)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/notes');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/notes`
                    );
                  }
                }}>
                Notes
              </TabStyledNew>
            )}

            {readPermissionCarePlan && <TabStyledNew
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              active={isCurrentTab(tab16)}
              onClick={() => {
                if (selectedClientId) {

                  props.history.push(`/client/edit/${selectedClientId}/carePlans`);
                }
              }}>
              Care Plans (Beta)
            </TabStyledNew>}

            {/* Client care plans */}
            {readPermissionClientCarePlan && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab21)}
                onClick={() => {
                  if (selectedClientId) {
                    props.history.push(`/client/edit/${selectedClientId}/care-plans`);
                  }
                }}>
                Client Care Plans (Beta)
              </TabStyledNew>)}
            {/* <div
            className="d-flex justify-content-around text-center "
            style={{ backgroundColor: '#F0F0F7', maxHeight: '60px' }}> */}
            {readPermissionBodyMap &&
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab17)}
                onClick={() => {
                  if (selectedClientId) {
                    props.history.push(`/client/edit/${selectedClientId}/bodyMap`);
                  }
                }}>
                Body Map (Beta)
              </TabStyledNew>}
            {readPermissionCareNotes && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab7)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/careNotes');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/careNotes`
                    );
                  }
                }}>
                Care Notes
              </TabStyledNew>
            )}

            {
              readPermissionVisitCareNotes && (
                <TabStyledNew
                  style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                  //style={{marginBottom:'0rem'}}
                  active={isCurrentTab(tab7A)}
                  onClick={() => {
                    if (
                      props.location.pathname.includes('/client/add') &&
                      cliId
                    ) {
                      props.history.push('/client/add/visitCareNotes');
                    } else if (
                      props.location.pathname.includes('/client/edit')
                    ) {
                      props.history.push(
                        `/client/edit/${selectedClientId}/visitCareNotes`
                      );
                    }
                  }}>
                  Visit Care Notes
                </TabStyledNew>
              )
            }

            {readPermissionMedicationTab && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab11)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/medicationDoses');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/medicationDoses`
                    );
                  }
                }}>
                Medication
              </TabStyledNew>
            )}
            {/* {readPermissionMedicationNotes && <TabStyledNew
             style={{ width:'fit-content',paddingRight:'1rem',paddingLeft:'1rem' }}
                active={isCurrentTab(tab8)}
                onClick={() => {
                  if (props.location.pathname.includes('/client/add') && cliId) {
                    props.history.push('/client/add/medicationNotes')
                  } else if (props.location.pathname.includes('/client/edit')) {
                    props.history.push(`/client/edit/${selectedClientId}/medicationNotes`)
                  }
                }}>
                Medication Notes
              </TabStyledNew>} */}

            {readPermissionClientFile && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab9)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/files');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/files`
                    );
                  }
                }}>
                Files
              </TabStyledNew>
            )}
            {readPermissionClientTriage && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab7B)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/triage');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/triage`
                    );
                  }
                }}>
                Triage
              </TabStyledNew>
            )}
            {readPlannedTaskPermissions && (<TabStyledNew
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              active={isCurrentTab(tab10)}
              onClick={() => {
                if (
                  props.location.pathname.includes('/client/add') &&
                  cliId
                ) {
                  props.history.push('/client/add/clientTask');
                } else if (
                  props.location.pathname.includes('/client/edit')
                ) {
                  props.history.push(
                    `/client/edit/${selectedClientId}/clientTask`
                  );
                }
              }}>
              Planned Task
            </TabStyledNew>)}

            {readPermissionCareDiary &&
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab12)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/clientdiary');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/clientdiary`
                    );
                  }
                }}>
                <span>Client Diary</span>
              </TabStyledNew>
            }

            {readPermissionClientVisit && (
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                //style={{marginBottom:'0rem'}}
                active={isCurrentTab(tab15)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/clientvisits');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/clientvisits`
                    );
                  }
                }}>
                <span>Client Visits</span>
              </TabStyledNew>
            )}
            {readPermissionTask && (
              // props.location.pathname.includes('/client/edit') &&
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                active={isCurrentTab(tab7c)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/task');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/task`
                    );
                  }
                }}>
                Task
              </TabStyledNew>
            )}

            {readPermissionCareForm &&
              // props.location.pathname.includes('/client/edit') &&
              <TabStyledNew
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                //style={{marginBottom:'0rem'}}
                active={isCurrentTab(tab14)}
                onClick={() => {
                  if (
                    props.location.pathname.includes('/client/add') &&
                    cliId
                  ) {
                    props.history.push('/client/add/careforum');
                  } else if (
                    props.location.pathname.includes('/client/edit')
                  ) {
                    props.history.push(
                      `/client/edit/${selectedClientId}/careforum`
                    );
                  }
                }}>
                <span>Care Forms</span>
              </TabStyledNew>
            }

            {/* {props.location.pathname.includes('/client/edit') && <TabStyledNew
             style={{ width:'fit-content',paddingRight:'1rem',paddingLeft:'1rem' }}
                  active={isCurrentTab(tab11)}
                  onClick={() => {
                      props.history.push(`/client/edit/${selectedClientId}/task`)
                  }}>
                  Task
                </TabStyledNew>} */}
            {/* </div> */}
            {/* <div
            className="d-flex justify-content-start text-center "
            style={{ backgroundColor: '#F0F0F7', maxHeight: '60px' }}> */}
            {readPermissionClientExpense &&
              <TabStyledNew
                style={{
                  width: 'fit-content',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                active={isCurrentTab(tab19)}
                onClick={() => {
                  if (selectedClientId) {

                    props.history.push(`/client/edit/${selectedClientId}/expense`);
                  }
                }}>
                Expense
              </TabStyledNew>}
            {readPermissionHRTask &&
              (<TabStyledNew
                style={{
                  width: 'fit-content',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                active={isCurrentTab(tab20)}
                onClick={() => {
                  if (selectedClientId) {
                    props.history.push(`/client/edit/${selectedClientId}/hrclienttask`);

                  }
                }}>
                QA Task
              </TabStyledNew>)}
              {readPermissionRiskAssessment &&
              (<TabStyledNew
                style={{
                  width: 'fit-content',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                active={isCurrentTab(tab23)}
                onClick={() => {
                  if (selectedClientId) {
                    props.history.push(`/client/edit/${selectedClientId+RISK_ASSESSMENT_ROUTE}`);

                  }
                }}>
                Risk Assessment
              </TabStyledNew>)}

              

          </div>

        </div>
      </ModalClientTabsContainer>
      {/* </div> */}
      <ComponentDynamic loading={loadingModal}>
        <div className="p-2 tab-comp-section">
          <ShowClientModalTab show={isCurrentTab(tab1)}>
            <PersonalDetails
              props={props}
              createPersonalDetails={createPersonalDetails}
              updatePersonalDetails={updatePersonalDetails}
              readPersonalDetails={readPermissionPersonalDetails}
              userStaff={userStaff}
              setRowData={setRowData}
              userClient={userClient}
              choices={choices}
              companyBranches={choices.branch}
              linkedTo={linkedTo}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              contextSubscription={contextSubscription}
              rightPermission={rightPermission}
              setCliId={setCliId}
              selectedClientId={selectedClientId}
              setClientAddress={setClientAddress}
              setClientFullName={setClientFullName}
              setDisplayPhoto={setDisplayPhoto}
              handleChangeChoices={handleChangeChoices}
              contextRelativesClient={contextRelativesClient}
              tabSelected={tabSelected}
            />
          </ShowClientModalTab>

          <ShowClientModalTab show={isCurrentTab(tab2)}>
            {tabSelected == tab2 && (
              <ClientPreference
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                createPermissionPrefrences={createPermissionPrefrences}
                updatePermissionPrefrences={updatePermissionPrefrences}
                readPermissionPrefrences={readPermissionPrefrences}
                userStaff={userStaff}
                userClient={userClient}
                choices={choices}
                employees={employees}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                setPrefDet={setPrefDet}
                prefDet={prefDet}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab3)}>
            {tabSelected == tab3 && (
              <ImportantContacts
                choices={choices}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                readPermissionImportantContact={readPermissionImportantContact}
                createPermissionImportantContact={
                  createPermissionImportantContact
                }
                updatePermissionImportantContact={
                  updatePermissionImportantContact
                }
                contextRelativesClient={contextRelativesClient}
              />
            )}
            {/* <div>
           
		  { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || ( ((subSectionClientDetailsCreate  || updatePermissionClientDetails | subSectionClientDetailsUpdate ||
createPermissionClientDetails ||readPermissionClientDetails || subSectionClientDetailsRead)  || (userStaff == true) ||(userClient ==='true')  &&  (userStaff ==='null' )  ) ) ||  ( (subSectionClientDetailsCreate  || updatePermissionClientDetails | subSectionClientDetailsUpdate ||
createPermissionClientDetails ||readPermissionClientDetails || subSectionClientDetailsRead)  || (userStaff == true) ||(userClient ==='true')  || (userStaff == true) ||(userClient ==='true')  ) ) && ( 
              <NextOfKin
                useInput={useInput}
                handlePickFile={handlePickFile}
                dropDownHandleSelect={dropDownHandleSelect}
                nameSection={nokSection}
                nokPhoto={nokPhoto}
                choices={choices}
                values={values}
              />
              )}
            </div> */}
            {/* <div className={'mt-3'}>
              <CareContact
                useInput={useInput}
                choices={choices}
                values={values}
                nameSection={careContactSection}
                dropDownHandleSelect={dropDownHandleSelect}
                roles={rolesList}
              />
            </div> */}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab4)}>
            {tabSelected == tab4 && (
              <HealthDetails
                handleChangeChoices={handleChangeChoices}
                choices={choices}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                employees={employees}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                setHealthDet={setHealthDet}
                healthDet={healthDet}
                permissionDetailsUpdateHealth={permissionDetailsUpdateHealth}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab5)}>
            {tabSelected == tab5 && (
              <ServiceClientList
                choices={choices}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                // handleSubmit={handleSubmit}
                allErrors={showError}
                setErrorSplitPercentage={setErrorSplitPercentage}
                errorSplitPercentage={errorSplitPercentage}
                loadingFinish={loadingFinish}
                loadingSave={loadingSave}
                textStatus={textStatus}
                buttonIcon={buttonIcon}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                contractId={contractId}
                savedContract={savedContract}
                setSavedContract={setSavedContract}
                addressValues={clientAddress || {}}
                clientFullName={clientFullName}
                setButtonIcon={setButtonIcon}
                setTextStatus={setTextStatus}
                clientErrors={errors}
                promisesWithErrors={promisesWithErrors}
                readServicePermissions={readServicePermissions}
                createServicePermissions={createServicePermissions}
                updateServicePermissions={updateServicePermissions}
                deleteServicePermissions={deleteServicePermissions}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab18)}>
            {tabSelected == tab18 && (
              <ServiceClientListAll
                choices={choices}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                // handleSubmit={handleSubmit}
                allErrors={showError}
                setErrorSplitPercentage={setErrorSplitPercentage}
                errorSplitPercentage={errorSplitPercentage}
                loadingFinish={loadingFinish}
                loadingSave={loadingSave}
                textStatus={textStatus}
                buttonIcon={buttonIcon}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                contractId={contractId}
                savedContract={savedContract}
                setSavedContract={setSavedContract}
                addressValues={clientAddress || {}}
                clientFullName={clientFullName}
                setButtonIcon={setButtonIcon}
                setTextStatus={setTextStatus}
                clientErrors={errors}
                promisesWithErrors={promisesWithErrors}
                readServicePermissions={readServicePermissions}
                createServicePermissions={createServicePermissions}
                updateServicePermissions={updateServicePermissions}
                deleteServicePermissions={deleteServicePermissions}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab6)}>
            {tabSelected == tab6 && (
              <Notes
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                notesDet={notesDet}
                setNotesDet={setNotesDet}
                readPermissionNotes={readPermissionNotes}
                createPermissionNotes={createPermissionNotes}
                updatePermissionNotes={updatePermissionNotes}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab7)}>
            {tabSelected == tab7 && (
              <CareNotes
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                notesDet={notesDet}
                setNotesDet={setNotesDet}
                readPermissionCareNotes={readPermissionCareNotes}
                createPermissionCareNotes={createPermissionCareNotes}
                updatePermissionCareNotes={updatePermissionCareNotes}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab15)}>
            {tabSelected == tab15 && (
              <ClientVisitModal
                props={props}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                notesDet={notesDet}
                setNotesDet={setNotesDet}
                readPermissionClientVisit={readPermissionClientVisit}
                createPermissionClientVisit={createPermissionClientVisit}
                updatePermissionClientVisit={updatePermissionClientVisit}
                deletePermissionClientVisit={deletePermissionClientVisit}
                contextRelativesClient={contextRelativesClient}
              />
            )}
          </ShowClientModalTab>

          <ShowClientModalTab show={isCurrentTab(tab7A)}>
            {tabSelected == tab7A && (
              <VisitCareNote
                handleChangeChoices={handleChangeChoices}
                selectedClientId={selectedClientId || cliId}
                choices={choices}
                props={props}
                rowData={rowData}
                displayPhoto={displayPhoto}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab7B)}>
            {tabSelected == tab7B && (
              <TriageClientModal
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                readPermissionClientFile={readPermissionClientFile}
                createPermissionClientFile={createPermissionClientFile}
                updatePermissionClientFile={updatePermissionClientFile}
                deletePermissionClientFile={deletePermissionClientFile}
                props={props}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab8)}>
            {tabSelected == tab8 && (
              <MedicationNotes
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                notesDet={notesDet}
                setNotesDet={setNotesDet}
                readPermissionMedicationNotes={readPermissionMedicationNotes}
                createPermissionMedicationNotes={
                  createPermissionMedicationNotes
                }
                updatePermissionMedicationNotes={
                  updatePermissionMedicationNotes
                }
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab9)}>
            {tabSelected == tab9 && (
              <Files
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                readPermissionClientFile={readPermissionClientFile}
                createPermissionClientFile={createPermissionClientFile}
                updatePermissionClientFile={updatePermissionClientFile}
                deletePermissionClientFile={deletePermissionClientFile}
                props={props}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab7c)}>
            {tabSelected == tab7c && (
              <TaskModal
                props={props}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                contextChoices={contextChoices && contextChoices}
                updatePermissionTask={updatePermissionTask}
                deletePermissionTask={deletePermissionTask}
                createPermissionTask={createPermissionTask}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab10)}>
            {tabSelected == tab10 && (
              <PlannedTasks
                choices={choices}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                // handleSubmit={handleSubmit}
                allErrors={showError}
                setErrorSplitPercentage={setErrorSplitPercentage}
                errorSplitPercentage={errorSplitPercentage}
                loadingFinish={loadingFinish}
                loadingSave={loadingSave}
                textStatus={textStatus}
                buttonIcon={buttonIcon}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                contractId={contractId}
                savedContract={savedContract}
                setSavedContract={setSavedContract}
                addressValues={clientAddress || {}}
                clientFullName={clientFullName}
                setButtonIcon={setButtonIcon}
                setTextStatus={setTextStatus}
                clientErrors={errors}
                promisesWithErrors={promisesWithErrors}
                readPlannedTaskPermissions={readPlannedTaskPermissions}
                createPlannedTaskPermissions={createPlannedTaskPermissions}
                updatePlannedTaskPermissions={updatePlannedTaskPermissions}
                deletePlannedTaskPermissions={deletePlannedTaskPermissions}
                handleChangeChoices={handleChangeChoices}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab12)}>
            {tabSelected == tab12 && (
              <ClientDiary selectedClientId={selectedClientId || cliId}
                clientFullName={clientFullName}
              // cliId={cliId} 
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab14)}>
            {tabSelected == tab14 && (
              <CareForum
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                contextChoices={contextChoices}
                name={rowData.full_name}
                contextChoicesMap={contextChoicesMap}
                handleChangeChoices={handleChangeChoices}
                readPermission={readPermissionCareForm}
                createPermission={createPermissionCareForm}
                updatePermission={updatePermissionCareForm}
                deletePermission={deletePermissionCareForm}
              />
            )}
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab11)}>
            {tabSelected == tab11 && (
              <>
                <div
                  id="client-medication-tabs"
                  className="d-flex justify-content-around text-center"
                  style={{ maxHeight: '60px' }}>
                  <TabStyled
                    active={tabSelectedMedication === 1}
                    onClick={() => {
                      setTabSelectedMedication(1);
                    }}>
                    Medication Prescriptions
                  </TabStyled>
                  <TabStyled
                    active={tabSelectedMedication === 2}
                    onClick={() => {
                      setTabSelectedMedication(2);
                    }}>
                    Medication Chart
                  </TabStyled>
                  <TabStyled
                    active={tabSelectedMedication === 3}
                    onClick={() => {
                      setTabSelectedMedication(3);
                    }}>
                    Medication Notes
                  </TabStyled>
                  <TabStyled
                    active={tabSelectedMedication === 4}
                    onClick={() => {
                      setTabSelectedMedication(4);
                    }}>
                    Medication Alert
                  </TabStyled>
                  <TabStyled
                    active={tabSelectedMedication === 5}
                    onClick={() => {
                      setTabSelectedMedication(5);
                    }}>
                    Medication Stock & Audit
                  </TabStyled>
                </div>
                {tabSelectedMedication === 1 && (
                  <MedicationPrescriptions
                    choices={choices}
                    contextChoicesMap={contextChoicesMap}
                    selectedClientId={selectedClientId || cliId}
                    // cliId={cliId}
                    // handleSubmit={handleSubmit}
                    allErrors={showError}
                    setErrorSplitPercentage={setErrorSplitPercentage}
                    errorSplitPercentage={errorSplitPercentage}
                    loadingFinish={loadingFinish}
                    loadingSave={loadingSave}
                    textStatus={textStatus}
                    buttonIcon={buttonIcon}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    contextSubscription={contextSubscription}
                    rightPermission={rightPermission}
                    userClient={userClient}
                    savedContract={savedContract}
                    setSavedContract={setSavedContract}
                    addressValues={clientAddress || {}}
                    clientFullName={clientFullName}
                    setButtonIcon={setButtonIcon}
                    setTextStatus={setTextStatus}
                    clientErrors={errors}
                    promisesWithErrors={promisesWithErrors}
                    readMedicationPresPermissions={readMedicationPresPermissions}
                    createMedicationPresPermissions={createMedicationPresPermissions}
                    updateMedicationPresPermissions={updateMedicationPresPermissions}
                    deleteMedicationPresPermissions={deleteMedicationPresPermissions}
                    handleChangeChoices={handleChangeChoices}
                    handleChangeChoicesMap={handleChangeChoicesMap}
                  />
                )}
              </>
            )}
            {tabSelectedMedication === 2 && (
              <MedicationChart
                selectedClientId={selectedClientId || cliId}
              // cliId={cliId}
              />
            )}
            {tabSelectedMedication === 3 && (
              <MedicationNotes
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                notesDet={notesDet}
                setNotesDet={setNotesDet}
                readPermissionMedicationNotes={readPermissionMedicationNotes}
                createPermissionMedicationNotes={
                  createPermissionMedicationNotes
                }
                updatePermissionMedicationNotes={
                  updatePermissionMedicationNotes
                }
              />
            )}
            {tabSelectedMedication === 5 && (
              <MedicationStockAndAudit
                choices={choices}
                contextChoicesMap={contextChoicesMap}
                selectedClientId={selectedClientId || cliId}
                // cliId={cliId}
                // handleSubmit={handleSubmit}
                allErrors={showError}
                setErrorSplitPercentage={setErrorSplitPercentage}
                errorSplitPercentage={errorSplitPercentage}
                loadingFinish={loadingFinish}
                loadingSave={loadingSave}
                textStatus={textStatus}
                buttonIcon={buttonIcon}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                contextSubscription={contextSubscription}
                rightPermission={rightPermission}
                userClient={userClient}
                savedContract={savedContract}
                setSavedContract={setSavedContract}
                addressValues={clientAddress || {}}
                clientFullName={clientFullName}
                setButtonIcon={setButtonIcon}
                setTextStatus={setTextStatus}
                clientErrors={errors}
                promisesWithErrors={promisesWithErrors}
                readServicePermissions={readServicePermissions}
                createServicePermissions={createServicePermissions}
                updateServicePermissions={updateServicePermissions}
                deleteServicePermissions={deleteServicePermissions}
              />
            )}
          </ShowClientModalTab>

          {/* Care Plans Tab */}
          <ShowClientModalTab show={isCurrentTab(tab16)}>
            {tabSelected == tab16 &&
              <CarePlans
                clientId={selectedClientId}
                clientFullName={clientFullName}
              />
            }
          </ShowClientModalTab>

          <ShowClientModalTab show={isCurrentTab(tab17)}>
            {tabSelected == tab17 &&
              <BodyMap
                clientId={selectedClientId}
                readPermission={readPermissionBodyMap}
                createPermission={createPermissionBodyMap}
                updatePermission={updatePermissionBodyMap}
                deletePermission={deletePermissionBodyMap}
                props={props}
              />
            }
          </ShowClientModalTab>

          <ShowClientModalTab show={isCurrentTab(tab19)}>
            {tabSelected == tab19 &&
              <Expense
                clientId={selectedClientId}
                props={props}
                readPermissionClientExpense={readPermissionClientExpense}
              />

            }
          </ShowClientModalTab>
          <ShowClientModalTab show={isCurrentTab(tab20)}>
            {readPermissionHRTask && tabSelected === tab20 && (
              <ClientHRTasks
                selectedClientId={selectedClientId}
                contextChoices={choices}
                props={props}
                readPermission={readPermissionHRTask}
                createPermission={createPermissionHRTask}
                updatePermission={updatePermissionHRTask}
                deletePermission={deletePermissionHRTask}
              />
            )}
          </ShowClientModalTab>
          
          <ShowClientModalTab show={isCurrentTab(tab21)}>
            {readPermissionClientCarePlan && tabSelected === tab21 && (
              <ClientCarePlans
                selectedClientId={selectedClientId}
                props={props}
                name={rowData.full_name}
                // readPermission={readPermissionHRTask}
                // createPermission={createPermissionHRTask}
                // updatePermission={updatePermissionHRTask}
                // deletePermission={deletePermissionHRTask}
              />
            )}
          </ShowClientModalTab>

          {/* About Me component */}
          <ShowClientModalTab show={isCurrentTab(tab22)}>
            {true && tabSelected === tab22 && (             
              <AboutMeContainer
                selectedClientId={selectedClientId}
                props={props}
                name={rowData.full_name}
                // readPermission={readPermissionHRTask}
                // createPermission={createPermissionHRTask}
                // updatePermission={updatePermissionHRTask}
                // deletePermission={deletePermissionHRTask}
              />
            )}
          </ShowClientModalTab>


            {/* Risk Assessment */}
            <ShowClientModalTab show={isCurrentTab(tab23)}>
            {tabSelected === tab23 && (             
              <RiskAssessment
                selectedClientId={selectedClientId}
                props={props}
                name={rowData.full_name}
                readPermission={readPermissionRiskAssessment}
                createPermission={createPermissionRiskAssessment}
                updatePermission={updatePermissionRiskAssessment}
                deletePermission={deletePermissionRiskAssessment}
              />
            )}
          </ShowClientModalTab>
          
        </div>
      </ComponentDynamic>
    </>
    //  </ModalDynamic>
    //  </ModalContainer>
  );
};

export default withRouter(Client);
