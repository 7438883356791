import React, { useState, useEffect, useRef, useContext } from 'react';
import CommonModelData from '../CommonModelData'
import { getRateCard, } from 'utils/api/SettingsApi';
import { useModal } from 'hooks/ModalHook';
import { AppContext } from "context"
import { useForm } from 'hooks/FormHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import { getClient, getEmployee, } from '../../../../../utils/localStorage/user';
import { useHistory, useLocation } from 'react-router-dom';


const Office = props => {
  const {
    readPermissionOffice,
    createPermissionOffice,
    updatePermissionOffice,
    deletePermissionOffice
  } = props;
  const { values, setValues, useInput, setCheckRequires } = useForm({});  //used useInput
  const { contextChoices, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const [rateCard, setRateCard] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [officeCard, setOfficeCard] = useState([]);
  const [rateCardID, setRateCardID] = useState();
  const [officeShiftType, setOfficeShiftType] = useState([]);
  const [officeTab, setOfficeTab] = useState(false);
  const [rateSource, setRateSource] = useState([]);
  const [loadingOfficeModal, setLoadingOfficeModal] = useState(true);
  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const history = useHistory();

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])


  useEffect(() => {
    if (contextChoices) {
      let rateCardType = contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype => ratetype.name == 'Office Rates').id;
      setRateCardID(rateCardType);
      loadRateCard(rateCardType);
      if (rateCardType) {
        setOfficeTab(true);
      }

      setRateSource(contextChoices.wages_rate_sources && contextChoices.wages_rate_sources.map(y => { return { id: y, name: y } }));

      setOfficeShiftType(contextChoices.regular_shift_type && contextChoices.regular_shift_type
        .filter(shift => !(shift.name == 'Shift')
          && !(shift.name == 'Availability')
          && !(shift.name == 'Bank-Flexible')
          && !(shift.name == 'Unavailability')
          && !(shift.name == 'On Call')
          && !(shift.name == 'Absence')))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices]);


  useEffect(() => {
    if (rateCardID) {
      loadRateCard(rateCardID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, values.ordering, values.limitperpage]);


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };

  const loadRateCard = (rateCardType) => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    if (rateCardType) {
      getRateCard({ ...values, ...pageToSearch }, values.limitperpage, rateCardType).then(response => {
        if (!mountedRef.current) return null;
        if (response) {
          setLoadingOfficeModal(false);
        }
        setOfficeCard(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
      });
    }
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/rate-card/office",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  return (
    <div>
      <CommonModelData
        addRateCardName={'NEW OFFICE RATE CARD'}
        listigData={officeCard}
        pages={pages}
        currentPage={currentPage}
        setQuerySort={setQuerySort}
        modalValue={modalValue}
        idSelected={idSelected}
        setModalValue={setModalValue}
        setRateCardModalValue={setModalValue}
        fullBind={fullBind}
        setTitle={setTitle}
        setIdSelected={setIdSelected}
        values={values}
        nameSection={'payValue'}
        useInput={useInput}
        loadRateCard={loadRateCard}
        setValues={setValues}
        setModalDeleteValue={setModalDeleteValue}
        setCheckRequires={setCheckRequires}
        bindModalDelete={bindModalDelete}
        addCard={'New Office Rate Card'}
        editCard={'Edit Office Rate Card'}
        copiedCard={'Copied Office Rate Card'}
        formObjName={'payValue'}
        firstLayerAddButton={'ADD Rate Details'}
        insideFirstLayerAddButton={'ADD Rate '}
        rateCardType={rateCardID}
        setIdToDelete={setIdToDelete}
        idToDelete={idToDelete}
        setDeleteValue={setDeleteValue}
        deleteValue={deleteValue}
        dropDownHandleSelectPagination={dropDownHandleSelectPagination}
        officeTab={officeTab}
        officeShiftType={officeShiftType}
        rateSource={rateSource}
        loadingModal={loadingOfficeModal}
        readPermission={readPermissionOffice}
        createPermission={createPermissionOffice}
        updatePermission={updatePermissionOffice}
        deletePermission={deletePermissionOffice}
      />

    </div>
  )
}

export default Office