import React, { useState, useEffect, useContext } from 'react';
import './style'
import { TitleCheckbox } from './style';
import { AppContext } from "context"

function RateCode({
   values,
   setValues,
   indexRate,
   nameSection,
   hitNewRate,
   data,
   copyToken,
   dummyID,
}) {



   const [defaultChecks, setDefaultChecks] = useState(false)
   const { contextChoices } = useContext(AppContext);
   const [rateCodeData, setRateCodeData] = useState([])

   useEffect(() => {
      if (!values[nameSection][indexRate].rateCode) {
         values[nameSection][indexRate].rateCode = contextChoices.rate_code && contextChoices.rate_code.map(x => {
            if (x.id) {
               return { id: x.id, name: x.name, checked: false };
            }
         })
         setRateCodeData(contextChoices.rate_code && contextChoices.rate_code.map(x => {
            if (x.id) {
               return { id: x.id, name: x.name, checked: false };
            }
         }) || [])
      }

   }, [])


   const clickCheckbox = (id, indexcheckbox, checkedValue) => {
      if (checkedValue == false) {
         values[nameSection][indexRate].rateCode.map(w => {
            return values[nameSection][indexRate].rateCode.find(y => y.id == id).checked = false;
         })
      }
      else {
         values[nameSection][indexRate].rateCode.map(w => {
            return values[nameSection][indexRate].rateCode.find(y => y.id == id).checked = true;
         })
      }

      setValues({ ...values })
   }


   useEffect(() => {
      // && !dummyID
      if ((values.id || copyToken)) {
         data && data.detail_rate_code && data.detail_rate_code.map(w => {
            if (hitNewRate == false) {
               return values[nameSection][indexRate].rateCode.find(y => y.id == w.rate_code).checked = true;
            }
            else if (hitNewRate == true) {
               return values[nameSection][indexRate].rateCode.find(y => y.id).checked = false;
            }
         })
         setValues({ ...values })
      }

   }, [])




   return (
      <div className='d-flex justify-content-around  align-center' style={{ backgroundColor: '#fafafc', lineHeight: '20px' }}  >
         <div className="d-flex flex-row">

            {values[nameSection][indexRate].rateCode && values[nameSection][indexRate].rateCode.map((list, index) => {
               return (
                  <div className='d-flex flex-column' key={index}>
                     <div><TitleCheckbox paddingLeft={'1.5px'} paddingRightt={'2.5px'}>
                        {list.name}
                     </TitleCheckbox></div>
                     <div className="pr-2 pr-2 text-center"> <input type="checkbox" name="checkboxes" id="checkboxes-1" checked={list.checked} onClick={(e) => clickCheckbox(list.id, index, e.target.checked)} /></div>
                  </div>
               )
            })}

         </div>
      </div>
   )
}

export default RateCode;
