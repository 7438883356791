import React, { useState, useEffect, useRef } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
  TabStyled,
} from 'shared/styles/constants/tagsStyles';
import {
  SETTINGS,
  BILLRATE,
  PAYRATE,
  TRAVELRATE,
  SETTINGS_RATECARD,
  PAGE_404,
  ONCALL,
  OFFICE,
  ABSCENCE,
  NLW,
  TIMESOURCE,
  SHIFT,
  WAIT,
} from 'utils/constants/routes';

import BillRate from 'components/DashboardComponents/Settings/SettingsRateCard/BillRate';
import PayRate from 'components/DashboardComponents/Settings/SettingsRateCard/PayRate';
import TravelRate from 'components/DashboardComponents/Settings/SettingsRateCard/TravelRate';
import OnCall from 'components/DashboardComponents/Settings/SettingsRateCard/OnCall';
import Office from 'components/DashboardComponents/Settings/SettingsRateCard/Office';
import Absence from 'components/DashboardComponents/Settings/SettingsRateCard/Absence';
import NLWRLW from 'components/DashboardComponents/Settings/SettingsRateCard/NLWRLW';
import TimeSource from 'components/DashboardComponents/Settings/SettingsRateCard/TimeSource';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'context';
import { useContext } from 'react';
import { setting_rate_card_choices } from 'utils/choiceConstant';
import Shift from './Shift';
import Wait from './Wait';
import { BoxTabStyled, HeaderContainer, MainTabsWraper, ModalTabsContainer, TabsWraper } from './styles';
import { ABSENCE, BILL_RATES, NLW_RLW, OFFICE_PERMISSION, ON_CALL, PAY_RATES, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, SETTINGS_RATE_CARD, SHIFT_RATES, TRAVEL_RATES, WAIT_RATES, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { truncate } from 'lodash';

const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const tab4 = 4;
const tab5 = 5;
const tab6 = 6;
const tab7 = 7;
const tab8 = 8;
const tab9 = 9;
const tab10 = 10;

const TAB_RATECARD_TIMESOURCE__URL = SETTINGS + SETTINGS_RATECARD + TIMESOURCE;
const TAB_RATECARD_BILLRATE_URL = SETTINGS + SETTINGS_RATECARD + BILLRATE;
const TAB_RATECARD_PAYRATE_URL = SETTINGS + SETTINGS_RATECARD + PAYRATE;
const TAB_RATECARD_TRAVELRATE_URL = SETTINGS + SETTINGS_RATECARD + TRAVELRATE;
const TAB_RATECARD_ONCALL_URL = SETTINGS + SETTINGS_RATECARD + ONCALL;
const TAB_RATECARD_OFFICE_URL = SETTINGS + SETTINGS_RATECARD + OFFICE;
const TAB_RATECARD_ABSCENCE_URL = SETTINGS + SETTINGS_RATECARD + ABSCENCE;
const TAB_RATECARD_NLW_URL = SETTINGS + SETTINGS_RATECARD + NLW;
const TAB_RATECARD_SHIFT_URL = SETTINGS + SETTINGS_RATECARD + SHIFT;
const TAB_RATECARD_WAIT_URL = SETTINGS + SETTINGS_RATECARD + WAIT;


const SettingsRateCard = props => {
  const [currentTab, setCurrentTab] = useState(tab1)
  const [currentTabPay, setCurrentTabPay] = useState(1)

  const [readPermissionBillRates, setReadPermissionBillRates] = useState(false)
  const [createPermissionBillRates, setCreatePermissionBillRates] = useState(false)
  const [updatePermissionBillRates, setUpdatePermissionBillRates] = useState(false)
  const [deletePermissionBillRates, setDeletePermissionBillRates] = useState(false)

  const [readPermissionPayRates, setReadPermissionPayRates] = useState(false)
  const [createPermissionPayRates, setCreatePermissionPayRates] = useState(false)
  const [updatePermissionPayRates, setUpdatePermissionPayRates] = useState(false)
  const [deletePermissionPayRates, setDeletePermissionPayRates] = useState(false)

  const [readPermissionOnCall, setReadPermissionOnCall] = useState(false)
  const [createPermissionOnCall, setCreatePermissionOnCall] = useState(false)
  const [updatePermissionOnCall, setUpdatePermissionOnCall] = useState(false)
  const [deletePermissionOnCall, setDeletePermissionOnCall] = useState(false)

  const [readPermissionOffice, setReadPermissionOffice] = useState(false)
  const [createPermissionOffice, setCreatePermissionOffice] = useState(false)
  const [updatePermissionOffice, setUpdatePermissionOffice] = useState(false)
  const [deletePermissionOffice, setDeletePermissionOffice] = useState(false)

  const [readPermissionShift, setReadPermissionShift] = useState(false)
  const [createPermissionShift, setCreatePermissionShift] = useState(false)
  const [updatePermissionShift, setUpdatePermissionShift] = useState(false)
  const [deletePermissionShift, setDeletePermissionShift] = useState(false)

  const [readPermissionWait, setReadPermissionWait] = useState(false)
  const [createPermissionWait, setCreatePermissionWait] = useState(false)
  const [updatePermissionWait, setUpdatePermissionWait] = useState(false)
  const [deletePermissionWait, setDeletePermissionWait] = useState(false)

  const [readPermissionTravel, setReadPermissionTravel] = useState(false)
  const [createPermissionTravel, setCreatePermissionTravel] = useState(false)
  const [updatePermissionTravel, setUpdatePermissionTravel] = useState(false)
  const [deletePermissionTravel, setDeletePermissionTravel] = useState(false)

  const [readPermissionAbsence, setReadPermissionAbsence] = useState(false)
  const [createPermissionAbsence, setCreatePermissionAbsence] = useState(false)
  const [updatePermissionAbsence, setUpdatePermissionAbsence] = useState(false)
  const [deletePermissionAbsence, setDeletePermissionAbsence] = useState(false)

  const [readPermissionALW_RLW, setReadPermissionALW_RLW] = useState(false)
  const [createPermissionALW_RLW, setCreatePermissionALW_RLW] = useState(false)
  const [updatePermissionALW_RLW, setUpdatePermissionALW_RLW] = useState(false)
  const [deletePermissionALW_RLW, setDeletePermissionALW_RLW] = useState(false)

  const [readPermissionRateCard, setReadPermissionRateCard] = useState(false)
  const [createPermissionRateCard, setCreatePermissionRateCard] = useState(false)
  const [updatePermissionRateCard, setUpdatePermissionRateCard] = useState(false)
  const [deletePermissionRateCard, setDeletePermissionRateCard] = useState(false)

  let history = useHistory();

  const reviewLocation = tab => {
    return currentTab === tab
  };

  const {
    contextChoices,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    handleGetChoices
  } = useContext(AppContext);
  let subscription = contextSubscription
  useEffect(() => {
    handleGetChoices(setting_rate_card_choices)
  }, [])

  useEffect(() => {
    if (props.location.pathname === TAB_RATECARD_TIMESOURCE__URL) {
      setCurrentTab(tab1);
    }
    if (props.location.pathname === TAB_RATECARD_BILLRATE_URL) {
      setCurrentTab(tab2);
    }
    if (props.location.pathname === TAB_RATECARD_PAYRATE_URL) {
      setCurrentTab(tab3);
      setCurrentTabPay(1);
    }
    if (props.location.pathname === TAB_RATECARD_TRAVELRATE_URL) {
      setCurrentTab(tab4);
    }
    if (props.location.pathname === TAB_RATECARD_NLW_URL) {
      setCurrentTab(tab10);
    }
    if (props.location.pathname === TAB_RATECARD_ABSCENCE_URL) {
      setCurrentTab(tab7);
    }

  }, [props.location.pathname])


  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }

    if (contextIsAdmin == true || contextIsSuperAdmin == true) {
      setReadPermissionBillRates(true)
      setCreatePermissionBillRates(true)
      setUpdatePermissionBillRates(true)
      setDeletePermissionBillRates(true)

      setReadPermissionPayRates(true)
      setCreatePermissionPayRates(true)
      setUpdatePermissionPayRates(true)
      setDeletePermissionPayRates(true)

      setReadPermissionOnCall(true)
      setCreatePermissionOnCall(true)
      setUpdatePermissionOnCall(true)
      setDeletePermissionOnCall(true)

      setReadPermissionOffice(true)
      setCreatePermissionOffice(true)
      setUpdatePermissionOffice(true)
      setDeletePermissionOffice(true)

      setReadPermissionShift(true)
      setCreatePermissionShift(true)
      setUpdatePermissionShift(true)
      setDeletePermissionShift(true)

      setReadPermissionWait(true)
      setCreatePermissionWait(true)
      setUpdatePermissionWait(true)
      setDeletePermissionWait(true)

      setReadPermissionTravel(true)
      setCreatePermissionTravel(true)
      setUpdatePermissionTravel(true)
      setDeletePermissionTravel(true)

      setReadPermissionAbsence(true)
      setCreatePermissionAbsence(true)
      setUpdatePermissionAbsence(true)
      setDeletePermissionAbsence(true)

      setReadPermissionALW_RLW(true)
      setCreatePermissionALW_RLW(true)
      setUpdatePermissionALW_RLW(true)
      setDeletePermissionALW_RLW(true)

      setReadPermissionRateCard(true)
      setCreatePermissionRateCard(true)
      setUpdatePermissionRateCard(true)
      setDeletePermissionRateCard(true)
    } else {

      const getPermission = (key, type) => {
        if (rolePermissions[key]) {
          debugger
          switch (type) {
            case WEB_READ_PERMISSION:
              return (subscription?.[key]?.[WEB_PERMISSION] &&
                (rolePermissions[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
              );
            case WEB_CREATE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
              );
            case WEB_UPDATE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
              )
            case WEB_DELETE_PERMISSION:
              return (
                (rolePermissions[key][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
                  rolePermissions[key][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
              )
            default:
              break;
          }

        }
      };
      // BILL_RATES
      setReadPermissionBillRates(getPermission(BILL_RATES, WEB_READ_PERMISSION) || false)
      setCreatePermissionBillRates(getPermission(BILL_RATES, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionBillRates(getPermission(BILL_RATES, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionBillRates(getPermission(BILL_RATES, WEB_DELETE_PERMISSION) || false)

      // PAY_RATES
      setReadPermissionPayRates(getPermission(PAY_RATES, WEB_READ_PERMISSION) || false)
      setCreatePermissionPayRates(getPermission(PAY_RATES, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionPayRates(getPermission(PAY_RATES, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionPayRates(getPermission(PAY_RATES, WEB_DELETE_PERMISSION) || false)

      // ON_CALL
      setReadPermissionOnCall(getPermission(ON_CALL, WEB_READ_PERMISSION) || false)
      setCreatePermissionOnCall(getPermission(ON_CALL, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionOnCall(getPermission(ON_CALL, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionOnCall(getPermission(ON_CALL, WEB_DELETE_PERMISSION) || false)

      // OFFICE_PERMISSION
      setReadPermissionOffice(getPermission(OFFICE_PERMISSION, WEB_READ_PERMISSION) || false)
      setCreatePermissionOffice(getPermission(OFFICE_PERMISSION, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionOffice(getPermission(OFFICE_PERMISSION, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionOffice(getPermission(OFFICE_PERMISSION, WEB_DELETE_PERMISSION) || false)

      // SHIFT_RATES
      setReadPermissionShift(getPermission(SHIFT_RATES, WEB_READ_PERMISSION) || false)
      setCreatePermissionShift(getPermission(SHIFT_RATES, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionShift(getPermission(SHIFT_RATES, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionShift(getPermission(SHIFT_RATES, WEB_DELETE_PERMISSION) || false)


      // WAIT_RATES
      setReadPermissionWait(getPermission(WAIT_RATES, WEB_READ_PERMISSION) || false)
      setCreatePermissionWait(getPermission(WAIT_RATES, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionWait(getPermission(WAIT_RATES, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionWait(getPermission(WAIT_RATES, WEB_DELETE_PERMISSION) || false)

      // TRAVEL_RATES
      setReadPermissionTravel(getPermission(TRAVEL_RATES, WEB_READ_PERMISSION) || false)
      setCreatePermissionTravel(getPermission(TRAVEL_RATES, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionTravel(getPermission(TRAVEL_RATES, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionTravel(getPermission(TRAVEL_RATES, WEB_DELETE_PERMISSION) || false)

      // ABSENCE
      setReadPermissionAbsence(getPermission(ABSENCE, WEB_READ_PERMISSION) || false)
      setCreatePermissionAbsence(getPermission(ABSENCE, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionAbsence(getPermission(ABSENCE, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionAbsence(getPermission(ABSENCE, WEB_DELETE_PERMISSION) || false)

      // NLW_RLW
      setReadPermissionALW_RLW(getPermission(NLW_RLW, WEB_READ_PERMISSION) || false)
      setCreatePermissionALW_RLW(getPermission(NLW_RLW, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionALW_RLW(getPermission(NLW_RLW, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionALW_RLW(getPermission(NLW_RLW, WEB_DELETE_PERMISSION) || false)

      //SETTINGS_RATE_CARD
      setReadPermissionRateCard(getPermission(SETTINGS_RATE_CARD, WEB_READ_PERMISSION) || false)
      setCreatePermissionRateCard(getPermission(SETTINGS_RATE_CARD, WEB_CREATE_PERMISSION) || false)
      setUpdatePermissionRateCard(getPermission(SETTINGS_RATE_CARD, WEB_UPDATE_PERMISSION) || false)
      setDeletePermissionRateCard(getPermission(SETTINGS_RATE_CARD, WEB_DELETE_PERMISSION) || false)

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSubscription, contextPermissions, contextIsSuperAdmin, contextIsAdmin, subscription]);
  console.log(contextSubscription, "contextSubscription")
  return (
    <div>
      <HeaderContainer>
        <Title> Rate Card </Title>
      </HeaderContainer>
      <ModalTabsContainer
        className='d-flex justify-content-center'
        style={{
          width: '84%',
          zIndex: 2,
          marginTop: '21px',
          position: 'fixed',
          paddingLeft: 0
        }}>
        <MainTabsWraper>

          <TabsWraper className="d-flex text-center" style={{ marginTop: "-129px", backgroundColor: `#F0F0F7`, }}>

            <BoxTabStyled
              active={reviewLocation(tab1)}
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              onClick={() =>
                props.history.push(TAB_RATECARD_TIMESOURCE__URL)
              }
            >
              Time Source
            </BoxTabStyled>

            {(
              <BoxTabStyled
                active={reviewLocation(tab2)}
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                onClick={() =>
                  props.history.push(TAB_RATECARD_BILLRATE_URL)
                }
              >
                Bill Rate
              </BoxTabStyled>)}

            <BoxTabStyled
              active={reviewLocation(tab3)}
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              onClick={() =>
                props.history.push(TAB_RATECARD_PAYRATE_URL)
              }
            >
              Pay Rate
            </BoxTabStyled>

            <BoxTabStyled
              active={reviewLocation(tab4)}
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              onClick={() =>
                props.history.push(TAB_RATECARD_TRAVELRATE_URL)
              }
            >
              Travel Rate
            </BoxTabStyled>
            <BoxTabStyled
              active={reviewLocation(tab7)}
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              onClick={() =>
                props.history.push(TAB_RATECARD_ABSCENCE_URL)
              }
            >
              Absence
            </BoxTabStyled>

            <BoxTabStyled
              active={reviewLocation(tab10)}
              style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
              onClick={() =>
                props.history.push(TAB_RATECARD_NLW_URL)
              }
            >
              NLW/RLW
            </BoxTabStyled>

          </TabsWraper>
        </MainTabsWraper>
      </ModalTabsContainer>

      <div style={{ marginTop: "133px" }}>
        <Switch>
          <Route exact path={TAB_RATECARD_TIMESOURCE__URL}>
            <TimeSource
              location={props.location}
              readPermissionRateCard={readPermissionRateCard}
              createPermissionRateCard={createPermissionRateCard}
              updatePermissionRateCard={updatePermissionRateCard}
              deletePermissionRateCard={deletePermissionRateCard}
            />
          </Route>
          <Route exact path={TAB_RATECARD_BILLRATE_URL}>
            <BillRate
              location={props.location}
              readPermissionBillRates={readPermissionBillRates}
              createPermissionBillRates={createPermissionBillRates}
              updatePermissionBillRates={updatePermissionBillRates}
              deletePermissionBillRates={deletePermissionBillRates}
            />
          </Route>
          <Route exact path={TAB_RATECARD_TRAVELRATE_URL}>
            <TravelRate
              location={props.location}
              readPermissionTravel={readPermissionTravel}
              createPermissionTravel={createPermissionTravel}
              updatePermissionTravel={updatePermissionTravel}
              deletePermissionTravel={deletePermissionTravel}
            />
          </Route>
          <Route exact path={TAB_RATECARD_ABSCENCE_URL}>
            <Absence
              location={props.location}
              readPermissionAbsence={readPermissionAbsence}
              createPermissionAbsence={createPermissionAbsence}
              updatePermissionAbsence={updatePermissionAbsence}
              deletePermissionAbsence={deletePermissionAbsence}
            />
          </Route>
          <Route exact path={TAB_RATECARD_NLW_URL}>
            <NLWRLW
              location={props.location}
              readPermissionALW_RLW={readPermissionALW_RLW}
              createPermissionALW_RLW={createPermissionALW_RLW}
              updatePermissionALW_RLW={updatePermissionALW_RLW}
              deletePermissionALW_RLW={deletePermissionALW_RLW}
            />
          </Route>

          <Route exact path={TAB_RATECARD_PAYRATE_URL}>
            <>
              <div
                className="d-flex justify-content-around text-center"
                style={{ maxHeight: '60px' }}>
                <TabStyled
                  active={currentTabPay === 1}
                  onClick={() =>
                    setCurrentTabPay(1)
                  }>
                  Pay Rate
                </TabStyled>

                <TabStyled
                  active={currentTabPay === 2}
                  onClick={() =>
                    setCurrentTabPay(2)
                  }
                >
                  On Call
                </TabStyled>

                <TabStyled
                  active={currentTabPay === 3}
                  onClick={() =>
                    setCurrentTabPay(3)
                  }
                >
                  Office
                </TabStyled>

                <TabStyled
                  active={currentTabPay === 4}
                  onClick={() =>
                    setCurrentTabPay(4)
                  }
                >
                  Shift
                </TabStyled>

                <TabStyled
                  active={currentTabPay == 5}
                  onClick={() =>
                    setCurrentTabPay(5)
                  }
                >
                  Wait
                </TabStyled>
              </div>
              <>
                {currentTabPay == 1 && (
                  <PayRate
                    location={props.location}
                    readPermissionPayRates={readPermissionPayRates}
                    createPermissionPayRates={createPermissionPayRates}
                    updatePermissionPayRates={updatePermissionPayRates}
                    deletePermissionPayRates={deletePermissionPayRates}
                  />)}
                {currentTabPay == 2 &&
                  <OnCall
                    location={props.location}
                    readPermissionOnCall={readPermissionOnCall}
                    createPermissionOnCall={createPermissionOnCall}
                    updatePermissionOnCall={updatePermissionOnCall}
                    deletePermissionOnCall={deletePermissionOnCall}
                  />}
                {currentTabPay == 3 &&
                  <Office
                    location={props.location}
                    readPermissionOffice={readPermissionOffice}
                    createPermissionOffice={createPermissionOffice}
                    updatePermissionOffice={updatePermissionOffice}
                    deletePermissionOffice={deletePermissionOffice}
                  />
                }
                {currentTabPay == 4 &&
                  <Shift
                    location={props.location}
                    readPermissionShift={readPermissionShift}
                    createPermissionShift={createPermissionShift}
                    updatePermissionShift={updatePermissionShift}
                    deletePermissionShift={deletePermissionShift}
                  />}
                {currentTabPay == 5 &&
                  <Wait
                    location={props.location}
                    readPermissionWait={readPermissionWait}
                    createPermissionWait={createPermissionWait}
                    updatePermissionWait={updatePermissionWait}
                    deletePermissionWait={deletePermissionWait}
                  />
                }
              </>
            </>
          </Route>

          <Redirect
            to={{
              pathname: PAGE_404,
              state: { referrer: '/' },
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default SettingsRateCard;
