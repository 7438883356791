import styled from 'styled-components';
import {AnimationStyle} from 'shared/styles/buttons';
import {
  PURPLE,
  PURPLE_GRAY_500,
  WHITE,
  GRAY_400,
  ERR_COLOR,
  ERR_COLOR_800,
  GRAY_800,
} from 'shared/styles/constants/colors';

export const CancelButton = styled.button`
  ${AnimationStyle}
  border: 1px solid ${GRAY_400};
  border-radius: 30px;
  background-color: ${WHITE};
  &:hover{
    background-color: ${PURPLE_GRAY_500};
    border: 1px solid ${PURPLE_GRAY_500};
  }
  color: ${PURPLE_GRAY_500};
  padding: 0.5rem 2rem;
  font-weight: 500;
`;

const handleColorType = type => {
  switch (type) {
    case 'information':
      return GRAY_400;
    case 'warning':
      return ERR_COLOR;
    default:
      return PURPLE_GRAY_500;
  }
};

const handleColorTypeHover = type => {
  switch (type) {
    case 'information':
      return PURPLE;
    case 'warning':
      return ERR_COLOR_800;
    default:
      return PURPLE;
  }
};

export const PrimaryButtonModal = styled.button`
  ${AnimationStyle}
  color: ${WHITE};
  border: 0;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  font-weight: 500;
  background-color: ${({typeButton}) => handleColorType(typeButton)};

  &:hover {
    background-color: ${({typeButton}) => handleColorTypeHover(typeButton)};
  }
  &:focus {
    outline: none;
  }

  svg {
    font-size: 1.2rem;
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
`;

export const IconStyle = styled.div`
  display: flex;
  justify-content: center;
  svg {
    color: ${GRAY_400};
    width: 5rem;
    height: 5rem;
  }
`;

export const Title = styled.div`
  color: ${GRAY_800};
  font-size: 1.3rem;
`;
