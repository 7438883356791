import React, {useState, useEffect, useRef} from 'react';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  SETTINGS,
  ALGO_SCORE_CLIENT,
  ALGO_SCORE_EMPLOYEE,
  ALGO_SCORE_TRAVEL,
  ALGO_SCORE_WTR,
  ALGO_SCORE_FINANCIAL,
  SETTINGS_ALGOSCORE,
  ALGO_SCORE_NEWFEILD,
  PAGE_404,
} from 'utils/constants/routes';
import Client from 'components/DashboardComponents/Settings/SettingsAlgoScore/Client';
import Employee from 'components/DashboardComponents/Settings/SettingsAlgoScore/Employee';
import Wtr from 'components/DashboardComponents/Settings/SettingsAlgoScore/WTR';
import Travel from 'components/DashboardComponents/Settings/SettingsAlgoScore/Travel';
import Financial from 'components/DashboardComponents/Settings/SettingsAlgoScore/Financial';
import Newfeild from './NewField/NewFeilds';
import {getAlgoScore} from 'utils/api/SettingsApi';
import {useHistory} from 'react-router-dom';
import {setCompany} from 'utils/api/SettingsApi';
const TAB_SCORE_NEWFEILD_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_NEWFEILD;
const TAB_SCORE_CLIENT_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_CLIENT;
const TAB_SCORE_EMPLOYEE_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_EMPLOYEE;
const TAB_SCORE_WTR_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_WTR;
const TAB_SCORE_TRAVEL_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_TRAVEL;
const TAB_SCORE_FINANCIAL_URL = SETTINGS + SETTINGS_ALGOSCORE + ALGO_SCORE_FINANCIAL;
const  ALGO_SCORE_NOT_FOUND = 'Algo score  details have not been created';


const SettingsAlgoScore = props => {
  let history = useHistory();
console.log();
  
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

 

  return (
    <div>
      <Title>  Algo Score</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center mb-4">
          <div className={'d-flex firstRow'}>
          <LinkTabStyled active={reviewLocation(TAB_SCORE_NEWFEILD_URL)}>
              <Link to={TAB_SCORE_NEWFEILD_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_NEWFEILD_URL)}>
                Algo-Time
                </LinkTab>
              </Link>
            </LinkTabStyled>
            <LinkTabStyled active={reviewLocation(TAB_SCORE_CLIENT_URL)}>
              <Link to={TAB_SCORE_CLIENT_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_CLIENT_URL)}>
                Client
                </LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_SCORE_EMPLOYEE_URL)}>
              <Link to={TAB_SCORE_EMPLOYEE_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_EMPLOYEE_URL)}>
                Employee
                </LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_SCORE_WTR_URL)}>
              <Link to={TAB_SCORE_WTR_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_WTR_URL)}>
                WTR
                </LinkTab>
              </Link>
            </LinkTabStyled>
          </div>

          <div className={'d-flex firstRow'}>
          {/* <div className={'d-flex secondRow'}> */}
            <LinkTabStyled active={reviewLocation(TAB_SCORE_TRAVEL_URL)}>
              <Link to={TAB_SCORE_TRAVEL_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_TRAVEL_URL)}>
                Travel
                </LinkTab>
              </Link>
            </LinkTabStyled>

            <LinkTabStyled active={reviewLocation(TAB_SCORE_FINANCIAL_URL)}>
              <Link to={TAB_SCORE_FINANCIAL_URL} className={'styledLink'}>
                <LinkTab active={reviewLocation(TAB_SCORE_FINANCIAL_URL)}>
                Financial
                </LinkTab>
              </Link>
            </LinkTabStyled>

          </div>
        </ContainerTabStyled>

        <Switch>
        <Route exact path={TAB_SCORE_NEWFEILD_URL}>
            <Newfeild   />
          </Route>
          <Route exact path={TAB_SCORE_CLIENT_URL}>
            <Client   ALGO_SCORE_NOT_FOUND={ALGO_SCORE_NOT_FOUND}  />
          </Route>
          <Route exact path={TAB_SCORE_EMPLOYEE_URL}>
            <Employee
              ALGO_SCORE_NOT_FOUND={ALGO_SCORE_NOT_FOUND}   
            />
          </Route>
          <Route exact path={TAB_SCORE_WTR_URL}>
            <Wtr
              ALGO_SCORE_NOT_FOUND={ALGO_SCORE_NOT_FOUND}         
            />
          </Route>
          <Route exact path={TAB_SCORE_TRAVEL_URL}>
            <Travel
              ALGO_SCORE_NOT_FOUND={ALGO_SCORE_NOT_FOUND}
              location={props.location}
            />
          </Route>
          <Route exact path={TAB_SCORE_FINANCIAL_URL}>
            <Financial
             ALGO_SCORE_NOT_FOUND={ALGO_SCORE_NOT_FOUND}
             location={props.location}
            />
          </Route> 
          <Redirect
            to={{
              pathname: PAGE_404,
              state: {referrer: '/'},
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default SettingsAlgoScore;
