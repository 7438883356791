import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import React, { useRef } from 'react'
import { useState } from 'react'
import BasicInformation from './SubComponents/BasicInformation'
import { useContext } from 'react'
import { AppContext } from 'context'
import { pickFile } from 'utils/files'
import _ from 'lodash'
import { ContainerResponsive, ErrorMessage, FlexEqual, FlexGrid3Container, FlexGrid3Item, Title } from 'shared/styles/constants/tagsStyles'
import PersonalAddress from './SubComponents/PersonalAddress'
import MapComponent from 'components/SharedComponents/Map/MapComponent'
import Geocode from 'react-geocode'
import PersonalContact from './SubComponents/PersonalContact'
import { Fragment } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons'
import { useForm } from 'hooks/FormHook'
import { useEffect } from 'react'
import { location_details_choices } from 'utils/choiceConstant'
import { NoPermissionContainer, TitleSection } from './styles'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { getLocationById, setLocation } from 'utils/api/LocationApi'
import { Toast } from 'primereact/toast'
import { LOCATION_ACCESS_BRANCH, LOCATION_ADDRESS, LOCATION_BASIC_INFORMATION, LOCATION_CONTACT, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions'
function LocationDetails({
  selectedId,
  readPermissionLocationDetails,
  createPermissionLocationDetails,
  updatePermissionLocationDetails,
  deletePermissionLocationDetails,
  setRowData,
  setClientAddress,
  setDisplayPhoto
}) {
  const history = useHistory()
  const toast = useRef()
  // const locationId = history?.location?.state['id'] || null
  const [modalLoading, setModalLoading] = useState(false)
  const [userPhoto, setUserPhoto] = useState('')
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);

  const [readPermissionBasicInfo, setReadPermissionBasicInfo] = useState(false)
  const [createPermissionBasicInfo, setCreatePermissionBasicInfo] = useState(false)
  const [updatePermissionBasicInfo, setUpdatePermissionBasicInfo] = useState(false)

  const [readPermissionAddress, setReadPermissionAddress] = useState(false)
  const [createPermissionAddress, setCreatePermissionAddress] = useState(false)
  const [updatePermissionAddress, setUpdatePermissionAddress] = useState(false)

  const [createPermissionContact, setCreatePermissionContact] = useState(false)
  const [readPermissionContact, setReadPermissionContact] = useState(false)
  const [updatePermissionContact, setUpdatePermissionContact] = useState(false)
  const [deletePermissionContact, setDeletePermissionContact] = useState(false)

  const [createPermissionAccesBranch, setCreatePermissionAccesBranch] = useState(false)
  const [readPermissionAccesBranch, setReadPermissionAccesBranch] = useState(false)
  const [updatePermissionAccesBranch, setUpdatePermissionAccesBranch] = useState(false)
  const [deletePermissionAccesBranch, setDeletePermissionAccesBranch] = useState(false)

  const {
    contextChoices: choices,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextChoices,
    handleGetChoices,
  } = useContext(AppContext);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});

  useEffect(() => {
    handleGetChoices(location_details_choices)
  }, [])


  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
      );
    }
    if (contextIsSuperAdmin || contextIsAdmin) {

      setReadPermissionBasicInfo(true)
      setCreatePermissionBasicInfo(true)
      setUpdatePermissionBasicInfo(true)


      setReadPermissionAddress(true)
      setCreatePermissionAddress(true)
      setUpdatePermissionAddress(true)

      setCreatePermissionContact(true)
      setReadPermissionContact(true)
      setUpdatePermissionContact(true)
      setDeletePermissionContact(true)

      setCreatePermissionAccesBranch(true)
      setReadPermissionAccesBranch(true)
      setUpdatePermissionAccesBranch(true)
      setDeletePermissionAccesBranch(true)

    } else {

      // LOCATION_BASIC_INFORMATION
      if (rolePermissions[LOCATION_BASIC_INFORMATION] !== undefined) {
        setCreatePermissionBasicInfo(
          rolePermissions[LOCATION_BASIC_INFORMATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[LOCATION_BASIC_INFORMATION]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION])
        )

        setReadPermissionBasicInfo(
          contextSubscription?.[LOCATION_BASIC_INFORMATION]?.[WEB_PERMISSION] &&
          (rolePermissions[LOCATION_BASIC_INFORMATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[LOCATION_BASIC_INFORMATION]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]))
        )

        setUpdatePermissionBasicInfo(
          rolePermissions[LOCATION_BASIC_INFORMATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[LOCATION_BASIC_INFORMATION]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION])
        )

      }

      // LOCATION_ADDRESS
      if (rolePermissions[LOCATION_ADDRESS] !== undefined) {
        setCreatePermissionAddress(
          rolePermissions[LOCATION_ADDRESS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[LOCATION_ADDRESS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION])
        )

        setReadPermissionAddress(
          contextSubscription?.[LOCATION_ADDRESS]?.[WEB_PERMISSION] &&
          (rolePermissions[LOCATION_ADDRESS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[LOCATION_ADDRESS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]))
        )

        setUpdatePermissionAddress(
          rolePermissions[LOCATION_ADDRESS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[LOCATION_ADDRESS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION])
        )

      }

      // LOCATION_CONTACT
      if (rolePermissions[LOCATION_CONTACT] !== undefined) {
        setCreatePermissionContact(
          rolePermissions[LOCATION_CONTACT][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[LOCATION_CONTACT]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION])
        )

        setReadPermissionContact(
          contextSubscription?.[LOCATION_CONTACT]?.[WEB_PERMISSION] &&
          (rolePermissions[LOCATION_CONTACT][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[LOCATION_CONTACT]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]))
        )

        setUpdatePermissionContact(
          rolePermissions[LOCATION_CONTACT][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[LOCATION_CONTACT]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION])
        )

        setDeletePermissionContact(
          rolePermissions[LOCATION_CONTACT][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        )
      }

      // LOCATION_ACCESS_BRANCH
      if (rolePermissions[LOCATION_ACCESS_BRANCH] !== undefined) {
        setCreatePermissionAccesBranch(
          rolePermissions[LOCATION_ACCESS_BRANCH][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[LOCATION_ACCESS_BRANCH]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION])
        )

        setReadPermissionAccesBranch(
          contextSubscription?.[LOCATION_ACCESS_BRANCH]?.[WEB_PERMISSION] &&
          (rolePermissions[LOCATION_ACCESS_BRANCH][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[LOCATION_ACCESS_BRANCH]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]))
        )

        setUpdatePermissionAccesBranch(
          rolePermissions[LOCATION_ACCESS_BRANCH][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[LOCATION_ACCESS_BRANCH]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION])
        )

        setDeletePermissionAccesBranch(
          rolePermissions[LOCATION_ACCESS_BRANCH][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        )
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices, contextIsSuperAdmin, contextIsAdmin, contextSubscription]);


  const handlePickFile = async (evt, section) => {
    const result = await pickFile(evt);

    switch (section) {
      case 'photo':
        setBasicInfoPhoto(result.file);
        break;

      default:
    }
  };

  const dropDownHandleSelect = (item, name) => {
    //recursive objects merge
    if ((name === 'branch') ||
      (name === 'accessBranch') ||
      (name === 'location_privacy') ||
      (name === 'location_run_routes')) {
      if (name === 'branch') {
        values.branch = item.branch;
      } else if (name === 'accessBranch') {
        values.location_access_branch = item?.branch;

      } else if (name === 'location_privacy') {
        values.location_privacy = item?.privacy;
      } else if (name === 'location_run_routes') {
        values.location_run_routes = item?.run_routes;
      }
      setValues({ ...values });

    } else if (name === 'status') {
      values.status = item.status;
      setValues({ ...values });
    } else {
      const result = _.merge(values, item);
      setValues({ ...result });
    }

  };

  const mapClickHandler = (lat, lon) => {
    Geocode.fromLatLng(lat, lon).then(
      (response) => {
        // const address = response.results[0].formatted_address;

        let tempAddress = _.cloneDeep(response.results[0].address_components)
        if (!values.address) {
          values.address = {}
        }
        values.address.latitude = lat
        values.address.longitude = lon
        response.results[0].address_components.forEach((element, index) => {
          if (element.types.find(value => value == 'postal_code')) {
            values.address.zip_code = element.long_name
            //  tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'country')) {
            values.address.country = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_1')) {
            values.address.state = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_2' || value == 'locality')) {
            values.address.city = element.long_name
            // tempAddress.splice(index,1)
          }

        });
        let lineAddress = ""
        tempAddress.forEach(ele => {
          if (ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
            || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
          ) {
            lineAddress = lineAddress
          } else {
            lineAddress += ele.long_name + " "
          }

        })
        values.address.line_1 = lineAddress
        setValues({ ...values })
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const getLocationByIdApi = async () => {
    setModalLoading(true)
    try {
      const response = await getLocationById(selectedId)

      setValues({
        ...response,
        location_access_branch: response?.branch_access?.map(item => ({ value: item.branch_id, id: item.branch_id, label: item.branch_name })),
        location_privacy: response?.privacy?.map(item => ({ value: item.privacy_id, id: item.privacy_id, label: item.privacy_name })),
        location_run_routes: response?.run_routes?.map(item => ({ value: item.run_routes_id, id: item.run_routes_id, label: item.run_routes_name }))
      })
      if (response.address) {
        setClientAddress({ ...response.address })
      }
    } catch (error) {
      console.log(error)
    }
    setModalLoading(false)
  }

  useEffect(() => {
    setRowData({
      first_name: values.first_name,
      middle_name: values.middle_name,
      full_name: values.full_name,
      location_onboarded_date: values.location_onboarded_date,
      photo: basicInfoPhoto
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.first_name, values.middle_name, values.full_name, values.location_onboarded_date, basicInfoPhoto])


  useEffect(() => {
    if (selectedId) {
      getLocationByIdApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])

  const handleSubmit = async () => {

    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    setLoadingSave(true)
    const body = {
      contact: {
        telephone: values?.contact?.telephone || '',
        // telephone_work: values.telephone_work || '',
        mobile: values?.contact?.mobile || '',
        email_personal: values?.contact?.email_personal || '',
        email_work: values?.contact?.email_work || '',
        contact_method: values?.contact?.contact_method || '',
      },
      // address: values.address,
      address: {
        name: values?.address?.name || '',
        line_1: values?.address?.line_1 || '',
        city: values?.address?.city || '',
        zip_code: values?.address?.zip_code || '',
        line_2: values?.address?.line_2 || '',
        state: values?.address?.state || '',
        country: values?.address?.country || '',
        latitude: values?.address?.latitude || '',
        longitude: values?.address?.longitude || '',
      },
      location_access_branch: values?.location_access_branch?.map(item => ({
        branch: item.value,
      })),
      location_privacy: values?.location_privacy?.map(item => ({
        privacy: item.value,
      })) || [],
      location_run_routes: values?.location_run_routes?.map(item => ({
        run_routes: item.value,
      })) || [],
      full_name: values.full_name,
      first_name: values.first_name,
      status: values.status,
      branch: values.branch,
    };
    if (values.id) {
      body.id = values.id
    }
    if (values.middle_name) {
      body.middle_name = values.middle_name
    }
    if (values.previous_name) {
      body.previous_name = values.previous_name
    }
    if (values.initials) {
      body.initials = values.initials
    }
    if (values.preferred_name) {
      body.preferred_name = values.preferred_name
    }
    if (values.final_location_date) {
      body.final_location_date = values.final_location_date
    }
    if (values.location_onboarded_date) {
      body.location_onboarded_date = values.location_onboarded_date
    }

    if (basicInfoPhoto) {
      body.photo = basicInfoPhoto;
    } else if (body.photo) {
      delete body.photo;
    }

    try {
      const res = await setLocation(body)
      if (res.message.status) {
        onSuccess(res)

      } else {
        onError(res)
      }

    } catch (error) {
      onError(error)
    }
  }

  const onSuccess = async (response) => {
    const id = response?.message?.location_id
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${response.message.message}`,
    });
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setErrorMessage("");
    setShowError(false)
    if (id) {
      history.push({
        pathname: `/location/edit/${id}/location-details`,
        state: { id: id }
      })
    }
  };

  const onError = err => {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${err?.message?.message}`,
    });
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(err?.message?.message);
  };
  return (
    <div className='mt-4'>
      <Toast ref={toast} />
      <ComponentDynamic loading={modalLoading}>
        {(selectedId ?
          (readPermissionBasicInfo || readPermissionAddress || readPermissionContact) :
          (createPermissionBasicInfo || createPermissionAddress || createPermissionContact)) ?
          <>
            {(selectedId ? readPermissionBasicInfo : createPermissionBasicInfo) &&
              <BasicInformation
                values={values}
                setValues={setValues}
                setPhotoById={() => { }}
                useInput={useInput}
                userPhoto={userPhoto}
                choices={choices}
                dropDownHandleSelect={dropDownHandleSelect}
                handlePickFile={handlePickFile}
                clientList={[]}
                readOnly={selectedId ? !updatePermissionBasicInfo : !createPermissionBasicInfo}
                createPermissionAccesBranch={createPermissionAccesBranch}
                readPermissionAccesBranch={readPermissionAccesBranch}
                updatePermissionAccesBranch={updatePermissionAccesBranch}
                selectedId={selectedId}
                basicInfoPhoto={basicInfoPhoto}
                setDisplayPhoto={setDisplayPhoto}
              />

            }
            {(selectedId ? readPermissionAddress : createPermissionAddress)
              &&
              <ContainerResponsive className="d-flex justify-content-between mt-3">
                <PersonalAddress
                  useInput={useInput}
                  values={values}
                  setValues={setValues}
                  readOnly={selectedId ? !updatePermissionAddress : !createPermissionAddress}
                />
                <FlexEqual className="mt-2">
                  <MapComponent
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    latitude={values && values.address && values.address.latitude}
                    longitude={values && values.address && values.address.longitude}
                    mapClickHandler={mapClickHandler}
                  />
                </FlexEqual>
              </ContainerResponsive>

            }

            {(selectedId ? readPermissionContact : createPermissionContact)
              && <PersonalContact
                useInput={useInput}
                values={values}
                choices={choices}
                dropDownHandleSelect={dropDownHandleSelect}
                readOnly={selectedId ? !updatePermissionContact : !readPermissionContact}
                // inviteUserStatus={values.make_user}
                inviteUserStatus={true}
              />}

            {(selectedId ? readPermissionBasicInfo : createPermissionBasicInfo) &&
              <ContainerResponsive>
                <FlexEqual className="">
                  <TitleSection>Privacy And Run Route</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <FlexGrid3Container className="w-100 mb-2">
                      <div className="w-50 pr-0 pr-md-1">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Privacy'}
                          options={choices ? choices.alert_privacy : []}
                          selectedOptionsProps={values.location_privacy}
                          selectedOption={values.location_privacy}
                          onChange={value =>
                            dropDownHandleSelect({ privacy: value ? value : null }, 'location_privacy')
                          }
                          disabled={selectedId ? !updatePermissionBasicInfo : !readPermissionBasicInfo}
                        />
                      </div>
                      <div className="w-50 pl-1 pr-0 pr-md-1">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Run Route'}
                          options={choices ? choices.run_routes : []}
                          selectedOptionsProps={values.location_run_routes}
                          selectedOption={values.location_run_routes}
                          onChange={value =>
                            dropDownHandleSelect({ run_routes: value ? value : null }, 'location_run_routes')
                          }
                          disabled={selectedId ? !updatePermissionBasicInfo : !readPermissionBasicInfo}
                        />
                      </div>
                      {/* {choices && choices.roles && choices.roles.find(role => role.id === values.role_id) && choices.roles.find(role => role.id === values.role_id).is_staff &&
                      <span
                        className={'ml-2 mt-1'}
                        style={{ color: "green", fontStyle: 'italic' }}
                      >OFFICE</span>
                    } */}

                    </FlexGrid3Container>
                  </div>
                </FlexEqual>
              </ContainerResponsive>

            }

            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div className={'d-flex justify-content-center justify-content-md-end mb-4'}>
                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      history.push('/location')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>

                  {(selectedId ? updatePermissionLocationDetails : true)
                    && <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      // disabled={selectedId ? !updatePermission : !readPermission}
                      onClick={handleSubmit}>
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  }

                </div>
              </div>
            </div>
          </>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedId ? 'read' : 'create'} the information.
          </NoPermissionContainer>
        }
        {/*  <ModalDecision
          type="warning"
          title="Warning"
          body={
            (warningText == 'Active') ? 'Shifts wont be created after employee end date, if you want to view the changes on scheduler please remove employee end date. ' : `Status for employee is set ${warningText}, Visit will be deleted from ${dateStatus}.`
          }
          subBody={'Do you still want to continue?'}
          onOk={() => handleSubmit()}
          onCancel={() => {
            setModalWarningValue(false);
          }}
          okTitle={'Confirm'}
          {...bindModalWarning}
        /> */}
      </ComponentDynamic>
    </div>

  )
}

export default LocationDetails