


import React, { useState, useEffect, useRef } from 'react';
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
    ContainerTabStyled,
    LinkTabStyled,
    LinkTab,
    Title,
    Header,
} from 'shared/styles/constants/tagsStyles';
import {
    SETTINGS,
    SETTINGS_CALENDAR,
    PUBLIC_HOLIDAYS,
    WD_WE,
    DAY_NIGHT_OVER,
    DURATION,
    CALENDAR_DATA,
    PAGE_404,
    CALENDAR_TYPE,
    SETTINGS_ESV,
    ESV,
    SPLIT_SHIFT_ROUTE,
} from 'utils/constants/routes';
import { SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION, CALENDAR_TYPE as CALENDAR_TYPE_PERMISSION_ } from 'utils/constants/permissions';
import { useContext } from 'react';
import { AppContext } from 'context';
import SettingsEsv from './SettingsEsv';
import SplitShift from './SplitShift';

const TAB_ESV_URL = SETTINGS + SETTINGS_ESV + ESV;
const TAB_SPLIT_SHIFT_URL = SETTINGS + SETTINGS_ESV + SPLIT_SHIFT_ROUTE;

const EsvSettings = props => {
    const initialPermission = {
        read: false,
        create: false,
        update: false,
        delete: false
    }
    const [permissionESV, setPermissionESV] = useState(initialPermission)
    const [permissionSplitShift, setPermissionSplitShift] = useState(initialPermission)


    const {
        contextPermissions,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextSubscription,
        contextIsSuperAdmin
    } = useContext(AppContext)

    let subscription = contextSubscription
    let history = useHistory();

    const reviewLocation = url => {
        return url === history.location.pathname;
    };

    // useEffect(() => {
    //     if (permissionESV.read) {
    //         history.push(TAB_ESV_URL)
    //     } else if (permissionSplitShift.read) {
    //         history.push(TAB_SPLIT_SHIFT_URL)
    //     }
    // }, [permissionESV, permissionSplitShift])

    useEffect(() => {
        if (history.location.pathname === (SETTINGS + SETTINGS_ESV)) {
            history.push(TAB_ESV_URL)
        }
    }, [history.location.pathname])

    useEffect(() => {
        if (contextIsAdmin == true || contextIsSuperAdmin == true) {
            setPermissionESV({
                read: true,
                create: true,
                delete: true,
                update: true
            })
            setPermissionSplitShift({
                read: true,
                create: true,
                delete: true,
                update: true
            })
        } else {
            const rolesPermissions = contextPermissions;

            let rolePermissions = {};
            if (rolesPermissions.length > 0) {
                rolesPermissions.forEach(
                    item => {
                        if (item.section !== null) {
                            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
                            rolePermissions[item.section.name] = {};
                            rolePermissions[item.section.name][WEB_PERMISSION] = {};
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_READ_PERMISSION
                            ] = item?.[WEB_READ_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_READ_PERMISSION
                            ] = item?.[SELF_WEB_READ_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_CREATE_PERMISSION
                            ] = item?.[WEB_CREATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_CREATE_PERMISSION
                            ] = item?.[SELF_WEB_CREATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_DELETE_PERMISSION
                            ] = item?.[WEB_DELETE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_UPDATE_PERMISSION
                            ] = item?.[WEB_UPDATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_UPDATE_PERMISSION
                            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_DELETE_PERMISSION
                            ] = item?.[SELF_WEB_DELETE_PERMISSION];
                        }
                    }
                    // }
                );
            }
            const roles = contextSubSectionPermissions;

            if (roles.length > 0) {
                roles.forEach(
                    item => {
                        if (item.section !== null) {
                            rolePermissions[item.section.name] = {};
                            rolePermissions[item.section.name][WEB_PERMISSION] = {};
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_READ_PERMISSION
                            ] = item?.[WEB_READ_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_READ_PERMISSION
                            ] = item?.[SELF_WEB_READ_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_CREATE_PERMISSION
                            ] = item?.[WEB_CREATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_CREATE_PERMISSION
                            ] = item?.[SELF_WEB_CREATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_DELETE_PERMISSION
                            ] = item?.[WEB_DELETE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                WEB_UPDATE_PERMISSION
                            ] = item?.[WEB_UPDATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_UPDATE_PERMISSION
                            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                            rolePermissions[item.section.name][WEB_PERMISSION][
                                SELF_WEB_DELETE_PERMISSION
                            ] = item?.[SELF_WEB_DELETE_PERMISSION];
                        }
                    }
                );
            }
            const getPermission = (key, type) => {
                if (rolePermissions[key]) {
                    debugger
                    switch (type) {
                        case WEB_READ_PERMISSION:
                            return (subscription?.[key]?.[WEB_PERMISSION] &&
                                (rolePermissions[key][WEB_PERMISSION][WEB_READ_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_READ_PERMISSION])
                            );
                        case WEB_CREATE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION])
                            );
                        case WEB_UPDATE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
                            )
                        case WEB_DELETE_PERMISSION:
                            return (
                                (rolePermissions[key][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
                                    rolePermissions[key][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION])
                            )
                        default:
                            break;
                    }

                }
            };

            setPermissionESV({
                read: true,
                create: true,
                delete: true,
                update: true
            })

            setPermissionSplitShift({
                read: true,
                create: true,
                delete: true,
                update: true
            })

            // setReadPermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_READ_PERMISSION) || false)
            // setCreatePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_CREATE_PERMISSION) || false)
            // setUpdatePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_UPDATE_PERMISSION) || false)
            // setDeletePermissionCalendarType(getPermission(CALENDAR_TYPE_PERMISSION_, WEB_DELETE_PERMISSION) || false)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextSubscription, contextPermissions, contextIsSuperAdmin, contextIsAdmin, subscription]);
    console.log(contextSubscription, "contextSubscription")

    return (
        <div>
            <Title> Settings ESV</Title>
            <div className="p-2 p-sm-3 p-md-4">
                <ContainerTabStyled className="text-center mb-4">
                   
                        <div className={'d-flex firstRow'}>
                        
                            <LinkTabStyled active={reviewLocation(TAB_ESV_URL)}>
                                <Link to={TAB_ESV_URL} className={'styledLink'}>
                                    <LinkTab active={reviewLocation(TAB_ESV_URL)}>
                                        ESV
                                    </LinkTab>
                                </Link>
                            </LinkTabStyled>

                            <LinkTabStyled active={reviewLocation(TAB_SPLIT_SHIFT_URL)} >
                                <Link to={TAB_SPLIT_SHIFT_URL} className={'styledLink'} >
                                    <LinkTab active={reviewLocation(TAB_SPLIT_SHIFT_URL)} >
                                        Split Shift
                                    </LinkTab>
                                </Link>
                            </LinkTabStyled>
                           
                        </div>
                    
                </ContainerTabStyled>
                <Header className='mb-2' />
                <Switch>

                    <Route exact path={SETTINGS + SETTINGS_ESV}>
                        <SettingsEsv
                            props={props}
                            permission={permissionESV} />
                    </Route>

                    <Route exact path={TAB_ESV_URL}>
                        <SettingsEsv
                            props={props}
                            permission={permissionESV} />
                    </Route>

                    <Route exact path={TAB_SPLIT_SHIFT_URL}>
                        <SplitShift
                            props={props}
                            permission={permissionSplitShift} />
                    </Route>

                    <Redirect
                        to={{
                            pathname: PAGE_404,
                            state: { referrer: '/' },
                        }}
                    />

                </Switch>
            </div>
        </div>
    );
};

export default EsvSettings;
