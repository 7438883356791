import React from "react";
import { StyledCheck } from "./styles";
import { useEffect } from "react";

const Checkbox = ({ item, id, label, checked }) => {
  useEffect(() => {
    checked=checked
  },[checked])
  return (
    <StyledCheck className="form-check">
      <div className="custom-control custom-checkbox">
        <input
          id={id}
          // {...props.useInput}
          type="checkbox"
          className="custom-control-input"
          checked={checked[item.id] === true}
          readOnly
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </StyledCheck>
  );
};

export default Checkbox;
