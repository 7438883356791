import {LARGE_COL  , SMALL_COL,EX_SMALL_COL, MEDIUM_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Wage Type',
    name: 'wages_type',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },
   {
    label: 'Wage Rate Description',
    name: 'wages_rate_description',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Age',
    name: 'age_from',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Effective Date From',
    name: 'effective_date_from',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Effective Date To',
    name: 'effective_date_to',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Pay Rate/hr',
    name: 'rate_per_hour',
    status: 0,
    className: 'grow-mobile ',
    max_width: MEDIUM_COL,
  },

  
];
