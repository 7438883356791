import React from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap4-modal';
import { FaTimes } from 'react-icons/fa';
import DropdownSearchable from '../DropdownSearchable';
import CustomDropdown from '../NoSelectAllDropdownFilter/CustomDropdown';
import './decision.css'

import {
  CancelButton, PrimaryButtonModal, Body, SubBody, CloseButtonContainer,
  CloseButtonWrapper
} from './styles';
import {
  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { useState } from 'react';
function ConfirmRunSchedular({
  type,
  max_width,
  title,
  body,
  subBody,
  showModal,
  onOk,
  onCancel,
  onClose,
  onNo,
  handleRunRadio,
  checkedRunRadio,
  checkedFreezeVisits,
  setcheckedRunRegular,
  checkedRunRegular,
  setconsiderTimeRes,
  setconsiderEmpRota,
  considerEmpRota,
  considerTimeRes,
  branch,
  dropDownHandleSelect,
  setFilterBranchRun,
  branchRunSchedularFilter,
  values,
  setPlanOnlyToggle,
  planOnlyToggle,
  overrideWindow,
  overrideWindowMinutes,
  setOverrideWindow,
  setOverrideWindowMinutes,
}) {

  console.log('checkedFreezeVisits', checkedFreezeVisits)
  // useEffect(()=>{
  //   if(branch && branch.length > 0){
  //     let branch_fill = branch.map((item)=>({value: item.id, label: item.name}));
  //     setFilterBranchRun([...branch])
  //   }
  // },[branch])

  return (
    <Modal visible={showModal} className='run-sch-modal'>
      <div className="modal-header border-0">
        <h5 className="modal-title">{title}</h5>
        <CloseButtonContainer>
          <CloseButtonWrapper>
            <FaTimes onClick={onClose} />
          </CloseButtonWrapper>
        </CloseButtonContainer>
      </div>

      <div className="modal-body">
        <ToggleDiv className='mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>Plan Only ?</p>
          </div>
          <ToggleWrapper>
            <Toggle
              id="planOnly"
              type="checkbox"
              checked={planOnlyToggle}
              onClick={() => {
                planOnlyToggle ? setPlanOnlyToggle(false) : setPlanOnlyToggle(true);
              }}
            />
            <ToggleLabel htmlFor="planOnly" />
          </ToggleWrapper>

        </ToggleDiv>
        <div>
          {planOnlyToggle ?
            <DropdownSearchable
              isMulti={true}
              placeHolder={'Freeze Visit Type'}
              options={[
                { id: 'freezeCancelled', name: 'Cancelled' },
                { id: 'freezeCompleted', name: 'Completed' },
                { id: 'freezeInProgress', name: 'In-Progress' },
                { id: 'freezePublished', name: 'Published' },
                { id: 'freezeAssigned', name: 'Assigned' },
                { id: 'freezeUnassigned', name: 'UnAssigned' }
              ]}
              selectedOptionsProps={checkedFreezeVisits}
              onChange={value =>
                handleRunRadio({ playOnDropdownValue: value }, 'freeze_visit')
              }
            />
            :
            <DropdownSearchable
              placeHolder={body}
              options={[
                { id: 'Unassigned', name: 'Unassigned' },
                { id: 'Unpublished', name: 'Unpublished' },
                { id: 'Regular', name: 'Regular' },
                { id: 'All', name: 'All' }
              ]}
              selectedOption={checkedRunRadio}
              onChange={value =>
                handleRunRadio({ playOnDropdownValue: value }, 'consider_visit')
              }
            />
          }
        </div>
        <ToggleDiv className='mt-3 mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>{subBody}</p>
          </div>
          <ToggleWrapper>
            <Toggle
              id="checkboxIncludeRNP"
              type="checkbox"
              checked={checkedRunRegular}
              onClick={() => {
                checkedRunRegular ? setcheckedRunRegular(false) : setcheckedRunRegular(true);
              }}
            />
            <ToggleLabel htmlFor="checkboxIncludeRNP" />
          </ToggleWrapper>
        </ToggleDiv>
        <ToggleDiv className='mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>Consider time restriction</p>
          </div>
          <ToggleWrapper>
            <Toggle
              id="checkboxConsiderTime"
              type="checkbox"
              checked={considerTimeRes}
              onClick={() => {
                considerTimeRes ? setconsiderTimeRes(false) : setconsiderTimeRes(true);
              }}
            />
            <ToggleLabel htmlFor="checkboxConsiderTime" />
          </ToggleWrapper>

        </ToggleDiv>
        <ToggleDiv className='mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>Consider Employee Rota</p>
          </div>
          <ToggleWrapper>
            <Toggle
              id="considerEmployeeRota"
              type="checkbox"
              checked={considerEmpRota}
              onClick={() => {
                considerEmpRota ? setconsiderEmpRota(false) : setconsiderEmpRota(true);
              }}
            />
            <ToggleLabel htmlFor="considerEmployeeRota" />
          </ToggleWrapper>

        </ToggleDiv>
        <ToggleDiv className='mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>Override TimeWindow</p>
          </div>
          <ToggleWrapper>
            <Toggle
              id="overrideWindow"
              type="checkbox"
              checked={overrideWindow}
              onClick={() => {
                overrideWindow ? setOverrideWindow(false) : setOverrideWindow(true);
              }}
            />
            <ToggleLabel htmlFor="overrideWindow" />
          </ToggleWrapper>

        </ToggleDiv>
        {overrideWindow && 
        <ToggleDiv className='mb-0 justify-content-between'>
          <div className="toggle-label ml-2  mb-1 pb-3 mr-2">
            <p>Override Minutes</p>
          </div>
          <div className='mb-2'>
          <input
            type="number"
            className='w-100'
            id="inputdecimal"
            name="inputDecimal"
            placeholder={'Minutes Before/After'}
            value={overrideWindowMinutes}
            style={{ padding: 7, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
            onChange={(event) => {
              setOverrideWindowMinutes(event.target.value)
            }}
          />
        </div>

        </ToggleDiv>

       
       }
        {/* <div className='d-flex'>
          <div className='col-8  text-left'>
            <Body>Consider time restriction</Body>
          </div>
          <div className='col-4 p-0  text-left'>
            <input
              type="checkbox"
              //aria-label={UPDATE_TITLE}
              checked={checkedRunRegular}
              onChange={() => setcheckedRunRegular(!checkedRunRegular)}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div className='col-8  text-left'>
            <Body>Consider time restriction</Body>
          </div>
          <div className='col-4 p-0  text-left'>
            <input
              type="checkbox"
              //aria-label={UPDATE_TITLE}
              checked={considerTimeRes}
              onChange={() => setconsiderTimeRes(!considerTimeRes)}
            />
          </div>
        </div> */}
        {/* <div className='d-flex mt-2'>
          <div className='col-8 text-left'>
            <Body>{body}</Body>
          </div>
          <div className='col-4 p-0 text-left'>
            <form >

              <div class="form-check">
                <input class="form-check-input" onClick={(val) => handleRunRadio('Unassigned')} type="radio" value='Unassigned' name="flexRadioDefault" id="flexRadioDefault1" checked={(checkedRunRadio === 'Unassigned') ? true : false} />
                <label class="form-check-label" for="flexRadioDefault1">
                  Unassigned
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" onClick={(val) => handleRunRadio('Unpublished')} type="radio" value='Unpublished' name="flexRadioDefault" id="flexRadioDefault2" checked={(checkedRunRadio === 'Unpublished') ? true : false} />
                <label class="form-check-label" for="flexRadioDefault2">
                  Unpublished
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" onClick={(val) => handleRunRadio('Regular')} type="radio" value='Regular' name="flexRadioDefault" id="flexRadioDefault3" checked={(checkedRunRadio === 'Regular') ? true : false} />
                <label class="form-check-label" for="flexRadioDefault3">
                  Regular
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" onClick={(val) => handleRunRadio(null)} type="radio" value='All' name="flexRadioDefault" id="flexRadioDefault4" checked={(checkedRunRadio === null) ? true : false} />
                <label class="form-check-label" for="flexRadioDefault4">
                  All
                </label>
              </div>
            </form >

          </div>
        </div> */}
        <div>
          <DropdownSearchable
          required
            // isMulti={true}
            placeHolder={'Branch'}
            options={branch ? branch : []}
            selectedOption={
              values
                ? values.branch_for_dropdown
                  ? values.branch_for_dropdown
                  : null
                : null
            }
            onChange={value =>
              dropDownHandleSelect({ branch_for_dropdown: value })
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-center modal-footer border-0">
        <PrimaryButtonModal typeButton={type} onClick={onOk}>
          {"Run"}
        </PrimaryButtonModal>
        {/* <CancelButton
          type="button"
          className="btn btn-secondary"
          onClick={onNo}>
          No
        </CancelButton> */}

      </div>
    </Modal>
  );
}

export default ConfirmRunSchedular;
