import React from 'react';
import { EmploymentTypesContainer, Title } from '../styles';
import { useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import { useEffect } from 'react';
import { team_skill_matrix_choices } from 'utils/choiceConstant';
import {
    getActiveEmployee,
    getDistinctSkillList,
    getEmployeeSkillMatrix,
} from 'utils/api/EmployeeApi';
import { useState } from 'react';
import moment from 'moment';
import { useContextMenu } from 'hooks/CustomContext';
import './styles.css';
import {
    MATRIX_GREEN,
    MATRIX_RED,
    MATRIX_YELLOW,
} from 'shared/styles/constants/colors';
import { useModal } from 'hooks/ModalHook';
import { useForm } from 'hooks/FormHook';
import UpdateModal from 'components/DashboardComponents/Settings/SettingsTeam/SkillMatrix/Modals/UpdateModal';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import {
    DropdownTypesContainer,
    FiltersContainer,
    FiltersLeftGroup,
    LoadingContainer,
    PublishButton,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { DOWNLOAD } from 'utils/constants/icons';
import { PrimaryButton } from 'shared/styles/buttons';
import { getEmployee } from '../../../../utils/localStorage/token';
import {
    EMPLOYEE_SKILL_MATRIX,
    SELF_WEB_CREATE_PERMISSION,
    SELF_WEB_DELETE_PERMISSION,
    SELF_WEB_READ_PERMISSION,
    SELF_WEB_UPDATE_PERMISSION,
    WEB_CREATE_PERMISSION,
    WEB_DELETE_PERMISSION,
    WEB_PERMISSION,
    WEB_READ_PERMISSION,
    WEB_UPDATE_PERMISSION,
} from 'utils/constants/permissions';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import Spinner from 'components/SharedComponents/Spinner';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Dropdown from 'components/SharedComponents/Dropdown';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
function TeamSkillMatrix() {
    const toast = useRef(null);
    // const {  handleGetChoices } = useContext(AppContext);
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle: setTitle,
        fullBind: fullBind,
    } = useModal(false);

    const {
        contextChoices: choices,
        contextPermissions,
        contextSubscription,
        contextSubSectionPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        handleGetChoices,
    } = useContext(AppContext);

    const userEmpoloyee = getEmployee();
    const { branchData } = useContext(BranchItemsContext);
    const { xPos, yPos, showMenu, seletedId, setSeletedId } = useContextMenu();
    const { values, setValues, useInput } =
        useForm({});

    const [skillMatrices, setSkillMatrices] = useState([]);
    const [distinctSkill, setDistinctSkill] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allEmployees, setAllEmployees] = useState([]);
    const [selfReadPermission, setSelfReadPermission] = useState(undefined);
    const [employeeFilter, setEmployeeFilter] = useState([]);
    const [branchFilter, setBranchFilter] = useState([]);
    const [skillFilter, setSkillFilter] = useState([])
    const [categoryFilter, setCategoryFilter] = useState([])
    const [filterChoiseEmployee, setFilterChoiseEmployee] = useState([]);
    const [rows, setRows] = useState([]);
    const [counts, setCounts] = useState({});
    const [statusCount, setStatusCount] = useState([])


    // Employee Skill Matrix & Distinct Skill List apis
    const getEmployeeSkillMatrixList = async () => {
        setLoading(true)
        let date_from = values?.date_from;
        let date_to = values?.date_to;
        if (date_from && date_to && new Date(date_from) >= new Date(date_to)) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Date From should be greater than Date To!`,
            });
            return;
        }
        try {
            const [employeeSkillMAtrix, distinctSkill, activeEmp] = await Promise.all(
                [
                    getEmployeeSkillMatrix({ ...branchData, ...values }),
                    getDistinctSkillList({ ...branchData, ...values }),
                    getActiveEmployee({ ...values }),
                ]
            );
            if (
                employeeSkillMAtrix.status &&
                distinctSkill.success &&
                activeEmp.status
            ) {
                setSkillMatrices(employeeSkillMAtrix);
                setDistinctSkill(distinctSkill?.data);
                setAllEmployees(activeEmp?.data);
                let status = getStatusCount(employeeSkillMAtrix)
                setStatusCount(status)
            }
        } catch (error) {
            console.error(error);
        }
    };

    function getStatusCount(data) {
        let obj = {};
        if (!!data.Passed) {
            obj.Passed = data.Passed
        }
        if (!!data.Completed) {
            obj.Completed = data.Completed
        } if (!!data.Failed) {
            obj.Failed = data.Failed
        }
        const mappedArray = Object.entries(obj).map(([key, value]) => {
            return {
                status: key,
                count: value,
            };
        });
        return mappedArray || []
    }
    useEffect(() => {
        getEmployeeSkillMatrixList();
    }, [branchData]);


    useEffect(() => {
        handleGetChoices(team_skill_matrix_choices);
    }, []);

    useEffect(() => {
        const rolesPermissions = contextPermissions;
        let rolePermissions = {};

        if (rolesPermissions?.length > 0) {
            rolesPermissions.forEach(item => {
                if (item.section !== null) {
                    // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
                    rolePermissions[item.section.name] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION] = {};
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_READ_PERMISSION
                    ] = item?.[WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_READ_PERMISSION
                    ] = item?.[SELF_WEB_READ_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_CREATE_PERMISSION
                    ] = item?.[WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_CREATE_PERMISSION
                    ] = item?.[SELF_WEB_CREATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_DELETE_PERMISSION
                    ] = item?.[WEB_DELETE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        WEB_UPDATE_PERMISSION
                    ] = item?.[WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_UPDATE_PERMISSION
                    ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    rolePermissions[item.section.name][WEB_PERMISSION][
                        SELF_WEB_DELETE_PERMISSION
                    ] = item?.[SELF_WEB_DELETE_PERMISSION];
                }
            });
        }

        const roles = contextSubSectionPermissions;

        if (roles.length > 0) {
            roles.forEach(
                item => {
                    if (item.section !== null) {
                        rolePermissions[item.section.name] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION] = {};
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_READ_PERMISSION
                        ] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_READ_PERMISSION
                        ] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_CREATE_PERMISSION
                        ] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_CREATE_PERMISSION
                        ] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_DELETE_PERMISSION
                        ] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            WEB_UPDATE_PERMISSION
                        ] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_UPDATE_PERMISSION
                        ] = item?.[SELF_WEB_UPDATE_PERMISSION];
                        rolePermissions[item.section.name][WEB_PERMISSION][
                            SELF_WEB_DELETE_PERMISSION
                        ] = item?.[SELF_WEB_DELETE_PERMISSION];
                    }
                }
                // }
            );
        }

        if (rolePermissions[EMPLOYEE_SKILL_MATRIX] !== undefined) {
            let self =
                rolePermissions[EMPLOYEE_SKILL_MATRIX]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
                ];
            setSelfReadPermission(self);
        }
    }, [
        choices,
        contextIsSuperAdmin,
        contextIsAdmin,
        contextSubscription,
        contextPermissions,
        allEmployees,
    ]);

    useEffect(() => {
        getUpdatedEmployeeList();
    }, [distinctSkill, allEmployees, selfReadPermission]);

    const getUpdatedEmployeeList = () => {
        if (values.employees && values.employees.length > 0) {
            let result = allEmployees?.filter(element => {
                return values.employees.includes(element.id);
            });
            setFilterChoiseEmployee(result || []);
        } else {
            setFilterChoiseEmployee(allEmployees);
        }
        if (selfReadPermission !== undefined) {
            if (selfReadPermission) {
                let filtedEmp = allEmployees?.filter(el => el.id === userEmpoloyee);
                setFilterChoiseEmployee(filtedEmp);

                setLoading(false);
            } else {
                setFilterChoiseEmployee(allEmployees);

                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        getEmployeeSkillMatrixList();
    };

    const colorCombination = date => {
        const currentDate = moment();
        const oneWeekLater = moment().add(7, 'd');
        const formatedParamDate = moment(date);
        if (
            new Date(formatedParamDate) >= new Date(currentDate) &&
            new Date(formatedParamDate) <= new Date(oneWeekLater)
        ) {
            return { color: MATRIX_YELLOW, name: 'Training due to Expire' };
        } else if (new Date(formatedParamDate) > new Date(oneWeekLater)) {
            return { color: MATRIX_GREEN, name: 'Currently Trained' };
        } else {
            return { color: MATRIX_RED, name: 'Training Expired' };
        }
    };

    useEffect(() => {
        const formattedRows = filterChoiseEmployee?.map(employee => {
            const row = {
                id: employee.id,
                name: employee.full_name,
            };

            const skillsByEmployee =
                skillMatrices.data?.find(data => data.id === employee.id)
                    ?.employee_skill_data || [];

            let a = distinctSkill?.forEach(skill => {
                const matchedSkill =
                    skillsByEmployee.find(skillData => skillData.id === skill.skill) ||
                    null;
                row[skill.skill] = matchedSkill;
            });

            return row;
        });

        const shouldFilterRows = item => {
            const valuesWithoutIdAndName = Object.values(item).filter(
                val => val !== null && val !== undefined
            );
            return valuesWithoutIdAndName.length > 2;
        };

        if (
            (values.employees && values.employees.length) ||
            (values.branch && values.branch.length) ||
            values.date_from ||
            values.date_to ||
            (values.status && values.status.length) ||
            (values.category && values.category.length) ||
            branchData?.headerbranch?.length ||
            values.expiration_status
        ) {
            const filteredRows = formattedRows.filter(shouldFilterRows);
            setRows(filteredRows);
        } else {
            setRows(formattedRows);
        }
    }, [filterChoiseEmployee, skillMatrices, distinctSkill]);

    const findNameFromChoices = (name, id) => {
        const find = choices?.skill?.find(skill => skill?.id == id);
        return find?.name;
    };
    var headers = distinctSkill?.map(s => ({
        id: s.skill,
        name: findNameFromChoices('skill', s?.skill),
    }));

    const columnWidth = `${headers?.length < 15 ? 100 / headers?.length : ''}%`;
    const capitalizeText = text =>
        text ? text.charAt(0).toUpperCase() + text.slice(1) : '';

    const statusColor = [
        { name: 'Training Expired', color_code: MATRIX_RED, count: 0 },
        { name: 'Currently Trained', color_code: MATRIX_GREEN, count: 0 },
        { name: 'Training due to Expire', color_code: MATRIX_YELLOW, count: 0 },
    ];

    const handleRightClck = (e, id) => {
        e.preventDefault();
        setSeletedId(id);
    };

    const handleUpdateHrTask = () => {
        // getSkillMatriceById()
        // setTitle('Update Skill Matrix')
        // setModalValue(!modalValue)
        // values.id = seletedId || '';
        // setValues({ ...values })
    };

    const dropDownHandleSelect = (item, name) => {
        if (name === 'branch') {
            values.branch = item?.branch?.map(item => item.id);
        } else if (name === 'employees') {
            values.employees = item?.employees?.map(item => item.id);
        } else if (name === 'status') {
            values.status = item.status?.map(item => item.id);
        } else if (name === 'category') {
            values.category = item.category?.map(item => item.id);
        } else if (name === 'expiration_status') {
            console.log(item.expiration_status)
            values.expiration_status = item.expiration_status || null
        }
        setValues({ ...values });
    };

    const setEmployee = item => {
        setEmployeeFilter([...item]);
    };

    const setBranchs = item => {
        setBranchFilter([...item]);
    };

    const setSkillStatus = item => {
        setSkillFilter([...item]);
    };

    const setSkillCategory = item => {
        setCategoryFilter([...item]);
    };

    let exportDate = moment().format('YYYY-MM-DD') + " " + moment().format('HH:mm:ss')

    useEffect(() => {
        let counts = {}
        rows.forEach((item) => {
            headers.map(header => {
                if (item[header.id]?.expiration_date) {
                    counts[colorCombination(item[header.id]?.expiration_date)?.name] = (counts[colorCombination(item[header.id]?.expiration_date)?.name] || 0) + 1;
                }
            })
        })
        setCounts(counts)
    }, [rows])

    const statusDropDownValue = [
        { name: 'Training Expired', id: "training_expired" },
        { name: 'Currently Trained', id: "currently_trained" },
        { name: 'Training Due To Expire', id: "training_due_to_expire" },
    ]
    console.log(values, "values")
    return (
        <div className="team-skill-container">
            <Toast ref={toast} position="top-right" />
            {/* <TeamCompetencyMatrix /> */}
            <Title>Skill Matrix</Title>
            <FiltersContainer className='d-flex justify-content-between w-100 mt-2'>
                <div className="d-flex flex-wrap" style={{gap:"0.5rem 0"}}>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={
                                    choices?.employee_listing?.map(el => ({
                                        ...el,
                                        name: el?.full_name,
                                    })) || []
                                }
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ employees: items }, 'employees');
                                }}
                                itemsSelected={employeeFilter}
                                setSelectedFilter={setEmployee}
                                placeHolder={'Employee'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.branch || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ branch: items }, 'branch');
                                }}
                                itemsSelected={branchFilter}
                                setSelectedFilter={setBranchs}
                                placeHolder={'Branch'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <DropdownTypesContainer className="ml-1  d-flex">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date From"
                                    {...useInput('date_from')}
                                />
                            </DropdownTypesContainer>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.skill_status || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ status: items }, 'status');
                                }}
                                itemsSelected={skillFilter}
                                setSelectedFilter={setSkillStatus}
                                placeHolder={'Skill Status'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <NoSelectAllDropdownFilter
                                items={choices?.skill_category || []}
                                setItemsSelected={items => {
                                    dropDownHandleSelect({ category: items }, 'category');
                                }}
                                itemsSelected={categoryFilter}
                                setSelectedFilter={setSkillCategory}
                                placeHolder={'Skill Category'}
                                isObject
                                allOption
                            // hitApiAfterClear={hitApiAfterClear}
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer
                            style={{ minWidth: '160px' }}
                            className="mr-2">
                            <DropdownSearchable
                                typeFilter={true}
                                placeHolder={'Status'}
                                options={
                                    statusDropDownValue || []
                                }
                                selectedOption={values.expiration_status}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        expiration_status: value ? value : null
                                    }, 'expiration_status')
                                }
                            />
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>

                    <FiltersLeftGroup>
                        <EmploymentTypesContainer className="mr-2">
                            <PrimaryButton
                                type="button"
                                onClick={handleSearch}>
                                <span className={'font-weight-bold'}>
                                    Search
                                </span>
                            </PrimaryButton>
                        </EmploymentTypesContainer>
                    </FiltersLeftGroup>
                </div>
                {rows.length > 0 && (
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="export-button"
                        table="skill-table-to-xls"
                        filename={`${exportDate} team-skill-matrix`}
                        sheet="tablexls"
                        buttonText={
                            <PublishButton>
                                {DOWNLOAD}
                            </PublishButton>
                        }
                    />)}
            </FiltersContainer>
            <div
                style={{ display: 'flex', justifyContent: 'between', marginTop: '8px', alignItems: 'end' }}>
                <div className='d-flex flex-wrap w-100'>
                    {
                        statusCount?.map(el => {
                            return (
                                <div className='mr-2 mb-2'>
                                    <p className='header-box'>{el?.status}:
                                        <span className='header-value'>{el?.count}</span></p>
                                </div>
                            )
                        })
                    }
                </div>
                {statusColor.map(item => (
                    <>
                        <p style={{ height: "31px" }} className="color-combination d-flex">
                            <div style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: `${item.color_code || ''}`,
                                marginRight: '8px',
                                marginBottom: '5px',
                                alignSelf: 'center',
                            }}></div>
                            <label className='count-text'> {item.name}</label>
                            <span className='count-text' style={{ marginLeft: '4px' }} >{`(${counts[item.name] || 0})`}</span>
                        </p>
                    </>
                ))}
            </div>
            <>{loading ? (
                <LoadingContainer maxHeight={null}>
                    <Spinner />
                </LoadingContainer>
            ) : (
                <div class="skill-matrix-table">
                    {rows.length ? (
                        <table id='skill-table-to-xls'>
                            <tr className="custom-border">
                                <th
                                    className="body-text"
                                    style={{
                                        textAlign: headers?.length < 1 ? 'left' : '',
                                        padding: headers?.length < 1 ? '0' : '',
                                    }}>
                                    <div
                                        className={headers?.length < 1 ? 'title' : ''}
                                        style={{ maxWidth: headers?.length < 1 ? '150px' : '' }}>
                                        Employees
                                    </div>
                                </th>
                                {headers?.map(header => {
                                    return (
                                        <th className="body-text" style={{ maxWidth: columnWidth }}>
                                            {capitalizeText(header?.name)}
                                        </th>
                                    );
                                })}
                            </tr>
                            {rows?.map((item, i) => {
                                return (
                                    <tr className="custom-border">
                                        <td
                                            className="header-text"
                                            style={{
                                                textAlign: headers.length < 1 ? 'left' : '',
                                                padding: headers.length < 1 ? '0' : '',
                                            }}>
                                            <div
                                                className={headers.length < 1 ? 'title' : ''}
                                                style={{ maxWidth: headers.length < 1 ? '150px' : '' }}>
                                                {capitalizeText(item.name)}
                                            </div>
                                        </td>
                                        {headers?.map(header => {
                                            const date = item[header.id]?.expiration_date
                                                ? item[header.id]?.expiration_date
                                                : null;
                                            const id = date ? item[header.id]?.id : null;
                                            return (
                                                <td
                                                    id=""
                                                    onContextMenu={e => handleRightClck(e, id)}
                                                    className="header-text"
                                                    style={{
                                                        backgroundColor: date ? colorCombination(date)?.color : '',
                                                        color: date ? 'black' : '',
                                                    }}>
                                                    {date}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </table>
                    ) : (
                        <div className='d-flex w-100 justify-content-center mt-4'>
                            <h4 style={{ fontSize: '1rem' }}>No data found</h4>
                        </div>
                    )}
                </div>)}
            </>

            {showMenu && seletedId && (
                <div
                    style={{
                        top: yPos,
                        left: xPos,
                        width: '250px',
                        border: '2px solid gray',
                    }}
                    class="dropdown-menu dropdown-menu-right show">
                    <button
                        class="dropdown-item d-flex justify-content-center"
                        type="button"
                        onClick={() => handleUpdateHrTask()}>
                        {/* <MdEdit />   */}
                        <span>Update</span>
                    </button>
                </div>
            )}
            {
                <UpdateModal
                    fullBind={fullBind}
                    loadingModal={false}
                    choices={choices}
                    values={values}
                    setValues={setValues}
                    useInput={useInput}
                    updateModalValue={modalValue}
                    seletedId={seletedId}
                    setUpdateModalValue={setModalValue}
                    getSkillMatrixListData={() => { }}
                    toast={toast}
                    readOnly={false}
                />
            }
        </div>
    );
}

export default TeamSkillMatrix;
