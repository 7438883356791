import {
    LARGE_COL,
    SMALL_COL,
    MEDIUM_COL,EX_SMALL_COL, EX_LARGE_COL
  } from 'shared/styles/constants/columns';
  

  export const headerColumns = [
    {
      label: 'Date',
      name: 'pay_adjustment_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Adjustment Type',
      name: 'pay_adjustment_type__name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
  
    {
      label: 'Pay Adjustment',
      name: 'pay_adjustment__name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Pay Adjustment Description',
      name: 'pay_adjustment_desc',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: 'Amount',
      name: 'amount',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Status',
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    // {
    //   label: '',
    //   name: '',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    
  ];
  