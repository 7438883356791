import React, { useEffect, useState, useRef, useContext } from 'react';
import Dropdown from 'components/SharedComponents/Dropdown';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { ESV, ESV_VISITS, EVV, EVV_VISITS } from 'utils/constants/routes';
import { LARGE_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useLocation } from 'react-router-dom'
import { getAdmin, getSuperAdmin, getStaff } from "../../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  PublishButton
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { DOWNLOAD } from 'utils/constants/icons';

import PropTypes from 'prop-types';
import { getEvvVisits, getEvvExport, getESvExport } from 'utils/api/EvvApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import { ItemContainer, MainContainer } from 'components/SharedComponents/CustomProgressBar/styles';
import { RightContainer } from 'components/SharedComponents/ProgressInformation/styles';
import { PURPLE } from 'shared/styles/constants/colors';
import { calender_esv_shift } from 'utils/choiceConstant';


const EvvVisits = ({
  loadEvvs,
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  choices,
  clients,
  employees,
  eventClickHandler,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  runRouteData,
  branch,
  setFilterBranch,
  setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  choicesVisitStatus,
  setFiltersetVisitStatus,
  visitStatusFilter,
  shiftTypeFilter,
  setFiltersetShiftType,
  visitStatus,
  loadingModal,
  employeeFilter,
  setFiltersetEmployee
}) => {

  const columns_values = [
    //    {
    //   label: 'Visit #',
    //   name: 'visit',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    // {
    //   label: 'Client Name',
    //   name: 'client__full_name',
    //   status: 1,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    {
      label: 'Employee Name',
      name: 'employee__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Start date',
      name: 'start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'End date',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Start time',
      name: 'start_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'End time',
      name: 'end_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    // {
    //   label: 'Duration',
    //   name: 'duration',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    {
      label: 'Shift Type',
      name: 'shift_type',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in',
      name: 'actual_start_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out',
      name: 'actual_end_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Duration(Clocked)',
      name: 'actual_visit_duration',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Shift status',
      name: 'shift_status',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },

  ];


  //added  4 datas  of visit to dispalyed -usestate below 
  const [numVisits, setNumVisits] = useState(0);
  const [sumVisitDuration, setSumVisitDuration] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numEmployees, setNumEmployees] = useState(0);
  // const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadDisable, setDownloadDisable] = useState(false)
  const {contextChoices, handleGetChoices, setContextChoices,contextIsAdmin,contextIsSuperAdmin,contextIsStaff} = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const history = useHistory();

  const mountedRef = useRef(true);

  let location = useLocation()
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterStaff = contextIsStaff;

  useEffect(() => {
    handleGetChoices(calender_esv_shift);
  }, []);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };


  useEffect(() => {
    if (values.client_id || values.employee_id || values.visit_no || values.shift_status || values.visit_priority_id || values.runroutes || values.branch || values.visitstatus || values.date_from || values.date_to || location.search || values.ordering || values.shiftType) {
      const obj_visits = {
        date_from: values.date_from,
        date_to: values.date_to,
        client_id: values.client_id,
        employee_id: values.employee_id,
        shift_status_id: values.shift_status,
        visit_priority_id: values.visit_priority_id,
        visit_no: values.visit_no,
        start_date: values.start_date,
        end_date: values.end_date,
        runroutes: values.runroutes,
        branch: values.branch,
        visitstatus: values.visitstatus,
      };
      const pageToSearch = getPageToSearchParam(location.search);
      loadEvvs({ ...branchData, ...obj_visits, ...pageToSearch }, ESV + ESV_VISITS, values.limitperpage);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.date_from,
    values.date_to,
    values.client_id,
    values.employee_id,
    values.visit_no,
    values.shift_status,
    values.visit_priority_id,
    values.runroutes,
    values.branch,
    branchData,
    values.ordering,
    values.limitperpage,
    values.visitstatus,
    values.shiftType,
    location.search,
  ]);


  // useEffect( ()=>{
  //   // const urlParams = queryString.parse(location.search);
  //   // urlParams[PAGINATION_PARAM] = 1;
  //   // const stringified = queryString.stringify(urlParams);
  //   // history.push({
  //   //   pathname: "/evv/visits",
  //   //   search: stringified,
  //   // });
  //   if(values.client_id || values.employee_id ||   values.visit_no || values.shift_status || values.visit_priority_id || values.runroutes || values.visitstatus || values.date_from || values.date_to ||  location.search   || values.ordering){ 
  //   displayData();
  //   }
  // },[
  //   values.date_from,
  //   values.date_to,
  //   values.client_id,
  //   values.employee_id,
  //   values.visit_no,
  //   values.shift_status,
  //   values.visit_priority_id,
  //   values.branch,
  //   branchData,
  //   values.runroutes,
  //   values.visitstatus,
  //   location.search,
  //   values.ordering,
  // ])

  // useEffect(()=>{

  //   displayData();

  //   const obj_visits = {
  //     date_from: values.date_from,
  //     date_to: values.date_to,
  //     client_id: values.client_id,
  //     employee_id: values.employee_id,
  //     shift_status_id: values.shift_status,
  //     visit_priority_id: values.visit_priority_id,
  //     visit_no: values.visit_no,
  //     start_date: values.start_date,
  //     end_date: values.end_date,
  //     runroutes: values.runroutes,
  //     branch: values.branch,
  //     visitstatus: values.visitstatus,
  //   };
  //   const pageToSearch = getPageToSearchParam(location.search);

  //   loadEvvs({...branchData,...obj_visits,...pageToSearch}, EVV + EVV_VISITS,values.limitperpage);


  // },[ location.search,values.ordering,])

  const displayData = () => {
    const pageToSearch = getPageToSearchParam(location.search);
    getEvvVisits({ ...branchData, ...values, ...pageToSearch }, PAGE_SIZE_RENDER).then(response => {
      //  if (!mountedRef.current) return null;
      setNumVisits(Math.ceil(response.no_of_visit));
      setSumVisitDuration(Math.round((response.visit_duration + Number.EPSILON) * 100) / 100);
      setNumClients(Math.ceil(response.no_of_client));
      setNumEmployees(Math.ceil(response.no_of_employee));
      //  setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    });
  };


  const handleDownload = () => {
    let fileName = "Esv_Shift_" + values.date_from + "_" + values.date_to
    setDownloadDisable(true)
    getESvExport(values.date_from, values.date_to,'Shifts').then(response => {
      response.blob().then(blob => {
        console.log(blob, "response")
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        console.log(url);
        a.href = url;
        a.download = fileName;
        a.click();
        setDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        setDownloadDisable(false)
      })
  }

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/esv/visits",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }


  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>

              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                {/* {((filterAdmin || filterSuperAdmin || filterStaff) || filterClient==='null') &&
                <DropdownTypesContainer className="ml-1 mr-1">
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Client name'}
                    options={clients?clients:[]}
                    selectedOption={values.client_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        client_id: value ? value : null,
                      })
                    }
                  />
                </DropdownTypesContainer>} */}

                {((filterAdmin || filterSuperAdmin)) &&
                  <DropdownTypesContainer className="ml-1 mr-1">
                    <NoSelectAllDropdownFilter
                      items={employees ? employees : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee_id: items });
                      }}
                      itemsSelected={employeeFilter}
                      setSelectedFilter={setFiltersetEmployee}
                      placeHolder={'Employee name'}
                      isObject
                      allOption

                    />
                  </DropdownTypesContainer>
                }
              </FiltersLeftGroup>

              {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-1">
                  <Dropdown
                    items={[]}
                    onSelect={item => {
                      dropDownHandleSelect({shift_status_id: item});
                    }}
                    placeHolder={'Visit Status'}
                    appendItem=""
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}

              {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ml-1 mr-2">
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Visit priority'}
                    options={choices.visit_priority?choices.visit_priority:[]}
                    selectedOption={values.visit_priority_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        visit_priority_id: value ? value : null,
                      })
                    }
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    typeFilter={true}
                    label={'Visit #'}
                    {...useInput('visit_no')}
                    onKeyPress={KeyPress.INTEGER}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}
              {/* added filters in runroute + branch  */}
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                {/* <DropdownTypesContainer className="ml-1 mr-2">
                  <NoSelectAllDropdownFilter
                    items={branch?branch:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
   
                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={visitStatus ? visitStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visitstatus: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={setFiltersetVisitStatus}
                    placeHolder={'Shift status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      contextChoices && contextChoices.regular_shift_type
                        ? contextChoices.regular_shift_type
                        : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({ shiftType: items });
                    }}
                    itemsSelected={shiftTypeFilter}
                    setSelectedFilter={setFiltersetShiftType}
                    placeHolder={'Shift Type'}
                    isObject
                    allOption
                  // hitApiAfterClear={hitApiAfterClear}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>

            </div>
          </FiltersContainer>
        </div>
       
        <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">


<PublishButton className=" ml-auto pl-2 mr-4" disable={downloadDisable} onClick={!downloadDisable && handleDownload}>
  {DOWNLOAD}
</PublishButton>
</DataContainer>
      </HeaderVisits>
     
      {/* visit data dispalyed below  */}
      {/* <div>
          <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start" >
        <div className="d-flex align-items-center mr-2">
        </div>
        <div className="data-container">
          <div className="mr-2">
            <p>{'Visits: ' +   numVisits }</p>
          </div>
        </div>

        <div className="d-flex align-items-center mr-2 pl-2" >
        </div>
        <div className="data-container">
          <div className="mr-2">
            <p>{' Visit (hrs) : ' +  sumVisitDuration   }</p>
          </div>
        </div>

        <div className="d-flex align-items-center mr-2 pl-2">
        </div>
        <div className="data-container">
          <div className="mr-2">
            <p>{'Clients: ' +   numClients  }</p>
          </div>
        </div>

        <div className="d-flex align-items-center mr-2  pl-2">
        </div>
        <div className="data-container">
          <div className="mr-2">
            <p>{' Employees: ' +   numEmployees }</p>
          </div>
        </div>
        <PublishButton  className=" ml-auto pl-2 mr-4" disable={downloadDisable}  onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
        </PublishButton>
</DataContainer>

          </div> */}
        

      <div className={'mt-5'}>
        <>    <ComponentDynamic loading={loadingModal} >


          <Table
            headerColumns={columns_values}
            headerPadding={true}
            noEditOption
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}

          >
            <div>
              {events && events.map((evvVisit, index) => {

                const dataOfSchedularDuration = (evvVisit.duration) * 60;
                const schedularDuration = dataOfSchedularDuration ? moment.utc(moment.duration(dataOfSchedularDuration, "seconds").asMilliseconds()).format("HH:mm") : ' ';

                const dataOfActualDuration = (evvVisit.actual_visit_duration) * 60;
                // const actualDuration = dataOfActualDuration ? moment.utc(moment.duration(dataOfActualDuration, "seconds").asMilliseconds()).format("HH:mm")   : ' ';   

                // seconds = Number(dataOfActualDuration);
                var d = Math.floor(dataOfActualDuration / (3600 * 24));
                var h = Math.floor(dataOfActualDuration % (3600 * 24) / 3600);
                var m = Math.floor(dataOfActualDuration % 3600 / 60);
                // var s = Math.floor(dataOfActualDuration % 60);

                var dDisplay = d > 0 ? d + (d == 1 ? "d" : "d") : "";
                var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
                var mDisplay = m > 0 ? m + (m == 1 ? "m" : "m") : "";
                // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                const actualDuration = dDisplay + hDisplay + mDisplay;

                const actualStartTime = evvVisit.actual_shift_start_time?.split('T')[1]
                  ? moment(evvVisit.actual_shift_start_time?.split('T')[1], 'HH:mm:ss').format('HH:mm')
                  : ' ';

                const actualEndTime = evvVisit.actual_shift_end_time?.split('T')[1]
                  ? moment(evvVisit.actual_shift_end_time?.split('T')[1], 'HH:mm:ss').format('HH:mm')
                  : ' ';

                const schedule_start_Date = evvVisit.start_date
                  ? moment(evvVisit.start_date).format('YYYY-MM-DD')
                  : ' ';

                const schedule_end_date = evvVisit.end_date
                  ? moment(evvVisit.end_date).format('YYYY-MM-DD')
                  : ' ';

                const schedule_start_time = evvVisit.start_date
                  ? moment(evvVisit.start_date).format('HH:mm')
                  : ' ';

                const schedule_end_time = evvVisit.end_date
                  ? moment(evvVisit.end_date).format('HH:mm')
                  : ' ';


                return (
                  <div key={index}>
                    <Row className="mb-1" bgColor>
                      <ColsGrouper
                        className="d-flex"
                      //onClick={()=>eventClickHandler(evvVisit)}
                      >
                        {/* <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvVisit && evvVisit.visit_number}
                        </Col> */}
                        {/* <Col className="sm-hidden" max_width={LARGE_COL} Left    overlap={true}>
                        {evvVisit.client_obj && evvVisit.client_obj.full_name}
                        </Col> */}
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvVisit.employee && evvVisit.employee}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_start_Date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_end_date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_start_time}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_end_time}
                        </Col>
                        {/* <Col className="sm-hidden" max_width={LARGE_COL} Center  overlap={true}>
                        {schedularDuration}
                        </Col> */}
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvVisit.shift_type}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {actualStartTime}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {actualEndTime}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvVisit.actual_shift_duration && ((evvVisit.actual_shift_duration === 'None') ? '' : evvVisit.actual_shift_duration)}
                          {/* clock duration */}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {
                            (choicesVisitStatus?.find(w => w.id === evvVisit.shift_status)) && (choicesVisitStatus?.find(w => w.id === evvVisit.shift_status))?.name
                          }
                        </Col>
                      </ColsGrouper>
                    </Row>
                  </div>
                )
              }
              )}
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER}
                  dataLength={events.length}
                />
              </div>
            </div>
          </Table>
        </ComponentDynamic></>
      </div>
    </div>
  );
};

EvvVisits.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvVisits;
