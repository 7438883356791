import React, { useState, useRef,useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { useForm } from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  Title
} from 'shared/styles/constants/tagsStyles';
import {
  ModalContainer,
} from  './style';
import moment from 'moment';      
import {getAbsenceList, getDetails, deleteAbsenceDetail} from 'utils/api/EmployeeApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {EX_LARGE_COL, LARGE_COL, SMALL_COL, MEDIUM_COL,EX_SMALL_COL4} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper
} from 'components/SharedComponents/Table/styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Table from 'components/SharedComponents/Table';
import {RiDeleteBinLine} from 'react-icons/ri';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {useModal} from 'hooks/ModalHook';
import AbsenceApproval from './AbsenceApproval';
import Spinner from 'components/SharedComponents/Spinner';




const HistoryModal = ({
  fullBind,
  Id,
  readOnly=true,
  props,
  location,
  dataIndex,
  absenceListData,
  deletePermission,
  contextChoices
}) => {
  const { values, setValues, useInput, isValid, errors,setFormErrors, setCheckRequires } =
    useForm({absence_detail_ids: []});


  const [loadingModal, setLoadingModal] = useState(true);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [absenceList, setAbsenceList] = useState([]);
  const [absenceDetailList, setAbsenceDeytailList] = useState([]);
  const [intialsOfFirstName,setIntialsOfFirstName]=useState( )
const [intialsOfLastName,setIntialsOfLastName]=useState( )
  const mountedRef = useRef(true);

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
    const {
      setValue: setAbsenceModalValue,
      value: absenceModalValue,
      setTitle: absenceTitle,
      fullBind: absenceBind,
    } = useModal(false);
  
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  // const headerColumns = [
  //   deletePermission && {
  //       label: 1,
  //       checkbox:true,
  //       name: 'checkbox',
  //       status: 0,
  //       className: 'grow-mobile',
  //       max_width: EX_SMALL_COL4,
  //     },
  //     {
  //       label: 'START DATE',
  //       name: 'start_date',
  //       status: 0,
  //       className: 'grow-mobile  sm-hidden',
  //       max_width: EX_LARGE_COL,
  //     },
  //     {
  //       label: 'END DATE',
  //       name: 'end_date',
  //       status: 0,
  //       className: 'grow-mobile sm-hidden',
  //       max_width: EX_LARGE_COL,
  //     },
  //   ];

  const getData=()=>{
    getDetails(Id)
    .then((res)=>{
      if(res.status){
        setAbsenceDeytailList(res.data);
      }
    })
  }

useEffect(() => {
  getData();
    setLoadingModal(false);
},[Id]);

useEffect(() => {
  searchAbscence()
},[ ]);

const searchAbscence = () => {
    setAbsenceList(absenceListData[dataIndex])
    const  name = (absenceListData && absenceListData[dataIndex] && absenceListData[dataIndex].employee_name) ? absenceListData[dataIndex].employee_name : '';
const  arrName = name.split(" ");
const  initialOfFirstName =  name.charAt(0).toUpperCase();
const initialOfLastName =  arrName[arrName.length - 1].charAt(0).toUpperCase();
setIntialsOfFirstName(initialOfFirstName)
    setIntialsOfLastName(initialOfLastName)
};

const handleSelectChange = (valcheck, visit, selectedIndex) => {
  if (!values.absence_detail_ids) {
    values.absence_detail_ids = [];
  }
  if (valcheck.target.checked) {
    values.absence_detail_ids.push(visit.id)
  } else {
    values.absence_detail_ids.map((item, index) => {
      if (item === visit.id) {
        values.absence_detail_ids.splice(index, 1);
      }
    })
  }
  setValues({ ...values })
}

const handleCheck = (visit) => {
  let check = false;
  if (values.absence_detail_ids) {
    values.absence_detail_ids.map((item) => {
      if (visit.id === item) {
        check = true
      }
    })
  }
  return check;
}

const deleteDeatils=()=>{
  deleteAbsenceDetail(values.absence_detail_ids)
  .then((res)=>{
    if(res.status === 'True'){
      setModalDeleteValue(false);
      setValues({...values, absence_detail_ids: []});
      getData();

    }
  })
}

const deleteClick=(id)=>{
  if(values.absence_detail_ids && values.absence_detail_ids.length === 0){
    values.absence_detail_ids = [id];
  setValues({...values});
  setModalDeleteValue(true);
  }
}

const handleAllSelectChange = (valcheck) => {
  if (!values.absence_detail_ids) {
    values.absence_detail_ids = [];
  }
  if (valcheck.target.checked) {
    absenceDetailList.map((visit)=>{
      values.absence_detail_ids.push(visit.id);
    })
  } else {
    absenceDetailList.map((visit)=>{
      values.absence_detail_ids.map((item, index) => {
        if (item === visit.id) {
          values.absence_detail_ids.splice(index, 1);
        }
      })
    })
  }
  setValues({ ...values })
}

const handleAllCheck = () => {
  let checkAll = true;

  const visitExists=(id)=>{
    return values.absence_detail_ids.some(function(el) {
      return el === id;
    });
  }
  if (values.absence_detail_ids && values.absence_detail_ids.length > 0) {
    absenceDetailList.map((visit)=>{
        if(!visitExists(visit.id)){
          checkAll = false
        }
      })
  }else{
    checkAll = false;
  }
  return checkAll;
}
const getUpdatedBy = (id) => {
  if(id){
   let match = contextChoices && contextChoices?.user_list && contextChoices?.user_list?.find(el => el.id === id)
   return match && match?.name
  }
}


console.log(absenceList,absenceDetailList,"absenceList")
  return (
    <>
<ModalContainer max_width={'60%'}>

      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        showRowInfo
        minWidthTitle="8.5rem"
        name={absenceList.employee_name || intialsOfFirstName}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: 'Employee Name :',
            value: absenceList.employee_name,
          },
        ]}
        loading={false}>
        <div className={'pl-3 pr-3 pb-3'}
        >
          <FlexGrid3Container>
      
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Type'}
                type="text"
                value={absenceList.absence_type || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Reason'}
                type="text"
                value={absenceList.absence_reason || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
              <InputLabelTop
                disabled={readOnly}
                label={'Sickness Reason'}
                type="text"
                value={absenceList.sickness_reason || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Paid / Unpaid'}
                type="text"
                value={absenceList.absence_paid || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Planned / Unplanned'}
                type="text"
                value={absenceList.absence_planned || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Request Date'}
                type="text"
                value={absenceList.created_at && absenceList.created_at?.split("T")[0]  || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Updated By'}
                type="text"
                value={absenceList?.absence_updated_by && getUpdatedBy(absenceList?.absence_updated_by)}
              />
            </FlexGrid3Item>
         
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Informed Method'}
                type="text"
                value={absenceList.absence_informed_method || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Start Date'}
                type="text"
                value={absenceList?.start_date && absenceList?.start_date?.split("T")[0]}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Start Time'}
                type="text"
                // 
                // value={absenceList.start_time || ''}
                value={absenceList?.start_date && absenceList?.start_date?.split("T")[1]?.slice(0,5)}
                
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence End Date'}
                type="text"
                value={absenceList.end_date && absenceList.end_date.split("T")[0]}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence End Time'}
                type="text"
                // value={absenceList.end_time || ''}
                value={absenceList.end_date && absenceList.end_date.split("T")[1]?.slice(0,5)}   
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Duration (Hours).'}
                type="text"
                value={absenceList?.absence_hours ? absenceList.absence_hours : "0" }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Absence Duration (Days)'}
                type="text"
                value={absenceList.absence_days || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Unavailable / Available Notes'}
                type="text"
                value={absenceList.absence_notes || ''}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>
        <div className={'pl-3 pr-3 pb-3'}>
       
        
        
            {/* {absenceDetailList && absenceDetailList.length > 0 ?
              <>
                <div className={'pb-2'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Title>Absence  Dates</Title>
                  {values.absence_detail_ids && (values.absence_detail_ids.length > 0) &&
                    <div class="dropdown">
                      <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className={'mr-2'}>Action</span>
                      </PrimaryButtonForm>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" onClick={() => setModalDeleteValue(true)}>Delete</a>
                        <a class="dropdown-item" onClick={() => { setAbsenceModalValue(true) }}>Absence Approval</a>
                      </div>
                    </div>}
                </div>
                <Table
                  headerColumns={headerColumns}
                  headerPadding={false}
                  noEditOption
                  fontSize="0.75rem"
                  callBackQuerySort={setQuerySort}
                  handleAllSelectChange={handleAllSelectChange}
                  handleAllCheck={handleAllCheck}
                // statusNumber={numberValue}   
                // setStatusNumber={changeNumber}   
                //   fullNameCol={fullNameCol}
                >

                  <div>
                    {absenceDetailList && absenceDetailList.map((list, index) => {
                      return (
                        <div key={index}>
                          <Row bgColor Shadow>
                            <ColsGrouper
                              className="d-flex">
                              {deletePermission && <Col
                                max_width={EX_SMALL_COL4}
                                Shrink={false}
                                NoFlexGrow={true}
                                reducePadding
                                Capitalize
                                Center

                              >
                                <input class="form-check-input" type="checkbox" checked={handleCheck(list)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, list, index) }} />

                              </Col>}
                              <Col
                                max_width={EX_LARGE_COL}
                                Shrink
                                reducePadding
                                Capitalize
                                className="sm-hidden"
                                overlap={true}
                                Center>
                                {
                                  list && moment(list.start_date).format('YYYY-MM-DD')
                                }
                              </Col>
                              <Col
                                max_width={EX_LARGE_COL}
                                Shrink
                                reducePadding
                                Capitalize
                                className="sm-hidden"
                                overlap={true}
                                Center>
                                {
                                  list && moment(list.end_date).format('YYYY-MM-DD')
                                }
                              </Col>
                              <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                {deletePermission && <IconWrapper
                                  onClick={() => {
                                    deleteClick(list.id);
                                  }}
                                >
                                  <RiDeleteBinLine />
                                </IconWrapper>}
                              </Col>
                            </ColsGrouper>
                          </Row>
                          <div className="mb-1" />
                        </div>
                      );
                    })}
                  </div>

                </Table>
              </>
              :
              <div className='mt-4 w-100 d-flex align-item-center justify-content-center'>
                <h4>Loading...</h4>
              </div>
            } */}
      </div>

          {absenceModalValue && (
            <AbsenceApproval
              resetSelectedIds={ () => setValues({...values, absence_detail_ids: []})}
              setTitle={absenceTitle}
              fullBind={absenceBind}
              setAbsenceModalValue={setAbsenceModalValue}
              idSelected={values.absence_detail_ids}
              absenceListData={absenceList}
              search={getData}
            />)}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this ? \n DELETING the data will remove ALL records from database.'
        }
        onOk={deleteDeatils}
        onCancel={() => {}}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

      </ModalDynamic>
    </ModalContainer>

    </>
  );
};

export default HistoryModal;
