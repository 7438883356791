import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import React from 'react'
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';

function AddForm({
    useInput,
    readOnly,
    choices,
    values,
    index,
    setValues,
    PRIVACY,
    errors,
    setFormErrors,
    toast
}) {


    const dropDownHandleSelect = (item, nameSection, index) => {
        if (nameSection === 'privacy') {
            values[PRIVACY][index][nameSection] = item[nameSection].map(y => y.id)
        } else {
            values[PRIVACY][index][nameSection] = item[nameSection]
        }
        setValues({ ...values });
    };



    const deletePrivacy = (values, index) => {
        const err = errors && errors.filter(el => el !== `privacy[${index}].user_type` && el !== `privacy[${index}].privacy`)
        setFormErrors(err || [])
        values[PRIVACY].splice(index, 1)
        setValues({ ...values })
    }



    const userType = [
        { id: 'client', name: 'Client' },
        { id: 'employee', name: 'Employee' },
        { id: 'important contact', name: 'Important contact' },
        { id: 'location', name: 'Location' }
    ]

    const handleUpdate = (index) => {
        let final = userType;
        for (let i = 0; i < userType.length; i++) {
            if (i !== index && values?.privacy[i]?.user_type !== undefined) {
                final = final.filter(el => (el.id !== values.privacy[i].user_type));
            }
        }

        return final;
    };


    return (
        <div>
            <div className="d-flex flex-column mt-2" style={{ marginLeft: '15px' }}>
                <div className="d-flex mb-2">
                    <CloseButtonWrapper
                        disabled={readOnly}
                        onClick={() => {
                            !readOnly && deletePrivacy(values, index);
                        }}>
                        {TIMES}
                    </CloseButtonWrapper>
                    <div
                        className="w-100 mr-2"
                        style={{ marginLeft: '8px', marginRight: '10px' }}>
                        <DropdownSearchable
                            required
                            {...useInput(`privacy[${index}].user_type`, 'isRequired')}
                            disabled={readOnly}
                            placeHolder={'User Type'}
                            options={handleUpdate(index) || []}
                            selectedOption={values[PRIVACY][index].user_type}
                            onChange={value =>
                                dropDownHandleSelect({
                                    user_type: value ? value : null,
                                },
                                    'user_type',
                                    index
                                )
                            }
                        />
                    </div>
                    <div
                        className="w-100 mr-2"
                        style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <DropdownSearchable
                            disabled={readOnly}
                            required
                            isMulti={true}
                            {...useInput(`privacy[${index}].privacy`, 'isRequired')}
                            placeHolder={'Privacy'}
                            options={choices?.alert_privacy?.map(e => ({ id: e.id, name: e.name })) || []}
                            selectedOptionsProps={
                                values[PRIVACY][index].privacy ? values[PRIVACY][index].privacy : null
                            }
                            onChange={value =>
                                dropDownHandleSelect(
                                    {
                                        privacy: value ? value : null,
                                    },
                                    'privacy',
                                    index
                                )
                            }
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddForm