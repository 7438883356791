import styled from 'styled-components';
import {
  PURPLE,
  GRAY_100,
  GRAY_200,
  ERR_COLOR,
  PURPLE_800,
} from 'shared/styles/constants/colors';
import {XS} from 'shared/styles/constants/resolutions';

export const Icon = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
`;

export const DurationStyled = styled.div`
  position: relative;

  label {
    top: 1%;
    font-size: 0.6em;
    font-weight: 600;
    cursor: text;
    position: absolute;
    left: 0;
    transition: all 0.15s ease;
    width: 100%;
    color: black;
    padding: 0.4em 1% 0 0.55rem;
    pointer-events: none;
    line-height: 1.1em;
    bottom: 1%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 1.3875rem;
    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  input.filled ~ label,
  input:focus ~ label {
    top: 1%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
  }

  input {
    width: 100%;
    height: 2.3994rem;
    padding-left: 0.55rem;
    padding-top: 0.5rem;
    padding-right: 1.6rem;
    font-size: 0.875rem;
    font-weight: 300;
    border: 1px solid ${PURPLE_800};
    background-color: ${GRAY_100};
    border-radius: 0.25rem;

    @media (max-width: ${XS}) {
      padding-left: 0.5rem;
      font-size: 0.7rem;
    }
  }
  input:focus {
    border: 1px solid ${PURPLE};
    outline: none;
    outline-color: unset;
  }
  input.error-border {
    border: 1px solid ${ERR_COLOR};
  }
  &.filled {
    input {
      border: 1px solid ${PURPLE_800};
    }
  }
`;
