import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {SetActualBulkTime, setPlannedVisitsAssign} from "utils/api/SchedulerApi"
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import moment from 'moment'
import DateSelector from 'components/SharedComponents/DateSelector';
const PlannerAssign = ({
    fullBind,
    searchScheduler,
    setPlannerSchAssign,
    branch,
    sch_values
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({start_date: '', end_date: ''});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

  useEffect(() => {
    if (sch_values) {
      values.start_date = moment(sch_values.start_date).format('YYYY-MM-DD')
      values.end_date = moment(sch_values.end_date).format('YYYY-MM-DD')
      setValues({ ...values })
    }
  }, [sch_values])

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Assign Planned Visits',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);
      const dropDownHandler=(item,nameItem)=>{
        values[nameItem] = item[nameItem];
        setValues({...values});
      }

      const handleTimeSelector=(e)=>{
        values.actual_visit_time= moment(e.target.value).format("HH:mm:ss")
        values.actual_time = moment(e.target.value).format("HH:mm")
        setValues({...values})
      }
      const handleSubmit=()=>{
       
        if(errors.length>0 || loadingSave || !values.assign_branch || (values.assign_branch.length < 1)){
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
        setLoadingSave(true)
          setErrorMessage('');

          const branch_data = values.assign_branch.map((item)=>item.id)
          let body={
            start_date: values.start_date,
            end_date: values.end_date,
            branch_ids: branch_data ? branch_data.join(','): []
        }
        setPlannedVisitsAssign(body)
        .then(res=>{
          if(!res.status){
            setSaveButtonIcon(CROSS);
            setErrorMessage(res.message);
            setTextStatus(ERROR);
            setLoadingSave(false);
          }else{
            setSaveButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            searchScheduler()
            setPlannerSchAssign(false)
          }
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          
        })

      }


    return (
        <ModalContainer max_width={'40%'}>
            <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
                      <div className={' pl-3 pr-3 pb-3'}>
    <div className="mb-2">
   
             <div className='d-flex'>
             <FlexGrid3Item className="pr-1 pb-2">
             <DateSelector
                                disabled={true}

                  labelTop="Start Date"
                  {...useInput(`start_date`)}
                />
           
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pb-2">
            <DateSelector
                                disabled={true}

                  labelTop="End Date"
                  {...useInput(`end_date`)}
                />
            </FlexGrid3Item>
             </div>
              <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                isMulti={true}
                required={true}
                placeHolder={'Branch'}
                options={branch ? branch : []}
                selectedOptionsProps={
                  values
                    ? values.assign_branch
                      ? values.assign_branch
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandler({ assign_branch: value },'assign_branch')
                }
              />
              </FlexGrid3Item>

    </div>
    <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
        </ModalDynamic>
        </ModalContainer>
    );
}

export default PlannerAssign;
