import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import {GlobalStyle} from './GlobalStyles';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {AUTH, DASHBOARDS, PAGE_404} from 'utils/constants/routes.js';
import DashboardRouter from 'components/DashboardComponents/DashboardRouter';
import Page404 from 'components/Page404';
import AuthRouter from 'components/AuthComponents/AuthRouter';
import {getUser} from 'utils/api/SessionApi';
import 'bootstrap/dist/css/bootstrap.css';
import {getOrgname, getUserId} from './utils/localStorage/user';
 

Date.prototype.getUTCString = function () {
  let mm = this.getUTCMonth() + 1; // getMonth() is zero-based
  let dd = this.getUTCDate();
  let hh = this.getUTCHours();
  let min = this.getUTCMinutes();
  let ss = this.getUTCSeconds();

  return (
    [
      this.getUTCFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
    ].join('/') +
    'T' +
    [
      (hh > 9 ? '' : '0') + hh,
      (min > 9 ? '' : '0') + min,
      (ss > 9 ? '' : '0') + ss,
    ].join(':') +
    '+0000'
  );
};

const isAuthed = async () => {
  const userId = getUserId();
  // if we don't have username in storage then return false and don't call backend
  if (!userId) {
    return false;
  }
  const org = getOrgname()
 try {
    const user = await getUser(userId, org);
    const days = user?.user?.passwordExpiryDaysLeft ?? null;
    localStorage.setItem("password_expiry_left", days)
    return user;
 } catch (error) {
  console.error("Error fetching user details",error)
 }
}


const PrivateRoute = ({component: Component, ...rest}) => {
  const [signed, setSigned] = useState(-1);
  useEffect(() => {
    isAuthed().then(signed => {
      setSigned(signed ? true : false);
     })
      
   
  }, []);

  if (signed === -1) {
    return null;
  }

  return (

    <Route
      {...rest}
      render={props =>
        signed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: AUTH,
            }}
          />
       
        )
      }
    />
  );
};

const PublicRoute = ({component: Component, ...rest}) => {
  const [isSigned, setIsSigned] = useState(-1);

  useEffect(() => {
    isAuthed().then(signed => {
      setIsSigned(signed ? true : false);
    });
  }, []);

  
  if (isSigned === -1) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isSigned ? (
          <Redirect
            to={{
              pathname: DASHBOARDS,
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

ReactDOM.render(
  <Fragment>
    <GlobalStyle />
    <BrowserRouter>
      <Switch>
        <Route path={PAGE_404} component={Page404} />
        <PublicRoute path={AUTH} component={AuthRouter} />
        <PrivateRoute path={DASHBOARDS} component={DashboardRouter} />
      </Switch>
    </BrowserRouter>
  </Fragment>,
  document.getElementById('root')
);

serviceWorker.unregister();
