import React, { useState, useEffect, useContext } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { AppContext, BranchItemsContext } from 'context';

import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  Title,
  HeaderVisits,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL, EX_LARGE_COL, EX_SMALL_COL, EX_VERY_LARGE_COL
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { Row, Col, ColsGrouper } from 'components/SharedComponents/Table/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import NavigationIcon from '@mui/icons-material/Navigation';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
// import { values } from 'lodash';
import { getChoices } from 'utils/api/CoreApi';
import { PURPLE, PURPLE_GRAY_500 } from 'shared/styles/constants/colors';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import CareNotes from './CareNotes';
const EventModal = ({
  readOnly,
  fullBind,
  eventValues,
  choices,
  serviceCategoryData,
  visitType,
  values,
  eventData,
}) => {
  fullBind.title = 'Evv Detail';
  console.log('evvdata', eventData)
  //   const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);
  // const [nokPhoto, setNokPhoto] = useState(null);
  // const [initials, setInitials] = useState();
  const [intialsOfFirstName, setIntialsOfFirstName] = useState();
  const [intialsOfLastName, setIntialsOfLastName] = useState();
  const [serviceName, setServiceName] = useState();
  const [visitTypeName, setVisitTypeName] = useState();
  const [temData, setTempData] = useState();
  const { contextChoices, contextChoicesMap } = useContext(AppContext);
  const [HideNotes, setHideNotes] = useState(true);
  const [locationName, setLocationName] = useState("");
  const [onboardingDate, setOnboardingDate] = useState("")

  const headerColumns = [
    {
      label: 'Clocked Date',
      name: 'clock_in_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clocked Time',
      name: 'clock_in_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clocked Location',
      name: 'clock_out_time',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: 'Action',
      name: 'clock_out_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },

  ];

  const handleAccordionClick = () => {
    setHideNotes(!HideNotes);
  }


  useEffect(() => {
    if (contextChoices.clock_out_reason) {
      setTempData(
        contextChoices.clock_out_reason.find(
          item => (item.id = eventData.clock_out_reason)
        )
      );
    }
  }, [contextChoices.clock_out_reason]);
  useEffect(() => {
    data();
  }, [eventValues.full_name]);

  const data = () => {
    let name = ""
    if (eventValues.location_name) {
      name = eventValues.location_name
      setLocationName(name)
      setOnboardingDate(eventValues.on_boarding_date)
    }
    if (eventValues.full_name) {
      name = eventValues.full_name;
    }

    const initialOfFirstName = name.charAt(0).toUpperCase();

    if (name && name.includes(" ")) {
      const arrName = name.split(' ');
      const initialOfLastName = arrName[arrName.length - 1]
        .charAt(0)
        .toUpperCase();
      setIntialsOfLastName(initialOfLastName);
    }
    setIntialsOfFirstName(initialOfFirstName);


    const dataName = serviceCategoryData?.find((u, i) => {
      if (u.id == eventValues.service_category) {
        return u.name;
      }
    });
    //  serviceName = dataName.filter(p=>p.name)
    dataName && setServiceName(dataName.name);

    const visitTypeData = visitType?.map(w => {
      return {
        id: w.id,
        name: w.name,
      };
    })
      .find((u, i) => {
        if (u.id == eventValues.visit_type) {
          return u.name;
        }
      });

    visitTypeData && setVisitTypeName(visitTypeData.name);
  };

  const addVisitPriorityName = () => {
    if (
      choices &&
      choices.visit_priority &&
      choices.visit_priority.length >= 1 &&
      eventValues.visit_priority
    ) {
      return choices.visit_priority.find(
        word => word.id === eventValues.visit_priority
      ).name;
    } else {
      return null;
    }
  };

  // const  absenceEndDate = list.end_date
  // ? moment(list.end_date).format('DD-MM-YYYY')
  // : 'Unset';
  const actualStartDate = eventValues.actual_start_time
    ? moment(eventValues.actual_start_time).format('DD-MM-YYYY')
    : ' ';

  const actualEndDate = eventValues.actual_end_time
    ? moment(eventValues.actual_end_time).format('DD-MM-YYYY')
    : ' ';

  //  console.log(eventData?.clock_count, "EVENTDATA EVENT DATA EVENET DTAT", eventData?.clock_count?.length, eventData?.clock_count[eventData?.clock_count?.length - 1]?.actual_start_location?.split(" , "))

  {/*CLOCK-IN MAP HANDLER */ }
  const handleClockInMap = (x, y) => {
    // const url = `https://www.google.com/maps/search/?api=1&query=${eventData?.clock_count[eventData?.clock_count?.length - 1]?.actual_start_location?.split(" , ")[0]},${eventData?.clock_count[eventData?.clock_count?.length - 1]?.actual_start_location?.split(" , ")[1]}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${x},${y}`;
    window.open(url, '_blank')
  };

  {/* CLOCK-OUTMAP HANDLER */ }
  const handleClockOutMap = (x, y) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${x},${y}`;
    window.open(url, '_blank')
  };
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        showRowInfo
        minWidthTitle="8.5rem"
        photoRow={eventValues.photo}
        name={eventValues.full_name || intialsOfFirstName}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: locationName ? "Location :" : 'Name :',
            value: locationName ? locationName : eventValues.full_name,
          },
          {
            label: locationName ? "OBD: " : 'DOB:',
            value: locationName ?
              onboardingDate
                ? `${moment(onboardingDate).format('DD-MM-YYYY')} (${moment().diff(onboardingDate, 'years')})`
                : ''
              : eventValues.date_of_birth
                ? `${moment(eventValues.date_of_birth).format('DD-MM-YYYY')} (${moment().diff(eventValues.date_of_birth, 'years')})`
                : '',
          },
        ]}
        loading={false}>

        <div className={'pl-3 pr-3 pb-3'}>
          <FlexGrid3Container>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Client reference'}
                type="text"
                value={eventValues.client_reference || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Employee reference'}
                type="text"
                value={eventValues.employee_reference || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
              <InputLabelTop
                disabled={readOnly}
                label={'No. of Clients'}
                type="text"
                value={eventValues.no_of_clients || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Time code'}
                type="text"
                value={eventValues.time_code || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Service category'}
                type="text"
                value={serviceName}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Visit type'}
                type="text"
                value={visitTypeName}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Visit priority'}
                type="text"
                value={addVisitPriorityName() || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                // label={'Actual start date'}
                label={' Clocked Start Date'}
                type="text"
                value={actualStartDate || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked End Date'}
                // label={'Actual end date'}
                type="text"
                value={actualEndDate || ''}
              />
            </FlexGrid3Item>

            {/* newly fields added below */}
            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Clocked Start Time"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}
            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Clocked End Time"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Scheduled duration"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            {/* <FlexGrid3Item className="pr-1 mt-1 mb-1">
            <TimeSelector
              disabled={readOnly}
            labelTop="Scheduled duration"
              // {...useInput(` `)}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
            />
            </FlexGrid3Item> */}

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Time Difference'}
                type="text"
                value={eventValues.Clock_in_time_difference || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked Start Early / Late'}
                type="text"
                value={eventValues.Clocked_Start_Early_Late || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Time Difference'}
                type="text"
                value={eventValues.Clock_out_time_difference || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked End Early / Late'}
                type="text"
                value={eventValues.Clocked_End_Early_Late || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Scheduled Duration vs Clocked Duration'}
                type="text"
                value={eventValues.schedule_duration_clocked_duration_diff || ''}
              />
              {/* <TimeSelector
                disabled={readOnly}
                labelTop={'Scheduled Duration vs Clocked Duration'}
                value={eventValues.schedule_duration_clocked_duration_diff || ''}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              /> */}
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked visit duration(%)'}
                type="text"
                value={eventValues.prcntge_clocked_visit_duration || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked Visit Provision'}
                type="text"
                value={eventValues.Clocked_Visit_Provision || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked Time Confirmation'}
                type="text"
                value={eventValues.Clocked_Time_Confirmation || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Distance from Location'}
                type="text"
                value={eventValues.Clock_in_Distance_from_Location || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-in Distance Verification'}
                type="text"
                value={eventValues.Clock_in_Distance_Verification || ''}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Distance from Location'}
                type="text"
                value={eventValues.Clock_out_Distance_from_Location || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 ">
              <InputLabelTop
                disabled={readOnly}
                label={'Clock-out Distance Verification'}
                type="text"
                value={eventValues.Clock_out_Distance_Verification || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
              <InputLabelTop
                disabled={readOnly}
                label={'Clocked Distance Confirmation'}
                type="text"
                value={eventValues.Clocked_Distance_Confirmation || ''}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'} style={{ marginBottom: "-0rem" }}>
              <InputLabelTop
                disabled={readOnly}
                label={'Clock out reason'}
                type="text"
                value={contextChoicesMap?.clock_out_reason[eventData.clock_out_reason]?.name || ''}
              />
            </FlexGrid3Item>

          </FlexGrid3Container>
          <div className="w-100 mt-2" style={{ marginTop: "-0.5rem" }}>
            <small className="pr-1 " >
              Clock out notes
            </small>
            <InputTextArea
              height={'4.5rem'}

              disabled={readOnly}
              type="text"
              value={eventData.clock_out_notes || ''}
            />
          </div>
        </div>


        <AccordionContainer>
          <AccordionArrow style={{ marginLeft: '5px' }} onClick={handleAccordionClick}>
            <div>
              {HideNotes ? (
                <IoIosArrowUp size={18} color={PURPLE} />
              ) : (
                <IoIosArrowDown size={18} color={PURPLE} />
              )}
            </div>
          </AccordionArrow>
          <TitleSection>Visit Care Notes</TitleSection>
        </AccordionContainer>
        {!HideNotes &&
          <>
            <CareNotes visitId={eventData.visit_obj.id} />
          </>}
        {/* Time and location data*/}

        {eventValues.clock_count.length > 0 ? (
          <div>
            <div className={'ml-1 pl-1 pb-2 mt-1'}>
              <Title>Time and location data</Title>
            </div>

            <Table
              headerColumns={headerColumns}
              headerPadding={false}
              noEditOption
              fontSize="0.75rem"
            // style={{cursor:pointer}}
            // callBackQuerySort={setQuerySort}
            >
              <div>
                {eventValues &&
                  eventValues.clock_count &&
                  eventValues.clock_count.map((list, index) => {
                    const clockDate = list && list.actual_datetime?.slice(0, 10)
                    const clockTime = list && list.actual_datetime?.slice(11, 16)

                    return (
                      <div key={index}>
                        <Row
                          bgColor
                          Shadow
                          //  cursor={pointer}
                          NoShadow
                        // mb-1
                        >
                          <ColsGrouper className="d-flex">
                            <Col
                              max_width={LARGE_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              Center>
                              {clockDate}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              Center>
                              {clockTime}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              Center>
                              {list.actual_location &&

                                <div>
                                  < NavigationIcon style={{ color: 'black' }} onClick={() => { handleClockInMap(list.actual_location?.split(" , ")[0], list.actual_location?.split(" , ")[1]) }} />
                                  {list.actual_location}
                                </div>

                              }
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              Center>
                              {list.type}
                            </Col>




                          </ColsGrouper>
                        </Row>
                        <div className="pb-2 mb-2" />
                      </div>
                    );
                  })}
              </div>
            </Table>
          </div>
        ) : null
        }


        {/*  CLOCK-IN & CLOCK-OUT MAP ICON*/}
        {/* {
          eventData?.clock_count.length > 0 ?
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: "flex-end",
              }}>

              {
                eventData?.clock_count[eventData?.clock_count?.length - 1]?.actual_start_location &&
                <Box sx={{ '& > :not(style)': { m: 1 }, }}>
                  <Fab variant="extended" onClick={handleClockInMap} >
                    <NavigationIcon />
                    clock-in Map
                  </Fab>
                </Box>
              }

              {
                eventData?.clock_count[eventData?.clock_count?.length - 1]?.actual_end_location &&
                <Box sx={{ '& > :not(style)': { m: 1 }, }}>
                  <Fab variant="extended" onClick={handleClockOutMap} >
                    <NavigationIcon />
                    clock-out Map
                  </Fab>
                </Box>
              }
            </div> : null
        } */}



      </ModalDynamic >
    </ModalContainer >
  );
};

EventModal.propTypes = {
  readOnly: PropTypes.bool,
  fullBind: PropTypes.object.isRequired,
  eventValues: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
};

EventModal.defaultProps = {
  readOnly: false,
};

export default EventModal;