import styled from 'styled-components';
import {
  GRAY_100,
  GRAY_200,
  GRAY_700,
  PURPLE_800,
  DARK_VIOLET,
  PURPLE,
  WHITE,
  ERR_COLOR,
  GRAY_800,
} from 'shared/styles/constants/colors';
import {MD} from 'shared/styles/constants/resolutions';

export const Container = styled.div`
  width: ${props => props.width};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  position: relative;

  .Select--multi {
    .Select-control {
      .Select-multi-value-wrapper {
        padding-bottom: 0.3125rem;
      }
      .Select-multi-value-wrapper > .Select-value {
        margin-top: 0.625rem;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid ${PURPLE_800};
        color: ${DARK_VIOLET};
        .Select-value-icon:hover {
          background-color: rgba(128, 63, 255, 0.08);
          color: ${PURPLE};
        }
      }
    }
  }
  div {
    .Select-control {
      height: 2.4rem;
      border: 1px solid ${props => (props.data_error ? ERR_COLOR : PURPLE_800)};
      background-color: ${props => (props.typeFilter ? WHITE : GRAY_100)};
      border-radius: ${props => (props.typeFilter ? '15rem !important' : 'auto')};

      .Select-input {
        input {
          font-size: 0.875rem;
          font-weight: 300;
          padding: 0.75rem 0 0.5rem 0;
        }
      }
      span.Select-clear-zone {
        vertical-align: middle;
      }
      span.Select-clear-zone span {
        background-color: ${PURPLE};
        color: ${WHITE};

        border-radius: 50%;
        width: 1.0625rem;
        height: 1.0625rem;
        align-self: center;
        text-align: center;
        line-height: 1rem;
        font-size: 0.8rem;
      }
      .Select-value {
        /* padding-left: 0.55rem; */
      }
    }
    .Select-placeholder {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 2.375rem;
      padding-left: 0.55rem;
      color: ${GRAY_700};
      @media (max-width: ${MD}) {
        font-size: 0.75rem;
      }
    }
    .Select-value-label {
      font-size: 0.875rem;
      font-weight: 300;
      /* line-height: 2.375rem; */
      color: black;
      @media (max-width: ${MD}) {
        font-size: 0.75rem;
      }
    }
    .Select-menu-outer {
      z-index: 2;
    }

    .Select-menu-outer {
      .VirtualizedSelectOption {
        font-size: 0.875rem;
        word-break: keep-all;
        line-height: 0.8rem;
      }
    }
  }
  div.has-value {
    .Select-control {
      border: 1px solid ${PURPLE_800};
    }
  }
  div.is-focused:not(.is-open) > .Select-control {
    border-color: ${PURPLE};
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
`;

export const ErrorLabel = styled.div`
  font-size: 0.6rem;
  color: ${ERR_COLOR};
  font-weight: bold;
`;

export const FloatingLabel = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.typeFilter ? '0.7rem' : '0.8rem')};
  z-index: 1;
  font-size: 0.6rem;
  margin: 0;
  color: ${props => (props.typeFilter ? GRAY_800 : 'black')} !important;
  font-weight: 600;

  span {
    color: ${ERR_COLOR};
  }
`;
