import React from 'react';
import Template from 'components/SharedComponents/SettingsTemplate';
import { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from 'context';
import {
  CLIENT_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import {
  AccordionArrow,
  AccordionContainer,
  StaticArrow,
} from 'components/DashboardComponents/LeftSidebar/styles';
import {
  TitleSection,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import { PURPLE } from 'shared/styles/constants/colors';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import CustomTemplate from 'components/SharedComponents/CustomComponent/SettingsTemplate';
import { useModal } from 'hooks/ModalHook';

import {
  getMedConditions,
  setMedCondition,
  deleteMedCondition,
  setAllergySource,
  getAllergySource,
  deleteAllergySource,
  getHealthSafty,
  setHealthSafty,
  deleteHealthSafty,
  getFluVaccination,
  deleteFluVaccination,
  setFluVaccination,
  getDisability,
  deleteDisability,
  setDisability,
  getInfectionControl,
  getOrganDonation,
  getDNACPR,
  deleteDNACPR,
  setDNACPR,
  getVaccinationExemptionType,
  deleteVaccinationExemptionType,
  setVaccinationExemptionType,
  deleteInfectionControl,
  setInfectionControl,
  getSignSymptoms,
  deleteSignSymptoms,
  setSignSymptoms,
  setOrganDonation,
  deleteOrganDonation,
  getAgeBind,
  deleteAgeBind,
  setAgeBind,
  getRotaRag,
  deleteRotaRag,
  setRotaRag,
  getClientVra,
  deleteClientVra,
  setClientVra,
  getSupportLevel,
  deleteSupportLevel,
  setSupportLevel,
  getMedicationName,
  setMedicationName,
  deleteMedicationName,
  getMadicationDose,
  deleteMedicationDose,
  setMedicalDose,
  getMadicationType,
  deleteMedicationType,
  setMedicalType,
  deleteMedicationFrequency,
  setMedicalFrequency,
  getMadicationForm,
  deleteMedicationForm,
  setMedicalForm,
  getMadicationRoute,
  deleteMedicationRoute,
  setMedicalRoute,
  getMadicationStatus,
  deleteMedicationStatus,
  setMedicalStatus,
  getMadicationAlert,
  deleteMedicationAlert,
  setMedicalAlert,
  getMadicationAssistance,
  deleteMedicationAssistance,
  setMedicalAssistance,
  getAdministrationType,
  deleteAdministrationType,
  setAdministrationType,
  getAdministrationReason,
  deleteAdministrationReason,
  setAdministrationReason,
  getExpiryMedication,
  deleteExpiryMedication,
  setExpiryMedication,
  getMadicationFrequency,
} from 'utils/api/SettingsApi';
import {
  getFunderSource,
  deleteFunderSource,
  setFunderSource,
  getCareContract,
  deleteCareContract,
  setCareContract,
  getNok,
  deleteNok,
  setNok,
  getCancellation,
  deleteCancellation,
  setCancellation,
  getCareGroup,
  deleteCareGruop,
  setCareGroup,
  getPPE,
  deletePPE,
  setPPES,
  getCareLevel,
  deleteCareLevel,
  setCareLevel,
  getCareDependency,
  deleteCareDependency,
  setCareDependency,
  getImpairmentSensory,
  deleteImpairmentSensory,
  setImpairmentSensory,
  getServiceTypeDescription,
  deleteServiceTypeDescription,
  setServiceTypeDescription,
  getSpecificCommunication,
  deleteSpecificCommunication,
  setSpecificCommunication,
  getRegulatedServices,
  deleteRegulatedServices,
  setRegulatedServices,
  getUserType,
  deleteUserType,
  setUserType,
  getTepIn,
  deleteTepIn,
  setTepIn,
  getCareNeeds,
  deleteCareNeeds,
  setCareNeeds,
  getLivingCircumstances,
  deleteLivingCircumstances,
  setLivingCircumstances,
  getServiceType,
  deleteServiceType,
  setServiceType,
  getClientGroup,
  deleteClientGroup,
  setClientGroup,
  getClientMobility,
  deleteClientMobility,
  setClientMobility,
  getUserTypeDescription,
  deleteUserTypeDescription,
  setUserTypeDescription,
} from 'utils/api/SettingsApi';

import MedicationNameModal from 'components/SharedComponents/CustomComponent/SettingsTemplate/MedicationName';
import MedicationDelete from 'components/SharedComponents/CustomComponent/SettingsTemplate/MedicationDelete';
import { useScrollTrigger } from '@mui/material';
import { setting_client_health_choices } from 'utils/choiceConstant';
import { KeyPress } from 'utils/constants/regularExpressions';
import { SettingsTemplateContainer, SettingsGridContainer } from 'components/SharedComponents/SettingsTemplate/styles';

const Health = ({ handleChangeChoices }) => {
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  // const [medicalCondition ,setMedicalCondition]=useState(true)
  const [medicals, setMedicals] = useState(true);
  const [allergiesSource, setAllergiesSource] = useState(true);
  const [healthSafety, setHealthSaftys] = useState(true);
  const [fluVaccination, setFluVaccinations] = useState(true);
  const [disabilitys, setDisabilitys] = useState(true);
  const [infectionControl, setInfectionControls] = useState(true);
  const [organDonation, setOrganDonations] = useState(true);
  const [dNACPR, setDNACPRs] = useState(true);
  const [vaccinationExemption, setVaccinationExemption] = useState(true);
  const [signSymptoms, setSignSymptom] = useState(true);
  const [ageBanding, setAgeBanding] = useState(true);
  const [rotaRag, setRotaRags] = useState(true);
  const [clientVra, setClientVras] = useState(true);
  const [supportLevel, setSupportLevels] = useState(true);
  const [hideMedicationName, setHideMedicationName] = useState(true);
  const [hideFunder, setHideFunder] = useState(true);
  const [hideCareContract, setHideCareContract] = useState(true);
  const [hideNok, setHideNok] = useState(true);
  const [hideCancellation, setHideCancelation] = useState(true);
  const [hideCareGroup, setHideCareGroup] = useState(true);
  const [hidePPE, setHidePPE] = useState(true);
  const [HideCareLevel, setHideCareLevel] = useState(true);
  const [hideCareDependency, setHideCareDependency] = useState(true);
  const [hideImpairmentSensory, setHideImpairmentSensory] = useState(true);
  const [isCheckedId, setCheckedId] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [apiCalls, setApiCalls] = useState(false);

  const [hideServiceTypeDescription, sethideServiceTypeDescription] =
    useState(true);
  const [hideSpecificCommunication, setHideSpecificCommunication] =
    useState(true);
  const [hideRegulatedServices, setHideRegulatedServices] = useState(true);
  const [hideUserType, setHideUserType] = useState(true);
  const [hideTepIn, setHideTepIn] = useState(true);
  const [hideCareNeeds, setHideCareNeeds] = useState(true);
  const [hideLivingCircumstances, setHideLivingCircumstances] = useState(true);
  const [hideServiceType, setHideServiceType] = useState(true);
  const [hideClientGroup, setHideClientGroup] = useState(true);
  const [hideClientMobility, setHideClientMobility] = useState(true);
  const [hideUserTypeDescription, setHideUserTypeDescription] = useState(true);
  const [hideMedicationDose, setHideMedicationDose] = useState(true);
  const [hideMedicationType, setHideMedicationType] = useState(true);
  const [hideMedicationFrequency, setHideMedicationFrequency] = useState(true);

  const [testingData, setTestingData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [hideMedicationAssistance, setHideMedicationAssistance] =
    useState(true);
  const [hideAdministrationType, setHideAdministrationType] = useState(true);
  const [hideAdministrationReason, setHideAdministrationReason] =
    useState(true);
  const [hideExpiryMedication, setHideExpiryMedication] = useState(true);

  const [readPermissionMedicals, setReadPermissionMedicals] = useState(false);
  const [updatePermissionMedicals, setUpdatePermissionMedicals] =
    useState(false);
  const [createPermissionMedicals, setCreatePermissionMedicals] =
    useState(false);
  const [deletePermissionMedicals, setDeletePermissionMedicals] =
    useState(false);

  const [readPermissionAllergiesSource, setReadPermissionAllergiesSource] =
    useState(false);
  const [updatePermissionAllergiesSource, setUpdatePermissionAllergiesSource] =
    useState(false);
  const [createPermissionAllergiesSource, setCreatePermissionAllergiesSource] =
    useState(false);
  const [deletePermissionAllergiesSource, setDeletePermissionAllergiesSource] =
    useState(false);

  const [readPermissionHealthSafety, setReadPermissionHealthSafety] =
    useState(false);
  const [updatePermissionHealthSafety, setUpdatePermissionHealthSafety] =
    useState(false);
  const [createPermissionHealthSafety, setCreatePermissionHealthSafety] =
    useState(false);
  const [deletePermissionHealthSafety, setDeletePermissionHealthSafety] =
    useState(false);

  const {
    contextChoices: choices,
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  const mountedRef = useRef(true);

  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setMedicationNameModal,
    value: medicationNameModals,
    setTitle: medicationTitles,
    fullBind: medicationNameBinds,
  } = useModal(false);
  const {
    setValue: setMedicationNameDelete,
    value: medicationNameModalDelete,
    setTitle: medicationTitlesDelete,
    fullBind: medicationNameBindDelete,
  } = useModal(false);
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(setting_client_health_choices)
  }, [])
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => { });
  const getFunction = func => {
    console.log(func, 'func');
  };
  useEffect(() => {
    if (getFunction) {
      getFunction();
    }
  }, [apiCall]);
  console.log(apiCall, 'apiCall');
  useEffect(() => {
    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setReadPermissions(true);
    } else {
      const roles = contextPermissions;
      let rolePermissions = {};
      if (roles.length > 0) {
        console.log(roles, 'aaaaaa');
        roles.forEach(item => {
          console.log(item, 'dddd');
          if (item?.section?.name === CLIENT_SETTINGS) {
            rolePermissions[CLIENT_SETTINGS] = {};
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        const rolesPermissions = contextSubSectionPermissions;
        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(item => {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          });
        }
        setCreatePermissions(
          rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[CLIENT_SETTINGS][WEB_PERMISSION][WEB_READ_PERMISSION]
        );
      }
    }
  }, []);
  const handleMedical = () => {
    setMedicals(!medicals);
  };
  const handleAllergies = () => {
    setAllergiesSource(!allergiesSource);
  };
  const handleHealth = () => {
    setHealthSaftys(!healthSafety);
  };
  const handleFlue = () => {
    setFluVaccinations(!fluVaccination);
  };

  const handleINfection = () => {
    setInfectionControls(!infectionControl);
  };
  const handleDisablity = () => {
    setDisabilitys(!disabilitys);
  };
  const handleOrganDonation = () => {
    setOrganDonations(!organDonation);
  };
  const handleDNACPR = () => {
    setDNACPRs(!dNACPR);
  };
  const handleVactionation = () => {
    setVaccinationExemption(!vaccinationExemption);
  };
  const handleSignSymptos = () => {
    setSignSymptom(!signSymptoms);
  };
  const handleNok = () => {
    setHideNok(!hideNok);
  };
  const handleAgeBinding = () => {
    setAgeBanding(!ageBanding);
  };
  const handleCareContract = () => {
    setHideCareContract(!hideCareContract);
  };
  const handleRota = () => {
    setRotaRags(!rotaRag);
  };
  const handleClientVRA = () => {
    setClientVras(!clientVra);
  };
  const handleSupportLevel = () => {
    setSupportLevels(!supportLevel);
  };
  const handleMedicationName = () => {
    setHideMedicationName(!hideMedicationName);
  };

  const handleFunderSource = () => {
    setHideFunder(!hideFunder);
  };
  const handleCancellationReason = () => {
    setHideCancelation(!hideCancellation);
  };
  const handleCareGroup = () => {
    setHideCareGroup(!hideCareGroup);
  };
  const handlePPE = () => {
    setHidePPE(!hidePPE);
  };
  const handleCareLevel = () => {
    setHideCareLevel(!HideCareLevel);
  };
  const handleCareDependency = () => {
    setHideCareDependency(!hideCareDependency);
  };
  const handleImpairmentSensory = () => {
    setHideImpairmentSensory(!hideImpairmentSensory);
  };
  const handleServiceTypeDescription = () => {
    sethideServiceTypeDescription(!hideServiceTypeDescription);
  };
  const handleSpecificCommunication = () => {
    setHideSpecificCommunication(!hideSpecificCommunication);
  };
  const handleRegulatedServices = () => {
    setHideRegulatedServices(!hideRegulatedServices);
  };
  const handleUserType = () => {
    setHideUserType(!hideUserType);
  };
  const handleTepIn = () => {
    setHideTepIn(!hideTepIn);
  };
  const handleCareNeeds = () => {
    setHideCareNeeds(!hideCareNeeds);
  };
  const handleLivingCircumstances = () => {
    setHideLivingCircumstances(!hideLivingCircumstances);
  };
  const handleServiceType = () => {
    setHideServiceType(!hideServiceType);
  };
  const handleClientGroup = () => {
    setHideClientGroup(!hideClientGroup);
  };
  const handleClientMobility = () => {
    setHideClientMobility(!hideClientMobility);
  };
  const handleUserTypeDescription = () => {
    setHideUserTypeDescription(!hideUserTypeDescription);
  };
  const handleMedicationDose = () => {
    setHideMedicationDose(!hideMedicationDose);
  };
  const handleMedicationType = () => {
    setHideMedicationType(!hideMedicationType);
  };
  const handleMedicationFrequency = () => {
    setHideMedicationFrequency(!hideMedicationFrequency);
  };

  const handleMedicationAssistance = () => {
    setHideMedicationAssistance(!hideMedicationAssistance);
  };
  const handleAdministrationType = () => {
    setHideAdministrationType(!hideAdministrationType);
  };
  const handleAdministrationReason = () => {
    setHideAdministrationReason(!hideAdministrationReason);
  };
  const handleExpiryMedication = () => {
    setHideExpiryMedication(!hideExpiryMedication);
  };

  return (
      <SettingsGridContainer>
        <SettingsTemplateContainer collapsed={medicals}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleMedical}>
              <div>
                {medicals ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medical Condition</TitleSection>
          </AccordionContainer>
          {!medicals && (
            <Template
              titleSection={'Medical Condition'}
              labelInputTitle={'Medical condition name'}
              getMethod={getMedConditions}
              deleteMethod={deleteMedCondition}
              setMethod={setMedCondition}
              textToMessageDelete={'Medical Condition'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() =>
                handleChangeChoices('medical_condition')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={allergiesSource}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAllergies}>
              <div>
                {allergiesSource ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Allergy Source</TitleSection>
          </AccordionContainer>
          {!allergiesSource && (
            <Template
              titleSection={'Allergy Source'}
              labelInputTitle={'Allergy source name'}
              getMethod={getAllergySource}
              deleteMethod={deleteAllergySource}
              setMethod={setAllergySource}
              textToMessageDelete={'allergy source'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('allergy_source')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={healthSafety}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleHealth}>
              <div>
                {healthSafety ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Health Safety Role</TitleSection>
          </AccordionContainer>
          {!healthSafety && (
            <Template
              titleSection={'Health Safety Role'}
              labelInputTitle={'Health Safety Role'}
              getMethod={getHealthSafty}
              deleteMethod={deleteHealthSafty}
              setMethod={setHealthSafty}
              textToMessageDelete={'Health Safety Role'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() =>
                handleChangeChoices('Health_Safety_Role')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={fluVaccination}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleFlue}>
              <div>
                {fluVaccination ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Flu Vaccination</TitleSection>
          </AccordionContainer>
          {!fluVaccination && (
            <Template
              titleSection={'Flu Vaccination'}
              labelInputTitle={'Flu Vaccination'}
              getMethod={getFluVaccination}
              deleteMethod={deleteFluVaccination}
              setMethod={setFluVaccination}
              textToMessageDelete={'Flu Vaccination'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('flu_vaccination')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={disabilitys}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleDisablity}>
              <div>
                {disabilitys ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Disability</TitleSection>
          </AccordionContainer>
          {!disabilitys && (
            <Template
              titleSection={'Disability'}
              labelInputTitle={'Disability type'}
              getMethod={getDisability}
              deleteMethod={deleteDisability}
              setMethod={setDisability}
              textToMessageDelete={'Disability'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('disability')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={infectionControl}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleINfection}>
              <div>
                {infectionControl ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Infection Control</TitleSection>
          </AccordionContainer>
          {!infectionControl && (
            <Template
              titleSection={'Infection Control'}
              labelInputTitle={'Infection Control'}
              getMethod={getInfectionControl}
              deleteMethod={deleteInfectionControl}
              setMethod={setInfectionControl}
              textToMessageDelete={'Infection Control'}
              textToEmptyDataValues={
                'No Infection Control  Role have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() =>
                handleChangeChoices('infection_control')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={organDonation}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleOrganDonation}>
              <div>
                {organDonation ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Organ Donation</TitleSection>
          </AccordionContainer>
          {!organDonation && (
            <Template
              titleSection={'Organ Donation'}
              labelInputTitle={'Organ Donation'}
              getMethod={getOrganDonation}
              deleteMethod={deleteOrganDonation}
              setMethod={setOrganDonation}
              textToMessageDelete={'Disability'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('organ_donation')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={dNACPR}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleDNACPR}>
              <div>
                {dNACPR ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>DNACPR</TitleSection>
          </AccordionContainer>
          {!dNACPR && (
            <Template
              titleSection={'DNACPR'}
              labelInputTitle={'DNACPR type'}
              getMethod={getDNACPR}
              deleteMethod={deleteDNACPR}
              setMethod={setDNACPR}
              textToMessageDelete={'DNACPR'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('dnacpr')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={vaccinationExemption}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleVactionation}>
              <div>
                {vaccinationExemption ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Vaccination Exemption Type</TitleSection>
          </AccordionContainer>
          {!vaccinationExemption && (
            <Template
              titleSection={'Vaccination Exemption Type'}
              labelInputTitle={'Vaccination Exemption Type type'}
              getMethod={getVaccinationExemptionType}
              deleteMethod={deleteVaccinationExemptionType}
              setMethod={setVaccinationExemptionType}
              textToMessageDelete={'Vaccination Exemption Type'}
              textToEmptyDataValues={
                'No Vaccination Exemption Type Role have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() =>
                handleChangeChoices('vaccination_exemption_type')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={signSymptoms}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSignSymptos}>
              <div>
                {signSymptoms ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Sign Symptoms</TitleSection>
          </AccordionContainer>
          {!signSymptoms && (
            <Template
              titleSection={'Sign Symptoms'}
              labelInputTitle={'Sign Symptoms'}
              getMethod={getSignSymptoms}
              deleteMethod={deleteSignSymptoms}
              setMethod={setSignSymptoms}
              textToMessageDelete={'Sign Symptoms Type'}
              textToEmptyDataValues={
                'No Sign Symptoms Type Role have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('sign_symptoms')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={ageBanding}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleAgeBinding}>
              <div>
                {ageBanding ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Age Banding</TitleSection>
          </AccordionContainer>
          {!ageBanding && (
            <Template
              titleSection={'Age Banding'}
              labelInputTitle={'Age Banding'}
              getMethod={getAgeBind}
              deleteMethod={deleteAgeBind}
              setMethod={setAgeBind}
              textToMessageDelete={'Age Banding Type'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('agebanding')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={rotaRag}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleRota}>
              <div>
                {rotaRag ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Rota-Rag</TitleSection>
          </AccordionContainer>
          {!rotaRag && (
            <Template
              titleSection={'Rota-Rag'}
              labelInputTitle={'Rota-Rag'}
              getMethod={getRotaRag}
              deleteMethod={deleteRotaRag}
              setMethod={setRotaRag}
              textToMessageDelete={'Rota-Rag Type'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('rota_rag')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={clientVra}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleClientVRA}>
              <div>
                {clientVra ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Client Vra</TitleSection>
          </AccordionContainer>
          {!clientVra && (
            <Template
              titleSection={'Client Vra'}
              labelInputTitle={'Client Vra'}
              getMethod={getClientVra}
              deleteMethod={deleteClientVra}
              setMethod={setClientVra}
              textToMessageDelete={'Client Vra Type'}
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('client_vra')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={supportLevel}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSupportLevel}>
              <div>
                {supportLevel ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Support Level</TitleSection>
          </AccordionContainer>
          {!supportLevel && (
            <Template
              titleSection={'Support Level '}
              labelInputTitle={'Support Level'}
              getMethod={getSupportLevel}
              deleteMethod={deleteSupportLevel}
              setMethod={setSupportLevel}
              textToMessageDelete={'Support Level'}
              textToEmptyDataValues={
                'No Support Level Type Role have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              handleChangeChoices={() => handleChangeChoices('support_level')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={hideFunder}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleFunderSource}>
              <div>
                {hideFunder ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Funder Source </TitleSection>
          </AccordionContainer>
          {!hideFunder && (
            <Template
              titleSection={'Funder Source  '}
              labelInputTitle={'Funder Source  Type '}
              getMethod={getFunderSource}
              deleteMethod={deleteFunderSource}
              setMethod={setFunderSource}
              handleChangeChoices={() => handleChangeChoices('funder_source ')}
              textToMessageDelete={'Funder Source  Type '}
              textToEmptyDataValues={
                'Funder Source  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideCareContract}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareContract}>
              <div>
                {hideCareContract ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Contact Type </TitleSection>
          </AccordionContainer>
          {!hideCareContract && (
            <Template
              titleSection={'Care Contact '}
              labelInputTitle={'Care Contact Type '}
              getMethod={getCareContract}
              deleteMethod={deleteCareContract}
              setMethod={setCareContract}
              handleChangeChoices={() =>
                handleChangeChoices('care_contact_type ')
              }
              textToMessageDelete={'Care Contact Type '}
              textToEmptyDataValues={
                'Care Contact Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideNok}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleNok}>
              <div>
                {hideNok ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Nok Relationship </TitleSection>
          </AccordionContainer>
          {!hideNok && (
            <Template
              titleSection={'Nok Relationship  '}
              labelInputTitle={'Nok Relationship  Type '}
              getMethod={getNok}
              deleteMethod={deleteNok}
              setMethod={setNok}
              handleChangeChoices={() =>
                handleChangeChoices('nok_relationship ')
              }
              textToMessageDelete={'Nok Relationship  Type '}
              textToEmptyDataValues={
                'Nok Relationship  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideCancellation}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCancellationReason}>
              <div>
                {hideCancellation ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Cancellation Reason </TitleSection>
          </AccordionContainer>
          {!hideCancellation && (
            <Template
              titleSection={'Cancellation Reason  '}
              labelInputTitle={'Cancellation Reason  Type '}
              getMethod={getCancellation}
              deleteMethod={deleteCancellation}
              setMethod={setCancellation}
              handleChangeChoices={() =>
                handleChangeChoices('cancellation_reason ')
              }
              textToMessageDelete={'Cancellation Reason  Type '}
              textToEmptyDataValues={
                'Cancellation Reason  Type  at other rate have been added'
              }
              addInput={[
                {
                  label: 'Client to be billed',
                  value: 'client_to_be_billed',
                  type: 'text',
                  onKeyPress: KeyPress.TWO_DECIMALS,
                  maxWidth: 128
                },
                {
                  label: 'Staff to be Paid',
                  value: 'staff_to_be_paid',
                  type: 'text',
                  onKeyPress: KeyPress.TWO_DECIMALS,
                  required: false,
                  maxWidth: 128
                },
                {
                  label: 'Color Code',
                  value: 'color_code',
                  type: "color_picker",
                  maxWidth: 128
                }
              ]}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideCareGroup}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareGroup}>
              <div>
                {hideCareGroup ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Group </TitleSection>
          </AccordionContainer>
          {!hideCareGroup && (
            <Template
              titleSection={'Care Group   '}
              labelInputTitle={'Care Group   Type '}
              getMethod={getCareGroup}
              deleteMethod={deleteCareGruop}
              setMethod={setCareGroup}
              handleChangeChoices={() => handleChangeChoices('care_group ')}
              textToMessageDelete={'Care Group   Type '}
              textToEmptyDataValues={
                'Care Group   Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hidePPE}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handlePPE}>
              <div>
                {hidePPE ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>PPE Category </TitleSection>
          </AccordionContainer>
          {!hidePPE && (
            <Template
              titleSection={'PPE Category  '}
              labelInputTitle={'PPE Category  Type '}
              getMethod={getPPE}
              deleteMethod={deletePPE}
              setMethod={setPPES}
              handleChangeChoices={() => handleChangeChoices('ppe_category ')}
              textToMessageDelete={'PPE Category  Type '}
              textToEmptyDataValues={
                'PPE Category  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={HideCareLevel}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareLevel}>
              <div>
                {HideCareLevel ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Level </TitleSection>
          </AccordionContainer>
          {!HideCareLevel && (
            <Template
              titleSection={'Care Level  '}
              labelInputTitle={'Care Level  Type '}
              getMethod={getCareLevel}
              deleteMethod={deleteCareLevel}
              setMethod={setCareLevel}
              handleChangeChoices={() => handleChangeChoices('care_level  ')}
              textToMessageDelete={'Care Level  Type '}
              textToEmptyDataValues={
                'Care Level  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideCareDependency}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareDependency}>
              <div>
                {hideCareDependency ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Dependency Level </TitleSection>
          </AccordionContainer>
          {!hideCareDependency && (
            <Template
              titleSection={'Care Dependency Level  '}
              labelInputTitle={'Care Dependency Level  Type '}
              getMethod={getCareDependency}
              deleteMethod={deleteCareDependency}
              setMethod={setCareDependency}
              handleChangeChoices={() =>
                handleChangeChoices('care_dependency_level')
              }
              textToMessageDelete={'Care Dependency Level  Type '}
              textToEmptyDataValues={
                'Care Dependency Level  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideImpairmentSensory}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleImpairmentSensory}>
              <div>
                {hideImpairmentSensory ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Impairment Sensory Loss </TitleSection>
          </AccordionContainer>
          {!hideImpairmentSensory && (
            <Template
              titleSection={'Impairment Sensory Loss  '}
              labelInputTitle={'Impairment Sensory Loss  Type '}
              getMethod={getImpairmentSensory}
              deleteMethod={deleteImpairmentSensory}
              setMethod={setImpairmentSensory}
              handleChangeChoices={() =>
                handleChangeChoices('impairment_sensory_loss')
              }
              textToMessageDelete={'Impairment Sensory Loss  Type '}
              textToEmptyDataValues={
                'Impairment Sensory Loss  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideServiceTypeDescription}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleServiceTypeDescription}>
              <div>
                {hideServiceTypeDescription ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Service Type Description</TitleSection>
          </AccordionContainer>
          {!hideServiceTypeDescription && (
            <Template
              titleSection={'Service Type Description '}
              labelInputTitle={'Service Type Description  '}
              getMethod={getServiceTypeDescription}
              deleteMethod={deleteServiceTypeDescription}
              setMethod={setServiceTypeDescription}
              handleChangeChoices={() =>
                handleChangeChoices('service_type_category')
              }
              textToMessageDelete={'Service Type Description  '}
              textToEmptyDataValues={
                'Service Type Description Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideSpecificCommunication}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleSpecificCommunication}>
              <div>
                {hideSpecificCommunication ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Specific Communication Needs</TitleSection>
          </AccordionContainer>
          {!hideSpecificCommunication && (
            <Template
              titleSection={'Specific Communication Needs '}
              labelInputTitle={'Specific Communication Needs  '}
              getMethod={getSpecificCommunication}
              deleteMethod={deleteSpecificCommunication}
              setMethod={setSpecificCommunication}
              handleChangeChoices={() =>
                handleChangeChoices('specific_communication_needs')
              }
              textToMessageDelete={'Specific Communication Needs  '}
              textToEmptyDataValues={
                'Specific Communication Needs Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideRegulatedServices}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleRegulatedServices}>
              <div>
                {hideRegulatedServices ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Regulated Services</TitleSection>
          </AccordionContainer>
          {!hideRegulatedServices && (
            <Template
              titleSection={'Regulated Services'}
              labelInputTitle={'Regulated Services '}
              getMethod={getRegulatedServices}
              deleteMethod={deleteRegulatedServices}
              setMethod={setRegulatedServices}
              handleChangeChoices={() =>
                handleChangeChoices('regulated_services')
              }
              textToMessageDelete={'Regulated Services '}
              textToEmptyDataValues={
                'Regulated Services Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideUserType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleUserType}>
              <div>
                {hideUserType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>User Type Category </TitleSection>
          </AccordionContainer>
          {!hideUserType && (
            <Template
              titleSection={'User Type Category'}
              labelInputTitle={'User Type Category '}
              getMethod={getUserType}
              deleteMethod={deleteUserType}
              setMethod={setUserType}
              handleChangeChoices={() =>
                handleChangeChoices('user_type_category')
              }
              textToMessageDelete={'User Type Category '}
              textToEmptyDataValues={
                'User Type Category Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideTepIn}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleTepIn}>
              <div>
                {hideTepIn ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Tep in place </TitleSection>
          </AccordionContainer>
          {!hideTepIn && (
            <Template
              titleSection={'Tep in place '}
              labelInputTitle={'Tep in place Type '}
              getMethod={getTepIn}
              deleteMethod={deleteTepIn}
              setMethod={setTepIn}
              handleChangeChoices={() => handleChangeChoices('tep_in_place  ')}
              textToMessageDelete={'Tep in place Type '}
              textToEmptyDataValues={
                'Tep in place Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideCareNeeds}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleCareNeeds}>
              <div>
                {hideCareNeeds ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Care Needs Category </TitleSection>
          </AccordionContainer>
          {!hideCareNeeds && (
            <Template
              titleSection={'Care Needs Category  '}
              labelInputTitle={'Care Needs Category  Type '}
              getMethod={getCareNeeds}
              deleteMethod={deleteCareNeeds}
              setMethod={setCareNeeds}
              handleChangeChoices={() =>
                handleChangeChoices('care_needs_category')
              }
              textToMessageDelete={'Care Needs Category  Type '}
              textToEmptyDataValues={
                'Care Needs Category  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideLivingCircumstances}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleLivingCircumstances}>
              <div>
                {hideLivingCircumstances ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Living Circumstances </TitleSection>
          </AccordionContainer>
          {!hideLivingCircumstances && (
            <Template
              titleSection={'Living Circumstances  '}
              labelInputTitle={'Living Circumstances  Type '}
              getMethod={getLivingCircumstances}
              deleteMethod={deleteLivingCircumstances}
              setMethod={setLivingCircumstances}
              handleChangeChoices={() =>
                handleChangeChoices('living_circumstances')
              }
              textToMessageDelete={'Living Circumstances  Type '}
              textToEmptyDataValues={
                'Living Circumstances  Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideServiceType}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleServiceType}>
              <div>
                {hideServiceType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Service Type Category</TitleSection>
          </AccordionContainer>
          {!hideServiceType && (
            <Template
              titleSection={'Service Type Category '}
              labelInputTitle={'Service Type Category   '}
              getMethod={getServiceType}
              deleteMethod={deleteServiceType}
              setMethod={setServiceType}
              handleChangeChoices={() =>
                handleChangeChoices('service_type_category')
              }
              textToMessageDelete={'Service Type Category   '}
              textToEmptyDataValues={
                'Service Type Category    at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideClientGroup}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleClientGroup}>
              <div>
                {hideClientGroup ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Client Group</TitleSection>
          </AccordionContainer>
          {!hideClientGroup && (
            <Template
              titleSection={'Client Group '}
              labelInputTitle={'Client Group  '}
              getMethod={getClientGroup}
              deleteMethod={deleteClientGroup}
              setMethod={setClientGroup}
              handleChangeChoices={() => handleChangeChoices('client_group')}
              textToMessageDelete={'Client Group  '}
              textToEmptyDataValues={
                'Client Group Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideClientMobility}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleClientMobility}>
              <div>
                {hideClientMobility ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Client Mobility</TitleSection>
          </AccordionContainer>
          {!hideClientMobility && (
            <Template
              titleSection={'Client Mobility'}
              labelInputTitle={'Client Mobility '}
              getMethod={getClientMobility}
              deleteMethod={deleteClientMobility}
              setMethod={setClientMobility}
              handleChangeChoices={() => handleChangeChoices('client_mobility')}
              textToMessageDelete={'Client Mobility '}
              textToEmptyDataValues={
                'Client Mobility Type  at other rate have been added'
              }
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={hideUserTypeDescription}>
          <AccordionContainer style={{ marginLeft: '40px' }}>
            <AccordionArrow onClick={handleUserTypeDescription}>
              <div>
                {hideUserTypeDescription ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>User Type Description </TitleSection>
          </AccordionContainer>
          {!hideUserTypeDescription && (
            <Template
              titleSection={'User Type Description'}
              labelInputTitle={'User Type Description '}
              getMethod={getUserTypeDescription}
              deleteMethod={deleteUserTypeDescription}
              setMethod={setUserTypeDescription}
              handleChangeChoices={() =>
                handleChangeChoices('user_type_description')
              }
              textToMessageDelete={'User Type Description '}
              textToEmptyDataValues={
                'User Type Description Type  at other rate have been added'
              }
              addOneDropdown={{
                placeholder: 'User Type Category',
                required: true,
                value: 'userTypeCategory',
                options: choices,
                choicesValue: 'user_type_category',
              }}
              fieldAttributes={{ maxLength: 50 }}
              // update={updatePermissionRegistrationType}
              update={update}
              // create={createPermissionRegistrationType}
              create={create}
              // deletePermission={deletePermissionRegistrationType}
              deletePermission={deletePermission}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

      </SettingsGridContainer>
  );
};

export default Health;
