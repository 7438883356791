import {
    LARGE_COL,
    EX_SMALL_COL4,
    P_W_Zero
  } from 'shared/styles/constants/columns';

export const headerColumns = [
{
        label: 1,
        checkbox: true,
        name: 'checkbox',
        custum_column: true,
        removeCusCol: true,
        id: 'checkbox',
        columnSelected: true,
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
},
 {
   label: 'Client Name',
   name: 'client_name',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
   custum_column: true,
   id: 'client_name',
  columnSelected: true,
 },
 {
  label: 'Employee Name',
  name: 'employee_name',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'employee_name',
  columnSelected: true,
},
 {
   label: 'Medication Name',
   name: 'medication_name',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
   custum_column: true,
   id: 'medication_name',
  columnSelected: true,
 },
 {
  label: 'Medication Dose',
  name: 'medication_dose',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'medication_dose',
  columnSelected: true,
},
 {
   label: 'Start date',
   name: 'schedule_start_date',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
   custum_column: true,
   id: 'schedule_start_date',
  columnSelected: true,
 },
//  {
//    label: 'End date',
//    name: 'schedule_end_date',
//    status: 0,
//    className: 'grow-mobile',
//    max_width: LARGE_COL,
//custum_column: true,
// id: 'visit',
//   columnSelected: true,
//  },
{
  label: 'Planned Dose Time',
  name: 'planned_dose_time',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'planned_dose_time',
  columnSelected: true,
},
 {
  label: 'Actual Dose Time',
  name: 'actual_dose_time',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'actual_dose_time',
  columnSelected: true,
},

 {
   label: 'Medication Type',
   name: 'medication_type',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
   custum_column: true,
   id: 'medication_type',
  columnSelected: true,
 },
 {
  label: 'Medication Status',
  name: 'medication_status',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'medication_status',
  columnSelected: true,
},
{
  label: 'Medication Reason',
  name: 'medication_reason',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'medication_reason',
  columnSelected: false,
},
{
  label: 'Medication Feedback',
  name: 'medication_feedback',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'medication_feedback',
  columnSelected: false,
},
{
  label: 'Review Status Name',
  name: 'review_status__name',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'review_status__name',
  columnSelected: false,
},
{
  label: 'Review Comment',
  name: 'review_comment',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'review_comment',
  columnSelected: false,
},
{
  label: 'Review Date',
  name: 'review_date',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'review_date',
  columnSelected: false,
},

{
  label: 'Review Time',
  name: 'review_time',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
  custum_column: true,
  id: 'review_time',
  columnSelected: false,
},
   
 ];