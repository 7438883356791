import { Switch, TextField } from '@mui/material'
import RichTextEditor from 'components/SharedComponents/RichTextEditor';
import React from 'react'

const RichTextHtmlHeading = ({ setJson, json }) => {

    return (
        <div className='mb-4'>
             <RichTextEditor
                        value={json?.richTextHtmlHeading?.label || ''}
                        tabIndex={''} // tabIndex of textarea
                        onChange={(event) => {
                            setJson({
                                ...json, richTextHtmlHeading: {
                                    ...json.richTextHtmlHeading,
                                    label: event
                                }
                            })
                        }}
                    />                
        </div>

    )
}

export default RichTextHtmlHeading;
