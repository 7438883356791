import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {setRateCard, setRateCardRota} from "utils/api/SchedulerApi"
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';


const AssignRate = ({
    fullBind,
    checkedRows,
    searchRotaShift,
    setAssignRate,
    travelRate,
    payRate,
    onCallRate,
    officeRate,
    choices,
    clearSelectedElId
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

    const rate_hook = useInput(`rate_card_id`,'isRequired');

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Assign Rate',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);
      const dropDownHandler=(item,nameItem)=>{
        values[nameItem] = item[nameItem];
        setValues({...values});
      }

      const handleSubmit=()=>{
          if (errors.length > 0 || loadingSave) {
              setCheckRequires(true)
              setErrorMessage(InvalidOrEmptyValues);
              return
          }
          setLoadingSave(true)
          setErrorMessage('');

          let checkedShiftData = [];
  
          checkedRows.forEach((item)=>{
              checkedShiftData.push(item.id)
          })
          let body = {
              is_travel_rate_card: (values.rate_type === "Travel") ? true : false,
              is_pay_rate_card: ((values.rate_type === "Pay") || (values.rate_type === "On Call") || (values.rate_type === "Office")) ? true : false,
              is_actual: (values.type === 'Actual') ? true : false,
              rate_card_id: values.rate_card_id,
              employee_shift: [...checkedShiftData],
          }
          if(values.travel_mode_id){
            body.travel_mode_id = values.travel_mode_id
          }
        setRateCardRota(body)
        .then(res=>{
          setSaveButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            searchRotaShift()
            setAssignRate(false)
            clearSelectedElId()
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          
        })

      }

      const rateOptions=()=>{
        switch (values.rate_type) {
          case 'Travel':
            return travelRate;
          case 'Pay':
            return payRate;
          case 'On Call':
            return onCallRate;
          case 'Office':
            return officeRate;
          default:
            return [];
        }
      }


    return (
        <ModalContainer max_width={'40%'}>
            <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
                      <div className={' pl-3 pr-3 pb-3'}>
    <div className="mb-2">
    <FlexGrid3Item className="pr-1 pb-2 mb-2">
    <DropdownSearchable
              required
                placeHolder={'Select Type'}
                // width="145%"
                options={[
                    {id:"Scheduled",name:"Scheduled"},
                    {id:"Actual",name:"Actual"}
                ]}
                {...useInput(
                  `type`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.type : null
                }
                onChange={value =>
                  dropDownHandler(
                    {type: value ? value : null},
                    'type'
                  )
                }
              />
        </FlexGrid3Item>
        <FlexGrid3Item className="pr-1 pb-2 mb-2">
        <DropdownSearchable
              required
                placeHolder={'Select Rate type'}
                // width="145%"
                options={[
                    {id:"Travel",name:"Travel"},
                    {id:"Pay",name:"Pay"},
                    {id:"On Call",name:"On Call"},
                    {id:"Office",name:"Office"},
                ]}
                {...useInput(
                  `rate_type`,
                  'isRequired'
                )}
                selectedOption={
                  values ? values.rate_type : null
                }
                onChange={value =>
                  dropDownHandler(
                    {rate_type: value ? value : null},
                    'rate_type'
                  )
                }
              />
            </FlexGrid3Item>
            {values.rate_type && 
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
            <DropdownSearchable
              required
                placeHolder={`Select ${values.rate_type} Rate`}
                // width="145%"
                options={rateOptions() || []}
                {...rate_hook}
                selectedOption={
                  values ? values.rate_card_id : null
                }
                onChange={value =>
                  dropDownHandler(
                    {rate_card_id: value ? value : null},
                    'rate_card_id'
                  )
                }
              />
            </FlexGrid3Item>}
            {(values.rate_type === "Travel") && 
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
             <DropdownSearchable
             required
               placeHolder={'Travel method'}
               options={choices.travel_type || []}
               selectedOption={values.travel_mode_id}
               onChange={value =>
                dropDownHandler({travel_mode_id: value ? value : null},'travel_mode_id')
               }
             />
           </FlexGrid3Item>}

    </div>
    <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
        </ModalDynamic>
        </ModalContainer>
    );
}

export default AssignRate;
