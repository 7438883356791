import React from 'react'
import Container from './Container'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function Formbuilder(props) {
    return (
        <div className="App">
            <DndProvider backend={HTML5Backend}>
                <Container handleChangeChoices={props.handleChangeChoices} props={props}/>
            </DndProvider>
        </div>
    )
}

export default Formbuilder
