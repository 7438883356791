import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import React from 'react'
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';

function AllergiesAndIntolerances({ useInput, values, setValues, allegrySourceList, choices, nameSection, index, errors, setFormErrors }) {
    const allergy_start_date_hook = useInput(`${[nameSection]}[${index}].${'allergy_start_date'}`);
    const treatment_reaction_hook = useInput(`${[nameSection]}[${index}].${'treatment_reaction'}`);

    const allergy_type_hook = useInput(`${[nameSection]}[${index}].${'allergy_type'}`, "isRequired");
    const allergy_source_hook = useInput(`${[nameSection]}[${index}].${'allergy_source'}`, "isRequired");
    const severity_hook = useInput(`${[nameSection]}[${index}].${'severity'}`, "isRequired");
    const sign_symptoms_hook = useInput(`${[nameSection]}[${index}].${'sign_symptoms'}`, "isRequired");

    const new_allergy_source = useInput(
        `${[nameSection]}[${index}].${'new_allergy_source'}`,
        values[nameSection][index].allergy_source === "newAllegry" ? "isRequired" : "");


    const dropDownHandleSelect = (item, nameSection, index, list_key) => {
        if (!item.allergy_source == 'newAllegry') {
            values[nameSection][index]['new_allergy_source']
                && delete values[nameSection][index]['new_allergy_source']
        }
        values[nameSection][index][list_key] = item[list_key];
        setValues({ ...values });
    };

    const deleteRow = (nameSection, index) => {
        let error = errors.filter(err => !err.includes(`${[nameSection]}[${index}]`))
        setFormErrors(error)
        values[nameSection].splice(index, 1);;
        setValues({ ...values });
    }
    return (
        <div >
            <div className="d-flex mt-2">
                <CloseButtonWrapper
                    onClick={() => {
                        deleteRow(nameSection, index);
                    }}>
                    {TIMES}
                </CloseButtonWrapper>
                <div className="w-100 mr-2">
                    <DateSelector
                        labelTop="Date Entered"
                        {...allergy_start_date_hook}
                    />
                </div>
                <div className="w-100 mr-2">
                    <DropdownSearchable
                        disabled={false}
                        required
                        {...allergy_type_hook}
                        placeHolder={'Allergy/Intolerance Type'}
                        options={choices.allergy_type}
                        selectedOption={
                            values
                                ? values[nameSection][index] && values[nameSection][index]?.allergy_type
                                    ? values[nameSection][index]?.allergy_type
                                    : null
                                : null
                        }
                        onChange={value =>
                            dropDownHandleSelect(
                                { allergy_type: value ? value : null },
                                nameSection,
                                index,
                                'allergy_type')
                        }
                    />
                </div>
                <div className="w-100">
                    <DropdownSearchable
                        placeHolder={'Allergy/Intolerance Source'}
                        options={allegrySourceList}
                        required
                        {...allergy_source_hook}
                        selectedOption={
                            values
                                ? values[nameSection][index]?.allergy_source
                                    ? values[nameSection][index]?.allergy_source
                                    : null
                                : null
                        }
                        onChange={value =>
                            dropDownHandleSelect(
                                { allergy_source: value ? value : null },
                                nameSection,
                                index,
                                'allergy_source')
                        }

                    />
                </div>
                {values['allergy_and_intolerances'][index].allergy_source == "newAllegry" &&
                    (
                        <div className="w-100 ml-2  mr-2">
                            <InputLabelTop
                                {...new_allergy_source}
                                required
                                type="text"
                                id="allegry_source"
                                label={'New Allegry Source'}

                                maxlength={100}
                            />
                        </div>
                    )
                }
            </div>
            <div className="d-flex mt-2">
                <CloseButtonWrapper>
                    <div style={{ height: '1rem', width: '1rem' }} />
                </CloseButtonWrapper>
                <div className="w-100 mr-2">
                    <DropdownSearchable
                        placeHolder={'Severity'}
                        required
                        {...severity_hook}
                        options={choices?.severity}
                        selectedOption={
                            values ? (values[nameSection][index]?.severity ? values[nameSection][index]?.severity : null) : null
                        }
                        onChange={value =>
                            dropDownHandleSelect({ severity: value ? value : null },
                                nameSection,
                                index,
                                'severity')
                        }

                    />
                </div>
                <div className="w-100 mr-2">
                    <DropdownSearchable
                        placeHolder={'Sign/Symptoms'}
                        options={choices.sign_symptoms}
                        required
                        {...sign_symptoms_hook}
                        selectedOption={
                            values
                                ? values[nameSection][index].sign_symptoms
                                    ? values[nameSection][index].sign_symptoms
                                    : null
                                : null
                        }
                        onChange={value =>
                            dropDownHandleSelect({ sign_symptoms: value ? value : null },
                                nameSection,
                                index,
                                'sign_symptoms')
                        }
                       
                    />
                </div>
                <div className="w-100">
                    <InputLabelTop
                        type="text"
                        id="treatment"
                        label={'Treatment-in case of reaction'}
                        {...treatment_reaction_hook}
                        maxlength={255}
                    />
                </div>
            </div>
        </div>
    )
}

export default AllergiesAndIntolerances