import React, {Fragment, useState, useEffect} from 'react';
import {AiFillMinusSquare, AiFillPlusSquare} from 'react-icons/ai';
import {FaAngleDown, FaAngleUp} from 'react-icons/fa';
import {PURPLE} from 'shared/styles/constants/colors';
import {FaUserShield} from 'react-icons/fa';
import {SquareStyle, ArrowStyle} from './styles';

let TITLE_PADDING = 3;
let TITLE_FONT_WEIGHT = 800;
const CREATE_TITLE = 'Create';
const READ_TITLE = 'Read';
const UPDATE_TITLE = 'Update';
const DELETE_TITLE = 'Delete';
const _ = require('lodash');

const BodyTable = ({rolValues, selectedRol, setRolValues,handleCheckboxClick}) => {
  const [expandable, setExpandable] = useState({});

  useEffect(() => {
    _.forEach(expandable, function(value, key) {
      expandable[key] = false;
    });

    setExpandable({...expandable});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);

  const SubHeader = () => {
    return (
      <tr className={'border'}>
        <th className={'sticky-col'}>
          <div className={'d-flex justify-content-center'}>
            <FaUserShield className={'mt-1'} size={18} />
          </div>
        </th>
        <td className={'border-left'}>
          <div className={'d-flex justify-content-between font-weight-bold'}>
            <div>{CREATE_TITLE}</div>
            <div>{READ_TITLE}</div>
            <div>{UPDATE_TITLE}</div>
            <div>{DELETE_TITLE}</div>
          </div>
        </td>
        <td className={'border-left'}>
          <div className={'d-flex justify-content-between font-weight-bold'}>
            <div>{CREATE_TITLE}</div>
            <div>{READ_TITLE}</div>
            <div>{UPDATE_TITLE}</div>
            <div>{DELETE_TITLE}</div>
          </div>
        </td>
      </tr>
    );
  };

  const createRows = (sections, keyValue = '') => {
    let tableToReturn = [];

    let valuesToMap = sections ? sections : rolValues;

    valuesToMap.forEach((tableValue, index) => {
      const myKey = tableValue.name.replace(/\s/g, '') + '/' + keyValue;

      expandable[`${keyValue ? keyValue : myKey}`] =
        expandable[`${keyValue ? keyValue : myKey}`] === undefined
          ? false
          : expandable[`${keyValue ? keyValue : myKey}`];

      tableToReturn = [
        ...tableToReturn,
        <Fragment key={index}>
          {!expandable[`${keyValue ? keyValue : myKey}`] &&
          !tableValue.principal ? null : (
            // adding rows
            <tr key={index} className={'border'}>
              <th
                className={'sticky-col'}
                style={{
                  paddingLeft: TITLE_PADDING,
                  fontWeight: TITLE_FONT_WEIGHT,
                }}>
                <div className={'d-flex align-items-center'} style={{ whiteSpace:"break-spaces"}}>
                  {/**adding square */}
                  {tableValue.principal
                    ? addSquare(tableValue, expandable, keyValue, myKey)
                    : null}

                  {/**adding arrows */}
                  {tableValue.sections &&
                  tableValue.sections.length >= 1 &&
                  !tableValue.principal ? (
                    <Fragment>{addArrows(expandable, myKey)}</Fragment>
                  ) : null}
                  {/**adding name */}
                  {tableValue.name}
                </div>
              </th>
              {/**adding web checkboxes */}
              <td
                className={'align-content-center border-left checkboxPosition'}>
                {checkboxComponent(tableValue, 'web')}
              </td>
              {/**adding mobile checkboxes */}
              {/* <td className={'align-self-center border-left checkboxPosition'}>
                {checkboxComponent(tableValue, 'mobile')}
              </td> */}
            </tr>
          )}
        </Fragment>,
      ];

      if (tableValue.sections && tableValue.sections.length >= 1) {
        TITLE_PADDING += 20;
        TITLE_FONT_WEIGHT -= 150;
        tableToReturn = [
          ...tableToReturn,
          createRows(tableValue.sections, `${myKey}`),
        ];
        TITLE_PADDING -= 20;
        TITLE_FONT_WEIGHT += 150;
      }

      if (tableValue.principal) {
        TITLE_PADDING = 3;
      }
    });

    return tableToReturn;
  };

  const addSquare = (tableValue, expandable, keyValue, myKey) => {
    if (!expandable[`${keyValue ? keyValue : myKey}`] && tableValue.principal) {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillPlusSquare
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]: !expandable[
                  `${keyValue ? keyValue : myKey}`
                ],
              });
            }}
          />
        </SquareStyle>
      );
    } else {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillMinusSquare
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function(value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]: !expandable[
                  `${keyValue ? keyValue : myKey}`
                ],
              });
            }}
          />
        </SquareStyle>
      );
    }
  };

  const addArrows = (expandable, myKey) => {
    if (expandable[`${myKey}`]) {
      return (
        <ArrowStyle className={'pb-1 pl-0'}>
          <FaAngleUp
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function(value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    } else {
      return (
        <ArrowStyle className={'pb-1 pl-0'}>
          <FaAngleDown
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    }
  };

  /**
   * Return horizontal checkboxes
   * @param {Object} tableValue
   * @param {String} columnType
   * @returns
   */
  const checkboxComponent = (tableValue, columnType) => {
    return (
      <div className={'d-flex  justify-content-between'}>
        
        <div className={'checkbox-container justify-content-center pl-3'}>
          <label className={'checkbox-label'}>
            <input
              type="checkbox"
              aria-label={READ_TITLE}
              checked={tableValue[columnType][`${columnType}_read`]}
              onChange={() => {
                changeCheckboxValues(
                  tableValue,
                  rolValues,
                  columnType,
                  `${columnType}_read`,
                  'read',
                );
              }}
            />
            <span className="checkbox-custom" />
          </label>
        </div>
      </div>
    );
  };

  const changeCheckboxValues = (
    tableValue,
    rolValues,
    columnType,
    typeCheckbox,
    type
  ) => {
    let myMenuIndex = 0;

    if (tableValue.principal) {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.id,
      );
    } else {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.category,
      );
    }

    if (tableValue.principal) {
      rolValues[myMenuIndex].sections.forEach(rolSection => {
        //change rolValues ej. menu>subMenu.web{web_create: false or true}
        rolSection[columnType][typeCheckbox] = !tableValue[columnType][
          typeCheckbox
        ];

        //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
        rolSection.sections.forEach(rolSubSection => {
          rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
            typeCheckbox
          ];
        });
      });
    } else {

      if(!tableValue.isSubsection){
        rolValues[myMenuIndex].sections.forEach(rolSection => {

          //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
          rolSection.sections.forEach(rolSubSection => {
            rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
              typeCheckbox
            ];
          });
        });
      }else{
        if (tableValue[columnType][typeCheckbox]) {
          rolValues[myMenuIndex][columnType][typeCheckbox] = false;
        }
      }
      //Change principal menu to false when submenu change to false
      
    }

    // change menu.web{web_create: false or true}
    tableValue[columnType][typeCheckbox] = !tableValue[columnType][
      typeCheckbox
    ];
    let check = tableValue[columnType][typeCheckbox];
    handleCheckboxClick({...tableValue,type: type, columnType: columnType },check);

    setRolValues([...rolValues]);
  };

  return (
    <Fragment>
      {/* <SubHeader /> */}
      {Object.keys(selectedRol).length >= 1 ? createRows() : null}
    </Fragment>
  );
};

export default BodyTable;
