import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';

const FunderSplit = ({useInput, sectionStyle, nameSection, indexSplit,editMode}) => {
;
  return (
    <div className={sectionStyle}>
      <div className={'mt-2'}>
        <FlexGrid3Container className="d-flex">
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
            disabled={!editMode}
              type="text"
              id={'funderSplit_contact_full_name_' + indexSplit}
              label={'Funder contact Full name'}
              {...useInput(`${[nameSection]}.full_name`)}
              maxlength={255}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              disabled={!editMode}
              type="text"
              id={'funderSplit_contact_email_' + indexSplit}
              label={'Funder contact Email'}
              {...useInput(`${[nameSection]}.email`, 'isEmail')}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pl-lg-1 pr-1 pr-md-0">
            <InputLabelTop
              disabled={!editMode}
              type="text"
              id={'funderSplit_contact_phone_' + indexSplit}
              label={'Funder contact Phone number'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(
                `${[nameSection]}.phone_no`,
                'isMobilePhone',
              )}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1">
          <InputLabelTop
            disabled={!editMode}
              type="text"
              id={'fundercontact_no' + indexSplit}
              label={'Funder Contact No.'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`${[nameSection]}.funder_contact_no`, 'isInt')}
              maxlength={3}
            />
            
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
          <InputLabelTop
            disabled={!editMode}
              type="text"
              id={'funderSplitcontact_no' + indexSplit}
              label={'Funder Contact Organization Title'}
              {...useInput(`${[nameSection]}.funder_contact_organisation_title`)}
              maxlength={50}
            />
          
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0">
         <InputLabelTop
           disabled={!editMode}
              type="text"
              id={'funderSplit_funding_arrangement_' + indexSplit}
              label={'Funding Split % arrangement'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`${[nameSection]}.arrangement`, 'isInt')}
              maxlength={3}
            />      
         
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1">
               <InputLabelTop
                 disabled={!editMode}
              type="text"
              id={'funderSplit_house_name_' + indexSplit}
              label={'House name / Company name'}
              {...useInput(`${[nameSection]}.name`)}
              maxlength={50}
            /> 
           
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <InputLabelTop
                  disabled={!editMode}
              type="text"
              id={'funderSplit_address_line1_' + indexSplit}
              label={'Address line 1'}
              {...useInput(`${[nameSection]}.line_1`)}
              maxlength={255}
            /> 
         
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pl-lg-1 pr-1 pr-md-0">
             <InputLabelTop
               disabled={!editMode}
              type="text"
              id={'funderSplit_address_line2_' + indexSplit}
              label={'Address line 2'}
              {...useInput(`${[nameSection]}.line_2`)}
              maxlength={255}
            /> 
          
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pl-md-0 pr-md-1">
              <InputLabelTop
                disabled={!editMode}
              type="text"
              id={'funderSplit_town_city_' + indexSplit}
              label={'Town / city'}
              {...useInput(`${[nameSection]}.city`)}
              maxlength={100}
            /> 
            
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1">
               <InputLabelTop
                 disabled={!editMode}
              type="text"
              id={'funderSplit_county_state_' + indexSplit}
              label={'County / State'}
              {...useInput(`${[nameSection]}.state`)}
              maxlength={100}
            /> 
           
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pl-lg-1 pr-1 pr-md-0">
            <InputLabelTop
              disabled={!editMode}
              type="text"
              id={'funderSplit_country_' + indexSplit}
              label={'Country'}
              {...useInput(`${[nameSection]}.country`)}
              maxlength={100}
            /> 
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pl-md-0 pr-md-1">
            <InputLabelTop
              disabled={!editMode}
              type="text"
              id={'funderSplit_zip_code_' + indexSplit}
              label={'Postcode / Zipcode'}
              {...useInput(`${[nameSection]}.zip_code`)}
              maxlength={25}
            /> 
            
          </FlexGrid3Item>
        </FlexGrid3Container>
      </div>
    </div>
  );
};

FunderSplit.propTypes = {
  useInput: PropTypes.func.isRequired,
  sectionStyle: PropTypes.string,
  nameSection: PropTypes.string.isRequired,
  indexSplit: PropTypes.number.isRequired,
};

FunderSplit.defaultProps = {
  sectionStyle: '',
};

export default FunderSplit;
