import React, {useEffect, useState, Fragment, useContext} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputColorPicker from 'components/SharedComponents/InputColorPicker';
import {Shift} from 'components/SharedComponents/FormSections';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {useForm} from 'hooks/FormHook';
import {
  getShiftPatternById,
  setShiftPattern,
  setShiftPatternsDetails,
  deleteShiftPatternsDetails,
} from 'utils/api/SchedulerApi';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {BsFillPlusSquareFill} from 'react-icons/bs';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  ContainerResponsive,
  TitleSection,
} from 'components/DashboardComponents/Team/AddModal/styles';
import {formatUtcTimeToLocalTime} from 'utils/times';
import moment from 'moment';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import {
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from '../../../..//utils/localStorage/user';
import {
  SHIFT_PATTERN_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from '../../../../utils/constants/permissions';
import {AppContext} from 'context';
import {Toast} from 'primereact/toast';
import { useRef } from 'react';

const SHIFT_VALUES = 'shiftValues';

const ShiftPatternsModal = ({
  fullBind,
  idSelected,
  choices,
  readOnly = false,
  setTitle,
  setIdSelected,
  loadShiftPatterns,
  userClient,
  userEmployee,
  userStaff,
  filterSuperAdmin,
  filterAdmin,
  setModalValue,
}) => {
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({
      [SHIFT_VALUES]: [],
    });
  const toast = useRef();
  const [loadingModal, setLoadingModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [showError, setShowError] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [timeError, setTimeError] = useState([]);
  const [requireDayOfWeek, setRequireDayOfWeek] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const {contextPermissions} = useContext(AppContext);
  const start_time_value = values[SHIFT_VALUES].start;
  const end_time_value = values[SHIFT_VALUES].end;

  const [timeDuration, setTimeDuration] = useState('');

  useEffect(() => {
    if (idSelected) {
      setTitle('Edit shift pattern');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === SHIFT_PATTERN_SETTINGS) {
          rolePermissions[SHIFT_PATTERN_SETTINGS] = {};
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION] = {};
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });

      if (filterAdmin == true || filterSuperAdmin == true) {
        setCreatePermissions(true);
        setDeletePermissions(true);
        setUpdatePermissions(true);
      } else {
        setCreatePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ]
        );
      }
    }

    if (values[SHIFT_VALUES].length === 0) {
      values[SHIFT_VALUES][0] = {
        id: '',
        daysofWeek: [
          {day: 'MON', dayValue: 1, selected: false},
          {day: 'TUE', dayValue: 2, selected: false},
          {day: 'WED', dayValue: 3, selected: false},
          {day: 'THU', dayValue: 4, selected: false},
          {day: 'FRI', dayValue: 5, selected: false},
          {day: 'SAT', dayValue: 6, selected: false},
          {day: 'SUN', dayValue: 7, selected: false},
        ],
      };
    }
  }, []);

  useEffect(() => {
    let hasSelectedDays = -1;

    if (values.details) {
      hasSelectedDays = values.details.findIndex(
        detail => detail.selected === true
      );
    }

    if (textStatus !== SAVE) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE); // response.shiftValues = {...response.details[0]} || {};
      // response.shiftValues.start = response.shiftValues.start
      // response.shiftValues.end = response.shiftValues.end
    }

    if (values.details) {
      if (hasSelectedDays >= 0) {
        setRequireDayOfWeek(false);
      }
    }

    if (showError && errors.length === 0 && hasSelectedDays >= 0) {
      setShowError(false);
      setCheckRequires(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (idSelected) {
      loadShiftPatternById(idSelected);
    } else {
      setLoadingModal(false);
      values.details = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values[SHIFT_VALUES].start && values[SHIFT_VALUES].end) {
      let start = moment(values[SHIFT_VALUES].start, 'HH:mm:ss a');
      let end = moment(values[SHIFT_VALUES].end, 'HH:mm:ss a');
      if (end > start) {
        setTimeDuration(end.diff(start, 'seconds'));
      } else {
        let add_date = end.add(1, 'days');
        setTimeDuration(add_date.diff(start, 'seconds'));
      }
    } else {
      setTimeDuration(0);
    }
    setValues({...values});
    console.log(timeDuration, 'NNN');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_time_value, end_time_value]);
  const loadShiftPatternById = async idSelected => {
    const response = await getShiftPatternById(idSelected);
    // response.shiftValues = {...response.details[0]} || {};
    // response.shiftValues.start = response.shiftValues.start
    // response.shiftValues.end = response.shiftValues.end

    values[SHIFT_VALUES] = [];
    values.name = response.name;
    values.id = response.id;
    values.color_code = response.color_code;
    Object.keys(response.details).forEach((key, index) => {
      values[SHIFT_VALUES][key] = {};
      response.details[key].forEach((detail, detIndex) => {
        if (detIndex === 0) {
          values[SHIFT_VALUES][key].start = detail.start;
          values[SHIFT_VALUES][key].end = detail.end;
          values[SHIFT_VALUES][key].break_start = detail.break_start;
          values[SHIFT_VALUES][key].break_end = detail.break_end;
          values[SHIFT_VALUES][key].shift_type = detail.shift_type;
          values[SHIFT_VALUES][key].travel_mode = detail.travel_mode;
          values[SHIFT_VALUES][key].shift_bill_rate = detail.shift_bill_rate;
          values[SHIFT_VALUES][key].pay_rate_card = detail.pay_rate_card;
          values[SHIFT_VALUES][key].repeat = detail.repeat;
          values[SHIFT_VALUES][key].duration = detail.duration;
          values[SHIFT_VALUES][key].travel_time = detail.travel_time;
          values[SHIFT_VALUES][key].duration = detail.start - detail.end;

          if (detail.start === '00:00:00' && detail.end === '23:59:00') {
            values[SHIFT_VALUES][key].full_day = true;
          } else {
            values[SHIFT_VALUES][key].full_day = false;
          }
          values[SHIFT_VALUES][key].is_overnight = detail.is_overnight;
          values[SHIFT_VALUES][key].daysofWeek = [
            {day: 'MON', dayValue: 1, selected: false},
            {day: 'TUE', dayValue: 2, selected: false},
            {day: 'WED', dayValue: 3, selected: false},
            {day: 'THU', dayValue: 4, selected: false},
            {day: 'FRI', dayValue: 5, selected: false},
            {day: 'SAT', dayValue: 6, selected: false},
            {day: 'SUN', dayValue: 7, selected: false},
          ];
          values[SHIFT_VALUES][key].details = [];
        }
        values[SHIFT_VALUES][key].daysofWeek.find(
          day => day.dayValue === detail.day_of_week
        ).selected = true;
        values[SHIFT_VALUES][key].details[detIndex] = {
          start: detail.start,
          end: detail.end,
          selected: true,
          day_of_week: detail.day_of_week,
          id: detail.id,
          is_available: false,
          shift_type: detail.shift_type,
          travel_mode: detail.travel_mode,
          shift_bill_rate: detail.shift_bill_rate,
          pay_rate_card: detail.pay_rate_card,
          repeat: detail.repeat,

          duration: detail.start - detail.end,
        };
      });
    });
    // if(response.shiftValues.start==='00:00:00' && response.shiftValues.end==="23:59:00"){
    // response.shiftValues.full_day=true
    // }else{
    // response.shiftValues.full_day=false
    // }

    setLoadingModal(false);
    setValues({...values});
  };

  const addNewShift = () => {
    values[SHIFT_VALUES] = [
      ...values[SHIFT_VALUES],
      {
        id: '',
        daysofWeek: [
          {day: 'MON', dayValue: 1, selected: false},
          {day: 'TUE', dayValue: 2, selected: false},
          {day: 'WED', dayValue: 3, selected: false},
          {day: 'THU', dayValue: 4, selected: false},
          {day: 'FRI', dayValue: 5, selected: false},
          {day: 'SAT', dayValue: 6, selected: false},
          {day: 'SUN', dayValue: 7, selected: false},
        ],
      },
    ];
    setValues({...values});
  };

  const deleteShift = shiftIndex => {
    if (values[SHIFT_VALUES].length > 0) {
      values[SHIFT_VALUES].splice(shiftIndex, 1);
    }
    timeError[shiftIndex] = false;
    setTimeError(timeError);
    setValues({...values});
  };
  const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };
  const setNewShiftPattern = async () => {
    let tempReqDay = [];
    setRequireDayOfWeek([]);
    let allGood = false;
    values.shiftValues.map((shift, index) => {
      if (shift.full_day) {
        values.shiftValues[index].start = '00:00';
        values.shiftValues[index].end = '23:59:59';
      }
      // const hasSelectedDays = shift.details.length?true:false
      const haveTimeErrors = timeError.find(data => data === true);
      // let errorValue = shift.full_day ? false : errors.length > 0;
      // let isFinalValid=shift.full_day?true:isValid;
      if (
        !shift.details ||
        !values.name ||
        haveTimeErrors ||
        !shift.start ||
        !shift.end
      ) {
        setCheckRequires(!shift.full_day);
        setShowError(true);
        allGood = true;
        // setErrorMessage(InvalidOrEmptyValues);
        if (!shift.details) {
          tempReqDay = [...tempReqDay, index];
          // setRequireDayOfWeek([...requireDayOfWeek,index]);
        }
        // return;
      } else if (shift.details) {
        let selectedShift = shift.details.find(det => det.selected);
        if (!selectedShift) {
          setCheckRequires(!shift.full_day);
          setShowError(true);
          allGood = true;
          // setErrorMessage(InvalidOrEmptyValues);
          tempReqDay = [...tempReqDay, index];
          // setRequireDayOfWeek([...requireDayOfWeek,index]);
        }
      }
    });
    setRequireDayOfWeek([...tempReqDay]);
    if (allGood) {
      return;
    }

    setLoadingSave(true);
    setErrorMessage('');
    let pattern_body = {
      id: values.id,
      name: values.name,
      color_code: values.color_code,
      shift_type: values.shift_type,
      travel_mode: values.travel_mode,
      travel_time: values.travel_time,
      pay_rate_card: values.pay_rate_card,
      shift_bill_rate: values.shift_bill_rate,
      repeat: values.repeat,
      duration: values.duration,
    };
    await setShiftPattern(pattern_body)
      .then(res => {
        onSuccess(res, res.name);
      })
      .catch(err => onError());
  };

  const onSuccess = async (res, res_name) => {
    setIdSelected(res.id);
    values.id = res.id;

    let detail_arr = [];
    values.shiftValues.map(shift => {
      let uniqueId = create_UUID();
      shift.details.map(details => {
        if (details.selected) {
          if (!details.id) {
            delete details.id;
          }
          delete details.selected;
          detail_arr.push({
            ...details,
            start: shift.start,
            end: shift.end,
            break_start: shift.break_start && shift.break_start,
            break_end: shift.break_end && shift.break_end,
            shift_type: shift.shift_type,
            travel_mode: shift.travel_mode,
            shift_bill_rate: shift.shift_bill_rate,
            pay_rate_card: shift.pay_rate_card,
            travel_time: shift.travel_time,
            duration: shift.duration,
            group_ref: uniqueId,
            repeat: shift.repeat ? shift.repeat : null,
            is_available: details.is_available ? details.is_available : false,
            is_overnight: shift.is_overnight ? shift.is_overnight : false,
            start_datetime: moment(shift.start, 'HH:mm').format(),
            end_datetime: moment(shift.end, 'HH:mm').format(),
          });
        }
      });
    });
    let temp_det = detail_arr.filter(det => det instanceof Object);
    console.log(temp_det, 'temp___det');
    if (temp_det.some(obj => Object.keys(obj).includes('id'))) {
      setShiftPatternsDetails(
        values.id,
        temp_det,
        temp_det && temp_det[0].id && temp_det[0].id
      )
        .then(res => {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          loadShiftPatterns();
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Shift Pattern ${res_name} is updated successfully`,
          });
          setTimeout(() => {
            setModalValue(false);
          }, 1000);
        })
        .catch(err => onError());
    } else {
      setShiftPatternsDetails(values.id, temp_det)
        .then(res => {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          loadShiftPatterns();
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Shift Pattern ${res_name} is updated successfully`,
          });
          setTimeout(() => {
            setModalValue(false);
          }, 1000);
        })
        .catch(err => onError());
    }
  };

  const onError = () => {
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setLoadingSave(false);
  };

  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic {...fullBind} max_width={'80%'} loading={loadingModal}>
      <Toast ref={toast} position="top-right" />
        <div className={'pt-2 pl-3 pr-3 pb-3'}>
          <FlexGrid3Item className="mb-2">
            <InputLabelTop
              required
              type="text"
              id="name_shift_pattern"
              label={'Shift pattern name'}
              {...useInput('name', 'isRequired')}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Container className="mt-2">
            <FlexGrid3Item>
              <InputColorPicker
                type="text"
                id="colour_code_shift_pattern"
                label={'Colour code'}
                {...useInput('color_code')}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <div className={'mt-2'}>
            <div className={'mb-2'}>
              <TitleSection>Shift</TitleSection>
            </div>
            {values[SHIFT_VALUES] &&
              values[SHIFT_VALUES].map((shift, index) => {
                return (
                  <div className={'mb-3'}>
                    <Shift
                      // shiftIndex={0}
                      useInput={useInput}
                      nameSection={`${SHIFT_VALUES}`}
                      setValues={setValues}
                      shiftIndex={index}
                      choices={choices}
                      values={values}
                      requireDayOfWeek={requireDayOfWeek}
                      deleteShift={deleteShift}
                      setTimeError={setTimeError}
                      timeError={timeError}
                      isShiftPatern ={true}
                    />
                  </div>
                );
              })}
            {values[SHIFT_VALUES] && (
              <PrimaryButtonForm
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={addNewShift}>
                <span>{<BsFillPlusSquareFill />}</span>
              </PrimaryButtonForm>
            )}
          </div>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              {values[SHIFT_VALUES] && (
                <div className={'ml-0 ml-md-3'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    onClick={setNewShiftPattern}>
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default ShiftPatternsModal;
