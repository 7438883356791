import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumsList';
import AddModal from 'components/DashboardComponents/Settings/SettingsRateCard/NLWRLW/AddModal';
import { FaPlus } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { getNLW, DeleteNLW } from 'utils/api/SettingsApi';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { LARGE_COL, MEDIUM_COL, EX_SMALL_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { AppContext } from "context";
import { useHistory, useLocation } from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

const NLWRLW = props => {
  const {
    readPermissionALW_RLW,
    createPermissionALW_RLW,
    updatePermissionALW_RLW,
    deletePermissionALW_RLW
  } = props;
  const { values, setValues, useInput, setCheckRequires } = useForm({});  //used useInput
  const [nLW, setNLW] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState();
  const [idToDelete, setIdToDelete] = useState('');
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { contextChoices, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext)
  const [tempChoices, setTempChoices] = useState({ choices_data: [] });
  const [countryData, setCountryData] = useState([]);
  const [rateSource, setRateSource] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);

  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );


  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const history = useHistory();

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })

  }, [])

  useEffect(() => {
    setTempChoices(contextChoices || []);
    setCountryData(contextChoices && contextChoices.country && contextChoices.country.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setRateSource(contextChoices.wages_rate_sources && contextChoices.wages_rate_sources.map(ratedata => { return { id: ratedata, name: ratedata } }).filter(data => data.name == 'NLW' || data.name == 'RLW'));

    loadNLW();

  }, [contextChoices])

  useEffect(() => {
    //if( ( (props.location.search || values.ordering ) && values.limitperpage!== undefined)){
    loadNLW();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, values.ordering, values.limitperpage]);


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    // offset = offset * PAGE_SIZE_RENDER;
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };

  const loadNLW = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    getNLW({ ...values, ...pageToSearch }).then(response => {
      if (!mountedRef.current) return null;
      if (response) {
        setLoadingModal(false);
      }
      setNLW(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  useEffect(() => {
    setTitle('NEW NLW/RLW RATE CARD');
  }, []);

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddHoliday = () => {
    setIdSelected('');
    handleModal();
    // setCheckRequires(false);
  };


  //delete  msg pop up begin 
  const toDeleteNLW = id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const handleDeleteNLW = async () => {
    await DeleteNLW(idToDelete);
    setModalDeleteValue(false);
    loadNLW();
  };
  //delete  msg pop up begin 

  const handleRowClickEdit = id => {
    setIdSelected(id);
    setModalValue(true);
  };


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/rate-card/nlw-rlw",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  return (
    <div>
      <Header>
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex align-items-center">
          {createPermissionALW_RLW && (
            <FloatingMobileButton onClick={handleAddHoliday}>
              <FaPlus />
              <label>NEW NLW/RLW RATE CARD</label>
            </FloatingMobileButton>
          )}

        </SearchAddContainer>
      </Header>
      {readPermissionALW_RLW ? (
        <ComponentDynamic loading={loadingModal} >
          <Table
            headerColumns={headerColumns}
            callBackQuerySort={setQuerySort}
            headerPadding={false}
            fontSize="0.75rem"
          >
            <div>
              {nLW && nLW.map((nlwData, index) => {
                return (
                  <div key={index} >
                    <Row bgColor Shadow>

                      <Col
                        max_width={MEDIUM_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {nlwData.wages_type && nlwData.wages_type}
                      </Col>

                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {nlwData.wages_rate_description && nlwData.wages_rate_description}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        Shrink
                        reducePadding
                        className="md-hidden"
                        Capitalize
                        Center>
                        {nlwData.age_from && nlwData.age_from}yrs - {nlwData.age_to && nlwData.age_to}yrs
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        Shrink
                        reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center>
                        {nlwData.effective_date_from && nlwData.effective_date_from}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        Shrink
                        reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center>
                        {nlwData.effective_date_to && nlwData.effective_date_to}
                      </Col>
                      <Col
                        max_width={MEDIUM_COL}
                        Shrink
                        reducePadding
                        className="sm-hidden"
                        Capitalize
                        Center>
                        {nlwData.rate_per_hour && nlwData.rate_per_hour}
                      </Col>

                      {updatePermissionALW_RLW && (
                        <Col className="sm-hidden" Center Icon Purple Shrink
                          reducePadding>
                          <IconWrapper
                            onClick={() => {
                              handleRowClickEdit(nlwData.id);
                            }}
                          >
                            <MdEdit />
                          </IconWrapper>
                        </Col>)}

                      {deletePermissionALW_RLW && (
                        <Col className="sm-hidden" Center Shrink
                          reducePadding NoFlexGrow >
                          <IconWrapper
                            onClick={() => {
                              toDeleteNLW(nlwData.id);
                            }}  >
                            <RiDeleteBinLine />
                          </IconWrapper>
                        </Col>)}

                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}

              <div className="mt-3">
                <Pagination totalPages={pages} currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={nLW.length}
                />
              </div>


            </div>
          </Table>
        </ComponentDynamic>
      ) : (
        <NoPermissionContainer>
          You don't have permission to access the information.
        </NoPermissionContainer>
      )
      }
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          setPublicHolidayModalValue={setModalValue}
          fullBind={fullBind}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          // nameSection={'rateValue'}
          useInput={useInput}
          loadPublicHoliday={loadNLW}
          contextChoices={contextChoices}
          countryData={countryData}
          rateSource={rateSource}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this NLW/RLW data ? '
        }
        onOk={handleDeleteNLW}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};


export default NLWRLW