import React from 'react';
import {MessageSpan} from '../styles';
import PropTypes from 'prop-types';

const ShiftExistingUnavailable = ({startDate, endDate}) => {
  return (
    <div className={'mt-3 d-flex justify-content-center text-center'}>
      <div>
        <div>
          The user has indicated he is Unavailable from{' '}
          <MessageSpan>{startDate}</MessageSpan> to{' '}
          <MessageSpan>{endDate}</MessageSpan>
        </div>
        <div className={'font-weight-bold'}>
          Would you like to remove it and assign the shift?
        </div>
      </div>
    </div>
  );
};

ShiftExistingUnavailable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default ShiftExistingUnavailable;
