import styled from 'styled-components';
import {GRAY_300, GRAY_700, GRAY_800} from 'shared/styles/constants/colors';

export const PageLink = styled.div`
  cursor: pointer;
  color: ${GRAY_800} !important;

  font-weight: bold;
  font-size: 0.8rem;
  line-height: 0.8rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  max-width: 2rem;
  max-height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    font-weight: 500;
    color: black;
  }

  ${props => leftRightButtons(props)}
`;

function leftRightButtons(props) {
  let returnStyle = '';

  // if (props['aria-label'] === 'Next') {
  //   returnStyle = `
  //     background: transparent !important;
  //     border: 0.15em solid ${GRAY_700} !important;
  //     border-radius: 4rem;
  //     font-weight: normal;   
  //     height: 2rem
  //     max-height: 2rem;  
  //     letter-spacing: 2pt;
  //     padding-top: 0.5rem;
  //     padding-left: 0.7rem;
  //     width: 6rem !important;
  //     max-width: 6rem !important;
  //     padding-left: 1rem;  
  //   `;
  // } 
  if (props['aria-label'] === 'Next') {
    returnStyle = `
    background: transparent !important;
      border: 0.15em solid ${GRAY_700} !important;
      padding-top: 0.5rem;
      padding-left: 0.7rem;       
    `;
  }  else if (props['aria-label'] === 'Previous') {
    returnStyle = `
      background: transparent !important;
      border: 0.15em solid ${GRAY_700} !important;
      padding-top: 0.5rem;
      padding-left: 0.7rem;       
    `;
  }else if (props['aria-label'] === 'DoublePrevious') {
    returnStyle = `
      background: transparent !important;
      border: 0.15em solid ${GRAY_700} !important;
      padding-top: 0.5rem;
      padding-left: 0.7rem;     
      margin-right:0.3rem;
    `;
  }else if (props['aria-label'] === 'DoubleNext') {
    returnStyle = `
      background: transparent !important;
      border: 0.15em solid ${GRAY_700} !important;
      padding-top: 0.5rem;
      padding-left: 0.7rem;     
      margin-left:0.3rem;
    `;
  }

  return returnStyle;
}

export const PaginationItem = styled.li`
  margin-right: ${props => (props.reduceMargin ? '0px' : '0.5rem !important')};
  margin-left: ${props => (props.reduceMargin ? '0px' : '0.5rem !important')};
  width: 2rem;
  height: 2rem;

  .page-link {
    background: transparent !important;
    border: 0 !important;

    &:hover {
      background: ${GRAY_300} !important;
    }
  }

  &.active {
    div {
      background-color: transparent !important;
      border: 0.15em solid ${GRAY_700} !important;
    }
  }
`;
