import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import React from 'react'
import { FlexGrid3Container, FlexGrid3Item, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { useState } from 'react';
import { FLOPPY } from 'utils/constants/icons';
import { InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import moment from 'moment';

function ConfirmPopup({
    fullBind,
    loadingModal,
    handleSubmit,
    setSpecialCase
}) {
        
    const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires, } = useForm({start_date: moment().format("YYYY-MM-DD")});
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCheck = () => {
        if (errors.length > 0) {
            setShowError(true)
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }
        handleSubmit(values.start_date, values.end_date)
    }

    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo
                {...fullBind}
                max_width={'60%'}
                minWidthTitle="8.5rem"
                title={"Update rate card"}
                loading={loadingModal}
            >
                <div className="pl-3 pr-3 pb-3 pt-0">
                    <FlexGrid3Container className={'w-100 mb-2'}>
                        <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'49%'}>
                            <DateSelector
                                isRequired
                                labelTop="Effective From"
                                {...useInput('start_date', 'isRequired')}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'49%'}>
                            <DateSelector
                                // isRequired
                                minDate={values.start_date && values.start_date}
                                labelTop="Effective To"
                                {...useInput('end_date')}
                            />
                        </FlexGrid3Item>
                    </FlexGrid3Container>
                    <div
                        className={
                            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                        }>
                        <div className={'mb-3'}>
                            {showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>

                        <div
                            className={'d-flex justify-content-center justify-content-md-end'}>
                            <div className={'ml-0 ml-md-3 mr-2'}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    onClick={handleCheck}>
                                    <span className={'mr-2'}>Save</span>
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    )
}

export default ConfirmPopup