import styled from 'styled-components';
import {
  PURPLE,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  BLACK_900,
  RED,
  WHITE,
  SOFT_RED,
  DARK_VIOLET,
  PURPLE_GRAY_500,
  ERR_COLOR,
  GRADIENT_GREEN,
  GRAY_400,
  ERR_COLOR_800,
} from 'shared/styles/constants/colors';
import { XS, SM, MD, LG, XL, XXL, XL1 } from 'shared/styles/constants/resolutions';
import { AnimationStyle } from 'shared/styles/buttons';

export const Header = styled.div`
  margin-top: ${props => props.marginTop ?  props.marginTop : '1.25rem'};
  margin-bottom: ${props => props.marginBottom ?  props.marginBottom : '1.25rem'};
   padding-bottom: 0.01rem;
  border-bottom: 0.0625rem solid ${GRAY_300};
  display: flex;
  justify-content: space-between;
  @media (max-width: ${MD}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
 
`;

export const HeaderLgBreak = styled(Header)`
padding-bottom:0px !important;

border-bottom:none !important;
  @media (max-width: ${LG}) {
    flex-direction: column-reverse;
    border-bottom-style: none !important;
    border-bottom:none !important;
    padding-bottom:0px !important;
  
   
  }
`;

export const HeaderXxlBreak = styled(Header)`
  @media (max-width: ${XXL}) {
    flex-direction: column-reverse;
  }
`;

export const HeaderVisits = styled(HeaderXxlBreak)`
  @media (max-width: ${XS}) {
    align-items: center;
  }
`;

export const Title = styled.label`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const SearchIcon = styled.div`
  display: inline-block;
  margin-right: ${props => (!props.noMarginLeft ? '1.25rem' : '0')};
  cursor: pointer;
  svg {
    width: 1.6875rem;
    height: 1.6875rem;
    opacity: 0.49;
  }
`;

export const SearchAddContainer = styled.div`
  @media (max-width: ${XXL}) {
    margin-bottom: 1.25rem;

  }
`;

export const SearchContainer = styled.div`
  margin-right: 1rem;
  width: ${props => (props.show ? '12.5rem' : '0')};
  min-width: ${props => (props.show ? '12.5rem' : '0')};
  transition-duration: ${props => (props.show ? '0.25s' : '0')};
  
`;

export const DropdownTypesContainer = styled.div`
min-width: 8.75rem;;


@media (max-width: ${SM}) {
  flex-grow: 1;
  flex-basis: 0;
}
`;


export const ModalContainer = styled.div`
  div.modal-dialog {
    margin: 1.75rem 0.5rem;
    max-width: 100%;
  }
  @media (min-width: 992px) {
    div.modal-dialog {
      max-width: ${props => (props.max_width ? props.max_width : '31.25rem')};
      margin: 1.75rem auto;
    }
  }
`;

export const TabStyled = styled.div`
  cursor: pointer;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: ${props => (props.active ? `2px solid ${PURPLE}` : '0')};
`;

export const TabStyledNew = styled.div`
display: flex;
align-items: center;
cursor: pointer;
width: 100%;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin-bottom: 1rem;
margin-right: 0.6rem;
background-color: #fff;
border-radius: 12px;
box-shadow: 3px 3px rgba(0,0,0,0.1);
 color: ${props => (props.active ? ` ${PURPLE}` : 'black')};
  border-bottom: ${props => (props.active ? `2px solid ${PURPLE}` : '0')};
`;
export const LinkStyled = styled.div`
  .styledLink {
    color: ${props => (props.active ? PURPLE : 'BLACK')};
    text-decoration: none;
  }
`;

export const ShowTab = styled.div`
  width: 100%;
  display: ${props => (props.show ? 'inline-block' : 'none')};
`;

export const ShowClientModalTab = styled.div`
  width: 100%;
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
  // margin-top:150px ;
  @media (max-width: 456px) {
    // margin-top:316px  !important;
  }
  @media (width: 540px) {
    // margin-top: 228px !important;
  }
  @media screen and (max-width: 920px) and (min-width: 768px) {
    // margin-top: 185px;
  
}
`;

export const ContainerTabStyled = styled.div`
  display: flex;
  justify-content: space-around;

  .secondRow{
    width: 50%;
  }

  .firstRow{
    width: 80%;
  }

  @media (max-width: calc(${SM} - 80px)) {
    font-size: 0.8rem;
    display: block;

    .firstRow{
      width: 100%;
    }

    .secondRow{
      margin-top: 0.5rem;
      width: 100%;
    }
  }
`;

export const DropDownMenu = styled.div`
width: 150px;
background: #fff;
position:fixed;
// top:${props => (props.clientX ? props.clientX : "-200%")};
// left:${props => (props.clientY ? props.clientY : "-200%")};;
cursor: pointer;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
border-radius: 3px;
z-index: 10;

// & .off {
//   top:-200%;
//   left:-200%;
// }

`;

export const LinkTabStyled = styled.div`
  cursor: pointer;
  width: 100%;

  .styledLink {
    text-decoration: none;
  }
  &:hover {
    font-weight: 500;
  }
`;
export const LinkTab = styled.div`
  color: ${props => (props.active ? PURPLE : 'BLACK')};
  padding-bottom: 1rem;
  border-bottom: ${props => (props.active ? `2px solid ${PURPLE}` : '0')};
`;

export const TitleSection = styled.div`
  font-weight: 600;
`;

export const ContainerResponsive = styled.div`
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const UserImgWrapper = styled.div`
  background-color: ${GRAY_100};
  border: 0.0625rem solid ${GRAY_200};
  border-radius: 0.25rem;
  height: 100%;
  /* margin-bottom: 0.5rem; */
  /* margin-right: 0.5rem; */
  padding: 0.5rem;

  @media (max-width: 575px) {
    margin-right: 0rem;
  }
`;

export const UserImgContainer = styled.div`
  position: relative;
  border-radius: 50%;
  -webkit-box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};
  box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  img {
    width: 4.8rem;
    height: 4.8rem;
  }

  background: ${GRAY_200};

  div {
    color: ${PURPLE};
    width: 100%;
    height: 100%;
  }
`;

export const UploadPhotoContainer = styled.div`
  svg {
    margin-right: 0.25rem;
  }
  label {
    cursor: pointer;
    font-size: 0.875rem;
    margin: 0;
  }
  &:hover {
    font-weight: 500;
  }
`;

export const FlexEqual = styled.div`
  flex: 1;

  @media (max-width: 767px) {
    margin-top: ${props => (props.marginTopSm ? '1rem' : '0')};
    margin-left: ${props => (props.noMarginLeft ? '0' : 'unset')};
  }
`;

export const ErrorMessage = styled.div`
  color: ${RED};

  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  font-weight: 500;
  label {
    margin: 0;
    line-height: 1.3rem;
  }
`;

export const WarningMessage = styled.div`
  color: ${ERR_COLOR_800};

  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  font-weight: 500;
  label {
    margin: 0;
    line-height: 1.3rem;
  }
`;

export const FlexGrid3Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexGrid3Item = styled.div`
  flex: ${props => (props.ResizeWidth ? props.ResizeWidth : '0 0 33.333333%')};
  @media (max-width: ${MD}) {
    flex: 0 0 50%;
  }
`;

export const AddNewServiceStyle = styled.div`
  ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.5rem 0;
    li {
      background: ${GRAY_200};
      border-radius: 0 0.5rem 0.5rem 0.5rem;
      margin-top: 0.5rem;
      padding: 0.5rem 0.5rem 0.5rem 6rem;
      position: relative;
      color: ${DARK_VIOLET};
      &::before,
      &::after {
        background: ${PURPLE_GRAY_500};
        border-radius: 15px 15px 0 15px;
        content: '';
        height: 2.8rem;
        left: -2.5rem;
        overflow: hidden;
        position: absolute;
        top: -1rem;
      }

      &::before {
        align-items: flex-end;
        content: 'Service contract';
        color: ${WHITE};
        font: 900 1rem;
        padding: 0.5rem;
        z-index: 1;
      }

      span {
        margin-left: -5rem;
        display: flex;
        justify-content: space-bettween;
        color: ${DARK_VIOLET};
      }
    }
  }

  .seeContract {
    cursor: pointer;
  }

  .reference {
    color: ${SOFT_RED};
  }
`;

export const IconWrapper = styled.div`
  height: 27px;
  width: 27px;
  -webkit-box-shadow: 0px 1px 3px ${BLACK_900};
  box-shadow: 0px 1px 3px ${BLACK_900};
  border: 0.5px solid ${GRAY_200};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: ${props => (props.color ? props.color : BLACK_900)};
  &:active {
    background: ${GRAY_200};
  }
`;

// export const CloseButtonWrapper = styled.div`
//   ${AnimationStyle}
//   cursor: pointer;
//   margin-top: 0.2rem;
//   margin-right: 0.5rem;
//   color: ${ERR_COLOR};
//   disabled:${props => props.disable}
//   :hover {
//     background-color: transparent;
//   }
// `;
export const CloseButtonWrapper = styled.button`
  ${AnimationStyle}
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  // margin-top: 0.2rem;
  // margin-right: 0.5rem;
  color: ${ERR_COLOR};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  disabled:${props => (props.disabled)};
  border:none;
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

export const SampleMessage = styled.div`
  color: ${RED};
`;

export const FiltersContainerRotaShifts = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${XS}) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
`;



export const DataContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${XL}) {
    margin-top: 3.5rem;
    margin-bottom: -5.75rem;
  }

  @media (max-width: ${MD}) {
    display: flex;
    margin-top: 2.2rem;

    justify-content: center;
  }

  .total-container {
    display: flex;
    flex-direction: column;
    margin-top: -7%;
    padding: 0.5rem;
  }
  .dropdown-menu:hover{
    cursor:pointer;
  }
  .data-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    margin-right: 1rem;
    align-items: flex-end;
    align-self: center;
  }

  .data-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 1rem;
    padding: 8px;
    background-color: white;
    border-radius: 1.875rem;
  }

  .data-container-schedule {
    margin-top: -4.5%;
    margin-bottom: -1%;
    padding-right: 2rem;
    padding-left: 2rem;
    display: flex;
  }

  .title {
    font-weight: bold;
  }

  .data {
    display: flex;
    flex-direction: column;
    // width: 8rem;
    align-items: center;
  }
.hour{
  margin-left:20px
}
  .data-schedule {
    width: 12rem;
  }

  .contents {
    display: flex;
    flex-direction: row;
  }

  .sub-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FiltersLeftGroupRotaShifts = styled.div`
  display: flex;
  align-items: center;
 
  @media (max-width: ${XS}) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
`;

export const FiltersTypesContainerRotaShifts = styled.div`
  min-width: 8.75rem;
  padding-bottom: 5px;
  
  @media (max-width: ${SM}) {
    max-width: 9.5rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    border-radius: 50%;
   
  }
`;

export const ButtonsModals = styled.div`

  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${LG}) {
    margin-bottom: 0.5rem;
    margin-top:2rem;
  }
  @media (max-width: ${MD}) {
    margin-top 0;
    margin-bottom: 1rem;
    display: block;
  }
  
`;

export const WeekContainer = styled.div`
  display: flex;

  .formContainer {
    width: 66.5%;
    max-width: 66.5%;
  }

  .formWeek {
    width: 33.5%;
    max-width: 33.5%;
  }

  @media (max-width: ${XXL}) {
    display: block !important;

    .formContainer {
      width: 100%;
      min-width: 100%;
    }

    .formWeek {
      width: 100%;
      max-width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export const WeekStyle = styled.div`
  display: flex;
  justify-content: center;
  cursor: ${props => (props.disabled ? '' : 'pointer')};

  @media (min-width: calc(${MD} + 20rem)) {
    padding-left: 1rem;
    padding-top: 0rem;
  }
`;

export const ButtonWeek = styled.div`
  label {
    padding: 0px !important;
    margin: 0px !important;
    color: ${props => (props.add_background ? WHITE : GRAY_400)};
    cursor: pointer;
    font-weight: bold;
  }

  div {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 3rem;
    background: ${props =>
    props.add_background
      ? `linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})`
      : GRAY_200};
  }

  @media (max-width: ${SM}) {
    div {
      width: 2rem;
      max-width: 2rem;

      label {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${MD}) {
    div {
      width: 4rem;
      max-width: 4rem;

      label {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${XXL}) {
    div {
      max-width: 2.5rem;

      label {
        text-align: center;
        align-items: center;
        width: 10px;
        max-width: 10px;
        word-wrap: break-word;
      }
    }
  }
`;
export const CustomButtonWeek = styled.div`

  width: 3.5rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-right: 0.2rem;
  background-color: ${props => (props.add_background ? PURPLE : GRAY_300)};
  
  label {
    padding-bottom:2px;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${props => (props.Active ? 'white' : '#8e8e8e')};

  }
 


  div {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 3rem;
    background: ${props =>
    props.add_background
      ? `linear-gradient(to bottom, ${GRADIENT_GREEN}, ${PURPLE})`
      : GRAY_200};
  }

  @media (max-width: ${SM}) {
    div {
      width: 2rem;
      max-width: 2rem;

      label {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${MD}) {
    div {
      width: 4rem;
      max-width: 4rem;

      label {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${XXL}) {
    div {
      max-width: 2.5rem;

      label {
        text-align: center;
        align-items: center;
        width: 10px;
        max-width: 10px;
        word-wrap: break-word;
      }
    }
  }
`;
export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  

  @media (max-width: ${XS}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FiltersLeftGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${XS}) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
`;

export const StylePointer = styled.div`
  cursor: pointer;
`;

export const SettingItemsContainer = styled.div`
  min-height: ${props => (props.minHeight ? props.minHeight : 'auto')};
`;

export const LoadingContainer = styled.div`
  height: 37.5rem;
`;

export const MessageSpan = styled.span`
  color: ${PURPLE};
`;

export const HeaderXsBreak = styled(Header)`
border-bottom:none !important;
  @media (max-width: ${XS}) {
    border-bottom:none !important;
    flex-direction: column;
.filterwidth{
  width: 50%;
}
  }
`;


export const noBottom = styled.div`
 .hUewFz {
   border-bottom:none;
 }

`;

export const PublishButton = styled.div`
  svg {
    font-size: 1.8rem;
    color: ${props => (props.disable ? '#868686' : PURPLE)};
    margin-top: 10%;
    cursor: ${props => (props.disable ? '' : 'pointer')};
  }

  &:active svg {
    color: '#868686';
  }

  padding: 0;
  height: 100%;
`;

export const NameWrapper = styled.div`
font-weight:bold;
  color: #f4480e;
font-size:11px;

`;