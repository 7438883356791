import styled from 'styled-components';
import {
  PURPLE,
  GRAY_100,
  WHITE,
  GRAY_200,
  ERR_COLOR,
  PURPLE_800,
  RED,
} from 'shared/styles/constants/colors';
import {XS} from 'shared/styles/constants/resolutions';

export const Icon = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  color: ${PURPLE};
`;

export const ErrorLabel = styled.div`
  font-size: 0.6rem;
  color: ${ERR_COLOR};
  font-weight: bold;
`;

export const DatePickerStyled = styled.div`
  position: relative;
  width: ${props => (props.typeFilter ? '10rem' : 'auto')};

  label {
    position: absolute;
    left: ${props => (props.typeFilter ? '0.8rem' : '0.55rem')};
    z-index: 1;
    font-size: 0.6rem;
    margin: 0;
    color: black;
    font-weight: 600;
    span {
      color: ${ERR_COLOR};
    }
  }
  div.react-datepicker__year-text--keyboard-selected
  {
    background-color:${PURPLE};

  }
  div.react-datepicker__year{
    margin:1.4rem
  }
  div.react-datepicker-popper {
    z-index: 2;
  }
  div.react-datepicker__year-text--selected{
    background-color: ${PURPLE};
}
div.dnrLdk{
  right:-10.5rem;
}
div.react-datepicker__year--container{
  max-width:272px
}
div.react-datepicker__year-wrapper{
  max-width:272px

}
  div.react-datepicker-wrapper {
    width: 100%;
    div {
      input {
        width: 100%;
        height: 2.3994rem;
        padding-left: ${props => (props.typeFilter ? '0.5rem' : '0.55rem')};
        padding-right: 1.6rem;
        padding-top: ${props => (props.typeFilter ? '0.2rem' : '0rem')};
        font-size: 0.875rem;
        font-weight: 300;
        border: 1px solid ${GRAY_200};
        background-color: ${props => (props.typeFilter ? WHITE : GRAY_100)};
        border-radius: ${props => (props.typeFilter ? '15rem' : '0.25rem')};

        @media (max-width: ${XS}) {
          padding-left: 0.5rem;
          font-size: 0.7rem;
        }
      }
      input:focus {
        border: 1px solid ${GRAY_200};
        outline: none;
        outline-color: unset;
      }
      input.error-border {
        border: 1px solid ${ERR_COLOR};
      }
      input.value-border {
        border: 1px solid ${GRAY_200};
      }
      button::after {
        background-color: ${PURPLE};
        display: ${props => (props.typeFilter ? 'none' : 'block')};
        -webkit-appearance: ${props => (props.typeFilter ? 'none' : 'block')};
      }
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${PURPLE};
  }
`;
