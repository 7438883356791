import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import { KeyPress } from 'utils/constants/regularExpressions';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER, HOUR, MINUTE
} from 'utils/constants/serviceVisits';
import { Fragment } from 'react';
import moment from 'moment';
import { useState } from 'react';
import DropdownMedication from 'components/SharedComponents/DropdownMedication';
import { useEffect } from 'react';
import { ERR_COLOR } from 'shared/styles/constants/colors';


const AddMedicationDose = ({
  nameSection,
  useInput,
  sectionStyle,
  setValues,
  editMode,
  choices,
  values,
  idSelectedMed,
  dropDownHandleSelect = () => { },
}) => {

  const [frequencyName, setFrequencyName] = useState('');

  const useInputHookKeys = {
    new_med_name: useInput('new_medication_name', 'isRequired'),
    brand_name: useInput('medication_brand_name'),
    name: useInput('name','isRequired'),
    medication_type: useInput('medication_type','isRequired'),
    supplier_brand_name: useInput('supplier_brand_name', ''),
    indication: useInput('indication', ''),
    medication_class: useInput('medication_class', ''),
    new_administration_type: useInput('new_administration_type'),
    new_administration_reason: useInput('new_administration_reason'),
    new_route: useInput('new_route'),
    new_form: useInput('new_form'),
    everyHook: useInput(`every`, 'isRequired'),
    repeatOnHook: useInput(`repeat`, 'isInt'),
    endTypeHook: useInput(`end_type`, 'isRequired'),
    endValueHook: useInput(
      `end_value`, 'isRequired'
    ),
    effectiveDateHook: useInput(`effective_date`),
    //New field inputs
    strength: useInput(`strength`),
    minimum_interval_between_doses: useInput(`min_interval_between_doses`),
    maximum_dose_in_24_hours: useInput(`max_dose_in_24_hours`),
    medication_for: useInput(`medication_purpose`),
    symptoms: useInput(`symptoms_to_look_for`),
    client_needs_medication: useInput(`client_need_medication_criteria`),
    describe_any_alternative: useInput(`alternative_administering_interventions`),
    medication_work_for: useInput(`medication_duration`),
    guidance_from_healthcare: useInput(`healthcare_professional_guidance_criteria`),
    completed_by: useInput('completed_by')
  }

  const switchEndValueInput = props => {
    // render correct input type depending of the end_type selected
    switch (values.end_type) {
      case END_TYPE_ON:
        // check if current value has date format
        const validDate = props.value
          ? moment(props.value, 'YYYY-MM-DD', true).isValid()
          : true;
        // if current value has date format then send props
        const dateProps = validDate ? props : {};
        return (
          <DateSelector isRequired labelTop={'End value'} {...dateProps} />
        );
      case END_TYPE_AFTER:
        return (
          <InputLabelTop
            required
            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
            label={'End value'}
            type="text"
            id={`end_value`}
            {...props}
          />
        );
      case END_TYPE_NEVER:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };

  const switchMedicationNameInput = props => {

    if (values.name === "new_medication_name") {

      return (
        <FlexGrid3Container className={'w-100 mt-'}>
          <FlexGrid3Item>
            {/* <InputLabelTop
              required
              readOnly={false}
              type="text"
              id="new_medication_name"
              {...useInputHookKeys.new_med_name}
              label={'New Medication Name'}
              maxlength={100}
            /> */}
            <InputLabelTop
              readOnly={false}
              type="text"
              id="medication_class"
              label={'New Medication Class'}
              {...useInputHookKeys.medication_class}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item>
            <InputLabelTop
              readOnly={false}
              type="text"
              id="supplier_brand_name"
              label={'Supplier Brand Name'}
              {...useInputHookKeys.supplier_brand_name}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item>
            <InputLabelTop
              readOnly={false}
              type="text"
              id="indication"
              label={'Indication'}
              {...useInputHookKeys.indication}
              maxlength={100}
            />
          </FlexGrid3Item>
          {/* <FlexGrid3Item>
            <InputLabelTop
              readOnly={false}
              type="text"
              id="medication_class"
              label={'New Medication Class'}
              {...useInputHookKeys.medication_class}
              maxlength={100}
            />
          </FlexGrid3Item> */}
        </FlexGrid3Container>
      );
    } else {
      return <Fragment />;
    }
  };

  const switchAdmTypeInput = props => {

    if (values.administration_type === "new_administration_type") {
      return (
        <FlexGrid3Item>
          <InputLabelTop
            required
            readOnly={false}
            type="text"
            id="new_administration_type"
            label={'New Administraion Type'}
            {...useInputHookKeys.new_administration_type}
            maxlength={100}
          />
        </FlexGrid3Item>
      );
    } else {
      return <Fragment />;
    }
  };
  const switchAdmReasonInput = props => {

    if (values.administration_reason === "new_administration_reason") {

      return (
        <div className="w-100 mr-1">
          <InputLabelTop
            required
            readOnly={false}
            type="text"
            id="new_administration_reason"
            label={'New Administration Reason'}
            {...useInputHookKeys.new_administration_reason}
            maxlength={100}
          />
        </div>
      )
    } else {
      return (
        <Fragment />
      )
    }
  };
  const switchAdmFormInput = props => {

    if (values.medication_form === "new_form") {

      return (
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
          <InputLabelTop
            required
            readOnly={false}
            type="text"
            id="new_form"
            label={'New Administration Form'}
            {...useInputHookKeys.new_form}
            maxlength={100}
          />
        </FlexGrid3Item>
      )
    } else {
      return (
        <Fragment />
      )
    }
  };
  const switchAdmRouteInput = props => {

    if (values.medication_route === "new_route") {

      return (
        <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
          <InputLabelTop
            required
            readOnly={false}
            type="text"
            id="new_route"
            label={'New Administration Route'}
            {...useInputHookKeys.new_route}
            maxlength={100}
          />
        </FlexGrid3Item>
      )
    } else {
      return (
        <Fragment />
      )
    }
  };

  useEffect(() => {
    if (values.alternative_administering_interventions && values.alternative_administering_interventions.length > 500) {
      // setErrorText('Ensure this field has no more than 500 characters.')
      values.alternative_field_error = true
    } else {
      values.alternative_field_error = false
    }
    if (values.healthcare_professional_guidance_criteria && values.healthcare_professional_guidance_criteria.length > 500) {
      values.professional_guidance_field_error = true
    } else {
      values.professional_guidance_field_error = false
    }
    setValues({ ...values })
  }, [values.alternative_administering_interventions, values.healthcare_professional_guidance_criteria])

  return (
    <div className={sectionStyle}>
      {/* <TitleSection>Service</TitleSection> */}
      <div className={'mt-2'}>
        <div className="d-flex mb-2">
          <div className="w-100 pr-1">
            {/* <Dropdown
             disabled={!editMode}
              items={choices.medication_name ? [{ id: 'new_medication_name', name: '+ New Medication Name' }, ...choices.medication_name] : []}
              onSelect={item => {
                dropDownHandleSelect({ name: item ? item : null }, 'name')
              }}
              placeHolder={'Medication Name'}
              value={values.name && contextChoicesMap && contextChoicesMap.medication_name && contextChoicesMap.medication_name[values.name]?.name}
              SecondValue={true}
              isObject
              allOption
            /> */}
            <DropdownMedication
              required
              {...useInput(`name`, 'isRequired')}
              disabled={!editMode}
              placeHolder={'Medication Name'}
              options={choices.medication_name ? [{ id: 'new_medication_name', name: '+ New Medication Name' }, ...choices.medication_name] : []}
              selectedOption={
                values.name ? values.name : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  { name: value ? value : null },
                  'name'
                )
              }
            />
          </div>
          {!(values.name === "new_medication_name") ?
            <div className="w-100 pr-1">
              <InputLabelTop
                disabled={true}
                label={'Medication Brand Name'}
                type="text"
                id={`med_brand_name`}
                // onKeyPress={KeyPress.INTEGER}
                {...useInputHookKeys.brand_name}
              />
            </div> : (
              <div className="w-100 pr-1">
                <InputLabelTop
                  required
                  readOnly={false}
                  type="text"
                  id="new_medication_name"
                  {...useInputHookKeys.new_med_name}
                  label={'New Medication Name'}
                  maxlength={100}
                />
              </div>
            )}

        </div >
        {switchMedicationNameInput()}
        <div className="d-flex" >
          <div className="w-100 pr-1">
            <DropdownSearchable
              required
              disabled={!editMode}
              {...useInputHookKeys.medication_type}
              placeHolder={'Medication Type'}
              options={choices.medication_type || []}
              selectedOption={
                values.medication_type ? values.medication_type : null
              }
              onChange={(value, label, obj) =>
                dropDownHandleSelect(
                  { medication_type: obj ? obj : null },
                  'medication_type'
                )
              }
            />
          </div>
          <div className="w-100 pr-1">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Expiry Reason'}
              options={choices.expiry_medication_reason || []}
              selectedOption={
                values.expiry_reason ? values.expiry_reason : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  { expiry_reason: value ? value : null },
                  'expiry_reason'
                )
              }
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="minimum_interval_between_doses"
              label={'Minimum interval between doses'}
              {...useInputHookKeys.minimum_interval_between_doses}
              maxlength={100}
            />
          </div>
        </div >

        <div className="d-flex" >
          <div className="w-100 pr-1">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="maximum_dose_in_24_hours"
              label={'Maximum dose in 24 hours'}
              {...useInputHookKeys.maximum_dose_in_24_hours}
              maxlength={100}
            />
          </div>
          <div className="w-100 pr-1">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="medication_for"
              label={'What is the medication for?'}
              {...useInputHookKeys.medication_for}
              maxlength={100}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="symptoms"
              label={'what are the symptoms to look out for?'}
              {...useInputHookKeys.symptoms}
              maxlength={100}
            />
          </div>
        </div >

        <div className="d-flex" >
          <div className="w-100 pr-1">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="client_needs_medication"
              label={' How will you know if the client will needs the medication?'}
              {...useInputHookKeys.client_needs_medication}
              maxlength={100}
            />
          </div>
          <div className="w-100 pr-1">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="medication_work_for"
              label={'How long should the medication work for?'}
              {...useInputHookKeys.medication_work_for}
              maxlength={100}
            />
          </div>
          <div className="w-100">

            <InputLabelTop
              readOnly={false}
              type="text"
              id="strength"
              label={'Strength'}
              {...useInputHookKeys.strength}
              maxlength={100}
            />
            <div></div>
          </div>
        </div >

        <div className="d-flex" >

          <div className="w-100 pr-1">
            <InputLabelTop
              readOnly={false}
              type="text"
              id="completed_by"
              label={'Completed by'}
              {...useInputHookKeys.completed_by}
              maxlength={100}
            />
          </div>
          <div className="w-100">
            <DateSelector
              disabled={!editMode}
              labelTop="Date"
              {...useInput(`date`)}
            />
          </div>
        </div >

        <div className="d-flex mb-2">
          <div className="w-100 pr-1">
            <div
              className={'pt-2 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              When should guidance sought from a healthcare professional?
            </div>
            <InputTextArea
              type="text"
              height={'4.4rem'}

              disabled={!editMode}
              // label={'Comments'}
              // onKeyPress={KeyPress.INTEGER}
              {...useInputHookKeys.guidance_from_healthcare}
            />
            {values.professional_guidance_field_error && <div
              className={'pt-1 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
              Ensure this field has no more than 500 characters.
            </div>
            }

          </div>

          <div className="w-100 pr-1">
            <div
              className={'pt-2 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              Describe any alternative interventions to consider before administering the medication.
            </div>
            <InputTextArea
              type="text"
              maxlength={5000}
              height={'4.4rem'}
              disabled={!editMode}
              // label={'Prescription Instructions'}
              // onKeyPress={KeyPress.INTEGER}
              {...useInputHookKeys.describe_any_alternative}
            />
            {values.alternative_field_error && <div
              className={'pt-1 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
              Ensure this field has no more than 500 characters.
            </div>}
          </div>
        </div>

        <FlexGrid3Container className={'w-100 mt-2'}>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Route'}
              options={choices.medication_route ? [{ id: 'new_route', name: '+ New Route' }, ...choices.medication_route] : []}
              selectedOption={values.medication_route ? values.medication_route : null}
              onChange={value =>
                dropDownHandleSelect(
                  { medication_route: value ? value : null },
                  'medication_route'
                )
              }
            />
          </FlexGrid3Item >
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Form'}
              options={choices.medication_form ? [{ id: 'new_form', name: '+ New Form' }, ...choices.medication_form] : []}
              selectedOption={values.medication_form ? values.medication_form : null}
              onChange={value =>
                dropDownHandleSelect(
                  { medication_form: value ? value : null },
                  'medication_form'
                )
              }
            />
          </FlexGrid3Item >
          {switchAdmRouteInput()}
          {switchAdmFormInput()}

          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Dose Regime.'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('dose_regime')}
            />
          </FlexGrid3Item>
          {/* <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Prescription Instructions'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('medication_instructions')}
            />
          </FlexGrid3Item> */}
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Frequency'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('frequency')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Important.'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('important')}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Prescription volume'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('prescription_volume')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={' Reason for'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('reason')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Potential Side Effects'}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('potential_side_effects')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <InputLabelTop
              disabled={!editMode}
              label={'Storage Location '}
              type="text"
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('storage_location')}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>
        <div className="d-flex mb-2">
          <div className="w-100 pr-1">
            <div
              className={'pt-2 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              Comments
            </div>
            <InputTextArea
              type="text"
              height={'4.4rem'}
              disabled={!editMode}
              label={'Comments'}
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('comments')}
            />
          </div>
          <div className="w-100 pr-1">
            <div
              className={'pt-2 ml-md-1 ml-0'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              Prescription Instructions
            </div>
            <InputTextArea
              type="text"
              height={'4.4rem'}
              disabled={!editMode}
              label={'Prescription Instructions'}
              // onKeyPress={KeyPress.INTEGER}
              {...useInput('medication_instructions')}
            />
          </div>
        </div>
        <FlexGrid3Container className={'w-100 mt-2'}>
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <DateSelector
              isRequired
              disabled={!editMode}
              labelTop="Start Date"
              {...useInput('start_date', 'isRequired')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-1 pl-0 pb-2">
            <DateSelector
              disabled={!editMode}
              labelTop="End Date"
              {...useInput(`expiry_date`)}
            />
          </FlexGrid3Item>
          {!(values.medication_type_name === 'PRN') &&
            <>
              <FlexGrid3Item className="pr-1 pl-0 pb-2">
                <DropdownSearchable
                  disabled={!editMode}
                  required
                  {...useInputHookKeys.everyHook}
                  placeHolder={'Repeat Every'}
                  options={frequencyName == 'Daily' ? [
                    { id: HOUR, name: 'Hour' },
                    { id: MINUTE, name: 'Minute' },
                    { id: EVERY_DAY, name: 'Day' },
                    { id: EVERY_WEEK, name: 'Week' },
                    { id: EVERY_MONTH, name: 'Month' },
                    { id: EVERY_YEAR, name: 'Year' },

                  ] :
                    [
                      { id: HOUR, name: 'Hour' },
                      { id: MINUTE, name: 'Minute' },
                      { id: EVERY_DAY, name: 'Day' },
                      { id: EVERY_WEEK, name: 'Week' },
                      { id: EVERY_MONTH, name: 'Month' },
                      { id: EVERY_YEAR, name: 'Year' },

                    ]
                  }
                  selectedOption={frequencyName == 'Daily' ? EVERY_DAY : (values.every ? values.every : null)}
                  onChange={value =>
                    dropDownHandleSelect({ every: value ? value : null }, 'every')
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
                <InputLabelTop
                  disabled={!editMode}
                  label={'Repeat No.'}
                  id={`repeat`}
                  onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
                  {...useInputHookKeys.repeatOnHook}
                  maxlength={5}
                />
              </FlexGrid3Item >
              <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
                <DropdownSearchable
                  required
                  disabled={!editMode}
                  {...useInputHookKeys.endTypeHook}
                  // data_error={errors.indexOf(`end_type`)}
                  //   disabled={readOnly}
                  placeHolder={'End'}
                  options={[
                    { id: END_TYPE_ON, name: 'On' },
                    { id: END_TYPE_AFTER, name: 'After' },
                    { id: END_TYPE_NEVER, name: 'Never' },
                  ]}
                  selectedOption={values.end_type ? values.end_type : null}
                  onChange={value =>
                    dropDownHandleSelect(
                      { end_type: value ? value : null },
                      'end_type'
                    )
                  }
                />
              </FlexGrid3Item>
            </>
          }
          <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Medication Assistance'}
              options={choices.medication_assistance || []}
              selectedOption={values.medication_assistance ? values.medication_assistance : null}
              onChange={value =>
                dropDownHandleSelect({ medication_assistance: value ? value : null }, 'medication_assistance')
              }
            />
          </FlexGrid3Item >
        </FlexGrid3Container >
        <FlexGrid3Container className={'w-100 mt-2'}>
          {idSelectedMed && <FlexGrid3Item className="pr-1 pl-0 pb-2">
            <DateSelector
              disabled={!editMode}
              labelTop="Effective Date"
              {...useInputHookKeys.effectiveDateHook}
            />
          </FlexGrid3Item>}
          {!(values.medication_type_name === 'PRN') && <FlexGrid3Item >
            {switchEndValueInput({
              ...useInputHookKeys.endValueHook,
            })}
          </FlexGrid3Item>}
        </FlexGrid3Container >

      </div >
      <TitleSection>Administration</TitleSection>
      <div className="d-flex mt-2">
        <div className="w-100 pr-1">
          <DropdownSearchable
            disabled={!editMode}
            placeHolder={'Administration Reason'}
            options={choices.administration_reason ? [{ id: 'new_administration_reason', name: '+ New Administration Reason' }, ...choices.administration_reason] : []}
            selectedOption={values.administration_reason ? values.administration_reason : null}
            onChange={value =>
              dropDownHandleSelect(
                { administration_reason: value ? value : null },
                'administration_reason'
              )
            }
          />
        </div>
        <div className="w-100 pr-1">
          <DropdownSearchable
            disabled={!editMode}
            placeHolder={'Administration Type'}
            options={choices.administration_type ? [{ id: 'new_administration_type', name: '+ New Administration Type' }, ...choices.administration_type] : []}
            selectedOption={values.administration_type ? values.administration_type : null}
            onChange={value =>
              dropDownHandleSelect(
                { administration_type: value ? value : null },
                'administration_type'
              )
            }
          />
        </div>
        <div className="w-100 pr-1">
          <InputLabelTop
            disabled={!editMode}
            label={'Administration area'}
            type="text"
            // onKeyPress={KeyPress.INTEGER}
            {...useInput('administration_area')}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        {switchAdmTypeInput()}
        {switchAdmReasonInput()}

      </div>

    </div >
  );
};

AddMedicationDose.propTypes = {
  useInput: PropTypes.func.isRequired,
  sectionStyle: PropTypes.string,
  nameSection: PropTypes.string.isRequired,
};

AddMedicationDose.defaultProps = {
  sectionStyle: '',
};

export default AddMedicationDose;
