import React, { useState, useEffect, useContext } from 'react';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import {
  Row,
  Col,
} from 'components/SharedComponents/Table/styles';
import Pagination from 'components/SharedComponents/Pagination';
import { headerColumns, headerColumnsSelector, headerColumnsSelectorByDefault } from './TableHeaders/BillingSummaryHeaders';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import queryString from 'query-string';
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { useForm } from 'hooks/FormHook';
import { getBillingSummary, billSummaryExport } from 'utils/api/finance'
import { AppContext, BranchItemsContext } from "context";
import { useHistory, useLocation } from 'react-router-dom';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getClientFundersList } from 'utils/api/ClientApi';
import { bill_summary_choices } from 'utils/choiceConstant';
import NoSelectAllDropdownFilterTable from 'components/SharedComponents/NoSelectAllDropdownFilterTable';
import { finance_billSummary_choices } from 'utils/choiceConstant';


const BillingSummary = (props) => {
  const [summaryData, setSummaryData] = useState([])
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientStatusFilter, setClientStatusFilter] = useState([])
  const [funderSourceFilter, setFunderSourceFilter] = useState([])
  const [clientFilter, setClientFilter] = useState([])
  const [clientOptions, setClientOptions] = useState([])
  const [funderNameFilter, setFunderNameFilter] = useState([])
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])
  const [visitStatusFilter, setVisitStatusFilter] = useState([])
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([])
  const [billPatternFilter, setbillPatternFilter] = useState([])
  const [adhocAmount, setAdhocAmount] = useState(0);
  const [scheduleAmount, setScheduleAmount] = useState(0);
  const [scheduleDuration, setScheduleDuration] = useState(0);
  const [adhocDuration, setAdhocDuration] = useState(0);
  const { contextChoices: choices, handleGetChoices } = useContext(AppContext)
  const { values, setValues, useInput, errors, isValid, setCheckRequires } = useForm({});
  const [funderList,setFunderList]=useState([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalExport, setLoadingModalExport] = useState(false);

  const [runTableOptionData, setrunTableOptionData] = useState(headerColumnsSelectorByDefault.map((val)=>(val.id)));
  const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumnsSelectorByDefault);
  const [branchFilter, setBranchFilter] = useState([]);

  const history = useHistory();
  const { branchData } = useContext(BranchItemsContext);

  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
    values.limitperpage,
    props.location.search,
    values.ordering
  ]);

  useEffect(()=>{
    const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/finance/billing/summary',
        search: stringified,
      });
  },[])
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])

  useEffect(() => {
    handleGetChoices(bill_summary_choices)    
  }, [])

  useEffect(() => {
    if (choices.client_listing) {
      let temp = choices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      })
      setClientOptions(temp);
    }
  }, [choices.client_listing])

  const funderLists=()=>{
    getClientFundersList(values)
    .then(res=>{
      if(res.data && (res.data.length > 0)){
       let newFunders=res.data.map(funder=>{
         return {
           id:funder.id,
           is_client: funder.is_client,
           name:funder.name
         }
       })
       setFunderList([...newFunders])
      }
    })
  }

  useEffect(()=>{
    if(values.date_from && values.date_to){
      funderLists();
    }
  },[values.date_from, values.date_to])


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  const handleClick = () => {

    const pageToSearch = getPageToSearchParam(props.location.search);
    if (!values.date_from || !values.date_to) {

      return
    }

    else {
      setLoadingModal(true)
      getBillingSummary({ ...values, ...pageToSearch,...branchData })
        .then(res => {
          if (res) {
            setLoadingModal(false);
          }
          setSummaryData(res.results)

          setAdhocAmount(res.total_adhoc_billing_amount?.toFixed(2));
          setScheduleAmount(res.total_schedule_billing_amount?.toFixed(2));
          setScheduleDuration(res.total_schedule_duration ? (res.total_schedule_duration/3600)?.toFixed(2) : 0);
          setAdhocDuration(res.total_adhoc_duration ? (res.total_adhoc_duration/60)?.toFixed(2) : 0);
          setPages(Math.ceil(res.count / values.limitperpage));
        })

    }

  }
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/finance/billing/summary",
      search: stringified,
    });
    // setValues({...values,...item})
    values.limitperpage = item[namesection]
    // setValues({...values});
    setValues({ ...values, ...item })
  }
  const handleExport = () => {
    setLoadingModalExport(true)
    const pageToSearch = getPageToSearchParam(props.location.search);
    billSummaryExport({ ...values, ...pageToSearch,...branchData }, {column_selector: runTableOptionData}).then(response => {
      setLoadingModalExport(false)
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `bill_summary_${values.date_from}_${values.date_to}`;
        a.click();
      })
    }
    )

  }

  const dropDownHandleSelect = (item) => {
    // const urlParams = queryString.parse(props.location.search);
    // urlParams[PAGINATION_PARAM] = 1;
    // const stringified = queryString.stringify(urlParams);
    // history.push({
    //   pathname: "/finance/billing/summary",
    //   search: stringified,
    // });
    if (item.client_service_visit__client__id__in) {
      item.client_service_visit__client__id__in = item.client_service_visit__client__id__in.length === (clientOptions ? clientOptions : 0).length || item.client_service_visit__client__id__in.length === 0 ? [] : item.client_service_visit__client__id__in
    }
    setValues({ ...values, ...item })
  }

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };


  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'} style={{ flexWrap: 'wrap', gap: "0.5rem" }}>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.client_status ? choices.client_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_status: items });
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={(items) => setClientStatusFilter([...items])}
                    placeHolder={'Client Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={clientOptions ? clientOptions : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_service_visit__client__id__in: items });
                    }}
                    itemsSelected={clientFilter}
                    setSelectedFilter={(items) => setClientFilter([...items])}
                    placeHolder={'Clients'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
               
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={funderList ? funderList : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ funder_name: items });
                    }}
                    itemsSelected={funderNameFilter}
                    setSelectedFilter={(items) => setFunderNameFilter([...items])}
                    placeHolder={'Funder Name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__client_service_visit__client_service__service_funders__category__id__in: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                    placeHolder={'Funder Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.visit_status ? choices.visit_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit_status: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={(items) => setVisitStatusFilter([...items])}
                    placeHolder={'Visit Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.service_category ? choices.service_category : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__service_category__id__in: items });
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={(items) => setServiceCategoryFilter([...items])}
                    placeHolder={'Service Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.Billing_patterns ? choices.Billing_patterns : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ bill_pattern: items });
                    }}
                    itemsSelected={billPatternFilter}
                    setSelectedFilter={(items) => setbillPatternFilter([...items])}
                    placeHolder={'Bill Pattern'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <PrimaryButtonForm onClick={handleClick} disabled={loadingModal}>
                 <div className='d-flex'>
                 <span className={'ml-2 mr-2 font-weight-bold'}>Search</span>
                  {/* {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )} */}
                 </div>
                </PrimaryButtonForm>
                {summaryData.length > 0 &&
                  <PrimaryButton
                  type="button"
                  className="ml-2"
                  onClick={handleExport} disabled={loadingModalExport || loadingModal}>
                  <span className={'ml-2 mr-2 font-weight-bold'}>Export</span>
                {!loadingModalExport ? (
                          null
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                          />
                        )}
                </PrimaryButton>
                }
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      <ComponentDynamic loading={loadingModal} >
      
        {
          summaryData.length > 0 &&
          <>
         <div className='d-flex justify-content-between'>
         <DropdownTypesContainer>
            <NoSelectAllDropdownFilterTable
              items={headerColumnsSelector ? headerColumnsSelector : []}
              setItemsSelected={items => {
                dropDownHandleSelectTable({ tableDropdown: items });
              }}
              itemsSelected={dropdownArraySelected}
              setSelectedFilter={(item) => setDropdownArraySelected([...item])}
              placeHolder={'Column'}
              isObject
              allOption

            />
          </DropdownTypesContainer>
            <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-md-end">

              <div className="data-container">
                <div className="mr-2">
                  <p>{'Sch Hrs: ' + scheduleDuration}</p>
                </div>
                <div className="mr-2">
                  <p>{'Actual Hrs: ' + adhocDuration}</p>
                </div>
                <div className="mr-2">
                  <p>{'Sch Amt: ' + scheduleAmount}</p>
                </div>
                <div className="mr-2">
                  <p>{'Actual Amt: ' + adhocAmount} </p>
                </div>

              </div>
            </DataContainer>
         </div>
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={true}
                noEditOption={true}
                noNeed={false}
                fontSize="0.75rem"
                values={runTableOptionData}
                coloumnSelectorHeader={headerColumnsSelector}
                callBackQuerySort={setQuerySort}>
                {summaryData.map((sum, index) => {
                  //              let tempData=[...sum.data,{}]
                  //              let dataLength=tempData.length
                  //              return (
                  //                tempData && tempData.map((innerData,innerIndex)=>{
                  //                   if(innerIndex==0){
                  //                    return (
                  //                      <Row bgColor Shadow>
                  //                           <Col
                  //                      max_width={LARGE_COL}
                  //                      Shrink
                  //                      reducePadding
                  //                      Capitalize
                  //                      Center
                  //                      >
                  //                       {sum.funder_name  }
                  //                    </Col>
                  //                    <Col
                  //                      max_width={LARGE_COL}
                  //                      Shrink
                  //                      reducePadding
                  //                      Capitalize
                  //                      Center
                  //                      >
                  //                     {sum.client_name  }
                  //                    </Col>
                  //                        <Col
                  //                          max_width={LARGE_COL}
                  //                        >
                  //                          <div>
                  //                            <div style={{ fontWeight: "bold" }}>{innerData.schedule_billing_amount}</div>
                  //                            <div style={{ fontWeight: "lighter" }}>{innerData.duration ? innerData.duration : ""}</div>
                  //                          </div>
                  //                        </Col>
                  //                        <Col
                  //                          max_width={LARGE_COL}
                  //                        >
                  //                          <div>
                  //                            <div style={{ fontWeight: "bold" }}>{innerData.adhoc_billing_amount}</div>
                  //                            <div style={{ fontWeight: "lighter" }}>{innerData.duration ? innerData.duration : ""}</div>
                  //                          </div>
                  //                        </Col>
                  //                        </Row>
                  //              
                  //                    )
                  //                      }
                  //                      else if(innerIndex!==0 && innerIndex!=dataLength-1){
                  //                        return (
                  //                          <Row bgColor Shadow>
                  //                           <Col
                  //                      max_width={LARGE_COL}
                  //                      Shrink
                  //                      reducePadding
                  //                      Capitalize
                  //                      Center
                  //                      >
                  //                      {/* {sum.client_name  } */}
                  //                    </Col>
                  //                    <Col
                  //                      max_width={LARGE_COL}
                  //                      Shrink
                  //                      reducePadding
                  //                      Capitalize
                  //                      Center
                  //                      >
                  //                      {/* {sum.funder_name  } */}
                  //                    </Col>
                  //                            <Col
                  //                              max_width={LARGE_COL}
                  //                            >
                  //                              <div>
                  //                                <div style={{ fontWeight: "bold" }}>{innerData.schedule_billing_amount}</div>
                  //                                <div style={{ fontWeight: "lighter" }}>{innerData.duration ? innerData.duration : ""}</div>
                  //                              </div>
                  //                            </Col>
                  //                            <Col
                  //                              max_width={LARGE_COL}
                  //                            >
                  //                              <div>
                  //                                <div style={{ fontWeight: "bold" }}>{innerData.adhoc_billing_amount}</div>
                  //                                <div style={{ fontWeight: "lighter" }}>{innerData.duration ? innerData.duration : ""}</div>
                  //                              </div>
                  //                            </Col>
                  //                        </Row>
                  //                        )
                  //                      }
                  //                      else{
                  return (
                    <Row bgColor Shadow>
                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                        className={!(runTableOptionData && runTableOptionData.includes("funder_name")) && 'd-none'}
                      >
                        {sum.funder_name}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                        className={!(runTableOptionData && runTableOptionData.includes("client_name")) && 'd-none'}
                      >
                        {<span >{sum.client_name}</span>}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className={!(runTableOptionData && runTableOptionData.includes("schedule_amount")) && 'd-none'}
                      >
                        <div>
                          <div style={{ fontWeight: "bold" }}>{sum.total_schedule_billing_amount}</div>
                          <div style={{ fontWeight: "lighter" }}>{sum.total_schedule_duration ? sum.total_schedule_duration : ""}</div>
                        </div>
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className={!(runTableOptionData && runTableOptionData.includes("actual_amount")) && 'd-none'}
                      >
                        <div>
                          <div style={{ fontWeight: "bold" }}>{sum.total_adhoc_billing_amount}</div>
                          <div style={{ fontWeight: "lighter" }}>{sum.total_adhoc_duration ? sum.total_adhoc_duration : ""}</div>
                        </div>
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className={!(runTableOptionData && runTableOptionData.includes("bill_pattern")) && 'd-none'}
                      >
                        {sum.bill_pattern}
                    
                      </Col>
                    </Row>
                  )
                  //                      }
                  //                   
                  //                  })


                  //              )  
                })}
                <div className="mt-3">
                  <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={20} dataLength={summaryData.length} />
                </div>
              </Table>
            </>
          </>
        }
      </ComponentDynamic>
    </div>
  );
}

export default BillingSummary;