import React, { useState, useEffect } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumns';
import {
  P_W_Zero,
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  DayPill,
  ColsGrouper,

} from 'components/SharedComponents/Table/styles';
import _ from 'lodash'
import moment from 'moment'
const MedicationListView = ({
  events,
  handleSelectChange,
  setCheckedRows,
  checkedRows,
  setCheckedValue,
  checkedValue,
  choices,
  runTableOptionData,
  eventClickHandler =()=>{},
  displayMenu
}) => {
  const [loadingModal, setLoadingModal] = useState(false)
  const [sortedEvents, setSortedEvents] = useState(events)




  useEffect(() => {
    setCheckedRows([])
    let actualEvents = events.filter(ev => ev.id)
    let tempEvents = actualEvents.sort((a, b) => {
      let da = new Date(a.start),
        db = new Date(b.start);
      return da - db;
    })
    setSortedEvents(tempEvents)
  }, [events])

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      events.map((visit) => {
        if (!visitExists(visit.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setCheckedValue(!checkedValue)
    //  let tempChecked=[]
    // if(e.target.checked){
    //   events.forEach(ev=>{
    //     tempChecked.push(ev.id)
    //   })
    // }

    // setCheckedRows([...tempChecked])

    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      events.map((visit) => {
        checkedRows.push(visit.id)
      })
    } else {
      events.map((visit) => {
        checkedRows.map((item, index) => {
          if (item === visit.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }
  console.log('visits', sortedEvents);
  return (
    <div className='mt-5'>
      <ComponentDynamic
        loading={loadingModal}>
        <Table values={runTableOptionData} headerColumns={headerColumns} handleAllCheck={handleAllCheck} handleAllSelectChange={handleAllSelectChange} headerPadding={false} noEditOption={true} noNeed={false}>
          <div >
            {
              sortedEvents && sortedEvents.map((visit, index) => {
                return (
                  <div onContextMenu={(e)=>{
                    e.preventDefault();
                    displayMenu(e, visit.id)
                  }}>
                    <Row bgColor Shadow>
                      <ColsGrouper
                        className="d-flex"
                      >
                        <Col
                          max_width={EX_SMALL_COL4}
                          Shrink={false}
                          NoFlexGrow={true}
                          reducePadding
                          Capitalize
                          Center
                          // className={!(runTableOptionData && runTableOptionData.includes("checkbox")) && 'd-none' }
                        >
                          <input class="form-check-input" type="checkbox"
                            checked={checkedRows.includes(visit.id)}
                            id="flexCheckDefault"
                            onChange={(e) => { handleSelectChange(e, visit) }}
                          />

                        </Col>
                        <ColsGrouper
                          className="d-flex"
                          onClick={() => {
                            eventClickHandler({ id: visit.id })
                          }}>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("client_name")) && 'd-none' }
                          >
                            {visit.client_name
                              ? visit.client_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("employee_name")) && 'd-none' }
                          >
                            {visit.employee_name
                              ? visit.employee_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_name")) && 'd-none' }
                          >
                            {visit.medication_name ?
                              visit.medication_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_dose")) && 'd-none' }
                          >
                            {visit.dose_name}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("schedule_start_date")) && 'd-none' }
                          >
                            {visit.start ? moment(visit.start).format('YYYY-MM-DD') : ''}
                          </Col>
                          {/* <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                          >

                            {
                              visit.end ? moment(visit.end).format('YYYY-MM-DD') : ''}
                          </Col> */}
                           <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("planned_dose_time")) && 'd-none' }
                          >
                            {visit.medication_dose_time ? visit.medication_dose_time : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("actual_dose_time")) && 'd-none' }
                          >
                            {visit.actual_medication_dose_time ? visit.actual_medication_dose_time : ''}
                          </Col>
                         
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_type")) && 'd-none' }
                          >
                            {visit.medication_type_name ?
                              visit.medication_type_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_status")) && 'd-none' }
                          >
                            {visit.medication_status_name ?
                              visit.medication_status_name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_reason")) && 'd-none' }
                          >
                            {visit.medication_reason ?
                              visit.medication_reason
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("medication_feedback")) && 'd-none' }
                          >
                            {visit.feedback ?
                              visit.feedback
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("review_status__name")) && 'd-none' }
                          >
                            {visit.review_status__name ?
                              visit.review_status__name
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("review_comment")) && 'd-none' }
                          >
                            {visit.review_comment ?
                              visit.review_comment
                              : ''}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("review_date")) && 'd-none' }
                          >
                            {visit.review_date ?
                              visit.review_date
                              : ''}
                          </Col>
                         
                          <Col
                            max_width={LARGE_COL}
                            overlap={true}
                            Shrink
                            reducePadding
                            Capitalize
                            Center
                            className={!(runTableOptionData && runTableOptionData.includes("review_time")) && 'd-none' }
                          >
                            {visit.review_time ?
                              visit.review_time
                              : ''}
                          </Col>
                        </ColsGrouper>
                      </ColsGrouper>
                    </Row>
                  </div>
                )
              })
            }
          </div>
        </Table>
      </ComponentDynamic>
    </div>
  );
}

export default MedicationListView;
