import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './notificationStyle.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import {
  getNotificationSettings,
  getNotificationSettingsSections,
  updateNotificationSettings,
  updateNotificationSettingAll,
} from 'utils/api/NotificationSettingsApi';
import { AppContext } from "context"
import Spinner from 'components/SharedComponents/Spinner';
import SettingTable from './SettingTable';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { NOTIFICATION_SETTING, NOTIFICATION_TAB, SELF_WEB_CREATE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { useContext } from 'react';

function NotificationSettings() {
  const history = useHistory();
  const toast = useRef();

  const [selectEmailAll, setSelectEmailAll] = useState(false);
  const [selectDesktopAll, setSelectDesktopAll] = useState(false);
  const [selectInAppAll, setSelectInAppAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupedData, setGroupedData] = useState();
  const [readNotificationTab, setReadNotificationTab] = useState(true);
  const [readNotificationSettingTab, setReadNotificationSettingTab] = useState(true);
  const { contextChoices: choices, contextPermissions, contextIsAdmin, contextIsSuperAdmin } = useContext(AppContext);


  // Fetch notification section data
  const notificationSectionsData = async () => {
    try {
      const res = await getNotificationSettingsSections()
      return res
    } catch (error) {

    }
  };

  // Fetch notification setting data
  const notificationSettingData = async () => {
    try {
      const res = await getNotificationSettings()
      return res
    } catch (err) {

    }
  };

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin == true || contextIsSuperAdmin == true) {
      setReadNotificationTab(true);
      setReadNotificationSettingTab(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_READ_PERMISSION
          ] = item?.[SELF_WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] = item?.[SELF_WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] = item?.[SELF_WEB_UPDATE_PERMISSION];
        }

        );
        if (rolePermissions[NOTIFICATION_TAB]) {
          setReadNotificationTab(
            rolePermissions[NOTIFICATION_TAB][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );

        }
        if (rolePermissions[NOTIFICATION_SETTING]) {
          setReadNotificationSettingTab(
            rolePermissions[NOTIFICATION_SETTING][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        if (!(rolePermissions[NOTIFICATION_TAB][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ])) {
          history.push('/dashboard-notification-settings')
        }
      }

    }

  }, [contextPermissions, contextIsSuperAdmin, contextIsAdmin])


  const notificationSettingMain = async () => {
    setIsLoading(true);
    const section = await notificationSectionsData();
    const settings = await notificationSettingData();

    let res = section?.results?.map(element => {
      let findId = settings?.results?.find(el => el.item == element.id);
      return {
        item: findId ? findId.item : element.id,
        id: findId ? findId.id : '',
        name: element.name,
        isDesktop: findId ? findId.is_desktop : false,
        isEmail: findId ? findId.is_email : false,
        isInApp: findId ? findId.is_in_app : false,
        category: element.category,
      };
    });
    setCheckboxes(res || []);
    setIsLoading(false);
  };

  useEffect(() => {
    notificationSettingMain();
  }, []);

  const updateAllSettings = async (body, allChecked) => {
    let res = await updateNotificationSettingAll(body);
    if (res.status) {
      setCheckboxes(allChecked || []);
      setSelectEmailAll(!selectEmailAll);
      let updateCheckboxes = checkboxes.map(element => {
        let findId = res.data.find(el => el.item == element.item);
        return {
          item: findId ? findId.item : element.item,
          id: findId ? findId.id : element.id,
          name: element.name,
          isDesktop: findId ? findId.is_desktop : element.isDesktop,
          isEmail: findId ? findId.is_email : element.isEmail,
          isInApp: findId ? findId.is_in_app : element.isInApp,
          category: element.category,
        };
      });
      setCheckboxes(updateCheckboxes || []);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${res?.message}`,
      });
    }
  }

  const handleAllCheck = async name => {
    switch (name) {
      case 'email':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isEmail: !selectEmailAll,
          }));
          const isEmail = allChecked.every(el => el.isEmail);
          const body = { is_email: isEmail };
          updateAllSettings(body, allChecked)
        }
        break;
      case 'desktop':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isDesktop: !selectDesktopAll,
          }));
          const isDesktop = allChecked.every(el => el.isDesktop);
          const body = { is_desktop: isDesktop };
          updateAllSettings(body, allChecked)
        }
        break;
      case 'inapp':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isInApp: !selectInAppAll,
          }));
          const isInApp = allChecked.every(el => el.isInApp);
          const body = { is_in_app: isInApp };
          updateAllSettings(body, allChecked)
        }
        break;
      default:
        return null;
    }
  };

  const updateList = async (value, data, allSelect) => {
    const body = {
      item: value.item,
      is_email: value.isEmail,
      is_desktop: value.isDesktop,
      is_in_app: value.isInApp,
    };
    if (value.id) {
      body.id = value.id;
    }
    let res = await updateNotificationSettings(body);
    if (res.status) {
      let data = checkboxes.map(item =>
        item.item === res?.data?.item
          ? {
            ...item,
            item: res.data.item,
            id: res.data.id,
            isDesktop: res.data.is_desktop,
            isEmail: res.data.is_email,
            isInApp: res.data.is_in_app,
          }
          : item
      );
      setSelectInAppAll(allSelect);
      setCheckboxes(data || [])
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${res?.message}`,
      });
    }
  };

  const updateOneByOne = (id, name) => {
    switch (name) {
      case 'email':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isEmail: !item.isEmail } : item
          );
          // setCheckboxes(data)
          const allSelect = data.every(item => item.isEmail);
          // setSelectEmailAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      case 'desktop':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isDesktop: !item.isDesktop } : item
          );
          // setCheckboxes(data);
          const allSelect = data.every(item => item.isDesktop);
          // setSelectDesktopAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      case 'inapp':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isInApp: !item.isInApp } : item
          );
          // setCheckboxes(data);
          const allSelect = data.every(item => item.isInApp);
          // setSelectDesktopAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      default:
        return null;
    }
  };

  const handleEmailChange = (id, name) => {
    updateOneByOne(id, name);
  };

  useEffect(() => {
    const isEmail = checkboxes?.every(el => el.isEmail);
    const isDesktop = checkboxes?.every(el => el.isDesktop);
    const isInApp = checkboxes?.every(el => el.isInApp);
    setSelectDesktopAll(isDesktop);
    setSelectEmailAll(isEmail);
    setSelectInAppAll(isInApp);

    const groupBy = checkboxes?.reduce((acc, cur) => {
      const { category } = cur;
      acc[category] = acc[category] || [];
      cur['category'] = category;
      acc[category].push(cur);
      return acc;
    }, {});

    if (groupBy) {
      const keys = Object.keys(groupBy);
      const formatedData = Object.values(groupBy).map((el, i) => {
        return { category: keys[i], items: el };
      });
      setGroupedData(formatedData);
    }

  }, [checkboxes]);

  return (
    <div >
      <Toast ref={toast} position="top-right" />
      {readNotificationSettingTab ?
        (isLoading ? (
          <Spinner />
        ) : checkboxes.length ? (
          <div className="w-100 bg-white p-2">
            <SettingTable
              groupedData={groupedData}
              handleAllCheck={handleAllCheck}
              selectEmailAll={selectEmailAll}
              selectDesktopAll={selectDesktopAll}
              selectInAppAll={selectInAppAll}
              handleEmailChange={handleEmailChange}
            />
          </div>
        ) : (
          <div className="w-100 mt-3 d-flex justify-content-center">
            <div className="text-danger">No data found</div>
          </div>
        ))
        :
        <div className="w-100 mt-3 d-flex justify-content-center">
          <div className="text-danger">No permissions given for notification access !</div>
        </div>
      }
    </div>
  );
}

export default NotificationSettings;
