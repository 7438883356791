import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { StylePointer } from 'shared/styles/constants/tagsStyles';
import { Title } from './styles';
import { KEY_WORDS } from '..';

const HeaderTable = ({ selectedRol, handleOnClick, createPermission }) => {
  return (
    <tr>
      <th scope="col" className={'border sticky-col first-col'}>
        <div className={'d-flex align-items-center'}>
          {createPermission && (
            <StylePointer onClick={() => handleOnClick({}, 'add', KEY_WORDS.ASSESSMENT)} style={{ paddingRight: 10 }}>
              <FaPlus color={PURPLE} size={20} />
            </StylePointer>)}
          New Assessment
        </div>
      </th>
      <th scope="col" className={'text-center border all-cols'}>
        <Title>Edit</Title>
      </th>
      <th scope="col" className={'text-center border all-cols'}>
        <Title>Delete</Title>
      </th>
    </tr>
  );
};

export default HeaderTable;
