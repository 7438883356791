import {MEDIUM_COL,} from 'shared/styles/constants/columns';
import {
  LARGE_COL,
  SMALL_COL
} from "../../../../shared/styles/constants/columns";

export const headerColumns = [
  {
    label: 'Full Name',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'type',
    name: 'user_type',
    status: 0,
    className:  '',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Status',
    name: 'user_type',
    status: 0,
    className:  '',
    max_width: MEDIUM_COL,
  },
  {
    label: 'role',
    name: 'role',
    status: 0,
    className: 'sm-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Company',
    name: 'company',
    status: 0,
    className: 'sm-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Email',
    name: 'email',
    status: 0,
    className: 'md-hidden',
    max_width: LARGE_COL,
  },
];
