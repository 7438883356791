import React,{useState,useEffect,Fragment} from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { PrimaryButtonForm} from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router';
import {
    setMedicationNotes,
    getMedicationNotes
  } from 'utils/api/ClientApi';
  import {
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

const MedicationNotes = ({
    // cliId,
    selectedClientId,
    notesDet,
    setNotesDet,
    readPermissionMedicationNotes,
    createPermissionMedicationNotes,
    updatePermissionMedicationNotes,
}) => {
    const[modalLoading,setModalLoading]=useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const { values, setValues, useInput, isValid, errors,setFormErrors, setCheckRequires } =
    useForm({});
    const history=useHistory()
    const location=useLocation()
    useEffect(()=>{
        if(selectedClientId || notesDet){
          loadEmpNotes()
        }
       else{
          setModalLoading(false)
        }
      },[])

      const handleSubmit = (evt) => {
        evt.preventDefault();
        if (loadingSave) {
          return;
        }
    
        let body = {
          ...values,
        };
        
        if ((!isValid && errors.length > 0) ) {
          setCheckRequires(true);
          setShowError(true);
          setErrorMessage(InvalidOrEmptyValues);
          return;
        }
    
          setLoadingSave(true);
        setCheckRequires(false);
        setShowError(false);
        let value = [];
        // let clientId=''
        // if(location.pathname.includes('/client/add')){
        //   clientId=cliId
        // }else{
        //   clientId=selectedClientId
        // }
        setMedicationNotes(body,selectedClientId)
          .then(response => {
            if (response.statusCode === 400 || response.statusCode === 405) {
    
              if (response.message) {
                value = Object.values(response.message)[0];
              }
              throw new Error(value);
            }
    
            setShowError(false);
            onSuccess(response);
          })
          .catch(onError);
      };
    
      const onSuccess = async (response) => {
        if (response.statusCode === 200 || response.statusCode === 201) {   
          // let clientId=''
          // if(location.pathname.includes('/client/add')){
          //   clientId=cliId
          // }else{
          //   clientId=selectedClientId
          // }
          setNotesDet(selectedClientId)
          setLoadingSave(false);
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
        } 
      };
    
      const onError = err => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(
          "Error in saving Medication Notes"
        );
      };

      
      const loadEmpNotes=async ()=>{
        let id=selectedClientId?selectedClientId:notesDet
        const response=await getMedicationNotes(id)
      if(response){
       values.medication_notes=response.data && response.data
       
        setValues({...values})
      }
      setModalLoading(false)
      }

const medication_notes_hook = useInput('medication_notes')
    return (
        <div>
              <ComponentDynamic
          loading={modalLoading}
        >
            {(selectedClientId ? readPermissionMedicationNotes : createPermissionMedicationNotes) ?
        <>
        <div className="w-100">
            <InputTextArea
              type="text"
              height={'14.4rem'}
              id="emp_desc"
              label={'Notes'}
              {...medication_notes_hook}
            //   maxlength={5}
            />
          </div>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  className={'mr-2'}
                  onClick={() => {
                    history.push('/client')
                  }}>
                  <span className={'mr-2'}>{'Cancel'}</span>
                </PrimaryButtonForm>
                {(selectedClientId ? updatePermissionMedicationNotes : true) && (
                <PrimaryButtonForm
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
                )}

              </div>
            </div>
          </div>
        </>
        :
         <NoPermissionContainer>
           You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
         </NoPermissionContainer>
       }
        </ComponentDynamic>
        </div>
    );
}

export default MedicationNotes;
