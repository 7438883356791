import React from 'react'
import { ButtonWeek } from 'shared/styles/constants/tagsStyles';

function NoLinkedMedication({ elm, days, isChecked, splitDate }) {

    return (
        <div className="mt-4 rounded" style={{ border: '1px solid #4c2ee1' }}>
            <div className="header p-2">
                <span className="title-text m-s-1">{elm.medicationName}</span>
            </div>
            <div className="p-2 m-s-2">
                <div className="d-flex w-100">
                    <div className="w-60">
                        <div className="cart-title  ps-3">{elm.dose}</div>
                        <div className="sub-text supprt-text">
                            {`${elm.startDoseTime ? elm.startDoseTime.slice(0, 5) : ""} ${elm.endDoseTime ? '-' + elm.endDoseTime.slice(0, 5) : ""}`}
                        </div>
                        <div className="sub-text supprt-text">
                            {elm.instruction}
                        </div>

                    </div>
                    <div className="w-40 d-flex sx-col justify-content-end">
                        <div className="d-flex row align-items-top pe-2">
                            {days.map(el => {
                                return (
                                    <ButtonWeek
                                        style={{ marginLeft: "0.5rem" }}
                                        key={el}
                                        add_background={isChecked(elm?.repeatOn, el)}
                                        disabled={true}>
                                        <div>
                                            <label style={{ cursor: "default" }}>{el?.toUpperCase()}</label>
                                        </div>
                                    </ButtonWeek>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {/* <>
                    <div className="sub-text message w-100 mt-1"
                    >
                        {elm.instruction}
                    </div>
                </> */}
            </div>
            <div></div>
        </div>
    )
}

export default NoLinkedMedication