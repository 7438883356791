import React from 'react';
import {
    FlexGrid3Container,
    FlexGrid3Item,
  } from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
const PostalCodes = ({
    index,
    useInput
}) => {
    return (
        <>
        <FlexGrid3Item className="w-100 pl-1 mt-2">
              <InputLabelTop
              required
                type="text"
                id={'Postalcode'}
                label={'Enter the Postal code'}
               name={"postalcode"}
                maxlength={725}   
                {...useInput(`postalCodes[${index}].name`,'isRequired')}
          //  onChange={e=>onChange(e,index)}
          // //  value={user.postalcode}
          // value={user.postalcode}
              />             
            </FlexGrid3Item>
         
        </>
    );
}

export default PostalCodes;
