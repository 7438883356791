import React, { useState, useEffect, Fragment, useRef } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router';
import { setClientUploadFile, getUploadedFiles, deleteUploadedFile, getUploadedFilesDownload, getRiskAssessmentFiles, setRiskAssessmentFile, deleteRiskAssessmentFile } from 'utils/api/ClientApi';
import { DOWNLOAD } from 'utils/constants/icons';
import { TitleSection, ErrorMessage } from 'shared/styles/constants/tagsStyles';
import { InvalidOrEmptyValues, ERROR, SAVE, SAVED, } from 'utils/constants/messages';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import { EX_LARGE_COL, LARGE_COL } from 'shared/styles/constants/columns';
import { Row, Col, IconWrapper, ColsGrouper, } from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { Toast } from 'primereact/toast';
import { getPageToSearchParam } from 'shared/methods';

export const headerColumns = [
    {
        label: 'FileName',
        name: 'file_name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },

    {
        label: 'Description',
        name: 'description',
        status: 0,
        className: 'grow-mobile sm-hidden md-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Uploaded',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
];

const RiskAssessment = ({
    // cliId,
    props,
    selectedClientId,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission

}) => {

    const [modalLoading, setModalLoading] = useState(true)
    const [desableDelete, setDesableDelete] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);

    const [uploadFile, setUploadFile] = useState('No File Chosen');
    const [toggleUploadFile, setToggleUploadFile] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [idSelected, setIdSelected] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [fileSize, setFileSize] = useState(0);

    const { values, setValues, useInput, setCheckRequires, errors, setFormErrors } = useForm({ limitperpage: PAGE_SIZE_RENDER });
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
        false,
    );
    const history = useHistory();

    const uploadFileRef = useRef(null);
    const toast = useRef(null)

    useEffect(() => {
        if (selectedClientId) loadRiskAssessmentFile(selectedClientId)
    }, [history.location.search, values.limitperpage, selectedClientId])

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleChange = async (event, key, keyName) => {
        const fileUploaded = event.target.files[0];
        const fileResult = await pickFile(event)
        setFileSize(fileUploaded.size)
        const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
        setValues({ ...result });
        if (key === 'upload_file') {
            setUploadFile(fileUploaded.name)
            setToggleUploadFile(true)
        }
    }

    const handleUpload = ref => {
        ref.current.click();
    };


    const loadRiskAssessmentFile = async (id) => {
        const pageToSearch = getPageToSearchParam(
            history.location.search,
            setCurrentPage
        );

        try {
            const response = await getRiskAssessmentFiles({ id, ...pageToSearch, ...values })
            if (response) {
                setUploadedFiles(response?.results || []);
                setPages(Math.ceil(response?.count / values?.limitperpage));
                setModalLoading(false)
            }

        } catch {
            setModalLoading(false)
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (loadingSave || errors.length) {
            return;
        }
        const maxSize = 5 * 1024 * 1024;
        if (fileSize > maxSize) {
            setShowError(true)
            setErrorMessage('The file size is greater than 5 MB. Please upload a compressed file.')
            return;
        }
        if (values && values.upload_file && values.upload_file.upload_file_for_client) {
            setLoadingSave(true);
            setCheckRequires(false);
            setShowError(false);
        } else {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(`Please choose a file`);
            return;
        }

        let body = {
            client: selectedClientId,
            description: values.Upload_file_description || null,
            file_name: values.upload_file.upload_file_for_client,
            name: uploadFile
        };


        try {
            const response = await setRiskAssessmentFile(body)
            console.log(response, "response")
            if (response.status == 201) {
                onSuccess()
            } else {
                onError()
            }
        } catch (error) {
            onError(error)
        }
    };
    const onSuccess = response => {
        setModalLoading(false)
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CHECK);
        loadRiskAssessmentFile(selectedClientId)
        setUploadFile('No File Chosen')
        setToggleUploadFile(false)
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success`,
        });
    };

    const onError = err => {
        let getErrorMessage = err ? err : 'Something went wrong!'
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(getErrorMessage);
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${getErrorMessage}`,
        });
    };


    const toDeleteFile = id => {
        setModalDeleteValue(true);
        setIdSelected(id);
    };

    const handleDeleteFile = async () => {
        if (desableDelete) return;
        setDesableDelete(true)
        try {
            let res = await deleteRiskAssessmentFile(idSelected);
            if (res.status == 204) {
                setModalDeleteValue(false);
                loadRiskAssessmentFile(selectedClientId)
                setIdSelected('')
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: res.message,
                });
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Something went wrong!',
                });
            }
            setDesableDelete(false)
        } catch (error) {
            setModalDeleteValue(false);
            setIdSelected('');
            setDesableDelete(false)
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: 'Something went wrong!',
            });
        }



    };

    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const Upload_file_description = useInput('Upload_file_description')
    return (
        <div>
            <Toast ref={toast} />
            <ComponentDynamic loading={modalLoading}>
                {createPermission &&
                    <>
                        <TitleSection>Upload a file</TitleSection>
                        <div className="d-flex align-items-center py-3">
                            <div style={{ width: '50%' }} className='mr-3'>
                                <InputLabelTop
                                    type="text"
                                    id="emp_desc"
                                    label={'Full description'}
                                    {...Upload_file_description}
                                    maxlength={50}
                                />
                            </div>
                            <div style={{ width: '50%' }}>
                                <input type="file"
                                    style={{ display: 'none' }}
                                    ref={uploadFileRef}
                                    onChange={(e) => handleChange(e, 'upload_file', 'upload_file_for_client')}
                                />
                                <PrimaryButtonForm
                                    minWidth="2rem"
                                    onClick={() => handleUpload(uploadFileRef)}
                                    className={'mr-2'}
                                >
                                    <span className={''}>{'Choose File'}</span>

                                </PrimaryButtonForm>
                                <a href={values.upload_file && values.upload_file.upload_file_for_client} style={{
                                    pointerEvents: uploadFile === 'No File Chosen' ? "none"
                                        : toggleUploadFile ? "none" : "", color: "#696969", fontStyle: "italic"
                                }}
                                    target="_blank" rel="noopener noreferrer"
                                    classname={'ml-1 mt-2'}
                                >
                                    {uploadFile}
                                </a>
                            </div>
                        </div>

                        <div
                            className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
                            <div className={'mb-3 mr-2'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <div className={'d-flex justify-content-center justify-content-md-end'}>

                                <div className={'ml-0 mr-2'}>
                                    <PrimaryButtonForm
                                        bgcolor="PURPLE_GRAY_500"
                                        minWidth="6rem"
                                        className={'mr-2'}
                                        onClick={() => {
                                            history.push('/client')
                                        }}>
                                        <span className={'mr-2'}>{'Cancel'}</span>
                                    </PrimaryButtonForm>
                                    {updatePermission && (
                                        <PrimaryButtonForm
                                            bgcolor="PURPLE_GRAY_500"
                                            minWidth="6rem"
                                            onClick={handleSubmit}>
                                            <span className={'mr-2'}>Upload</span>
                                            {!loadingSave ? (
                                                buttonIcon
                                            ) : (
                                                <span
                                                    className="spinner-border spinner-border-sm mr-1"
                                                    role="status"
                                                />
                                            )}
                                        </PrimaryButtonForm>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>

                }
                {readPermission ?
                    <>
                        <div className='mt-3'>
                            {(uploadedFiles?.length > 0) &&
                                <>
                                    <Table
                                        headerColumns={headerColumns}
                                        headerPadding={false}
                                        noEditOption
                                        fontSize="0.75rem"
                                        callBackQuerySort={setQuerySort}>
                                        <div>
                                            {uploadedFiles && uploadedFiles?.map((file, index) => {

                                                return (
                                                    <div key={index}>
                                                        <Row bgColor Shadow>
                                                            <ColsGrouper
                                                                className="d-flex"
                                                            >
                                                                <Col
                                                                    max_width={LARGE_COL}
                                                                    Shrink
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center>
                                                                    {file.name}
                                                                </Col>
                                                                <Col
                                                                    max_width={LARGE_COL}
                                                                    className='sm-hidden md-hidden'
                                                                    Shrink
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center>
                                                                    {file.description === 'undefined' ? '' : file.description}
                                                                </Col>
                                                                <Col
                                                                    max_width={LARGE_COL}
                                                                    Shrink
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center
                                                                >
                                                                    Uploaded at {moment(file.created_at).format('HH:mm DD-MM-YYYY')}
                                                                </Col>
                                                            </ColsGrouper>
                                                            <Col className="" Center Purple Shrink NoFlexGrow>
                                                                <IconWrapper
                                                                    className='mr-3'
                                                                    onClick={() => {
                                                                        window.open(file.file_name, '_blank');
                                                                    }}
                                                                >
                                                                    {DOWNLOAD}
                                                                </IconWrapper>
                                                            </Col>
                                                            {deletePermission &&
                                                                <Col className="" Center Shrink NoFlexGrow>
                                                                    <IconWrapper
                                                                        className='mr-3'
                                                                        onClick={() => {
                                                                            toDeleteFile(file.id);
                                                                        }}
                                                                    >
                                                                        <RiDeleteBinLine />
                                                                    </IconWrapper>
                                                                </Col>}

                                                        </Row>
                                                        <div className="mb-1" />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Table>

                                    <div className="mt-3">
                                        <Pagination
                                            totalPages={pages}
                                            currentPage={currentPage}
                                            values={values}
                                            dropDownHandleSelect={dropDownHandleSelectPagination}
                                            number={values?.limitperpage}
                                            dataLength={uploadedFiles?.length}
                                        />
                                    </div></>}
                            <div className='mod_dec'>
                                <ModalDecision
                                    type="delete"
                                    title="Warning"
                                    width={"50%"}
                                    max_width={'fit-Content'}
                                    body={'Are you sure you want to delete this file?'}
                                    onOk={handleDeleteFile}
                                    onCancel={() => {
                                        setIdSelected('');
                                    }}
                                    okTitle={'DELETE'}
                                    {...bindModalDelete}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <NoPermissionContainer>
                        You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
                    </NoPermissionContainer>
                }
            </ComponentDynamic>
        </div>
    );
}

export default RiskAssessment;
