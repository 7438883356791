import Table from 'components/SharedComponents/Table';
import {
  Col,
  ColsGrouper,
  IconWrapper,
  Row,
} from 'components/SharedComponents/Table/styles';
import React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { BsFolderPlus } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  EX_LARGE_COL,
  EX_SMALL_COL,
  EX_SMALL_COL4,
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import AddExpense from './AddModal/AddExpense';
import { useContext } from 'react';
import { AppContext } from 'context';
import { useEffect } from 'react';
import { visit_wise_expense_choices } from 'utils/choiceConstant';
import { useForm } from 'hooks/FormHook';
import {
  addNewExpenses,
  deleteExpense,
  getVsitWiseExpensesList,
} from 'utils/api/SchedulerApi';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import _ from 'lodash';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import './VisitExpense.css';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import Spinner from 'components/SharedComponents/Spinner';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getPageToSearchParam } from 'shared/methods';
import { MdEdit } from 'react-icons/md';

const headerColumns = [
  {
    label: 'Pay Adjustment',
    name: 'pay_adjustment__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Pay Adjustment Type',
    name: 'pay_adjustment_type__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'Description',
    name: 'pay_adjustment_desc',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Amount',
    name: 'amount',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL,
  },
  // {
  //   label: '',
  //   name: '',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: EX_SMALL_COL4,
  // },
];

function Expenses({
  assignedEmployee,
  visitId,
  clientSelectedId,
  props,
  values: vasitValues,
  readPermissionVisitExpense,
  createPermissionVisitExpense,
  updatePermissionVisitExpense,
  deletePermissionVisitExpense,
  employees
}) {
  const toast = useRef();
  const VISITS_VALUES = 'visitValues';
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  console.log(
    visitId,
    assignedEmployee,
    clientSelectedId,
    vasitValues[VISITS_VALUES]?.visit_start_date,
    '001VEC'
  );
  const { handleGetChoices, contextChoices: choices } = useContext(AppContext);

  const [modalValue, setModalValue] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [uploadedFile, setUploadedFile] = useState('No File Chosen');
  const [toggleFileProof, setToggleFileProof] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);

  const handleModal = event => {
    if (event == 'new') {
      setModalValue(true);
      setSelectedId('');
      setUploadedFile('No File Chosen');
      setShowError(false);
      setErrorMessage('');
      setCheckRequires(false);
      setValues({});
      setTextStatus(SAVE);
    } else {
      setModalValue(false);
    }
  };

  useEffect(() => {
    handleGetChoices(visit_wise_expense_choices);
  }, []);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values });
  }, []);

  // Get list of expenses
  const getListOfExpenses = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    try {
      const res = await getVsitWiseExpensesList(visitId, {
        ...pageToSearch,
        ...values,
      });
      setExpenseList(res.results);
      setModalLoading(false);
      setPages(Math.ceil(res.count / PAGE_SIZE_RENDER));
      console.log(res.results);
    } catch (error) {
      console.error(error);
    }
  };

  // function urlToBase64(url) {
  //   // Encode the URL to Base64
  //   const base64String = btoa(unescape(encodeURIComponent(url)));
  //   return base64String;
  // }
  const handleSubmit = async (event, saveTrigger = true) => {
    event.preventDefault();
    console.log(isValid, errors, 'error');
    if (loadingSave) {
      return;
    }

    if (!isValid || errors?.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    if (values.amount) {
      values.amount = parseFloat(Math.abs(values.amount)).toFixed(2);
      // values.amount= Math.abs( values.amount );
      setValues({ ...values });
    }

    let body = _.cloneDeep(values);
    if (saveTrigger) {
      setLoadingSave(true);
    }
    // if (!toggleFileProof && body.expense_document) {
    //   delete body.expense_document
    // }
    if (assignedEmployee) {
      body.employee = assignedEmployee;
    }
    if (visitId) {
      body.visit = visitId;
    }
    if (clientSelectedId) {
      body.client = clientSelectedId;
    }
    if (values.pay_adjustment) {
      body.pay_adjustment = values.pay_adjustment;
    }
    if (values.pay_adjustment_type) {
      body.pay_adjustment_type = values.pay_adjustment_type;
    }
    if (values.pay_adjustment_desc) {
      body.pay_adjustment_desc = values.pay_adjustment_desc;
    }
    if (values.amount) {
      body.amount = values.amount;
    }
    // if (values.expense_document) {
    //   body.expense_document = values.expense_document
    // }
    body.pay_adjustment_date = vasitValues[VISITS_VALUES]?.visit_start_date;
    let value = '';
    try {
      const res = await addNewExpenses(body, selectedId);
      console.log(res, 'add res');
      if (res.success === true) {
        setSelectedId('');
        getListOfExpenses();
        setShowError(false);
        onSuccess(res);
        setModalValue(false);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res?.message}`,
        });
      } else {
        onError();
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${res?.message?.expense_document[0]}`,
        });
      }
    } catch (error) {
      console.log(error);
      onError();
    }
    console.log(body, '001body');
  };
  const onSuccess = async response => {
    let expenseId = response.id;
    // if employee id

    setValues({
      ...values,
      id: expenseId,
    });
    setLoadingSave(false);
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  };

  const onError = err => {
    // const onError = () => {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
      // "Some of the fields have invalid or empty values. Please review."
    );
  };

  // Delete expense function
  const deleteExpenseApi = async id => {
    try {
      const res = await deleteExpense(id);
      if (res.status) {
        setModalDeleteValue(false);
        getListOfExpenses();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Expense deleted successfully',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'Something went wrong!',
        });
      }
    } catch (error) { }
  };

  const findFromChoices = (id, name) => {
    if (name === 'pay_adjustment') {
      return choices?.pay_adjustment?.find(item => item?.id === id)?.name;
    } else if (name === 'pay_adjustment_type') {
      return choices?.pay_adjustment_type?.find(item => item?.id === id)?.name;
    }
  };

  const handleRowClick = item => {
    setModalValue(true);
    setSelectedId(item.id);
    if (item.expense_document) {
      setUploadedFile(item.expense_document.split('/').reverse()[0]);
      values.expense_document = item.expense_document;
    }
    if (item.client) {
      values.client = item.client;
    }
    values.amount = parseFloat(item.amount).toFixed(2);
    setValues({ ...values, ...item });
    console.log(item, '001item');
  };

  useEffect(() => {
    getListOfExpenses();
  }, [values.limitperpage, props.location.search]);

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  return (
    <div style={{ margin: '20px' }}>
      <Toast ref={toast} />
      {modalLoading ? (
        <div style={{ marginTop: '300px' }}>
          <Spinner />
        </div>
      ) : (
        <>
          <>
            <div>
              {!modalValue &&
                expenseList?.length >= 1 &&
                createPermissionVisitExpense ? (
                <div
                  className={
                    'w-100 d-flex justify-content-center mb-3 pt-2 mt-4'
                  }>
                  <div className={'text-primary font-weight-bold'}>
                    <div>
                      {
                        <PrimaryButtonForm
                          minWidth="6rem"
                          onClick={() => handleModal('new')}>
                          <BsFolderPlus size={18} />
                          <span className={'ml-2'}>ADD NEW EXPENSE</span>
                        </PrimaryButtonForm>
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
              {modalValue && expenseList?.length ? (
                <div
                  className={'w-100 d-flex justify-content-center mt-4 pt-3'}>
                  <div>
                    <PrimaryButtonForm
                      bgcolor={'PURPLE_GRAY_500'}
                      minWidth="6rem"
                      onClick={handleModal}>
                      <div className={'d-flex align-items-center'}>
                        <TiArrowBackOutline size={24} />
                        <div>BACK</div>
                      </div>
                    </PrimaryButtonForm>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
              {!modalValue && expenseList?.length >= 1 ? (
                <div className="visit-expense-table">
                  <Table
                    headerColumns={headerColumns}
                    // callBackQuerySort={setQuerySort}
                    headerPadding={false}
                    // noEditOption
                    noNeed={false}
                  >
                    {expenseList?.map(item => {
                      return (
                        <div key={Math.random()}>
                          <Row bgColor shadow >
                            <ColsGrouper className='d-flex'>
                              <Col
                                max_width={LARGE_COL}
                                className="sm-hidden"
                                Capitalize
                                Center
                                overlap={true}>
                                <lebel>
                                  {findFromChoices(
                                    item?.pay_adjustment,
                                    'pay_adjustment'
                                  )}
                                </lebel>
                              </Col>

                              <Col
                                max_width={LARGE_COL}
                                className="sm-hidden"
                                Capitalize
                                Center
                                overlap={true}>
                                <label>
                                  {findFromChoices(
                                    item?.pay_adjustment_type,
                                    'pay_adjustment_type'
                                  )}
                                </label>
                              </Col>
                              <Col
                                max_width={EX_LARGE_COL}
                                className="sm-hidden"
                                Capitalize
                                Center
                                overlap={true}>
                                <label>{item?.pay_adjustment_desc}</label>
                              </Col>

                              <Col
                                max_width={MEDIUM_COL}
                                className="sm-hidden"
                                Capitalize
                                Center
                                overlap={true}>
                                <label>{item?.amount}</label>
                              </Col>
                              <Col className="sm-hidden" Center Icon Purple>
                                <IconWrapper
                                  onClick={() => { handleRowClick(item) }}>
                                  <MdEdit />
                                </IconWrapper>
                              </Col>

                            </ColsGrouper>



                            <Col className="sm-hidden" max_width={SMALL_COL} Center Shrink NoFlexGrow>


                              {deletePermissionVisitExpense && (
                                <IconWrapper
                                  onClick={() => {
                                    setModalDeleteValue(true);
                                    setSelectedId(item.id);
                                  }}>
                                  <RiDeleteBinLine />
                                </IconWrapper>
                              )}


                            </Col>

                          </Row>
                          <div className="mb-1" />
                        </div>
                      );
                    })}
                  </Table>
                  <div className="mt-3">
                    <Pagination
                      // simplePagination={true}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      dataLength={expenseList?.length}
                      dropdownEntriesLimit={false}
                      values={values}
                      dropDownHandleSelect={dropDownHandleSelectPagination}
                      number={PAGE_SIZE_RENDER}
                    />
                  </div>
                </div>
              ) : (
                <Fragment />
              )}{' '}
              {(modalValue || !expenseList?.length) &&
                (createPermissionVisitExpense || updatePermissionVisitExpense) ? (
                <AddExpense
                  choices={choices}
                  setUploadedFile={setUploadedFile}
                  uploadedFile={uploadedFile}
                  setValues={setValues}
                  values={values}
                  setToggleFileProof={setToggleFileProof}
                  toggleFileProof={toggleFileProof}
                  useInput={useInput}
                  textStatus={textStatus}
                  buttonIcon={buttonIcon}
                  loadingSave={loadingSave}
                  handleSubmit={handleSubmit}
                  readOnly={
                    selectedId
                      ? !updatePermissionVisitExpense
                      : !createPermissionVisitExpense
                  }
                  employees={employees}
                />
              ) : null}
            </div>
          </>

          <div className="visit-expense-delete">
            <ModalDecision
              type="delete"
              title="Warning"
              body={
                'Are you sure you want to delete this expense? \n DELETING the expense will remove ALL records from database.'
              }
              subBody={''}
              onOk={() => {
                deleteExpenseApi(selectedId);
              }}
              onCancel={() => {
                setSelectedId('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Expenses;
