import React from 'react';
import Table from 'components/SharedComponents/Table';
import {
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
  EX_SMALL_COL4,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  UserImg,
  UserImgContainer,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {
  TitleSection,
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import { MdEdit } from 'react-icons/md';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import Pagination from 'components/SharedComponents/Pagination';
import { RiDeleteBinLine } from 'react-icons/ri';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
const Teamview = ({
  employeeData,
  userClient,
  userEmployee,
  userStaff,
  filterAdmin,
  filterSuperAdmin,
  headerTeamColumns,
  handleExpansion,
  expandFields,
  employeePages,
  EmployeeCurrentPage,
  toDeletePinning,
  update,
  handleEditClick,
  deletePermission,
  setQuerySort,
  loadingModalTeam,
  values,
  dropDownHandleSelectPagination,
  setClickedRow,
  clickedRow,
  checkedRows = [],
  setCheckedRows,
  handleSubChildClicked,
  CheckAllChildSelected,
  handleParentAllClicked,
  handleChildAllClicked,
}) => {

  const handleAllSelectChange = (e) => {
    let checked = []
    if (e.target.checked) {
      employeeData.forEach((runItem, runIndex) => {
        //pinned_data data
        employeeData[runIndex].pinned_data.forEach((pinnedItem, pinnedIndex) => {
          // repeat_on_data data
          employeeData[runIndex].pinned_data[pinnedIndex].repeat_on_data.forEach((repeatItem, repeatIndex) => {
            // ids.push(repeatItem.client_pinned_employee_day_id)
            checked.push({
              client_pinned_employee_id: repeatItem.client_pinned_employee_id,
              client_pinned_employee_day_id: repeatItem.client_pinned_employee_day_id
            })
          })
        })
      })

    } else {
      checked.push([])
    }
    setCheckedRows([...checked])
  }

  const checkAllSelected = () => {
    let checkAll = true;
    const visitExists = (id) => {
      return checkedRows.some(function (el) {
        return el.client_pinned_employee_day_id === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {

      employeeData.forEach((runItem, runIndex) => {
        //pinned_data data
        employeeData[runIndex].pinned_data.forEach((pinnedItem, pinnedIndex) => {
          // repeat_on_data data
          employeeData[runIndex].pinned_data[pinnedIndex].repeat_on_data.forEach((repeatItem, repeatIndex) => {
            // ids.push(repeatItem.client_pinned_employee_day_id)
            if (!visitExists(repeatItem.client_pinned_employee_day_id)) {
              checkAll = false
            }
          })
        })
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  return (
    <div>
      {employeeData ? (
        <>
          <ComponentDynamic loading={loadingModalTeam}>
            <Table
              headerColumns={headerTeamColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}
              noNeed={false}
              noEditOption={true}
              handleAllCheck={checkAllSelected}
              handleAllSelectChange={handleAllSelectChange}
            >
              <div>
                {employeeData && employeeData.map((clientPin, clientIndex) => {
                  return (
                    <div key={clientIndex}>
                      <Row bgColor className="mb-1">
                        <ColsGrouper className="d-flex">
                          <Col
                            max_width={EX_SMALL_COL4}
                            Shrink={false}
                            NoFlexGrow={true}
                            reducePadding
                            Capitalize
                            Center>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              // checked={handleCheck(list)}
                              checked={CheckAllChildSelected(clientIndex, null, "parent", employeeData)}
                              id="flexCheckDefault"
                              onChange={e => {
                                handleParentAllClicked(e, clientPin, clientIndex, employeeData);
                              }}
                            />
                          </Col>
                          <Col
                            max_width={EX_SMALL_COL}
                            className="d-flex justify-content-center align-content-center">
                            <CloseButtonWrapper
                              className="closeButtonWrapper"
                              onClick={() => {
                                handleExpansion(clientIndex, null);
                              }}>
                              {expandFields[clientIndex] && expandFields[clientIndex].open ? (
                                <IoIosArrowDown
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              ) : (
                                <IoIosArrowForward
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              )}
                            </CloseButtonWrapper>
                          </Col>
                          <Col max_width={LARGE_COL}>{clientPin.employee}</Col>
                          <Col
                            max_width={SMALL_COL}
                            // className='p-2'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on}</p>
                            )}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            // className='p-2'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].run_name}</p>
                            )}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            // className='p-2'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].requirement}</p>
                            )}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{(clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].start_time) + "-"
                                + (clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].end_time)
                              }</p>
                            )}
                          </Col>

                          <Col
                            max_width={SMALL_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].repeat}</p>
                            )}
                          </Col>
                          <Col
                            max_width={MEDIUM_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].start_date}</p>
                            )}
                          </Col>
                          <Col
                            max_width={MEDIUM_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].end_date}</p>
                            )}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].clientName}</p>
                            )}
                          </Col>
                          <Col
                            max_width={MEDIUM_COL}
                            className='p2 pr-4'
                            Selected={
                              expandFields[clientIndex] && expandFields[clientIndex].open ? true : false
                            }>
                            {clientPin && clientPin.pinned_data && clientPin.pinned_data.length > 1 ? (
                              <>Various</>
                            ) : clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data.length > 1 ? (
                              <p>Various</p>
                            ) : (
                              <p>{clientPin && clientPin.pinned_data && clientPin.pinned_data[0].repeat_on_data[0].is_suspended ? 'Inactive' : 'Active'}</p>
                            )}
                          </Col>

                        </ColsGrouper>
                      </Row>
                      <div>

                        {expandFields[clientIndex] &&
                          expandFields[clientIndex].open ? (
                          clientPin && clientPin.pinned_data && clientPin.pinned_data.map(
                            (pinnedEmployee, employeeIndex) => {
                              return (
                                <div key={employeeIndex}>
                                  <Row className="mb-1">
                                    <ColsGrouper className="d-flex">
                                      <Col max_width={EX_SMALL_COL}></Col>
                                      <Col
                                        max_width={EX_SMALL_COL4}
                                        Shrink={false}
                                        NoFlexGrow={true}
                                        reducePadding
                                        Capitalize
                                        Center>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          // checked={handleCheck(list)}
                                          checked={CheckAllChildSelected(clientIndex, employeeIndex, "child", employeeData)
                                          }
                                          id="flexCheckDefault"
                                          onChange={e => {
                                            handleChildAllClicked(e, clientIndex, employeeIndex, employeeData);
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        max_width={LARGE_COL}
                                        className="d-flex justify-content-center align-items-center">
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 &&
                                          <CloseButtonWrapper
                                            onClick={() => {
                                              handleExpansion(
                                                clientIndex,
                                                employeeIndex
                                              );
                                            }}
                                          >

                                            {expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                              expandFields[clientIndex][employeeIndex]
                                                .open ? (
                                              <IoIosArrowDown
                                                style={{
                                                  color: '#6200f9',
                                                  fontWeight: 'bold',
                                                }}
                                              />
                                            ) : (

                                              <IoIosArrowForward
                                                style={{
                                                  color: '#6200f9',
                                                  fontWeight: 'bold',
                                                }}
                                              />
                                            )}
                                          </CloseButtonWrapper>
                                        }
                                      </Col>

                                      <Col
                                        bgColor
                                        max_width={SMALL_COL}
                                        className="p2">
                                        {pinnedEmployee.repeat_on}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={LARGE_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].run_name}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={LARGE_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].requirement}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={LARGE_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {(pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].start_time) + "-"
                                            + (pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].end_time)
                                          }
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={SMALL_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].repeat}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={MEDIUM_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].start_date}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={MEDIUM_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].end_date}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={LARGE_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].clientName}
                                        </>
                                        )}
                                      </Col>
                                      <Col
                                        bgColor
                                        max_width={MEDIUM_COL}
                                        className="p2 pr-4"
                                        Selected={
                                          expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                            expandFields[clientIndex][employeeIndex]
                                              .open
                                            ? true
                                            : false
                                        }>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length > 1 ? (
                                          <p>Various</p>
                                        ) : expandFields[clientIndex] &&
                                          expandFields[clientIndex][employeeIndex] &&
                                          expandFields[clientIndex][employeeIndex]
                                            .open ? (
                                          <></>
                                          // <p>Empty</p>
                                        ) : (<>
                                          {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].is_suspended ? 'Inactive' : 'Active'}
                                        </>
                                        )}
                                      </Col>
                                      {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.length === 1 &&
                                        <>
                                          {/* {(update || ( (userEmployee === 'true')  &&  ((userClient  === 'null' )  || (  userStaff  === 'true'  ) ) ))&& ( */}
                                          {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (update))) && (
                                            <Col
                                              bgColor
                                              className="sm-hidden"
                                              Center
                                              Icon
                                              Purple>
                                              {!(clickedRow['parent'] === clientIndex
                                                && clickedRow['child'] === employeeIndex
                                                && clickedRow['subChild'] === null)
                                                && <IconWrapper
                                                  onClick={() => {
                                                    handleEditClick(pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_id,
                                                      pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_day_id
                                                    );
                                                  }}>
                                                  <MdEdit />
                                                </IconWrapper>}
                                            </Col>
                                          )}
                                          {/* {(deletePermission && ( (userEmployee === 'true')  &&  ((userClient  === 'null' )  &&  (  userStaff  === 'null'  ) ) ) )&& ( */}
                                          {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (deletePermission))) && (
                                            <Col
                                              bgColor
                                              className="sm-hidden"
                                              Center
                                              Shrink
                                              NoFlexGrow>
                                              {!(clickedRow['parent'] === clientIndex
                                                && clickedRow['child'] === employeeIndex
                                                && clickedRow['subChild'] === null)
                                                && <IconWrapper
                                                  onClick={() => {
                                                    toDeletePinning(
                                                      pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_id,
                                                      pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data[0].client_pinned_employee_day_id
                                                    );
                                                    setClickedRow({ parent: clientIndex, child: employeeIndex, subChild: null });
                                                  }}>
                                                  <RiDeleteBinLine />
                                                </IconWrapper>}
                                            </Col>
                                          )}
                                        </>
                                      }
                                    </ColsGrouper>
                                  </Row>
                                  <div>
                                    {expandFields[clientIndex] && expandFields[clientIndex][employeeIndex] &&
                                      expandFields[clientIndex][employeeIndex]
                                        .open ? (
                                      <>
                                        {pinnedEmployee && pinnedEmployee.repeat_on_data && pinnedEmployee.repeat_on_data.map(
                                          (pinnedVisit, visitIndex) => {
                                            return (
                                              <div key={visitIndex}>
                                                <Row className="mb-1">
                                                  <ColsGrouper className="d-flex">
                                                    <Col max_width={EX_SMALL_COL}></Col>
                                                    <Col max_width={LARGE_COL}></Col>
                                                    <Col max_width={SMALL_COL}></Col>
                                                    <Col
                                                      max_width={EX_SMALL_COL4}
                                                      Shrink={false}
                                                      NoFlexGrow={true}
                                                      reducePadding
                                                      Capitalize
                                                      Center>
                                                      <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        // checked={handleCheck(list)}
                                                        checked={checkedRows.length && checkedRows.some(el =>
                                                          el?.client_pinned_employee_id === pinnedVisit?.client_pinned_employee_id &&
                                                          el?.client_pinned_employee_day_id === pinnedVisit?.client_pinned_employee_day_id
                                                        )}

                                                        id="flexCheckDefault"
                                                        onChange={e => {
                                                          handleSubChildClicked(e, pinnedVisit);
                                                        }}
                                                      />
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={LARGE_COL}>
                                                      {pinnedVisit.run_name}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={LARGE_COL}>
                                                      {pinnedVisit.requirement}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={LARGE_COL}>
                                                      {pinnedVisit.start_time + "-"
                                                        + pinnedVisit.end_time
                                                      }
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={SMALL_COL}>
                                                      {pinnedVisit.repeat}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={MEDIUM_COL}>
                                                      {pinnedVisit.start_date}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={MEDIUM_COL}>
                                                      {pinnedVisit.end_date}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={LARGE_COL}>
                                                      {pinnedVisit.clientName}
                                                    </Col>
                                                    <Col
                                                      bgColor
                                                      Center
                                                      // className="p-2"
                                                      max_width={MEDIUM_COL}>
                                                      {pinnedVisit.is_suspended ? 'Inactive' : 'Active'}
                                                    </Col>
                                                    {/* {(update ||( (userEmployee === 'true')  &&  ((userClient  === 'null' )  || (  userStaff  === 'true'  ) ) ))&& ( */}
                                                    {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (update))) && (
                                                      <Col
                                                        bgColor
                                                        className="sm-hidden"
                                                        Center
                                                        Icon
                                                        Purple>
                                                        {!(clickedRow['parent'] === clientIndex
                                                          && clickedRow['child'] === employeeIndex
                                                          && clickedRow['subChild'] === visitIndex)
                                                          && <IconWrapper
                                                            onClick={() => {
                                                              handleEditClick(pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_id,
                                                                pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_day_id
                                                              );
                                                              // setClickedRow({parent: clientIndex ,child: employeeIndex});
                                                            }}>
                                                            <MdEdit />
                                                          </IconWrapper>}
                                                      </Col>
                                                    )}
                                                    {/* {(deletePermission ||( (userEmployee === 'true')  &&  ((userClient  === 'null' )  &&  (  userStaff  === 'null'  ) ) ))&& ( */}
                                                    {(((filterAdmin == true) || (filterSuperAdmin == true)) || ((userStaff == true) && (deletePermission))) && (
                                                      <Col
                                                        bgColor
                                                        className="sm-hidden"
                                                        Center
                                                        Shrink
                                                        NoFlexGrow>
                                                        {!(clickedRow['parent'] === clientIndex
                                                          && clickedRow['child'] === employeeIndex
                                                          && clickedRow['subChild'] === visitIndex)
                                                          && <IconWrapper
                                                            onClick={() => {
                                                              toDeletePinning(
                                                                pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_id,
                                                                pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_day_id
                                                              );
                                                              setClickedRow({ parent: clientIndex, child: employeeIndex, subChild: visitIndex });
                                                            }}>
                                                            <RiDeleteBinLine />
                                                          </IconWrapper>}
                                                      </Col>
                                                    )}
                                                  </ColsGrouper>
                                                </Row>
                                              </div>
                                            )
                                          })
                                        }
                                      </>


                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="mt-3">
                  <Pagination totalPages={employeePages} currentPage={EmployeeCurrentPage} values={values} number={PAGE_SIZE_RENDER} dropDownHandleSelect={dropDownHandleSelectPagination} dataLength={employeeData.length} />
                </div>
              </div>
            </Table>
          </ComponentDynamic>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Teamview;
