import React, { useState, useEffect, Fragment, useRef } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { useForm } from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
  CloseButtonWrapper,
  TitleSection,
  Title,
  IconWrapper,
} from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import './invoice.css'
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FaPlus } from 'react-icons/fa';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import TableFormData from './TableFormData';
import { useModal } from 'hooks/ModalHook';
import { invoice_module } from 'utils/choiceConstant';
import { AppContext } from 'context';
import { useContext } from 'react';
import { useMemo } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ERR_COLOR, PURPLE } from 'shared/styles/constants/colors';
import { RiDeleteBinLine } from 'react-icons/ri';
import { DOWNLOAD, FLOPPY } from 'utils/constants/icons';
import { getInvoiceAddressByClientId, getInvoiceAddressByFunderId, getInvoiceDataById, setInvoice } from 'utils/api/InvoiceApi';
import moment from 'moment';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getFundersList } from 'utils/api/ClientApi';
import InvoicePdfPage from '../InvoicePdf';
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';

const TriageModal = ({
  fullBind,
  idSelected,
  setTitle,
  setModalValue,
  contextChoices,
  props,
  loadInvoiceData,
  setFunderLis,
  funderList,
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({ invoice_detail_data: [],limitperpage:  PAGE_SIZE_RENDER});
  const [loadingModal, setLoadingModal] = useState(false);
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const [invoiceDetailTaxAmt, setInvoiceDetailTaxAmt] = useState(0);
  const [invoiceDetailDataTaxPer, setInvoiceDetailDataTaxPer] = useState(0);
  const [invoiceDetailDataDiscountAmt, setInvoiceDetailDataDiscountAmt] = useState(0);
  const [invoiceDetailDataDiscountPer, setInvoiceDetailDataDiscountPer] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [taxInput, setTaxInput] = useState(false);
  // const [funderList, setFunderList] = useState([])
  const [showError, setShowError] = useState(false);

  const {
    setValue: setEmailPreviewModal,
    value: emailPreviewModal,
    //setTitle,
    fullBind: fullBindPreview,
} = useModal(false);

  const toast = useRef();
  const { handleGetChoices } = useContext(AppContext);

  const clientOptions = () => {
    const data =
      contextChoices?.client_listing?.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    return data || []
  }

  const subTotalFn=()=>{
    let count = 0
    invoiceDetailData.forEach((v)=>{
      if(v.amount){
        count = Number(count) + Number(v.amount)
      }
    })
    return count || 0;
  }

  const client_list = useMemo(clientOptions, [contextChoices.client_listing])

  const sub_total_count = useMemo(subTotalFn, [invoiceDetailData])


  useEffect(()=>{
    if(invoiceDetailData && (invoiceDetailData.length > 0) && sub_total_count){
      let tax_amt_temp = 0;
      invoiceDetailData.forEach((val)=>{
        if(val.tax_percentage && val.amount){
          tax_amt_temp = tax_amt_temp + val.amount * (val.tax_percentage/100)
        }
      })
      if(tax_amt_temp, sub_total_count){
        setInvoiceDetailTaxAmt(tax_amt_temp)
        let tax_per_total = (tax_amt_temp/sub_total_count * 100)
        setInvoiceDetailDataTaxPer(tax_per_total)
      }
    }
  },[invoiceDetailData, sub_total_count])



  

  const {
    setValue: setModalValueInvoiceDetail,
    value: modalValueInvoiceDetail,
    setTitle: setTitleInvoiceDetail,
    fullBind: fullBindInvoiceDetail,
  } = useModal(false);

  useEffect(() => {
    setTitle('Invoice')
    // funderLists();
  }, [])

  useEffect(()=>{
    if(idSelected){
      getInvoiceDataById(idSelected)
      .then((res)=>{
        let data = {...res}
        let array = []
        res.invoice_detail && res.invoice_detail.forEach((item)=>{
            array.push({
              type: item.is_visit ? 'visit' : (item.is_travel ? 'travel' : (item.is_mileage ? 'distance' : 'expenses')), 
              id: item.id,
              visit: item.visit,
              expenses: item.expenses,
              visit_type__name: item.visit_type,
              service_category__name: item.service_category,
              schedule_start_date: item.is_expenses ? item.start_date : moment(item.schedule_start_date_time).format('YYYY-MM-DD'),
              schedule_end_date: item.is_expenses ? item.end_date : moment(item.schedule_end_date_time).format('YYYY-MM-DD'),
              schedule_start_time: item.is_expenses ? '' : moment(item.schedule_start_date_time).format('HH:mm'),
              schedule_end_time: item.is_expenses ? '' : moment(item.schedule_end_date_time).format('HH:mm'),
              amount: (item.amount_type == 'scheduled') ?  item.amount : item.amount,
              tax_amount: item.tax_amount,
              tax_percentage: item.tax_percentage
            })
        })
        setInvoiceDetailTaxAmt(data.tax_amount)
        setInvoiceDetailDataTaxPer(data.tax_percentage)
        setInvoiceDetailDataDiscountAmt(data.discount_amount)
        setInvoiceDetailDataDiscountPer(data.discount_percentage)
        setInvoiceDetailData(array || [])
        setValues({...data})
        
      })
    }

  },[idSelected])

  const funderLists = () => {
    getFundersList()
      .then(res => {
        if (res.length > 0) {
          let newFunders = res.map(funder => {
            return {
              id: funder.id,
              name: funder.funder_name
            }
          })
          // setFunderList([...newFunders])
        }
      })
  }

  const dropDownHandleSelect = item => {
    if(item.client){
      getInvoiceAddressByClientId(item.client)
      .then((res)=>{
        if(res.status){
          values.invoice_address = res.data
          setValues({ ...values, ...item });
        }
      })
    }else if(item.funder){
      getInvoiceAddressByFunderId(item.funder)
      .then((res)=>{
        if(res.status){
          values.invoice_address = res.data
          setValues({ ...values, ...item });
        }
      })
    }else{
      setValues({ ...values, ...item });
    }
   
  };

  console.log('values', invoiceDetailData)

  const backToSch=()=>{
    setModalValue(false)
  }

  const handleOnChangeTax=(val,index)=>{
    invoiceDetailData[index].tax_percentage = val.target.value;
    setInvoiceDetailData([...invoiceDetailData])
  }

  const handleOnChangeAmount=(val,index)=>{
    invoiceDetailData[index].amount = val.target.value;
    setInvoiceDetailData([...invoiceDetailData])
  }

  const handleOnChangeTaxPer=(val)=>{
    setInvoiceDetailDataTaxPer(val.target.value)
    setInvoiceDetailTaxAmt(sub_total_count*(val.target.value/100))
  }
  const handleOnChangeTaxAmt=(val)=>{
    setInvoiceDetailTaxAmt(val.target.value)
    setInvoiceDetailDataTaxPer((val.target.value/sub_total_count) * 100)
  }
  const handleOnChangeDiscountAmt=(val)=>{
    setInvoiceDetailDataDiscountAmt(val.target.value)
    setInvoiceDetailDataDiscountPer((val.target.value/sub_total_count) * 100)
  }
  const handleOnChangeDiscountPer=(val)=>{
    setInvoiceDetailDataDiscountPer(val.target.value)
    setInvoiceDetailDataDiscountAmt(sub_total_count*(val.target.value/100))
  }

  const toDeleteInvoice=(index)=>{
    let arr = [...invoiceDetailData]
    arr.splice(index, 1);
    setInvoiceDetailData([...arr])
  }

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
  };

  const handleSubmit=()=>{
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage('');
    setLoadingSave(true);

    if(!(values.client || values.funder)){
      onError('Please fill the mandatory fields.');
      return
    }

    if(invoiceDetailData && invoiceDetailData.length < 1){
      onError('Please add invoice details.');
      return
    }

    let invoice_det = invoiceDetailData.map((val)=>{
      let temp = {}
      if (val.type == 'expenses') {
        temp = {
          visit_id: null,
          expenses_id: val.expenses,
          is_visit: (val.type == 'visit') ? true : false,
          is_travel: (val.type == 'travel') ? true : false,
          is_mileage: (val.distance == 'distance') ? true : false,
          is_actual: (val.amount_type == "actual") ? true : false,
          is_schedule: (val.amount_type == "scheduled") ? true : false,
          is_contract: null,
          manual_amount: val.amount,
          is_tax_percentage: true,
          tax_amount: val.tax_percentage,
          is_discount_percentage: true,
          discount_amount: 0
        }
        if(val.id){
          temp.id = val.id
        }
      } else {
        temp = {
          visit_id: val.visit,
          expenses_id: null,
          is_visit: (val.type == 'visit') ? true : false,
          is_travel: (val.type == 'travel') ? true : false,
          is_mileage: (val.distance == 'distance') ? true : false,
          is_actual: (val.amount_type == "actual") ? true : false,
          is_schedule: (val.amount_type == "scheduled") ? true : false,
          is_contract: null,
          manual_amount: val.amount,
          is_tax_percentage: true,
          tax_amount: val.tax_percentage,
          is_discount_percentage: true,
          discount_amount: 0
        }
        if(val.id){
          temp.id = val.id
        }
      }
      return temp;
    })

    let body = {
      // invoice_number: values.invoice_number,
      client: values.client,
      funder: values.funder,
      invoice_address: values.invoice_address,
      company_address: values.invoice_address,
      issue_date: values.issue_date,
      invoice_due_date: values.invoice_due_date,
      discount_percentage: invoiceDetailDataDiscountPer,
      tax_percentage: invoiceDetailDataTaxPer,
      paid_amount: 0,
      is_paid: false,
      is_row_wise: false,
      details: [...invoice_det]
    }
    if(idSelected){
      body.id = idSelected
    }
    setInvoice(
      { ...body }
    ).then(response => {
      setLoadingSave(false);
     if(response.status){
      loadInvoiceData()
      backToSch()
     }
    });
  }

  const handleInputChange = (e, attribute) => {
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    values[attribute] = e.target.value;
    setValues({...values})
  };

  const handleDownlaodPdf = () => {
    const input = document.querySelector('#mypdfinvoice');
    // var tempString = ReactDOMServer.renderToStaticMarkup(
    //   <InvoicePdfPage
    //   values={values}
    //   invoiceDetailData={invoiceDetailData}
    //   funderList={ funderList}
    //   invoiceDetailTaxAmt={invoiceDetailTaxAmt }
    //   invoiceDetailDataTaxPer={invoiceDetailDataTaxPer }
    //   invoiceDetailDataDiscountAmt={ invoiceDetailDataDiscountAmt}
    //   invoiceDetailDataDiscountPer={ invoiceDetailDataDiscountPer}
    //   errorMessage={errorMessage }
    //   loadingSave={loadingSave}
    //   buttonIcon={buttonIcon }
    //   taxInput={ taxInput}
    //   sub_total_count = {sub_total_count}
    //   client_list={client_list}
    // />
    // )
    // let htmlObject = document.createElement('div');
    // htmlObject.innerHTML = tempString;
    // const input = htmlObject.querySelector('#mypdfinvoice')

    html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
          });
          let position = 0;
          let pageHeight = 210;
          let imgProps = pdf.getImageProperties(imgData);
          let pdfWidth = pdf.internal.pageSize.getWidth();
          let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          var heightLeft = pdfHeight;
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;
          }
          const current_date = moment().format('DD-MM-YYY')
          pdf.save(`${values.client}_pdf_${moment().format('YYYY-MM-DD')}`);
        })
        .catch((err)=>{
          debugger
        })
  };

  return (
    // <ModalContainer max_width={'60%'}>
    //   <Toast ref={toast} position="top-right" />

    //   <ModalDynamic
    //     {...fullBind}
    //     max_width={'60%'}
    //     minWidthTitle="8.5rem"
    //     loading={loadingModal}>
    //     <div className='mx-3 my-1 '>
    <>
    <div className='d-flex justify-content-between mb-2'>
    <Toast ref={toast} position="top-right" />
          <Title>{idSelected ? 'Edit' : 'Add'} Invoice</Title>
          <div className='my-2'>
            <MdKeyboardArrowLeft
              style={{
                fontSize: '2rem',
                color: PURPLE,
                cursor: 'pointer'
              }}
              onClick={() => backToSch()} />
            <span>Back to invoice list</span>
          </div>
        </div>
      <div className='invoice p-2 mt-2'>
        <FlexGrid3Item className="pl-0 pr-1 d-flex">
          <label
            style={{
              fontSize: '0.875rem',
              marginRight: '7px',
              alignSelf: 'center',
            }}>
            Client
          </label>
          <ToggleButton
            label="Funder"
            disabled={false}
            useInput={useInput('is_funder')}
            id="client"
          // onClick={handelOverNight}
          />
        </FlexGrid3Item>
        <table className="invoice-info-container">
          <tr>
            <td className="client-name">
              {!values.is_funder ?
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'60%'}>
                <DropdownSearchable
                required
                  disabled={false}
                  placeHolder={'Client'}
                  options={client_list || []}
                  selectedOption={values.client}
                  onChange={value =>
                    dropDownHandleSelect(
                      { client: value ? value : null },
                      'client'
                    )
                  }
                />
              </FlexGrid3Item>
              :
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'60%'}>
                <DropdownSearchable
                required
                  disabled={false}
                  placeHolder={'Funder'}
                  options={funderList || []}
                  selectedOption={values.funder}
                  onChange={value =>
                    dropDownHandleSelect(
                      { funder: value ? value : null },
                      'funder'
                    )
                  }
                />
              </FlexGrid3Item>}
            </td>
            <td className='d-flex justify-content-end align-items-center'>
              Invoice Date:
              <strong>
                <div style={{ width: '80%', marginLeft: '5px' }}>
                  <DateSelector
                    id={`issue_date`}
                    labelTop=""
                    value={values.issue_date
                    }
                    onChange={e => {
                       handleInputChange(e, 'issue_date');
                    }}
                  />
                </div>
              </strong>
            </td>

          </tr>

          <tr>
            <td>
              <FlexGrid3Item className="pr-1 mt-2 mb-2" ResizeWidth={'60%'}>
                <InputTextArea
                  disabled={false}
                  type="text"
                  height={'3.5rem'}
                  id="Comments"
                  placeholder={'Invoice Address'}
                  {...useInput('invoice_address')}
                />
              </FlexGrid3Item>
            </td>
            <td className='d-flex justify-content-end align-items-center'>
              Due Date :
              <strong>
                <div style={{ width: '80%', marginLeft: '5px' }}>
                  <DateSelector
                    id={`invoice_due_date`}
                    labelTop=""
                    value={values.invoice_due_date
                    }
                    onChange={e => {
                      handleInputChange(e, 'invoice_due_date');
                    }}
                  />
                </div>
              </strong>
            </td>
          </tr>
        </table>


        <div className='d-flex justify-content-between text-end'>
          <PrimaryButtonForm minWidth="6rem" onClick={() => { 
             if(!values.client){
              toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please choose client first.`,
              });
             }else{
              setModalValueInvoiceDetail(true)
             }
            
           }}>
            <FaPlus />
            New Invoice Detail
          </PrimaryButtonForm>
          <ToggleButton
            label="Add Tax For Every Row"
            disabled={false}
            id="tax_input"
            status={taxInput}
            onClick={(val) => {
              setTaxInput(val.target.checked)
              if(val.target.checked){
                setInvoiceDetailTaxAmt('')
                setInvoiceDetailDataTaxPer('')
              }
            }}
          />
        </div>

        <table className="line-items-container">
          <thead>
            <tr className='text-600 text-white bgc-default-tp1 py-25'>
            <th className="heading-subtotal">Remove</th>
              <th className="heading-subtotal">Type</th>
              <th className="heading-subtotal">Visit Type Name</th>
              <th className="heading-subtotal">Service Category</th>
              <th className="heading-subtotal">Start Date</th>
              <th className="heading-subtotal">End Date</th>
              <th className="heading-subtotal">Start Time</th>
              <th className="heading-subtotal">End Time</th>
              {taxInput && <th className="heading-subtotal">Tax(%)</th>}
              <th className="heading-subtotal" style={{paddingRight:'10px'}}>Amount</th>
            </tr>
          </thead>
         
            
          {(invoiceDetailData && (invoiceDetailData.length > 0)) &&
            <tbody>
              {invoiceDetailData.map((item, index) => {
                return (
                  <tr className='text-95 text-secondary-d3'>
                    <td style={{ textAlign: '-webkit-center' }} >
                      <IconWrapper
                        color={ERR_COLOR}
                        onClick={() => {
                          toDeleteInvoice(index);
                        }}>
                        <RiDeleteBinLine />
                      </IconWrapper>
                    </td>
                    <td>{item.type}</td>
                    <td>{item.visit_type__name}</td>
                    <td>{item.service_category__name}</td>
                    <td>{item.schedule_start_date}</td>
                    <td>{item.schedule_end_date}</td>
                    <td>{item.schedule_start_time}</td>
                    <td>{item.schedule_end_time}</td>
                    {taxInput && <td style={{ textAlign: 'left' }}>
                      <InputLabelTop
                        type="text"
                        style={{ marginLeft: '10px', textAlign: 'center' }}
                        id={`tax${index}`}
                        label={''}
                        value={item.tax_percentage || ''}
                        onChange={(val) => { handleOnChangeTax(val, index) }}
                        maxlength={50}
                        invoice
                        no_internal_margin
                      />
                    </td>}
                    <td className="bold" style={{ paddingRight: '10px', textAlign: 'left' }}>
                      <InputLabelTop
                        type="text"
                        style={{ marginLeft: '10px', textAlign: 'right' }}
                        id={`amount${index}`}
                        label={''}
                        value={item.amount || ''}
                        onChange={(val) => { handleOnChangeAmount(val, index) }}
                        maxlength={50}
                        invoice
                        no_internal_margin
                      />
                    </td>

                  </tr>
                )
              })}
            </tbody>
          }
            
         
        </table>
        {!(invoiceDetailData && (invoiceDetailData.length > 0)) &&
            <div style={{ textAlign: 'center', padding: '3rem' }}>
              No data found
            </div>
          }
        <div class="mt-4">
          <div class="row border-b-2 brc-default-l2"></div>
          <div class="row mt-3">
            <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
            </div>
            <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
              <div class="row">
                <div class="col-7 text-right">
                  SubTotal
                </div>
                <div class="col-5">
                  <span class="text-120 text-secondary-d1">
                  <InputLabelTop
                    readOnly={true}
                      type="text"
                      id="subtotal"
                      label={''}
                      value={sub_total_count}
                      // onChange={(val)=>{handleOnChangeTaxPer(val)}}
                      maxlength={50}
                      invoice
                      no_internal_margin
                    />
                    </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-right">
                  Tax(%)
                </div>
                <div class="col-5">
                  <span class="text-120 text-secondary-d1">
                    <InputLabelTop
                    readOnly={taxInput}
                      type="text"
                      id="tax_per"
                      label={''}
                      value={invoiceDetailDataTaxPer}
                      onChange={(val)=>{handleOnChangeTaxPer(val)}}
                      maxlength={50}
                      invoice
                      no_internal_margin
                    />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-right">
                  Tax Amount
                </div>
                <div class="col-5">
                  <span class="text-120 text-secondary-d1">
                  <InputLabelTop
                  readOnly={taxInput}
                      type="text"
                      id="tax_amt"
                      label={''}
                      value={invoiceDetailTaxAmt}
                      onChange={(val)=>{handleOnChangeTaxAmt(val)}}
                      maxlength={50}
                      invoice
                      no_internal_margin
                    />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-right">
                  Discount(%)
                </div>
                <div class="col-5">
                  <span class="text-120 text-secondary-d1">
                  <InputLabelTop
                      type="text"
                      id="dis_per"
                      label={''}
                      value={invoiceDetailDataDiscountPer}
                      onChange={(val)=>{handleOnChangeDiscountPer(val)}}
                      maxlength={50}
                      invoice
                      no_internal_margin
                    />
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-7 text-right">
                  Discount Amount
                </div>
                <div class="col-5">
                  <span class="text-110 text-secondary-d1"> 
                  <InputLabelTop
                      type="text"
                      id="dis_amt"
                      label={''}
                      value={invoiceDetailDataDiscountAmt}
                      onChange={(val)=>{handleOnChangeDiscountAmt(val)}}
                      maxlength={50}
                      invoice
                      no_internal_margin
                    />
                    </span>
                </div>
              </div>

              <div class="row bgc-primary-l3">
                <div class="col-7 text-right">
                  Total Amount
                </div>
                <div class="col-5">
                  <span class="text-150 text-success-d3 opacity-2">
                  <InputLabelTop
                  readOnly={true}
                      type="text"
                      id="total"
                      label={''}
                      value={sub_total_count + invoiceDetailTaxAmt - invoiceDetailDataDiscountAmt}
                      //onChange={(val)=>{handleOnChangeDiscountAmt(val)}}
                      maxlength={50}
                      invoice
                      fontSize={'1.3rem'}
                      no_internal_margin
                    />
                    </span>
                </div>
              </div>
            </div>
          </div>

          <hr />
        </div>
        <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                {idSelected &&
                 <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => setEmailPreviewModal(true)}
                  disabled={false}>
                  <span className={'mr-2'}>Preview</span>
                  {/* {!loadingSave ? (
                    DOWNLOAD
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )} */}
                </PrimaryButtonForm>
              </div>}
              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => handleSubmit()}
                  disabled={false}>
                  <span className={'mr-2'}>Save</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>

      </div>
      {!modalValueInvoiceDetail ? null : (
        <TableFormData
          idSelected={idSelected}
          setModalValue={setModalValueInvoiceDetail}
          fullBind={fullBindInvoiceDetail}
          setTitle={setTitleInvoiceDetail}
          contextChoices={contextChoices}
          values={values}
          setValues={setValues}
          useInput={useInput}
          setInvoiceDetailData={setInvoiceDetailData}
          invoiceDetailData={invoiceDetailData}
          props={props}
          setCheckRequires={setCheckRequires}
    setShowError={setShowError}
        />
      )}
     {emailPreviewModal &&
      <InvoicePdfPage
      values={values}
      invoiceDetailData={invoiceDetailData}
      funderList={ funderList}
      invoiceDetailTaxAmt={invoiceDetailTaxAmt }
      invoiceDetailDataTaxPer={invoiceDetailDataTaxPer }
      invoiceDetailDataDiscountAmt={ invoiceDetailDataDiscountAmt}
      invoiceDetailDataDiscountPer={ invoiceDetailDataDiscountPer}
      errorMessage={errorMessage }
      loadingSave={loadingSave}
      buttonIcon={buttonIcon }
      taxInput={ taxInput}
      sub_total_count = {sub_total_count}
      client_list={client_list}
      fullBind={fullBindPreview}
      handleDownlaodPdf={handleDownlaodPdf}
    />}
     
      {/* </ModalDynamic>
     </ModalContainer> */}
    </>
  );
};

export default TriageModal;
