import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import _ from 'lodash';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { FlexEqual, TitleSection } from 'shared/styles/constants/tagsStyles';
import { DELETE } from 'utils/constants/icons';
import { pickFile } from 'utils/files';
function AddExpense({
  choices,
  setUploadedFile,
  uploadedFile,
  setValues,
  values,
  setToggleFileProof,
  toggleFileProof,
  useInput,
  textStatus,
  buttonIcon,
  loadingSave,
  handleSubmit,
  readOnly,
  employees
}) {

  const fileRef = useRef(null);
  const [uploadedDocumnetValue, setUploadedDocumnetValue] = useState();
  const [amountValueDecimalPartLength, setAmountValueDecimalPartLength] = useState(0);
  const [amountWithoutdecimal, setAmountWithoutdecimal] = useState(false);


  const dropDownHandleSelect = item => {
    //recursive objects merge

    if (item.pay_adjustment_type) {
      let ids = item.pay_adjustment_type;
      let checkName = choices && choices.pay_adjustment_type.find(payname => payname.id == ids).name;
      if (checkName == "Deduction") {
        values.amount = values.amount > 0 ? -Math.abs(values.amount) : values.amount;
      } else if (checkName == "Addition") {
        values.amount = values.amount < 0 ? Math.abs(values.amount) : values.amount;
      }
    }

    let result;

    result = _.merge(values, item);

    setValues({ ...result });
  };

  const amountdecimalcheck = () => {
    if (values.amount && values.amount.toString().includes('.')) {
      var amountValue = values.amount && values.amount.toString().split('.');
      if (amountValue && amountValue[1] && amountValue[1] !== undefined) {
        setAmountValueDecimalPartLength(amountValue[1].length);
        setAmountWithoutdecimal(false);
        if (amountValue[1].length > 2) {
          values.amount = amountValue[0] + "." + amountValue[1].slice(0, 2)
          setValues({ ...values })
          setAmountWithoutdecimal(false);
        }
      }
    } else {
      // setAmountWithoutdecimal(true);
      setAmountValueDecimalPartLength(0);
    }

  }

  useEffect(() => {
    if (values.pay_adjustment_type) {
      let ids = values.pay_adjustment_type;
      let checkName = choices && choices.pay_adjustment_type?.find(payname => payname.id == ids).name;
      if (checkName == "Deduction") {
        values.amount = - Math.abs(values.amount);
      } else if (checkName == "Addition") {
        values.amount = Math.abs(values.amount);
      }
    }
    setValues({ ...values })
  }, [choices.pay_adjustment_type, values.pay_adjustment_type])

  useEffect(() => {
    amountdecimalcheck()

  }, [values.amount])

  const handleChange = async (event, key) => {
    const fileUploaded = event.target.files[0];
    const fileResult = await pickFile(event)
    const result = _.merge(values, { [key]: fileResult.file });
    // setValues({ ...result });
    setUploadedFile(fileUploaded.name)
    setToggleFileProof(true)
  }

  const deleteUploadedFile = () => {
    values.expense_document = '';
    setUploadedFile('No File Chosen');
    setToggleFileProof(false);
    setValues({ ...values })
  };

  const handleUpload = ref => {
    ref.current.click();
  };
  useEffect(() => {
    setUploadedDocumnetValue(values.expense_document);
  }, [values.expense_document])
  console.log(values)
  return (
    <div >
      <FlexEqual>
        <TitleSection>Expenses Details</TitleSection>
        <div className="d-flex flex-column mt-2">
          <div className='d-flex'>
            <div className="w-100 mr-2">
              <DropdownSearchable
                required
                placeHolder={'Pay Adjustment'}
                {...useInput('pay_adjustment', 'isRequired')}
                options={choices?.pay_adjustment}
                selectedOption={values &&
                  values.pay_adjustment ?
                  values.pay_adjustment
                  : null
                }
                disabled={readOnly}
                onChange={value =>
                  dropDownHandleSelect(
                    { pay_adjustment: value ? value : null }
                  )
                }
              />
            </div>

            {/*   <div className='w-100 mr-2'>
            <DropdownSearchable
              placeHolder={'Status'}
              options={statusDropdownOptions}
              selectedOption={values &&
                values.status ?
                values.status
                : null
              }
              onChange={value =>
                dropDownHandleSelect(
                  { status: value ? value : null }
                )
              }
            />
          </div> */}
            <div className='w-100 '>
              <DropdownSearchable
                required
                placeHolder={'Pay Adjustment Type'}
                {...useInput('pay_adjustment_type', 'isRequired')}
                options={choices?.pay_adjustment_type}
                selectedOption={values &&
                  values.pay_adjustment_type ?
                  values.pay_adjustment_type
                  : null
                }
                disabled={readOnly}
                onChange={value =>
                  dropDownHandleSelect(
                    { pay_adjustment_type: value ? value : null }
                  )
                }
              />
            </div>

          </div>
          <div className='d-flex mt-2'>

            <div className='w-100 mr-2'>
              <DropdownSearchable
                placeHolder={'Employee'}
                disabled={readOnly}
                options={employees || []}
                selectedOption={values &&
                  values.employee ?
                  values.employee
                  : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { employee: value ? value : null }
                  )
                }
              />
            </div>

            <div className='w-100'>
              <InputLabelTop
                required
                type="number"
                id="nok_no"
                label={'Amount'}
                disabled={readOnly}
                // onKeyPress={KeyPress.DECIMAL}
                {...useInput(`amount`, 'isRequired')}
                maxlength={25}
              />
              <>
                <small>
                  {((values.amount) && ((amountValueDecimalPartLength !== 2) || (amountWithoutdecimal))) ? <p style={{ color: 'red' }}>Enter the amount with two decimals places.</p> : null}
                </small>
              </>
            </div>
          </div>

          <div className='w-100'>
            <small className="pr-1 " style={{ color: 'black' }}>
              Pay Adjustment Description
            </small>
            <InputTextArea
              type="textrarea"
              height={'4rem'}
              id="nok_no"
              disabled={readOnly}
              label={'Pay Adjustment Description'}
              {...useInput(`pay_adjustment_desc`)}

            />
          </div>
          <div className="d-flex mt-2 mb-2">
            <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Upload Document</span>
            <input type="file"
              style={{ display: 'none' }}
              disabled={readOnly}
              ref={fileRef}
              onChange={(e) => handleChange(e, 'expense_document')}

            />
            <PrimaryButtonForm
              minWidth="2rem"
              onClick={() => handleUpload(fileRef)}
              className={'ml-2 mr-2'}
            >
              <span className={''}>{'Choose File'}</span>
            </PrimaryButtonForm>
            <a href={values.expense_document && values.expense_document} style={{
              pointerEvents: uploadedFile === 'No File Chosen' ? "none"
                : toggleFileProof ? "none" : "", color: "#696969", fontStyle: "italic"
            }}
              target="_blank" rel="noopener noreferrer"
              classname={'ml-1 mt-2'}
            >
              {uploadedFile}
            </a>
            {uploadedDocumnetValue || values.expense_document ?
              <span style={{ marginLeft: "10px" }}>
                <IconWrapper
                  style={{ color: SOFT_RED }}
                  color={SOFT_RED}
                  onClick={() => deleteUploadedFile()}
                >
                  {DELETE}
                </IconWrapper>
              </span>
              : null}
          </div>

        </div>
      </FlexEqual>
      <div
        className={
          'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
        }>
        <div className={'mb-3'}>

        </div>

        <div
          className={
            'd-flex justify-content-center justify-content-md-end'
          }>

          <div className={'ml-0 ml-md-3 mr-2'}>
            <PrimaryButtonForm
              bgcolor="PURPLE_GRAY_500"
              minWidth="6rem"
              disabled={readOnly}
              onClick={handleSubmit}
            >
              <span className={'mr-2'}>{textStatus}</span>
              {!loadingSave ? (
                buttonIcon
              ) : (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                />
              )}

            </PrimaryButtonForm>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddExpense