import React from 'react';
import {DurationStyled, Icon} from './styles';
import {MdAccessTime} from 'react-icons/md';
// @ts-ignore
import DurationInputMask from 'react-duration-input-mask';

function DurationInput(props) {
  return (
    <DurationStyled Disabled={props.disabled}>
      <DurationInputMask
        {...props}
        className={props.value ? 'filled' : ''}
        onChange={props.disabled ? () => {} : props.onChange}
      />
      <label>{props.label_top}</label>
      <Icon>
        <MdAccessTime />
      </Icon>
    </DurationStyled>
  );
}
export default DurationInput;
