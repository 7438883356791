import React from 'react';
import DatePicker from 'react-datepicker';
import {DatePickerStyled, ErrorLabel, Icon} from './styles';
import {FaRegCalendar} from 'react-icons/fa';
import {createDateWithTime} from 'shared/methods';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
import {TIMES} from 'utils/constants/icons';
import 'react-datepicker/dist/react-datepicker.css';

function DateSelector(props) {
  const nr_years = 3;
  let maxYearDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + nr_years),
  );

  const _minDate = props.minDate
    ? createDateWithTime(props.minDate, '00:00:00')
    : '';
    const _maxDate = props.maxDate
    ? createDateWithTime(props.maxDate, '00:00:00')
    : '';
  let selectedValue = null;
  if (props.value) {
    selectedValue = new Date(props.value + 'T00:00:00');
  }

  return (
    <DatePickerStyled disabled ={props.disabled} typeFilter={props.typeFilter}>
      <label>
        {props.labelTop} <span>{props.isRequired ? '*' : ''}</span>
      </label>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        className={
          props['data-error']
            ? 'error-border'
            : props.value
            ? 'value-border'
            : ''
        }
        placeholderText="Click to select"
        selected={selectedValue}
        onChange={date =>
          props.onChange({
            target: {
              value: date,
              type: 'date',
              checked: '',
              dataset: {idx: props.dataIdx},
            },
          })
        }
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={props.disabled}
        maxDate={_maxDate}
        minDate={_minDate}
      />
      {props['data-error'] ? (
        <ErrorLabel>{props.errorMessage || 'Is required'}</ErrorLabel>
      ) : (
        <></>
      )}
      {props.disabled ? "": (props.value ? (<Icon><CloseButtonWrapper
                      onClick={()=>props.onChange({
                        target: {
                          value: "",
                          type: 'date',
                          checked: '',
                          dataset: {idx: props.dataIdx},
                        },
                      })}>
                      {TIMES}
                    </CloseButtonWrapper></Icon>
      ) : (
        <Icon>
          <FaRegCalendar />
        </Icon>
      ))}
    </DatePickerStyled>
  );
}
export default DateSelector;
