import LineContainer from 'components/SharedComponents/LineContainer'
import React from 'react'
import PermissionsTable from './Table'
import { useState } from 'react';
import { useModal } from 'hooks/ModalHook';
import AssessmentModal from './Modals';
import { useContext } from 'react';
import { AppContext } from 'context';
import { useEffect } from 'react';
import { deleteAssessment, deleteAssessmentCategory, deleteAssessmentType, getAssessment } from 'utils/api/SettingsApi';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useForm } from 'hooks/FormHook';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
export const KEY_WORDS = {
    ASSESSMENT: "Assessment",
    ASSESSMENT_TYPE: "Assessment Type",
    ASSESSMENT_CATEGORY: "Assessment Category"
}


function Assessment({
    props,
    readPermission,
    updatePermission,
    deletePermission,
    createPermission
}) {
    const { handleChangeChoices, handleGetChoices } = useContext(AppContext)
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
    const { setValue: setModalValue, value: modalValue, setTitle, fullBind, } = useModal(false);
    const { values, setValues, useInput, isValid, errors, setCheckRequires, } = useForm({});
    const [rolValues, setRolValues] = useState([]);
    const { contextChoices: choices } = useContext(AppContext);
    const [selectedData, setSelectedData] = useState({});
    const [selectedMethod, setSelectedMethod] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [showFrom, setShowForm] = useState(false)

    const handleOnClick = (row, method, type) => {
        console.log(selectedMethod, "selectedMethod")
        setCheckRequires(false)
        handleModal();
        setSelectedData(row);
        setSelectedMethod(method);
        setSelectedType(type);
    };

    const handleClickShowFrom = (date) => {
        setShowForm(true)
        setModalValue(false);
        setSelectedData(date);
    }

    const handleDelete = (row, type) => {
        setModalDeleteValue(true);
        setSelectedData(row);
        setSelectedType(type);
    };

    const handleDeleteOk = () => {
        if (selectedType === KEY_WORDS.ASSESSMENT) {
            deleteAssessment(selectedData.id)
                .then(res => {
                    setModalDeleteValue(false);
                    setSelectedData({});
                    setSelectedType('');
                    loadRolPermission();
                    // handleChangeChoices('care_category');
                });
        } else if (selectedType === KEY_WORDS.ASSESSMENT_CATEGORY) {
            deleteAssessmentCategory(selectedData.id)
                .then(res => {
                    setModalDeleteValue(false);
                    setSelectedData({});
                    setSelectedType('');
                    loadRolPermission();
                    // handleChangeChoices('task_type');
                });
        } else if (selectedType === KEY_WORDS.ASSESSMENT_TYPE) {
            deleteAssessmentType(selectedData.id)
                .then(res => {
                    setModalDeleteValue(false);
                    setSelectedData({});
                    setSelectedType('');
                    loadRolPermission();
                    // handleChangeChoices('care_task');
                });
        }
    };

    const handleModal = () => {
        setModalValue(!modalValue);
    };
    const loadSections = async () => {

        return getAssessment()
            .then(response => {
                if (response.status == 200) {
                    return response;
                }

            })
            .catch(() => { });
    };
    const loadRolPermission = async () => {
        const mySections = await loadSections();
        const addArrayList = JSON.parse(JSON.stringify(mySections?.data || []));

        const myRolPermissions = [];
        //check all sections
        addArrayList.forEach((principalArray, index) => {
            //add new index in myRolPermissions
            myRolPermissions.push({});
            myRolPermissions[index].name = principalArray.name;
            myRolPermissions[index].id = principalArray.id;
            myRolPermissions[index].principal = true;

            const myPrincipalSections = [];
            principalArray.category.forEach((principalSection, indexSections) => {
                // add new index in myPrincipalSections
                myPrincipalSections.push({});
                // add principal values in new index
                myPrincipalSections[indexSections].name = principalSection.name;
                myPrincipalSections[indexSections].id = principalSection.id;
                myPrincipalSections[indexSections].category = principalArray.id;
                myPrincipalSections[indexSections].category_name = principalArray.name;
                myPrincipalSections[indexSections].principal = false;
                myPrincipalSections[indexSections].isSubsection = false;

                myPrincipalSections[indexSections].sections = [];

                principalSection.type.forEach((subSection, indexSubSection) => {
                    myPrincipalSections[indexSections].sections.push({});
                    // add principal aux
                    myPrincipalSections[indexSections].sections[indexSubSection].principal = false;
                    // add isSubsection aux
                    myPrincipalSections[indexSections].sections[indexSubSection].isSubsection = true;
                    //add name
                    myPrincipalSections[indexSections].sections[indexSubSection].name = subSection.name;
                    myPrincipalSections[indexSections].sections[indexSubSection].message = subSection.message;
                    //add id
                    myPrincipalSections[indexSections].sections[indexSubSection].id = subSection.id;
                    // add category
                    myPrincipalSections[indexSections].sections[indexSubSection].category = principalArray.id;

                    myPrincipalSections[indexSections].sections[indexSubSection].type_id = principalSection.id;
                    myPrincipalSections[indexSections].sections[indexSubSection].type_name = principalSection.name;

                });
            });

            myRolPermissions[index].sections = myPrincipalSections;
        });
        setRolValues([...myRolPermissions]);
    };
    useEffect(() => {
        loadRolPermission();
    }, []);

    console.log({
        readPermission,
        updatePermission,
        deletePermission,
        createPermission
    }, "roless")
    debugger
    return (
        <>
            {readPermission ?
                <div>
                    <div className="row p-0 m-0">
                        <div className="col-xl-12 col-lg-12 pl-0 pr-0 pl-md-1 pr-md-1 pb-3">
                            <LineContainer>
                                <div className={'pt-2 pl-3 pr-3 pb-3'}>
                                    {/* <TitleSection>Care Category</TitleSection> */}
                                    <div className={'mt-2'}>
                                        <PermissionsTable
                                            rolValues={rolValues}
                                            handleOnClick={handleOnClick}
                                            handleDelete={handleDelete}
                                            handleClickShowFrom={handleClickShowFrom}
                                            showFrom={showFrom}
                                            setShowForm={setShowForm}
                                            selectedData={selectedData}
                                            readPermission={readPermission}
                                            updatePermission={updatePermission}
                                            deletePermission={deletePermission}
                                            createPermission={createPermission}
                                        />
                                    </div>
                                </div>
                            </LineContainer>
                        </div>
                    </div>

                    <AssessmentModal
                        selectedData={selectedData}
                        selectedMethod={selectedMethod}
                        selectedType={selectedType}
                        setModalValue={handleModal}
                        fullBind={fullBind}
                        setTitle={setTitle}
                        setselectedData={setSelectedData}
                        setselectedMethod={setSelectedMethod}
                        setselectedType={setSelectedType}
                        loadRolPermission={loadRolPermission}
                        handleChangeChoices={handleChangeChoices}

                        values={values}
                        setValues={setValues}
                        useInput={useInput}
                        isValid={isValid}
                        errors={errors}
                        setCheckRequires={setCheckRequires}
                        readOnly={selectedMethod == 'add' ? !createPermission : !updatePermission}
                    />

                    <ModalDecision
                        type="delete"
                        title="Warning"
                        body={`Are you sure you want to delete this ${selectedType}? \n DELETING this ${selectedType} will remove ALL records from database.`}
                        subBody={''}
                        onOk={handleDeleteOk}
                        onCancel={() => {
                            setSelectedData({});
                        }}
                        okTitle={'DELETE'}
                        {...bindModalDelete}
                    />
                </div>
                :
                <NoPermissionContainer>
                    You don't have permission to access the information.
                </NoPermissionContainer>}
        </>
    )
}

export default Assessment