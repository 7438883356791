import {
    LARGE_COL,
    EX_SMALL_COL4,
    P_W_Zero
  } from 'shared/styles/constants/columns';

export const headerColumns = [
{
        label: 1,
        checkbox: true,
        name: 'checkbox',
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
},
 {
   label: 'Client Name',
   name: 'client_name',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
   label: 'Employee Name',
   name: 'employee_name',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
  label: 'Location Name',
  name: '',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
 {
  label: 'Run Name',
  name: 'run_name',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
{
  label: 'Service Category',
  name: '',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
 {
   label: 'Start date',
   name: 'schedule_start_date',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
   label: 'End date',
   name: 'schedule_end_date',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
   label: 'Start time',
   name: 'schedule_start_time',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
   label: 'End time',
   name: 'schedule_end_time',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
 {
  label: 'Sch Duration',
  name: 'duration',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
 {
  label: 'Actual Start Time',
  name: 'actual_end_time',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
{
  label: 'Actual End time',
  name: 'actual_end_time',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
{
  label: 'Actual Duration',
  name: 'actual_duration',
  status: 0,
  className: 'grow-mobile',
  max_width: LARGE_COL,
},
 {
   label: 'Visit status',
   name: 'visit_status',
   status: 0,
   className: 'grow-mobile',
   max_width: LARGE_COL,
 },
   
 ];