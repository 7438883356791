import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { useHistory, useLocation } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { GrSearch } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';
import { getEmployee, getClient } from "../../../utils/localStorage/token"
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { DOWNLOAD } from 'utils/constants/icons';
import { Toast } from 'primereact/toast';
import { getClientVisits, saveRegularVisit, saveRegularVisitSingleSwap, getRegularVisits, deleteRegularVisit, getShiftDetailById } from 'utils/api/RegularVisitApi'
import { bulkDeleteRegularVisits, getDownloadRegularVisitFile } from 'utils/api/SchedulerApi'
import {
  Header,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  EmploymentTypesContainer,
  ButtonsContainer,
  ItemContainer,
  PublishButton,
} from './styles';
import {
  FiltersLeftGroup,
  DropdownTypesContainer,
  FiltersContainer,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import { getClients } from 'utils/api/ClientApi';
import { getEmployees } from 'utils/api/EmployeeApi';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RegularVisitModal from "./AddPinModal/RegularVisitModal"
import RegularVisitModalRun from "./AddPinModal/RegularVisitModalRun"
import moment from 'moment';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import {
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';
import queryString from 'query-string';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
//Modal imports
import { useModal } from 'hooks/ModalHook';
import { useForm } from 'hooks/FormHook';
import {
  getPermissions,
  getStaff, getEmail
} from '../../../utils/localStorage/user';
import { getAdmin, getSuperAdmin } from "../../../utils/localStorage/token"
import {
  REGULAR_VISITS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from '../../../utils/constants/permissions';
import _ from 'lodash';
import ClientView from "./ClientView"
import TeamView from './TeamView'
import RunView from './RunView'
import { getCodeDaysArray, getDaysArray, getTimesArrayFormat } from 'utils/Array';
import Dropdown from 'components/SharedComponents/Dropdown';
import { getRunVisits, getRunEmp } from 'utils/api/RegularVisitApi';
import RegularEmployeeRun from './AddPinModal/RegularEmployeeRun';
import NoSelectAllDropdownFilterTable from 'components/SharedComponents/NoSelectAllDropdownFilterTable';
import { calendar_regular_visit_choices } from 'utils/choiceConstant';


const timesArray = getTimesArrayFormat();
const daysArray = getDaysArray();
const codeDaysArray = getCodeDaysArray();

const startingData = [
  {
    client: 'Cath Clooney',
    employees: [
      {
        employeeName: 'Carol Chambers',
        serviceVisits: [{ day: 'Fri', time: '3pm' }],
      },
      { employeeName: 'Yana Yogo', serviceVisits: [{ day: 'Wed', time: '3pm' }] },
    ],
  },
  {
    client: 'Oliver Redmayne',
    employees: [
      {
        employeeName: 'Fred Flintstone',
        serviceVisits: [{ day: 'Mon', time: '9am' }],
      },
      {
        employeeName: 'Yana Yogo',
        serviceVisits: [{ day: 'Mon', time: '12pm' }],
      },
    ],
  },
  {
    client: 'Charlie Cooper',
    employees: [
      {
        employeeName: 'Diana Nunes',
        serviceVisits: [
          { day: 'Mon', time: '10am' },
          { day: 'Wed', time: '2pm' },
        ],
      },
      {
        employeeName: 'Ali Zuto',
        serviceVisits: [
          { day: 'Tue', time: '4pm' },
          { day: 'Thu', time: '4pm' },
        ],
      },
      {
        employeeName: 'Fred Flinstone',
        serviceVisits: [
          { day: 'Thu', time: '4pm' },
          { day: 'Thu', time: '4pm' },
        ],
      },
    ],
  },
];

const headerColumnsByDefault = [
  {
    name: 'CHECKBOX',
    id: 'checkbox',
  },
  {
    name: 'FULLNAME',
    id: 'fullname',

  },
  {
    name: 'Day',
    id: 'day',

  },
  {
    name: 'PREFERRED START TIME -  END TIME',
    id: 'preferred_start_time',

  },
  {
    name: 'DURATION',
    id: 'duration',

  },
  {
    name: 'EARLIEST START TIME',
    id: 'earlist_start_time',

  },
  {
    name: 'LATEST START TIME',
    id: 'latest_start_time',

  },
  {
    name: 'Address',
    id: 'address',

  },
  {
    name: 'POSTCODE',
    id: 'postcode',

  },
  {
    name: 'EMP NO.',
    id: 'noofemployees',
  },
  {
    name: 'GENDER',
    id: 'gender',

  },
  {
    name: 'Avoid Employee',
    id: 'avoid_employee',

  },
  {
    name: 'RUN NAME',
    id: 'runname',

  }

];
const headerColumns = [
  {
    name: 'CHECKBOX',
    id: 'checkbox',
  },
  {
    name: 'FULLNAME',
    id: 'fullname',

  },
  {
    name: 'Day',
    id: 'day',

  },
  {
    name: 'PREFERRED START TIME -  END TIME',
    id: 'preferred_start_time',

  },
  {
    name: 'DURATION',
    id: 'duration',

  },
  {
    name: 'EARLIEST START TIME',
    id: 'earlist_start_time',

  },
  {
    name: 'LATEST START TIME',
    id: 'latest_start_time',

  },
  {
    name: 'Address',
    id: 'address',

  },
  {
    name: 'POSTCODE',
    id: 'postcode',

  },
  {
    name: 'EMP NO.',
    id: 'noofemployees',
  },
  {
    name: 'GENDER',
    id: 'gender',

  },
  {
    name: 'Skills',
    id: 'skills',

  },
  {
    name: 'COMPETENCIES',
    id: 'competencies',

  },
  {
    name: 'Avoid Employee',
    id: 'avoid_employee',

  },
  {
    name: 'RUN NAME',
    id: 'runname',

  },
  {
    name: 'START DATE',
    id: 'startdate',

  },
  {
    name: 'END DATE',
    id: 'enddate',

  }

];
const headerColumnsEmp = [
  {
    name: 'CHECKBOX',
    id: 'checkbox',

  },
  {
    name: 'FULL NAME',
    id: 'full_name',
  },
  {
    name: 'Day',
    id: 'day',
  },
  {
    name: 'SHIFT NAME',
    id: 'shift_name',
  },
  {
    name: 'START TIME - END TIME',
    id: 'preferred_start_time',
  },
  {
    name: 'TRAVEL MODE',
    id: 'travel_mode',
  },
  {
    name: 'GENDER',
    id: 'gender',
  },
  {
    name: 'Address',
    id: 'address',
  },
  {
    name: 'POSTCODE',
    id: 'postcode',
  },
  {
    name: 'Skills',
    id: 'skills',
  },
  {
    name: 'COMPETENCIES',
    id: 'competencies',
  },
  {
    name: 'Avoid CLIENT',
    id: 'avoid_client',
  },
  {
    name: 'RUN NAME',
    id: 'runname',
  }
];
const headerColumnsEmpByDefault = [
  {
    name: 'CHECKBOX',
    id: 'checkbox',

  },
  {
    name: 'FULL NAME',
    id: 'full_name',
  },
  {
    name: 'Day',
    id: 'day',
  },
  {
    name: 'SHIFT NAME',
    id: 'shift_name',
  },
  {
    name: 'START TIME - END TIME',
    id: 'preferred_start_time',
  },
  {
    name: 'TRAVEL MODE',
    id: 'travel_mode',
  },
  {
    name: 'GENDER',
    id: 'gender',
  },
  {
    name: 'Address',
    id: 'address',
  },
  {
    name: 'POSTCODE',
    id: 'postcode',
  },
  {
    name: 'Avoid CLIENT',
    id: 'avoid_client',
  },
  {
    name: 'RUN NAME',
    id: 'runname',
  }
];

const ClientPrePinning = (props) => {
  const [mockData, setMockData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeRunData, setEmployeeRunData] = useState([]);
  const [runData, setRunData] = useState([])
  const [expandFields, setExpandFields] = useState({});
  const [clients, setClients] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [runNames, setRunNames] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [shiftArray, setShiftArray] = useState([]);
  const [branch, setBranch] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [visit, setVisit] = useState([]);
  const [clientpages, setClientPages] = useState(0);
  const [runPages, setRunPages] = useState(0);
  const [employeePages, setEmployeePages] = useState(0);
  const [clientCurrentPage, setClientCurrentPage] = useState(1);
  const [EmployeeCurrentPage, setEmployeeCurrentPage] = useState(1);
  const [runCurrentPage, setRunCurrentPage] = useState(1);
  const [visitView, setVisitView] = useState('run')
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [employeeDayId, setEmployeeDayId] = useState('');
  const [deleteId, setDeleteId] = useState('')
  const [employeeFilter, setEmployeeFilter] = useState([])
  const [clientFilter, setClientFilter] = useState([])
  const [branchFilter, setBranchFilter] = useState([])
  const [runFilter, setRunFilter] = useState([])
  const { values, setValues, useInput, errors, setFormErrors, setCheckRequires } = useForm({});
  const filterEmployee = getEmployee()
  const filterClient = getClient()
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [choices, setChoices] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [employeeRequired, setEmployeeRequired] = useState([])
  const [visitData, setVisitData] = useState([])
  const [empEmployeeReq, setEmpEmployeeReq] = useState([])
  const [empVisitData, setEmpVisitData] = useState([])
  const [visitRun, setVisitRun] = useState('createrun');
  const [empVisit, setEmpVisit] = useState([])
  const [clearAll, setClearAll] = useState(false);
  const { contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [arrayDaysData, setArrayDaysData] = useState([]);
  const [daysFilter, setDaysFilter] = useState([]);
  const [timeSelected, setTimeSelected] = useState('');
  const [choiceSkills, setChoiceSkills] = useState([]);
  const [choiceCompetency, setChoiceCompetency] = useState([]);
  const [SkilFilter, setSkilFilter] = useState([]);
  const [ComFilter, setComFilter] = useState([]);
  const [visitHours, setVisitHours] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numVisits, setNumVisits] = useState(0);
  const [numEmployees, setNumEmployees] = useState(0);
  const [loadingModal, setLoadingModal] = useState(true);
  const [downloadDisable, SetDownloadDisable] = useState(false)
  const [newRun, setNewRun] = useState(false);
  const [dayOptions, setDayOptions] = useState([]);
  const [empDayOptions, setEmpDayOptions] = useState([]);
  const [runTableOptionData, setrunTableOptionData] = useState([]);
  const [runEmpTableOptionData, setrunEmpTableOptionData] = useState([]);
  const [runDataAttachEmp, setRunDataAttachEmp] = useState([]);
  const [dropdownArray, setDropdownArray] = useState([...headerColumns]);
  const [dropdownArrayEmp, setDropdownArrayEmp] = useState([...headerColumnsEmp]);
  const [dropdownArraySelected, setDropdownArraySelected] = useState([...headerColumnsByDefault]);
  const [clientStatusFilterTemp, setClientStatusFilterTemp] = useState([[...headerColumnsByDefault]]);
  const [dropdownArraySelectedEmp, setDropdownArraySelectedEmp] = useState([...headerColumnsEmpByDefault]);
  const [clientStatusFilterTempEmp, setClientStatusFilterTempEmp] = useState([[...headerColumnsEmpByDefault]]);
  const [loadingModalTeam, setloadingModalTeam] = useState(true);
  const [loadingModalRun, setLoadingModalRun] = useState(true);
  const [loadingModalEmployeeRun, setLoadingModalEmployeeRun] = useState(true);
  const [loadingModalClientView, setLoadingModalClientView] = useState(true);
  const [clickedRow, setClickedRow] = useState({ parent: null, child: null, subChild: null });

  const [checkedRows, setCheckedRows] = useState([]);

  const history = useHistory();
  const menuRef = useRef(null);
  const toast = useRef()

  const { setValue: setModalDetachAll, bind: bindModalDetachAall } =
    useModal(false);
  const {
    setValue: setVisitRunModal,
    value: visitRunModal,
    //setTitle,
    fullBind: fullBindVisit,
  } = useModal(false);
  const {
    setValue: setVisitDetachRunModal,
    value: visitDetachRunModal,
    //setTitle,
    fullBind: fullBindVisitDetach,
  } = useModal(false);
  const {
    setValue: setVisitSwapRunModal,
    value: visitSwapRunModal,
    //setTitle,
    fullBind: fullBindVisitSwap,
  } = useModal(false);
  const {
    setValue: setEmployeeRunModal,
    value: employeeRunModal,
    setTitle,
    fullBind: fullBindEmployee,
  } = useModal(false);
  const {
    setValue: setEmployeeRunDetachModal,
    value: employeeRunDetachModal,
    //setTitle,
    fullBind: fullBindEmployeeDetach,
  } = useModal(false);
  const {
    setValue: setEmployeeRunSwapModal,
    value: employeeRunSwapModal,
    //setTitle,
    fullBind: fullBindEmployeeSwap,
  } = useModal(false);
  const {
    setValue: setRegularVisit,
    value: regularVisit,
    fullBind: fullBindRegularVisit,
  } = useModal(false);

  const {
    setValue: setRegularVisitRun,
    value: regularVisitRun,
    fullBind: fullBindRegularVisitRun,
  } = useModal(false);
  const {
    setValue: setSingleRun,
    value: singleRun,
    fullBind: fullBindSingleRun,
  } = useModal(false);

  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);
  const {
    setValue: setModalBulkDelete,
    bind: bindModalBulkDelete
  } = useModal(false);

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterAdmin = contextIsAdmin;
  const { handleGetChoices } = useContext(AppContext)

  useEffect(() => {
    handleGetChoices(calendar_regular_visit_choices)
  }, [])
  const setArrayDaysfilter = (item) => {
    setDaysFilter([...item])
  }
  const setArrayDropdownfilter = (item) => {
    setDropdownArraySelected([...item])
  }
  const setArrayDropdownfilterEmp = (item) => {
    setDropdownArraySelectedEmp([...item])
  }

  const handleClick = timeSelected => {
    setTimeSelected(timeSelected);
  };

  const setSkillFilterFun = (item) => {
    setSkilFilter([...item])
  }
  const setComFilterFun = (item) => {
    setComFilter([...item])
  }

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};


    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === REGULAR_VISITS) {
            rolePermissions[REGULAR_VISITS] = {};
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION] = {};
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        setCreatePermissions(
          rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[REGULAR_VISITS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );
      }
    }

  }, [])

  const getvisits = async () => {
    try {
      const response = await getRunVisits({ ...values });
      setLoadingModal(false);
      setClientsData(response.data.data);
      setNumVisits(Math.ceil(response.data.visit_count))
      setNumClients(Math.ceil(response.data.client_count))
      setVisitHours(Math.round(((response.data.total_duration) + Number.EPSILON) * 100) / 100)
    } catch {
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    setArrayDaysData(daysArray.filter(w => w.id !== ''))
  }, [])


  // useEffect(() => {
  //   let val = values;
  //   values.search = searchKeyword;
  //   setValues({ ...values, ...val })
  //   if (visitView === 'create_employee_run') {
  //     getEmployeeData();
  //   } else if (visitView === 'createrun') {
  //     getvisits();
  //   }
  // }, [
  //   searchKeyword,
  // ])

  const getEmployeeData = async () => {
    try {
      const response = await getRunEmp({ ...values });
      if (response) {
        setLoadingModalEmployeeRun(false);
      }
      const employeeArray = response.data;
      let arr = [];
      employeeArray.map((val) => {
        let obj = val.shift && val.shift.repeat_on;
        arr.push({ ...val, repeat_on: obj })
      })
      setNumEmployees(response.employee_count);
      setEmployeeRunData(arr);
    } catch (err) {
      console.log(err);
    }
  }



  useEffect(() => {
    if (visitView === 'create_employee_run') {
      history.push({ pathname: "/regular-visit" });
      getEmployeeData();
    } else if (visitView === 'createrun') {
      getvisits();
    }
  }, [
    visitView,
    values.repeat_on,
    values.preferred_start_time,
    values.preferred_end_time,
    values.employees,
    values.runNames,
    values.clients,
    values.branch,
    values.skillFilter,
    values.competencyFilter
  ]);

  useEffect(() => {
    if (visitView === 'createrun') {
      history.push({
        pathname: "/regular-visit"
      });
    }
    // else {
    //   const urlParams = queryString.parse(props.location.search);
    //   urlParams[PAGINATION_PARAM] = 1;
    //   const stringified = queryString.stringify(urlParams);
    //   history.push({
    //     pathname: "/regular-visit",
    //     search: stringified,
    //   });
    // }
    if (visitView === 'client') {
      history.push({ pathname: "/regular-visit" });
      getAllRegularClientVisits(visitView)
      values.limitperpage = PAGE_SIZE_RENDER;
      setValues({ ...values })
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: "/regular-visit",
        search: stringified,
      });
      history.push({ pathname: "/regular-visit" });
    } else if (visitView === 'team') {
      history.push({ pathname: "/regular-visit" });
      getAllRegularEmployeeVisits(visitView)
      values.limitperpage = PAGE_SIZE_RENDER;
      setValues({ ...values })
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: "/regular-visit",
        search: stringified,
      });
    } else if (visitView === 'run') {
      getAllRegularRunVisits(visitView)
      values.limitperpage = PAGE_SIZE_RENDER;
      setValues({ ...values })
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: "/regular-visit",
        search: stringified,
      });
    }
  }, [visitView,
    values.employees,
    values.clients,
    values.runNames,
    values.branch,
    branchData,
  ])

  useEffect(() => {
    if (values.limitperpage || values.ordering || props.location.search) {
      if (visitView === 'client') {
        if (values.limitperpage == undefined) {
          values.limitperpage = PAGE_SIZE_RENDER;
          setValues({ ...values })
        } else {
          setValues({ ...values })
        }
        getAllRegularClientVisits(visitView)
      } else if (visitView === 'team') {
        if (values.limitperpage == undefined) {
          values.limitperpage = PAGE_SIZE_RENDER;
          setValues({ ...values })
        } else {
          setValues({ ...values })
        }
        getAllRegularEmployeeVisits(visitView)
      } else if (visitView === 'run') {
        if (values.limitperpage == undefined) {
          values.limitperpage = PAGE_SIZE_RENDER;
          setValues({ ...values })
        } else {
          setValues({ ...values })
        }
        getAllRegularRunVisits(visitView)
      }
    }
  }, [
    props.location.search,
    values.ordering,
    values.limitperpage,
  ])

  useEffect(() => {
    if (contextChoices && (contextChoices.client_listing || contextChoices.employee_listing)) {
      let clientsArr = contextChoices?.client_listing?.map(client => {
        return { id: client.id, name: client.full_name };
      });
      let employeesArr = contextChoices.employee_listing.map(emp => {
        return { id: emp.id, name: emp.full_name };
      });
      setClients(clientsArr || []);
      setEmployees(employeesArr || []);
      setClientList(clientsArr || []);
      setEmployeeList(employeesArr || [])
    }
    // loadClients()
    // loadEmployees()
  }, [
    branchData, contextChoices
  ])


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getPageToSearchParamClient = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setClientCurrentPage(offset + 1);

    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage;
    return {
      offset,
    };
  };
  const getPageToSearchParamEmployee = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setEmployeeCurrentPage(offset + 1);

    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage;
    return {
      offset,
    };
  };

  const getPageToSearchParamRun = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setRunCurrentPage(offset + 1);
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };


  const employeeChekedFilter = (item) => {
    setEmployeeFilter([...item])
  }
  const clientChekedFilter = (item) => {
    setClientFilter([...item])
  }
  const runChekedFilter = (item) => {
    setRunFilter([...item])
  }
  const branchChekedFilter = (item) => {
    setBranchFilter([...item])
  }
  const getAllRegularClientVisits = (visitView) => {
    setLoadingModalClientView(true)
    const pageToSearch = getPageToSearchParamClient(props.location.search);

    getRegularVisits({ branchData, view: visitView, ordering: values.ordering, limitperpage: values.limitperpage, ...pageToSearch }, values)
      .then(res => {
        if (res) {
          setLoadingModalClientView(false);
        }
        let finalData = []
        finalData = res.results && res.results.map(visits => {

          let tempData = { ...visits }
          tempData.employees && tempData.employees.forEach((employee, index) => {
            employee.employee_pinned_details.forEach((pinned_det, pindex_index) => {
              tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits =
                tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits[0].day.split(",").map(day => {
                  return {
                    day: day,
                    time: tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits[0].time
                  }
                })
            })


          })
          return tempData
        })
        setClientData([...finalData])
        // setClientPages(Math.ceil(res.count / PAGE_SIZE_RENDER));
        setClientPages(Math.ceil(res.count / values.limitperpage));
        const initialExpansionState = {};

        // finalData.forEach((client, cIndex) => {
        //   if (clickedRow.parent == cIndex) {
        //     initialExpansionState[cIndex] = { open: true };
        //   } else {
        //     initialExpansionState[cIndex] = { open: false };
        //   }
        //   client.pinned_data && client.pinned_data.forEach((employee, eIndex) => {
        //     if (clickedRow.child == eIndex) {
        //       initialExpansionState[cIndex][eIndex] = { open: true };
        //     }
        //     else {
        //       initialExpansionState[cIndex][eIndex] = { open: false };
        //     }

        //   });
        // });

        // setExpandFields({ ...initialExpansionState });

      }

      )

  }

  const getAllRegularRunVisits = (visitView) => {
    setLoadingModalRun(true);
    const pageToSearch = getPageToSearchParamRun(props.location.search);

    getRegularVisits({ branchData, view: visitView, ordering: values.ordering, limitperpage: values.limitperpage, ...pageToSearch }, values)
      .then(res => {
        if (res) {
          setLoadingModalRun(false);
        }
        let finalData = []
        finalData = res?.results && res?.results?.map(visits => {

          let tempData = { ...visits }
          tempData.employees && tempData.employees.forEach((employee, index) => {
            employee.employee_pinned_details.forEach((pinned_det, pindex_index) => {
              tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits =
                tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits[0].day.split(",").map(day => {
                  return {
                    day: day,
                    time: tempData.employees[index].employee_pinned_details[pindex_index].serviceVisits[0].time
                  }
                })
            })


          })
          return tempData
        })
        setRunData([...finalData])
        setRunPages(Math.ceil(res.count / values.limitperpage));
        const initialExpansionState = {};

        // finalData.forEach((client, cIndex) => {
        //   if (clickedRow.parent == cIndex) {
        //     initialExpansionState[cIndex] = { open: true };
        //   } else {
        //     initialExpansionState[cIndex] = { open: false };
        //   }

        //   client.pinned_data && client.pinned_data.forEach((employee, eIndex) => {
        //     if (clickedRow.child == eIndex) {
        //       initialExpansionState[cIndex][eIndex] = { open: true };
        //     }
        //     else {
        //       initialExpansionState[cIndex][eIndex] = { open: false };
        //     }
        //   });
        // });

        // setExpandFields({ ...initialExpansionState });

      }

      )


  }


  const getAllRegularEmployeeVisits = (visitView) => {
    setloadingModalTeam(true);
    const pageToSearch = getPageToSearchParamEmployee(props.location.search);

    getRegularVisits({ branchData, view: visitView, ordering: values.ordering, limitperpage: values.limitperpage, ...pageToSearch }, values)
      .then(res => {
        if (res) {
          setloadingModalTeam(false);
        }
        let finalData = []
        finalData = res.results && res.results.map(visits => {
          let tempData = { ...visits }
          tempData.clients && tempData.clients.forEach((client, index) => {
            client.client_pinned_details.forEach((pinned_det, pindex_index) => {
              tempData.clients[index].client_pinned_details[pindex_index].serviceVisits =
                tempData.clients[index].client_pinned_details[pindex_index].serviceVisits[0].day.split(",").map(day => {
                  return {
                    day: day,
                    time: tempData.clients[index].client_pinned_details[pindex_index].serviceVisits[0].time
                  }
                })
            })


          })
          return tempData
        })
        setEmployeeData([...finalData])
        setEmployeePages(Math.ceil(res.count / values.limitperpage));
        const initialExpansionState = {};

        // finalData.forEach((employee, cIndex) => {
        //   if (clickedRow.parent == cIndex) {
        //     initialExpansionState[cIndex] = { open: true };
        //   } else {
        //     initialExpansionState[cIndex] = { open: false };
        //   }

        //   employee.pinned_data && employee.pinned_data.forEach((employee, eIndex) => {
        //     if (clickedRow.child == eIndex) {
        //       initialExpansionState[cIndex][eIndex] = { open: true };
        //     }
        //     else {
        //       initialExpansionState[cIndex][eIndex] = { open: false };
        //     }
        //   });
        // });

        // setExpandFields({ ...initialExpansionState });

      }

      )

  }

  const getVisitName = visit => {
    return (
      moment(visit.preferred_start_time, 'HH:mm').format('hh:mm A') +
      ' - ' +
      moment(visit.preferred_end_time, 'HH:mm').format('hh:mm A')
    );
  }
  const getSkillLevelName = empReq => {
    let required = ''
    let skillLevelName = ''
    let gender = ''
    if (empReq.skill_level) {
      skillLevelName = "" + choices && choices.job_title
        && choices.job_title.find(job => job.id == empReq.skill_level).name
    }
    if (empReq.preferred_gender) {
      gender = "" + choices && choices.gender
        && choices.gender.find(gender => gender.id == empReq.preferred_gender).name
    }
    if (skillLevelName) {
      required = '' + skillLevelName
    }
    if (empReq.start_time && empReq.end_time) {
      required =
        required +
        '(' +
        moment(empReq.start_time, 'HH:mm').format('hh:mm A') +
        ' - ' +
        moment(empReq.end_time, 'HH:mm').format('hh:mm A') + ')'

    }
    if (gender) {
      required = required + gender
    }
    return required
  }
  const handleSaveVisit = () => {
    let allGood = false
    if (values.specific === 'employee') {
      if (!values.employee || !values.start_date || !values.every || !values.end_type || !values.shift) {
        setCheckRequires(true)
        setErrorMessage(InvalidOrEmptyValues);
        return
      }
      if (values.end_type === 'ON' || values.end_type === 'AFTER') {
        if (!values.end_value) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
      }
    }
    else if (values.specific === 'client') {
      if (!values.client || !values.start_date || !values.every || !values.end_type) {
        setCheckRequires(true)
        setErrorMessage(InvalidOrEmptyValues);
        return
      }
      if (values.end_type === 'ON' || values.end_type === 'AFTER') {
        if (!values.end_value) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
      }
    }
    else if (values.specific === 'run') {
      if (!values.start_date || !values.every || !values.end_type) {
        setCheckRequires(true)
        setErrorMessage(InvalidOrEmptyValues);
        return
      }
      if (values.end_type === 'ON' || values.end_type === 'AFTER') {
        if (!values.end_value) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
      }
    }
    values.employee_day_detail && values.employee_day_detail.map((det => {
      if (values.specific == 'employee') {

        if (!det.client || !det.repeat_on || !det.visit) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          allGood = true
        }
      }
      else if (values.specific == 'client') {
        if (!det.employee || !det.repeat_on || !det.shift) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          allGood = true
        }
      }
      else if (values.specific == 'run') {
        // removed    !det.employee ||  from if condition below  to make employee non-mandatory 
        if (!det.client || !det.repeat_on || !det.visit) {
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          allGood = true
        }
      }
    }))
    if (allGood) {
      return
    }
    setCheckRequires(false)
    setErrorMessage('');
    setLoadingSave(true);
    let tempValues = _.cloneDeep(values)
    delete tempValues.employee_day_detail
    let emp_day_det = values.employee_day_detail.map((det, index) => {
      let week_days = [
        { id: "1", name: "MON" },
        { id: "2", name: "TUE" },
        { id: "3", name: "WED" },
        { id: "4", name: "THU" },
        { id: "5", name: "FRI" },
        { id: "6", name: "SAT" },
        { id: "7", name: "SUN" },
      ]
      if (values.specific == 'employee') {
        return {
          client: det.client,
          repeat_on: values.id ? week_days.find(day => day.id === det.repeat_on).name
            : (det.repeat_on.map(rep => {
              return (
                week_days.find(day => day.id === rep.value).name
              )
            })).join(),
          id: det.id && det.id,
          requirement: det.requirement,
          client_service_visit: det.visit
        }
      }
      else if (values.specific == 'client') {
        return {
          employee: det.employee,
          repeat_on: values.id ? week_days.find(day => day.id === det.repeat_on).name
            : (det.repeat_on.map(rep => {
              return (
                week_days.find(day => day.id === rep.value).name
              )
            })).join(),
          id: det.id && det.id,
          shift: det.shift,
          requirement: det.requirement,
          adjustment_details: det.adjustment_details ? det.adjustment_details : null
        }
      }
      else if (values.specific == 'run') {
        return {
          client: det.client,
          employee: det.employee,
          repeat_on: values.id ? week_days.find(day => day.id === det.repeat_on).name
            : (det.repeat_on.map(rep => {
              return (
                week_days.find(day => day.id === rep.value).name
              )
            })).join(),

          id: det.id && det.id,
          requirement: det.requirement,
          client_service_visit: det.visit,
          shift: det.shift,
          adjustment_details: det.adjustment_details ? det.adjustment_details : null,
          start_time: det.start_time,
          end_time: det.end_time
        }
      }
    })
    let emp_day_data = emp_day_det.map((item) => {
      if (item) {
        return item
      }
    })
    let body = {
      ...tempValues,
      run_name_view: values.run_name_view ? values.run_name_view : null,
      employee_day_detail: [...emp_day_data],
      is_deleted: false
    }
    saveRegularVisit(body)
      .then(res => {
        if (res.id) {
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          if (visitView === 'client') {
            getAllRegularClientVisits(visitView)
          } else if (visitView === 'team') {
            getAllRegularEmployeeVisits(visitView)
          } else if (visitView === 'run') {
            getAllRegularRunVisits(visitView)
          }
          setRegularVisit(!regularVisit);
        } else {
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setErrorMessage("Error in saving Visit")
          setLoadingSave(false);
        }

      })
      .catch(error => {

      });
  }

  const handleSaveVisitSwapRun = () => {
    if (!values.run_name_swap_id) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setCheckRequires(false)
    setErrorMessage('');

    let body = {
      run_name_view: values.run_name_swap_id ? values.run_name_swap_id : null,
      detail: values.visitDetails_id
    }
    saveRegularVisitSingleSwap(body)
      .then(res => {
        if (res.status === "FAIL") {
          setSaveButtonIcon(CROSS);
          setErrorMessage("Error in saving Visit")
          setLoadingSave(false);
        } else {
          setSaveButtonIcon(CHECK);
          setLoadingSave(false);
          if (visitView === 'client') {
            getAllRegularClientVisits(visitView)
          } else if (visitView === 'team') {
            getAllRegularEmployeeVisits(visitView)
          } else if (visitView === 'run') {
            getAllRegularRunVisits(visitView)
          }
          setRegularVisit(!regularVisit);
          setSingleRun(!singleRun);
        }
      })
      .catch(error => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setErrorMessage("Error in saving Visit")
        setLoadingSave(false);
      });
  }

  const getEmployeeWeekDay = (day) => {
    if (day === 'MON')
      return { id: '1', name: day }
    if (day === 'TUE')
      return { id: '2', name: day }
    if (day === 'WED')
      return { id: '3', name: day }
    if (day === 'THU')
      return { id: '4', name: day }
    if (day === 'FRI')
      return { id: '5', name: day }
    if (day === 'SAT')
      return { id: '6', name: day }
    if (day === 'SUN')
      return { id: '7', name: day }
  }

  const dropDownHandleRegVisit = (item, nameItem) => {
    if (nameItem === 'client') {
      values[nameItem] = item[nameItem];
      values.visit = null
      setVisit([])
      setValues({ ...values });
      if (values.client) {
        getClientVisits(values[nameItem])
          .then(res => {
            let tempVisit = []
            setVisitData([...res.data])
            tempVisit = res && res.data && res.data.map(visit => {
              let visitName = getVisitName(visit)
              return (
                { id: visit.id, name: visitName }
              )

            })
            setVisit(tempVisit)
          })
      }
    }
    else if (nameItem === 'visit') {
      values[nameItem] = item[nameItem];
      values.employee_day_detail = [{}]
      setEmployeeRequired([])
      let tempEmpReq = [];
      let tempDaysOptions = [];
      let selectedVisit = visitData && visitData.find(visit => visit.id == item.visit)
      tempEmpReq = selectedVisit && selectedVisit.employee_required
        && selectedVisit.employee_required.map(empReq => {
          //let skillLevelName = getSkillLevelName(empReq)
          return (
            {
              id: empReq.id,
              name: empReq.skill_level__name
            }
          )
        })
      tempDaysOptions = selectedVisit && (selectedVisit.repeat_on.length > 0) && selectedVisit.repeat_on.split(',').map((i) => { return getEmployeeWeekDay(i) });
      setDayOptions(tempDaysOptions ? tempDaysOptions : [
        { id: "1", name: "MON" },
        { id: "2", name: "TUE" },
        { id: "3", name: "WED" },
        { id: "4", name: "THU" },
        { id: "5", name: "FRI" },
        { id: "6", name: "SAT" },
        { id: "7", name: "SUN" },
      ]);
      setEmployeeRequired([...tempEmpReq])
      setValues({ ...values })
    }
    else if (nameItem === 'specific') {
      setEmpVisit([])
      setEmpEmployeeReq([])
      setValues({ [nameItem]: item[nameItem], employee_day_detail: [{}] })
    }
    else if (nameItem === 'employee') {
      values[nameItem] = item[nameItem]
      setValues({ ...values });
      if (item.employee) {
        getShiftDetailById(item.employee)
          .then((res) => {
            if (res.message === "Success") {
              let employees = [];
              if (res.data) {
                employees = res.data.map(employee => {
                  return { id: employee.value, name: employee.label };
                });
              }
              setShiftArray(employees || []);

            }
          })
          .catch((error) => { })
      }
    }
    else {
      values[nameItem] = item[nameItem]
      setValues({ ...values });
    }

  };
  const getEmployeeWeekDayOptions = (day) => {
    if (day === '1')
      return [{ id: '1', name: 'MON' }]
    if (day === '2')
      return [{ id: '2', name: 'TUE' }]
    if (day === '3')
      return [{ id: '3', name: 'WED' }]
    if (day === '4')
      return [{ id: '4', name: 'THU' }]
    if (day === '5')
      return [{ id: '5', name: 'FRI' }]
    if (day === '6')
      return [{ id: '6', name: 'SAT' }]
    if (day === '7')
      return [{ id: '7', name: 'SUN' }]
  }

  const dropDownHandleRegEmpDet = (item, nameItem, index) => {
    if (nameItem === 'client') {
      values.employee_day_detail[index][nameItem] = item[nameItem];
      values.employee_day_detail[index].visit = null
      empVisit[index] = []
      setEmpVisit([...empVisit])
      setValues({ ...values });
      if (values.employee_day_detail[index].client) {
        getClientVisits(values.employee_day_detail[index][nameItem])
          .then(res => {
            let tempVisit = [...empVisit]
            empVisitData[index] = [...res.data]
            setEmpVisitData([...empVisitData])
            tempVisit[index] = res && res.data && res.data.map(visit => {
              let visitName = getVisitName(visit)
              return (
                { id: visit.id, name: visitName }
              )

            })
            setEmpVisit([...tempVisit])
          })
      }
    }
    else if (nameItem === 'repeat_on') {
      values.employee_day_detail[index][nameItem] = item[nameItem];
      values.employee_day_detail[index].visit = null
      empVisit[index] = []
      setEmpVisit([...empVisit])
      setValues({ ...values });
      if (values.employee_day_detail[index].client) {
        let tempVisit = [...empVisit]


        tempVisit[index] = item && item.repeat_on && empVisitData[index] && empVisitData[index].map(visit => {
          let dayArray = visit && (visit.repeat_on.length > 0) && visit.repeat_on.split(',');
          let checkArray = true;
          if (item && item.repeat_on && dayArray) {
            const visitExists = (id) => {
              return dayArray.some(function (el) {
                return el === id;
              });
            }
            if (idSelected) {
              getEmployeeWeekDayOptions(item.repeat_on).forEach((item) => {
                if (!visitExists(item.label)) {
                  checkArray = false
                }
              })
            } else {
              item.repeat_on.forEach((item) => {
                if (!visitExists(item.label)) {
                  checkArray = false
                }
              })
            }

          }

          if (checkArray) {
            let visitName = getVisitName(visit)
            return (
              { id: visit.id, name: visitName }
            )
          }
        }).filter(notUndefined => notUndefined !== undefined);
        setEmpVisit([...tempVisit])
      }
    } else if (nameItem === 'visit') {
      values.employee_day_detail[index][nameItem] = item[nameItem];
      values.employee_day_detail[index].requirement = null
      empEmployeeReq[index] = []
      empDayOptions[index] = []
      let tempDaysOptions = [...empDayOptions];
      setEmpEmployeeReq([...empEmployeeReq])
      setEmpDayOptions([...empDayOptions])
      setValues({ ...values });
      let tempEmpReq = [...empEmployeeReq]
      let selectedVisit = empVisitData[index] && empVisitData[index].find(visit => visit.id == item.visit)
      tempEmpReq[index] = selectedVisit && selectedVisit.employee_required
        && selectedVisit.employee_required.map(empReq => {
          //let skillLevelName = getSkillLevelName(empReq)
          return (
            {
              id: empReq.id,
              name: empReq.skill_level__name
            }
          )
        })
      tempDaysOptions[index] = selectedVisit && (selectedVisit.repeat_on.length > 0) && selectedVisit.repeat_on.split(',').map((i) => { return getEmployeeWeekDay(i) });
      setEmpDayOptions([]);
      // if(tempEmpReq && tempEmpReq.find(req=>req.name.length<1)){
      //   empEmployeeReq[index]=[]
      setEmpEmployeeReq([...tempEmpReq])
      // }
      // else{
      // setEmpEmployeeReq([...tempEmpReq])
      // }
      setValues({ ...values })
    }
    else if (nameItem === 'employee') {
      values.employee_day_detail[index][nameItem] = item[nameItem]
      setValues({ ...values });
      if (item[nameItem]) {
        getShiftDetailById(item[nameItem])
          .then((res) => {
            if (res.message === "Success") {
              let employees = [];
              if (res.data) {
                employees = res.data.map(employee => {
                  return { id: employee.value, name: employee.label };
                });
              }
              values.employee_day_detail[index].shiftArray = employees;
              setValues({ ...values });
            }
          })
          .catch((error) => { })
      }
    } else {
      values.employee_day_detail[index][nameItem] = item[nameItem]
      setValues({ ...values });
    }

  }

  const loadVisitOnEdit = (client, client_visit) => {

    getClientVisits(client)
      .then(res => {
        let tempVisit = []
        let tempReq = []
        let tempDaysOptions = []
        // setVisitData([...res.data])
        tempVisit = res && res.data && res.data.map(visit => {
          let visitName = getVisitName(visit)
          return (
            { id: visit.id, name: visitName }
          )

        })
        let selectedVisit = res && res.data && res.data.find(visit => visit.id == client_visit)
        tempReq = selectedVisit && selectedVisit.employee_required
          && selectedVisit.employee_required.map(empReq => {
            //let skillLevelName = getSkillLevelName(empReq)
            return (
              {
                id: empReq.id,
                name: empReq.skill_level__name
              }
            )
          })
        tempDaysOptions = selectedVisit && (selectedVisit.repeat_on.length > 0) && selectedVisit.repeat_on.split(',').map((i) => { return getEmployeeWeekDay(i) });
        setDayOptions(tempDaysOptions ? tempDaysOptions : [
          { id: "1", name: "MON" },
          { id: "2", name: "TUE" },
          { id: "3", name: "WED" },
          { id: "4", name: "THU" },
          { id: "5", name: "FRI" },
          { id: "6", name: "SAT" },
          { id: "7", name: "SUN" },
        ]);
        setVisit(tempVisit)
        setEmployeeRequired(tempReq)
      })

  }

  useEffect(() => {
    const initialExpansionState = {};

    startingData.forEach((client, cIndex) => {
      // if (clickedRow.parent == cIndex) {
      //   initialExpansionState[cIndex] = { open: true };
      // } else {
      initialExpansionState[cIndex] = { open: false };
      // }

      client.employees.forEach((employee, eIndex) => {
        // if (clickedRow.child == eIndex) {
        //   initialExpansionState[cIndex][eIndex] = { open: true };
        // }
        // else {
        initialExpansionState[cIndex][eIndex] = { open: false };
        // }
      });
    });

    setExpandFields({ ...initialExpansionState });


  }, [visitView]);


  useEffect(() => {
    setChoices(contextChoices && contextChoices || []);
    let runValues = [];
    if (contextChoices && contextChoices.run_view) {
      runValues = contextChoices.run_view.map(y => {
        return { id: y && y.id, name: y && y.name }
      })
      runValues.unshift({ id: 'none', name: 'None' })
    }
    setRunNames(runValues || []);
    setBranch(contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
  }, [contextChoices])

  const headerClientColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: 'Client Name',
      name: 'client_name',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Visits',
      name: 'repeat_on',
      status: 0,
      className: 'm-0 p-0  md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Run Name',
      name: 'run_name',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Requirement',
      name: 'requirement',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Start-End Time',
      name: 'time',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Repeat',
      name: 'repeat',
      status: 0,
      className: 'm-0 p-0   md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Start Date',
      name: 'start_date',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'End Date',
      name: 'end_date',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Employee Name',
      name: 'employee_name',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Status',
      name: 'status',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
  ];
  const headerTeamColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: '',
      name: 'expand',
      status: 0,
      className: 'm-0 p-0 md-hidden',
      max_width: EX_SMALL_COL,
    },
    {
      label: 'Employee Name',
      name: 'employee_name',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Visits',
      name: 'repeat_on',
      status: 0,
      className: 'm-0 p-0  md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Run Name',
      name: 'run_name',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Requirement',
      name: 'requirement',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Start-End Time',
      name: 'time',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Repeat',
      name: 'repeat',
      status: 0,
      className: 'm-0 p-0   md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Start Date',
      name: 'start_date',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'End Date',
      name: 'end_date',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Client Name',
      name: 'client_name',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Status',
      name: 'status',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },


  ];
  const headerRunColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: '',
      name: 'expand',
      status: 0,
      className: 'm-0 p-0 md-hidden',
      max_width: EX_SMALL_COL,
    },
    {
      label: 'Run Name',
      name: 'run_name',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },

    {
      label: 'Visits',
      name: 'repeat_on',
      status: 0,
      className: 'm-0 p-0  md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Requirement',
      name: 'requirement',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Start-End Time',
      name: 'time',
      status: 0,
      className: 'm-0 p-0  sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Repeat',
      name: 'repeat',
      status: 0,
      className: 'm-0 p-0   md-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Start Date',
      name: 'start_date',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Pin Start Time',
      name: 'pin_start_time',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Pin End Time',
      name: 'pin_end_time',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Client Name',
      name: 'client_name',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Employee Name',
      name: 'employee_name',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Shift Name',
      name: 'shift_name',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Status',
      name: 'status',
      status: 0,
      className: 'm-0 p-0   sm-hidden',
      max_width: MEDIUM_COL,
    },


  ];
  const dropDownHandleSelect = item => {

    if (item.employees) {
      item.employees = item.employees.length === employees.length || item.employees.length === 0 ? [] : item.employees
    }
    if (item.clients) {
      item.clients = item.clients.length === clients.length || item.clients.length === 0 ? [] : item.clients
    }
    if (item.runNames) {
      item.runNames = item.runNames.length === (runNames ? runNames : 0).length || item.runNames.length === 0 ? [] : item.runNames
    }
    if (item.branch) {
      item.branch = item.branch.length === branch.length || item.branch.length === 0 ? [] : item.branch
    }
    if (item.branch) {
      item.branch = item.branch.length === (branch ? branch : 0).length || item.branch.length === 0 ? [] : item.branch
    }
    if (item.skillFilter) {
      item.skillFilter = item.skillFilter.length === choiceSkills.length || item.skillFilter.length === 0 ? [] : item.skillFilter
    }
    if (item.competencyFilter) {
      item.competencyFilter = item.competencyFilter.length === choiceCompetency.length || item.competencyFilter.length === 0 ? [] : item.competencyFilter
    }

    setValues({ ...values, ...item });
  };

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }

    setrunTableOptionData(data)
  };
  const dropDownHandleSelectTableEmp = item => {
    const data = [];
    if (item.tableDropdownEmp) {
      item && item.tableDropdownEmp.map((item) => {
        data.push(item.id);
      })
    }

    setrunEmpTableOptionData(data)
  };

  useEffect(() => {
    const data = [];
    const dataEmp = []

    headerColumnsByDefault.map((item) => {
      data.push(item.id);
    })
    headerColumnsEmpByDefault.map((item) => {
      dataEmp.push(item.id);
    })
    setrunTableOptionData(data)
    setrunEmpTableOptionData(dataEmp)
  }, [employeeRunData, clientData])


  const handleExpansion = (clientIndex, employeeIndex) => {
    const updatedExpandFields = { ...expandFields };

    if (employeeIndex === null) {
      updatedExpandFields[clientIndex] = { open: !updatedExpandFields[clientIndex]?.open };
    } else {
      if (!updatedExpandFields[clientIndex]) {
        updatedExpandFields[clientIndex] = {};
      }
      updatedExpandFields[clientIndex][employeeIndex] = {
        open: !updatedExpandFields[clientIndex][employeeIndex]?.open,
      };
    }

    setExpandFields(updatedExpandFields);
  };


  const handleEditClick = (id, empDayId) => {
    setIdSelected(id);
    setValues({})
    setEmployeeDayId(empDayId)
    setCheckRequires(false)
    setErrorMessage('')
    setEmpEmployeeReq([])
    setEmpVisit([])
    setVisit([])
    setEmployeeRequired([])
    setTextStatus(SAVE);
    setSaveButtonIcon(FLOPPY);
    setRegularVisit(true);
  };

  const toDeletePinning = (id, empDayId) => {
    setModalDeleteValue(true);
    setDeleteId(id);
    setEmployeeDayId(empDayId)
  };

  const handleDeleteVisit = async () => {

    let dayid = employeeDayId
    setEmployeeDayId('')

    try {
      let res = await deleteRegularVisit(deleteId, dayid);
      if (res.message == "Deleted Successfully") {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res?.message}`,
        });

        setModalDeleteValue(false);
        if (visitView === 'client') {
          getAllRegularClientVisits(visitView)
        } else if (visitView === 'team') {
          getAllRegularEmployeeVisits(visitView)
        } else if (visitView === 'run') {
          getAllRegularRunVisits(visitView)
        }

      }
    } catch (error) {
      setClickedRow({parent: null, child: null, subChild: null})
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${error?.message}`,
      });
    }


  }

  const searchFunc = (value, view) => {
    values.search = value;
    setValues({ ...values });
    if (visitView === 'create_employee_run') {
      getEmployeeData();
    } else if (visitView === 'createrun') {
      getvisits();
    } else if (view === 'client') {
      getAllRegularClientVisits(view)
    } else if (view === 'team') {
      getAllRegularEmployeeVisits(view)
    } else if (visitView === 'run') {
      getAllRegularRunVisits(view)
    }
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d)
    }
  }

  //const debounceOnchange = useCallback(debounce(searchFunc, 300),[]);
  const keyPressed = (e) => {
    setSearchKeyword(e.target.value)
    let view = visitView
    searchFunc(e.target.value, view)
    // debounceOnchange(e.target.value, view)
  };



  const handleAddPin = () => {
    setIdSelected('');
    setEmployeeDayId('')
    setValues({})
    setCheckRequires(false)
    setErrorMessage('')
    setEmpEmployeeReq([])
    setEmpVisit([])
    setVisit([])
    setEmployeeRequired([])
    setTextStatus(SAVE);
    setSaveButtonIcon(FLOPPY);
    setLoadingSave(false);
    setRegularVisit(!regularVisit);
  };


  const resetAllCheck = () => {
    values.visits = [];
    values.addingcarers = [];
    setValues({ ...values });
  }

  const attachVisitRun = () => {
    values.run_name = '';
    values.run_name_id = null;
    values.start_date = null;
    values.end_date = null;
    values.every = ''
    values.repeat = null;
    values.end_type = null;
    values.end_value = null;
    setValues({ ...values });
    setNewRun(false);
    setVisitRunModal(true);
    setTextStatus('SAVE');
    setErrorMessage('');
    setCheckRequires(false)
  }

  const attachVisitDetachRun = () => {
    setVisitDetachRunModal(true);
    setTextStatus('SAVE');
    setErrorMessage('');
    setCheckRequires(false)
  }

  const attachVisitDetachAllRun = () => {
    setModalDetachAll(true);
    setTextStatus('SAVE');
    setErrorMessage('');
    setCheckRequires(false)
  }

  const attachVisitSwapRun = () => {
    values.run_name = '';
    values.run_name_id = null;
    values.start_date = null;
    values.end_date = null;
    values.every = ''
    values.repeat = null;
    values.end_type = null;
    values.end_value = null;
    setVisitSwapRunModal(true);
    setNewRun(false);
    setTextStatus('SAVE');
    setErrorMessage('');
    setCheckRequires(false)
  }

  const attachEmployeeRun = () => {
    values.run_name = '';
    values.run_name_id = null;
    values.start_date = null;
    values.end_date = null;
    values.every = ''
    values.repeat = null;
    values.end_type = null;
    values.end_value = null;
    values.client_pinned_employee_day_id = null;
    setRunDataAttachEmp([]);
    setValues({ ...values });
    setErrorMessage('');
    setNewRun(false);
    setEmployeeRunModal(true);
    setTextStatus(SAVE);
    setCheckRequires(false)
  }

  const attachEmployeeDetachRun = () => {
    values.run_name = '';
    values.run_name_id = null;
    setValues({ ...values });
    setErrorMessage('');
    setEmployeeRunDetachModal(true);
    setTextStatus(SAVE);
    setCheckRequires(false)
  }

  const attachEmployeeSwapRun = () => {
    values.run_name = '';
    values.run_name_id = null;
    values.start_date = null;
    values.end_date = null;
    values.every = ''
    values.repeat = null;
    values.end_type = null;
    values.end_value = null;
    setValues({ ...values });
    setNewRun(false);
    setErrorMessage('');
    setEmployeeRunSwapModal(true);
    setTextStatus(SAVE);
    setCheckRequires(false)
  }

  const handleDownlaod = () => {
    let fileName = "regular_visits_" + moment(values.start_date).format('YYYY-MM-DD') + "_" + moment(values.end_date).format('YYYY-MM-DD')
    SetDownloadDisable(true)
    getDownloadRegularVisitFile().then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        SetDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        SetDownloadDisable(false)
      })
  }

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/regular-visit",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

// Bulk delete function
  const handleBulkDelete = async () => {
    let groupedBy = {}
    if (checkedRows.length)
    checkedRows.forEach(row => {
      if (groupedBy[row.client_pinned_employee_id]) {
        groupedBy[row.client_pinned_employee_id] = {
          "client_pinned_employee_id": row.client_pinned_employee_id,
          "client_pinned_employee_day_ids":
            [...groupedBy[row.client_pinned_employee_id]['client_pinned_employee_day_ids'],
             row.client_pinned_employee_day_id]
        }
      } else {
        groupedBy[row.client_pinned_employee_id] = {
          "client_pinned_employee_id": row.client_pinned_employee_id,
          "client_pinned_employee_day_ids": [row.client_pinned_employee_day_id]

        }
      }
    })
 
  try {
    const body = {
      regularVisitsForDelete : Object.values(groupedBy)
    }
    const res = await bulkDeleteRegularVisits(body)
    if(res.status == "FAIL"){
      throw res.message
    }
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${res?.message}`,
    });
    setModalBulkDelete(false)
    setCheckedRows([])
    if (visitView === 'client') {
      getAllRegularClientVisits(visitView)
    } else if (visitView === 'team') {
      getAllRegularEmployeeVisits(visitView)
    } else if (visitView === 'run') {
      getAllRegularRunVisits(visitView)
    }
  } catch (error) {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${error}`,
    });
    setModalBulkDelete(false)
  }
  }

  // Here's bulk selection functions 

  const handleSubChildClicked = (e, repeatItem) => {
    let checked = [...checkedRows]

    if (e.target.checked) {
      let alreadyExist = checkedRows.some(el =>
        el?.client_pinned_employee_id === repeatItem?.client_pinned_employee_id &&
        el?.client_pinned_employee_day_id === repeatItem?.client_pinned_employee_day_id
      )
      if (!alreadyExist) {
        checked.push({
          client_pinned_employee_id: repeatItem.client_pinned_employee_id,
          client_pinned_employee_day_id: repeatItem.client_pinned_employee_day_id
        })
      }

    } else {
      checked = checked.filter(el =>
        el?.client_pinned_employee_id !== repeatItem?.client_pinned_employee_id ||
        el?.client_pinned_employee_day_id !== repeatItem?.client_pinned_employee_day_id
      )
    }
    setCheckedRows([...checked])
  }


  const CheckAllChildSelected = (runIndex, pinnedIndex, type, viewData) => {
    let checkAll = true;

    const checkExist = (repeatItem) => {
      return checkedRows.some(function (el) {
        return (el?.client_pinned_employee_id === repeatItem?.client_pinned_employee_id &&
          el?.client_pinned_employee_day_id === repeatItem?.client_pinned_employee_day_id)
      });
    }

    if (checkedRows && checkedRows.length > 0) {
      if (type == "parent") {
        let a = viewData[runIndex]?.pinned_data?.forEach((pinnedItem, pinnedIndex) => {
          let a = viewData[runIndex]?.pinned_data[pinnedIndex]?.repeat_on_data?.forEach((repeatItem, repeatIndex) => {
            if (!checkExist(repeatItem)) {
              checkAll = false
            }
          })
        })
      } else if (type == "child") {
        let a = viewData[runIndex]?.pinned_data[pinnedIndex]?.repeat_on_data?.forEach((repeatItem, repeatIndex) => {
          if (!checkExist(repeatItem)) {
            checkAll = false
          }
        })
      }
    } else {
      checkAll = false;
    }

    return checkAll
  }


  const handleParentAllClicked = (e, pinnedData, runIndex, viewData) => {
    let updatedChecked = [...checkedRows];

    if (e.target.checked) {
      // Handle selecting all parent items and their children
      viewData[runIndex].pinned_data.forEach((pinnedItem) => {
        pinnedItem.repeat_on_data.forEach((repeatItem) => {
          const alreadyExist = updatedChecked.some(
            (el) =>
              el?.client_pinned_employee_id === repeatItem?.client_pinned_employee_id &&
              el?.client_pinned_employee_day_id === repeatItem?.client_pinned_employee_day_id
          );

          if (!alreadyExist) {
            updatedChecked.push({
              client_pinned_employee_id: repeatItem.client_pinned_employee_id,
              client_pinned_employee_day_id: repeatItem.client_pinned_employee_day_id,
            });
          }
        });
      });
    } else {
      // Handle deselecting all parent items and their children
      pinnedData.pinned_data.forEach((pinnedItem) => {
        pinnedItem.repeat_on_data.forEach((repeatItem) => {
          updatedChecked = updatedChecked.filter(
            (el) =>
              el.client_pinned_employee_id !== repeatItem?.client_pinned_employee_id ||
              el.client_pinned_employee_day_id !== repeatItem?.client_pinned_employee_day_id
          );
        });
      });
    }
    setCheckedRows(updatedChecked);
  };


  const handleChildAllClicked = (e, runIndex, pinnedIndex, viewData) => {
    let updatedChecked = [...checkedRows];

    if (e.target.checked) {
      // Handle selecting all child items
      let a = viewData[runIndex]?.pinned_data[pinnedIndex]?.repeat_on_data?.forEach((repeatItem) => {
        const alreadyExist = updatedChecked.some(
          (el) =>
            el?.client_pinned_employee_id === repeatItem?.client_pinned_employee_id &&
            el?.client_pinned_employee_day_id === repeatItem?.client_pinned_employee_day_id
        );

        if (!alreadyExist) {
          updatedChecked.push({
            client_pinned_employee_id: repeatItem.client_pinned_employee_id,
            client_pinned_employee_day_id: repeatItem.client_pinned_employee_day_id,
          });
        }
      });
    } else {
      // Handle deselecting all child items
      updatedChecked = updatedChecked.filter(
        (el) =>
          !viewData[runIndex]?.pinned_data[pinnedIndex]?.repeat_on_data?.some(
            (repeatItem) =>
              el.client_pinned_employee_id === repeatItem?.client_pinned_employee_id &&
              el.client_pinned_employee_day_id === repeatItem?.client_pinned_employee_day_id
          )
      );
    }
    setCheckedRows(updatedChecked);
  };



  // console.log(checkedRows, "checkedRows")

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <TitleSection>Regular Visits</TitleSection>
      <Header>
        <FiltersContainer style={{ flexWrap: 'wrap' }}>
          <FiltersLeftGroup>

            <EmploymentTypesContainer className="mr-2">
              <NoSelectAllDropdownFilter
                items={employees ? employees : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ employees: items });
                }}
                itemsSelected={employeeFilter}
                setSelectedFilter={employeeChekedFilter}
                placeHolder={'Employees'}
                isObject
                allOption

              />
            </EmploymentTypesContainer>


            <EmploymentTypesContainer className="mr-2">
              <NoSelectAllDropdownFilter
                items={clients ? clients : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ clients: items });
                }}
                itemsSelected={clientFilter}
                setSelectedFilter={clientChekedFilter}
                placeHolder={'Clients'}
                isObject
                allOption

              />
            </EmploymentTypesContainer>

            <EmploymentTypesContainer className="mr-2">
              <NoSelectAllDropdownFilter
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ branch: items });
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={branchChekedFilter}
                placeHolder={'Branch'}
                isObject
                allOption

              />
            </EmploymentTypesContainer>
            <EmploymentTypesContainer className="mr-2">
              <NoSelectAllDropdownFilter
                items={runNames ? runNames : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ runNames: items });
                }}
                itemsSelected={runFilter}
                setSelectedFilter={runChekedFilter}
                placeHolder={'Run Name'}
                isObject
                allOption

              />
            </EmploymentTypesContainer>
            <DropdownTypesContainer className="ml-1 mr-1">
              <NoSelectAllDropdownFilter
                items={arrayDaysData ? arrayDaysData : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ repeat_on: items });
                }}
                itemsSelected={daysFilter}
                setSelectedFilter={setArrayDaysfilter}
                placeHolder={'Day'}
                isObject
                allOption

              />
            </DropdownTypesContainer>
          </FiltersLeftGroup>
          <FiltersLeftGroup className="d-flex justify-content-center mb-2" style={{ marginTop: '4px' }}>
            <DropdownTypesContainer className="ml-1 mr-1">
              <Dropdown
                items={timesArray}
                onSelect={item => {
                  dropDownHandleSelect({ preferred_start_time: item });
                  handleClick(1);
                }}
                placeHolder={'Start time'}
                value={values.start_time || ''}
                appendItem=""
                isObject
                allOption
              />
            </DropdownTypesContainer>
            <DropdownTypesContainer className="ml-1 mr-1">
              <Dropdown
                items={timesArray}
                onSelect={item => {
                  dropDownHandleSelect({ preferred_end_time: item });
                  handleClick(2);
                }}
                value={values.end_time || ''}
                placeHolder={'End time'}
                appendItem=""
                isObject
                allOption
              />
            </DropdownTypesContainer>
            {/* <DropdownTypesContainer className="ml-1 mr-1">
              <NoSelectAllDropdownFilter
                    items={choiceSkills && choiceSkills}
                    setItemsSelected={items => {
                      dropDownHandleSelect({skillFilter: items});
                    }}
                    itemsSelected={SkilFilter}
                    setSelectedFilter={setSkillFilterFun}
                    placeHolder={'Select Skills'}
                    isObject
                    allOption   
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1">
              <NoSelectAllDropdownFilter
                    items={choiceCompetency && choiceCompetency}
                    setItemsSelected={items => {
                      dropDownHandleSelect({competencyFilter: items});
                    }}
                    itemsSelected={ComFilter}
                    setSelectedFilter={setComFilterFun}
                    placeHolder={'Select Competency '}
                    isObject
                    allOption   
                  />
                </DropdownTypesContainer> */}
          </FiltersLeftGroup>
        </FiltersContainer>


        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                onChange={(e) => {
                  keyPressed(e)
                }
                }
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>
          {create && (
            <FloatingMobileButton onClick={handleAddPin}>
              <FaPlus />
              <label>NEW REGULAR VISIT</label>
            </FloatingMobileButton>
          )}
        </SearchAddContainer>

      </Header>
      <ButtonsContainer visitView={visitView} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={'calendarView'}>

          <button
            className={'createRunView'}
            onClick={() => {
              setVisitView('createrun')
              setValues({ ...values, ordering: '', addingcarers: [], run_name_id: '', run_name: '' })
              setSearchKeyword('')
              setClickedRow({ parent: null, child: null, subChild: null })
              setCheckedRows([])
            }} >
            Create Run
          </button>
          <button
            className={'createEmployeeRunView'}
            onClick={() => {
              setVisitView('create_employee_run')
              setValues({ ...values, ordering: '', visits: [] })
              setSearchKeyword('')
              setClickedRow({ parent: null, child: null, subChild: null })
              setCheckedRows([])
            }} >
            Create Employee Run
          </button>
          <button
            className={'clientView'}
            onClick={() => {
              setVisitView('client')
              setValues({ ...values, ordering: '' })
              setSearchKeyword('')
              setClickedRow({ parent: null, child: null, subChild: null })
              setCheckedRows([])
            }}>
            Client View
          </button>
          <button
            className={'carerView'}
            onClick={() => {
              setVisitView('team')
              setValues({ ...values, ordering: '' })
              setSearchKeyword('')
              setClickedRow({ parent: null, child: null, subChild: null })
              setCheckedRows([])
            }} >
            Team View
          </button>
          <button
            className={'runView'}
            onClick={() => {
              setVisitView('run')
              setValues({ ...values, ordering: '' })
              setSearchKeyword('')
              setClickedRow({ parent: null, child: null, subChild: null })
              setCheckedRows([])
            }} >
            Run View
          </button>
        </div>
        <div className={'d-flex justify-content-center justify-content-md-end'}>
          {visitView === 'createrun' &&
            <DropdownTypesContainer className="ml-1 mr-1">
              <NoSelectAllDropdownFilterTable
                items={dropdownArray ? dropdownArray : []}
                setItemsSelected={items => {
                  dropDownHandleSelectTable({ tableDropdown: items });
                }}
                itemsSelected={dropdownArraySelected}
                clientStatusFilterTemp={clientStatusFilterTemp}
                setSelectedFilter={setArrayDropdownfilter}
                placeHolder={'column'}
                isObject
                allOption

              />
            </DropdownTypesContainer>
          }
          {visitView === 'create_employee_run' &&
            <DropdownTypesContainer className="ml-1 mr-1">
              <NoSelectAllDropdownFilterTable
                items={dropdownArrayEmp ? dropdownArrayEmp : []}
                setItemsSelected={items => {
                  dropDownHandleSelectTableEmp({ tableDropdownEmp: items });
                }}
                itemsSelected={dropdownArraySelectedEmp}
                clientStatusFilterTemp={clientStatusFilterTempEmp}
                setSelectedFilter={setArrayDropdownfilterEmp}
                placeHolder={'column'}
                isObject
                allOption

              />
            </DropdownTypesContainer>
          }
          {values.visits && (values.visits.length > 0) &&
            <div class="dropdown">
              <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className={'mr-2'}>Action</span>
              </PrimaryButtonForm>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" onClick={attachVisitRun}>Attach</a>
                <a class="dropdown-item" onClick={attachVisitDetachRun}>Detach</a>
                <a class="dropdown-item" onClick={attachVisitSwapRun}>Swap</a>
                <a class="dropdown-item" onClick={attachVisitDetachAllRun}>Detach all</a>
                <a class="dropdown-item" onClick={resetAllCheck}>Reset all</a>
              </div>
            </div>}

          {((values.addingcarers && values.addingcarers.length > 0) || checkedRows.length > 0) && (
            <div className="dropdown">
              <PrimaryButtonForm
                minWidth="6rem"
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2">Action</span>
              </PrimaryButtonForm>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {values.addingcarers && values.addingcarers.length > 0 && (
                  <>
                    <a className="dropdown-item" onClick={attachEmployeeRun}>Attach</a>
                    <a className="dropdown-item" onClick={attachEmployeeDetachRun}>Detach</a>
                    <a className="dropdown-item" onClick={attachEmployeeSwapRun}>Swap</a>
                    <a className="dropdown-item" onClick={attachVisitDetachAllRun}>Detach all</a>
                    <a className="dropdown-item" onClick={resetAllCheck}>Reset all</a>
                  </>
                )}
                {checkedRows.length > 0 && (
                  <a className="dropdown-item" onClick={() => setModalBulkDelete(true)}>Bulk Delete</a>
                )}
              </div>
            </div>
          )}

          {visitView === 'createrun' && <DataContainer className=" ml-2 mt-0 mb-3 d-flex justify-content-start">
            <div className="data-container">
              <div className="mr-2">
                <p>{'Visits: ' + numVisits} </p>
              </div>
              <div className="mr-2">
                <p>{'Clients: ' + numClients}</p>
              </div>
              <p>{'Hours: ' + visitHours}</p>
            </div>
          </DataContainer>}
          {visitView === 'create_employee_run' &&
            <DataContainer className=" ml-2 mt-0 mb-3 d-flex justify-content-start">
              <div className="data-container">
                <div className="mr-2">
                  <p>{'Employees: ' + numEmployees} </p>
                </div>
              </div>
            </DataContainer>
          }
          <ItemContainer>
            <PublishButton disable={downloadDisable} onClick={!downloadDisable && handleDownlaod}>
              {DOWNLOAD}
            </PublishButton>
          </ItemContainer>

        </div>

      </ButtonsContainer>
      {visitView === 'create_employee_run' &&
        <RegularEmployeeRun
          values={values}
          setValues={setValues}
          searchKeyword={searchKeyword}
          employeeRunData={employeeRunData}
          setEmployeeRunData={setEmployeeRunData}
          menuRef={menuRef}
          errors={errors}
          useInput={useInput}
          setTextStatus={setTextStatus}
          textStatus={textStatus}
          setErrorMessage={setErrorMessage}
          fullBind={employeeRunModal ? fullBindEmployee : fullBindEmployeeDetach}
          fullBindEmployeeSwap={fullBindEmployeeSwap}
          setCheckRequires={setCheckRequires}
          setEmployeeRunModal={setEmployeeRunModal}
          setEmployeeRunDetachModal={setEmployeeRunDetachModal}
          setEmployeeRunSwapModal={setEmployeeRunSwapModal}
          employeeRunModal={employeeRunModal}
          employeeRunDetachModal={employeeRunDetachModal}
          employeeRunSwapModal={employeeRunSwapModal}
          setFormErrors={setFormErrors}
          setLoadingModalEmployeeRun={setLoadingModalEmployeeRun}
          loadingModalEmployeeRun={loadingModalEmployeeRun}
          getEmployeeData={getEmployeeData}
          handleChangeChoices={props.handleChangeChoices}
          setModalDetachAll={setModalDetachAll}
          bindModalDetachAall={bindModalDetachAall}
          setRunDataAttachEmp={setRunDataAttachEmp}
          runDataAttachEmp={runDataAttachEmp}
          runEmpTableOptionData={runEmpTableOptionData}
        />
      }
      {visitView === 'createrun' &&
        <RegularVisitModalRun
          values={values}
          setValues={setValues}
          searchKeyword={searchKeyword}
          clientsData={clientsData}
          setClientsData={setClientsData}
          setNewRun={setNewRun}
          newRun={newRun}
          errors={errors}
          useInput={useInput}
          setTextStatus={setTextStatus}
          textStatus={textStatus}
          setErrorMessage={setErrorMessage}
          fullBind={visitRunModal ? fullBindVisit : fullBindVisitDetach}
          fullBindVisitSwap={fullBindVisitSwap}
          setCheckRequires={setCheckRequires}
          setVisitRunModal={setVisitRunModal}
          setVisitDetachRunModal={setVisitDetachRunModal}
          setVisitSwapRunModal={setVisitSwapRunModal}
          visitRunModal={visitRunModal}
          visitDetachRunModal={visitDetachRunModal}
          visitSwapRunModal={visitSwapRunModal}
          setFormErrors={setFormErrors}
          setLoadingModal={setLoadingModal}
          loadingModal={loadingModal}
          getvisits={getvisits}
          handleChangeChoices={props.handleChangeChoices}
          setModalDetachAll={setModalDetachAll}
          bindModalDetachAall={bindModalDetachAall}
          runTableOptionData={runTableOptionData}
        />
      }
      {visitView === 'client' &&
        <ClientView
          clientData={clientData}
          setQuerySort={setQuerySort}
          userEmployee={userEmployee}
          userStaff={userStaff}
          filterAdmin={filterAdmin}
          filterSuperAdmin={filterSuperAdmin}
          headerClientColumns={headerClientColumns}
          handleExpansion={handleExpansion}
          expandFields={expandFields}
          clientpages={clientpages}
          clientCurrentPage={clientCurrentPage}
          toDeletePinning={toDeletePinning}
          update={update}
          deletePermission={deletePermission}
          handleEditClick={handleEditClick}
          loadingModalClientView={loadingModalClientView}
          values={values}
          dropDownHandleSelectPagination={dropDownHandleSelectPagination}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          handleSubChildClicked={handleSubChildClicked}
          CheckAllChildSelected={CheckAllChildSelected}
          handleParentAllClicked={handleParentAllClicked}
          handleChildAllClicked={handleChildAllClicked}
        />
      }
      {visitView === 'team' &&
        <TeamView
          employeeData={employeeData}
          userEmployee={userEmployee}
          setQuerySort={setQuerySort}
          userStaff={userStaff}
          filterAdmin={filterAdmin}
          filterSuperAdmin={filterSuperAdmin}
          headerTeamColumns={headerTeamColumns}
          handleExpansion={handleExpansion}
          expandFields={expandFields}
          employeePages={employeePages}
          EmployeeCurrentPage={EmployeeCurrentPage}
          toDeletePinning={toDeletePinning}
          update={update}
          handleEditClick={handleEditClick}
          deletePermission={deletePermission}
          loadingModalTeam={loadingModalTeam}
          values={values}
          dropDownHandleSelectPagination={dropDownHandleSelectPagination}
          setClickedRow={setClickedRow}
          clickedRow={clickedRow}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          handleSubChildClicked={handleSubChildClicked}
          CheckAllChildSelected={CheckAllChildSelected}
          handleParentAllClicked={handleParentAllClicked}
          handleChildAllClicked={handleChildAllClicked}
        />
      }
      {
        visitView === 'run' &&
        <RunView
          runData={runData}
          userEmployee={userEmployee}
          setQuerySort={setQuerySort}
          userStaff={userStaff}
          filterAdmin={filterAdmin}
          filterSuperAdmin={filterSuperAdmin}
          headerRunColumns={headerRunColumns}
          handleExpansion={handleExpansion}
          expandFields={expandFields}
          runPages={runPages}
          runCurrentPage={runCurrentPage}
          toDeletePinning={toDeletePinning}
          update={update}
          deletePermission={deletePermission}
          handleEditClick={handleEditClick}
          values={values}
          dropDownHandleSelectPagination={dropDownHandleSelectPagination}
          loadingModalRun={loadingModalRun}
          // setClickedRow={setClickedRow}
          // clickedRow={clickedRow}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          handleSubChildClicked={handleSubChildClicked}
          CheckAllChildSelected={CheckAllChildSelected}
          handleParentAllClicked={handleParentAllClicked}
          handleChildAllClicked={handleChildAllClicked}
        />
      }
      {!regularVisit ? null : (
        <RegularVisitModal
          readOnly={true}
          setRegularVisit={setRegularVisit}
          regularVisit={regularVisit}
          toast={toast}
          fullBind={fullBindRegularVisit}
          dropDownHandleRegVisit={dropDownHandleRegVisit}
          dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
          handleSaveVisit={handleSaveVisit}
          handleSaveVisitSwapRun={handleSaveVisitSwapRun}
          useInput={useInput}
          values={values}
          errorMessage={errorMessage}
          visit={visit}
          dayOptions={dayOptions}
          setEmpDayOptions={setEmpDayOptions}
          textStatus={textStatus}
          loadingSave={loadingSave}
          idSelected={idSelected}
          employeeDayId={employeeDayId}
          saveButtonIcon={saveButtonIcon}
          clients={clients}
          setValues={setValues}
          employees={employees}
          shiftArray={shiftArray}
          setShiftArray={setShiftArray}
          loadVisitOnEdit={loadVisitOnEdit}
          setFormErrors={setFormErrors}
          errors={errors}
          choices={choices}
          employeeRequired={employeeRequired}
          empEmployeeReq={empEmployeeReq}
          setEmpEmployeeReq={setEmpEmployeeReq}
          empDayOptions={empDayOptions}
          setEmpVisit={setEmpVisit}
          empVisit={empVisit}
          getVisitName={getVisitName}
          getSkillLevelName={getSkillLevelName}
          getClientVisits={getClientVisits}
          setSingleRun={setSingleRun}
          singleRun={singleRun}
          fullBindSingleRun={fullBindSingleRun}
        />
      )}
      {!regularVisitRun ? null : (
        <RegularVisitModalRun
          readOnly={true}
          values={values}
          setValues={setValues}
          errorMessage={errorMessage}
          fullBind={fullBindRegularVisitRun}
          choices={choices}
          useInput={useInput}
          textStatus={textStatus}
          loadingSave={loadingSave}
          saveButtonIcon={saveButtonIcon}
          visitRun={visitRun}
          dropDownHandleRegVisit={dropDownHandleRegVisit}
        />
      )}
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this regular visit?'
        }
        onOk={handleDeleteVisit}
        onCancel={() => {
          setDeleteId('');
          setEmployeeDayId('');
          setClickedRow({parent: null, child: null, subChild: null})
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

     <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete selected regular visits? \n DELETING the regular visits will remove ALL records from database.'
        }
        onOk={handleBulkDelete}
        onCancel={() => {
          setCheckedRows([])
          setClickedRow({parent: null, child: null, subChild: null})
        }}
        okTitle={'DELETE'}
        {...bindModalBulkDelete}
      />
    </div>
  );
};

export default ClientPrePinning;
