import React from "react";
import {
    ContainerTabStyled,
    LinkTabStyled,
    LinkTab,
    Title,
} from 'shared/styles/constants/tagsStyles';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { Header, SearchAddContainer } from 'shared/styles/constants/tagsStyles';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import Table from 'components/SharedComponents/Table';
import {
    Row,
    Col,
    IconWrapper,
    ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';
import { useState } from "react";
import AdddBradFord from "./addBradFord/addBradFord";
import { useContext } from "react";
import { AppContext } from "context";
import { useEffect } from "react";
import { BRAD_FORD_CHOICES } from "utils/choiceConstant";
import { addBradFordFactor, deleteBradFord, getAllBradFordDetails, getAllBradFordDetailsbyId } from "utils/api/AlertsApi";
import Spinner from "components/SharedComponents/Spinner";
import { DeleteModalPopUp, ErrorAlert } from "./Alerts/Alerts";
import Pagination from "utils/constants/pagination";
import { useModal } from 'hooks/ModalHook';
import ModalDecision from "components/SharedComponents/ModalDecision";
import { BRAD_FORD_PERMISSION } from "utils/constants/permissions";
import {
    TEAM_PERMISSION,
    TRIAGE_PERMISSION,
    WEB_CREATE_PERMISSION,
    WEB_DELETE_PERMISSION,
    WEB_PERMISSION,
    WEB_UPDATE_PERMISSION,
    SELF_WEB_DELETE_PERMISSION,
    SELF_WEB_CREATE_PERMISSION,
    SELF_WEB_UPDATE_PERMISSION,
    WEB_READ_PERMISSION,
    SELF_WEB_READ_PERMISSION,
    CLIENT_TRIAGE,
} from '../../../../utils/constants/permissions';



const headerColumns = [
    {
        label: 'Branch name',
        name: 'Branch name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'FACTOR DISPLAY  ',
        name: 'FACTOR DISPLAY',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'FACTOR ALERT',
        name: 'FACTOR ALERT',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Rolling Point',
        name: 'Rolling Point',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Absence type',
        name: 'Absence type',
        status: 0,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },
];
const SettingBradFord = (props) => {
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
    } = useModal(false);
    const {
        contextPermissions,
        contextSubSectionPermissions,
        contextCategoryPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        contextIsStaff,
        contextSubscription,
    } = useContext(AppContext);

    const filterAdmin = contextIsAdmin;
    const filterSuperAdmin = contextIsSuperAdmin;

    const { contextChoices, handleGetChoices } = useContext(AppContext);
    const [addBradModal, setAddBradModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [selectedOption, setSelectedOption] = useState([]);
    const [displayToggle, setDisplayToggle] = useState(false)
    const [alertToggle, setAlertToggle] = useState(false)
    const [branchList, setBranchList] = useState([]);
    const [absenceList, setAbsenceList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [arr, setArrr] = useState([])
    const [period, setPeriod] = useState(null)

    const [bradFordStatus, setBradFordStatus] = useState([])
    const [allList, setAllList] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)

    const [deleteId, setDeleteId] = useState(null)
    const [errorSnack, setErrorSnack] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const [bradFordId, setBradFodeId] = useState(null)

    //For deleting Modal
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
    const [idSelected, setIdSelected] = useState('');

    const [update, setUpdatePermissions] = useState(false);
    const [create, setCreatePermissions] = useState(false);
    const [deletePermission, setDeletePermissions] = useState(false);
    const [readPermission, setReadPermissions] = useState(false);
    const [error, setError] = useState({
        errorValue: false,
        abseneType: false,
        rollingPeriod: false,
        branchType: false,
        bradFordStatus: false,
        factorValue: false,
        factorMessgae: false
    })


    // console.log({
    //     update: update,
    //     create: create,
    //     deletePermission: deletePermission,
    //     readPermission: readPermission
    // }, "PERMISSIONS BRADFORD settin")

    // console.log({
    //     props: props,
    //     contextChoices: contextChoices,
    //     selectedOption: selectedOption,
    //     edit: edit,
    //     displayToggle: displayToggle,
    //     alertToggle: alertToggle,
    //     period: period,
    //     arr: arr,
    //     selectedBranch: selectedBranch,
    //     bradFordStatus: bradFordStatus,
    //     allList: allList,
    //     bradFordId: bradFordId,
    //     setModalValue: setModalValue,
    //     modalValue: modalValue
    // }, 'SettingBradFord.js')

    const removeNullValue = (obj) => {
        const newObj = {};
        for (let key in obj) {
            if (obj[key] !== null) {
                newObj[key] = obj[key];
            }
        }
        return newObj
    }

    useEffect(() => {
        handleGetChoices(BRAD_FORD_CHOICES)
        getBradFordAllData()
    }, [])

    useEffect(() => {
        if (contextChoices?.absence_type) {
            setAbsenceList(contextChoices.absence_type)
        } if (contextChoices?.branch) {
            setBranchList(contextChoices.branch)
        }
    }, [contextChoices])


    useEffect(() => {
        // setTitle('Add New Triage');
        let selfPermission = {};
        const self = contextPermissions;
        if (self.length > 0) {
            self.forEach(item => {
                selfPermission[item.section.name] = {};
                selfPermission[item.section.name][WEB_PERMISSION] = {};
                selfPermission[item.section.name][WEB_PERMISSION][
                    SELF_WEB_READ_PERMISSION
                ] = item?.[SELF_WEB_READ_PERMISSION];

                selfPermission[item.section.name][WEB_PERMISSION][
                    SELF_WEB_CREATE_PERMISSION
                ] = item?.[SELF_WEB_CREATE_PERMISSION];

                selfPermission[item.section.name][WEB_PERMISSION][
                    SELF_WEB_UPDATE_PERMISSION
                ] = item?.[SELF_WEB_UPDATE_PERMISSION];

                selfPermission[item.section.name][WEB_PERMISSION][
                    SELF_WEB_DELETE_PERMISSION
                ] = item?.[SELF_WEB_DELETE_PERMISSION];
            });
        }
        let rolePermissions = {};

        const roles = contextPermissions;
        if (roles.length > 0) {
            roles.forEach(item => {
                rolePermissions[item.section.name] = {};
                rolePermissions[item.section.name][WEB_PERMISSION] = {};
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_READ_PERMISSION
                ] = item?.[WEB_READ_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_CREATE_PERMISSION
                ] = item?.[WEB_CREATE_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_DELETE_PERMISSION
                ] = item?.[WEB_DELETE_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_UPDATE_PERMISSION
                ] = item?.[WEB_UPDATE_PERMISSION];
            });
            contextPermissions.forEach(item => {
                rolePermissions[item.section.name] = {};
                rolePermissions[item.section.name][WEB_PERMISSION] = {};
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_READ_PERMISSION
                ] = item?.[WEB_READ_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_CREATE_PERMISSION
                ] = item?.[WEB_CREATE_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_DELETE_PERMISSION
                ] = item?.[WEB_DELETE_PERMISSION];
                rolePermissions[item.section.name][WEB_PERMISSION][
                    WEB_UPDATE_PERMISSION
                ] = item?.[WEB_UPDATE_PERMISSION];
            });
        }
        if (filterSuperAdmin) {
            setReadPermissions(true);
            setUpdatePermissions(true);
            setDeletePermissions(true);
            setCreatePermissions(true);
        }
        if (filterAdmin) {
            setReadPermissions(
                contextSubscription?.[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]
            );
            setUpdatePermissions(true);
            setDeletePermissions(true);
            setCreatePermissions(true);
        }

        if (rolePermissions[BRAD_FORD_PERMISSION] !== undefined) {
            setCreatePermissions(
                (contextSubscription?.[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION] &&
                    rolePermissions[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                    WEB_CREATE_PERMISSION
                    ]) ||
                selfPermission[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                SELF_WEB_CREATE_PERMISSION
                ]
            );
            setDeletePermissions(
                (contextSubscription?.[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION] &&
                    rolePermissions[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                    WEB_DELETE_PERMISSION
                    ]) ||
                selfPermission[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                SELF_WEB_DELETE_PERMISSION
                ]
            );
            setUpdatePermissions(
                (contextSubscription?.[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION] &&
                    rolePermissions[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                    WEB_UPDATE_PERMISSION
                    ]) ||
                selfPermission[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                SELF_WEB_UPDATE_PERMISSION
                ]
            );

            setReadPermissions(
                (contextSubscription?.[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION] &&
                    rolePermissions[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                    WEB_READ_PERMISSION
                    ]) ||
                selfPermission[BRAD_FORD_PERMISSION]?.[WEB_PERMISSION]?.[
                SELF_WEB_READ_PERMISSION
                ]
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        contextIsAdmin,
        contextIsSuperAdmin,
        contextPermissions,
        contextCategoryPermissions,
        contextSubscription,
    ]);



    const stateManagement = () => {
        setEdit(false)
        setSelectedOption([])
        setDisplayToggle(false)
        setAlertToggle(false)
        setSelectedBranch(null)
        setAddBradModal(null)
        setArrr([])
        setPeriod(null)
        setBradFordStatus([])
        setIsLoading(false)
        setIsSubmitted(false)
        setDeleteId(null)
        setDeleteModal(false)
        setBradFodeId(null)
        setError(false)
        setAddBradModal(false)
        setModalValue(false)
        setPeriod(null)
    }

    const saveBradFordFactor = async () => {
        setIsSubmitted(true)
        const newBody = {};
        const body = {
            "id": bradFordId,
            "factor_display": displayToggle,
            "factor_alert": alertToggle,
            "branch": selectedBranch ? selectedBranch : null,
            "factor_period": period,
            "bradfordTrigger": edit ? arr?.map((item) => {

                const obj = {
                    "id": item?.id ? item?.id : null,
                    "upper_range": item?.upper_range,
                    "lower_range": item?.lower_range,
                    "message": item?.message,
                    "trigger_point": item?.trigger_point
                }
                return removeNullValue(obj)
            }) : arr?.map((item) => {
                return ({
                    "upper_range": item?.upper_range,
                    "lower_range": item?.lower_range,
                    "message": item?.message,
                    "trigger_point": item?.trigger_point
                })
            }),
            "bradford_status": edit ? bradFordStatus?.map((item) => {
                const obj = {
                    "id": item?.id ? item?.id : null,
                    "name": item?.name,
                    "point": item?.point
                }
                return removeNullValue(obj)
            }) :
                bradFordStatus?.map((item) => {
                    return ({
                        "name": item?.name,
                        "point": item?.point
                    })
                }),
            "bradfordAbsence": edit ? selectedOption?.map((item) => {
                const obj = {
                    "id": item?.new_Id ? item?.new_Id : null,
                    "absence_type": item?.id,
                }
                return removeNullValue(obj)
            }) : selectedOption?.map((item) => {
                return ({
                    "absence_type": item?.id,
                })
            }),
        }

        for (let key in body) {
            if (body[key] !== null) {
                newBody[key] = body[key];
            }
        }
        console.log(newBody, "saveBradFordFactor_body")
        await addBradFordFactor(newBody, edit)
            .then((resp) => {
                console.log(resp, "addBradFordFactor_API_resp")
                if (resp.status === true) {
                    setIsSubmitted(false)
                    stateManagement()
                    getBradFordAllData()
                } else {
                    console.log("yha AAYA KYA ????")
                    setErrorMessage(resp.message)
                    setErrorSnack(true)
                    setIsSubmitted(false)
                }



            }).catch((err) => {
                setIsSubmitted(false)
                console.log("addBradFordFactor_CATCH_ERR")
                return err
            })
    }

    const getBradFordAllData = async () => {
        setIsLoading(true)
        await getAllBradFordDetails()
            .then((resp) => {
                console.log(resp, "getBradFordAllData_API_resp")
                if (resp.status) {
                    setAllList(resp?.data)
                    setIsLoading(false)
                    setIsSubmitted(false)
                }
            }).catch((err) => {
                setIsLoading(false)
                console.log("getBradFordAllData_CATCH_ERR", err)
                return err
            })
    }

    const getBradFordDataById = async (id) => {
        setIsSubmitted(true)
        await getAllBradFordDetailsbyId(id)

            .then((resp) => {
                console.log(resp, "getBradFordDataById_API_resp")
                if (resp.status) {

                    setBradFodeId(resp.data?.id)
                    setSelectedBranch(resp?.data?.branch)
                    setEdit(true)
                    setDisplayToggle(resp.data?.factor_display)
                    setAlertToggle(resp.data?.factor_alert)
                    setPeriod(resp.data?.factor_period)
                    setSelectedOption(resp?.data.bradfordAbsence?.map((item) => { return ({ new_Id: item?.id, id: item?.absence_type, label: item?.absence_type__name, value: item?.absence_type, }) }))
                    setArrr(resp.data.bradfordTrigger?.map((item, index) => { return ({ ...item, trigger_id: index }) }))
                    setBradFordStatus(resp.data.bradfordStatus?.map((item, index) => { return ({ ...item, status_id: index }) }))
                    setIsSubmitted(false)

                }
            }).catch((err) => {
                console.log("getBradFordAllData_CATCH_ERR 000")
                return err
            })
    }

    const deleteBradFordDataById = async (item) => {
        setIsSubmitted(true)
        await deleteBradFord(item)
            .then((resp) => {
                console.log(resp, "getBradFordDataById_API_resp")
                if (resp.status) {
                    // setAllList(resp?.data)
                    stateManagement()
                    setModalDeleteValue(false)
                    getBradFordAllData()
                }
            }).catch((err) => {
                console.log("getBradFordAllData_CATCH_ERR")
                return err
            })
    }

    return (
        <div >
            <AdddBradFord addBradModal={modalValue} stateManagement={stateManagement} setAddBradModal={setModalValue} edit={edit} setEdit={setEdit} selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                alertToggle={alertToggle} setAlertToggle={setAlertToggle} displayToggle={displayToggle} setDisplayToggle={setDisplayToggle} period={period} setPeriod={setPeriod}
                arr={arr} setArrr={setArrr} bradFordStatus={bradFordStatus} setBradFordStatus={setBradFordStatus} saveBradFordFactor={saveBradFordFactor} isSubmitted={isSubmitted}
                absenceList={absenceList} setAbsenceList={setAbsenceList} branchList={branchList} setBranchList={setBranchList} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} setBradFodeId={setBradFodeId}
                update={update} fullBind={fullBind} setTitle={setTitle}
                error={error} setError={setError}

            />

            <ErrorAlert open={errorSnack} setOpen={setErrorSnack} errorMessage={errorMessage} />
            {
                !isLoading ?
                    <div >
                        <Title style={{ marginBottom: 20 }}>Settings Brad-Ford</Title>


                        {
                            create && <Header style={{ padding: 10 }}>
                                <div className="d-flex align-items-center" />
                                <SearchAddContainer >
                                    <FloatingMobileButton
                                        onClick={() => {
                                            setEdit(false)
                                            stateManagement()

                                            // setAddBradModal(true)
                                            setModalValue(true)
                                        }}>
                                        <FaPlus />
                                        <label>  NEW BRADFORD</label>
                                    </FloatingMobileButton>
                                </SearchAddContainer>
                            </Header>
                        }

                        {
                            allList?.length > 0 ? <Table headerColumns={headerColumns}>
                                {allList.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Row bgColor>
                                                <ColsGrouper
                                                    className="d-flex"
                                                // onClick={() => {
                                                //     alert("Don't Clicke Me.")
                                                // }}
                                                >

                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}
                                                    >
                                                        <label>{item.branch__name}</label>
                                                    </Col>

                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}
                                                    >
                                                        <label>{item.factor_display ? 'Yes' : 'No'}</label>
                                                    </Col>


                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}
                                                    >
                                                        <label>{item.factor_alert ? 'Yes' : "No"}</label>
                                                    </Col>

                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}
                                                    >
                                                        <label>
                                                            {
                                                                //  data.bradfordStatus.forEach(item=>console.log(item))
                                                                item.factor_period
                                                            }
                                                        </label>

                                                    </Col>

                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}
                                                    >
                                                        <label>
                                                            {
                                                                //  data.bradfordStatus.forEach(item=>console.log(item))
                                                                item.bradfordAbsence.length > 0 ?
                                                                    item.bradfordAbsence.toString()
                                                                    : null
                                                            }
                                                        </label>
                                                    </Col>


                                                    <Col className="sm-hidden" Center Icon Purple>
                                                        <IconWrapper

                                                            onClick={async () => {
                                                                await getBradFordDataById(item?.id)
                                                                // setAddBradModal(true)
                                                                setModalValue(true)
                                                                // alert("Hey,I'm edit Icon")

                                                            }}
                                                        >
                                                            <MdEdit />
                                                        </IconWrapper>
                                                    </Col>

                                                </ColsGrouper>

                                                <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                                    {
                                                        deletePermission &&
                                                        <IconWrapper
                                                            onClick={async () => {
                                                                await setIdSelected(item?.id)
                                                                console.log(item, "kdsjl")
                                                                setModalDeleteValue(true)

                                                                // alert("Don't Clicke Me.")
                                                            }}>
                                                            <RiDeleteBinLine />
                                                        </IconWrapper>
                                                    }
                                                </Col>

                                            </Row>
                                            <div className="mb-1" />
                                        </div>
                                    )
                                })}
                            </Table > :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <h4>No data found</h4>
                                </div>
                        }
                    </div> :
                    <Spinner
                        spinnerTop={"50%"}
                        textTop={'68%'}
                    />

            }

            <div className="mt-3">
                {/* <Pagination
                    totalPages={100}
                    currentPage={1}
                // values={values}
                // dropDownHandleSelect={dropDownHandleSelectPagination}
                // number={PAGE_SIZE_RENDER}
                // dataLength={taskData.length}
                /> */}
            </div>

            <ModalDecision
                type="delete"
                title="Warning"
                body={
                    'Are you sure you want to delete this Brad-ford Factor? \n deleting the bradFord factor will remove all records from database.'
                }
                onOk={() => { deleteBradFordDataById(idSelected) }}
                onCancel={() => {
                    setIdSelected('');
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
        </div >
    )
}

export default SettingBradFord;




// {
//     "id": "a3bd27eb-07e8-4c14-9e7d-5793a8d09d17",
//     "factor_display": true,
//     "factor_alert": true,
//     "branch": "bace0264-ea2e-488d-a3d2-c2eb369cf531",
//     "factor_period": 2,

//     ],
//     "bradfordStatus": [
//         {
//             "name": "status_1",
//             "point": 45,
//             "id": "905d707f-c7f7-4e5f-85ab-370bb4df4647"
//         },
//         {
//             "name": "status_2",
//             "point": 46,
//             "id": "40926606-9b2b-4ae3-811e-a8e24f871950"
//         },
//         {
//             "name": "status_3",
//             "point": 47,
//             "id": "a07158e7-afd1-4584-a380-d09bfad358ad"
//         }
//     ],
//     "bradfordTrigger": [
//         {
//             "upper_range": 20,
//             "lower_range": 10,
//             "id": "80825345-ebad-444f-a5bb-90b6293dfc95",
//             "message": "this is bradford trigger",
//             "trigger_point": 1
//         },
//         {
//             "upper_range": 30,
//             "lower_range": 20,
//             "id": "3ef4408f-4615-48e0-b810-7a5236855e8d",
//             "message": "this is bradford trigger",
//             "trigger_point": 3
//         },
//         {
//             "upper_range": 40,
//             "lower_range": 30,
//             "id": "a2b69253-f2b4-415d-bfb8-bd405b91ff47",
//             "message": "this is bradford trigger",
//             "trigger_point": 2
//         }
//     ]
// }

