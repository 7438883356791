import { lineHeight } from '@mui/system';
import React, { useEffect } from 'react';
import { useState } from 'react';
import './style'
import {TitleCheckbox} from './style' ;
import {Container, FloatingLabel, ErrorLabel} from '../../../../../../SharedComponents/DropdownSearchable/styles';

function WeekDays({
   values,
   setValues,
   indexRate,
   nameSection,
   checkedCondition,
   copyToken,
   dummyID,
   // checkBoxTick,
}
) {

   const[defaultChecksMon,setDefaultChecksMon]=useState(false)
   const[defaultChecksTue,setDefaultChecksTue]=useState(false)
   const[defaultChecksWed,setDefaultChecksWed]=useState(false)
   const[defaultChecksThru,setDefaultChecksThru]=useState(false)
   const[defaultChecksFri,setDefaultChecksFri]=useState(false)
   const[defaultChecksSat,setDefaultChecksSat]=useState(false)
   const[defaultChecksSun,setDefaultChecksSun]=useState(false)

   useEffect(()=>{
      if(!copyToken){
         if(!values[nameSection][indexRate].daysofWeek){
            values[nameSection][indexRate].daysofWeek=[
               {day: 'MON', dayValue: 1, checked: false},
               {day: 'TUE', dayValue: 2, checked: false},
               {day: 'WED', dayValue: 3, checked: false},
               {day: 'THU', dayValue: 4, checked: false},
               {day: 'FRI', dayValue: 5, checked: false},
               {day: 'SAT', dayValue: 6, checked: false},
               {day: 'SUN', dayValue: 7, checked: false},
             ]
         }

               setDefaultChecksMon(values[nameSection][indexRate].is_monday)
               setDefaultChecksTue(values[nameSection][indexRate].is_tuesday)
               setDefaultChecksWed(values[nameSection][indexRate].is_wednesday)
               setDefaultChecksThru(values[nameSection][indexRate].is_thursday)
               setDefaultChecksFri(values[nameSection][indexRate].is_friday)
               setDefaultChecksSat(values[nameSection][indexRate].is_saturday)
               setDefaultChecksSun(values[nameSection][indexRate].is_sunday)

      }    
   },[])


useEffect(()=>{
   if(copyToken){
         values[nameSection][indexRate].daysofWeek=[
            {day: 'MON', dayValue: 1, checked: values[nameSection][indexRate].is_monday},
            {day: 'TUE', dayValue: 2, checked: values[nameSection][indexRate].is_tuesday},
            {day: 'WED', dayValue: 3, checked: values[nameSection][indexRate].is_wednesday},
            {day: 'THU', dayValue: 4, checked: values[nameSection][indexRate].is_thursday},
            {day: 'FRI', dayValue: 5, checked: values[nameSection][indexRate].is_friday},
            {day: 'SAT', dayValue: 6, checked: values[nameSection][indexRate].is_saturday},
            {day: 'SUN', dayValue: 7, checked: values[nameSection][indexRate].is_sunday},
          ]
setDefaultChecksMon(values[nameSection][indexRate].is_monday)
setDefaultChecksTue(values[nameSection][indexRate].is_tuesday)
setDefaultChecksWed(values[nameSection][indexRate].is_wednesday)
setDefaultChecksThru(values[nameSection][indexRate].is_thursday)
setDefaultChecksFri(values[nameSection][indexRate].is_friday)
setDefaultChecksSat(values[nameSection][indexRate].is_saturday)
setDefaultChecksSun(values[nameSection][indexRate].is_sunday)
setValues({...values})
   }

},[

])

// useEffect(()=>{
//    if(dummyID){
//       values[nameSection][indexRate].daysofWeek=[
//          {day: 'MON', dayValue: 1, checked: values[nameSection][indexRate].is_monday},
//          {day: 'TUE', dayValue: 2, checked: values[nameSection][indexRate].is_tuesday},
//          {day: 'WED', dayValue: 3, checked: values[nameSection][indexRate].is_wednesday},
//          {day: 'THU', dayValue: 4, checked: values[nameSection][indexRate].is_thursday},
//          {day: 'FRI', dayValue: 5, checked: values[nameSection][indexRate].is_friday},
//          {day: 'SAT', dayValue: 6, checked: values[nameSection][indexRate].is_saturday},
//          {day: 'SUN', dayValue: 7, checked: values[nameSection][indexRate].is_sunday},
//        ]
// setDefaultChecksMon(values[nameSection][indexRate].is_monday)
// setDefaultChecksTue(values[nameSection][indexRate].is_tuesday)
// setDefaultChecksWed(values[nameSection][indexRate].is_wednesday)
// setDefaultChecksThru(values[nameSection][indexRate].is_thursday)
// setDefaultChecksFri(values[nameSection][indexRate].is_friday)
// setDefaultChecksSat(values[nameSection][indexRate].is_saturday)
// setDefaultChecksSun(values[nameSection][indexRate].is_sunday)
// setValues({...values})
// }

// },[
// ])


   const checkboxHit=(a,b)=>{
      values[nameSection][indexRate].daysofWeek.find(x=>x.dayValue == a).checked=b;

      if(a == 1){
         setDefaultChecksMon(!defaultChecksMon)
         values[nameSection][indexRate].is_monday=!values[nameSection][indexRate].is_monday;
      }
      if(a == 2){
         setDefaultChecksTue(!defaultChecksTue)
         values[nameSection][indexRate].is_tuesday=!values[nameSection][indexRate].is_tuesday;
      }
      if(a == 3){
         setDefaultChecksWed(!defaultChecksWed)
         values[nameSection][indexRate].is_wednesday=!values[nameSection][indexRate].is_wednesday;
      }
      if(a == 4){
         setDefaultChecksThru(!defaultChecksThru)
         values[nameSection][indexRate].is_thursday=!values[nameSection][indexRate].is_thursday;
      }
      if(a == 5){
         setDefaultChecksFri(!defaultChecksFri)
         values[nameSection][indexRate].is_friday=!values[nameSection][indexRate].is_friday;
      }
      if(a == 6){
         setDefaultChecksSat(!defaultChecksSat)
         values[nameSection][indexRate].is_saturday=!values[nameSection][indexRate].is_saturday;
      }
      if(a == 7){
         setDefaultChecksSun(!defaultChecksSun)
         values[nameSection][indexRate].is_sunday=!values[nameSection][indexRate].is_sunday;
      }
        setValues({...values})
      }



  return(
   <>  
    <div className='d-flex justify-content-around  align-center'  style={{backgroundColor : '#fafafc',lineHeight:'20px'}}>
 <div   className="d-flex flex-row">
  <div  className='d-flex flex-column'>
   <div><TitleCheckbox>M</TitleCheckbox></div>
   <div className="pr-1 text-left"   > <input type="checkbox" name="checkboxes" id="checkboxes-1" value="1"  checked={defaultChecksMon}  onChange={e=>checkboxHit( e.target.value,e.target.checked )}    /></div>
</div>
<div  className="d-flex flex-column">
<div><TitleCheckbox>T</TitleCheckbox></div>
   <div  className="pr-1 text-left"> <input type="checkbox" name="checkboxes" id="checkboxes-2" value="2"  checked={defaultChecksTue}  onChange={e=>checkboxHit( e.target.value,e.target.checked )}
   /></div>
</div>
<div  className="d-flex flex-column">
<div><TitleCheckbox>W</TitleCheckbox></div>
   <div  className="pr-1"> <input type="checkbox" name="checkboxes" id="checkboxes-3" value="3"  checked={defaultChecksWed} onChange={e=>checkboxHit( e.target.value,e.target.checked )}  /></div>
</div>
<div  className="d-flex flex-column">
<div><TitleCheckbox>Th</TitleCheckbox></div>
   <div  className="pr-1"> <input type="checkbox" name="checkboxes" id="checkboxes-4" value="4"  checked={defaultChecksThru}  onChange={e=>checkboxHit( e.target.value,e.target.checked )}   /></div>
</div>
<div  className="d-flex flex-column">
<div><TitleCheckbox>F</TitleCheckbox></div>
   <div  className="pr-1"> <input type="checkbox" name="checkboxes" id="checkboxes-5" value="5"   checked={defaultChecksFri} onChange={e=>checkboxHit( e.target.value,e.target.checked )} /></div>
</div>
<div  className="d-flex flex-column">
<div><TitleCheckbox>Sa</TitleCheckbox></div>
   <div className="pr-1"> <input type="checkbox" name="checkboxes" id="checkboxes-6" value="6"  checked={defaultChecksSat}  onChange={e=>checkboxHit( e.target.value,e.target.checked )} /></div>
</div>
<div  className="d-flex flex-column">
<div ><TitleCheckbox>Su</TitleCheckbox></div>
   <div  className="pr-1"> <input type="checkbox" name="checkboxes" id="checkboxes-7" value="7"   checked={defaultChecksSun} onChange={e=>checkboxHit( e.target.value,e.target.checked )} /></div>
</div>
</div>
    
</div>
 {/* {haveError ? labelError :  ''}   */}
 </>
  )
}

export default WeekDays;
