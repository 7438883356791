import React, { Fragment, useEffect, useState } from 'react';
import { ServiceVisit } from 'components/SharedComponents/FormSections';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import {
  ButtonWeek,
  CloseButtonWrapper,
  FlexGrid3Container,
  FlexGrid3Item,
  IconWrapper,
  TitleSection,
  WeekStyle,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import { getPreferredVisits, getPreferredCompetency } from 'utils/api/ClientApi';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { DELETE, TIMES } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { HOUR, MINUTE } from 'utils/constants/serviceVisits';
import CustomDateSelector from 'components/SharedComponents/DateSelector/custumDateSelector';
import DateSelector from 'components/SharedComponents/DateSelector';

const AddNewAdjustment = ({
  values,
  dataVisit,
  useInput,
  nameSection,
  deleteVisit,
  addNewVisit,
  editMode,
  indexVisit,
  setValues,
  contextChoices

}) => {
 
  return (
    <div key={dataVisit.id} className="row p-2">
        <CloseButtonWrapper
            onClick={() => {
                deleteVisit(dataVisit.id);
            }}
        >
            {TIMES}
        </CloseButtonWrapper>
        <div className={'col'}>
            <InputLabelTop
                type="number"
                id="repeat_no"
                label={'Adjustment no.'}
               // onKeyPress={KeyPress.INTEGER}
                maxlength={5}
                {...useInput(`adjustment[${indexVisit}].count_up_down`)}
                //value={val.count_up_down}
                // onChange={valu => {
                //     console.log(val)
                //     const final = []
                //     numberOfForms.forEach(value => {
                //         if (val.id === value.id) {
                //             final.push({ ...value, count_up_down: valu.target.value })
                //             return
                //         }
                //         final.push(value)
                //     })
                //     setNumberOfForms(final)
                // }
                //}
            />
        </div>
        <div className={'col'} >
            <DateSelector
                errorMessage={"Hello"}
                labelTop={'Consumption date'}
                {...useInput(`adjustment[${indexVisit}].date_of_entry`)}
                // value={val.date_of_entry}
                // onChange={valu => {
                //     console.log(valu)
                //     const final = []
                //     numberOfForms.forEach(value => {
                //         if (val.id === value.id) {
                //             final.push({ ...value, date_of_entry: moment(valu).format("YYYY-MM-DD") })
                //             return
                //         }
                //         final.push(value)
                //     })
                //     setNumberOfForms(final)
                // }
                // }
            />
        </div>

        <div className={'col'}>
            <InputLabelTop
                type="text"
                id="repeat_no"
                label={'Adjustment Reason'}
                maxlength={50}
                {...useInput(`adjustment[${indexVisit}].reason`)}
                // value={val.reason}
                // onChange={valu => {
                //     console.log(val)
                //     const final = []
                //     numberOfForms.forEach(value => {
                //         if (val.id === value.id) {
                //             final.push({ ...value, reason: valu.target.value })
                //             return
                //         }
                //         final.push(value)
                //     })
                //     setNumberOfForms(final)
                // }
                //}
            />
        </div>

        <div className={'col'}>
            <InputLabelTop
                type="text"
                disabled={false}
                id="repeat_no"
                label={'Created by'}
                maxlength={50}
                // value={contextChoices ? contextChoices?.user_list?.find(val => val.id === val.created_by)?.name : ""}
                // onChange={valu => { }}
                {...useInput(`adjustment[${indexVisit}].${contextChoices ? contextChoices?.user_list?.find(val => val.id === val.created_by)?.name : ""}`)}
            />
        </div>

    </div>
)
};

AddNewAdjustment.propTypes = {
  dropDownHandleVisits: PropTypes.func.isRequired,
  addNewVisit: PropTypes.func.isRequired,
  deleteVisit: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  visits: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  toDeleteVisitSkill: PropTypes.array.isRequired,
  toDeleteVisitEmployee: PropTypes.array.isRequired,
  serviceId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  errors: PropTypes.array,
};

export default AddNewAdjustment;
