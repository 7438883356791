import React, { useEffect, useState, Fragment } from 'react';
import {
  Demographics,
} from 'components/SharedComponents/FormSections';
import { useForm } from 'hooks/FormHook';
import _ from 'lodash';
import { NoPermissionContainer, TitleSection } from '../../Team/AddModal/styles'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import AvoidDetails from 'components/SharedComponents/FormSections/Team/AvoidDetails'
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { BsFillPlusSquareFill } from "react-icons/bs"
import { useHistory, useLocation } from 'react-router';
import { setClientPrefernce, getClientPreference } from 'utils/api/ClientApi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  CLIENT_DEMOGRAPHICS,
  CLIENT_PREFERENCES,
  CLIENT_INCOMPATIBILITIES,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION
} from '../../../../utils/constants/permissions';
import { AppContext } from 'context';
import { useContext } from 'react';
import { client_preferences_choices } from 'utils/choiceConstant';


const ClientPreference = ({
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  createPermissionPrefrences,
  updatePermissionPrefrences,
  readPermissionPrefrences,
  userClient,
  choices,
  employees,
  selectedClientId,
  // cliId,
  setPrefDet,
  prefDet,
  contextRelativesClient
}) => {
  const [preferredSelected, setPreferredSelected] = useState([]);
  const [avoidedSelected, setAvoidedSelected] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalLoading, setModalLoading] = useState(true)
  const [clientPreClientDemographicsCreate, setClientPreClientDemographicsCreate] = useState(false);
  const [clientPreClientDemographicsUpdate, setClientPreClientDemographicsUpdate] = useState(false);
  const [clientPreClientDemographicsRead, setClientPreClientDemographicsRead] = useState(false);
  const [clientPreClientPreferencesCreate, setClientPreClientPreferencesCreate] = useState(false);
  const [clientPreClientPreferencesUpdate, setClientPreClientPreferencesUpdate] = useState(false);
  const [clientPreClientPreferencesRead, setClientPreClientPreferencesRead] = useState(false);
  const [clientPreIncompatibilitiesRead, setClientPreIncompatibilitiesRead] = useState(false);
  const [clientPreIncompatibilitiesCreate, setClientPreIncompatibilitiesCreate] = useState(false);
  const [clientPreIncompatibilitiesUpdate, setClientPreIncompatibilitiesUpdate] = useState(false);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const history = useHistory()
  const location = useLocation()

  const { contextChoices, handleGetChoices } = useContext(AppContext)


  useEffect(() => {
    handleGetChoices(client_preferences_choices)
  }, [])

  useEffect(() => {
    if (filterSuperAdmin) {
      setClientPreClientDemographicsCreate(true);
      setClientPreClientDemographicsUpdate(true);
      setClientPreClientDemographicsRead(true);
      setClientPreClientPreferencesCreate(true)
      setClientPreClientPreferencesUpdate(true)
      setClientPreClientPreferencesRead(true)
      setClientPreIncompatibilitiesRead(true)
      setClientPreIncompatibilitiesUpdate(true)
      setClientPreIncompatibilitiesCreate(true)
    } else if (filterAdmin) {
      setClientPreClientDemographicsCreate(true);
      setClientPreClientDemographicsUpdate(true);
      setClientPreClientDemographicsRead(contextSubscription?.[CLIENT_DEMOGRAPHICS]?.[WEB_PERMISSION]);
      setClientPreClientPreferencesCreate(true)
      setClientPreClientPreferencesUpdate(true)
      setClientPreClientPreferencesRead(contextSubscription?.[CLIENT_PREFERENCES]?.[WEB_PERMISSION])
      setClientPreIncompatibilitiesRead(contextSubscription?.[CLIENT_INCOMPATIBILITIES]?.[WEB_PERMISSION])
      setClientPreIncompatibilitiesUpdate(true)
      setClientPreIncompatibilitiesCreate(true)
    }
    else {
      if (rightPermission[CLIENT_DEMOGRAPHICS] !== undefined) {
        setClientPreClientDemographicsCreate(
          rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientPreClientDemographicsUpdate(
          rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientPreClientDemographicsRead(contextSubscription?.[CLIENT_DEMOGRAPHICS]?.[WEB_PERMISSION] && (
          rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[CLIENT_DEMOGRAPHICS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_PREFERENCES] !== undefined) {

        setClientPreClientPreferencesCreate(
          rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientPreClientPreferencesUpdate(
          rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientPreClientPreferencesRead(contextSubscription?.[CLIENT_PREFERENCES]?.[WEB_PERMISSION] && (
          rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[CLIENT_PREFERENCES][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_INCOMPATIBILITIES] !== undefined) {
        setClientPreIncompatibilitiesRead(contextSubscription?.[CLIENT_INCOMPATIBILITIES]?.[WEB_PERMISSION] && (
          rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setClientPreIncompatibilitiesUpdate(
          rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setClientPreIncompatibilitiesCreate(
          rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_INCOMPATIBILITIES][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }


    }

  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription, contextRelativesClient])

  useEffect(() => {
    if (!values.client_avoid_employee) {
      values.client_avoid_employee = []
      setValues({ ...values })
    }
  })
  useEffect(() => {
    if (selectedClientId || prefDet) {
      loadPrefernces()
    }
    else {
      setModalLoading(false)
    }

  }, [])
  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;

    result = _.merge(values, item);

    setValues({ ...result });
  };
  const loadPrefernces = async () => {
    let id = selectedClientId ? selectedClientId : prefDet
    const response = await getClientPreference(id)
    if (response.results.length > 0) {
      debugger

      response.results[0].client_preferred_employee = response.results[0].client_preferred_employee
        && response.results[0].client_preferred_employee.map(emp_Client => {
          return (
            {
              id: emp_Client.employee,
              value: emp_Client.employee,
              label: emp_Client.name,
              employee_id: emp_Client.id
            }
          )

        })

      response.results[0].client_preferred_skills = response.results[0].client_preferred_skills
        && response.results[0].client_preferred_skills.map(item => {
          return (
            {
              value: item.value,
              id: item.value,
              label: item.label,
              skill_id: item.id
            }
          )

        })

      response.results[0].client_preferred_competency = response.results[0].client_preferred_competency
        && response.results[0].client_preferred_competency.map(item => {
          return (
            {
              id:  item.value,
              value: item.value,
              label: item.label,
              competency_id: item.id
            }
          )

        })
      setValues({ ...values, ...response.results[0] })
    }

    setModalLoading(false)
  }
  const preferredHandleSelect = preferred => {
    if (preferred.client) {
      values.client_preferred_employee = preferred.client
      setValues({ ...values })
    }
    if (preferred.skill) {
      values.client_preferred_skills = preferred.skill
      setValues({ ...values })
    }
    if (preferred.competency) {
      values.client_preferred_competency = preferred.competency
      setValues({ ...values })
    }
    if (preferred.group_id) {
      values.group_id = preferred.group_id
      setValues({ ...values })
    }
    if (preferred.task_id) {
      values.task_id = preferred.task_id
      setValues({ ...values })
    }
  };
  const avoidHandleSelect = avoided => {
    setAvoidedSelected(avoided.demographic.avoid_employees);
  };
  const dropDownHandleAvoid = (item, nameSection, index, list_key) => {
    values[nameSection][index][list_key] = item.avoid
    setValues({ ...values })
  }
  const deleteAvoid = (nameSection, index) => {
    values.client_avoid_employee.splice(index, 1)
    setValues({ ...values })
  }
  const addNewAvoid = (nameSection) => {
    values[nameSection] = [...values[nameSection], {}]
    setValues({ ...values })

  }
  const handleSubmit = (evt, triggerSave = true) => {
    if (loadingSave) {
      return;
    }

    let body = {
      ...values,
    };

    if (body.client_preferred_competency) {
      body.client_preferred_competency = body.client_preferred_competency.map(item => {
        if (item.competency_id) {
          return (
            {
              id: item.competency_id,
              value: item.value,
            }
          )
        } else {
          return (
            {
              value: item.value
            }
          )
        }
      })
    }

    if (body.client_preferred_skills) {
      body.client_preferred_skills = body.client_preferred_skills.map(item => {
        if (item.skill_id) {
          return {
            id: item.skill_id,
            value: item.value,
          };
        } else {
          return {
            value: item.value,
          };
        }
      });
    }
    if (body.client_preferred_employee) {
      body.client_preferred_employee = body.client_preferred_employee.map(
        item => {
          if (item.employee_id) {
            return {
              id: item.employee_id,
              employee: item.value,
            };
          } else {
            return {
              employee: item.value,
            };
          }
        }
      );
    }


    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    if (triggerSave) {
      setLoadingSave(true);
    }
    setCheckRequires(false);
    setShowError(false);
    let value = [];

    setClientPrefernce(body, selectedClientId)
      .then(response => {
        if (response.statusCode === 400) {

          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };

  const onSuccess = async (response) => {
    if (response.statusCode === 200 || response.statusCode === 201) {
      let clientId = ''



      let preferenceId = response.id;
      // if employee id

      setValues({
        ...values,
        id: preferenceId,
      });

      setPrefDet(selectedClientId)
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
    );
  };
  console.log(values, "333333")
  return (
    <>
      <ComponentDynamic
        loading={modalLoading}
      >
        {(selectedClientId ? readPermissionPrefrences : createPermissionPrefrences) ?
          <>
            <div className={'mb-3'}>
              {(selectedClientId ? clientPreClientDemographicsRead : clientPreClientDemographicsCreate) &&
                <Demographics
                  useInput={useInput}
                  dropDownHandleSelect={dropDownHandleSelect}
                  choices={contextChoices}
                  values={values}
                  employeesData={employees}
                  preferredHandleSelect={preferredHandleSelect}
                  preferredSelected={preferredSelected}
                  avoidHandleSelect={avoidHandleSelect}
                  avoidSelected={avoidedSelected}
                />
              }
            </div>
            {(selectedClientId ? clientPreClientPreferencesRead : clientPreClientPreferencesCreate) &&
              <>
                <TitleSection>Preferences</TitleSection>
                <div className="d-flex flex-column mt-2">
                  <div className="d-flex mb-2">
                    <div className="w-100 mr-2">
                      <DropdownSearchable
                        placeHolder={'Preferred Gender'}
                        options={contextChoices.gender}
                        selectedOption={
                          values.gender ? values.gender : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { gender: value ? value : null },
                          )
                        }
                      />
                    </div>
                    <div className="w-100">
                      <DropdownSearchable
                        placeHolder={'keyWorker Name'}
                        options={employees}
                        selectedOption={
                          values.key_worker ? values.key_worker : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { key_worker: value ? value : null },
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="w-100">
                      <DropdownSearchable
                        isMulti={true}
                        placeHolder={'Preferred Employees'}
                        options={employees}
                        selectedOptionsProps={values.client_preferred_employee
                          ? values.client_preferred_employee
                          : null}
                        onChange={values =>
                          preferredHandleSelect(
                            {
                              client: values.length ? values : [],
                            },
                          )
                        }
                      />
                    </div>
                    <div className="w-100 mt-2">
                      <DropdownSearchable
                        isMulti={true}
                        placeHolder={'Preferred Skills'}
                        options={contextChoices.skill}
                        selectedOptionsProps={values.client_preferred_skills
                          ? values.client_preferred_skills
                          : null}
                        onChange={values =>
                          preferredHandleSelect(
                            {
                              skill: values.length ? values : [],
                            },
                          )
                        }
                      />
                    </div>
                    <div className="d-flex mt-2">
                      <div className="w-100 mr-2">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Competencies'}
                          options={contextChoices.competency_name}
                          selectedOptionsProps={values.client_preferred_competency
                            ? values.client_preferred_competency
                            : null}
                          onChange={values =>
                            preferredHandleSelect(
                              {
                                competency: values.length ? values : [],
                              },
                            )
                          }
                        />
                      </div>
                      {/* <div className="w-100 mr-2">
                       <DropdownSearchable
                    width={'33%'}
                   marginLeft={'0.5%'}
                  placeHolder={'Group Tasks'}
                  options={choices.group_task}
                  isMulti={true}
                  selectedOptionsProps={
                    values.group_id ? values.group_id : null
                  }
                  onChange={value =>
                    preferredHandleSelect(
                      {group_id: value.length ? value : []},
                    )
                    
                  }
                />
                </div>
                 <div className="w-100 mr-2">
                <DropdownSearchable
                    width={'33%'}
                   marginLeft={'0.5%'}
                  placeHolder={'Care Tasks'}
                  options={choices.care_task}
                  isMulti={true}
                  selectedOptionsProps={
                    values.task_id ? values.task_id : null
                  }
                  onChange={value =>
                    preferredHandleSelect(
                      {task_id: value.length ? value : []},
                    )
                    
                  }
                />
                </div> */}
                    </div>
                  </div>
                </div>
              </>
            }
            {(selectedClientId ? clientPreIncompatibilitiesRead : clientPreIncompatibilitiesCreate) &&
              <>
                <TitleSection className={'mt-3'}>Incompatibilities</TitleSection>
                <div>

                  {
                    values.client_avoid_employee && values.client_avoid_employee.map((employee, indexEmployee) => {
                      return (
                        <AvoidDetails
                          values={values}
                          dropDownLabel={'DO-NOT Send Employees'}
                          dropDownList={employees}
                          list_key={'employee'}
                          dropDownHandleAvoid={dropDownHandleAvoid}
                          useInput={useInput}
                          nameSection={'client_avoid_employee'}
                          index={indexEmployee}
                          deleteAvoid={deleteAvoid}
                        />
                      )
                    })
                  }
                </div>
                <PrimaryButtonForm
                  className={'ml-3 mt-1 mb-2'}
                  bgcolor="BLUE"
                  minWidth="3rem"
                  onClick={() => addNewAvoid('client_avoid_employee')}
                >
                  <span>{<BsFillPlusSquareFill />}Avoid Employees</span>
                </PrimaryButtonForm>
              </>
            }
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      // setModalValue()
                      history.push('/client')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                  {(selectedClientId ? updatePermissionPrefrences : true) && (
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={handleSubmit}
                    >
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  )}
                </div>


              </div>
            </div>
          </>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
          </NoPermissionContainer>
        }
      </ComponentDynamic>
    </>
  );
}

export default ClientPreference;
