import React from 'react';
import DatePicker from 'react-datepicker';
import {DatePickerStyled, ErrorLabel, Icon} from './styles';
import {MdAccessTime} from 'react-icons/md';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function TimeSelector(props) {
  let selectedValue = null;
  let excludeTimes = null;
   
  if (props.value) {
  
    selectedValue = new Date('01/02/1970 ' + props.value);
  }
 
  if(props.excludeTimes){
    excludeTimes =  props.excludeTimes.map(time => {
      const [hr, min] = time.split(":")
      return new Date(0, 0, 0, hr, min)
    })
  }
 
  // Default min and max time values
  const minTime = props.minTime
    ? new Date('01/02/1970 ' + props.minTime)
    : new Date('01/02/1970 00:00');
  const maxTime = props.maxTime
    ? new Date('01/02/1970 ' + props.maxTime)
    : new Date('01/02/1970 23:59');

  // Hardcoded min and max time values (08:00 to 18:00)
  const _minTime = new Date('01/02/1970 08:00');
  const _maxTime = new Date('01/02/1970 18:00');
    console.log(minTime,  maxTime, props.minTime,
      props.maxTime, _maxTime,_minTime ,"Hardcoded")
  return (
    <DatePickerStyled>
      <label>
        {props.labelTop} <span>{props.isRequired ? '*' : ''}</span>
      </label>
      <DatePicker
        className={
          props['data-error']
            ? 'error-border'
            : props.value
            ? 'value-border'
            : ''
        }
        // placeholderText="Click to select"
        selected={selectedValue}
        onChange={time =>
          props.onChange({
            target: {
              value: time,
              type: 'time',
              checked: '',
              dataset: {idx: props.dataIdx},
            },
          })
        }
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={props.timeIntervals || 5}
        timeCaption={props.timeCaption ? props.timeCaption  : "Time"}
        placeholderText={props.placeholderText}
        timeFormat={props.timeFormat }
        dateFormat={props.dateFormat ? props.dateFormat  :  'h:mm aa'}
        disabled={props.disabled}
        isClearable={!props.disabled}
        locale={props.locale}
        excludeTimes={excludeTimes || []}
        minTime={minTime}
        maxTime={maxTime}
      />
      {props['data-error'] ? (
        <ErrorLabel>{props.errorMessage || 'Is required'}</ErrorLabel>
      ) : (
        <></>
      )}
      {props.value ? (
        <></>
      ) : (
        <Icon>
          <MdAccessTime />
        </Icon>
      )}
    </DatePickerStyled>
  );
}
export default TimeSelector;
