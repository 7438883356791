import React from 'react';

function CareTable({
  days,
  index,
  el,
  startDate,
  endDate,
  isChecked,
  splitDate,
}) {

  const renderEmployeeRequirement = (employee, el) => {
    let str = ""
    if (employee.noOfEmployee) {
      str += `${employee.noOfEmployee}X`
    } if (employee.skillName) {
      str += ` ${employee.skillName} ${employee.gender ? "(" + employee.gender.slice(0, 1) + ")" : ""}`
    }
    if (employee.noOfEmployee || employee.skillName) {
      if (employee.startTime && employee.endTime) {
        str += ` ${splitDate(employee.startTime + "-" + employee.endTime)}`
      } else {
        str += ` ${splitDate(el.preferredStartEnd)}`
      }
    }
    return str
  }

  return (

    <tr>
      <td className="">
        <div className="m-s-1 pb-1">
          <span className="title-text">{el.visitType}</span>
          <p className="sub-text mt-1 mb-1">
            {`${el.serviceCategory ? el.serviceCategory + ',' : ''
              } ${splitDate(el.preferredStartEnd)}`}
          </p>
          <p className="sub-text mb-1">{`${startDate} - ${el.endDate ? endDate : 'Ongoing' + ', ' + el.everyRepeatOn
            }`}</p>
          {el.employeeRequirement.map((employee) => {
            return <p key={Math.random()} className="sub-text mt-1 mb-1">
              {renderEmployeeRequirement(employee, el)}
            </p>
          })}
        </div>
      </td>
      {days.map((element, i) => {
        return (
          <td key={Math.random()} className="th3">
            <div className="container">
              <div
                className={`round ${isChecked(el.repeatOn, element) ? 'active-check' : ''
                  }`}
              >
              </div>
            </div>
          </td>
        );
      })}
    </tr>

  );
}

export default CareTable;
