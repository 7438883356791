import styled from 'styled-components';
import {PURPLE, GRAY_300, GRAY_700} from 'shared/styles/constants/colors';
import {SM, MD, XXL} from 'shared/styles/constants/resolutions';

export const Header = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid ${GRAY_300};
  display: flex;
  justify-content: space-between;
  @media (max-width: ${XXL}) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${MD}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const EmploymentTypesContainer = styled.div`
  min-width: 8.75rem;
  @media (max-width: ${SM}) {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const PublishButton = styled.div`
  svg {
    font-size: 1.8rem;
    color: ${props => (props.disable ?  GRAY_700 : PURPLE)};
    margin-top: 10%;
    cursor: ${props => (props.disable ?  '' : 'pointer')};
  }

  &:active svg {
    color: ${GRAY_700};
  }

  padding: 0;
  height: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;

  label {
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  div {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const Title = styled.label`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const SearchAddContainer = styled.div`
  @media (max-width: ${XXL}) {
    margin-bottom: 1.25rem;
  }
`;

export const ButtonsContainer = styled.div`
  .calendarView {
    margin-top: 0.75%;
    margin-bottom: 1.5%;
    .createEmployeeRunView {
      color: ${props => (props.visitView === 'create_employee_run' ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.visitView === 'create_employee_run' ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.visitView === 'create_employee_run' ? 'bold' : 'normal'};
    }

    .createRunView {
      color: ${props => (props.visitView === 'createrun' ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.visitView === 'createrun' ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.visitView === 'createrun' ? 'bold' : 'normal'};
    }

    .clientView {
      color: ${props => (props.visitView === 'client' ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.visitView === 'client' ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.visitView === 'client' ? 'bold' : 'normal'};
    }

    .carerView {
      color: ${props => (props.visitView === 'team' ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.visitView === 'team' ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.visitView === 'team' ? 'bold' : 'normal'};
    }
    .runView {
      color: ${props => (props.visitView === 'run' ? PURPLE : 'black')};
      border: 0;
      border-bottom: ${props =>
        props.visitView === 'run' ? `4px solid ${PURPLE}` : 0};
      background: transparent !important;
      font-weight: ${props =>
        props.visitView === 'run' ? 'bold' : 'normal'};
    }
  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchContainer = styled.div`
  margin-right: 1rem;
  width: ${props => (props.show ? '12.5rem' : '0')};
  min-width: ${props => (props.show ? '12.5rem' : '0')};
  transition-duration: ${props => (props.show ? '0.25s' : '0')};
`;

export const SearchIcon = styled.div`
  display: inline-block;
  margin-right: 1.25rem;
  cursor: pointer;
  svg {
    width: 1.6875rem;
    height: 1.6875rem;
    opacity: 0.49;
  }
`;

export const SortIcon = styled.div`
  display: inline-block;
  svg {
    font-size: 1rem;
    margin-bottom: 0;
    color: ${PURPLE};
  }
`;
