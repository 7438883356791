import styled from 'styled-components';
import {PURPLE, BLACK_900, WHITE} from 'shared/styles/constants/colors';

export const StyledRadio = styled.div`
  padding-left: 0;

  .form-check-label {
    font-size: 0.875rem;
    line-height: 2;
    padding-left: 0.4rem;
  }
  .form-check-label::after,
  .form-check-label::before {
    height: 20px;
    width: 20px;
  }
  .form-check-inline .form-check-input{
    height: 20px;
    width: 20px;
    accent-color: ${PURPLE};
  }
`;
