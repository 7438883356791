import React, {Fragment} from 'react';
import Modal from 'react-bootstrap4-modal';
import {PrimaryButtonModal, CancelButton, IconStyle, Title} from './styles';
import {CHECK_CIRCLE} from 'utils/constants/icons';
import PropTypes from 'prop-types';

//add hideOnCancel false for show cancel button
function ModalInformation({
  type,
  title,
  icon,
  children,
  showModal,
  onOk,
  okTitle,
  onCancel,
  onCancelTitle,
  hideOnCancel,
}) {
  return (
    <Modal visible={showModal}>
      <div className="modal-header border-0">
        <Title>{title}</Title>
      </div>
      <div className="modal-body">
        <div>
          <IconStyle type={type}>{icon}</IconStyle>
          {children}
        </div>
      </div>
      <div className="d-flex justify-content-center modal-footer border-0">
        {hideOnCancel ? (
          <Fragment />
        ) : (
          <CancelButton
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}>
            {onCancelTitle || 'CANCEL'}
          </CancelButton>
        )}
        <PrimaryButtonModal typeButton={type} onClick={onOk}>
          {okTitle || 'Ok'}
        </PrimaryButtonModal>
      </div>
    </Modal>
  );
}

ModalInformation.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  children: PropTypes.element.isRequired,
  hideOnCancel: PropTypes.bool,
};

ModalInformation.defaultProps = {
  icon: CHECK_CIRCLE,
  hideOnCancel: false,
};

export default ModalInformation;
