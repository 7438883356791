module.exports = {
  XS: '574px',
  SM: '575px',
  MD: '767px',
  MD_VAL: 767,
  MD_LG: '900px',
  LG: '991px',
  XL: '1119px',
  XL1: ' 1196px',
  XXL: '1229px',
  XXLL: '1263px',
  XXLLA: ' 1265px',
  XXLLB: '1268px',
  XXLL1: '1277px',
  XXLLL:'1281px',
  XXLLL1: '1288px',
  XXLLLL: '1318px',
};
