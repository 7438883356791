import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import Table from 'components/SharedComponents/Table';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { Col, Row } from 'components/SharedComponents/Table/styles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getPlannerListSch } from 'utils/api/SchedulerApi';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from 'context';
import { useContext } from 'react';
export const headerColumns = [
  {
    label: 'Action',
    name: 'type',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Created at',
    name: 'type',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Created by',
    name: 'type',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
];
const PlannerModal = ({
  fullBind,
  values,
  PlannerClick=()=>{}
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [plannerData, setPlannerData] = useState([]);
  const history = useHistory()
  const {contextChoicesMap} = useContext(AppContext);


  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Schedular Planner',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  useEffect(()=>{
   if(values.start_date && values.end_date){
    const start_date_val = moment(values.start_date).format('YYYY-MM-DD')
    const end_date_val = moment(values.end_date).format('YYYY-MM-DD')
    getPlannerListSch(start_date_val, end_date_val)
    .then((res)=>{
      setPlannerData(res.data || [])
    })
   }
  },[values.start_date, values.end_date])

  // const PlannerClick = id => {
  //   history.push({
  //     pathname: `/scheduler/planner/${id}`,
  //    // state: {}
  //   })
  // };



  return (
    <ModalContainer max_width={'70%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div>
          {
            plannerData.length > 0 ?
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={true}
                noEditOption
                fontSize="0.75rem"
              //   callBackQuerySort={setQuerySort}
              >
                {plannerData.map((det, index) => {
                  let user_name = det.created_by && contextChoicesMap?.user_list[det.created_by]?.name
                  return (
                    <Row bgColor Shadow style={{justifyContent:'space-between'}}>

                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {det.type}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {det.created_at && moment(det.created_at).format("YYYY-MM-DD HH:mm")}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {user_name}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                      >
                        <PrimaryButtonForm
                          minWidth="6rem"
                         onClick={()=>PlannerClick(det.id)}
                        >
                          <span className={'pl-4 pr-4'}>Compare</span>
                        </PrimaryButtonForm>
                      </Col>

                    </Row>
                  )
                })}

              </Table>
            </>
            :
            <div style={{height:'20rem', margin:'auto',textAlign:'center'}}>
              <p style={{paddingTop:'9rem',color:'gray'}}>No data available</p>
            </div>
          }
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default PlannerModal;
