import DateSelector from 'components/SharedComponents/DateSelector'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import InputLabelTop from 'components/SharedComponents/InputLabelTop'
import InputTextArea from 'components/SharedComponents/InputTextArea'
import TimeSelector from 'components/SharedComponents/TimeSelector'
import ToggleButton from 'components/SharedComponents/ToggleButton'
import React from 'react'
import { FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles'
import EditableDropdown from 'components/SharedComponents/EditableDropdown';

function VisitCancelation({
  fullBind,
  errorMessage,
  loadingSave,
  useInput,
  nameSection,
  values,
  setValues,
  dropDownHandleCancelVisit,
  choices,
  listView,
  textAreaValue,
  setTextAreaValue
}) {
  const handleTextArea = (e) => {
    setTextAreaValue(e.target.value)
  }
  const handleChangeClient = (e, val) => {
    values[nameSection].client_to_be_billed = (e === 'selected') ? val : e.target.value;
    setValues({ ...values })
  }
  const handleChangeStaff = (e, val) => {
    values[nameSection].staff_to_be_paid = (e === 'selected') ? val : e.target.value;
    setValues({ ...values })
  }
  const employee_name = useInput(`${[nameSection]}.employee_full_name`)
  return (
    <div>
      <TitleSection>
        Cancellation Details
      </TitleSection>
      <div className="mt-3 mb-2">
        <div className="d-flex">
          <div className="pr-1 w-100 pb-2 mb-2">
            <DropdownSearchable
              required
              placeHolder={'Reason For Cancellation'}
              width="100%"
              options={choices.cancellation_reason}
              {...useInput(
                `${[nameSection]}.cancellation_reason`,
                'isRequired'
              )}
              selectedOption={
                values[nameSection] ? values[nameSection].cancellation_reason : null
              }
              onChange={value =>
                dropDownHandleCancelVisit(
                  { cancellation_reason: value ? value : null },
                  'cancellation_reason'
                )
              }
            />
          </div>
          <div className="pr-1 w-100 pb-2 mb-2">
            <DropdownSearchable
              required
              placeHolder={'Notification Method Of Cancellation'}
              width="100%"
              // marginLeft="50%"
              {...useInput(
                `${[nameSection]}.notification_cancellation`,
                'isRequired'
              )}
              options={choices.notification}
              selectedOption={
                values[nameSection] ? values[nameSection].notification_cancellation : null
              } time_of_cancel
              onChange={value =>
                dropDownHandleCancelVisit(
                  { notification_cancellation: value ? value : null },
                  'notification_cancellation'
                )
              }
            />
          </div>
        </div>
        <InputTextArea
          type="text"
          height={'4.5rem'}
          placeholder={'Cancellation Notes'}
          // {...useInput(`alert_comment[${index}].comment`)}
          onChange={handleTextArea}
          value={textAreaValue}
        />
        <div className="d-flex">
          <FlexGrid3Item className="pr-0 pr-md-1 mt-2 pb-2 pb-md-0">
            <DateSelector
              isRequired
              labelTop={'Date of cancellation'}
              // value={moment().format('YYYY-MM-DD')}
              {...useInput(`${[nameSection]}.cancel_date`, 'isRequired')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 mt-2 pb-2 pb-md-0">
            <TimeSelector
              isRequired
              labelTop={'Time of cancellation'}
              timeFormat={"HH:mm"}
              dateFormat={"HH:mm"}
              {...useInput(`${[nameSection]}.time_of_cancel`, 'isRequired')}
            />
          </FlexGrid3Item>
          {!listView &&
            <FlexGrid3Item className="pr-0 pr-md-1 mt-2 pb-2 pb-md-0">
              <InputLabelTop
                readOnly={true}
                type="text"
                id="employee_name"
                label={'Employee Name'}
                value={values[nameSection].employee_full_name}
                {...employee_name}
                maxlength={100}
              />
            </FlexGrid3Item>}
        </div>
        <div className="d-flex">
          <FlexGrid3Item className="d-flex justify-content-center pr-3 pt-0">
            <label style={{ fontSize: "0.875rem", marginRight: '7px', alignSelf: 'center' }}>Is fixed cancel amount</label>
            <ToggleButton
              label=" "
              disabled={false}
              useInput={useInput(`${[nameSection]}.is_fixed_cancel_amount`)}
              id="test"
            // onClick={handelOverNight}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 mt-2 pb-2 pb-md-0">
            <EditableDropdown
              placeHolder={!values[nameSection].is_fixed_cancel_amount ? 'Client to be Billed ( % )' : 'Client to be Billed'}
              options={!values[nameSection].is_fixed_cancel_amount ? [
                { id: '100', name: '100%' },
                { id: '75', name: '75%' },
                { id: '50', name: '50%' },
                { id: '25', name: '25%' },
                { id: '0', name: '0%' }
              ] :
                [
                  { id: '100', name: '100' },
                  { id: '75', name: '75' },
                  { id: '50', name: '50' },
                  { id: '25', name: '25' },
                  { id: '0', name: '0' }
                ]
              }
              data={values[nameSection].client_to_be_billed}
              onChnageData={handleChangeClient}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pr-0 pr-md-1 mt-2 pb-2 pb-md-0">
            <EditableDropdown
              placeHolder={!values[nameSection].is_fixed_cancel_amount ? 'Staff to be Paid ( % )' : 'Staff to be Paid'}
              options={!values[nameSection].is_fixed_cancel_amount ? [
                { id: '100', name: '100%' },
                { id: '75', name: '75%' },
                { id: '50', name: '50%' },
                { id: '25', name: '25%' },
                { id: '0', name: '0%' }
              ] :
                [
                  { id: '100', name: '100' },
                  { id: '75', name: '75' },
                  { id: '50', name: '50' },
                  { id: '25', name: '25' },
                  { id: '0', name: '0' }
                ]}
              data={values[nameSection].staff_to_be_paid}
              onChnageData={handleChangeStaff}
            />
          </FlexGrid3Item>
        </div>
      </div>
      {/* <div
      className={
        'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
      }>
      <div className={'mb-3'}>
        {errorMessage ? (
          <ErrorMessage>
            <label className={'mt-2'}>{errorMessage}</label>
          </ErrorMessage>
        ) : (
          <Fragment />
        )}
      </div> */}

      {/* <div
        className={
          'd-flex justify-content-center justify-content-md-end'
        }>

        <div className={'ml-0 ml-md-3'}>
          <PrimaryButtonForm minWidth="6rem" onClick={() => saveCancelVisit(textAreaValue)}>
            <span className={'mr-2'}>{textStatus}</span>
            {!loadingSave ? (
              saveButtonIcon
            ) : (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
              />
            )}
          </PrimaryButtonForm>
        </div>
      </div>
    </div> */}
    </div>
  )
}

export default VisitCancelation