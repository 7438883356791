import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles'
import Template from 'components/SharedComponents/SettingsTemplate'
import { AppContext } from 'context'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { PURPLE } from 'shared/styles/constants/colors'
import { TitleSection } from 'shared/styles/constants/tagsStyles'
import { deleteCalendarType, getCalenderType, setCalendarType } from 'utils/api/SettingsApi'

function CalendarType({
    readPermission,
    createPermission,
    updatePermission,
    deletePermission}) {

    const { handleChangeChoices, contextIsStaff } = useContext(AppContext)

    const userStaff = contextIsStaff;

    const [showCalendar, setShowCalendar] = useState(false)

    const handleShowCalendar = () => {
        setShowCalendar(!showCalendar);
    };
    return (
        <div>
                <AccordionContainer>
                    <AccordionArrow onClick={handleShowCalendar}>
                        <div>
                            {showCalendar ? (
                                <IoIosArrowUp size={18} color={PURPLE} />
                            ) : (
                                <IoIosArrowDown size={18} color={PURPLE} />
                            )}
                        </div>
                    </AccordionArrow>
                    <TitleSection>Calender Type</TitleSection>
                </AccordionContainer>
                {!showCalendar && (
                    <Template
                        titleSection={'Calendar Type'}
                        labelInputTitle={'Name'}
                        getMethod={getCalenderType}
                        deleteMethod={deleteCalendarType}
                        setMethod={setCalendarType}
                        handleChangeChoices={() => handleChangeChoices('calendar_type')}
                        textToMessageDelete={'Calendar Type'}
                        fieldAttributes={{ maxLength: 50 }}
                        update={updatePermission}
                        create={createPermission}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                        isEditable={true}
                        coreCalender={true}
                        addInput={[
                            {
                                label: 'Start Date',
                                value: 'start_date',
                                type: "date",
                                format:"MM/dd",
                                required: true,
                                //   maxWidth: 80
                            },
                            {
                                label: 'End Date',
                                value: 'end_date',
                                type: "date",
                                format:"MM/dd",
                                required: true,
                                //   maxWidth: 80
                            }
                        ]}
                    />
                )}
        </div>
    )
}

export default CalendarType