import React from 'react'

function SettingTable({
    groupedData,
    handleAllCheck,
    selectEmailAll,
    selectDesktopAll,
    selectInAppAll,
    handleEmailChange
}) {
    const bgPurple = { accentColor: "#8300f800" }
    const fs13 = {
        fontSize: "14px",
        fontWeight: 400,
        paddingTop: "15px"
    }
    return (
        <div className='w-75 parent'>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col d-flex align-items-center" style={{ width: "8.125rem" }}>
                            <input type='checkbox' style={bgPurple} onChange={() => handleAllCheck('email')} checked={selectEmailAll} />
                            <span className='fs-13-b'> Email</span>
                        </th>
                        <th scope="col" style={{ width: "8.125rem" }}>
                            <input type='checkbox' style={bgPurple} onChange={() => handleAllCheck('desktop')} checked={selectDesktopAll} />
                            <span className='fs-13-b'> Desktop</span>
                        </th>
                        <th scope="col" style={{ width: "8.125rem" }}>
                            <input type='checkbox' style={bgPurple} onChange={() => handleAllCheck('inapp')} checked={selectInAppAll} />
                            <span className='fs-13-b'> In-App</span>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        groupedData.map((item) => {
                            return (
                                <>
                                    <p className='setting-list-label'>{item.category}</p>
                                    {item.items.map((el) => {
                                        return (
                                            <tr>
                                                <th scope="row fs-13" style={fs13}>{el.name}</th>
                                                <td style={{ width: "8.125rem" }}><input type='checkbox' style={bgPurple} checked={el.isEmail} onChange={() => {
                                                    handleEmailChange(el.item, 'email')
                                                }} /></td>
                                                <td style={{ width: "8.125rem" }}><input type='checkbox' style={bgPurple} checked={el.isDesktop}
                                                    onChange={() => {
                                                        handleEmailChange(el.item, 'desktop')
                                                    }} /></td>
                                                <td style={{ width: "8.125rem" }}><input type='checkbox' style={bgPurple} checked={el.isInApp}
                                                    onChange={() => {
                                                        handleEmailChange(el.item, 'inapp')
                                                    }} /></td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </>
                            )
                        })

                    }

                </tbody>
            </table>
        </div>
    )
}

export default SettingTable