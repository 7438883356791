import React, { Fragment, useState, useEffect } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { TiArrowBackOutline } from 'react-icons/ti';
import { useForm } from 'hooks/FormHook';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumnsList';
import { RiDeleteBinLine } from 'react-icons/ri';
import _, { update } from 'lodash'
import moment from 'moment';
import { EX_LARGE_COL, LARGE_COL, MEDIUM_COL, SMALL_COL } from 'shared/styles/constants/columns';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { getPageToSearchParam } from 'shared/methods';
import {
  setEmployeeExpenses, getTeamExpenses,
  getTeamExpenseById, deleteExpense
} from 'utils/api/EmployeeApi'
import ModalExpense from './ModalExpense'
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from 'components/SharedComponents/Pagination';
import { useModal } from 'hooks/ModalHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { NoPermissionContainer } from '../AddModal/styles';
import { useContext } from 'react';
import { AppContext } from 'context';
import { emp_expenses_choices } from 'utils/choiceConstant';
import { EXPENSE_AUTHORIZATION, EXPENSE_DETAILS, SELF_WEB_CREATE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, WEB_CREATE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import './styles.css'
import { MdEdit } from 'react-icons/md';
import { Header } from 'shared/styles/constants/tagsStyles';

const Expenses = ({
  choices,
  selectedEmpId,
  empId,
  props,
  createPermissionEmployeeExpensess,
  updatePermissionEmployeeExpensess,
  readPermissionEmployeeExpensess,
  deletePermissionEmployeeExpensess,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  userEmployee,
  rightPermission
}) => {
  const toast = useRef()
  const [modalLoading, setModalLoading] = useState(true)
  const [modalValue, setModalValue] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [expenses, setExpenses] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [toggleFileProof, setToggleFileProof] = useState(false)
  const [uploadedFile, setUploadedFile] = useState('No File Chosen')
  const [simplePagination] = useState(true);
  const [deleteId, setDeleteId] = useState('')
  const [pages, setPages] = useState(0);
  const [isDecimlIncluded, setIsDecimlIncluded] = useState(false);

  // Expenss details & Expense authorization 
  const [readPermissionEmployeeExpenseDetails, setReadPermissionEmployeeExpenseDetails] = useState(false)
  const [createPermissionEmployeeExpenseDetails, setCreatePermissionEmployeeExpenseDetails] = useState(false)
  const [deletePermissionEmployeeExpenseDetails, setDeletePermissionEmployeeExpenseDetails] = useState(false)
  const [updatePermissionEmployeeExpenseDetails, setUpdatePermissionEmployeeExpenseDetails] = useState(false)

  const [readPermissionEmployeeExpenseAuthorization, setReadPermissionEmployeeExpenseAuthorization] = useState(false)
  const [createPermissionEmployeeExpenseAuthorization, setCreatePermissionEmployeeExpenseAuthorization] = useState(false)
  const [deletePermissionEmployeeExpenseAuthorization, setDeletePermissionEmployeeExpenseAuthorization] = useState(false)
  const [updatePermissionEmployeeExpenseAuthorization, setUpdatePermissionEmployeeExpenseAuthorization] = useState(false)



  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({ ordering: '-pay_adjustment_date' });
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const NEW_EXPENSE = 'new';
  const history = useHistory()
  const location = useLocation()


  const { handleGetChoices, contextChoices } = useContext(AppContext)

  useEffect(() => {
    if (filterSuperAdmin) {
      setReadPermissionEmployeeExpenseDetails(true)
      setCreatePermissionEmployeeExpenseDetails(true)
      setDeletePermissionEmployeeExpenseDetails(true)
      setUpdatePermissionEmployeeExpenseDetails(true)

      setReadPermissionEmployeeExpenseAuthorization(true)
      setCreatePermissionEmployeeExpenseAuthorization(true)
      setDeletePermissionEmployeeExpenseAuthorization(true)
      setUpdatePermissionEmployeeExpenseAuthorization(true)
    } else if (filterAdmin) {

      setReadPermissionEmployeeExpenseDetails(contextSubscription?.[EXPENSE_DETAILS]?.[WEB_PERMISSION])
      setCreatePermissionEmployeeExpenseDetails(true)
      setDeletePermissionEmployeeExpenseDetails(true)
      setUpdatePermissionEmployeeExpenseDetails(true)

      setReadPermissionEmployeeExpenseAuthorization(contextSubscription?.[EXPENSE_AUTHORIZATION]?.[WEB_PERMISSION])
      setCreatePermissionEmployeeExpenseAuthorization(true)
      setDeletePermissionEmployeeExpenseAuthorization(true)
      setUpdatePermissionEmployeeExpenseAuthorization(true)
    }
    else {
      if (rightPermission[EXPENSE_DETAILS] !== undefined) {
        setCreatePermissionEmployeeExpenseDetails(
          rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedEmpId))
        );
        setUpdatePermissionEmployeeExpenseDetails(
          rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedEmpId))
        );
        setReadPermissionEmployeeExpenseDetails(
          contextSubscription?.[EXPENSE_DETAILS]?.[WEB_PERMISSION] &&
          (rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EXPENSE_DETAILS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedEmpId)))
        );
      }

      if (rightPermission[EXPENSE_AUTHORIZATION] !== undefined) {

        setCreatePermissionEmployeeExpenseAuthorization(
          rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] &&
            (userEmployee == selectedEmpId))
        );
        setUpdatePermissionEmployeeExpenseAuthorization(
          rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] &&
            (userEmployee == selectedEmpId))
        );
        setReadPermissionEmployeeExpenseAuthorization(
          contextSubscription?.[EXPENSE_AUTHORIZATION]?.[WEB_PERMISSION] &&
          (rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[EXPENSE_AUTHORIZATION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] &&
              (userEmployee == selectedEmpId)))
        );
      }
    }

  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription])

  useEffect(() => {
    handleGetChoices(emp_expenses_choices)
  }, [])
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])



  useEffect(() => {
    if (selectedEmpId) {
      getTeamAllExpenses();
    }
    else {
      setModalLoading(false)
    }
  }, [values.ordering, values.limitperpage, props.location.search])

  useEffect(() => {
    if (idSelected) {
      searchTeamExpense();
      setShowError(false);
      setErrorMessage('');
    }
  }, [idSelected])

  const handleModal = event => {
    setModalValue(!modalValue);
    setIdSelected('');
    setToggleFileProof(false)
    setValues({})
    setShowError(false)
    setErrorMessage("")
    setCheckRequires(false);

    if (event === NEW_EXPENSE) {
      setButtonIcon(FLOPPY);
      setUploadedFile("No File Chosen")
      setTextStatus(SAVE);
    } else {
      getTeamAllExpenses();
    }
  };
  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const searchTeamExpense = async () => {
    let employeeId = empId ? empId : selectedEmpId
    const response = await getTeamExpenseById(employeeId, idSelected);

    if (response) {
      if (response && response.expense_document) {
        setUploadedFile(response.expense_document.split('/').reverse()[0])
      }
      let x = (response.amount);
      values.amount = parseFloat(x).toFixed((2));
      if (response?.client) {
        values.client = response?.client
      }
      if (response.expense_document) {
        values.expense_document = response.expense_document
      }
      setValues({
        ...values,
        ...response
      });

    }

  };


  const handleDeleteExpense = async () => {
    try {
      let employeeId = empId ? empId : selectedEmpId
      await deleteExpense(employeeId, deleteId);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      getTeamAllExpenses();
      setDeleteId('')
    }
  };

  const getTeamAllExpenses = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    //const pageToSearch = getPageToSearchParam(props.location.search);

    const response = await getTeamExpenses(selectedEmpId, { ...pageToSearch, ...values });

    setExpenses(response.results || []);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    // setPages(Math.ceil(response.count / values.limitperpage));
    setModalLoading(false)
  };
  const dropDownHandleSelect = item => {
    //recursive objects merge

    if (item.pay_adjustment_type) {
      let ids = item.pay_adjustment_type;
      let checkName = choices && choices.pay_adjustment_type.find(payname => payname.id == ids).name;
      if (checkName == "Deduction") {
        values.amount = values.amount > 0 ? -Math.abs(values.amount) : values.amount;
      } else if (checkName == "Addition") {
        values.amount = values.amount < 0 ? Math.abs(values.amount) : values.amount;
      }
    }

    let result;

    result = _.merge(values, item);

    setValues({ ...result });
  };
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const toDeleteContact = id => {
    setModalDeleteValue(true);
    setDeleteId(id);
  };
  const handleSubmit = (
    evt,
    saveTrigger = true,
  ) => {
    evt.preventDefault();

    if (loadingSave) {
      return;
    }


    if (values.amount) {
      values.amount = parseFloat((Math.abs(values.amount))).toFixed((2))
      // values.amount= Math.abs( values.amount );
      setValues({ ...values })
    }
    if (values.pay_adjustment_desc && values.pay_adjustment_desc.length > 500) {
      setErrorMessage('The pay adjustment description should be limited to 500 characters or fewer')
      setShowError(true);
      return;
    }

    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }



    let body = _.cloneDeep(values)
    // let body = {
    //   pay_adjustment_date: values.pay_adjustment_date,
    //   pay_adjustment_type: values.pay_adjustment_type,
    //   amount: values.amount
    // }
    if (saveTrigger) {
      setLoadingSave(true);
    }
    if (!toggleFileProof && body.expense_document) {
      delete body.expense_document
    }

    let employeeId = ''
    if (location.pathname.includes('/team/add')) {
      employeeId = empId
    }
    else {
      employeeId = selectedEmpId
    }
    if (values.expense_document) {
      body.expense_document = values.expense_document
    }
    if (values.client) {
      body.client = values?.client
    }
    if (values.visit) {
      body.visit = values.visit
    }
    if (values.pay_adjustment_desc) {
      body.pay_adjustment_desc = values.pay_adjustment_desc
    }
    if (createPermissionEmployeeExpenseAuthorization) {
      if (values.status) {
        body.status = values.status
      }
      if (values.authorization_description) {
        body.authorization_description = values.authorization_description
      }
    }

    body.employee = employeeId
    let value = ''
    console.log(body, "body")
    setEmployeeExpenses(body, employeeId)
      .then(response => {
        if (response.statusCode === 400) {
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        if (response.status === "False") {
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${response?.message?.expense_document[0]}`,
          });

        } else {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${'Successful'}`,
          });
          setShowError(false);
          onSuccess(response);
          getTeamAllExpenses()
          setModalValue(false)
          setIdSelected('');
        }

      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    let expenseId = response.id;
    // if employee id
    setValues({
      ...values,
      id: expenseId,
    });
    setLoadingSave(false);
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  }

  const onError = err => {
    // const onError = () => {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
      // "Some of the fields have invalid or empty values. Please review."
    );
  };
  const getName = (id, key) => {
    let obj = choices && choices[key] && choices[key].find(ele => ele.id == id)
    if (obj) {
      return obj.name
    }
  }

  const checkExpAmount = (amount, id, key) => {
    if (id) {
      let checkName = choices && choices[key] && choices[key].find(ele => ele.id == id).name;
      if (checkName == "Deduction") {
        // let amountvalue=  -1*(amount )  ;
        let negativeamount = -1 * amount;
        let amountvalue = parseFloat(Math.round(negativeamount * 100) / 100).toFixed(2);
        return amountvalue;
      } else if (checkName == "Addition") {
        let amountvalues = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
        return amountvalues;
      }
    } else { return null }
  }

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };


  return (
    <>

      <ComponentDynamic
        loading={modalLoading}
      >
        <Toast ref={toast} />
        {(selectedEmpId ? readPermissionEmployeeExpensess : createPermissionEmployeeExpensess) ?
          <>

            <div>
              {!modalValue ? (
                <>
                  <div className={'w-100 d-flex justify-content-center mb-3 mt-2'}>
                    <div className={'text-primary font-weight-bold'}>
                      <div>
                        {createPermissionEmployeeExpensess &&
                          <PrimaryButtonForm
                            minWidth="6rem"
                            onClick={() => handleModal(NEW_EXPENSE)}
                          >
                            <BsFolderPlus size={18} />
                            <span className={'ml-2'}>NEW EXPENSE</span>
                          </PrimaryButtonForm>}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Fragment />
              )}
              {modalValue ? (
                <div className={'w-100 d-flex justify-content-center mt-4 pt-3'}>
                  <div>
                    <PrimaryButtonForm
                      bgcolor={'PURPLE_GRAY_500'}
                      minWidth="6rem"
                      onClick={handleModal}
                    >
                      <div className={'d-flex align-items-center'}>
                        <TiArrowBackOutline size={24} />
                        <div>BACK</div>
                      </div>
                    </PrimaryButtonForm>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
              {!modalValue && readPermissionEmployeeExpensess ? (
                <>
                  {
                    expenses.length > 0 ? (
                      <>
                        <Table
                          headerColumns={headerColumns}
                          callBackQuerySort={setQuerySort}
                          headerPadding={false}
                        >
                          <div className='employee-expense-table'>
                            {expenses.length && expenses.map((exp, index) => {
                              // const daysSelected = getDaysSelected(importantContact.visits);
                              return (
                                <div key={index}>
                                  <Row bgColor Shadow >
                                    <ColsGrouper className="d-flex" >
                                      <Col
                                        max_width={LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {exp.pay_adjustment_date}
                                      </Col>

                                      <Col
                                        max_width={LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {getName(exp.pay_adjustment_type, 'pay_adjustment_type')}
                                      </Col>

                                      <Col
                                        max_width={LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {getName(exp.pay_adjustment, 'pay_adjustment')}
                                      </Col>
                                      <Col
                                        max_width={EX_LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {exp.pay_adjustment_desc && exp.pay_adjustment_desc.length > 40 ? exp.pay_adjustment_desc.slice(0, 40) + "..." : ""}
                                      </Col>

                                      <Col
                                        max_width={LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {checkExpAmount(exp.amount, exp.pay_adjustment_type, 'pay_adjustment_type')}
                                      </Col>
                                      <Col
                                        max_width={LARGE_COL}
                                        className="sm-hidden"
                                        Capitalize
                                        Center
                                        overlap={true}
                                      >
                                        {exp?.status}
                                      </Col>
                                      {/* {updatePermissionEmployeeExpensess && ( */}
                                      <Col className="sm-hidden" Center Icon Purple>
                                        <IconWrapper
                                          onClick={() => {
                                            handleRowClick(exp.id)
                                          }}>
                                          <MdEdit />
                                        </IconWrapper></Col>
                                      {/* )} */}
                                    </ColsGrouper>


                                    <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                      {deletePermissionEmployeeExpensess && (
                                        <IconWrapper
                                          onClick={() => {
                                            toDeleteContact(exp.id);
                                          }}>
                                          <RiDeleteBinLine />
                                        </IconWrapper>)}</Col>

                                  </Row>
                                  <div className="mb-1" />
                                </div>
                              );
                            })}

                          </div>

                        </Table>
                        <div className="mt-3">
                          <Pagination
                            // simplePagination={simplePagination}
                            setCurrentPage={setCurrentPage}
                            totalPages={pages}
                            currentPage={currentPage}
                            dataLength={expenses?.length}
                            dropdownEntriesLimit={false}
                            values={values}
                            dropDownHandleSelect={dropDownHandleSelectPagination}
                            number={PAGE_SIZE_RENDER}
                          />
                        </div>
                      </>
                    ):(
                      <div className='d-flex justify-content-center align-items-center font-weight-bold'
                        style={{
                          height: '30vh',
                        }}
                      >
                        No data found
                      </div>
                    )
                  }
                </>
              ) : (
                <>
                  <Fragment />
                  {!modalValue &&
                    <div
                      className={'d-flex justify-content-center justify-content-md-end'}>
                      <div>
                        <PrimaryButtonForm
                          bgcolor="PURPLE_GRAY_500"
                          minWidth="6rem"
                          className={'mr-2'}
                          onClick={() => {
                            history.push('/team')
                          }}>
                          <span className={'mr-2'}>{'Cancel'}</span>
                        </PrimaryButtonForm>
                      </div>
                    </div>
                  }
                </>
              )}

              {modalValue ? (
                <ModalExpense
                  useInput={useInput}
                  values={values}
                  setModalValue={setModalValue}
                  choices={contextChoices}
                  dropDownHandleSelect={dropDownHandleSelect}
                  setValues={setValues}
                  showError={showError}
                  errorMessage={errorMessage}
                  handleSubmit={handleSubmit}
                  textStatus={textStatus}
                  loadingSave={loadingSave}
                  buttonIcon={buttonIcon}
                  idSelected={idSelected}
                  toggleFileProof={toggleFileProof}
                  setToggleFileProof={setToggleFileProof}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  setIsDecimlIncluded={setIsDecimlIncluded}
                  selectedEmpId={selectedEmpId}
                  createPermissionEmployeeExpensess={createPermissionEmployeeExpensess}
                  updatePermissionEmployeeExpensess={updatePermissionEmployeeExpensess}

                  readPermissionEmployeeExpenseDetails={readPermissionEmployeeExpenseDetails}
                  createPermissionEmployeeExpenseDetails={createPermissionEmployeeExpenseDetails}
                  deletePermissionEmployeeExpenseDetails={deletePermissionEmployeeExpenseDetails}
                  updatePermissionEmployeeExpenseDetails={updatePermissionEmployeeExpenseDetails}

                  readPermissionEmployeeExpenseAuthorization={readPermissionEmployeeExpenseAuthorization}
                  createPermissionEmployeeExpenseAuthorization={createPermissionEmployeeExpenseAuthorization}
                  deletePermissionEmployeeExpenseAuthorization={deletePermissionEmployeeExpenseAuthorization}
                  updatePermissionEmployeeExpenseAuthorization={updatePermissionEmployeeExpenseAuthorization}
                />
              ) : null}
            </div>
            <div>
              <ModalDecision
                type="delete"
                title="Warning"
                body={
                  'Are you sure you want to delete this expense? \n DELETING the expense will remove ALL records from database.'
                }
                subBody={
                  ''
                }
                onOk={handleDeleteExpense}
                onCancel={() => {
                  setDeleteId('');
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
              />
            </div>
          </>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedEmpId ? 'update' : 'create'} the information.
          </NoPermissionContainer>
        }
      </ComponentDynamic>

    </>
  );
}

export default Expenses;
