import { Title } from 'components/DashboardComponents/HomeComponent/style';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ACCOUNT_ROUTE, CHANGE_PASSWORD_ROUTE, DELETE_ACCOUNT_ROUTE } from 'utils/constants/routes';
import ChangePassword from './ChangePassword';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useModal } from 'hooks/ModalHook';
const tab1 = 1;
const tab2 = 2;
let title = ""
function AddAccountModal() {

  const {
    setValue: setChangePasswordModalValue,
    value: changePasswordModalValue,
    setTitle: setChangePasswordTitle,
    fullBind: fullBindChangePassword
  } = useModal(false);

  const [tabSelected, setTabSelected] = useState(tab1)
  const history = useHistory()
  const toast = useRef()

  useEffect(() => {
    if (history.location.pathname === ACCOUNT_ROUTE + CHANGE_PASSWORD_ROUTE) {
      setTabSelected(tab1)
      // setChangePasswordModalValue(true)
      setChangePasswordTitle('Change Password')
      title = 'Change Password'
    } else if (history.location.pathname === ACCOUNT_ROUTE + DELETE_ACCOUNT_ROUTE) {
      setTabSelected(tab2)
      title = 'Delete Account'
    }
  }, [history.location.pathname])

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <Title>{title}</Title>
      <div>
        {
          tabSelected == tab1 &&
          <ChangePassword
            toast={toast}
            fullBind={fullBindChangePassword}
            setModalValue={setChangePasswordModalValue}
            modalValue={changePasswordModalValue}
          />
        }
      </div>
    </div>
  )
}

export default AddAccountModal