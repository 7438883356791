import TimeSelector from 'components/SharedComponents/TimeSelector';
import React, { Fragment, useEffect } from 'react';
import DurationInput from 'components/SharedComponents/DurationInput';
import moment from 'moment';

const TimeNewEmployee = ({
  indexEmployee,
  setValues,
  useInput,
  index,
  nameSection,
  values,
  editMode,
}) => {

//   const start_time =
//   values[nameSection].new_requirement[indexEmployee].start_time;
//  const end_time =
//   values[nameSection].new_requirement[indexEmployee].end_time;

//   useEffect(() => {

//     if (
//       values[nameSection].new_requirement[indexEmployee].start_time &&  values[nameSection].new_requirement[indexEmployee].end_time
//     ) {
//       let start_time = moment(
//         values[nameSection].new_requirement[indexEmployee].start_time,
//         'HH:mm:ss'
//       );
//       let end_time = moment(
//          values[nameSection].new_requirement[indexEmployee].end_time,
//         'HH:mm:ss'
//       );
//       if(end_time>start_time){
         
//         values[nameSection].new_requirement.duration = end_time.diff(
//           start_time,
//           'seconds'
//         );
//       }else{
//         let add_date=end_time.add(1,'days')
//         values[nameSection].new_requirement.duration = add_date.diff(
//           start_time,
//           'seconds'
//         );
//       }
//     } else {
//       values[nameSection].new_requirement.duration = 0;
//     }
//     setValues({...values});

//   }, [start_time, end_time]);

const start_time =
values[nameSection].new_requirement[indexEmployee].start_time;
const end_time =
values[nameSection].new_requirement[indexEmployee].end_time;


useEffect(()=>{
  // debugger
  if (
  values[nameSection].new_requirement[indexEmployee].start_time &&
  values[nameSection].new_requirement[indexEmployee].end_time
) {
  // debugger
  const start_time =
  values[nameSection].new_requirement[indexEmployee].start_time;
  const end_time =
  values[nameSection].new_requirement[indexEmployee].end_time;
  
  if(end_time && start_time ){
    // debugger
 values[nameSection].new_requirement[indexEmployee].duration = moment.utc(moment(end_time,"HH:mm:ss").diff(moment(start_time," HH:mm:ss"))).format("HH:mm:ss")
  }else {
    // debugger
    values[nameSection].new_requirement[indexEmployee].duration =0;
  }
}
 setValues({ ...values });

},[ start_time, end_time  ])


// useEffect(() => {

// if (
//   values[nameSection].new_requirement[indexEmployee].start_time &&
//   values[nameSection].new_requirement[indexEmployee].end_time
// ) {
//   let start_time = moment(
//     values[nameSection].new_requirement[indexEmployee].start_time,
//     'h:mma'
//   );
//   let end_time = moment(
//     values[nameSection].new_requirement[indexEmployee].end_time,
//     'h:mma'
//   );
//   if (end_time > start_time) {

//     // values[nameSection].new_requirement[indexEmployee].duration = end_time.diff(
//     //   start_time,
//     //   'seconds'
//     // )
   
//     values[nameSection].new_requirement[indexEmployee].duration = moment.utc(end_time.diff(
//       start_time,
//       'seconds'
//     )*1000).format('HH:mm:ss');

//   } else {
//     let add_date = end_time.add(1, 'days')
  
//     // values[nameSection].new_requirement[indexEmployee].duration = add_date.diff(
//     //   start_time,
//     //   'seconds'
//     // )

//     values[nameSection].new_requirement[indexEmployee].duration = moment.utc(add_date.diff(
//       start_time,
//       'seconds'
//     )*1000).format('HH:mm:ss');

//   }

// } else {
//   values[nameSection].new_requirement[indexEmployee].duration = 0;
// }

// setValues({ ...values });

// }, [start_time, end_time]);




  return (
    <>
      <div className='ml-2'>
      <TimeSelector
       disabled={!editMode}
        labelTop={'Start time'}
        {...useInput(
          `${[nameSection]}.new_requirement[${indexEmployee}].start_time`
        )}
                //added below code  for time in 24hrs format 
                timeFormat={"HH:mm"}
                dateFormat={"HH:mm"}
      />
      </div>
      <div className='ml-2'>
      <TimeSelector
       disabled={!editMode}
        labelTop={'End time'}
        {...useInput(
          `${[nameSection]}.new_requirement[${indexEmployee}].end_time`
        )}
                //added below code  for time in 24hrs format 
                timeFormat={"HH:mm"}
                dateFormat={"HH:mm"}
      />
      </div>
      <div className='ml-2'>
      <DurationInput
        disabled={!editMode}
        label_top={'Duration'}
        {...useInput( `${[nameSection]}.new_requirement[${indexEmployee}].duration`   )}
        // value={values[nameSection].new_requirement[indexEmployee].duration}
      />
        {/* <DurationInput           
            // disabled={true}
            disabled={!editMode}
            label_top={'Duration'}
            {...useInput(
              `${[nameSection]}.new_requirement[${indexEmployee}].duration`)}
          /> */}
      </div>
    </>
  )
}

export default TimeNewEmployee;