import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Dropdown from 'components/SharedComponents/Dropdown';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
// imported  new files
import { getClientTypes } from 'utils/api/CoreApi';
import { setClientContract, getFunderDetails, getClientTask, getClientGroupTask, getPlanned, getMedicationDoseTimingOptionList, getClientsServicesVisitsByVisitId, setClientContractVisits, setClientContractFunder, shouldDeleteServiceContractApi } from 'utils/api/ClientApi';
import { getFundersList } from 'utils/api/ClientApi'
import { useHistory } from 'react-router';
import _ from "lodash"


import moment from 'moment';
import {
  InvalidOrEmptyValues,
  DetailsNotCreated,
  SAVE,
  SAVED,
  ERROR
} from 'utils/constants/messages';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import ModalServiceClient from './ModalServiceClient';
import {
  getClientsServicesById,
  getClientsServices,
  deleteServiceContract,
} from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';
import { BsFolderPlus, BsPlusCircleFill } from 'react-icons/bs';
import { headerColumns, headerColumnsVisits, headerColumnsVisitsEmployee } from './headerColumsList';
import { LARGE_COL, MEDIUM_COL, EX_LARGE_COL, EX_SMALL_COL } from 'shared/styles/constants/columns';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getPageToSearchParam } from 'shared/methods';
import { useModal } from 'hooks/ModalHook';
import { FLOPPY, CROSS, CHECK, COPY } from 'utils/constants/icons';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { AppContext } from "context"
import {

  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { getCategoryPermission, getPermissions, getSubSectionPermissions } from '../../../../utils/localStorage/user';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { client_service_contract_choices } from 'utils/choiceConstant';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { GRAY_200, GRAY_300, SOFT_RED } from 'shared/styles/constants/colors';
import '../AddModal/ClientModal.css'
import TemplatePopup from './TemplatePopup';
import { getClientsServicesTemplate, getClientsServicesTemplateById } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
const NEW_CONTRACT = 'new';

const ServiceClientList = ({
  selectedClientId,
  choices,
  allErrors,
  errorSplitPercentage,
  setErrorSplitPercentage,
  loadingFinish,
  textStatus,
  contractId,
  savedContract,
  setSavedContract,
  addressValues,
  clientFullName,
  setTextStatus,
  clientErrors,
  promisesWithErrors,
  //cliId,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userClient,
  readServicePermissions,
  createServicePermissions,
  updateServicePermissions,
  deleteServicePermissions,
  contextRelativesClient
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
    setFormErrors
  } = useForm({});
  const toast = useRef(null)
  const [idSelected, setIdSelected] = useState('');
  const [idSelectedFunder, setIdSelectedFunder] = useState('');

  const [isCopy, setIsCopy] = useState(false);
  const [idSelectedVisit, setIdSelectedVisit] = useState('');
  const [editTypeSelected, setEditTypeSelected] = useState('create');
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalValue, setModalValue] = useState(false);
  const [loadingSaveOnService, setLoadingSaveOnService] = useState(false);
  const [editToggle, setEditToggle] = useState(false)
  const [pages, setPages] = useState(0);
  const [serviceContracts, setServiceContracts] = useState([]);
  const [ServiceContractLength, setServiceContractLength] = useState();
  const [simplePagination] = useState(true);
  const [dateError, setDateError] = useState([]);
  const [timeError, setTimeError] = useState([])
  const [clientTaskOptions, setClientTaskOptions] = useState([])
  const [groupTaskOptions, setGroupTaskOptions] = useState([])
  const [clientTypes, setClientTypes] = useState([]);  //clienttype
  const mountedRef = useRef(true);
  const [funderList, setFunderList] = useState([])
  const [medDoseTimingList, setMedDoseTimingList] = useState([])
  const [medDoseTimingListMap, setMedDoseTimingListMap] = useState({})

  const [plannedTasks, setPlannedTasks] = useState([]);
  const [editMode, setEditMode] = useState(true)
  const [details, setDetails] = useState([])
  const [earlieMessage, setEarlieMessage] = useState('')
  const [earlieErrors, setEarlieError] = useState([])
  const [showEarlieError, setShowEarlieError] = useState(false)
  const [expandFields, setExpandFields] = useState({});
  const [visitToUpdateData, setVisitToUpdateData] = useState([]);
  const [visitToCreateData, setVisitToCreateData] = useState([]);
  const [visitToDeleteData, setVisitToDeleteData] = useState([]);
  const [loadingVisit, setLoadingVisit] = useState(false)
  const { setValue: setModalCopyWarning, bind: bindModalCopyWarning } = useModal(
    false,
  );
  console.log(medDoseTimingListMap, "medDoseTimingListMap")
  const {
    setValue: setModalValueConfirmSave,
    value: modalValueConfirmSave,
    fullBind: fullBindConfirmSave,
  } = useModal(false);

  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const {
    setValue: setModalFunderValue,
    value: modalFunderValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const {
    setValue: setModalTemplateValue,
    value: modalTemplateValue,
    setTitle: setTemplateTitle,
    fullBind: fullBindTemplate,
  } = useModal(false);
  const [loadingSave, setLoadingSave] = useState(false)
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [modalLoading, setModalLoading] = useState(true)

  const servAddressSection = 'service_address';
  const history = useHistory()
  const location = useLocation();
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(client_service_contract_choices)
  }, [])
  useEffect(() => {
    loadClientTypes();
    funderLists();
    medicationDoseTimeLists()
  }, [])




  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      response.results.unshift({ id: '', name: 'All ' });
      setClientTypes(response.results);
    });
  };

  const dropDownHandleSelectClient = item => {
    setValues({ ...values, ...item });
  };

  const funderLists = () => {
    getFundersList()
      .then(res => {
        if (res.length > 0) {
          let newFunders = res.map(funder => {
            return {
              id: funder.id,
              name: funder.funder_name
            }
          })
          setFunderList([...newFunders, { id: "add_funder", name: "+Add New Funder" }])
        }
      })
  }

  const medicationDoseTimeLists = () => {
    getMedicationDoseTimingOptionList(selectedClientId)
      .then(res => {
        if (res.message.length > 0) {
          let madMap = {}
          let newMed = res?.message?.map(med => {
            madMap[med.id] = {
              id: med.id,
              name: med.name
            }
            return {
              id: med.id,
              name: med.name
            }
          })
          setMedDoseTimingListMap(madMap)
          setMedDoseTimingList([...newMed])
        }
      })
  }




  useEffect(() => {
    if (selectedClientId) {
      getClientContracts();
      getPlannedTasks();
    }
    else {
      setModalLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, currentPage, savedContract]);

  useEffect(() => {
    if (!selectedClientId) {
      setModalValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  useEffect(() => {
    if (contractId) {
      values.service_clients[0].id = contractId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);


  useEffect(() => {
    if (idSelected || idSelectedVisit || medDoseTimingListMap) {
      searchClientService();
      setShowError(false);
      setErrorMessage('');
      setDateError([]);
      errors.splice(0, errors.length - 1);
    } else {
      values.service_clients = [
        {
          visits: [],
          service_funders: [],
          service_address: {},
        },
      ];
      setValues({ ...values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalValue,medDoseTimingListMap]);

  useEffect(() => {
    if (choices && choices?.care_task && choices?.group_task) {
      groupTaskFn();
    }
  }, [choices])

  useEffect(() => {
    const haveDateErrors = dateError.find(data => data === true);

    if (showError && errors.length === 0 && !allErrors && !haveDateErrors) {
      setShowError(false);
      setCheckRequires(false);
    }

    if (allErrors) {
      setShowError(true);
      setCheckRequires(true);
      if (clientErrors.length >= 1) {
        setErrorMessage(DetailsNotCreated);
      } else {
        setErrorMessage(InvalidOrEmptyValues);
      }
    }

    if (promisesWithErrors.length >= 1) {
      setShowError(true);
      setCheckRequires(true);

      let error = '';

      promisesWithErrors.forEach(promise => {
        error += `*${promise} `;
      });

      setErrorMessage(`Error while saving ${error}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, allErrors, dateError]);

  const checkBeforeSend = () => {
    setSavedContract('unsaved');

    if (editTypeSelected == 'contract_visit') {
      console.log("contract_visit")
      handleSubmitVisit()
    } else if (editTypeSelected == 'funder') {
      console.log("funder")
      handleSubmitFunder()
    } else {
      console.log("submit")
      handleSubmit();
    }
  };


  const handleSubmit = (
    saveTrigger = true
  ) => {
    debugger
    if (loadingSave) {
      return;
    }
    if ((!isValid && errors.length > 0) || errorSplitPercentage !== '') {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = _.cloneDeep(values)
    if (body.service_clients) {
      delete body.service_clients
    }
    if (body.service_funder) {
      delete body.service_funder
    }

    // if(body.service_funderss){
    //   delete body.service_funderss
    // }

    if (body[servAddressSection]) {
      if (body[servAddressSection].same_as_client === undefined) {
        body[servAddressSection].same_as_client = false;
      }

      if (body[servAddressSection].smoking === undefined) {
        body[servAddressSection].smoking = false;
      }
    }
    // if(body.service_funders.status){
    //   if(body.service_funders.status==1){
    //     body.service_funders.status=true
    //   }
    //   if(body.service_funders.status==2){
    //     body.service_funders.status=false
    //   }
    // }
    // if(body.service_funders.core_funder){
    //     if(body.service_funders.self_funded==2){
    //       body.service_funders.name=funderList.find(funder=>funder.id==body.service_funders.name).name 
    //     }
    // }
    // if(body.service_funders.self_funded){
    //   if(body.service_funders.self_funded==1){
    //     body.service_funders.self_funded=true
    //   }
    //   if(body.service_funders.self_funded==2){
    //     body.service_funders.self_funded=false
    //   }
    // }
    if (body.service_funders) {
      body.service_funders = body.service_funders.map((val, indexF) => {
        if (val.id) {
          delete body.service_funders[indexF].id
        }
        let name_temp = funderList.find(funder => funder.id == val.name) && funderList.find(funder => funder.id == val.name).name
        return { ...val, name: (val.self_funded === 1) ? val.name : name_temp, self_funded: (val.self_funded === 1) ? true : false, status: (val.status === 1) ? true : false }
      })
    }
    if (body.addFunder) {
      delete body.addFunder
    }


    // if (body['service_funders'].is_split === undefined) {
    //   body['service_funders'].is_split = false;
    // }
    // if (!body.id) {
    delete body?.service_address?.id
    if (body.bill_rate && body.bill_rate.length > 0) {
      body.bill_rate = body.bill_rate.map((rate_val) => {
        if (rate_val.id) {
          delete rate_val.id
        }
        delete rate_val.core_service
        delete rate_val.client_service
        return rate_val
      })
    }
    if (body.pay_rate && body.pay_rate.length > 0) {
      body.pay_rate = body.pay_rate.map((rate_val) => {
        if (rate_val.id) {
          delete rate_val.id
        }
        delete rate_val.core_service
        delete rate_val.client_service
        return rate_val
      })
    }
    if (body.travel_pay_rate && body.travel_pay_rate.length > 0) {
      body.travel_pay_rate = body.travel_pay_rate.map((rate_val) => {
        if (rate_val.id) {
          delete rate_val.id
        }
        delete rate_val.core_service
        delete rate_val.client_service
        return rate_val
      })
    }
    // }
    if (body.visits) {
      if (values.service_address.destination) {
        body.visits = body.visits.map(v => ({ ...v, service_destination: values.service_address.destination }))
      }
      body.visits && body.visits.forEach((visit, index) => {
        delete body.visits[index].bill_rate
        delete body.visits[index].travel_pay_rate
        if (visit.id === '') {
          delete body.visits[index].id
        }
        if (visit.id) {
          delete body.visits[index].id
        }
        if (visit.employee_required) {
          body.visits[index].requirement = _.cloneDeep(visit.employee_required)
          body.visits[index].requirement.forEach((req, reqIndex) => {
            delete body.visits[index].requirement[reqIndex].pay_rates
            delete body.visits[index].requirement[reqIndex].medication

            if (!req.no_of_employees) {
              body.visits[index].requirement[reqIndex].no_of_employees = 1
            }
            if (req.id === '') {
              delete req.id;
            }
            if (req.client_service_visit === '') {
              delete req.client_service_visit;
            }
            if (req.duration || req.duration === 0) {
              const formatted = moment.utc(req.duration * 1000).format('HH:mm:ss');
              body.visits[index].requirement[reqIndex].duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
            }
            body.visits[index].requirement[reqIndex].client_task = {}
            if (req.task_id && req.task_id.length > 0) {
              body.visits[index].requirement[reqIndex].client_task.task_id = req.task_id.map((val) => {
                return ({ value: val.value })
              })
            }
            if (req.skills && req.skills.length > 0) {
              body.visits[index].requirement[reqIndex].skills = req.skills.map((val) => {
                if (val.skill_id) {
                  return { label: val.label, value: val.value, id: val.skill_id }
                } else {
                  return { label: val.label, value: val.value }
                }
              })
            }
            if (req.competency && req.competency.length > 0) {
              body.visits[index].requirement[reqIndex].competency = req.competency.map((val) => {
                if (val.competency_id) {
                  return { label: val.label, value: val.value, id: val.competency_id }
                } else {
                  return { label: val.label, value: val.value }
                }
              })
            }
            if (req.specific_groups && req.specific_groups.length > 0) {
              // delete req.specific_groups
              body.visits[index].requirement[reqIndex].specific_groups = req.specific_groups.map((val) => {
                if (val.planned_id) {
                  return { specific_group_id: val.value, id: val.planned_id }
                } else {
                  return { specific_group_id: val.value }
                }
              })
            }
            
          })
          delete body.visits[index].employee_required
        }


        if (visit.medication && visit.medication.length) {
          console.log(body.visits[index].medication,"medication")
          body.visits[index].medication =
            body.visits[index].medication.map((item, medIndex) => ({
              ...(item.id && { id: item.id }),
              effective_from: item.effective_from || null,
              effective_to: item.effective_to || null,
              // doseTime: item.doseTime || null,
              // prescriptions:[item.prescriptions] || []
              doseTime: {
                ...(item.dose_id && { id: item.dose_id }),
                medication_dose_timing: item.doseTime || null,
                prescriptions: [item.prescriptions] || []
            }
            }))
        }
        if (body.visits[index].planned_task && body.visits[index].planned_task.length) {
          body.visits[index].planned_task = body.visits[index].planned_task.length ? 
            body.visits[index].planned_task.map(item => ({
              effective_from: item.effective_from || null,
              effective_to: item.effective_to || null,
              ...(item.id && { id: item.id }),
              tasks: item.tasks && item.tasks.length ? 
                item.tasks.map((task, index) => ({
                  support_level: task.support_level !== undefined ? task.support_level : "",
                  care_task: task.care_task_data !== undefined ? task.care_task_data : "",
                  care_category: task.care_category !== undefined ? task.care_category : "",
                  task_type: task.task_type !== undefined ? task.task_type : "",
                  message: task.message !== undefined ? task.message : "",
                  ordered: Number(index + 1),
                  is_essential: task.is_essential !== undefined ? task.is_essential : false,
                  ...(task.id && { id: task.id }),
                })) : []
            })) : [];
        }
        
        if (visit.time_critical === undefined) {
          body.visits[index].time_critical = false;
        }

        if (visit.include_holiday === undefined) {
          body.visits[index].include_holiday = false;
        }

        if (visit.is_suspended === undefined) {
          body.visits[index].is_suspended = false;
        }
        if (visit.is_overnight === undefined) {
          body.visits[index].is_overnight = false;
        }
        if (visit.full_day === undefined) {
          body.visits[index].full_day = false;
        }
        // BE expects repeat default value as 1
        if (!visit.repeat || visit.repeat <= 0) {
          body.visits[index].repeat = 1;
        }
        // if(values.service_address.destination){
        //   if(!body.visit[index].service_destination){
        //     body.visit[index].service_destination=''
        //   }
        //   body.visit[index].service_destination=values.service_address.destination
        // }
        // BE expects repeat default value as 1
        if (visit.end_value === undefined || visit.end_value === null) {
          body.visits[index].end_value = '';
        }

        // format duration from seconds to HH:mm:ss
        if (visit.duration || visit.duration === 0) {
          const formatted = moment.utc(visit.duration * 1000).format('HH:mm:ss');
          body.visits[index].duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
        }

        if (visit.full_day) {
          body.visits[index].preferred_start_time = "00:00:00"
          body.visits[index].preferred_end_time = "23:59:59"
          // delete body.visits[index].full_day
        }
        visit.skills_required && delete body.visits[index].skills_required
      })


    }

    if (saveTrigger) {
      setLoadingSave(true);
    }

    let value = [];
    // let clientId = ''
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId
    // } else {
    //   clientId = selectedClientId
    // }
    console.log(body, "body")
    setClientContract(body, selectedClientId)
      .then(response => {
        if (response.status === 400) {
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };

  const handleSubmitFunder = (
  ) => {
    debugger


    if (loadingSave) {
      return;
    }
    if ((!isValid && errors.length > 0) || errorSplitPercentage !== '') {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = _.cloneDeep(values)
    if (body.service_clients) {
      delete body.service_clients
    }
    if (body.service_funder) {
      delete body.service_funder
    }
    if (body[servAddressSection]) {
      if (body[servAddressSection].same_as_client === undefined) {
        body[servAddressSection].same_as_client = false;
      }

      if (body[servAddressSection].smoking === undefined) {
        body[servAddressSection].smoking = false;
      }
    }
    if (body.service_funders) {
      body.service_funders = body.service_funders.map((val) => {
        let name_temp = funderList.find(funder => funder.id == val.name) && funderList.find(funder => funder.id == val.name).name;
        if (val.id == "") {
          delete val.id
        }
        return { ...val, name: (val.self_funded === 1) ? val.name : name_temp, self_funded: (val.self_funded === 1) ? true : false, status: (val.status === 1) ? true : false }
      })
    }
    if (body.addFunder) {
      delete body.addFunder
    }
    // if (body['service_funders'].is_split === undefined) {
    //   body['service_funders'].is_split = false;
    // }
    if (body.visits) {
      delete body.visits
    }

    setLoadingSave(true);

    let value = [];
    // let clientId = ''
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId
    // } else {
    //   clientId = selectedClientId
    // }
    setClientContractFunder(body, selectedClientId)
      .then(response => {
        if (response.status === 400) {
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        setShowError(false);
        if (response.status) {
          handleModalOnSave()

          let contractId = response?.data?.id;
          // if employee id

          setValues({
            ...values,
            id: contractId,
          });

          // setPrefDet(clientId)
          setLoadingSave(false);
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
        }
      })
      .catch(onError);
  };

  const handleSubmitVisit = () => {
    // if (loadingSave) {
    //   return;
    // }
    debugger
    if ((!isValid && errors.length > 0) || errorSplitPercentage !== '') {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = _.cloneDeep(values.visits[0])
    if (body) {
      if (modalValueConfirmSave) {
        body.is_force = true
      }
      if (values.service_address.destination) {
        body.service_destination = values.service_address.destination;
      }

      // body.visits && body.visits.forEach((visit, index) => {
      if (body.id === '') {
        delete body.id
      }

      if (body.client_service) {
        body.client = selectedClientId;
      }
      if (body.employee_required) {
        body.requirement = _.cloneDeep(body.employee_required)
        body.requirement.forEach((req, reqIndex) => {
          delete body.requirement[reqIndex].pay_rates
          delete body.requirement[reqIndex].medication
          delete body.requirement[reqIndex].specific_groups
          body.requirement[reqIndex].skill_level = req?.skill_level ? req?.skill_level : null
          if (!req.no_of_employees) {
            body.requirement[reqIndex].no_of_employees = 1
          }
          if (((req.id === '') || !body.id)) {
            delete body.requirement[reqIndex].id
          }
          if (req.client_service_visit === '') {
            delete req.client_service_visit;
          }
          if (req.duration || req.duration === 0) {
            const formatted = moment.utc(req.duration * 1000).format('HH:mm:ss');
            body.requirement[reqIndex].duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
          }
          body.requirement[reqIndex].client_task = {}
          if (req.task_id && req.task_id.length > 0) {
            body.requirement[reqIndex].client_task.task_id = req.task_id.map((val) => {
              return ({ value: val.value })
            })
          }
          if (req.skills && req.skills.length > 0) {
            body.requirement[reqIndex].skills = req.skills.map((val) => {
              if (val.skill_id) {
                return { label: val.label, value: val.value, id: val.skill_id }
              } else {
                return { label: val.label, value: val.value }
              }
            })
          }
          if (req.competency && req.competency.length > 0) {
            body.requirement[reqIndex].competency = req.competency.map((val) => {
              if (val.competency_id) {
                return { label: val.label, value: val.value, id: val.competency_id }
              } else {
                return { label: val.label, value: val.value }
              }
            })
          }
        })
        delete body.employee_required
      }


      if (body.medication && body.medication.length) {
        body.medication =
          body.medication.map((item, medIndex) => ({
            ...(item.id && { id: item.id }),
            effective_from: item.effective_from || null,
            effective_to: item.effective_to || null,
            // doseTime: item.doseTime || null,
            // prescriptions:[item.prescriptions] || []
            doseTime: {
              ...(item.dose_id && { id: item.dose_id }),
              medication_dose_timing: item.doseTime || null,
              prescriptions: [item.prescriptions] || []
          }
          }))
        }
       
      if (body.planned_task && body.planned_task.length) {
        body.planned_task = body.planned_task.length ?
          body.planned_task.map(item => {
            return {
              effective_from: item.effective_from || null,
              effective_to: item.effective_to || null,
              ...(item.id && { id: item.id }),
              tasks: item.tasks && item.tasks.length ?
                item.tasks.map((task, index) => {
                  return {
                    ...(task.id && { id: task.id }),
                    support_level: task.support_level || null,
                    care_task: task.care_task_data || null,
                    care_category: task.care_category || null,
                    task_type: task.task_type || null,
                    message: task.message || null,
                    ordered: Number(index + 1),
                    is_essential: task.is_essential ? task.is_essential : false,
                  }
                }) : []
            }
          }) : []
      }

      if (body.time_critical === undefined) {
        body.time_critical = false;
      }

      if (body.include_holiday === undefined) {
        body.include_holiday = false;
      }

      if (body.is_suspended === undefined) {
        body.is_suspended = false;
      }
      if (body.is_overnight === undefined) {
        body.is_overnight = false;
      }
      if (body.full_day === undefined) {
        body.full_day = false;
      }
      // BE expects repeat default value as 1
      if (!body.repeat || body.repeat <= 0) {
        body.repeat = 1;
      }
      // if(values.service_address.destination){
      //   if(!body.body[index].service_destination){
      //     body.body[index].service_destination=''
      //   }
      //   body.body[index].service_destination=values.service_address.destination
      // }
      // BE expects repeat default value as 1
      if (body.end_value === undefined || body.end_value === null) {
        body.end_value = '';
      }

      // format duration from seconds to HH:mm:ss
      if (body.duration || body.duration === 0) {
        const formatted = moment.utc(body.duration * 1000).format('HH:mm:ss');
        body.duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
      }

      if (body.full_day) {
        body.preferred_start_time = "00:00:00"
        body.preferred_end_time = "23:59:59"
        // delete body.visits[index].full_day
      }
      body.skills_required && delete body.skills_required
      body.bill_rate && delete body.bill_rate
      body.travel_pay_rate && delete body.travel_pay_rate
      // })


    }

    setLoadingSave(true);

    let value = [];
    // let clientId = ''
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId
    // } else {
    //   clientId = selectedClientId
    // }

    console.log(values, body, "bbbbbbbbbb")
    setClientContractVisits(body, selectedClientId)
      .then(response => {
        if ((response.status === 400) || (response.status == 'False') || (response.status === false)) {
          if (response?.message?.visit_to_update || response?.message?.visit_to_create || response?.message?.visit_to_delete) {
            setModalValueConfirmSave(true)
            setLoadingSave(false);
            setVisitToUpdateData(response.message.visit_to_update || [])
            setVisitToCreateData(response.message.visit_to_create || [])
            setVisitToDeleteData(response.message.visit_to_delete || [])
            return;
          } else if (response?.message?.notes) {
            throw { message: response?.message?.notes[0] };
          } else {
            if (response.message) {
              value = response.message
            } else {
              value = Object.keys(response)[0] + "-" + Object.values(response)[0]
            }
            throw value;
          }
        }
        setShowError(false);
        if (response.status) {
          handleModalOnSave()
          setModalValueConfirmSave(false)
          // let contractId = response?.data?.id;
          // if employee id

          setValues({
            ...values,
            //  id: contractId,
          });

          // setPrefDet(clientId)
          setLoadingSave(false);
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
        }
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    if (response.status === 200 || response.status === 201 || response.status === true) {
      // let clientId=''
      // if(location.pathname.includes('/client/add')){
      //   clientId=cliId
      // }else{
      //   clientId=selectedClientId
      // }
      handleModalOnSave()

      let contractId = response.message.id;
      // if employee id

      setValues({
        ...values,
        id: contractId,
      });

      // setPrefDet(clientId)
      setLoadingSave(false);
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };
  const onError = err => {
    const { message } = err || {};
    let msg = ""
    if (typeof message === "object") {
      msg = message.message
    } else {
      msg = message
    }
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      msg
    );
  };
  const getClientContracts = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getClientsServices({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    setServiceContractLength(response.results.length)
    setServiceContracts(response.results || []);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    const initialExpansionState = {};

    response.results.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: false, requirementData: {} };
      client.visits_data && (client.visits_data.visits.length > 0) && client.visits_data.visits.forEach((req, rIndex) => {
        initialExpansionState[cIndex].requirementData[rIndex] = { open: false }
      })
    });
    setExpandFields({ ...initialExpansionState });
    setModalLoading(false)
  };



  const getPlannedTasks = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getPlanned({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    if (response.results) {
      setPlannedTasks(response.results.map((item) => {
        return { id: item.id, name: item.group_name }
      }));
      // setModalLoading(false)
    }


  };

  const getValueName = (res, type) => {
    let value = []
    let _;
    if (type) {
      _= res && res?.forEach((resData) => {
     _=  choices?.care_task?.forEach((careData) => {
          if (careData.id === resData.care_task) {
            value.push(careData)
          }
        })
      })
    } else {
       _=  res && res?.forEach((resData) => {
        _=choices?.group_task?.forEach((careData) => {
          if (careData.id === resData.group_task) {
            value.push(careData)
          }
        })
      })
    }
    return value
  }

  const groupTaskFn = async () => {
    // let clientId = cliId ? cliId : selectedClientId;
    const responseGroupTask = await getClientGroupTask(selectedClientId);
    const responseTask = await getClientTask(selectedClientId);

    if (responseTask) {
      const tempTask = getValueName(responseTask, 1)
      setClientTaskOptions(tempTask);

    }
    if (responseGroupTask) {
      const tempGroupTask = getValueName(responseGroupTask, 0)
      setGroupTaskOptions(tempGroupTask)
    }
  }

  const searchClientService = async () => {
    // let clientId = cliId ? cliId : selectedClientId
   
    if (editTypeSelected === 'contract_visit') {
      const responseVisit = await getClientsServicesVisitsByVisitId(selectedClientId, idSelectedVisit);
      if (isCopy && responseVisit.data && responseVisit.data.id) {
        responseVisit.data.id = ''
      }
      if (responseVisit.data) {
        if (responseVisit.data.employee_required) {
          responseVisit.data.employee_required.map((emp, empIndex) => {
            if (emp.skills) {
              emp.skills.map((skill, skillIndex) => {
                let name = choices.skill && choices.skill.find(x => x.id === skill.skill)?.name
                //responseVisit.data.employee_required[empIndex].skills[skillIndex]={id:skill.id,value:skill.skill,label:name}
                responseVisit.data.employee_required[empIndex].skills[skillIndex] = { value: skill.skill, label: name, skill_id: skill.id }
                if (isCopy) {
                  delete responseVisit.data.employee_required[empIndex].skills[skillIndex].skill_id
                }
              })
            }
            if (emp.competency) {
              emp.competency.map((comptence, competencyIndex) => {
                let name = choices.competency_name && choices.competency_name.find(x => x.id === comptence.competency)?.name
                responseVisit.data.employee_required[empIndex].competency[competencyIndex] = { value: comptence.competency, label: name, competency_id: comptence.id }
                //responseVisit.data.employee_required[empIndex].competency[competencyIndex]={id:comptence.id,value:comptence.competency,label:name}
                if (isCopy) {
                  delete responseVisit.data.employee_required[empIndex].competency[competencyIndex].competency_id
                }
              })
            }
            if (emp.client_task) {
              responseVisit.data.employee_required[empIndex].specific_groups = [];
              emp.client_task.map((planItem, planId) => {
                let name = plannedTasks && plannedTasks.find(x => x.id === planItem.specific_group)?.name;
                responseVisit.data.employee_required[empIndex].specific_groups[planId] = { planned_id: planItem.id, value: planItem.specific_group, label: name }
                if (isCopy) {
                  delete responseVisit.data.employee_required[empIndex].specific_groups[planId].planned_id
                }
              })
            }
            if (emp.clientMedication) {
              responseVisit.data.employee_required[empIndex].medication = [];
              emp.clientMedication.map((planItem, planId) => {
                let name = choices.medication_dose_timing && choices.medication_dose_timing.find(x => x.id === planItem.medicationDoseTime)?.name;
                responseVisit.data.employee_required[empIndex].medication[planId] = { med_id: planItem.id, value: planItem.medicationDoseTime, label: name }
                if (isCopy) {
                  delete responseVisit.data.employee_required[empIndex].medication[planId].med_id
                }
              })
            }
          })
        }
        if (responseVisit.data.preferred_start_time === "00:00:00" && responseVisit.data.preferred_end_time === "23:59:59") {
          responseVisit.data.full_day = true
        }
        // responseVisit.data.medicationLength = responseVisit?.data?.medication && responseVisit?.data?.medication.length
        responseVisit.data.medication = responseVisit.data.medication?.map((item => ({
          ...item,
        doseTime: item.doseTime.medication_dose_timing,
        dose_id: item.doseTime.id,
        prescriptions: item?.doseTime?.prescriptions[0] && item?.doseTime?.prescriptions[0]

        })))
        if (isCopy) {
          delete responseVisit.data.created_at
          if (responseVisit.data.bill_rate) {
            responseVisit.data.bill_rate = responseVisit?.data?.bill_rate.map((bill_r) => {
              delete bill_r.id
              delete bill_r.client_service_visit
              return bill_r
            })
          }
          if (responseVisit.data.employee_required) {
            responseVisit.data.employee_required = responseVisit?.data?.employee_required.map((req_d) => {
              delete req_d.service_visit
              if (req_d.pay_rates) {
                req_d.pay_rates = req_d.pay_rates.map((pay_d) => {
                  delete pay_d.client_service_visit_requirement
                  delete pay_d.id
                  return pay_d
                })
              }
              return req_d
            })
          }
        }
      }


      if (responseVisit.data.planned_task) {
        responseVisit.data.planned_task = responseVisit.data.planned_task.map(item => {
          return {
            ...item,
            tasks: item.tasks.map(el => {
              return {
                ...el,
                care_task_data: el.care_task
              }
            })
          }
        })
      }



      values.visits[0] = { ...responseVisit.data };
      setValues({
        ...values,
        ...responseVisit
      });
      setLoadingVisit(false)
    } else {
      const responseFunder = await getClientsServicesById(selectedClientId, idSelected);
      if (responseFunder.service_funders) {
        responseFunder.service_funders = responseFunder.service_funders.map((val, index) => {
          let name_tem = null;
          if (val.name && !val.self_funded) {
            name_tem = funderList.find(funder => funder.name == val.name) && funderList.find(funder => funder.name == val.name).id

          }
          return { ...val, name: name_tem ? name_tem : val.name, self_funded: val.self_funded ? 1 : 2, status: val.status ? 1 : 2 }
        })
      }
      if (isCopy && responseFunder && responseFunder.id) {
        delete responseFunder.id;
        (responseFunder.bill_rate && (responseFunder.bill_rate.length > 0)) ? responseFunder.bill_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.bill_rate;
        (responseFunder.pay_rate && (responseFunder.pay_rate.length > 0)) ? responseFunder.pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.pay_rate;
        responseFunder.service_funders && responseFunder.service_funders.forEach((val) => {
          if (val.split_funders) {
            val.split_funders.forEach((split) => { delete split.id })
          }
          delete val.id;
          delete val.bill_rate
          delete val.pay_rate
          delete val.travel_pay_rate
        });
        (responseFunder.travel_pay_rate && (responseFunder.travel_pay_rate.length > 0)) ? responseFunder.travel_pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.travel_pay_rate;
        responseFunder.requirement && responseFunder.requirement.forEach((val) => { delete val.id });

      }
      delete responseFunder.visits

      if (isCopy && values && values.id) {
        delete values.id;
        (values.bill_rate && (values.bill_rate.length > 0)) ? values.bill_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.bill_rate;
        (values.pay_rate && (values.pay_rate.length > 0)) ? values.pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.pay_rate;
        values.service_funders && values.service_funders.forEach((val) => {
          if (val.split_funders) {
            val.split_funders.forEach((split) => { delete split.id })
          }
          delete val.id;
          delete val.bill_rate
          delete val.pay_rate
          delete val.travel_pay_rate
        });
        (values.travel_pay_rate && (values.travel_pay_rate.length > 0)) ? values.travel_pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.travel_pay_rate;
        values.requirement && values.requirement.forEach((val) => { delete val.id });
        delete values.visits
      }
      setValues({
        ...values,
        ...responseFunder
      });
    }


    // const visit_data = response.visits.find((i)=>i.id==idSelected)
    // if(visit_data){
    //   values.visits[0] = visit_data
    // }



    // if(response.service_funders){
    //       if(response.service_funders.status===true){
    //         response.service_funders.status=1
    //       }
    //       if(response.service_funders.status===false){
    //         response.service_funders.status=2
    //       } 
    //       if(response.service_funders.name){
    //         if(response.service_funders.self_funded===false){
    //           response.service_funders.name=funderList.find(funder=>funder.name==response.service_funders.name) && funderList.find(funder=>funder.name==response.service_funders.name).id
    //         }
    //     }
    //       if(response.service_funders.self_funded===true){
    //         response.service_funders.self_funded=1
    //       }
    //       if(response.service_funders.self_funded===false){
    //         response.service_funders.self_funded=2
    //       } 

    // }

  };

  const searchClientServiceTemplate = async (id) => {
    const responseFunder = await getClientsServicesTemplateById(id);
    if (responseFunder.service_funders) {
      responseFunder.service_funders = responseFunder.service_funders.map((val, index) => {
        let name_tem = null;
        if (val.name && !val.self_funded) {
          name_tem = funderList.find(funder => funder.name == val.name) && funderList.find(funder => funder.name == val.name).id

        }
        return { ...val, name: name_tem ? name_tem : val.name, self_funded: val.self_funded ? 1 : 2, status: val.status ? 1 : 2 }
      })
    }
    if (responseFunder && responseFunder.id) {
      delete responseFunder.id;
      (responseFunder.bill_rate && (responseFunder.bill_rate.length > 0)) ? responseFunder.bill_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.bill_rate;
      (responseFunder.pay_rate && (responseFunder.pay_rate.length > 0)) ? responseFunder.pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.pay_rate;
      responseFunder.service_funders && responseFunder.service_funders.forEach((val) => {
        if (val.split_funders) {
          val.split_funders.forEach((split) => { delete split.id })
        }
        delete val.id;
        delete val.bill_rate
        delete val.pay_rate
        delete val.travel_pay_rate
      });
      responseFunder.visits && responseFunder.visits.map((val, valIndex) => {
        debugger
        delete val.core_service
        val.employee_required.map((split, splitIndex) => {
          delete split.id
          split.skills.map((el, elIndex) => {
            let skillname = choices.skill && choices.skill.find(x => x.id === el.skill)?.name
            responseFunder.visits[valIndex].employee_required[splitIndex].skills[elIndex] = { ...el, label: skillname, value: el.skill }
          })

          split.competency.map((comp, compIndex) => {
            let compName = choices.competency_name && choices.competency_name.find(x => x.id === comp.competency)?.name
            responseFunder.visits[valIndex].employee_required[splitIndex].competency[compIndex] = { ...comp, label: compName, value: comp.competency }
          })
        })
        val.bill_rate && val.bill_rate.length && val.bill_rate.forEach((split) => { delete split.id; delete split.client_service_visit })
        val.travel_pay_rate && val.travel_pay_rate.length && val.travel_pay_rate.forEach((split) => { delete split.id; delete split.client_service_visit })
      });
      (responseFunder.travel_pay_rate && (responseFunder.travel_pay_rate.length > 0)) ? responseFunder.travel_pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete responseFunder.travel_pay_rate;
      responseFunder.requirement && responseFunder.requirement.forEach((val) => { delete val.id });

    }
    if (values && values.id) {
      delete values.id;
      (values.bill_rate && (values.bill_rate.length > 0)) ? values.bill_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.bill_rate;
      (values.pay_rate && (values.pay_rate.length > 0)) ? values.pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.pay_rate;
      values.service_funders && values.service_funders.forEach((val) => {
        if (val.split_funders) {
          val.split_funders.forEach((split) => { delete split.id })
        }
        delete val.id;
        delete val.bill_rate
        delete val.pay_rate
        delete val.travel_pay_rate
      });
      (values.travel_pay_rate && (values.travel_pay_rate.length > 0)) ? values.travel_pay_rate.forEach((val) => { delete val.id; delete val.service_funder }) : delete values.travel_pay_rate;
      values.requirement && values.requirement.forEach((val) => { delete val.id });
      delete values.visits
    }
    setValues({
      ...values,
      ...responseFunder
    });

    // }


  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = (id, type) => {

    if (type == 'contract_visit') {
      values.visits = [];
      values.visits.push({ id: id, skills_required: [] });
      setValues({ ...values });
      setIdSelectedVisit(id)
    } else {
      setIdSelected(id);
    }
    //for error message
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])
    setIsCopy(false)
    setEditToggle(true)
    setEditMode(false)

    setEditTypeSelected(type)
    setModalValue(true);
  };

  const handleRowClickAdd = (id, type) => {

    values.visits = [];
    values.visits.push({ client_service: id, skills_required: [] });
    setValues({ ...values });
    //for error message
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])
    setEditToggle(false)
    setEditMode(true)
    setIsCopy(false)
    setIdSelectedVisit('')
    setEditTypeSelected(type)
    setModalValue(true);
  };

  const handleRowClickCopy = (id, type) => {

    if (type == 'contract_visit') {
      values.visits = [];
      values.visits.push({ client_service: id, skills_required: [] });
      setValues({ ...values });
      setIdSelectedVisit(id)
    } else {
      setIdSelected(id);
    }
    //for error message
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])
    setIsCopy(true)
    setEditToggle(false)
    setEditMode(true)

    setEditTypeSelected(type)
    setModalValue(true);
    setModalCopyWarning(false)
  };

  const handleModalOnSave = () => {
    setModalValue(false);
    setEditToggle(false)
    getClientContracts();
  }

  const handleModal = event => {

    setModalValue(!modalValue);
    setIdSelected('');
    setEditTypeSelected('create')
    setValues({})
    setEditToggle(false)
    setEditMode(false)

    //error states
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])

    if (event === NEW_CONTRACT) {
      setButtonIcon(FLOPPY);

      setEditMode(true)
      setTextStatus(SAVE);
    } else {
      getClientContracts();

    }
  };

  const handleModalTemplateCopy = id => {

    setModalValue(!modalValue);
    setIdSelected('');
    setEditTypeSelected('create')
    setValues({})
    setEditToggle(false)
    setEditMode(true)

    //error states
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])
    setModalTemplateValue(false)
    // setIsCopy(true)
    searchClientServiceTemplate(id)

    // if (event === NEW_CONTRACT) {
    //   setButtonIcon(FLOPPY);

    //   setEditMode(true)
    //   setTextStatus(SAVE);
    // } else {
    //   getClientContracts();

    // }
  };

  const shouldDeleteContract = async () => {
    try {
      const res = await shouldDeleteServiceContractApi(idSelected)
      if (res.count === 0 && res.status) {
        handleDeleteContract()
      } else if (res.count > 0 && !res.status) {
        const message = `This service visit have more than ${res?.count} In-progress/Completed visits, Please revert them or add new employee requirement.`
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${message}`,
        });
        setModalDeleteValue(false);
        setIdSelected('');
      }
    } catch (error) {

    }
  }

  const handleDeleteContract = async () => {
    try {
      const res = await deleteServiceContract(idSelected);

      // await deleteServiceContract(selectedClientId, idSelected);
      //Todo: confirm deleted client message
      if (res.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `Successfully deleted!`,
        });
      }

    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      getClientContracts();
      setIdSelected('');
    }
  };

  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const dropDownHandleVisits = (item, nameItem, index) => {
    //recursive objects merge
    values.visits[index][nameItem] = item[nameItem];

    setValues({ ...values });
  };

  const getTimesVisit = visits => {

    const times = visits.map(visit => {
      // return (
      //   moment(visit.preferred_start_time, 'HH:mm').format('HH:mm') +
      //   ' - ' +
      //   moment(visit.preferred_end_time, 'HH:mm').format('HH:mm')
      // );
      return (
        visit.preferred_start_time +
        ' - ' +
        visit.preferred_end_time
      );
    });
    return times;
  };

  const getDateFunder = visits => {
    let visitsData = Array.isArray(visits) ? visits : [visits];

    const times = visitsData.map(visit => {
      return (visit?.effective_date_from || visit?.effective_date_to ?
        `${visit?.effective_date_from ? visit?.effective_date_from : ""} ${visit?.effective_date_to ? ` - ${visit?.effective_date_to}` : ' - Unset'}`
        : "Unset");
    });
    return times;

  };

  const getTimeVisitPlaceHolder = visits => {
    let placeHolder;
    if (visits.length) {
      placeHolder =
        moment(visits[0].preferred_start_time, 'HH:mm').format('HH:mm') +
        ' - ' +
        moment(visits[0].preferred_end_time, 'HH:mm').format('HH:mm');
    }
    return placeHolder;
  };

  const getDateFunderPlaceHolder = visits => {
    let visitsData = Array.isArray(visits) ? visits : [visits];
    let placeHolder;
    if (visitsData.length) {
      placeHolder = visitsData[0]?.effective_date_from || visitsData[0]?.effective_date_to ?
        visitsData[0]?.effective_date_from ? visitsData[0]?.effective_date_from : "" + ' - ' +
          `${visitsData[0]?.effective_date_to ? visitsData[0]?.effective_date_to : 'Unset'}` : "Unset"
    }
    return placeHolder;
  };

  const getDaysSelected = visits => {
    // get the string days and make them arrays
    let days = visits.map(visit => {
      return visit.repeat_on.split(',');
    });
    // flatten array of arrays
    let days_merged = [].concat.apply([], days);

    // remove duplicates
    let uniqueArray = days_merged.filter(function (item, pos) {
      return days_merged.indexOf(item) === pos;
    });

    return uniqueArray;
  };

  const isDaySelected = (name, daysSelected = '') => {
    let result = daysSelected.indexOf(name);

    return result > -1;
  };

  const getDays = () => {
    return [
      { name: 'MON' },
      { name: 'TUE' },
      { name: 'WED' },
      { name: 'THU' },
      { name: 'FRI' },
      { name: 'SAT' },
      { name: 'SUN' },
    ];
  };

  if (values && !values.service_clients) {
    values.service_clients = [
      { visits: [], service_funders: [] },
    ];
  }

  const dropDownHandleSelect = (item, nameItem) => {
    if (nameItem == 'self_funded') {
      if (item[nameItem] == 1) {
        values.service_funders = { bill_rate: [...values.service_funders.bill_rate], pay_rate: [...values.service_funders.pay_rate], split_funders: [...values.service_funders.split_funders] }
        values.service_funders.name = clientFullName
        values.service_funders.split_funders[0] = {}
        values.service_funders.split_funders[0] = { ...addressValues }
        delete values.service_funders.split_funders[0].id

      } else {
        values.service_funders = { bill_rate: [...values.service_funders.bill_rate], pay_rate: [...values.service_funders.pay_rate], split_funders: [...values.service_funders.split_funders] }
        values.service_funders.name = null
        values.service_funders.split_funders[0] = {}
      }

      setValues({ ...values })
    }


    if (nameItem == 'name') {
      if (item[nameItem] == 'add_funder') {
        setModalFunderValue(!modalFunderValue)
      }
      else {
        values.service_funders[nameItem] = item[nameItem];
        getFunderDetails(item[nameItem])
          .then((res) => {
            if (res) {
              values.service_funders.category = res.funder_category && res.funder_category
              values.service_funders.no = res.funder_code && res.funder_code
              values.service_funders.status = res.status ? 1 : 2
              values.service_funders.funder_accounts_code = res.funder_accounts_code && res.funder_accounts_code
              values.service_funders.reference = res.reference && res.reference
              values.service_funders.invoice_type = res.invoice_type && res.invoice_type
              values.service_funders.billing_mode = res.bill_rate_model && res.bill_rate_model
              values.service_funders.billing_duration_type = res.billing_duration_type && res.billing_duration_type
              values.service_funders.billing_duration_source = res.billing_duration_source && res.billing_duration_source
              values.service_funders.bill_time_unit = res.bill_time_unit && res.bill_time_unit
              values.service_funders.discount = res.discount && res.discount
              values.service_funders.pattern = res.billing_pattern && res.billing_pattern
              values.service_funders.billing_term = res.billing_terms && res.billing_terms
              values.service_funders.payment_method = res.billing_payment_method && res.billing_payment_method
              values.service_funders.funder_cancellation_fees = res.funder_cancellation_fee && res.funder_cancellation_fee
              values.service_funders.split_funders[0].full_name = res.funder_contact_full_name && res.funder_contact_full_name
              values.service_funders.split_funders[0].email = res.funder_contact_email && res.funder_contact_email
              values.service_funders.split_funders[0].phone_no = res.funder_contact_phone_numbers && res.funder_contact_phone_numbers
              values.service_funders.split_funders[0].funder_contact_no = res.funder_contact_no && res.funder_contact_no
              values.service_funders.split_funders[0].funder_contact_organisation_title = res.funder_contact_organisation_title && res.funder_contact_organisation_title
              values.service_funders.split_funders[0].full_name = res.funder_name && res.funder_name
              values.service_funders.split_funders[0].line_1 = res.line_1 && res.line_1
              values.service_funders.split_funders[0].line_2 = res.line_2 && res.line_2
              values.service_funders.split_funders[0].city = res.city && res.city
              values.service_funders.split_funders[0].state = res.state && res.state
              values.service_funders.split_funders[0].zip_code = res.zip_code && res.zip_code
              values.service_funders.core_funder = res.id
              setValues({ ...values })

            }
          })
        setValues({ ...values }); //item added
      }
    }
    else {
      values.service_funders[nameItem] = item[nameItem];
      setValues({ ...values }); //item added
    }

  };

  const dropDownHandleSelectFunderBilling = (item, nameItem, indexVisit) => {

    values.service_funders[indexVisit][nameItem] = item[nameItem];
    setValues({ ...values }); //item added

  };

  const dropDownHandleSelectFunder = (item, nameItem, indexVisit) => {
    if (nameItem == 'self_funded') {
      if (item[nameItem] == 1) {
        // values.service_funders[indexVisit]={bill_rate:values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].bill_rate],pay_rate: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].pay_rate],split_funders: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].split_funders]}
        values.service_funders[indexVisit].name = clientFullName
        values.service_funders[indexVisit].split_funders[0] = {}
        values.service_funders[indexVisit].split_funders[0] = { ...addressValues }
        delete values.service_funders[indexVisit].split_funders[0].id

      } else {
        // values.service_funders[indexVisit]={bill_rate:values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].bill_rate],pay_rate: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].pay_rate],split_funders: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].split_funders]}
        values.service_funders[indexVisit].name = null
        values.service_funders[indexVisit].split_funders[0] = {}
      }
      setValues({ ...values })
    }


    if (nameItem == 'name') {
      if (item[nameItem] == 'add_funder') {
        setModalFunderValue(!modalFunderValue)
      }
      else {
        values.service_funders[indexVisit][nameItem] = item[nameItem];
        getFunderDetails(item[nameItem])
          .then((res) => {
            if (res) {
              values.service_funders[indexVisit].category = res.funder_category && res.funder_category
              values.service_funders[indexVisit].no = res.funder_code && res.funder_code
              values.service_funders[indexVisit].status = res.status ? 1 : 2
              values.service_funders[indexVisit].funder_accounts_code = res.funder_accounts_code && res.funder_accounts_code
              values.service_funders[indexVisit].reference = res.reference && res.reference
              values.service_funders[indexVisit].invoice_type = res.invoice_type && res.invoice_type
              values.service_funders[indexVisit].billing_mode = res.bill_rate_model && res.bill_rate_model
              values.service_funders[indexVisit].billing_duration_type = res.billing_duration_type && res.billing_duration_type
              values.service_funders[indexVisit].billing_duration_source = res.billing_duration_source && res.billing_duration_source
              values.service_funders[indexVisit].bill_time_unit = res.bill_time_unit && res.bill_time_unit
              values.service_funders[indexVisit].discount = res.discount && res.discount
              values.service_funders[indexVisit].pattern = res.billing_pattern && res.billing_pattern
              values.service_funders[indexVisit].billing_term = res.billing_terms && res.billing_terms
              values.service_funders[indexVisit].payment_method = res.billing_payment_method && res.billing_payment_method
              values.service_funders[indexVisit].funder_cancellation_fees = res.funder_cancellation_fee && res.funder_cancellation_fee
              values.service_funders[indexVisit].split_funders[0].full_name = res.funder_contact_full_name && res.funder_contact_full_name
              values.service_funders[indexVisit].split_funders[0].email = res.funder_contact_email && res.funder_contact_email
              values.service_funders[indexVisit].split_funders[0].phone_no = res.funder_contact_phone_numbers && res.funder_contact_phone_numbers
              values.service_funders[indexVisit].split_funders[0].funder_contact_no = res.funder_contact_no && res.funder_contact_no
              values.service_funders[indexVisit].split_funders[0].funder_contact_organisation_title = res.funder_contact_organisation_title && res.funder_contact_organisation_title
              values.service_funders[indexVisit].split_funders[0].full_name = res.funder_name && res.funder_name
              values.service_funders[indexVisit].split_funders[0].line_1 = res.line_1 && res.line_1
              values.service_funders[indexVisit].split_funders[0].line_2 = res.line_2 && res.line_2
              values.service_funders[indexVisit].split_funders[0].city = res.city && res.city
              values.service_funders[indexVisit].split_funders[0].state = res.state && res.state
              values.service_funders[indexVisit].split_funders[0].zip_code = res.zip_code && res.zip_code
              values.service_funders[indexVisit].core_funder = res.id
              setValues({ ...values })

            }
          })
        setValues({ ...values }); //item added
      }
    }
    else {
      values.service_funders[indexVisit][nameItem] = item[nameItem];
      setValues({ ...values }); //item added
    }

  };

  const dropDownHandleSelectAddress = (item, nameItem) => {
    values.service_address[nameItem] = item[nameItem];
    setValues({ ...values });
  };



  const displayFunderType = (data) => {
   let find =  data && choices && choices?.service_category && choices?.service_category?.find(source => source?.id === data)
    return find ? (find?.name || "") : ""
  }

  const displayVisitType = (data) => {
    // let name = ''
    // if (data) {
    //   return choices && choices.visit_type && choices.visit_type.find(source => source.id === data).name
    // }
    let find = data && choices && choices?.visit_type && choices?.visit_type?.find(source => source?.id === data)
    return find ? (find?.name || "") : ""
  }

  console.log('expandFields', expandFields)

  const handleExpansion = (clientIndex) => {
    if (expandFields[clientIndex].open) {
      expandFields[clientIndex].open = false;
      setExpandFields({ ...expandFields });
    } else {
      expandFields[clientIndex].open = true;
      setExpandFields({ ...expandFields });
    }
  };

  const handleExpansionVisit = (clientIndex, visitIndex) => {
    if (expandFields[clientIndex].requirementData[visitIndex].open) {
      expandFields[clientIndex].requirementData[visitIndex].open = false;
      setExpandFields({ ...expandFields });
    } else {
      expandFields[clientIndex].requirementData[visitIndex].open = true;
      setExpandFields({ ...expandFields });
    }
  };


  const getDurations = (startDate, endDate, totalDuration, noOfDays) => {
    const formatedStartDate = moment(startDate, 'HH:mm:ss');
    const formatedEndDate = moment(endDate, 'HH:mm:ss')

    if (formatedEndDate < formatedStartDate)
      formatedEndDate.add(1, 'day')

    const durationMs = formatedEndDate.diff(formatedStartDate)
    const final = noOfDays > 0 ? noOfDays : 1
    totalDuration.add(durationMs * final);

  }

  const formatDuration = (duration) => {
    const day = duration?.days();
    const hours = duration?.hours()?.toLocaleString()?.padStart(2, "0");
    const minutes = duration?.minutes()?.toLocaleString()?.padStart(2, "0");
    const seconds = duration?.seconds()?.toLocaleString()?.padStart(2, "0");
    const final = `${day ? day + "d:" : ""}${hours}h:${minutes}m`
    return final

  }


  return (
    <ComponentDynamic
      loading={modalLoading}
    >
      <Toast ref={toast} />
      {(selectedClientId ? readServicePermissions : createServicePermissions) ?
        <div>

          {(createServicePermissions && (serviceContracts.length >= 1) && !modalValue) ? (

            <div style={{ paddingTop: "20px" }} className={'w-100 d-flex justify-content-center mb-3 mt-2'}>
              <div className={'text-primary font-weight-bold'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => handleModal(NEW_CONTRACT)}>
                  <BsFolderPlus size={18} />
                  <span className={'ml-2'}>ADD NEW SERVICE CONTRACT</span>
                </PrimaryButtonForm>
              </div>
              <div className={'ml-2 text-primary font-weight-bold'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => setModalTemplateValue(true)}>
                  <BsFolderPlus size={18} />
                  <span className={'ml-2'}>ADD FROM SERVICE CONTRACT TEMPLATE</span>
                </PrimaryButtonForm>
              </div>
            </div>

          ) : (
            <Fragment />
          )}
          {(modalValue && serviceContracts.length >= 1) ? (
            <div style={{ paddingTop: "20px" }} className={'w-100 d-flex justify-content-center'}>
              <div>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={handleModal}>
                  <div className={'d-flex align-items-center'}>
                    <TiArrowBackOutline size={24} />
                    <div>BACK</div>
                  </div>
                </PrimaryButtonForm>
              </div>

            </div>
          ) : (
            <Fragment />
          )}
          {editToggle ?
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

              <ToggleDiv>
                <ToggleWrapper>
                  <Toggle
                    id="checkbox"
                    type="checkbox"
                    checked={editMode}
                    onClick={() => {
                      editMode ? setEditMode(false) : setEditMode(true);
                    }}
                  />
                  <ToggleLabel htmlFor="checkbox" />
                </ToggleWrapper>
                <div className="toggle-label ml-2  mb-3 pb-3 mr-2">
                  <p>Edit Mode</p>
                </div>
              </ToggleDiv>

            </div>
            : <Fragment />}
          {serviceContracts.length >= 1 && !modalValue ? (
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                //noEditOption
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>
                <div>

                  {serviceContracts.map((clientContract, index) => {
                    let totalVisitsDuration = moment.duration();

                    let aa = clientContract?.visits_data?.visits?.map((clientContractVisit) => {
                      let days = clientContractVisit.repeat_on && clientContractVisit.repeat_on.split(",").length
                      getDurations(clientContractVisit?.preferred_start_time, clientContractVisit?.preferred_end_time, totalVisitsDuration, days)
                    })
                    const endDate = clientContract.end_date
                      ? moment(clientContract.end_date).format('DD-MM-YYYY')
                      : 'Unset';
                    const startDate = clientContract.start_date
                      ? moment(clientContract.start_date).format('DD-MM-YYYY')
                      : 'Unset';

                    // const daysSelected = getDaysSelected(clientContract.visits);
                    return (
                      <div key={index}       >
                        <Row bgColor Shadow
                          zIndex={ServiceContractLength - index}
                        >
                          <Col
                            max_width={EX_SMALL_COL}
                            className="d-flex justify-content-center align-content-center">
                            <div
                              className="closeButtonWrapper client-service-expand-icon"
                              onClick={() => {
                                handleExpansion(index);
                              }}>
                              {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                <IoIosArrowDown
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              ) : (
                                <IoIosArrowForward
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize

                            overlap={true}
                            Center>
                            {clientContract.funder_data && (clientContract.funder_data.length > 0) && clientContract.funder_data[0]?.category__name}
                          </Col>
                          <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {clientContract.funder_data && (clientContract.funder_data.length > 0) && clientContract.funder_data[0]?.name}
                          </Col>
                          <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {clientContract.funder_bill_rate_data && clientContract.funder_bill_rate_data?.service_bill_rate__name}
                          </Col>

                          {/* <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            overlap={true}
                            Capitalize
                            Center>
                            {startDate}
                          </Col> */}
                          <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center

                          >
                            {clientContract?.visits_data?.count}
                          </Col>
                          <Col
                            onClick={() => {
                              //handleRowClick(clientContract.id);
                            }}
                            className='md-hidden sm-hidden'
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center

                          >
                            {formatDuration(totalVisitsDuration)}
                          </Col>
                          <Col
                            max_width={EX_LARGE_COL}
                            className='sm-hidden'
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center
                          >
                            <Dropdown
                              noPadding
                              disableSelection
                              alignmentRight
                              justifyContent={'center'}
                              items={getDateFunder(clientContract.funder_data)}
                              onSelect={() => { }}
                              placeHolder={
                                clientContract.funder_data ? getDateFunderPlaceHolder(clientContract.funder_data) :
                                  'Unset'
                              }
                              appendItem=""
                            />
                          </Col>

                          {/* <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={MEDIUM_COL}
                            Shrink
                            justifyContent={'end'}
                            alignItems={'end'}
                            reducePadding
                            Capitalize
                            Center
                            overlap={true}
                            className="visits-custom-row sm-hidden">
                            {getDays().map(day => {
                              return (
                                <DayPill
                                  key={`day_pill_` + day.name}
                                  Active={isDaySelected(day.name, daysSelected)}>
                                  <label>{day.name}</label>
                                </DayPill>
                              );
                            })}
                          </Col> */}

                          <Col className="" Center Icon Purple>

                            <IconWrapper
                              style={{ marginRight: '8px' }}
                              onClick={() => {
                                handleRowClick(clientContract.id, 'funder');
                              }}>
                              <MdEdit />
                            </IconWrapper>
                            <IconWrapper
                              onClick={() => {
                                handleRowClickAdd(clientContract.id, 'contract_visit');
                              }}>
                              <BsPlusCircleFill />
                            </IconWrapper>
                          </Col>
                          <Col className="" Center Shrink NoFlexGrow>
                            <IconWrapper

                              color={SOFT_RED}
                              onClick={() => {
                                setModalCopyWarning(true)
                                setIdSelectedFunder(clientContract.id)
                                setEditTypeSelected('copy_funder')
                                // handleRowClickCopy(clientContract.id, 'copy_funder')
                              }}
                            >
                              {COPY}
                            </IconWrapper>

                          </Col>
                          {/* <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            {deleteServicePermissions && <IconWrapper
                              onClick={() => {
                                toDeleteClient(clientContract.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>}
                          </Col> */}
                        </Row>
                        <div className="mb-1" />
                        <div className='mb-2 ml-5'>
                          {
                            expandFields && expandFields[index] && expandFields[index]?.open && clientContract.visits_data.visits && (clientContract.visits_data.visits.length > 0) &&
                            <>
                              <Table
                                headerColumns={headerColumnsVisits}
                                headerPadding={false}
                                // noEditOption
                                fontSize="0.75rem"
                                callBackQuerySort={setQuerySort}>
                                <div>

                                  {clientContract?.visits_data?.visits?.map((clientContractVisit, indexVisit) => {

                                    const endDate = clientContractVisit.end_date
                                      ? moment(clientContractVisit.end_date).format('DD-MM-YYYY')
                                      : 'Unset';
                                    const startDate = clientContractVisit.start_date
                                      ? moment(clientContractVisit.start_date).format('DD-MM-YYYY')
                                      : 'Unset';

                                    // const daysSelectedVisit = getDaysSelected(clientContractVisit);
                                    return (
                                      <div key={indexVisit}       >
                                        <Row bgColor Shadow style={{ backgroundColor: GRAY_200 }}
                                        // zIndex={ServiceContractLength - index}
                                        >
                                          {/* <Col
                                            max_width={EX_SMALL_COL}
                                            className="d-flex justify-content-center align-content-center">
                                            <div
                                              className="closeButtonWrapper client-service-expand-icon"
                                              onClick={() => {
                                                handleExpansionVisit(index, indexVisit);
                                              }}>
                                              {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                                <IoIosArrowDown
                                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                                />
                                              ) : (
                                                <IoIosArrowForward
                                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                                />
                                              )}
                                            </div>
                                          </Col> */}
                                          <Col
                                            onClick={() => {
                                              //handleRowClick(clientContractVisit.id);
                                            }}
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize

                                            overlap={true}
                                            Center>
                                            {displayVisitType(clientContractVisit.visit_type)}
                                          </Col>

                                          <Col
                                            onClick={() => {
                                              //handleRowClick(clientContractVisit.id);
                                            }}
                                            className='sm-hidden'
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            overlap={true}
                                            Center>
                                            {displayFunderType(clientContractVisit.service_category)}
                                          </Col>

                                          <Col
                                            onClick={() => {
                                              //handleRowClick(clientContractVisit.id);
                                            }}
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            overlap={true}
                                            Capitalize
                                            Center>
                                            {startDate}
                                          </Col>
                                          <Col
                                            onClick={() => {
                                              //handleRowClick(clientContractVisit.id);
                                            }}
                                            className='md-hidden sm-hidden'
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            overlap={true}
                                            Center

                                          >
                                            {endDate}
                                          </Col>
                                          <Col
                                            className='md-hidden sm-hidden'
                                            max_width={EX_LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            overlap={true}
                                            Center
                                          >
                                            {moment(clientContractVisit.preferred_start_time, 'HH:mm:ss').format("HH:mm") +
                                              ' - ' +
                                              moment(clientContractVisit.preferred_end_time, 'HH:mm:ss').format("HH:mm")}
                                          </Col>

                                          <Col
                                            max_width={MEDIUM_COL}
                                            Shrink
                                            justifyContent={'end'}
                                            alignItems={'end'}
                                            reducePadding
                                            Capitalize
                                            Center
                                            overlap={true}
                                            className="visits-custom-row md-hidden sm-hidden">
                                            {getDays().map(day => {
                                              return (
                                                <DayPill
                                                  key={`day_pill_` + day.name}
                                                  Active={isDaySelected(day.name, clientContractVisit.repeat_on)}>
                                                  <label>{day.name}</label>
                                                </DayPill>
                                              );
                                            })}
                                          </Col>
                                          <Col className="" Center Icon Purple>

                                            <IconWrapper
                                              onClick={() => {
                                                handleRowClick(clientContractVisit.id, 'contract_visit');
                                                setLoadingVisit(true)
                                              }}>
                                              <MdEdit />
                                            </IconWrapper>
                                          </Col>
                                          <Col className="" Center Shrink NoFlexGrow>

                                            {deleteServicePermissions && <IconWrapper
                                              style={{ marginRight: '8px' }}
                                              onClick={() => {
                                                toDeleteClient(clientContractVisit.id);
                                              }}>
                                              <RiDeleteBinLine />
                                            </IconWrapper>}
                                            <IconWrapper

                                              color={SOFT_RED}
                                              onClick={() => {
                                                setModalCopyWarning(true)
                                                setIdSelectedFunder(clientContractVisit.id)
                                                setEditTypeSelected('contract_visit')
                                                // handleRowClickCopy(clientContractVisit.id, 'contract_visit')
                                              }
                                              }
                                            >
                                              {COPY}
                                            </IconWrapper>
                                          </Col>
                                        </Row>
                                        <div className="mb-1" />
                                        {/* <div className='mb-2 ml-5'>
                                          {
                                            expandFields && expandFields[index] && expandFields[index]?.open && clientContract.visits_data.visits && (clientContract.visits_data.visits.length > 0) && clientContract.visits_data.visits[indexVisit].requirementData && (clientContract.visits_data.visits[indexVisit].requirementData.length > 0) && expandFields[index]?.requirementData && expandFields[index]?.requirementData[indexVisit]?.open &&
                                            <>
                                              <Table
                                                headerColumns={headerColumnsVisitsEmployee}
                                                headerPadding={false}
                                                noEditOption
                                                fontSize="0.75rem"
                                                callBackQuerySort={setQuerySort}>
                                                <div>

                                                  {clientContract?.visits_data?.visits[indexVisit].requirementData.map((clientContractVisitReq, index) => {

                                                    const endDate = clientContractVisit.end_date
                                                      ? moment(clientContractVisit.end_date).format('DD-MM-YYYY')
                                                      : 'Unset';
                                                    const startDate = clientContractVisit.start_date
                                                      ? moment(clientContractVisit.start_date).format('DD-MM-YYYY')
                                                      : 'Unset';

                                                    return (
                                                      <div key={index}       >
                                                        <Row bgColor Shadow style={{ backgroundColor: GRAY_300 }}
                                                        >

                                                          <Col
                                                            onClick={() => {
                                                            }}
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize

                                                            overlap={true}
                                                            Center>
                                                            {clientContractVisitReq.no_of_employee}
                                                          </Col>

                                                          <Col
                                                            onClick={() => {
                                                            }}
                                                            className='sm-hidden'
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            {clientContractVisitReq.employee_type}
                                                          </Col>

                                                          <Col
                                                            onClick={() => {
                                                            }}
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            reducePadding
                                                            overlap={true}
                                                            Capitalize
                                                            Center>
                                                            {clientContractVisitReq.medication}
                                                          </Col>
                                                          <Col
                                                            onClick={() => {
                                                            }}
                                                            className='md-hidden sm-hidden'
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center

                                                          >
                                                            {clientContractVisitReq.plannedTask}
                                                          </Col>
                                                        </Row>
                                                        <div className="mb-1" />

                                                      </div>
                                                    );
                                                  })}
                                                 
                                                </div>

                                              </Table>
                                            </>
                                          }
                                        </div> */}
                                      </div>
                                    );
                                  })}
                                  {/* <div className="mt-3">
                    <Pagination
                      simplePagination={simplePagination}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      dataLength={serviceContracts.length}
                      dropdownEntriesLimit={false}
                    />
                  </div> */}
                                </div>

                              </Table>
                            </>
                          }
                        </div>
                      </div>
                    );
                  })
                  }
                  <div className="mt-3">
                    <Pagination
                      simplePagination={simplePagination}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      dataLength={serviceContracts.length}
                      dropdownEntriesLimit={false}
                    />
                  </div>
                </div>

              </Table>
              <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'mr-2'}
                    onClick={() => {
                      history.push('/client')
                    }}>
                    <span className={'mr-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <Fragment />
              {!modalValue &&
                <div
                  className={'d-flex justify-content-center justify-content-md-end'}>
                  <div>


                  </div>
                </div>
              }
            </>
          )}

          {!modalValue && serviceContracts.length >= 1 ? null : (
            <ModalServiceClient

              earlieErrors={earlieErrors}
              setShowEarlieError={setShowEarlieError}
              setEarlieMessage={setEarlieMessage}
              earlieMessage={earlieMessage}
              setEarlieError={setEarlieError}
              showEarlieError={showEarlieError}
              setSavedContract={setSavedContract}
              InvalidOrEmptyValues={InvalidOrEmptyValues}
              DetailsNotCreated={DetailsNotCreated}
              setErrorMessage={setErrorMessage}
              clientErrors={clientErrors}
              setShowError={setShowError}
              promisesWithErrors={promisesWithErrors}
              setCheckRequires={setCheckRequires}
              isValid={isValid}
              allErrors={allErrors}
              editMode={editMode}
              setEditMode={setEditMode}
              serviceId={idSelected}
              selectedClientId={selectedClientId}
              dropDownHandleVisits={dropDownHandleVisits}
              dropDownHandleSelect={dropDownHandleSelect}
              dropDownHandleSelectFunder={dropDownHandleSelectFunder}
              dropDownHandleSelectFunderBilling={dropDownHandleSelectFunderBilling}
              dropDownHandleSelectAddress={dropDownHandleSelectAddress}
              values={values}
              setValues={setValues}
              clientId={selectedClientId}
              useInput={useInput}
              choices={choices}
              checkBeforeSend={checkBeforeSend}
              showError={showError}
              errorMessage={errorMessage}
              setErrorSplitPercentage={setErrorSplitPercentage}
              errorSplitPercentage={errorSplitPercentage}
              errors={errors}
              loadingFinish={loadingFinish}
              loadingSave={loadingSave}
              setLoadingSave={setLoadingSave}
              textStatus={textStatus}
              buttonIcon={buttonIcon}
              addressValues={addressValues}
              clientFullName={clientFullName}
              setDateError={setDateError}
              dateError={dateError}
              setTimeError={setTimeError}
              timeError={timeError}
              funderList={funderList}
              updateServicePermissions={updateServicePermissions}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              contextSubscription={contextSubscription}
              rightPermission={rightPermission}
              userClient={userClient}
              clientTaskOptions={clientTaskOptions}
              groupTaskOptions={groupTaskOptions}
              plannedTasks={plannedTasks}
              contextRelativesClient={contextRelativesClient}
              medDoseTimingList={medDoseTimingList}
              setFormErrors={setFormErrors}
              loadingSaveOnService={loadingSaveOnService}
              editTypeSelected={editTypeSelected}
              setModalValueConfirmSave={setModalValueConfirmSave}
              modalValueConfirmSave={modalValueConfirmSave}
              fullBindConfirmSave={fullBindConfirmSave}
              visitToUpdateData={visitToUpdateData}
              visitToCreateData={visitToCreateData}
              visitToDeleteData={visitToDeleteData}
              loadingVisit={loadingVisit}
            />
          )}
          {!modalFunderValue ? null : (
            <AddModal
              choices={choices}
              values={values}
              setValues={setValues}
              isValid={isValid}
              errors={errors}
              useInput={useInput}
              readOnly={false}
              fullBind={fullBind}
              setTitle={setTitle}
              setIdSelected={setIdSelected}
              setCheckRequires={setCheckRequires}
              loadFunders={funderLists}
              newAddFunder={true}
            />
          )}

          {!modalTemplateValue ? null : (
            <TemplatePopup
              choices={choices}
              values={values}
              setValues={setValues}
              isValid={isValid}
              errors={errors}
              useInput={useInput}
              readOnly={false}
              fullBind={fullBindTemplate}
              setTitle={setTemplateTitle}
              setIdSelected={setIdSelected}
              setCheckRequires={setCheckRequires}
              handleModalTemplateCopy={handleModalTemplateCopy}
              selectedClientId={selectedClientId}

            />
          )}

          <div>
            <ModalDecision
              type="warning"
              title="Warning"
              body={
                `Do you really want to copy this ${(editTypeSelected == 'contract_visit') ? 'visit' : 'funder'} ?`
              }
              subBody={
                ''
              }
              onOk={() => { handleRowClickCopy(idSelectedFunder, editTypeSelected) }}
              onCancel={() => {
                setIdSelectedFunder('');
                setEditTypeSelected('create')
                setModalCopyWarning(false)
              }}
              okTitle={'Confirm'}
              {...bindModalCopyWarning}
            />
            <ModalDecision
              type="delete"
              title="Warning"
              body={
                'Are you sure you want to delete this service contract? \n DELETING the service contract will remove ALL records from database.'
              }
              // subBody={
              //   'TIP (Recommended) - you could change the service contract status to discard instead to retain database records.'
              // }
              // onOk={handleDeleteContract}
              onOk={shouldDeleteContract}
              onCancel={() => {
                setIdSelected('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
          </div>
        </div>
        :
        <NoPermissionContainer>
          You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
        </NoPermissionContainer>
      }
    </ComponentDynamic>
  );
};

ServiceClientList.propTypes = {
  choices: PropTypes.object.isRequired,
  selectedClientId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allErrors: PropTypes.bool.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  savedContract: PropTypes.string.isRequired,
  setSavedContract: PropTypes.func.isRequired,
  addressValues: PropTypes.object.isRequired,
  setTextStatus: PropTypes.func.isRequired,
  clientErrors: PropTypes.array.isRequired,
  promisesWithErrors: PropTypes.array.isRequired,
};

export default ServiceClientList;