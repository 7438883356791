import React, {useState, useEffect, useRef, useContext} from 'react';
import {GrSearch} from 'react-icons/gr';
import {FaPlus} from 'react-icons/fa';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {DropdownTypesContainer} from 'shared/styles/constants/tagsStyles';
import {Header, SearchAddContainer} from './styles';
import {getClientTypes} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import {exportClientCronJob, getClientsList} from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import NewVisitCare from './NewVisitCare';
import DailyWeaklyFilter from './DailyWeaklyFiter';
import {Fragment} from 'react';
import TaskFilter from './TaskFilter';
import { reports_client_choices } from 'utils/choiceConstant';
import ServiceContract from './ServiceContract';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Toast } from 'primereact/toast';
const ClientFilters = ({
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  tabClick,
  apiKey
}) => {

  const clientVisitColumn = [
    "client_name",
    "location_name",
    "client_branch_name",
    "location_branch_name",
    "csv_id",
    "service_category",
    "visit_type",
    "contract_start_date",
    "contract_end_date",
    "end_value",
    "preferred_start_time",
    "preferred_end_time",
    "every",
    "repeat",
    "repeat_on",
    "last_visit_id",
    "last_visit_date",
    "last_visit_created_at_date"
  ];

 const medicationColumn = [
        "client_full_name",
        "client_branch_name",
        "medication_name",
        "dose",
        "dose_timing",
        "start_date",
        "medication_dose_id",
        "last_medication_id",
        "last_medication_date",
        "created_at"
    ];

    const deletedVisitColumn = [
          "visit_id",
          "csv_id",
          "client_name",
          "client_branch_name",
          "employee_name",
          "visit_status",
          "service_category",
          "is_regular_visit",
          "start_date",
          "end_date",
          "history_date",
          "is_visit_cancelled",
          "deleted_by",
          "is_double_up"
    ];

  const capitalize = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  const removeUnderCcore = str => str ? str.includes("_") ? capitalize(str.split("_").join(" ")) : str : ""

  const clientVisitColumnSelecter = clientVisitColumn.map((item) => ({ id: item, name: removeUnderCcore(item) }));
  const medicationColumnSelecter = medicationColumn.map((item) => ({ id: item, name: removeUnderCcore(item) }));
  const deletedVisitColumnSelecter = deletedVisitColumn.map((item) => ({id: item, name: removeUnderCcore(item)}));


let history = useHistory();
  const toast = useRef()
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput, errors, setFormErrors, setCheckRequires} = useForm({});
  const [runRouteData, setRunRouteData] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [clientTypeFilter, setClientTypeFilter] = useState([]);
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([]);
  const [visitTypeFilter, setVisitTypeFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [deletedByFilter, setDeletedByFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [medicationDoseFilter, setMedicationDoseFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [columnSelectorFilter, setColumnSelectorFilter] = useState([]);

  const {contextChoices,handleGetChoices, contextChoicesMap} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [clients, setClients] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [visitType, setVisitType] = useState([]);
  const [location, setLocation] = useState([]);
  const [medicationDose, setMedicationDose] = useState([]);
  const [visitStatus, setVisitStatus] = useState([]);
  const [usersList, setUsersList] = useState([]);
  


  // const start_date_hook = useInput('start_date', ((tabClick === 'Client Visit' || tabClick === 'Medication') && tabClick !== 'Medication') ? "isRequired" : "");
  const deleted_date_hook = useInput('deleted_date',
   ((tabClick === "Deleted Visit" && !values.visit_start_date) || values.deleted_end_date ) ? "isRequired" : "");
  const deleted_end_hook = useInput('deleted_end_date',
    ((tabClick === "Deleted Visit" && !values.visit_start_date )|| values.deleted_date) ? "isRequired" : "");

  const visit_start_date_hook = useInput('visit_start_date',
    ((tabClick === "Deleted Visit" && !values.deleted_date) ||  values.visit_end_date) ? "isRequired" : ""
  );
  const visit_end_date_hook = useInput('visit_end_date',
    ((tabClick === "Deleted Visit"&& !values.deleted_date) || values.visit_start_date)  ? "isRequired" : ""
  );
  // const medication_start_date_hook = useInput('medication_start_date', tabClick === 'Medication' ? "isRequired" : "");
  // const end_date_hook = useInput('end_date');



  useEffect(() => {
    handleGetChoices(reports_client_choices)
    
  }, [])
  console.log({contextChoicesMap, contextChoices},"contextChoices")
  
  const handleDownload = () => {
    console.log({errors,activeTab},"errors")
    if(errors.length){
      setCheckRequires(true)
      return;
    }
    setLoadingButton(true);
    SetDownloadDisable(true);
    let fileName = 'report_client_' + activeTab + '_' + moment().format('YYYY-MM-DD');
    ((tabClick === 'Client Visit' || tabClick === 'Medication' || tabClick === "Deleted Visit") ?
      exportClientCronJob({ ...branchData, ...values }, apiKey)
      : getClientsList({ ...branchData, ...values }, activeTab))
      .then(response => {
        if (response) {
          setLoadingButton(true);
          SetDownloadDisable(true);
        }
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        setTimeout(() => {
          setLoadingButton(false);
          SetDownloadDisable(false);

        }, 200);
      })
      .catch(err => {
        setLoadingButton(false);
        SetDownloadDisable(false);
        toast.current.show({
                  severity: 'error',
                  summary: 'Failed',
                  detail: `Export process failed`,
                });
       });
    SetDownloadDisable(false);
  };

  useEffect(() => {
    loadClientTypes();
  }, []);

  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      setClientTypes(response.results);
    });
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setClientStatus(
      contextChoices.client_status &&
        contextChoices.client_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setClients(
      contextChoices &&
        contextChoices?.client_listing &&
        contextChoices?.client_listing?.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );

    setServiceCategory(
      contextChoices &&
        contextChoices?.service_category &&
        contextChoices?.service_category
    );
    setVisitType(
      contextChoices &&
        contextChoices?.visit_type &&
        contextChoices?.visit_type
    );
    setMedicationDose(
      contextChoices &&
        contextChoices?.medication_dose &&
        contextChoices?.medication_dose
    );

    setLocation(
      contextChoices &&
        contextChoices?.visit_type &&
        contextChoices?.visit_type
    );

    setVisitStatus(
      contextChoices &&
        contextChoices?.visit_status &&
        contextChoices?.visit_status
    );

    setClientStatus(
      contextChoices &&
        contextChoices?.visit_status &&
        contextChoices?.visit_status
    );

    setUsersList(
      contextChoices &&
        contextChoices?.user_list &&
        contextChoices?.user_list
    );


    
  }, [contextChoices]);

  useEffect(() => {
    setLoadingButton(false);
    SetDownloadDisable(false);
    clearFilter()
    setActiveTab(tabClick);
    console.log(values)
  }, [tabClick]);

  const clearFilter = () => {
    setClientStatusFilter([])
    setRunRoutesFilter([])
    setBranchFilter([])
    setClientTypeFilter([])
    setServiceCategoryFilter([])
    setVisitTypeFilter([])
    setClientFilter([])
    setMedicationDoseFilter([])
    setLocationFilter([])
    setColumnSelectorFilter([])
    setVisitStatusFilter([])
    setDeletedByFilter([])
    setCheckRequires(false)
    setValues({})
  }

  const filterClientStatus = item => {
    setClientStatusFilter([...item]);
  };
  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const filterClientType = item => {
    setClientTypeFilter([...item]);
  };

  const setServiceCaregory = item => {
    setServiceCategoryFilter([...item]);
  };

  const setVisitTypeFill = item => {
    setVisitTypeFilter([...item]);
  };

  const setVisitStatusFill = item => {
    setVisitStatusFilter([...item]);
  };
  const setDeletedByFill = item => {
    setDeletedByFilter([...item]);
  };

  const setClient = item => {
    setClientFilter([...item]);
  };


  const setMedicationDoseFill = item => {
    setMedicationDoseFilter([...item]);
  };
  const setLocationFill = item => {
    setLocationFilter([...item]);
  };

  const setClientStatusFill = item => {
    setClientStatusFilter([...item]);
  };
  

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };

  const setColumnSelector = (item) => {
    setColumnSelectorFilter([...item])
  }
  const getEndDateValidation = (data) => {
    return moment(data).add(3, "month").format("YYYY-MM-DD")
  }
  const getStartDateValidation = (data) => {
    return moment(data).subtract(3, "month").format("YYYY-MM-DD")
  }
  console.log(tabClick, values, apiKey, "tabClick")
  return (
    <div>
      <Toast ref={toast}></Toast>
      {tabClick === 'Client_Care_Notes' ? (
        <NewVisitCare tabClick={tabClick} />
      ) : tabClick === 'ClientTaskReport' ? (
        <TaskFilter tabClick={tabClick} />
      ) : tabClick === 'ServiceContract' ?
        <ServiceContract tabClick={tabClick} />
        : (
          <div>
            <Header>
                  {(tabClick === 'Client Visit' || tabClick === 'Medication') ? (
                    <div className="d-flex ">
                      {/* <DropdownTypesContainer className="mr-2">
                        <div>
                          <DateSelector
                            typeFilter={true}
                            labelTop={tabClick === 'Medication' ? "Med. Start Date" : "Contract Start Date"}
                            isRequired
                            {...(tabClick === 'Medication'
                              ? medication_start_date_hook
                              : start_date_hook)}
                          />
                        </div>
                      </DropdownTypesContainer> */}
                      {/* <DropdownTypesContainer className="mr-2">
                        <div>
                          <DateSelector
                            typeFilter={true}
                            labelTop="End Date"
                            {...end_date_hook}
                          />
                        </div>
                      </DropdownTypesContainer> */}
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={branch ? branch : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ branch: items });
                          }}
                          itemsSelected={branchFilter}
                          setSelectedFilter={setFilterBranch}
                          placeHolder={'Branch'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>

                      
                      {tabClick === 'Client Visit' &&
                        <DropdownTypesContainer className="mr-2">
                          <NoSelectAllDropdownFilter
                            items={ serviceCategory || []}
                            setItemsSelected={items => {
                              dropDownHandleSelect({ service_category: items });
                            }}
                            itemsSelected={serviceCategoryFilter}
                            setSelectedFilter={setServiceCaregory}
                            placeHolder={'Service Category'}
                            isObject
                            allOption
                          />
                        </DropdownTypesContainer>}

                      {tabClick === 'Client Visit' &&
                        <DropdownTypesContainer className="mr-2">
                          <NoSelectAllDropdownFilter
                            items={visitType  || []}
                            setItemsSelected={items => {
                              dropDownHandleSelect({ visit_type: items });
                            }}
                            itemsSelected={visitTypeFilter}
                            setSelectedFilter={setVisitTypeFill}
                            placeHolder={'Visit Type'}
                            isObject
                            allOption
                          />
                        </DropdownTypesContainer>}

                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={clients || []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ client: items });
                          }}
                          itemsSelected={clientFilter}
                          setSelectedFilter={setClient}
                          placeHolder={'Clients'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>

                      {tabClick === 'Medication' &&
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={medicationDose || []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ medication_dose: items });
                          }}
                          itemsSelected={medicationDoseFilter}
                          setSelectedFilter={setMedicationDoseFill}
                          placeHolder={'Medication Dose'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>}

                      {tabClick === 'Client Visit' &&
                        <DropdownTypesContainer className="mr-2">
                          <NoSelectAllDropdownFilter
                            items={location || []}
                            setItemsSelected={items => {
                              dropDownHandleSelect({ location: items });
                            }}
                            itemsSelected={locationFilter}
                            setSelectedFilter={setLocationFill}
                            placeHolder={'Location'}
                            isObject
                            allOption
                          />
                        </DropdownTypesContainer>}

                      {
                        <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={clientStatus ? clientStatus : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ client_status: items });
                          }}
                          itemsSelected={clientStatusFilter}
                          setSelectedFilter={filterClientStatus}
                          placeHolder={'Client Status'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>}
                        
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={tabClick === 'Client Visit' ? clientVisitColumnSelecter:  tabClick === 'Medication'? medicationColumnSelecter : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ column_selector: items });
                          }}
                          itemsSelected={columnSelectorFilter}
                          setSelectedFilter={setColumnSelector}
                          placeHolder={'Select Column'}
                          isObject
                          allOption
                        // hitApiAfterClear={hitApiAfterClear}
                        />
                      </DropdownTypesContainer>
                
                    </div>
                     ) : (
                      tabClick == "Deleted Visit") ? (
                    <div className="d-flex flex-wrap">

                    <DropdownTypesContainer className="mr-2 mb-2">
                      <div>
                        <DateSelector
                          typeFilter={true}
                          isRequired={!values.visit_start_date}
                          labelTop="Deleted Start Date"
                          minDate={values.deleted_end_date && getStartDateValidation(values.deleted_end_date)}
                          maxDate={values.deleted_end_date && values.deleted_end_date }
                          {...deleted_date_hook}
                        />
                      </div>
                    </DropdownTypesContainer>

                    <DropdownTypesContainer className="mr-2 mb-2">
                      <div>
                        <DateSelector
                          typeFilter={true}
                          isRequired={!values.visit_start_date}
                          minDate={values.deleted_date && values.deleted_date}
                          maxDate={values.deleted_date && getEndDateValidation(values.deleted_date)}
                          labelTop="Deleted End Date"
                          {...deleted_end_hook}
                        />
                      </div>
                    </DropdownTypesContainer>

                    <DropdownTypesContainer className="mr-2 mb-2">
                      <div>
                        <DateSelector
                          typeFilter={true} 
                          isRequired={!values.deleted_date}
                          labelTop="Visit Start Date"
                          {...visit_start_date_hook}
                          minDate={values.visit_end_date && getStartDateValidation(values.visit_end_date)}
                          maxDate={values.visit_end_date && values.visit_end_date }
                        />
                      </div>
                    </DropdownTypesContainer>

                    <DropdownTypesContainer className="mr-2 mb-2">
                        <DateSelector
                          typeFilter={true}
                          isRequired={!values.deleted_date}
                          labelTop="Visit End Date"
                          {...visit_end_date_hook}
                          minDate={values.visit_start_date && values.visit_start_date}
                          maxDate={values.visit_start_date && getEndDateValidation(values.visit_start_date)}
                        />
                    </DropdownTypesContainer>
                    
                    {/* <DropdownTypesContainer className="mr-2 mb-2">
                      <div>
                        <DateSelector
                          typeFilter={true}
                          labelTop="End Date"
                          {...end_date_hook}
                        />
                      </div>
                    </DropdownTypesContainer> */}
                    <DropdownTypesContainer className="mr-2 mb-2">
                      <NoSelectAllDropdownFilter
                        typeFilter={true}
                        items={branch ? branch : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ branch: items });
                        }}
                        itemsSelected={branchFilter}
                        setSelectedFilter={setFilterBranch}
                        placeHolder={'Branch'}
                        isObject
                        allOption
                      />
                    </DropdownTypesContainer>

                      <DropdownTypesContainer className="mr-2 mb-2">
                        <NoSelectAllDropdownFilter
                          items={ serviceCategory || []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ service_category: items });
                          }}
                          itemsSelected={serviceCategoryFilter}
                          setSelectedFilter={setServiceCaregory}
                          placeHolder={'Service Category'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>

                      

                      <DropdownTypesContainer className="mr-2 mb-2">
                        <NoSelectAllDropdownFilter
                          items={visitStatus  || []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ visit_status: items });
                          }}
                          itemsSelected={visitStatusFilter}
                          setSelectedFilter={setVisitStatusFill}
                          placeHolder={'Visit Status'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>
                      
                    
                      <DropdownTypesContainer className="mr-2 mb-2">
                        <NoSelectAllDropdownFilter
                          items={usersList  || []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ deleted_by: items });
                          }}
                          itemsSelected={deletedByFilter}
                          setSelectedFilter={setDeletedByFill}
                          placeHolder={'Deleted By'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>

                      

                    <DropdownTypesContainer className="mr-2 mb-2">
                      <NoSelectAllDropdownFilter
                        items={clients || []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ client: items });
                        }}
                        itemsSelected={clientFilter}
                        setSelectedFilter={setClient}
                        placeHolder={'Clients'}
                        isObject
                        allOption
                      />
                    </DropdownTypesContainer>

                  
                    <DropdownTypesContainer className="mr-2 mb-2">
                      <NoSelectAllDropdownFilter
                        items={deletedVisitColumnSelecter  || []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ column_selector: items });
                        }}
                        itemsSelected={columnSelectorFilter}
                        setSelectedFilter={setColumnSelector}
                        placeHolder={'Select Column'}
                        isObject
                        allOption
                      // hitApiAfterClear={hitApiAfterClear}
                      />
                    </DropdownTypesContainer>
              
                  </div>
                
                ) : (
                    <div className="d-flex ">
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={clientTypes ? clientTypes : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ client_type: items });
                          }}
                          itemsSelected={clientTypeFilter}
                          setSelectedFilter={filterClientType}
                          placeHolder={'Client type'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={branch ? branch : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ branch: items });
                          }}
                          itemsSelected={branchFilter}
                          setSelectedFilter={setFilterBranch}
                          placeHolder={'Branch'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={clientStatus ? clientStatus : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ client_status: items });
                          }}
                          itemsSelected={clientStatusFilter}
                          setSelectedFilter={filterClientStatus}
                          placeHolder={'Client Status'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>
                      <DropdownTypesContainer className="mr-2">
                        <NoSelectAllDropdownFilter
                          items={runRouteData ? runRouteData : []}
                          setItemsSelected={items => {
                            dropDownHandleSelect({ runroutes: items });
                          }}
                          itemsSelected={runRoutesFilter}
                          setSelectedFilter={setRunroutefilter}
                          placeHolder={'Group Area'}
                          isObject
                          allOption
                        />
                      </DropdownTypesContainer>
                    </div>
                  )}
          </Header>

          
                <SearchAddContainer className="d-flex align-items-center">
                  <FloatingMobileButton
                    onClick={!downloadDisable && handleDownload}
                    disabled={downloadDisable}>
                    <label>Download List</label>
                    {loadingButton ? (
                      <Fragment>
                        <span
                          className="spinner-border spinner-border-sm mr-1 ml-2"
                          role="status"
                        />
                      </Fragment>
                    ) : (
                      <Fragment />
                    )}
                  </FloatingMobileButton>
                </SearchAddContainer>
        </div>
      )}
    </div>
  );
};

export default ClientFilters;
