import React, {Fragment,useContext,useEffect,useState} from 'react';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {PLUS, TIMES} from 'utils/constants/icons';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
import moment from 'moment'

const TravelModeRestrictions = ({
    index,
    values,
    data,
    addTravelModeRestriction,
    deleteTravelModeRestriction,
    useInput,
    travelType,
    dropDownHandleSelect=()=>{},
    timeRescrtictionHoursLength,
    setTimeRescrtictionHoursLength,
    timeRescrtictionHoursValue,
    setTimeRescrtictionHoursValue,
    timeRescrtictionMinuteLength,
    setTimeRescrtictionMinuteLength,
    timeRescrtictionMinuteValue,
    setTimeRescrtictionMinuteValue,
    erorTimeRescrtiction,
    setErorTimeRescrtiction,
    erorTimeRescrtictionMag,
    setErorTimeRescrtictionMag,
    setCheckBeforeSubmit,
    
//below is the props for  home-client time restrciction 
    homeTimeRescrtictionHoursLength,
    setHomeTimeRescrtictionHoursLength,
    homeTimeRescrtictionHoursValue,
    setHomeTimeRescrtictionHoursValue,
    homeTimeRescrtictionMinuteLength,
    setHomeTimeRescrtictionMinuteLength,
    homeTimeRescrtictionMinuteValue,
    setHomeTimeRescrtictionMinuteValue,
    homeErorTimeRescrtiction,
    setHomeErorTimeRescrtiction,
    homeErorTimeRescrtictionMag,
    setHomeErorTimeRescrtictionMag,
    setHomeCheckBeforeSubmit,
}) => {
    
    const[travelTimeInhrsMin,setTravelTimeInhrsMin]=useState();
    const[travelHomeTimeInhrsMin,setHomeTravelTimeInhrsMin]=useState();

useEffect(()=>{
    setTravelTimeInhrsMin(moment(values.ShiftTravelModeRestriction[index].travel_time, 'hh:mm').format('hh:mm'));
},[ values.ShiftTravelModeRestriction[index].travel_time ])

useEffect(()=>{
  setHomeTravelTimeInhrsMin(moment(values.ShiftTravelModeRestriction[index].home_client_restriction, 'hh:mm').format('hh:mm'));
},[ values.ShiftTravelModeRestriction[index].home_client_restriction ])

useEffect(()=>{
  setTravelModeHoursMinute();
},[
  values.ShiftTravelModeRestriction[index].travel_time,
])

useEffect(()=>{
  setHomeTravelModeHoursMinute();
},[
  values.ShiftTravelModeRestriction[index].home_client_restriction,
])

useEffect(()=>{
  if(values.ShiftTravelModeRestriction[index].travel_time && values.ShiftTravelModeRestriction[index].travel_time.toString().includes(':') == false  ){
    setCheckBeforeSubmit(true)
  }
},[  values.ShiftTravelModeRestriction[index].travel_time,])

useEffect(()=>{
  if(values.ShiftTravelModeRestriction[index].home_client_restriction && values.ShiftTravelModeRestriction[index].home_client_restriction?.toString().includes(':') == false  ){
    setHomeCheckBeforeSubmit(true)
  }
},[  values.ShiftTravelModeRestriction[index].home_client_restriction,])

const setTravelModeHoursMinute=()=>{

  var TimeSerializer =values.ShiftTravelModeRestriction[index].travel_time && values.ShiftTravelModeRestriction[index].travel_time.toString().split(':');

  if(TimeSerializer && TimeSerializer[0] && TimeSerializer[0] !== undefined){ 
    setTimeRescrtictionHoursLength( TimeSerializer[0].length) ;
    setTimeRescrtictionHoursValue( TimeSerializer[0]) ;
     }
  if(TimeSerializer && TimeSerializer[1] && TimeSerializer[1] !== undefined){ 
    setTimeRescrtictionMinuteLength(TimeSerializer[1].length);
    setTimeRescrtictionMinuteValue(TimeSerializer[1]);
     } 
  
  if( values.ShiftTravelModeRestriction[index].travel_time && values.ShiftTravelModeRestriction[index].travel_time .toString().includes(':')  ){
    setCheckBeforeSubmit(false)
    setErorTimeRescrtiction('');
    setErorTimeRescrtictionMag(null);
     }else if(  
      timeRescrtictionHoursLength>3  ||   timeRescrtictionHoursValue>999 ||  timeRescrtictionMinuteValue>59 ||   timeRescrtictionMinuteLength>2  || ( timeRescrtictionMinuteValue ==0 && timeRescrtictionMinuteLength <2   ) 
      ){
        setCheckBeforeSubmit(true);
         } 
  }
  
  const setHomeTravelModeHoursMinute=()=>{

    var HomeTimeSerializer =values.ShiftTravelModeRestriction[index].home_client_restriction && values.ShiftTravelModeRestriction[index].home_client_restriction?.toString().split(':');
  
    if(HomeTimeSerializer && HomeTimeSerializer[0] && HomeTimeSerializer[0] !== undefined){ 
      setHomeTimeRescrtictionHoursLength( HomeTimeSerializer[0].length) ;
      setHomeTimeRescrtictionHoursValue( HomeTimeSerializer[0]) ;
       }
    if(HomeTimeSerializer && HomeTimeSerializer[1] && HomeTimeSerializer[1] !== undefined){ 
      setHomeTimeRescrtictionMinuteLength(HomeTimeSerializer[1].length);
      setHomeTimeRescrtictionMinuteValue(HomeTimeSerializer[1]);
       } 
    
    if( values.ShiftTravelModeRestriction[index].home_client_restriction && values.ShiftTravelModeRestriction[index].home_client_restriction?.toString().includes(':')  ){
      setHomeCheckBeforeSubmit(false)
      setHomeErorTimeRescrtiction('');
      // setHomeErorTimeRescrtictionMag(null);
       }else if(  
        homeTimeRescrtictionHoursLength>3  ||    homeTimeRescrtictionHoursValue>999 ||   homeTimeRescrtictionMinuteValue>59 ||    homeTimeRescrtictionMinuteLength>2  || (  homeTimeRescrtictionMinuteValue ==0 &&  homeTimeRescrtictionMinuteLength <2   ) 
        ){
          setHomeCheckBeforeSubmit(true);
           } 
    }


  return (
    <div>
            
        <>
        <div key={index} className={`mt-1 `}>
               <div className='d-flex'>
                  <Fragment />
                  {/* {index!==0 &&  */}
                  <CloseButtonWrapper
                  onClick={() => {
                    deleteTravelModeRestriction(data, index);
                  }}>
                  {TIMES}
                </CloseButtonWrapper>
                  {/* } */}
       <div className="p-0  w-100 d-flex align-items-center  ">
     <FlexGrid3Container className={'w-100 mb-1 mt-1 '}  >
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
   <DropdownSearchable
          placeHolder={'Travel mode'}
          options={travelType?travelType:[]}
    
          selectedOption={ values.ShiftTravelModeRestriction[index] ?  values.ShiftTravelModeRestriction[index].travel_mode : null }
          onChange={value =>
          dropDownHandleSelect(
           {travel_mode: value ? value : null},  'travel_mode',index
            )  }
          />
   </FlexGrid3Item>
   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
   <InputLabelTop
     type="text"
      id="`travel_time_client"
    {...useInput(`ShiftTravelModeRestriction[${[index]}].travel_time`)}
    // label={'time restriction  (hh:mm) '}
    label={'client - client time restriction  (hh:mm) '}
    maxlength={6}
       />
   </FlexGrid3Item>

   <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
   <InputLabelTop
     type="text"
      id="`travel_time_home_client"
    //  placeholder={'hh:mm:ss'}
    {...useInput(`ShiftTravelModeRestriction[${[index]}].home_employee_restriction`)}
    // values={travelTimeInhrsMin}
    label={'home - client time restriction  (hh:mm) '}
    maxlength={6}
       />
   </FlexGrid3Item>
  
   </FlexGrid3Container>
   </div>
   </div>
   </div>

   
    </>
    </div>
  )
}

export default TravelModeRestrictions