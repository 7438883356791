import {
    LARGE_COL,
    SMALL_COL
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    
      {
        label: 'Funder Name',
        name: 'funder_name',
        status: 1,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Client Name',
        name: 'client__full_name',
        status: 1,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
    {
      label: 'Schedule Amount',
      name: 'visit__schedule_bill_amount',
      status: 1,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    
    {
        label: 'Actual Amount',
        name: 'actual_bill_rate_amount',
        status: 1,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
        label: 'Bill Pattern',
        name: 'bill_pattern',
        status: 1,
        custum_column: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
  ];

  export const headerColumnsSelector = [
    
    {
      name: 'Funder Name',
      id: 'funder_name',
    },
    {
      name: 'Client Name',
      id: 'client_name',
    },
  {
    name: 'Schedule Amount',
    id: 'schedule_amount',
  },
  {
      name: 'Actual Amount',
      id: 'actual_amount',
    },
    {
      name: 'Bill Pattern',
      id: 'bill_pattern',
    },
];

export const headerColumnsSelectorByDefault = [
    
  {
    name: 'Funder Name',
    id: 'funder_name',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
{
  name: 'Schedule Amount',
  id: 'schedule_amount',
},
{
    name: 'Actual Amount',
    id: 'actual_amount',
  },
];
  