import { useForm } from 'hooks/FormHook';
import React, { useState, useEffect, Fragment } from 'react';
import { getVisitCareNotesOnVisit } from 'utils/api/SchedulerApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import { Row, Col, IconWrapper } from 'components/SharedComponents/Table/styles';
import { LARGE_COL, SMALL_COL, MEDIUM_COL, EX_LARGE_COL, EX_VERY_LARGE_COL } from 'shared/styles/constants/columns';
import { AppContext, BranchItemsContext } from "context"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useContext } from 'react';
import moment from 'moment';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { client_visit_care_notes } from 'utils/choiceConstant';

 const headerColumns = [

    {
      label: 'Date', 
      name: 'visit__start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: SMALL_COL,
    },
    {
      label: 'Time',
      name: 'visit__start_date',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Employee Name',  
      name: 'care_notes_created_by__first_name',
      status: 0,
      className: 'grow-mobile',
      max_width: SMALL_COL,
    },
    {
      label: 'category',
      name: 'visit__client_service_visit__service_category__name',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Visit Type',
      name: 'visit__client_service_visit__visit_type__name',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: SMALL_COL,
    },
   
    {
      label: 'Visit Care Notes',
      name: 'care_notes',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'Privacy',
      name: 'Privacy',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'Labels',
      name: 'Labels',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
    
  ];

const CareNotes = ({
    visitId
}) => {

    const { values, setValues, useInput } = useForm({ limitperpage: PAGE_SIZE_RENDER });
    const [visitCareNotes, setVisitCareNotes] = useState([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [countCheck, setCoutCheck] = useState(0)
    const [loadingModal, setLoadingModal] = useState(true);
    const { contextChoices, handleGetChoices, contextChoicesMap } = useContext(AppContext);
    const [alertPrivacy, setAlertPrivacy] = useState([]);
    const [labels, setLabels] = useState([]);
    const [employeeName, setEmployeeName] = useState([]);
    const [userList, setUserList] = useState([]);
    const [visitType, setVisitType] = useState([]);
    const [serviceCategory, setServiceCategory] = useState([]);
    const [privacyName, setPrivacyName] = useState([])
    const schemaName = localStorage.getItem("schema_name");

    useEffect(() => {
      handleGetChoices(client_visit_care_notes)
    }, [])

    useEffect(() => {
        if (values.limitperpage !== 'undefined')
          loadVisitCareNotes()
      }, [
        values.limitperpage,
      ])

      useEffect(() => {

        setEmployeeName((contextChoices && contextChoices.employee_listing && contextChoices.employee_listing.map(y => {
          return { id: y && y.id, name: y && y.full_name }
        })))
    
        setLabels((contextChoices && contextChoices.alert_label && contextChoices.alert_label.map(y => {
          return { id: y && y.id, name: y && y.name }
        })))
    
        setAlertPrivacy((contextChoices && contextChoices.alert_privacy && contextChoices.alert_privacy.map(y => {
          return { id: y && y.id, name: y && y.name }
        })))
    
        setUserList((contextChoices && contextChoices.user_list && contextChoices.user_list.filter(data => data.schema_name == schemaName).map(y => {
          return { id: y && y.id, name: y && y.name }
        })
        ))
        setPrivacyName((contextChoices && contextChoices.alert_privacy && contextChoices.alert_privacy.map(y => {
          return { id: y && y.id, name: y && y.name }
        })))
        setVisitType((contextChoices && contextChoices.visit_type && contextChoices.visit_type.map(y => {
          return { id: y && y.id, name: y && y.name }
        })))
    
        setServiceCategory((contextChoices && contextChoices.service_category
          && contextChoices.service_category.map(y => {
            return { id: y && y.id, name: y && y.name }
          })))
    
      }, [contextChoices])

      const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
      };


    const loadVisitCareNotes = () => {
        getVisitCareNotesOnVisit({ ...values }, values.limitperpage, visitId).then(response => {
          if (response) {
            setLoadingModal(false);
          }
          setCoutCheck(response?.data?.length || 0)
          setVisitCareNotes(response.data || []);
        //   setPages(Math.ceil(response.count / values.limitperpage));
        });
      };


      const displayEmployeeName = (empId) => {
        if (empId) {
          let Name = userList?.find(userdata => userdata?.id === empId)?.name;
          return Name;
        } else {
          return
        }
      }
    
      const displayVisitType = (visitTypeId) => {
        if (visitTypeId) {
          let Name = visitType?.find(userdata => userdata.id === visitTypeId)?.name;
          return Name;
        } else {
          return
        }
      }
    
      const displayCategoryName = (catgoryId) => {
        if (catgoryId) {
          let Name = serviceCategory?.find(service => service.id === catgoryId)?.name;
          return Name;
        } else {
          return
        }
      }
      const displayPrivacyName = (PrivacyId) => {
        if (PrivacyId) {
          let Name = privacyName?.find(privacy => privacy.id === PrivacyId)?.name;
          return Name;
        } else {
          return
        }
      }
    
    
      const getStartEndTime = (starttime, endtime) => {
        let startTime = starttime ? moment(starttime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';
    
        let endTime = endtime ? moment(endtime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';
    
        let Time = startTime + '' + '-' + '' + endTime;
    
        return Time
      }
    
      const sliceString = string => {
        return string.length > 60 ? string.slice(0, 60) + '...' : string;
      }
      const mappedLabel = label => {
        return contextChoicesMap?.alert_label?.[label.label_id]?.name || "";
      }
      
    
  return (
   <>
   <div>
   <ComponentDynamic loading={loadingModal} >
        {countCheck !== 0 ? (
          <Table headerColumns={headerColumns} noEditOption callBackQuerySort={setQuerySort} headerPadding={false}  >
            <div>
              {visitCareNotes.map((notesData, index) => {
                return (
                  <div    >
                    <Row bgColor>

                      <Col max_width={SMALL_COL} Center
                        overlap={true}>
                        {notesData.notes_date}
                      </Col>
                      <Col max_width={SMALL_COL} Center
                        className='sm-hidden'
                        overlap={true}>
                        {getStartEndTime(notesData.visit_start_date && notesData.visit_start_date, notesData.visit_end_date && notesData.visit_end_date)}
                      </Col>
                      <Col max_width={SMALL_COL} Center
                        overlap={true}>
                        {displayEmployeeName(notesData.care_notes_created_by && notesData.care_notes_created_by)}
                      </Col>
                      <Col max_width={SMALL_COL} Center
                        className='sm-hidden md-hidden'
                        overlap={true}>
                        {displayCategoryName(notesData.service_category && notesData.service_category)}

                      </Col>
                      <Col max_width={MEDIUM_COL} Center
                        className='sm-hidden md-hidden'
                        overlap={true}>

                        {displayVisitType(notesData.visit_type && notesData.visit_type)}
                      </Col>
                      <Col max_width={EX_VERY_LARGE_COL} CentervisitCareNotes
                        className='sm-hidden md-hidden'
                        overlap={true}>
                        {notesData.care_notes}
                      </Col>
                      <Col max_width={EX_VERY_LARGE_COL} CentervisitCareNotes
                        className='sm-hidden md-hidden'
                        overlap={true}>
                        {displayPrivacyName(notesData.notes_privacy && notesData.notes_privacy)}
                      </Col>
                      <Col max_width={EX_VERY_LARGE_COL} CentervisitCareNotes
                        className='sm-hidden md-hidden'
                        overlap={true}>
                        {notesData.care_notes_label.map(mappedLabel).join(", ")}
                      </Col>

                      {/* <Col className="" Center Icon Purple>
                        <IconWrapper
                          onClick={() => {
                            handleRowClick(notesData.id && notesData.id);
                          }}>
                          <MdEdit />
                        </IconWrapper>
                      </Col> */}

                      {/* <Col className="" Center Shrink NoFlexGrow>
                        <IconWrapper
                          onClick={() => {
                            handleDeleteRow(notesData.id && notesData.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </Col> */}

                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              {/* <div className="mt-3">
                <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={visitCareNotes.length} />
              </div> */}
            </div>
          </Table>
        ) : <TitleSection style={{textAlign:"center"}}>No data found</TitleSection>}
      </ComponentDynamic>
   </div>
   </>
  );
}

export default CareNotes;
