import React from 'react';
import Modal from 'react-bootstrap4-modal';
import {Title} from './styles';
import Spinner from "../../../../SharedComponents/Spinner";
import {LoadingContainer} from "../../../ModalDynamic/styles";

//add hideOnCancel false for show cancel button
function LoadingModal({
                        title,
                        showModal,
                      }) {
  return (
    <Modal visible={showModal}>
      <div className="modal-body">
        <LoadingContainer maxHeight={'10rem'}>
          <Spinner />
        </LoadingContainer>
      </div>
    </Modal>
  );
}


export default LoadingModal;
