import React,{useState,useEffect,Fragment} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    ErrorMessage
  } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import { KeyPress } from 'utils/constants/regularExpressions';


const SwapEmployeeRun = ({
    useInput,
    values,
    errorMessage,
    dataValues,
    dropDownHandleSelect,
    newRun,
    modalLoading,
    setModalLoading,
    fullBind,
    handleSaveVisitSwapRun,
    saveButtonIcon,
    loadingSave,
    textStatus,
    dropDownHandleRegVisit,
    calculateEndValueProps,
    switchEndValueInput,
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Visit Run',
    });
    setModalLoading(false)
  }, [fullBind]);


  const switchRunNameInput = (props, start, end, every, repeat, end_type, end_value) => {
    if (newRun) {
     return( 
       <>
       <div className="pl-3 pr-3 pb-3 pt-0">
      <InputLabelTop
      required
      readOnly={false}
      type="text"
      id="run_name"
      label={'Run Name'}
      {...props}
      maxlength={100}
     />
  </div>
  <FlexGrid3Container className="pl-3 pr-3">
  <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
    <DateSelector
      //   disabled={readOnly}
     
      // data-error={dateError}
      errorMessage={errorMessage}
      labelTop={'Service start date'}
      {...start}
    />
  </FlexGrid3Item>
  <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
    <DateSelector
      //   disabled={readOnly}
      // data-error={dateError}
      errorMessage={errorMessage}
      labelTop={'Service end date'}
      {...end}
    />
  </FlexGrid3Item>
  <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
    <DropdownSearchable
      {...every}
      placeHolder={'Repeat Every'}
      options={[
        { id: EVERY_DAY, name: 'Day' },
        { id: EVERY_WEEK, name: 'Week' },
        { id: EVERY_MONTH, name: 'Month' },
        { id: EVERY_YEAR, name: 'Year' },
      ]}
      selectedOption={
        values
          ? values.every
          : null
      }
      onChange={value =>
        dropDownHandleRegVisit(
          { every: value ? value : null },
          'every'
        )
      }
    />
  </FlexGrid3Item>
  <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
    <InputLabelTop
      //   disabled={readOnly}
      label={'Repeat No.'}
      onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
      id={`repeat`}
      {...repeat}
      maxlength={5}
    />
  </FlexGrid3Item>
  <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
    <DropdownSearchable
     {...end_type}
      // data_error={errors.indexOf(`end_type`)}
      //   disabled={readOnly}
      placeHolder={'End'}
      options={[
        { id: END_TYPE_ON, name: 'On' },
        { id: END_TYPE_AFTER, name: 'After' },
        { id: END_TYPE_NEVER, name: 'Never' },
      ]}
      selectedOption={
        values
          ? values.end_type
          : null
      }
      onChange={value =>
        dropDownHandleRegVisit(
          { end_type: value ? value : null },
          'end_type'
        )
      }
    />
  </FlexGrid3Item>
  <FlexGrid3Item className="pl-1 mt-0 md-2 pr-0 pr-md-1">
    {switchEndValueInput({ ...end_value })}
  </FlexGrid3Item>
</FlexGrid3Container>
       </>
     )
    } else {
      return(
        <Fragment />
      )
    }
  };
  
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={modalLoading}>
        <div>
          <div className="pl-3 pr-3 pb-3 pt-0">
            <DropdownSearchable
             required
             {...useInput(`run_name_id`, 'isRequired')}
              placeHolder={'Select Run Name'}
              options={dataValues}
              selectedOption={values.run_name_id ? values.run_name_id : (newRun ? 'new' : '')}
              onChange={value =>
                dropDownHandleSelect({ run_name_id: value ? value : null })
              }
            />
          </div>
          {switchRunNameInput({ ...useInput('run_name', 'isRequired') },{...useInput(`start_date`)}, {...useInput(`end_date`)},{...useInput(`every`)},{...useInput(`repeat`, 'isInt')}, {...useInput(`end_type`)}, {...useInput(
        `end_value`,
        calculateEndValueProps(
          values.end_type
        )
      )} )}
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ml-0 ml-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveVisitSwapRun()} >
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default SwapEmployeeRun;
