import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons'
import { getTravelCommuteList, updateTravelCommute } from 'utils/api/SettingsApi'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons'
import "./style.css"
import { Toast } from 'primereact/toast'
import { LoadingContainer } from 'shared/styles/constants/tagsStyles'
import Spinner from 'components/SharedComponents/Spinner'
import { useRef } from 'react'
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles'
import { ERROR, SAVE, SAVED } from 'utils/constants/messages'

const SUCCESS_MESSAGE = "Payroll Non-Commute travel setting updated successfully!"
const ERROR_MESSAGE = "Something went wrong!"

function PayrollNonCommute({
    branch,
    choices,
    readPermission,
    readOnly,
    deletePermission
}) {
    const toast = useRef(null)
    const [isLoading, setIsLoading] = useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [rowData, setRowData] = useState({})
    const [loadingSave, setLoadingSave] = useState(false);
    const [traveSettings, setTraveSettings] = useState([
        { name: "Home-Client", travelTime: false, travelMileage: false },
        { name: "Home-Office", travelTime: false, travelMileage: false },
        { name: "Office-Client", travelTime: false, travelMileage: false },
        { name: "Office-Home", travelTime: false, travelMileage: false },
        { name: "Office-Office", travelTime: false, travelMileage: false },
        { name: "Client-Client", travelTime: false, travelMileage: false },
        { name: "Client-Office", travelTime: false, travelMileage: false },
        { name: "Client-Home", travelTime: false, travelMileage: false },
    ])

    const getPayrollNonCommute = async () => {
        try {
            const res = await getTravelCommuteList({ ...branch })
            updateTravelSettingState(res?.results[0])
            setRowData(res?.results[0])
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPayrollNonCommute()
    }, [])
    const splitName = (name) => {
        const arr = name.split("-")
        let n = arr[0].toLowerCase() + '_to_' + arr[1].toLowerCase()
        return n
    }

    const updateTravelSettingState = (item) => {
        let data = traveSettings.map(setting => ({
            ...setting,
            travelTime: item[`${splitName(setting.name)}_travel`] || false,
            travelMileage: item[`${splitName(setting.name)}_milage`] || false
        }))
        setTraveSettings(data)
    }

    const handleChange = (name, value, index) => {
        traveSettings[index][name] = !value
        setTraveSettings([...traveSettings])
    }

    const updateTravelCommuteSetting = async () => {
        if (loadingSave) {
            return;
        }
        

        const selectedId = rowData.id
        const body = { ...rowData }

        traveSettings.map(setting => {
            body[`${splitName(setting.name)}_travel`] = setting.travelTime
            body[`${splitName(setting.name)}_milage`] = setting.travelMileage
        })
        setLoadingSave(true)
        try {
            const res = await updateTravelCommute(body, selectedId);
            if (Object.keys(res).length > 0) {
                getPayrollNonCommute()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: SUCCESS_MESSAGE,
                })
                onSuccess()
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: ERROR_MESSAGE,
                });
            }

        } catch (error) {
            onError(error)
        }
    }
    const onSuccess = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
    }

    const onError = err => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
    };

    return (
        <>{readPermission ? (
            <div style={{ width: "100%", marginTop: "9rem" }}>
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingContainer maxHeight={null}>
                        <Spinner />
                    </LoadingContainer>)
                    : (
                        <div
                            style={{ padding: "0 1rem 1rem 1rem" }}
                            className='payroll-non-commute'>
                            <div style={{ width: "100%" }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <td className='header-text'>Non Commute Travel</td>
                                            <td className='header-text'>Travel Mileage</td>
                                            <td className='header-text'>Travel Time</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            traveSettings.map((item, index) => {
                                                return (<tr>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <ToggleWrapper>
                                                            <Toggle
                                                                id={`travel-mileage-${index}`}
                                                                type="checkbox"
                                                                checked={item.travelMileage}
                                                                onClick={() => {
                                                                    handleChange('travelMileage', item.travelMileage, index)
                                                                }}
                                                                disabled={readOnly}
                                                            />
                                                            <ToggleLabel htmlFor={`travel-mileage-${index}`} />
                                                        </ToggleWrapper>
                                                    </td>
                                                    <td>
                                                        <ToggleWrapper>
                                                            <Toggle
                                                                id={`travel-time-${index}`}
                                                                type="checkbox"
                                                                checked={item.travelTime}
                                                                onClick={() => {
                                                                    handleChange('travelTime', item.travelTime, index)
                                                                }}
                                                                disabled={readOnly}
                                                            />
                                                            <ToggleLabel htmlFor={`travel-time-${index}`} />
                                                        </ToggleWrapper>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>


                                </table>
                            </div>
                            <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
                                <div className={'ml-0 ml-md-3 mr-2'}>
                                    <PrimaryButtonForm
                                        minWidth="6rem"
                                        disabled={readOnly}
                                        onClick={() => updateTravelCommuteSetting()}
                                        style={{ marginBottom: '5px' }}>
                                        <span className={'mr-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            buttonIcon
                                        ) : (
                                            <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        ) : (
            <div className='d-flex w-100 justify-content-center'>
                <NoPermissionContainer>
                    You don't have permission to read the information.
                </NoPermissionContainer>
            </div>
        )
        }
        </>
    )
}


export default PayrollNonCommute