import React, { useState, useEffect, useRef, useContext } from 'react';
import CommonModelData from '../CommonModelData'
import { getRateCard, } from 'utils/api/SettingsApi';
import { useModal } from 'hooks/ModalHook';
import { AppContext } from "context"
import { useForm } from 'hooks/FormHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_TWELVE_RENDER, PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import { getCategoryPermission, getPermissions } from '../../../../../utils/localStorage/user';
import {
  RATE_CARD,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions'
import { getAdmin, getSuperAdmin } from "../../../../../utils/localStorage/token"
import { getStaff, getClient, getEmployee, getSubSectionPermissions } from '../../../../../utils/localStorage/user';
import { useHistory, useLocation } from 'react-router-dom';

const PayRate = props => {
  const {
    readPermissionPayRates,
    createPermissionPayRates,
    updatePermissionPayRates,
    deletePermissionPayRates
  } = props;
  const { values, setValues, useInput, setCheckRequires } = useForm({});  //used useInput
  const { contextChoices, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const [rateCard, setRateCard] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [deleteValue, setDeleteValue] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [updatePermissions, setUpdatePermissions] = useState(false);
  const [createPermissions, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [payCard, setPayCard] = useState([]);
  const [rateCardID, setRateCardID] = useState();
  const [numberValue, setNumberValue] = useState();
  const [loadingPayCardModal, setLoadingPayCardModal] = useState(true);
  const mountedRef = useRef(true);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const fullNameCol = 'name';

  const history = useHistory();

  useEffect(() => {

    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])


  useEffect(() => {
    if (contextChoices) {
      let rateCardType = contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype => ratetype.name == 'Pay Rates').id;
      setRateCardID(rateCardType);
      if (rateCardType) {
        loadRateCard(rateCardType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices]);


  useEffect(() => {
    if (rateCardID) {
      loadRateCard(rateCardID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, values.ordering, values.limitperpage]);


  const rateCardType = contextChoices.rate_card_type && contextChoices.rate_card_type.find(ratetype => ratetype.name == 'Pay Rates').id;


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage; return {
      offset,
    };
  };

  const loadRateCard = (rateCardType) => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    if (rateCardType) {
      getRateCard({ ...values, ...pageToSearch }, values.limitperpage, rateCardType).then(response => {
        if (!mountedRef.current) return null;
        if (response) {
          setLoadingPayCardModal(false);
        }
        setPayCard(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
      });
    }
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/rate-card/pay-rate",
      search: stringified,
    });

    values.limitperpage = item[namesection]
    setValues({ ...values });
  }


  return (
    <div>
      <CommonModelData
        addRateCardName={'NEW PAY  RATE CARD'}
        listigData={payCard}
        pages={pages}
        currentPage={currentPage}
        setQuerySort={setQuerySort}
        modalValue={modalValue}
        idSelected={idSelected}
        setModalValue={setModalValue}
        setRateCardModalValue={setModalValue}
        fullBind={fullBind}
        setTitle={setTitle}
        setIdSelected={setIdSelected}
        values={values}
        nameSection={'payValue'}
        useInput={useInput}
        loadRateCard={loadRateCard}
        setValues={setValues}
        setModalDeleteValue={setModalDeleteValue}
        setCheckRequires={setCheckRequires}
        bindModalDelete={bindModalDelete}
        addCard={'New Pay Rate Card'}
        editCard={'Edit Pay Rate Card'}
        copiedCard={'Copied Pay Rate Card'}
        formObjName={'payValue'}
        firstLayerAddButton={'ADD Rate Details'}
        insideFirstLayerAddButton={'ADD Rate '}
        rateCardType={rateCardType}
        setIdToDelete={setIdToDelete}
        idToDelete={idToDelete}
        setDeleteValue={setDeleteValue}
        deleteValue={deleteValue}
        dropDownHandleSelectPagination={dropDownHandleSelectPagination}
        loadingModal={loadingPayCardModal}
        isPayRate={true}
        readPermission={readPermissionPayRates}
        createPermission={createPermissionPayRates}
        updatePermission={updatePermissionPayRates}
        deletePermission={deletePermissionPayRates}
      />

    </div>
  )
}

export default PayRate