import {LARGE_COL,SMALL_COL} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Full Name',
    name: 'full_name',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Contact Type',
    name: 'contact_type',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Contact Status',
    name: 'contact_status',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
 
  {
    label: 'Phone no.',
    name: 'funder_contact_phone_number',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Email',
    name: 'funder_contact_email',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  
];
