import { BLACK_900, GRAY_100, GRAY_200, PURPLE } from "shared/styles/constants/colors";
import styled from "styled-components";

export const UserImgWrapper = styled.div`
  background-color: ${GRAY_100};
  border: 0.0625rem solid ${GRAY_200};
  border-radius: 0.25rem;
  height: 100%;
  /* margin-bottom: 0.5rem; */
  /* margin-right: 0.5rem; */
  padding: 0.5rem;

  @media (max-width: 575px) {
    margin-right: 0rem;
  }
`;

export const UserImgContainer = styled.div`
  position: relative !important;
  border-radius: 50%!important;
  -webkit-box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900}!important;
  box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900}!important;

  overflow: hidden!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  width: 4.8rem!important;
  height: 4.8rem!important;
  max-width: 4.8rem!important;
  max-height: 4.8rem!important;
  img {
    width: 4.8rem!important;
    height: 4.8rem!important;
  }
  background: ${GRAY_200}!important;
  div {
    color: ${PURPLE}!important;
    width: 100%!important;
    height: 100%!important;
  }
`;

export const CommentFileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 53%;
  label {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  svg {
    color: green;
    margin-right: 0.2rem;
  }
`;
export const UploadPhotoContainer = styled.div`
  svg {
    margin-right: 0.25rem;
  }
  label {
    cursor: pointer;
    font-size: 0.875rem;
    margin: 0;
  }
  &:hover {
    font-weight: 500;
  }
`;