import React from 'react';
import Template from 'components/SharedComponents/SettingsTemplate';
import {useContext, useEffect, useState, useRef} from 'react';
import {AppContext} from 'context';
import {
  CLIENT_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import {
  AccordionArrow,
  AccordionContainer,
  StaticArrow,
} from 'components/DashboardComponents/LeftSidebar/styles';
import {
  TitleSection,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import {PURPLE} from 'shared/styles/constants/colors';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import CustomTemplate from 'components/SharedComponents/CustomComponent/SettingsTemplate';
import {useModal} from 'hooks/ModalHook';
import CustomMedDoseTemplate from 'components/SharedComponents/CustomMedDoseTemplate';
import {
  getMedConditions,
  setMedCondition,
  deleteMedCondition,
  setAllergySource,
  getAllergySource,
  deleteAllergySource,
  getHealthSafty,
  setHealthSafty,
  deleteHealthSafty,
  getFluVaccination,
  deleteFluVaccination,
  setFluVaccination,
  getDisability,
  deleteDisability,
  setDisability,
  getInfectionControl,
  getOrganDonation,
  getDNACPR,
  deleteDNACPR,
  setDNACPR,
  getVaccinationExemptionType,
  deleteVaccinationExemptionType,
  setVaccinationExemptionType,
  deleteInfectionControl,
  setInfectionControl,
  getSignSymptoms,
  deleteSignSymptoms,
  setSignSymptoms,
  setOrganDonation,
  deleteOrganDonation,
  getAgeBind,
  deleteAgeBind,
  setAgeBind,
  getRotaRag,
  deleteRotaRag,
  setRotaRag,
  getClientVra,
  deleteClientVra,
  setClientVra,
  getSupportLevel,
  deleteSupportLevel,
  setSupportLevel,
  getMedicationName,
  setMedicationName,
  deleteMedicationName,
  getMadicationDose,
  deleteMedicationDose,
  setMedicalDose,
  getMadicationType,
  deleteMedicationType,
  setMedicalType,
  getMadicationStatus,
  deleteMedicationStatus,
  setMedicalStatus,
  getMadicationAssistance,
  deleteMedicationAssistance,
  setMedicalAssistance,
  getAdministrationType,
  deleteAdministrationType,
  setAdministrationType,
  getAdministrationReason,
  deleteAdministrationReason,
  setAdministrationReason,
  getExpiryMedication,
  deleteExpiryMedication,
  setExpiryMedication,
  getMadicationErrorType,
  deleteMedicationErrorType,
  setMedicalError,
  getMadicationFrequency,
  getMadicationForm,
  deleteMedicationForm,
  setMedicalForm,
  getMadicationRoute,
  deleteMedicationRoute,
  setMedicalRoute,
  getMadicationAlert,
  deleteMedicationAlert,
  setMedicalAlert,
  getMadicationDoseTiming,
  setMedicalDoseTiming,
  deleteMedicationDoseTiming,
} from 'utils/api/SettingsApi';

import MedicationNameModal from 'components/SharedComponents/CustomComponent/SettingsTemplate/MedicationName';
import MedicationDelete from 'components/SharedComponents/CustomComponent/SettingsTemplate/MedicationDelete';
import CustomMedicationTiming from 'components/SharedComponents/CustomMedicationTiming';

import { SettingsGridContainer, SettingsTemplateContainer } from 'components/SharedComponents/SettingsTemplate/styles';

const Medications = ({handleChangeChoices}) => {
  const [hideMedicationName, setHideMedicationName] = useState(true);
  const [hideMedicationError, setHideMedicationError] = useState(true);
  const [isCheckedId, setCheckedId] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const [hideMedicationDose, setHideMedicationDose] = useState(true);
  const [hideMedicationTiming, setHideMedicationTiming] = useState(true);

  const [hideMedicationType, setHideMedicationType] = useState(true);

  const [hideMedicationStatus, setHideMedicationStatus] = useState(true);
  const [hideMedicationAlert, setHideMedicationAlert] = useState(true);
  const [testingData, setTestingData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [hideMedicationAssistance, setHideMedicationAssistance] =
    useState(true);
  const [hideAdministrationType, setHideAdministrationType] = useState(true);
  const [hideAdministrationReason, setHideAdministrationReason] =
    useState(true);
  const [hideExpiryMedication, setHideExpiryMedication] = useState(true);
  const [hideMedicationForm, setHideMedicationForm] = useState(true);
  const [hideMedicationRoute, setHideMedicationRoute] = useState(true);

  const {
    contextChoices: choices,
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  const mountedRef = useRef(true);

  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setMedicationNameModal,
    value: medicationNameModals,
    setTitle: medicationTitles,
    fullBind: medicationNameBinds,
  } = useModal(false);
  const {
    setValue: setMedicationNameDelete,
    value: medicationNameModalDelete,
    setTitle: medicationTitlesDelete,
    fullBind: medicationNameBindDelete,
  } = useModal(false);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {});
  const getFunction = func => {};
  useEffect(() => {
    if (getFunction) {
      getFunction();
    }
  }, [apiCall]);

  const handleMedicationName = () => {
    setHideMedicationName(!hideMedicationName);
  };

  const handleMedicationDose = () => {
    setHideMedicationDose(!hideMedicationDose);
  };
  const handleMedicationTiming = () => {
    setHideMedicationTiming(!hideMedicationTiming);
  };
  const handleMedicationType = () => {
    setHideMedicationType(!hideMedicationType);
  };

  const handleMedicationStatus = () => {
    setHideMedicationStatus(!hideMedicationStatus);
  };

  const handleMedicationAssistance = () => {
    setHideMedicationAssistance(!hideMedicationAssistance);
  };
  const handleAdministrationType = () => {
    setHideAdministrationType(!hideAdministrationType);
  };
  const handleAdministrationReason = () => {
    setHideAdministrationReason(!hideAdministrationReason);
  };
  const handleExpiryMedication = () => {
    setHideExpiryMedication(!hideExpiryMedication);
  };
  const handleMedicationError = () => {
    setHideMedicationError(!hideMedicationError);
  };
  const handleMedicationForm = () => {
    setHideMedicationForm(!hideMedicationForm);
  };
  const handleMedicationRoute = () => {
    setHideMedicationRoute(!hideMedicationRoute);
  };

  const handleMedicationAlert = () => {
    setHideMedicationAlert(!hideMedicationAlert);
  };
  return (
      <SettingsGridContainer>
        <SettingsTemplateContainer collapsed={hideMedicationName}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationName}>
              <div>
                {hideMedicationName ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Name</TitleSection>
          </AccordionContainer>
          {!hideMedicationName && (
            <CustomTemplate
              getFunction={getFunction}
              setApiCall={setApiCall}
              setMedicationNameModal={setMedicationNameModal}
              setMedicationNameDelete={setMedicationNameDelete}
              apiCall={apiCall}
              setIsEdit={setIsEdit}
              testingData={testingData}
              setCheckedId={setCheckedId}
              setTestingData={setTestingData}
              setMedicationModals={setMedicationNameModal}
              titleSection={'Medication Name '}
              labelInputTitle={'Medication Name'}
              getMethod={getMedicationName}
              deleteMethod={deleteMedicationName}
              setMethod={setMedicationName}
              textToMessageDelete={'Medication Name'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() => handleChangeChoices('medication_name')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
              addOneInput={{
                label: 'Supplier Brand Name',
                required: true,
                value: 'supplier_brand_name',
              }}
            />
          )}
        </SettingsTemplateContainer>
        {!medicationNameModals ? null : (
          <MedicationNameModal
            setApiCall={setApiCall}
            testingData={testingData}
            setModalValue={setMedicationNameModal}
            isCheckedId={isCheckedId}
            // enabledInput={enabledInput}
            setModalValues={() => {
              setMedicationNameModal(!medicationNameModals);
            }}
            fullBind={medicationNameBinds}
          />
        )}
        {!medicationNameModalDelete ? null : (
          <MedicationDelete
            setApiCall={setApiCall}
            testingData={testingData}
            setModalValue={setMedicationNameDelete}
            isEdit={isEdit}
            isCheckedId={isCheckedId}
            setModalValues={() => {
              setMedicationNameDelete(!medicationNameModalDelete);
            }}
            fullBind={medicationNameBindDelete}
          />
        )}
        <SettingsTemplateContainer collapsed={hideMedicationDose}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationDose}>
              <div>
                {hideMedicationDose ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Dose</TitleSection>
          </AccordionContainer>
          {!hideMedicationDose && (
            <Template
              titleSection={'Medication Dose '}
              labelInputTitle={'Medication Dose'}
              getMethod={getMadicationDose}
              deleteMethod={deleteMedicationDose}
              setMethod={setMedicalDose}
              textToMessageDelete={'Medication Dose'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() => handleChangeChoices('medication_dose')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
              addOneInput={{
                label: 'value',
                required: true,

                value: 'count_deduction',
              }}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationTiming}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationTiming}>
              <div>
                {hideMedicationTiming ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication dose timing</TitleSection>
          </AccordionContainer>
          {!hideMedicationTiming && (
            <CustomMedicationTiming
              getFunction={getFunction}
              titleSection={'Medication dose timing '}
              labelInputTitle={'medication dose timing'}
              getMethod={getMadicationDoseTiming}
              deleteMethod={deleteMedicationDoseTiming}
              setMethod={setMedicalDoseTiming}
              textToMessageDelete={'medication dose timing'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication_dose_timing')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
              addOneInput={{
                label: 'Start Time',
                required: true,

                value: 'start_time',
              }}
              addSecondInput={{
                label: 'End time',
                value: 'end_time',
                required: true,
              }}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationType}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationType}>
              <div>
                {hideMedicationType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Type</TitleSection>
          </AccordionContainer>
          {!hideMedicationType && (
            <Template
              titleSection={'Medication Type '}
              labelInputTitle={'Medication Type'}
              getMethod={getMadicationType}
              deleteMethod={deleteMedicationType}
              setMethod={setMedicalType}
              textToMessageDelete={'Medication Type'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() => handleChangeChoices('medication_type')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationError}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationError}>
              <div>
                {hideMedicationError ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Error Type</TitleSection>
          </AccordionContainer>
          {!hideMedicationError && (
            <Template
              titleSection={'Medication Error Type '}
              labelInputTitle={'Medication Error Type'}
              getMethod={getMadicationErrorType}
              deleteMethod={deleteMedicationErrorType}
              setMethod={setMedicalError}
              textToMessageDelete={'Medication Error Type'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication-error-type')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationStatus}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationStatus}>
              <div>
                {hideMedicationStatus ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Status</TitleSection>
          </AccordionContainer>
          {!hideMedicationStatus && (
            <CustomMedDoseTemplate
              titleSection={'Medication Status '}
              labelInputTitle={'name'}
              getMethod={getMadicationStatus}
              deleteMethod={deleteMedicationStatus}
              setMethod={setMedicalStatus}
              textToMessageDelete={'Medication Status'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication_status')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
              addCheckBox={{
                label: 'Reason',

                value: 'is_reason',
              }}
              addSecondInput={{
                label: 'Color Code',
                value: 'color_code',
              }}

            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={hideMedicationAssistance}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationAssistance}>
              <div>
                {hideMedicationAssistance ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Assistance</TitleSection>
          </AccordionContainer>
          {!hideMedicationAssistance && (
            <Template
              titleSection={'Medication Assistance '}
              labelInputTitle={'Medication Assistance'}
              getMethod={getMadicationAssistance}
              deleteMethod={deleteMedicationAssistance}
              setMethod={setMedicalAssistance}
              textToMessageDelete={'Medication Assistance'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication_assistance')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideAdministrationType}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleAdministrationType}>
              <div>
                {hideAdministrationType ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Administration Type</TitleSection>
          </AccordionContainer>
          {!hideAdministrationType && (
            <Template
              titleSection={'Administration Type '}
              labelInputTitle={'Administration Type'}
              getMethod={getAdministrationType}
              deleteMethod={deleteAdministrationType}
              setMethod={setAdministrationType}
              textToMessageDelete={'Administration Type'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('administration_type')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideAdministrationReason}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleAdministrationReason}>
              <div>
                {hideAdministrationReason ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Administration Reason </TitleSection>
          </AccordionContainer>
          {!hideAdministrationReason && (
            <Template
              titleSection={'Administration Reason '}
              labelInputTitle={'Administration Reason'}
              getMethod={getAdministrationReason}
              deleteMethod={deleteAdministrationReason}
              setMethod={setAdministrationReason}
              textToMessageDelete={'Administration Reason'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('administration_reason')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideExpiryMedication}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleExpiryMedication}>
              <div>
                {hideExpiryMedication ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Expiry Medication Reason </TitleSection>
          </AccordionContainer>
          {!hideExpiryMedication && (
            <Template
              titleSection={'Expiry Medication Reason '}
              labelInputTitle={'Expiry Medication Reason'}
              getMethod={getExpiryMedication}
              deleteMethod={deleteExpiryMedication}
              setMethod={setExpiryMedication}
              textToMessageDelete={'Expiry Medication Reason'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('expiry_medication_reason')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationForm}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationForm}>
              <div>
                {hideMedicationForm ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Form</TitleSection>
          </AccordionContainer>
          {!hideMedicationForm && (
            <Template
              titleSection={'Medication Form '}
              labelInputTitle={'Medication Form'}
              getMethod={getMadicationForm}
              deleteMethod={deleteMedicationForm}
              setMethod={setMedicalForm}
              textToMessageDelete={'Medication Form'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() => handleChangeChoices('medication_form')}
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
        <SettingsTemplateContainer collapsed={hideMedicationRoute}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationRoute}>
              <div>
                {hideMedicationRoute ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Route</TitleSection>
          </AccordionContainer>
          {!hideMedicationRoute && (
            <Template
              titleSection={'Medication Route '}
              labelInputTitle={'Medication Route'}
              getMethod={getMadicationRoute}
              deleteMethod={deleteMedicationRoute}
              setMethod={setMedicalRoute}
              textToMessageDelete={'Medication Route'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication_route')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>

        <SettingsTemplateContainer collapsed={hideMedicationAlert}>
          <AccordionContainer style={{marginLeft: '40px'}}>
            <AccordionArrow onClick={handleMedicationAlert}>
              <div>
                {hideMedicationAlert ? (
                  <IoIosArrowDown size={18} color={PURPLE} />
                ) : (
                  <IoIosArrowUp size={18} color={PURPLE} />
                )}
              </div>
            </AccordionArrow>
            <TitleSection>Medication Alert</TitleSection>
          </AccordionContainer>
          {!hideMedicationAlert && (
            <Template
              titleSection={'Medication Alert '}
              labelInputTitle={'Medication Alert'}
              getMethod={getMadicationAlert}
              deleteMethod={deleteMedicationAlert}
              setMethod={setMedicalAlert}
              textToMessageDelete={'Medication Alert'}
              fieldAttributes={{maxLength: 50}}
              handleChangeChoices={() =>
                handleChangeChoices('medication_alert')
              }
              updatePrioritySettings={true}
              createPrioritySettings={true}
              deletePermissionPrioritySettings={true}
              readPermissionPrioritySettings={true}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </SettingsTemplateContainer>
      </SettingsGridContainer>
  );
};

export default Medications;