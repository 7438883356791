import React, {useEffect, useState, useRef, useContext} from 'react';
import {useModal} from 'hooks/ModalHook';
import {useHistory} from 'react-router-dom';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {getAdmin, getSuperAdmin} from '../../../../utils/localStorage/token';
//import Funder from '../../Settings/SettingsClient/Funder'
import {
  getSubSectionPermissions,
  getStaff,
} from '../../../../utils/localStorage/user';
import {
  REPORT,
  CLIENT,
  CLIENT_DETAILS,
  PREFERRED_EMPLOYEES,
  HEALTH,
  ALLERGIES,
  AVOID_EMPLOYEES,
  SKILLS_TRAINING,
  COMPETENCIES,
  MEDICAL,
  IMPORTANT_CONTACTS,
  VISIT_CARE_NOTES,
  ROTA_EMAIL,
  CLIENT_KPI_DETAILS,
  WEAKLY_DETAILS,
  KPI_DAILY_DETAILS,
  KPI,
  KPI_WEAKLY_DETAILS,
  KPI_VISIT,
  // CLIENT_DAILY_DETAILS,
  // CLIENT_WEAKLY_DETAILS,
} from 'utils/constants/routes';
import {
  ContainerTabStyled,
  Title,
  LinkTabStyled,
  LinkTab,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  REPORT_CLIENT_PD,
  REPORT_CLIENT_PREFERRED_EMPLOYEE,
  REPORT_CLIENT_AVOID_EMPLOYEE,
  REPORT_CLIENT_COMPETENCY,
  REPORT_CLIENT_SKILLS,
  REPORT_CLIENT_HEALTH,
  REPORT_CLIENT_MEDICAL,
  REPORT_CLIENT_ALLERGIES,
  REPORT_CLIENT_CARE_CONTACT,
  REPORT_CLIENT_ROTA_EMAIL,
  REPORT_VISIT_CARE_CONTACT,
  REPORT_CLIENT_TASK,
  REPORT_CLIENT_KPI,
  REPORT_CLIENT_COMPLIANCE,
  REPORT_TEAM_KPI,
  REPORT_CLIENT_QUALITY,
  REPORT_FINANCE_KPI,
} from '../../../../utils/constants/permissions';
import ClientFilters from './ClientFilters';
import _, {get} from 'lodash';
import {AppContext} from 'context';
import ClientRota from './ClientRota';
import NewVisitCare from './ClientFilters/NewVisitCare';
import DailyWeaklyFilter from './ClientFilters/DailyWeaklyFiter';

const TAB_KPI_URL = REPORT + KPI + KPI_DAILY_DETAILS;
const TAB_KPI_WEAKLY_URL = REPORT + KPI + KPI_WEAKLY_DETAILS;
// const TAB_CLIENT_DAILY_DETAILS_URL = REPORT + CLIENT + CLIENT_DAILY_DETAILS;
// const TAB_CLIENT_WEAKLY_DETAILS_URL = REPORT + CLIENT + CLIENT_WEAKLY_DETAILS

const TAB_PREFERRED_WEAKLY_URL = REPORT + CLIENT + KPI_DAILY_DETAILS;
const TAB_AVOID_EMPLOYEES_URL = REPORT + CLIENT + AVOID_EMPLOYEES;
const TAB_MEDICAL_URL = REPORT + CLIENT + MEDICAL;

const ReportKpi = props => {
  // const [reportClientKpi, setReportClientKpi] = useState(tabClientKpi);
  const [teamTabs, setTeamTabs] = useState('kpi');
  const [updateFunderSettings, setUpdatePermissionsFunderSettings] =
    useState(false);
  const [createFunderSettings, setCreatePermissionsFunderSettings] =
    useState(false);
  const [deletePermissionFunderSettings, setDeletePermissionsFunderSettings] =
    useState(false);
  const [readPermissionFunderSettings, setReadPermissionsFunderSettings] =
    useState(false);
const [readPermissionFinanceKpi ,setReadPermissionFinanceKpi]=useState(false)
  const {
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextChoices,
    contextPermissions,handleGetChoices
  } = useContext(AppContext);
  const [newData, setNewData] = useState('');
  let history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const userStaff = contextIsStaff;
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  const reviewTab = tab => {
    return tab === teamTabs;
  };
  const [readClientkpiPermission, setReadClientkpiPermission] = useState(false);
  const [readQualitykpiPermission, setReadQualitykpiPermission] = useState(false);
  const [readVisitCompliancePermission, setReadVistCompliancePermission] =
    useState(false);
  const [readTeamKpiPermission, setReadTeamKpiPermission] = useState(false);
  useEffect(() => {
   
    const rolesPermissions = contextPermissions && contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    const roles = contextSubSectionPermissions && contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    if (contextIsSuperAdmin == true || contextIsAdmin == true) {
      setReadClientkpiPermission(true);
      setReadVistCompliancePermission(true)
      setReadTeamKpiPermission(true)
      setReadQualitykpiPermission(true)
      setReadPermissionFinanceKpi(true)
      // setReadPermissionBillCompare(true);
      // setReadPermissionBillDetails(true);
    } else {
      setReadClientkpiPermission(
        rolePermissions &&
          rolePermissions[REPORT_CLIENT_KPI] &&
          rolePermissions[REPORT_CLIENT_KPI][WEB_PERMISSION] &&
          rolePermissions[REPORT_CLIENT_KPI][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
      );
      setReadVistCompliancePermission(
        rolePermissions &&
          rolePermissions[REPORT_CLIENT_COMPLIANCE] &&
          rolePermissions[REPORT_CLIENT_COMPLIANCE][WEB_PERMISSION] &&
          rolePermissions[REPORT_CLIENT_COMPLIANCE][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
      );
      setReadQualitykpiPermission(
        rolePermissions &&
          rolePermissions[REPORT_CLIENT_QUALITY] &&
          rolePermissions[REPORT_CLIENT_QUALITY][WEB_PERMISSION] &&
          rolePermissions[REPORT_CLIENT_QUALITY][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
      );

      setReadPermissionFinanceKpi(
        rolePermissions &&
          rolePermissions[REPORT_FINANCE_KPI] &&
          rolePermissions[REPORT_FINANCE_KPI][WEB_PERMISSION] &&
          rolePermissions[REPORT_FINANCE_KPI][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ]
      );
      setReadTeamKpiPermission(
        rolePermissions &&
          rolePermissions[REPORT_TEAM_KPI] &&
          rolePermissions[REPORT_TEAM_KPI][WEB_PERMISSION] &&
          rolePermissions[REPORT_TEAM_KPI][WEB_PERMISSION][WEB_READ_PERMISSION]
      );
    }

  }, [
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
  ]);

  const tabClick = tab => {
    setNewData(tab);

    setTeamTabs(tab);
    if (tab === 'kpi') {
      history.push(REPORT + KPI_DAILY_DETAILS);
    } else {
      history.push(REPORT + KPI);
    }
  };
  console.log(readPermissionFinanceKpi,"HHHHHHHHH")
  return (
    <div>
      <Title>Report Kpi</Title>
      <div className={'d-flex w-100 text-center mb-4'}>
     
        <LinkTabStyled active={reviewTab('kpi')}>
          <div onClick={() => tabClick('kpi')} className={'styledLink'}>
          {readClientkpiPermission &&(
            <LinkTab active={reviewTab('kpi')}>Client KPI (Beta)</LinkTab>
          )}
          </div>
        </LinkTabStyled>
      
      
        <LinkTabStyled active={reviewTab('visit')}>
          <div onClick={() => tabClick('visit')} className={'styledLink'}>
          {readVisitCompliancePermission&&(
            <LinkTab active={reviewTab('visit')}>
              Visit Compliance KPI (Beta)
            </LinkTab>
          )}
          </div>
        </LinkTabStyled>
        
        
        <LinkTabStyled active={reviewTab('teamkpi')}>
          <div onClick={() => tabClick('teamkpi')} className={'styledLink'}>
          {readTeamKpiPermission&&(
            <LinkTab active={reviewTab('teamkpi')}>Team KPI (Beta)</LinkTab>
          )}
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('qualitykpi')}>
          <div onClick={() => tabClick('qualitykpi')} className={'styledLink'}>
          {readQualitykpiPermission&&(
            <LinkTab active={reviewTab('qualitykpi')}> QA KPI (Beta)</LinkTab>
          )}
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('triagekpi')}>
          <div onClick={() => tabClick('triagekpi')} className={'styledLink'}>
        
            <LinkTab active={reviewTab('triagekpi')}>Triage  KPI (Beta)</LinkTab>
          
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('medicationkpi')}>
          <div onClick={() => tabClick('medicationkpi')} className={'styledLink'}>
        
            <LinkTab active={reviewTab('medicationkpi')}>Medication  KPI (Beta)</LinkTab>
          
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('financekpi')}>
          <div onClick={() => tabClick('financekpi')} className={'styledLink'}>
        {readPermissionFinanceKpi&&(
            <LinkTab active={reviewTab('financekpi')}>Finance  KPI (Beta)</LinkTab>
            )}
          </div>
        </LinkTabStyled>
      
      </div>
      <>
        <Switch>
         { readClientkpiPermission&& teamTabs === 'kpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              handleGetChoices={handleGetChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          {readVisitCompliancePermission && teamTabs === 'visit' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          {readTeamKpiPermission && teamTabs === 'teamkpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          {readQualitykpiPermission && teamTabs === 'qualitykpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          { teamTabs === 'triagekpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          { teamTabs === 'medicationkpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        <Switch>
          { teamTabs === 'financekpi' && (
            <DailyWeaklyFilter
              contextChoices={contextChoices}
              location={props.location}
              tabClick={teamTabs}
              updateFunderSettings={updateFunderSettings}
              createFunderSettings={createFunderSettings}
              deletePermissionFunderSettings={deletePermissionFunderSettings}
              readPermissionFunderSettings={readPermissionFunderSettings}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              userStaff={userStaff}
            />
          )}
        </Switch>
        
      </>
    </div>
  );
};

export default ReportKpi;
