import React from "react";
import { StyledCheck } from "./styles";

const CheckboxBranch = ({ item, id, label, checked }) => {
  return (
    <StyledCheck className="form-check">
      <div className="custom-control custom-checkbox">
        <input
          id={id}
          // {...props.useInput}
          type="checkbox"
          className="custom-control-input"
          checked={checked}
          readOnly
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </StyledCheck>
  );
};

export default CheckboxBranch;
