import styled from 'styled-components';
import {
  PURPLE,
  SOFT_RED,
  GRAY_100,
  GRAY_200,
  GRAY_700,
  GRAY_800,
  PURPLE_800,
  WHITE,
} from 'shared/styles/constants/colors';
import {MD, SM} from 'shared/styles/constants/resolutions';

const FORM = 'form';

const handleTheme = props => {
  switch (props.theme.main) {
    case FORM:
      return true;
    default:
      return false;
  }
};

const handleTitle = (theme, itemsSelected) => {
  if (!itemsSelected) {
    return `
    display : none;
    `;
  }

  return ` top: 1%;
    left: 0.8rem; 
    font-size: 0.6rem;
    padding-top: 0.2em;
    padding-bottom: 0.8em;
    font-weight: 600;
    position: absolute
    `;
};

export const DropdownButton = styled.button`

  min-width: 100%;
  min-height: 2.4rem;
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  text-align: left;
  color: ${GRAY_800};
  font-weight: 300;
  background-color: ${props => (handleTheme(props) ? GRAY_100 : WHITE)};
  padding: ${props =>
    props.noPadding
      ? 0
      : handleTheme(props)
      ? '0.375rem 0.5rem'
      : '0.2 0.5rem 0 0.5rem'};

  text-decoration: none;
  -webkit-border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  font-size: 0.875rem;

  border: ${props =>
    props.itemsSelected
      ? `1px solid ${PURPLE}`
      : `1px solid ${PURPLE_800}`};

  &:focus {
    outline: none;
  }

  h6 {
    ${({theme, itemsSelected}) => handleTitle(theme, itemsSelected)}
  }

  span {
    color: ${SOFT_RED};
  }

  color: ${props => (props.disabled ? GRAY_700 : GRAY_800)};

  width: ${props =>
    handleTheme(props) ? '100%' : props.width ? props.width : 'auto'};

  border-radius: ${props => (handleTheme(props) ? '0.25rem' : '1.875rem')};
  -webkit-border-radius: ${props =>
    handleTheme(props) ? '0.25rem' : '1.875rem'};

  svg {
    font-size: 1.2rem;
    vertical-align: text-bottom;
    margin-top: ${({theme, itemsSelected}) =>
      theme.main !== FORM && itemsSelected
        ? '0.225rem; padding-bottom: -100% !important'
        : '0.125rem'};
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0 !important;
    color: ${PURPLE} !important;
  }

  label {
    text-transform: capitalize;
    margin: 0;
    ${({theme, itemsSelected}) =>
      theme.main === FORM && itemsSelected
        ? 'padding-top: 0.3em'
        : theme.main !== FORM && itemsSelected
        ? 'padding-top: 0.20em;'
        : ''};
    cursor: pointer;
    white-space: pre-wrap !important;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis !important;
    max-height: 1.5rem;
    padding-bottom: 0px !important;

    @media (max-width: ${MD}) {
      font-size: 0.75rem;
      max-height: unset;
    }
  }

  @media (max-width: ${SM}) {
    border-radius: ${props => (handleTheme(props) ? '0.25rem' : '0.85rem')};
    -webkit-border-radius: ${props =>
      handleTheme(props) ? '0.25rem' : '0.85rem'};

    padding-left: 0.65rem;
    padding-right: 0.2rem;
  }
`;

export const ItemsDropdownStyle = styled.div`
  max-height: ${({maxheight}) => (maxheight ? maxheight : '10rem')};
  overflow: ${({maxheight}) => (maxheight ? 'scroll' : 'none')};
  overflow-x: hidden;
  font-size: 0.875rem;

  button {
    white-space: pre-wrap !important;
    overflow: hidden;

    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;

    &:focus {
      background: ${GRAY_200};
      color: ${PURPLE};
    }
  }

  @media (max-width: ${MD}) {
    font-size: 0.75rem;
  }
`;
