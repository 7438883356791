import React, {useState, useEffect, Fragment} from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {useForm} from 'hooks/FormHook';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  SetActualBulkTime,
  SetBulkTimeRotaShiftWithOutShift,
  SetBulkTimeRotaShiftWithShift,
} from 'utils/api/SchedulerApi';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import moment from 'moment';
const ActualEdits = ({
  fullBind,
  checkedRows,
  searchRotaShift,
  setActualEditsVisits,
  shiftTypeChecked,
  clearSelectedElId,
  setEditActual
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {values, setValues, useInput, errors, setCheckRequires} = useForm({});
  const [errorMessage, setErrorMessage] = useState('');
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Edit Actuals',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  const dropDownHandler = (item, nameItem) => {
    if (nameItem == 'time_type') {
      values.actual_visit_time = null;
    }
    values[nameItem] = item[nameItem];
    setValues({...values});
  };

  const handleTimeSelector = e => {
    values.actual_visit_time = moment(e.target.value, 'h:mm:ss A').format(
      'HH:mm'
    );
    setValues({...values});
  };
  const handleSubmit = async () => {
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    setLoadingSave(true);
    setErrorMessage('');
    let checkedWithoutShift = [];

    checkedRows.forEach(item => {
      checkedWithoutShift.push(item.id);
    });

    console.log(checkedRows, 123);
    let time = moment(values.actual_visit_time, 'HH:mm').format('h:mm:ss');

    let visit_time =
      time && (time.split(':')[0].length == 1 ? `0${time}` : time);

    let clock_time = {};
    if (values.actual_visit_time) {
      if (values.clock_type === 'Clock-in') {
        clock_time.start_time = visit_time;
      } else {
        clock_time.end_time = visit_time;
      }
    }

    let bodyShift = {
      employee_shift: [...checkedWithoutShift],
      clock_type: values.clock_type,
      is_revert: values.time_type === 'Revert' ? true : false,
      is_web: true,
      is_same: values.time_type === 'default' ? true : false,
      ...clock_time,
    };

    let response = await SetBulkTimeRotaShiftWithShift(bodyShift);

    console.log(response, 'DDDDDY');
    if (response.message === 'successfully done') {
      setSaveButtonIcon(CHECK);
      setTextStatus(SAVED);
      setLoadingSave(false);
      clearSelectedElId();
      setEditActual(false)
      searchRotaShift();

    } else {
      setSaveButtonIcon(CROSS);
      setTextStatus(ERROR);
      setLoadingSave(false);
    }
  };

  return (
    <ModalContainer max_width={'40%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}>
        <div className={' pl-3 pr-3 pb-3'}>
          <div className="mb-2">
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Select Clock Type'}
                // width="145%"
                options={[
                  {id: 'Clock-out', name: 'Actual Clock Out'},
                  {id: 'Clock-in', name: 'Actual Clock In'},
                ]}
                {...useInput(`clock_type`, 'isRequired')}
                selectedOption={values ? values.clock_type : null}
                onChange={value =>
                  dropDownHandler(
                    {clock_type: value ? value : null},
                    'clock_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pb-2 mb-2">
              <DropdownSearchable
                required
                placeHolder={'Select Time Type'}
                // width="145%"
                options={[
                  {id: 'default', name: 'Same As Scheduled Time'},
                  {id: 'Manual', name: 'Custom'},
                  {id: 'Revert', name: 'Revert'},
                ]}
                {...useInput(`time_type`, 'isRequired')}
                selectedOption={values ? values.time_type : null}
                onChange={value =>
                  dropDownHandler(
                    {time_type: value ? value : null},
                    'time_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pb-2">
              {values.time_type == 'Manual' && (
                <TimeSelector
                  isRequired={true}
                  labelTop={'Actual Visit Time'}
                  onChange={e => handleTimeSelector(e)}
                  value={values.actual_visit_time}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  //  {...useInput(`actual_visit_time`,'isRequired')}
                />
              )}
            </FlexGrid3Item>
          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default ActualEdits;
