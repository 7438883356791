import styled from 'styled-components';
import {PURPLE, GRAY_300} from 'shared/styles/constants/colors';
import {SM, MD, XXL} from 'shared/styles/constants/resolutions';

export const Header = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid ${GRAY_300};
  display: flex;
  justify-content: space-between;
  @media (max-width: ${XXL}) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${MD}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const EmploymentTypesContainer = styled.div`
  min-width: 8.75rem;

  @media (max-width: ${SM}) {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const Title = styled.label`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const SearchAddContainer = styled.div`
  @media (max-width: ${XXL}) {
    margin-bottom: 1.25rem;
  }
`;

export const SearchContainer = styled.div`
  margin-right: 1rem;
  width: ${props => (props.show ? '12.5rem' : '0')};
  min-width: ${props => (props.show ? '12.5rem' : '0')};
  transition-duration: ${props => (props.show ? '0.25s' : '0')};
`;

export const SearchIcon = styled.div`
  display: inline-block;
  margin-right: 1.25rem;
  cursor: pointer;
  svg {
    width: 1.6875rem;
    height: 1.6875rem;
    opacity: 0.49;
  }
`;

export const SortIcon = styled.div`
  display: inline-block;
  svg {
    font-size: 1rem;
    margin-bottom: 0;
    color: ${PURPLE};
  }
`;
