import React, {Fragment} from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {AppContext, BranchItemsContext} from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PrimaryButtonModal} from 'components/SharedComponents/ModalDecision/styles';
import {Title} from 'shared/styles/constants/tagsStyles';
import {IconStyle} from 'components/SharedComponents/ModalInformation/styles';
import {CancelButton} from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import {Toast} from 'primereact/toast';
import {MessageOptaPlanner} from 'components/DashboardComponents/Scheduler/styles';
import {setClientContract} from 'utils/api/ClientApi';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import {PLAY, ERROR_OUTLINE} from 'utils/constants/icons';

import _ from 'lodash';
import {ModalContainer, StylePointer} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';

import {deleteMedicationName, getMedicationName} from 'utils/api/SettingsApi';
const MedicationDelete = ({
  fullBind,
  setModalValue,

  type,

  setDataChecking,
  testingData,
  setApiCall,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {contextChoices} = useContext(AppContext);
  const [newDetails, setDetails] = useState([]);
  const [fullDay, setFullDay] = useState('Full Day');
  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Warning',
    });
  }, [fullBind]);

  console.log(setDataChecking);
  const handleDelete = () => {
    deleteMedicationName(testingData && testingData.id);

    setApiCall(true);

    setModalValue(false);
  };

  return (
    <div>
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{ERROR_OUTLINE}</IconStyle>
          <Toast ref={toast} position="top-right" />
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage pl-3 pr-3'}>
              <h5>
                Are you sure you want to delete this Medication Name data ?
              </h5>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              onClick={() => {
                handleDelete();
                setModalValue(false);
              }}>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default MedicationDelete;
