import { ButtonsContainer, SchedulerContainer } from 'components/DashboardComponents/Scheduler/styles';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';
import Spinner from 'components/SharedComponents/Spinner';
import TimelineMedicationCalendar from 'components/SharedComponents/TimelineMedicationCalendar';
import { AppContext, BranchItemsContext } from 'context';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import moment from 'moment';
import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { GRAY_820, WHITE } from 'shared/styles/constants/colors';
import { DataContainer, DropdownTypesContainer, FiltersContainerRotaShifts, FiltersLeftGroupRotaShifts, FiltersTypesContainerRotaShifts, Title } from 'shared/styles/constants/tagsStyles';
import { getDownloadMedicationFile, getDownloadMedicationPdf, getDownloadMedicationPdfCalendar, getMedicationCalendar, getMedicationChart, getMedicationDoseById, getMedicationList, setMedBulkEdit, setMedClickEvent, setMedReview } from 'utils/api/ClientApi';
import { setBulkDeleteMedication } from 'utils/api/SchedulerApi';
import { InvalidOrEmptyValues } from 'utils/constants/messages';
import MedicationListView from './MedicationListView';
import AddMedication from './Modals/AddMedication';
import BulkEditMedication from './Modals/BulkEditMedication';
import BulkReviewMedication from './Modals/BulkReviewMedication';
import EditMedication from './Modals/EditMedication';
import NoSelectAllDropdownFilterTable from 'components/SharedComponents/NoSelectAllDropdownFilterTable';
import MedicationErrorType from './Modals/MedicationError';
import { jsPDF } from "jspdf";
import { calendar_medication_choices } from 'utils/choiceConstant';
import { Toast } from 'primereact/toast';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { headerColumns } from './MedicationListView/headerColumns';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';
import {
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_UPDATE_PERMISSION
} from 'utils/constants/permissions';
import { getEmployee } from 'utils/localStorage/token';
import ViewToExport from './Modals/ViewToExport';
import ContextMenu, { useContextMenu } from '../../SharedComponents/ContextMenu/ContextMenu';
import html2canvas from 'html2canvas';

const headerColumnsByDefault = [
  {
    name: 'CHECKBOX',
    id: 'checkbox',
  },
  {
    name: 'Client Name',
    id: 'client_name',
  },
  {
    name: 'Employee Name',
    id: 'employee_name',
  },
  {
    name: 'Medication Name',
    id: 'medication_name',
  },
  {
    name: 'Medication Dose',
    id: 'medication_dose',
  },
  {
    name: 'Start date',
    id: 'schedule_start_date',
  },
  {
    name: 'Actual Dose Time',
    id: 'actual_dose_time',
  },
  {
    name: 'Planned Dose Time',
    id: 'planned_dose_time',
  },
  {
    name: 'Medication Type',
    id: 'medication_type',
  },
  {
    name: 'Medication Status',
    id: 'medication_status',
  },

];
// const headerColumns = [
//   {
//     name: 'CHECKBOX',
//     id: 'checkbox',
//   },
//   {
//     name: 'Client Name',
//     id: 'client_name',
//   },
//   {
//     name: 'Employee Name',
//     id: 'employee_name',
//   },
//   {
//     name: 'Medication Name',
//     id: 'medication_name',
//   },
//   {
//     name: 'Medication Dose',
//     id: 'medication_dose',
//   },
//   {
//     name: 'Start date',
//     id: 'schedule_start_date',
//   },
//   {
//     name: 'Actual Dose Time',
//     id: 'actual_dose_time',
//   },
//   {
//     name: 'Planned Dose Time',
//     id: 'planned_dose_time',
//   },
//   {
//     name: 'Medication Type',
//     id: 'medication_type',
//   },
//   {
//     name: 'Medication Status',
//     id: 'medication_status',
//   },
//   {
//     name: 'Medication Reason',
//     id: 'medication_reason',
//   },
//   {
//     name: 'Medication Feedback',
//     id: 'medication_feedback',
//   },
//   {
//     name: 'Review Comment',
//     id: 'review_comment',
//   },
//   {
//     name: 'Review Date',
//     id: 'review_date',
//   },
//   {
//     name: 'Review Status Name',
//     id: 'review_status__name',
//   },
//   {
//     name: 'Review Time',
//     id: 'review_time',
//   },

// ];

const MedicationCalendar = ({ }) => {
  // let clientId=cliId?cliId:selectedClientId
  const [events, setEvents] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [resourcesMedication, setResourcesMedication] = useState([]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const { values, setValues, useInput, errors, isValid, setFormErrors, setCheckRequires } = useForm({viewType: 'daily'});
  const { contextChoices, contextChoicesMap, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextSubSectionPermissions } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const [listView, setListView] = useState(false)
  const [calendarView, setCalendarView] = useState('client');
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const [checkedRowsEmp, setCheckedRowsEmp] = useState([])
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [employeeForFilter, setEmployeesForFilter] = useState([])
  const [medicationNameFilter, setMedicationNameFilter] = useState([]);
  const [medicationStatusFilter, setMedicationStatusFilter] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true)
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [clientResource, setClientResource] = useState(0)
  const [assignedHours, setAssignedHours] = useState(0);
  const [unassignedHours, setUnassignedHours] = useState(0);
  const [linkedMeds, setLinkedMeds] = useState(0);
  const [unLinkedMeds, setUnLinkedMeds] = useState(0);
  const headerSelectedData = headerColumns.map((val) => (val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumns.filter((val) => val.columnSelected));
  const [MedicationColourCode, setMedicationColourCode] = useState([]);
  const [medList, setMedList] = useState([])
  const [loadingSaveMedication, setLoadingSaveMedication] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [readSelf, setReadSelfPermissions] = useState(false)
  const filterEmployee = getEmployee()

  const toast = useRef();

  const { setValue: setModalBulkDelete, bind: bindModalBulkDelete } = useModal(false);
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(calendar_medication_choices)
  }, [])

  const {
    setValue: setEditMedication,
    value: editMedication,
    fullBind: fullBindEditMedication,
  } = useModal(false);

  const {
    setValue: setAddMedication,
    value: addMedication,
    fullBind: fullBindAddMedication,
  } = useModal(false);

  const {
    setValue: setBulkEditMedication,
    value: bulkEditMedication,
    fullBind: fullBindBulkEditMedication,
  } = useModal(false);

  const {
    setValue: setBulkReviewMedication,
    value: bulkReviewMedication,
    fullBind: fullBindBulkReviewMedication,
  } = useModal(false);

  const {
    setValue: setMedicationError,
    value: MedicationError,
    fullBind: fullBindMedicationError,
  } = useModal(false);

  const {
    setValue: setModalViewToExport,
    value: modalViewToExport,
    setTitle: setTitle,
    fullBind: fullBindViewToExport,
  } = useModal(false);

  const mountedRef = useRef(true);



  useEffect(() => {
    searchScheduler()
  }, [
    // clientId,
    shiftType,
    //values.start_date,
    values.end_date,
    branchData
  ])

  useEffect(() => {
    if (contextChoicesMap.client_listing && contextChoicesMap.medication_dose && contextChoices.medication_status&& (readPermission || readSelf || contextIsAdmin || contextIsSuperAdmin)) {
      updateGetMedicationData(medicationData)
    }
  }, [
    contextChoicesMap.client_listing,
    contextChoicesMap.medication_dose,
    contextChoices.medication_status,
    medicationData,
    readPermission, readSelf, contextIsAdmin
  ])

  useEffect(() => {
    if (contextChoices.medication_status) {
      let temp_obj = {}
      contextChoices.medication_status.forEach((val) => {
        temp_obj[val.name] = val;
      })
      setMedicationColourCode(temp_obj)
    }
  }, [contextChoices.medication_status])

  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {

        // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];


      }
        // }
      );
    }
    // const roles = contextSubSectionPermissions;
    // if (roles.length > 0) {
    //   roles.forEach(item => {
    //     rolePermissions[item.section.name] = {};
    //     rolePermissions[item.section.name][WEB_PERMISSION] = {};
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       WEB_READ_PERMISSION
    //     ] = item?.[WEB_READ_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       SELF_WEB_READ_PERMISSION
    //     ] = item?.[SELF_WEB_READ_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       WEB_CREATE_PERMISSION
    //     ] = item?.[WEB_CREATE_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       SELF_WEB_CREATE_PERMISSION
    //     ] = item?.[SELF_WEB_CREATE_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       WEB_DELETE_PERMISSION
    //     ] = item?.[WEB_DELETE_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       WEB_UPDATE_PERMISSION
    //     ] = item?.[WEB_UPDATE_PERMISSION];
    //     rolePermissions[item.section.name][WEB_PERMISSION][
    //       SELF_WEB_UPDATE_PERMISSION
    //     ] = item?.[SELF_WEB_UPDATE_PERMISSION];
    //   }

    //   );
    // }
    if (contextIsSuperAdmin) {
      setReadPermissions(true);
      setReadSelfPermissions(true);
    } else if (contextIsAdmin) {
      setReadPermissions(true);
      setReadSelfPermissions(true);
    }else {
      setReadPermissions(
        rolePermissions['Medication']?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ]
      );
      setReadSelfPermissions(
        rolePermissions['Medication']?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
        ]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices, contextIsAdmin, contextIsSuperAdmin, contextSubSectionPermissions, contextPermissions]);

  useEffect(() => {
    let employees = [];
    if (contextChoices.employee_listing) {
      employees = contextChoices.employee_listing.map(employee => {
        return { id: employee.id, name: employee.full_name };
      });
      setEmployeesForFilter([{ id: "none", name: "Unassigned" }, ...employees] || []);
    }

    let clients = [];

    if (contextChoices.client_listing) {
      clients = contextChoices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      });
      setClients(clients || []);
    }
  }, [contextChoices])

  // useEffect(() => {
  //   const data = [];
  //   const dataEmp = []

  //   headerColumnsByDefault.map((item) => {
  //     data.push(item.id);
  //   })
  //   setrunTableOptionData(data)
  // }, [events])

  const changeCalendar = (view) => {
    setCalendarView(view)
    if (view === 'client') {
      //calenderChange(view)
      setListView(false)
    } else if (view === 'list ') {
      //calenderChange(view)
      setListView(true);
    }
  }

  const getMedicationNameList = (id) => {
    getMedicationList(id)
      .then((res) => {
        const temp_opt = res?.data?.map((i) => ({ id: i.name__id, name: i.name__name, med_sch_id: i.id }));
        setMedList(temp_opt || [])
      })
      .catch((err) => { })
  }

  const getMedicationColor = (status) => {

    return MedicationColourCode[status]?.color_code || '#868686'

    // switch (status) {
    //   case 'Fully Taken':
    //     return '#A8E39B'
    //   case 'Not Needed':
    //   case 'Not Observed':
    //   case 'Not Reported':
    //   case 'Not Scheduled':
    //   case 'Not Taken':
    //   case 'Not Started':
    //   case 'No Visit':
    //     return '#ff3b3b'
    //   case 'Partially Taken':
    //     return '#ffa500'
    //   default:
    //     return '#868686';
    // }
  }

  const searchScheduler = async () => {
    setLoading(true)
    setEvents([]);
    //let clientId=cliId?cliId:selectedClientId
    setCheckedValue(false)
    Promise.all([getMedicationCalendar({ ...values, ...branchData }, 800)])
      .then(response => {
        if (!mountedRef.current) return null;
        setMedicationData(response)
        // updateGetMedicationData(response)
      })
  };

  const updateGetMedicationData = (response) => {
    let data = [];
    let resource_data = []
    let client_count = []
    let linkedCounts = 0;
    let unLinkedCounts = 0;
    console.log('get response', response[0])
    const array_length = response && response[0]?.length

    //data = 
    response[0] && response[0].map(med => {
      data.push({
        id: med.id,
        resourceId: `${med.client}${med.medication_dose__dose__id}${med.medication_name}`,
        title: (med.start_date && med.medication_dose_time) ? (moment.utc(med.start_date + ' ' + med.medication_dose_time).format("HH:mm") + (med.medication_dose_end_time ? (' - ' + moment.utc(med.start_date + ' ' + med.medication_dose_end_time).format("HH:mm")) : '')) : '',
        backgroundColor: getMedicationColor(med.medication_status_name),
        borderColor: getMedicationColor(med.medication_status_name),
        textColor: WHITE,
        constraint: {
          resourceIds: [med.medication_dose__id],
        },
        dose: med.medication_dose__dose__id,
        dose_name: (med?.medication_dose__dose__id && contextChoicesMap?.medication_dose) ? contextChoicesMap?.medication_dose[med.medication_dose__dose__id]?.name : '',
        medication_name: med.medication_name,
        medication_dose_time: med.medication_dose_time,
        actual_medication_dose_time: med.actual_medication_dose_time,
        medication_type: med.medication_type,
        medication_type_name: med.medication_type,
        start: med.start_date + ' ' + med.medication_dose_time,
        end: med.medication_dose_end_time ? med.start_date + ' ' + med.medication_dose_end_time : moment(med.start_date + ' ' + med.medication_dose_time).add(5, "minutes").format("YYYY-MM-DD HH:mm"),
        clientId: med.client,
        client_name: (med.client && contextChoicesMap?.client_listing) ? contextChoicesMap?.client_listing[med.client]?.full_name : '',
        employee_name: (med.employee && contextChoicesMap?.employee_listing) ? contextChoicesMap?.employee_listing[med.employee]?.full_name : '',
        employeeId: med.employee,
        is_adhoc: med.is_adhoc,
        medication_status_name: med.medication_status_name,
        review_status__name: med.review_status__name,
        review_comment: med.review_comment,
        review_date: med.review_date,
        review_time: med.review_time,
        is_relation: med.is_relation,
        visitId: med.visitId,
        linkedWithVisit: med.linkedWithVisit

      });
      if (med.linkedWithVisit) {
        linkedCounts = linkedCounts + 1
      } else {
        unLinkedCounts = unLinkedCounts + 1
      }
      client_count.push(med.client)
      resource_data.push({
        id: `${med.client}${med.medication_dose__dose__id}${med.medication_name}`,
        client: (med.client && contextChoicesMap?.client_listing) ? contextChoicesMap?.client_listing[med.client]?.full_name : '',
        title: (med.medication_dose__dose__id && contextChoicesMap?.medication_dose) ? contextChoicesMap?.medication_dose[med.medication_dose__dose__id]?.name : '',
        med: med.medication_name
      })
    });
    let uniqueChars = [];
    client_count.forEach((c) => {
      if (!uniqueChars.includes(c)) {
        uniqueChars.push(c);
      }
    });
    setLinkedMeds(linkedCounts)
    setUnLinkedMeds(unLinkedCounts)
    setClientResource(uniqueChars?.length || 0)
    const adm_count = data.filter((val) => val.medication_status_name == 'Family Administered' || val.medication_status_name == 'Self Administered' || val.medication_status_name == 'Fully Taken');
    setAssignedHours(adm_count.length)
    setUnassignedHours(array_length - adm_count.length)
    let final_data = []
    if(!readPermission && readSelf && !contextIsAdmin && !contextIsSuperAdmin){
      final_data = data?.filter((selfPer)=>selfPer.employeeId == filterEmployee)
    }else{
      final_data = [...data]
    }
    
    setEvents(final_data.flat());
    setResourcesMedication(resource_data.flat());
    if (contextChoicesMap.medication_dose) {
      setLoading(false)
    }

  }

  console.log('medication data', events)
  console.log('medication dose', contextChoicesMap.medication_dose)
  console.log('medication client', contextChoicesMap.client_listing)


  const handleDownlaod = () => {
    if (!loading) {
      setLoading(true)
    }
    let fileName =
      'Medication_' +
      moment(values.start_date).format('YYYY-MM-DD') +
      '_' +
      moment(values.end_date).format('YYYY-MM-DD');
    SetDownloadDisable(true);
    getDownloadMedicationFile(values)
      .then(response => {
        setLoading(false)
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        SetDownloadDisable(false);
      });
  };

  const handleSelectChange = (e, visit) => {
    // console.log(visit.id,"441125")
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.id])
      setCheckedRowsEmp([...checkedRowsEmp, visit.employee_name])
    } else {
      let index = checkedRows.indexOf(visit.id)
      let indexEmp = checkedRowsEmp.indexOf(visit.employee_name)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
      if (indexEmp != -1) {
        let tempCheckedRowsEmp = [...checkedRowsEmp]
        tempCheckedRowsEmp.splice(indexEmp, 1)
        setCheckedRowsEmp([...tempCheckedRowsEmp])
      }
    }
  }

  const handleSubmit = () => {
    console.log("Handle submit values", values)
    
    
    if (values[`professional_guidance_field_error`] || values[`alternative_field_error`]) {
      setErrorMessage(InvalidOrEmptyValues)
      setShowError(true)
      return;
    }
    setLoadingSaveMedication(true)
    let body = values.medication.id ?
      {
        core_medication_dose: values.medication.core_medication_dose,
        start_date: values.medication.start_date,
        is_adhoc: false,
        name: values.medication.name,
        medication_type: values.medication.medication_type,
        medication_dose_time: values.medication.medication_dose_time,
        administration_type: values.medication.administration_type,
        administration_reason: values.medication.administration_reason,
        medication_route: values.medication.medication_route,
        medication_form: values.medication.medication_form,
        client: values.medication.client,
        employee_id: values.medication.employee,
        medication_assistance: values.medication.medication_assistance,
        medication_instructions: values.medication.medication_instructions,
        instructions: values.medication.instructions,
        dose_regime: values.medication.dose_regime,
        frequency: values.medication.frequency,
        important: values.medication.important,
        prescription_volume: values.medication.prescription_volume,
        reason: values.medication.reason,
        potential_side_effects: values.medication.potential_side_effects,
        comments: values.medication.comments,
        administration_area: values.medication.administration_area,
        marking_style: values.medication.marking_style,
        storage_location: values.medication.storage_location,
        prescription_expiry_date: values.medication.prescription_expiry_date,
        administration_support: values.medication.administration_area,
        photo: null,
        medication_status_id: values.medication.medication_status,
        // dose: {
        //   dose_id: values.medication.medication_dose,
        //   medication_dose_timing_id: values.medication.medication_dose_timing,
        //   medication_dose_time: values.medication.medication_dose_time,
        //   medication_dose_end_time: values.medication.medication_dose_end_time,
        //   instructions: values.medication.instructions
        // },
        medication_dose_timing: values.medication.medication_dose_timing,
        medication_dose_time: values.medication.medication_dose_time,
        medication_dose_end_time: values.medication.medication_dose_end_time,
        body_part: values.medication_body_part || [],
        // New fields Added
        strength: values.strength,
        min_interval_between_doses: values.min_interval_between_doses,
        max_dose_in_24_hours: values.max_dose_in_24_hours,
        medication_purpose: values.medication_purpose,
        symptoms_to_look_for: values.symptoms_to_look_for,
        client_need_medication_criteria: values.client_need_medication_criteria,
        alternative_administering_interventions: values.alternative_administering_interventions,
        medication_duration: values.medication_duration,
        healthcare_professional_guidance_criteria: values.healthcare_professional_guidance_criteria,
        completed_by: values.completed_by,
        // signature: values.signature || null,
        date: values.date,
      } :
      {
        core_medication_dose_id: values.medication.core_medication_dose,
        start_date: values.medication.start_date,
        is_adhoc: false,
        name_id: values.medication.name,
        medication_type_id: values.medication.medication_type,
        administration_type_id: values.medication.administration_type,
        administration_reason_id: values.medication.administration_reason,
        medication_route_id: values.medication.medication_route,
        medication_form_id: values.medication.medication_form,
        client_id: values.medication.client,
        employee_id: values.medication.employee,
        medication_assistance_id: values.medication.medication_assistance,
        medication_instructions: values.medication.medication_instructions,
        instructions: values.medication.instructions,
        dose_regime: values.medication.dose_regime,
        frequency: values.medication.frequency,
        important: values.medication.important,
        prescription_volume: values.medication.prescription_volume,
        reason: values.medication.reason,
        potential_side_effects: values.medication.potential_side_effects,
        comments: values.medication.comments,
        administration_area: values.medication.administration_area,
        marking_style: values.medication.marking_style,
        storage_location: values.medication.storage_location,
        prescription_expiry_date: values.medication.prescription_expiry_date,
        administration_support: values.medication.administration_area,
        photo: null,
        medication_status_id: values.medication.medication_status,
        // dose: {
        //   dose_id: values.medication.medication_dose,
        //   medication_dose_timing: values.medication.medication_dose_timing,
        //   medication_dose_time: values.medication.medication_dose_time,
        //   medication_dose_end_time: values.medication.medication_dose_end_time,
        //   instructions: values.medication.instructions
        // },
        medication_dose_timing_id: values.medication.medication_dose_timing,
        medication_dose_time: values.medication.medication_dose_time,
        medication_dose_end_time: values.medication.medication_dose_end_time,
        body_part: values.medication_body_part || [],
        // New fields Added
        strength: values.strength,
        min_interval_between_doses: values.min_interval_between_doses,
        max_dose_in_24_hours: values.max_dose_in_24_hours,
        medication_purpose: values.medication_purpose,
        symptoms_to_look_for: values.symptoms_to_look_for,
        client_need_medication_criteria: values.client_need_medication_criteria,
        alternative_administering_interventions: values.alternative_administering_interventions,
        medication_duration: values.medication_duration,
        healthcare_professional_guidance_criteria: values.healthcare_professional_guidance_criteria,
        completed_by: values.completed_by,
        // signature: values.signature || null,
        date: values.date,
      }


    if (values.medication.id) {
      body.id = values.medication.id;
      body.actual_start_date = values.medication.actual_start_date;
      body.actual_medication_dose_time = values.medication.actual_medication_dose_time;
      body.feedback = values.medication.feedback;
      body.medication_reason = values.medication.medication_reason;
    }

    if (values.medication.medication_type_name === 'PRN') {
      body.is_prn = true;
      body.medication_schedule_id = values.medication.medication_schedule_id;
    }

    if (!isValid && errors.length > 0) {
      setCheckRequires(true);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: InvalidOrEmptyValues,
      });
    }
    else {
      setMedClickEvent(body, values.medication.client)
        .then(response => {
          setLoadingSaveMedication(false)
          if (response.status === "SUCCESS") {
            if (values.medication.id) {
              setEditMedication(false);
            } else {
              setAddMedication(false)
            }
            searchScheduler()
          }
        })
        .catch(() => { });
    }
  }

  const handleBulkEdit = () => {
    if (!values.medication.instructions &&
      !values.medication.medication_type &&
      !values.medication.administration_type &&
      !values.medication.administration_reason &&
      !values.medication.medication_route &&
      !values.medication.medication_form &&
      !values.medication.medication_assistance &&
      !values.medication.medication_status &&
      !values.medication.actual_start_date &&
      !values.medication.actual_medication_dose_time) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    setErrorMessage('');
    setCheckRequires(false);

    const tempList = selectedElId? [selectedElId?.event?._def?.publicId || selectedElId]:checkedRows

    let body = {
      instructions: values.medication.instructions,
      medication_type: values.medication.medication_type,
      administration_type: values.medication.administration_type,
      administration_reason: values.medication.administration_reason,
      medication_route: values.medication.medication_route,
      medication_form: values.medication.medication_form,
      medication_assistance: values.medication.medication_assistance,
      medication_status_id: values.medication.medication_status,
      actual_start_date: values.medication.actual_start_date,
      actual_medication_dose_time: values.medication.actual_medication_dose_time,
      medication_id_list: [...tempList]
    }

    setMedBulkEdit(body)
      .then(response => {
        // debugger
        // if (response.status === "SUCCESS") {
        setBulkEditMedication(false);
        clearSelectedElId()
        searchScheduler()
        //}
      })
      .catch(() => { });
  }

  const handleBulkReview = () => {
    if (!values.medication.review_status || !values.medication.review_time || !values.medication.review_date) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    setErrorMessage('');
    setCheckRequires(false);

    const tempList = selectedElId? [selectedElId?.event?._def?.publicId || selectedElId]:checkedRows
    let body = {
      review_status: values.medication.review_status,
      review_comment: values.medication.review_comment,
      review_time: values.medication.review_time,
      review_date: values.medication.review_date,
      medication_id_list: [...tempList]
    }

    setMedReview(body)
      .then(response => {
        if (response.status) {
          setBulkReviewMedication(false);
          clearSelectedElId()
          searchScheduler()
        }
      })
      .catch(() => { });
  }

  const deleteBulkMedication = () => {
    const tempList = selectedElId? [selectedElId?.event?._def?.publicId || selectedElId]:checkedRows
    
    setBulkDeleteMedication(tempList)
      .then((res) => {
        setModalBulkDelete(false);
        clearSelectedElId()
        searchScheduler();
      })
  }
  const keys = [
    'min_interval_between_doses',
    'max_dose_in_24_hours',
    'medication_purpose',
    'symptoms_to_look_for',
    'client_need_medication_criteria',
    'medication_duration',
    'strength',
    'completed_by',
    'date',
    'healthcare_professional_guidance_criteria',
    'alternative_administering_interventions']
  const addMedicationFucn = ({ id, extendedProps }) => {
    setAddMedication(true);
    keys.forEach((key) => {
      values[key] = ''
    })
    values.medication = {}
    values.medication_body_part = []
    setValues({ ...values })
  }

  const eventClickHandler = ({ id, extendedProps }) => {
    setEditMedication(true);
    setValues({ start_date: values.start_date, end_date: values.end_date })
    getMedicationDoseById(id, extendedProps.clientId)
      .then((res) => {
        if (res.id) {
          values.medication = {}
          values.medication = { ...res }
          values.medication_body_part = res?.body_part || []
          setValues({ ...values, ...res })
        }
      })
      .catch((err) => { })
  }

  const handleBulkEditOnClick = () => {
    setBulkEditMedication(true)
    values.medication = {}
    setValues({ ...values })
  }

  const handleBulkReviewOnClick = () => {
    setBulkReviewMedication(true)
    values.medication = {}
    setValues({ ...values })
  }

  const handleMedicationErrorOnClick = () => {
    setMedicationError(true)
    values.medication = {}
    setValues({ ...values })
  }
  const dropDownHandleSelect = (item, nameItem, index) => {
    if (nameItem == 'client') {
      getMedicationNameList(item[nameItem])
    }
    if (nameItem == 'medication_dose_timing') {
      values.medication[nameItem] = item[nameItem].value;
      values.medication.medication_dose_timing_name = item[nameItem].label;
      values.medication.medication_dose_time = item[nameItem].start_time;
      values.medication.medication_dose_end_time = item[nameItem].end_time;
    } else if (nameItem == 'medication_type') {
      values.medication.medication_type_name = item[nameItem]?.name
      values.medication[nameItem] = item[nameItem]?.id
    } else if (nameItem == 'name') {
      values.medication[nameItem] = item[nameItem].id;
      values.medication.medication_schedule_id = item[nameItem].med_sch_id;
    } else {
      values.medication[nameItem] = item[nameItem];
    }

    setValues({ ...values }); //item added

  };

  const dropDownHandleSelectFilter = (item) => {
    setValues({ ...values, ...item });
  };

  const setEmloyeefilter = (item) => {
    setEmployeesFilter([...item])
  }

  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }

  const handleMedicationNameFilter = (item) => {
    setMedicationNameFilter([...item])
  }

  const handleMedicationStatusFilter = (item) => {
    setMedicationStatusFilter([...item])
  }

  const setArrayDropdownfilter = (item) => {
    setDropdownArraySelected([...item])
  }

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }

    setrunTableOptionData(data)
  };

  const getView = () => {
    return values.viewType == 'daily' ? [460, 460] :
      values.viewType == 'weekly' ? [850, 850] :
        values.viewType == 'month' ? [2400, 2400] :
          [500, 500]
  }



  console.log(values,"values buttom")

  const [decreaseWidth, setDecreaseWidth] = useState(true);

  const {
    selectedElId, displayMenu, clearSelectedElId, encloseModalBind
  } = useContextMenu(decreaseWidth)
  const medicationMenuItems = [
        {
          name: 'Medication Review',
          onClick: handleBulkReviewOnClick,
        },
        {
          name: 'Edit',
          onClick: handleBulkEditOnClick
        },
        {
          name: 'Delete',
          onClick: function () {
            setModalBulkDelete(true)
          },
        },
        {
          name: 'Medication Error',
          onClick: handleMedicationErrorOnClick
        },
  ]
 
  const rightClickHandler = (arge) => {
    arge.el.addEventListener("contextmenu", (e)=>displayMenu(e, arge))
  }
  const removeRightClickHandler = (arge) => {
    arge.el.removeEventListener("contextmenu", (e)=>displayMenu(e, arge))
  }

const handleDownloadImage = () => {
  const stateData = { shiftType, events, resources: resourcesMedication };
  localStorage.setItem('heavy-print-temp-data', JSON.stringify(stateData));
  window.open('/heavy-print', '_blank');
  };

  return (
    <>
      <ContextMenu menuItems={medicationMenuItems}/>
      <div>
        {
          loading &&
          <Spinner
            spinnerTop={"70%"}
            textTop={'68%'}
          />
        }
        <Toast ref={toast} position="top-right" />
        <SchedulerContainer loading={loading}
        // style={loading ? { overflow: 'hidden', minHeight: '800px' } : {}}
        height={decreaseWidth ?'100%':"auto"}
        >
          {
            decreaseWidth && (
              <>
              <Title>Medication</Title>
              <div className='mt-2'>
                <FiltersContainerRotaShifts>
                  <FiltersLeftGroupRotaShifts>
                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={employeeForFilter ? employeeForFilter : []}
                        setItemsSelected={items => {
                          dropDownHandleSelectFilter({ employees: items });
                          // setEmployeesFilter(items)
                        }}
                        itemsSelected={employeesFilter}
                        setSelectedFilter={setEmloyeefilter}
                        placeHolder={'Employees'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>
                    <FiltersTypesContainerRotaShifts className="mr-2">
                      <CustomDropdown
                        items={clients ? clients : []}
                        setItemsSelected={items => {
                          dropDownHandleSelectFilter({ client: items });
                        }}
                        itemsSelected={clientsFilter}
                        setSelectedFilter={setClientfilter}
                        placeHolder={'Clients'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>
                    {/* )} */}
                  </FiltersLeftGroupRotaShifts>
                  <FiltersTypesContainerRotaShifts className="mr-2">
                    <CustomDropdown
                      items={contextChoices.medication_name || []}
                      setItemsSelected={items => {
                        dropDownHandleSelectFilter({ medication_name: items });
                      }}
                      itemsSelected={medicationNameFilter}
                      setSelectedFilter={handleMedicationNameFilter}
                      placeHolder={'Medication Name'}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}                
                    />
                  </FiltersTypesContainerRotaShifts>
                  <FiltersTypesContainerRotaShifts className="mr-2">
                    <CustomDropdown
                      items={contextChoices.medication_status || []}
                      setItemsSelected={items => {
                        dropDownHandleSelectFilter({ medication_status__id__in: items });
                      }}
                      itemsSelected={medicationStatusFilter}
                      setSelectedFilter={handleMedicationStatusFilter}
                      placeHolder={'Medication Status'}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}                
                    />
                  </FiltersTypesContainerRotaShifts>

                  <FiltersTypesContainerRotaShifts className="mr-2">
                    <DropdownSearchable
                      typeFilter={true}
                      placeHolder={'Visit Links'}
                      options={
                        [
                          { id: 'link', name: 'Linked Visits' },
                          { id: 'unlink', name: 'UnLinked Visits' }
                        ]
                      }
                      selectedOption={values.visit_link}
                      onChange={value =>
                        dropDownHandleSelectFilter({
                          visit_link: value ? value : null,
                        })
                      }
                    />
                  </FiltersTypesContainerRotaShifts>

                  <PrimaryButtonForm minWidth="6rem" onClick={() => searchScheduler()}>
                    Search
                  </PrimaryButtonForm>
                </FiltersContainerRotaShifts>

              </div>
              <div className='d-flex justify-content-between flex-wrap'>
                <ButtonsContainer calendarView={calendarView} listView={listView} style={{ minWidth: '20%' }}>
                  <div className={'calendarView'} style={{ marginTop: '2%', minWidth:"13rem" }}>
                    <button
                      className={'clientView'}
                      onClick={() => {
                        changeCalendar('client')
                      }}>
                      Client View
                    </button>
                    <button style={{ marginRight: '14%' }}
                      className={'listView'}
                      onClick={() => {
                        changeCalendar('list')
                        setListView(true)
                      }}>
                      List View
                    </button>
                  </div>


                </ButtonsContainer>
                {/* <DataContainer> */}

                {/* </DataContainer> */}
                <DataContainer className="mt-0" style={{ height: '6rem', flex: 'none' }}>
                  {!listView &&
                    <>
                      <PrimaryButtonForm minWidth="6rem" style={{ margin: 'auto 5px' }}  onClick={handleDownloadImage}>
                        <span className={'mr-1'}>Export to Image</span>
                      </PrimaryButtonForm>
                      <PrimaryButtonForm minWidth="6rem" style={{ margin: 'auto 5px' }}  onClick={() => setModalViewToExport(true)}>
                        <span className={'mr-1'}>Preview</span>
                      </PrimaryButtonForm>

                      <PrimaryButtonForm minWidth="6rem" style={{ margin: 'auto 5px' }} onClick={addMedicationFucn}>
                        <span className={'mr-1'}>Add Medication</span>
                      </PrimaryButtonForm>
                    </>
                  }

                  {(checkedRows.length > 0 && listView) &&
                    <div class="dropdown mr-3">
                      <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Action</span>
                      </PrimaryButtonForm>
                      <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item"
                          onClick={() => {
                            handleBulkReviewOnClick()

                          }
                          }
                        >Medication Review</a>
                        <a class="dropdown-item"
                          onClick={() => {
                            handleBulkEditOnClick()

                          }
                          }
                        >Bulk Edit</a>
                        <a class="dropdown-item"
                          onClick={() => setModalBulkDelete(true)}
                        >Bulk Delete</a>

                        <a class="dropdown-item"
                          onClick={() => handleMedicationErrorOnClick()}
                        >Medication Error </a>
                      </div>
                    </div>


                  }
                  {listView &&
                    <DropdownTypesContainer className="ml-1 mr-1">
                      <NoSelectAllDropdownColumnSelector
                        items={headerColumns ? headerColumns.filter((item) => !item.removeCusCol) : []}
                        setItemsSelected={items => {
                          dropDownHandleSelectTable({ tableDropdown: items });
                        }}
                        itemsSelected={dropdownArraySelected}
                        setSelectedFilter={(item) => setDropdownArraySelected([...item])}
                        placeHolder={'Column'}
                        isObject
                        allOption

                      />
                    </DropdownTypesContainer>
                  }
                  <div className={`data-container data-container-schedule mt-1`} style={{ marginBottom: '0px' }}>

                    <div className="data mr-2">
                      <p className="fontBold">Clients</p>
                      <div className="contents">
                        <div className="sub-contents">
                          <p>Count</p>
                          <p>{clientResource}</p>
                        </div>
                      </div>
                    </div>
                    <div className="data">
                      <p className="fontBold">Administred</p>
                      <div className="contents">
                        <div className="sub-contents mr-2">
                          <p>Meds</p>
                          <p>{assignedHours}</p>
                        </div>
                      </div>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Planned</p>
                      <div className="contents">
                        <div className="sub-contents mr-2">
                          <p>Meds</p>
                          <p>{unassignedHours}</p>
                        </div>
                      </div>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Linked</p>
                      <div className="contents">
                        <div className="sub-contents mr-2">
                          <p>Meds</p>
                          <p>{linkedMeds}</p>
                        </div>
                      </div>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">UnLinked</p>
                      <div className="contents">
                        <div className="sub-contents mr-2">
                          <p>Meds</p>
                          <p>{unLinkedMeds}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </DataContainer>

              </div>
              </>
            )
          }

          {listView ?
            <MedicationListView
              events={events}
              handleSelectChange={handleSelectChange}
              setCheckedRows={setCheckedRows}
              checkedRows={checkedRows}
              setCheckedValue={setCheckedValue}
              checkedValue={checkedValue}
              choices={contextChoices}
              runTableOptionData={runTableOptionData}
            //eventClickHandler={eventClickHandler}
              displayMenu={displayMenu}
            />
            :
            <TimelineMedicationCalendar
              editMode={false}
              showProgressSection={true}
              resourceAreaHeaderContent={'Medication Chart'}
              resources={resourcesMedication}
              events={events}
              setShiftType={setShiftType}
              shiftType={shiftType}
              values={values}
              setValues={setValues}
              medCalendar={true}
              resourceColoumn={[
                {
                  group: true,
                  field: 'client',
                  headerContent: 'Client'
                },
                {
                  field: 'med',
                  headerContent: 'Medication'
                },
                {
                  field: 'title',
                  headerContent: 'Doses'
                }
              ]}
              eventClickHandler={data => {
                eventClickHandler(data);
              }}
              useInput={useInput}
              downloadDisable={downloadDisable}
              handleDownlaod={handleDownlaod}
              contextChoices={contextChoices}
              getViewType={type => {
                setValues({...values, viewType : type })}
              }
              decreaseWidth={decreaseWidth}
              setDecreaseWidth={setDecreaseWidth}
              rightClickHandler={(args)=> rightClickHandler(args)}
              removeRightClickHandler={(args)=>removeRightClickHandler(args)}
            />
          }
          {
            addMedication &&
            <AddMedication
              fullBind={fullBindAddMedication}
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              dropDownHandleSelect={dropDownHandleSelect}
              handleSubmit={handleSubmit}
              errorMessage={errorMessage}
              medList={medList}
              setValues={setValues}
              loadingSaveMedication={loadingSaveMedication}
            />
          }
          {
            editMedication &&
            <EditMedication
              clientId={values?.medication?.client}
              fullBind={fullBindEditMedication}
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              dropDownHandleSelect={dropDownHandleSelect}
              handleSubmit={handleSubmit}
              medList={medList}
              setValues={setValues}
              loadingSaveMedication={loadingSaveMedication}
            />
          }
          {
            bulkEditMedication &&
            <BulkEditMedication
              fullBind={()=>encloseModalBind(fullBindBulkEditMedication)}
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              dropDownHandleSelect={dropDownHandleSelect}
              handleBulkEdit={handleBulkEdit}
              errorMessage={errorMessage}
              showError={showError}
            />
          }
          {
            MedicationError &&
            <MedicationErrorType
              fullBind={()=>encloseModalBind(fullBindMedicationError)}
              useInput={useInput}
              data={values}
              choices={contextChoices}
              editMode={true}
              errorMessage={errorMessage}
              showError={showError}
              dropDownHandleSelect={dropDownHandleSelect}
              checkedRows={selectedElId? [selectedElId?.event?._def?.publicId || selectedElId]:checkedRows}
              setMedicationError={setMedicationError}
              clearSelectedElId={clearSelectedElId}
            />
          }
          {
            bulkReviewMedication &&
            <BulkReviewMedication
              fullBind={()=>encloseModalBind(fullBindBulkReviewMedication)}
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              errorMessage={errorMessage}
              showError={showError}
              dropDownHandleSelect={dropDownHandleSelect}
              handleBulkReview={handleBulkReview}
            />
          }

          {modalViewToExport && (
            <ViewToExport
            fullBind={fullBindViewToExport}
            getView={getView}
            setModalValue={setModalViewToExport}
            values={values}
            />
          )}
          <ConfirmDecision
            type="confirm"
            title="Confirm"
            body={
              'Are you sure you want to delete this medication? '
            }
            onOk={() => {
              deleteBulkMedication()
            }
            }
            onNo={
              () => {
                clearSelectedElId();
                setModalBulkDelete(false)
              }
            }
            {...bindModalBulkDelete}
          />
        </SchedulerContainer>
      </div>
    </>
  );
};



export default MedicationCalendar;
