import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import jsPDF from 'jspdf'
import React from 'react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { getDownloadMedicationPdf, getDownloadMedicationPdfCalendar } from 'utils/api/ClientApi'
import ReactHtmlParser from 'react-html-parser';
import { useRef } from 'react'
import './ViewToExport.css'
import moment from 'moment'
import html2canvas from 'html2canvas'

function ViewToExport({ values, fullBind, setModalValue, clientId }) {

    const [disableExport, setDisableExport] = useState(false)
    const [exportToPDF, setExportToPDF] = useState(<div></div>)
    const [loading, setLoading] = useState(true)
    const [printLoader,setPrintLoader] = useState(false)
    const exportRef = useRef(null)

    // const handleDownlaodPdf = () => {
    //     // let clientId=cliId?cliId:selectedClientId
    //     (values, 0, selectedClientId)
    //       .then(response => {
    //         response.text().then((pdfData) => {
    //           //console.log('pdf', pdfData.replace('<th></th>',''))
    //           const doc = new jsPDF({
    //             orientation: "landscape",
    //             format: [600, 600]
    //           });
    //           // Adding the fonts.
    //           const parser = new DOMParser();
    //           const html = parser.parseFromString(pdfData, 'text/html');
    
    //           const body = html.body;
    
    //           body.style.fontSize = "10px";
    //           body.style.width = "100%";
    
    
    //           doc.html(body, {
    //             async callback(doc) {
    //               await doc.save('document');
    //             },
    //           });
    //         })
    
    //       })
    //       .catch(error => {
    //       });
    //   };

    const getExportMedicationPdf = () => {
        getDownloadMedicationPdf(values, 0, clientId)
            .then(response => {
                response.text().then((pdfData) => {
                    setExportToPDF(pdfData)
                    setModalValue(true)
                    setLoading(false)
                })
            }).catch(err => err)
    }

    useEffect(() => {
        getExportMedicationPdf()
    }, [])

    // const handleDownlaodPdf = () => {
    //     setDisableExport(true)
    //     const doc = new jsPDF({
    //         orientation: "landscape",
    //         format: getView()
    //     });
    //     // Adding the fonts.
    //     doc.setFontSize(5)
    //     doc.setFont('Inter-Regular', 'normal');
    //     handleExportRes(doc, exportToPDF)
    
    // };

    const handleDownlaodPdf = () => {
        const input = document.querySelector('#export-data');
        setDisableExport(true)
        setTimeout(() => {
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                });
                let position = 0;
                let pageHeight = 210;
                let imgProps = pdf.getImageProperties(imgData);
                let pdfWidth = pdf.internal.pageSize.getWidth();
                let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                var heightLeft = pdfHeight;
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - pdfHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                    heightLeft -= pageHeight;
                }
                const current_date = moment().format('DD-MM-YYY HH:mm')
                pdf.save(`client_medication_${current_date}`);
                console.log("yaha tak pahucha")
                setDisableExport(false)
            });
        },1)
    };

    // const handleExportRes = (doc, pdfData) => {
    //     setDisableExport(true)
    //     setTimeout(() => {
    //     try {
    //         doc.html(pdfData, {
    //             html2canvas: {
    //                 allowTaint: true,
    //                 useCORS: true,
    //             },
    //             callback: async (doc) => {
    //                 await doc.save(`doc.pdf`);
    //                 setDisableExport(false)
    //             }
    //         });

    //         setDisableExport(false)
    //     } catch (error) {
    //         setDisableExport(false)
    //         console.error(error);
    //     }
    // },1)
    // }

    // const printElement = () => {
    //     const element = document.getElementById("export-data")
    //     const myframe = document.createElement('iframe');
    //     setPrintLoader(false)
    //     myframe.style.position = 'absolute';
    //     myframe.style.top = '-10000px';
    //     document.body.appendChild(myframe);
    //     myframe.contentDocument.write(element.innerHTML);
    //     setTimeout(() => {
    //       myframe.focus();
    //       myframe.contentWindow.print();
    //       myframe.parentNode.removeChild(myframe); 
    //       setPrintLoader(false)
    //     }, 1000);
    //     window.focus();
    //   };
    console.log(disableExport, "disableExport")
    return (
        <ModalContainer max_width={values.viewType == "month" ?  "98%": '65%'}>
            <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={loading}>
                <div className='d-flex w-100 justify-content-end mr-2'>
                    <div>
                    <PrimaryButtonForm minWidth="6rem"
                        style={{ margin: 'auto 5px' }}
                        disabled={disableExport}
                        onClick={handleDownlaodPdf}>
                        <span className={'mr-1'}>Export To PDF</span>
                        {disableExport ? (
                            <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                            />
                        ) : <Fragment />}
                    </PrimaryButtonForm>
                    </div>
                    {/* <div>
                    <PrimaryButtonForm minWidth="6rem"
                        style={{ margin: 'auto 5px' }}
                        disabled={printLoader}
                        onClick={printElement}>
                        <span className={'mr-1'}>Print</span>
                        {printLoader ? (
                            <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                            />
                        ) : <Fragment />}
                    </PrimaryButtonForm>
                    </div> */}
                </div>
                <div id="export-data" ref={exportRef} className={values.viewType == 'month' ? "client_table_container_monthly" :'client_table_container'}>
                    {exportToPDF && ReactHtmlParser(exportToPDF)}
                </div>
            </ModalNoInfo>

        </ModalContainer>
    )
}

export default ViewToExport