import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  DayPill,
  ColsGrouper,

} from 'components/SharedComponents/Table/styles';
import "./style.css"
import Table from 'components/SharedComponents/Table';
import {
  P_W_Zero,
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import moment from 'moment';
import { getDaysArray, getTimesArrayFormat } from 'utils/Array';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { Fragment } from 'react';


// const headerColumns = [
//   {
//     label: '',
//     name: 'checkbox',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: P_W_Zero,
//   },
//   {
//     label: 'FULLNAME',
//     name: 'Client_Service__Client__full_name',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'Day',
//     name: 'day',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'PREFERRED START TIME -  END TIME',
//     name: 'preferred_start_time',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'DURATION',
//     name: 'preferred_start_time',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'EARLIEST START TIME',
//     name: 'preferred_start_time',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'LATEST START TIME',
//     name: 'preferred_start_time',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'Address',
//     name: 'address',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'POSTCODE',
//     name: 'postcode',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'EMP NO.',
//     name: 'noofemployees',
//     status: 0,
//     NoFlexGrow: true,
//     shrink: false,
//     className: 'grow-mobile',
//     max_width: P_W_Zero,
//     overlap: true
//   },
//   {
//     label: 'GENDER',
//     name: 'gender',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'Skills',
//     name: 'skills',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'COMPETENCIES',
//     name: 'competencies',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'Avoid Employee',
//     name: 'avoid_employee',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'RUN NAME',
//     name: 'runname',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: LARGE_COL,
//     overlap: true
//   },
//   {
//     label: 'START DATE',
//     name: 'startdate',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   },
//   {
//     label: 'END DATE',
//     name: 'enddate',
//     status: 0,
//     className: 'grow-mobile',
//     max_width: EX_SMALL_COL,
//     overlap: true
//   }

// ];

const timesArray = getTimesArrayFormat();
const daysArray = getDaysArray();

const AddingVisitRun = ({
  values,
  setValues,
  loadingModal,
  choices,
  clientsData,
  runTableOptionData
}) => {
  //let dataForClient = clientsData;
  const [clientTableData, setClientTableData] = useState([]);
  const [arrayDaysData, setArrayDaysData] = useState([]);
  const [timeSelected, setTimeSelected] = useState('');
  
  const headD = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL4,
    },
    {
      label: 'FULLNAME',
      name: 'fullname',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Day',
      name: 'day',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    },
    {
      label: 'PREFERRED START TIME -  END TIME',
      name: 'preferred_start_time',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'DURATION',
      name: 'duration',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'EARLIEST START TIME',
      name: 'earlist_start_time',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    },
    {
      label: 'LATEST START TIME',
      name: 'latest_start_time',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    },
    {
      label: 'Address',
      name: 'address',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'POSTCODE',
      name: 'postcode',
      status: 0,
      custum_column: true,
      max_width: SMALL_COL,
      overlap: true
    },
    {
      label: 'EMP NO.',
      name: 'noofemployees',
      status: 0,
      custum_column: true,
      NoFlexGrow: true,
      shrink: false,
      max_width: P_W_Zero,
      overlap: true
    },
    {
      label: 'GENDER',
      name: 'gender',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    },
    {
      label: 'Skills',
      name: 'skills',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'COMPETENCIES',
      name: 'competencies',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Avoid Employee',
      name: 'avoid_employee',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'RUN NAME',
      name: 'runname',
      status: 0,
      custum_column: true,
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'START DATE',
      name: 'startdate',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    },
    {
      label: 'END DATE',
      name: 'enddate',
      status: 0,
      custum_column: true,
      max_width: EX_SMALL_COL,
      overlap: true
    }
  
  ]

  

  useEffect(() => {
    setClientTableData(clientsData);
    setArrayDaysData(daysArray.filter(w => w.id !== ''))
  }, [clientsData])



  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getTimeVisit = visit => {
    const time =
      moment(visit.start_time, 'HH:mm').format('HH:mm') +
      ' - ' +
      moment(visit.end_time, 'HH:mm').format('HH:mm');
    return time;
  };
  
  const handleAllSelectChange = (valcheck) => {
    if (!values.visits) {
      values.visits = [];
    }
    if (valcheck.target.checked) {
      clientsData.map((visit)=>{
        values.visits.push({ ...visit, visit_id: `${visit.client_service_visit}${visit.day}${visit.requirement}` })
      })
    } else {
      clientsData.map((visit)=>{
        values.visits.map((item, index) => {
          if (item.visit_id === `${visit.client_service_visit}${visit.day}${visit.requirement}`) {
            values.visits.splice(index, 1);
          }
        })
      })
    }
    setValues({ ...values })
  }

  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    if (!values.visits) {
      values.visits = [];
    }
    if (valcheck.target.checked) {
      values.visits.push({ ...visit, visit_id: `${visit.client_service_visit}${visit.day}${visit.requirement}` })
    } else {
      values.visits.map((item, index) => {
        if (item.visit_id === `${visit.client_service_visit}${visit.day}${visit.requirement}`) {
          values.visits.splice(index, 1);
        }
      })
    }
    setValues({ ...values })
    //setClients(arr);
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists=(id)=>{
      return values.visits.some(function(el) {
        return el.visit_id === id;
      });
    }
    if (values.visits && values.visits.length > 0) {
        clientsData.map((visit)=>{
          if(!visitExists(`${visit.client_service_visit}${visit.day}${visit.requirement}`)){
            checkAll = false
          }
        })
    }else{
      checkAll = false;
    }
    return checkAll;
  }

  const handleCheck = (visit) => {
    let check = false;
    if (values.visits) {
      values.visits.map((item) => {
        if (`${visit.client_service_visit}${visit.day}${visit.requirement}` === item.visit_id) {
          check = true
        }
      })
    }
    return check;
  }

  const getSkillName = (skills) => {
    let data = []
    if (skills && choices && choices.skill) {
      skills.forEach(detail => {
        choices.skill.forEach(val => {
          if (detail.skill === val.id) {
            data.push({
              name: val.name
            })
          }
        })
      })
    }
    return data;
  }

  const getCompetencyName = (competency) => {
    let data = []
    if (competency && choices && choices.competency_name) {
      competency.forEach(detail => {
        choices.competency_name.forEach(val => {
          if (detail.competency === val.id) {
            data.push({
              name: val.name
            })
          }
        })
      })
    }
    return data;
  }

  const getGenderName = (genderId) => {
    let data = '';
    if (genderId && choices && choices.gender) {
      choices.gender.forEach(val => {
        if (genderId === val.id) {
          data = val.name
        }
      })
    }
    return data;
  }

  useEffect(() => {
    if (values.preferred_start_time && values.preferred_end_time) {
      let start_time = moment(values.preferred_start_time, 'HH:mm:ss');
      let end_time = moment(values.preferred_end_time, 'HH:mm:ss');

      if (start_time.isAfter(end_time) && timeSelected === 1) {
        values.preferred_start_time = values.preferred_end_time;
        start_time = end_time;
      }

      if (end_time.isBefore(start_time) && timeSelected === 2) {
        values.preferred_end_time = values.preferred_start_time;
        end_time = start_time;
      }
    }

    timesArray.forEach(timeValue => {
      if (timeSelected === 1 && timeValue.id === values.preferred_start_time) {
        values.start_time = timeValue.name;
      } else if (
        timeSelected === 2 &&
        timeValue.id === values.preferred_end_time
      ) {
        values.end_time = timeValue.name;
      }
    });

    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.preferred_start_time, values.preferred_end_time]);



  const calculateDuration = duration => {
    if (duration && typeof duration === 'string') {
      const res = duration.split(':').reduce((acc, time) => 60 * acc + +time);

      const d = Number(res);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);

      const hDisplay = h > 0 ? h + 'h' : '';
      const mDisplay = m > 0 ? m + 'm' : '';

      return hDisplay + ' ' + mDisplay;
    } else {
      return duration;
    }
  };


  return (
    <>
      <ComponentDynamic
        loading={loadingModal}
      >
        <Table values={runTableOptionData} headerColumns={headD} handleAllSelectChange={handleAllSelectChange} handleAllCheck={handleAllCheck} callBackQuerySort={setQuerySort} headerPadding={false} noEditOption={true} noNeed={false}>
          <div>
            {clientsData && clientsData.map((visit, index) => {
              const latestStartTime = visit.latest_start_time
                ? moment(visit.latest_start_time, 'HH:mm').format('HH:mm')
                : 'Unset';
              const earliestStartTime = visit.earliest_start_time
                ? moment(visit.earliest_start_time, 'HH:mm').format('HH:mm')
                : 'Unset';



              return (
                <div key={index}>
                  
                  <Row bgColor Shadow>
                    <ColsGrouper
                      className="d-flex"
                    >
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("checkbox")) && 'd-none' }
                        max_width={EX_SMALL_COL4}
                        Shrink={false}
                        NoFlexGrow={true}
                        reducePadding
                        Capitalize
                        Center

                      >
                        <input class="form-check-input" type="checkbox" checked={handleCheck(visit)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, visit, index) }} />

                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("fullname")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.client_name
                          ? visit.client_name
                          : ''}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("day")) ? 'd-none' : 'visits-custom-row sm-hidden visit-days' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center 
                        style={{ maxWidth: '5%' }}
                      >
                        {visit.day&&visit.day?(
                        <DayPill
                        className={!(runTableOptionData && runTableOptionData.includes("day")) }
                          key={`day_pill_` + visit.day}
                          Active={true}>
                          {visit.day}
                        </DayPill>):<Fragment/>}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("preferred_start_time")) && 'd-none' }
                        max_width={EX_LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {getTimeVisit(visit) || 'Unset'}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("duration")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {calculateDuration(visit.duration)}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("earlist_start_time")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {earliestStartTime}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("latest_start_time")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {latestStartTime}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("address")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.address.line_1}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("postcode")) && 'd-none' }
                        max_width={SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.address.zip_code}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("noofemployees")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink={false}
                        NoFlexGrow={true}
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.no_employees}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("gender")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {getGenderName(visit.gender)}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("skills")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {getSkillName(visit.skill).map((val) => {
                          return val.name + ', '
                        })}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("competencies")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {getCompetencyName(visit.competency).map((val) => {
                          return val.name + ', '
                        })}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("avoid_employee")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.avoid_employee && visit.avoid_employee[0] && visit.avoid_employee[0].employee__full_name}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("runname")) && 'd-none' }
                        max_width={LARGE_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.run_name && visit.run_name.join(",")}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("startdate")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.start_date ? visit.start_date : '--'}
                      </Col>
                      <Col
                        className={!(runTableOptionData && runTableOptionData.includes("enddate")) && 'd-none' }
                        max_width={EX_SMALL_COL}
                        overlap={true}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                      >
                        {visit.end_date ? visit.end_date : '--'}
                      </Col>
                    </ColsGrouper>

                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
          </div>
        </Table>

      </ComponentDynamic>
    </>
  );
}

export default AddingVisitRun;
