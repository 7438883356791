import React, { Fragment } from 'react';
import DynamicList from 'components/SharedComponents/DynamicList';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { FaTimes } from 'react-icons/fa';
import DateSelector from 'components/SharedComponents/DateSelector';
import moment from 'moment';
import { IconWrapper } from 'components/SharedComponents/Table/styles';

import {
  CloseButtonWrapper,
  SkillRow,
  LeftSkillRow,
  BorderContainer,
} from './styles';
import LineContainer from 'components/SharedComponents/LineContainer';
import { DELETE, COPY } from 'utils/constants/icons';
import { TitleSection } from '../AddModal/styles';
import { PURPLE, SOFT_RED, GRAY_300 } from 'shared/styles/constants/colors';
import { AppContext } from 'context';
import { useContext } from 'react';
import { skills_emp_choices } from 'utils/choiceConstant';
import { useEffect } from 'react';

function AddSkills(props) {
  const dropDownHandleSelect = (item, index, attribute) => {
    const newSkills = [...props.employee_skills];
    newSkills[index][attribute] = item;

    props.setSkillsCallback(newSkills);
  };
  const { handleGetChoices } = useContext(AppContext)

  useEffect(() => {
    handleGetChoices(skills_emp_choices)
  }, [])
  const handleInputChange = (e, attribute) => {
    const newSkills = [...props.employee_skills];
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    newSkills[e.target.dataset.idx][attribute] = e.target.value;

    props.setSkillsCallback(newSkills);
  };
  const copySkills = index => {
    props.addSkillCopy(index);
  };

  const getSkillsByCategory = (index) => {
    const filterData = props?.skill_name?.filter(item => item.category === props?.employee_skills[index]?.category)
    if (filterData?.length) {
      return filterData?.length ? filterData : []
    } else {
      const newSkills = [...props?.employee_skills];
      newSkills[index].name = null;
    }

  }

  console.log("skill cate", props)
  return (
    <DynamicList
      title="Skills & Training"
      titleAdd={props.readOnly ? false : 'ADD SKILL'}
      titleSave="SAVE SKILLS"
      addNew={props.addNewSkillCallback}
      save={() => { }}
      noSave={props.noSave}>
      <Fragment>
        {props.employee_skills ? (
          props.employee_skills.map((skill, index) => {
            console.log(props.employee_skills[index].name, "map data")
            return (
              <BorderContainer className={'pl-1 pr-2 pt-3 pb-1 mb-3'}>
                <SkillRow className="d-flex ml-1 mb-2" key={index}>
                  <LeftSkillRow className="d-flex flex-column w-100">
                    <div className={'d-flex mb-2'}>
                      <CloseButtonWrapper>
                        <FaTimes
                          onClick={() => {
                            props.deleteSkillCallback(index);
                          }}
                        />

                        <IconWrapper
                          style={{ marginRight: '10px' }}
                          color={PURPLE}
                          onClick={() => copySkills(index)}>
                          {COPY}
                        </IconWrapper>
                      </CloseButtonWrapper>

                      <div className="w-100 mr-1">
                        <DropdownSearchable
                          placeHolder={'Skill Category'}
                          options={props.skill_Category}
                          disabled={props.readOnly}
                          selectedOption={
                            props.employee_skills[index].category
                              ? props.employee_skills[index].category
                              : null
                          }
                          onChange={value => {
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'category'
                            );
                            getSkillsByCategory(index)
                          }
                          }
                        />
                      </div>
                      <div className="w-100 mr-1">
                        <DropdownSearchable
                          placeHolder={'Skill Name'}
                          options={getSkillsByCategory(index)}
                          disabled={props.readOnly}
                          selectedOption={
                            props.employee_skills[index].name
                              ? props.employee_skills[index].name
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'name'
                            )
                          }
                        />
                      </div>
                      <div className="w-100 mr-1">
                        <DropdownSearchable
                          placeHolder={'Training Type'}
                          options={props.training_type}
                          disabled={props.readOnly}
                          selectedOption={
                            props.employee_skills[index].training_type
                              ? props.employee_skills[index].training_type
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'training_type'
                            )
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Skills Status'}
                          disabled={props.readOnly}
                          options={props.skill_status}
                          selectedOption={skill.status ? skill.status : null}
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'status'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className={'d-flex ml-4'}>
                      <div className="w-100 mr-1">
                        <DateSelector
                          id={`completed_date_${index}`}
                          dataIdx={index}
                          labelTop="Completed Date"
                          value={
                            props.employee_skills[index].completed_date
                              ? props.employee_skills[index].completed_date
                              : ''
                          }
                          onChange={e => {
                            handleInputChange(e, 'completed_date');
                          }}
                        />
                      </div>
                      <div className="w-100 mr-1">
                        <DateSelector
                          id={`expiration_date_${index}`}
                          dataIdx={index}
                          labelTop="Expiration Date"
                          value={
                            props.employee_skills[index].expiration_date
                              ? props.employee_skills[index].expiration_date
                              : ''
                          }
                          onChange={e => {
                            handleInputChange(e, 'expiration_date');
                          }}
                        />
                      </div>
                      <div className="w-100 mr-1">
                        <InputLabelTop
                          type="text"
                          id={`reapplication_frequency_${index}`}
                          readOnly={props.readOnly}
                          label={'Reapplication Frequency'}
                          data-idx={index}
                          value={
                            props.employee_skills[index].reapplication_frequency
                          }
                          onChange={e => {
                            handleInputChange(e, 'reapplication_frequency');
                          }}
                        />
                      </div>
                      <div className="w-100 mr-1">
                        <InputLabelTop
                          type="text"
                          readOnly={props.readOnly}
                          id={`results_${index}`}
                          label={'Results'}
                          data-idx={index}
                          value={props.employee_skills[index].results}
                          onChange={e => {
                            handleInputChange(e, 'results');
                          }}
                        />
                      </div>
                      <div className="w-100 ">
                        <InputLabelTop
                          type="text"
                          id={`skill_notes_${index}`}
                          readOnly={props.readOnly}
                          label={'Notes'}
                          data-idx={index}
                          value={props.employee_skills[index].skill_notes}
                          onChange={e => {
                            handleInputChange(e, 'skill_notes');
                          }}
                        />
                      </div>
                    </div>
                  </LeftSkillRow>
                </SkillRow>
              </BorderContainer>
            );
          })
        ) : (
          <></>
        )}
      </Fragment>
    </DynamicList>
  );
}

export default AddSkills;
