import React from 'react';
import { InputLabelTopStyle } from './InputTopStyle';
import { useRef } from 'react';
import "./MyStyle.css"
import { TiSortNumerically } from 'react-icons/ti';

function MultiSelectBox(props) {
    const myref = useRef(null)
    const isRequired = props.required;
    let haveError = props['data-error'];

    let labelError = <span />;

    if (props['data-error'] && !isRequired && !props.value) {
        haveError = false;
    }

    if (props['data-error'] && !isRequired && props.value) {
        labelError = (
            <label className={'errorMessageHaveValue'}>Invalid format</label>
        );
    }

    if (props['data-error'] && isRequired && !props.value) {
        labelError = (
            <label className={'errorMessage'}>
                {props.error_msg || 'Is required'}
            </label>
        );
    }

    if (props['data-error'] && isRequired && props.value) {
        labelError = (
            <label className={'errorMessageHaveValue'}>
                {props.error_msg || 'Invalid format'}
            </label>
        );
    }

    const handleRemove = (index) => {
        const data = [...props?.data]
        data.splice(index, 1);
        props.setValues(prevValues => ({
            ...prevValues,
            frontBodyParts: {
                tittle: [...data]
            }
        }));
    }


    return (
        
          <InputLabelTopStyle
                no_internal_margin={props.no_internal_margin}
                have_error={haveError}
                type={props.type}
                typeFilter={props.typeFilter}> 

             <input
                    ref={myref}
                    // disabled
                    readOnly={props.readOnly}
                    onKeyPress={props.onKeyPress}
                    onChange={props.onChange}
                    step={'0.01'}
                    // min={'0'}
                    // max={'100'}
                    //added onchange
                    value={props.value} //added value
                    name={props.name}  //added name
                    type={props.type}
                    id={props.id}
                    className={props?.data?.length ? 'filled' : ''}
                    {...props}
                /> 

            <label>
                    {props.label} <span>{isRequired ? '*' : ''}</span>
                </label>

                {labelError}
            </InputLabelTopStyle> 
    );
}
export default MultiSelectBox;
