import React, { useState, useEffect, useContext } from 'react';
import { Title } from "./style"
// import {getCategoryPermission, getPermissions,getStaff,getClient,getEmployee} from '../../../utils/localStorage/user';
import { AppContext } from 'context'
import { PieChart } from 'react-minimal-pie-chart';
import { Chart } from "react-google-charts";

import moment from 'moment';
import { getDasboardTeamKpis } from 'utils/api/DashboardApi';
import { DropdownTypesContainer, ErrorMessage } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Toast } from 'primereact/toast';
import { useForm } from 'hooks/FormHook';
import { useRef } from 'react';
import { Fragment } from 'react';
import { LoadingContainer } from 'components/DashboardComponents/ModalDynamic/styles';
import Spinner from 'components/SharedComponents/Spinner';
import './teamkpi.css'
import { dashboard_choices } from 'utils/choiceConstant';
const TeamKpis = () => {
  //   const[employeeData,setEmployeeData]=useState({})
  let start_date_today = moment().subtract(7, 'days').format('YYYY-MM-DD')
  let end_date_today = moment().format('YYYY-MM-DD')

  const { contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextChoices,handleGetChoices } = useContext(AppContext)
  const { values, setValues, useInput } = useForm({ start_date: start_date_today, end_date: end_date_today, kpi: 'day' });
  const toast = useRef();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  let startDate = useInput('start_date', 'isRequired');
  let endDate = useInput('end_date', 'isRequired');

  useEffect(() => {
    if(contextChoices.kpi){
      loadTeamKpiData()
    }
  }, [contextChoices.kpi])
  console.log('context',contextChoices)
  useEffect(() => {
    handleGetChoices(dashboard_choices)
    
  }, [])
  useEffect(() => {
    if (values.start_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );
      console.log(daysDiff, '9999999');
      if (values.kpi === 'day' && daysDiff > 60) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }

      if (values.kpi === 'month' && daysDiff > 365) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 4567) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
    }
  }, [values.start_date || values.kpi]);

  useEffect(() => {
    if (values.end_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      if (values.kpi === 'day' && daysDiff > 60) {
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }
      if (values.kpi === 'month' && daysDiff > 365) {
        values.start_date = moment(values.end_date)
          .subtract(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.start_date = moment(values.end_date)
          .subtract(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 457) {
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
      setValues({ ...values });
    }
  }, [values.end_date || values.kpi]);

  const loadTeamKpiData = () => {
    if(!loading){
      setLoading(true)
    }
    if (!values.kpi) {
      setShowError(true)
      setErrorMessage('Please select Frequency ');
      return
    }
    getDasboardTeamKpis(values.start_date, values.end_date, values.kpi)
      .then(res => {
        setLoading(false)
        if (res.data) {
          values.one_day_data = null
          if (res.data.length == 1) {
            values.one_day_data = res.data[0];
            setValues({ ...values })
          }else{
            //values.one_day_data = res.data[0];
            values.active_emp = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.activeEmployee || 0, item.activeProbationEmployee || 0]
            })
            values.active_emp_gender = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.maleActiveEmployee || 0, item.femaleActiveEmployee || 0]
            })
            values.available_emp = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.employeeAvailability || 0, item.unavailabilityEmployee || 0, item.employeeShiftPattern || 0]
            })
            values.available_hrs = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.availabilityHrs || 0, item.unavailabilityHrs || 0, item.avgStaffAvailabilityHrs || 0, item.totalOfficeHrs || 0, item.employeeShiftHrs || 0]
            })
            values.absence = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.employeeAbsence || 0, item.holidayAbsence || 0, item.medicalAbsence || 0, item.medicalShortNoticeAbsence || 0, item.otherAbsence || 0, item.otherShortAbsence || 0]
            })
            values.absence_hrs = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.employeeAbsenceHrs || 0, item.holidayAbsenceHrs || 0, item.medicalAbsenceHrs || 0, item.medicalShortNoticeAbsenceHrs || 0, item.otherAbsenceHrs || 0, item.otherShortAbsenceHrs || 0]
            })
            setValues({ ...values })
          }
          
        }
      })
  }

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });

    if (item.kpi) {

      setShowError(false)
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      var month = moment(values.end_date).diff(
        moment(values.start_date),
        'months'
      );

      if (daysDiff > 60 && item.kpi === 'day') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
      }
      if (daysDiff > 365 && item.kpi === 'month') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }

      if (daysDiff > 91 && item.kpi === 'week') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      if (daysDiff > 457 && item.kpi === 'quarter') {
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      setValues({ ...values, ...item });
    }
  };

  const dataChartMaleAndFemale = [
    ["Male", "Female"],
    ["Male", values.one_day_data ? values.one_day_data.maleActiveEmployee : 0],
    ["Female", values.one_day_data ? values.one_day_data.femaleActiveEmployee : 0],
  ];

  const dataChartTotalCareAndNonCare = [
    ["Care", "Non-Care"],
    ["Care", values.one_day_data ? values.one_day_data.totalCare : 0],
    ["Non-Care", values.one_day_data ? values.one_day_data.totalNonCare : 0],
  ];

  const dataAbsenceHrs = [
    ["Tasks", "Absence Count"],
    ["Employee Hrs", values.one_day_data ? values.one_day_data.employeeAbsenceHrs : 0],
    ["Holiday Hrs", values.one_day_data ? values.one_day_data.holidayAbsenceHrs : 0],
    ["Medical Hrs", values.one_day_data ? values.one_day_data.medicalAbsenceHrs : 0],
    ["Medical Short Notice Hrs", values.one_day_data ? values.one_day_data.medicalShortNoticeAbsenceHrs : 0],
    ["Other Hrs", values.one_day_data ? values.one_day_data.otherAbsenceHrs : 0],
    ["Other Short Hrs", values.one_day_data ? values.one_day_data.otherShortAbsenceHrs : 0],
  ];

  const dataAbsence = [
    ["Tasks", "Absence Count"],
    ["Employee", values.one_day_data ? values.one_day_data.employeeAbsence : 0],
    ["Holiday", values.one_day_data ? values.one_day_data.holidayAbsence : 0],
    ["Medical", values.one_day_data ? values.one_day_data.medicalAbsence : 0],
    ["Medical Short Notice", values.one_day_data ? values.one_day_data.medicalShortNoticeAbsence : 0],
    ["Other", values.one_day_data ? values.one_day_data.otherAbsence : 0],
    ["Other Short", values.one_day_data ? values.one_day_data.otherShortAbsence : 0],
  ];


  const dataBar = [
    ["Date", "value"],
    ["January", 1000],
    ["Feb", 1170],
    ["March", 660],
    ["April", 342],
    ["May", 245],
    ["June", 1030],
    ["July", 657],
    ["August", 535],
    ["September", 1030],
    ["Octobor", 768],
    ["November", 465],
    ["December", 30],
  ];

  const optionsBar = {
    chart: {
      title: "Active employee vs Active probation employee",
      //subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };
  const optionsBarAvailability = {
    chart: {
      title: "Employee availability",
    },
   //chartArea:{left:20,top:10,width: '100%', height: '80%'}
  };
  const optionsLineAvailability = {
      title: "Employee availability",
      curveType: "function",
      legend: { position: "top", maxLines: 5 },
  };

  const optionsLineAvailabilityHrs = {
    title: "Employee availability hrs",
    curveType: "function",
    legend: { position: "top", maxLines: 5 },
};

  const optionsChartMaleAndFemale = {
    title: "Male and Female active employees",
    pieHole: 0.4,
    is3D: false,
    // slices: [{color: ''},{color: 'red'}]
  };

  const optionsChartTotalCareAndNonCare = {
    title: "Total care and non-care",
    pieHole: 0.4,
    is3D: false,
  };
  const optionsAbsence = {
    title: "Absence Count",
  };

  const optionsAbsenceHours = {
    title: "Absence Hours",
  };

  return (
    <div>
      {loading ? (
        <LoadingContainer maxHeight={null}>
          <Spinner />
        </LoadingContainer>
      ) : (
        <>
        <Toast ref={toast} position="top-right" />
      <Title>Team KPIs</Title>
      <div className="d-flex mt-2">
        <DropdownTypesContainer className="mr-2">
          {' '}
          <DateSelector
            isRequired
            // data-error={true}
            typeFilter={true}
            labelTop="Date from"
            value={values.start_date ? values.start_date : ''}
            //onChange={(val)=>onDateChange(val)}
            {...startDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="mr-2">
          {' '}
          <DateSelector
            isRequired
            typeFilter={true}
            labelTop="Date to"
            value={values.end_date ? values.end_date : ''}
            // minDate={values.date_from}
            {...endDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="mr-2">
          <DropdownSearchable
            typeFilter
            required
            placeHolder={'Frequency'}
            options={contextChoices && contextChoices.kpi}
            selectedOption={values.kpi}
            onChange={value =>
              dropDownHandleSelect({ kpi: value ? value : null })
            }
          />
        </DropdownTypesContainer>
        <PrimaryButtonForm minWidth="6rem" onClick={() => loadTeamKpiData()}>
          Search
        </PrimaryButtonForm>
      </div>
      <div className={'mb-3'}>
        {showError ? (
          <ErrorMessage>
            <label className={'mt-2'}>{errorMessage}</label>
          </ErrorMessage>
        ) : (
          <Fragment />
        )}
      </div>
      {values.one_day_data ?
        <div>
        <div className='mt-3'>
          <div className="d-flex justify-content-between">
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Active Employee</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.activeEmployee}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Active Probation Emp</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.activeProbationEmployee}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Availability Hrs</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.availabilityHrs}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Office Hrs</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalOfficeHrs}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Avg Staff Availability Hrs</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.avgStaffAvailabilityHrs}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-3'>
          <div className="d-flex justify-content-between">
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Leaver Employee</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.leaverEmployee}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Unavailability Employee</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.unavailabilityEmployee}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Unavailability Hrs</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.unavailabilityHrs}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">New Employee Hired</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.newEmployeeHired}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">No. Active & Probation</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.noActiveAndProbation}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-2'>
          <div className="row">
          {values.one_day_data && !((values.one_day_data.totalCare === 0) && (values.one_day_data.totalNonCare === 0)) && 
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataChartTotalCareAndNonCare}
                  options={optionsChartTotalCareAndNonCare}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>
          }
            {values.one_day_data && !((values.one_day_data.maleActiveEmployee === 0) && (values.one_day_data.femaleActiveEmployee === 0)) && 
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataChartMaleAndFemale}
                  options={optionsChartMaleAndFemale}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>}
          </div>
        </div>
        <div className='mt-2'>
          <div className="row">
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataAbsence}
                  options={optionsAbsence}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataAbsenceHrs}
                  options={optionsAbsenceHours}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        :

        <div>
         
          {values.available_emp && <div className='mt-2'>
            <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{left:20,top:20,position:'relative'}}
                data={[["Date", "Availability Employee", 'Unavailability Employee', "Employee Shift Pattern"],...values.available_emp]}
                options={optionsBarAvailability}
              />
            </div>
          </div>}
         <div className='row mt-2'>
         {values.active_emp && <div className='col-6'>
           <div className='chart_box'> 
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{left:20,top:20,position:'relative'}}
                data={[["Date", "Active Employee", 'Active Probation Employee'],...values.active_emp]}
                options={optionsBar}
              />
            </div>
          </div>}
          {values.available_hrs && <div className='col-6'>
            <div className='chart_box'> 
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={[["Dates", "Availability Hrs", 'Unavailability Hrs', "Avg Staff Availability Hrs", "Total Office Hrs", "Employee Shift Hrs"],...values.available_hrs]}
                options={optionsLineAvailabilityHrs}
              />
            </div>
          </div>}
         </div>
          {values.active_emp_gender && <div className='mt-2'>
          <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{left:20,top:20,position:'relative'}}
                data={[["Date", "Male Active Employee", 'Female Active Employee'],...values.active_emp_gender]}
                options={{
                  chart: {
                    title: "Male active emp vs Female active emp",
                  },
                  legend: { position: "none",alignment: 'end' },
                }}
              />
            </div>
          </div>}
         <div className='row mt-2'>
         {values.absence && <div className='col-6'>
            <div className='chart_box'> 
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={[["Dates", "Employee Absence", 'Holiday Absence', "Medical Absence", "Medical Short Notice Absence", "Other Absence", "Other Short Absence"],...values.absence]}
                options={optionsLineAvailability}
              />
            </div>
          </div>}
          {values.absence_hrs && <div className='col-6'>
            <div className='chart_box'> 
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={[["Dates", "Employee Absence Hrs", 'Holiday Absence Hrs', "Medical Absence Hrs", "Medical Short Notice Absence Hrs", "Other Absence Hrs", "Other Short Absence Hrs"],...values.absence_hrs]}
                options={optionsLineAvailabilityHrs}
              />
            </div>
          </div>}
         </div>
        </div>
      }
        </>
      )}
    </div>
  );
};

export default TeamKpis;
