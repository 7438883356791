import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED,InvalidOrEmptyValues} from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {  PublicHoliday,  setPublicHolidayById, } from 'utils/api/SettingsApi';
import { stringify } from 'query-string';
import InputColorPicker from 'components/SharedComponents/InputColorPicker'; 
import DateSelector from 'components/SharedComponents/DateSelector';
// import {AppContext} from "context"

const AddMoal = ({
  fullBind,
  idSelected,
  setTitle,
  loadPublicHoliday,
  setPublicHolidayModalValue,
  contextChoices,
  countryData,
}) => {

  const {
    values, setValues, useInput,isValid, errors,  setFormErrors,setCheckRequires
  } = useForm({  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
const [skillAddedMessage, setSkillAddedMessage] = useState('');
const [holidayType, setHolidayType] = useState([]);
const [rateCode, setRateCode] = useState([]);
const [region, setRegion] = useState([]);
const [branch, setBranch] = useState([])
const [calendarYear, setCalendarYear] = useState( )
const [rateCodeName, setRateCodeName] = useState( )
const edit_holiday = 'Edit Holiday';
const new_holiday = 'Add Holiday';


  useEffect(() => {
    if (idSelected) {
      setTitle(edit_holiday);
    } else {
      setTitle(new_holiday);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  useEffect(()=>{
  
    setHolidayType(contextChoices && contextChoices.rate_code && contextChoices.rate_code.map(y=>{
      return{ id: y.id  ,  name: y.description}
  }) )
  setRateCode(contextChoices && contextChoices.rate_code && contextChoices.rate_code.map(y=>{
      return{ id: y.id  ,  name: y.name}
  }) )  
  setRegion(contextChoices && contextChoices.region && contextChoices.region.map(y=>{
    return{ id: y.id  ,  name: y.name}
}) ) 
setBranch(contextChoices && contextChoices.branch && contextChoices.branch.map(y=>{
  return{ id: y.id  ,  name: y.name}
}) ) 
  },[contextChoices])

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


useEffect(()=>{
  if (idSelected) {
    loadPublicHolidayById();
  }
},[])

console.log(values,"AAAAA")
  const loadPublicHolidayById = async() => {
    const response =await setPublicHolidayById(idSelected)
console.log(response,"response")
    if(response){
      values.id=response.public_holiday.id;
      values.public_holiday_name = response.public_holiday.public_holiday_name;
      values.date  = response.public_holiday.date;
      values.pay_multiplier = response.public_holiday.pay_multiplier;
      values.bill_multiplier = response.public_holiday.bill_multiplier;
      values.country=response.public_holiday.country;
      values.calendar_marked_color=response.public_holiday.colour_code;
      values.rate_code=response.public_holiday.rate_code && response.public_holiday.rate_code.id && response.public_holiday.rate_code.id;
      values.holiday_type=response.public_holiday.rate_code && response.public_holiday.rate_code.id && response.public_holiday.rate_code.id;
      values.branch= response.public_holiday_branch.map(x=>{return{selected_id:x.id,public_holiday:x.public_holiday,id: x.branch, label:contextChoices.branch && contextChoices.branch.find(w=>w.id ===x.branch).name}});
      values.region=response.public_holiday_region.map(x=>{return{selected_id:x.id,public_holiday:x.public_holiday,id: x.regions, label:contextChoices.region && contextChoices.region.find(w=>w.id ===x.regions).name}}); 
      setValues({...values});
    }
    setLoadingModal(false);   
  };

  const handleSubmit = async (evt) => {
      evt.preventDefault();
      if (loadingSave) {
        return;
      }
  
  //   if ((!isValid && errors.length > 0) ) {
  // console.log(errors,1415)
  //     setCheckRequires(true);
  //     setShowError(true);
  //     setErrorMessage(InvalidOrEmptyValues);
  //     return;
  //   }
  if ((!isValid )  ) {
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(InvalidOrEmptyValues);
        return;
      }

    if (textStatus==='SAVED') {    return; }
    setLoadingSave(true);
      const bodyData= values.id ? {
        calendar_year:calendarYear,
        public_holiday_name: values.public_holiday_name,
        date: values.date,
        pay_multiplier:values.pay_multiplier,
        bill_multiplier:values.bill_multiplier,
        // rate_code: values.rate_code,
        rate_code: contextChoices.rate_code.find(w=>w.name === rateCodeName).id,
        country: values.country,
       
        colour_code: values.calendar_marked_color,
        public_holiday_region: values.region.map(x=>{
          let temp = {public_holiday:x.public_holiday, regions:x.id}
          if(x.selected_id){
            temp.id = x.selected_id
          }
          return temp
        }),
        public_holiday_branch: values.branch.map(x=>{
          let temp = {public_holiday:x.public_holiday,branch:x.id}
          if(x.selected_id){
            temp.id = x.selected_id
          }
          return temp
        }),
      }:{
        calendar_year:calendarYear,
        public_holiday_name: values.public_holiday_name,
        date: values.date,
        // rate_code: values.rate_code,
        rate_code: contextChoices  && contextChoices.rate_code && contextChoices.rate_code.find(w=>w.name === rateCodeName).id,
        country: values.country,
        pay_multiplier:values.pay_multiplier,
        bill_multiplier:values.bill_multiplier,
        colour_code: values.calendar_marked_color,
        public_holiday_region: values.region.map(x=>{return {regions:x.value}}),
        public_holiday_branch: values.branch.map(x=>{return {branch:x.value}}),
      }
 console.log(bodyData,"RRRR")
     await  PublicHoliday(values,bodyData)
     .then(res=>{
       if( res.status==='success'){ 
          onSuccess()
          setPublicHolidayModalValue(false)
          }
  else{  onError()  }
     })
     .catch(onError)
  };
//above code  is for saving rate code  
  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    loadPublicHoliday()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setShowError(true);
    setCheckRequires(true);
      setErrorMessage(
        "Some of the fields have invalid or empty values. Please review."
      );
  };

const dropDownHandleSelect = (item, nameItem) => {
    values[nameItem] = item[nameItem];
   setValues({...values})
 };

 const dropdownHandleMultiSelect = preferred => {
  setValues({ ...values, ...preferred });
};


useEffect(()=>{
  if(values.date){
  var  getYear = values.date.split("-");
  var  calendar_year = getYear[0];
  if(calendar_year ){
  setCalendarYear(calendar_year)
  }else{setCalendarYear(null)}
}else {
  setCalendarYear(null)
}

},[values && values.date])

useEffect(()=>{
  if(values.holiday_type ){
    var str =contextChoices.rate_code.find(w=>w.id === values.holiday_type).name;
    setRateCodeName(str)
  }else{
    setRateCodeName()
  }
},[values &&values.holiday_type])

console.log(values.region, 78595111)

  return (
    <ModalContainer max_width={'70%'}>      
      <ModalDynamic
        {...fullBind}
        max_width={'70%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="pl-3 pr-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100 mb-2 pb-1'}>
          <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
          <InputLabelTop
                 required
                label={'Public holiday name'}
                type="text"
                {...useInput(`public_holiday_name`, 'isRequired')}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >
            <DateSelector
            isRequired
              id="date"
              labelTop={'Date'}
              {...useInput(`date`, 'isRequired')}
            />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1  pt-1 "  >          
                 <InputLabelTop
                // disabled={true}
              label={'Calendar year'}
                type="number"
                value={calendarYear?  calendarYear : null}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >          
            <DropdownSearchable
              required
            {...useInput(
             `holiday_type`,
             'isRequired'
           )}
                placeHolder={' Holiday type'}
                options={holidayType}
                selectedOption={
                  values
                    ?  values.holiday_type 
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    {holiday_type: value ? value : null},
                    'holiday_type', 'isRequired'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >          
               <InputLabelTop
                // disabled={true}
              label={'Rate Code'}
                type="text"
                value={rateCodeName? rateCodeName : null}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >  
            <InputColorPicker
                type="text"
                id="calendar_marked_color"
                label={'Calendar Marked colour'}
                {...useInput('calendar_marked_color')}
              />        
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  > 
            <DropdownSearchable
             required
            {...useInput(
             `country`,
             'isRequired'
           )}
                placeHolder={'Country'}
                options={countryData}
                selectedOption={
                  values
                    ?  values.country 
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    {country: value ? value : null},
                    'country', 'isRequired'
                  )
                }
              />         
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  > 
       <DropdownSearchable
                     required
                     {...useInput(
                      `region`,
                      'isRequired'
                    )}
             width={'100%'}
        //   marginLeft={'0.5%'}
        placeHolder={' Region'}
              options={region}
            isMulti={true}
             selectedOptionsProps={
            values && values.region && values.region }
          onChange={value =>
           dropdownHandleMultiSelect( { region: value.length ? value : [] },
            'region', 'isRequired' ) }   />            
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "  >          
            <DropdownSearchable
              required
              {...useInput(`branch`,  'isRequired' )}
             width={'100%'}
        //   marginLeft={'0.5%'}
             placeHolder={'Branches'}
              options={branch}
            isMulti={true}
             selectedOptionsProps={
            values && values.branch && values.branch }
          onChange={value =>
           dropdownHandleMultiSelect( { branch: value.length ? value : [] },
            'branch', 'isRequired' ) }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
          <InputLabelTop
                 required
                label={'Pay Multiplier Rate'}
                type="text"
                {...useInput(`pay_multiplier`)}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1  pr-0 pr-md-1 pt-1"  >
          <InputLabelTop
                 required
                label={'Bill Multiplier rate '}
                type="text"
                {...useInput(`bill_multiplier`)}
                maxlength={100}
              />
            </FlexGrid3Item>
            </FlexGrid3Container >
          
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2 mt-1'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMoal;
