import React, { Fragment, useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { AppContext } from 'context';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CloseButtonWrapper, TitleSection } from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';
import { TIMES } from 'utils/constants/icons';
import moment from 'moment';
import { createMedication } from 'utils/api/ClientApi';
import { useForm } from 'hooks/FormHook';
import CustomDateSelector from 'components/SharedComponents/DateSelector/custumDateSelector';
import CustomDropdownSearchable from 'components/SharedComponents/DropdownSearchable/customDropdownSearchable';
import { KeyPress } from 'utils/constants/regularExpressions';
import AddNewAdjustment from './AddNewAdjustment';
import {Link, useHistory, useParams} from 'react-router-dom';
import DropdownMedication from 'components/SharedComponents/DropdownMedication';

const ModalServiceClient = ({ 
    editMode, 
    selectedClientId, 
    editData, 
    setEditMode, 
    values,
    setValues,
    useInput,
    errors,
    setEditData, 
    getClientContracts, 
    setModalValue, 
    modalValue, 
    handleModal ,
    dropDownHandleSelect,
    checkBeforeSend
}) => {

    const initial_stock_hook = useInput('initial_stock')
    const minimum_stock_hook = useInput('minimum_stock')
    //const initial_stock_hook = useInput('initial_stock')
   //const initial_stock_hook = useInput('initial_stock')
    
    useEffect(() => {
        values.medication_names = editData?.medication_name 
       

        setValues({ ...values })
    }, [editData])
    const { contextChoices } = useContext(AppContext);
    const [medicationName, setMedicationName] = useState(!editMode ? editData?.medication_name : "")
    const [medicationStock, setMedicationStock] = useState(!editMode ? editData?.initial_stock : null)
    const [minimumStock, setMinimumStock] = useState(!editMode ? editData?.minimum_stock : 0)
    const [numberOfForms, setNumberOfForms] = useState(!editMode && editData?.adjustment ? editData?.adjustment : [])
    const [remainingStock, setRemainingStock] = useState('')
    const [toDeleteVisits] = useState([]);
    const history = useHistory();

    // const deleteVisit = (indexs) => {
    //     setNumberOfForms(prev => prev.filter((val, index) => val.id !== indexs))
    // }

    const addNewVisit = () => {
        if (!values.adjustment) {
          values.adjustment = [];
        }
        values.adjustment.push({});
        setValues({...values});
      };

      const deleteVisit = (dataVisit, indexVisit) => {
        toDeleteVisits.push({...dataVisit});
    
        const possibleError = `adjustment[${indexVisit}]`;
    
        for (var i = errors.length; i--; ) {
          if (errors[i].includes(possibleError)) {
            errors.splice(i, 1);
          }
        }
    
        values.adjustment.splice(indexVisit, 1);
    
        setValues({...values});
      };
    // useEffect(()=>{
    //    if(editData){
    //     setMedicationStock(editData?.initial_stock)
    //    }
    // },[editData])
    // useEffect(() => {
    //     const findMedication = () => {
    //         if (editData) {
    //             let finalCount = editData?.adjustment?.reduce((cur, pre) => cur + pre.count_up_down, 0)
    //             setRemainingStock(finalCount + editData?.initial_stock)
    //         }
    //     }
    //     findMedication()
    // }, [editData])
console.log(values,"RRRRRR")
    return (
        <div max_width={'92%'}>

            <div className="row p-2 p-sm-3 p-md-4">
                <div className={'col  mt-2'}>
                {/* <DropdownSearchable
              required
              placeHolder={'Medication Name'}
              options={contextChoices ? contextChoices.medication_name : []}
              {...useInput('medication_name', "isRequired")}
              selectedOption={values.medication_name ? values.medication_name: null}
              onChange={item =>
                dropDownHandleSelect({ medication_name: item ? item : null }, 'medication_name')
              }
            /> */}
            <DropdownMedication
              required
              placeHolder={'Medication Name'}
              options={contextChoices ? contextChoices.medication_name : []}
              selectedOption={values.medication_name ? values.medication_name: null}
              onChange={value =>
                dropDownHandleSelect({ medication_name: value ? value : null }, 'medication_name')
              }
            />
                </div>
                <div className={'col mt-2'}>
                    <InputLabelTop
                        type="number"
                        disabled={false}
                        id="repeat_no"
                        //value={medicationStock}
                        label={'Medication stock no.'}
                        //onKeyPress={KeyPress.INTEGER}
                        maxlength={5}
                        {...initial_stock_hook}
                    />
                </div>

                {/* <div className={'col mt-2'}>
                    <InputLabelTop
                        type="number"
                        disabled={!editMode}
                        id="repeat_no"
                        value={minimumStock}
                        label={'Minimum stock.'}
                        onKeyPress={KeyPress.INTEGER}
                        maxlength={5}
                        onChange={value => {
                            setMinimumStock(value.target.value)
                        }}
                    />
                </div> */}
                <div className={'col mt-2'}>
                    <DateSelector
                     isRequired
                        labelTop={'Intial date'}
                        disabled={false}
                        {...useInput('date_of_entry_initial_stock', 'isRequired')}
                    />
                </div>

                <div className={'col mt-2'}>
                    <DateSelector
                     isRequired
                        disabled={false}
                        labelTop={'Expiry date'}
                        {...useInput('date_of_expiry', 'isRequired')}
                    />
                </div>

                {
                    !editMode &&
                    <div className={'col mt-2'}>
                        <InputLabelTop
                            type="number"
                            id="repeat_no"
                            label={'Remaining stock'}
                            maxlength={50}
                           // value={remainingStock}
                            {...minimum_stock_hook}
                        />
                    </div>
                }


                {
                    !editMode &&
                    <div className={'col mt-2'}>
                        <InputLabelTop
                            type="number"
                            id="repeat_no"
                            label={'Lowest stock'}
                            maxlength={50}
                           // {...useInput('medication_brand_name')}
                        />
                    </div>
                }
            </div>

            {
                values && values.adjustment && values.adjustment.map((val, index) => {
                    return (
                        <AddNewAdjustment
                          editMode={editMode}
                          addNewVisit={addNewVisit}
                          deleteVisit={deleteVisit}
                          useInput={useInput}
                          nameSection={'adjustment'}
                          dataVisit={val}
                          values={values}
                          setValues={setValues}
                          indexVisit={index}
                          contextChoices={contextChoices}
                        />
                      )
                    
                })
            }
            {
                !editMode &&
                <div className='pl-4'>
                    <PrimaryButtonForm minWidth="6rem" onClick={addNewVisit} >
                        <FaPlus />
                    </PrimaryButtonForm>
                </div>

            }
            <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                        bgcolor="PURPLE_GRAY_500"
                        minWidth="6rem"
                        className={'mr-2'}
                        onClick={()=>{
                          history.push('/client')
                        }}>
                        <span className={'mr-2'}>{'Cancel'}</span>
                      </PrimaryButtonForm>
                    <PrimaryButtonForm
                        disabled={false}
                        bgcolor="PURPLE_GRAY_500"
                        minWidth="6rem"
                        onClick={checkBeforeSend}
                    >
                        Save
                    </PrimaryButtonForm>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default ModalServiceClient;
