import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  SETTINGS,
  COMPANY,
  COMPANY_PROFILE,
  COMPANY_LICENSE,
  COMPANY_CALENDAR,
  COMPANY_BRANCHES,
  COMPANY_PRIORITY,
  PAGE_404,
  COMPANY_RUN_ROUTE,
  COMPANY_MOBILE_AUTH,
  ANNUAL_LEAVE_ACCRUAL,
  BRANCH_ABSCENCE_OVERVIEW,
  BRANCH_SETTING,
  COMPANY_ORGANIZATION
  // RUN_ROUTE
} from 'utils/constants/routes';
import Company from 'components/DashboardComponents/Settings/SettingsCompany/Company';
import CompanyLicense from 'components/DashboardComponents/Settings/SettingsCompany/CompanyLicense';
import Branches from 'components/DashboardComponents/Settings/SettingsCompany/Branches';
import Priority from 'components/DashboardComponents/Settings/SettingsCompany/Priority';
import RunRoutes from 'components/DashboardComponents/Settings/SettingsCompany/RunRoutes';
import { getCompanies } from 'utils/api/SettingsApi';
import { useHistory } from 'react-router-dom';
import { setCompany } from 'utils/api/SettingsApi';
import { getAdmin, getSuperAdmin } from '../../../../utils/localStorage/token';
import {
  getCategoryPermission,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
  getSubSectionPermissions,
} from '../../../../utils/localStorage/user';
import {
  COMPANYS,
  COMPANY_LICENSE_SETTINGS,
  CALENDAR_SETTINGS,
  BRANCH_SETTINGS,
  RUN_ROUTE,
  PRIORITY_SETTINGS,
  COMPANY_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  GROUP_AREA,
} from '../../../../utils/constants/permissions';
import { AppContext } from 'context';
import MobileAuthTime from './MobileAuthTime';
import BranchSeting from './Branches/BranchSettings';
import { company_setting_choices, settings_accrual_leaves_choices } from 'utils/choiceConstant';
import Organization from './Orgenization';

const TAB_COMPANY_URL = SETTINGS + COMPANY + COMPANY_PROFILE;
const TAB_LICENSE_URL = SETTINGS + COMPANY + COMPANY_LICENSE;
export const TAB_BRANCHES_URL = SETTINGS + COMPANY + COMPANY_BRANCHES;
const TAB_PRIORITY_URL = SETTINGS + COMPANY + COMPANY_PRIORITY;
const TAB_RUNROUTE_URL = SETTINGS + COMPANY + COMPANY_RUN_ROUTE;
const TAB_MOBILE_AUTH_URL = SETTINGS + COMPANY + COMPANY_MOBILE_AUTH;

export const BRANCH_SETTINGS_TAB = SETTINGS + COMPANY + COMPANY_BRANCHES + BRANCH_SETTING
export const ANNUAL_LEAVE_ACCRUAL_TAB = SETTINGS + COMPANY + COMPANY_BRANCHES + BRANCH_SETTING + ANNUAL_LEAVE_ACCRUAL
export const ABSCENCE_OVERVIEW_TAB = SETTINGS + COMPANY + COMPANY_BRANCHES + BRANCH_SETTING + BRANCH_ABSCENCE_OVERVIEW
export const ADD_ABSCENCE_OVERVIEW = SETTINGS + COMPANY + COMPANY_BRANCHES + BRANCH_SETTING + "/add-absence-overview"
const TAB_ORG_URL = SETTINGS + COMPANY + COMPANY_ORGANIZATION;


const COMPANY_NOT_FOUND = 'Company details have not been created';
const RUNROUTE_NOT_FOUND = 'Run route details can not been found';

const Settings = props => {
  let history = useHistory();

  const [companies, setCompanies] = useState({});
  const mountedRef = useRef(true);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);

  //CompanyLicenses
  const [updateCompanyLicenses, setUpdatePermissionsCompanyLicenses] =
    useState(false);
  const [createCompanyLicenses, setCreatePermissionsCompanyLicenses] =
    useState(false);
  const [deletePermissionCompanyLicenses, setDeletePermissionsCompanyLicenses] =
    useState(false);
  const [readPermissionCompanyLicenses, setReadPermissionsCompanyLicenses] =
    useState(false);

  //BranchSettings
  const [updateBranchSettings, setUpdatePermissionsBranchSettings] =
    useState(false);
  const [createBranchSettings, setCreatePermissionsBranchSettings] =
    useState(false);
  const [deletePermissionBranchSettings, setDeletePermissionsBranchSettings] =
    useState(false);
  const [readPermissionBranchSettings, setReadPermissionsBranchSettings] =
    useState(false);

  //Priority
  const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] =
    useState(false);
  const [createPrioritySettings, setCreatePermissionsPrioritySettings] =
    useState(false);
  const [
    deletePermissionPrioritySettings,
    setDeletePermissionsPrioritySettings,
  ] = useState(false);
  const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] =
    useState(false);

  //// RunRoute
  const [updateRunRoutes, setUpdatePermissionsRunRoutes] = useState(false);
  const [createRunRoutes, setCreatePermissionsRunRoutes] = useState(false);
  const [deletePermissionRunRoutes, setDeletePermissionsRunRoutes] =
    useState(false);
  const [readPermissionRunRoutes, setReadPermissionsRunRoutes] =
    useState(false);

  const [updateGroupArea, setUpdatePermissionsGroupArea] = useState(false);
  const [createGroupArea, setCreatePermissionsGroupArea] = useState(false);
  const [deletePermissionGroupArea, setDeletePermissionsGroupArea] = useState(false);
  const [readPermissionGroupArea, setReadPermissionsGroupArea] =    useState(false);



  const {
    handleGetChoices,
    handleChangeChoices,
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(()=>{
    loadCompanies();
  },[readPermission])
  useEffect(()=>{
    handleGetChoices([...company_setting_choices, ...settings_accrual_leaves_choices])
  },[])
 
  useEffect(() => {
    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setReadPermissions(true);
      setUpdatePermissionsBranchSettings(true);
      setCreatePermissionsBranchSettings(true);
      setDeletePermissionsBranchSettings(true);
      setReadPermissionsBranchSettings(true);
      setUpdatePermissionsPrioritySettings(true);
      setCreatePermissionsPrioritySettings(true);
      setDeletePermissionsPrioritySettings(true);
      setReadPermissionsPrioritySettings(true);
      setUpdatePermissionsRunRoutes(true);
      setCreatePermissionsRunRoutes(true);
      setDeletePermissionsRunRoutes(true);
      setReadPermissionsRunRoutes(true);

      setReadPermissionsCompanyLicenses(false)
      setUpdatePermissionsCompanyLicenses(true)
      setCreatePermissionsCompanyLicenses(true)
      setDeletePermissionsCompanyLicenses(true)

      setUpdatePermissionsGroupArea(true)
      setCreatePermissionsGroupArea(true)
      setDeletePermissionsGroupArea(true)
      setReadPermissionsGroupArea(true)
    } else {
      const roles = contextPermissions;

      let rolePermissions = {};
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === COMPANY_SETTINGS) {
            rolePermissions[COMPANY_SETTINGS] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[COMPANY_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });

        const rolesPermissions = contextSubSectionPermissions;

        if (rolesPermissions.length > 0) {
          rolesPermissions.forEach(
            item => {
              // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
            }
            // }
          );
        }

        setCreatePermissions(
          rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[COMPANY_SETTINGS]?.[WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );

        // Company Licenses Settings
        if (rolePermissions[COMPANY_LICENSE_SETTINGS]) {
          setCreatePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsCompanyLicenses(
            rolePermissions[COMPANY_LICENSE_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        //BranchSettings
        if (rolePermissions[BRANCH_SETTINGS]) {
          setCreatePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsBranchSettings(
            rolePermissions[BRANCH_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        //Priority Settings
        if (rolePermissions[PRIORITY_SETTINGS]) {
          setCreatePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]
          );
          setDeletePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]
          );
          setUpdatePermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]
          );

          setReadPermissionsPrioritySettings(
            rolePermissions[PRIORITY_SETTINGS]?.[WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]
          );
        }
        //RunRoute
        if (rolePermissions[RUN_ROUTE]) {
          setCreatePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionsRunRoutes(
            rolePermissions[RUN_ROUTE]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionsRunRoutes(
            rolePermissions[RUN_ROUTE]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }

        if (rolePermissions[GROUP_AREA]) {
          setCreatePermissionsGroupArea(
            rolePermissions[GROUP_AREA]?.[WEB_PERMISSION][WEB_CREATE_PERMISSION]
          );
          setDeletePermissionsGroupArea(
            rolePermissions[GROUP_AREA]?.[WEB_PERMISSION][WEB_DELETE_PERMISSION]
          );
          setUpdatePermissionsGroupArea(
            rolePermissions[GROUP_AREA]?.[WEB_PERMISSION][WEB_UPDATE_PERMISSION]
          );

          setReadPermissionsGroupArea(
            rolePermissions[GROUP_AREA]?.[WEB_PERMISSION][WEB_READ_PERMISSION]
          );
        }
      }
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,]);

  const loadCompanies = () => {
    if(readPermission){
    getCompanies().then(response => {
      if (!mountedRef.current) return null;
      response?.results && setCompanies({ ...response?.results[0] } || {});
    });
  }
  };

  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  const handleSubmit = async valuesToSave => {
    try {
      let response = await setCompany(valuesToSave);
      if (response.id) {
        loadCompanies();
        handleChangeChoices(['company', 'branch'])
      }
      return response;
    } catch (error) {
      return error;
    }
  };


  useEffect(() => {
    if (filterAdmin) {
      history.push(TAB_ORG_URL)
    } else if (readPermission) {
      history.push(TAB_COMPANY_URL)
    } else if (readPermissionCompanyLicenses) {
      history.push(TAB_LICENSE_URL)
    } else if (readPermissionBranchSettings) {
      history.push(TAB_BRANCHES_URL)
    } else if (readPermissionRunRoutes) {
      history.push(TAB_PRIORITY_URL)
    } else if (readPermissionGroupArea) {
      history.push(TAB_RUNROUTE_URL)
    } else {
      history.push(TAB_MOBILE_AUTH_URL)
    }
  }, [filterAdmin, readPermission, readPermissionCompanyLicenses, readPermissionBranchSettings, readPermissionRunRoutes, readPermissionGroupArea])

console.log({readPermission,
  readPermissionCompanyLicenses,
  readPermissionBranchSettings,
  readPermissionRunRoutes},"permission")

  return (
    <div>


      {history.location.pathname.includes(BRANCH_SETTINGS_TAB) ?
        <div>

          <Switch>
            <Route exact path={BRANCH_SETTINGS_TAB}>
              <BranchSeting  readPermissionBranchSettings={readPermissionBranchSettings}/>
            </Route>
            <Redirect
              to={{
                pathname: PAGE_404,
                state: { referrer: '/' },
              }}
            />
          </Switch>
        </div>
        :

        <>
          <Title>Settings Company</Title>
          <div className="p-2 p-sm-3 p-md-4">

            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex firstRow'}>

                {filterAdmin == true ?
                  <LinkTabStyled active={reviewLocation(TAB_ORG_URL)}>
                    <Link to={TAB_ORG_URL} className={'styledLink'}>
                      <LinkTab active={reviewLocation(TAB_ORG_URL)}>
                        Organization
                      </LinkTab>
                    </Link>
                  </LinkTabStyled> : null}
                

                {readPermission &&
                  <LinkTabStyled active={reviewLocation(TAB_COMPANY_URL)}>
                    <Link to={TAB_COMPANY_URL} className={'styledLink'}>
                      <LinkTab active={reviewLocation(TAB_COMPANY_URL)}>
                        Company
                      </LinkTab>
                    </Link>
                  </LinkTabStyled>
                }

                {readPermissionCompanyLicenses && 
                <LinkTabStyled active={reviewLocation(TAB_LICENSE_URL)}>
                  <Link to={TAB_LICENSE_URL} className={'styledLink'}>
                    <LinkTab active={reviewLocation(TAB_LICENSE_URL)}>
                      Company Licenses
                    </LinkTab>
                  </Link>
                </LinkTabStyled>} 
              

              {/* <div className={'d-flex firstRow'}> */}
                {readPermissionBranchSettings && 
                <LinkTabStyled active={reviewLocation(TAB_BRANCHES_URL)}>
                  <Link to={TAB_BRANCHES_URL} className={'styledLink'}>
                    <LinkTab active={reviewLocation(TAB_BRANCHES_URL)}>
                      Branches
                    </LinkTab>
                  </Link>
                </LinkTabStyled>}

                
                  {readPermissionRunRoutes && 
                  <LinkTabStyled active={reviewLocation(TAB_PRIORITY_URL)}>
                    <Link to={TAB_PRIORITY_URL} className={'styledLink'}>
                      <LinkTab active={reviewLocation(TAB_PRIORITY_URL)}>
                        Runs
                      </LinkTab>
                    </Link>
                  </LinkTabStyled>}
             

                {readPermissionGroupArea &&
                  <LinkTabStyled active={reviewLocation(TAB_RUNROUTE_URL)}>
                    <Link to={TAB_RUNROUTE_URL} className={'styledLink'}>
                      <LinkTab active={reviewLocation(TAB_RUNROUTE_URL)}>
                        Group Area
                      </LinkTab>
                    </Link>
                  </LinkTabStyled>}
 
                <LinkTabStyled active={reviewLocation(TAB_MOBILE_AUTH_URL)}>
                  <Link to={TAB_MOBILE_AUTH_URL} className={'styledLink'}>
                    <LinkTab active={reviewLocation(TAB_MOBILE_AUTH_URL)}>
                      Mobile
                    </LinkTab>
                  </Link>
                </LinkTabStyled>
                </div>
                {/* )} */}
              {/* </div> */}
            </ContainerTabStyled>

            <Switch>

              {/* Organization Page*/}
              <Route exact path={TAB_ORG_URL}>
                <Organization
                  isAdmin={filterAdmin}
                />
              </Route>

              <Route exact path={TAB_COMPANY_URL}>
                {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (   (userStaff ==='null' ) ) || ((userStaff == true)&& ((readPermission) || (deletePermission) || (create) || (update) )) )  && (  */}
                <Company
                  companies={companies}
                  handleSubmit={handleSubmit}
                  location={props.location}
                  handleChangeChoices={handleChangeChoices}
                />
                {/* )} */}
              </Route>

              <Route exact path={TAB_LICENSE_URL}>
                {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (  (userStaff ==='null' ) ) || ((userStaff == true)&& ((updateCompanyLicenses) || (createCompanyLicenses) || (deletePermissionCompanyLicenses) || (readPermissionCompanyLicenses) ) ))  && (  */}
                <CompanyLicense
                  location={props.location}
                  companies={companies}
                  COMPANY_NOT_FOUND={COMPANY_NOT_FOUND}
                  handleSubmit={handleSubmit}
                />
                {/* )} */}
              </Route>

              <Route exact path={TAB_BRANCHES_URL}>
                {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) ||( (userStaff == true ) ) || ((userStaff == true)&&((updateBranchSettings) || (createBranchSettings) || (deletePermissionBranchSettings) || (readPermissionBranchSettings) ) ) )  && (  */}
                <Branches
                  companies={companies}
                  COMPANY_NOT_FOUND={COMPANY_NOT_FOUND}
                  location={props.location}
                  updateBranchSettings={updateBranchSettings}
                  createBranchSettings={createBranchSettings}
                  deletePermissionBranchSettings={deletePermissionBranchSettings}
                  readPermissionBranchSettings={readPermissionBranchSettings}
                  filterAdmin={filterAdmin}
                  handleChangeChoices={props.handleChangeChoices}
                  filterSuperAdmin={filterSuperAdmin}
                  userStaff={userStaff}
                />
                {/* )} */}
              </Route>
              <Route exact path={TAB_PRIORITY_URL}>
                {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (  (userStaff == true )  )  ||  */}
                {/* ((userStaff == true)&&( (updatePrioritySettings) || (createPrioritySettings) || (deletePermissionPrioritySettings) || (readPermissionPrioritySettings) ) ))  && (  */}
                <Priority
                  updatePrioritySettings={updatePrioritySettings}
                  createPrioritySettings={createPrioritySettings}
                  deletePermissionPrioritySettings={
                    deletePermissionPrioritySettings
                  }
                  readPermissionPrioritySettings={readPermissionPrioritySettings}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  handleChangeChoices={props.handleChangeChoices}
                  userStaff={userStaff}
                />
                {/* )} */}
              </Route>
              {/* added Route of runroute below */}
              <Route exact path={TAB_RUNROUTE_URL}>
                {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || (  (userStaff == true )&& ( (updatePrioritySettings) || (createPrioritySettings) || (deletePermissionPrioritySettings) || (readPermissionPrioritySettings)  )   ) )  && (  */}
                <RunRoutes
                  location={props.location}
                  RUNROUTE_NOT_FOUND={RUNROUTE_NOT_FOUND}
                  updateRunRoutes={updateRunRoutes}
                  createRunRoutes={createRunRoutes}
                  deletePermissionRunRoutes={deletePermissionRunRoutes}
                  readPermissionRunRoutes={readPermissionRunRoutes}
                  filterAdmin={filterAdmin}
                  handleChangeChoices={() =>
                    props.handleChangeChoices('run_routes')
                  }
                  filterSuperAdmin={filterSuperAdmin}
                  userStaff={userStaff}
                />
                {/* )} */}
              </Route>
              <Route exact path={TAB_MOBILE_AUTH_URL}>
                <MobileAuthTime
                />
              </Route>

              <Redirect
                to={{
                  pathname: PAGE_404,
                  state: { referrer: '/' },
                }}
              />
            </Switch>
          </div>
        </>
      }
    </div>

  );
};

export default Settings;
