import React, { useState, useEffect, useContext, useRef } from 'react';
import { useModal } from 'hooks/ModalHook';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import * as html2pdf from 'html2pdf.js';
import Backdrop from '@mui/material/Backdrop';
import MatBox from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { AppContext } from 'context';
import BodyMapModal from 'components/DashboardComponents/FormBuilder/BodyMapModal';
import SearchModalRichText from './components/SearchModalRichText'
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ItemTypes } from 'components/DashboardComponents/FormBuilder/ItemTypes';
import { formdata_choice } from 'utils/choiceConstant';
import ComponentRenderer from './components/ComponentRenderer';

// For client and employee module views
const FormViewer = ({
  jsonData,
  setJsonData,
  contextChoices,
  name,
  parent = '',
  title = '',
  handleChangeChoices = () => { }
}) => {
  const readOnly = parent === 'client_care_plans';

  const [signature, setSignature] = useState(null);
  const [signatureItem, setSignatureItem] = useState({});
  // console.log("signatureItem : ", signatureItem)

  const [popUpData, setPopUpData] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);
  const { handleGetChoices } = useContext(AppContext);

  const {
    setValue: setModalBodyMap,
    value: modalBodyMap,
    fullBind: fullBindBodyMap,
  } = useModal(false);
  const {
    setValue: setSearchValueRich,
    value: searchValueRich,
    fullBind: fullBindSearchValueRich,
  } = useModal(false);

  // check if this is called globally, if it is then not required here
  useEffect(() => {
    handleGetChoices(formdata_choice);
  }, []);

  // refactor jsonData & fetch required choices
  useEffect(() => {
    // refactor jsonData for "signature" item
    let json_data = jsonData.map((j_val, globalIndex) => {
      if (j_val.type === ItemTypes.signature && !signatureItem.childOf) {
        // if global signature component
        if (globalIndex === signatureItem.index)
          j_val.value = signature
        return j_val
      }
      else if (j_val.type === ItemTypes.table && signatureItem.childOf === 'table') {
        // if signature component inside section or table
        let r_data =
          j_val.rows &&
          j_val.rows.map((r_val, index_r) => {
            // console.log("signatureItem.item.type === ItemTypes.signature", signatureItem.item.type === ItemTypes.signature)
            // console.log("index_r === signatureItem.index", index_r === signatureItem.index)
            if (
              signatureItem.item.type === ItemTypes.signature &&
              index_r === signatureItem.index
            ) {
              r_val[signatureItem.item.column_name] = signature;
              // r_val.value = signature;
            }
            return r_val;
          });
        let data = { ...j_val, rows: r_data };
        return data;
      }
      else if (j_val.type === ItemTypes.section && signatureItem.childOf === 'section') {
        // if signature component inside section or table
        let r_data =
          j_val.cols &&
          j_val.cols.map((r_val, index_r) => {
            if (
              r_val.type === ItemTypes.signature &&
              index_r === signatureItem.index
            ) {
              r_val.value = signature;
            }
            return r_val;
          });
        let data = { ...j_val, cols: r_data };
        return data;
      }
      else return j_val
    });
    setJsonData([...json_data]);

    // fetch choices used in our form
    const choices = [...json_data]
      .filter(item => item.type === ItemTypes.choices)
      .map(item => item.choices);
    if (choices?.length)
      handleChangeChoices(choices);
  }, [signature]);

  // export form functionality
  // const handleDownloadPdf = () => {
  //   const input = document.querySelector('#dustbin-pdf');
  //   const current_date = moment().format('DD-MM-YYY');
  //   var opt = {
  //     filename: `form_${current_date}`,
  //     pagebreak: { mode: 'avoid-all' },
  //     image: { quality: 1 },
  //     html2canvas: {
  //       dpi: 192,
  //       scale: 1,
  //       letterRendering: true,
  //       useCORS: true,
  //     },
  //   };
  //   setLoadingExport(true);
  //   setTimeout(() => {
  //     html2pdf()
  //       .set(opt)
  //       .from(input)
  //       .save()
  //       .then(() => {
  //         setLoadingExport(false);
  //       });
  //   }, 1);
  // };

  const handleDownloadPdf = () => {
    const input = document.querySelector('#dustbin-pdf');
    const current_date = moment().format('DD-MM-YYYY'); // Fixed format from 'DD-MM-YYY' to 'DD-MM-YYYY'

    const opt = {
      filename: `form_${current_date}.pdf`, // Added .pdf extension
      pagebreak: { mode: 'avoid-all' },
      image: { quality: 1 },
      html2canvas: {
        dpi: 192,
        scale: 1,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: 'pt',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    setLoadingExport(true);
    setTimeout(() => {
      html2pdf()
        .set(opt)
        .from(input)
        .toPdf() // Generates the PDF
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          pdf.setFontSize(10);

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            const pageText = `Page ${i} of ${totalPages}`;
            // Adjusted padding to 20 from the right
            pdf.text(pageText, pageWidth - 60, pageHeight - 20);
          }
        })
        .save() // Saves the generated PDF
        .then(() => {
          setLoadingExport(false);
        });
    }, 1);
  };


  const sigRef = useRef();
  const [openSignature, setOpenSignature] = useState(false);
  // signature component utility
  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL('image/png'));
    setOpenSignature(false);
  };
  // signature component utility
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  return (
    <>
      <div className="w-100" style={{ textAlign: 'end' }}>
        <PrimaryButtonForm
          className="mr-2"
          minWidth="6rem"
          disabled={loadingExport}
          onClick={() => handleDownloadPdf()}>
          <span className={'mr-2'}>Export PDF</span>
          {loadingExport && (
            <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
            />
          )}
        </PrimaryButtonForm>
      </div>
      <div data-testid="dustbin" id="dustbin-pdf">
        {loadingExport && (
          <div style={{
            borderBottom: `0.5px solid #746f6f`,
            padding: '15px',
            margin: '20px'
          }}>
            <h3 className="w-100 mt-2 d-flex justify-content-center">{`${title} (${name})`}</h3>
          </div>
        )}
        {jsonData?.length > 0 ? (
          jsonData.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 20,
                }}>
                <ComponentRenderer
                  item={item}
                  index={index}
                  readOnly={readOnly}
                  jsonData={jsonData}
                  setJsonData={setJsonData}
                  contextChoices={contextChoices}
                  setSignature={setSignature}
                  setSignatureItem={setSignatureItem}
                  setModalBodyMap={setModalBodyMap}
                  setPopUpData={setPopUpData}
                  setSearchValueRich={setSearchValueRich}
                  setOpenSignature={setOpenSignature}
                  loadingExport={loadingExport}
                />
              </div>
            );
          })
        ) : (
          <p style={{ color: 'grey', display: "flex", justifyContent: "center" }}>No form content found!</p>
        )}
      </div>

      {/* Initiator: ?? */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSignature}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={openSignature}>
          <MatBox
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '40vw',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxHeight: '80vh',
              overflow: 'scroll',
            }}>
            <div style={{ marginBottom: 20 }}>
              <h6>Signature</h6>
            </div>
            <div>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: 'sigCanvas',
                }}
                ref={sigRef}
              //  onEnd={handleSignatureEnd}
              />
              <button onClick={clearSignature}>Clear</button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setOpenSignature(false);
                }}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSignatureEnd();
                }}>
                Save
              </Button>
            </div>
          </MatBox>
        </Fade>
      </Modal>
      {/* Initiator: BodyMap Input > Create */}
      {modalBodyMap && (
        <BodyMapModal
          fullBind={fullBindBodyMap}
          setModalBodyMap={setModalBodyMap}
        />
      )}
      {/* Initiator: Rich Text Input > TableAdd Icon */}
      {searchValueRich && (
        <SearchModalRichText
          fullBind={fullBindSearchValueRich}
          jsonData={jsonData}
          setJsonData={setJsonData}
          popUpData={popUpData}
          setSearchValueRich={setSearchValueRich}
          name={name}
        />
      )}
    </>
  );
};

export default FormViewer;
