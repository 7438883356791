import React, { Fragment, useEffect } from 'react';
import Modal from 'react-bootstrap4-modal';
import { FaTimes } from 'react-icons/fa';
import Spinner from 'components/SharedComponents/Spinner';
import {
    CloseButtonWrapper,
    ModalDynamicContainer,
    FlexGrow,
    CloseButtonContainer,
    ModalTitle,
    LoadingContainer,
} from './styles';
import { ModalContainer } from 'shared/styles/constants/tagsStyles';

function ModalDynamicOffers({
    children,
    title,
    onClose,
    showModal,
    loadingMaxWidth = null,
    minWidthTitle = '',
    loading = true,
}) {

    return (
        <ModalContainer max_width={'80%'}>

            <Modal visible={showModal} >
                {loading ? (
                    <LoadingContainer maxHeight={loadingMaxWidth}>
                        <Spinner />
                    </LoadingContainer>
                ) : (
                    <Fragment>
                        <ModalDynamicContainer className="modal-header row-muted border-0">
                            <FlexGrow>
                                <ModalTitle MinWidth={minWidthTitle} className="modal-title">
                                    {title}
                                </ModalTitle>
                            </FlexGrow>

                            <CloseButtonContainer>
                                <CloseButtonWrapper>
                                    <FaTimes onClick={onClose} />
                                </CloseButtonWrapper>
                            </CloseButtonContainer>
                        </ModalDynamicContainer>

                        {children}
                    </Fragment>
                )}
            </Modal>
        </ModalContainer>

    );
}

export default ModalDynamicOffers;
