import React, {useEffect, useState} from 'react';
import {ModalContainer} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {useForm} from 'hooks/FormHook';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {CHECK_CIRCLE, ERROR_OUTLINE, FLOPPY} from 'utils/constants/icons';
import {SAVE} from 'utils/constants/messages';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import moment from 'moment';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {
  MEDIUM_COL,
  LARGE_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import Image from 'components/SharedComponents/Image';
import {
  Col,
  ColsGrouper,
  IconWrapper,
  Row,
  UserImg,
  ActionIcon,
  UserImgContainer,
} from 'components/SharedComponents/Table/styles';
import _ from 'lodash';
import {
  getUser,
  patchUser,
  resetUserPassword,
  resetUserPin,
  getHistory,
} from '../../../../utils/api/UsersApi';
import {MessageOptaPlanner} from '../../Scheduler/styles';
import ModalInformation from '../../../SharedComponents/ModalInformation';
import {useRef} from 'react';
import {IconContext} from 'react-icons';
import Table from 'components/SharedComponents/Table';
import {
  SearchAddContainer,
  HeaderLgBreak,
  Title,
  DropdownTypesContainer,
  HeaderVisits,
} from 'shared/styles/constants/tagsStyles';

const HistoryModal = ({
  fullBind,
  Id,
  roles,
  setModalValue,
  setUsers,
  showSuccessModal,
}) => {
  const {values, setValues, useInput} = useForm({});
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [staffValuechecked, seStaffValueChecked] = useState();
  const [defaultChecks, setDefaultChecks] = useState();
  const [copySuccessWeb, setCopySuccessWeb] = useState(false);
  const [copySuccessMobile, setCopySuccessMobile] = useState(false);
  const [users, setUser] = useState([]);
  const textAreaRef = useRef(null);
  const [HistroyData, setHistoryData] = useState([]);
  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    result = _.merge(values, item);
    setDefaultChecks(roles.find(role => role.id === item.role_id).is_staff);
    setValues({...result});
  };
  const loadUser = async () => {
    const response = await getUser(Id);
    setDefaultChecks(response.user.is_staff);
    // seStaffValueChecked(!defaultChecks)
    setValues({...values, ...response.user});
    setLoadingModal(false);
  };

  useEffect(() => {
    // get info and display it
    if (Id) {
      loadUser();
    } else {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  useEffect(() => {}, [values.name]);

  const handelSaveClick = () => {
    setLoadingSave(true);
    // setValues({...values, is_staff : defaultChecks});
    patchUser({...values}).then(response => {
      setLoadingSave(false);
      setUsers(response);
      setModalValue(false);
    });
  };

  const handelResetPassword = async () => {
    try {
      if (values.email) {
        setLoadingModal(true);
        const response = await resetUserPassword(values.email);
        if (response.statusCode === 404)
          throw new Error('Email for the reset' + ' password is not found.');
        setLoadingModal(false);
        setModalValue(false);
        showSuccessModal();
      }
    } catch (err) {
      setLoadingModal(false);
      setErrorMessage({...errorMessage, message: err.message});
      setShowErrorMessage(true);
    }
  };
  const handelResetPin = async () => {
    try {
      if (values.email) {
        setLoadingModal(true);
        const response = await resetUserPin(values.email);
        if (response.statusCode === 404)
          throw new Error('Email for the reset' + ' password is not found.');
        setLoadingModal(false);
        setModalValue(false);
        showSuccessModal();
      }
    } catch (err) {
      setLoadingModal(false);
      setErrorMessage({...errorMessage, message: err.message});
      setShowErrorMessage(true);
    }
  };
  useEffect(() => {
    if(!values.email) return ;
    getHistory(values.email).then(res => {
      setHistoryData(res.data);
    });
  }, [values.email]);
 
  const handleErrorModelCancel = () => {
    setShowErrorMessage(false);
  };
  

  function copyToClipboardWeb(e) {
    navigator.clipboard.writeText(e.target.value);
    setCopySuccessWeb(true);
    setCopySuccessMobile(false);
  }
  function copyToClipboardMobile(e) {
    navigator.clipboard.writeText(e.target.value);
    setCopySuccessMobile(true);
    setCopySuccessWeb(false);
  }
  const headerColumns = [
    {
      label: 'Last Login',
      name: 'user_type',
      status: 0,
      className: '',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Time',
      name: 'role',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },

    {
      label: 'User Device',
      name: 'user_type',
      status: 0,
      className: '',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Version',
      name: 'user_type',
      status: 0,
      className: '',
      max_width: MEDIUM_COL,
    },
  ];
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  console.log(values, '9999');
  console.log(HistroyData, 'HistroyData');
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        loading={loadingModal}
        loadingMaxWidth={'10rem'}
        {...fullBind}
        title={values?.name || values?.first_name || 'Edit User'}
        max_width={'150%'}
        minWidthTitle="8.5rem"
        maginLeft={'100px'}>
        <div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
           Mobile Login History 
          </div>
          <HeaderVisits>
            <SearchAddContainer className="d-flex align-items-center"></SearchAddContainer>
          </HeaderVisits>
          <ComponentDynamic loading={loadingModal}>
            <Table
              headerColumns={headerColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}>
              <div>
                {HistroyData &&
                  HistroyData.map((item, index) => {
                    return (
                      <div>
                        <Row bgColor Shadow>
                          <ColsGrouper className="d-flex">
                            <Col max_width={LARGE_COL} Center overlap={true}>
                            <label style={{marginRight: '20px'}}>
                              {item.device_data_created_at
                                ? moment(
                                    item.device_data_created_at.split('T')[0],
                                    'YYYY-MM-DD'
                                  ).format('YYYY-MM-DD')
                                : ''}
                                </label>
                            </Col>
                            <Col max_width={LARGE_COL} Center overlap={true}>
                              <label style={{marginRight: '70px'}}>
                                {item.device_data_created_at
                                  ? moment(
                                      item.device_data_created_at.split('T')[1],
                                      'HH:mm:ss'
                                    ).format('HH:mm:ss')
                                  : ''}
                              </label>
                            </Col>
                            <Col max_width={MEDIUM_COL} Center overlap={true}>
                              <label style={{marginRight: '100px'}}>
                                {item.user_device}
                              </label>
                            </Col>
                            <Col max_width={MEDIUM_COL} Center overlap={true}>
                              <label style={{marginRight: '200px'}}>
                                {item.application_version}
                              </label>
                            </Col>
                          </ColsGrouper>
                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}
              </div>
            </Table>
          </ComponentDynamic>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default HistoryModal;
