import React, { useState, useEffect, Fragment, useContext } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  WeekStyle,
  ButtonWeek,
} from 'shared/styles/constants/tagsStyles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { dateStringToDate, twentyFourTimeToTwelve } from 'shared/methods';
import {
  AvailableExistingUnavailable,
  UnavailableExistingAvailable,
  UnavailableExistingShift,
} from '../RotaValidations';
import {
  AVAILABLE_EXISTING_UNAVAILABLE,
  UNAVAILABLE_EXISTING_AVAILABLE,
  UNAVAILABLE_EXISTING_SHIFT,
} from 'utils/constants/rotaShift';
import PropTypes from 'prop-types';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import { InvalidRange } from 'utils/constants/messages';
import { ContainerResponsive } from '../../Team/AddModal/styles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import moment from 'moment';
import { indexOf } from 'lodash';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { getDynamicPayRate } from 'utils/api/SchedulerApi';
import { getAbsenceHours, recalculatebasenceHr } from 'utils/api/RotaShift';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { getEmployeeBranchAccess } from 'utils/api/EmployeeApi';
import { AppContext } from 'context';
import { add_availavility_choices } from 'utils/choiceConstant';
const AddUnavailability = ({
  absenceRateCard,
  // contextChoices,
  readOnly,
  fullBind,
  dropDownHandleSelect,
  employees,
  useInput,
  values,
  errorTime,
  splitAvailability,
  addNewAvailability,
  deleteAvailabilityDetail,
  errorMessage,
  buttonIcon,
  loadingSave,
  textStatus,
  dateMessage,
  timeMessage,
  dateError,
  // invalidOvernight,
  isDateAssigned,
  closeModal,
  typeOfMessage,

  // new props passed  below
  nameSection,
  setValues,
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  filterEmployee,
  filterClient,
  userStaff,
  filterSuperAdmin,
  filterAdmin,
  invalidOvernight,
  setInvalidOvernight,
  handleDropAbsenceType,
  availabilitiesEmployeeId,
  availabilitiesEmployeeFullName,
  employeesHandleSelect,
  createUnAvailable,
  createSelfUnAvailable,
  handleDeleteClicked,
  loadingModal = false,
  copyShift = false,
  setCopyShift = () => {}
}) => {
  const { contextChoices, handleGetChoices } = useContext(AppContext);
  const [absence, setAbsence] = useState('');
  const [isFullDay, setIsFullDay] = useState(false);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [isOverNight, setIsOverNight] = useState(false);
  const [absenceTypeFiltered, setAbsenceTypeFiltered] = useState([]);
  
  const [invalidOvernightMessage, setInvalidOvernightMessage] = useState('');

  const [loadingRecalculate, setLoadingRecalculate] = useState(false);
  const [buttonRecalculateIcon, setButtonRecalculateIcon] = useState(<Fragment />);

  // const start_day = moment(values.start_date,  'DD-MM-YYYY').format('dddd')
  const [empAccessBranchs, setEmpAccessBranchs] = useState([])

  const [absenceType, setAbsenceType] = useState([]);
  const [absenceReason, setAbsenceReason] = useState([]);
  const [regularShiftType, setRegularShiftType] = useState([]);
  const [sicknessReason, setSicknessReason] = useState([]);
  const [absenceInformedMethod, setAbsenceInformedMethod] = useState([]);
  const [absencePlanned, setAbsencePlanned] = useState([]);
  const [absencePaid, setAbsencePaid] = useState([]);
useEffect(() => {
  handleGetChoices(add_availavility_choices)
},[])

  useEffect(() => {
    if (copyShift)
      delete values.idSelected
  }, [copyShift, values.idSelected])


  const loadChoices = () => {
    if (contextChoices) {
      setAbsenceType(contextChoices?.absence_type || []);
      setAbsenceReason(contextChoices?.absence_reason || []);
      setRegularShiftType(contextChoices?.regular_shift_type || []);
      setSicknessReason(contextChoices?.sickness_reason || []);
      setAbsenceInformedMethod(contextChoices?.absence_informed_method || []);
      setAbsencePlanned(contextChoices?.absence_planned || []);
      setAbsencePaid(contextChoices?.absence_paid || []);
    }
  };

useEffect(() => {
  loadChoices()
},[contextChoices])
console.log(contextChoices,"contextChoices")

  const getEmployeeBranches = async () => {
    try {
      if (values.employee && typeof values.employee == "string") {
        const res = await getEmployeeBranchAccess(values.employee)
        setEmpAccessBranchs(contextChoices?.branch?.filter(el => res?.employee_access_branches?.includes(el.id)))
      } else if (values?.employee && typeof values?.employee == "object" && values?.employee?.length === 1) {
        const res = await getEmployeeBranchAccess(values?.employee[0]?.id)
        setEmpAccessBranchs(contextChoices?.branch?.filter(el => res?.employee_access_branches?.includes(el.id)))
      } else if (typeof values.employee == "object" && values.employee.length > 1) {
        setEmpAccessBranchs(contextChoices?.branch)
      } else {
        setEmpAccessBranchs([])
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    if(values.employee){
    getEmployeeBranches()
    }else{
      setEmpAccessBranchs([])
    }
  }, [values.employee])



  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title:copyShift ? "Copy Unavailability" : values.idSelected ? 'Unavailability' : 'Add Unavailability',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
 
  useEffect(() => {
    if (absenceType && absenceReason && values.absence_type) {
      let arr = absenceReason.filter(
        val => val.absence_type === values.absence_type
      );
      setAbsenceTypeFiltered(arr);
    }
  }, [values.absence_type]);


  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
  };
  const handelBranch = () => {
    values.branch_specific = !values.branch_specific;
    setValues({ ...values })
  }

  const checkFullDay = () => {
    const start_time = moment(values.start_date).format('hh:mm:ss');
    const end_time = moment(values.end_date).format('hh:mm:ss');

    if (start_time == '12:00:00 AM' && end_time == '11:59:59 PM ') {
      setIsFullDay(true);
    }
  };

  useEffect(() => {
    checkFullDay();
  }, []);

  useEffect(() => {
    if (values.is_overnight) {
      const start_time = moment(values.start, 'h:mma');
      const end_time = moment(values.end, 'h:mma');
      if (!end_time.isBefore(start_time)) {
        setInvalidOvernight(true);
        setInvalidOvernightMessage(InvalidRange);
      } else {
        setInvalidOvernight(false);
        setInvalidOvernightMessage('');
      }
    } else {
      const start_time = moment(values.start, 'h:mma');
      const end_time = moment(values.end, 'h:mma');
      if (end_time.isBefore(start_time)) {
        setInvalidOvernight(true);
        setInvalidOvernightMessage(InvalidRange);
      } else {
        setInvalidOvernight(false);
        setInvalidOvernightMessage('');
      }
    }
  }, [values.start, values.end, values.is_overnight]);

  useEffect(() => {
    if (isFullDay) {
      values.start = "00:00"
      values.end = "23:59"
      setValues({ ...values })
    } else {
      values.start = ""
      values.end = ""
      setValues({ ...values })
    }
  }, [isFullDay]);

  console.log(values, 'values');
  console.log(contextChoices, "contextChoices");
  const addValidationMessage = () => {
    if (isDateAssigned) {
      switch (typeOfMessage) {
        case UNAVAILABLE_EXISTING_SHIFT:
          return (
            <UnavailableExistingShift
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        case UNAVAILABLE_EXISTING_AVAILABLE:
          return (
            <UnavailableExistingAvailable
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={errorTime.start}
              endTime={errorTime.end}
            />
          );
        case AVAILABLE_EXISTING_UNAVAILABLE:
          return (
            <AvailableExistingUnavailable
              startDate={values.start_day ? '' : null}
              endDate={values.end_day ? '' : null}
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        default:
          return <Fragment />;
      }
    }
  };

  const handleAbsenceType = (value, label) => {
    setAbsence(label);
    handleDropAbsenceType({ absence_type: value ? value : null });
  };

  // const  daySelected = ()=>{
  //   if( start_day=='Monday' ){
  //     daysOfWeek[0].selected = true;
  //   }else if (  start_day=='Tuesday'){
  //     daysOfWeek[1].selected = true;
  //   }else if (  start_day=='Wednesday'){
  //     daysOfWeek[2].selected = true;
  //   }else if (  start_day=='Thursday'){
  //     daysOfWeek[3].selected = true;
  //   }else if (  start_day=='Friday'){
  //     daysOfWeek[4].selected = true;
  //   }else if (  start_day=='Saturday'){
  //     daysOfWeek[5].selected = true
  //   }else if (  start_day=='Sunday'){
  //     daysOfWeek[6].selected = true;
  //   }
  //   setDaysOfWeek(daysOfWeek)
  // }

  // useEffect(()=>{
  //   daySelected()
  // },[ ])

  const selectedDay = (day, indexWeek) => {
    daysOfWeek[indexWeek].selected = !daysOfWeek[indexWeek].selected;

    setDaysOfWeek(daysOfWeek);

    if (!values.details) {
      values.details = [];
    }

    values.details.forEach(detail => {
      if (detail.week_of_day - 1 === indexWeek) {
        detail.selected = daysOfWeek[indexWeek].selected;
      }
    });

    const addNew = values.details.find(
      detail => detail.week_of_day === daysOfWeek[indexWeek].dayValue
    );

    if (!addNew) {
      values.details.push({
        ...values.shiftValues,
        selected: true,
        week_of_day: daysOfWeek[indexWeek].dayValue,
        is_available: values.shiftValues.is_available
          ? values.shiftValues.is_available
          : false,
        id: '',
      });
    }

    setValues({ ...values });
  };

  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
  };

  const handleNotes = e => {
    values.absence_notes = e.target.value;
    setValues({ ...values });
  };

  console.log(values, 'KKKKKK');
  const rate_per_hr_hook = useInput(`pay_rate_per_hour`)
  // const amount_hook = useInput(`pay_amount`, 'isInt')
  const average_working_days_52_weeks_hook = useInput(`avg_52_weeks_hours`)
  // const average_work_hrs_52_weeks_hook = useInput(`average_work_hrs_52_weeks`)
  const absence_hours_to_paid_hook = useInput(`absence_hours_to_paid`)

  useEffect(() => {
    if (values.start && values.end) {
      const startTime = moment(values.start, 'HH:mm');
      const endTime = moment(values.end, 'HH:mm');
      if (endTime < startTime)
        endTime.add(1, 'day')

      // Calculate duration
      const duration = moment.duration(endTime.diff(startTime));

      // Format duration as hh:mm
      const durationFormatted = `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`;
      values.duration = durationFormatted;
    } else {
      values.duration = "00:00";
    }
    setValues({ ...values })
  }, [values.start, values.end])
  console.log(values[nameSection], "values")


  const autoFillPayRate = async () => {
    let match = regularShiftType && regularShiftType.find(el => el.name === "Absence")
    if (values.idSelected || !values.is_absent || !values.branch_id || !values?.start_day || values?.employee?.length > 1 || !match) {
      // values.pay_rate_card = ""
      // setValues({...values})
      return;
    }
    try {
      const body = {
        employeeId: values.employee[0]?.id,
        branch: values.branch_id,
        shiftType: match.id,
        start_date: values?.start_day
      }
      const res = await getDynamicPayRate(body)
      if (res.results && res?.results[0]) {
        let pay_rate = res.results[0].rate_card;
        values.pay_rate_card = pay_rate
        setValues({ ...values })
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    autoFillPayRate()
  }, [values.employee[0], values.branch_id, values?.start_day, contextChoices?.regular_shift_type])

  const dropDownSelect = (item, name) => {
    if (item.branch_id) {
      values.branch_id = item.branch_id
      setValues({ ...values })
    }
  }

  const getAbsenceHoursData = async () => {
    let employee = values.idSelected
      ? values.employee
        ? values.employee
        : ''
      : values?.employee && values?.employee?.length === 1
        ? values?.employee[0] && values?.employee[0]?.id
        : '';
    if (values.idSelected || !values.is_absent || !values.branch_id || !values?.start_day || !employee) {
      return;
    }
    const params = {
      branch: values.branch_id,
      employee: employee,
      start_date: values?.start_day,
      branch_specific: values.branch_specific
    }
    try {
      const res = await getAbsenceHours(params)
      if (res) {
        values.avg_52_weeks_hours = res.avg_52_weeks_hours;
        values.absence_hours_to_paid = res.absence_hours_to_paid;
        setValues({ ...values })
      }
    } catch (error) {
      console.error('Error fetching absence hours', error)
    }
  }

  useEffect(() => {
    getAbsenceHoursData()
  }, [values.employee, values?.start_day, values.branch_id, values.is_absent, values.branch_specific])


  const handleRecalculateAbsenceHr = async() => {
    setLoadingRecalculate(true)
    if(!values.idSelected){
      setLoadingRecalculate(false)
      return;
    }
    try {
    const res = await recalculatebasenceHr(values.idSelected)
    setValues({...values, ...res})
    setLoadingRecalculate(false)
    setButtonRecalculateIcon(CHECK)
    } catch (error) {
      console.error("Error: In recalculate absence hours API", error)
      setLoadingRecalculate(false)
      setButtonRecalculateIcon(CROSS)
    }
  }
console.log(values.employee, values.branch_id,"employee")

// To validated maximum date range
const getStartEndValidation = (date, type) => {
  if (type == "start") {
    return moment(date).subtract(1, "month").format("YYYY-MM-DD")
  } else {
    return moment(date).add(1, "month").format("YYYY-MM-DD")
  }
}


  const branch_hook = useInput(`branch_id`, 'isRequired');
  const employee_hook = useInput(`employee`, 'isRequired')
  const branch_specific_hook = useInput('branch_specific')
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className={'pt-2 pl-3 pr-3 pb-3'}>
          <FlexGrid3Item className="pl-0 pr-1 d-flex">
            <label
              style={{
                fontSize: '0.875rem',
                marginRight: '7px',
                alignSelf: 'center',
              }}>
              Unavailable
            </label>
            <ToggleButton
              label="Absence"
              disabled={values.idSelected}
              useInput={useInput('is_absent')}
              id="absence"
            // onClick={handelOverNight}
            />
          </FlexGrid3Item>
          {createUnAvailable && (
            <FlexGrid3Item className="d-flex mb-2">
              <div className='w-100 mr-2'>
                {values.idSelected ?
                  <DropdownSearchable
                    disabled={true}
                    placeHolder={'Select Employee'}
                    required
                    {...employee_hook}
                    options={employees}
                    selectedOptionsProps={values.employee}
                    selectedOption={values.employee}
                    onChange={value =>
                      dropDownHandleSelect({ employee: value ? value : null }, "employee")
                    }
                  />
                  :
                  <DropdownSearchable
                    isMulti={true}
                    disabled={false}
                    placeHolder={'Select Employee'}
                    required
                    {...employee_hook}
                    options={employees}
                    selectedOptionsProps={values.employee}
                    selectedOption={values.employee}
                    onChange={value =>
                      dropDownHandleSelect({ employee: value ? value : null })
                    }
                  />
                }
              </div>
              {<div className='w-100'>
                <DropdownSearchable
                  disabled={false}
                  placeHolder={"Select Branch"}
                  required
                  {...branch_hook}
                  options={empAccessBranchs || []}
                  selectedOptionsProps={values.branch_id}
                  selectedOption={values.branch_id}
                  onChange={value =>
                    dropDownSelect({ branch_id: value ? value : null })
                  }
                />
              </div>}
            </FlexGrid3Item>
          )}
          <FlexGrid3Container className="mt-2">
            <FlexGrid3Item className="pl-0 pr-1">
              <DateSelector
                isRequired
                minDate={values.end_day && getStartEndValidation(values.end_day, "start")}
                disabled={values.idSelected}
                errorMessage={dateMessage}
                labelTop="Start Day"
                data-error={dateError}
                {...useInput('start_day', 'isRequired')}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
              <DateSelector
                maxDate={values.start_day && getStartEndValidation(values.start_day, "")}
                isRequired
                disabled={values.idSelected}
                errorMessage={dateMessage}
                labelTop="End Day"
                data-error={dateError}
                {...useInput('end_day', 'isRequired')}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0 mt-2 mt-md-0">
              <TimeSelector
                isRequired={!isFullDay}
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="Start Time"
                // data-error={invalidOvernight}
                {...useInput('start', 'isRequired')}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="pr-0 pr-md-1 mt-2">
              <TimeSelector
                isRequired={!isFullDay}
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="End Time"
                // data-error={invalidOvernight}
                {...useInput('end', 'isRequired')}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>

            {/* Duration */}
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mt-2">
              <TimeSelector
                disabled={true}
                labelTop="Duration"
                {...useInput('duration')}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>

            {/* Average Work Hrs (52 weeks) */}
            {/* {values.is_absent && (  
           <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
              <InputLabelTop
                disabled={true}
                type="text"
                id="average_work_hrs_52_weeks"
                label={'Average Work Hrs (52 weeks)'}
                {...average_work_hrs_52_weeks_hook}
                maxlength={5}
              />
            </FlexGrid3Item>
           )} */}
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 mt-3">
              <div className='d-flex justify-content-between'>
                <ToggleButton
                  label="Is Overnight"
                  disabled={readOnly}
                  useInput={useInput('is_overnight')}
                  id="overnigth"
                  onClick={handelOverNight}
                />

              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 mt-3">
              <div className='d-flex justify-content-between'>
                <ToggleButton
                  label="Full Day"
                  useInput={useInput('full_day')}
                  id="full_day"
                  onClick={handelFullDay}
                // disabled={readOnly}
                // status={isFullDay}
                />
              </div>
            </FlexGrid3Item>
            {values.is_absent &&
              <FlexGrid3Item className="pl-0 pl-md-1 pr-1 mt-3">
                <div className='d-flex justify-content-between'>
                  <ToggleButton
                    label="Branch Specific"
                    useInput={branch_specific_hook}
                    id="branch_specifics"
                    onClick={handelBranch}
                  // disabled={readOnly}
                  // status={isFullDay}
                  />
                </div>
              </FlexGrid3Item>}
            {/* Average Working Days (52 weeks) */}
            {values.is_absent && (
              <FlexGrid3Item className="pl-1 mt-2">
                <InputLabelTop
                  disabled={true}
                  type="text"
                  id="average_working_days_52_weeks"
                  label={'Average Work Hrs (52 weeks)'}
                  onKeyPress={KeyPress.DECIMAL}
                  {...average_working_days_52_weeks_hook}
                  maxlength={5}
                />
              </FlexGrid3Item>)}

            {/* Absence Hrs to be paid */}
            {values.is_absent && (
              <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2">
                <InputLabelTop
                  disabled={true}
                  type="text"
                  id="absence_hours_to_paid"
                  label={'Absence Hrs To Be Paid'}
                  // onKeyPress={KeyPress.INTEGER}
                  {...absence_hours_to_paid_hook}
                  maxlength={5}
                />
              </FlexGrid3Item>
            )}



          </FlexGrid3Container>
          {
            !values.idSelected &&
            <FlexGrid3Item className="pl-1 pl-md-0 pr-0 pr-md-1 mt-2 d-flex justify-content-left ">
              {/* weekdays start here  */}
              <div className={'formWeek d-block  '}>
                <div
                  className={'pt-2 ml-md-1 ml-0 text-center'}
                  style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                  Day of the week
                </div>
                <WeekStyle>
                  {daysOfWeek.map((itemWeek, indexWeek) => {
                    return (
                      <ButtonWeek
                        key={itemWeek.day}
                        // disabled={readOnly}
                        add_background={itemWeek.selected}
                        onClick={() => {
                          selectedDay(itemWeek.day, indexWeek);
                        }}>
                        <div>
                          <label>
                            {itemWeek.day}
                            {/* {itemWeek.start_day} */}
                          </label>
                        </div>
                      </ButtonWeek>
                    );
                  })}
                </WeekStyle>
                {requireDayOfWeek ? (
                  <div
                    className={'text-center'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    <ErrorMessage fontSize={'0.6rem'}>
                      <label className={'mt-2'}>Is required</label>
                    </ErrorMessage>
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>

              {/* week days end here */}
            </FlexGrid3Item>
          }


          <div className="w-100 pt-2">
            <InputTextArea
              type="text"
              // height={'14.4rem'}
              id="emp_desc"
              label={'Comment'}
              placeholder={'Comments ....'}
              {...useInput('comment')}
            //   maxlength={5}
            />
          </div>
          {values && values.is_absent && values.is_absent ? (
            <div>
              <FlexGrid3Container className="mt-2">
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly}
                    placeHolder={'Absence Type'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absenceType ? absenceType: []}
                    selectedOptionsProps={values.absence_type}
                    selectedOption={values.absence_type}
                    onChange={(value, label) => handleAbsenceType(value, label)}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly || !values.absence_type}
                    placeHolder={'Absence Reason'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absenceTypeFiltered ? absenceTypeFiltered : []}
                    selectedOptionsProps={values.absence_reason}
                    selectedOption={values.absence_reason}
                    onChange={value =>
                      dropDownHandleSelect({
                        absence_reason: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>
                {absence === 'Sickness' ||
                  (values.absence_type &&
                    absenceType?.find(type => type.id === values?.absence_type)?.name === 'Sickness'
                  )
                  ? (
                  <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                    <DropdownSearchable
                      disabled={readOnly}
                      placeHolder={'Sickness Reason'}
                      // required
                      // {...useInput(`employee`, 'isRequired')}
                      options={sicknessReason ? sicknessReason : []}
                      selectedOptionsProps={values.sickness_reason}
                      selectedOption={values.sickness_reason}
                      onChange={value =>
                        dropDownHandleSelect({
                          sickness_reason: value ? value : null,
                        })
                      }
                    />
                  </FlexGrid3Item>
                ) : null}

                {/* </FlexGrid3Container>
              <FlexGrid3Container className="mt-2"> */}
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly}
                    placeHolder={'Absence informed method'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absenceInformedMethod ? absenceInformedMethod : []}
                    selectedOptionsProps={values.absence_informed_method}
                    selectedOption={values.absence_informed_method}
                    onChange={value =>
                      dropDownHandleSelect({
                        absence_informed_method: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly}
                    placeHolder={'Absence Planned'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absencePlanned ? absencePlanned : []}
                    selectedOptionsProps={values.absence_planned}
                    selectedOption={values.absence_planned}
                    onChange={value =>
                      dropDownHandleSelect({
                        absence_planned: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly}
                    placeHolder={'Absence Paid'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absencePaid ? absencePaid : []}
                    selectedOptionsProps={values.absence_paid}
                    selectedOption={values.absence_paid}
                    onChange={value =>
                      dropDownHandleSelect({ absence_paid: value ? value : null })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
                  <InputLabelTop
                    disabled={readOnly}
                    type="text"
                    id="warning_message"
                    label={'Notes'}
                    onChange={val => handleNotes(val)}
                    value={values.absence_notes}
                  />
                </FlexGrid3Item>

                {/* Absence Pay Rate */}
                <FlexGrid3Item className="pl-1 mt-2 pr-0  pr-md-1">
                  <DropdownSearchable
                    disabled={readOnly}
                    placeHolder={'Absence Rate Card'}
                    // required
                    // {...useInput(`employee`, 'isRequired')}
                    options={absenceRateCard}
                    selectedOptionsProps={values.pay_rate_card}
                    selectedOption={values.pay_rate_card}
                    onChange={value =>
                      dropDownHandleSelect({
                        pay_rate_card: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>

                {/* Rate/hr */}
                {
                  //  values.idSelected && 
                  <FlexGrid3Item className="pl-1 pr-0 mt-2 pr-md-1">
                    <InputLabelTop
                      disabled
                      type="text"
                      id="pay_rate_per_hour"
                      label={'Rate/hr'}
                      // onKeyPress={KeyPress.INTEGER}
                      value={values.pay_rate_per_hour}
                      {...rate_per_hr_hook}
                      maxlength={5}
                    />
                  </FlexGrid3Item>}

                {/* Amount */}
                {/* { values.idSelected && 
                <FlexGrid3Item className="pl-1 pr-0  pr-md-1">
                  <InputLabelTop
                    disabled
                    type="text"
                    id="pay_amount"
                    label={'Amount'}
                    onKeyPress={KeyPress.INTEGER}
                    {...amount_hook}
                    maxlength={5}
                  />
                </FlexGrid3Item>} */}
              </FlexGrid3Container>
            </div>
          ) : (
            ''
          )}

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              {values.idSelected ? (
                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    bgcolor="ERR_COLOR"
                    minWidth="9rem"
                    onClick={() => {
                      deleteAvailabilityDetail();
                    }}>
                    <span className={'mr-2'}>{'DELETE'}</span>
                    {handleDeleteClicked && (
                      !loadingSave ? (
                        <RiDeleteBinLine />
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      ))}
                  </PrimaryButtonForm>
                </div>
              ) : (<Fragment />)}

              {values.idSelected && <div className={'ml-0 ml-md-0 mr-2'}>
                <PrimaryButtonForm
                  // minWidth="9rem"
                  disabled={false}
                  onClick={() => handleRecalculateAbsenceHr()}>
                  <span className={'mr-2'}>{'Recalculate Absence Hr'}</span>
                  {!loadingRecalculate ? (
                    buttonRecalculateIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
              }

              <div className={'ml-0 ml-md-0 mr-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  disabled={isDateAssigned}
                  onClick={() => addNewAvailability(false)}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave || isDateAssigned ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>

            </div>
          </div>

          {/* {addValidationMessage()} */}
          {!isDateAssigned ? (
            <Fragment />
          ) : (
            ''
            // <div className={'d-flex justify-content-center mt-3'}>
            //   <div className={'mr-4'}>
            //     <PrimaryButtonForm
            //       minWidth="6rem"
            //       onClick={() => {
            //         splitAvailability(false, true);
            //         closeModal();
            //       }}>
            //       <span className={'mr-2 pl-3 pr-3'}>SPLIT SHIFTS</span>
            //     </PrimaryButtonForm>
            //   </div>
            //   <div className={'mr-4'}>
            //     <PrimaryButtonForm
            //       minWidth="6rem"
            //       onClick={() => {
            //         addNewAvailability(false, true);
            //         closeModal();
            //       }}>
            //       <span className={'mr-2 pl-3 pr-3'}>YES, PROCEED</span>
            //     </PrimaryButtonForm>
            //   </div>
            //   <div className={'ml-2'}>
            //     <PrimaryButtonForm
            //       bgcolor={'PURPLE_GRAY_500'}
            //       minWidth="6rem"
            //       onClick={closeModal}>
            //       <span className={'pl-4 pr-4'}>NO, CANCEL</span>
            //     </PrimaryButtonForm>
            //   </div>
            // </div>
          )}
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

AddUnavailability.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  addNewAvailability: PropTypes.func.isRequired,
  deleteAvailabilityDetail: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  dateMessage: PropTypes.string.isRequired,
  timeMessage: PropTypes.string.isRequired,
  dateError: PropTypes.bool.isRequired,
  invalidOvernight: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  //new props added
  nameSection: PropTypes.string.isRequired,
  setValues: PropTypes.object.isRequired,
  daysOfWeek: PropTypes.object.isRequired,
  setDaysOfWeek: PropTypes.object.isRequired,
  requireDayOfWeek: PropTypes.object.isRequired,
};

export default AddUnavailability;
