import React, {Fragment} from 'react';
import DynamicList from 'components/SharedComponents/DynamicList';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {FaTimes} from 'react-icons/fa';
import DateSelector from 'components/SharedComponents/DateSelector';
import moment from 'moment';
import { KeyPress } from 'utils/constants/regularExpressions';
import {CloseButtonWrapper, SkillRow, LeftSkillRow,BorderContainer} from './styles';
import {DELETE, COPY} from 'utils/constants/icons';
import {TitleSection} from '../AddModal/styles';
import {PURPLE, SOFT_RED, GRAY_300} from 'shared/styles/constants/colors';
import {IconWrapper} from 'components/SharedComponents/Table/styles';

function AddQualification(props) {
  
  const dropDownHandleSelect = (item, index, attribute) => {
    const newQualifications = [...props.employee_qualification];
    newQualifications[index][attribute] = item;

    props.setQualificationsCallback(newQualifications);
  };

  const handleInputChange = (e, attribute) => {
    const newQualifications = [...props.employee_qualification];
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    newQualifications[e.target.dataset.idx][attribute] = e.target.value;

    props.setQualificationsCallback(newQualifications);
  };
const addQualification=(index)=>{
  props.addQualificationCopy(index)
}
  return (
    <DynamicList
      title="Qualifications & Apprenticeships"
      titleAdd={props.readOnly ? false : "ADD QUALIFICATION"}
      titleSave="SAVE SKILLS"
      addNew={props.addNewQualificationCallback}
      save={() => {}}
      noSave={props.noSave}>
      <Fragment>
        {props.employee_qualification ? (
          props.employee_qualification.map((qualification, index) => {
            return (
              <BorderContainer className={'pl-1 pr-2 pt-3 pb-1 mb-3'}>
              <SkillRow className="d-flex ml-1 mb-2" key={index}>
                <LeftSkillRow className="d-flex flex-column w-100">
                  <div className={'d-flex mb-2'}>
                  <CloseButtonWrapper>
                    <FaTimes
                      onClick={() => {
                        props.deleteQualificationCallback(index);
                      }}
                    />
                    <IconWrapper
                        style={{marginRight:"10px"}}

color={PURPLE}
onClick={() => addQualification(index)}>
{COPY}
</IconWrapper>
                  </CloseButtonWrapper>
                  <div className="w-100 mr-2">
                <DropdownSearchable
                          placeHolder={'Qualification Level'}
                          options={props.choices.qualification_level}
                          disabled={props.readOnly}
                          selectedOption={
                            qualification
                              ? qualification.qualification_level
                                ? qualification.qualification_level
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'qualification_level',
                            )
                          }
                        />
                      </div>
                      <div className="w-100 mr-2">
                      <DropdownSearchable
                          placeHolder={'Qualification Type'}
                          options={props.choices.qualification_type}
                          disabled={props.readOnly}
                          selectedOption={
                            qualification
                              ? qualification.qualification_type
                                ? qualification.qualification_type
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'qualification_type',
                            )
                          }
                        />
                      </div>
                      <div className="w-100">
                      <DropdownSearchable
                          placeHolder={'Qualification Status'}
                          options={props.choices.qualification_status}
                          disabled={props.readOnly}
                          selectedOption={
                            qualification
                              ? qualification.qualification_status
                                ? qualification.qualification_status
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'qualification_status',
                            )
                          }
                        />
                      </div>
              </div>
              <div className="d-flex mb-1 ml-4">
              <div className="w-100 mr-2">
                <DropdownSearchable
                          placeHolder={'Qualification/Apprenticeship Name'}
                          options={props.choices.qualification_name}
                          disabled={props.readOnly}
                          selectedOption={
                            qualification
                              ? qualification.qualification_name
                                ? qualification.qualification_name
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'qualification_name',
                            )
                          }
                        />
                
                      </div>
                      <div className="w-95">
                      <DateSelector
                          labelTop="Completed Date"
                          dataIdx={index}
                          value={
                            qualification
                              ? qualification.completed_date
                                ? qualification.completed_date
                                : null
                              : null
                          }
                          onChange={e => {
                            handleInputChange(e, 'completed_date');
                          }}
                        />
                      </div>
              </div>
            
                  
                  </LeftSkillRow>
                </SkillRow>
                </BorderContainer>
              );
            })
          ) : (
            <></>
          )}
        </Fragment>
      </DynamicList>
    );
  }
  
  export default AddQualification;
  