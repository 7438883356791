import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {InvalidRange} from 'utils/constants/messages';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  WeekContainer,
  WeekStyle,
  ButtonWeek,
} from 'shared/styles/constants/tagsStyles';
import {format} from 'date-fns';
import {dateStringToDate, twentyFourTimeToTwelve} from 'shared/methods';
import {RiDeleteBinLine} from 'react-icons/ri';
import {
  AvailableExistingUnavailable,
  UnavailableExistingAvailable,
  AvailableExistingShift,
} from '../RotaValidations';
import {
  AVAILABLE_EXISTING_UNAVAILABLE,
  UNAVAILABLE_EXISTING_AVAILABLE,
  AVAILABLE_EXISTING_SHIFT,
} from 'utils/constants/rotaShift';
import PropTypes from 'prop-types';
import {ContainerResponsive, ShowTab} from '../../Team/AddModal/styles';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import moment from 'moment'
import { getPayRates } from 'utils/api/ClientApi';

const EditBulk = ({
  readOnly,
  fullBind,
  travelRate,
  dropDownHandleRate,
  dropDownHandleSelect,
  employees,
  useInput,
  values,
  splitAvailability,
  editAvailabilityBulk,
  deleteAvailabilityDetail,
  errorMessage,
  buttonIcon,
  loadingSave,
  textStatus,
  dateMessage,
  timeMessage,
  dateError,
  // invalidOvernight,
  choices,
  isDateAssigned,
  closeModal,
  typeOfMessage,
  // new props passed  below
  nameSection,
  setValues,
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  filterEmployee,
  filterClient,
  userStaff,
  filterSuperAdmin,
  filterAdmin,
  invalidOvernight,
  setInvalidOvernight,
  availabilitiesEmployeeFullName,
  availabilitiesEmployeeId,
  availabilitiesEmployeeIdName,
  employeesHandleSelect,
  availabilityDetailsDays,
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [isFullDay, setIsFullDay] = useState(false);
  const [isOverNight, setIsOverNight] = useState(false);
  const [filterShiftType,setFilterShiftType]=useState([]);
  const[invalidOvernightMessage,setInvalidOvernightMessage]=useState('')
  const [TravelData,setTravelData]=useState([])
  const [absenceType, setAbsenceType] = useState([]);
  const [absence, setAbsence]= useState('')

  // const [start_days, setStart_days] = useState(availabilityDetailsDays);
// const   start_days =  availabilityDetailsDays;

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Edit',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  useEffect(()=>{
    getPayRates()
    .then(res=>{
      setTravelData(  res  ||  [])
    })
  },[])
  useEffect(()=>{
    if(choices.absence_type && choices.absence_reason && values.absence_type){
      let arr = choices.absence_reason.filter(val =>  val.absence_type === values.absence_type );
      setAbsenceType(arr);
    }
  },[values.absence_type])

  useEffect(()=>{
    //let tempValues=choices && choices.regular_shift_type.filter(shift=>shift.name!='Shift' && shift.name!='Unavailability' && shift.name!='Absence')
    setFilterShiftType([...choices.regular_shift_type])
  },[choices.regular_shift_type])
useEffect(()=>{
  if(values.is_overnight){
    const start_time=moment(values.start,'h:mma')
    const end_time=moment(values.end,'h:mma')
    if(!end_time.isBefore(start_time)){
      setInvalidOvernight(true)
      setInvalidOvernightMessage(InvalidRange);
    }else {
      setInvalidOvernight(false)
          setInvalidOvernightMessage('');
        }
  }else {
    const start_time=moment(values.start,'h:mma')
    const end_time=moment(values.end,'h:mma')
    if(end_time.isBefore(start_time)){
      setInvalidOvernight(true)
      setInvalidOvernightMessage(InvalidRange);
    }else {
      setInvalidOvernight(false)
          setInvalidOvernightMessage('');
        }
    
  }
},[values.start,values.end,values.is_overnight, choices])

  const addValidationMessage = () => {
    if (isDateAssigned) {
      switch (typeOfMessage) {
        case AVAILABLE_EXISTING_SHIFT:
          return (
            <AvailableExistingShift
              startDate={
                values.start_day
                  ? "" 
                  : null
              }
              endDate={
                values.end_day
                  ? ""
                  : null
              }
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        case AVAILABLE_EXISTING_UNAVAILABLE:
          return (
            <AvailableExistingUnavailable
              startDate={
                values.start_day
                  ? ""
                  : null
              }
              endDate={
                values.end_day
                  ? ""
                  : null
              }
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        case UNAVAILABLE_EXISTING_AVAILABLE:
          return (
            <UnavailableExistingAvailable
              startDate={
                values.start_day
                  ? ""
                  : null
              }
              endDate={
                values.end_day
                  ? ""
                  : null
              }
              startTime={twentyFourTimeToTwelve(values.start)}
              endTime={twentyFourTimeToTwelve(values.end)}
            />
          );
        default:
          return <Fragment />;
      }
    }
  };

  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
  };
//   const checkFullDay=()=>{
//     const start_time = moment(values.start_date).format('hh:mm:ss A')
//     const end_time = moment(values.end_date).format('hh:mm:ss A')

// if(start_time == '12:00:00 AM'  &&  end_time == '11:59:59 PM '){
//   setIsFullDay(true);
// }
//   }
  
  // useEffect(()=>{
  //   checkFullDay()
  // },[  ])
  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
  };
  // added new repeat patterns
  // useEffect(() => {
  //   if (values.details && values.details.length >= 1) {
  //     values.details.forEach((detail, indexDetail) => {
  //       daysOfWeek.forEach((dayWeek, indexWeek) => {
  //         if (dayWeek.dayValue === detail.week_of_day) {
  //           daysOfWeek[indexWeek].selected = true;
  //           values.details[indexDetail].selected = true;
  //         }
  //       });
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.details]);
  const handleTravelTime=(e)=>{
    values.travel_time=moment(e.target.value, "h:mm:ss A").format("HH:mm")
    setValues({...values})
  }
  const selectedDay = (day, indexWeek) => {
    daysOfWeek[indexWeek].selected = !daysOfWeek[indexWeek].selected;

    setDaysOfWeek(daysOfWeek);

    if (!values.details) {
      values.details = [];
    }

    values.details.forEach(detail => {
      if (detail.week_of_day - 1 === indexWeek) {
        detail.selected = daysOfWeek[indexWeek].selected;
      }
    });

    const addNew = values.details.find(
      detail => detail.week_of_day === daysOfWeek[indexWeek].dayValue
    );

    if (!addNew) {
      values.details.push({
        ...values.shiftValues,
        selected: true,
        week_of_day: daysOfWeek[indexWeek].dayValue,
        is_available: values.shiftValues.is_available
          ? values.shiftValues.is_available
          : false,
        id: '',
      });
    }

    setValues({...values});
  };

  const getPayRateOptions=(option)=>{
    let arr = TravelData.filter(data=>data.rate_card_type==option)  || [];
    return arr;
  }


  const switchTravelModeValue = props => {
    let shift_type=filterShiftType && filterShiftType.find(shift=>shift.id===values.shift_type) && 
    filterShiftType.find(shift=>shift.id===values.shift_type).name
    // switch (shift_type) {
    //     case 'Availability':
    //     case 'Bank-Flexible':
        return (
         
                <>
             <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
             <DropdownSearchable
             required
               placeHolder={'Travel method'}
               disabled={readOnly}
               options={choices.travel_type}
               selectedOption={values.travel_method}
               onChange={value =>
                 dropDownHandleSelect({travel_method: value ? value : null})
               }
             />
           </FlexGrid3Item>
           <FlexGrid3Item className="pl-1 pr-0 pr-md-1 mb-2">
           <TimeSelector
                 id="extra_travel_time"
                 timeFormat={"HH:mm"}
                 dateFormat={"HH:mm"}
                 placeholderText={'hh:mm'}
                 locale={"sv-sv"}
                 timeCaption={'Duration'}
                 labelTop={'Extra Travel Time'}
                 value={values.travel_time}
                    onChange={handleTravelTime}
                //  {...useInput(`travel_time`)}
               />
           </FlexGrid3Item>
            <FlexGrid3Item>
              <DropdownSearchable
              disabled={readOnly}
                placeHolder={'Travel Rate'}
                options={travelRate}
                selectedOption={
                  values.travel_rate
                }
                onChange={value =>
                  dropDownHandleRate(
                    {
                      travel_rate: value ? value : null,
                    }
                  )
                }
              />
            </FlexGrid3Item>
           </>
             
        );  
  };

  const switchAbsenceModeValue = props => {
    let shift_type=filterShiftType && filterShiftType.find(shift=>shift.id===values.shift_type) && 
    filterShiftType.find(shift=>shift.id===values.shift_type).name
    switch (shift_type) {
        case 'Absence':
            return  <>
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly}
                  placeHolder={'Absence Type'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={choices ? choices.absence_type : []}
                  selectedOptionsProps={values.absence_type }
                  selectedOption={values.absence_type }
                  onChange={(value, label) =>
                     handleAbsenceType(value, label)
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly || !values.absence_type}
                  placeHolder={'Absence Reason'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={absenceType ? absenceType : []}
                  selectedOptionsProps={values.absence_reason}
                  selectedOption={values.absence_reason}
                  onChange={value =>
                    dropDownHandleSelect({ absence_reason: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              {absence === "Sickness" || (values.absence_type &&
              choices.absence_type.find(type=>type.id===values.absence_type) &&
              choices.absence_type.find(type=>type.id===values.absence_type).name==='Sickness'
              )
              ? 
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly}
                  placeHolder={'Sickness Reason'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={choices ? choices.sickness_reason : []}
                  selectedOptionsProps={values.sickness_reason}
                  selectedOption={values.sickness_reason}
                  onChange={value =>
                    dropDownHandleSelect({ sickness_reason: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              :
              null
              }
              
  
           
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly}
                  placeHolder={'Absence informed method'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={choices ? choices.absence_informed_method : []}
                  selectedOptionsProps={values.absence_informed_method}
                  selectedOption={values.absence_informed_method}
                  onChange={value =>
                    dropDownHandleSelect({ absence_informed_method: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly}
                  placeHolder={'Planned / Unplanned'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={choices ? choices.absence_planned : []}
                  selectedOptionsProps={values.absence_planned}
                  selectedOption={values.absence_planned}
                  onChange={value =>
                    dropDownHandleSelect({ absence_planned: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                <DropdownSearchable
                  
                  disabled={readOnly}
                  placeHolder={'Paid / Unpaid / Paid at other rate'}
                  // required
                  // {...useInput(`employee`, 'isRequired')}
                  options={choices ? choices.absence_paid : []}
                  selectedOptionsProps={values.absence_paid}
                  selectedOption={values.absence_paid}
                  onChange={value =>
                    dropDownHandleSelect({ absence_paid: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pl-1 pr-0 pt-2 pr-md-1">
            <InputLabelTop
              disabled={readOnly}
              type="text"
              id="warning_message"
              label={'Notes'}
              onChange={(val)=>handleNotes(val)}
              value={values.absence_notes}
            />
          </FlexGrid3Item>
             
  
          </>
      default:
        return <Fragment />;
    }
  };

  const switchPayRateValue = props => {
    let shift_type= choices.regular_shift_type && choices.regular_shift_type.find(shift=>shift.id===values.shift_type) && 
    choices.regular_shift_type.find(shift=>shift.id===values.shift_type).name
    switch (shift_type) {
      case 'Shift':
        return <Fragment />;
        case 'Availability':
          return <Fragment />;
          case 'Bank-Flexible':
            return <Fragment />;
      case 'Office - Training':
      case 'Office - Meetings':
      case 'Office - Administration':
      case 'Office - Supervision':
        return (
         <>
         <FlexGrid3Item
            className="pl-0 pr-1"
            >
          <DropdownSearchable
                  {...props}
                  placeHolder={'Pay Rate'}
                  options={getPayRateOptions('Office')}
                  selectedOption={
                    values.regular_shift_pay_rate_id
                      ? values.regular_shift_pay_rate_id
                      : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                      { regular_shift_pay_rate_id: value ? value : null }
                    )
                  }
                />
                 </FlexGrid3Item>
                </>
        );
      case 'Unavailability':
        return <Fragment />;

      case 'On Call':
        return (
          <>
           <FlexGrid3Item
            className="pl-0 pr-1"
            >
          <DropdownSearchable
                  required
                  {...props}
                  placeHolder={'Pay Rate'}
                  options={getPayRateOptions('On Call')}
                  selectedOption={
                    values.regular_shift_pay_rate_id
                      ? values.regular_shift_pay_rate_id
                      : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                      { regular_shift_pay_rate_id: value ? value : null }
                    )
                  }
                />
                 </FlexGrid3Item>
                
              </>
        );
        default:
        return <Fragment />;
    }
  };
  const handleDropAbsenceType=item=>{
    if(item.absence_type || item.absence_type==null){
      values.absence_reason=null
      values.sickness_reason=null
    }
    setValues({ ...values, ...item });
  }

  const handleNotes =(e)=>{
    values.absence_notes = e.target.value;
    setValues({...values});
  }

  const handleAbsenceType =(value, label)=>{
      setAbsence(label);
     handleDropAbsenceType({ absence_type : value ? value : null })
  }

  const calculateEndValueProps = value => {
    let shiftname= filterShiftType && filterShiftType.find(shift=>shift.id===values.shift_type) && filterShiftType.find(shift=>shift.id===values.shift_type).name
    switch (shiftname) {
      case 'Availability' :
        case 'Bank-Flexible':
        return 'isRequired';
      default:
        return '';
    }
  };
  // const  daySelected = ()=>{  
  //   if( start_days=='Monday' ){
  //     daysOfWeek[0].selected = true;
  //   }else if (  start_days=='Tuesday'){
  //     daysOfWeek[1].selected = true;
  //   }else if (  start_days=='Wednesday'){
  //     daysOfWeek[2].selected = true;
  //   }else if (  start_days=='Thursday'){
  //     daysOfWeek[3].selected = true;
  //   }else if (  start_days=='Friday'){
  //     daysOfWeek[4].selected = true;
  //   }else if (  start_days=='Saturday'){
  //     daysOfWeek[5].selected = true
  //   }else if (  start_days=='Sunday'){
  //     daysOfWeek[6].selected = true;
  //   }
  //   setDaysOfWeek(daysOfWeek)
   
  // }
  
  // useEffect(()=>{
  //   daySelected()
  // },[ ])
  console.log(values.employee, 56955)

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className={'pt-2 pl-3 pr-3 pb-3'}>
         
          <FlexGrid3Container className="mt-2">
          <FlexGrid3Item className="pl-0 pr-1 mb-2">
              <DropdownSearchable
                placeHolder={'Shift Type'}
                  {...useInput(`shift_type`, '')}
                disabled={readOnly}
                options={filterShiftType}
                selectedOption={values.shift_type}
                onChange={value =>
                  dropDownHandleSelect({shift_type: value ? value : null})
                }
              />
            </FlexGrid3Item>
           
            {switchTravelModeValue(
                  {
                    ...useInput(`travel_method`,
                      calculateEndValueProps(
                        values.shift_type
                      )
                    ),
                  })} 
            {switchPayRateValue(
            {
              ...useInput(`regular_shift_pay_rate_id`,''
              ),
            })} 
           

            <FlexGrid3Item className={`pl-1 pr-0 pr-md-1 ${values.shift_type && values.shift_type!='2079a68d-eb9b-42de-8f94-bc398abd166c' ? "mt-1" : "mt-2"}`}>
              <TimeSelector
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="Start time"
                {...useInput('start', '')}
                             //added below code  for time in 24hrs format 
                             timeFormat={"HH:mm"}
                             dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-0  pr-1 mt-2">
              <TimeSelector
                disabled={readOnly || isFullDay}
                data-error={invalidOvernight}
                errorMessage={invalidOvernightMessage}
                labelTop="End time"
                {...useInput('end', '')}
                             //added below code  for time in 24hrs format 
                             timeFormat={"HH:mm"}
                             dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
          {switchAbsenceModeValue()}
            <FlexGrid3Item className="pl-0 pl-md-1 pr-1 mt-3">
            <ToggleButton
              label="Is Overnight"
              disabled={readOnly}
              useInput={useInput('is_overnight')}
              id="overnigth"
              onClick={handelOverNight}
            />
            </FlexGrid3Item>
           
              {/* <div className={'formWeek d-block'}>
            <div
              className={'pt-2 ml-md-1 ml-0 text-center'}
              style={{fontSize: '0.6rem', fontWeight: 'bold'}}>
              Day of the week
            </div>
          
            <WeekStyle  >
              {daysOfWeek.map((itemWeek, indexWeek) => {

                return (
                  <ButtonWeek
                  // disabled={values.start_date? true : false}
                    key={itemWeek.day}
                    add_background={itemWeek.selected}
                    onClick={() => {
                    selectedDay(itemWeek.day, indexWeek);
                    }}
                    // disabled={readOnly}
                    >
                    <div>
                      <label>{itemWeek.day}</label>
                    </div>
                  </ButtonWeek>
                );
              })}
            </WeekStyle>
           
            {requireDayOfWeek ? (
              <div
                className={'text-center'}
                style={{fontSize: '0.6rem', fontWeight: 'bold'}}>
                <ErrorMessage fontSize={'0.6rem'}>
                  <label className={'mt-2'}>Is required</label>
                </ErrorMessage>
              </div>
             
            ) : (
              <Fragment />
            )}
          </div> */}
{/* week days end here */}

          </FlexGrid3Container>
          <ContainerResponsive className="d-flex  mt-3">
            <ToggleButton             
              label="Full Day"
              useInput={useInput('full_day')}
              id="full_day"
              onClick={handelFullDay}
              // disabled={readOnly}
              // status={isFullDay}
            />
          </ContainerResponsive>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2'}>
                {values.idSelected ? (
                 <>
                  <PrimaryButtonForm
                    bgcolor="ERR_COLOR"
                    minWidth="9rem"
                    onClick={() => {
                      deleteAvailabilityDetail();
                    }}>
                    <span className={'mr-2'}>{'DELETE'}</span>
                    {!loadingSave ? (
                      <RiDeleteBinLine />
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                  <PrimaryButtonForm
                  minWidth="9rem"
                  disabled={isDateAssigned}
                  onClick={() => editAvailabilityBulk(true)}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave || isDateAssigned ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
                 </>
                ) : (
                  <PrimaryButtonForm
                    minWidth="9rem"
                    disabled={isDateAssigned}
                    onClick={() => editAvailabilityBulk(true)}>
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave || isDateAssigned ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                )}
              </div>
            </div>
          </div>

        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

EditBulk.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  editAvailabilityBulk: PropTypes.func.isRequired,
  deleteAvailabilityDetail: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  dateMessage: PropTypes.string.isRequired,
  timeMessage: PropTypes.string.isRequired,
  dateError: PropTypes.bool.isRequired,
  invalidOvernight: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  //new props added
  nameSection: PropTypes.string.isRequired,
  setValues : PropTypes.func.isRequired,
  daysOfWeek:PropTypes.array.isRequired,
  setDaysOfWeek:PropTypes.func.isRequired,
  requireDayOfWeek: PropTypes.bool.isRequired,
};

export default EditBulk;
