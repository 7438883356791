import React from 'react';
import HeaderTable from './HeaderTable';
import BodyTable from './BodyTable';
import { Container, LoaderWraper } from './styles';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import Spinner from 'components/SharedComponents/Spinner';

const PermissionsTable = ({
  rolValues,
  selectedRol,
  setRolValues,
  handleCheckboxClick,
  userType,
  permissionLoading,
  loadingRolValues,
  updateRowLevelPermission,
  expandable = {},
  setExpandable = () => {},
  scrollValue
}) => {
  return (
    <Container>
      <div className="wrapper">
        {loadingRolValues ? (
          <LoaderWraper>
            <Spinner />
          </LoaderWraper>
        ) : rolValues.length > 0 ? (
          <table className="table table-sm">
            <thead>
              <HeaderTable selectedRol={selectedRol} />
            </thead>
            <tbody>
              <BodyTable
                handleCheckboxClick={handleCheckboxClick}
                rolValues={rolValues}
                selectedRol={selectedRol}
                setRolValues={setRolValues}
                userType={userType}
                permissionLoading={permissionLoading}
                updateRowLevelPermission={updateRowLevelPermission}
                expandable={expandable}
                setExpandable={setExpandable}
                scrollValue={scrollValue}
              />
            </tbody>
          </table>
        ) : (
          <NoDataFoundMessage top={'50%'} style={{fontSize: '0.85rem'}}>
            Please select a role to populate fields here
          </NoDataFoundMessage>
        )}
      </div>
    </Container>
  );
};

export default PermissionsTable;
