import { Switch, TextField } from '@mui/material'
import JoditEditor from 'jodit-react';
import React from 'react'
import { useRef } from 'react';
import { useMemo } from 'react';

const FileUploadCom = ({ setJson, json }) => {
    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: 'Start typings...',
        }),
        []
    )

    return (
        <div className='mb-4'>
            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginBottom: 10 }}>
                <div style={{ marginBottom: 10, display: "flex", flex: 1 }}>

                    <TextField
                        id="outlined-error"
                        label="Input Label *"
                        placeholder='Input label'
                        defaultValue={json?.fileUpload?.label}
                        style={{ marginRight: 10, flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, fileUpload: {
                                    ...json.fileUpload,
                                    label: event.target.value
                                }
                            })
                        }}
                    />
                    {/* <TextField
                        id="outlined-error-helper-text"
                        label="Placeholder"
                        placeholder="placeholder"
                        defaultValue={json.fileUpload?.placeholder}
                        style={{ flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, fileUpload: {
                                    ...json.fileUpload,
                                    placeholder: event.target.value
                                }
                            })
                        }}
                    /> */}
                </div>

               <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.fileUpload?.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, fileUpload: {
                                ...json.fileUpload,
                                description: event.target.value
                            }
                        })
                    }}
                />
                 <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.fileUpload?.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, fileUpload: {
                                ...json.fileUpload,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileUpload?.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileUpload: {
                                                ...json.fileUpload,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileUpload?.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileUpload: {
                                                ...json.fileUpload,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileUpload?.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileUpload: {
                                                ...json.fileUpload,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.fileUpload?.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.fileUpload?.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, fileUpload: {
                                ...json.fileUpload,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
            </div>
        </div>

    )
}

export default FileUploadCom;
