import React, { useEffect, useState, useRef, useContext } from 'react';
import { useModal } from 'hooks/ModalHook';
import { useHistory } from 'react-router-dom';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { getAdmin, getSuperAdmin } from '../../../../utils/localStorage/token';
//import Funder from '../../Settings/SettingsClient/Funder'
import {
  getSubSectionPermissions,
  getStaff,
} from '../../../../utils/localStorage/user';
import {
  REPORT,
  CLIENT,
  CLIENT_DETAILS,
  PREFERRED_EMPLOYEES,
  HEALTH,
  ALLERGIES,
  AVOID_EMPLOYEES,
  SKILLS_TRAINING,
  COMPETENCIES,
  MEDICAL,
  IMPORTANT_CONTACTS,
  VISIT_CARE_NOTES,
  ROTA_EMAIL,
  CLIENT_KPI_DETAILS,
  CLIENT_WEAKLY_DETAILS,
  KPI,
  CLIENT_TASKS,
  REPORT_SERVICE_CONTRACT_ROUTE,
  REPORT_CLIENT,
  CRON_JOB_ROUTE,
  MEDICATION,
  CLIENT_VISIT_ROUTE,
  DELETED_VISIT_ROUTE,
} from 'utils/constants/routes';
import {
  ContainerTabStyled,
  Title,
  LinkTabStyled,
  LinkTab,
  TabStyledNew,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  REPORT_CLIENT_PD,
  REPORT_CLIENT_PREFERRED_EMPLOYEE,
  REPORT_CLIENT_AVOID_EMPLOYEE,
  REPORT_CLIENT_COMPETENCY,
  REPORT_CLIENT_SKILLS,
  REPORT_CLIENT_HEALTH,
  REPORT_CLIENT_MEDICAL,
  REPORT_CLIENT_ALLERGIES,
  REPORT_CLIENT_CARE_CONTACT,
  REPORT_CLIENT_ROTA_EMAIL,
  REPORT_VISIT_CARE_CONTACT,
  REPORT_TASKS,
  REPORT_CLIENT_TASK,
  REPORT_SERVICE_CONTRACT,
  REPORT_CLIENT_SERVICE_CONTRACT,
  CRON_JOB_VISIT_REPORTS,
  CRON_JOB_MEDICATION_REPORTS,
  CRON_JOB_DELETED_VISIT_REPORTS
} from '../../../../utils/constants/permissions';
import ClientFilters from './ClientFilters';
import _, { get } from 'lodash';
import { AppContext } from 'context';
import ClientRota from './ClientRota';
import NewVisitCare from './ClientFilters/NewVisitCare';
import DailyWeaklyFilter from './ClientFilters/DailyWeaklyFiter';
import { Header } from './ClientFilters/styles';

const TAB_CLIENT_DETAILS_URL = REPORT + CLIENT + CLIENT_DETAILS;

const TAB_HEALTH_URL = REPORT + CLIENT + HEALTH;
const TAB_ALLERGIES_URL = REPORT + CLIENT + ALLERGIES;
const TAB_PREFERRED_EMPLOYEES_URL = REPORT + CLIENT + PREFERRED_EMPLOYEES;

const TAB_AVOID_EMPLOYEES_URL = REPORT + CLIENT + AVOID_EMPLOYEES;
const TAB_MEDICAL_URL = REPORT + CLIENT + MEDICAL;
const TAB_PREFERRED_SKILLS_URL = REPORT + CLIENT + SKILLS_TRAINING;
const TAB_PREFERRED_COMPETENCIES_URL = REPORT + CLIENT + COMPETENCIES;
const TAB_IMPORTANT_CONTACTS_URL = REPORT + CLIENT + IMPORTANT_CONTACTS;

const TAB_VISIT_CARE_NOTES_URL = REPORT + CLIENT + VISIT_CARE_NOTES;
const TAB_ROTA_EMAIL_URL = REPORT + CLIENT + ROTA_EMAIL;
const TAB_CLIENT_TASKS_URL = REPORT + CLIENT + CLIENT_TASKS
const TAB_SERVICE_CONTRACT = REPORT + REPORT_CLIENT + REPORT_SERVICE_CONTRACT_ROUTE
const TAB_DELETED_VISIT = REPORT + REPORT_CLIENT + DELETED_VISIT_ROUTE

// Cron Job URL
const TAB_CRON_JOB_URL = REPORT + CLIENT + CRON_JOB_ROUTE + CLIENT_VISIT_ROUTE;
const TAB_CRON_JOB_MEDICATION_URL = REPORT + CLIENT + CRON_JOB_ROUTE + MEDICATION;
const TAB_CRON_JOB_CLIENT_VISIT_URL = REPORT + CLIENT + CRON_JOB_ROUTE + CLIENT_VISIT_ROUTE;

const tabTeamArray = [
  {
    label: 'Client Details',
    route: TAB_CLIENT_DETAILS_URL,
    name: 'Personal_Details',
    permission: REPORT_CLIENT_PD,
  },

  {
    label: 'Preferred Employees',
    route: TAB_PREFERRED_EMPLOYEES_URL,
    name: 'Client_Preferred_Employee',
    permission: REPORT_CLIENT_PREFERRED_EMPLOYEE,
  },
  {
    label: 'Preferred Skills',
    route: TAB_PREFERRED_SKILLS_URL,
    name: 'Client_Skills',
    permission: REPORT_CLIENT_SKILLS,
  },
  {
    label: 'Preferred Competencies',
    route: TAB_PREFERRED_COMPETENCIES_URL,
    name: 'Client_Competency',
    permission: REPORT_CLIENT_COMPETENCY,
  },
  {
    label: 'Avoid Employees',
    route: TAB_AVOID_EMPLOYEES_URL,
    name: 'Client_Avoid_Employee',
    permission: REPORT_CLIENT_AVOID_EMPLOYEE,
  },
  {
    label: 'Health',
    route: TAB_HEALTH_URL,
    name: 'Client_Health',
    permission: REPORT_CLIENT_HEALTH,
  },
  {
    label: 'Medical',
    route: TAB_MEDICAL_URL,
    name: 'Client_Medical',
    permission: REPORT_CLIENT_MEDICAL,
  },
  {
    label: 'Allergies',
    route: TAB_ALLERGIES_URL,
    name: 'Client_Allergies',
    permission: REPORT_CLIENT_ALLERGIES,
  },
  {
    label: 'Important Contacts',
    route: TAB_IMPORTANT_CONTACTS_URL,
    name: 'Client_Care_Contact',
    permission: REPORT_CLIENT_CARE_CONTACT,
  },
  {
    label: 'Visit Care Notes',
    route: TAB_VISIT_CARE_NOTES_URL,
    name: 'Client_Care_Notes',
    permission: REPORT_VISIT_CARE_CONTACT,
  },
  {
    label: 'Tasks',
    route: TAB_CLIENT_TASKS_URL,
    name: 'ClientTaskReport',
    permission: REPORT_CLIENT_TASK,
  },
  {
    label: 'Service Contract',
    route: TAB_SERVICE_CONTRACT,
    name: 'ServiceContract',
    // permission: REPORT_SERVICE_CONTRACT,
    permission: REPORT_CLIENT_SERVICE_CONTRACT
  },
  {
    label: 'Deleted Visit',
    route: TAB_DELETED_VISIT,
    name: 'Deleted Visit',
    permission: CRON_JOB_DELETED_VISIT_REPORTS,
    apiKey: "deleted-visit"
  },
];
const tabCronJobArray = [
  {
    label: 'Client Visit',
    route: TAB_CRON_JOB_CLIENT_VISIT_URL,
    name: 'Client Visit',
    permission: CRON_JOB_VISIT_REPORTS,
    apiKey: "visit-cron-jobs"
  },
  {
    label: 'Medication',
    route: TAB_CRON_JOB_MEDICATION_URL,
    name: 'Medication',
    permission: CRON_JOB_MEDICATION_REPORTS,
    apiKey: "medication-cron-jobs"
  }
];

const ReportClient = props => {
  const [reportClientArray, setReportClientArray] = useState(tabTeamArray);
  const [cronJobArray, setCronJobArray] = useState(tabCronJobArray);
  const [teamTabs, setTeamTabs] = useState('client');
  const [clientRotaPermission, setClientRotaPermission] = useState(false);
  const [updateFunderSettings, setUpdatePermissionsFunderSettings] =
    useState(false);
  const [createFunderSettings, setCreatePermissionsFunderSettings] =
    useState(false);
  const [deletePermissionFunderSettings, setDeletePermissionsFunderSettings] =
    useState(false);
  const [readPermissionFunderSettings, setReadPermissionsFunderSettings] =
    useState(false);
  const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] =
    useState(false);
  const [createPrioritySettings, setCreatePermissionsPrioritySettings] =
    useState(false);
  const [
    deletePermissionPrioritySettings,
    setDeletePermissionsPrioritySettings,
  ] = useState(false);
  const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] =
    useState(false);
  const {
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  const [newData, setNewData] = useState('');
  let history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const userStaff = contextIsStaff;
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  const reviewTab = tab => {
    return tab === teamTabs;
  };
  useEffect(() => {
    let rolePermissions = {};
    const rolesPermissions = contextSubSectionPermissions;

    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    console.log(rolesPermissions, '552144');
    if (contextIsAdmin || contextIsSuperAdmin) {
      setReportClientArray(tabTeamArray);
      setCronJobArray(tabCronJobArray)
      setClientRotaPermission(true);
    } else {
      if (!_.isEmpty(rolePermissions)) {
        let values1 = reportClientArray.filter(item => {
          if (item.permission === CRON_JOB_DELETED_VISIT_REPORTS) {
            return rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_CREATE_PERMISSION
            ] && item
          } else {
            return rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_READ_PERMISSION
            ] && item
          }
        }
        );
        setClientRotaPermission(
          rolePermissions[REPORT_CLIENT_ROTA_EMAIL]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ]
        );
        setReportClientArray(values1);

        //Cron Job Permission
        let hasCronJobTabPermissions = tabCronJobArray.filter(item => {
          if (item.permission) {
            return rolePermissions[item.permission]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          } else {
            return true
          }
        });
        console.log(rolePermissions,"rolePermissions")
        setCronJobArray(hasCronJobTabPermissions)

      }
    }
  }, [contextIsSuperAdmin, contextIsAdmin]);

  const tabClick = tab => {
    setNewData(tab);

    setTeamTabs(tab);
    if (tab === 'client') {
      history.push(TAB_CLIENT_DETAILS_URL);
    } else if (tab === "client_email") {
      history.push(REPORT + CLIENT);
    } else if (tab === "cron_job") {
      history.push(TAB_CRON_JOB_URL);
    }
  };
  console.log(teamTabs, cronJobArray, "teamTabs")
  return (
    <div>
      <Title>Report Client</Title>
      <Header>
        <div className={'d-flex w-100 text-center'}>
          <LinkTabStyled active={reviewTab('client')}>
            <div onClick={() => tabClick('client')} className={'styledLink'}>
              <LinkTab active={reviewTab('client')}>
                Client
              </LinkTab>
            </div>
          </LinkTabStyled>
          {clientRotaPermission && (
            <LinkTabStyled active={reviewTab('client_email')}>
              <div
                onClick={() => tabClick('client_email')}
                className={'styledLink'}>
                <LinkTab active={reviewTab('client_email')}>
                  Client Emails
                </LinkTab>
              </div>
            </LinkTabStyled>
          )}
          {cronJobArray?.length ? (
            <LinkTabStyled active={reviewTab('cron_job')}>
              <div
                onClick={() => tabClick('cron_job')}
                className={'styledLink'}>
                <LinkTab active={reviewTab('cron_job')}>
                  Cron Job
                </LinkTab>
              </div>
            </LinkTabStyled>
          ) : null}
        </div>
      </Header>
      <>
        {teamTabs === 'client' && (
          <div className="p-2 p-sm- ">
            <ContainerTabStyled className="text-center">
              <div className={'d-flex flex-wrap w-100'}>
                {reportClientArray.map(item => {
                  return (
                    <TabStyledNew
                      style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                      active={reviewLocation(item.route)}
                      onClick={() => history.push(item.route)}>
                      {item.label}
                    </TabStyledNew>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
        )}
        <Switch>
          {teamTabs === 'client' &&
            reportClientArray.map(item => {
              return (
                <Route exact path={item.route}>
                  <ClientFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                    apiKey={item?.apiKey && item?.apiKey}
                  />
                </Route>
              );
            })}
        </Switch>


        {teamTabs === 'client_email' && (
          <ClientRota
            location={props.location}
            updateFunderSettings={updateFunderSettings}
            createFunderSettings={createFunderSettings}
            deletePermissionFunderSettings={deletePermissionFunderSettings}
            readPermissionFunderSettings={readPermissionFunderSettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
            userStaff={userStaff}
          />
        )}
      </>


      {/* Cron Job Content here */}
      {teamTabs === 'cron_job' && (
        <div className="p-2">
          <ContainerTabStyled className="text-center">
            <div className={'d-flex justify-content-center w-100'}>
              {cronJobArray.map(item => {
                return (
                  <TabStyledNew
                    style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                    active={reviewLocation(item.route)}
                    onClick={() => history.push(item.route)}>
                    {item.label}
                  </TabStyledNew>
                );
              })}
            </div>
          </ContainerTabStyled>
        </div>
      )}
      <Switch>
        {teamTabs === 'cron_job' &&
          cronJobArray.map(item => {
            return (
              <Route exact path={item.route}>
                <ClientFilters
                  location={props.location}
                  tabClick={item.name}
                  updateFunderSettings={updateFunderSettings}
                  createFunderSettings={createFunderSettings}
                  deletePermissionFunderSettings={
                    deletePermissionFunderSettings
                  }
                  readPermissionFunderSettings={readPermissionFunderSettings}
                  filterAdmin={filterAdmin}
                  filterSuperAdmin={filterSuperAdmin}
                  userStaff={userStaff}
                  apiKey={item.apiKey}
                />
              </Route>
            );
          })}
      </Switch>


    </div>
  );
};

export default ReportClient;
