import React from 'react';
import Image from 'components/SharedComponents/Image';

import {EmployeeSelectedContainer, ClientInfo, UserImg} from './styles';

const CustomHeaderLabel = ({photoRow = '', rows = [], name = '', lastName = '', dateOfBirth='',popupStyle= false,circleImage}) => {
  return (
    <EmployeeSelectedContainer className={popupStyle ? 'd-flex justify-content-end' : 'd-flex'} style={popupStyle ? {marginRight:'-100px'} : {}}>
  
      {rows.map((row, index) => {
        return (
          <ClientInfo key={index} style={{fontSize:'larger'}}>
            {row.label}
            <div style={{fontSize:'medium'}}>{row.value}</div>
          </ClientInfo>
        );
      })}
    </EmployeeSelectedContainer>
  );
};

export default CustomHeaderLabel;
