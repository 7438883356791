import { apiURL } from '../env';
import { get, post, deleteFetch } from './Api';
import { getTenant } from '../localStorage/token';
import { methods } from 'utils/constants/api';


const { PATCH } = methods

const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
};

export const getNotificationSettings = async () => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/push-notification/notification-setting/?limit=100&offset=0`

    return await get(url)
}

export const getNotificationSettingsSections = async () => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/push-notification/notification-section/?limit=100&offset=0`

    return await get(url)
}

export const updateNotificationSettings = async (value) => {
    const baseUrl = getBaseUrl();
    const method = PATCH;
    const url = `${baseUrl}/core/push-notification/notification-setting/update_notification_setting/`
    const body = value

    return await post(url, body, method)
}

export const updateNotificationSettingAll = async (value) => {
   const baseUrl = getBaseUrl();
   const method = PATCH;
   const url = `${baseUrl}/core/push-notification/notification-setting/update_all/`
   const body = value;

     console.log("all update",url, body, method)
   return post(url, body, method)
}