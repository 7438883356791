// contextChoices[val.name].map((vale) => ({ value: vale.id, label: vale.name }))

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { AppContext } from 'context';
import React, { useState } from 'react'
import { useContext } from 'react';

export default function DropDownForChoices({disabled=false,json, jsonData, setJsonData, index }) {
    // const [label, setLabel] = useState(json.dropdown.label)

    const [selected, setSelected] = useState(json.value)
    const {
        contextChoices,
    } = useContext(AppContext);
    // debugger
    console.log(contextChoices[json.choices])
    return (
        <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400 }}>
            <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // disabled
                label={json.placeholder}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                value={json?.value}
                onChange={item => {
                    const data = jsonData && jsonData?.map((val, newIndex) => {
                        console.log(newIndex, index)
                        if (newIndex === index) {
                            return ({
                                ...val,
                                value: item.target.value
                            })
                        }
                        return val
                    })
                    setJsonData(data)
                }}
            >
                {
                   contextChoices && json.choices && contextChoices[json.choices] && contextChoices[json.choices]?.map((vale) => ({ value: vale.id, label: vale.name })).map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
