import styled from 'styled-components';
import {AnimationStyle} from 'shared/styles/buttons';
import {MD} from 'shared/styles/constants/resolutions';
import {ERR_COLOR,WHITE,BLACK_900} from 'shared/styles/constants/colors';

export const CloseButtonWrapper = styled.div`
  ${AnimationStyle}
  cursor: pointer;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  color: ${ERR_COLOR};
  :hover {
    background-color: transparent;
  }
`;

export const SkillRow = styled.div`
  @media (max-width: ${MD}) {
    flex-direction: column;
  }
`;

export const LeftSkillRow = styled.div`
  @media (max-width: ${MD}) {
    margin-bottom: 0.5rem;
  }
`;
export const BorderContainer = styled.div`
  background: ${WHITE}

  border-top: 3px solid white
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  width: ${props => (props.width ? props.width : 'auto')};

  min-height: ${props => (props.minHeight ? props.minHeight : 'auto')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'auto')};

  -webkit-box-shadow: 0 0.1875rem 0.25rem ${BLACK_900};
  box-shadow: 0 0.1875rem 0.25rem ${BLACK_900};
`;