import {apiURL} from '../env';
import {get} from './Api';
import {getTenant} from '../localStorage/token';

const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};

export const getEmployeesStatus = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employee-status/`;

  return get(url);
};
export const getRolePermissions = () => {
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/roles_and_permissions/user-roles-and-permissions?view=Web`;

  return get(url);
};
export const getEmploymentTypes = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employment-type/`;

  return get(url);
};
export const getEmployeeCategories = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/employee-category/`;

  return get(url);
};

export const getJobTitles = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/job-title/`;

  return get(url);
};

export const getChoices = changedKey => {
  const baseUrl = getBaseUrl();
  const url = changedKey
    ? `${baseUrl}/core/choices/?key=${changedKey}`
    : `${baseUrl}/core/choices/`;
  return get(url);
};

export const getChoicesMap = changedKey => {
  const baseUrl = getBaseUrl();
  const url = changedKey
    ? `${baseUrl}/core/map/choices/?key=${changedKey}`
    : `${baseUrl}/core/map/choices/`;
  return get(url);
};

export const getClientTypes = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/client-type/`;

  return get(url);
};

export const getClientsStatus = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/client-status/`;

  return get(url);
};

export const getCompanyBranches = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/companies/all/branches/`;

  return get(url);
};

export const getFunderTypes = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/core/funder-source/`;

  return get(url);
};
