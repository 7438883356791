import React, { useState, useEffect, Fragment } from 'react';
import {
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, SAVE, SAVED, InvalidOrEmptyValues, } from 'utils/constants/messages';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { addFunder, getFunderById } from 'utils/api/SettingsApi'
const TemplatePopup = ({
  fullBind,
  setTitle,
  idSelected,
  choices,
  values,
  setValues,
  useInput,
  errors,
  isValid,
  setCheckRequires,
  handleModalTemplateCopy,
  readOnly,
}) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');




  useEffect(() => {
    setTitle('Copy Template');

    // if (idSelected) {
    //   getFunder()
    // } else {
    //   setTitle('Add New Funder');
    //   setLoadingModal(false);
    //   if (newAddFunder) {
    //     values.addFunder = {}
    //     setValues({ ...values })
    //   }
    // }
    // setCheckRequires(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const dropDownHandleSelect = (item, nameItem, funderAdd) => {
  //   if (funderAdd) {
  //     values.addFunder[nameItem] = item[nameItem]
  //     setValues({ ...values })
  //   }
  //   else {
  //     values[nameItem] = item[nameItem]
  //     setValues({ ...values })
  //   }

  // }


  // const getFunder = async () => {
  //   let response = await getFunderById(idSelected)
  //   if (response) {
  //     if (response.status === true) {
  //       response.status = 1
  //     }
  //     else if (response.status === false) {
  //       response.status = 2
  //     }
  //     if (response.branches) {
  //       response.branches = response.branches.map(branch => {
  //         return (
  //           {
  //             value: branch.branch,
  //             label: branch.branch_name
  //           }
  //         )
  //       })
  //     }
  //     setValues({ ...response })
  //     setLoadingModal(false)
  //   }
  // }

  // const handleSubmit = () => {
  //   if (loadingSave) {
  //     return
  //   }

  //   if (!isValid && errors.length > 0) {
  //     setErrorMessage(InvalidOrEmptyValues);
  //     setCheckRequires(true);
  //     return;
  //   }
  //   setLoadingSave(true);
  //   let body =  { ...values }
  //   if (body.branches) {
  //     body.branches = body.branches.map(branch => {
  //       return (
  //         { branch: branch.value }
  //       )
  //     })
  //   }
  //   if (body.status) {
  //     if (body.status === 1) {
  //       body.status = true
  //     }
  //     else {
  //       body.status = false
  //     }
  //   }
  //   addFunder(body)
  //     .then(res => {
  //       if (res.id) {
  //        setValues({
  //             ...values,
  //             id: res.id
  //           })
  //       }
  //       onSuccess()
  //     })
  //     .catch(onError);
  // }
  // const onSuccess = response => {
  //   setLoadingSave(false);
  //   setButtonIcon('');
  //   setButtonIcon(CHECK);
  //   setTextStatus(SAVED);
  //   setErrorMessage('');
  //   // loadFunders();
  // };
  // const onError = () => {
  //   setLoadingSave(false);
  //   setButtonIcon('');

  //   setButtonIcon(CROSS);
  //   setTextStatus(ERROR);
  // };

  const getTemplateNameOptions=()=>{
    let tempData = choices.core_services.map(i=>({id: i.id, name: i.template_name, service_category_type: i.service_category_type}));
    let optionsTemplate = tempData.filter(i=>i.service_category_type == 'location');
    return optionsTemplate || [];
  }
  console.log(values, 4458888)

  return (
    <ModalContainer max_width={'55%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'55%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="pl-3 pr-3 pb-3 pt-0">
          {/* <TitleSection className={''}>Copy Template</TitleSection> */}
          <div className='d-flex mt-2 mb-2'>
            <div className='w-100 mr-2'>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Template Contracts'}
                options={choices.core_services ? getTemplateNameOptions() : []}
                selectedOption={
                  selectedTemplateId
                }
                onChange={(value) =>
                  setSelectedTemplateId(value)
                }
              />
            </div>
          </div>
        </div>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <div
            className={
              'd-flex justify-content-center justify-content-md-end'
            }>
            <div className={'ml-0 ml-md-3 mr-3 mb-3'}>
              <PrimaryButtonForm minWidth="6rem"
                onClick={()=>handleModalTemplateCopy(selectedTemplateId)}
              >
                <span className={'mr-2'}>Create</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default TemplatePopup;
