import React,{useState,useEffect,Fragment} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    ErrorMessage
  } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {PrimaryButtonForm} from 'shared/styles/buttons';



const DetachRun = ({
    useInput,
    values,
    errorMessage,
    dataValues,
    dropDownHandleDetachRun,
    //newRun,
    modalLoading,
    setModalLoading,
    fullBind,
    handleSaveVisitDetachRun,
    saveButtonIcon,
    loadingSave,
    textStatus,
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Detach Run',
    });
    setModalLoading(false)
  }, [fullBind]);


//   const switchRunNameInput = props => {
//     if (newRun) {
//      return( 
//        <div className="pl-3 pr-3 pb-3 pt-0">
//       <InputLabelTop
//       required
//       readOnly={false}
//       type="text"
//       id="run_name"
//       label={'Run Name'}
//       {...props}
//       maxlength={100}
//      />
//   </div>
//      )
//     } else {
//       return(
//         <Fragment />
//       )
//     }
//   };
  
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={modalLoading}>
        <div>
          <div className="pl-3 pr-3 pb-3 pt-0">
          <DropdownSearchable
                required
                {...useInput(`run_name_id`, 'isRequired')}
                  placeHolder={'Select Run Name'}
                  options={dataValues}
                  isMulti={true}
                  selectedOptionsProps={
                    values && values.run_name_id && values.run_name_id
                  }
                  onChange={value =>
                    dropDownHandleDetachRun(
                      {run_name_id: value.length ? value : []},
                      )
                    }
                />
          </div>
          {/* {switchRunNameInput({ ...useInput('run_name', 'isRequired') })} */}
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ml-0 ml-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveVisitDetachRun()} >
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default DetachRun;
