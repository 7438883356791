import styled from 'styled-components';
import {
  PURPLE,
  WHITE,
  GRAY_700,
  SOFT_RED,
} from 'shared/styles/constants/colors';

export const Container = styled.div`
  width: 100%;

  table {
    width: 100%;
  }

  .wrapper {
    position: relative;
    overflow: auto;
    white-space: nowrap;
  }

  .first-col {
    min-width: 250px !important;
    width: 250px !important;
  }

  .all-cols {
    min-width: 63px !important;
    width: 10% !important;
  }

  .sticky-col {
    // position: -webkit-sticky !important;
    // position: sticky !important;
    // background-color: white;
  }

  .checkboxPosition {
    padding-top: 12px;
  }

  .checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 14px;
    width: 14px;
    clear: both;
  }

  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border-radius: 2px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    border: 1px solid ${GRAY_700};
  }

  .checkbox-label input:checked ~ .checkbox-custom {
    background-color: ${PURPLE};
    border-radius: 2px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 0;
  }

  .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: '';
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid ${GRAY_700};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 4px;
    top: 0px;
    width: 6px;
    height: 10px;
    border: solid ${WHITE};
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const Title = styled.div`
  color: ${PURPLE};
`;

export const ArrowStyle = styled.span`
  color: ${PURPLE};
  cursor: Pointer;
  font-size: 20px;
`;

export const SquareStyle = styled.span`
  color: ${PURPLE};
  cursor: Pointer;
  padding-right: 5px;
`;

export const RoleName = styled.span`
  color: ${SOFT_RED};
  font-weight: bold;
`;

export const TypeHeader = styled.div`
      color: rgb(0, 0, 0);
      font-weight: 600;
      font-size: 1rem;
      margin-left 0rem;
`