import { apiURL } from '../env';
import { get, post, deleteFetch } from './Api';
import queryString from 'query-string';
import { methods } from 'utils/constants/api';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getTenant } from '../localStorage/token';

const { POST, PATCH } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

export const getClientVisits = (clientId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/visit-filter-day?client_id=${clientId}`;

  return get(url);
};
export const getShiftDetailById = (Id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee-regular-shift?employee_id=${Id}`;

  return get(url);
};

export const getRunVisits = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();

  let stringified = '';
  let toParse = {};
  let filterParse = {};

  if (values.preferred_start_time) {
    toParse.preferred_start_time__gte = values.preferred_start_time;
  }
  if (values.preferred_end_time) {
    toParse.preferred_end_time__lte = values.preferred_end_time;
  }
  if (values.repeat_on) {
    let clientFilter = values.repeat_on.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    toParse.repeat_on__contains = strclientFilter;
  }

  if (values.employees) {
    let clientFilter = values.employees.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.avoid_employee = strclientFilter;
  }

  if (values.clients) {
    let clientFilter = values.clients.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.client = strclientFilter;
  }
  if (values && values.search) {
    toParse.search_multi_csv = values.search
  }

  if (values && values.runNames) {
    values.runNames.map((val) => {
      if (val.id === 'none') {
        toParse.visit__run_name_null = true;
        return;
      }
    })
    let runFilter = values.runNames.map(data => data.id)
    let strRunFilter = runFilter.join(",")
    toParse.visit__run_name = strRunFilter;
  }
  if (values && values.branch) {
    values.branch.map((val) => {
      if (val.id === 'none') {
        toParse.null_branch = true;
        return;
      }
    })
    let runFilter = values.branch.map(data => data.id)
    let strRunFilter = runFilter.join(",")
    toParse.Client_Service__Client__branch_id__in = strRunFilter;
  }
  // if (values.offset) {
  //   toParse.offset = values.offset;
  // }


  // if(filterParse.service_category  ||  filterParse.client_status || filterParse.funder_source  ||  filterParse.client  ||   filterParse.branch_id ||  filterParse.runroute ){
  //   values.offset=0;
  //   toParse.offset=0;
  // }else{
  //toParse.limit = limitPerPage || PAGE_SIZE_RENDER;
  //toParse.offset= values.offset;
  //}




  if (toParse.limit) {
    stringified += '&limit=' + toParse.limit
  }
  if (toParse.offset) {
    stringified += '&offset=' + toParse.offset
  }


  //stringified = queryString.stringify(toParse) || '';
  if (filterParse.avoid_employee) {
    stringified += '&avoid_employee=' + filterParse.avoid_employee
  }
  if (filterParse.client) {
    stringified += '&Client_Service__Client__id__in=' + filterParse.client
  }
  if (toParse.repeat_on__contains) {
    stringified += '&repeat_on=' + toParse.repeat_on__contains
  }
  if (toParse.preferred_start_time__gte) {
    stringified += '&rpreferred_start_time__gte=' + toParse.preferred_start_time__gte
  }
  if (toParse.preferred_end_time__lte) {
    stringified += '&preferred_end_time__lte=' + toParse.preferred_end_time__lte
  }
  if (toParse.search_multi_csv) {
    stringified += '&search_multi_csv=' + toParse.search_multi_csv
  }

  if (toParse.visit__run_name_null) {
    stringified += '&visit__run_name_null=' + true
  } else if (toParse.visit__run_name) {
    stringified += '&visit__run_name=' + toParse.visit__run_name
  }

  if (toParse.null_branch) {
    stringified += '&null_branch=' + true
  } else if (toParse.Client_Service__Client__branch_id__in) {
    stringified += '&Client_Service__Client__branch_id__in=' + toParse.Client_Service__Client__branch_id__in
  }


  const url = stringified
    ? `${baseUrl}/clients/all/services/all/visits/?list_for_regular_visit=true${stringified}`
    : `${baseUrl}/clients/all/services/all/visits/?list_for_regular_visit=true`;
  return get(url);
};

export const getRunEmp = (values, limitPerPage) => {
  const baseUrl = getBaseUrl();

  let stringified = '';
  let toParse = {};
  let filterParse = {};
  if (values.preferred_start_time) {
    toParse.employee__start_time__gte = values.preferred_start_time;
  }
  if (values.preferred_end_time) {
    toParse.employee__end_time__gte = values.preferred_end_time;
  }
  if (values.repeat_on) {
    let clientFilter = values.repeat_on.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    toParse.employee__repeat_on = strclientFilter;
  }
  if (values.employees) {
    let clientFilter = values.employees.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.id__in = strclientFilter;
  }
  if (values.skillFilter) {
    let clientFilter = values.skillFilter.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.employee__skill__id = strclientFilter;
  }
  if (values.competencyFilter) {
    let clientFilter = values.competencyFilter.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.employee__competency__id = strclientFilter;
  }
  if (values.clients) {
    let clientFilter = values.clients.map(data => data.id)
    let strclientFilter = clientFilter.join(",")
    filterParse.avoid_client = strclientFilter;
  }
  if (values && values.search) {
    toParse.search_multi_employee = values.search
  }
  if (values && values.runNames) {
    values.runNames.map((val) => {
      if (val.id === 'none') {
        toParse.employee__run_name_null = true;
        return;
      }
    })
    let runFilter = values.runNames.map(data => data.id)
    let strRunFilter = runFilter.join(",")
    toParse.employee__run_name = strRunFilter;
  }
  if (values && values.branch) {
    values.branch.map((val) => {
      if (val.id === 'none') {
        toParse.null_branch = true;
        return;
      }
    })
    let runFilter = values.branch.map(data => data.id)
    let strRunFilter = runFilter.join(",")
    toParse.job_detail__branch_id__in = strRunFilter;
  }
  // if (values.offset) {
  //   toParse.offset = values.offset;
  // }
  // if (limitPerPage) {
  //   toParse.limit = limitPerPage;
  // }

  // if(filterParse.service_category  ||  filterParse.client_status || filterParse.funder_source  ||  filterParse.client  ||   filterParse.branch_id ||  filterParse.runroute ){
  //   values.offset=0;
  //   toParse.offset=0;
  // }else{
  //   toParse.offset= values.offset;
  // }

  // toParse.limit = limitPerPage || PAGE_SIZE_RENDER;
  // toParse.offset= values.offset;


  if (toParse.limit) {
    stringified += '&limit=' + toParse.limit
  }
  if (toParse.offset) {
    stringified += '&offset=' + toParse.offset
  }


  //stringified = queryString.stringify(toParse) || '';
  if (filterParse.id__in) {
    stringified += '&id__in=' + filterParse.id__in
  }
  if (filterParse.avoid_client) {
    stringified += '&avoid_client=' + filterParse.avoid_client
  }
  if (filterParse.employee__skill__id) {
    stringified += '&employee__skill__id=' + filterParse.employee__skill__id
  }
  if (filterParse.employee__competency__id) {
    stringified += '&employee__competency__id=' + filterParse.employee__competency__id
  }
  if (toParse.employee__repeat_on) {
    stringified += '&employee__repeat_on=' + toParse.employee__repeat_on
  }
  if (toParse.employee__start_time__gte) {
    stringified += '&remployee__start_time__gte=' + toParse.employee__start_time__gte
  }
  if (toParse.employee__end_time__gte) {
    stringified += '&employee__end_time__gte=' + toParse.employee__end_time__gte
  }
  if (toParse.search_multi_employee) {
    stringified += '&search_multi_employee=' + toParse.search_multi_employee
  }
  if (toParse.employee__run_name_null) {
    stringified += '&employee__run_name_null=' + true
  } else if (toParse.employee__run_name) {
    stringified += '&employee__run_name=' + toParse.employee__run_name
  }
  if (toParse.null_branch) {
    stringified += '&null_branch=' + true
  } else if (toParse.job_detail__branch_id__in) {
    stringified += '&job_detail__branch_id__in=' + toParse.job_detail__branch_id__in
  }


  const url = stringified
    ? `${baseUrl}/employees/?list_employees_for_regular_visit=true${stringified}`
    : `${baseUrl}/employees/?list_employees_for_regular_visit=true`;
  return get(url);
};

export const setVisitsRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-before`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setVisitsDetachRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-delete`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};


export const setVisitsDetachAllRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-delete-all`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setVisitsDetachEmployeeRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-delete?specific=employee`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setVisitsDetachAllEmployeeRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-delete-all?specific=employee`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setVisitsSwapRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/swap-regular-visit`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setEmployeeRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-before-merge`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setEmployeeDetachRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-delete?specific=employee`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setEmployeeSwapRun = async values => {
  const baseUrl = getBaseUrl();

  const method = POST;
  const url = `${baseUrl}/clients/client/swap-regular-visit?specific=employee`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const saveRegularVisit = values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/clients/client/pinned-employee/${values.id}/`
    : `${baseUrl}/clients/client/pinned-employee/`;
  let body = {}
  if (values.specific === 'employee' || values.specific === 'run') {
    body = {
      ...values,

    }
  }
  else {
    body = {
      ...values,
      client_service_visit: values.visit,

    }

  }
  //  delete body.employee_specific
  delete body.days
  delete body.visit
  delete body.clients
  delete body.employees
  body.id && delete body.id

  return post(url, body, method);
};

export const saveBulkRun = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client/pinned-employee/bulk_pinned/`;
    const body = {
      ...values,
    }
  
  return post(url, body, method);
};

export const saveAddOffer = values => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/scheduler/visits/add_offer/`;
    const body = {
      ...values,
    }
  
  return post(url, body, method);
};

export const saveRegularVisitSingleSwap = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/clients/client/single-swap-regular-visit`;
  let body = {}
  body = {
    ...values
  }

  return post(url, body, method);
};

export const getRegularVisits = (values, filter) => {
  const baseUrl = getBaseUrl();
  // let stringified = '';
  let conditionString = ''
  let toParse = {};
  if (filter && filter.employees && filter.employees.length) {
    let employeeFilter = filter.employees.map(data => data.id)
    let strEmployeeFilter = employeeFilter.join(",")
    toParse.employees_ids = strEmployeeFilter;
  }
  if (filter && filter.clients && filter.clients.length) {
    let clientFilter = filter.clients.map(data => data.id)
    let strClientFilter = clientFilter.join(",")
    toParse.clients_ids = strClientFilter;
  }
  if (filter && filter.runNames && filter.runNames.length) {
    let runFilter = filter.runNames.map(data => data.id)
    let strRunFilter = runFilter.join(",")
    toParse.run_name_ids = strRunFilter;
  }
  if (values.branchData.headerbranch) {
    let branchFilter = values.branchData.headerbranch.map(data => data.id)
    let strheaderbranchFilter = branchFilter.join(",")
    toParse.headerbranch = strheaderbranchFilter;
  }
  if (filter && filter.branch && filter.branch.length) {
    let branchFilter = filter.branch.map(data => data.id)
    let strBranchFilter = branchFilter.join(",")
    toParse.branch_ids = strBranchFilter;
  }
  if (filter && filter.search) {
    toParse.search = filter.search
  }
  if (values.view) {
    toParse.view = values.view === 'run' ? 'run_name' : values.view;
  }

  toParse.offset = values.offset;

  if (values.limitperpage) {
    toParse.limit = values.limitperpage;
  } else {
    toParse.limit = PAGE_SIZE_RENDER;
  }

  conditionString += 'view=' + toParse.view
  conditionString += '&limit=' + toParse.limit
  //  if(toParse.offset){
  conditionString += '&offset=' + toParse.offset
  //  }
  if (toParse.ordering) {
    conditionString += '&ordering=' + toParse.ordering
  }
  if (toParse.employees_ids) {
    conditionString += '&employees_ids=' + toParse.employees_ids
  }
  if (toParse.clients_ids) {
    conditionString += '&clients_ids=' + toParse.clients_ids
  }
  if (toParse.run_name_ids) {
    conditionString += '&run_name_ids=' + toParse.run_name_ids
  }

  // if (toParse.branch_ids) {
  //   if (toParse.branch_ids == 'None') {
  //     conditionString += '&null_branch=' +  toParse.branch_ids
  //   } else{
  //   conditionString += '&branch_ids=' +   toParse.branch_ids
  //   }
  // } 

  if (toParse.branch_ids) {
    if (toParse.branch_ids.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = toParse.branch_ids;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_ids=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_ids=' + toParse.branch_ids;
    }
  } else if (toParse.headerbranch) {
    if (toParse.headerbranch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = toParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_ids=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_ids=' + toParse.headerbranch;
    }
  }

  if (toParse.search) {
    conditionString += '&search=' + toParse.search
  }
  // conditionString = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/clients/client/pinned-employee/?${conditionString}`;

  return get(url);
};

export const getRegularVisitsRunData = (run_name_id) => {
  const baseUrl = getBaseUrl();
  // let stringified = '';
  let conditionString = ''
  let toParse = {};


  conditionString += 'view=run_name'
  conditionString += '&limit=500'
  if (run_name_id) {
    conditionString += '&run_name_ids=' + run_name_id
  }




  // conditionString = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/clients/client/pinned-employee/?${conditionString}`;

  return get(url);
};

export const getRegularVisitById = (id, empDayId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/pinned-employee/${id}/?client_pinned_employee_day_id=${empDayId}`;

  return get(url);
};
export const getRegularVisitRunById = (runId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client-pinned-runname?run_name_id=${runId}`;
  return get(url);
};

export const deleteRegularVisit = (id, dayId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/clients/client/pinned-employee/${id}/`
  const body = {
    is_deleted: true,
    client_pinned_employee_day_id: dayId
  };
  return post(url, body, method);
};