import RichTextEditor from 'components/SharedComponents/RichTextEditor';
import React from 'react'
import { useRef } from 'react';
import { useMemo } from 'react';
import { TitleSection } from 'shared/styles/constants/tagsStyles';

const CHAR_LIMIT = 5000;

function TabContent({
    item,
    tabData,
    setValues,
    values,
    readOnly }) {
    const handleChange = (text) => {
        values[`${tabData?.section}`][`${item?.value}`] = text
        setValues({ ...values })
    };
    const editor = useRef(null);
    const config = useMemo(
        () => ({
            readonly: readOnly,
            placeholder: 'Start typings...',
        }),
        []
    )
    return (
        <div className='mb-4 mt-3'>
            {/* <TitleSection style={{ margin: '0px 0px 0.5rem 2.5rem' }}>{item.label}</TitleSection> */}
            <div className='wrapper-class'>
                <TitleSection className='mb-2' style={{ fontWeight: "500" }}>{item.label}</TitleSection>
                <RichTextEditor
                    config={{
                        limitChars: CHAR_LIMIT
                    }}
                    value={values[`${tabData?.section}`] && values[`${tabData?.section}`][`${item?.value}`] && values[`${tabData?.section}`][`${item?.value}`] || ''}
                    onBlur={text => handleChange(text)}
                />
            </div>
        </div>
    )
}

export default TabContent