import styled from 'styled-components';
import {
  PURPLE,
  GRAY_100,
  GRAY_200,
  BLACK_900,
  RED,
} from 'shared/styles/constants/colors';

export const ModalContainer = styled.div`
  div.modal-dialog {
    margin: 1.75rem 0.5rem;
    max-width: 100%;
  }
  @media (min-width: 992px) {
    div.modal-dialog {
      max-width: ${(props) => (props.max_width ? props.max_width : '31.25rem')};
      margin: 1.75rem auto;
    }
  }
`;

export const ContainerResponsive = styled.div`
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContainerResponsiveInverted = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: row;
  }
`;

export const ShowTab = styled.div`
  width: 100%;
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
`;

export const TabStyled = styled.div`
  cursor: pointer;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: ${(props) => (props.active ? `2px solid ${PURPLE}` : '0')};
`;

export const FlexEqual = styled.div`
  flex: 1;

  @media (max-width: 767px) {
    margin-top: ${(props) => (props.marginTopSm ? '1rem' : '0')};
    margin-left: ${(props) => (props.noMarginLeft ? '0' : 'unset')};
  }
`;

export const TitleSection = styled.div`
  font-weight: 600;
`;

export const UserImgWrapper = styled.div`
  background-color: ${GRAY_100};
  border: 0.0625rem solid ${GRAY_200};
  border-radius: 0.25rem;
  height: 100%;
  /* margin-bottom: 0.5rem; */
  /* margin-right: 0.5rem; */
  padding: 0.5rem;

  @media (max-width: 575px) {
    margin-right: 0rem;
  }
`;

export const UserImgContainer = styled.div`
  position: relative;
  border-radius: 50%;
  -webkit-box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};
  box-shadow: 0.1875rem 0px 0.25rem ${BLACK_900};

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  img {
    width: 4.8rem;
    height: 4.8rem;
  }
  background: ${GRAY_200};
  div {
    color: ${PURPLE};
    width: 100%;
    height: 100%;
  }
`;

export const CommentFileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 53%;
  label {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  svg {
    color: green;
    margin-right: 0.2rem;
  }
`;

export const ErrorMessage = styled.div`
  color: ${RED};

  font-size: 1rem;
  font-weight: 500;
  label {
    margin: 0;
    line-height: 1.3rem;
  }
`;

export const UploadPhotoContainer = styled.div`
  svg {
    margin-right: 0.25rem;
  }
  label {
    cursor: pointer;
    font-size: 0.875rem;
    margin: 0;
  }
  &:hover {
    font-weight: 500;
  }
`;
