import React, { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import Spinner from 'components/SharedComponents/Spinner';
import {
    CloseButtonWrapper,
    ModalDynamicContainer,
    FlexGrow,
    CloseButtonContainer,
    ModalTitle,
    LoadingContainer,
} from './styles';

function ShowForm({
    children,
    title,
    onClose,
    showModal,
    loadingMaxWidth = null,
    minWidthTitle = '',
    loading = true,
}) {

    return (
        <div>
            {loading ? (
                <LoadingContainer maxHeight={loadingMaxWidth}>
                    <Spinner />
                </LoadingContainer>
            ) : (
                <Fragment>
                    <ModalDynamicContainer className="modal-header row-muted border-0">
                        <FlexGrow>
                            <ModalTitle MinWidth={minWidthTitle} className="modal-title">
                                {title}
                            </ModalTitle>
                        </FlexGrow>

                        <CloseButtonContainer>
                            <CloseButtonWrapper>
                                <FaTimes onClick={onClose} />
                            </CloseButtonWrapper>
                        </CloseButtonContainer>
                    </ModalDynamicContainer>

                    {children}
                </Fragment>
            )}
        </div>

    );
}

export default ShowForm;
