import { Switch, TextField } from '@mui/material'
import JoditEditor from 'jodit-react';
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useMemo } from 'react';
import { FiUpload } from 'react-icons/fi';
import { UploadInput } from 'shared/styles/buttons';
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles';
import { attachImage } from 'utils/api/FormDataApi';
import { pickFile } from 'utils/files';

const PhotoAttachCom = ({ setJson, json }) => {
    const editor = useRef(null);
    const [uploadedFile, setUploadedFile] = useState('')

    const handlePickFile = async (evt, section) => {

        const result = await pickFile(evt);
        console.log(result, 555333)
        switch (section) {
          case 'photo':
            const photoUrl = await attachImage({file : result.file})
            if(photoUrl.message.file){
                setUploadedFile(photoUrl.message.file);
                setJson({
                    ...json, photoAttach: {
                        ...json.photoAttach,
                        file: photoUrl.message.id,
                        value: photoUrl.message.file
                    }
                })
            }
            break;
          case 'nok':
            // setNokPhoto(result.file);
            break;
          default:
        }
      };

    return (
        <div className='mb-4'>
            <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                    onInput={e => {
                        handlePickFile(e, 'photo');
                    }}
                    onClick={event => {
                        event.target.value = null;
                    }}
                    type="file"
                    accept={'.png,.jpg,.jpeg'}
                    id="employee-photo"
                    name="employee-photo"
                    placeholder={'Employee Photo'}
                />
                <FiUpload />
                {
                    <label htmlFor="employee-photo">Upload</label>
                }

            </UploadPhotoContainer>
            <div>
               {uploadedFile && <img style={{width:'100%', height:'100%'}} src={uploadedFile} alt='' />}
            </div>
        </div>

    )
}

export default PhotoAttachCom;
