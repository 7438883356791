import React, {useState, useEffect, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import {headerColumns} from 'components/DashboardComponents/Alerts/headerColumnList';

import AddModal from 'components/DashboardComponents/Alerts/AddModal';
import {FaPlus} from 'react-icons/fa';
import {RiDeleteBinLine} from 'react-icons/ri';
import {MdRemoveRedEye} from 'react-icons/md';
import {
  ERR_COLOR,
  PURPLE,
  GRAY_700,
  WHITE,
  GRAY_400,
} from 'shared/styles/constants/colors';
import {
  getTriage,
  deleteTriageRow,
  intantNotificationData,
  getTriageClient,
} from 'utils/api/AlertsApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {MdEdit} from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {
  Header,
  SearchAddContainer,
  SearchContainer,
  HeaderLgBreak,
  Title,
  DropdownTypesContainer,
  HeaderVisits,
  FiltersLeftGroup,
  FiltersContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';

import {LARGE_COL, EX_SMALL_COL4} from 'shared/styles/constants/columns';
import {useForm} from 'hooks/FormHook';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext, BranchItemsContext} from 'context';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import moment from 'moment';
import BulkTriageModal from 'components/DashboardComponents/Alerts/BulkAlertModal';
import BulkNotificationRule from 'components/DashboardComponents/Alerts/BulkNotificationRule';
import {Toast} from 'primereact/toast';

import {
  getCategoryPermission,
  getEmail,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from 'utils/localStorage/user';
import {
  TEAM_PERMISSION,
  TRIAGE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  CLIENT_TRIAGE,
} from 'utils/constants/permissions';
import ClientTriageModal from './ClientTriageModal';
import {client_triage_choices} from 'utils/choiceConstant';

const TriageClientModal = props => {
  const {values, setValues, useInput} = useForm({
    ordering: 'client__full_name',
  });
  const [triage, setTriage] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const history = useHistory();
  const {contextChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const [clientName, setClientName] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);

  const [severity, setSeverity] = useState([]);
  const [TriagePrivacy, setTriagePrivacy] = useState([]);
  const [labels, setLabels] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [TriageStatus, setTriageStatus] = useState([]);
  const [type, setType] = useState([]);
  const [userList, setUserList] = useState([]);
  const [clientsnameFilter, setClientNameFilter] = useState([]);
  const [employeenameFilter, setEmployeeNameFilter] = useState([]);
  const [employeeAssignFilter, setEmployeeAssignFilter] = useState([]);
  const [severityFilter, setSeverityFilter] = useState([]);
  const [labelsFilter, setLabelsFilter] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState([]);
  const [TriageStatusFilter, setTriageStatusFilter] = useState([]);
  const [TriageStatusFilterTemp, setTriageStatusFilterTemp] = useState([]);
  const [countCheck, setCountCheck] = useState([]);

  const [typeFilter, setTypeFilter] = useState([]);
  const [title, setTitles] = useState('');
  const [employeeAssigned, setEmployeeAssigned] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextSubscription,
  } = useContext(AppContext);
  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const {
    setValue: setBulkTriageModalValue,
    value: bulkTriageModalValue,
    // setBulkAlertTitle,
    fullBind: fullBindBulkTriage,
  } = useModal(false);

  const {
    setValue: setBulkNotificationRuleModalValue,
    value: bulkNotificationRuleModalValue,
    setTitle: setBulkNotificationRuleTitle,
    fullBind: fullBindBulkNotificationRule,
  } = useModal(false);

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);

  const [loadingModal, setLoadingModal] = useState(true);
  const {handleGetChoices} = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(client_triage_choices);
  }, []);
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, []);
  useEffect(() => {
    setTitle('Add New Triage');

    let selfPermission = {};
    const self = contextPermissions;
    if (self.length > 0) {
      self.forEach(item => {
        selfPermission[item.section.name] = {};
        selfPermission[item.section.name][WEB_PERMISSION] = {};
        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
      });
    }
    let rolePermissions = {};

    const roles = contextPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
      contextPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
    }
    if (filterSuperAdmin) {
      setReadPermissions(true);
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }
    if (filterAdmin) {
      setReadPermissions(
        contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION]
      );
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }

    if (rolePermissions[CLIENT_TRIAGE] !== undefined) {
      setCreatePermissions(
        (contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ]) ||
          selfPermission[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ]
      );
      setDeletePermissions(
        (contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ]) ||
          selfPermission[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ]
      );
      setUpdatePermissions(
        (contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ]) ||
          selfPermission[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ]
      );

      setReadPermissions(
        (contextSubscription?.[CLIENT_TRIAGE]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]) ||
          selfPermission[CLIENT_TRIAGE]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextCategoryPermissions,
    contextSubscription,
  ]);

  useEffect(() => {
    setClientName(
      contextChoices &&
        contextChoices.client_listing &&
        contextChoices.client_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );

    setEmployeeName(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
    setEmployeeAssigned(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
    setSeverity(
      contextChoices &&
        contextChoices.alert_severity &&
        contextChoices.alert_severity.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setTriageStatus(
      contextChoices &&
        contextChoices.alert_status &&
        contextChoices.alert_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    let status_obj =
      contextChoices &&
      contextChoices.alert_status &&
      contextChoices.alert_status.filter(y => y.name !== 'Resolved');

    setTriageStatusFilter(status_obj || []);
    setTriageStatusFilterTemp(status_obj || []);
    setValues({...values, alert_status: status_obj || []});

    setType(
      contextChoices &&
        contextChoices.alert_type &&
        contextChoices.alert_type.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setLabels(
      contextChoices &&
        contextChoices.alert_label &&
        contextChoices.alert_label.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setCreatedBy(
      contextChoices &&
        contextChoices.user_list &&
        contextChoices.user_list.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setTriagePrivacy(
      contextChoices &&
        contextChoices.alert_privacy &&
        contextChoices.alert_privacy.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setUserList(
      contextChoices &&
        contextChoices.user_list &&
        contextChoices.user_list.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);

  useEffect(() => {
    if (
      values.clientname ||
      values.employeename ||
      values.employeeassigned ||
      values.severity ||
      values.triagestatus ||
      values.type ||
      values.labels ||
      values.created_by ||
      values.date_to ||
      values.date_from ||
      values.Triagestatus ||
      branchData.length > 0 ||
      values.alert_status ||
      values?.limitperpage ||
      props?.location?.search ||
      values?.ordering
    ) {
      loadTriage();
    }
  }, [
    values.clientname,
    values.employeename,
    values.employeeassigned,
    values.severity,
    values.Triagestatus,
    values.type,
    values.labels,
    values.created_by,
    values.date_to,
    values.date_from,
    branchData,
    values.alert_status,
    branchData.length > 0,
    values.Triagestatus,
    values?.limitperpage,
    props?.location?.search,
    values?.ordering,

    // values.ordering,
    // props.location.search,
    // values.limitperpage,
  ]);

  const toDeleteTriageRow = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const loadTriage = () => {
    const pageToSearch = getPageToSearchParam(
      props?.location?.search,
      setCurrentPage
    );
    let id = props.selectedClientId;
    getTriageClient(
      {id, ...branchData, ...values, ...pageToSearch},
      values.limitperpage
    ).then(response => {
      if (response) {
        setLoadingModal(false);
        setCountCheck(response.count);
      }
      setTriage(response.data || []);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };
  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const HandleBulkTriageModal = () => {
    setBulkTriageModalValue(!bulkTriageModalValue);
  };

  const handleBulkTriage = () => {
    setBulkTriageModalValue(true);
  };

  const handleBulkNotificationRule = () => {
    setBulkNotificationRuleModalValue(true);
  };

  const handleAddBranches = () => {
    setIdSelected('');
    handleModal();
  };

  const handleDeleteTriage = async () => {
    await deleteTriageRow(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');
    loadTriage();
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
    // history.push({
    //   pathname: `/triage`,
    //   state: {
    //     updateSelfListOfEmployee: update,
    //     updateAllListOfEmployee: update,
    //   },
    // });
  };

  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/triage',
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const filterClientName = item => {
    setClientNameFilter([...item]);
  };

  const filterEmployeeName = item => {
    setEmployeeNameFilter([...item]);
  };

  const filterEmployeeAssign = item => {
    setEmployeeAssignFilter([...item]);
  };
  const filterSeverity = item => {
    setSeverityFilter([...item]);
  };

  const filterLabels = item => {
    setLabelsFilter([...item]);
  };

  const filterCreatedBy = item => {
    setCreatedByFilter([...item]);
  };

  const filterTriageStatus = item => {
    setTriageStatusFilter([...item]);
  };

  const filterType = item => {
    setTypeFilter([...item]);
  };

  const handleSelectChange = (e, triageData, selectedIndex) => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      values.checkbox_alerdata.push({
        ...triageData,
        checkbox_rowId: triageData.id,
      });
    } else {
      values.checkbox_alerdata.map((data, index) => {
        if (data.checkbox_rowId == triageData.id) {
          values.checkbox_alerdata.splice(index, 1);
        }
      });
    }
    setValues({...values});
  };

  const handleCheck = alertsData => {
    let check = false;
    if (values.checkbox_alerdata) {
      values.checkbox_alerdata.map(data => {
        if (data.checkbox_rowId == alertsData.id) {
          check = true;
        }
      });
    }
    return check;
  };

  const handleAllSelectChange = e => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      triage.map(triageData => {
        values.checkbox_alerdata.push({
          ...triageData,
          checkbox_rowId: triageData.id,
        });
      });
    } else {
      // alerts.map((alertsData,index)=>{
      //   values.checkbox_alerdata.map(chkdata=>{
      //     if(chkdata.checkbox_rowId == alertsData.id  ){
      //       values.checkbox_alerdata.splice(index,1)
      //     }
      //   })
      // })
      values.checkbox_alerdata = [];
    }
    setValues({...values});
  };

  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = id => {
      return values.checkbox_alerdata.some(function (el) {
        return el.checkbox_rowId === id;
      });
    };

    if (values.checkbox_alerdata && values.checkbox_alerdata.length > 0) {
      triage.map(triageData => {
        if (!alertCheckBoxExists(triageData.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const resetAllCheck = () => {
    values.checkbox_alerdata = [];
    setValues({...values});
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props?.location?.search);
    urlParams[PAGINATION_PARAM] = 1;

    setValues({...values, ...item});
  };

  const getSeverityName = ID => {
    if (ID) {
      return severity?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };

  const getTriageStatusName = ID => {
    if (ID) {
      return TriageStatus?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };

  const getTriageTypeName = ID => {
    if (ID) {
      return type?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };

  const getEmployeeAssignedName = iddata => {
    if (iddata) {
      return userList?.find(data => data.id == iddata)?.name;
      // return employeeName?.find(data=>data.id == iddata)?.name;
    } else {
      return null;
    }
  };

  const getClientName = ID => {
    if (ID) {
      return clientName?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };

  const getEmployeeName = ID => {
    if (ID) {
      return employeeName?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };
  const getAssignedEmpolyee = ID => {
    if (ID) {
      return employeeAssigned?.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };
  const getDatePlusTime = dateTime => {
    if (dateTime) {
      let date = dateTime.split('T')[0];
      let time = moment(dateTime.split('T')[1], 'HH:mm:ss').format('HH:mm:ss');
      return date + ' ' + time;
    } else {
      return null;
    }
  };

  const toast = useRef();
  const handleInstantNotification = async () => {
    let newdet = [];
    if (values.checkbox_alerdata) {
      values.checkbox_alerdata.forEach(item => {
        newdet.push(item.id);
      });
    }

    const data = {alert: newdet};

    let response = await intantNotificationData(data);
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Instant notification send successfully',
      });
    } else {
    }
  };
  return (
    <div>
        <Toast ref={toast} position="top-right" />
      {countCheck !== 0 ? (
        <div>
          <ComponentDynamic loading={loadingModal}>
            <Table
              headerColumns={headerColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}>
              <div>
                {triage.map((alertsData, index) => {
                  return (
                    <div>
                      <Row bgColor>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {alertsData?.client__full_name}
                        </Col>
                        <Col
                        className='sm-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {alertsData?.created_by_name}
                        </Col>
                        <Col
                        className='sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {alertsData?.employeeAssigned}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Center
                          //  className="sm-hidden"
                          overlap={true}>
                          {alertsData?.alert_type__name}
                        </Col>
                        <Col
                        className='sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {alertsData?.tittle}
                        </Col>

                        <Col
                        className='sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="md-hidden"
                          overlap={true}>
                          {alertsData?.alert_severity__name}
                        </Col>
                        <Col
                        className='sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {alertsData?.alert_status__name}
                        </Col>
                        <Col
                        className='sm-hidden md-hidden align-self-center mb-0'
                          max_width={LARGE_COL}
                          Center
                          overlap={true}>
                          {alertsData?.alert_created_by__full_name}
                          {/* {getEmployeeAssignedName(alertsData?.alert_created_by)} */}
                        </Col>
                        <Col
                        className='sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {getDatePlusTime(alertsData?.alert_created_at)}
                        </Col>
                        {update ? (
                          <Col className="" Center Icon Purple>
                            <IconWrapper
                              onClick={() => {
                                handleRowClick(alertsData.id);
                              }}>
                              <MdEdit />
                            </IconWrapper>
                          </Col>
                        ) : (
                          readPermission && (
                            <Col className="" Center Icon Purple>
                              <MdRemoveRedEye
                                onMouseEnter={'view'}
                                size={'23'}
                                color={PURPLE}
                                onClick={() => handleRowClick(alertsData.id)}
                              />
                            </Col>
                          )
                        )}
                        {deletePermission && (
                          <Col className="" Center Shrink NoFlexGrow>
                            <IconWrapper
                              onClick={() => {
                                toDeleteTriageRow(alertsData.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </Col>
                        )}
                      </Row>

                      <div className="mb-1" />
                    </div>
                  );
                })}
                <div className="mt-3">
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={triage.length}
                  />
                </div>
              </div>
            </Table>
          </ComponentDynamic>
        </div>
      ) : (
        <TitleSection>No data found</TitleSection>
      )}
      {!modalValue ? null : (
        <ClientTriageModal
          update={update}
          readonly={
            update || create ? (idSelected ? !update : !create) : readPermission
          }
          type={type}
          alertStatus={TriageStatus}
          severity={severity}
          clientName={clientName}
          labels={labels}
          employeeName={employeeName}
          alertPrivacy={TriagePrivacy}
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          setTitle={setTitle}
          loadTriage={loadTriage}
          triage={triage}
          contextChoices={contextChoices}
        />
      )}

      {!bulkTriageModalValue ? null : (
        <BulkTriageModal
          type={type}
          TriageStatus={TriageStatus}
          severity={severity}
          setModalValue={setBulkTriageModalValue}
          fullBind={fullBindBulkTriage}
          loadTriage={loadTriage}
          triage={triage}
          contextChoices={contextChoices}
          dataTriageId={values}
          resetAllCheck={resetAllCheck}
          handleChangeChoices={props.handleChangeChoices}
        />
      )}

      {!bulkNotificationRuleModalValue ? null : (
        <BulkNotificationRule
          fullBind={fullBindBulkNotificationRule}
          loadTriage={loadTriage}
          triage={triage}
          contextChoices={contextChoices}
          dataTriageId={values}
          resetAllCheck={resetAllCheck}
          handleChangeChoices={props.handleChangeChoices}
          setModalValue={setBulkNotificationRuleModalValue}
        />
      )}
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Triage? \n DELETING the Triage will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the Triage to RESOLVED instead to retain database records.'
        }
        onOk={handleDeleteTriage}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default TriageClientModal;
