module.exports = {
  EVERY_DAY: 'DAY',
  EVERY_WEEK: 'WEEK',
  EVERY_MONTH: 'MONTH',
  EVERY_YEAR: 'YEAR',
  END_TYPE_AFTER: 'AFTER',
  END_TYPE_ON: 'ON',
  END_TYPE_NEVER: 'NEVER',
  HOUR:'HOUR',
  MINUTE:"MINUTE"
};
