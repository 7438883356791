import styled from 'styled-components';
import {GRAY_400, ERR_COLOR, GRAY_700,PURPLE} from 'shared/styles/constants/colors.js';

export const MainContainer = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  padding-left:20px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  label {
    cursor: pointer;
    font-size: 0.75rem;
    margin: 0;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.775rem;

  label {
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  div {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const StopWatchContainer = styled.div`
  display: flex;

  svg {
    font-size: 1rem;
    margin-right: 0.1875rem;
    margin-top: 0.1875rem;
  }
  font-size: 0.875rem;
  font-weight: 600;

  div {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const DropDownContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 1;
  display: flex;
  background-color: #f0f0f7;
  border-radius: 0.125rem;
  border: 0.0625rem solid ${GRAY_400};

  div > label {
    font-size: 11px;
  }
`;

export const DeleteButton = styled.div`
  svg {
    font-size: 1.5rem;
    color: ${ERR_COLOR};
    margin-top: 40%;
    cursor: pointer;
  }

  &:active svg {
    color: ${GRAY_700};
  }

  padding: 0;
  height: 100%;
`;
export const DeleteButtonSch = styled.div`
  svg {
    font-size: 1.8rem;
    color: ${ERR_COLOR};
    margin-top: 10%;
    cursor: pointer;
  }

  &:active svg {
    color: ${GRAY_700};
  }

  padding: 0;
  height: 100%;
`;
export const PublishButton = styled.div`
  svg {
    font-size: 1.8rem;
    color: ${props => (props.disable ?  GRAY_700 : PURPLE)};
    margin-top: 10%;
    cursor: ${props => (props.disable ?  '' : 'pointer')};
  }

  &:active svg {
    color: ${GRAY_700};
  }

  padding: 0;
  height: 100%;
`;
export const ResetButton = styled.div`
  svg {
    font-size: 1.6rem;
    color: ${ERR_COLOR};
    margin-top: 40%;
    cursor: pointer;
  }

  &:active svg {
    color: ${GRAY_700};
  }

  padding: 0;
  height: 100%;
`;