import React, { useState } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns, RowsData } from './headerColumsList';
import { Header } from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { LARGE_COL, MEDIUM_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';


const TimeSource = props => {
  const { readPermissionRateCard } = props;
  const [timeData, setTimeData] = useState([]);

  useForm(() => {
    if (RowsData) {
      setTimeData(RowsData)
    }
  }, [RowsData])



  return (
    <>
      {readPermissionRateCard ? (
        <div>
          <Header>
            <div className="d-flex align-items-center" />
          </Header>
          <Table
            headerColumns={headerColumns}
            headerPadding={false}
            fontSize="0.75rem"
            noEditOption
          >
            <div>
              {timeData?.map((timeSourceData, index) => {
                return (
                  <div key={index} >
                    <Row bgColor Shadow>

                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {timeSourceData.Menu && timeSourceData.Menu}
                      </Col>

                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        className='sm-hidden'
                        Center>
                        {timeSourceData.TimeSource && timeSourceData.TimeSource}
                      </Col>
                      <Col
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {timeSourceData.Type && timeSourceData.Type}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {timeSourceData.RateSource && timeSourceData.RateSource}
                      </Col>

                      <Col className="sm-hidden" Center Icon Purple Shrink
                        reducePadding>
                        {/* <IconWrapper 
                      onClick={() => {
                        handleRowClickEdit( );
                      }}
                      >
                     <MdEdit    />
                     </IconWrapper> */}
                      </Col>

                      <Col className="sm-hidden" Center Shrink
                        reducePadding NoFlexGrow >
                        {/* <IconWrapper   
           onClick={() => {
            toDeleteNLW( );  }}  >
                   <RiDeleteBinLine />
                     </IconWrapper> */}
                      </Col>
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}



            </div>
          </Table>
        </div>
      ) : (
        <NoPermissionContainer>
          You don't have permission to access the information.
        </NoPermissionContainer>
      )
      }
    </>
  )
}

export default TimeSource