import styled from 'styled-components';
import {
  ERR_COLOR,
  PURPLE,
  PURPLE_800,
  SOFT_RED,
  GRAY_100,
  GRAY_200,
  WHITE,
} from 'shared/styles/constants/colors';

function createHeight(no_internal_margin, have_error) {
  if (no_internal_margin) {
    return '2.375rem';
  }

  if (have_error) {
    return '2.3rem';
  } else {
    return '1.65rem';
  }
}

const inputType = type => {
  switch (type) {
    case 'text':
      return `
      color: black;
      font-size: 0.875rem;
      font-weight: 300;
      padding: 0.4em 1% 0 0.55rem;
      pointer-events: none;
      line-height: 1.1em;
      top: 16%;
      bottom: 1%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; 
      height: 1.3875rem;
      position: absolute;
      right: 0%;
      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
      `;
    default:
      return `
      color: black;
      font-size: 0.875rem;
      font-weight: 300;
      padding: 0.4em 1% 0 0.55rem;
      pointer-events: none;
      line-height: 1.1em;
      top: 16%;
      bottom: 1%;
      @media (max-width: 767px) {
        font-size: 0.75rem;
      }
      `;
  }
};

export const InputLabelColorPickerStyle = styled.div`
  height: ${props => createHeight(props.no_internal_margin, props.have_error)};
  position: relative;
  width: 100%;
  margin-bottom: ${props => (props.no_internal_margin ? '0' : '1.25rem')};

  input,
  label {
    cursor: text;
    padding: 0.6em 0.3rem 0.15em 0.55rem;
    transition: all 0.15s ease;
    width: 100%;
    color: blue;
  }

  input,
  span {
    color: ${SOFT_RED};
  }

  input {
    background-color: ${GRAY_100};
    border: 1px solid ${GRAY_200};
    color: black !important;
    font-size: 0.875rem;
    font-weight: 300;
    height: 2.4rem;
    border-radius: 0.25rem;

    @media (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  label {
    ${props => inputType(props.type)}
  }

  input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: ${PURPLE};
  }

  input.filled ~ label,
  input:focus ~ label {
    top: 1%;
    font-size: 0.6em;
    padding-top: 0.2em;
    font-weight: 600;
    position: absolute;
  }

  input[type='text'].filled {
    border-color: ${PURPLE_800};
  }

  input[data-error] {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: ${props => (props.have_error ? ERR_COLOR : '')} !important;
  }

  .errorMessage {
    ${props => errorMessage(props.have_error, props.type)}
  }

  .errorMessageHaveValue {
    ${props => errorMessageHaveValue(props.have_error, props.type)}
  }

  .swatch {
    position: absolute;
    right: 3%;
    width: 25%;
    padding: 3px;
    border-radius: 0.25rem;
    border: 1px solid ${GRAY_200};
    background: ${WHITE};
    height: 100%;
    top: 0.35rem;
    cursor: pointer;

    .swatchColor {
      border-radius: 0.15rem;
      height: 100%;
      width: 100%;

      div {
        font-size: 0.5rem;
        padding-top: 0.2rem;
      }
    }
  }

  div.pickerStyle {
    position: absolute;
    top: 2rem;
    right: 0%;
    z-index: 50;
  }
`;

const errorMessageFormat = /*css*/ `
  position: absolute;
  font-size: 0.6rem;
  left: -0.4rem;
  color: ${ERR_COLOR} !important;
  font-weight:bold;
`;

function errorMessage(have_error, type) {
  if (have_error) {
    return `
      margin-top: ${type === 'date' ? '2.3rem' : '1.9rem'};
      ${errorMessageFormat}
    `;
  }
}

function errorMessageHaveValue(have_error) {
  if (have_error) {
    return `
      margin-top: 2.4rem;
      ${errorMessageFormat}
    `;
  }
}
