import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'

export default function SignatureComponent({ json, setJson }) {
    useEffect(()=>{
        setJson({
            ...json, signature: {
                ...json.signature,
                label: 'Add signature'
            }
        })
    },[])
    return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Add signature"
                disabled={true}
                defaultValue={"Add signature"}
                style={{ marginRight: 10 }}
                onChange={(event) => {
                    setJson({
                        ...json, signature: {
                            ...json.signature,
                            label: event.target.value
                        }
                    })
                }}
            />
            <FormControl style={{ flex: 1 }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={json.signature.type}
                    label="Tag"
                    onChange={(event) => {
                        setJson({
                            ...json, signature: {
                                ...json.signature,
                                type: event.target.value
                            }
                        })
                    }}
                >
                    <MenuItem value={"contained"}>Contained</MenuItem>
                    <MenuItem value={"outlined"}>Outlined</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}
