import { Switch, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'

export default function ChoicesComp({ json, setJson, name }) {
    useEffect(() => {
        setJson({
            ...json, choices: {
                ...json.choices,
                label: name
            }
        })
    }, [name])
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Label *"
                defaultValue={"Label"}
                style={{ marginBottom: 10 }}
                value={json.choices.label ? json.choices.label : name}
                onChange={(event) => {
                    setJson({
                        ...json, choices: {
                            ...json.choices,
                            label: event.target.value
                        }
                    })
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label="Description"
                className='mb-2'
                multiline
                rows={3}
                value={json.choices.description}
                placeholder="Description"
                onChange={(event) => {
                    setJson({
                        ...json, choices: {
                            ...json.choices,
                            description: event.target.value
                        }
                    })
                }}
            />
             <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.choices.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, choices: {
                                ...json.choices,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
             <div className='d-flex'>
             <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Multiselect'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.choices.is_multi}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, choices: {
                                                ...json.choices,
                                                is_multi: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.choices.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, choices: {
                                                ...json.choices,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.choices.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, choices: {
                                                ...json.choices,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ml-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.choices.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, choices: {
                                                ...json.choices,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.choices.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.choices.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, choices: {
                                ...json.choices,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
        </div>
    )
}
