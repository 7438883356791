import React from 'react';
import Modal from 'react-bootstrap4-modal';
import {FaTimes} from 'react-icons/fa';
import './decision.css'

import {CancelButton, PrimaryButtonModal, Body, SubBody,CloseButtonContainer,
  CloseButtonWrapper
} from './styles';

function ConfirmDecision({
  type,
  max_width,
  title,
  body,
  subBody,
  showModal,
  onOk,
  onCancel,
  onClose,
  onNo,
  isDisable = false,
  loading = false
}) {


  return (
    <Modal max_width={'50%'} visible={showModal}     >
      <div className="modal-header border-0">
        <h5 className="modal-title">{title}</h5>
        <CloseButtonContainer>
              <CloseButtonWrapper>
                <FaTimes onClick={onClose} />
              </CloseButtonWrapper>
            </CloseButtonContainer>
      </div>
      
      <div className="modal-body text-center">
        <Body>{body}</Body>
        <SubBody>{subBody}</SubBody>
      </div>
      <div className="d-flex justify-content-center modal-footer border-0">
        <PrimaryButtonModal disabled={isDisable} typeButton={type} onClick={onOk}>
          <span className={'mr-2'}>Yes</span>
          {loading && (
            <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
            />
          )}
        </PrimaryButtonModal>
        <CancelButton
          type="button"
          className="btn btn-secondary"
          onClick={onNo}>
          No
        </CancelButton>
       
      </div>
    </Modal>
  );
}

export default ConfirmDecision;
