import React, {useEffect, useState, useRef, useCallback, useContext } from 'react';
import {LARGE_COL, SMALL_COL, MEDIUM_COL,EX_LARGE_COL,COL_CONTAIN_PARA,COL_CONTAIN_WORD,EX_SMALL_COL} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {
  Row,
  Col,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import {getCategoryPermission , getPermissions} from '../../../../../utils/localStorage/user';
import {
  SETTINGS_ALGO_SCORE,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions';
import {getAdmin, getSuperAdmin} from "../../../../../utils/localStorage/token"
import {getAlgoScore,statusAlgo,resetAlgoRow,resetAllAlgo} from 'utils/api/SettingsApi';
import {MdEdit} from 'react-icons/md';
import {BiReset} from 'react-icons/bi'
import {ResetButton}from'components/SharedComponents/ProgressInformation/styles'
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import AddModal from './AddModal'
import {AppContext} from "context"  


const headerColumns = [
  {
    label: 'ALGORITHM  RULES',
    name: 'algorithm_rules',
    status: 0,
    className: 'grow-mobile',
    max_width: COL_CONTAIN_PARA,
  },
  // {
  //   label: 'MIN(HRS)',
  //   name: 'min_time',
  //   status: 0,
  //   className: 'sm-hidden    grow-mobile   ',
  //   max_width: SMALL_COL,
  // },

  // {
  //   label: 'MAX(HRS)',
  //   name: 'max_time',
  //   status: 0,
  //   className: 'sm-hidden   grow-mobile ',
  //   max_width: SMALL_COL,
  // },
  {
    label: 'CONSTRAINTS',
    name: 'score_level',
    status: 0,
    className: ' grow-mobile   ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'REWARD',
    name: 'reward',
    status: 0,
    className: ' md-hidden  grow-mobile ' ,
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'PENALISE',
    name: 'penalise',
    status: 0,
    className: 'sm-hidden    grow-mobile ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'UNASSIGNED VIOLATED',
    name: 'consider_hard_rule',
    status: 0,
    className: 'md-hidden   grow-mobile  '  ,
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'STATUS',
    name: 'status',
    status: 0,
    className: 'sm-hidden   grow-mobile  '  ,
    max_width: COL_CONTAIN_WORD,
  },
  
];


function Financial( ) {
  const {values, setValues, useInput,setCheckRequires} = useForm({});  //used useInput
  const [financial, setFinancial] =  useState([ ]);
  const [update, setUpdatePermissions] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  
  const[numberValue,setNumberValue]=useState() ;
  const {contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext) ;
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;
  const fullNameCol='algorithm_rules';
  const tabName='Financial';

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} = useModal(
    false,
  );

  useEffect(() => {

    const roles = contextPermissions;
    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === SETTINGS_ALGO_SCORE) {
          rolePermissions[SETTINGS_ALGO_SCORE] = {};
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION] = {};
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });

      if(filterAdmin  == true  ||  filterSuperAdmin == true  ){
        setUpdatePermissions(true);
      }
      else {

      // if(rolePermissions[SETTINGS_ALGO_SCORE] && rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION])
        setUpdatePermissions(
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ]
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
useEffect(()=>{
    values.ordering='algorithm_rules';
    setNumberValue(1);
      setValues({...values});
     },[])
     
useEffect(()=>{
if(numberValue == 0){
values.ordering='-algorithm_rules';
setValues({...values});
}
},[numberValue])


  const changeNumber=(value)=>{
    setNumberValue(value);
  }
  
  useEffect(() => {
    loadAlgoScore();
}, [
  values.ordering, 
]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  }
  const loadAlgoScore = () => {
getAlgoScore({...values},tabName).then(response => {
      setFinancial(response.Financial)
    });
  };

  const handleRowClick = async(id , status ,togglename) => { 
    if(status ==null || status ==false){
      status = true;
    }else{
      status = false;
    }
         await  statusAlgo(id, status ,togglename) 
         loadAlgoScore()
         } 

         const handleModal = () => {
          setModalValue(!modalValue);
        };
    
    
        const handleRowClickEdit = id => {
          setIdSelected(id);
          setModalValue(true);
          // setValues({...values});
        };
    
        const toAlgoClientrRowData =async (id )=> {
          // setModalDeleteValue(true);
          // setIdToDelete(id);
                await resetAlgoRow( id);
                // setModalDeleteValue(false);
                loadAlgoScore();
        };
    
        const resetAll =async ( )=> {
          // setModalDeleteValue(true);
          // setIdToDelete(id);
                await resetAllAlgo('Financial');
                // setModalDeleteValue(false);
                loadAlgoScore();
        };

        
    return (
        <div>
        <div   className="d-flex justify-content-end"  data-toggle="tooltip" data-placement="top" title="Reset All" >
<ResetButton    onClick={resetAll} >
   <BiReset/>
  </ResetButton>
</div>
      <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">
      </DataContainer>
      <Table
        headerColumns={headerColumns}
        headerPadding={false}
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        statusNumber={numberValue}   
        setStatusNumber={changeNumber}   
          fullNameCol={fullNameCol}
          >
        <div>
          {financial && financial.map((financials, index) => {

            return (
              <div key={index}>
                <Row bgColor Shadow>
                  <Col
                    max_width={COL_CONTAIN_PARA}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {
                      financials.algorithm_rules  &&  financials.algorithm_rules
                      }
                  </Col>
                  {/* <Col
                      max_width={SMALL_COL}
                      className="sm-hidden"
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                     {
                        financials.min_time && financials.min_time
                      }
                  </Col> */}
                  {/* <Col
                    max_width={SMALL_COL}
                    className="sm-hidden"
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                     {
                        financials.max_time  &&  financials.max_time
                      }
                  </Col> */}
                  <Col
                    max_width={COL_CONTAIN_WORD}
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                  {
                      financials.score_level &&  financials.score_level
                      }
                  </Col>
                  <Col
                  max_width={COL_CONTAIN_WORD}
                  className="md-hidden"
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                     {
                      financials.reward  &&     financials.reward  
                      }
                  </Col>
                  <Col
                   max_width={COL_CONTAIN_WORD}
                   className="sm-hidden"
                    Shrink
                    reducePadding
                    Capitalize
                    Center>
                    {
                     financials.penalise &&  financials.penalise
                      }
                  </Col>
                  <Col
                         max_width={SMALL_COL}
                      //  Shrink
                      //  reducePadding
                       className="md-hidden"
                       Capitalize           
                     >
          
                   <div  className=" mt-1">
                     {update && ( <ToggleButton  
                       onClick= {()=> handleRowClick(financials.id , financials.consider_hard_rule,'consider_hard_rule')}
                   status = { financials.consider_hard_rule} /> ) }
                     {!update && (financials.consider_hard_rule ?'Active' : 'InActive' ) }
                   </div>  
       
                    </Col>
                   
                  <Col
                      max_width={SMALL_COL}
                      className="sm-hidden"
                      //  Shrink
                      //  reducePadding
                       Capitalize
                       Center>
                  
                  <div  className="mr-4">
                  {update && (
                       <ToggleButton  
                       onClick= {()=> handleRowClick(financials.id , financials.status,'status')}
                   status = { financials.status}
                       />
                       ) }
                           {!update && (financials.status ?'Active' : 'InActive' ) }
                       </div>
                    </Col>
                    
                    <Col   className="sm-hidden" Center Icon Purple       Shrink
                    reducePadding>   
                     <IconWrapper 
                      onClick={() => {
                        handleRowClickEdit(financials.id);
                      }}
                      >
                     <MdEdit    />
                     </IconWrapper>
                     </Col>
      
                  <Col  className="sm-hidden" Center Shrink 
                    reducePadding  NoFlexGrow > 
              <div   data-toggle="tooltip" data-placement="top" title="Reset " >
              <IconWrapper   onClick={() => {
                  toAlgoClientrRowData(financials.id );
                }}    >
                     <BiReset/>
                     </IconWrapper>
                     </div>
                     </Col> 
                </Row>
                <div className="mb-1" />
              </div>
            );
           })} 
         
        </div>
      </Table>
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          setAlgoClientModalValue={setModalValue}
          fullBind={fullBind}        
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          useInput={useInput}
          loadAlgoScore={loadAlgoScore}
        />
      )}
        </div>
    )
}

export default Financial;
