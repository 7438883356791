import React from 'react'
import { memo } from 'react'
import { useDrag } from 'react-dnd'
const style = {
    border: '1px solid gray',
    backgroundColor: 'white',
    padding: '0.5rem',
    marginRight: '1.5rem',
    cursor: 'move',
    width: "95%",
}
export const Box = memo(function Box({
    name,
    id,
    type,
    html,
    value,
    placeholder,
    required,
    order,
    options, choices }) {
    const [{ opacity }, drag] = useDrag(
        () => ({
            type,
            item: {
                id,
                name,
                type,
                html,
                value,
                placeholder,
                required,
                order,
                options,
                choices
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [name, type],
    )
    return (
        <div ref={drag} style={{ ...style, opacity }} data-testid="box">
            {name}
        </div>
    )
})
