import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { isArray } from 'lodash'
import React, { useState } from 'react'

export default function DropDown({ disabled=false ,json, jsonData, setJsonData, index, multiple= false }) {
    // const [label, setLabel] = useState(json.dropdown.label)

    const [selected, setSelected] = useState(json.value)
    return (
        <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400, }}>
            {/* <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel> */}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={multiple ? (json?.value ? [...json?.value] : []) : json?.value}
                multiple={multiple}
                disabled={disabled}
                label={json.placeholder}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                onChange={item => {
                    const data = jsonData.map((val, newIndex) => {
                        if (newIndex === index) {
                            return ({
                                ...val,
                                value: item.target.value
                            })
                        }
                        return val
                    })
                    setJsonData(data)
                }}
            >
                {
                    json.options.map((item, index) => <MenuItem key={index} value={item.label}>{item.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
