import {apiURL} from '../env';
import {get} from './Api';
import {getTenant} from '../localStorage/token';
import queryString from 'query-string';

const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};

export const getEmployeesRouteMap = (branchData,runroutes,branch,startDate, endDate, employeeId,runNames) => {

  let stringified = '';
  let toParse = {};
  let filterParse={};
  const baseUrl = getBaseUrl();

  if (runroutes) {
    let runRouteFilter=runroutes.map(data=>data.id)
    let strRunRouteFilter=runRouteFilter.join(",")
    filterParse.runroute = strRunRouteFilter;
  }
  if(branchData.headerbranch){
    let branchFilter=(branchData.headerbranch).map(data=>data.id)
    let strheaderbranchFilter=branchFilter.join(",")
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (branch) {
    // debugger
    let branchFilter=branch.map(data=>data.id)
    let strbranchFilter=branchFilter.join(",")
    filterParse.branch_id = strbranchFilter;
  }
  
  if (runNames) {
    let runNamesFilter=runNames.map(data=>data.id)
    let strrunNamesFilter=runNamesFilter.join(",")
    filterParse.runname_view = strrunNamesFilter;
  }

  //newly added code 
  if (employeeId) {
    let employeeIdFilter=employeeId.filter(data=>data.id!=="Unassigned").map(data=>data.id)
    let stremployeeIdFilter=employeeIdFilter.join(",")
    filterParse.employeeIdData = stremployeeIdFilter;
  }

  let dates =
    startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : '';
  // let id = `&employeeIds=${employeeId}`;


  stringified = queryString.stringify(toParse) || '';

  if(filterParse.runroute){
    stringified+='&run_route_ids='+filterParse.runroute
  }

  if(filterParse.employeeIdData){
    stringified+='&employeeIds='+filterParse.employeeIdData
  }
  // if (filterParse.branch_id) {
  //   if (filterParse.branch_id == 'None') {
  //     stringified += '&null_branch=' +  filterParse.branch_id
  //   } else{
  //   stringified += '&branch_ids=' +   filterParse.branch_id
  //   }
  // }  

  if (filterParse.branch_id) {
    if (filterParse.branch_id.includes('None')) {
       stringified += '&null_branch=' +  'None';
      let branchItemsIds = filterParse.branch_id;
      const branchIdsWithoutNoneId=branchItemsIds.split(",").filter(e=>e!=='None').join(",")
       if(branchIdsWithoutNoneId)
       stringified += '&branch_ids=' + branchIdsWithoutNoneId;
    } else{
    stringified += '&branch_ids=' +   filterParse.branch_id;
    }
  }else  if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
       stringified += '&null_branch=' +  'None';
      let branchItemsIds =filterParse.headerbranch;
      const branchIdsWithoutNoneId=branchItemsIds.split(",").filter(e=>e!=='None').join(",")
       if(branchIdsWithoutNoneId)
       stringified += '&branch_ids=' + branchIdsWithoutNoneId;
    } else{
    stringified += '&branch_ids=' +  filterParse.headerbranch;
    }
  }
  

  if(filterParse.runname_view){
    stringified+='&run_view_name='+filterParse.runname_view
  }
  // let url = baseUrl + '/optaplanner/app/getEmployeesRouteMap' + dates + id + stringified;
  let url = baseUrl + '/optaplanner/app/getEmployeesRouteMap' + dates + stringified

  return get(url);
};
