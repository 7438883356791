import * as React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const ErrorAlert = ({ open, setOpen, errorMessage }) => {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        // <Alert severity="error">
        //     <AlertTitle>Error</AlertTitle>
        //     This is an error alert — <strong>check it out!</strong>
        // </Alert>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </Alert>
        </Snackbar>
    )
}


export const DeleteModalPopUp = ({ open, setOpen, isSubmitted, save, deleteId }) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are,you sure you want to delete ?"}</DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are,You sure you want to delete ?
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={() => { save(deleteId) }} disabled={isSubmitted} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}