import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {
  TitleSection,
  ModalContainer,
  LinkTabStyled, 
  LinkTab,
  WeekStyle,
  ButtonWeek
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    ErrorMessage
  } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import { KeyPress } from 'utils/constants/regularExpressions';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import Table from 'components/SharedComponents/Table';
import {
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper
} from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import {RiDeleteBinLine} from 'react-icons/ri';
import ToggleButton from 'components/SharedComponents/ToggleButton';

const headerRunColumns = [
  {
    label: 1,
    checkbox:true,
    name: 'checkbox',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Visits',
    name: 'repeat_on',
    status: 0,
    className: 'm-0 p-0  md-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Requirement',
    name: 'requirement',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Start-End Time',
    name: 'time',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Repeat',
    name: 'repeat',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Start Date',
    name: 'start_date',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Pin Start-End Time',
    name: 'start_time',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
    overlap: true
  },
  {
    label: 'Run Name',
    name: 'run_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  },
  {
    label: 'Employee Name',
    name: 'employee_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
    overlap: true
  }


];

const AddRegularVisit = ({
  fullBind,
  loadingModal,
  useInput,
  values,
  setValues,
  errorMessage,
  dataValues,
  calculateEndValueProps,
  dropDownHandleSelect,
  switchEndValueInput,
  dropDownHandleRegVisit,
  handleSaveVisitRun,
  saveButtonIcon,
  loadingSave,
  textStatus,
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  setLoadingModal,
  choices,
  newRun,
  employees,
  pinnedData = [],
  deletePinnedEmp,
  contextChoicesMap
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [displayPhoto,setDisplayPhoto]=useState( )
  const [teamTabs, setTeamTabs] = useState('create');
  const [daysAdded, setDaysAdded] = useState([]);

  const alluseInp = {
    add_emp_hook: useInput('add_emp'),
    run_name: { ...useInput('run_name', 'isRequired') },
    regular_start_date: {...useInput(`regular_start_date`)},
    regular_end_date: {...useInput(`regular_end_date`)},
    every: {...useInput(`every`)},
    repeat: {...useInput(`repeat`, 'isInt')},
    emp_name: {...useInput(`employee`)},
    end_type: {...useInput(`end_type`)},
    end_value: {
      ...useInput(
        `end_value`,
        calculateEndValueProps(
          values.end_type
        )
      ),
    }
}
  
  const reviewTab = tab => {

    return tab === teamTabs;

  };
const tabClick=(tab)=>{
    setTeamTabs(tab);
  }

  const getDataFormChoiceMap = () => {
    let match = null
    if (values.employee) {
      match = contextChoicesMap
        && contextChoicesMap?.employee_listing
        && contextChoicesMap?.employee_listing[values?.employee]
        && contextChoicesMap?.employee_listing[values?.employee]
    }
    return {
      full_name: match ? match?.full_name : "",
      photo: match ? match?.photo : displayPhoto
    }
  }
console.log('values', values)
  useEffect(() => {
    const {full_name,photo} = getDataFormChoiceMap() || {};
    setAddFullBind({
        ...addFullBind,
        title: 
        <RowInfoModel
        name={full_name || ""}
        //lastName={values.employee.last_name}
        photoRow={photo}
        rows={[
          { label: 'Name:', value: full_name || "" },
        ]}
        popupStyle={true}
        />
        ,
      });
      setLoadingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind,contextChoicesMap?.employee_listing]);

  
console.log(values, 3333)
  const switchRunNameInput = props => {
     if (newRun) {
     return( 
       <div className="pl-3 pr-3 pb-3 pt-0">
      <InputLabelTop
      required
      readOnly={false}
      type="text"
      id="run_name"
      label={'Run Name'}
      {...props}
      maxlength={100}
     />
  </div>
     )
    } else {
      return(
        <Fragment />
      )
   }
  };

  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    if (!values.selectedPinned) {
      values.selectedPinned = [];
    }
    if (valcheck.target.checked) {
      values.selectedPinned.push(visit.day_id)
    } else {
      values.selectedPinned.map((item, index) => {
        if (item === visit.day_id) {
          values.selectedPinned.splice(index, 1);
        }
      })
    }
    setValues({ ...values })
  }
  
  const handleCheck = (visit) => {
    let check = false;
    if (values.selectedPinned) {
      values.selectedPinned.map((item) => {
        if (visit.day_id === item) {
          check = true
        }
      })
    }
    return check;
  }

  const handleAllSelectChange = (valcheck) => {
    if (!values.selectedPinned) {
      values.selectedPinned = [];
    }
    if (valcheck.target.checked) {
      pinnedData.map((visit)=>{
        values.selectedPinned.push(visit.day_id);
      })
    } else {
      pinnedData.map((visit)=>{
        values.selectedPinned.map((item, index) => {
          if (item === visit.day_id) {
            values.selectedPinned.splice(index, 1);
          }
        })
      })
    }
    setValues({ ...values })
  }
  
  const handleAllCheck = () => {
    let checkAll = true;
  
    const visitExists=(id)=>{
      return values.selectedPinned.some(function(el) {
        return el === id;
      });
    }
    if (values.selectedPinned && values.selectedPinned.length > 0) {
      pinnedData.map((visit)=>{
          if(!visitExists(visit.day_id)){
            checkAll = false
          }
        })
    }else{
      checkAll = false;
    }
    return checkAll;
  }

  const selectedDay = (day, indexWeek) => {
    const removeValue = daysAdded.indexOf(day);
    const weekDays = daysOfWeek;
    const addedDays = daysAdded;

    if (removeValue > -1) {
      daysAdded.splice(removeValue, 1);

      weekDays[indexWeek].selected = false;

      values.repeat_on = addedDays
      console.log('days',values.repeat_on)
      setDaysOfWeek(weekDays);
      setDaysAdded(daysAdded);
      setValues({ ...values });
      return;
    }

    addedDays.push(day);

    var addUniqs = addedDays.filter(function (item, indexUniq) {
      return addedDays.indexOf(item) === indexUniq;
    });

    weekDays[indexWeek].selected = true;

    values.repeat_on = addedDays
console.log('days',values.repeat_on)
    setDaysOfWeek(weekDays);
    setDaysAdded(addUniqs);
    setValues({ ...values });
  };

  const createRender=(item)=>{
    if(teamTabs === 'create') {
        return(<div>
           <div className="d-flex pl-3 pr-3 pb-3 pt-0">
          <label style={{fontSize:"0.875rem", marginRight:'7px', alignSelf:'center'}}>Attach new employee with selected visits</label>
            <ToggleButton
              label=" "
              disabled={false}
              useInput={alluseInp.add_emp_hook} 
              id="test"
              // onClick={handelOverNight}
            />
          </div>
          {values.add_emp && 
            <div className="pl-3 pr-3 pb-3 pt-0">
            <DropdownSearchable
               required
               {...alluseInp.emp_name}
                placeHolder={'Employee Name'}
                options={employees}
                selectedOption={values.employee ? values.employee : ''}
                onChange={value =>
                  dropDownHandleSelect({employee: value ? value : null})
                }
              />
            </div>
         }
          <div className="pl-3 pr-3 pb-3 pt-0">
            <DropdownSearchable
              placeHolder={'Select Run Name'}
              options={[{ id: 'new', name: '+ New Run' }, ...choices.run_view]}
              selectedOption={values.run_name_view}
              onChange={value =>
                dropDownHandleSelect({ run_name_view: value ? value : null })
              }
            />
          </div>
          {switchRunNameInput(item.run_name)}
      <FlexGrid3Container className="pl-3 pr-3">
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
               
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service start date'}
                {...item.regular_start_date}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service end date'}
                {...item.regular_end_date}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0 mb-3">
              <DropdownSearchable
                {...item.every}
                placeHolder={'Repeat Every'}
                options={[
                  { id: EVERY_DAY, name: 'Day' },
                  { id: EVERY_WEEK, name: 'Week' },
                  { id: EVERY_MONTH, name: 'Month' },
                  { id: EVERY_YEAR, name: 'Year' },
                ]}
                selectedOption={
                  values
                    ? values.every
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { every: value ? value : null },
                    'every'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
              <InputLabelTop
                //   disabled={readOnly}
                label={'Repeat No.'}
                onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
                id={`repeat`}
                {...item.repeat}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pr-1 pl-0 pb-2 pb-md-0">
              <DropdownSearchable
                {...item.end_type}
                // data_error={errors.indexOf(`end_type`)}
                //   disabled={readOnly}
                placeHolder={'End'}
                options={[
                  { id: END_TYPE_ON, name: 'On' },
                  { id: END_TYPE_AFTER, name: 'After' },
                  { id: END_TYPE_NEVER, name: 'Never' },
                ]}
                selectedOption={
                  values
                    ? values.end_type
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { end_type: value ? value : null },
                    'end_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 mt-0 md-2 pr-0 pr-md-1">
              {switchEndValueInput(item.end_value)}
            </FlexGrid3Item>
            <div className={'formWeek d-block'}>
                <div
                  className={'pt-2 ml-md-1 ml-0 text-center'}
                  style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                  Day of the week
                </div>

                <WeekStyle  >
                  {daysOfWeek.map((itemWeek, indexWeek) => {

                    return (
                      <ButtonWeek
                        // disabled={values.start_date? true : false}
                        key={itemWeek.day}
                        add_background={itemWeek.selected}
                        onClick={() => {
                          selectedDay(itemWeek.day, indexWeek);
                        }}
                      // disabled={readOnly}
                      >
                        <div>
                          <label>{itemWeek.day}</label>
                        </div>
                      </ButtonWeek>
                    );
                  })}
                </WeekStyle>

                {requireDayOfWeek ? (
                  <div
                    className={'text-center'}
                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                    <ErrorMessage fontSize={'0.6rem'}>
                      <label className={'mt-2'}>Is required</label>
                    </ErrorMessage>
                  </div>

                ) : (
                  <Fragment />
                )}
              </div>
          </FlexGrid3Container>
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ml-0 ml-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveVisitRun()} >
                      <span className={'mr-2'}>{textStatus}</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>)
    }
  }
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        
        {pinnedData && (pinnedData.length > 0) && <div className={'d-flex w-100 text-center mb-4'}>
            <LinkTabStyled active={reviewTab('create')}>
              <div onClick={() => tabClick('create')} className={'styledLink'}>
                <LinkTab active={reviewTab('create')}>
                  Create 
                </LinkTab>
              </div>
            </LinkTabStyled>
            <LinkTabStyled active={reviewTab('pinned')}>
              <div onClick={() => tabClick('pinned')} className={'styledLink'}>
                <LinkTab active={reviewTab('pinned')}>
                  Pinned Employee
                </LinkTab>
              </div>
            </LinkTabStyled>
      </div>}
          {createRender(alluseInp)}
          {values.selectedPinned && (values.selectedPinned.length > 0) &&
            <div class="dropdown" style={{alignSelf:'end', marginRight:'20px'}}>
              <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className={'mr-2'}>Action</span>
              </PrimaryButtonForm>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" onClick={deletePinnedEmp}>Unpinned</a>
              </div>
            </div>}
        { (teamTabs==='pinned') &&  <div style={{maxHeight: '400px', paddingTop: '30px', overflowY: 'auto', marginLeft:'20px', marginRight:'20px'}}>
        <Table headerColumns={headerRunColumns} callBackQuerySort={setQuerySort} noEditOption headerPadding={false}   handleAllSelectChange={handleAllSelectChange} 
          handleAllCheck={handleAllCheck} noNeed={true}>
            <div>
              {pinnedData && pinnedData.map((runPin, runIndex) => {
                return (
                  <div key={runIndex}>
                    <Row bgColor className="mb-1">
                      <ColsGrouper className="d-flex">
                      <Col
                          max_width={EX_SMALL_COL4}
                          Shrink={false}
                          NoFlexGrow={true}
                          reducePadding
                          Capitalize
                          Center
                        >
                          <input class="form-check-input" type="checkbox" checked={handleCheck(runPin)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, runPin, runIndex) }} />
                        </Col>
                        <Col
                          max_width={MEDIUM_COL}
                          Center
                          className='p-2'
                          >
                         {runPin.repeat_on}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          // className='p-2 pr-4'
                          Center
                          >
                           {runPin.requirement_name}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          className='p-2 pr-4'
                          Center
                          >
                          {runPin.start_time+"-"
                                                  +runPin.end_time
                                                  }
                        </Col>
                      
                        <Col
                          max_width={MEDIUM_COL}
                          className='p-2 pr-4'
                          Center
                          >
                           {runPin.repeat}
                        </Col>
                        <Col
                          max_width={MEDIUM_COL}
                          className='p-2 pr-4'
                          Center
                          >
                          {runPin.start_date}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          className='p-2 pr-4'
                          Center
                          >
                          {runPin.pin_start_time+"-"
                                                  +runPin.pin_end_time
                                                  }
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          overlap={true}
                          className='p-2 pr-4'
                          Center
                          >
                          {runPin.run_name_run}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          overlap={true}
                          className='p-2 pr-4'
                          Center
                          >
                          {runPin.client_name}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          overlap={true}
                          className='p-2 pr-4'
                          Center
                          >
                           {runPin.employee_name}
                        </Col>
                        <Col className="sm-hidden" Center Shrink NoFlexGrow>
                      <IconWrapper
                        onClick={() => {
                          deletePinnedEmp(runPin.day_id);
                        }}
                        >
                        <RiDeleteBinLine />
                      </IconWrapper>
                    </Col>
                      </ColsGrouper>
                    </Row>
                  </div>
                );
              })}
               
            </div>
          </Table>
          </div>}
  </ModalDynamic>
    </ModalContainer>
  );
};


export default AddRegularVisit;
