import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { isArray } from 'lodash'
import React, { useState } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { ERR_COLOR } from 'shared/styles/constants/colors'
import { IconWrapper } from 'shared/styles/constants/tagsStyles'

export default function DropDownMultiSelectList({ disabled=false ,json, jsonData, setJsonData, index, multiple= false }) {
    // const [label, setLabel] = useState(json.dropdown.label)

    const [selected, setSelected] = useState(json.value)

    const toDeleteJob = (indexD) => {
        if(jsonData[index] && jsonData[index].value){
            jsonData[index].value.splice(indexD,1)
        }
        setJsonData([...jsonData])
    }

    return (
        <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: 400, }}>
            {/* <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel> */}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={""}
                disabled={disabled}
                label={json.placeholder}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                onChange={item => {
                    let findValue = jsonData[index]?.value?.find((val) => item.target.value == val)

                    if (!findValue) {
                        const data = jsonData.map((val, newIndex) => {
                            if (newIndex === index) {
                                return ({
                                    ...val,
                                    value: val.value ? [...val.value,item.target.value] : [item.target.value]
                                })
                            }
                            return val
                        })
                        setJsonData(data)
                    } 
                   
                }}
            >
                {
                    json?.options?.map((item, index) => <MenuItem key={index} value={item.label}>{item.label}</MenuItem>)
                }
            </Select>
                            {json?.value?.map((tableval, index) => (
                                        <div
                                            className="item-card"
                                        >
                                            <div className='d-flex border p-1' >
                                                <div className='d-flex align-items-center sticky-col w-100 justify-content-between'
                                                    style={{
                                                        paddingLeft: 3,
                                                    }}
                                                >
                                                    <div>
                                                        {tableval}
                                                    </div>
                                                    <IconWrapper
                                                        style={{ color: ERR_COLOR, marginRight: '0.5rem' }}
                                                        onClick={() => {
                                                            toDeleteJob(index);
                                                        }}>
                                                        <RiDeleteBinLine />
                                                    </IconWrapper>

                                                </div>
                                            </div>

                                        </div>
                            ))}

        </FormControl>
    )
}
