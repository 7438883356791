import React, {useEffect, useState, useRef, useContext} from 'react';
import {useModal} from 'hooks/ModalHook';
import {useHistory} from 'react-router-dom';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {getAdmin, getSuperAdmin} from '../../../../utils/localStorage/token';
//import Funder from '../../Settings/SettingsClient/Funder'
import {
  getSubSectionPermissions,
  getStaff,
} from '../../../../utils/localStorage/user';
import {
  REPORT,
  TEAM,
  JOB_DETAILS,
  PERSONAL_DETAILS,
  NOK,
  RATES,
  HEALTH,
  ALLERGIES,
  VISIT,
  PREFERRED_CLIENTS,
  AVOID_CLIENTS,
  AVOID_EMPLOYEES,
  POSTAL_AREA,
  AVOID_ANIMALS,
  SKILLS_TRAINING,
  COMPETENCIES,
  QUALIFICATION,
  OVERVIEW,
  EXPIRY_NOTIFICATION,
  REFERENCES,
  RIGHTTOWORK,
  DBS,
  VACCINATIONS,
  DRIVERS,
  EMPLOYEEID,
  EXPENSE,
  AVOID_RUN,
  PREFERRED_RUN,
  EMPLOYEE_ROTA,
  VISIT_CARE_NOTES,
  VISIT_CARE,
  REGULAR_SHIFT_ROUTE,
  // RUN_ROUTE
} from 'utils/constants/routes';
import {
  ContainerTabStyled,
  Title,
  LinkTabStyled,
  LinkTab,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  REPORT_TEAM_PD,
  REPORT_TEAM_JD,
  REPORT_TEAM_NOK,
  REPORT_TEAM_JOB_RATE_CARD,
  REPORT_TEAM_MEDICAL,
  REPORT_TEAM_ALLERGIES,
  REPORT_TEAM_VISIT_CARE,
  REPORT_TEAM_PREFERRED_CLIENT,
  REPORT_TEAM_AVOID_CLIENT,
  REPORT_TEAM_AVOID_EMPLOYEE,
  REPORT_TEAM_AVOID_POSTAL,
  REPORT_TEAM_AVOID_ANIMAL,
  REPORT_AVOID_RUN,
  REPORT_PREFERRED_RUN,
  REPORT_VISIT_CARE,
  REPORT_TEAM_SKILL_TRAINING,
  REPORT_TEAM_COMPETENCY,
  REPORT_TEAM_QUA_APP,
  REPORT_TEAM_TRAINING,
  REPORT_TEAM_REFERENCES,
  REPORT_TEAM_EMPLOYEE_VISA,
  REPORT_TEAM_EMPLOYEE_DBS,
  REPORT_TEAM_VACCINATION,
  REPORT_TEAM_DRIVER,
  REPORT_TEAM_EMPLOYEE_ID,
  REPORT_TEAM_EXPENSE,
  REPORT_EMPLOYEE_ROTA_EMAIL,
  REGULAR_SHIFT,
  CRON_JOB_SHIFT_REPORTS,
} from '../../../../utils/constants/permissions';
import TeamFilters from './TeamFilters';
import _ from 'lodash';
import {AppContext} from 'context';
import TeamRota from './TeamRota';

const TAB_PERSONAL_DETAILS_URL = REPORT + TEAM + PERSONAL_DETAILS;
const TAB_JOB_DETAILS_URL = REPORT + TEAM + JOB_DETAILS;
const TAB_NOK_URL = REPORT + TEAM + NOK;
const TAB_RATES_URL = REPORT + TEAM + RATES;
const TAB_HEALTH_URL = REPORT + TEAM + HEALTH;
const TAB_ALLERGIES_URL = REPORT + TEAM + ALLERGIES;
const TAB_VISIT_CARE_NOTES_URL = REPORT + TEAM + VISIT;
const TAB_PREFERRED_CLIENTS_URL = REPORT + TEAM + PREFERRED_CLIENTS;
const TAB_AVOID_CLIENTS_URL = REPORT + TEAM + AVOID_CLIENTS;
const TAB_AVOID_EMPLOYEES_URL = REPORT + TEAM + AVOID_EMPLOYEES;
const TAB_POSTAL_AREA_URL = REPORT + TEAM + POSTAL_AREA;
const TAB_AVOID_ANIMALS_URL = REPORT + TEAM + AVOID_ANIMALS;
const TAB_AVOID_RUN_URL = REPORT + TEAM + AVOID_RUN;
const TAB_PREFERRED_RUN_URL = REPORT + TEAM + PREFERRED_RUN;
const TAB_VISIT_CARE_URL = REPORT + TEAM + VISIT_CARE;
const TAB_SKILLS_TRAINING_URL = REPORT + TEAM + SKILLS_TRAINING;
const TAB_COMPETENCIES_URL = REPORT + TEAM + COMPETENCIES;
const TAB_QUALIFICATION_URL = REPORT + TEAM + QUALIFICATION;
const TAB_OVERVIEW_URL = REPORT + TEAM + OVERVIEW;
const TAB_EXPIRY_NOTIFICATION_URL = REPORT + TEAM + EXPIRY_NOTIFICATION;
const TAB_REFERENCES_URL = REPORT + TEAM + REFERENCES;
const TAB_RIGHTTOWORK_URL = REPORT + TEAM + RIGHTTOWORK;
const TAB_DBS_URL = REPORT + TEAM + DBS;
const TAB_VACCINATIONS_URL = REPORT + TEAM + VACCINATIONS;
const TAB_DRIVERS_URL = REPORT + TEAM + DRIVERS;
const TAB_EMPLOYEEID_URL = REPORT + TEAM + EMPLOYEEID;
const TAB_EXPENSE_URL = REPORT + TEAM + EXPENSE;
const TAB_EMPLOYEE_ROTA_URL = REPORT + TEAM + EMPLOYEE_ROTA;
const TAB_CRON_JOB_URL = REPORT + TEAM + REGULAR_SHIFT_ROUTE;


const tabTeamArray = [
  {
    label: 'Personal Details',
    route: TAB_PERSONAL_DETAILS_URL,
    name: 'Personal_Details',
    permission: REPORT_TEAM_PD,
  },
  {
    label: 'Job Details',
    route: TAB_JOB_DETAILS_URL,
    name: 'Job_Details',
    permission: REPORT_TEAM_JD,
  },
  {
    label: 'NOK',
    route: TAB_NOK_URL,
    name: 'NOK',
    permission: REPORT_TEAM_NOK,
  },
  {
    label: 'Rates',
    route: TAB_RATES_URL,
    name: 'job_rate_card',
    permission: REPORT_TEAM_JOB_RATE_CARD,
  },
  {
    label: 'Health',
    route: TAB_HEALTH_URL,
    name: 'Medical_Condition',
    permission: REPORT_TEAM_MEDICAL,
  },
  {
    label: 'Allergies',
    route: TAB_ALLERGIES_URL,
    name: 'Employee_Allergies',
    permission: REPORT_TEAM_ALLERGIES,
  },

  {
    label: 'Preferred Client',
    route: TAB_PREFERRED_CLIENTS_URL,
    name: 'Preferred_Client',
    permission: REPORT_TEAM_PREFERRED_CLIENT,
  },
  {
    label: 'Avoid Clients',
    route: TAB_AVOID_CLIENTS_URL,
    name: 'Avoid_Client',
    permission: REPORT_TEAM_AVOID_CLIENT,
  },
  {
    label: 'Avoid Employees',
    route: TAB_AVOID_EMPLOYEES_URL,
    name: 'Avoid_Employee',
    permission: REPORT_TEAM_AVOID_EMPLOYEE,
  },
  {
    label: 'Postal Area',
    route: TAB_POSTAL_AREA_URL,
    name: 'Avoid_Postal_Area',
    permission: REPORT_TEAM_AVOID_POSTAL,
  },
  {
    label: 'Avoid Animals',
    route: TAB_AVOID_ANIMALS_URL,
    name: 'Avoid_Animal',
    permission: REPORT_TEAM_AVOID_ANIMAL,
  },
  {
    label: 'Avoid Run',
    route: TAB_AVOID_RUN_URL,
    name: 'avoid_run',
    permission: REPORT_AVOID_RUN,
  },
  {
    label: 'Preferred Run',
    route: TAB_PREFERRED_RUN_URL,
    name: 'preferred_run',
    permission: REPORT_PREFERRED_RUN,
  },

];

const tabTeamTrainingArray = [
  {
    label: 'Skills & Training',
    route: TAB_SKILLS_TRAINING_URL,
    name: 'Skill_and_Training',
    permission: REPORT_TEAM_SKILL_TRAINING,
  },
  {
    label: 'Competencies',
    route: TAB_COMPETENCIES_URL,
    name: 'Competency',
    permission: REPORT_TEAM_COMPETENCY,
  },
  {
    label: 'Qualification',
    route: TAB_QUALIFICATION_URL,
    name: 'Qualification_and_Appreciation',
    permission: REPORT_TEAM_QUA_APP,
  },
  {
    label: 'Overview',
    route: TAB_OVERVIEW_URL,
    name: 'Training',
    permission: REPORT_TEAM_TRAINING,
  },
  // {
  //   label: 'Expiry Notification',
  //   route: TAB_EXPIRY_NOTIFICATION_URL,
  //   name: 'Medical_Condition'
  // }
];

const tabTeamComplianceArray = [
  {
    label: 'References',
    route: TAB_REFERENCES_URL,
    name: 'References',
    permission: REPORT_TEAM_REFERENCES,
  },
  {
    label: 'Right TO Work',
    route: TAB_RIGHTTOWORK_URL,
    name: 'Employee_Visa',
    permission: REPORT_TEAM_EMPLOYEE_VISA,
  },
  {
    label: 'DBS',
    route: TAB_DBS_URL,
    name: 'Employee_Dbs',
    permission: REPORT_TEAM_EMPLOYEE_DBS,
  },
  {
    label: 'Vaccinations',
    route: TAB_VACCINATIONS_URL,
    name: 'vaccination',
    permission: REPORT_TEAM_VACCINATION,
  },
  {
    label: 'Drivers',
    route: TAB_DRIVERS_URL,
    name: 'driver',
    permission: REPORT_TEAM_DRIVER,
  },
  {
    label: 'Employee Id',
    route: TAB_EMPLOYEEID_URL,
    name: 'employee_id',
    permission: REPORT_TEAM_EMPLOYEE_ID,
  },
];

const tabCronJobArray = [
  {
    label: 'Regular Shift',
    route: TAB_CRON_JOB_URL,
    name: 'Regular Shift',
    permission: CRON_JOB_SHIFT_REPORTS,
  }
];
const ReportTeam = props => {
  const [teamTabs, setTeamTabs] = useState('team');
  const [teamFirstArray, setTeamFirstArray] = useState(tabTeamArray);
  const [teamSecondArray, setTeamSecondArray] = useState(tabTeamTrainingArray);
  const [teamThirdArray, setTeamThirdArray] = useState(tabTeamComplianceArray);
  const [teamCronJobTabs, setTeamCronJobTabs] = useState(tabCronJobArray);
  const [expensePermission, setExpensePermission] = useState(false);
  const [employeeRotaPermission, setEmployeeRotaPermission] = useState(false);
  const [updateFunderSettings, setUpdatePermissionsFunderSettings] =
    useState(false);
  const [createFunderSettings, setCreatePermissionsFunderSettings] =
    useState(false);
  const [deletePermissionFunderSettings, setDeletePermissionsFunderSettings] =
    useState(false);
  const [readPermissionFunderSettings, setReadPermissionsFunderSettings] =
    useState(false);
  const [updatePrioritySettings, setUpdatePermissionsPrioritySettings] =
    useState(false);
  const [createPrioritySettings, setCreatePermissionsPrioritySettings] =
    useState(false);
  const [
    deletePermissionPrioritySettings,
    setDeletePermissionsPrioritySettings,
  ] = useState(false);
  const [readPermissionPrioritySettings, setReadPermissionsPrioritySettings] =
    useState(false);
  const {
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);

  let history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const userStaff = contextIsStaff;
  const reviewLocation = url => {
    return url === history.location.pathname;
  };

  const reviewTab = tab => {
    return tab === teamTabs;
  };
  useEffect(() => {
    let rolePermissions = {};
    const rolesPermissions = contextSubSectionPermissions;

    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
    }
    if (contextIsAdmin || contextIsSuperAdmin) {
      setTeamFirstArray(tabTeamArray);
      setTeamSecondArray(tabTeamTrainingArray);
      setTeamThirdArray(tabTeamComplianceArray);
      setTeamCronJobTabs(tabCronJobArray);
      setExpensePermission(true);
      setEmployeeRotaPermission(true);
    } else {
      if (!_.isEmpty(rolePermissions)) {
        let values1 = teamFirstArray.filter(
          item =>
            rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_READ_PERMISSION
            ] && item
        );
        let values2 = teamSecondArray.filter(
          item =>
            rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_READ_PERMISSION
            ] && item
        );
        let values3 = teamThirdArray.filter(
          item =>
            rolePermissions[item.permission]?.[WEB_PERMISSION]?.[
              WEB_READ_PERMISSION
            ] && item
        );
        setExpensePermission(
          rolePermissions[REPORT_TEAM_EXPENSE]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]
        );
        setEmployeeRotaPermission(
          rolePermissions[REPORT_EMPLOYEE_ROTA_EMAIL]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]
        );
        setTeamFirstArray(values1);
        setTeamSecondArray(values2);
        setTeamThirdArray(values3);

        //Cron Job Permission
        let hasCronJobTabPermission = tabCronJobArray.filter(item => {
          if (item.permission) {
            return rolePermissions[item.permission]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION]
          } else {
            return true
          }
        }
        );
        setTeamCronJobTabs(hasCronJobTabPermission);
      }
    }
  }, [contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin]);

  const tabClick = tab => {
    setTeamTabs(tab);
    if (tab === 'team') {
      history.push(TAB_PERSONAL_DETAILS_URL);
    } else if (tab === 'team_training') {
      history.push(TAB_SKILLS_TRAINING_URL);
    } else if (tab === 'team_compliance') {
      history.push(TAB_REFERENCES_URL);
    } else if (tab === 'team_expense') {
      history.push(TAB_EXPENSE_URL);
    } else if (tab === 'employee_rota') {
      history.push(TAB_EMPLOYEE_ROTA_URL);
    } else if (tab === 'cron_job') {
      history.push(TAB_CRON_JOB_URL);
    }
    
  };
console.log(teamCronJobTabs)
  return (
    <div>
      <Title>Report Team</Title>
      <div className={'d-flex w-100 text-center mb-4'}>
        <LinkTabStyled active={reviewTab('team')}>
          <div onClick={() => tabClick('team')} className={'styledLink'}>
            <LinkTab active={reviewTab('team')}>Team</LinkTab>
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('team_training')}>
          <div
            onClick={() => tabClick('team_training')}
            className={'styledLink'}>
            <LinkTab active={reviewTab('team_training')}>Team Training</LinkTab>
          </div>
        </LinkTabStyled>
        <LinkTabStyled active={reviewTab('team_compliance')}>
          <div
            onClick={() => tabClick('team_compliance')}
            className={'styledLink'}>
            <LinkTab active={reviewTab('team_compliance')}>
              Team Compliance
            </LinkTab>
          </div>
        </LinkTabStyled>
        {expensePermission && (
          <LinkTabStyled active={reviewTab('team_expense')}>
            <div
              onClick={() => tabClick('team_expense')}
              className={'styledLink'}>
              <LinkTab active={reviewTab('team_expense')}>
                Team Expenses
              </LinkTab>
            </div>
          </LinkTabStyled>
        )}
        {employeeRotaPermission && (
          <LinkTabStyled active={reviewTab('employee_rota')}>
            <div
              onClick={() => tabClick('employee_rota')}
              className={'styledLink'}>
              <LinkTab active={reviewTab('employee_rota')}>
                Employee Rota
              </LinkTab>
            </div>
          </LinkTabStyled>
        )}
         {teamCronJobTabs?.length ? (
          <LinkTabStyled active={reviewTab('cron_job')}>
            <div
              onClick={() => tabClick('cron_job')}
              className={'styledLink'}>
              <LinkTab active={reviewTab('cron_job')}>
                Cron Job
              </LinkTab>
            </div>
          </LinkTabStyled>
        ): null}
        
      </div>
      {teamTabs === 'team' && (
        <>
          <div className="p-2 p-sm-3 p-md-4">
            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex w-100'}>
                {teamFirstArray.map(item => {
                  return (
                    <LinkTabStyled active={reviewLocation(item.route)}>
                      <Link to={item.route} className={'styledLink'}>
                        <LinkTab active={reviewLocation(item.route)}>
                          {item.label}
                        </LinkTab>
                      </Link>
                    </LinkTabStyled>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
          <Switch>
            {teamFirstArray.map(item => {
              return (
                <Route exact path={item.route}>
                  <TeamFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                  />
                </Route>
              );
            })}
          </Switch>
        </>
      )}
      {teamTabs === 'team_training' && (
        <>
          <div className="p-2 p-sm-3 p-md-4">
            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex w-100'}>
                {teamSecondArray.map(item => {
                  return (
                    <LinkTabStyled active={reviewLocation(item.route)}>
                      <Link to={item.route} className={'styledLink'}>
                        <LinkTab active={reviewLocation(item.route)}>
                          {item.label}
                        </LinkTab>
                      </Link>
                    </LinkTabStyled>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
          <Switch>
            {teamSecondArray.map(item => {
              return (
                <Route exact path={item.route}>
                  <TeamFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                  />
                </Route>
              );
            })}
          </Switch>
        </>
      )}
      {teamTabs === 'team_compliance' && (
        <>
          <div className="p-2 p-sm-3 p-md-4">
            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex w-100'}>
                {teamThirdArray.map(item => {
                  return (
                    <LinkTabStyled active={reviewLocation(item.route)}>
                      <Link to={item.route} className={'styledLink'}>
                        <LinkTab active={reviewLocation(item.route)}>
                          {item.label}
                        </LinkTab>
                      </Link>
                    </LinkTabStyled>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
          <Switch>
            {teamThirdArray.map(item => {
              return (
                <Route exact path={item.route}>
                  <TeamFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                  />
                </Route>
              );
            })}
          </Switch>
        </>
      )}
      {teamTabs === 'team_expense' && (
        <>
          <Switch>
            <Route exact path={TAB_EXPENSE_URL}>
              <TeamFilters
                location={props.location}
                tabClick={'employee_expense'}
                updateFunderSettings={updateFunderSettings}
                createFunderSettings={createFunderSettings}
                deletePermissionFunderSettings={deletePermissionFunderSettings}
                readPermissionFunderSettings={readPermissionFunderSettings}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                userStaff={userStaff}
              />
            </Route>
          </Switch>
        </>
      )}
      {teamTabs === 'employee_rota' && (
        <>
          <Switch>
            <Route exact path={TAB_EMPLOYEE_ROTA_URL}>
              <TeamRota
                location={props.location}
                updateFunderSettings={updateFunderSettings}
                createFunderSettings={createFunderSettings}
                deletePermissionFunderSettings={deletePermissionFunderSettings}
                readPermissionFunderSettings={readPermissionFunderSettings}
                filterAdmin={filterAdmin}
                filterSuperAdmin={filterSuperAdmin}
                userStaff={userStaff}
              />
            </Route>
          </Switch>
        </>
      )}
        {teamTabs === 'cron_job' && (
        <>
          <div className="p-2 p-sm-3 p-md-4">
            <ContainerTabStyled className="text-center mb-4">
              <div className={'d-flex justify-content-center pr-2 pl-2 w-100'}>
                {teamCronJobTabs.map(item => {
                  return (
                    <LinkTabStyled  style={{
                      maxWidth: 'fit-content',
                    }} active={reviewLocation(item.route)}>
                      <Link to={item.route} className={'styledLink'}>
                        <LinkTab
                         style={{
                          paddingLeft: '20px',
                          paddingRight: '20px'
                        }}
                          active={reviewLocation(item.route)}>
                          {item.label}
                        </LinkTab>
                      </Link>
                    </LinkTabStyled>
                  );
                })}
              </div>
            </ContainerTabStyled>
          </div>
          <Switch>
            {teamCronJobTabs.map(item => {
              return (
                <Route exact path={item.route}>
                  <TeamFilters
                    location={props.location}
                    tabClick={item.name}
                    updateFunderSettings={updateFunderSettings}
                    createFunderSettings={createFunderSettings}
                    deletePermissionFunderSettings={
                      deletePermissionFunderSettings
                    }
                    readPermissionFunderSettings={readPermissionFunderSettings}
                    filterAdmin={filterAdmin}
                    filterSuperAdmin={filterSuperAdmin}
                    userStaff={userStaff}
                  />
                </Route>
              );
            })}
          </Switch>
        </>
      )}
    </div>
  );
};

export default ReportTeam;
