import {
  LARGE_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
  EX_SMALL_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0',
    max_width: EX_SMALL_COL,
  },
  {
    label: 'FUNDER CATEGORY',
    name: 'client_category',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'FUNDER NAME',
    name: 'client_category',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'FUNDER RATECARD',
    name: 'funder_ratecard',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  },

  // {
  //   label: 'START DATE',
  //   name: 'start_dates',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'VISIT COUNTS',
    name: 'visit_counts',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'WEEKLY DURATION',
    name: '',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'FUNDER START - END DATE',
    name: 'times',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: EX_LARGE_COL,
  },
  // {
  //   label: 'VISIT DAYS',
  //   name: 'visit_days',
  //   status: 0,
  //   className: 'grow-mobile visits-custom sm-hidden',
  //   max_width: EX_LARGE_COL,
  // },
];

export const headerColumnsVisits = [
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0',
    max_width: EX_SMALL_COL,
  },
  {
    label: 'VISIT TYPE',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'SERVICE CATEGORY',
    name: 'client_category',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'START DATE',
    name: 'start_dates',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'END DATE',
    name: 'end_dates',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'START TIME - END TIME',
    name: 'times',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT DAYS',
    name: 'visit_days',
    status: 0,
    className: 'grow-mobile visits-custom md-hidden sm-hidden',
    max_width: EX_LARGE_COL,
  },
  // {
  //   label: '',
  //   name: '',
  //   status: 0,
  //   className: 'grow-mobile visits-custom sm-hidden',
  //   max_width: SMALL_COL,
  // },
];

export const headerColumnsVisitsEmployee = [
  {
    label: 'NO. OF EMPLOYEES',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'EMPLOYEE TYPE',
    name: 'client_category',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'MEDICATION',
    name: 'start_dates',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'PLANNED TASK',
    name: 'end_dates',
    status: 0,
    className: 'grow-mobile md-hidden sm-hidden',
    max_width: LARGE_COL,
  }
];