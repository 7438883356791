import {LARGE_COL,EX_SMALL_COL4,MEDIUM_COL,VERY_EX_SMALL_COL_CHECKBOX} from 'shared/styles/constants/columns';

export const headerColumns = [
 
  {
    label: 'Invoice No.',
    name: 'invoice_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Type',
    name: 'Type',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Name',
    name: 'Name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Date',
    name: 'date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Due Date',
    name: 'due_date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Total Amount',
    name: 'ttl_amt',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  
];
