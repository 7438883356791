import styled from 'styled-components';
import {AnimationStyle} from 'shared/styles/buttons';
import {MD} from 'shared/styles/constants/resolutions';

export const CloseButtonWrapper = styled.div`
  width: 1.25rem;
  cursor: pointer;
`;


export const CancelButton = styled.button`
  ${AnimationStyle}
  &:focus {
    outline: none !important;
  }
`;

export const LoadingContainer = styled.div`
  height: ${props => (props.maxHeight ? props.maxHeight : '37.5rem')};
`;

export const ModalTitle = styled.h5`
  min-width: ${props => (props.MinWidth ? props.MinWidth : '0')};
`;

export const ModalDynamicContainer = styled.div`
  @media (max-width: ${MD}) {
    flex-direction: column;

    h5 {
      margin-bottom: 1rem;
    }
  }
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const CloseButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${MD}) {
    position: absolute;
    right: 1.25rem;
  }
`;
