import React from "react";
import { StyledRadio } from "./styles";

const RadioButton = ({
     options,
     onChangeRadio,
     checkedValue
    }) => {
  return (
     <StyledRadio className="form-check">
      <form action="#">
          <div className="d-flex">
              {options.map((val, i) => {
                  return (
                      <div class="form-check form-check-inline">
                          <input
                              className="form-check-input"
                              type="radio"
                              name={val}
                              id={`radio${i}`}
                              value={val}
                              checked={checkedValue === val}
                              onChange={onChangeRadio}
                          />
                          <label className="form-check-label" for={`radio${i}`}>
                              {val}
                          </label>
                      </div>
                  )
              })}
          </div>
      </form>
     </StyledRadio>
  );
};

export default RadioButton;
