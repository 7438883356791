import React, {useEffect, useState, useRef, useContext} from 'react';
import {getShiftPatterns, deleteShiftPattern} from 'utils/api/SchedulerApi';
import {getChoices} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import {headerColumns} from './headerColumnsList';
import Table from 'components/SharedComponents/Table';
import {LARGE_COL} from 'shared/styles/constants/columns';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {useModal} from 'hooks/ModalHook';
import {
  Row,
  Col,
  DayPill,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import {RiDeleteBinLine} from 'react-icons/ri';
import ShiftPatternModal from './Modals/ShiftPatternModal';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {FaPlus} from 'react-icons/fa';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {Title, Header} from 'shared/styles/constants/tagsStyles';
import {getPermissions, getEmail} from '../../../utils/localStorage/user';
import {
  SHIFT_PATTERN_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from '../../../utils/constants/permissions';
import {
  getAdmin,
  getSuperAdmin,
  getStaff,
  getClient,
  getEmployee,
} from '../../../utils/localStorage/token';
import {AppContext} from 'context';
import {useHistory} from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { calendar_shift_pattern } from 'utils/choiceConstant';
const ShiftPatterns = props => {
  const {values, setValues} = useForm({ordering: 'full_name'});
  const [shiftPatterns, setShiftPatterns] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [choices, setChoices] = useState({});
  const [numberValue, setNumberValue] = useState();
  const [pages, setPages] = useState(0);
  const mountedRef = useRef(true);

  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const {
    contextPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextChoices,
  } = useContext(AppContext);
  const [loadingModal, setLoadingModal] = useState(true);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const history = useHistory();
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const fullNameCol = 'name';

  const getDays = () => {
    return [
      {name: 'MON'},
      {name: 'TUE'},
      {name: 'WED'},
      {name: 'THU'},
      {name: 'FRI'},
      {name: 'SAT'},
      {name: 'SUN'},
    ];
  };

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(calendar_shift_pattern)

  },[])
  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);

  useEffect(() => {
    setChoices(contextChoices || []);
  }, [contextChoices]);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};

    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === SHIFT_PATTERN_SETTINGS) {
            rolePermissions[SHIFT_PATTERN_SETTINGS] = {};
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });

        setCreatePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[SHIFT_PATTERN_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ]
        );
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    loadShiftPatterns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, values.ordering, values.limitperpage]);

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =values.limitperpage===100?0: offset * values.limitperpage;    return {
      offset,
    };
  };
  const loadShiftPatterns = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getShiftPatterns({...values, ...pageToSearch}, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;
        if (response) {
          setLoadingModal(false);
        }
        setShiftPatterns(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
      }
    );
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };

  const handleAddShift = () => {
    setIdSelected('');
    setTitle('Add shift pattern');
    setModalValue(true);
  };

  const reviewDays = (day, details) => {
    let repeat_on = false;
    const daysOfWeek = getDays();

    details.forEach(({day_of_week}) => {
      let position = day_of_week - 1 === -1 ? 0 : day_of_week - 1;
      if (day === `${daysOfWeek[position].name}`) {
        repeat_on = true;
        return;
      }
    });

    return repeat_on;
  };

  const toDeleteShiftPattern = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDeleteShiftPattern = async () => {
    try {
      await deleteShiftPattern(idSelected);
      //Todo: confirm deleted message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      setIdSelected('');
      loadShiftPatterns();
    }
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/shift-patterns',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({...values});
  };
console.log(values,"QQQQQQQQ")
  return (
    <div>
      <Title>Shift patterns</Title>
      <Header>
        <div className={'w-100 d-flex justify-content-end mb-2'}>
          {create &&
            ((userClient === 'null' && userEmployee === 'null') ||
              userStaff == true) && (
              <FloatingMobileButton onClick={handleAddShift} style={{marginBottom:'10px'}}>
                <FaPlus />
                <label>NEW SHIFT PATTERN</label>
              </FloatingMobileButton>
            )}
        </div>
      </Header>
      <>
        <ComponentDynamic loading={loadingModal}>
          <Table
            headerColumns={headerColumns}
            headerPadding={false}
            noEditOption
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}>
            <div>
              {shiftPatterns.map((pattern, index) => {
                const startEndTime = pattern.details[0]
                  ? `${
                      pattern.details[0].start ? pattern.details[0].start : ''
                    } - ${pattern.details[0].end ? pattern.details[0].end : ''}`
                  : '';
                return (
                  <div key={index}>
                    <Row bgColor Shadow>
                      <Col
                        onClick={() => {
                          handleRowClick(pattern.id);
                        }}
                        max_width={LARGE_COL}
                        Shrink
                        Capitalize
                        Center
                        overlap={true}>
                        {pattern.name}
                      </Col>
                      <Col
                        onClick={() => {
                          handleRowClick(pattern.id);
                        }}
                        max_width={''}
                        Shrink
                        Capitalize
                        Center
                        overlap={true}>
                        {startEndTime}
                      </Col>
                      {/* DAYS */}
                      <Col
                        onClick={() => {
                          handleRowClick(pattern.id);
                        }}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center
                        className="pattern-custom-row sm-hidden"
                        overlap={true}>
                        {getDays().map(day => {
                          return (
                            <DayPill
                              key={`day_pill_` + day.name}
                              Active={reviewDays(day.name, pattern.details)}>
                              <label>{day.name}</label>
                            </DayPill>
                          );
                        })}
                      </Col>
                      {deletePermission &&
                        ((userClient === 'null' && userEmployee === 'null') ||
                          userStaff === 'true') && (
                          <Col className="sm-hidden" Shrink reducePadding Icon>
                            <IconWrapper
                              onClick={() => {
                                toDeleteShiftPattern(pattern.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </Col>
                        )}
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              <div className="mt-3">
                <Pagination
                  setCurrentPage={setCurrentPage}
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={shiftPatterns.length}
                />
              </div>
            </div>
          </Table>
        </ComponentDynamic>
      </>
      {!modalValue ? null : (
        <ShiftPatternModal
          fullBind={fullBind}
          idSelected={idSelected}
          setTitle={setTitle}
          readOnly={false}
          setIdSelected={setIdSelected}
          loadShiftPatterns={loadShiftPatterns}
          choices={choices}
          userClient={userClient}
          userEmployee={userEmployee}
          userStaff={userStaff}
          filterSuperAdmin={filterSuperAdmin}
          filterAdmin={filterAdmin}
          setModalValue={setModalValue}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Shift pattern? \n DELETING the Shift pattern will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the Shift pattern status to discard instead to retain database records.'
        }
        onOk={handleDeleteShiftPattern}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default ShiftPatterns;
