import React from 'react';
import {DivContainerLine} from './styles';

const LineContainer = ({
  width = '',
  line_color = '',
  background_color = '',
  children,
  className = '',
  minHeight = '',
  maxHeight = '',
}) => {
  return (
    <DivContainerLine
      className={className}
      width={width}
      line_color={line_color}
      background_color={background_color}
      minHeight={minHeight}
      maxHeight={maxHeight}>
      {children}
    </DivContainerLine>
  );
};

export default LineContainer;
