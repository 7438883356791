import React, {useState, useEffect, useRef,useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import moment from 'moment';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {LARGE_COL , MEDIUM_COL} from 'shared/styles/constants/columns';
import {getAuditHistoryData, getMedAuditHistoryData} from 'utils/api/SchedulerApi';
import Pagination from 'components/SharedComponents/Pagination';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {useHistory} from 'react-router-dom';
import {useForm} from 'hooks/FormHook';

const AuditHistory = ({
  med_id,
  choices,
}) => {
  const {values, setValues, useInput,setCheckRequires} = useForm({limitperpage: PAGE_SIZE_RENDER});  //used useInput
  const [pages, setPages] = useState(0); 
  const [currentPage, setCurrentPage] = useState(1);
  const [auditHistoryById, setAuditHistoryById] = useState([]);
  const [noData, setNoData] = useState();
  const history=useHistory();
  const headerColumns = [
    {
      label: 'Created At',
      name: 'created_at',
      status: 0,
      className: 'grow-mobile  ',
      max_width: LARGE_COL,
    },
    {
      label: 'Action',
      name: 'action_name',
      status: 0,
      className: 'grow-mobile    ',
      max_width: LARGE_COL,
    },
    {
      label: 'Description',
      name: 'description',
      status: 0,
      className: 'grow-mobile    ',
      max_width: 'LARGE_COL',
    },
    {
      label: 'Performed By',
      name: 'created_by',
      status: 0,
      className: 'grow-mobile    ',
      max_width: LARGE_COL,
    },
  ];


    useEffect(()=>{
        loadAuditHistory();
    },[])


  const loadAuditHistory = () => {
    getMedAuditHistoryData(med_id).then(response=>{
      if(!response.status){
        setNoData(
          <div  className='text-center'>
          <p className='text-danger text-capitalize '>No data found</p>
          </div>
          );
      } 
      setAuditHistoryById(response.data)
      //setPages(Math.ceil(response.count / values.limitperpage));
    })
  };


  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };


  const createdATDataFormat=(created_at)=>{
    if(created_at){
      let dataArray =created_at.split('T');
      let date=dataArray[0];  
      let crudeTime=dataArray[1];
     let time= moment(crudeTime,'HH:mm').format('HH:mm');
     return date +"  "+ " "+time;
    }else{
    return 'no data';
    }
  };

const createdBYFormatName=(createdById)=>{
if(createdById ){
let getName=choices?.user_list?.find(dataId=>dataId.id == createdById)?.name;
return getName;
}else{
  return 'no data';
}
}

// const dropDownHandleSelectPaginations=(item,namesection)=>{
//   const urlParams = queryString.parse(props.location.search);
//   urlParams[PAGINATION_PARAM] = 1;
//   const stringified = queryString.stringify(urlParams);
//   history.push({
//     pathname: "/scheduler",
//     search: stringified,
//   });
//   values.limitperpage=item[namesection]
//   setValues({...values});
// }

  return (
    <div>
      {noData?noData:
    <Table
    headerColumns={headerColumns}
    callBackQuerySort={setQuerySort} 
    headerPadding={false}
    fontSize="0.75rem"
    noEditOption={true}
   >
    <div>
      {auditHistoryById?.map((Data, index) => {
        return (
         <div >
            <Row bgColor Shadow>
        
              <Col
                max_width={LARGE_COL}
                Shrink
                reducePadding
                Capitalize
                Center>
             {createdATDataFormat(Data.created_at)}
              </Col>

              <Col
              max_width={LARGE_COL}
                Shrink
                reducePadding
                Capitalize
                Center>
       {Data.action_name  && Data.action_name}
              </Col>
              <Col
              max_width={LARGE_COL}
                Shrink
                reducePadding
            
                Capitalize
                Center>
         {Data.description  && Data.description}
              </Col>
              <Col
                  max_width={LARGE_COL}
                Shrink
                reducePadding
                // className="sm-hidden"
                Capitalize
                Center>
   {createdBYFormatName(Data.created_by)}
              </Col>
            
          
                <Col   className="sm-hidden" Center Icon Purple       Shrink
                reducePadding>   
                 {/* <IconWrapper 
                  onClick={() => {
                    handleRowClickEdit(nlwData.id );
                  }}
                  >
                 <MdEdit    />
                 </IconWrapper> */}
                 </Col>
  
              <Col  className="sm-hidden" Center Shrink 
                reducePadding  NoFlexGrow > 
          {/* <IconWrapper   
       onClick={() => {
        toDeleteNLW(nlwData.id );  }}  >
               <RiDeleteBinLine />
                 </IconWrapper> */}
                 </Col> 
              
            </Row>
            <div className="mb-1" />
          </div>
);
     })} 

{/* <div className="mt-3">
        <Pagination totalPages={pages} currentPage={currentPage}
         values={values}  
          dropDownHandleSelect={dropDownHandleSelectPaginations}  
          number={PAGE_SIZE_RENDER}  
              dataLength={auditHistoryById?.length}
              />
      </div> */}

     
    </div>
  </Table>
}
  </div>
  )
}

export default AuditHistory