import React, { useState, useEffect, Fragment, useRef } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { useForm } from 'hooks/FormHook';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
  CloseButtonWrapper,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import './invoice.css'
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FaPlus } from 'react-icons/fa';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { FLOPPY } from 'utils/constants/icons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import TableInvoiceRows from './TableInvoiceRows';
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, EX_SMALL_COL_TOGGLE, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles';
import Table from 'components/SharedComponents/Table';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { getInvoiceVisitsByDate } from 'utils/api/InvoiceApi';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER, PER_PAGE_TEN } from 'utils/constants/pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const headerColumns = [
  {
      label: 1,
      checkbox:true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
  {
    label: 'Invoicing Type ',
    name: 'invoicing_type',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Toggle',
  //   name: 'toggle',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden',
  //   max_width: EX_SMALL_COL_TOGGLE,
  // },
  {
    label: 'Service Category',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual Start date',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Actual End date',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Scheduled Start date',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Scheduled End date',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Travel Start date',
  //   name: 'end_date',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'Travel End date',
  //   name: 'end_date',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Scheduled Travel Miles',
    name: 'sch_trl_miles',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Duration',
  //   name: 'end_date',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Amount Type',
    name: 'amount_type',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Amount',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  }
];
const TableFormData = ({
  props,
  fullBind,
  idSelected,
  setTitle,
  setModalValue,
  contextChoices,
  values,
  setValues,
  useInput,
  setInvoiceDetailData,
  invoiceDetailData,
  setCheckRequires,
    setShowError
}) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const toast = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchClick, setIsSearchClick] = useState(false);

  const history = useHistory();

  const toggleHook = useInput('is_absent')
  useEffect(() => {
    setTitle('Invoice Details')
  }, [])

  useEffect(()=>{
    if (values.invoice_detail_data && (values.invoice_detail_data.length > 0)) {
      getVisitDataForInvoice()
    }
  },[props.location.search, values.limitperpage])

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/invoice',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * PER_PAGE_TEN;
    return {
      offset,
    };
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
  };

  const getVisitDataForInvoice = () => {
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage('');

    if(!(values.start_day && values.end_day)){
      onError('Please fill the start and end date fields.');
      return
    }
    
    setIsSearchClick(true)
    const pageToSearch = getPageToSearchParam(props.location.search);

    getInvoiceVisitsByDate(
      { ...values, ...pageToSearch },
      values.limitperpage
    ).then(response => {
      if (response) {
        values.invoice_detail_data = response.results.map((item)=>({
          ...item, 
          id_select : item.invoicing_type + item.id,
          schedule_start_date_time: (item.invoicing_type == 'travel') ? (item.schedule_travel_start_datetime && moment(item.schedule_travel_start_datetime).format('YYYY-MM-DD HH:mm')) : (item.schedule_start_date && (item.schedule_start_date + ' ' + item.schedule_start_time)),
          schedule_end_date_time: (item.invoicing_type == 'travel') ? (item.schedule_travel_end_datetime && moment(item.schedule_travel_end_datetime).format('YYYY-MM-DD HH:mm')) :(item.schedule_end_date && (item.schedule_end_date + ' ' + item.schedule_end_time)),
          scheduled_amount: item.is_expenses ? item.expense_amount : item[`schedule_${item.invoicing_type}_amount`],
          actual_amount:  item.is_expenses ? item.expense_amount : item[`actual_${item.invoicing_type}_amount`],
          amount_type : 'actual'
        }));
        setValues({ ...values })
        setPages(Math.ceil(response.count / values.limitperpage));
      }
    });
  }

  const toggleSelect=(item, name, index)=>{
    values.invoice_detail_data[index][name] = item.target.checked;
     setValues({...values})
  }

  const dropDownHandleSelect=(item, name, index)=>{
    values.invoice_detail_data[index][name] = item[name];
    setValues({...values})
  }

  const addSubmit=()=>{
    let array = []
    values.checkbox_invoice_data && values.checkbox_invoice_data.forEach((item)=>{
        array.push({
          type: item.invoicing_type, 
          visit: !(item.invoicing_type == 'expenses') && item.id,
          expenses: (item.invoicing_type == 'expenses') && item.id,
          // data_id: item.id,
          visit_type__name: item.visit_type__name,
          service_category__name: item.service_category__name,
          schedule_start_date: item.is_expenses ? item.start_date : moment(item.schedule_start_date_time).format('YYYY-MM-DD'),
          schedule_end_date: item.is_expenses ? item.end_date : moment(item.schedule_end_date_time).format('YYYY-MM-DD'),
          schedule_start_time: item.is_expenses ? '' : moment(item.schedule_start_date_time).format('HH:mm'),
          schedule_end_time: item.is_expenses ? '' : moment(item.schedule_end_date_time).format('HH:mm'),
          amount: (item.amount_type == 'scheduled') ?  item.scheduled_amount : item.actual_amount
        })
    })
    setInvoiceDetailData(array || [])
    setModalValue(false)
  }
  const handleAllSelectChange = e => {
    if (!values.checkbox_invoice_data) {
      values.checkbox_invoice_data = [];
    }

    if (e.target.checked) {
      values.invoice_detail_data.map(triageData => {
        values.checkbox_invoice_data.push({
          ...triageData,
          checkbox_rowId: triageData.id_select,
        });
      });
    } else {
      // alerts.map((alertsData,index)=>{
      //   values.checkbox_invoice_data.map(chkdata=>{
      //     if(chkdata.checkbox_rowId == alertsData.id  ){
      //       values.checkbox_invoice_data.splice(index,1)
      //     }
      //   })
      // })
      values.checkbox_invoice_data = [];
    }
    setValues({...values});
  };

  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = id => {
      return values.checkbox_invoice_data.some(function (el) {
        return el.checkbox_rowId === id;
      });
    };

    if (values.checkbox_invoice_data && values.checkbox_invoice_data.length > 0) {
      values.invoice_detail_data.map(triageData => {
        if (!alertCheckBoxExists(triageData.id_select)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const handleCheck = alertsData => {
    let check = false;
    if (values.checkbox_invoice_data) {
      values.checkbox_invoice_data.map(data => {
        if (data.checkbox_rowId == alertsData.id_select) {
          check = true;
        }
      });
    }
    return check;
  };

  const handleSelectChange = (e, iData, selectedIndex) => {
    if (!values.checkbox_invoice_data) {
      values.checkbox_invoice_data = [];
    }

    if (e.target.checked) {
      values.checkbox_invoice_data.push({
        ...iData,
        checkbox_rowId: iData.id_select,
      });
    } else {
      values.checkbox_invoice_data.map((data, index) => {
        if (data.checkbox_rowId == iData.id_select) {
          values.checkbox_invoice_data.splice(index, 1);
        }
      });
    }
    setValues({...values});
  };

  console.log('values', values)


  return (
    <ModalContainer max_width={values.invoice_detail_data && values.invoice_detail_data.length ? "80%" : "43rem"}>
      <Toast ref={toast} position="top-right" />
      <ModalDynamic
        {...fullBind}
        max_width={values.invoice_detail_data && values.invoice_detail_data.length ? "80%" : "43rem"}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className='mx-3 my-1 '>
          <div className={'w-100 mb-2'} style={{display:"flex", gap: "1rem"}}>
              <DateSelector
                isRequired
                // errorMessage={dateMessage}
                labelTop="Start Date"
                // data-error={dateError}
                {...useInput('start_day', 'isRequired')}
              />
              <DateSelector
                isRequired
                // disabled={values.idSelected ? true : false}
                // errorMessage={dateMessage}
                labelTop="End Date"
                // data-error={dateError}
                {...useInput('end_day', 'isRequired')}
              />
              <PrimaryButtonForm
                minWidth="6rem"
                onClick={() => { getVisitDataForInvoice() }}
                disabled={false}>
                <span className={'mr-2'}>Search</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
          </div>

          {(values.invoice_detail_data && (values.invoice_detail_data.length > 0)) ?
            <Table
              headerColumns={headerColumns}
              headerPadding={false}
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}
              noEditOption
              fontSize="0.75rem"
            >
              <div>
                {values.invoice_detail_data && values.invoice_detail_data.map((list, index) => {
                  return (
                    <div key={index}>
                      <Row bgColor Shadow
                       zIndex={values?.invoice_detail_data?.length - index}
                      >
                        <ColsGrouper
                          className="d-flex">
                             <Col
                      max_width={EX_SMALL_COL4}
                      Shrink={false}
                      NoFlexGrow={true}
                      reducePadding
                      Capitalize
                      Center>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={handleCheck(list)}
                        id="flexCheckDefault"
                        onChange={e => {
                          handleSelectChange(e, list, index);
                        }}
                      />
                    </Col>
                         
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.invoicing_type
                          }
                          </Col>
                          {/* <Col
                            max_width={EX_SMALL_COL_TOGGLE}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                           {
                             <ToggleButton
                             label=""
                             disabled={false}
                             //  useInput={toggleHook}
                             id= {`is_${list.invoicing_type}`}
                             status={list[`is_${list.invoicing_type}_toggle`]}
                             onClick={(val)=>toggleSelect(val, `is_${list.invoicing_type}_toggle`, index)}
                             small
                           />
                           }
                          </Col> */}
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.service_category__name}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.start_date && moment(list.start_date).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.end_date && moment(list.end_date).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.schedule_start_date_time && moment(list.schedule_start_date_time).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.schedule_end_date_time && moment(list.schedule_end_date_time).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          {/* <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.schedule_travel_start_datetime && moment(list.schedule_travel_start_datetime).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.schedule_travel_end_datetime && moment(list.schedule_travel_end_datetime).format('YYYY-MM-DD HH:mm')}
                          </Col> */}
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.schedule_travel_miles}
                          </Col>
                          {/* <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {list.duration}
                          </Col> */}
                          <Col 
                           max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            <div className='w-100'>
                            <DropdownSearchable
                              disabled={false}
                              placeHolder={''}
                              options={[
                                {id: 'scheduled', name: 'Scheduled'},
                                {id: 'actual', name: 'Actual'}
                              ]}
                              selectedOption={list.amount_type}
                              onChange={value =>
                                dropDownHandleSelect(
                                  { amount_type: value ? value : null },
                                  'amount_type', index
                                )
                              }
                            />
                            </div>
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            className="sm-hidden"
                            overlap={true}
                            Center>
                            {(list.amount_type == 'scheduled') ?  list.scheduled_amount : list.actual_amount}
                          </Col>
                          {/* <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            {<IconWrapper
                              onClick={() => {
                                //   deleteClick(list.id);
                              }}
                            >
                              <RiDeleteBinLine />
                            </IconWrapper>}
                          </Col> */}
                        </ColsGrouper>
                      </Row>
                      <div className="mb-1" />
                    </div>
                  );
                })}
                <div className="mt-3">
                        <Pagination
                          totalPages={pages}
                          currentPage={currentPage}
                          values={values}
                          dropDownHandleSelect={dropDownHandleSelectPagination}
                          number={PAGE_SIZE_RENDER}
                          dataLength={values?.invoice_detail_data?.length}
                        />
                      </div>
              </div>

            </Table>
            :
            <>
             {isSearchClick &&
             <div style={{textAlign:'center', padding:'3rem'}}>
              No data found
            </div>}
            </>
            }
          {/* <div>
                   <PrimaryButtonForm
            className={' mt-2 mb-2'}
            bgcolor="BLUE"
            minWidth="3rem"
            onClick={() => addNewInvoice('invoice_detail_data')}>
            <span>{<BsFillPlusSquareFill />}</span>
          </PrimaryButtonForm>
                   </div> */}
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
           {(values.checkbox_invoice_data && (values.checkbox_invoice_data?.length > 0)) &&
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => {addSubmit()}}
                  disabled={!(values.checkbox_invoice_data && (values.checkbox_invoice_data?.length > 0))}>
                  <span className={'mr-2'}>Add</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>}
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default TableFormData;
