import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FlexGrid3Container, FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  HOUR,
  MINUTE
} from 'utils/constants/serviceVisits';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
const BulkEditMedication = ({
  fullBind,
  useInput,
  values,
  choices,
  editMode,
  handleBulkEdit,
  errorMessage,
  showError,data,
  dropDownHandleSelect = () => { }
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.instructions`);
  const newDoseTime = useInput(
    `medication.medication_dose_time`, 'isRequired');

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Edit Medication',
    });
  }, [fullBind]);
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className='pt-2 pl-3 pr-3 pb-3'>
          <FlexGrid3Container className={'w-100 mb-2'}>
            <FlexGrid3Item >
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication Type'}
                options={choices.medication_type || []}
                selectedOption={values.medication ? values.medication.medication_type : null}
                onChange={(value, label, obj) =>
                  dropDownHandleSelect({ medication_type: obj ? obj : null }, 'medication_type')
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item >
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Type'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item >
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication Assistance'}
                options={choices.medication_assistance || []}
                selectedOption={values.medication ? values.medication.medication_assistance : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_assistance: value ? value : null }, 'medication_assistance')
                }
              />
            </FlexGrid3Item>

          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-2'}>
            <FlexGrid3Item>
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication status'}
                options={choices.medication_status || []}
                selectedOption={values.medication ? values.medication.medication_status : null}
                {...useInput('medication.medication_status')}
                onChange={value =>
                  dropDownHandleSelect({ medication_status: value ? value : null }, 'medication_status')
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item>
              <DateSelector
                disabled={!editMode}
                labelTop="Actual medication start date"
                {...useInput('medication.actual_start_date')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item>
              <TimeSelector
                disabled={!editMode}
                // data-error={timeError[index]}
                // errorMessage={timeErrorMessage}
                //isRequired={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
                labelTop={'Actual medication dose time'}
                {...useInput(`medication.actual_medication_dose_time`)}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <TitleSection>Administration</TitleSection>
          <div className="d-flex mt-2">
            <div className="w-100 pr-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Reason'}
                options={choices.administration_reason || []}
                selectedOption={values.medication ? values.medication.administration_reason : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_reason: value ? value : null }, 'administration_reason')
                }
              />
            </div>
            <div className="w-100 pr-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Route'}
                options={choices.medication_route || []}
                selectedOption={values.medication ? values.medication.medication_route : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_route: value ? value : null }, 'medication_route')
                }
              />
            </div>
            <div className="w-100 pr-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Form'}
                options={choices.medication_form || []}
                selectedOption={values.medication ? values.medication.medication_form : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_form: value ? value : null }, 'medication_form')
                }
              />
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="w-100 pr-1">
              <div
                className={'pt-2 ml-md-1 ml-0 text-center'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Additional Instructions
              </div>
              <InputTextArea
                type="text"
                height={'3.4rem'}
                id="emp_desc"
                placeHolder={'Additional Instructions'}
                label={'Additional Instructions'}
                {...ins_hook}
              //   maxlength={5}
              />
            </div>
          </div>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ml-0 ml-md-3 mr-2'}>
                {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleBulkEdit}
                >
                  Save
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default BulkEditMedication;