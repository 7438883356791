import styled from 'styled-components';
import {PURPLE} from 'shared/styles/constants/colors';
import { MD } from'shared/styles/constants/resolutions';

export const ShiftTypeTab = styled.div`
  border-bottom: ${props => (props.selected ? `4px solid ${PURPLE}` : 0)};
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const RotaShiftContainer = styled.div`
  height: ${props=>props.height ? props.height :"72%"};
  opacity:${props=>props.loading ? '0.4' : "1"};
  pointer-events:${props=>props.loading?"none":"auto"};
`;

export const MessageSpan = styled.span`
  color: ${PURPLE};
  font-weight: normal;
`;

export const ToggleDiv = styled.div`
  margin-top: 1%;
  margin-bottom: 0.75%;
  display: flex;
  .toggle-label {
    margin-left: 0.5%;

   
`;

export const ButtonsContainerRota = styled.div`
.rotaView {
  margin-top: 0.75%;
  margin-bottom: 1.5%;
  
  .listView {
    color: ${props => (props.listView  ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props =>
      props.listView  ? `4px solid ${PURPLE}` : 0};
    background: transparent !important;
    font-weight: ${props =>
      props.listView  ? 'bold' : 'normal'};
  }

  .teamView {
    color: ${props => (props.rotaView === 'teamView' && !props.listView ? PURPLE : 'black')};
    border: 0;
    border-bottom: ${props =>props.rotaView === 'teamView' &&
   !props.listView? `4px solid ${PURPLE}` : 0};
    background: transparent !important;
    font-weight: ${props =>props.rotaView === 'teamView' &&
    !props.listView? 'bold' : 'normal'};
  }

  }

  @media (max-width: ${MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;