import React, { Fragment, useEffect, useRef, useState } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  ErrorMessage,
  TitleSection,
  FlexEqual,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import { pickFile } from 'utils/files';
import { useHistory } from 'react-router-dom';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { DELETE } from 'utils/constants/icons';
import { IconWrapper } from 'shared/styles/constants/tagsStyles';
import _ from "lodash"
import { getVisitDateWiseApi } from 'utils/api/EmployeeApi';
import InputTextArea from 'components/SharedComponents/InputTextArea';
const ModalExpense = ({
  useInput,
  values,
  choices,
  dropDownHandleSelect,
  setValues,
  showError,
  errorMessage,
  handleSubmit,
  textStatus,
  loadingSave,
  buttonIcon,
  toggleFileProof,
  setToggleFileProof,
  uploadedFile,
  setUploadedFile,
  selectedEmpId,
  idSelected,

  createPermissionEmployeeExpensess,
  updatePermissionEmployeeExpensess,

  readPermissionEmployeeExpenseDetails,
  createPermissionEmployeeExpenseDetails,
  deletePermissionEmployeeExpenseDetails,
  updatePermissionEmployeeExpenseDetails,

  readPermissionEmployeeExpenseAuthorization,
  createPermissionEmployeeExpenseAuthorization,
  deletePermissionEmployeeExpenseAuthorization,
  updatePermissionEmployeeExpenseAuthorization

}) => {
  const [amountValueDecimalPartLength, setAmountValueDecimalPartLength] = useState(0);

  const [amountWithoutdecimal, setAmountWithoutdecimal] = useState(false);
  const [uploadedDocumnetValue, setUploadedDocumnetValue] = useState();
  const [visitOptions, setVisitOptions] = useState([])

  const history = useHistory()
  const fileRef = useRef(null);
  const handleChange = async (event, key) => {
    const fileUploaded = event.target.files[0];
    const fileResult = await pickFile(event)
    const result = _.merge(values, { [key]: fileResult.file });
    setValues({ ...result });
    setUploadedFile(fileUploaded.name)
    setToggleFileProof(true)
  }

  const handleUpload = ref => {
    ref.current.click();
  };

  useEffect(() => {
    setUploadedDocumnetValue(values.expense_document);
  }, [values.expense_document])

  const deleteUploadedFile = () => {
    values.expense_document = '';
    setUploadedFile('No File Chosen');
    setToggleFileProof(false);
    setValues({ ...values })
  };

  useEffect(() => {
    amountdecimalcheck();
  }, [
    values.amount,
    values.pay_adjustment_type,
  ])


  useEffect(() => {
    if (values.pay_adjustment_type) {
      let ids = values.pay_adjustment_type;
      let checkName = choices && choices.pay_adjustment_type?.find(payname => payname.id == ids).name;
      if (checkName == "Deduction") {
        values.amount = - Math.abs(values.amount);
      } else if (checkName == "Addition") {
        values.amount = Math.abs(values.amount);
      }
    }
    setValues({ ...values })
  }, [
    choices.pay_adjustment_type,
    values.pay_adjustment_type,
  ])


  const amountdecimalcheck = () => {
    if (values.amount && values.amount.toString().includes('.')) {
      var amountValue = values.amount && values.amount.toString().split('.');
      if (amountValue && amountValue[1] && amountValue[1] !== undefined) {
        setAmountValueDecimalPartLength(amountValue[1].length);
        setAmountWithoutdecimal(false);
        if (amountValue[1].length > 2) {
          values.amount = amountValue[0] + "." + amountValue[1].slice(0, 2)
          setValues({ ...values })
          setAmountWithoutdecimal(false);
        }
      }
    } else {
      setAmountWithoutdecimal(true);
      setAmountValueDecimalPartLength(0);
    }

  }

  const combineVisitName = (item) => {
    let name = ''
    if (item.job_title) {
      name += item.job_title;
    }
    if (item.schedule_start_time && item.schedule_end_time) {
      name += " " + item.schedule_start_time + "-" + item.schedule_end_time
    }
    else {
      name += " " + item.start_date.slice(11, 16) + "-" + item.end_date.slice(11, 16)
    }
    return name
  }
  const visitDropdownOptions = async () => {
    const date = values?.pay_adjustment_date;
    const clientId = values?.client;

    if (clientId && date) {
      try {
        const res = await getVisitDateWiseApi(clientId, date);

        if (res.status === "True") {
          const options = res?.data?.map(item => ({
            id: item?.id,
            name: combineVisitName(item)
          }));
          setVisitOptions(options);
        } else {
          setVisitOptions([]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    visitDropdownOptions();
  }, [values.client]);

  const statusDropdownOptions = [
    { id: 'Approved', name: 'Approved' },
    { id: 'Unapproved', name: 'Unapproved' },
    { id: 'Rejected', name: 'Rejected' }
  ]

  let textInput = useInput('authorization_description')
  let payAdjustDateInput = useInput('pay_adjustment_date', 'isRequired')
  let payAdjustTypeInput = useInput('pay_adjustment_type', 'isRequired')
  let amountInput = useInput(`amount`, 'isRequired')
  let payAdjustDiscInput = useInput(`pay_adjustment_desc`)

  return (
    <>
      {/* {(readPermissionEmployeeExpenseDetails && readPermissionEmployeeExpenseAuthorization) ? (*/}
      <div className="mt-3">
        <FlexEqual>
          {readPermissionEmployeeExpenseDetails && (
            <>
              <TitleSection>Expenses Details</TitleSection>
              <div className="d-flex flex-column mt-2">
                <div className="d-flex mb-3">
                  <div className="w-100 mr-2">
                    <DateSelector
                      isRequired
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      labelTop="Date"
                      {...payAdjustDateInput}
                    />
                  </div>
                  <div className="w-100 mr-2">
                    <DropdownSearchable
                      placeHolder={'Select Client'}
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      options={choices?.client_listing?.map(client => {
                        return {
                          id: client.id,
                          name: client.full_name
                        }
                      })}
                      selectedOption={values &&
                        values?.client ?
                        values?.client
                        : null
                      }
                      onChange={value => {
                        dropDownHandleSelect({ client: value ? value : null });
                        visitDropdownOptions();
                      }}
                    />
                  </div>
                  <div className='w-100 mr-2'>
                    <DropdownSearchable
                      placeHolder={'Visit'}
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      options={visitOptions}
                      selectedOption={values &&
                        values.visit ?
                        values.visit
                        : null
                      }
                      onChange={value =>
                        dropDownHandleSelect(
                          { visit: value ? value : null }
                        )
                      }
                    />
                  </div>
                </div>
                <div className='d-flex mb-0'>
                  <div className="w-100 mr-2">
                    <DropdownSearchable
                      placeHolder={'Pay Adjustment'}
                      options={choices.pay_adjustment}
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      selectedOption={values &&
                        values.pay_adjustment ?
                        values.pay_adjustment
                        : null
                      }
                      onChange={value =>
                        dropDownHandleSelect(
                          { pay_adjustment: value ? value : null }
                        )
                      }
                    />
                  </div>
                  <div className='w-100 mr-2'>
                    <DropdownSearchable
                      required
                      placeHolder={'Pay Adjustment Type'}
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      {...payAdjustTypeInput}
                      options={choices.pay_adjustment_type}
                      selectedOption={values &&
                        values.pay_adjustment_type ?
                        values.pay_adjustment_type
                        : null
                      }
                      onChange={value =>
                        dropDownHandleSelect(
                          { pay_adjustment_type: value ? value : null }
                        )
                      }
                    />
                  </div>
                  <div className='w-100 mr-2'>
                    <InputLabelTop
                      required
                      type="number"
                      pattern="\d+"
                      id="nok_no"
                      label={'Amount'}
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      // onKeyPress={handleInputChange}
                      // onChange={ (e) => handleInputChange(e)}
                      {...amountInput}
                      maxlength={25}
                    />
                    <>
                      <small>
                        {((values?.amount) && ((amountValueDecimalPartLength !== 2) || (amountWithoutdecimal))) ? <p style={{ color: 'red' }}>Enter the amount with two decimals places.</p> : null}
                      </small>
                    </>
                  </div>
                </div>


                <div className='w-100'>
                  <FlexGrid3Item className="pr-1 mb-2">
                    <small className="pr-1 " style={{ color: 'black' }}>
                      Pay Adjustment Description
                    </small>
                    <InputTextArea
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      type="text"
                      height={'4rem'}
                      id="nok_no"
                      label={'Pay Adjustment Description'}
                      {...payAdjustDiscInput}
                      maxlength={8}
                    />
                    {(values?.pay_adjustment_desc && values?.pay_adjustment_desc?.length > 500) ?
                      <small className="pr-1 " style={{ color: 'red' }}>{errorMessage}</small> :
                      null}
                  </FlexGrid3Item>
                  {/* <InputLabelTop
                      disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                      type="text"
                      id="nok_no"
                      label={'Pay Adjustment Description'}
                      {...payAdjustDiscInput}

                    /> */}
                </div>
                <div className="d-flex mb-2">
                  <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Upload Document</span>
                  <input type="file"
                    disabled={idSelected ? !updatePermissionEmployeeExpenseDetails : !createPermissionEmployeeExpenseDetails}
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={(e) => handleChange(e, 'expense_document')}

                  />
                  <PrimaryButtonForm
                    minWidth="2rem"
                    onClick={() => handleUpload(fileRef)}
                    className={'ml-2 mr-2'}
                  >
                    <span className={''}>{'Choose File'}</span>
                  </PrimaryButtonForm>
                  <a href={values.expense_document && values.expense_document} style={{
                    pointerEvents: uploadedFile === 'No File Chosen' ? "none"
                      : toggleFileProof ? "none" : "", color: "#696969", fontStyle: "italic"
                  }}
                    target="_blank" rel="noopener noreferrer"
                    classname={'ml-1 mt-2'}
                  >
                    {uploadedFile}
                  </a>
                  {uploadedDocumnetValue ?
                    <IconWrapper
                      color={SOFT_RED}
                      onClick={() => deleteUploadedFile()}
                    >
                      {DELETE}
                    </IconWrapper>
                    : null}
                </div>

              </div>
            </>
          )}

          {readPermissionEmployeeExpenseAuthorization ?
            <div className='mt-4'>
              <TitleSection>Authorization Details</TitleSection>
              <div className="d-flex mt-2">
                <div className='w-100 mb-2 mr-2'>
                  <small className="pr-1 " style={{ color: 'black' }}>
                    Status
                  </small>
                  <DropdownSearchable
                    placeHolder={''}
                    disabled={idSelected ? !updatePermissionEmployeeExpenseAuthorization : !createPermissionEmployeeExpenseAuthorization}
                    options={statusDropdownOptions}
                    selectedOption={values &&
                      values.status ?
                      values.status
                      : null
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        { status: value ? value : null }
                      )
                    }
                  />
                </div>
                <div className='w-100'>
                  <FlexGrid3Item className="pr-1 mb-2">
                    <small className="pr-1 " style={{ color: 'black' }}>
                      Comment
                    </small>
                    <InputTextArea
                      disabled={idSelected ? !updatePermissionEmployeeExpenseAuthorization : !createPermissionEmployeeExpenseAuthorization}
                      required
                      type="text"
                      height={'4rem'}
                      id="Comment"
                      label={'Comment'}
                      //onChange={(val)=>handleOnChangeDescription(val)}
                      {...textInput}
                      maxlength={8}
                    />
                  </FlexGrid3Item>
                </div>
              </div>
            </div> : null}
        </FlexEqual>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            <>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </>
          </div>

          <div
            className={
              'd-flex justify-content-center justify-content-md-end'
            }>

            <div className={'ml-0 ml-md-3 mr-2'}>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'mr-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/team')
                }}>
                <span className={'mr-2'}>{'Cancel'}</span>
              </PrimaryButtonForm>
              {
                (<PrimaryButtonForm
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  disabled={idSelected ?
                    ((updatePermissionEmployeeExpenseDetails || updatePermissionEmployeeExpenseAuthorization) ? false : true)
                    : ((createPermissionEmployeeExpenseDetails || createPermissionEmployeeExpenseAuthorization) ? false : true)}
                  onClick={handleSubmit}>
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* ) : (<NoPermissionContainer>
          You don't have permission to {idSelected ? 'update' : 'create'} the Expense Details & Authorization Details.
        </NoPermissionContainer>
        )} */}
    </>
  );

}

export default ModalExpense;