import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import TimeSelector from 'components/SharedComponents/TimeSelector';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {SetActualBulkTime, getCopySch} from "utils/api/SchedulerApi"
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import moment from 'moment'
import DateSelector from 'components/SharedComponents/DateSelector';
const CopySchedular = ({
    fullBind,
    checkedRows,
    searchScheduler,
    setCopySch,
    shiftType,
    sch_start,
    sch_end,
    toast,
    branch,
    setLoadingCopySch
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);

    const from_hook = useInput('date_from');
    const from_hook_daily = useInput('date_from_daily');
    const to_hook = useInput('date_to');

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Copy Scheduler',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);

      useEffect(()=>{
        if(values.date_from){
            values.date_to = moment(values.date_from,"YYYY-MM-DD").add(6, 'days').format("YYYY-MM-DD");
        setValues({...values});
        }
    },[values.date_from])

    useEffect(()=>{
        if(values.date_to){
            values.date_from = moment(values.date_to).subtract(6, 'days').format("YYYY-MM-DD");
        setValues({...values});
        }
    },[values.date_to])

    const dropDownHandleSelect = (item) => {
      setValues({ ...values, ...item });
    };
    
      const handleSubmit=()=>{
        if(errors.length>0 || loadingSave){
          setCheckRequires(true)
          setErrorMessage(InvalidOrEmptyValues);
          return
        }
        setLoadingCopySch(true)
        setCopySch(false)
        setErrorMessage('');
        let body = {};
        if(shiftType === 'resourceTimelineDay'){
           body = {
            start_date: moment(sch_start).format('YYYY-MM-DD'),
            end_date: moment(sch_end).format('YYYY-MM-DD'),
            date_from: moment(values.date_from_daily).format('YYYY-MM-DD'),
            date_to: moment(values.date_from_daily).add(1, 'days').format('YYYY-MM-DD'),
            branch_ids: (values.branch_copy && (values.branch_copy.length > 0)) ? values.branch_copy.map((item)=>(item.value)).join(',') : ''
           }
          }else{
            body = {
              start_date: moment(sch_start).format('YYYY-MM-DD'),
              end_date: moment(sch_end).format('YYYY-MM-DD'),
              date_from: moment(values.date_from).format('YYYY-MM-DD'),
              date_to: moment(values.date_to).format('YYYY-MM-DD'),
              branch_ids: values.branch_copy
             }
          }
        
        getCopySch(body)
        .then(res=>{
          setLoadingCopySch(false)
         if(res.status){
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          searchScheduler()
         
         }else{
          toast.current.show({ severity: 'error', summary: '', detail: res.message });
         }
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          
        })

      }


    return (
        <ModalContainer max_width={'40%'}>
            <ModalDynamic
        {...addFullBind}
        max_width={'40%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
          <div className={'pl-3 pr-3 pb-3'}>
         <div className='d-flex w-100'>
         {shiftType === 'resourceTimelineDay' ?
         <>
          <div className='mr-2'>
          <DateSelector
            typeFilter={true}
            labelTop="Date from"
            {...from_hook_daily}
          />
        </div>
        <div className='w-100'>
           <DropdownSearchable
                 isMulti={true}
                 required={true}
                 placeHolder={'Branch'}
                 options={branch ? branch : []}
                 selectedOptionsProps={
                   values
                     ? values.branch_copy
                       ? values.branch_copy
                       : null
                     : null
                 }
                 onChange={value =>
                  dropDownHandleSelect({ branch_copy: value })
                 }
                />
        </div>
         </>
        :
          <>
           <div className='mr-2'>
              <DateSelector
                typeFilter={true}
                labelTop="Date from"
                {...from_hook}
              />
            </div>
            <div className='mr-2'>
              <DateSelector
                typeFilter={true}
                labelTop="Date from"
                {...to_hook}
              />
            </div>
            <div className='w-100'>
           <DropdownSearchable
                 isMulti={true}
                 required={true}
                 placeHolder={'Branch'}
                 options={branch ? branch : []}
                 selectedOptionsProps={
                   values
                     ? values.branch_copy
                       ? values.branch_copy
                       : null
                     : null
                 }
                 onChange={value =>
                  dropDownHandleSelect({ branch_copy: value })
                 }
                />
        </div>
            </>
            }
         </div>
            <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ml-0 ml-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
        </ModalDynamic>
        </ModalContainer>
    );
}

export default CopySchedular;
