import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DateSelector from 'components/SharedComponents/DateSelector'
import DurationInput from 'components/SharedComponents/DurationInput';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {FLOPPY} from 'utils/constants/icons';
import {setUpdateVisit,setUpdateOptaplannerVisit,} from 'utils/api/SchedulerApi';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  MessageSpan,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  ProgressBarContainer,
 
} from '../../../SharedComponents/TimelineCalendar/styles';
import PropTypes from 'prop-types';
import CustomProgressBar from 'components/SharedComponents/CustomProgressBar';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import ServiceAdhocEmployee from '../../../SharedComponents/FormSections/Service/ServiceAdhocEmployee';
import moment from 'moment';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import {FloatingMobileButton} from 'shared/styles/buttons';

const ServiceContractDetails = ({
  readOnly,
  fullBind,
  dropDownHandleVisit,
  dropDownHandleEmployee,
  values,
  setValues,
  useInput,
  nameSection,
  choices,
  assignedEmployee,
  employees,
  handleAddVisitModal,
  searchScheduler,
  handleCancelVisit,
  handleDeleteVisit,
  onShowAlert,
  clientdata
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [showQuestion, setShowQuestion] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [pic, setPic] = useState('');
  const [dob, setDOB] = useState( );
// const [initials, setInitials] = useState();
const [intialsOfFirstName,setIntialsOfFirstName]=useState( )
const [intialsOfLastName,setIntialsOfLastName]=useState( )
const [visitStart,setVisitStart]=useState( )
const[visitEnd,setVisitEnd] = useState()
const[showServiceContractDetails,setShowServiceContractDetails]=useState(false)
const currentVisitStatus=()=>{
  if(values[nameSection].evv_data.actual_end_time){
    return 4
  }
  else if(values[nameSection].evv_data.actual_start_time){
    return 3
  }
  else if(values[nameSection].evv_data.employee){
    return 2
  }
  else if(values[nameSection].evv_data.employee){
}
else{
  return 1
}
}
  useEffect(() => {

    setAddFullBind({
      
      ...addFullBind,
      title:
      
      <div style={{display:"flex"}}>
       
      <p style={{width:"120px"}}>Visit View</p>
      {!values.is_visit_cancelled &&
      <div className={'mr-4'}>
       <ProgressBarContainer>
       <CustomProgressBar
         currentStep={currentVisitStatus}
         items={['Unassigned', 'Assigned', 'Inprogress', 'Completed']}
         // loadingOptaPlanner={loadingOptaPlanner}
         // optaplannerStatus={optaplannerStatus}
         // runningDate={runningDate}
         // actualDate={values.start_date}
       />
      </ProgressBarContainer>
      </div>
      }
     {values.is_visit_cancelled && 
     <p style={{color:"#6E6BFA"}}>Cancelled</p>
     }
     </div>,
     
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);
  
  useEffect(() => {
    if (
      values[nameSection].duration &&
      typeof values[nameSection].duration === 'string'
    ) {
      const res = values[nameSection].duration
        .split(':')
        .reduce((acc, time) => 60 * acc + +time);

      values[nameSection].duration = res;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection].duration]);

  useEffect(() => {
    if (
      values[nameSection].evv_data.actual_visit_start_time &&
      values[nameSection].evv_data.actual_visit_end_time
    ) {
      let start_time = moment(
        values[nameSection].evv_data.actual_visit_start_time,
        'HH:mm:ss'
      );
      let end_time = moment(
        values[nameSection].evv_data.actual_visit_end_time,
        'HH:mm:ss'
      );


      if(end_time>start_time){
         
        values[nameSection].evv_data.actual_visit_duration = end_time.diff(
          start_time,
          'seconds'
        );
      }else{
        let add_date=end_time.add(1,'days')
        values[nameSection].evv_data.actual_visit_duration = add_date.diff(
          start_time,
          'seconds'
        );
      }
    } else {
      values[nameSection].evv_data.actual_visit_duration = 0;
    }
    setValues({...values});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection].evv_data.actual_visit_start_time, values[nameSection].evv_data.actual_visit_end_time]);

  useEffect(() => {
    if (!values.employee || (values.employee && !values.employee.id)) {
      setShowQuestion(false);
    } else {
      setErrorMessage('');
    }
  }, [values]);

  useEffect(()=>{
    data()
  },[ clientdata.full_name ])

const data=async()=>{
  if(clientdata.full_name != undefined ){
  const  clientName = await( clientdata.full_name);
  // const initials = clientName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join(" ").toUpperCase();
  const  arrName = clientName.split(" ");
const  initialOfFirstName =  clientName.charAt(0).toUpperCase();
const initialOfLastName =  arrName[arrName.length - 1].charAt(0).toUpperCase();
setIntialsOfFirstName(initialOfFirstName)
    setIntialsOfLastName(initialOfLastName)
  
}
}
              
  useEffect(()=>{    
      const visitStartTime = values[nameSection].start_date
              ? moment(values[nameSection].start_date).format('h:mm A')
              : 'Unset';   
 const visitEndTime =  values[nameSection].end_date
              ? moment( values[nameSection].end_date).format('h:mm A')
              : 'Unset'; 

          setVisitStart(visitStartTime)
          setVisitEnd(visitEndTime)
        },[values[nameSection]])

  return (
    <>   
          <FlexGrid3Container className={'mt-1 mb-4'}>
			 <FlexGrid3Item
              className="pr-1 pl-0 pb-2 pb-md-0"
              onClick={() => {}}>
              <TimeSelector
                disabled={readOnly}
                isRequired
                labelTop={'Preferred start time'}    
                {...useInput(
                  `${[nameSection]}.preferred_start_time`,
                  'isRequired'
                )}
                      //added below code  for time in 24hrs format 
                      timeFormat={"HH:mm"}
                      dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
			 <FlexGrid3Item
              className="pl-1 pr-0 pr-md-1 pb-2 pb-md-0"
              onClick={() => {}}>
              <TimeSelector
                disabled={readOnly}
                isRequired
                labelTop={'Preferred end time'}
                {...useInput(
                  `${[nameSection]}.preferred_end_time`,
                  'isRequired'
                )}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
			<FlexGrid3Item className="pr-1 pl-0 pl-md-1 pb-1">
              <DurationInput
                disabled={true}
                label_top={'Preferred Duration'}
                {...useInput(`${[nameSection]}.duration`)}
              />
            </FlexGrid3Item>
			 <FlexGrid3Item className="pr-0 pr-md-1 pl-1 pl-md-0 mb-2 pt-md-1">
              <TimeSelector
                disabled={readOnly}
                labelTop={'Earliest start time'}
                {...useInput(`${[nameSection]}.earliest_start_time`)}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
			<FlexGrid3Item className="pl-0 pl-md-1 pr-1 pb-2 pb-md-0 pt-md-1">
              <TimeSelector
                disabled={readOnly}
                labelTop={'Latest start time'}
                {...useInput(`${[nameSection]}.latest_start_time`)}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
              />
            </FlexGrid3Item>
	
			   </FlexGrid3Container>
     </>

  );
};


export default ServiceContractDetails;
