import React,{useState,useEffect,Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import Image from 'components/SharedComponents/Image';
  import {FiUpload} from 'react-icons/fi';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import {ERROR, SAVE, SAVED, InvalidOrEmptyValues,} from 'utils/constants/messages';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
  import {KeyPress} from 'utils/constants/regularExpressions';
  import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {UploadInput} from 'shared/styles/buttons';
import {pickFile} from 'utils/files';
  import {
    ContainerResponsive,
    FlexGrid3Container,
    FlexGrid3Item,
    TitleSection,
    UploadPhotoContainer,
    UserImgContainer,
    UserImgWrapper,
  } from 'shared/styles/constants/tagsStyles';
  import {addImpContact,getImpContactById} from 'utils/api/SettingsApi'
  import _ from 'lodash'
const AddModal = ({
    fullBind,
    idSelected,
    setTitle,
    setCheckRequires,
    useInput,
    values,
    setValues,
    choices,
    isValid,
    errors,
    loadImpContacts
}) => {
    const [loadingModal, setLoadingModal] = useState(true);
    const [contactPic, setContactPhoto] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [address,setAddress]=useState("")
    useEffect(() => {
        if (idSelected) {
          setTitle('Edit Contact');
          getImportantContacts()
        } else {
          setTitle('Add New Contact');
          setLoadingModal(false);
        //   if(newAddFunder){
        //     values.addFunder={}
        //     setValues({...values})
        //   }
        }
        setCheckRequires(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      useEffect(()=>{
        let limitperpage={};
        values.limitperpage =PAGE_SIZE_RENDER;
        setValues({...values})
        },[PAGE_SIZE_RENDER])
    const handleChange=(address)=>{
      setAddress(address)
    }
    const dropDownHandleSelect=(item,nameItem)=>{
          values[nameItem]=item[nameItem]
          setValues({...values})
        
      }

            
const getImportantContacts=async ()=>{
  let response=await getImpContactById(idSelected)
  if(response){
    setValues({...response})
    setLoadingModal(false)
  }
  }
      const handleSelect=async (value)=>{
        handleChange(value)
      const result=await geocodeByAddress(value)
      console.log(result,"41544")
      const coordinates=await getLatLng(result[0])
      
      if(result){
       let tempAddress=_.cloneDeep(result[0].address_components)
    
      values.latitude=coordinates.lat
      values.longitude=coordinates.lng
       result[0].address_components.forEach((element,index) => {
         if(element.types.find(value=>value=='postal_code')){
           values.zip_code=element.long_name
          //  tempAddress.splice(index,1)
         }
        else if(element.types.find(value=>value=='country')){
          values.country=element.long_name
          // tempAddress.splice(index,1)
        }
        else if(element.types.find(value=>value=='administrative_area_level_1')){
          values.state=element.long_name
          // tempAddress.splice(index,1)
        }
        else if(element.types.find(value=>value=='administrative_area_level_2' || value=='locality')){
          values.city=element.long_name
          // tempAddress.splice(index,1)
        }
      
       });
       let lineAddress=""
       tempAddress.forEach(ele=>{
         if(ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
         || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
         ){
          lineAddress=lineAddress
         }else{
          lineAddress+=ele.long_name+" "
         }
        
       })
       values.line_1=lineAddress
       setValues({...values})
      }
      }
      const handleSubmit=()=>{
        if(loadingSave){
          return
        }
    
        if (!isValid && errors.length > 0) {
          setErrorMessage(InvalidOrEmptyValues);
          setCheckRequires(true);
          return;
        }
        setLoadingSave(true);
        let body={...values}
      if(contactPic){
        body.photo=contactPic
      }else{
        delete body.photo
      }
        addImpContact(body)
        .then(res=>{
          if(res.id){
            setValues({
              ...values,
              id:res.id
            })
          }
          onSuccess()
        })
        .catch(onError);
    }
    const onSuccess = response => {
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      setErrorMessage('');
      loadImpContacts();
    };
    const onError = () => {
      setLoadingSave(false);
      setButtonIcon('');
     
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
    };


      const handlePickFile = async (evt, section) => {
        const result = await pickFile(evt);
    
        switch (section) {
          case 'nok':
            setContactPhoto(result.file);
            break;
          default:
        }
      };
console.log(values,"445777")
    return (
        <ModalContainer max_width={'75%'}>
              <ModalDynamic
        {...fullBind}
        max_width={'75%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
      <div className="pl-3 pr-3 pb-3 pt-0">
        <div className="d-flex flex-column mt-2">
        <ContainerResponsive className="d-flex">
          <div className="mr-md-2 mb-2">
            <UserImgWrapper>
              <UserImgContainer>
                {/* TODO: review if values.photo is correct, add name and lastname correct */}
                <Image
                  src={
                    contactPic
                      ? URL.createObjectURL(contactPic)
                      : values
                      ? values.photo
                      : ''
                  }
                  name={`${
                    values 
                      ? values.full_name
                      : ''
                  }`}
                  alt={``}
                  fontSize={'1.5rem'}
                />
              </UserImgContainer>

              <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                  onInput={e => {
                    handlePickFile(e, 'nok');
                  }}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  type="file"
                  accept={'.png,.jpg,.jpeg'}
                  id="photo"
                  name="photo"
                  placeholder={'Photo'}
                />
                <FiUpload />
                <label htmlFor="photo">Upload</label>
              </UploadPhotoContainer>
            </UserImgWrapper>
          </div>

          <div className="w-100 d-flex flex-column">
            <div className="w-100 d-flex">
              <div className="w-100 mr-2">
                <DropdownSearchable
                  placeHolder={'Title'}
                  options={choices.title}
                  selectedOption={
                    values ? values.title : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                       {title: value ? value : null},
                       'title'
                    )
                  }
                />
              </div>
              <div className="w-100 mr-2">
                <InputLabelTop
                required
                  type="text"
                  id="nok_full_name"
                  label={'Full Name'}
                  {...useInput(`full_name`,'isRequired')}
                  maxlength={255}
                />
              </div>
            </div>

            <div className="d-flex mb-2">
           
              <div className="w-100">
              <DropdownSearchable
                  placeHolder={'Contact Type'}
                  options={choices.care_contact_type}
                  required
                  {...useInput(`contact_type`, 'isRequired')}
                  selectedOption={
                    values ? values.contact_type : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                     {contact_type: value ? value : null},
                     'contact_type'
                    )
                  }
                />
              </div>
            </div>
          </div>
        </ContainerResponsive>
        <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    >
                      {
                        ({getInputProps, suggestions, getSuggestionItemProps, loading})=>(
                          <div>
                             <InputLabelTop
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
                          <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#D3D3D3', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>  
                          </div>
                        )

                        
                      }
                    </PlacesAutocomplete>
        <div className="d-flex">
          <div className="w-100 mr-2">
            <InputLabelTop
              type="text"
              id="nok_line_1"
              label={'Address Line 1'}
              {...useInput(`line_1`)}
              maxlength={255}
            />
          </div>

          <div className="w-100">
            <InputLabelTop
              type="text"
              id="nok_line_2"
              label={'Address Line 2'}
              {...useInput(`line_2`)}
              maxlength={255}
            />
          </div>
        </div>

        <FlexGrid3Container className="mb-2">
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              type="text"
              id="nok_town_city"
              label={'Town / City'}
              {...useInput(`city`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              type="text"
              id="nok_state"
              label={'County / State'}
              {...useInput(`state`)}
              maxlength={100}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
            <InputLabelTop
              type="text"
              id="nok_postcode_zipcode"
              label={' Postcode / Zipcode'}
              {...useInput(`zip_code`)}
              maxlength={25}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              type="text"
              id="nok_country"
              label={'Country'}
              {...useInput(`country`)}
              maxlength={254}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
                        <InputLabelTop
                        disabled={true}
                          type="text"
                          id="latitude"
                          label={'Latitude'}
                          onKeyPress={KeyPress.DECIMAL}
                          {...useInput('latitude', 'isDecimal')}
                          maxlength={25}
                        />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
                        <InputLabelTop
                          disabled={true}
                          type="text"
                          id="longitude"
                          label={'Longitude'}
                          onKeyPress={KeyPress.DECIMAL}
                          {...useInput('longitude', 'isDecimal')}
                          maxlength={25}
                        />
                     </FlexGrid3Item>
          <FlexGrid3Item className="pr-1">
            <InputLabelTop
              type="text"
              id="nok_mobile"
              label={'Mobile No.'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(`mobile`, 'isMobilePhone')}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-1 pr-0 pr-md-1">
            <InputLabelTop
              type="email"
              id="nok_email"
              label={'Email'}
              {...useInput(`email`, 'isEmail')}
              maxlength={254}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pl-0 pl-md-1 pr-1 pr-md-0">
          <DropdownSearchable
                  placeHolder={'Contact Status'}
                  options={choices.contact_status}
                  selectedOption={
                    values ? values.contact_status : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                     {contact_status: value ? value : null},
                     'contact_status'
                    )
                  }
                />
          </FlexGrid3Item>
          
          <FlexGrid3Item className="pl-1">
          <InputLabelTop
              type="text"
              id="nok_mobile"
              label={'Additional Mobile No.'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(`additional_telephone`, 'isMobilePhone')}
              maxlength={25}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>
        <div style={{marginTop:"-7px"}} className={'d-flex'}>
        <div className={"w-100 mr-2"}>
          <InputLabelTop
              type="text"
              id="nok_contact"
              label={'Contact Notes'}
              {...useInput(`person_notes`)}
              maxlength={25}
            />
          </div>
  
        </div>

      </div>
      </div>
      <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ml-0 ml-md-3 mr-3 mb-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                >
                  <span className={'mr-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
      </ModalDynamic>
        </ModalContainer>
    );
}

export default AddModal;
