import React,{Fragment} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
    IconWrapper,
  } from 'components/SharedComponents/Table/styles';
  import {RiDeleteBinLine} from 'react-icons/ri';
  import AdjustEmployee from './AdjustEmployee'
  import {AiOutlineSwap} from 'react-icons/ai';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
const RegularEmployeeEdit = ({
    values,
    setValues,
    index,
    dropDownHandleRegEmpDet,
    employees,
    useInput,
    deleteEmployeeDetails,
    employeeRequired,
    visitDetails,
    dayOptions,
    setSingleRun,
    idSelected
}) => {
  const addNewAdjustDetails=()=>{
    if(!visitDetails.adjustment_details){
      values.employee_day_detail[index].adjustment_details=[]
    }
    values.employee_day_detail[index].adjustment_details=[...values.employee_day_detail[index].adjustment_details,{}]
    setValues({...values})
  }
  
  const dropDownHandleAdjust=(item,nameItem,adjustIndex)=>{
    values.employee_day_detail[index].adjustment_details[adjustIndex][nameItem]=item[nameItem]
    setValues({...values})
  }
  
  const deleteEmployeeAdjust=(innerIndex)=>{
    values.employee_day_detail[index].adjustment_details.splice(innerIndex,1)
    setValues({...values})
  }
  const handleSingleSwap=()=>{
    values.visitDetails_id = visitDetails.id;
    setValues({...values});
    setSingleRun(true);
  }

  const switchShiftField = props => {
    if (visitDetails.employee) {
     return( 
      <div className={'w-100'}>
      <DropdownSearchable
           required
           {...props}
            placeHolder={'Shift Name'}
            options={visitDetails.shiftArray ? visitDetails.shiftArray : []}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].shift
              ?values.employee_day_detail[index].shift:null}
            onChange={value =>
              dropDownHandleRegEmpDet({shift: value ? value : null}, 'shift',index)
            }
          />
      </div>
     )
    } else {
      return(
        <Fragment />
      )
    }
  };
    return (
      <>
        <div  className={`d-flex mt-2 mb-3 mr-4`}>
          <div className={'w-50 mr-2'}>
          <DropdownSearchable
                required
                {...useInput(
                 `employee_day_detail[${index}].repeat_on`,
                 'isRequired'
               )}
                placeHolder={'Select Day'}
                options={dayOptions}
                    selectedOption={
                   values && values.employee_day_detail &&
                   values.employee_day_detail[index] &&
                   values.employee_day_detail[index].repeat_on
                   ?values.employee_day_detail[index].repeat_on:null
                }
                onChange={value =>
                    dropDownHandleRegEmpDet(
                    {repeat_on: value ? value : null},
                    'repeat_on',index
                  )
                }
              />
          </div>
        <div className={'w-100 mr-2'}>
        <DropdownSearchable
              placeHolder={'Requirement'}
              options={employeeRequired}
              selectedOption={values && values.employee_day_detail &&
                values.employee_day_detail[index] &&
                values.employee_day_detail[index].requirement
                ?values.employee_day_detail[index].requirement:null}
              onChange={value =>
                dropDownHandleRegEmpDet({requirement: value ? value : null}, 'requirement',index)
              }
            />
        </div>
        <div className={'w-50  mr-2'}>
        <DropdownSearchable
             required
             {...useInput(
              `employee_day_detail[${index}].employee`,
              'isRequired'
            )}
              placeHolder={'Employee Name'}
              options={employees}
              selectedOption={values && values.employee_day_detail &&
                values.employee_day_detail[index] &&
                values.employee_day_detail[index].employee
                ?values.employee_day_detail[index].employee:null}
              onChange={value =>
                dropDownHandleRegEmpDet({employee: value ? value : null}, 'employee',index)
              }
            />
        </div>
        {switchShiftField( {...useInput(`employee_day_detail[${index}].shift`,'isRequired')})}
           
             {/* {index!==0 && */}
             <IconWrapper
              className={'ml-2'}
             onClick={() => {
               deleteEmployeeDetails(index);
              }}
              >
            <RiDeleteBinLine 
            color="red"
            cursor='pointer'
            width='6rem'
            />
        </IconWrapper>
        {visitDetails.id && <IconWrapper
              className={'ml-2'}
             onClick={() => {
              handleSingleSwap()
              }}
              >
            <AiOutlineSwap 
            color="purple"
            cursor='pointer'
            width='6rem'
            />
        </IconWrapper>}
            {/* } */}
            </div>
             <div>
             {visitDetails && visitDetails.adjustment_details && visitDetails.adjustment_details.map((adjustDet,adjustIndex)=>{
             return (
                  <AdjustEmployee
                   index={adjustIndex}
                   outerIndex={index}
                   values={values}
                   useInput={useInput}
                   employees={employees}
                   deleteEmployeeAdjust={deleteEmployeeAdjust}
                   nameSection={`employee_day_detail[${index}].adjustment_details[${adjustIndex}]`}
                   dropDownHandleAdjust={dropDownHandleAdjust}
                  />
             )
                 
           })}
            <PrimaryButtonForm
                     bgcolor="BLUE"
                     minWidth="0.5rem"
                     className={'mb-2 ml-2'}
                     onClick={addNewAdjustDetails}
                     >
                     <span>{'Add Adjustment'}</span>
                   </PrimaryButtonForm>
                   </div>
                   </>
    );
}

export default RegularEmployeeEdit;
