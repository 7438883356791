import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { KeyPress } from 'utils/constants/regularExpressions';
import { TIMES } from 'utils/constants/icons';
import {
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import { useEffect } from 'react';
import { useState } from 'react';
const RateDetails = ({
  detailRate,
  contextChoices,
  useInput,
  dropDownHandleSelect,
  index,
  indexRate,
  deleteRateDetails,
  travel_cat = ''
}) => {

  const details_rate_time_unit =  detailRate && detailRate.details_rate_time_unit ? detailRate.details_rate_time_unit : null
  detailRate.durations= detailRate && detailRate.durations ? details_rate_time_unit === "Day" ? Math.round(detailRate.durations) : detailRate.durations : null
  return (
    <div className={'d-flex'}>
      <CloseButtonWrapper
        onClick={() => {
          deleteRateDetails(index);
        }}>
        {TIMES}
      </CloseButtonWrapper>
      <div className={'w-100 mr-2'}>
        <DropdownSearchable
          required
          placeHolder={'Pay Time Unit'}
          options={(travel_cat === 'Mileage Rate') ?
            [
              { id: 'hour', name: 'Hour' },
              { id: 'minute', name: 'Minute' },
              { id: 'distance', name: 'Distance' }
            ] :
            [
              { id: 'hour', name: 'Hour' },
              { id: 'minute', name: 'Minute' },
              // { id: 'week', name: 'Week' },
              // { id: '4-Week', name: '4-Week' },
              { id: 'Day', name: 'Day'}
              
            ]
          }
          {...useInput(
            `rateValue[${indexRate}].details_rate[${index}].details_rate_time_unit`,
            'isRequired'
          )}
          selectedOption={
            detailRate
              ? detailRate.details_rate_time_unit
                ? detailRate.details_rate_time_unit
                : null
              : null
          }
          onChange={(value) => {
            if(value == "Day"){
              detailRate.durations = 1
            }
            dropDownHandleSelect(
              { details_rate_time_unit: value ? value : null },
              'details_rate_time_unit', index
            )
          }
        }
        />
      </div>
      <div className='w-100 mr-2'>
        <InputLabelTop
          disabled={details_rate_time_unit === "Day"}
          required
          label={'Time Period'}
          type="number"
          id={`time_period`}
          // value={details_rate_time_unit === "Day" ? 1 : durations}
          onKeyPress={KeyPress.DECIMAL}
          {...useInput(`rateValue[${indexRate}].details_rate[${index}].durations`, 'isRequired')}
          maxlength={5}
        />
      </div>
      <div className='w-100'>
        <InputLabelTop
          //   disabled={readOnly}
          // required
          label={'Rate Charge'}
          type="number"
          id={`Rate Charge`}
          onKeyPress={KeyPress.DECIMAL}
          {...useInput(`rateValue[${indexRate}].details_rate[${index}].charges`)}
          maxlength={5}
        />
      </div>
    </div>
  );
}

export default RateDetails;
