import {
  LARGE_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
  EX_SMALL_COL4,
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Medication Name',
    name: 'name__name',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Medication Type',
    name: 'medication_type__name',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: LARGE_COL,
  },

  {
    label: 'Start Date',
    name: 'start_date',
    status: 2,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Expiry Date',
    name: 'expiry_date',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Last Update Time',
    name: 'updated_at',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
];
