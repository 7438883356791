export const headers = {
  'Content-Type': 'application/json',
};

export const methods = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const unauthorized_status_code = 401;
export const bad_gateway_status_code = 502;
