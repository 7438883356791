import {
  LARGE_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  SMALL_COL
} from 'shared/styles/constants/columns';

export const headerColumns = [
  {
    label: 'Group Name',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: '',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: '',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: '',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: '',
    name: 'reference_no',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
 
];
