import React, {
  useState,
  useEffect,
  useContext,
  useModal,
  useRef,
  Fragment,
} from 'react';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {setAbsenceApprovel} from 'utils/api/EmployeeApi';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {useForm} from 'hooks/FormHook';
import {Toast} from 'primereact/toast';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {AppContext} from 'context';

import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';

const new_absence = 'Absence Approval';

const AbsenceApproval = ({
  fullBind,
  resetSelectedIds = ( )=> {},
  setTitle,
  editDatas,
  setDataCheck,
  setModalValue,
  selectedClientId,
  setAbsenceModalValue,
  addData,
  idSelected,
  absenceListData,search,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const [loadingModal, setLoadingModal] = useState(false);

  const toast = useRef();
  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
  } = useContext(AppContext);
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({});
useEffect(()=>{

  setTitle('Absence Approval')
})
 
  const handleDropDownSelect = item => {
    setValues({...values, ...item});
  };
  const handleSubmit = async () => {
    if (!isValid && errors.length > 0) {
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review'
      );
      setCheckRequires(true);
      return;
    }
    else{
    let body = {
      id: idSelected,
      absence_authorisation_comments: values.absence_authorisation_comments,
      absence_status: values.absence_status,
    };
    let response = await setAbsenceApprovel(body);
    if (response.status === true) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      
      setTimeout(() => {
        setAbsenceModalValue(false);
      }, 1000);
      search()
      resetSelectedIds()
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
    }
  }
  };
  return (
    <ModalContainer max_width={'55%'}>
      <ModalDynamic {...fullBind()} minWidthTitle="8.5rem" loading={loadingModal}>
        <Toast ref={toast} position="top-right" />
        <div className="d-flex flex-column mt-2">
          <div className="d-flex mb-2">
            <div
              className="w-100 mr-2"
              style={{marginLeft: '10px', marginRight: '10px'}}>
              <DropdownSearchable
                required
                {...useInput('absence_status', 'isRequired')}
                placeHolder="Absence Status"
                options={contextChoices.absence_status}
                selectedOption={
                  values.absence_status ? values.absence_status : null
                }
                onChange={value =>
                  handleDropDownSelect(
                    {
                      absence_status: value ? value : null,
                    },
                    'absence_status',

                    value
                  )
                }
              />
            </div>
            <div
              className="w-100 mr-2"
              style={{marginLeft: '10px', marginRight: '10px'}}>
              <InputTextArea
                type="text"
                height={'2.5rem'}
                id="Comments"
                placeholder='Absence Authorisation Comments'
                {...useInput('absence_authorisation_comments')}
              />
            </div>
          </div>
        </div>

        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div
            className={'mb-3'}
            style={{display: 'flex', justifyContent: 'right'}}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ml-0 ml-md-3 mr-2'}>
              <PrimaryButtonForm
                minWidth="6rem"
                style={{marginBottom: '5px'}}
                onClick={handleSubmit}>
                Save
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AbsenceApproval;
