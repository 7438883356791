import Image from 'components/SharedComponents/Image';
import Pagination from 'components/SharedComponents/Pagination';
import { Col, ColsGrouper, IconWrapper, Row, UserImg, UserImgContainer } from 'components/SharedComponents/Table/styles';
import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { FloatingMobileButton, PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';
import { LARGE_COL, MEDIUM_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { DataContainer, DropdownTypesContainer, Header, HeaderLgBreak, SearchAddContainer, SearchContainer, SearchIcon, Title } from 'shared/styles/constants/tagsStyles'
import ComponentDynamic from '../ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useState } from 'react';
import { useForm } from 'hooks/FormHook';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteLocation, getLocations } from 'utils/api/LocationApi';
import { useEffect } from 'react';
import { getPageToSearchParam } from 'shared/methods';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useContext } from 'react';
import { AppContext, BranchItemsContext } from 'context';
import {
    LIST_OF_LOCATIONS,
    SELF_WEB_CREATE_PERMISSION,
    SELF_WEB_DELETE_PERMISSION,
    SELF_WEB_READ_PERMISSION,
    SELF_WEB_UPDATE_PERMISSION,
    WEB_CREATE_PERMISSION,
    WEB_DELETE_PERMISSION,
    WEB_PERMISSION,
    WEB_READ_PERMISSION,
    WEB_UPDATE_PERMISSION,
} from 'utils/constants/permissions';
import { NoDataFoundMessage, NoPermissionContainer } from './AddLocationModal/styles';
import { Fragment } from 'react';
import { list_of_location_choices } from 'utils/choiceConstant';
import { getClient as localStorageClient, } from 'utils/localStorage/token';
import { Fade } from 'react-reveal';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { GrSearch } from 'react-icons/gr';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import NoSelectAllDropdownFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilterCustom';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
export const headerColumns = [
    {
        label: 'Full Name',
        name: 'full_name',
        status: 1,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Status',
        name: 'status__name',
        status: 0,
        className: 'sm-hidden',
        max_width: SMALL_COL,
    },
    {
        label: 'Branch Name',
        name: 'branch__name',
        status: 0,
        className: '',
        max_width: MEDIUM_COL,
    },
    {
        label: 'Address',
        name: 'address__name',
        status: 0,
        className: 'md-hidden sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Group Area', //run routes
        name: '',
        status: 0,
        className: 'md-hidden sm-hidden',
        max_width: MEDIUM_COL,
    },

];

function Location() {
    const history = useHistory()
    const toast = useRef(null)
    const userClient = localStorageClient();
    // Custom Hooks here
    const { values, setValues } = useForm({ limitperpage: PAGE_SIZE_RENDER, ordering: 'full_name' })
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);

    // Context
    const {
        contextPermissions,
        contextIsAdmin,
        contextIsSuperAdmin,
        contextChoices: choices,
        handleGetChoices,
        contextRelativesClient
    } = useContext(AppContext)
    const { branchData } = useContext(BranchItemsContext);

    const [loadingModal, setloadingModal] = useState(true)
    const [locations, setLocations] = useState([])
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState(null)
    const [numberOfLocations, setNumberOfLocations] = useState(0)
    //Permission states
    const [createPermissions, setCreatePermission] = useState(false);
    const [readPermissions, setReadPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [updatePermissions, setUpdatePermission] = useState(false);
    const [updateSelfPermission, setUpdateSelfPermission] = useState(false);
    const [showSearch, setShowSearch] = useState(false)
    const [branchTypeFilter, setBranchTypeFilter] = useState([])
    const [statusTypeFilter, setStatusTypeFilter] = useState([])
    const [groupAreaTypeFilter, setGroupAreaTypeFilter] = useState([])

    const [status, setStatus] = useState([])
    const [runRoutes, setRunRoutes] = useState([])
    const [branch, setBranch] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        handleGetChoices(list_of_location_choices)
    }, [])
    useEffect(() => {
        const roles = contextPermissions;
        let rolePermissions = {};
        if (contextIsAdmin || contextIsSuperAdmin) {
            setUpdatePermission(true)
            setCreatePermission(true)
            setReadPermission(true)
            setDeletePermission(true)
            setUpdateSelfPermission(true)
        } else {
            if (roles.length > 0) {
                roles.forEach(item => {
                    if (item.section.name === LIST_OF_LOCATIONS) {
                        rolePermissions[LIST_OF_LOCATIONS] = {};
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION] = {};
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][WEB_READ_PERMISSION] = item?.[WEB_READ_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] = item?.[SELF_WEB_READ_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] = item?.[SELF_WEB_CREATE_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION] = item?.[SELF_WEB_DELETE_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION];
                        rolePermissions[LIST_OF_LOCATIONS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] = item?.[SELF_WEB_UPDATE_PERMISSION];
                    }
                })
                setCreatePermission(
                    rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[WEB_CREATE_PERMISSION] || rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
                );
                setReadPermission(
                    rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[WEB_READ_PERMISSION] || rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
                );
                setDeletePermission(
                    rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[WEB_DELETE_PERMISSION] || rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION]
                );
                setUpdatePermission(
                    rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[WEB_UPDATE_PERMISSION] || rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
                );
                setUpdateSelfPermission(
                    rolePermissions[LIST_OF_LOCATIONS]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [contextPermissions, contextIsAdmin, contextIsSuperAdmin, choices])

    const handleAddLocation = () => {
        history.push(`/location/add/location-details`)
    }
    const handleEditLocation = (id) => {
        history.push({
            pathname: `/location/edit/${id}/location-details`,
            state: { id: id }
        })
    }

    const handleDeleteEmployee = (id) => {
        setModalDeleteValue(true)
        setSelectedId(id)
    }

    const getLocationList = async () => {
        const pageToSearch = getPageToSearchParam(
            history.location.search,
            setCurrentPage
        );
        try {
            const res = await getLocations({ ...values, ...branchData, ...pageToSearch }, values.search)
            setLocations(res?.results || [])
            setPages(Math.ceil(res.count / values.limitperpage));
            setNumberOfLocations(res.count || 0)
            setloadingModal(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getLocationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search, values.limitperpage, branchData, values.ordering])


    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const handleDeleteLocation = async () => {
        try {
            const res = await deleteLocation(selectedId)
            setModalDeleteValue(false)
            if (res.status) {
                getLocationList()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res.message}`,
                })
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Something went wrong!`,
            });
        }
    }

    const findNameFromChoices = (id, key) => {
        if (!id) return '';
        let find = choices?.[key]?.find(item => item.id === id)
        if (find) {
            return find.name;
        }
    }

    const formatAddress = address => {
        // const name = address.name ? `${address.name}, ` : '';
        const line_1 = address?.line_1 ? `${address?.line_1}, ` : '';
        const city = address?.city ? `${address?.city}, ` : '';
        const zip_code = address?.zip_code ? `${address?.zip_code}` : '';

        return `${line_1}${city}${zip_code}`;
    };

    const getRunRoutes = (runRoute) => {
        let runroutes = runRoute && runRoute.map(item => {
            if (choices && choices?.run_routes &&
                choices?.run_routes.find(choice => choice.id === item.run_routes) &&
                choices?.run_routes.find(choice => choice.id === item.run_routes).name) {
                return choices?.run_routes.find(choice => choice.id === item.run_routes).name
            }

        })
        let routes = runroutes && runroutes.join(",")
        return routes
    }

    const dropDownHandleSelect = item => {

        const urlParams = queryString.parse(history.location.search);
        urlParams[PAGINATION_PARAM] = 1;
        const stringified = queryString.stringify(urlParams);
        history.push({
            pathname: "/location",
            search: stringified,
        });

        setValues({ ...values, ...item });
    };

    const filterBranchType = (item) => {
        setBranchTypeFilter([...item])
    }

    const filterStatusType = (item) => {
        setStatusTypeFilter([...item])
    }
    const filterGroupAreaType = (item) => {
        setGroupAreaTypeFilter([...item])
    }

    

    useEffect(() => {
        setStatus(choices.client_status && choices.client_status.map(y => ({ id: y && y.id, name: y && y.name })))
        setRunRoutes(choices.run_routes && choices.run_routes.map(y => ({ id: y && y.id, name: y && y.name })))
        setBranch(choices.branch && choices.branch.map(y => ({ id: y && y.id, name: y && y.name })))
    }, [choices])

    const debounce = (fn, d) => {
        let timer;
        return function (...args) {
            let context = this
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, d)
        }
    }


    const hitSearch = (value) => {
        values.search = value
        getLocationList();
        setValues({ ...values })
    }


    const debounceOnchange = useRef(debounce(hitSearch, 300));

    const keyPressed = e => {
        setSearchKeyword(e.target.value)
        debounceOnchange.current(e.target.value)
    };

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    return (
        <div>
            <Toast ref={toast} />
            <Title>List of Locations</Title>
            <HeaderLgBreak>
                <div className="d-flex " style={{ flexWrap: 'wrap' }}>
                    <DropdownTypesContainer className="mr-2 mb-1">
                        <NoSelectAllDropdownFilter
                            items={branch || []}
                            setItemsSelected={items => {
                                dropDownHandleSelect({ branch: items });
                            }}
                            itemsSelected={branchTypeFilter}
                            setSelectedFilter={filterBranchType}
                            placeHolder={'Branch'}
                            isObject
                            allOption
                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="mr-2 mb-1">
                        <NoSelectAllDropdownFilter
                            items={status || []}
                            setItemsSelected={items => {
                                dropDownHandleSelect({ status: items });
                            }}
                            itemsSelected={statusTypeFilter}
                            setSelectedFilter={filterStatusType}
                            placeHolder={'Status'}
                            isObject
                            allOption

                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="mr-2 mb-1">
                        <NoSelectAllDropdownFilter
                            items={runRoutes || []}
                            setItemsSelected={items => {
                                dropDownHandleSelect({ group_area: items });
                            }}
                            itemsSelected={groupAreaTypeFilter}
                            setSelectedFilter={filterGroupAreaType}
                            placeHolder={'Group Area'}
                            isObject
                            allOption

                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="mr-2 mb-1">
                        <PrimaryButton
                            type="button"
                            onClick={() => getLocationList()}
                            style={{ marginLeft: '4px', marginBottom: "2px" }}>
                            <span className={'ml-2 mr-2 font-weight-bold ml-3'}>Search</span>
                        </PrimaryButton>
                    </DropdownTypesContainer>
                </div>

                <SearchAddContainer className="d-flex align-items-center">
                    <SearchIcon
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}>
                        <GrSearch />
                    </SearchIcon>

                    <SearchContainer show={showSearch}>
                        <Fade collapse when={showSearch}>
                            <InputLabelTop
                                no_internal_margin="true"
                                type="text"
                                id="search"
                                label={'Search'}
                                onChange={(e) => {
                                    keyPressed(e)
                                }
                                }
                                value={searchKeyword}
                            />
                        </Fade>
                    </SearchContainer>

                    {createPermissions &&
                        <div className='d-flex w-100 justify-content-end'>
                            <FloatingMobileButton onClick={() => handleAddLocation(null)} >
                                <FaPlus />
                                <label>NEW LOCATION</label>
                            </FloatingMobileButton>
                        </div>}

                </SearchAddContainer>
            </HeaderLgBreak>

            <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-start">
                <div className="data-container">
                    <div className="mr-2">
                        <p>{'Locations: ' + numberOfLocations || 0}</p>
                    </div>
                </div>
            </DataContainer>
            <div style={{ marginTop: "1rem" }}>
                <ComponentDynamic loading={loadingModal} >
                    <>
                        {readPermissions ? (
                            <Fragment>
                                {
                                    locations.length > 0 ? (
                                        <>
                                            <Table
                                                headerColumns={headerColumns}
                                                callBackQuerySort={setQuerySort}
                                                // headerPadding={false}
                                                noEditOption={!updatePermissions}
                                            // noNeed={false}
                                            >
                                                <div>
                                                    {locations?.map((location, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Row bgColor>
                                                                    <ColsGrouper
                                                                        className="d-flex"
                                                                        onClick={() => {
                                                                            handleEditLocation(location.id)
                                                                        }}>
                                                                        <Col
                                                                            Initials
                                                                            max_width={LARGE_COL}
                                                                            Shrink
                                                                            ShadowMobile
                                                                            Capitalize
                                                                            Center
                                                                            overlap={true}
                                                                        >
                                                                            {/* <img src={purpleElipse} alt="elipse" /> */}
                                                                            <UserImgContainer>
                                                                                <UserImg>
                                                                                    <Image
                                                                                        src={location.photo ? location.photo : ''}
                                                                                        name={location.first_name || location.full_name}
                                                                                        lastName={location.middle_name}
                                                                                        alt={'location-img'}
                                                                                        fontSize={'0.9rem'}
                                                                                    />
                                                                                </UserImg>
                                                                                <label className="align-self-center mb-0">
                                                                                    {location.full_name}
                                                                                </label>
                                                                            </UserImgContainer>
                                                                        </Col>
                                                                        <Col
                                                                            className="sm-hidden"
                                                                            max_width={SMALL_COL}
                                                                            Capitalize
                                                                            Center
                                                                            overlap={true}
                                                                            Color={findNameFromChoices(location.status, 'client_status')}
                                                                        >
                                                                            {findNameFromChoices(location.status, 'client_status')}
                                                                        </Col>
                                                                        <Col
                                                                            className="sm-hidden"
                                                                            max_width={MEDIUM_COL}
                                                                            Center
                                                                            overlap={true}>
                                                                            {location.branch_name}
                                                                        </Col>
                                                                        <Col
                                                                            className="md-hidden"
                                                                            Capitalize
                                                                            Center
                                                                            max_width={LARGE_COL}
                                                                            overlap={true}>
                                                                            {formatAddress(location.address)}
                                                                        </Col>
                                                                        <Col
                                                                            className="md-hidden"
                                                                            Capitalize
                                                                            Center
                                                                            max_width={MEDIUM_COL}
                                                                            overlap={true}>
                                                                            {getRunRoutes(location.location_run_routes)}
                                                                        </Col>
                                                                        {updatePermissions && (
                                                                            <Col
                                                                                // style={{ maxWidth: "51px" }} 
                                                                                className="sm-hidden"
                                                                                Center
                                                                                Icon
                                                                                Purple>
                                                                                <IconWrapper
                                                                                    onClick={() => {
                                                                                        handleEditLocation(location.id)
                                                                                    }}>
                                                                                    <MdEdit />
                                                                                </IconWrapper>
                                                                            </Col>
                                                                        )}
                                                                    </ColsGrouper>

                                                                    {deletePermission && (
                                                                        <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                                                            <IconWrapper
                                                                                onClick={() => {
                                                                                    handleDeleteEmployee(location.id);
                                                                                }}>
                                                                                <RiDeleteBinLine />
                                                                            </IconWrapper>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                                <div className="mb-1" />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Table>
                                            <div className="mt-3">
                                                <Pagination
                                                    totalPages={pages}
                                                    currentPage={currentPage}
                                                    values={values}
                                                    dropDownHandleSelect={dropDownHandleSelectPagination}
                                                    number={PAGE_SIZE_RENDER}
                                                    dataLength={locations.length}
                                                    limitperpage={values.limitperpage}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        // <div className='w-100 d-flex justify-content-center'>
                                        //     <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                                        // </div>
                                        <NoDataFoundMessage>No data found</NoDataFoundMessage>
                                    )
                                }
                            </Fragment>

                        ) : (
                            <NoPermissionContainer>
                                You don't have permission to read the information.
                            </NoPermissionContainer>
                        )}
                    </>
                </ComponentDynamic>
            </div>
            <ModalDecision
                type="delete"
                title="Warning"
                body={'Are you sure you want to delete this location? \n DELETING the location will remove ALL records from database.'}
                onOk={handleDeleteLocation}
                onCancel={() => {
                    setSelectedId('');
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
        </div>
    )
}

export default Location