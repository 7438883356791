import React, { useState } from 'react';
import { ButtonWeek } from 'components/SharedComponents/FormSections/Service/Styles';
import { PURPLE } from 'shared/styles/constants/colors';
import ReactHtmlParser from 'react-html-parser';
import { useEffect } from 'react';

function Card({ isChecked, days, elm,setIsShowMore,isShowMore, el, hideExportBtn }) {
  const [showMessage, setShowMessage] = useState()
  // const [isShowMore, setIsShowMore] = useState(false)

  const handleToggle = () => {
    setIsShowMore(prev => !prev)
  }

  useEffect(() => {
    setShowMessage(
      isShowMore ?
        ReactHtmlParser(elm?.message) :
        ReactHtmlParser(elm?.message)[0])
  }, [isShowMore, elm])

  return (
    <div className="mt-4 rounded" style={{ border: '1px solid #4c2ee1' }}>
      <div className="header p-2">
        <span className="title-text m-s-1">{elm.careTask}</span>
      </div>
      <div className="p-2 m-s-2">
        <div className="d-flex w-100">
          <div className="w-60">
            <div className="cart-title  ps-3">{elm.taskType}</div>
            {elm.supportLevel && (
              <div className="sub-text supprt-text">
                {elm.supportLevel}
              </div>
            )}
        <div className="custom-element">{elm.careCategory}</div>

          </div>
          <div className="w-40 d-flex sx-col justify-content-end">
            <div className="d-flex row align-items-top pe-2">
              {days.map(el => {
                return (
                  <ButtonWeek
                    style={{ marginLeft: "0.5rem" }}
                    key={el}
                    add_background={isChecked(elm.repeatOn, el)}
                    disabled={true}>
                    <div>
                      <label style={{ cursor: "default" }}>{el.toUpperCase()}</label>
                    </div>
                  </ButtonWeek>
                );
              })}
            </div>
          </div>
        </div>
        {elm.message && (
          <>
            <div className="sub-text message w-100 mt-1"
              style={{
                maxHeight: !isShowMore ? "100px" : "",
                overflow: !isShowMore ? "hidden" : ""
              }}>
              {showMessage}
            </div>
            {elm?.message?.length > 200 &&
              <div className='d-flex justify-content-end' style={{ color: PURPLE, cursor: "pointer"}}
                onClick={() => handleToggle()}>
                {hideExportBtn ? "" : isShowMore ? " ...less" : " ...more"}
              </div>}</>
        )}
        {/* <div style={{height: "10px"}}></div> */}
      </div>
      <div></div>
    </div>
  );
}

export default Card;
