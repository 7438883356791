import React, { useState, useEffect, useContext } from 'react';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import {
  Row,
  Col,

} from 'components/SharedComponents/Table/styles';
import { headerColumns, headerColumnsRates, headerColumnsSelector, headerColumnsSelectorByDefault } from './TableHeaders/PayrollSummaryHeaders';
import queryString from 'query-string';
import { PrimaryButton } from 'shared/styles/buttons';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import DateSelector from 'components/SharedComponents/DateSelector';
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { AppContext, BranchItemsContext } from "context"
import { useForm } from 'hooks/FormHook';
import { getPayrollSummary, paySummaryExport } from 'utils/api/finance';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';

import { billing_payrollsummary_choices } from 'utils/choiceConstant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const PayrollSummary = (props) => {
  const [summaryData, setSummaryData] = useState([])
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeStatusFilter, setEmployeeStatusFilter] = useState([])
  const [visitStatusFilter, setVisitStatusFilter] = useState([])
  const [serviceCategoryFilter,setServiceCategoryFilter]=useState([])
  const [mileageAmount, setMileageAmount] = useState(0);
  const [travelAmount, setTravelAmount] = useState(0);
  const [travelMiles, setTravelMiles] = useState(0);
  const [travelTime, setTravelTime] = useState(0);
  const [visitAmount, setVisitAmount] = useState(0);
  const [visitDuration, setVisitDuration] = useState(0);
  const [schAmount, setSchAmount] = useState(0);
  const [schDuration, setSchDuration] = useState(0);
  const [actualGrossAmount, setActualGrossAmount] = useState(0);
  const [schGrossAmount, setSchGrossAmount] = useState(0);
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])
  const [payPatternFilter, setPayPatternFilter] = useState([])
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const { values, setValues, useInput, errors, isValid, setCheckRequires } = useForm({limitperpage: PAGE_SIZE_RENDER});
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalExport, setLoadingModalExport] = useState(false);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employees, setEmployees] = useState([]);

  const headerSelectedData = [...headerColumns,...headerColumnsRates].map((val)=>(val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState([...headerColumns,...headerColumnsRates].filter((val)=>val.columnSelected));

  const { contextChoices: choices, handleGetChoices } = useContext(AppContext)
  const { branchData } = useContext(BranchItemsContext);
  const history = useHistory();


  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
    values.limitperpage,
    props.location.search,
    values.ordering
  ]);

  useEffect(()=>{
    const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/finance/payroll/summary',
        search: stringified,
      });
  },[])

  useEffect(() => {
    if (choices.employee_listing) {
      setEmployees(choices && choices.employee_listing && choices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name }
      }));
    }
   
  }, [choices])



  useEffect(() => {
    handleGetChoices(billing_payrollsummary_choices)
  }, [])

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };
  console.log('branch', branchData)
  const handleClick = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    if (!values.date_from || !values.date_to) {
      return
    }
    else {
      if(!loadingModal){
        setLoadingModal(true)
      }
      getPayrollSummary({ ...values, ...pageToSearch, ...branchData })
        .then(res => {
          if (res) {
            setLoadingModal(false);
          }
          setSummaryData(res.results)
          setSchAmount(((res.total_all_sch_amount || 0) + (res.absence_pay_amount || 0) + (res.schedule_amount || 0) + (res.schedule_oncall_pay_amount || 0) + (res.schedule_office_pay_amount || 0) + (res.expenses_pay_amount || 0))?.toFixed(2) || 0)
          setSchDuration(res.total_all_sch_duration ? ((res.total_all_sch_duration + res.absence_pay_duration + res.schedule_duration + res.schedule_oncall_duration + res.schedule_office_duration)/3600)?.toFixed(2) : 0)
          setActualGrossAmount(res.total_all_gross_act_amount?.toFixed(2) || 0)
          setSchGrossAmount(res.total_all_gross_sch_amount?.toFixed(2) || 0)
          setMileageAmount(res.total_all_mileage_amount?.toFixed(2) || 0)
          setTravelAmount(res.total_all_travel_amount?.toFixed(2) || 0)
          setTravelMiles(res.total_all_travel_miles?.toFixed(2) || 0)
          setTravelTime(res.total_all_travel_time)
          setVisitAmount(res.total_all_visit_amount?.toFixed(2) || 0)
          setVisitDuration(res.total_all_visit_duration ? (res.total_all_sch_duration/3600)?.toFixed(2) : 0)
          // setSchAmount(res.total_all_sch_amount?.toFixed(2) || 0)
          // setSchDuration(res.total_all_sch_duration ? (res.total_all_sch_duration/3600)?.toFixed(2) : 0)
          // setActualGrossAmount(res.total_all_gross_act_amount?.toFixed(2) || 0)
          // setSchGrossAmount(res.total_all_gross_sch_amount?.toFixed(2) || 0)
          // setMileageAmount(res.total_all_mileage_amount?.toFixed(2) || 0)
          // setTravelAmount(res.total_all_travel_amount?.toFixed(2) || 0)
          // setTravelMiles(res.total_all_travel_miles?.toFixed(2) || 0)
          // setTravelTime(res.total_all_travel_time)
          // setVisitAmount(res.total_all_visit_amount?.toFixed(2) || 0)
          // setVisitDuration(res.total_all_visit_duration ? (res.total_all_sch_duration/3600)?.toFixed(2) : 0)
          setPages(Math.ceil(res.count / values.limitperpage));
        })
    }
  }
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const handleExport = () => {
    setLoadingModalExport(true)
    const pageToSearch = getPageToSearchParam(props.location.search);
    paySummaryExport({ ...values, ...pageToSearch, ...branchData  }, {column_selector: runTableOptionData}).then(response => {
      setLoadingModalExport(false)
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `pay_summary_${values.date_from}_${values.date_to}`;
        a.click();
      })
    }
    )

  }
  const dropDownHandleSelect = (item) => {
    setValues({ ...values, ...item })
  }
  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };
  console.log('loading', loadingModal)
  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ml-1 mr-3 mr-md-0 row d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'}  style={{ flexWrap: 'wrap' }}>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__id__in: items });
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={(items) => setEmployeeFilter([...items])}
                    placeHolder={'Employee name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.employee_status ? choices.employee_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_status: items });
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={(items) => setEmployeeStatusFilter([...items])}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.visit_status ? choices.visit_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit_status: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={(items) => setVisitStatusFilter([...items])}
                    placeHolder={'Visit Status'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                <NoSelectAllDropdownFilter
                    items={choices.service_category? choices.service_category : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({visit__service_category__id__in: items});
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={(items)=>setServiceCategoryFilter([...items])}
                    placeHolder={'Service Category'}
                    isObject
                    allOption
            
                  />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit__client_service_visit__client_service__service_funders__category__id__in: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                    placeHolder={'Funder Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ml-1 mr-1 mb-2">
                  <NoSelectAllDropdownFilter
                    items={choices.Billing_patterns ? choices.Billing_patterns : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ pay_pattern: items });
                    }}
                    itemsSelected={payPatternFilter}
                    setSelectedFilter={(items) => setPayPatternFilter([...items])}
                    placeHolder={'Pay Pattern'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <PrimaryButtonForm className="ml-1 mr-1 mb-2" onClick={handleClick} disabled={loadingModalExport || loadingModal}>
                 <div className='d-flex'>
                 <span className={'ml-2 mr-2 font-weight-bold'}>Search</span>
                  {/* {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )} */}
                 </div>
                </PrimaryButtonForm>
                {summaryData.length > 0 &&
                  <PrimaryButtonForm type="button"
                  className="ml-1 mr-1 mb-2"
                    onClick={handleExport} disabled={loadingModalExport || loadingModal}>
                    <span className={'ml-2 mr-2 font-weight-bold'}>Export</span>
                  {!loadingModalExport ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          )}
                  </PrimaryButtonForm>
                }
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      {
        summaryData.length > 0 &&
        <>
        <DataContainer className=" ml-2 mt-2 mb-3 d-flex justify-content-end">
         <DropdownTypesContainer className="ml-1 mr-1">
            <NoSelectAllDropdownColumnSelector
              items={headerColumns ? [...headerColumns,...headerColumnsRates].filter((item)=>!item.removeCusCol) : []}
              setItemsSelected={items => {
                dropDownHandleSelectTable({ tableDropdown: items });
              }}
              itemsSelected={dropdownArraySelected}
              setSelectedFilter={(item) => setDropdownArraySelected([...item])}
              placeHolder={'Column'}
              isObject
              allOption

            />
          </DropdownTypesContainer>

            <div className="data-container">
              {/* <div className="mr-2">
                <p>{'Gross Act Amt: ' + actualGrossAmount}</p>
              </div>
              <div className="mr-2">
                <p>{'Gross Sch Amt: ' + schGrossAmount}</p>
              </div> */}
              <div className="mr-2">
                <p>{'Sch Amt: ' + schAmount}</p>
              </div>
              <div className="mr-2">
                <p>{'Sch Duration: ' + schDuration}</p>
              </div>
              <div className="mr-2">
                <p>{'Travel Amt: ' + travelAmount} </p>
              </div>
              <div className="mr-2">
                <p>{'Travel Miles: ' + travelMiles} </p>
              </div>
              <div className="mr-2">
                <p>{'Travel Time: ' + travelTime} </p>
              </div>
              <div className="mr-2">
                <p>{'Visit Pay: ' + visitAmount}</p>
              </div>
              <div className="mr-2">
                <p>{'Visit Duration: ' + visitDuration}</p>
              </div>
              <p>{'Mileage Amt: ' + mileageAmount} </p>
            </div>
          </DataContainer>
          <ComponentDynamic loading={loadingModal} >
          <div style={{overflowX: 'scroll', width:'100%'}}>
            <div style={{width:'max-content', minWidth:'-webkit-fill-available'}}>
          <Table
            headerColumns={headerColumns}
            headerPadding={true}
            noEditOption={true}
            noNeed={false}
            values={runTableOptionData}
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}>
            {summaryData.map((sum, index) => {
              return (
                <Row bgColor Shadow>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("employee_name")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    {sum.employee__full_name}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("branch_name")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    {sum.employee__job_detail__branch__name}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("pay_pattern")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    {sum.pay_pattern}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_visit_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.schedule_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.schedule_duration_hr ? sum.schedule_duration_hr : 0}</div>
                    </div>
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_visit_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.actual_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.actual_duration_hr ? sum.actual_duration_hr : 0}</div>
                    </div>

                  </Col>
                   <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_travel_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.schedule_travel_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.schedule_travel_time ? sum.schedule_travel_time : ""}</div>
                    </div>
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_travel_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.actual_travel_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.actual_travel_time ? sum.actual_travel_time : ""}</div>
                    </div>
                  </Col>
<Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_travel_mileage_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.schedule_travel_mileage_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.schedule_travel_miles}</div>
                    </div>
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_travel_mileage_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                  >
                    <div>
                      <div style={{ fontWeight: "bold" }}>{sum.actual_travel_mileage_amount}</div>
                      <div style={{ fontWeight: "lighter" }}>{sum.actual_travel_miles}</div>
                    </div>
                  </Col>
                  
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("total_schedule_gross_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_gross_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("total_actual_gross_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_gross_amount}
                  </Col>
                  {/* <Col
                  className={!(runTableOptionData && runTableOptionData.includes("total_gross_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.gross_amount}
                  </Col> */}
                    <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_travel_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_travel_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_travel_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_travel_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_travel_miles_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_travel_miles_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_office_travel_miles")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_office_travel_miles}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_travel_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_travel_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_travel_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_travel_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_travel_miles_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_travel_miles_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_office_travel_miles")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_office_travel_miles}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("absence_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.absence_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("absence_pay_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.absence_pay_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_oncall_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_oncall_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("schedule_oncall_pay_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.schedule_oncall_pay_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_oncall_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_oncall_pay_amount}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("actual_oncall_pay_duration")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.actual_oncall_pay_duration}
                  </Col>
                  <Col
                  className={!(runTableOptionData && runTableOptionData.includes("expenses_pay_amount")) && 'd-none'}
                    max_width={LARGE_COL}
                  >
                    {sum.expenses_pay_amount}
                  </Col>
                </Row>
              )
            })}
            <div className="mt-3">
              <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={summaryData.length} />
            </div>
          </Table>
          </div>
            </div>
          </ComponentDynamic>
        </>
      }
    </div>
  );
}

export default PayrollSummary;
