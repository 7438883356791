import FormBuilderMainPage from 'components/DashboardComponents/FormBuilder/firstPage';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
  ContainerTabStyled,
  LinkTabStyled,
  LinkTab,
  Title,
} from 'shared/styles/constants/tagsStyles';
import { AppContext, BranchItemsContext } from 'context';

import {
  SETTINGS,
  SETTINGS_CALENDAR,
  PUBLIC_HOLIDAYS,
  WD_WE,
  DAY_NIGHT_OVER,
  DURATION,
  CALENDAR_DATA,
  PAGE_404,
  SETTINGS_FORM_BUILDER,
  SETTINGS_CHOICES,
  SETTINGS_CATEGORY,
  SETTINGS_ASSESSMENT,
} from 'utils/constants/routes';
import Choices from './Choices';
import SettingsFormBuilders from 'components/DashboardComponents/FormBuilder/showFormModal/SettingFormBuilders';
import {
  ASSESSMENT,
  FORM_BUILDER_CATEGORY_PERMISSION,
  FORM_BUILDER_CHOICES_PERMISSION,
} from 'utils/constants/permissions';
//import FormBuilderCatagoryPage from './DynamicCatagoryBuilder/firstPage';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import Assessment from './Assessnent';
const TAB_CHOICES_URL = SETTINGS + SETTINGS_FORM_BUILDER + SETTINGS_CHOICES;
const TAB_CATAGORY_URL = SETTINGS + SETTINGS_FORM_BUILDER + SETTINGS_CATEGORY;
const TAB_ASSESSMENT_URL = SETTINGS + SETTINGS_FORM_BUILDER + SETTINGS_ASSESSMENT;
const TAB_PUBLIC_HOLIDAYS_URL =
  SETTINGS + SETTINGS_FORM_BUILDER + PUBLIC_HOLIDAYS;
const TAB_WDWES_URL = SETTINGS + SETTINGS_CALENDAR + WD_WE;
const TAB_DAYNIGHTOVER_URL = SETTINGS + SETTINGS_CALENDAR + DAY_NIGHT_OVER;
const TAB_DURATION_URL = SETTINGS + SETTINGS_CALENDAR + DURATION;

const SettingsFormBuilder = props => {
  const [categoryReadPermission, setCategoryReadPermission] = useState(false);
  const [categoryCreatePermission, setCategoryCreatePermission] =
    useState(false);
  const [categoryDeletePermission, setCategoryDeletePermission] =
    useState(false);
  const [categoryUpdatePermission, setCategoryUpdatePermission] =
    useState(false);

  const [choicesReadPermission, setChoicesReadPermission] = useState(false);
  const [choicesUpdatePermission, setChoicesUpdatePermission] = useState(false);
  const [choicesDeletePermission, setChoicesDeletePermission] = useState(false);
  const [choicesCreatePermission, setChoicesCreatePermission] = useState(false);

  const [readPermissionAssessment, setReadPermissionAssessment] = useState(false);
  const [updatePermissionAssessment, setUpdatePermissionAssessment] = useState(false);
  const [deletePermissionAssessment, setDeletePermissionAssessment] = useState(false);
  const [createPermissionAssessment, setCreatePermissionAssessment] = useState(false);

  const [rightPermission, setRightPermission] = useState({});

  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextSubscription,
  } = useContext(AppContext);

  let history = useHistory();
  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    setRightPermission({ ...rolePermissions });
    if (contextIsSuperAdmin) {
      setCategoryReadPermission(true);
      setChoicesReadPermission(true);
      setCategoryCreatePermission(true);
      setCategoryDeletePermission(true);
      setCategoryUpdatePermission(true);
      setChoicesUpdatePermission(true);
      setChoicesDeletePermission(true);
      setChoicesCreatePermission(true);

      setReadPermissionAssessment(true)
      setUpdatePermissionAssessment(true)
      setDeletePermissionAssessment(true)
      setCreatePermissionAssessment(true)
    }
    else if (contextIsAdmin) {
      setCategoryReadPermission(
        contextSubscription?.[FORM_BUILDER_CATEGORY_PERMISSION]?.[
        WEB_PERMISSION
        ]
      );
      setChoicesReadPermission(
        contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION]
      );
      setCategoryCreatePermission(true);
      setCategoryDeletePermission(true);
      setCategoryUpdatePermission(true);
      setChoicesUpdatePermission(true);
      setChoicesDeletePermission(true);
      setChoicesCreatePermission(true);

      setReadPermissionAssessment(contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION])
      setUpdatePermissionAssessment(true)
      setDeletePermissionAssessment(true)
      setCreatePermissionAssessment(true)
    } else {
      if (rolePermissions?.[FORM_BUILDER_CATEGORY_PERMISSION]) {
        setCategoryReadPermission(
          (contextSubscription?.[FORM_BUILDER_CATEGORY_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ]
        );
        setCategoryCreatePermission(
          (contextSubscription?.[FORM_BUILDER_CATEGORY_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
          ]
        );
        setCategoryUpdatePermission(
          (contextSubscription?.[FORM_BUILDER_CATEGORY_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
          ]
        );

        setCategoryDeletePermission(
          (contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CATEGORY_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_DELETE_PERMISSION
          ]
        );
      }
      if (rolePermissions?.[FORM_BUILDER_CHOICES_PERMISSION]) {
        setChoicesReadPermission(
          (contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CHOICES_PERMISSION][WEB_PERMISSION][
            WEB_READ_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_READ_PERMISSION
          ]
        );
        setChoicesCreatePermission(
          (contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CHOICES_PERMISSION][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_CREATE_PERMISSION
          ]
        );
        setChoicesUpdatePermission(
          (contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CHOICES_PERMISSION][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_UPDATE_PERMISSION
          ]
        );

        setChoicesDeletePermission(
          (contextSubscription?.[FORM_BUILDER_CHOICES_PERMISSION]?.[
            WEB_PERMISSION
          ] &&
            rolePermissions[FORM_BUILDER_CHOICES_PERMISSION][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
            ]) ||
          rolePermissions[FORM_BUILDER_CHOICES_PERMISSION]?.[WEB_PERMISSION]?.[
          SELF_WEB_DELETE_PERMISSION
          ]
        );
      }
      if (rolePermissions?.[ASSESSMENT]) {

        setReadPermissionAssessment(
          (contextSubscription?.[ASSESSMENT]?.[WEB_PERMISSION] &&
            rolePermissions[ASSESSMENT][WEB_PERMISSION][WEB_READ_PERMISSION]) ||
          rolePermissions[ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION]
        )
        setUpdatePermissionAssessment(
          (contextSubscription?.[ASSESSMENT]?.[WEB_PERMISSION] &&
            rolePermissions[ASSESSMENT][WEB_PERMISSION][WEB_UPDATE_PERMISSION]) ||
          rolePermissions[ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION]
        )
        setDeletePermissionAssessment(
          (contextSubscription?.[ASSESSMENT]?.[WEB_PERMISSION] &&
            rolePermissions[ASSESSMENT][WEB_PERMISSION][WEB_DELETE_PERMISSION]) ||
          rolePermissions[ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION]
        )
        setCreatePermissionAssessment(
          (contextSubscription?.[ASSESSMENT]?.[WEB_PERMISSION] &&
            rolePermissions[ASSESSMENT][WEB_PERMISSION][WEB_CREATE_PERMISSION]) ||
          rolePermissions[ASSESSMENT]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION]
        )
      }
    }
  }, [
    contextIsSuperAdmin ||
    contextIsAdmin ||
    contextSubscription ||
    contextSubSectionPermissions ||
    contextPermissions,
  ]);
  const reviewLocation = url => {
    return url === history.location.pathname;
  };
  return (
    <div>
      <Title> Settings Form Builder</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center mb-4">
          <div className={'d-flex firstRow'}>
            {choicesReadPermission && (
              <LinkTabStyled active={reviewLocation(TAB_CHOICES_URL)}>
                <Link to={TAB_CHOICES_URL} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_CHOICES_URL)}>
                    Choices
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
            {categoryReadPermission && (
              <LinkTabStyled active={reviewLocation(TAB_CATAGORY_URL)}>
                <Link to={TAB_CATAGORY_URL} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_CATAGORY_URL)}>
                    Category
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
            {readPermissionAssessment && (
              <LinkTabStyled active={reviewLocation(TAB_ASSESSMENT_URL)}>
                <Link to={TAB_ASSESSMENT_URL} className={'styledLink'}>
                  <LinkTab active={reviewLocation(TAB_ASSESSMENT_URL)}>
                    Assessment
                  </LinkTab>
                </Link>
              </LinkTabStyled>
            )}
          </div>
          <div className={'d-flex firstRow'}></div>
        </ContainerTabStyled>

        <Switch>
          <Route exact path={TAB_CHOICES_URL}>
            <Choices
              choicesReadPermission={choicesReadPermission}
              choicesUpdatePermission={choicesUpdatePermission}
              choicesDeletePermission={choicesDeletePermission}
              choicesCreatePermission={choicesCreatePermission}
            />
          </Route>
          <Route exact path={TAB_CATAGORY_URL}>
            <SettingsFormBuilders
              props={props}
              categoryReadPermission={categoryReadPermission}
              categoryCreatePermission={categoryCreatePermission}
              categoryDeletePermission={categoryDeletePermission}
              categoryUpdatePermission={categoryUpdatePermission}
            />
          </Route>
          {/* Assessment */}
          <Route exact path={TAB_ASSESSMENT_URL}>
            <Assessment
              props={props}
              readPermission={readPermissionAssessment}
              updatePermission={updatePermissionAssessment}
              deletePermission={deletePermissionAssessment}
              createPermission={createPermissionAssessment}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default SettingsFormBuilder;
