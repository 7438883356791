import React, {useState, useEffect, Fragment} from 'react';
import { Title,TitleSection} from 'shared/styles/constants/tagsStyles';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {NameWrapper}  from 'shared/styles/constants/tagsStyles';

const ActualManualBillRate = ({
    dropDownHandleSelect,
    billRate,
      useInput,
      values,
      setValues,
}) => {

  useEffect(()=>{
values.actual_manual_final_bill_rate_amount = Math.round( Number(values.actual_manual_bill_rate_amount)  ) ;

setValues({...values})
  },[
values.actual_manual_bill_rate_amount
  ])

  return (
    <div>
            <FlexGrid3Container className={'w-100 mb-2 mt-1 '}>
  <div style={{margin:'auto 0 ', width:'25%'}} className={`pr-1 mr-1 ml-1` }><small> <TitleSection>Actual  Manual Bill Rate</TitleSection></small></div>
<FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "     ResizeWidth={'20%'}>
            <InputLabelTop
        
                label={'Actual  Manual Bill Rate'}
                type="text"
                {...useInput(`actual_manual_bill_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "    ResizeWidth={'20%'} >
            <InputLabelTop
                label={'Actual  Manual Bill Amount'}
                type="text"
                {...useInput(`actual_manual_bill_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pl-1 pr-0 pr-md-1 pt-1  "    ResizeWidth={'20%'}>
            <InputLabelTop
                    readOnly={true}
                    disabled={true}
                label={'Actual Rounded  Manual Bill Amount'}
                type="text"
                {...useInput(`actual_manual_final_bill_rate_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
  </FlexGrid3Container>
    </div>
  )
}

export default ActualManualBillRate