
import React from 'react';
import { useState } from 'react';
import {
    EX_LARGE_COL,
    EX_SMALL_COL,
    LARGE_COL,
    MEDIUM_COL,
    SMALL_COL,
} from 'shared/styles/constants/columns';
import {
    Header,

    Title,
} from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import Table from 'components/SharedComponents/Table';
import {
    Col,
    ColsGrouper,
    IconWrapper,
    Row,
} from 'components/SharedComponents/Table/styles';
import { ERR_COLOR, PURPLE } from 'shared/styles/constants/colors';
import { MdEdit } from 'react-icons/md';
import { IoIosBody } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { useModal } from 'hooks/ModalHook';
import { useForm } from 'hooks/FormHook';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { deleteBodyPart, getClientBodyPart, postAddBodyPart, updateBodyPart } from 'utils/api/ClientApi';
import { useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import Spinner from 'components/SharedComponents/Spinner';
import { getPageToSearchParam } from 'shared/methods';
import queryString from 'query-string';
import { useContext } from 'react';
import { AppContext } from 'context';

import { client_body_map_choices } from 'utils/choiceConstant';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import BodyMapModal from './BodyMapModal';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Pagination from 'components/SharedComponents/Pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const headerColumns = [
    {
        label: '',
        name: '',
        status: 0,
        className: '',
        max_width: EX_SMALL_COL,
    },
    {
        label: 'BODY PART',
        name: 'tittle',
        status: 0,
        className: '',
        max_width: SMALL_COL,
    },
    {
        label: 'DESCRIPTION',
        name: 'description',
        status: 0,
        className: 'sm-hidden md-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'LINKED FROM',
        name: 'client__full_name',
        status: 0,
        className: '',
        max_width: LARGE_COL,
    },
    {
        label: 'CREATED BY',
        name: 'created_by__username',
        status: 0,
        className: 'sm-hidden md-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'CREATED AT',
        name: 'updated_at',
        status: 2,
        className: 'sm-hidden',
        max_width: LARGE_COL,
    },

];
function BodyMap({ props, clientId, readPermission, createPermission, updatePermission, deletePermission }) {
    const toastRef = useRef(null)
    const { contextChoices, handleGetChoices } = useContext(AppContext)
    const [bodyPartId, setBodyPartId] = useState(null);
    const [isUpdate, setIsUpdate] = useState(true)
    const [bodyParts, setBodyParts] = useState([])
    const [loading, setLoading] = useState(true)
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [backMode, setBackMode] = useState(false);
    const {
        setValue: setModalBodyMap,
        value: modalBodyMap,
        fullBind: fullBindBodyMap,
    } = useModal(false);

    const { setValue: setModalDetachBodyMap, bind: bindModalDetachBodyMap } = useModal(false);
    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ tempBodyMapData: {}, alert_body_part: [], ordering: '-updated_at' });

    useEffect(() => {
        values.limitperpage = PAGE_SIZE_RENDER;
        setValues({ ...values })
    }, [PAGE_SIZE_RENDER])


    // GET Client Body Part API
    const getClientBodyPartsApi = async (id) => {
        const pageToSearch = getPageToSearchParam(
            props.location.search,
            setCurrentPage,
        )
        try {
            const res = await getClientBodyPart(id, { ...values, ...pageToSearch })
            setBodyParts(res?.results)
            setPages(Math.ceil(res?.count / values?.limitperpage));
            setLoading(false)
        } catch (error) {
            console.log("ERROR", error)
        }

    }

    // Add New Client Body Part API
    const addBodyParts = async () => {
        const body = {
            client: clientId,
            tittle: values?.tempBodyMapData?.tittle,
            description: values?.tempBodyMapData?.description,
            is_back_mode: backMode
        }
        try {
            const res = await postAddBodyPart(body)

            if (res) {
                getClientBodyPartsApi(clientId)
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${"Successfully added body part !"}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${"Somthing went wrong !"}`,
                });
            }
            setBackMode(false)
        } catch (error) {
            console.log("ERROR", error)
        }
    }

    // Update Client Body Part API
    const updateBodyParts = async () => {
        const find = bodyParts.find(el => el.id == bodyPartId)
        const body = {
            client: find?.client,
            id: find?.id,
            tittle: values?.tempBodyMapData?.tittle,
            description: values?.tempBodyMapData?.description,
            is_back_mode: backMode
        }
        try {
            const res = await updateBodyPart(body, find?.id)
            if (res) {
                getClientBodyPartsApi(clientId)

                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${"Successfully updated body part !"}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${"Somthing went wrong !"}`,
                });
            }
            setBackMode(false)
        } catch (error) {
            console.log("ERROR", error)
        }
    }

    // Delete Client Body Part API
    const handleDeleteBodyPart = async (id) => {
        try {
            const res = await deleteBodyPart(id)
            if (res.status == "SUCCESS") {
                getClientBodyPartsApi(clientId)
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res?.message}`,
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${res?.message}`,
                });
            }
        } catch (error) {
            console.log("ERROR", error)
        }
        setModalDetachBodyMap(false);
    };

    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    useEffect(() => {
        handleGetChoices(client_body_map_choices)
    }, [])

    useEffect(() => {
        getClientBodyPartsApi(clientId)
    }, [values.ordering, props.location.search, values.limitperpage])

    const handleModelOpen = (client_Id) => {
        setIsUpdate(false)
        setModalBodyMap(true);
        values.tempBodyMapData = {};
        setValues({ ...values });
    };

    const handleEditBodyPart = bodyPart => {
        setModalBodyMap(true);
        if (bodyPart.is_back_mode) {
            setBackMode(true)
        } else {
            setBackMode(false)
        }
        values.tempBodyMapData = {
            tittle: bodyPart.tittle,
            description: bodyPart.description
        }
        setValues({ ...values });
    };



    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const findUserFromChoices = id => {
        const user = contextChoices?.user_list?.find(user => user.id === id);
        if (user) {
            return user.name;
        } else {
            return null;
        }
    };

    const capitalizeText = (text) => {
        if (text) {

            return `${text?.toString()[0]?.toUpperCase() + text?.slice(1)}`
        }
        return ""
    }
    useEffect(() => {
        if (!modalBodyMap) {
            setBackMode(false)
        }
    }, [modalBodyMap])
    return (
        <ComponentDynamic loading={loading}>
            <Toast ref={toastRef} position="top-right" />
            <div className="mt-2">
                <div className={`d-flex justify-content-center mb-4`}>
                    {/* <Title>Body Map</Title> */}
                    {createPermission &&
                        <PrimaryButtonForm
                            minWidth="6rem"
                            onClick={handleModelOpen}>
                            <BsFolderPlus size={18} />
                            <span className={'ml-2'}>ADD BODY PART</span>
                        </PrimaryButtonForm>
                    }
                </div>
                {
                    bodyParts.length ?
                        <>
                            <Table headerColumns={headerColumns} callBackQuerySort={setQuerySort}>
                                <div>

                                    {bodyParts.map((item, index) => {
                                        const sentAt = `${item?.created_at_org?.slice(0, 10)} ${item?.created_at_org?.slice(11, 16)}`;

                                        return (
                                            <Row key={index} bgColor style={{ padding: '5px 0px', margin: "5px 0px" }}>
                                                <ColsGrouper
                                                    className="d-flex justify-content-between"
                                                    style={{ paddingLeft: '10px', }}>
                                                    <Col className="" Center Icon Purple>

                                                        <IconWrapper style={{ color: PURPLE }}>
                                                            <IoIosBody />
                                                        </IconWrapper>

                                                    </Col>
                                                    <Col
                                                        max_width={SMALL_COL}
                                                        Center
                                                        className=""
                                                        overlap={true}>
                                                        <label>{capitalizeText(item?.tittle)}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={EX_LARGE_COL}
                                                        Center
                                                        className="sm-hidden md-hidden"
                                                        overlap={true}>
                                                        <label>{item?.description}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className=""
                                                        overlap={true}>
                                                        <label>{item?.created_from}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden md-hidden"
                                                        overlap={true}>
                                                        <label>{findUserFromChoices(item?.created_by)}</label>
                                                    </Col>
                                                    <Col
                                                        max_width={LARGE_COL}
                                                        Center
                                                        className="sm-hidden"
                                                        overlap={true}>
                                                        <label>{sentAt}</label>
                                                    </Col>
                                                    {updatePermission && 
                                                    <Col className="" Center Icon Purple>
                                                        <IconWrapper
                                                            style={{ color: PURPLE }}
                                                            onClick={() => {
                                                                setBodyPartId(item?.id);
                                                                handleEditBodyPart(item);
                                                                setIsUpdate(true)
                                                            }}>
                                                            <MdEdit />
                                                        </IconWrapper>
                                                    </Col>}

                                                    {deletePermission && 
                                                    <Col className="sm-hidden" Center Icon Red>
                                                        <IconWrapper
                                                            style={{ color: PURPLE, marginLeft: '0.5rem' }}
                                                            onClick={() => {
                                                                setBodyPartId(item?.id);
                                                                setModalDetachBodyMap(true);
                                                            }}>
                                                            <RiDeleteBinLine />
                                                        </IconWrapper>
                                                    </Col>}

                                                </ColsGrouper>
                                            </Row>
                                        );
                                    })}
                                </div>
                            </Table>
                            <div className="mt-3">
                                <Pagination
                                    totalPages={pages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    values={values}
                                    dropDownHandleSelect={dropDownHandleSelectPagination}
                                    number={PAGE_SIZE_RENDER}
                                    dataLength={bodyParts?.length}
                                />
                            </div>
                        </> :
                        <div className='d-flex w-100 justify-content-center mt-2'>
                            <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                        </div>}
                {modalBodyMap ? (
                    <BodyMapModal
                        fullBind={fullBindBodyMap}
                        setModalBodyMap={setModalBodyMap}
                        useInput={useInput}
                        values={values}
                        setValues={setValues}
                        addBodyParts={addBodyParts}
                        updateBodyParts={updateBodyParts}
                        update={isUpdate}
                        isClient={true}
                        setBackMode={setBackMode}
                        backMode={backMode}
                    />
                ) : null}

                <ModalDecision
                    type="delete"
                    title="Warning"
                    width={"50%"}
                    max_width={'fit-Content'}
                    body={
                        'Are you sure you want to delete the row? '
                    }
                    onOk={() => {
                        handleDeleteBodyPart(bodyPartId)
                    }
                    }
                    onCancel={() => {
                        setModalDetachBodyMap(false);
                    }}
                    okTitle={'DELETE'}
                    {...bindModalDetachBodyMap}
                />

            </div>
        </ComponentDynamic>
    );
}

export default BodyMap;
