import React from 'react';
import moment from 'moment';
import './model.css';
import ReactHtmlParser from 'react-html-parser';
import { ModalContainer } from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { GoFile } from 'react-icons/go';
import { getDownloadAttachment } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

function EmailModal({
    findUserFromChoices,
    modelLoading,
    emailHistoryContent,
    fullBind,
}) {
    const toast = useRef(null)
    const spiltFileName = (file) => {
        if(!file) return ""
        let lastIndex = file.lastIndexOf('/')
        let fileName = file.slice(lastIndex + 1)
        return fileName ? fileName.match(/.{1,13}/g).join(' ') : ""

    }

    const handleDownloadAttachment = async (id, fileName) => {
        if (!fileName) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `File URL not found`,
            });
            return;
        }
        const res = await getDownloadAttachment(id)
        if (res) {
            const newWindow = window.open(fileName, '_blank');
            if (newWindow) {
                newWindow.focus();
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `"${res?.message}"`,
            });
        }
    }

    return (
        <React.Fragment>
            <Toast ref={toast} position="top-right" />
            <ModalContainer max_width={'75%'}>
                <ModalDynamic
                    loading={modelLoading}
                    loadingMaxWidth={'10rem'}
                    {...fullBind}
                    title={'Preview email'}
                    max_width={"75%"}
                    minWidthTitle="8.5rem"
                    maginLeft={'100px'}>

                    <div id="setting-email-model" className="p-3">

                        {emailHistoryContent?.data?.mail_subject ? (
                            <h4 className="subject">{emailHistoryContent?.data?.mail_subject}</h4>
                        ) : null}
                        <div className="header mt-2">
                            <div>
                                <p className="from-text">To - <span className="fs-sml">{emailHistoryContent?.data?.send_to}</span> </p>
                                {emailHistoryContent?.data?.send_to_cc ? <p className="from-text">Cc - <span className="fs-sml">{emailHistoryContent?.data?.send_to_cc}</span></p>
                                    : null}
                                {emailHistoryContent?.data?.send_to_bcc ? <p className="from-text">Bcc - <span className="fs-sml">{emailHistoryContent?.data?.send_to_bcc}</span></p>
                                    : null}
                                {findUserFromChoices(emailHistoryContent?.data?.send_by) ? <p className="from-text">From -{' '}<span className="fs-sml">{findUserFromChoices(emailHistoryContent?.data?.send_by)}</span></p>
                                    : null}
                            </div>
                            <p className="fs-sml">
                                {moment(emailHistoryContent?.data?.mail_sent_at).format('MMM DD YYYY hh:mm')}
                            </p>
                        </div>
                        {emailHistoryContent?.data?.content ? <div className='mt-2 email-content'>
                            <div id="email-content" style={{ maxWidth: '100%', overflow: "auto", color: "black" }}>
                                {ReactHtmlParser(emailHistoryContent?.data?.content)}
                            </div>
                        </div> : ""}
                        <div className="button-container align-items-end">
                            {!!emailHistoryContent?.data.mail_record_attachment ? emailHistoryContent?.data.mail_record_attachment.map(attachment => {
                                console.log(attachment.file_name,"attachment.file_name")
                                return (
                                    <>
                                        <div
                                            className='file-container'
                                            onClick={() => {
                                                handleDownloadAttachment(attachment.id, attachment.file_name)
                                            }}>
                                            <GoFile style={{ height: '80px', width: '80px' }} />
                                            <p style={{ width: '70px' }} className='file-name'>
                                                {spiltFileName(attachment.file_name)}
                                            </p>
                                        </div>
                                    </>
                                );
                            }) : ""}
                        </div>


                    </div>

                </ModalDynamic>
            </ModalContainer>
        </React.Fragment>
    );
}

export default EmailModal;
