import { PURPLE } from "shared/styles/constants/colors";
import styled from "styled-components";


export const BoxTabStyled = styled.div`
display: flex;
align-items: center;
cursor: pointer;
width: 100%;
padding-top: 0.5rem;
padding-bottom: 0.8rem;
margin-bottom: 1rem;
margin-right: 0.6rem;
background-color: #fff;
border-radius: 12px;
box-shadow: 3px 3px rgba(0,0,0,0.1);
 color: ${props => (props.active ? ` ${PURPLE}` : 'black')};
  border-bottom: ${props => (props.active ? `2px solid ${PURPLE}` : '0')};
`;

export const ModalTabsContainer = styled.div`
postion:fixed !important;
background-color:'#F0F0F7' !important;
width:'100%'  !important;,
margin-left:-36px;
padding:10px;
z-index: 2;
display:flex;
justify-content:around;
text-align:center;
margin-top:23px;

@media (max-width: 456px) {
  background-color:'#F0F0F7' !important;

  overflow-x: scroll !important;;
  font-size: 15px;
padding-right: 25px;
  padding-left: 1px;
  margin-top:88px;
}

`;

export const TabsWraper = styled.div`
    flex-wrap: wrap;
    justify-content: center;
`;
export const MainTabsWraper = styled.div`
    width: 100%;
    background-color: #F0F0F7;
`;

export const HeaderContainer = styled.div`
   width: 84%;
   z-index: 2;
   background-color: #F0F0F7;
   position: fixed;
   margin-top: -141px;
`;