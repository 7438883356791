import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { StylePointer } from 'shared/styles/constants/tagsStyles';
import {Title} from './styles';
import {RoleName} from './styles';

const HeaderTable = ({selectedRol, handleOnClick}) => {
  return (
    <tr>
      <th scope="col" className={'border sticky-col first-col'}>
        <div className={'d-flex align-items-center'}>
          <StylePointer  onClick={()=>handleOnClick({}, 'add', `care_category`)} style={{ paddingRight: 10 }}>
            <FaPlus color={PURPLE} size={20} />
          </StylePointer>
          Care Category
        </div>
      </th>
      <th scope="col" className={'text-center border all-cols'}>
        <Title>Edit</Title>
      </th>
      <th scope="col" className={'text-center border all-cols'}>
        <Title>Delete</Title>
      </th>
    </tr>
  );
};

export default HeaderTable;
