import React, {useEffect, useState, useContext} from 'react';
import {
  ContainerTabStyled,
  Title,
  TabStyled,
  TabStyledNew,
} from 'shared/styles/constants/tagsStyles';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import PayrollDetails from './PayrollDetails';
import PayrollSummary from './PayrollSummary';
import FinPayroll from './FinPayroll';
import {AppContext} from 'context';
import {
  FINANCE,
  PAYROLL,
  SUMMARY,
  DETAILS,
  FINPAYROLL,
} from 'utils/constants/routes';
import {
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  FINANCE_PAY_SUMMARY,
  FINANCE_PAY_FINROLL,
  FINANCE_PAY_DETAILS,
} from '../../../../utils/constants/permissions';
import PayrollDetailsOffice from './PayrollDetailsOffice';
import PayrollDetailsDistance from './PayrollDetailsDistance';
import PayrollDetailsOnCall from './PayrollDetailsOnCall';
import PayrollDetailsTravel from './PayrollDetailsTravel';
import PayrollDetailsVisits from './PayrollDetailsVisits';
import PayrollDetailsAbsence from './PayrollDetailsAbsence';
import PayrollDetailsShift from './PayrollDetailsShift';
const TAB_PAYROLL_SUMMARY = FINANCE + PAYROLL + SUMMARY;
const TAB_PAYROLL_DETAILS = FINANCE + PAYROLL + DETAILS;
const TAB_PAYROLL_FINPAYROLL = FINANCE + PAYROLL + FINPAYROLL;
const FinancePayroll = props => {
  const [readPermissionPaySummary, setReadPermissionPaySummary] =
    useState(false);
  const [readPermissionPayFinRoll, setReadPermissionPayFinRoll] =
    useState(false);
  const [readPermissionPayDetails, setReadPermissionPayDetails] =
    useState(false);
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
  } = useContext(AppContext);
  const [tabSelectedDetails, setTabSelectedDetails] = useState(1);
  let history = useHistory();
  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
      });
    }
    if (contextIsSuperAdmin|| contextIsAdmin) {
      setReadPermissionPaySummary(true);
      setReadPermissionPayFinRoll(true);
      setReadPermissionPayDetails(true);
    } else {
      setReadPermissionPaySummary(
        rolePermissions[FINANCE_PAY_SUMMARY]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ]
      );
      setReadPermissionPayFinRoll(
        rolePermissions[FINANCE_PAY_FINROLL]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ]
      );
      setReadPermissionPayDetails(
        rolePermissions[FINANCE_PAY_DETAILS]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
        ]
      );
    }
  }, [contextIsSuperAdmin, contextIsAdmin, contextPermissions, contextSubSectionPermissions]);
  const reviewLocation = url => {
    return url === history.location.pathname;
  };
  return (
    <div>
      <Title>Payroll</Title>
      <div className="p-2 p-sm-3 p-md-4">
        <ContainerTabStyled className="text-center">
          <div className={'d-flex'}>
            {readPermissionPaySummary && (
              <TabStyledNew
                active={reviewLocation(TAB_PAYROLL_SUMMARY)}
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                onClick={()=>{
                  props.history.push(TAB_PAYROLL_SUMMARY)
                }}
              >
                Payroll Summary
              </TabStyledNew>
            )}
            {readPermissionPayDetails && (
              <TabStyledNew
                active={reviewLocation(TAB_PAYROLL_DETAILS)}
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                onClick={()=>{
                  props.history.push(TAB_PAYROLL_DETAILS)
                }}
              >
                Payroll Details
              </TabStyledNew>
            )}
            {readPermissionPayFinRoll && (
              <TabStyledNew
                active={reviewLocation(TAB_PAYROLL_FINPAYROLL)}
                style={{ width: 'fit-content', paddingRight: '1rem', paddingLeft: '1rem' }}
                onClick={()=>{
                  props.history.push(TAB_PAYROLL_FINPAYROLL)
                }}
              >
                Payroll
              </TabStyledNew>
            )}
          </div>
        </ContainerTabStyled>
      </div>
      <Switch>
        <Route exact path={TAB_PAYROLL_SUMMARY}>
          <PayrollSummary location={props.location} />
        </Route>
        <Route exact path={TAB_PAYROLL_DETAILS}>
        <ContainerTabStyled className="text-center mb-4">
            <div className={'d-flex firstRow'}>
              <TabStyled
                active={tabSelectedDetails === 1}
                onClick={() => {
                  setTabSelectedDetails(1)
                }}>
                Overview
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 2}
                onClick={() => {
                  setTabSelectedDetails(2)
                }}>
                Visits
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 3}
                onClick={() => {
                  setTabSelectedDetails(3)
                }}>
                Travel Time
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 4}
                onClick={() => {
                  setTabSelectedDetails(4)
                }}>
                Distance
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 5}
                onClick={() => {
                  setTabSelectedDetails(5)
                }}>
                Office
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 6}
                onClick={() => {
                  setTabSelectedDetails(6)
                }}>
                On Call
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 7}
                onClick={() => {
                  setTabSelectedDetails(7)
                }}>
                Absence
              </TabStyled>
              <TabStyled
                active={tabSelectedDetails === 8}
                onClick={() => {
                  setTabSelectedDetails(8)
                }}>
                Shift
              </TabStyled>
            </div>
          </ContainerTabStyled>
          {tabSelectedDetails === 1 &&
            <PayrollDetails location={props.location} />
          }
          {tabSelectedDetails === 2 &&
            <PayrollDetailsVisits location={props.location} />
          }
          {tabSelectedDetails === 3 &&
            <PayrollDetailsTravel location={props.location} />
          }
          {tabSelectedDetails === 4 &&
            <PayrollDetailsDistance location={props.location} />
          }
          {tabSelectedDetails === 5 &&
            <PayrollDetailsOffice location={props.location} />
          }
          {tabSelectedDetails === 6 &&
            <PayrollDetailsOnCall location={props.location} />
          }
          {tabSelectedDetails === 7 &&
            <PayrollDetailsAbsence location={props.location} />
          }
          {tabSelectedDetails === 8 &&
            <PayrollDetailsShift location={props.location} />
          }
        </Route>
        <Route exact path={TAB_PAYROLL_FINPAYROLL}>
          <FinPayroll location={props.location} />
        </Route>
      </Switch>
    </div>
  );
};

export default FinancePayroll;
