// contextChoices[val.name].map((vale) => ({ value: vale.id, label: vale.name }))

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { AppContext } from 'context';
import React, { useState } from 'react'
import { useContext } from 'react';

export default function DropDownForChoices({ json, multiple = false,jsonData = [], setJsonData=()=>{}, index, minWidth = 400 }) {
    // const [label, setLabel] = useState(json.dropdown.label)

    const [selected, setSelected] = useState(multiple ? [] : json.value)
    const {
        contextChoices,
    } = useContext(AppContext);
    return (
        <FormControl style={{ flex: 1 }} sx={{ m: 1, minWidth: minWidth }}>
            <InputLabel id="demo-simple-select-label">{json.placeholder}</InputLabel>
            <Select
            style={{height:'45px'}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple={multiple}
                // label={json.placeholder}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}
                value={multiple ? [] : json?.value}
                onChange={item => {
                    const data = jsonData?.map((val, newIndex) => {
                        console.log(newIndex, index)
                        if (newIndex === index) {
                            return ({
                                ...val,
                                value: item.target.value
                            })
                        }
                        return val
                    })
                    setJsonData(data)
                }}
            >
                {
                    contextChoices && json.choices && contextChoices[json.choices]?.map((vale) => ({ value: vale.id, label: vale.name || vale.full_name })).map((item, index) => <MenuItem key={index} value={item.label}>{item.label}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
