import React, { useState, useEffect, Fragment, useRef } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  setTriageApi,
  getTriageById,
  getNotificationData,
} from 'utils/api/AlertsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
  CloseButtonWrapper,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import MultiFileUpload from 'components/DashboardComponents/Alerts/AddModal/MultiFileUpload';
import CommentList from 'components/DashboardComponents/Alerts/AddModal/CommentList';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { getClientVisits } from 'utils/api/RegularVisitApi';
import {
  getVisitsId,
  getModifiedVisitsId,
  getClientCareContact,
} from 'utils/api/AlertsApi';
import {
  setTriageLabel,
  setOnlyforTriagePopupTriageLabel,
} from 'utils/api/SettingsApi';
import { PLUS, TIMES } from 'utils/constants/icons';
import NotificationRule from './NotificationRule';
import { Toast } from 'primereact/toast';
import DynamicList from 'components/SharedComponents/DynamicList';
import {
  BorderContainer,
  LeftSkillRow,
  SkillRow,
} from 'components/DashboardComponents/Team/AddSkills/styles';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { useModal } from 'hooks/ModalHook';
import BodyMapModal from '../BodyMapModal';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';
import {IoIosBody} from 'react-icons/io';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { ERR_COLOR, GRAY_300, GRAY_400, GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import './alert.css'
const TriageModal = ({
  fullBind,
  idSelected,
  clientName,
  setTitle,
  type,
  alertStatus,
  severity,
  labels,
  employeeName,
  alertPrivacy,
  setModalValue,
  loadTriage,
  alerts,
  contextChoices,
  setContextChoices,
  readonly,
  update,
  handleGetChoices,
  triage_choices,
  noSave,
  handleChangeChoices = () => { },
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({tempBodyMapData:{}, alert_body_part:[]});
  const tab1 = 1;
  const tab2 = 2;
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [securityType, setSecurityType] = useState([]);
  const [clientsnameFilter, setClientNameFilter] = useState([]);
  const [visitIDData, setVisitIDData] = useState([]);
  const [timeRange, setTimeRange] = useState([]);
  const [showError, setShowError] = useState(false);
  const [tabSelected, setTabSelected] = useState(tab1);
  const [addedSkillMessage, setAddedSkillMessage] = useState('');
  const [clientCareData, setClientCareData] = useState([]);

  const { setValue: setModalDetachBodyMap, bind: bindModalDetachBodyMap } = useModal(false);
  const [bodyMapDeleteIndex, setBodyMapDeleteIndex] = useState(null);
  // const edit_alert = 'Edit Alert';
  // const new_alerth = 'Add Alert';
  const edit_alert = 'Edit Triage';
  const new_alerth = 'Add Triage';
  const toast = useRef();

  const {
    setValue: setModalBodyMap,
    value: modalBodyMap,
    fullBind: fullBindBodyMap,
  } = useModal(false);

  useEffect(() => {
    if (idSelected) {
      handleGetChoices(triage_choices);
      IndividualData();
      setTitle(edit_alert);
      setLoadingModal(false);
      values.id = idSelected;
      setValues({ ...values });
    } else {
      setTitle(new_alerth);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  // useEffect(()=>{
  //   if(values.client && idSelected){
  //     getClientVisits(values.client)
  //         .then(res => {
  //    if(res){
  // let TimeData=res.data.map(time=>{
  // return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
  // });
  // setTimeRange(TimeData);
  //    } } ) }
  //   setValues({...values});
  // },[values.client, idSelected])

  useEffect(() => {
    if (values.client && values.client_service_visit_date && contextChoices)
      visitCompleteData();
  }, [values.client, values.client_service_visit_date, idSelected]);

  const IndividualData = async () => {
    //    let x=  alerts?.map(data=>{
    //   if((data.id == idSelected) && data){
    // values.alert_created_by = data && data.alert_created_by && data.alert_created_by;
    // values.employee_assigned=data?.employee_assigned?.map(x=>{return{value: x.employee_assigned, label:employeeName?.find(dataid=>dataid.id == x.employee_assigned)?.name}});
    // values.client=data && data.client && data.client;
    // values.tittle=data && data.tittle && data.tittle;
    // values.description=data && data.description && data.description;
    // values.alert_type=data && data.alert_type && data.alert_type;
    // values.alert_severity=data && data.alert_severity && data.alert_severity;
    // values.alert_status=data && data.alert_status && data.alert_status;
    // values.alert_privacy=data &&  data.alert_privacy && data.alert_privacy;
    // values.alert_label=  data.alert_label?.map(x=>{return{value: x.label, label:labels?.find(dataid=>dataid.id == x.label)?.name}});
    // if(data.reminder_notification){
    // values.reminder_notification_date=data.reminder_notification?.split('T')[0];
    // values.reminder_notification_time=moment(data.reminder_notification?.split('T')[1],'HH:mm').format('HH:mm');
    // }else{
    //   values.reminder_notification_date='';
    //   values.reminder_notification_time='';
    // }
    // values.post_alert_comment='' ;
    // if(data.file_upload.length >0){
    // values.file_upload=data.file_upload?.map((fileupload)=>{
    //   return{ id:fileupload.id , name:fileupload.file  }
    // });
    // }else{
    //  values.file_upload=[];
    // }
    // values.alert_comment=data.alert_comment.length>0 ? data.alert_comment: [] ;
    // setValues({...values});
    //       }
    //     })
    if (idSelected) {
      const data = await getTriageById(idSelected);

      // values.alert_created_by =
      //   data && data.alert_created_by && data.alert_created_by;
      values.employee_assigned = data?.employee_assigned?.map(x => {
        return {
          value: x.employee_assigned,
          label: employeeName?.find(dataid => dataid.id == x.employee_assigned)
            ?.name,
          employee_assigned: x.employee_assigned,
          id: x.id,
        };
      });
      values.triage_tasks = data.alertTaskStatus;
      values.alertImportantContact = data.alertImportantContact.map(item => {
        return {
          value: item.clientCareContact,
          label: item.clientCareContact__full_name,
          id: item.id,
        };
      });
      values.resolution_date = data.resolution_date && data.resolution_date;
      values.overDueDays = data.overDueDays;

      values.client = data && data.client && data.client;
      values.tittle = data && data.tittle && data.tittle;
      values.description = data && data.description && data.description;
      values.alert_type = data && data.alert_type && data.alert_type;
      values.alert_severity =
        data && data.alert_severity && data.alert_severity;
      values.alert_status = data && data.alert_status && data.alert_status;
      values.alert_privacy = data && data.alert_privacy && data.alert_privacy;
      values.alert_label = data.alert_label?.map(x => {
        return {
          value: x.label,
          label: labels?.find(dataid => dataid.id == x.label)?.name,
          id: x.id,
          labelid: x.label,
        };
      });
      if (data.reminder_notification) {
        values.reminder_notification_date =
          data.reminder_notification?.split('T')[0];
        values.reminder_notification_time = moment(
          data.reminder_notification?.split('T')[1],
          'HH:mm'
        ).format('HH:mm');
      } else {
        values.reminder_notification_date = '';
        values.reminder_notification_time = '';
      }
      values.post_alert_comment = '';
      if (data.file_upload.length > 0) {
        values.file_upload = data.file_upload?.map(fileupload => {
          return { id: fileupload.id, name: fileupload.file };
        });
      } else {
        values.file_upload = [];
      }

      values.alert_comment =
        data.alert_comment.length > 0 ? data.alert_comment : [];
      // values.client_service_visit_timerange=timeRange?.find(x=>x.id == data.client_service_visit)?.name;
      values.client_service_visit_timerange =
        data.client_service_visit && data.client_service_visit;
      values.client_service_visit_date = data && data.visit_date;
      values.visit = visitIDData?.find(
        getname => getname.id == values.visit
      )?.name;
      values.visit = data.visit && data.visit;
      values.no_of_days = data.no_of_days.days ? data.no_of_days.days : '';
      values.no_of_hrs = data.no_of_days.hours
        ? (Math.round(data.no_of_days.hours * 100) / 100).toFixed(2)
        : '';
      values.alert_body_part = data.alert_body_part
      setValues({ ...values });
    }
  };

  const dropDownHandleSelect = (item, nameItem) => {
    //     if(nameItem == 'client'){
    //       getClientVisits(item.client)
    //           .then(res => {
    //      if(res){
    // let TimeData=res.data.map(time=>{
    //   return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
    // });
    // setTimeRange(TimeData);
    //      }
    //         } ) }

    //     if(nameItem  == 'client_service_visit_timerange'){
    //    values.client_service_visit_timerange =item.client_service_visit_timerange;
    //     }

    setValues({ ...values, ...item });
  };

  const HandleMultiSelect = (preferred, diffKeyname) => {
    setValues({ ...values, ...preferred });
  };

  const deleteCommentList = (data, index) => {
    if (values.alert_comment) {
      values.alert_comment.splice(index, 1);
    }
    setValues({ ...values });
  };

  const employeeAssigned = values?.employee_assigned?.map(emp => {
    // return{ employee_id : emp.value }
    if (idSelected && emp.id !== emp.value) {
      return { employee_id: emp.value, id: emp.id };
    } else {
      return { employee_id: emp.value };
    }
  });


  const alertImportantContacts = values?.alertImportantContact?.map(item => {
    if (!values.id) {
      return { clientCareContact: item.value };
    } else {
      return {
        id: item.id,
        clientCareContact: item.value,
      };
    }
  });
  const commentspatch = values?.alert_comment?.map(commentdata => {
    return {
      id: commentdata.id ? commentdata.id : '',
      comment: commentdata.comment,
    };
  });
  const file_upload_id = values?.file_upload?.map(files =>
    files.id ? files.id : ''
  );
  const date = values.reminder_notification_date
    ? moment(values.reminder_notification_date).format('YYYY-MM-DD')
    : '';
  const time = values.reminder_notification_time
    ? moment(values.reminder_notification_time, 'HH:mm').format('HH:mm:ss')
    : '';
  const dateAndTime = date && time ? date + 'T' + time : '';

  const visitCompleteData = async () => {
    if (values.client && values.client_service_visit_date) {
      const response = await getModifiedVisitsId(
        values.client,
        values.client_service_visit_date
      );

      let VisitDropdown = response?.data?.map(visitData => {
        return {
          id: visitData.id,
          name: visitData.job_title
            ? visitData.job_title +
            '' +
            ' ' +
            visitData.start_date?.split('T')[1] +
            '-' +
            visitData.end_date?.split('T')[1]
            : visitData.start_date?.split('T')[1] +
            '-' +
            visitData.end_date?.split('T')[1],
        };
      });

      setVisitIDData(VisitDropdown);
      setValues({ ...values });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }
    let alertNewName = [];
    let arrIds = [];

    if (values.alert_label) {
      let data = values?.alert_label?.forEach(name => {
        if (name.className === 'Select-create-option-placeholder') {
          alertNewName.push(name.label);
        }
      });

      if (alertNewName && alertNewName.length > 0) {
        await setOnlyforTriagePopupTriageLabel(alertNewName)
          .then(response => {
            if (response && response.data) {
              handleChangeChoices('alert_label')
              let data = values?.alert_label?.forEach(dataname => {
                if (dataname.className === 'Select-create-option-placeholder') {
                  let alertNewIds = response.data.find(
                    alertData => alertData.name == dataname.label
                  ).id;
                  delete dataname.value;
                  arrIds.push({ ...dataname, value: alertNewIds });
                } else {
                  arrIds.push({ ...dataname });
                }
              });
            }


            const alretData = arrIds?.map(item => {
              if (idSelected && item?.id !== item?.id) {
                return {
                  label_id: item?.item.value,
                  id: item?.id,
                };
              } else {
                return {
                  label_id: item.value,
                };
              }
            });

            console.log(alretData, arrIds)
            if (!values.id && values.post_alert_comment) {
              values.comments = [{ comment: values.post_alert_comment }];
              setValues({ ...values });
            } else if (values.id && values.post_alert_comment) {
              values.comments = [
                { comment: values.post_alert_comment },
                ...commentspatch,
              ];
              setValues({ ...values });
            }
            if (
              !values.alert_severity ||
              !values.alert_type ||
              !values.alert_status ||
              !values.tittle ||
              !values.alert_privacy
            ) {
              onError('Please fill the  mandatory fields ');
            } else {
              const bodyData = {
                employee_assigned: employeeAssigned && employeeAssigned,
                alertImportantContact: alertImportantContacts && alertImportantContacts,
                tittle: values?.tittle,
                // alert_label: alertLabel? alertLabel : '',
                alert_label: alretData && alretData,
                alert_comment: values.comments && values.comments,
                reminder_notification: dateAndTime && dateAndTime,
                alert_privacy: values?.alert_privacy,
                client: values?.client,
                alert_severity: values?.alert_severity,
                description: values?.description,
                alert_type: values?.alert_type,
                alert_status: values?.alert_status,
                visit: values?.visit,
                client_service_visit: values?.client_service_visit_timerange,
                visit_date: values?.client_service_visit_date,
                alert_created_by: values?.alert_created_by,
                file_upload_id: file_upload_id && file_upload_id,
                file_upload: values?.file_upload,
                resolution_date: values?.resolution_date,
                alertTask: values?.triage_tasks,
                alert_body_part: values.alert_body_part || []
              };

              const filteredBodyData = Object.fromEntries(
                Object.entries(bodyData).filter(
                  ([key, value]) => value !== undefined && value !== ''
                )
              );
              console.log(bodyData,filteredBodyData, 'AAAAAA');
              setLoadingSave(true);
              setTriageApi(filteredBodyData, values.id)
                .then(response => {
                  if (response?.status === true) {
                    toast.current.show({
                      severity: 'success',
                      summary: 'Success',
                      detail: `${response?.message?.message}`,
                    });
                  } else {
                    toast.current.show({
                      severity: 'error',
                      summary: 'Failed',
                      detail: `${response?.message}`,
                    });
                  }
                  if (response?.message?.message) {
                    onError(response?.message?.message);
                  } else {
                    onSuccess(response);
                  }
                })
                .catch(onError);
            }

          })
          .catch(onError);
      }
    }

    if (!alertNewName || (alertNewName && alertNewName.length < 1)) {
      if (!values.id && values.post_alert_comment) {
        values.comments = [{ comment: values.post_alert_comment }];
        setValues({ ...values });
      } else if (values.id && values.post_alert_comment) {
        values.comments = [
          { comment: values.post_alert_comment },
          ...commentspatch,
        ];
        setValues({ ...values });
      }

      const alretData = values?.alert_label?.map(item => {
        if (idSelected && item?.id !== item?.id) {
          return {
            label_id: item?.item.value,
            id: item?.id,
          };
        } else {
          return {
            label_id: item.value,
          };
        }
      });

      if (
        !values.alert_severity ||
        !values.alert_type ||
        !values.alert_status ||
        !values.tittle ||
        !values.alert_privacy
      ) {
        onError('Please fill the  mandatory fields ');
      } else {
        const bodyData = {
          employee_assigned: employeeAssigned && employeeAssigned,
          alertImportantContact: alertImportantContacts && alertImportantContacts,
          tittle: values?.tittle,
          // alert_label: alertLabel? alertLabel : '',
          alert_label: alretData && alretData,
          alert_comment: values.comments && values.comments,
          reminder_notification: dateAndTime && dateAndTime,
          alert_privacy: values?.alert_privacy,
          client: values?.client,
          alert_severity: values?.alert_severity,
          description: values?.description,
          alert_type: values?.alert_type,
          alert_status: values?.alert_status,
          visit: values?.visit,
          client_service_visit: values?.client_service_visit_timerange,
          visit_date: values?.client_service_visit_date,
          alert_created_by: values?.alert_created_by,
          file_upload_id: file_upload_id && file_upload_id,
          file_upload: values?.file_upload,
          resolution_date: values?.resolution_date,
          alertTask: values?.triage_tasks,
          alert_body_part: values.alert_body_part || []
        };

        const filteredBodyData = Object.fromEntries(
          Object.entries(bodyData).filter(
            ([key, value]) => value !== undefined && value !== ''
          )
        );
        console.log(filteredBodyData, 'AAAAAA');
        setLoadingSave(true);
        setTriageApi(filteredBodyData, values.id)
          .then(response => {
            if (response?.status === true) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `${response?.message?.message}`,
              });
            } else {
              toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${response?.message}`,
              });
            }
            if (response?.message?.message) {
              onError(response?.message?.message);
            } else {
              onSuccess(response);
            }
          })
          .catch(onError);
      }
    }




  };

  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(true);
    handleChangeChoices();
    loadTriage();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
  };
  const checkTabSwitch = async tabName => {
    setTabSelected(tabName);
    if (tabName === tab2) {
      const response = await getNotificationData(idSelected);
      if (response) {
        values.notificationrule = response.results;
        setValues({ ...values });
      }
    }
  };

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };

  const addNewnotificationrule = () => {
    if (!values.notificationrule) {
      values.notificationrule = [];
      values.notificationrule.push({});
      setValues({ ...values });
      setAddedSkillMessage('');
    } else {
      values.notificationrule.push({});
      setValues({ ...values });
    }
  };
  useEffect(() => {
    if (values.client) {
      getClientCareContact(values).then(res => setClientCareData(res.data));
    }
  }, [values.client]);
  const dropDownHandleNotificationrule = (item, nameItem, index) => {
    values.notificationrule[index][nameItem] = item[nameItem];
    setValues({ ...values });
  };

  const addNewTask = () => {
    const skillDefault = {
      employee: null,
      task_status: null,
      resolution_date: null,
      alert_task: null,
      message: null,
    };
    let newTask = [];
    if (values.triage_tasks) {
      newTask = values.triage_tasks.concat([skillDefault]);
    } else {
      newTask = [skillDefault];
    }
    values.triage_tasks = newTask;
    setValues({ ...values });
  };

  const setTaskInputs = newData => {
    values.triage_tasks = newData;
    setValues({ ...values });
  };

  const dropDownHandleSelectTask = (item, index, attribute) => {
    const newTask = [...values.triage_tasks];
    newTask[index][attribute] = item;

    setTaskInputs(newTask);
  };

  const handleInputChange = (e, attribute) => {
    const newTask = [...values.triage_tasks];
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    newTask[e.target.dataset.idx][attribute] = e.target.value;
    setTaskInputs(newTask);
  };

  const deleteTask = index => {
    let newTask = [...values.triage_tasks];
    newTask.splice(index, 1);
    values.triage_tasks = newTask;
    setValues({ ...values });
  };

  const toDeleteBodyData=(index)=>{
    values.alert_body_part.splice(index, 1);
    setValues({...values})
    setModalDetachBodyMap(false)
  }

  const handleRowBodyClick=(index)=>{
    setModalBodyMap(true)
    values.tempBodyMapData = {...values.alert_body_part[index], index: `${index}`}
    setValues({...values})
  }

  const createNewBodyMap=()=>{
    setModalBodyMap(true)
    values.tempBodyMapData = {}
    setValues({...values})
  }
  console.log(values, 'TTTTTTTT');
  return (
    <ModalContainer max_width={'60%'}>
      <Toast ref={toast} position="top-right" />

      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        {idSelected && (
          <div className="p-2 p-sm-3 p-md-4">
            <div className="d-flex justify-content-around text-center">
              <TabStyled
                active={isCurrentTab(tab1)}
                onClick={() => checkTabSwitch(tab1)}>
                Triage
              </TabStyled>

              <TabStyled
                active={isCurrentTab(tab2)}
                onClick={() => checkTabSwitch(tab2)}>
                Notification rule
              </TabStyled>
            </div>
          </div>
        )}

        <ShowTab show={isCurrentTab(tab1)}>
          <div className="pl-3 pr-3 pb-3 pt-0">
            <FlexGrid3Container className={'w-100 mb-2'}>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                  disabled={readonly}
                  placeHolder={'Client'}
                  options={clientName ? clientName : []}
                  selectedOption={values.client}
                  onChange={value =>
                    dropDownHandleSelect(
                      { client: value ? value : null },
                      'client'
                    )
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                  disabled={readonly}
                  placeHolder={'Employee'}
                  options={employeeName ? employeeName : []}
                  selectedOption={values.alert_created_by}
                  onChange={value =>
                    dropDownHandleSelect({
                      alert_created_by: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'100%'}>
                <DropdownSearchable
                  disabled={readonly}
                  isMulti={true}
                  placeHolder={'Client Important Contact'}
                  options={clientCareData}
                  selectedOptionsProps={values.alertImportantContact}
                  selectedOption={values.alertImportantContact}
                  onChange={value =>
                    HandleMultiSelect({
                      alertImportantContact: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DateSelector
                  disabled={readonly}
                  labelTop="Client service visit date"
                  {...useInput(`client_service_visit_date`)}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 " ResizeWidth={'50%'}>
                <DropdownSearchable
                  disabled={readonly}
                  //   required
                  //   {...useInput(
                  //    `visit`,
                  //    'isRequired'
                  //  )}
                  placeHolder={'visit'}
                  width={'100%'}
                  options={visitIDData ? visitIDData : []}
                  selectedOption={values ? values.visit : null}
                  onChange={value =>
                    dropDownHandleSelect({ visit: value ? value : null }, 'visit')
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 " ResizeWidth={'50%'}>
                <InputLabelTop
                  disabled={readonly}
                  required
                  type="text"
                  id="name"
                  label={'Triage title'}
                  {...useInput('tittle', 'isRequired')}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                  disabled={readonly}
                  required
                  {...useInput(`alert_type`, 'isRequired')}
                  placeHolder={'Type'}
                  options={type ? type : []}
                  selectedOption={values.alert_type}
                  onChange={value =>
                    dropDownHandleSelect({ alert_type: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mb-2" ResizeWidth={'100%'}>
                <small className="pr-1 " style={{ color: 'black' }}>
                  Triage Description
                </small>
                <InputTextArea
                  disabled={readonly}
                  type="text"
                  height={'4rem'}
                  id="description"
                  label={'Triage Description'}
                  {...useInput('description')}
                  maxlength={5}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                  disabled={readonly}
                  required
                  {...useInput(`alert_severity`, 'isRequired')}
                  placeHolder={'Severity'}
                  options={severity ? severity : []}
                  selectedOption={values.alert_severity}
                  onChange={value =>
                    dropDownHandleSelect({ alert_severity: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                  disabled={readonly}
                  required
                  {...useInput(`alert_status`, 'isRequired')}
                  placeHolder={'Triage Status'}
                  options={alertStatus ? alertStatus : []}
                  selectedOption={values.alert_status}
                  onChange={value =>
                    dropDownHandleSelect({ alert_status: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                  disabled={readonly}
                  required
                  {...useInput(`alert_privacy`, 'isRequired')}
                  placeHolder={'Privacy Type'}
                  options={alertPrivacy ? alertPrivacy : []}
                  selectedOption={values.alert_privacy}
                  onChange={value =>
                    dropDownHandleSelect({ alert_privacy: value ? value : null })
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'100%'}>
                <DropdownSearchable
                  disabled={readonly}
                  isCreate={true}
                  // to add items not present in the options(dropdown items)
                  isMulti={true}
                  placeHolder={'Labels'}
                  options={labels ? labels : []}
                  selectedOptionsProps={values?.alert_label}
                  selectedOption={values?.alert_label}
                  onChange={value =>
                    HandleMultiSelect({ alert_label: value ? value : null })
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'100%'}>
                <DropdownSearchable
                  disabled={readonly}
                  isMulti={true}
                  placeHolder={'Employees Assigned'}
                  options={employeeName}
                  selectedOptionsProps={values.employee_assigned}
                  selectedOption={values.employee_assigned}
                  onChange={value =>
                    HandleMultiSelect({ employee_assigned: value ? value : null })
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DateSelector
                  disabled={readonly}
                  labelTop="Resolution Date"
                  {...useInput(`resolution_date`)}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <InputLabelTop
                  disabled={true}
                  type="text"
                  id="no_of_days"
                  label={
                    values.no_of_days > 0 ? 'Number of days' : 'Number of hours'
                  }
                  {...useInput(
                    values.no_of_days > 0 ? 'no_of_days' : 'no_of_hrs'
                  )}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <InputLabelTop
                  disabled={true}
                  type="text"
                  id="no_of_days"
                  label={'Over Due Days'}
                  {...useInput('overDueDays')}
                />
              </FlexGrid3Item>
              {update && (
                <FlexGrid3Item className="pr-1 mt-1 mb-1" ResizeWidth={'100%'}>
                  <MultiFileUpload
                    setValues={setValues}
                    values={values}
                    MAX_COUNT={20}
                    keyName={'file_upload'}
                  />
                </FlexGrid3Item>
              )}
              <FlexGrid3Item className="pr-1 mt-2 mb-2" ResizeWidth={'100%'}>
                <InputTextArea
                  disabled={readonly}
                  type="text"
                  height={'2.5rem'}
                  id="Comments"
                  placeholder={'New comments ....'}
                  {...useInput('post_alert_comment')}
                />
              </FlexGrid3Item>

              {values.alert_comment?.length > 0 ? (
                <FlexGrid3Item
                  disabled={readonly}
                  className="pr-1 pt-1 mt-1 mb-2"
                  ResizeWidth={'100%'}
                  style={{
                    maxHeight: '160px',
                    overflowY: 'auto',
                    height: '160px',
                  }}>
                  {values.alert_comment?.length > 0 ? (
                    <small className="pr-1 ">Previous comments</small>
                  ) : null}
                  {values.alert_comment?.length > 0 &&
                    values.alert_comment?.map((commentData, index) => {
                      return (
                        <div key={commentData.id}>
                          <CommentList
                            commentData={commentData}
                            index={index}
                            deleteCommentList={deleteCommentList}
                            useInput={useInput}
                            values={values}
                            setValues={setValues}
                            employeeName={employeeName}
                            contextChoices={contextChoices}
                            handleGetChoices={handleGetChoices}
                            triage_choices={triage_choices}
                          />
                        </div>
                      );
                    })}
                </FlexGrid3Item>
              ) : null}
            </FlexGrid3Container>
            <div>
              <TitleSection>Are there any additional instructions ?</TitleSection>
              <div>
                { values?.alert_body_part?.map((item, indexBody)=>{
                  return(<>
                    <BorderContainer className={'pl-1 pr-2 pt-1 pb-1 mb-2'}>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex w-100'>
                          <div className='ml-1 mr-2' style={{ color: PURPLE }}>
                            <IoIosBody />
                          </div>
                          <div className='d-flex w-100'>
                            <div className='' style={{ width: '30%' }}>
                              {item.tittle}
                            </div>
                            <div className='ml-2' style={{ color: GRAY_700, width: '70%' }}>
                              {item.description}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <IconWrapper
                            style={{ color: PURPLE }}
                            onClick={() => {
                              handleRowBodyClick(indexBody);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                          <IconWrapper
                            style={{ color: ERR_COLOR, marginLeft: '1rem' }}
                            onClick={() => {
                              setModalDetachBodyMap(true)
                              setBodyMapDeleteIndex(indexBody)
                              
                            }}>
                            <RiDeleteBinLine />
                          </IconWrapper>
                        </div>
                      </div>
                    </BorderContainer>
                  </>)
                })}
              </div>
              <div className={'mt-2 mb-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={() => createNewBodyMap()}>
                  <FaPlus />
                  Create body map
                </PrimaryButtonForm>
              </div>
            </div>
            <DynamicList
              title="Triage Tasks"
              titleAdd={'ADD TASK'}
              titleSave="SAVE TASK"
              addNew={addNewTask}
              save={() => { }}
              noSave={noSave}>
              <Fragment>
                {values.triage_tasks ? (
                  values.triage_tasks.map((skill, index) => {
                    return (
                      <BorderContainer className={'pl-1 pr-2 pt-3 pb-1 mb-3'}>
                        <SkillRow className="d-flex ml-1 mb-2" key={index}>
                          <LeftSkillRow className="d-flex flex-column w-100">
                            <div className={'d-flex mb-2'}>
                              <CloseButtonWrapper>
                                <FaTimes
                                  onClick={() => {
                                    deleteTask(index);
                                  }}
                                />
                              </CloseButtonWrapper>
                              <div className="w-100 mr-1">
                                <DropdownSearchable
                                  required
                                  placeHolder={'Employee'}
                                  options={employeeName || []}
                                  disabled={false}
                                  selectedOption={
                                    values.triage_tasks[index].employee
                                      ? values.triage_tasks[index].employee
                                      : null
                                  }
                                  onChange={value =>
                                    dropDownHandleSelectTask(
                                      value ? value : null,
                                      index,
                                      'employee'
                                    )
                                  }
                                />
                              </div>
                              <div className="w-100 mr-1">
                                <DropdownSearchable
                                  placeHolder={'Task Status'}
                                  options={contextChoices.alert_task_status}
                                  disabled={false}
                                  selectedOption={
                                    values.triage_tasks[index].task_status
                                      ? values.triage_tasks[index].task_status
                                      : null
                                  }
                                  onChange={value =>
                                    dropDownHandleSelectTask(
                                      value ? value : null,
                                      index,
                                      'task_status'
                                    )
                                  }
                                />
                              </div>
                              <div className="w-100 mr-1">
                                <DropdownSearchable
                                  required
                                  placeHolder={'Triage Task'}
                                  options={contextChoices.alert_task}
                                  disabled={false}
                                  selectedOption={
                                    values.triage_tasks[index].alert_task
                                      ? values.triage_tasks[index].alert_task
                                      : null
                                  }
                                  onChange={value =>
                                    dropDownHandleSelectTask(
                                      value ? value : null,
                                      index,
                                      'alert_task'
                                    )
                                  }
                                />
                              </div>
                              <div className="w-100 mr-1">
                                <DateSelector
                                  id={`resolution_date`}
                                  dataIdx={index}
                                  labelTop="Resolution Date"
                                  value={
                                    values.triage_tasks[index].resolution_date
                                      ? values.triage_tasks[index]
                                        .resolution_date
                                      : ''
                                  }
                                  onChange={e => {
                                    handleInputChange(e, 'resolution_date');
                                  }}
                                />
                              </div>
                              <div className="w-100 ">
                                <InputLabelTop
                                  type="text"
                                  id={`message`}
                                  readOnly={false}
                                  label={'Messgae'}
                                  data-idx={index}
                                  value={values.triage_tasks[index].message}
                                  onChange={e => {
                                    handleInputChange(e, 'message');
                                  }}
                                />
                              </div>
                            </div>
                          </LeftSkillRow>
                        </SkillRow>
                      </BorderContainer>
                    );
                  })
                ) : (
                  <></>
                )}
              </Fragment>
            </DynamicList>
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {errorMessage ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ml-0 ml-md-3 mr-2'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={handleSubmit}
                    disabled={readonly && !update ? true : false}>
                    <span className={'mr-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </div>
        </ShowTab>

        <ShowTab show={isCurrentTab(tab2)}>
          <div className="pl-3 pr-3 pb-3 ">
            <div className={'mb-2 pb-2 '}>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'mt-3'}>
                  <PrimaryButtonForm
                    disabled={readonly && !update ? true : false}
                    minWidth="6rem"
                    onClick={addNewnotificationrule}>
                    <span>{PLUS} Notification rule</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
            {values.notificationrule?.length >= 1 &&
              values.notificationrule?.map((data, index) => {
                return (
                  <div key={data.id}>
                    <NotificationRule
                      data={data}
                      index={index}
                      values={values}
                      setValues={setValues}
                      addNewnotificationrule={addNewnotificationrule}
                      dropDownHandleNotificationrule={
                        dropDownHandleNotificationrule
                      }
                      addedSkillMessage={addedSkillMessage}
                      idSelected={idSelected}
                      setModalValue={setModalValue}
                      loadTriage={loadTriage}
                      setErrorMessage={setErrorMessage}
                      setShowError={setShowError}
                      setCheckRequires={setCheckRequires}
                      useInput={useInput}
                      errorMessage={errorMessage}
                    />
                  </div>
                );
              })}
          </div>
        </ShowTab>
        {!modalBodyMap ? null : (
          <BodyMapModal
            fullBind={fullBindBodyMap}
            setModalBodyMap={setModalBodyMap}
            useInput={useInput}
            values={values}
            setValues={setValues}
            toast={toast}
          />
        )}
        <div>
        
          <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            'Are you sure you want to delete the row? '
          }
          onOk={() => {
            toDeleteBodyData(bodyMapDeleteIndex);
          }
          }
          onNo={
            () => {
              setModalDetachBodyMap(false)
            }
          }
          {...bindModalDetachBodyMap}
        />
          </div>
       
         {/* <ModalDecision
          type="delete"
          title="Confirm"
          body={
            'Are you sure you want to delete the row? '
          }
          subBody={''}
          onOk={()=>toDeleteBodyData(bodyMapDeleteIndex)}
          onCancel={() => {
            setModalDetachBodyMap(false)
          }}
          okTitle={'DELETE'}
          {...bindModalDetachBodyMap}
        /> */}
       
      </ModalDynamic>
    </ModalContainer>
  );
};

export default TriageModal;
