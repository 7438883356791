import React, {useMemo} from 'react';
import {getOptionName} from 'utils/Array';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import {Container, FloatingLabel, ErrorLabel} from './styles';
import { Creatable, createFilter } from 'react-select';    //creatble  imported to make func. to add item not in option
const DropdownMedication = ({
  options = [],
  selectedOption = null,
  selectedOptionsProps = [],
  onChange = () => {},
  required = false,
  placeHolder = '',
  disabled,
  clearable = true,
  isMulti = false,
  errorMessage = '',
  data_error = false,
  typeFilter = false,
  ...props

}) => {
 
  const isRequired = required;
  let haveError = props['data-error'];
  let labelError = <span />;
  let value = isMulti
    ? (selectedOptionsProps && selectedOptionsProps.length)
    : selectedOption
    ? selectedOption
    : 0;

  if (props['data-error'] && !isRequired && !value) {
    haveError = false;
  }

  if (props['data-error'] && isRequired && !value) {
    labelError = <ErrorLabel>{errorMessage || 'Is required'}</ErrorLabel>;
  }

  
  const formatOptions = options => {
    
    if (options) {
      options = options.map(option => {
        return {value: option?.id, label: (option?.name ||  option?.full_name)};
      });
   
    } else {
      options = [];
    }
    return options;
  };
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option,
  });
  // const filterOptions = opts => {
  //   const options = formatOptions(opts);
  //   return createFilterOptions({options});
  // };
 
  const handleChange = selectedOption => {
    if (isMulti) {
      onChange(selectedOption);
    } else {
      if(selectedOption){
        onChange(selectedOption.value , (selectedOption.label || selectedOption.full_name), selectedOption);
      }else {
        onChange(null);
      }
    }
  };

  return (
    <Container
      data_error={haveError}
      typeFilter={typeFilter}
      width={props.width}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      >
      <Select
        disabled={disabled}
        clearable={clearable}
        multi={isMulti}
        selectComponent={props.isCreate ? Creatable : null}  //creatble to add item not in option
        value={
          isMulti
            ? selectedOptionsProps
            : selectedOption
            ? {
                value: selectedOption,
                label: getOptionName(options, selectedOption),
              }
            : ''
        }
        options={useMemo(() => formatOptions(options), [options])}
        filterOptions={useMemo(
          () => filterOptions(options), // eslint-disable-next-line react-hooks/exhaustive-deps
          [options]
        )}
        onChange={handleChange}
      />
      { labelError }

      <FloatingLabel typeFilter={typeFilter}>
        {placeHolder} <span>{required ? '*' : ''}</span>
      </FloatingLabel>
    </Container>
  );
};

export default DropdownMedication;
