module.exports = {
  ON_SAVE: 'onSave',
  ON_EDIT: 'onEdit',
  ON_SUCCESS: 'onSuccess',
  ON_ERROR: 'onError',
  ON_LOADING: 'onLoading',
  ON_DELETE: 'onDelete',
  ON_REMOVE: 'onRemove',
  ON_CANCEL: 'onCancel',
};
