import React, { Fragment } from 'react';
import Modal from 'react-bootstrap4-modal';
import { FaTimes } from 'react-icons/fa';
import './decision.css'

import { CancelButton, PrimaryButtonModal, Body, SubBody, WarningText } from './styles';
import { useEffect } from 'react';
import { useState } from 'react';

function OverlapDicisionModal({
  type,
  max_width,
  title,
  body,
  waningText = "",
  subBody,
  showModal,
  onOk,
  onCancel,
  okTitle,
  hideOnCancel,
  hideOnOk,
  onCancelTitle,
  isdisabled,
  ActionData = {},
  handleUnAssignVisits =() => {}
}) {
  const iconType = () => {
    switch (type) {
      case 'delete':
        return <FaTimes />;
      default:
        return <></>;
    }
  };
  // const [actionData,setActionData] = useState(ActionData)
  // useEffect(() => {
  //   setActionData(ActionData);
  // },[ActionData])
  // message: res.message,
  // actions: OVERLAP_ACTION_TYPES.filter(item => item.type  === "FORCE ASSIGN VISIT"),
  // visit_id: changeVisit.event._def.publicId,
  const { message, actions } = ActionData || {}
  console.log(ActionData,message, actions,"modal")
  //action.type === "FORCE UNASSIGN VISIT" ? 
  return (
    <Modal visible={showModal}     >
      <div className="modal-header border-0">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body text-center">
        <Body>{body}</Body>
        {ActionData?.message ? <WarningText>{ActionData?.message}</WarningText> : null}
        <SubBody>{subBody}</SubBody>
      </div>
      <div className="d-flex justify-content-center modal-footer border-0">
       
        {hideOnOk ? (
          <Fragment />
        ) : (
          <>
            {
              ActionData?.actions && ActionData?.actions && ActionData?.actions?.map(action => {
                return (
                  <PrimaryButtonModal disabled={isdisabled} typeButton={type} 
                    onClick={action.onOk}>
                    {iconType()}
                    {action.name || 'Ok'}
                  </PrimaryButtonModal>
                )
              })
            }</>
          // <PrimaryButtonModal disabled={isdisabled} typeButton={type} onClick={onOk}>
          //   {iconType()}
          //   {okTitle || 'Ok'}
          // </PrimaryButtonModal>
        )}
         {hideOnCancel ? (
          <Fragment />
        ) : (
          <CancelButton
            type="button"
            className="btn btn-secondary"
            onClick={() => onCancel()}>
            {onCancelTitle || 'Cancel'}
          </CancelButton>
        )}
      </div>
    </Modal>
  );
}

export default OverlapDicisionModal;
